import { takeEvery, put, call, all } from "redux-saga/effects";
import {
  Types,
  IRequestCyclesLoadAction,
  IRequestCyclesLoadByIdAction,
  IRequestCyclesAddAction,
  IRequestCyclesDeleteAction,
  IRequestCyclesEditAction,
  IRequestCyclesAtualizarOrgAction,
  ISuccessCyclesAtualizarOrgAction,
  CycleType,
} from "../ducks/cycles/types";
import api from "~/services/api";

const apiCallGet = (search: string) => {
  return api
    .get(`ciclos?SearchYear=${search}`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallGetById = (id: number) => {
  return api
    .get(`ciclos/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallPost = (item: CycleType) => {
  return api
    .post("ciclos", {
      ...item,
      idFuncionarioResponsavel: item.responsavel.idFuncionario,
      status: "P",
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallPut = (item: Partial<CycleType>) => {
  return api
    .put(`ciclos/${item.idCiclo}`, {
      ...item,
      idFuncionarioResponsavel: item.responsavel?.idFuncionario,
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallDel = (id: number) => {
  return api
    .delete(`ciclos/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallUpdateOrg = (
  idCiclo: IRequestCyclesAtualizarOrgAction["idCiclo"]
) => {
  return api
    .get(`ciclos/${idCiclo}`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

function* load(action: IRequestCyclesLoadAction) {
  try {
    const items = yield call(apiCallGet, action.search);
    yield put({ type: Types.SUCCESS_CYCLES_LOAD, payload: items });
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível carregar os ciclos.";
    yield put({ type: Types.FAILURE_CYCLES_LOAD, message });
  }
}

function* loadById(action: IRequestCyclesLoadByIdAction) {
  try {
    const item = yield call(apiCallGetById, action.id);
    yield put({ type: Types.SUCCESS_CYCLES_LOAD_ID, payload: item });
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível carregar o ciclo.";
    yield put({ type: Types.FAILURE_CYCLES_LOAD_ID, message });
  }
}

function* add(action: IRequestCyclesAddAction) {
  try {
    const idCiclo = yield call(apiCallPost, action.cycle);
    yield put({
      type: Types.SUCCESS_CYCLES_ADD,
      cycle: { ...action.cycle, ...idCiclo, status: "P" },
    });
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível adicionar ciclo.";
    yield put({ type: Types.FAILURE_CYCLES_ADD, message });
  }
}

function* edit(action: IRequestCyclesEditAction) {
  try {
    yield call(apiCallPut, action.cycle);
    yield put({ type: Types.SUCCESS_CYCLES_EDIT, cycle: action.cycle });
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível editar o ciclo.";
    yield put({ type: Types.FAILURE_CYCLES_EDIT, message });
  }
}

function* del(action: IRequestCyclesDeleteAction) {
  try {
    yield call(apiCallDel, action.id);
    yield put({ type: Types.SUCCESS_CYCLES_DELETE, id: action.id });
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível editar o ciclo.";
    yield put({ type: Types.FAILURE_CYCLES_DELETE, message });
  }
}

function* updateOrg(action: IRequestCyclesAtualizarOrgAction) {
  try {
    const dataHoraAtuOrg: Date = yield call(apiCallUpdateOrg, action.idCiclo);
    yield put({
      type: Types.SUCCESS_CYCLES_ATUALIZAR_ORG,
      payload: dataHoraAtuOrg,
    } as ISuccessCyclesAtualizarOrgAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível atualizar o organograma.";
    yield put({ type: Types.FAILURE_CYCLES_ATUALIZAR_ORG, message });
  }
}

export default function* companiesRoot() {
  yield all([
    yield takeEvery(Types.REQUEST_CYCLES_LOAD, load),
    yield takeEvery(Types.REQUEST_CYCLES_LOAD_ID, loadById),
    yield takeEvery(Types.REQUEST_CYCLES_ADD, add),
    yield takeEvery(Types.REQUEST_CYCLES_EDIT, edit),
    yield takeEvery(Types.REQUEST_CYCLES_DELETE, del),
    yield takeEvery(Types.REQUEST_CYCLES_ATUALIZAR_ORG, updateOrg),
  ]);
}
