/**
 * Types
 */
export enum Types {
  //GET
  REQUEST_POSITIONS_LOAD = "positions/REQUEST_POSITIONS_LOAD",
  SUCCESS_POSITIONS_LOAD = "positions/SUCCESS_POSITIONS_LOAD",
  FAILURE_POSITIONS_LOAD = "positions/FAILURE_POSITIONS_LOAD",
  //GET BY ID
  REQUEST_POSITIONS_LOAD_BY_ID = "positions/REQUEST_POSITIONS_LOAD_BY_ID",
  SUCCESS_POSITIONS_LOAD_BY_ID = "positions/SUCCESS_POSITIONS_LOAD_BY_ID",
  FAILURE_POSITIONS_LOAD_BY_ID = "positions/FAILURE_POSITIONS_LOAD_BY_ID",
  //ADD
  REQUEST_POSITION_ADD = "positions/REQUEST_POSITION_ADD",
  SUCCESS_POSITION_ADD = "positions/SUCCESS_POSITION_ADD",
  FAILURE_POSITION_ADD = "positions/FAILURE_POSITION_ADD",
  //PUT
  REQUEST_POSITION_EDIT = "positions/REQUEST_POSITION_EDIT",
  SUCCESS_POSITION_EDIT = "positions/SUCCESS_POSITION_EDIT",
  FAILURE_POSITION_EDIT = "positions/FAILURE_POSITION_EDIT",
  //DELETE
  REQUEST_POSITION_DELETE = "positions/REQUEST_POSITION_DELETE",
  SUCCESS_POSITION_DELETE = "positions/SUCCESS_POSITION_DELETE",
  FAILURE_POSITION_DELETE = "positions/FAILURE_POSITION_DELETE",
  //CLEAR
  CLEAR_POSITION_SEARCH = "positions/CLEAR_POSITION_SEARCH",
}
/**
 * Data types
 */
export interface PositionTypes {
  idCargo: number | null;
  codCargo: string;
  titulo: string;
  // cbo: string;
  dataExtinto: Date | null;
  gs: number | null;
  idCargoSuperior: number | null;
  flagAtivo: boolean;
  flagLideranca: boolean | null;
  cargosuperior: {
    titulo: string;
  } | null;
  idWorklevel: number | null;
  worklevel: {
    descricao: string;
  } | null;
  idCategoriaCargo: number | null;
  categoria: {
    descCategoriaCargo: string;
  } | null;
  idPadraoCargo: number | null;
  padraoCargo: {
    descPadraoCargo: string;
  } | null;
  idCarreira: number | null;
  carreira: {
    idCarreira: number | null;
    nomeCarreira: string;
  } | null;
}

export type CargoHistType = {
  idHistCargo: number;
  dataHist: Date;
  idCargo: number;
  idTipoMov: number;
  cargo?: {
    idCargo: number;
    titulo: string;
  };
  tipomov?: {
    idTipoMov: string;
    descTipoMov: string;
  }
}


interface PositionSelectedState {
  item: PositionTypes | null;
  loading: boolean;
  success: boolean;
  error: boolean;
}

interface IPositionsState {
  readonly data: PositionTypes[];
  readonly dataSearch: PositionTypes[];
  readonly itemSelected: PositionSelectedState;
  readonly loadingData: boolean;
  readonly loadingDataSearch: boolean;
  readonly loadingAction: boolean;
  readonly error: boolean;
  readonly success: boolean;
  readonly successDataSearch: boolean;
}

export type DataTypes = IPositionsState;

/**
 * Actions
 */

//GET
export interface IRequestPositionsLoadAction {
  type: typeof Types.REQUEST_POSITIONS_LOAD;
  payload: {
    search: object;
    isSearch?: boolean;
  };
}

export interface ISuccessPositionsLoadAction {
  type: typeof Types.SUCCESS_POSITIONS_LOAD;
  payload: {
    isSearch?: boolean;
    position: PositionTypes[];
  };
}

interface IFailurePositionsLoadAction {
  type: typeof Types.FAILURE_POSITIONS_LOAD;
  message: string;
}

type PositionsLoadAction =
  | IRequestPositionsLoadAction
  | ISuccessPositionsLoadAction
  | IFailurePositionsLoadAction;

//GET BY ID
export interface IRequestPositionsLoadByIdAction {
  type: typeof Types.REQUEST_POSITIONS_LOAD_BY_ID;
  idCargo: number;
}

export interface ISuccessPositionsLoadByIdAction {
  type: typeof Types.SUCCESS_POSITIONS_LOAD_BY_ID;
  payload: PositionTypes;
}

interface IFailurePositionsLoadByIdAction {
  type: typeof Types.FAILURE_POSITIONS_LOAD_BY_ID;
  message: string;
}

type PositionsLoadByIdAction =
  | IRequestPositionsLoadByIdAction
  | ISuccessPositionsLoadByIdAction
  | IFailurePositionsLoadByIdAction;

//ADD
export interface IRequestPositionAddAction {
  type: typeof Types.REQUEST_POSITION_ADD;
  position: PositionTypes;
}

interface ISuccessPositionAddAction {
  type: typeof Types.SUCCESS_POSITION_ADD;
  position: PositionTypes;
}

interface IFailurePositionAddAction {
  type: typeof Types.FAILURE_POSITION_ADD;
  message: string;
}

type PositionAddAction =
  | IRequestPositionAddAction
  | ISuccessPositionAddAction
  | IFailurePositionAddAction;

//PUT
export interface IRequestPositionEditAction {
  type: typeof Types.REQUEST_POSITION_EDIT;
  position: PositionTypes;
}

export interface ISuccessPositionEditAction {
  type: typeof Types.SUCCESS_POSITION_EDIT;
  position: PositionTypes;
}

export interface IFailurePositionEditAction {
  type: typeof Types.FAILURE_POSITION_EDIT;
  message: string;
}

type PositionEditAction =
  | IRequestPositionEditAction
  | ISuccessPositionEditAction
  | IFailurePositionEditAction;

//DELETE
export interface IRequestPositionDeleteAction {
  type: typeof Types.REQUEST_POSITION_DELETE;
  id: number;
}

export interface ISuccessPositionDeleteAction {
  type: typeof Types.SUCCESS_POSITION_DELETE;
  id: number;
}

export interface IFailurePositionDeleteAction {
  type: typeof Types.FAILURE_POSITION_DELETE;
  message: string;
}

interface IClearPositionSearch {
  type: typeof Types.CLEAR_POSITION_SEARCH;
}

type PositionDeleteAction =
  | IRequestPositionDeleteAction
  | ISuccessPositionDeleteAction
  | IFailurePositionDeleteAction
  | IClearPositionSearch;

export type PositionActionTypes =
  | PositionsLoadAction
  | PositionAddAction
  | PositionEditAction
  | PositionDeleteAction
  | PositionsLoadByIdAction;
