import styled from 'styled-components';

export const ContainerCycle = styled.div`
    background-color: #EFF1F3;
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding: 20px;
`
export const Title = styled.div`
    display: flex;
    flex-direction: row;
    align-content: center;
`
export const IconCycle = styled.img`
    padding-right: 20px;
`
export const DetailsWrapper = styled.div<{ notHasMarginLabelGroups: boolean }>`
    margin-left: ${({ notHasMarginLabelGroups }) => notHasMarginLabelGroups ? 0 : 52}px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-end;

`


export const WrapperButton = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
    width: max-content;
`
