import {
    Types,
    DataTypes,
    StandardsPositionsActionTypes,
    StandardPositionAddOrEditDTO,
  } from "./types";
  import customToast from "~/components/Toast/index";
  /**
   * Reducers
   */
  const INITIAL_STATE: DataTypes = {
    data: [],
    loadingData: false,
    loadingAction: false,
    success: false,
    error: false,
  };
  
  /**
   * Actions
   */
  
  export const Creators = {
    getStandardsPositions: (): StandardsPositionsActionTypes => {
      return {
        type: Types.REQUEST_LOAD_STANDARDS_POSITIONS,
      };
    },
  
    // getStandardPositionById: (
    //   idPadraoCargo: number
    // ): StandardsPositionsActionTypes => ({
    //   type: Types.REQUEST_LOAD_STANDARD_POSITION_BY_ID,
    //   idPadraoCargo,
    // }),
  
    addStandardPosition: (
      standard: StandardPositionAddOrEditDTO
    ): StandardsPositionsActionTypes => ({
      type: Types.REQUEST_ADD_STANDARD_POSITION,
      payload: standard,
    }),
  
    editStandardPosition: (
      standard: StandardPositionAddOrEditDTO
    ): StandardsPositionsActionTypes => ({
      type: Types.REQUEST_EDIT_STANDARD_POSITION,
      payload: standard,
    }),
  
    delStandardPosition: (
      idPadraoCargo: number
    ): StandardsPositionsActionTypes => ({
      type: Types.REQUEST_DEL_STANDARD_POSITION,
      idPadraoCargo,
    }),
  };
  
  export default function standardsPositionsReducer(
    state = INITIAL_STATE,
    action: StandardsPositionsActionTypes
  ): DataTypes {
    switch (action.type) {
      // LOAD
      case Types.REQUEST_LOAD_STANDARDS_POSITIONS:
        return {
          ...state,
          loadingData: true,
          error: false,
          success: false,
        };
      case Types.SUCCESS_LOAD_STANDARDS_POSITIONS:
        return {
          ...state,
          loadingData: false,
          error: false,
          success: true,
          data: action.payload,
        };
      case Types.FAILURE_LOAD_STANDARDS_POSITIONS:
        customToast.error(action.message);
        return {
          ...state,
          data: [],
          loadingData: false,
          success: false,
          error: true,
        };
  
      // //LOAD BY ID
      // case Types.REQUEST_LOAD_STANDARD_POSITION_BY_ID:
      //   return {
      //     ...state,
      //     itemSelected: {
      //       item: null,
      //       loading: true,
      //       success: false,
      //       error: false,
      //     },
      //   };
      // case Types.SUCCESS_LOAD_STANDARD_POSITION_BY_ID:
      //   return {
      //     ...state,
      //     itemSelected: {
      //       item: action.payload,
      //       loading: false,
      //       success: true,
      //       error: false,
      //     },
      //   };
      // case Types.FAILURE_LOAD_STANDARD_POSITION_BY_ID:
      //   customToast.error(action.message);
      //   return {
      //     ...state,
      //     itemSelected: {
      //       item: null,
      //       loading: false,
      //       success: false,
      //       error: true,
      //     },
      //   };
  
      // ADD
      case Types.REQUEST_ADD_STANDARD_POSITION:
        return { ...state, loadingAction: true, error: false, success: false };
      case Types.SUCCESS_ADD_STANDARD_POSITION:
        return {
          ...state,
          loadingAction: false,
          error: false,
          success: true,
          data: [...state.data, action.payload],
        };
      case Types.FAILURE_ADD_STANDARD_POSITION:
        customToast.error(action.message);
        return {
          ...state,
          loadingAction: false,
          success: false,
          error: true,
        };
  
      // EDIT
      case Types.REQUEST_EDIT_STANDARD_POSITION:
        return { ...state, loadingAction: true, error: false, success: false };
      case Types.SUCCESS_EDIT_STANDARD_POSITION:
        return {
          ...state,
          loadingAction: false,
          error: false,
          success: true,
          data: state.data.map((item) => {
            if (item.idPadraoCargo === action.payload.idPadraoCargo) {
              return action.payload;
            }
            return item;
          }),
        };
      case Types.FAILURE_EDIT_STANDARD_POSITION:
        customToast.error(action.message);
        return {
          ...state,
          loadingAction: false,
          success: false,
          error: true,
        };
  
      // DEL
      case Types.REQUEST_DEL_STANDARD_POSITION:
        return { ...state, error: false, success: false };
      case Types.SUCCESS_DEL_STANDARD_POSITION:
        return {
          ...state,
          error: false,
          success: true,
          data: state.data.filter(
            (item) => item.idPadraoCargo !== action.idPadraoCargo
          ),
        };
      case Types.FAILURE_DEL_STANDARD_POSITION:
        customToast.error(action.message);
        return {
          ...state,
          success: false,
          error: true,
        };
  
      default:
        return state;
    }
  }