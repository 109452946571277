import {
  Types,
  DataTypes,
  ProfileMetasActionTypes,
  IProfileMetasType,
  IProjectType,
  IProfileType,
  ProfilePdiAcoesTypes,
  NewFeedbackDataType,
  AddNewFeedbackDataType,
} from "./types";
import customToast from "~/components/Toast/index";

/**
 * Reducers
 */

const initialProjectSelected: IProjectType = {
  idCiclo: null,
  descProjeto: "",
  idCicloColaboradorProjeto: null,
  idCicloPesoPai: null,
  peso: "",
  prazoEntrega: null,
  idFuncionario: null,
  nomeProjeto: "",
  cicloPeso: {
    idObjetivo: null,
    objetivo: {
      descObjetivo: "",
    },
  },
};

const initialData: IProfileMetasType = {
  posicao: {
    area: {
      nomeLocal: "",
      idAreaPai: null,
    },
    cc: {
      nomeCC: "",
    },
    unidade: {
      nomeUnidade: "",
    },
    obsStatus: null,
    projetos: [],
    status: "",
    descPeriodo: "",
    flagSolicitarAprovacao: false,
    idArea: null,
    idVaga: null,
    metas: [],
    vigencia: null,
    idCicloColaborador: null,
    vaga: {
      area: {
        nomeLocal: "",
        idAreaPai: null,
      },
      cargo: {
        codCargo: "",
        titulo: "",
      },
      idArea: null,
      idCargo: null,
      idFuncionario: null,
      idUnidade: null,
      status: "",
    },
  },
  posicoes: [],
  temporadas: [],
  totalPesos: null,
  objetivosPesoAreas: [],
  FuncionarioOwner: {
    idFuncionario: null,
    matricula: "",
    nomeCompleto: "",
    status: "",
  },
  projectSelected: {
    item: initialProjectSelected,
    loadingAction: false,
    loading: false,
    success: false,
  },
};

const initialDataProfile: IProfileType = {
  idFuncionario: null,
  matricula: "",
  nomeCompleto: "",
  status: "",
  imagem: null,
  Area: {
    codArea: "",
    idArea: null,
    nomeLocal: "",
  },
  Cargo: {
    codCargo: "",
    idCargo: null,
    titulo: "",
  },
  cc: {
    idCC: null,
    nomeCC: "",
  },
  Unidade: {
    idUnidade: null,
    nomeUnidade: "",
  },
};

const INITIAL_STATE: DataTypes = {
  currentTab: "0",
  dataProfile: initialDataProfile,
  dataPerformance: [],
  dataMedidas: [],
  dataPdi: {
    anos: [],
    dataPDI: null,
  },
  dataFeedbacks: null,
  dataNewFeedback: null,
  dataFeedbackSelected: null,
  data: initialData,
  loadingData: false,
  error: false,
  success: false,
  successAction: false,
  hasMetas: false,
  loadingAction: false,
  loadingProject: false,
  loadingAprovacao: false,
};

/**
 * Actions
 */

export const Creators = {
  setCurrentTab: (indexTab: string): ProfileMetasActionTypes => {
    return {
      type: Types.SET_CURRENT_TAB,
      indexTab,
    };
  },

  getProfile: (id?: number): ProfileMetasActionTypes => {
    return {
      type: Types.REQUEST_PROFILE_LOAD,
      id,
    };
  },

  getProfileMetas: (
    idFuncionario?: number | null,
    idCiclo?: number,
    idCicloColaborador?: number
  ): ProfileMetasActionTypes => {
    return {
      type: Types.REQUEST_PROFILE_METAS_LOAD,
      payload: {
        idFuncionario,
        idCiclo,
        idCicloColaborador,
      },
    };
  },

  getProjects: (
    idCiclo: number,
    idCicloColaborador: number,
    idFuncionario?: number
  ): ProfileMetasActionTypes => ({
    type: Types.REQUEST_GET_PROJECTS,
    payload: {
      idCiclo,
      idCicloColaborador,
      idFuncionario,
    },
  }),

  getProjectsById: (
    idCicloColaborador: number,
    idCicloColaboradorProjeto: number,
    idFuncionario?: number
  ): ProfileMetasActionTypes => ({
    type: Types.REQUEST_GET_PROJECT_BY_ID,
    payload: {
      idCicloColaboradorProjeto,
      idFuncionario,
      idCicloColaborador,
    },
  }),

  addProject: (
    project: Partial<IProjectType>,
    idCicloColaborador: number
  ): ProfileMetasActionTypes => ({
    type: Types.REQUEST_ADD_PROJECT,
    payload: {
      project,
      idCicloColaborador,
    },
  }),

  editProject: (
    project: IProjectType,
    idCicloColaborador: number
  ): ProfileMetasActionTypes => ({
    type: Types.REQUEST_EDIT_PROJECT,
    payload: {
      project,
      idCicloColaborador,
    },
  }),

  delProject: (
    idCicloColaborador: number,
    idCicloColaboradorProjeto: number,
    idFuncionario?: number
  ): ProfileMetasActionTypes => ({
    type: Types.REQUEST_DEL_PROJECT,
    payload: {
      idCicloColaboradorProjeto,
      idFuncionario,
      idCicloColaborador,
    },
  }),

  reqAprovacao: (
    idCiclo: number,
    idCicloColaborador: number,
    idFuncionario?: number
  ): ProfileMetasActionTypes => ({
    type: Types.REQUEST_APROVACAO,
    payload: {
      idCiclo,
      idCicloColaborador,
      idFuncionario,
    },
  }),

  getPerformance: (idFuncionario?: number): ProfileMetasActionTypes => ({
    type: Types.REQUEST_LOAD_PERFORMANCE,
    idFuncionario,
  }),

  getPdi: (
    idFuncionario?: number,
    ano: string = ""
  ): ProfileMetasActionTypes => ({
    type: Types.REQUEST_PROFILE_PDI_LOAD,
    payload: {
      idFuncionario,
      ano,
    },
  }),

  addPdi: (
    ano: string,
    pdiAction: Omit<ProfilePdiAcoesTypes, "idPDI">,
    idFuncionario?: number
  ): ProfileMetasActionTypes => ({
    type: Types.REQUEST_PROFILE_PDI_ADD,
    payload: {
      idFuncionario,
      ano,
      pdiAction,
    },
  }),

  editPdi: (
    ano: string,
    pdiAction: ProfilePdiAcoesTypes,
    idFuncionario?: number
  ): ProfileMetasActionTypes => ({
    type: Types.REQUEST_PROFILE_PDI_EDIT,
    payload: {
      idFuncionario,
      ano,
      pdiAction,
    },
  }),

  delPdi: (
    ano: string,
    idPDIAcao: number,
    idFuncionario?: number
  ): ProfileMetasActionTypes => ({
    type: Types.REQUEST_PROFILE_PDI_DEL,
    payload: {
      idFuncionario,
      ano,
      idPDIAcao,
    },
  }),

  getMedidas: (idFuncionario?: number | string): ProfileMetasActionTypes => ({
    type: Types.REQUEST_PROFILE_MEDIDAS_LOAD,
    idFuncionario,
  }),

  getFeedbacks: (idFuncionario: number): ProfileMetasActionTypes => ({
    type: Types.REQUEST_PROFILE_FEEDBACKS_LOAD,
    idFuncionario,
  }),

  getNewFeedback: (idFuncionario: number): ProfileMetasActionTypes => ({
    type: Types.REQUEST_PROFILE_NEWFEEDBACKS_LOAD,
    idFuncionario,
  }),

  addNewFeedback: (idFuncionario: number, data: AddNewFeedbackDataType): ProfileMetasActionTypes => ({
    type: Types.REQUEST_PROFILE_NEWFEEDBACKS_ADD,
    payload: { idFuncionario, data }
  }),

  getNewFeedbackById: (idFuncionario: number, idFeedback: number): ProfileMetasActionTypes => ({
    type: Types.REQUEST_PROFILE_NEWFEEDBACK_LOAD_BY_ID,
    payload: { idFuncionario, idFeedback }
  }),
};

export default function profileReducer(
  state = INITIAL_STATE,
  action: ProfileMetasActionTypes
): DataTypes {
  switch (action.type) {
    case Types.SET_CURRENT_TAB:
      return { ...state, currentTab: action.indexTab };

    case Types.REQUEST_PROFILE_LOAD:
      return { ...state, dataProfile: initialDataProfile };
    case Types.SUCCESS_PROFILE_LOAD:
      return { ...state, dataProfile: action.payload };
    case Types.FAILURE_PROFILE_LOAD:
      customToast.error(action.message);
      return { ...state, dataProfile: initialDataProfile };

    case Types.REQUEST_PROFILE_METAS_LOAD:
      return {
        ...state,
        loadingData: true,
        success: false,
        error: false,
        data: {
          ...initialData,
          projectSelected: {
            item: initialProjectSelected,
            success: false,
            loading: false,
            loadingAction: false,
          },
        },
      };
    case Types.SUCCESS_PROFILE_METAS_LOAD:
      if (action.payload) {
        return {
          ...state,
          loadingData: false,
          success: true,
          error: false,
          data: {
            ...action.payload,
            projectSelected: {
              item: initialProjectSelected,
              success: false,
              loading: false,
              loadingAction: false,
            },
          },
          hasMetas: true,
        };
      } else {
        return {
          ...state,
          loadingData: false,
          success: true,
          error: false,
          hasMetas: false,
        };
      }
    case Types.FAILURE_PROFILE_METAS_LOAD:
      customToast.error(action.message);
      return { ...state, loadingData: false, success: false, error: true };

    case Types.REQUEST_GET_PROJECTS:
      return { ...state, error: false, loadingProject: true };
    case Types.SUCCESS_GET_PROJECTS:
      return {
        ...state,
        data: {
          ...state.data,
          posicao: { ...state.data.posicao, projetos: action.payload },
        },
        error: false,
        success: true,
        loadingProject: false,
      };
    case Types.FAILURE_GET_PROJECTS:
      customToast.error(action.message);
      return { ...state, error: true, loadingProject: false };

    case Types.REQUEST_GET_PROJECT_BY_ID:
      return {
        ...state,
        data: {
          ...state.data,
          projectSelected: {
            item: initialProjectSelected,
            loading: true,
            success: false,
            loadingAction: false,
          },
        },
      };
    case Types.SUCCESS_GET_PROJECT_BY_ID:
      return {
        ...state,
        data: {
          ...state.data,
          projectSelected: {
            item: action.payload,
            loading: false,
            success: true,
            loadingAction: false,
          },
        },
      };
    case Types.FAILURE_GET_PROJECT_BY_ID:
      customToast.error(action.message);
      return {
        ...state,
        data: {
          ...state.data,
          projectSelected: {
            item: initialProjectSelected,
            loading: false,
            success: false,
            loadingAction: false,
          },
        },
      };

    case Types.REQUEST_ADD_PROJECT:
      return {
        ...state,
        error: false,
        success: false,
        data: {
          ...state.data,
          projectSelected: {
            ...state.data.projectSelected,
            loadingAction: true,
          },
        },
      };
    case Types.SUCCESS_ADD_PROJECT:
      customToast.success("Iniciativa chave adicionada com sucesso");
      return {
        ...state,
        data: {
          ...state.data,
          projectSelected: {
            ...state.data.projectSelected,
            loadingAction: false,
          },
          posicao: {
            ...state.data.posicao,
            projetos: [...state.data.posicao.projetos, action.payload],
          },
        },
        error: false,
        success: true,
      };
    case Types.FAILURE_ADD_PROJECT:
      customToast.error(action.message);
      return {
        ...state,
        error: true,
        success: false,
        data: {
          ...state.data,
          projectSelected: {
            ...state.data.projectSelected,
            loadingAction: false,
          },
        },
      };

    case Types.REQUEST_EDIT_PROJECT:
      return {
        ...state,
        error: false,
        success: false,
        data: {
          ...state.data,
          projectSelected: {
            ...state.data.projectSelected,
            loadingAction: true,
          },
        },
      };
    case Types.SUCCESS_EDIT_PROJECT:
      customToast.success("Iniciativa chave editado com sucesso");
      return {
        ...state,
        data: {
          ...state.data,
          projectSelected: {
            ...state.data.projectSelected,
            loadingAction: false,
          },
          posicao: {
            ...state.data.posicao,
            projetos: state.data.posicao.projetos.map((project) => {
              if (
                project.idCicloColaboradorProjeto !==
                action.payload.idCicloColaboradorProjeto
              ) {
                return project;
              } else {
                return action.payload;
              }
            }),
          },
        },
        error: false,
        success: true,
      };
    case Types.FAILURE_EDIT_PROJECT:
      customToast.error(action.message);
      return {
        ...state,
        error: true,
        success: false,
        data: {
          ...state.data,
          projectSelected: {
            ...state.data.projectSelected,
            loadingAction: false,
          },
        },
      };

    case Types.REQUEST_DEL_PROJECT:
      return { ...state, error: false, success: false };
    case Types.SUCCESS_DEL_PROJECT:
      customToast.success("Iniciativa chave excluído com sucesso");
      return {
        ...state,
        data: {
          ...state.data,
          posicao: {
            ...state.data.posicao,
            projetos: state.data.posicao.projetos.filter(
              (project) =>
                project.idCicloColaboradorProjeto !==
                action.idCicloColaboradorProjeto
            ),
          },
        },
        error: false,
        success: true,
      };
    case Types.FAILURE_DEL_PROJECT:
      customToast.error(action.message);
      return { ...state, error: true, success: false };

    case Types.REQUEST_APROVACAO:
      return { ...state, loadingAprovacao: true, success: false };
    case Types.SUCCESS_APROVACAO:
      customToast.success("Solicitação enviada com sucesso");
      return {
        ...state,
        loadingAprovacao: false,
        success: true,
        data: {
          ...state.data,
          posicao: {
            ...state.data.posicao,
            flagSolicitarAprovacao: false,
            status: "AGUARDANDO APROVAÇÃO",
          },
        },
      };
    case Types.FAILURE_APROVACAO:
      customToast.error(action.message);
      return {
        ...state,
        loadingAprovacao: false,
        success: false,
        data: {
          ...state.data,
          posicao: { ...state.data.posicao, flagSolicitarAprovacao: true },
        },
      };

    case Types.REQUEST_LOAD_PERFORMANCE:
      return {
        ...state,
        dataPerformance: [],
        loadingData: true,
        success: false,
        error: false,
      };
    case Types.SUCCESS_LOAD_PERFORMANCE:
      return {
        ...state,
        dataPerformance: action.payload,
        loadingData: false,
        success: true,
        error: false,
      };
    case Types.FAILURE_LOAD_PERFORMANCE:
      customToast.error(action.message);
      return {
        ...state,
        dataPerformance: [],
        loadingData: false,
        success: false,
        error: true,
      };

    // GET PDI
    case Types.REQUEST_PROFILE_PDI_LOAD:
      return {
        ...state,
        dataPdi: { anos: [], dataPDI: null },
        loadingData: true,
        success: false,
        error: false,
      };
    case Types.SUCCESS_PROFILE_PDI_LOAD:
      return {
        ...state,
        dataPdi: action.payload,
        loadingData: false,
        success: true,
        error: false,
      };
    case Types.FAILURE_PROFILE_PDI_LOAD:
      customToast.error(action.message);
      return {
        ...state,
        dataPdi: { anos: [], dataPDI: null },
        loadingData: false,
        success: false,
        error: true,
      };

    // ADD PDI
    case Types.REQUEST_PROFILE_PDI_ADD:
      return {
        ...state,
        loadingAction: true,
        success: false,
        error: false,
      };
    case Types.SUCCESS_PROFILE_PDI_ADD:
      customToast.success("PDI adicionado com sucesso!");
      return {
        ...state,
        dataPdi: {
          ...state.dataPdi,
          dataPDI: {
            ...state.dataPdi.dataPDI!,
            acoes: [...state.dataPdi.dataPDI?.acoes!, action.payload],
          },
        },
        loadingAction: false,
        success: true,
        error: false,
      };
    case Types.FAILURE_PROFILE_PDI_ADD:
      customToast.error(action.message);
      return {
        ...state,
        loadingAction: false,
        success: false,
        error: true,
      };

    // EDIT PDI
    case Types.REQUEST_PROFILE_PDI_EDIT:
      return {
        ...state,
        loadingAction: true,
        success: false,
        error: false,
      };
    case Types.SUCCESS_PROFILE_PDI_EDIT:
      customToast.success("PDI editado com sucesso!");
      return {
        ...state,
        dataPdi: {
          ...state.dataPdi,
          dataPDI: {
            ...state.dataPdi.dataPDI!,
            acoes: state.dataPdi.dataPDI?.acoes.map((item) => {
              if (Number(item.idPDIAcao) !== action.payload.idPDIAcao) {
                return item;
              } else {
                return action.payload;
              }
            })!,
          },
        },
        loadingAction: false,
        success: true,
        error: false,
      };
    case Types.FAILURE_PROFILE_PDI_EDIT:
      customToast.error(action.message);
      return {
        ...state,
        loadingAction: false,
        success: false,
        error: true,
      };

    // DEL PDI
    case Types.REQUEST_PROFILE_PDI_DEL:
      return {
        ...state,
        loadingAction: true,
        success: false,
        error: false,
      };
    case Types.SUCCESS_PROFILE_PDI_DEL:
      customToast.success("PDI excluído com sucesso!");
      return {
        ...state,
        dataPdi: {
          ...state.dataPdi,
          dataPDI: {
            ...state.dataPdi.dataPDI!,
            acoes: state.dataPdi.dataPDI?.acoes.filter(
              (item) => item.idPDIAcao !== action.idPDIAcao
            )!,
          },
        },
        loadingAction: false,
        success: true,
        error: false,
      };
    case Types.FAILURE_PROFILE_PDI_DEL:
      customToast.error(action.message);
      return {
        ...state,
        loadingAction: false,
        success: false,
        error: true,
      };

    // DEL PDI
    case Types.REQUEST_PROFILE_MEDIDAS_LOAD:
      return {
        ...state,
        loadingData: true,
        success: false,
        error: false,
      };
    case Types.SUCCESS_PROFILE_MEDIDAS_LOAD:
      return {
        ...state,
        dataMedidas: action.payload,
        loadingData: false,
        success: true,
        error: false,
      };
    case Types.FAILURE_PROFILE_MEDIDAS_LOAD:
      customToast.error(action.message);
      return {
        ...state,
        dataMedidas: [],
        loadingData: false,
        success: false,
        error: true,
      };

    // GET FEEDBACKS
    case Types.REQUEST_PROFILE_FEEDBACKS_LOAD:
      return {
        ...state,
        dataFeedbacks: null,
        loadingData: true,
        success: false,
        error: false,
      };
    case Types.SUCCESS_PROFILE_FEEDBACKS_LOAD:
      return {
        ...state,
        dataFeedbacks: action.payload,
        loadingData: false,
        success: true,
        error: false,
      };
    case Types.FAILURE_PROFILE_FEEDBACKS_LOAD:
      customToast.error(action.message);
      return {
        ...state,
        dataFeedbacks: null,
        loadingData: false,
        success: false,
        error: true,
      };

    // GET NEW FEEDBACKS
    case Types.REQUEST_PROFILE_NEWFEEDBACKS_LOAD:
      return {
        ...state,
        dataNewFeedback: null,
        loadingData: true,
        success: false,
        successAction: false,
        error: false,
        dataFeedbackSelected: null
      };
    case Types.SUCCESS_PROFILE_NEWFEEDBACKS_LOAD:
      return {
        ...state,
        dataNewFeedback: action.payload,
        loadingData: false,
        success: true,
        error: false,
      };
    case Types.FAILURE_PROFILE_NEWFEEDBACKS_LOAD:
      customToast.error(action.message);
      return {
        ...state,
        dataNewFeedback: null,
        loadingData: false,
        success: false,
        error: true,
      };

    // GET NEW FEEDBACKS
    case Types.REQUEST_PROFILE_NEWFEEDBACKS_ADD:
      return {
        ...state,
        successAction: false,
        loadingAction: true,
        success: false,
        error: false,
      };
    case Types.SUCCESS_PROFILE_NEWFEEDBACKS_ADD:
      customToast.success("Feedback enviado com sucesso!");
      return {
        ...state,
        successAction: true,
        loadingAction: false,
        success: true,
        error: false,
      };
    case Types.FAILURE_PROFILE_NEWFEEDBACKS_ADD:
      customToast.error(action.message);
      return {
        ...state,
        successAction: false,
        loadingAction: false,
        success: false,
        error: true,
      };
    // GET NEW FEEDBACKS
    case Types.REQUEST_PROFILE_NEWFEEDBACK_LOAD_BY_ID:
      return {
        ...state,
        loadingData: true,
        success: false,
        error: false,
        dataFeedbackSelected: null
      };
      case Types.SUCCESS_PROFILE_NEWFEEDBACK_LOAD_BY_ID:
      return {
        ...state,
        loadingData: false,
        success: true,
        error: false,
        dataFeedbackSelected: action.payload
      };
    case Types.FAILURE_PROFILE_NEWFEEDBACK_LOAD_BY_ID:
      customToast.error(action.message);
      return {
        ...state,
        loadingData: false,
        success: false,
        error: true,
        dataFeedbackSelected: null
      };

    default:
      return state;
  }
}
