//#region Imports
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Creators } from "~/store/ducks/home";
import { useFormik } from "formik";
import * as yup from "yup";

import {
  CommandBarButton,
  IconButton,
  ICommandBarStyles,
  IButtonStyles,
  Spinner,
  SpinnerSize,
} from "office-ui-fabric-react";
import { Stack } from "office-ui-fabric-react/lib/Stack";
import { IDialogContentProps } from "office-ui-fabric-react/lib/Dialog";
import { Toggle } from "office-ui-fabric-react/lib/Toggle";

import {
  DefaultButton,
  Wrapper,
  ContainerContent,
  WrapperTreeview,
} from "./styles";

import MenuItem from "@material-ui/core/MenuItem";

import { PrimaryButton } from "~/components/Buttons";
import CustomDialog from "~/components/CustomDialogFluentUI";
import Panel from "~/components/layout/Panel";
import Dropdown from "~/components/layout/Dropdown";
import { InputText, InputCheckbox } from "~/components/Forms";
import HeaderPage from "~/components/layout/HeaderPage";
import NoItems from "~/components/layout/NoItems";
import TreeView from "~/components/TreeView";
import { DeleteButton } from "~/components/Buttons";

import { Page } from "~/store/ducks/home/types";
import { RootState } from "~/store/ducks";
import { ChartType, DataTypes } from "~/store/ducks/admin/chart/types";
import { Creators as CreatorCharts } from "~/store/ducks/admin/chart";

import colors from "~/assets/js/colors";

const validationSchema = yup.object().shape({
  idArea: yup.number().required("Campo obrigatório"),
  idAreaPai: yup.number().nullable().notRequired(),
  codArea: yup
    .string()
    .max(30, "O código do local não pode ter mais do que 30 caracteres")
    .required("Campo obrigatório"),
  nomeLocal: yup
    .string()
    .min(2, "A descrição do objetivo deve conter pelo menos 2 caracteres")
    .max(60, "A descrição do objetivo não pode ter mais do que 60 caracteres")
    .required("Campo obrigatório"),
  nivel: yup.string().required("Campo obrigatório"),
  apelido: yup
    .string()
    .nullable()
    .max(4, "O apelido não pode ter mais do que 4 caracteres")
    .required("Campo obrigatório"),
  tipoLocal: yup.string().required("Campo obrigatório"),
});

const btnStyle: Partial<ICommandBarStyles> = {
  root: {
    height: 44,
  },
};

const btnPanelStyles: Partial<IButtonStyles> = {
  root: {
    color: colors.darkGray,
  },
};

let initial: ChartType = {
  idArea: null,
  codArea: "",
  nomeLocal: "",
  nivel: "",
  apelido: "",
  areaPai: null,
  idAreaPai: null,
  tipoLocal: "",
  children: [],
  flagAtiva: true,
};

const Chart: React.FC = () => {
  const dispatch = useDispatch();

  const [initialValues, setInitialValues] = useState<ChartType>(initial);

  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [isDialogClosePanel, setIsDialogClosePanel] = useState(false);

  const [itemSelected, setItemSelected] = useState<ChartType | null>(null);
  const [isDialogExcludeOpen, setIsDialogExcludeOpen] = useState(false);
  const [isToggleActive, setIsToggleActive] = useState(false);

  const chartState = useSelector<RootState, DataTypes>(
    (state) => state.chartReducer
  );

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    resetForm,
    setValues,
    setFieldError,
  } = useFormik({
    initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit(values) {
      if (!initialValues.idArea) {
        dispatch(CreatorCharts.addChart(values));
      } else {
        dispatch(
          CreatorCharts.editChart(
            { ...values, flagAtiva: !values.flagAtiva },
            initialValues.idAreaPai
          )
        );
      }
    },
  });

  useEffect(() => {
    const page: Page = {
      key: "organograma",
      pages: [
        {
          text: "Home",
          isCurrentItem: false,
          icon: "HomeSolid",
          onlyIcon: true,
          url: "/",
        },
        { text: "Organograma", isCurrentItem: true },
      ],
    };

    dispatch(CreatorCharts.getChart("", true));
    dispatch(Creators.setCurrentPage(page));
  }, [dispatch]);

  useEffect(() => {
    if (chartState.itemSelected.success) {
      // if (isAddingChild) {
      //   const { nomeLocal, idArea } = chartState.itemSelected.item;
      //   initial.areaPai = { nomeLocal };
      //   initial.idAreaPai = idArea;
      //   setInitialValues(initial);
      // } else {
      setInitialValues({
        ...chartState.itemSelected.item,
        flagAtiva: !chartState.itemSelected.item.flagAtiva,
      });
      // }
    }
  }, [chartState.itemSelected]);

  useEffect(() => {
    if (chartState.success) {
      setIsPanelOpen(false);
    }
  }, [chartState.success]);

  useEffect(() => {
    if (chartState.error) {
      // setIsPanelOpen(false);
    }
  }, [chartState.error]);

  const handleClosePanel = () => {
    setIsPanelOpen(false);
    window.setTimeout(() => {
      resetForm();
    }, 500);
  };

  const excludeArea = () => {
    setIsDialogExcludeOpen(false);
    setItemSelected(null);
    dispatch(CreatorCharts.delChart(itemSelected?.idArea!));
  };

  const cancelPanel = () => {
    setIsPanelOpen(false);
  };

  const openEdit = (item: ChartType) => {
    setIsPanelOpen(true);
    // setIsAddingChild(false);
    dispatch(CreatorCharts.getChartById(item?.idArea!));
  };

  const handleOnItemInvoked = (item: ChartType) => {
    openEdit(item);
  };

  const handleToggle = () => {
    setIsToggleActive(!isToggleActive);
    if (isToggleActive) {
      dispatch(CreatorCharts.getChart("", true));
    } else {
      dispatch(CreatorCharts.getChart());
    }
  };

  const commandBarRender = () => {
    if (itemSelected) {
      return (
        <>
          <CommandBarButton
            styles={btnStyle}
            iconProps={{ iconName: "Edit" }}
            text="Editar"
            onClick={() => openEdit(itemSelected)}
          />
          <CommandBarButton
            styles={btnStyle}
            iconProps={{ iconName: "RowsChild" }}
            text="Adicionar Área Filho"
            onClick={() => {
              setIsPanelOpen(true);
              const { nomeLocal, idArea } = itemSelected;
              setInitialValues({
                ...initial,
                idAreaPai: idArea,
                areaPai: { nomeLocal },
              });
            }}
          />
          <CommandBarButton
            styles={btnStyle}
            iconProps={{ iconName: "Delete" }}
            text="Excluir"
            onClick={() => setIsDialogExcludeOpen(true)}
          />
        </>
      );
    }
  };

  const dialogProps: IDialogContentProps = {
    title: !values.idArea ? "Cancelar cadastro?" : "Cancelar edição?",
    subText: !values.idArea
      ? "Todos os dados inseridos serão perdidos. Tem certeza que quer cancelar o cadastro da área? "
      : "As mudanças serão descardas. Tem certeza que quer cancelar a edição da área?",
  };

  const dialogContentProps = {
    title: "Excluir?",
    closeButtonAriaLabel: "Close",
    subText:
      0 <= 1
        ? "Tem certeza de que deseja excluir este item?"
        : "Tem certeza de que deseja excluir estes items?",
  };

  return (
    <>
      <Panel
        title={
          !initialValues.idArea
            ? "Nova Área"
            : chartState.itemSelected.item.nomeLocal
        }
        loading={chartState.itemSelected.loading}
        open={isPanelOpen}
        onClose={() => cancelPanel}
        hideClose={true}
        itemsHeader={
          <IconButton
            iconProps={{ iconName: "ChromeClose" }}
            styles={btnPanelStyles}
            title="Fechar"
            ariaLabel="Fechar"
            onClick={() => setIsPanelOpen(false)}
          />
        }
        footer={
          <Stack horizontal tokens={{ childrenGap: 10 }}>
            <DefaultButton onClick={() => cancelPanel()}>
              Cancelar
            </DefaultButton>
            <PrimaryButton
              onClick={(e: any) => handleSubmit(e)}
              isLoading={chartState.loadingAction}
              text="Salvar"
            />
          </Stack>
        }
      >
        {chartState.itemSelected.loading ? (
          <Spinner
            size={SpinnerSize.large}
            styles={{ root: { height: "calc(100% - 50px)" } }}
          />
        ) : (
          <form onSubmit={handleSubmit}>
            {!initialValues.idArea && (
              <InputText
                value={values.idArea ?? ""}
                id="idArea"
                name="idArea"
                onChange={handleChange}
                type="number"
                label="ID Área"
                className="mt-2"
                autoFocus
              />
            )}
            {values.areaPai !== null && (
              <InputText
                value={values.idAreaPai ?? ""}
                id="idAreaPai"
                name="idAreaPai"
                onChange={handleChange}
                type="number"
                label="Área Pai"
                className="mt-2"
                disabled={!values.idArea}
              />
            )}
            <InputText
              value={values.codArea}
              onChange={(e: any) => {
                handleChange(e);
                setFieldError("codArea", "");
              }}
              id="codArea"
              error={errors.codArea ? true : false}
              name="codArea"
              type="text"
              label="Código da área"
              helperText={errors.codArea}
              className="mt-2"
              inputProps={{ maxLength: 30 }}
            />
            <InputText
              value={values.nomeLocal}
              onChange={(e: any) => {
                handleChange(e);
                setFieldError("nomeLocal", "");
              }}
              id="nomeLocal"
              error={errors.nomeLocal ? true : false}
              name="nomeLocal"
              type="text"
              label="Nome da área"
              helperText={errors.nomeLocal}
              inputProps={{ maxLength: 60 }}
              className="mt-2"
            />
            <InputText
              value={values.apelido ?? ""}
              onChange={(e: any) => {
                handleChange(e);
                setFieldError("apelido", "");
              }}
              id="apelido"
              error={errors.apelido ? true : false}
              name="apelido"
              type="text"
              label="Apelido"
              helperText={errors.apelido}
              inputProps={{ maxLength: 4 }}
              className="mt-2"
            />
            <Dropdown
              errors={errors.nivel}
              label="Nível do objetivo"
              name="nivel"
              values={values.nivel}
              handleChange={(e: any) => {
                handleChange(e);
                setFieldError("nivel", "");
              }}
              errorText={errors.nivel}
            >
              <MenuItem value={"T"}>Tático</MenuItem>
              <MenuItem value={"E"}>Estratégico</MenuItem>
              <MenuItem value={"O"}>Operacional</MenuItem>
            </Dropdown>
            <Dropdown
              errors={errors.tipoLocal}
              label="Nível do local"
              name="tipoLocal"
              values={values.tipoLocal}
              handleChange={(e: any) => {
                handleChange(e);
                setFieldError("tipoLocal", "");
              }}
              errorText={errors.tipoLocal}
            >
              <MenuItem value={"P"}>Presidência</MenuItem>
              <MenuItem value={"V"}>Vice Presidência</MenuItem>
              <MenuItem value={"D"}>Diretoria</MenuItem>
              <MenuItem value={"G"}>Gerência</MenuItem>
              <MenuItem value={"L"}>Local</MenuItem>
            </Dropdown>
            {!!initialValues.idArea && (
              <InputCheckbox
                checked={values.flagAtiva}
                onChange={handleChange}
                name="flagAtiva"
                color="primary"
                label="Inativar Área"
              />
            )}
          </form>
        )}
      </Panel>

      <CustomDialog
        hidden={!isDialogExcludeOpen}
        onDismiss={() => setIsDialogExcludeOpen(false)}
        dialogContentProps={dialogContentProps}
      >
        <DefaultButton
          onClick={() => setIsDialogExcludeOpen(false)}
          text="Cancelar"
        />
        <DeleteButton onClick={excludeArea} text="Excluir" />
      </CustomDialog>

      <CustomDialog
        hidden={!isDialogClosePanel}
        onDismiss={() => setIsDialogClosePanel(false)}
        dialogContentProps={dialogProps}
      >
        <DefaultButton
          onClick={() => setIsDialogClosePanel(false)}
          text="Cancelar"
        />
        <PrimaryButton
          onClick={() => {
            setIsDialogClosePanel(false);
            handleClosePanel();
          }}
          text="Fechar"
        />
      </CustomDialog>

      <Wrapper>
        <ContainerContent>
          <HeaderPage
            title="Organograma"
            leftItems={
              <>
                <CommandBarButton
                  styles={btnStyle}
                  iconProps={{ iconName: "Add" }}
                  text="Adicionar Área"
                  onClick={() => {
                    setValues({ ...initial, areaPai: null });
                    dispatch(CreatorCharts.clearChartSelected());
                    setInitialValues(initial);
                    setIsPanelOpen(true);
                  }}
                  disabled={chartState.loadingData}
                />
                {commandBarRender()}
              </>
            }
            rightItems={
              <Stack horizontal verticalAlign="center">
                <Toggle
                  checked={isToggleActive}
                  onText="Todos items"
                  offText="Items ativos"
                  onChange={handleToggle}
                  styles={{ root: { marginBottom: 0 } }}
                />
                <CommandBarButton
                  styles={btnStyle}
                  iconProps={{ iconName: "Refresh" }}
                  text="Atualizar"
                  onClick={() => {
                    setItemSelected(null);
                    dispatch(CreatorCharts.getChart("", !isToggleActive));
                  }}
                />
              </Stack>
            }
          />
          <WrapperTreeview>
            {chartState.data.length === 0 && !chartState.loadingData ? (
              <NoItems
                error={chartState.error}
                text="Não há áreas cadastradas"
                img="/static/icons/supermarket.svg"
                alt="Locais"
              />
            ) : (
              <TreeView
                columns={[
                  {
                    name: "Área",
                    fieldName: "nomeLocal",
                  },
                  {
                    name: "Código",
                    fieldName: "idArea",
                    width: 120,
                  },
                  {
                    name: "Nível",
                    fieldName: "tipoLocal",
                    width: 120,
                    onRender: convertTipo,
                  },
                  {
                    name: "Tipo",
                    fieldName: "nivel",
                    width: 120,
                    onRender: convertNivel,
                  },
                ]}
                status={{
                  hasStatus: true,
                  fieldName: "flagAtiva",
                }}
                selectionMode="single"
                state={chartState}
                fieldId="idArea"
                changeItemsSelected={(item) => setItemSelected(item[0])}
                handleOnItemInvoked={handleOnItemInvoked}
                clearSelection={itemSelected === null}
              />
            )}
          </WrapperTreeview>
        </ContainerContent>
      </Wrapper>
    </>
  );
};

const convertNivel = (nivel: string): string => {
  switch (nivel.toUpperCase()) {
    case "E":
      return "Estratégico";
    case "T":
      return "Tático";
    case "O":
      return "Operacional";
    default:
      return "";
  }
};

const convertTipo = (tipo: string): string => {
  switch (tipo.toUpperCase()) {
    case "P":
      return "Presidência";
    case "V":
      return "Vice Presidência";
    case "D":
      return "Diretoria";
    case "G":
      return "Gerência";
    case "L":
      return "Local";
    default:
      return "";
  }
};

export default Chart;
