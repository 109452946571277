import api from "~/services/api";
import { takeEvery, put, call, all } from "redux-saga/effects";
import {
    Types,
    IUnit,
    IRequestUnitAddAction,
    IRequestUnitEditAction,
    IRequestUnitDeleteAction,
    IRequestUnitsLoadAction,
    IRequestUnitsLoadByIdAction,
    IRequestUnitAddEsAction,
    IRequestUnitDelEsAction,
    IRequestUnitLoadEsByIdAction,
    IRequestUnitEditEsAction,
    ISuccessUnitAddEsAction,
    ISuccessUnitDelEsAction,
    ISuccessUnitLoadEsByIdAction,
    ISuccessUnitEditEsAction,
    UnitESType
} from "../../ducks/admin/units/types";

const apiCallGet = (search: any) => {
    const searchFilter = search.searchText !== "" ? `?SearchText=${search.searchText}` : "?";
    const filter = search.filterAtivo !== null ? `&FilterAtivo=${search.filterAtivo}` : "";

    return api.get(`unidades${searchFilter}${filter}`)
        .then(response => response.data)
        .catch(err => {
            throw err.response;
        });
};

const apiCallGetById = (id: number) => {
    return api.get(`unidades/${id}`)
        .then(response => response.data)
        .catch(err => {
            throw err.response;
        });
};

const apiCallPost = (item: IUnit) => {
    return api.post('unidades', {
        ...item,
    })
        .then(response => response.data)
        .catch(err => {
            throw err.response;
        });
};

const apiCallPut = (item: IUnit) => {
    return api.put(`unidades/${item.idUnidade}`, {
        ...item
    })
        .then(response => response.data)
        .catch(err => {
            throw err.response;
        });
};

const apiCallDel = (id: number) => {
    return api.delete(`unidades/${id}`)
        .then(response => response.data)
        .catch(err => {
            throw err.response;
        });
};

const apiCallLoadESById = (payload: IRequestUnitLoadEsByIdAction["payload"]) => {
    return api.get(`unidades/${payload.idUnidade}/es/${payload.idUnidadeES}`)
        .then(response => response.data)
        .catch(err => {
            throw err.response;
        });
};

const apiCallAddES = (payload: IRequestUnitAddEsAction["payload"]) => {
    return api.post(`unidades/${payload.idUnidade}/es`, {
        ...payload.es
    })
        .then(response => response.data)
        .catch(err => {
            throw err.response;
        });
};

const apiCallEditES = (payload: IRequestUnitAddEsAction["payload"]) => {
    return api.put(`unidades/${payload.idUnidade}/es/${payload.es.idUnidadeES}`, {
        ...payload.es
    })
        .then(response => response.data)
        .catch(err => {
            throw err.response;
        });
};

const apiCallDelES = (payload: IRequestUnitDelEsAction["payload"]) => {
    return api.delete(`unidades/${payload.idUnidade}/es/${payload.idUnidadeES}`)
        .then(response => response.data)
        .catch(err => {
            throw err.response;
        });
};

function* loadUnits(action: IRequestUnitsLoadAction) {
    try {
        const items = yield call(apiCallGet, action.search);
        yield put({ type: Types.SUCCESS_UNITS_LOAD, payload: items });
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível carregar as unidades.';
        yield put({ type: Types.FAILURE_UNITS_LOAD, message });
    };
};

function* loadUnitsById(action: IRequestUnitsLoadByIdAction) {
    try {
        const item = yield call(apiCallGetById, action.unit.idUnidade!);
        yield put({ type: Types.SUCCESS_UNITS_LOAD_ID, unit: { ...action.unit, ...item } });
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível carregar a unidade.';
        yield put({ type: Types.FAILURE_UNITS_LOAD_ID, message });
    };
};

function* add(action: IRequestUnitAddAction) {
    try {
        const idUnidade = yield call(apiCallPost, action.unit);
        yield put({ type: Types.SUCCESS_UNIT_ADD, unit: { ...action.unit, ...idUnidade } });
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível adicionar unidade';
        yield put({ type: Types.FAILURE_UNIT_ADD, message });
    };
};

function* edit(action: IRequestUnitEditAction) {
    try {
        yield call(apiCallPut, action.unit);
        yield put({ type: Types.SUCCESS_UNIT_EDIT, unit: action.unit });
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível editar unidade';
        yield put({ type: Types.FAILURE_UNIT_EDIT, message });
    };
};

function* del(action: IRequestUnitDeleteAction) {
    try {
        yield call(apiCallDel, action.id);
        yield put({ type: Types.SUCCESS_UNIT_DELETE, id: action.id });
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível excluir unidade';
        yield put({ type: Types.FAILURE_UNIT_DELETE, message });
    };
};

function* loadESById(action: IRequestUnitLoadEsByIdAction) {
    try {
        const data: UnitESType = yield call(apiCallLoadESById, action.payload);
        yield put({
            type: Types.SUCCESS_UNIT_LOAD_ES_BY_ID,
            payload: data
        } as ISuccessUnitLoadEsByIdAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível carregar a estrutura salarial';
        yield put({ type: Types.FAILURE_UNIT_LOAD_ES_BY_ID, message });
    };
};

function* addES(action: IRequestUnitAddEsAction) {
    try {
        const idUnidadeES: { idUnidadeES: number } = yield call(apiCallAddES, action.payload);
        yield put({
            type: Types.SUCCESS_UNIT_ADD_ES,
            payload: {
                ...action.payload.es,
                ...idUnidadeES
            }
        } as ISuccessUnitAddEsAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível adicionar a estrutura salarial';
        yield put({ type: Types.FAILURE_UNIT_ADD_ES, message });
    };
};

function* editES(action: IRequestUnitEditEsAction) {
    try {
        yield call(apiCallEditES, action.payload);
        yield put({
            type: Types.SUCCESS_UNIT_EDIT_ES,
            payload: action.payload.es
        } as ISuccessUnitEditEsAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível editar a estrutura salarial';
        yield put({ type: Types.FAILURE_UNIT_EDIT_ES, message });
    };
};

function* delES(action: IRequestUnitDelEsAction) {
    try {
        yield call(apiCallDelES, action.payload);
        yield put({
            type: Types.SUCCESS_UNIT_DEL_ES,
            idUnidadeES: action.payload.idUnidadeES
        } as ISuccessUnitDelEsAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível excluir a estrutura salarial';
        yield put({ type: Types.FAILURE_UNIT_DEL_ES, message });
    };
};

export default function* companiesRoot() {
    yield all([
        yield takeEvery(Types.REQUEST_UNITS_LOAD, loadUnits),
        yield takeEvery(Types.REQUEST_UNITS_LOAD_ID, loadUnitsById),
        yield takeEvery(Types.REQUEST_UNIT_ADD, add),
        yield takeEvery(Types.REQUEST_UNIT_EDIT, edit),
        yield takeEvery(Types.REQUEST_UNIT_DELETE, del),
        yield takeEvery(Types.REQUEST_UNIT_LOAD_ES_BY_ID, loadESById),
        yield takeEvery(Types.REQUEST_UNIT_ADD_ES, addES),
        yield takeEvery(Types.REQUEST_UNIT_EDIT_ES, editES),
        yield takeEvery(Types.REQUEST_UNIT_DEL_ES, delES),
    ]);
};