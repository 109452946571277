import React from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from "formik";
import * as yup from 'yup';

import { Dialog, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { DefaultButton } from 'office-ui-fabric-react';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { Stack } from 'office-ui-fabric-react/lib/Stack';

import { InputText } from '~/components/Forms/index';
import { PrimaryButton } from '~/components/Buttons';

import InputAdornment from '@material-ui/core/InputAdornment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import { PesoType } from '~/store/ducks/cycle/painel/types';
import { PesoDismembermentType } from '~/store/ducks/cycle/dismemberment/types';


const dialogPesoContentProps = {
    title: 'Definir Peso',
    closeButtonAriaLabel: 'Close',
    subText: "",
};

interface IFormPesosProps {
    isOpen: boolean;
    isLoadingAction: boolean;
    setIsOpen: () => void;
    descObjetivo: string;
    isLoadingPeso: boolean;
    initialValues: PesoType | PesoDismembermentType;
    disabledFlagInativo?: boolean;
    onAddPeso: (peso: PesoType | Partial<PesoType>) => void;
    onEditPeso: (peso: PesoType | Partial<PesoType>) => void;
    onDeletePeso: (peso?: Partial<PesoType>) => void;
}

const validadtionDefinePesoSchema = yup.object().shape({
    definirPeso: yup.bool(),
    definirResponsabilidade: yup.bool(),
    peso: yup.number().when("definirPeso", {
        is: true,
        then: yup.number()
            .min(0, "O peso não pode ser menor que 0%")
            .max(100, "O peso não pode ser maior que 100%")
            .required("Campo obrigatório")
    })
})

const validadtionDefinePesoDismembermentSchema = yup.object().shape({
    definirPeso: yup.bool(),
    peso: yup.object().shape({
        peso: yup.number()
            .when("definirPeso", {
                is: true,
                then: yup.number()
                    .min(0, "O peso não pode ser menor que 0%")
                    .max(100, "O peso não pode ser maior que 100%")
                    .required("Campo obrigatório")
            })
    })
})


const FormPesos: React.FC<IFormPesosProps> = (props) => {
    const {
        isOpen,
        setIsOpen,
        descObjetivo,
        isLoadingPeso,
        initialValues,
        onAddPeso,
        onEditPeso,
        onDeletePeso,
        isLoadingAction,
        disabledFlagInativo
    } = props;
    const dispatch = useDispatch();

    return (
        <Dialog
            hidden={!isOpen}
            dialogContentProps={dialogPesoContentProps}
            minWidth={640}
        >
            {
                isLoadingPeso ?
                    <Stack styles={{ root: { height: 323 } }} verticalAlign="center">
                        <Spinner size={SpinnerSize.large} />
                    </Stack>
                    :
                    <Formik
                        initialValues={{ ...initialValues, definirPeso: true, flagInativo: ("area" in initialValues) ? initialValues.flagInativo : initialValues.peso.flagInativo }}
                        validationSchema={"area" in initialValues ? validadtionDefinePesoSchema : validadtionDefinePesoDismembermentSchema}
                        enableReinitialize
                        validateOnChange={false}
                        validateOnBlur={true}
                        validate={((values) => {
                            let errors: any = {};
                            if (!('area' in initialValues)) {
                                if ((values.peso as any).peso === "") {
                                    errors.peso = "Campo obrigatório"
                                } else {
                                    if (Number((values.peso as any).peso) < 0) {
                                        errors.peso = "O peso não pode ser menor que 0%"
                                    } else if (Number((values.peso as any).peso) > 100) {
                                        errors.peso = "O peso não pode ser maior que 100%"
                                    }
                                }
                            }
                            return errors;
                        })}
                        onSubmit={(values: any) => {
                            const peso: PesoType = {
                                ...initialValues as PesoType,
                                peso: values.peso,
                                definirResponsabilidade: values.definirResponsabilidade,
                                idCicloPeso: values.idCicloPeso === null ? 0 : values.idCicloPeso,
                                flagInativo: values.flagInativo
                            };

                            const pesoDismemberment: Partial<PesoType> = {
                                peso: values.peso.peso,
                                definirResponsabilidade: false,
                                idCicloPeso: values.peso.idCicloPeso,
                                idArea: values.peso.idArea,
                                idCicloPesoPai: values.peso.idCicloPesoPai,
                                idObjetivo: values.peso.idObjetivo !== 0 ? values.peso.idObjetivo : (initialValues as PesoType).idObjetivo, // Se o item não tiver idObjetivo, pega do Pai 
                                flagInativo: values.peso.flagInativo
                            };

                            if ('area' in initialValues) {
                                if (values.idCicloPeso === null) {
                                    dispatch(onAddPeso(peso));
                                } else {
                                    if (values.definirPeso) {
                                        dispatch(onEditPeso(peso));
                                    } else {
                                        dispatch(onDeletePeso());
                                    };
                                };
                            } else {
                                if (values.peso.idCicloPeso === 0) {
                                    dispatch(onAddPeso(pesoDismemberment));
                                } else {
                                    if (values.definirPeso) {
                                        dispatch(onEditPeso(pesoDismemberment));
                                    } else {
                                        dispatch(onDeletePeso(pesoDismemberment));
                                    };
                                };

                            }
                        }}
                    >
                        {({ handleSubmit, handleChange, values, errors, setFieldValue }) => {
                            let hasIdCicloPeso: boolean;
                            let nomeLocal: string;
                            let peso: string | Number;
                            let flagInativo: boolean;

                            if ('area' in initialValues) {
                                const value = (values as PesoType);
                                hasIdCicloPeso = value.idCicloPeso !== null && value.idCicloPeso !== 0;
                                nomeLocal = value.area.nomeLocal;
                                peso = String(value.peso);
                                flagInativo = value.flagInativo;
                            } else {
                                const value = (values as PesoDismembermentType);
                                hasIdCicloPeso = value.peso.idCicloPeso !== null && value.peso.idCicloPeso !== 0;
                                nomeLocal = value.nomeLocal;
                                peso = String(value.peso.peso);
                                flagInativo = value.peso.flagInativo
                            }

                            return (
                                <form onSubmit={handleSubmit}>
                                    <div className="ms-Grid" dir="ltr">
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12">
                                                <InputText
                                                    value={descObjetivo}
                                                    onChange={handleChange}
                                                    label="Objetivo"
                                                    disabled={true}
                                                    style={{ width: 380 }}
                                                    className="mt-2" />
                                            </div>
                                            <div className="ms-Grid-col ms-sm12">
                                                <InputText
                                                    value={nomeLocal}
                                                    label="Local"
                                                    style={{ width: 380 }}
                                                    disabled
                                                    className="mt-2" />
                                            </div>
                                            {
                                                hasIdCicloPeso &&
                                                <FormControlLabel style={{ padding: 8 }}
                                                    control={<Switch
                                                        size="small"
                                                        type="checkbox"
                                                        checked={values.definirPeso}
                                                        onChange={handleChange}
                                                        name="definirPeso"
                                                        id="definirPeso"
                                                        color="primary"
                                                    />}
                                                    label="Definir Peso"
                                                />
                                            }

                                            {
                                                hasIdCicloPeso &&
                                                <FormControlLabel style={{ padding: 8 }}
                                                    control={<Switch
                                                        size="small"
                                                        type="checkbox"
                                                        checked={flagInativo ?? false}
                                                        onChange={e => {
                                                            if (("area" in initialValues)) {
                                                                setFieldValue("flagInativo", e.target.checked)
                                                            } else {
                                                                setFieldValue("peso", { ...initialValues.peso, flagInativo: e.target.checked })
                                                            }
                                                        }}
                                                        name="flagInativo"
                                                        id="flagInativo"
                                                        color="primary"
                                                        disabled={disabledFlagInativo}
                                                    />}
                                                    label="Inativar Peso"
                                                />
                                            }
                                            {/* {
                                                ("area" in initialValues) && hasIdCicloPeso &&
                                                <FormControlLabel style={{ padding: 8 }}
                                                    control={<Switch
                                                        size="small"
                                                        type="checkbox"
                                                        checked={(values as PesoType).definirResponsabilidade}
                                                        onChange={handleChange}
                                                        name="definirResponsabilidade"
                                                        id="definirResponsabilidade"
                                                        color="primary"
                                                        disabled={!values.definirPeso}
                                                    />}
                                                    label="Definir Responsabilidade"
                                                />
                                            } */}
                                            <div className="ms-Grid-col ms-sm12">
                                                <InputText
                                                    value={peso}
                                                    onChange={(e) => {
                                                        setFieldValue("peso", ("area" in initialValues) ? e.target.value : { ...initialValues.peso, peso: e.target.value, flagInativo: flagInativo })
                                                    }}
                                                    id="peso"
                                                    error={errors.peso ? true : false}
                                                    name="peso"
                                                    type="number"
                                                    label="Peso"
                                                    disabled={!values.definirPeso || flagInativo}
                                                    helperText={errors.peso}
                                                    style={{ width: 380 }}
                                                    autoFocus
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end" style={{ paddingRight: 20 }}>%</InputAdornment>,
                                                    }}
                                                    className="mt-2" />
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ paddingRight: 5 }}>
                                        <DialogFooter styles={{ actions: { lineHeight: 0 } }}>
                                            <Stack horizontal horizontalAlign="end">
                                                <DefaultButton styles={{ root: { width: 110, marginRight: 10 } }} onClick={setIsOpen} text="Cancelar" />
                                                <PrimaryButton type="submit" isLoading={isLoadingAction} text="Salvar" />
                                            </Stack>
                                        </DialogFooter>
                                    </div>
                                </form>
                            )
                        }}
                    </Formik>
            }
        </Dialog >
    );
}

export default FormPesos;