/**
 * Types
 */
export enum Types {
  //GET MERITO
  REQUEST_MERIT_LOAD = "adm_merit/REQUEST_MERIT_LOAD",
  SUCCESS_MERIT_LOAD = "adm_merit/SUCCESS_MERIT_LOAD",
  FAILURE_MERIT_LOAD = "adm_merit/FAILURE_MERIT_LOAD",
  //GET MERITO BY ID
  REQUEST_MERIT_LOAD_BY_ID = "adm_merit/REQUEST_MERIT_LOAD_BY_ID",
  SUCCESS_MERIT_LOAD_BY_ID = "adm_merit/SUCCESS_MERIT_LOAD_BY_ID",
  FAILURE_MERIT_LOAD_BY_ID = "adm_merit/FAILURE_MERIT_LOAD_BY_ID",
  //EDIT PARAMETRO
  REQUEST_PARAMETRO_EDIT = "adm_merit/REQUEST_PARAMETRO_EDIT",
  SUCCESS_PARAMETRO_EDIT = "adm_merit/SUCCESS_PARAMETRO_EDIT",
  FAILURE_PARAMETRO_EDIT = "adm_merit/FAILURE_PARAMETRO_EDIT",
  //GET MERIT PERFORMANCE
  REQUEST_MERIT_PERFORMANCE_LOAD_BY_ID = "adm_merit/REQUEST_MERIT_PERFORMANCE_LOAD_BY_ID",
  SUCCESS_MERIT_PERFORMANCE_LOAD_BY_ID = "adm_merit/SUCCESS_MERIT_PERFORMANCE_LOAD_BY_ID",
  FAILURE_MERIT_PERFORMANCE_LOAD_BY_ID = "adm_merit/FAILURE_MERIT_PERFORMANCE_LOAD_BY_ID",
  //EDIT MERIT PERFORMANCE
  REQUEST_MERIT_PERFORMANCE_EDIT = "adm_merit/REQUEST_MERIT_PERFORMANCE_EDIT",
  SUCCESS_MERIT_PERFORMANCE_EDIT = "adm_merit/SUCCESS_MERIT_PERFORMANCE_EDIT",
  FAILURE_MERIT_PERFORMANCE_EDIT = "adm_merit/FAILURE_MERIT_PERFORMANCE_EDIT",
  //GET TEMPORADAS
  REQUEST_MERIT_SEASONS_LOAD = "adm_merit/REQUEST_MERIT_SEASONS_LOAD",
  SUCCESS_MERIT_SEASONS_LOAD = "adm_merit/SUCCESS_MERIT_SEASONS_LOAD",
  FAILURE_MERIT_SEASONS_LOAD = "adm_merit/FAILURE_MERIT_SEASONS_LOAD",
  //ADD TEMPORADAS
  REQUEST_MERIT_SEASONS_ADD = "adm_merit/REQUEST_MERIT_SEASONS_ADD",
  SUCCESS_MERIT_SEASONS_ADD = "adm_merit/SUCCESS_MERIT_SEASONS_ADD",
  FAILURE_MERIT_SEASONS_ADD = "adm_merit/FAILURE_MERIT_SEASONS_ADD",
  //CLEAR MERIT
  CLEAR_MERIT = "adm_merit/CLEAR_MERIT",
}

export type MeritSeasonType = {
  idConfigMerito: number | null;
  ano: string;
};

export interface MeritType extends MeritSeasonType {
  parametros: ParametroType[];
  performances: MeritPerformanceType[];
}

export type ParametroType = {
  idConfigMeritoParametro: number;
  idConfigMerito?: number;
  idParametro: number;
  valor: number | string;
  parametro: {
    grupo: string;
    nome: string;
    descricao: string;
    tipoValor: string;
    idParametroGrupo: number;
    grupoparam: {
      nome: string;
    };
  };
};

export type MeritPerformanceType = {
  idConfigMeritoPerformance: number;
  idTabPerformanceNivel: number;
  multiplicador: number;
  flagPromocao: boolean;
  flagEnquadramento: boolean;
  flagZerarReajusteMinimo: boolean;
  nivel: {
    nivel: number;
    descNivel: string;
  };
};

type ParametroSelectedState = {
  parametro: ParametroType | null;
  performance: MeritPerformanceType | null;
  loading: boolean;
  success: boolean;
  error: boolean;
};

interface IMeritState {
  readonly data: MeritType | null;
  readonly itemSelected: ParametroSelectedState;
  readonly seasonData: MeritSeasonType[];
  readonly loadingData: boolean;
  readonly loadingAction: boolean;
  readonly loadingSeason: boolean;
  readonly error: boolean;
  readonly success: boolean;
}

export type DataTypes = IMeritState;

/**
 * Actions
 */

//GET
export interface IRequestMeritLoadAction {
  type: typeof Types.REQUEST_MERIT_LOAD;
  idConfigMerito: number;
}

export interface ISuccessMeritLoadAction {
  type: typeof Types.SUCCESS_MERIT_LOAD;
  payload: MeritType;
}

interface IFailureMeritLoadAction {
  type: typeof Types.FAILURE_MERIT_LOAD;
  message: string;
}

type MeritLoadAction =
  | IRequestMeritLoadAction
  | ISuccessMeritLoadAction
  | IFailureMeritLoadAction;

//GET BY ID
export interface IRequestMeritLoadByIdAction {
  type: typeof Types.REQUEST_MERIT_LOAD_BY_ID;
  payload: {
    idConfigMerito: number;
    idConfigMeritoParametro: number;
  };
}

export interface ISuccessMeritLoadByIdAction {
  type: typeof Types.SUCCESS_MERIT_LOAD_BY_ID;
  payload: ParametroType;
}

interface IFailureMeritLoadByIdAction {
  type: typeof Types.FAILURE_MERIT_LOAD_BY_ID;
  message: string;
}

type MeritLoadByIdAction =
  | IRequestMeritLoadByIdAction
  | ISuccessMeritLoadByIdAction
  | IFailureMeritLoadByIdAction;

//EDIT PARAMETRO
export interface IRequestParametroEditAction {
  type: typeof Types.REQUEST_PARAMETRO_EDIT;
  payload: {
    idConfigMerito: number;
    idConfigMeritoParametro: number;
    valor: number | string;
  };
}

export interface ISuccessParametroEditAction {
  type: typeof Types.SUCCESS_PARAMETRO_EDIT;
  payload: {
    idConfigMeritoParametro: number;
    valor: number | string;
  };
}

interface IFailureParametroEditAction {
  type: typeof Types.FAILURE_PARAMETRO_EDIT;
  message: string;
}

type MeritParametroEditAction =
  | IRequestParametroEditAction
  | ISuccessParametroEditAction
  | IFailureParametroEditAction;

//GET MERIT PERFORMANCE BY ID
export interface IRequestMeritPerformanceLoadByIdAction {
  type: typeof Types.REQUEST_MERIT_PERFORMANCE_LOAD_BY_ID;
  payload: {
    idConfigMerito: number;
    idConfigMeritoPerformance: number;
  };
}

export interface ISuccessMeritPerformanceLoadByIdAction {
  type: typeof Types.SUCCESS_MERIT_PERFORMANCE_LOAD_BY_ID;
  payload: MeritPerformanceType;
}

interface IFailureMeritPerformanceLoadByIdAction {
  type: typeof Types.FAILURE_MERIT_PERFORMANCE_LOAD_BY_ID;
  message: string;
}

type MeritPerformanceLoadByIdAction =
  | IRequestMeritPerformanceLoadByIdAction
  | ISuccessMeritPerformanceLoadByIdAction
  | IFailureMeritPerformanceLoadByIdAction;

//GET MERIT PERFORMANCE BY ID
export interface IRequestMeritPerformanceEditAction {
  type: typeof Types.REQUEST_MERIT_PERFORMANCE_EDIT;
  payload: {
    idConfigMerito: number;
    performance: MeritPerformanceType;
  };
}

export interface ISuccessMeritPerformanceEditAction {
  type: typeof Types.SUCCESS_MERIT_PERFORMANCE_EDIT;
  payload: MeritPerformanceType;
}

interface IFailureMeritPerformanceEditAction {
  type: typeof Types.FAILURE_MERIT_PERFORMANCE_EDIT;
  message: string;
}

type MeritPerformanceEditAction =
  | IRequestMeritPerformanceEditAction
  | ISuccessMeritPerformanceEditAction
  | IFailureMeritPerformanceEditAction;

//GET SEASONS
export interface IRequestMeritSeasonsLoadAction {
  type: typeof Types.REQUEST_MERIT_SEASONS_LOAD;
}

export interface ISuccessMeritSeasonsLoadAction {
  type: typeof Types.SUCCESS_MERIT_SEASONS_LOAD;
  payload: MeritSeasonType[];
}

interface IFailureMeritSeasonsLoadAction {
  type: typeof Types.FAILURE_MERIT_SEASONS_LOAD;
  message: string;
}

type MeritSeasonsLoadAction =
  | IRequestMeritSeasonsLoadAction
  | ISuccessMeritSeasonsLoadAction
  | IFailureMeritSeasonsLoadAction;

//ADD SEASON
export interface IRequestMeritSeasonsAddAction {
  type: typeof Types.REQUEST_MERIT_SEASONS_ADD;
  payload: {
    ano: number;
    idTabPerformance: number;
  };
}

export interface ISuccessMeritSeasonsAddAction {
  type: typeof Types.SUCCESS_MERIT_SEASONS_ADD;
  payload: MeritType;
}

interface IFailureMeritSeasonsAddAction {
  type: typeof Types.FAILURE_MERIT_SEASONS_ADD;
  message: string;
}

type MeritSeasonsAddAction =
  | IRequestMeritSeasonsAddAction
  | ISuccessMeritSeasonsAddAction
  | IFailureMeritSeasonsAddAction
  | IMeritClear;

interface IMeritClear {
  type: typeof Types.CLEAR_MERIT;
}

export type MeritActionTypes =
  | MeritSeasonsLoadAction
  | MeritSeasonsAddAction
  | MeritLoadAction
  | MeritLoadByIdAction
  | MeritParametroEditAction
  | MeritPerformanceLoadByIdAction
  | MeritPerformanceEditAction;
