import { Types, DataTypes, TiposPDIActionTypes } from "./types";
import customToast from "~/components/Toast/index";

/**
 * Reducers
 */
const INITIAL_STATE: DataTypes = {
    data: [],
    error: false,
    loading: false,
    loadingAction: false,
    success: false
};

/**
 * Actions
 */

export const Creators = {
    /**
         * REQUEST: Carregar todos os tipos dos PDIs
         * @returns Types.REQUEST_PDI_TYPES_LOAD
         */
    getPdiTypes: (): TiposPDIActionTypes => ({
        type: Types.REQUEST_PDI_TYPES_LOAD,
    }),

    /**
     * REQUEST POST: ADICIONAR TIPO PDI
     * @param descTipoPDI 
     * @returns 
     */
    addPdiType: (descTipoPDI: string): TiposPDIActionTypes => ({
        type: Types.REQUEST_PDI_TYPES_ADD,
        descTipoPDI
    })
}


export default function TypesPdiReducer(state = INITIAL_STATE, action: TiposPDIActionTypes): DataTypes {

    switch (action.type) {
        // GET PDI TYPES
        case Types.REQUEST_PDI_TYPES_LOAD:
            return { ...state, loading: true, success: false, error: false };
        case Types.SUCCESS_PDI_TYPES_LOAD:
            return { ...state, data: action.payload, loading: false, success: true, error: false };
        case Types.FAILURE_PDI_TYPES_LOAD:
            customToast.error(action.message);
            return { ...state, data: [], loading: false, success: false, error: true };

        // ADD PDI TYPES
        case Types.REQUEST_PDI_TYPES_ADD:
            return { ...state, loadingAction: true, success: false, error: false }
        case Types.SUCCESS_PDI_TYPES_ADD:
            customToast.success("Tipo PDI adicionado com sucesso!");
            return { ...state, data: [...state.data, action.payload], loadingAction: false, success: true, error: false }
        case Types.FAILURE_PDI_TYPES_ADD:
            customToast.error(action.message);
            return { ...state, loadingAction: false, success: false, error: true }

        default:
            return state;
    }
}
