import { takeEvery, put, call, all } from "redux-saga/effects";
import {
  Types,
  IRequestAddCategoriesPositionsAction,
  IRequestDelCategoriesPositionsAction,
  IRequestGetByIdCategoriesPositionsAction,
  IRequestEditCategoriesPositionsAction,
  ISuccessGetCategoriesPositionsAction,
  ISuccessAddCategoriesPositionsAction,
  ISuccessDelCategoriesPositionsAction,
  ISuccessGetByIdCategoriesPositionsAction,
  ISuccessEditCategoriesPositionsAction,
  CategoryPositionLoadDTO,
  CategoryPositionType,
} from "../../ducks/admin/categoriesPositions/types";
import api from "~/services/api";

const apiCallGet = () => {
  return api
    .get("admin/categoriasCargos")
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallGetById = (idCategoriaCargo: number) => {
  return api
    .get(`admin/categoriasCargos/${idCategoriaCargo}`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallAdd = (item: IRequestAddCategoriesPositionsAction["payload"]) => {
  return api
    .post("admin/categoriasCargos", {
      ...item,
      flagAtivo: true,
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallEdit = (
  item: IRequestEditCategoriesPositionsAction["payload"]
) => {
  return api
    .put(`admin/categoriasCargos/${item.idCategoriaCargo}`, item)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallDel = (idCategoriaCargo: number) => {
  return api
    .delete(`admin/categoriasCargos/${idCategoriaCargo}`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

function* load() {
  try {
    const items: CategoryPositionLoadDTO[] = yield call(apiCallGet);
    yield put({
      type: Types.SUCCESS_LOAD_CATEGORIES_POSITIONS,
      payload: items,
    } as ISuccessGetCategoriesPositionsAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível carregar as categorias de cargos";
    yield put({ type: Types.FAILURE_LOAD_CATEGORIES_POSITIONS, message });
  }
}

function* loadById(action: IRequestGetByIdCategoriesPositionsAction) {
  try {
    const item: CategoryPositionType = yield call(
      apiCallGetById,
      action.idCategoriaCargo
    );
    yield put({
      type: Types.SUCCESS_LOAD_CATEGORY_POSITION_BY_ID,
      payload: item,
    } as ISuccessGetByIdCategoriesPositionsAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível carregar a categoria de cargo";
    yield put({ type: Types.FAILURE_LOAD_CATEGORY_POSITION_BY_ID, message });
  }
}

function* add(action: IRequestAddCategoriesPositionsAction) {
  try {
    const data: { idCategoriaCargo: number } = yield call(
      apiCallAdd,
      action.payload
    );
    yield put({
      type: Types.SUCCESS_ADD_CATEGORY_POSITION,
      payload: {
        idCategoriaCargo: data.idCategoriaCargo,
        descCategoriaCargo: action.payload.descCategoriaCargo,
        nivel: action.payload.nivel,
        flagAtivo: true
      },
    } as ISuccessAddCategoriesPositionsAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível adicionar a categoria de cargo";
    yield put({ type: Types.FAILURE_ADD_CATEGORY_POSITION, message });
  }
}

function* edit(action: IRequestEditCategoriesPositionsAction) {
  try {
    yield call(apiCallEdit, action.payload);
    yield put({
      type: Types.SUCCESS_EDIT_CATEGORY_POSITION,
      payload: action.payload,
    } as ISuccessEditCategoriesPositionsAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível editar a categoria de cargo";
    yield put({ type: Types.FAILURE_EDIT_CATEGORY_POSITION, message });
  }
}

function* del(action: IRequestDelCategoriesPositionsAction) {
  try {
    yield call(apiCallDel, action.idCategoriaCargo);
    yield put({
      type: Types.SUCCESS_DEL_CATEGORY_POSITION,
      idCategoriaCargo: action.idCategoriaCargo,
    } as ISuccessDelCategoriesPositionsAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível excluír a categoria de cargo";
    yield put({ type: Types.FAILURE_DEL_CATEGORY_POSITION, message });
  }
}

export default function* categoriesPositionsRoot() {
  yield all([
    yield takeEvery(Types.REQUEST_LOAD_CATEGORIES_POSITIONS, load),
    yield takeEvery(Types.REQUEST_LOAD_CATEGORY_POSITION_BY_ID, loadById),
    yield takeEvery(Types.REQUEST_ADD_CATEGORY_POSITION, add),
    yield takeEvery(Types.REQUEST_EDIT_CATEGORY_POSITION, edit),
    yield takeEvery(Types.REQUEST_DEL_CATEGORY_POSITION, del),
  ]);
}