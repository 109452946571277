import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from "yup";
import { Formik } from "formik";

import { InputText, InputAutocomplete } from '~/components/Forms';

import { EmployeeType, DataTypes as DataTypesEmployee } from "~/store/ducks/employees/types";
import { ChartType, DataTypes as DataTypesChart } from "~/store/ducks/admin/chart/types";
import { CycleType } from "~/store/ducks/cycles/types";
import { Creators as CreatorsEmployees } from "~/store/ducks/employees";
import { FormHeaderStyle } from '~/components/FormGroup';

import { Creators as CreatorsChart } from "~/store/ducks/admin/chart";

import { RootState } from "~/store/ducks";

export type InfoCycleType = {
    ano: number | null;
    responsavel: Partial<EmployeeType>;
};

interface IFormInfoProps {
    formRef: any;
    initialValues: Partial<CycleType>;
    success: (values: Partial<CycleType>) => any;
};

const validationSchemaInfo = yup.object().shape({
    ano: yup.number().nullable().required("Campo obrigatório"),
    responsavel: yup.object().shape({
        idFuncionario: yup.number()
            .nullable()
            .required("Campo obrigatório"),
    }),
    idAreaPai: yup.number().nullable().required("Campo obrigatório"),
});

const FormContentInfo: React.FC<IFormInfoProps> = (props) => {
    const [employees, setEmployees] = useState<Partial<EmployeeType>>({
        idFuncionario: props.initialValues.responsavel?.idFuncionario,
        nomeCompleto: props.initialValues.responsavel?.nomeCompleto
    });

    const [chart, setChart] = useState<Partial<ChartType>>(props.initialValues.area!);

    const dispatch = useDispatch();
    let timeout: number = 0;

    const employeesState = useSelector<RootState, DataTypesEmployee>(state => state.employeesReducer);
    const chartState = useSelector<RootState, DataTypesChart>(state => state.chartReducer);

    const search = (text: string, type: string) => {
        if (text.trim()) {
            clearTimeout(timeout);
            timeout = window.setTimeout(() => {
                switch (type) {
                    case 'responsavel':
                        dispatch(CreatorsEmployees.getEmployees(text));
                        break;
                    case 'areaSearch':
                        dispatch(CreatorsChart.getChart(text, true, false));
                        break;
                }
            }, 700);
        }
    };

    return (
        <>
            <FormHeaderStyle text="Gerenciar informações principais" />
            <Formik
                enableReinitialize={true}
                innerRef={props.formRef}
                initialValues={props.initialValues!}
                validationSchema={validationSchemaInfo}
                validateOnChange={false}
                validateOnBlur={true}
                onSubmit={(values: Partial<CycleType>) => {
                    values.responsavel = {
                        idFuncionario: employees.idFuncionario,
                        nomeCompleto: employees.nomeCompleto
                    };
                    props.success(values);
                }}
            >
                {({ handleSubmit, handleChange, values, errors, setFieldError, setFieldValue }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <div className="ms-Grid" dir="ltr">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12" style={{ paddingLeft: 0, paddingRight: 8 }}>
                                        <InputText
                                            value={values.ano}
                                            onChange={(e) => { handleChange(e); setFieldError("ano", "") }}
                                            id="ano"
                                            error={errors.ano ? true : false}
                                            name="ano"
                                            type="text"
                                            label="Ano"
                                            helperText={errors.ano}
                                            className="mt-2"
                                            inputProps={{
                                                maxLength: 10
                                            }}
                                            autoFocus
                                        />
                                    </div>
                                    <div className="ms-Grid-col ms-sm12 paddingZero" style={{ height: 70 }}>
                                        <InputAutocomplete
                                            value={(employees as EmployeeType)}
                                            onChange={(_, newValue) => {
                                                setEmployees(newValue!)
                                                setFieldValue("responsavel", { idFuncionario: newValue?.idFuncionario });
                                            }}
                                            onInputChange={(_, newInputValue) => {
                                                setFieldError("responsavel", "");
                                                search(newInputValue, "responsavel");
                                            }}
                                            getOptionLabel={(unit: EmployeeType) => unit.nomeCompleto}
                                            getOptionSelected={(option, value) => option.idFuncionario === value.idFuncionario}
                                            options={employeesState.data}
                                            input={{
                                                idInput: "responsavel",
                                                labelInput: "Responsavel",
                                                helperTextInput: (errors.responsavel as any)?.idFuncionario as string,
                                                errorInput: (errors.responsavel as any)?.idFuncionario ? true : false
                                            }}
                                        />
                                    </div>
                                    <div className="ms-Grid-col ms-sm12 paddingZero" style={{ height: 70 }}>
                                        <InputAutocomplete
                                            value={(chart as ChartType)}
                                            onChange={(_, newValue) => {
                                                setChart(newValue!)
                                                setFieldValue("idAreaPai", newValue?.idArea);
                                            }}
                                            onInputChange={(_, newInputValue) => {
                                                setFieldError("idAreaPai", "");
                                                search(newInputValue, "areaSearch");
                                            }}
                                            getOptionLabel={(position: ChartType) => position.nomeLocal}
                                            getOptionSelected={(option, value) => option.idArea === value.idArea}
                                            id="idAreaPai"
                                            noOptionsText="Não há opções"
                                            options={chartState.data}
                                            input={{
                                                idInput: "idAreaPai",
                                                labelInput: "Área Principal",
                                                helperTextInput: errors.idAreaPai,
                                                errorInput: errors.idAreaPai ? true : false
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    )
                }}
            </Formik>
        </>
    );
};

export default FormContentInfo;