import { takeEvery, put, call, all } from "redux-saga/effects";
import {
  Types,
  MotivosFaltasLoadDTO,
  MotivoFaltaType,
  IRequestGetByIdMotivoFaltaAction,
  IRequestAddMotivoFaltaAction,
  IRequestEditMotivoFaltaAction,
  IRequestDelMotivoFaltaAction,
  ISuccessGetMotivosFaltasAction,
  ISuccessGetByIdMotivoFaltaAction,
  ISuccessAddMotivoFaltaAction,
  ISuccessEditMotivoFaltaAction,
  ISuccessDelMotivoFaltaAction,
  IRequestGetMotivosFaltasAction,
} from "../../ducks/admin/motivoFalta/types";
import api from "~/services/api";

const apiCallGet = (search?: string) => {
  return api
    .get("admin/motivosFaltas", {
      params: {
        SearchText: search,
      },
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallGetById = (idMotivoFalta: number) => {
  return api
    .get(`admin/motivosFaltas/${idMotivoFalta}`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallAdd = (item: IRequestAddMotivoFaltaAction["payload"]) => {
  return api
    .post("admin/motivosFaltas", item)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallEdit = (item: IRequestEditMotivoFaltaAction["payload"]) => {
  return api
    .put(`admin/motivosFaltas/${item.idMotivoFalta}`, item)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallDel = (idMotivoFalta: number) => {
  return api
    .delete(`admin/motivosFaltas/${idMotivoFalta}`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

function* load(action: IRequestGetMotivosFaltasAction) {
  try {
    const items: MotivosFaltasLoadDTO[] = yield call(apiCallGet, action.search);
    yield put({
      type: Types.SUCCESS_LOAD_MOTIVOS_FALTAS,
      payload: items,
    } as ISuccessGetMotivosFaltasAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível carregar os motivos de faltas";
    yield put({ type: Types.FAILURE_LOAD_MOTIVOS_FALTAS, message });
  }
}

function* loadById(action: IRequestGetByIdMotivoFaltaAction) {
  try {
    const item: MotivoFaltaType = yield call(
      apiCallGetById,
      action.idMotivoFalta
    );
    yield put({
      type: Types.SUCCESS_LOAD_MOTIVO_FALTA_BY_ID,
      payload: item,
    } as ISuccessGetByIdMotivoFaltaAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível carregar o motivo de falta";
    yield put({ type: Types.FAILURE_LOAD_MOTIVO_FALTA_BY_ID, message });
  }
}

function* add(action: IRequestAddMotivoFaltaAction) {
  try {
    const data: { idMotivoFalta: number } = yield call(
      apiCallAdd,
      action.payload
    );
    yield put({
      type: Types.SUCCESS_ADD_MOTIVO_FALTA,
      payload: {
        idMotivoFalta: data.idMotivoFalta,
        descMotivoFalta: action.payload.descMotivoFalta,
      },
    } as ISuccessAddMotivoFaltaAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível adicionar o motivo de falta";
    yield put({ type: Types.FAILURE_ADD_MOTIVO_FALTA, message });
  }
}

function* edit(action: IRequestEditMotivoFaltaAction) {
  try {
    yield call(apiCallEdit, action.payload);
    yield put({
      type: Types.SUCCESS_EDIT_MOTIVO_FALTA,
      payload: action.payload,
    } as ISuccessEditMotivoFaltaAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível editar o motivo de falta";
    yield put({ type: Types.FAILURE_EDIT_MOTIVO_FALTA, message });
  }
}

function* del(action: IRequestDelMotivoFaltaAction) {
  try {
    yield call(apiCallDel, action.idMotivoFalta);
    yield put({
      type: Types.SUCCESS_DEL_MOTIVO_FALTA,
      idMotivoFalta: action.idMotivoFalta,
    } as ISuccessDelMotivoFaltaAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível excluír o motivo de falta";
    yield put({ type: Types.FAILURE_DEL_MOTIVO_FALTA, message });
  }
}

export default function* motivoFaltaRoot() {
  yield all([
    yield takeEvery(Types.REQUEST_LOAD_MOTIVOS_FALTAS, load),
    yield takeEvery(Types.REQUEST_LOAD_MOTIVO_FALTA_BY_ID, loadById),
    yield takeEvery(Types.REQUEST_ADD_MOTIVO_FALTA, add),
    yield takeEvery(Types.REQUEST_EDIT_MOTIVO_FALTA, edit),
    yield takeEvery(Types.REQUEST_DEL_MOTIVO_FALTA, del),
  ]);
}
