import { takeEvery, put, call, all } from "redux-saga/effects";
import {
    Types,
    ISuccessWorklevelLoadAction
} from "../ducks/worklevel/types";
import api from "~/services/api";

const apiCallLoad = (ciclo?: number) => {
    return api.get(`worklevel`)
        .then((response) => {
            return response.data;
        }).catch((err) => {
            throw err.response;
        });
};


function* load() {
    try {
        const items: any = yield call(apiCallLoad);
        yield put({
            type: Types.SUCCESS_WORKLEVEL_LOAD,
            payload: items
        } as ISuccessWorklevelLoadAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível carregar o worklevel'
        yield put({
            type: Types.FAILURE_WORKLEVEL_LOAD,
            message
        });
    }
}


export default function* meetingsRoot() {
    yield all([
        yield takeEvery(Types.REQUEST_WORKLEVEL_LOAD, load),
    ]);
}
