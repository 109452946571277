import {
  Types,
  DataTypes,
  SalaryStructureActionTypes,
  SalaryStructureType,
  GS,
} from "./types";
import customToast from "~/components/Toast/index";

/**
 * Reducers
 */
const INITIAL_STATE: DataTypes = {
  data: [],
  dataGS: null,
  gsSelected: null,
  itemSelected: {
    item: null,
    error: false,
    loading: false,
    success: false,
  },
  infoSalarial: null,
  error: false,
  loadingData: false,
  loadingDataGS: false,
  loadingAction: false,
  success: false,
  successGs: false,
};

/**
 * Actions
 */

export const Creators = {
  getSalaryStructure: (search?: string): SalaryStructureActionTypes => {
    search = search ? search : "";
    return {
      type: Types.REQUEST_ES_LOAD,
      search,
    };
  },

  getSalaryStructureByID: (idES: number): SalaryStructureActionTypes => ({
    type: Types.REQUEST_ES_LOAD_BY_ID,
    idES,
  }),

  addSalaryStructure: (
    salary: SalaryStructureType
  ): SalaryStructureActionTypes => ({
    type: Types.REQUEST_ES_ADD,
    payload: salary,
  }),

  editSalaryStructure: (
    salary: SalaryStructureType
  ): SalaryStructureActionTypes => ({
    type: Types.REQUEST_ES_EDIT,
    payload: salary,
  }),

  delSalaryStructure: (idES: number): SalaryStructureActionTypes => ({
    type: Types.REQUEST_ES_DEL,
    idES,
  }),

  getTabGs: (idES: number, idESTab: number): SalaryStructureActionTypes => ({
    type: Types.REQUEST_ES_LOAD_GS,
    payload: {
      idES,
      idESTab,
    },
  }),

  loadGsById: (
    idES: number,
    idESTab: number,
    idGS: number
  ): SalaryStructureActionTypes => ({
    type: Types.REQUEST_ES_LOAD_GS_BY_ID,
    payload: {
      idES,
      idESTab,
      idGS,
    },
  }),

  addGS: (
    idES: number,
    idESTab: number,
    gs: Partial<GS>
  ): SalaryStructureActionTypes => ({
    type: Types.REQUEST_ES_ADD_GS,
    payload: {
      idES,
      idESTab,
      gs,
    },
  }),

  editGS: (
    idES: number,
    idESTab: number,
    gs: Partial<GS>
  ): SalaryStructureActionTypes => ({
    type: Types.REQUEST_ES_EDIT_GS,
    payload: {
      idES,
      idESTab,
      gs,
    },
  }),

  delGS: (
    idES: number,
    idESTab: number,
    idGS: number
  ): SalaryStructureActionTypes => ({
    type: Types.REQUEST_ES_DEL_GS,
    payload: {
      idES,
      idESTab,
      idGS,
    },
  }),

  addEsTab: (
    idES: number,
    dataTab: Date,
    percent: number
  ): SalaryStructureActionTypes => ({
    type: Types.REQUEST_ES_TAB,
    payload: {
      idES,
      dataTab,
      percent,
    },
  }),

  getInfoSalarial: (idES: number, gs: number): SalaryStructureActionTypes => ({
    type: Types.REQUEST_ES_INFO_SALARIAL,
    payload: {
      idES,
      gs,
    },
  }),

  clearES: (): SalaryStructureActionTypes => ({
    type: Types.CLEAR_ES,
  }),
};

export default function salaryStructureReducer(
  state = INITIAL_STATE,
  action: SalaryStructureActionTypes
): DataTypes {
  switch (action.type) {
    //LOAD
    case Types.REQUEST_ES_LOAD:
      return { ...state, loadingData: true, error: false, success: false };
    case Types.SUCCESS_ES_LOAD:
      return {
        ...state,
        data: action.payload,
        loadingData: false,
        error: false,
        success: true,
      };
    case Types.FAILURE_ES_LOAD:
      customToast.error(action.message);
      return {
        ...state,
        data: [],
        loadingData: false,
        error: true,
        success: false,
      };

    // LOAD BY ID
    case Types.REQUEST_ES_LOAD_BY_ID:
      return {
        ...state,
        itemSelected: {
          item: null,
          success: false,
          loading: true,
          error: false,
        },
      };
    case Types.SUCCESS_ES_LOAD_BY_ID:
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          item: action.payload,
          success: true,
          loading: false,
          error: false,
        },
      };
    case Types.FAILURE_ES_LOAD_BY_ID:
      customToast.error(action.message);
      return {
        ...state,
        itemSelected: {
          item: null,
          success: false,
          loading: false,
          error: true,
        },
      };

    //ADD
    case Types.REQUEST_ES_ADD:
      return { ...state, loadingAction: true, error: false, success: false };
    case Types.SUCCESS_ES_ADD:
      customToast.success("Estrutura salarial adicionada com sucesso");
      return {
        ...state,
        data: [...state.data, action.payload],
        loadingAction: false,
        error: false,
        success: true,
      };
    case Types.FAILURE_ES_ADD:
      customToast.error(action.message);
      return { ...state, loadingAction: false, error: true, success: false };

    //EDIT
    case Types.REQUEST_ES_EDIT:
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          loading: true,
          success: false,
          error: false,
        },
      };
    case Types.SUCCESS_ES_EDIT:
      customToast.success("Estrutura salarial editada com sucesso");
      return {
        ...state,
        data: state.data.map((item) => {
          if (item.idES !== action.payload.idES) {
            return item;
          } else {
            return action.payload;
          }
        }),
        itemSelected: {
          item: action.payload,
          loading: false,
          success: true,
          error: false,
        },
      };
    case Types.FAILURE_ES_EDIT:
      customToast.error(action.message);
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          loading: false,
          success: false,
          error: true,
        },
      };

    //DEL
    case Types.REQUEST_ES_DEL:
      return { ...state, loadingAction: true, error: false, success: false };
    case Types.SUCCESS_ES_DEL:
      customToast.success("Estrutura salarial removida com sucesso");
      return {
        ...state,
        data: state.data.filter((item) => item.idES !== action.idES),
        loadingAction: false,
        error: false,
        success: true,
      };
    case Types.FAILURE_ES_DEL:
      customToast.error(action.message);
      return { ...state, loadingAction: false, error: true, success: false };

    // ADD TAB
    case Types.REQUEST_ES_TAB:
      return {
        ...state,
        loadingAction: true,
        error: false,
        success: false,
        itemSelected: { ...state.itemSelected, success: false },
      };
    case Types.SUCCESS_ES_TAB:
      customToast.success("Tabela adicionada com sucesso");
      return {
        ...state,
        loadingAction: false,
        error: false,
        success: true,
        itemSelected: {
          ...state.itemSelected,
          item: {
            ...state.itemSelected.item!,
            tabs: [...state.itemSelected.item!.tabs!, action.payload],
          },
          success: true,
        },
      };
    case Types.FAILURE_ES_TAB:
      customToast.error(action.message);
      return {
        ...state,
        loadingAction: false,
        error: true,
        success: false,
        itemSelected: { ...state.itemSelected, success: false },
      };

    case Types.REQUEST_ES_LOAD_GS:
      return {
        ...state,
        dataGS: null,
        loadingDataGS: true,
        success: false,
        error: false,
      };
    case Types.SUCCESS_ES_LOAD_GS:
      return {
        ...state,
        dataGS: action.payload,
        loadingDataGS: false,
        success: true,
        error: false,
      };
    case Types.FAILURE_ES_LOAD_GS:
      customToast.error(action.message);
      return {
        ...state,
        dataGS: null,
        loadingDataGS: false,
        success: false,
        error: true,
      };

    case Types.REQUEST_ES_LOAD_GS_BY_ID:
      return { ...state, loadingDataGS: true, successGs: false, error: false };
    case Types.SUCCESS_ES_LOAD_GS_BY_ID:
      return {
        ...state,
        gsSelected: action.payload,
        loadingDataGS: false,
        successGs: true,
        error: false,
      };
    case Types.FAILURE_ES_LOAD_GS_BY_ID:
      customToast.error(action.message);
      return { ...state, loadingDataGS: false, successGs: false, error: true };

    case Types.REQUEST_ES_ADD_GS:
      return { ...state, loadingAction: true, success: false, error: false };
    case Types.SUCCESS_ES_ADD_GS:
      customToast.success("Grupo salarial adicionado com sucesso");
      return {
        ...state,
        dataGS: {
          ...state.dataGS!,
          resp: [...state.dataGS!.resp, action.payload],
        },
        loadingAction: false,
        success: true,
        error: false,
      };
    case Types.FAILURE_ES_ADD_GS:
      customToast.error(action.message);
      return { ...state, loadingAction: false, success: false, error: true };

    case Types.REQUEST_ES_EDIT_GS:
      return { ...state, loadingAction: true, success: false, error: false };
    case Types.SUCCESS_ES_EDIT_GS:
      customToast.success("Grupo salarial editado com sucesso");
      return {
        ...state,
        dataGS: {
          ...state.dataGS!,
          resp: state.dataGS!.resp.map((item) => {
            if (item.idGS !== action.payload.idGS) {
              return item;
            } else {
              return action.payload;
            }
          }),
        },
        loadingAction: false,
        success: true,
        error: false,
      };
    case Types.FAILURE_ES_EDIT_GS:
      customToast.error(action.message);
      return { ...state, loadingAction: false, success: false, error: true };

    case Types.REQUEST_ES_DEL_GS:
      return { ...state, loadingAction: true, success: false, error: false };
    case Types.SUCCESS_ES_DEL_GS:
      customToast.success("Grupo salarial excluído com sucesso");
      return {
        ...state,
        dataGS: {
          ...state.dataGS!,
          resp: state.dataGS!.resp.filter((item) => item.idGS !== action.idGS),
        },
        loadingAction: false,
        success: true,
        error: false,
      };
    case Types.FAILURE_ES_DEL_GS:
      customToast.error(action.message);
      return { ...state, loadingAction: false, success: false, error: true };

    case Types.CLEAR_ES:
      return { ...state, data: [] };

    case Types.REQUEST_ES_INFO_SALARIAL:
      return {
        ...state,
        infoSalarial: null,
        loadingAction: true,
        success: false,
        error: false,
      };
    case Types.SUCCESS_ES_INFO_SALARIAL:
      return {
        ...state,
        infoSalarial: action.payload,
        loadingAction: false,
        success: false,
        error: false,
      };
    case Types.FAILURE_ES_INFO_SALARIAL:
      customToast.error(action.message);
      return {
        ...state,
        infoSalarial: null,
        loadingAction: false,
        success: false,
        error: false,
      };

    default:
      return state;
  }
}
