import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  Dropdown as DropdownUI,
  IDropdownOption,
} from "office-ui-fabric-react/lib/Dropdown";

import CustomDialog from "~/components/CustomDialogFluentUI";
import {
  TitleField,
  GroupItems,
  GroupItemUmTerco,
  GroupItemDoisTerco,
} from "~/components/FormGroup";
import { InputText } from "~/components/Forms";
import { DataTypes } from "~/store/ducks/tasks/types";
import { Creators } from "~/store/ducks/tasks";
import {
  DefaultButton,
  Icon,
  Spinner,
  SpinnerSize,
  Stack,
} from "office-ui-fabric-react";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "~/store/ducks";
import { PrimaryButton } from "~/components/Buttons";
import Colors from "~/assets/js/colors";
import { Text } from "@fluentui/react";

const validationSchema = yup.object().shape({
  resultadoatitudes: yup.string().required("Campo obrigatório"),
  comentariosAtitudes: yup.string().notRequired(),
});

interface IDialogAtitudesProps {
  isOpen: boolean;
  idTarefa: number | null;
  idTarefaAvaliacao: number;
  nomeCompleto: string;
  //initialValues: CalibrationValues;
  loadingData: boolean;
  success: boolean;
  //performances: TabPerfomanceType[];
  readonly: boolean;
  modoHidden?: boolean;
  onClose: () => void;
  onSuccess: (values: any) => void;
}

const initial = {
  resultadoatitudes: "",
  comentariosAtitudes: "",
};

const DialogAtitudes: React.FC<IDialogAtitudesProps> = (props) => {
  const {
    isOpen,
    onClose,
    onSuccess,
    idTarefa,
    idTarefaAvaliacao,
    nomeCompleto,
    loadingData,
    success,
    readonly,
    //performances,
    //initialValues,
  } = props;
  const dispatch = useDispatch();

  const { selectedColetiva: avaliacao, selected } = useSelector<
    RootState,
    DataTypes
  >((state) => state.tasksReducer);

  const atitudes = selected?.TarefaAvaliacaoColetiva?.atitudes ?? [];

  useEffect(() => {
    if (idTarefa && idTarefaAvaliacao) {
      dispatch(Creators.getInfoAvaliacaoColetiva(idTarefa, idTarefaAvaliacao));
    }
  }, [idTarefa, idTarefaAvaliacao]);

  useEffect(() => {
    resetForm({
      values: {
        resultadoatitudes: avaliacao?.resultadoatitudes ?? "",
        comentariosAtitudes: avaliacao?.comentariosAtitudes ?? "",
      },
    });
  }, [avaliacao]);

  useEffect(() => {
    if (success && isOpen) {
      resetForm();
    }
  }, [success, isOpen]);

  const onRenderTitle = (options: IDropdownOption[]): JSX.Element => {
    const option = options[0];

    return (
      <Stack horizontal verticalAlign="center">
        {option.data && option.data.icon && (
          <Icon
            iconName={option.data.icon}
            aria-hidden="true"
            title={option.data.icon}
            styles={{ root: { ...option.data.style, marginRight: 10 } }}
          />
        )}
        <Stack>
          <span
            style={{
              fontSize: 12,
              whiteSpace: "break-spaces",
              lineHeight: 1.5,
              textAlign: "left",
              ...option.data.styleOption,
            }}
          >
            {option.text}
          </span>
        </Stack>
      </Stack>
    );
  };

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    resetForm,
    setFieldValue,
    setFieldError,
  } = useFormik({
    initialValues: initial,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit(values) {
      onSuccess(values);
    },
  });

  return (
    <CustomDialog
      hidden={!isOpen}
      title="Avaliação da Performance"
      minWidth={640}
      minHeight={420}
      onRender={
        <>
          {loadingData ? (
            <Spinner
              size={SpinnerSize.medium}
              label="Carregando avaliado"
              styles={{ root: { height: 284 } }}
            />
          ) : (
            <form onSubmit={handleSubmit}>
              <GroupItemDoisTerco>
                <GroupItems title="Funcionário" titleRegular>
                  <TitleField text={nomeCompleto} bold />
                </GroupItems>
              </GroupItemDoisTerco>
              <Text
                as={"p"}
                styles={{
                  root: {
                    color: Colors.orange,
                    marginBottom: 50,
                    display: "block",
                  },
                }}
              >
                Avalie o funcionário com base nas atitudes vencedoras:{" "}
                {atitudes.map((item) => item.atitude.nomeAtitude).join(", ")}.
              </Text>
              <GroupItems
                noMargins
                stylesWrapperItems={{
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <GroupItemUmTerco>
                  <TitleField text="AVALIAÇÃO" />
                </GroupItemUmTerco>
                <GroupItemDoisTerco width={360}>
                  <DropdownUI
                    placeholder="Selecione uma opção"
                    options={options}
                    defaultSelectedKey={
                      avaliacao?.resultadoatitudes ?? "Selecione uma opção"
                    }
                    onRenderTitle={onRenderTitle as any}
                    disabled={readonly}
                    onChange={(e, item: IDropdownOption | any) => {
                      setFieldValue(`resultadoatitudes`, item?.key);
                      setFieldError(`resultadoatitudes`, "");
                    }}
                    styles={{
                      title: {
                        height: 60,
                        display: "flex",
                        alignItems: "center",
                        borderColor: errors.resultadoatitudes
                          ? Colors.error
                          : "#CCCCCC",
                      },
                      caretDownWrapper: { top: 16 },
                    }}
                  />
                </GroupItemDoisTerco>
              </GroupItems>

              <InputText
                name="comentariosAtitudes"
                label="Comentários gerais"
                value={values.comentariosAtitudes ?? ""}
                onChange={(e: any) => {
                  handleChange(e);
                  setFieldError("comentariosAtitudes", "");
                }}
                multiline
                rows={5}
                rowsMax={5}
                error={errors.comentariosAtitudes ? true : false}
                helperText={errors.comentariosAtitudes}
                disabled={readonly}
                height_container={150}
              />
            </form>
          )}
        </>
      }
    >
      <DefaultButton
        onClick={() => {
          onClose();
          resetForm();
        }}
      >
        Cancelar
      </DefaultButton>
      <PrimaryButton
        onClick={() => handleSubmit()}
        // disabled={!avaliacao?.flagAutoAvaliacao}
      >
        Confirmar
      </PrimaryButton>
    </CustomDialog>
  );
};

const options: IDropdownOption[] = [
  {
    key: "Selecione uma opção",
    text: "Selecione uma opção",
    disabled: true,
    hidden: true,
    data: { styleOption: { fontSize: 14 } },
  },
  {
    key: "Acima da Expectativa",
    text: "ACIMA DA EXPECTATIVA",
    data: {
      icon: "SkypeCircleArrow",
      style: { color: Colors.blue, transform: "rotate(90deg)" },
    },
  },
  {
    key: "Dentro da Expectativa",
    text: "DENTRO DA EXPECTATIVA",
    data: { icon: "SkypeCircleCheck", style: { color: Colors.primary } },
  },
  {
    key: "Abaixo da Expectativa",
    text: "ABAIXO DA EXPECTATIVA",
    data: {
      icon: "SkypeCircleArrow",
      style: { color: Colors.error, transform: "rotate(-90deg)" },
    },
  },
];

export default DialogAtitudes;
