/**
 * Types
 */
export enum Types {
  //GET
  REQUEST_CONDUCTS_LOAD = "conducts/REQUEST_CONDUCTS_LOAD",
  SUCCESS_CONDUCTS_LOAD = "conducts/SUCCESS_CONDUCTS_LOAD",
  FAILURE_CONDUCTS_LOAD = "conducts/FAILURE_CONDUCTS_LOAD",
  //ADD
  REQUEST_CONDUCT_ADD = "conducts/REQUEST_CONDUCT_ADD",
  SUCCESS_CONDUCT_ADD = "conducts/SUCCESS_CONDUCT_ADD",
  FAILURE_CONDUCT_ADD = "conducts/FAILURE_CONDUCT_ADD",
  //PUT
  REQUEST_CONDUCT_EDIT = "conducts/REQUEST_CONDUCT_EDIT",
  SUCCESS_CONDUCT_EDIT = "conducts/SUCCESS_CONDUCT_EDIT",
  FAILURE_CONDUCT_EDIT = "conducts/FAILURE_CONDUCT_EDIT",
  //DELETE
  REQUEST_CONDUCT_DELETE = "conducts/REQUEST_CONDUCT_DELETE",
  SUCCESS_CONDUCT_DELETE = "conducts/SUCCESS_CONDUCT_DELETE",
  FAILURE_CONDUCT_DELETE = "conducts/FAILURE_CONDUCT_DELETE",
}

export type ConductType = {
  idConduta: number | null;
  idSancao: number | null;
  idTipoConduta: number | null;
  titulo: string;
  gravidade: string;
  flagAtivo: boolean;
  sancao: {
    descSancao: string;
  };
  tipoConduta: {
    titulo: string;
  };
};

interface IConductsState {
  readonly data: ConductType[];
  readonly loading: boolean;
  readonly loadingAction: boolean;
  readonly error: boolean;
  readonly success: boolean;
}

export type DataTypes = IConductsState;

/**
 * Actions
 */

//GET
export interface IRequestConductsLoadAction {
  type: typeof Types.REQUEST_CONDUCTS_LOAD;
}

export interface ISuccessConductsLoadAction {
  type: typeof Types.SUCCESS_CONDUCTS_LOAD;
  payload: ConductType[];
}

interface IFailureConductsLoadAction {
  type: typeof Types.FAILURE_CONDUCTS_LOAD;
  message: string;
}

type ConductsLoadAction =
  | IRequestConductsLoadAction
  | ISuccessConductsLoadAction
  | IFailureConductsLoadAction;

//ADD
export interface IRequestConductAddAction {
  type: typeof Types.REQUEST_CONDUCT_ADD;
  conduct: ConductType;
}

export interface ISuccessConductAddAction {
  type: typeof Types.SUCCESS_CONDUCT_ADD;
  conduct: ConductType;
}

interface IFailureConductAddAction {
  type: typeof Types.FAILURE_CONDUCT_ADD;
  message: string;
}

type ConductAddAction =
  | IRequestConductAddAction
  | ISuccessConductAddAction
  | IFailureConductAddAction;

//PUT
export interface IRequestConductEditAction {
  type: typeof Types.REQUEST_CONDUCT_EDIT;
  conduct: ConductType;
}

export interface ISuccessConductEditAction {
  type: typeof Types.SUCCESS_CONDUCT_EDIT;
  conduct: ConductType;
}

export interface IFailureConductEditAction {
  type: typeof Types.FAILURE_CONDUCT_EDIT;
  message: string;
}

type ConductEditAction =
  | IRequestConductEditAction
  | ISuccessConductEditAction
  | IFailureConductEditAction;

//DELETE
export interface IRequestConductDeleteAction {
  type: typeof Types.REQUEST_CONDUCT_DELETE;
  idConduta: number;
}

export interface ISuccessConductDeleteAction {
  type: typeof Types.SUCCESS_CONDUCT_DELETE;
  idConduta: number;
}

export interface IFailureConductDeleteAction {
  type: typeof Types.FAILURE_CONDUCT_DELETE;
  message: string;
}

type ConductDeleteAction =
  | IRequestConductDeleteAction
  | ISuccessConductDeleteAction
  | IFailureConductDeleteAction;

export type ConductActionTypes =
  | ConductsLoadAction
  | ConductAddAction
  | ConductEditAction
  | ConductDeleteAction;
