import { takeEvery, put, call, all } from "redux-saga/effects";
import {
    Types,
    ObjectivesType,
    IRequestObjectivesLoadAction,
    IRequestObjectivesAddAction,
    IRequestObjectivesEditAction,
    IRequestObjectivesDeleteAction,
    IRequestObjectiveLoadByIdAction
} from "../ducks/objectives/types";
import api from "~/services/api";

const apiCallGet = (payload: IRequestObjectivesLoadAction["payload"]) => {
    const { search, treeview, filterAtivo, filterPai } = payload;
    return api.get(`objetivos?SearchText=${search}${treeview ? '&TreeView=true' : ''}${filterAtivo ? `&FilterAtivo=${filterAtivo}` : ''}${filterPai ? `&FilterPai=${filterPai}` : ''}`)
        .then(response => response.data)
        .catch(err => {
            throw err.response;
        });
};


const apiCallGetById = (id: number) => {
    return api.get(`objetivos/${id}`)
        .then(response => response.data)
        .catch(err => {
            throw err.response;
        });
};


const apiCallPost = (item: Partial<ObjectivesType>) => {
    return api.post('objetivos', {
        ...item
    })
        .then(response => response.data)
        .catch(err => {
            throw err.response;
        });
};

const apiCallPut = (item: ObjectivesType) => {
    return api.put(`objetivos/${item.idObjetivo}`, {
        ...item
    })
        .then(response => response.data)
        .catch(err => {
            throw err.response;
        });
};

const apiCallDel = (id: number) => {
    return api.delete(`objetivos/${id}`)
        .then(response => response.data)
        .catch(err => {
            throw err.response;
        });
};

function* load(action: IRequestObjectivesLoadAction) {
    try {
        const items: ObjectivesType = yield call(apiCallGet, action.payload);
        yield put({ type: Types.SUCCESS_OBJECTIVES_LOAD, payload: items });
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível carregar os objetivos'
        yield put({ type: Types.FAILURE_OBJECTIVES_LOAD, message });
    }
};


function* loadUnitsById(action: IRequestObjectiveLoadByIdAction) {
    try {
        const item = yield call(apiCallGetById, action.id);
        yield put({ type: Types.SUCCESS_OBJECTIVES_LOAD_ID, objective: item });
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível carregar a unidade.';
        yield put({ type: Types.FAILURE_OBJECTIVES_LOAD_ID, message });
    };
};

function* add(action: IRequestObjectivesAddAction) {
    try {
        const idObjetivo = yield call(apiCallPost, action.payload.objective);

        yield put({
            type: Types.SUCCESS_OBJECTIVES_ADD,
            payload: {
                objective: { ...action.payload.objective, ...idObjetivo, children: [] },
                showInItemSelected: action.payload.showInItemSelected
            }
        });
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível adicionar o objetivo';
        yield put({ type: Types.FAILURE_OBJECTIVES_ADD, message: message });
    }
};

function* edit(action: IRequestObjectivesEditAction) {
    try {
        yield call(apiCallPut, action.objective);
        yield put({ type: Types.SUCCESS_OBJECTIVES_EDIT, objective: action.objective });
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível adicionar o objetivo';
        yield put({ type: Types.FAILURE_OBJECTIVES_EDIT, message: message });
    }
};

function* del(action: IRequestObjectivesDeleteAction) {
    try {
        yield call(apiCallDel, action.id);
        yield put({ type: Types.SUCCESS_OBJECTIVES_DELETE, id: action.id });
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível excluír objetivo';
        yield put({ type: Types.FAILURE_OBJECTIVES_DELETE, message });
    }
};

export default function* companiesRoot() {
    yield all([
        yield takeEvery(Types.REQUEST_OBJECTIVES_LOAD, load),
        yield takeEvery(Types.REQUEST_OBJECTIVES_LOAD_ID, loadUnitsById),
        yield takeEvery(Types.REQUEST_OBJECTIVES_ADD, add),
        yield takeEvery(Types.REQUEST_OBJECTIVES_EDIT, edit),
        yield takeEvery(Types.REQUEST_OBJECTIVES_DELETE, del)
    ])
};