import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { withRouter, RouteComponentProps } from "react-router-dom";

import { ContainerContent } from "./styles";

//FluentUI
import { Stack } from "office-ui-fabric-react/lib/Stack";
import { RootState } from "~/store/ducks";
import { OutlinedButton } from "~/components/Buttons";
import Dropdown from "~/components/layout/Dropdown";
import { MenuItem } from "@material-ui/core";

import { Creators as getResumo } from "~/store/ducks/merit";
import { Creators as setCurrentTab } from "~/store/ducks/profile";
import { Creators as setCurrentPage } from "~/store/ducks/home";
import { DataTypes as DataTypesMerit } from "~/store/ducks/merit/types";

import GridResumeMerit from "./GridResumeMerit";

interface ICycleState {
  decisao: "G" | "RH";
}

interface ICyclesProps {
  merits: DataTypesMerit;
  idMerito: number;
  getResumo: (idMerito: number, decisao: "G" | "RH") => void;
  setCurrentTab: (indexTab: string) => void;
}

class ResumeMerit extends Component<ICyclesProps, ICycleState> {
  constructor(props: ICyclesProps) {
    super(props);

    this.state = {
      decisao: "G",
    };
  }

  componentDidMount() {
    this.handleClickResumo();
  }

  handleClickResumo = () => {
    const { idMerito, getResumo } = this.props;
    getResumo(idMerito, this.state.decisao);
  };

  render() {
    const { decisao } = this.state;
    const { merits } = this.props;
    return (
      <ContainerContent>
        <Stack
          horizontal
          verticalAlign="center"
          styles={{ root: { marginBottom: 20 } }}
        >
          <Dropdown
            variant="outlined"
            label=""
            name="season"
            values={decisao}
            handleChange={(e: any) =>
              this.setState({ decisao: e.target.value })
            }
            fieldHeight={32}
            styles={{
              boxSizing: "border-box",
              height: 32,
              backgroundColor: "white",
              width: "100%",
            }}
            autofocus
            customPadding="6.5px 14px"
          >
            <MenuItem value={"RH"}>Sugestão de Remuneração</MenuItem>
            <MenuItem value={"G"}>Decisão do gestor</MenuItem>
          </Dropdown>
          <OutlinedButton
            text="Abrir Resumo"
            style={{ width: 150, marginLeft: 15 }}
            onClick={this.handleClickResumo}
          />
        </Stack>
        <GridResumeMerit
          merits={merits.dataResumo}
          isLoading={merits.loadingData}
        />
      </ContainerContent>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  merits: state.meritReducer,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      ...setCurrentPage,
      ...setCurrentTab,
      ...getResumo,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ResumeMerit);
