import React from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import ContainerPrivateRouter from "~/components/layout/ContainerPrivateRouter";

import SignIn from "~/pages/SignIn";
import RetrieveAccount from "~/pages/RetrieveAccount";
import Home from "~/pages/Home";
import Companies from "~/pages/Admin/Companies";
import Cycles from "~/pages/Cycles";
import Cycle from "~/pages/Cycle";
import Pillars from "~/pages/Pillars";
import Profile from "~/pages/Profile";
import ProfileNewFeedback from "~/pages/Profile/Feedbacks/NewFeedback";
import Objectives from "~/pages/Objectives";
import Chart from "~/pages/Admin/Chart";
import Employees from "~/pages/Employees";
import Units from "~/pages/Admin/Units";
import Positions from "~/pages/Admin/Positions";
import WorkFlow from "~/pages/WorkFlow";
import Task from "~/pages/WorkFlow/IndividualTask";
import SelfEvaluation from "~/pages/Tasks/SelfEvaluation";
import Attitudes from "~/pages/Attitudes";
import SelfEvaluationManager from "~/pages/SelfEvaluationManager";
import Meetings from "~/pages/Meetings";
import Calibration from "~/pages/Calibration";
import ErrorBoundary from "~/pages/ErrorBoundary";
import ManageTasks from "~/pages/Tasks/ManageTasks";
import Pdis from "~/pages/Admin/Pdis";
import ReportPerformanceCurve from "~/pages/Reports/ReportPerformanceCurve";
import MatrizTalentos from "~/pages/Reports/MatrizTalentos";
import ReportSuccession from "~/pages/Reports/Succession";
import MeritGenerate from "~/pages/MeritGenerate";
import Merit from "~/pages/Merit";
import MyTeam from "~/pages/MyTeam";
import SalaryStructures from "~/pages/Admin/SalaryStructure";
import ConfigSalaryStructure from "~/pages/Admin/ConfigSalaryStructure";
import ConfigMerit from "~/pages/Admin/ConfigMerit";
import PerformanceTables from "~/pages/Admin/PerformanceTables";

import NavBar from "~/components/layout/NavBar";
import { RootState } from "~/store/ducks";
import { DataTypes } from "~/store/ducks/login/types";
import HalfYearAvaliation from "~/pages/Tasks/HalfYearAvaliation";
import BonusGenerate from "~/pages/BonusGenerate";
import Bonus from "~/pages/Bonus";
import CategoriesPositions from "~/pages/Admin/CategoriesPositions";
import MotivosDesligamentos from "~/pages/Admin/MotivosDesligamentos";
import MotivosFaltas from "~/pages/Admin/MotivosFaltas";
import StandardsPositions from "~/pages/Admin/StandardsPositions";
import TipoMov from "~/pages/Admin/TipoMov";
import MotivosAfastamentos from "~/pages/Admin/MotivosAfastamentos";
import Vinculos from "~/pages/Admin/Vinculos";
import { Reports } from "~/pages/Admin/Reports";
import MeritReport from "~/pages/Reports/Merit";
import Feedback from "~/pages/Tasks/Feedback";
import FeedbackFuncionario from "~/pages/Tasks/FeedbackFuncionario";
import Sanctions from "~/pages/Admin/Sanctions";
import Conducts from "~/pages/Admin/Conducts";
import ConfigJustaCausa from "~/pages/Admin/ConfigJustaCausa";
import EducationalMeasure from "~/pages/EducationalMeasure";
import TypesConducts from "~/pages/Admin/TypesConducts";

const style = {
  display: "flex",
};

const PublicRoute: React.FC = () => (
  <BrowserRouter basename="/">
    <Switch>
      <ErrorBoundary>
        <Route path="/" exact component={SignIn} />
        <Route path="/recuperar/:type" component={RetrieveAccount} />
        <Redirect from="*" to="/" />
      </ErrorBoundary>
    </Switch>
  </BrowserRouter>
);

const AdmRoutes: React.FC = () => (
  <>
    <Route path="/ciclos" exact component={Cycles} />
    <Route path="/ciclos/:idCiclo" exact component={Cycle} />
    <Route path="/pilares" exact component={Pillars} />
    <Route path="/objetivos" exact component={Objectives} />
    <Route path="/funcionarios" exact component={Employees} />
    <Route path="/atitudes" exact component={Attitudes} />
    <Route path="/reunioes" exact component={Meetings} />
    <Route path="/bonus" exact component={BonusGenerate} />
    <Route path="/bonus/:idBonus" exact component={Bonus} />
    <Route path="/reunioes/:idCicloReuniao" exact component={Calibration} />
    <Route
      path="/relatorios/curva-performance"
      exact
      component={ReportPerformanceCurve}
    />
    <Route
      path="/relatorios/matriz-talentos"
      exact
      component={MatrizTalentos}
    />
    <Route path="/relatorios/sucessao" exact component={ReportSuccession} />
    <Route path="/relatorios/merito" exact component={MeritReport} />
    <Route path="/merito" exact component={MeritGenerate} />
    <Route path="/merito/:idMerito" exact component={Merit} />
    <Route path="/admin/empresas" exact component={Companies} />
    <Route path="/admin/sancoes" exact component={Sanctions} />
    <Route path="/admin/condutas" exact component={Conducts} />
    <Route path="/admin/tipoConduta" exact component={TypesConducts} />
    <Route path="/admin/organograma" exact component={Chart} />
    <Route path="/admin/unidades" exact component={Units} />
    <Route path="/admin/cargos" exact component={Positions} />
    <Route path="/admin/acoes-desenvolvimento" exact component={Pdis} />
    <Route
      path="/admin/categorias-cargos"
      exact
      component={CategoriesPositions}
    />
    <Route path="/admin/padroes-cargos" exact component={StandardsPositions} />
    <Route
      path="/admin/motivos-desligamentos"
      exact
      component={MotivosDesligamentos}
    />
    <Route path="/admin/motivos-faltas" exact component={MotivosFaltas} />
    <Route
      path="/admin/motivos-afastamentos"
      exact
      component={MotivosAfastamentos}
    />
    <Route path="/admin/tipos-movimentacoes" exact component={TipoMov} />
    <Route path="/admin/vinculos" exact component={Vinculos} />
    <Route
      path="/admin/configuracao-justa-causa"
      exact
      component={ConfigJustaCausa}
    />
    <Route
      path="/admin/estruturas-salariais"
      exact
      component={SalaryStructures}
    />
    <Route
      path="/admin/configuracao-es"
      exact
      component={ConfigSalaryStructure}
    />
    <Route path="/admin/configuracao-merito" exact component={ConfigMerit} />
    <Route
      path="/admin/tabelas-performance"
      exact
      component={PerformanceTables}
    />
    <Route path="/admin/relatorios" exact component={Reports} />
  </>
);

const BpRoutes: React.FC = () => (
  <>
    <Route path="/reunioes" exact component={Meetings} />
    <Route path="/reunioes/:idCicloReuniao" exact component={Calibration} />
  </>
);

const EfetividadeRoute: React.FC = () => (
  <>
    <Route path="/ciclos" exact component={Cycles} />
    <Route path="/ciclos/:idCiclo" exact component={Cycle} />
  </>
);

const RhRoute: React.FC = () => (
  <>
    <Route path="/merito" exact component={MeritGenerate} />
    <Route path="/merito/:idMerito" exact component={Merit} />
    <Route path="/admin/empresas" exact component={Companies} />
    <Route path="/admin/organograma" exact component={Chart} />
    <Route path="/admin/unidades" exact component={Units} />
    <Route path="/admin/cargos" exact component={Positions} />
    <Route
      path="/admin/categorias-cargos"
      exact
      component={CategoriesPositions}
    />
    <Route path="/admin/padroes-cargos" exact component={StandardsPositions} />
    <Route
      path="/admin/motivos-desligamentos"
      exact
      component={MotivosDesligamentos}
    />
    <Route path="/admin/motivos-faltas" exact component={MotivosFaltas} />
    <Route
      path="/admin/motivos-afastamentos"
      exact
      component={MotivosAfastamentos}
    />
    <Route path="/admin/tipos-movimentacoes" exact component={TipoMov} />
    <Route path="/admin/vinculos" exact component={Vinculos} />
    {/* <Route path="/admin/sancoes" exact component={Sanctions} />
    <Route path="/admin/condutas" exact component={Conducts} />
    <Route path="/admin/tipoConduta" exact component={TypesConducts} /> */}
  </>
);

const PrivateRoute: React.FC = () => (
  <>
    <Route path="/" exact component={Home} />
    <Route path="/perfil" exact component={Profile} />
    <Route path="/perfil/:idFuncionario" exact component={Profile} />
    <Route path="/perfil/:idFuncionario/feedback-adicionar" exact component={ProfileNewFeedback} />
    <Route path="/perfil/:idFuncionario/feedback/:idFeedback" exact component={FeedbackFuncionario}  />

    <Route path="/perfil/:idFuncionario/medida-educativa/:idMedidaEducativa?" exact component={EducationalMeasure} />
    <Route path="/workflow" exact component={WorkFlow} />
    <Route path="/tarefas/:idTarefa" exact component={ManageTasks} />
    <Route path="/tarefas/:idTarefa/feedback/:idTarefaFeedback" exact component={Feedback} />
    <Route
      path="/tarefas/:idTarefa/avaliacao/:id"
      exact
      component={SelfEvaluationManager}
    />
    <Route path="/tarefas/meio-ano/:id" exact component={HalfYearAvaliation} />
    <Route path="/workflow/solicitacao/:id" exact component={Task} />
    <Route path="/workflow/autovaliacao/:id" exact component={SelfEvaluation} />
    <Route path="/time" exact component={MyTeam} />
    
  </>
);

export default () => {
  const { isLogged, data } = useSelector<RootState, DataTypes>(
    (state) => state.loginReducer
  );

  const tk = localStorage.getItem("user/token");

  if (isLogged && tk) {
    return (
      <BrowserRouter basename="/">
        <Switch>
          <ContainerPrivateRouter>
            <div style={style}>
              <NavBar />
              <ErrorBoundary>
                {data.flagAdministrador ? (
                  <AdmRoutes />
                ) : (
                  <>
                    {data.flagBP && <BpRoutes />}
                    {data.flagEfetividade && <EfetividadeRoute />}
                    {data.flagRH && <RhRoute />}
                  </>
                )}
                <PrivateRoute />
              </ErrorBoundary>
            </div>
          </ContainerPrivateRouter>
          <Redirect from="*" to="/" />
        </Switch>
      </BrowserRouter>
    );
  } else {
    return <PublicRoute />;
  }
};
