/**
 * Types
 */
export enum Types {
  //LOAD
  REQUEST_TEAM_LOAD = "team/REQUEST_TEAM_LOAD",
  SUCCESS_TEAM_LOAD = "team/SUCCESS_TEAM_LOAD",
  FAILURE_TEAM_LOAD = "team/FAILURE_TEAM_LOAD",
}

export interface TeamType {
  idFuncionario: number;
  matricula: string;
  nomeCompleto: string;
  status: string;
  imagem: string;
  idFuncionarioChefe: number;
  idCargo: number;
  titulo: string;
  children?: TeamType[];
}

interface ITeamState {
  readonly data: TeamType[];
  readonly loadingAction: boolean;
  readonly loadingData: boolean;
  readonly error: boolean;
  readonly success: boolean;
}

export type DataTypes = ITeamState;

//SAVE
export interface IRequestTeamLoadAction {
  type: typeof Types.REQUEST_TEAM_LOAD;
  search?: string;
}

export interface ISuccessTeamLoadAction {
  type: typeof Types.SUCCESS_TEAM_LOAD;
  payload: TeamType[];
}

interface IFailureTeamLoadAction {
  type: typeof Types.FAILURE_TEAM_LOAD;
  message: string;
}

type LoadTeamAction =
  | IRequestTeamLoadAction
  | ISuccessTeamLoadAction
  | IFailureTeamLoadAction;

export type TeamActionTypes = LoadTeamAction;
