import styled from "styled-components";
import {
    DefaultButton as DefaultBtn,
} from "office-ui-fabric-react";

export const Wrapper = styled.div`
  height: calc(100% - 50px);
  width: 100%;
  flex-basis: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

export const ListContainer = styled.div`
  width: 100%;
  height: calc(100% - 82px);
  overflow-y: auto;
`;

export const DefaultButton = styled(DefaultBtn)`
  width: 110px;
`
export const WrapperBox = styled.div<{ color: string }>`
    display: flex;
    border-radius: 2px;
    height: 100px;
    max-width: 310px;
    margin-right: 24px;
    background-color: ${({color}) => color};
    padding: 10px 15px;
`

export const WrapperAutoComplete = styled.div`
  position: relative;
  width: 100%;
  .MuiAutocomplete-inputRoot  .MuiAutocomplete-endAdornment{
    right: 35px;
  }
`
