import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import { RouteComponentProps, withRouter } from "react-router-dom";
import * as yup from "yup";
import { Formik } from "formik";

//FluentUI
import { Stack } from "office-ui-fabric-react/lib/Stack";
import { ICommandBarStyles } from "office-ui-fabric-react";

import { CommandBarButton } from "~/components/Buttons";

//Componentes
import PainelHeader from '~/components/layout/PainelHeader';
import { RootState } from "~/store/ducks";

import { Creators as getNewFeedback } from "~/store/ducks/profile";
import { Creators as addNewFeedback } from "~/store/ducks/profile";
import { Creators as setCurrentTab } from "~/store/ducks/profile";
import { Creators as setCurrentPage } from "~/store/ducks/home";

import {
  Wrapper,
  Div,
  Content
} from "./styles";


import { Page, BreadcrumbItems } from "~/store/ducks/home/types";
import { AddNewFeedbackDataType, DataTypes } from "~/store/ducks/profile/types";
import Accordion from "~/components/Accordion";
import { InputDate, InputText } from "~/components/Forms";

interface IProfileNewFeedbackState {
  isDialogOpen: boolean;
  redirect: boolean;
  idCycle: number | null;
  currentPivot: string;
  currentPainel: string | null;
};

interface IProfileNewFeedbackProps extends RouteComponentProps {
  profile: DataTypes;
  getNewFeedback: (idFuncionario: number) => void;
  addNewFeedback: (idFuncionario: number, data: AddNewFeedbackDataType) => void;
  setCurrentTab: (indexTab: string) => void;
  setCurrentPage: (page: Page) => void;
  match: any;
  history: RouteComponentProps["history"];
};
const validationFeedbackSchema = yup.object().shape({
  dataOcorrencia: yup.date().nullable().typeError('Data inválida').required("Campo obrigatório"),
  descricao: yup.string().required("Campo obrigatório"),
});

const btnStyle: Partial<ICommandBarStyles> = {
  root: {
    height: 44,
  },
};

const itemsBreadCrumb: BreadcrumbItems[] = [
  {
    text: "Home",
    isCurrentItem: false,
    icon: "HomeSolid",
    onlyIcon: true,
    url: "/",
  },
];

const page: Page = {
  key: "feedback",
  pages: itemsBreadCrumb,
};

class ProfileNewFeedback extends Component<IProfileNewFeedbackProps, IProfileNewFeedbackState> {
  private formRef: any;

  constructor(props: IProfileNewFeedbackProps) {
    super(props);
    this.state = {
      isDialogOpen: false,
      redirect: false,
      idCycle: null,
      currentPivot: "0",
      currentPainel: null
    };

    this.formRef = React.createRef();
  };

  componentDidMount() {
    const { idFuncionario } = this.props.match.params;

    this.props.setCurrentPage(page);
    this.props.getNewFeedback(idFuncionario);
  }

  componentDidUpdate(prevProps: IProfileNewFeedbackProps, prevState: IProfileNewFeedbackState) {
    const { successAction: prevSuccessAction, success: prevSucess } = prevProps.profile
    const { successAction: nextSuccessAction, success: nextSucess } = this.props.profile
    const { idFuncionario } = this.props.match.params;

    if (prevSuccessAction !== nextSuccessAction) {
      if (nextSuccessAction) {
        this.props.setCurrentTab("5");
        this.props.history.push(`/perfil/${idFuncionario}`)
      }
    }

    if (prevSucess !== nextSucess) {
      if (nextSucess) {
        this.props.setCurrentPage({
          key: "feedback",
          pages: [
            {
              text: "Home",
              isCurrentItem: false,
              icon: "HomeSolid",
              onlyIcon: true,
              url: "/",
            },
            { text: "Perfil", isCurrentItem: false, url: `/perfil/${idFuncionario}` },
            {
              text: "Feedback de Funcionário",
              isCurrentItem: true,
            }
          ],
        })
      }
    }

  }

  handleSubmit = () => {
    if (this.formRef.current) {
      this.formRef.current.handleSubmit();
    }
  };

  toggleDialog = () => {
    this.setState({ isDialogOpen: !this.state.isDialogOpen });
  };

  handlePivot = (idPivot: string) => {
    this.setState({ currentPivot: idPivot })
  }

  handleChangePainel = (nivel: string) => {
    this.setState({ currentPainel: nivel })
  }

  render() {
    const { profile, addNewFeedback } = this.props;
    const { dataNewFeedback, loadingAction } = profile;

    const { idFuncionario } = this.props.match.params

    if (this.state.redirect) return (
      <Redirect to="/ciclos" />
    );

    return (
      <Div>
        <PainelHeader
          title={"Registro Feedback"}
          icon={{ icon: "WorkItemAlert" }}
          labelGroups={[
            {
              icon: "contact",
              label: "Funcionário",
              value: dataNewFeedback?.funcionario ?? ""
            },
            {
              icon: "Flag",
              label: "Status",
              value: dataNewFeedback?.status ?? ""
            }
          ]}
        />
        <Content>
          <Wrapper>
            <Stack
              horizontal
              horizontalAlign="space-between"
              verticalAlign="center"
            >
              <Stack horizontal>
                <CommandBarButton
                  styles={btnStyle}
                  iconProps={{ iconName: "Save" }}
                  isLoading={loadingAction}
                  text="Salvar"
                  onClick={() => this.handleSubmit()}
                />
                <CommandBarButton
                  styles={btnStyle}
                  iconProps={{ iconName: "Cancel" }}
                  text="Cancelar"
                  onClick={() => {
                    this.props.setCurrentTab("5");
                    this.props.history.push({
                      pathname: `/perfil/${idFuncionario}`,
                    })
                  }}
                />
              </Stack>
            </Stack>
            <Accordion title="Registro do Feedback">
              <Formik
                innerRef={this.formRef}
                initialValues={{
                  dataOcorrencia: null,
                  descricao: ""
                }}
                validationSchema={validationFeedbackSchema}
                validateOnChange={false}
                validateOnBlur={true}
                enableReinitialize
                onSubmit={(values: AddNewFeedbackDataType) => {
                  addNewFeedback(idFuncionario, values);
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  values,
                  errors,
                  setFieldError,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit} style={{ marginBottom: 20 }}>
                    <Stack styles={{ root: { maxWidth: 370 } }}>
                      <InputDate
                        value={values.dataOcorrencia}
                        onChange={(date) => {
                          console.log(date)
                          setFieldValue("dataOcorrencia", date);
                          setFieldError("dataOcorrencia", "");
                        }}
                        id="dataOcorrencia"
                        error={errors.dataOcorrencia ? true : false}
                        name="dataOcorrencia"
                        label="Data da Ocorrência"
                        className="mt-2"
                        helperText={errors.dataOcorrencia}
                      />
                    </Stack>
                    <InputText
                      id="descricao"
                      name="descricao"
                      label="Descrição"
                      multiline
                      rows={3}
                      value={values.descricao}
                      onChange={(e) => {
                        handleChange(e);
                        setFieldError("descricao", "");
                      }}
                      error={errors.descricao ? true : false}
                      aria-readonly="true"
                      helperText={errors.descricao}
                    />
                  </form>
                )}
              </Formik>
            </Accordion>
          </Wrapper>
        </Content>
      </Div>
    );
  };
};



const mapStateToProps = (state: RootState) => ({
  profile: state.profileReducer,
});

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  ...getNewFeedback,
  ...addNewFeedback,
  ...setCurrentTab,
  ...setCurrentPage
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProfileNewFeedback));
