// #region Imports
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Formik } from "formik";
import * as yup from "yup";

//FluentUI
import { Stack } from "office-ui-fabric-react/lib/Stack";
import {
  DetailsListLayoutMode,
  SelectionMode,
  IColumn,
} from "office-ui-fabric-react/lib/DetailsList";
import { ShimmeredDetailsList } from "office-ui-fabric-react/lib/ShimmeredDetailsList";
import { Spinner, SpinnerSize } from "office-ui-fabric-react/lib/Spinner";

import Dialog from "~/components/CustomDialog";
import Accordion from "~/components/Accordion";
import { Page, BreadcrumbItems } from "~/store/ducks/home/types";
import { InputText } from "~/components/Forms";

import HeaderPage from "~/components/layout/HeaderPage";
//Estilos
import { Wrapper, ListContainer, ContainerContent } from "./styles";

import {
  ConfigJustaCausaType,
  DataTypes as DataTypesConfig,
} from "~/store/ducks/admin/admin/types";
import { Creators as loadConfigJustaCausa } from "~/store/ducks/admin/admin";
import { Creators as editConfigJustaCausa } from "~/store/ducks/admin/admin";

import { Creators as setCurrentPage } from "~/store/ducks/home";
import { RootState } from "~/store/ducks";
//#endregion

interface IConfigJustaCausaState {
  columnsRegras: IColumn[];
  selectionDetails: string;
  isDialogOpen: boolean;
}

interface IPropsConfigJustaCausa {
  configJustaCausa: DataTypesConfig;
  loadConfigJustaCausa: () => void;
  editConfigJustaCausa: (config: ConfigJustaCausaType) => void;
  setCurrentPage: (page: Page) => void;
}

class ConfigJustaCausa extends Component<
  IPropsConfigJustaCausa,
  IConfigJustaCausaState
> {
  private formRefEditConfig: any;

  constructor(props: IPropsConfigJustaCausa) {
    super(props);
    //#region Colunas
    const columnsRegras: IColumn[] = [
      {
        key: "column1",
        name: "Descrição",
        ariaLabel: "Descrição",
        fieldName: "descricao",
        isRowHeader: true,
        minWidth: 100,
        isResizable: true,
        isSortedDescending: false,
        isPadded: true,
      },
      {
        key: "column2",
        name: "Valor Atual",
        fieldName: "valorAtual",
        minWidth: 100,
        maxWidth: 100,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        data: "string",
        onRender: (item) => (
          <Stack
            horizontal
            horizontalAlign="end"
            styles={{ root: { paddingRight: 32 } }}
          >
            {item.valorAtual}
          </Stack>
        ),
      },
    ];
    //#endregion

    this.state = {
      columnsRegras,
      selectionDetails: "",
      isDialogOpen: false,
    };

    this.formRefEditConfig = React.createRef();
  }

  componentDidMount() {
    const page: Page = {
      key: "configJustaCausa",
      pages: itemsBreadCrumb,
    };
    this.props.setCurrentPage(page);
    this.props.loadConfigJustaCausa();
  }

  // Submit do formulário Formik
  handleSubmitEditConfig = () => {
    if (this.formRefEditConfig.current) {
      this.formRefEditConfig.current.handleSubmit();
    }
  };

  _onItemInvokedRegras = (item: any): void => {
    this.setState({ isDialogOpen: true });
  };

  render() {
    const { columnsRegras, isDialogOpen } = this.state;
    const { editConfigJustaCausa, configJustaCausa } = this.props;
    const { justaCausaConfig } = configJustaCausa;

    const data = [
      {
        descricao: "Prazo de comparação das condutas em meses",
        valorAtual: justaCausaConfig?.periodoSoma,
      },
      {
        descricao: "Pontuação necessária de sanções para Justa Causa",
        valorAtual: justaCausaConfig?.pontosJustaCausa,
      },
      {
        descricao:
          "Zerar pontuação quando última medida ultrapassar a quantidade de dias",
        valorAtual: justaCausaConfig?.diasZerar,
      },
    ];

    return (
      <>
        <Wrapper>
          <ContainerContent>
            <HeaderPage title="Configurações de Justa Causa" />
            {configJustaCausa.loading ? (
              <Spinner
                size={SpinnerSize.medium}
                label="Carregando Configurações"
                styles={{ root: { height: "100%" } }}
              />
            ) : (
              <>
                <Accordion title="Regras">
                  <ListContainer>
                    <ShimmeredDetailsList
                      items={data}
                      enableShimmer={configJustaCausa.loading}
                      columns={columnsRegras}
                      selectionMode={SelectionMode.none}
                      selectionPreservedOnEmptyClick={true}
                      setKey="single"
                      layoutMode={DetailsListLayoutMode.justified}
                      isHeaderVisible={true}
                      onItemInvoked={this._onItemInvokedRegras}
                    />
                  </ListContainer>
                </Accordion>
              </>
            )}
            <Dialog
              title="Editar Configurações"
              open={isDialogOpen}
              onClickCancel={() => this.setState({ isDialogOpen: false })}
              onClickConfirm={this.handleSubmitEditConfig}
              btnConfirmText="Adicionar"
            >
              <Formik
                innerRef={this.formRefEditConfig}
                initialValues={configJustaCausa.justaCausaConfig!}
                validationSchema={validationSchema}
                validateOnChange={false}
                validateOnBlur={true}
                onSubmit={(values) => {
                  editConfigJustaCausa(values);
                  this.setState({ isDialogOpen: false });
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  values,
                  errors,
                  setFieldError,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <InputText
                      value={values.periodoSoma ?? ""}
                      onChange={(e) => {
                        handleChange(e);
                        setFieldError("periodoSoma", "");
                      }}
                      id="periodoSoma"
                      error={!!errors.periodoSoma}
                      name="periodoSoma"
                      type="number"
                      label="Prazo de comparação das condutas em meses"
                      helperText={errors.periodoSoma}
                      autoFocus
                      className="mt-2"
                    />
                    <InputText
                      value={values.pontosJustaCausa ?? ""}
                      onChange={(e) => {
                        handleChange(e);
                        setFieldError("pontosJustaCausa", "");
                      }}
                      id="pontosJustaCausa"
                      error={!!errors.pontosJustaCausa}
                      name="pontosJustaCausa"
                      type="number"
                      label="Pontuação necessária de sanções para Justa Causa"
                      helperText={errors.pontosJustaCausa}
                      autoFocus
                      className="mt-2"
                    />
                    <InputText
                      value={values.diasZerar ?? ""}
                      onChange={(e) => {
                        handleChange(e);
                        setFieldError("diasZerar", "");
                      }}
                      id="diasZerar"
                      error={!!errors.diasZerar}
                      name="diasZerar"
                      type="number"
                      label="Quantidade de dias para zerar a pontuação"
                      helperText={errors.diasZerar}
                      autoFocus
                      className="mt-2"
                    />
                  </form>
                )}
              </Formik>
            </Dialog>
          </ContainerContent>
        </Wrapper>
      </>
    );
  }
}

/**
 * Validação do formulário
 */
const validationSchema = yup.object().shape({
  diasZerar: yup.number().required("Campo obrigatório"),
  periodoSoma: yup.number().required("Campo obrigatório"),
  pontosJustaCausa: yup.number().required("Campo obrigatório"),
});

const itemsBreadCrumb: BreadcrumbItems[] = [
  {
    text: "Home",
    isCurrentItem: false,
    icon: "HomeSolid",
    onlyIcon: true,
    url: "/",
  },
  { text: "Configurações de Justa Causa", isCurrentItem: true },
];

const mapStateToProps = (state: RootState) => ({
  configJustaCausa: state.adminReducer,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      ...loadConfigJustaCausa,
      ...editConfigJustaCausa,
      ...setCurrentPage,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ConfigJustaCausa);
