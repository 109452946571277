// #region Imports
import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
 

//FluentUI
import { Text, ITextStyles } from 'office-ui-fabric-react/lib/Text';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import {
  DetailsListLayoutMode,
  SelectionMode,
  Selection,
  IColumn,
} from 'office-ui-fabric-react/lib/DetailsList';
import { ShimmeredDetailsList } from 'office-ui-fabric-react/lib/ShimmeredDetailsList';
import { CommandBarButton, IconButton, ICommandBarStyles, DetailsHeader } from 'office-ui-fabric-react';
import { ScrollablePane } from 'office-ui-fabric-react/lib/ScrollablePane';
import { Sticky } from 'office-ui-fabric-react/lib/Sticky';
//Componentes
import PainelHeader from '~/components/layout/PainelHeader';
import { OutlinedButton } from '~/components/Buttons';
import { Page, BreadcrumbItems } from "~/store/ducks/home/types";
import NoItems from '~/components/layout/NoItems';
import Status from '~/components/Status';
//Estilos
import {
  Wrapper,
  ListContainer,
  ContainerContent,
  ContainerTitle,
  StatusTeam
} from "./styles";


import { Creators as setCurrentPage } from "~/store/ducks/home";
import { Creators as loadTeam } from "~/store/ducks/teamEvaluation";
import { Creators as getTasksById } from "~/store/ducks/tasks";
import { Creators as finishTask } from "~/store/ducks/tasks";
import { Creators as getAvaliationById } from "~/store/ducks/avaliation";
import { DataTypes as DataTypesTasks } from "~/store/ducks/tasks/types";
import { MembrosType } from "~/store/ducks/teamEvaluation/types";
import { RootState } from "~/store/ducks";
import { convertStatusTarefa } from "~/utils/tasks";
//#endregion
//#region Types e Interfaces

const btnStyle: Partial<ICommandBarStyles> = {
  root: {
    height: 44
  }
};

type Filter = {
  isOpen?: boolean;
  filteredBy?: string[];
  departamento?: any;
};

interface IEvaluationsState {
  columns: IColumn[];
  items: MembrosType[];
  isFiltering: boolean;
  selectionDetails: string;
  selectionCount: number;
  filter: Filter;
};

interface IProps extends RouteComponentProps {
  setCurrentPage: (page: Page) => void;
  loadTeam: (idCiclo: number) => void;
  finishTask: (idTarefa: number) => void;
  history: RouteComponentProps["history"];
  getTasksById: (idTarefa: number) => void;
  match: any;
  tasks: DataTypesTasks;
};
//#endregion

const itemsBreadCrumb: BreadcrumbItems[] = [
  { text: "Home", isCurrentItem: false, icon: "HomeSolid", onlyIcon: true, url: "/" },
  { text: "Workflow", isCurrentItem: false, url: "/workflow" },
  { text: "Avaliação do Time", isCurrentItem: true },
];

const page: Page = {
  key: 'timeavaliacao',
  pages: itemsBreadCrumb
};

class Evaluations extends Component<IProps, IEvaluationsState> {
  private _selection: Selection;

  constructor(props: IProps) {
    super(props);
    //#region Colunas
    const columns: IColumn[] = [
      {
        key: 'column0',
        name: '',
        ariaLabel: 'Status do Funcionário',
        fieldName: 'membros',
        minWidth: 15,
        maxWidth: 20,
        isResizable: true,
        isSortedDescending: false,
        onRender: (item: MembrosType) => <Status statusLetter={item.funcionario.status.toLocaleLowerCase()} />
      },
      {
        key: 'column1',
        name: 'Matrícula',
        ariaLabel: 'Matrícula do Funcionário',
        fieldName: 'membros',
        isRowHeader: true,
        minWidth: 75,
        maxWidth: 75,
        isSortedDescending: false,
        onColumnClick: this._onColumnClick,
        onRender: (item: MembrosType) => item.funcionario.matricula
      },
      {
        key: 'column2',
        name: 'Nome Completo',
        fieldName: 'membros',
        ariaLabel: 'Nome Completo',
        isRowHeader: true,
        minWidth: 200,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
        onRender: (item: MembrosType) => item.funcionario.nomeCompleto
      },
      {
        key: 'column3',
        name: 'Autoavaliação',
        fieldName: 'membros',
        ariaLabel: 'Autoavaliação',
        isRowHeader: true,
        minWidth: 130,
        maxWidth: 130,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
        onRender: (item: MembrosType) => {
          if (item.avaliacao?.tipoAvaliacao === 'Avaliação gestor' || item.avaliacao?.tipoAvaliacao === 'Desligado' || item.avaliacao?.tipoAvaliacao === 'Afastado') {
            return (
              <Stack horizontal>
                <Text variant="medium" styles={{ root: { textTransform: 'capitalize' } }}>{item.avaliacao?.tipoAvaliacao}</Text>
              </Stack>
            )
          } else if (item.avaliacao?.flagRealizado === true)
          return (
            <Stack horizontal verticalAlign="center">
                <StatusTeam bgColor={item.avaliacao?.performance?.cor} />
                <Text variant="medium">{item.avaliacao?.performance?.descNivel}</Text>
              </Stack>
            )
            else if (item.avaliacao === null){
              return (
                <Stack horizontal>
                  <Text variant="medium" styles={{ root: { textTransform: 'capitalize' } }}>Aguardando</Text>
                </Stack>
              )
             }
          else {
            return ''
          }
        }
      },
      {
        key: 'column4',
        name: 'Avaliação Gestor',
        fieldName: 'membros',
        ariaLabel: 'Avaliação Gestor',
        isRowHeader: true,
        minWidth: 130,
        maxWidth: 130,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        data: 'string',
        styles: { cellTitle: { display: 'flex', justifyContent: 'center', padding: 0 } },
        onRender: (item: MembrosType) => {
          const idTarefa = props.tasks.selected?.TarefaTime?.idTarefa;
          const idCicloAvaliacao = item.avaliacao?.idCicloAvaliacao;

          if (item.flagRealizarAvaliacao) {
            return (
              <Stack horizontal horizontalAlign="center">
                <OutlinedButton
                  text="Realizar Avaliação"
                  style={{ width: 130 }}
                  onClick={() => {
                    this.props.history.push(`/tarefas/${idTarefa}/avaliacao/${idCicloAvaliacao}`)
                  }}
                />
              </Stack>
            )
          } else if (item.flagEditarAvaliacao) {
            return (
              <Stack horizontal verticalAlign="center">
                <StatusTeam bgColor={item.avaliacao?.performancegestor?.cor ?? ''} />
                <Text variant="medium">{item.avaliacao?.performancegestor?.descNivel}</Text>
                <IconButton
                  iconProps={{ iconName: 'Edit' }}
                  title={'Editar avaliação'}
                  ariaLabel={'Editar avaliação'}
                  styles={{ root: { marginLeft: '5px !important', fontSize: 14 } }}
                  onClick={() => {
                    this.props.history.push(`/tarefas/${idTarefa}/avaliacao/${idCicloAvaliacao}`)
                  }}
                />
              </Stack>
            )
          } else if (item.flagVisualizarAvaliacao) {
            return (
              <Stack horizontal verticalAlign="center">
                <StatusTeam bgColor={item.avaliacao?.performancegestor?.cor ?? ''} />
                <Text variant="medium">{item.avaliacao?.performancegestor?.descNivel}</Text>
                <IconButton
                  iconProps={{ iconName: 'View' }}
                  title={"Visualizar avaliação"}
                  ariaLabel={"Visualizar avaliação"}
                  styles={{ root: { marginLeft: '5px !important', fontSize: 14 } }}
                  onClick={() => {
                    this.props.history.push(`/tarefas/${idTarefa}/avaliacao/${idCicloAvaliacao}`)
                  }}
                />
              </Stack>
            )
          }
          else {
            return '';
          }
        }
      },
      {
        key: 'column6',
        name: 'Potencial',
        fieldName: 'membros',
        ariaLabel: 'Potencial',
        isRowHeader: true,
        minWidth: 130,
        maxWidth: 150,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        data: 'string',
        onRender: (item: MembrosType) => {
          return <p style={{ whiteSpace: 'break-spaces' }}>{item.avaliacao?.potencial}</p>
        }
      },
      {
        key: 'column7',
        name: 'Sucessão',
        fieldName: 'membros',
        ariaLabel: 'Sucessão',
        isRowHeader: true,
        minWidth: 120,
        maxWidth: 150,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        data: 'string',
        onRender: (item: MembrosType) => {
          return <p style={{ whiteSpace: 'break-spaces' }}>{item.avaliacao?.sucessao}</p>
        }
      },
    ];
    //#endregion

    this.state = {
      columns: columns,
      items: [],
      selectionDetails: "",
      isFiltering: false,
      selectionCount: 0,
      filter: {
        isOpen: false,
        filteredBy: [],
        departamento: {}
      }
    };

    this._selection = new Selection({
      onSelectionChanged: () => {
        this.setState({
          selectionDetails: this._getSelectionDetails(),
        });
      },
    });

  };

  componentDidMount() {
    this.props.setCurrentPage(page);
    const id = this.props.match.params.id
    // this.props.getTasksById(id)
  };

  componentDidUpdate(prevProps: IProps, _: any) {
    const { redirectTo: prevRedirectTo } = prevProps.tasks;
    const { redirectTo: nextRedirectTo } = this.props.tasks;

    if (prevRedirectTo !== nextRedirectTo) {
      if (nextRedirectTo) {
        this.props.history.push(nextRedirectTo)
      }
    }
  }

  _onItemInvoked = (item: MembrosType): void => {
    if (item.flagEditarAvaliacao || item.flagVisualizarAvaliacao) {
      const idTarefa = this.props.tasks.selected?.TarefaTime?.idTarefa;
      this.props.history.push(`/tarefas/${idTarefa}/avaliacao/${item.avaliacao?.idCicloAvaliacao}`)
    }
  };

  _getSelectionDetails(): any {
    const selectionCount = this._selection.getSelectedCount();
    this.setState({ selectionCount });
    this._selection.getSelection();
  };

  //#endregion

  render() {
    const { isFiltering, items, columns } = this.state;
    const { tasks, finishTask, match } = this.props;
    const { selected } = tasks;
    const teams = selected?.TarefaTime;
    const idTarefa = selected?.TarefaTime?.idTarefa!;

    return (
      <>
        <Wrapper>
          <PainelHeader
            title="Avaliação do Time"
            icon={{ icon: "Equalizer" }}
            labelGroups={teams?.time.membros?.length !== 0 ? [
              {
                label: "Ciclo",
                value: teams?.time.ciclo?.ano ? String(teams?.time.ciclo?.ano) : '',
                icon: "HomeGroup"
              },
              {
                label: "Líder",
                value: teams?.time.funcionario.nomeCompleto ?? '',
                icon: "Flag"
              },
              {
                label: "Status",
                value: convertStatusTarefa(selected?.statusTarefa ?? ''),
                icon: "Flag",
                size: "full"
              },
            ] : []}
          />
          {
            !selected?.flagFinalizada &&
            <Stack horizontal styles={{ root: { padding: '10px 20px 0px' } }}>
              <CommandBarButton styles={btnStyle} iconProps={{ iconName: 'Send' }} text="Enviar Avaliação" onClick={() => finishTask(idTarefa)} />
            </Stack>
          }
          <ContainerContent>
            <ContainerTitle>
              <Text variant="mediumPlus" styles={textStyles}>Funcionários</Text>
            </ContainerTitle>
            {!tasks.loadingData && teams?.time.membros?.length === 0 ?
              <NoItems
                error={tasks.error}
                text="Não há time cadastrado"
                icon="Teamwork"
              />
              :
              <ListContainer>
                <ScrollablePane>
                  <ShimmeredDetailsList
                    items={isFiltering ? items : teams?.time.membros ?? []}
                    enableShimmer={tasks.loadingData}
                    columns={columns}
                    selectionMode={SelectionMode.none}
                    selection={this._selection}
                    getKey={this._getKey}
                    selectionPreservedOnEmptyClick={true}
                    setKey="single"
                    layoutMode={DetailsListLayoutMode.justified}
                    onItemInvoked={this._onItemInvoked}
                    className="teamTable"
                    onRenderDetailsHeader={
                      detailsHeaderProps => {
                        return (
                          <Sticky>
                            <DetailsHeader
                              {...detailsHeaderProps}
                              layoutMode={DetailsListLayoutMode.justified}
                            />
                          </Sticky>
                        );
                      }
                    }
                  />
                </ScrollablePane>
              </ListContainer>
            }
          </ContainerContent>
        </Wrapper>
      </>
    );
  }

  private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    const { columns } = this.state;
    const newColumns: IColumn[] = columns.slice();
    let items: MembrosType[] = [];
    if (this.state.isFiltering) {
      items = this.state.items;
    } else {
      items = this.props.tasks.selected?.TarefaTime?.time.membros ?? [];
    };
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      };
    });
    const newItems = this._sort(items, currColumn.fieldName!, currColumn.isSortedDescending);
    this.setState({
      columns: newColumns,
      items: newItems,
    });
  };

  private _getKey(item: any, index?: number): any {
    if (item !== undefined)
      return item.key;
  };

  private _sort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
    const key = columnKey as keyof T;
    const itemsSorted = items.sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
    return itemsSorted;
  };

}

const textStyles: ITextStyles = {
  root: {
    fontWeight: 500,
    marginTop: 10,
    display: 'block'
  }
}

const mapStateToProps = (state: RootState) => ({
  // tasks: state.tasksReducer
});

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  ...setCurrentPage,
  ...loadTeam,
  ...getTasksById,
  ...finishTask,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Evaluations));
