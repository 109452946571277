import styled from 'styled-components';

export const Div = styled.div`
    flex-basis: 100%;
    width: calc(100% - 250px);
    height: calc(100vh - 50px);
    display: flex;
    flex-direction: column;
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  z-index: 1;
  overflow: auto;
  padding: 20px;
`;

export const Table = styled.table`
 border-collapse: separate;
  /* display: block;
  overflow: auto; */
  width: 490px;
`

export const TrHead = styled.tr<{ bg?: string, color?: string }>`
  background-color: ${({ bg }) => bg ?? '#FFFFFF'};
  color: ${({ color }) => color ?? '#323130'};
  height: 40px;
`

export const Th = styled.th`

`

export const Tr = styled.tr`
  height: 100px;
`

export const TrSubtitle = styled.tr`
  height: 40px;
  text-align: center;
`

export const TdSubtitle = styled.td<{ bg?: string, color?: string }>`
  background-color: ${({ bg }) => bg ?? '#000000'};
  color: ${({ color }) => color ?? '#FFFFFF'};
  width: 40px;
  min-width: 40px;
  max-width: 40px;
  font-size: 11px;
  height: 100px;
  max-height: 100px;
  font-weight: 600;
  text-align: center;
`
export const TdSubtitleHorizontal = styled.td<{ bg?: string, color?: string }>`
  background-color: ${({ bg }) => bg ?? '#000000'};
  color: ${({ color }) => color ?? '#FFFFFF'};
  font-size: 11px;
  height: 40px;
  max-height: 40px;
  font-weight: 600;
  text-align: center;
  padding: 0 15px;
  box-sizing: border-box;
`

export const NumberDetailBox = styled.span`
  font-size: 11px;
  opacity: 1;
  margin-right: 5px;
`
export const NumberBox = styled.p`
  font-size: 32px;
  font-weight: bold;
`
export const PercentBox = styled.p`
  font-size: 12px;
  font-weight: bold;
`
export const DetailBox = styled.p`
  font-size: 9px;
  opacity: 0.4;
`
export const Box = styled.td<{ bg: string, color?: string }>`
  background-color: ${({ bg }) => bg};
  width: 140px;
  min-width: 140px;
  padding: 7px;
  height: 100px;
  box-sizing: border-box;

  ${NumberDetailBox}{
    color: ${({ color }) => color};
  };

  ${NumberBox}{
    color: ${({ color }) => color};
  };

  ${PercentBox}{
    color: ${({ color }) => color};
  };

  ${DetailBox}{
    color: ${({ color }) => color};
  }

`

