import styled from 'styled-components';

export const Content = styled.div`
    padding: 20px;
`
export const Container = styled.div<{ modalView?: boolean }>`
    flex-basis: 100%;
    width: calc(100% - 250px);
    height: calc(100vh - 50px);
    display: flex;
    flex-direction: column;
    overflow: auto;
`;

export const WrapperResults = styled.div`
    padding-bottom: 20px;
`

export const WrapperForm = styled.div`
    padding: 10px 0 20px;
    box-sizing: border-box;
    width: 100%;
`
export const Divider = styled.div`
margin-top: 5px;
border-bottom: 1px solid #CFD3DA;
`

export const Bold = styled.span`
    font-weight: 500;
`