import {
  Types,
  DataTypes,
  ChartActionTypes,
  ChartType,
  ISuccessChartEditAction,
} from "./types";
import customToast from "~/components/Toast/index";
/**
 * Reducers
 */
const INITIAL_STATE: DataTypes = {
  data: [],
  loadingData: false,
  loadingAction: false,
  success: false,
  error: false,
  itemSelected: {
    item: {
      idArea: null,
      codArea: "",
      nomeLocal: "",
      nivel: "",
      apelido: "",
      tipoLocal: "",
      idAreaPai: null,
      flagAtiva: true,
      areaPai: {
        nomeLocal: "",
      },
      children: [],
    },
    loading: false,
    success: false,
    error: false,
  },
};

/**
 * Actions
 */

export const Creators = {
  getChart: (
    search?: string,
    filter?: boolean | null,
    treeview: boolean = true
  ): ChartActionTypes => {
    const pesquisa = search !== "" && search !== undefined ? search : "";
    const filtro = filter !== null && filter !== undefined ? filter : null;

    return {
      type: Types.REQUEST_LOAD_CHART,
      payload: {
        search: pesquisa,
        treeview,
        filterAtivo: filtro,
      },
    };
  },

  getChartById: (id: number): ChartActionTypes => ({
    type: Types.REQUEST_CHART_LOAD_ID,
    id,
  }),

  addChart: (chart: ChartType): ChartActionTypes => ({
    type: Types.REQUEST_ADD_CHART,
    chart,
  }),

  editChart: (
    chart: ChartType,
    oldIdAreaPai?: number | null
  ): ChartActionTypes => ({
    type: Types.REQUEST_EDIT_CHART,
    payload: {
      chart,
      oldIdAreaPai,
    },
  }),

  delChart: (id: number): ChartActionTypes => ({
    type: Types.REQUEST_DEL_CHART,
    id,
  }),

  clearChartSelected: (): ChartActionTypes => ({
    type: Types.CLEAR_CHART_SELECTED,
  }),
};

export default function chartReducer(
  state = INITIAL_STATE,
  action: ChartActionTypes
): DataTypes {
  switch (action.type) {
    // LOAD
    case Types.REQUEST_LOAD_CHART:
      return { ...state, loadingData: true, error: false, success: false };
    case Types.SUCCESS_LOAD_CHART:
      return {
        ...state,
        loadingData: false,
        error: false,
        success: true,
        data: action.payload,
      };
    case Types.FAILURE_LOAD_CHART:
      customToast.error(action.message);
      return {
        ...state,
        data: [],
        loadingData: false,
        success: false,
        error: true,
      };

    //GET ID
    case Types.REQUEST_CHART_LOAD_ID:
      return {
        ...state,
        error: false,
        itemSelected: {
          ...state.itemSelected,
          loading: true,
          success: false,
          error: false,
        },
      };
    case Types.SUCCESS_CHART_LOAD_ID:
      return {
        ...state,
        error: false,
        itemSelected: {
          loading: false,
          success: true,
          error: false,
          item: action.chart,
        },
      };
    case Types.FAILURE_CHART_LOAD_ID:
      customToast.error(action.message);
      return {
        ...state,
        error: true,
        itemSelected: {
          ...state.itemSelected,
          loading: false,
          success: false,
          error: true,
        },
      };

    // ADD
    case Types.REQUEST_ADD_CHART:
      return { ...state, loadingAction: true, error: false, success: false };
    case Types.SUCCESS_ADD_CHART:
      customToast.success("Área cadastrada com sucesso");
      return {
        ...state,
        loadingAction: false,
        error: false,
        success: true,
        data: addItem(action.chart, state.data),
      };
    case Types.FAILURE_ADD_CHART:
      customToast.error(action.message);
      return { ...state, loadingAction: false, error: true, success: false };

    // EDIT
    case Types.REQUEST_EDIT_CHART:
      return {
        ...state,
        loadingAction: true,
        success: false,
        itemSelected: { ...state.itemSelected, error: false },
      };
    case Types.SUCCESS_EDIT_CHART:
      customToast.success("Área editada com sucesso");
      return {
        ...state,
        loadingAction: false,
        success: true,
        data: editItem(action.payload, state.data),
        itemSelected: { ...state.itemSelected, error: false },
      };
    case Types.FAILURE_EDIT_CHART:
      customToast.error(action.message);
      return {
        ...state,
        loadingAction: false,
        success: false,
        itemSelected: { ...state.itemSelected, error: true },
      };

    // DELETE
    case Types.REQUEST_DEL_CHART:
      return { ...state, loadingAction: true, error: false, success: false };
    case Types.SUCCESS_DEL_CHART:
      customToast.success("Área removida com sucesso");
      return {
        ...state,
        loadingAction: false,
        error: false,
        success: true,
        data: removeItem(action.id, state.data),
      };
    case Types.FAILURE_DEL_CHART:
      customToast.error(action.message);
      return { ...state, loadingAction: false, error: true, success: false };

    case Types.CLEAR_CHART_SELECTED:
      return {
        ...state,
        itemSelected: {
          error: false,
          item: {
            idArea: null,
            codArea: "",
            nomeLocal: "",
            nivel: "",
            apelido: "",
            tipoLocal: "",
            idAreaPai: null,
            flagAtiva: true,
            areaPai: {
              nomeLocal: "",
            },
            children: [],
          },
          loading: false,
          success: false,
        },
      };

    default:
      return state;
  }
}

const addItem = (chart: ChartType, data: ChartType[]): ChartType[] => {
  if (chart.idAreaPai !== 0 && chart.idAreaPai !== null) {
    recursiveAdd(chart, data);
  } else {
    data.push(chart);
  }
  return data;
};

const recursiveAdd = (chart: ChartType, data: ChartType[]): ChartType[] => {
  data.forEach((item) => {
    if (item.idArea === chart.idAreaPai) {
      item.children.push(chart);
    } else {
      if (item.children) {
        recursiveAdd(chart, item.children);
      }
    }
  });

  return data;
};

const editItem = (
  payload: ISuccessChartEditAction["payload"],
  data: ChartType[]
): ChartType[] => {
  return recursiveEdit(payload, data, data);
};

const recursiveEdit = (
  payload: ISuccessChartEditAction["payload"],
  data: ChartType[],
  originalData: ChartType[]
): ChartType[] => {
  const changeIdPai =
    !!payload.oldIdAreaPai && payload.chart.idAreaPai !== payload.oldIdAreaPai;

  let chart: ChartType | undefined = undefined;

  data.forEach((item, i) => {
    if (item.idArea === payload.chart.idArea) {
      if (!changeIdPai) {
        data[i] = { ...payload.chart, children: item.children };
      } else {
        chart = { ...payload.chart, children: item.children };
        data.splice(i, 1);
      }
    } else {
      if (item.children.length > 0) {
        return recursiveEdit(payload, item.children, originalData);
      }
    }
  });

  if (chart) {
    data = recursiveEditAndMove(originalData, chart);
  }

  return data;
};

const recursiveEditAndMove = (data: ChartType[], chart: ChartType) => {
  try {
    data.forEach((item, i) => {
      if (item.idArea === chart.idAreaPai) {
        return data[i].children.push(chart);
      } else {
        if (item.children.length > 0) {
          return recursiveEditAndMove(item.children, chart);
        }
      }
    });
  } catch (error) {
    console.log(error);
  }
  return data;
};

const removeItem = (id: number, data: ChartType[]): ChartType[] => {
  return recusiveRemove(id, data);
};

const recusiveRemove = (id: number, data: ChartType[]): ChartType[] => {
  data.forEach((item, i) => {
    if (item.idArea === id) {
      data.splice(i, 1);
      return;
    } else {
      if (item.children.length > 0) {
        recusiveRemove(id, item.children);
      }
    }
  });
  return data;
};
