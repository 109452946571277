// #region Imports
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter, RouteComponentProps } from "react-router-dom";

//FluentUI
import { Text, ITextStyles } from "office-ui-fabric-react/lib/Text";
import { Stack } from "office-ui-fabric-react/lib/Stack";
import {
  DetailsListLayoutMode,
  SelectionMode,
  Selection,
  IColumn,
  IDetailsListProps,
  IDetailsRowStyles,
  DetailsRow,
} from "office-ui-fabric-react/lib/DetailsList";
import { ShimmeredDetailsList } from "office-ui-fabric-react/lib/ShimmeredDetailsList";
import {
  CommandBarButton,
  IconButton,
  ICommandBarStyles,
  DetailsHeader,
  PersonaSize,
  Icon,
} from "office-ui-fabric-react";
//Componentes
import PainelHeader from "~/components/layout/PainelHeader";
import { Page, BreadcrumbItems } from "~/store/ducks/home/types";
import NoItems from "~/components/layout/NoItems";
//Estilos
import { Wrapper, ListContainer, ContainerContent } from "./styles";

import { Creators as setCurrentPage } from "~/store/ducks/home";
import { Creators as loadTeam } from "~/store/ducks/teamEvaluation";
import { Creators as getTasksById } from "~/store/ducks/tasks";
import { Creators as finaliFbzaTarefaColetiva } from "~/store/ducks/tasks";
import { Creators as editAvaliacaoColetivaResultadoMeta } from "~/store/ducks/tasks";
import { Creators as editAvaliacaoColetivaResultadoAtitude } from "~/store/ducks/tasks";
import { Creators as editAvaliacaoColetivaResultadoFinal } from "~/store/ducks/tasks";
import {
  DataTypes as DataTypesTasks,
  TaskFeedbackColetivoFuncionario,
} from "~/store/ducks/tasks/types";
import { RootState } from "~/store/ducks";
import { convertStatusTarefa } from "~/utils/tasks";
import Accordion from "~/components/Accordion";
import { Persona } from "@fluentui/react";
import { getAbbreviatedName } from "~/utils/abbreviatedName";
import Colors from "~/assets/js/colors";
import { getPresence } from "~/utils/statusFunc";
import { renderAvaliacao } from "~/pages/SelfEvaluationCollective/selfEvaluationCollectiveRender";
import { OutlinedButton } from "~/components/Buttons";
//#endregion
//#region Types e Interfaces

const btnStyle: Partial<ICommandBarStyles> = {
  root: {
    height: 44,
  },
};

type Filter = {
  isOpen?: boolean;
  filteredBy?: string[];
  departamento?: any;
};

interface FeedbackICollectiveState {
  columns: IColumn[];
  items: TaskFeedbackColetivoFuncionario[];
  isFiltering: boolean;
  selectionDetails: string;
  selectionCount: number;
  filter: Filter;
}

interface IFeedbackEvaluationProps extends RouteComponentProps {
  setCurrentPage: (page: Page) => void;
  loadTeam: (idCiclo: number) => void;
  finalizaTarefaColetiva: (idTarefa: number) => void;
  history: RouteComponentProps["history"];
  getTasksById: (idTarefa: number) => void;
  editAvaliacaoColetivaResultadoMeta: (
    idTarefa: number,
    idTarefaAvaliacao: number,
    resultadometas: string,
    comentariosMetas: string
  ) => void;
  editAvaliacaoColetivaResultadoAtitude: (
    idTarefa: number,
    idTarefaAvaliacao: number,
    resultadoatitudes: string,
    comentariosAtitudes: string
  ) => void;
  editAvaliacaoColetivaResultadoFinal: (
    idTarefa: number,
    idTarefaAvaliacao: number,
    idTabPerformance: number | null,
    potencial: string,
    sucessao: string | null,
    comentariosGerais: string
  ) => void;
  match: any;
  taskState: DataTypesTasks;
}
//#endregion

const itemsBreadCrumb: BreadcrumbItems[] = [
  {
    text: "Home",
    isCurrentItem: false,
    icon: "HomeSolid",
    onlyIcon: true,
    url: "/",
  },
  { text: "Workflow", isCurrentItem: false, url: "/workflow" },
  { text: "Feedback Coletivo", isCurrentItem: true },
];

const page: Page = {
  key: "timeavaliacao",
  pages: itemsBreadCrumb,
};

const baseURL = process.env.REACT_APP_BASE_URL;

class FeedbackCollective extends Component<
  IFeedbackEvaluationProps,
  FeedbackICollectiveState
> {
  private _selection: Selection;

  constructor(props: IFeedbackEvaluationProps) {
    super(props);
    //#region Colunas
    //#endregion

    this.state = {
      columns: [],
      items: [],
      selectionDetails: "",
      isFiltering: false,
      selectionCount: 0,
      filter: {
        isOpen: false,
        filteredBy: [],
        departamento: {},
      },
    };

    this._selection = new Selection({
      onSelectionChanged: () => {
        this.setState({
          selectionDetails: this._getSelectionDetails(),
        });
      },
    });
  }

  allColumns = (): IColumn[] => [
    {
      key: "avatar",
      name: "",
      ariaLabel: "Avatar",
      fieldName: "nomeCompleto",
      minWidth: 30,
      maxWidth: 30,
      isSortedDescending: false,
      styles: {
        root: {
          position: "sticky",
          left: 0,
          top: 50,
          backgroundColor: "#FFFFFF",
          zIndex: 9,
        },
      },
      onRender: (item: TaskFeedbackColetivoFuncionario) => (
        <Persona
          imageUrl={`${baseURL}uploads/${item.avaliacao.funcionario.imagem}`}
          imageInitials={getAbbreviatedName(
            item.avaliacao.funcionario.nomeCompleto
          )}
          initialsColor={Colors.primary}
          size={PersonaSize.size32}
          presence={getPresence(item.avaliacao.funcionario.status)}
          styles={{
            root: {
              width: 32,
              marginRight: 10,
              selectors: {
                "& .ms-Persona-initials": {
                  filter:
                    item.avaliacao.funcionario.status === "D"
                      ? "grayscale(1)"
                      : "grayscale(0)",
                },
              },
            },
          }}
        />
      ),
    },
    {
      key: "idFuncionario",
      name: "ID",
      ariaLabel: "Id funcionário",
      fieldName: "avaliacao.idFuncionario",
      isRowHeader: true,
      minWidth: 50,
      maxWidth: 70,
      isResizable: true,
      isSortedDescending: false,
      styles: {
        root: {
          position: "sticky",
          left: 50,
          top: 50,
          backgroundColor: "#FFFFFF",
          zIndex: 9,
        },
      },
      onColumnClick: this._onColumnClick,
      onRender: (item: TaskFeedbackColetivoFuncionario) =>
        item.avaliacao.idFuncionario,
    },
    {
      key: "nomeCompleto",
      name: "Nome",
      fieldName: "avaliacao.funcionario.nomeCompleto",
      minWidth: 210,
      isRowHeader: true,
      isResizable: true,
      isSortedDescending: false,
      sortAscendingAriaLabel: "Sorted A to Z",
      sortDescendingAriaLabel: "Sorted Z to A",
      onColumnClick: this._onColumnClick,
      data: "string",
      styles: {
        root: {
          position: "sticky",
          left: 120,
          top: 50,
          backgroundColor: "#FFFFFF",
          zIndex: 9,
        },
      },
      onRender: (item: TaskFeedbackColetivoFuncionario) =>
        item.avaliacao.funcionario.nomeCompleto,
    },
    {
      key: "area",
      name: "Área",
      fieldName: "area.nomeLocal",
      minWidth: 200,
      maxWidth: 200,
      isRowHeader: true,
      isResizable: true,
      isSortedDescending: false,
      sortAscendingAriaLabel: "Sorted A to Z",
      sortDescendingAriaLabel: "Sorted Z to A",
      onColumnClick: this._onColumnClick,
      data: "string",
      onRender: (item: TaskFeedbackColetivoFuncionario) => (
        <Text style={{ whiteSpace: "break-spaces" }}>
          {item.area?.nomeLocal}
        </Text>
      ),
    },
    {
      key: "cargo",
      name: "Cargo",
      fieldName: "cargo.titulo",
      minWidth: 250,
      maxWidth: 250,
      isRowHeader: true,
      isResizable: true,
      isSortedDescending: false,
      sortAscendingAriaLabel: "Sorted A to Z",
      sortDescendingAriaLabel: "Sorted Z to A",
      onColumnClick: this._onColumnClick,
      data: "string",
      onRender: (item: TaskFeedbackColetivoFuncionario) => (
        <Text style={{ whiteSpace: "break-spaces" }}>{item.cargo?.titulo}</Text>
      ),
    },
    {
      key: "status",
      name: "Feedback",
      fieldName: "avaliacao.statusAvaliacao",
      minWidth: 160,
      maxWidth: 160,
      isRowHeader: true,
      isResizable: true,
      isSortedDescending: false,
      sortAscendingAriaLabel: "Sorted A to Z",
      sortDescendingAriaLabel: "Sorted Z to A",
      onColumnClick: this._onColumnClick,
      data: "string",
      onRender: (item: TaskFeedbackColetivoFuncionario) => {
        const idTarefa = this.props.taskState.selected?.idTarefa;
        if (item.avaliacao.statusAvaliacao === "Finalizada") {
          return (
            <IconButton
              iconProps={{ iconName: "View" }}
              title="Visualizar Feedback"
              onClick={() =>
                this.props.history.push(
                  `/tarefas/${idTarefa}/feedback/${item.idTarefaFeedback}`
                )
              }
            />
          );
        }
        return (
          <OutlinedButton
            text="Realizar Feedback"
            onClick={() =>
              this.props.history.push(
                `/tarefas/${idTarefa}/feedback/${item.idTarefaFeedback}`
              )
            }
          />
        );
      },
    },
  ];

  componentDidMount() {
    this.props.setCurrentPage(page);
    this.setState({ columns: this.allColumns() });
  }

  componentDidUpdate(prevProps: IFeedbackEvaluationProps, _: any) {
    if (
      prevProps.taskState.selected?.flagEdicao !==
      this.props.taskState.selected?.flagEdicao
    ) {
      this.setState({ columns: this.allColumns() });
    }

    if(prevProps.taskState.redirectTo !== this.props.taskState.redirectTo){
      if(!!this.props.taskState.redirectTo){
        this.props.history.push(this.props.taskState.redirectTo)
      }
    }
  }

  _getSelectionDetails(): any {
    const selectionCount = this._selection.getSelectedCount();
    this.setState({ selectionCount });
    this._selection.getSelection();
  }

  //#endregion

  render() {
    const { isFiltering, items, columns } = this.state;
    const { taskState, match, finalizaTarefaColetiva } = this.props;
    const { selected } = taskState;
    const tarefaFbColetiva = selected?.TarefaFeedbackColetivo;
    const idTarefa = selected?.idTarefa!;

    return (
      <>
        <Wrapper>
          <PainelHeader
            title={selected?.tituloTarefa ?? "FEEDBACK COLETIVO"}
            icon={{ icon: "Equalizer" }}
            labelGroups={[
              // {
              //   label: "Ciclo",
              //   value: tarefaFbColetiva?.time.ciclo?.ano ? String(teams?.time.ciclo?.ano) : '',
              //   icon: "HomeGroup"
              // },
              {
                label: "Líder",
                value: selected?.funcionario?.nomeCompleto ?? "",
                icon: "Flag",
              },
              {
                label: "Status",
                value: convertStatusTarefa(selected?.statusTarefa ?? ""),
                icon: "Flag",
                size: "full",
              },
            ]}
          />
          {!selected?.flagFinalizada && (
            <Stack horizontal styles={{ root: { padding: "10px 20px 0px" } }}>
              <CommandBarButton
                styles={btnStyle}
                iconProps={{ iconName: "Send" }}
                text="Finalizar Avaliação"
                onClick={() => finalizaTarefaColetiva(idTarefa)}
              />
            </Stack>
          )}
          <ContainerContent>
            <Accordion title="Funcionários">
              {!taskState.loadingData &&
              tarefaFbColetiva?.funcionarios.length === 0 ? (
                <NoItems
                  error={taskState.error}
                  text="Não há funcionários para avaliar"
                  icon="Teamwork"
                />
              ) : (

                                  <ShimmeredDetailsList
                                  //items={tarefaFbColetiva?.funcionarios ?? []}
                                  items={
                                    isFiltering ? items : tarefaFbColetiva?.funcionarios ?? []
                                  }
                                  enableShimmer={taskState.loadingData}
                                  columns={columns}
                                  selectionMode={SelectionMode.none}
                                  selection={this._selection}
                                  getKey={this._getKey}
                                  selectionPreservedOnEmptyClick={true}
                                  setKey="single"
                                  layoutMode={DetailsListLayoutMode.justified}
                                  onRenderRow={this._onRenderRow}
                                  className="teamTable"
                                />
              )}
            </Accordion>
          </ContainerContent>
        </Wrapper>
      </>
    );
  }

  private _onColumnClick = (
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn
  ): void => {
    const { columns } = this.state;
    const newColumns: IColumn[] = columns.slice();
    let items: TaskFeedbackColetivoFuncionario[] = [];
    if (this.state.isFiltering) {
      items = this.state.items;
    } else {
      items =
        this.props.taskState.selected?.TarefaFeedbackColetivo?.funcionarios ??
        [];
    }
    const currColumn: IColumn = newColumns.filter(
      (currCol) => column.key === currCol.key
    )[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    const newItems = this._sort(
      items,
      currColumn.fieldName!,
      currColumn.isSortedDescending
    );
    this.setState({
      columns: newColumns,
      items: newItems,
    });
  };

  private _getKey(item: any, index?: number): any {
    if (item !== undefined) return item.key;
  }

  private _sort<T>(
    items: T[],
    columnKey: string,
    isSortedDescending?: boolean
  ): T[] {
    const result = (prop: string, obj: T) =>
      prop.split(".").reduce((a: any, b: any) => a[b] ?? "", obj);
    return items.sort((a: T, b: T) =>
      (
        isSortedDescending
          ? result(columnKey, a) < result(columnKey, b)
          : result(columnKey, a) > result(columnKey, b)
      )
        ? 1
        : -1
    );
  }

  private _onRenderRow: IDetailsListProps["onRenderRow"] = (props) => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (props) {
      const stylesForRow = (detailsRowStyleProps: any) => {
        customStyles.checkCell = {
          position: "sticky",
          left: 0,
          zIndex: 9,
          backgroundColor: detailsRowStyleProps.isSelected
            ? "#EDEBE9"
            : "#FFFFFF",
          marginTop: 50,
          display: "flex",
          alignItems: "center",
        };
        customStyles.root = {
          selectors: {
            // "& .ms-DetailsRow-fields": {
            //   opacity: props.item.funcionario.isVisible ? 1 : 0.5,
            // },
            "&:hover div.ms-DetailsRow-fields div.ms-DetailsRow-cell": {
              backgroundColor: "#F3F2F1",
            },
          },
        };
        customStyles.cell = {
          display: "flex",
          alignItems: "center",
          selectors: {
            "&:nth-child(1)": {
              position: "sticky",
              zIndex: 9,
              left: 0,
              backgroundColor: detailsRowStyleProps.isSelected
                ? "#EDEBE9"
                : "#FFFFFF",
            },
            "&:nth-child(2)": {
              position: "sticky",
              zIndex: 9,
              left: 50,
              backgroundColor: detailsRowStyleProps.isSelected
                ? "#EDEBE9"
                : "#FFFFFF",
            },
            "&:nth-child(3)": {
              position: "sticky",
              zIndex: 9,
              left: 120,
              backgroundColor: detailsRowStyleProps.isSelected
                ? "#EDEBE9"
                : "#FFFFFF",
            },
            "&:nth-child(7) div, &:nth-child(8) div, &:nth-child(9) div, &:nth-child(10) div, &:nth-child(11) div, &:nth-child(12) div": {
              fontWeight: detailsRowStyleProps.isSelected
                ? "inherit"
                : "normal",
            },
          },
        };
        return customStyles;
      };
      return (
        <DetailsRow
          {...props}
          styles={stylesForRow}
          itemIndex={props.itemIndex}
        />
      );
    }
    return null;
  };
}

const mapStateToProps = (state: RootState) => ({
  // tasks: state.tasksReducer
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      ...setCurrentPage,
      ...loadTeam,
      ...getTasksById,
      ...finaliFbzaTarefaColetiva,
      ...editAvaliacaoColetivaResultadoMeta,
      ...editAvaliacaoColetivaResultadoAtitude,
      ...editAvaliacaoColetivaResultadoFinal,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FeedbackCollective)
);
