import { takeEvery, put, call, all } from "redux-saga/effects";
import {
  Types,
  StandardPositionLoadDTO,
  StandardPositionAddOrEditDTO,
  IRequestAddStandardsPositionsAction,
  IRequestEditStandardsPositionsAction,
  IRequestDelStandardsPositionsAction,
  ISuccessGetStandardsPositionsAction,
  ISuccessAddStandardsPositionsAction,
  ISuccessEditStandardsPositionsAction,
  ISuccessDelStandardsPositionsAction,
} from "../../ducks/admin/standardPosition/types";
import api from "~/services/api";

const apiCallGet = () => {
  return api
    .get("admin/padroesCargos")
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

// const apiCallGetById = (idPadraoCargo: number) => {
//   return api
//     .get(`admin/padroesCargos/${idPadraoCargo}`)
//     .then((response) => response.data)
//     .catch((err) => {
//       throw err.response;
//     });
// };

const apiCallAdd = (item: IRequestAddStandardsPositionsAction["payload"]) => {
  return api
    .post("admin/padroesCargos", {
      ...item,
      flagAtivo: true,
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallEdit = (
  item: IRequestEditStandardsPositionsAction["payload"]
) => {
  return api
    .put(`admin/padroesCargos/${item.idPadraoCargo}`, item)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallDel = (idPadraoCargo: number) => {
  return api
    .delete(`admin/padroesCargos/${idPadraoCargo}`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

function* load() {
  try {
    const items: StandardPositionLoadDTO[] = yield call(apiCallGet);
    yield put({
      type: Types.SUCCESS_LOAD_STANDARDS_POSITIONS,
      payload: items,
    } as ISuccessGetStandardsPositionsAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível carregar os padrões de cargos";
    yield put({ type: Types.FAILURE_LOAD_STANDARDS_POSITIONS, message });
  }
}

// function* loadById(action: IRequestGetByIdCategoriesPositionsAction) {
//   try {
//     const item: CategoryPositionType = yield call(
//       apiCallGetById,
//       action.idPadraoCargo
//     );
//     yield put({
//       type: Types.SUCCESS_LOAD_CATEGORY_POSITION_BY_ID,
//       payload: item,
//     } as ISuccessGetByIdCategoriesPositionsAction);
//   } catch (error) {
//     const message =
//       error !== undefined
//         ? error.data.message
//         : "Não foi possível carregar a categoria de cargo";
//     yield put({ type: Types.FAILURE_LOAD_CATEGORY_POSITION_BY_ID, message });
//   }
// }

function* add(action: IRequestAddStandardsPositionsAction) {
  try {
    const data: { idPadraoCargo: number } = yield call(
      apiCallAdd,
      action.payload
    );
    yield put({
      type: Types.SUCCESS_ADD_STANDARD_POSITION,
      payload: {
        idPadraoCargo: data.idPadraoCargo,
        descPadraoCargo: action.payload.descPadraoCargo,
      },
    } as ISuccessAddStandardsPositionsAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível adicionar o padrão de cargo";
    yield put({ type: Types.FAILURE_ADD_STANDARD_POSITION, message });
  }
}

function* edit(action: IRequestEditStandardsPositionsAction) {
  try {
    yield call(apiCallEdit, action.payload);
    yield put({
      type: Types.SUCCESS_EDIT_STANDARD_POSITION,
      payload: action.payload,
    } as ISuccessEditStandardsPositionsAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível editar o padrão de cargo";
    yield put({ type: Types.FAILURE_EDIT_STANDARD_POSITION, message });
  }
}

function* del(action: IRequestDelStandardsPositionsAction) {
  try {
    yield call(apiCallDel, action.idPadraoCargo);
    yield put({
      type: Types.SUCCESS_DEL_STANDARD_POSITION,
      idPadraoCargo: action.idPadraoCargo,
    } as ISuccessDelStandardsPositionsAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível excluír o padrão de cargo";
    yield put({ type: Types.FAILURE_DEL_STANDARD_POSITION, message });
  }
}

export default function* standardPositionRoot() {
  yield all([
    yield takeEvery(Types.REQUEST_LOAD_STANDARDS_POSITIONS, load),
    // yield takeEvery(Types.REQUEST_LOAD_CATEGORY_POSITION_BY_ID, loadById),
    yield takeEvery(Types.REQUEST_ADD_STANDARD_POSITION, add),
    yield takeEvery(Types.REQUEST_EDIT_STANDARD_POSITION, edit),
    yield takeEvery(Types.REQUEST_DEL_STANDARD_POSITION, del),
  ]);
}