import axios from "axios";

const baseURLRefresh = process.env.REACT_APP_BASE_URL;

export async function refreshToken(error: any) {
  const { baseURL, data, headers, method, url } = error.config;

  return new Promise((resolve, reject) => {
    try {
      const header = {
        "Content-Type": "application/json",
        "Accept-Language": "pt-BR",
      };
      const parameters = {
        headers: header,
      };
      const body = {
        tk: localStorage.getItem("user/refresh-token"),
      };
      axios
        .post(`${baseURLRefresh}login/refresh-token`, body, parameters)
        .then(async (res: any) => {
          localStorage.setItem("user/token", res.data.token);
          const lastRequestMethod = error.config.method;
          const lastRequestUrl = error.config.url;
          const lastRequestBody = error.config.data;
          const lastParams = error.config.params;
          const lastRequestHeaders = {
            "Access-Control-Allow-Origin": "*",
            "Accept-Language": "pt-BR",
            "Content-Type": "application/json",
            tk: res.data.token,
          };
          let response = {};
          const parameters = {
            lastRequestMethod,
            params: lastParams,
            headers: lastRequestHeaders,
          };

          try {
            switch (lastRequestMethod) {
              case "get":
                response = await axios.get(
                  baseURL + lastRequestUrl,
                  parameters
                );
                break;
              case "post":
                response = await axios.post(
                  baseURL + lastRequestUrl,
                  lastRequestBody,
                  parameters
                );
                break;
              case "put":
                response = await axios.put(
                  baseURL + lastRequestUrl,
                  lastRequestBody,
                  parameters
                );
                break;
              case "delete":
                response = await axios.delete(
                  baseURL + lastRequestUrl,
                  parameters
                );
                break;
              default: {
                return null;
              }
            }
            return resolve(response);
          } catch (error) {
            return reject(error);
          }
        })
        .catch((err: any) => {
          localStorage.clear();
          window.location.href = "/";
          return reject(err);
        });
    } catch (err) {
      localStorage.clear();
      window.location.href = "/";
      return reject(err);
    }
  });
}

const errorHandler = async (error: any) => {
  const errorStatus = error.response.status;
  const { baseURL, data, headers, method, url } = error.config;
  // console.log({ baseURL, data, headers, method, url, errorStatus });

  if (error.code === "ECONNABORTED") {
    throw errorMsgConectServidor;
  }
  if (error.message === "Network Error") {
    throw errorMsgConectServidor;
  }
  switch (errorStatus) {
    case 404:
      throw errorMsg(error.response.data?.message);
    case 500:
      throw errorMsgServidor;
    default:
      break;
  }
  throw error;
};

const errorMsgConectServidor = {
  response: {
    data: {
      message:
        "Não foi possível conectar com o servidor. Tente de novo mais tarde.",
    },
  },
};

const errorMsg = (message: string) => ({
  response: {
    data: {
      message: message ?? "Recurso não encontrado.",
    },
    statusCode: 404,
  },
});

const errorMsgServidor = {
  response: {
    data: {
      message: "Ocorreu um erro no servidor. Tente novamente mais tarde",
    },
  },
};

export default errorHandler;
