import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'

import { ICommandBarStyles, Stack, Text } from 'office-ui-fabric-react';
import {
    DetailsListLayoutMode,
    SelectionMode,
    Selection,
    IColumn,
} from 'office-ui-fabric-react/lib/DetailsList';
import { ShimmeredDetailsList } from 'office-ui-fabric-react/lib/ShimmeredDetailsList';
import { InputAutocomplete } from '~/components/Forms';
import { Creators as getEmployees } from '~/store/ducks/employees';
import { DataTypes as DataTypesCycle } from "~/store/ducks/cycle/types";
import { Creators as pesquisaFunc } from "~/store/ducks/cycle";
import { EmployeeType, DataTypes as DataTypesEmployees } from '~/store/ducks/employees/types';
import { Creators as getCycleTeams } from '~/store/ducks/cycle/teams';
import { Creators as transferMemberTeam } from '~/store/ducks/cycle/teams';
import { CycleTeamType, DataTypes as DataTypesTeams } from '~/store/ducks/cycle/teams/types';
import { RootState } from "~/store/ducks";
import { CommandBarButton, PrimaryButton } from '~/components/Buttons';
import NoItems from '~/components/layout/NoItems';

import { Container, ListContainer } from './styles';
import FormSearch from '~/pages/Employees/formSearch';

interface ICycleTeamsState {
    columns: IColumn[];
    items: any[];
    selectionDetails: string;
    selectionCount: number;
    isDialogOpen: boolean;
    lider: Partial<EmployeeType>;
    liderado: Partial<EmployeeType>;
}

interface ICycleTeamsProps {
    idCycle: number;
    employeeState: DataTypesEmployees;
    cycleTeams: DataTypesTeams;
    cycleState: DataTypesCycle;
    getEmployees: (search?: string, filter?: boolean | null) => void;
    transferMemberTeam: (idCiclo: number, idFuncionario: number, liderNome: string, membros: Partial<CycleTeamType>[]) => void;
    getCycleTeams: (idCiclo: number, idLider?: number | null, idLiderado?: number | null) => void;
    pesquisaFunc: (idCiclo: number, nomePesq?: string, idArea?: number | null, worklevel?: number | null, idLider?: number | null) => void;
}

class CycleTeams extends Component<ICycleTeamsProps, ICycleTeamsState> {
    private timeout: number;
    private _selection: Selection;

    constructor(props: ICycleTeamsProps) {
        super(props);

        const columns: IColumn[] = [
            {
                key: 'column1',
                name: 'Matrícula',
                ariaLabel: 'Matrícula',
                fieldName: 'funcionario.matricula',
                isRowHeader: true,
                minWidth: 75,
                maxWidth: 100,
                isResizable: true,
                isSortedDescending: false,
                onColumnClick: this._onColumnClick,
                onRender: (value: CycleTeamType) => value.funcionario.matricula
            },
            {
                key: 'column2',
                name: 'Nome',
                fieldName: 'funcionario.nomeCompleto',
                minWidth: 210,
                maxWidth: 350,
                isRowHeader: true,
                isResizable: true,
                isSortedDescending: false,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                onColumnClick: this._onColumnClick,
                data: 'string',
                isPadded: true,
                onRender: (value: CycleTeamType) => value.funcionario.nomeCompleto
            },
            {
                key: 'column3',
                name: 'Líder',
                fieldName: 'time.funcionario.nomeCompleto',
                minWidth: 210,
                maxWidth: 350,
                isRowHeader: true,
                isResizable: true,
                isSortedDescending: false,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                onColumnClick: this._onColumnClick,
                data: 'string',
                isPadded: true,
                onRender: (value: CycleTeamType) => value.time.funcionario.nomeCompleto
            }
        ];
        this.state = {
            columns: columns,
            selectionDetails: '',
            selectionCount: 0,
            isDialogOpen: false,
            items: [],
            lider: {
                idFuncionario: null,
                nomeCompleto: ''
            },
            liderado: {
                idFuncionario: null,
                nomeCompleto: ''
            }
        }

        this.timeout = 0;
        this._selection = new Selection({
            onSelectionChanged: () => {
                this.setState({
                    selectionDetails: this._getSelectionDetails(),
                });
            },
        });
    }

    componentDidUpdate(prevProps: ICycleTeamsProps, _: any) {
        if (prevProps.cycleTeams.success !== this.props.cycleTeams.success) {
            if (this.props.cycleTeams.success) {
                this.setState({ isDialogOpen: false });
                this._selection.setAllSelected(false);
            }
        }
    }


    search = (text: string) => {
        if (text.trim()) {
            clearTimeout(this.timeout);
            this.timeout = window.setTimeout(() => {
                this.props.getEmployees(text);
            }, 700);
        };
    };

    searchTeams = () => {
        const { lider, liderado } = this.state;
        if (lider?.idFuncionario || liderado?.idFuncionario) {
            this.props.getCycleTeams(this.props.idCycle, lider?.idFuncionario, liderado?.idFuncionario)
        }
    }

    _onItemInvoked = (): void => {

    };

    _getSelectionDetails(): any {
        const selectionCount = this._selection.getSelectedCount();
        this.setState({ selectionCount });
        this._selection.getSelection();
    };

    commandBarRender = () => {
        const { selectionCount } = this.state;
        if (selectionCount > 0) {
            return (
                <>
                    <CommandBarButton styles={btnStyle} className='transferirIcon' iconProps={{ iconName: 'Sort' }} text="Transferir" onClick={() => this.setState({ isDialogOpen: true })} />
                    {/* <CommandBarButton styles={btnStyle} iconProps={{ iconName: 'Delete' }} text="Excluir" onClick={() => this.setState({ isDialogExcludeOpen: true })} /> */}
                </>
            );
        }
    };

    render() {
        const { lider, liderado, columns, isDialogOpen } = this.state;
        const { employeeState, cycleTeams, idCycle, transferMemberTeam, cycleState, pesquisaFunc } = this.props;

        return (
            <Container>
                <Stack horizontal styles={{ root: { borderBottom: '1px solid #CFD3DA', paddingBottom: 30 } }}>
                    <Stack>
                        <Stack horizontal verticalAlign="center">
                            <Text variant="medium" styles={{ root: { marginRight: 44 } }}>Lider</Text>
                            <InputAutocomplete
                                value={(lider as EmployeeType)}
                                onChange={(_, newValue) => {
                                    this.setState({ lider: newValue! })
                                }}
                                onInputChange={(_, newInputValue) => {
                                    this.search(newInputValue);
                                }}
                                getOptionLabel={(employee: EmployeeType) => {
                                    if (employee.nomeCompleto) {
                                        return employee.nomeCompleto;
                                    } else {
                                        return "";
                                    };
                                }}

                                getOptionSelected={(option, value) => {
                                    return option.idFuncionario === value.idFuncionario;
                                }}
                                options={employeeState.data}
                                style={{ width: '100%', height: 32 }}
                                input={{
                                    idInput: "lider",
                                    labelInput: "",
                                    autoFocus: true,
                                    variant: "outlined",
                                    styles: { height: 32, width: 360 },
                                    smaller: "small",
                                }}
                            />
                        </Stack>
                        <Stack horizontal verticalAlign="center" styles={{ root: { marginTop: '10px !important' } }}>
                            <Text variant="medium" styles={{ root: { marginRight: 20 } }}>Liderado</Text>
                            <InputAutocomplete
                                value={(liderado as EmployeeType)}
                                onChange={(_, newValue) => {
                                    this.setState({ liderado: newValue! })
                                }}
                                onInputChange={(_, newInputValue) => {
                                    this.search(newInputValue);
                                }}
                                getOptionLabel={(employee: EmployeeType) => {
                                    if (employee.nomeCompleto) {
                                        return employee.nomeCompleto;
                                    } else {
                                        return "";
                                    };
                                }}
                                getOptionSelected={(option, value) => {
                                    return option.idFuncionario === value.idFuncionario;
                                }}
                                options={employeeState.data}
                                style={{ width: '100%', height: 32 }}
                                input={{
                                    idInput: "lider",
                                    labelInput: "",
                                    variant: "outlined",
                                    styles: { height: 32, width: 360 },
                                    smaller: "small",
                                }}
                            />
                        </Stack>
                    </Stack>
                    <Stack styles={{ root: { marginLeft: '35px !important' } }} verticalAlign="end">
                        <PrimaryButton text="Pesquisar" onClick={this.searchTeams} />
                    </Stack>
                </Stack>
                <Stack horizontal horizontalAlign="space-between" verticalAlign="center" styles={{ root: { height: 44 } }}>
                    <Stack>
                        {/* <CommandBarButton
                            styles={btnStyle}
                            iconProps={{ iconName: 'Add' }}
                            disabled={cycleTeams.loadingData}
                            text="Adicionar Funcionário"
                            onClick={() => this.setState(
                                {
                                    isPanelOpen: true,
                                    inicialValues: initialPosition
                                }
                            )}
                        />  */}
                        {this.commandBarRender()}
                    </Stack>
                    <Stack>
                        {/* <CommandBarButton
                            styles={btnStyle}
                            iconProps={{ iconName: 'Refresh' }}
                            text="Atualizar"
                            onClick={() => getCycleTeams(idCycle, lider?.idFuncionario, liderado?.idFuncionario)}
                        /> */}
                    </Stack>
                </Stack>
                {!cycleTeams.loadingData && cycleTeams.data.length === 0 ?
                    <NoItems
                        error={cycleTeams.error}
                        text="Não há times"
                        icon="Group"
                    />
                    :
                    <ListContainer>
                        <ShimmeredDetailsList
                            items={cycleTeams.data}
                            enableShimmer={cycleTeams.loadingData}
                            columns={columns}
                            selectionMode={SelectionMode.multiple}
                            selection={this._selection}
                            getKey={this._getKey}
                            selectionPreservedOnEmptyClick={true}
                            setKey="single"
                            layoutMode={DetailsListLayoutMode.justified}
                            isHeaderVisible={true}
                            onItemInvoked={this._onItemInvoked}
                        />
                    </ListContainer>
                }

                <FormSearch
                    isOpen={isDialogOpen}
                    items={cycleState.pesquisaFunc}
                    selectionMode={SelectionMode.single}
                    loading={cycleState.loading}
                    onClose={() => this.setState({ isDialogOpen: false })}
                    onAdd={(values: any) => {
                        transferMemberTeam(idCycle!, values[0].idFuncionario, values[0].nomeCompleto, (this._selection.getSelection() as CycleTeamType[]))
                    }}
                    searchAPI={(nomeSearch?: string, idArea?: number | null, idWorkLevel?: number | null, idLider?: number | null) =>
                        pesquisaFunc(idCycle!, nomeSearch, idArea, idWorkLevel ? Number(idWorkLevel) : null, idLider ?? null)
                    }
                />
            </Container>
        )
    }


    private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const { columns } = this.state;
        const newColumns: IColumn[] = columns.slice();
        let items: CycleTeamType[] = this.props.cycleTeams.data;
        const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            };
        });
        const newItems = this._sort(items, currColumn.fieldName!, currColumn.isSortedDescending);
        this.setState({
            columns: newColumns,
            items: newItems,
        });
    };

    private _getKey(item: any, index?: number): any {
        if (item !== undefined)
            return item.key;
    };

    private _sort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
        const result = (prop: string, obj: T) => (prop.split('.').reduce((a: any, b: any) => a[b] ?? '', obj))
        return items.sort((a: T, b: T) => ((isSortedDescending ? result(columnKey, a) < result(columnKey, b) : result(columnKey, a) > result(columnKey, b)) ? 1 : -1));
    };

}

const btnStyle: Partial<ICommandBarStyles> = {
    root: {
        height: 44
    }
}

const mapStateToProps = (state: RootState) => ({
    employeeState: state.employeesReducer,
    cycleTeams: state.cycleTeamsReducer,
    cycleState: state.cycleReducer
});

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    ...getEmployees,
    ...transferMemberTeam,
    ...pesquisaFunc,
    ...getCycleTeams
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(CycleTeams);