/**
 * Types
 */
export enum Types {
  REQUEST_LOAD_MOTIVOS_FALTAS = "motivosFaltas/REQUEST_LOAD_MOTIVOS_FALTAS",
  SUCCESS_LOAD_MOTIVOS_FALTAS = "motivosFaltas/SUCCESS_LOAD_MOTIVOS_FALTAS",
  FAILURE_LOAD_MOTIVOS_FALTAS = "motivosFaltas/FAILURE_LOAD_MOTIVOS_FALTAS",

  REQUEST_LOAD_MOTIVO_FALTA_BY_ID = "motivosFaltas/REQUEST_LOAD_MOTIVO_FALTA_BY_ID",
  SUCCESS_LOAD_MOTIVO_FALTA_BY_ID = "motivosFaltas/SUCCESS_LOAD_MOTIVO_FALTA_BY_ID",
  FAILURE_LOAD_MOTIVO_FALTA_BY_ID = "motivosFaltas/FAILURE_LOAD_MOTIVO_FALTA_BY_ID",

  REQUEST_ADD_MOTIVO_FALTA = "motivosFaltas/REQUEST_ADD_MOTIVO_FALTA",
  SUCCESS_ADD_MOTIVO_FALTA = "motivosFaltas/SUCCESS_ADD_MOTIVO_FALTA",
  FAILURE_ADD_MOTIVO_FALTA = "motivosFaltas/FAILURE_ADD_MOTIVO_FALTA",

  REQUEST_EDIT_MOTIVO_FALTA = "motivosFaltas/REQUEST_EDIT_MOTIVO_FALTA",
  SUCCESS_EDIT_MOTIVO_FALTA = "motivosFaltas/SUCCESS_EDIT_MOTIVO_FALTA",
  FAILURE_EDIT_MOTIVO_FALTA = "motivosFaltas/FAILURE_EDIT_MOTIVO_FALTA",

  REQUEST_DEL_MOTIVO_FALTA = "motivosFaltas/REQUEST_DEL_MOTIVO_FALTA",
  SUCCESS_DEL_MOTIVO_FALTA = "motivosFaltas/SUCCESS_DEL_MOTIVO_FALTA",
  FAILURE_DEL_MOTIVO_FALTA = "motivosFaltas/FAILURE_DEL_MOTIVO_FALTA",
}

export type MotivosFaltasLoadDTO = {
  idMotivoFalta: number;
  descMotivoFalta: string;
};

export type MotivosFaltasAddOrEditDTO = {
  idMotivoFalta?: number;
  descMotivoFalta: string;
  considerarBonus: boolean;
};

export type MotivoFaltaType = {
  idMotivoFalta: number;
  descMotivoFalta: string;
  considerarBonus: boolean;
};

export type ItemSelectedType = {
  item: MotivoFaltaType | null;
  loading: boolean;
  success: boolean;
  error: boolean;
};

interface IMotivosFaltasState {
  readonly data: MotivosFaltasLoadDTO[];
  readonly loadingData: boolean;
  readonly loadingAction: boolean;
  readonly success: boolean;
  readonly error: boolean;
  readonly itemSelected: ItemSelectedType;
}

export type DataTypes = IMotivosFaltasState;

/**
 * Actions
 */

// LOAD
export interface IRequestGetMotivosFaltasAction {
  type: typeof Types.REQUEST_LOAD_MOTIVOS_FALTAS;
  search?: string;
}

export interface ISuccessGetMotivosFaltasAction {
  type: typeof Types.SUCCESS_LOAD_MOTIVOS_FALTAS;
  payload: MotivosFaltasLoadDTO[];
}

export interface IFailureGetMotivosFaltasAction {
  type: typeof Types.FAILURE_LOAD_MOTIVOS_FALTAS;
  message: string;
}

type MotivosFaltasActionGetActions =
  | IRequestGetMotivosFaltasAction
  | ISuccessGetMotivosFaltasAction
  | IFailureGetMotivosFaltasAction;

//LOAD BY ID

export interface IRequestGetByIdMotivoFaltaAction {
  type: typeof Types.REQUEST_LOAD_MOTIVO_FALTA_BY_ID;
  idMotivoFalta: number;
}

export interface ISuccessGetByIdMotivoFaltaAction {
  type: typeof Types.SUCCESS_LOAD_MOTIVO_FALTA_BY_ID;
  payload: MotivoFaltaType;
}

export interface IFailureGetByIdMotivoFaltaAction {
  type: typeof Types.FAILURE_LOAD_MOTIVO_FALTA_BY_ID;
  message: string;
}

type MotivoFaltaGetByIdActions =
  | IRequestGetByIdMotivoFaltaAction
  | ISuccessGetByIdMotivoFaltaAction
  | IFailureGetByIdMotivoFaltaAction;

// //ADD

export interface IRequestAddMotivoFaltaAction {
  type: typeof Types.REQUEST_ADD_MOTIVO_FALTA;
  payload: MotivosFaltasAddOrEditDTO;
}

export interface ISuccessAddMotivoFaltaAction {
  type: typeof Types.SUCCESS_ADD_MOTIVO_FALTA;
  payload: MotivosFaltasLoadDTO;
}

export interface IFailureAddMotivoFaltaAction {
  type: typeof Types.FAILURE_ADD_MOTIVO_FALTA;
  message: string;
}

type MotivoFaltaAddActions =
  | IRequestAddMotivoFaltaAction
  | ISuccessAddMotivoFaltaAction
  | IFailureAddMotivoFaltaAction;

//EDIT

export interface IRequestEditMotivoFaltaAction {
  type: typeof Types.REQUEST_EDIT_MOTIVO_FALTA;
  payload: MotivosFaltasAddOrEditDTO;
}

export interface ISuccessEditMotivoFaltaAction {
  type: typeof Types.SUCCESS_EDIT_MOTIVO_FALTA;
  payload: MotivosFaltasLoadDTO;
}

export interface IFailureEditMotivoFaltaAction {
  type: typeof Types.FAILURE_EDIT_MOTIVO_FALTA;
  message: string;
}

type MotivoFaltaEditActions =
  | IRequestEditMotivoFaltaAction
  | ISuccessEditMotivoFaltaAction
  | IFailureEditMotivoFaltaAction;

//DELETE

export interface IRequestDelMotivoFaltaAction {
  type: typeof Types.REQUEST_DEL_MOTIVO_FALTA;
  idMotivoFalta: number;
}

export interface ISuccessDelMotivoFaltaAction {
  type: typeof Types.SUCCESS_DEL_MOTIVO_FALTA;
  idMotivoFalta: number;
}

export interface IFailureDelMotivoFaltaAction {
  type: typeof Types.FAILURE_DEL_MOTIVO_FALTA;
  message: string;
}

type MotivoFaltaDelActions =
  | IRequestDelMotivoFaltaAction
  | ISuccessDelMotivoFaltaAction
  | IFailureDelMotivoFaltaAction;

export type MotivosFaltasActionTypes =
  | MotivosFaltasActionGetActions
  | MotivoFaltaGetByIdActions
  | MotivoFaltaAddActions
  | MotivoFaltaEditActions
  | MotivoFaltaDelActions;
