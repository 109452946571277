import { Types, DataTypes, TipoMovActionTypes, TipoMovType } from "./types";
import customToast from "~/components/Toast/index";

/**
 * Reducers
 */
const INITIAL_STATE: DataTypes = {
  data: [],
  error: false,
  loading: false,
  loadingAction: false,
  success: false,
  itemSelected: {
    item: null,
    error: false,
    loading: false,
    success: false,
  },
};

/**
 * Actions
 */

export const Creators = {
  getTiposMov: (search?: string): TipoMovActionTypes => ({
    type: Types.REQUEST_TIPO_MOV_LOAD,
    search,
  }),
  getTiposMovById: (idTipoMov: number): TipoMovActionTypes => ({
    type: Types.REQUEST_TIPO_MOV_LOAD_BY_ID,
    idTipoMov,
  }),
  addTipoMov: (tipo: TipoMovType): TipoMovActionTypes => ({
    type: Types.REQUEST_TIPO_MOV_ADD,
    payload: tipo,
  }),
  editTipoMov: (tipo: TipoMovType): TipoMovActionTypes => ({
    type: Types.REQUEST_TIPO_MOV_EDIT,
    payload: tipo,
  }),
  delTipoMov: (idTipoMov: number): TipoMovActionTypes => ({
    type: Types.REQUEST_TIPO_MOV_DEL,
    idTipoMov,
  }),
};

export default function tipoMovReducer(
  state = INITIAL_STATE,
  action: TipoMovActionTypes
): DataTypes {
  switch (action.type) {
    //LOAD
    case Types.REQUEST_TIPO_MOV_LOAD:
      return {
        ...state,
        loading: true,
        error: false,
        success: false,
        itemSelected: {
          item: null,
          error: false,
          loading: false,
          success: false,
        },
      };
    case Types.SUCCESS_TIPO_MOV_LOAD:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        success: true,
      };
    case Types.FAILURE_TIPO_MOV_LOAD:
      customToast.error(action.message);
      return {
        ...state,
        data: [],
        loading: false,
        error: true,
        success: false,
      };

    //LOAD BY ID
    case Types.REQUEST_TIPO_MOV_LOAD_BY_ID:
      return {
        ...state,
        itemSelected: {
          item: null,
          error: false,
          loading: true,
          success: false,
        },
      };
    case Types.SUCCESS_TIPO_MOV_LOAD_BY_ID:
      return {
        ...state,
        itemSelected: {
          item: action.payload,
          error: false,
          loading: false,
          success: true,
        },
      };
    case Types.FAILURE_TIPO_MOV_LOAD_BY_ID:
      customToast.error(action.message);
      return {
        ...state,
        itemSelected: {
          item: null,
          error: true,
          loading: false,
          success: false,
        },
      };

    //ADD
    case Types.REQUEST_TIPO_MOV_ADD:
      return { ...state, loadingAction: true, error: false, success: false };
    case Types.SUCCESS_TIPO_MOV_ADD:
      return {
        ...state,
        data: [...state.data, action.payload],
        loadingAction: false,
        error: false,
        success: true,
      };
    case Types.FAILURE_TIPO_MOV_ADD:
      customToast.error(action.message);
      return {
        ...state,
        loadingAction: false,
        error: true,
        success: false,
      };

    //EDIT
    case Types.REQUEST_TIPO_MOV_EDIT:
      return { ...state, loadingAction: true, error: false, success: false };
    case Types.SUCCESS_TIPO_MOV_EDIT:
      return {
        ...state,
        data: state.data.map((item) => {
          if (item.idTipoMov === action.payload.idTipoMov) {
            return action.payload;
          }
          return item;
        }),
        loadingAction: false,
        error: false,
        success: true,
      };
    case Types.FAILURE_TIPO_MOV_EDIT:
      customToast.error(action.message);
      return {
        ...state,
        loadingAction: false,
        error: true,
        success: false,
      };

    //DEL
    case Types.REQUEST_TIPO_MOV_DEL:
      return { ...state, loadingAction: true, error: false, success: false };
    case Types.SUCCESS_TIPO_MOV_DEL:
      return {
        ...state,
        data: state.data.filter((item) => item.idTipoMov !== action.idTipoMov),
        loadingAction: false,
        error: false,
        success: true,
      };
    case Types.FAILURE_TIPO_MOV_DEL:
      customToast.error(action.message);
      return {
        ...state,
        loadingAction: false,
        error: true,
        success: false,
      };

    default:
      return state;
  }
}
