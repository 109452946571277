import React, { useState } from "react";
import * as yup from "yup";
import { Formik } from "formik";
import moment from "moment";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { EmployeeType } from "~/store/ducks/employees/types";

import { FormHeaderStyle } from "~/components/FormGroup";
import { InputAutocomplete, InputDate } from "~/components/Forms";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "~/store/ducks";

import {
  DataTypes as DataTypesVinculos,
  VinculosLoadDTO,
} from "~/store/ducks/admin/vinculo/types";
import { Creators as CreatorsVinculo } from "~/store/ducks/admin/vinculo";

interface IFormIdentification {
  initialValues: EmployeeType;
  formRef: any;
  success: (values: EmployeeType) => void;
}

const validationFormCadastralSchema = yup.object().shape({
  dataAdmissao: yup
    .date()
    .typeError("Data de nascimento inválida")
    .required("Campo obrigatório"),
  dataDemissao: yup
    .date()
    .nullable()
    .when("status", {
      is: false,
      then: yup
        .date()
        .typeError("Data de nascimento inválida")
        .required("Campo obrigatório"),
    }),
  idVinculo: yup.string().nullable(),
});

const FormCadastral: React.FC<IFormIdentification> = (props) => {
  const { formRef, initialValues } = props;
  const isActive: boolean = initialValues.status === "A";
  const [status, setStatus] = useState(!isActive);

  const [valueVinculo, setValueVinculo] = useState<VinculosLoadDTO | null>(
    props.initialValues.vinculo
    // props.initialValues.vinculo
  );
  const vinculoState = useSelector<RootState, DataTypesVinculos>(
    (state) => state.vinculoReducer
  );

  const dispatch = useDispatch();
  let timeout: number = 0;

  // useEffect(() => {
  //     setValueUnit(props.initialValues.unidade);
  // }, []);

  const search = (text: string, type: string) => {
    if (text.trim()) {
      clearTimeout(timeout);
      timeout = window.setTimeout(() => {
        dispatch(CreatorsVinculo.getVinculos(text));
      }, 700);
    }
  };

  return (
    <>
      <FormHeaderStyle text="Gerenciar situação cadastral" />
      <Formik
        enableReinitialize
        innerRef={formRef}
        initialValues={initialValues}
        validationSchema={validationFormCadastralSchema}
        validateOnChange={false}
        validateOnBlur
        onSubmit={(values: EmployeeType) => {
          values.vinculo = valueVinculo;
          values.status = status ? "D" : "A";
          values.dataDemissao = status ? values.dataDemissao : null;
          props.success(values);
        }}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          errors,
          setFieldError,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <InputDate
              id="dataAdmissao"
              error={!!errors.dataAdmissao}
              name="dataAdmissao"
              label="Data de Admissão"
              value={moment(values.dataAdmissao)}
              onChange={(value) => setFieldValue("dataAdmissao", value)}
              helperText={errors.dataAdmissao}
            />
            <InputAutocomplete
              value={valueVinculo as VinculosLoadDTO}
              onChange={(_, newValue) => {
                setValueVinculo(newValue!);
                setFieldValue("idVinculo", newValue?.idVinculo);
                // props.editValues({ ...values, vinculo: newValue });
              }}
              onInputChange={(_, newInputValue) => {
                setFieldError("idVinculo", "");
                search(newInputValue, "idVinculo");
              }}
              getOptionLabel={(vinc: VinculosLoadDTO) =>
                `${vinc.idVinculo} - ${vinc.descVinculo}`
              }
              getOptionSelected={(option, value) =>
                option.idVinculo === value.idVinculo
              }
              options={vinculoState.data}
              input={{
                idInput: "idVinculo",
                labelInput: "Vínculo Empregatício",
                helperTextInput: errors.idVinculo ?? "",
                errorInput: !!errors.idVinculo,
              }}
            />

            <>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={status}
                    onChange={(e) => {
                      setStatus(e.target.checked);
                      setFieldValue("status", !e.target.checked);
                    }}
                    name="status"
                    color="primary"
                  />
                }
                label="Inativar Funcionário"
              />
              {status && (
                <InputDate
                  style={{ marginTop: 15 }}
                  id="dataDemissao"
                  error={!!errors.dataDemissao}
                  name="dataDemissao"
                  label="Data de Desligamento"
                  value={
                    values.dataDemissao ? moment(values.dataDemissao) : null
                  }
                  onChange={(value) => setFieldValue("dataDemissao", value)}
                  className="mt-2"
                  helperText={errors.dataDemissao}
                />
              )}
            </>
          </form>
        )}
      </Formik>
    </>
  );
};

export default FormCadastral;
