/**
 * Types
 */
export enum Types {
    //GET
    REQUEST_ATTITUDES_LOAD = "attitudes/REQUEST_ATTITUDES_LOAD",
    SUCCESS_ATTITUDES_LOAD = "attitudes/SUCCESS_ATTITUDES_LOAD",
    FAILURE_ATTITUDES_LOAD = "attitudes/FAILURE_ATTITUDES_LOAD",
    //GETBYID
    REQUEST_ATTITUDES_LOAD_BY_ID = "attitudes/REQUEST_ATTITUDES_LOAD_BY_ID",
    SUCCESS_ATTITUDES_LOAD_BY_ID = "attitudes/SUCCESS_ATTITUDES_LOAD_BY_ID",
    FAILURE_ATTITUDES_LOAD_BY_ID = "attitudes/FAILURE_ATTITUDES_LOAD_BY_ID",
    //ADD
    REQUEST_ATTITUDES_ADD = "attitudes/REQUEST_ATTITUDES_ADD",
    SUCCESS_ATTITUDES_ADD = "attitudes/SUCCESS_ATTITUDES_ADD",
    FAILURE_ATTITUDES_ADD = "attitudes/FAILURE_ATTITUDES_ADD",
    //PUT
    REQUEST_ATTITUDE_EDIT = "attitudes/REQUEST_ATTITUDE_EDIT",
    SUCCESS_ATTITUDE_EDIT = "attitudes/SUCCESS_ATTITUDE_EDIT",
    FAILURE_ATTITUDE_EDIT = "attitudes/FAILURE_ATTITUDE_EDIT",
    //DELETE
    REQUEST_ATTITUDE_DELETE = "attitudes/REQUEST_ATTITUDE_DELETE",
    SUCCESS_ATTITUDE_DELETE = "attitudes/SUCCESS_ATTITUDE_DELETE",
    FAILURE_ATTITUDE_DELETE = "attitudes/FAILURE_ATTITUDE_DELETE",
};

/**
 * Data types
 */

export type AttitudeType = {
    idAtitude: number | null;
    nomeAtitude: string;
    descAtitude: string;
    descColetivo: string;
};

type AttitudeSelectedState = {
    item : AttitudeType | null;
    loading: boolean;
    success: boolean;
    error: boolean;
}

interface IAttitudeState {
    readonly data: AttitudeType[];
    readonly itemSelected: AttitudeSelectedState;
    readonly loadingData: boolean;
    readonly loadingAction: boolean;
    readonly error: boolean;
    readonly success: boolean;
};

export type DataTypes = IAttitudeState;


/**
 * Actions
 */

//GET
export interface IRequestAttitudesLoadAction {
    type: typeof Types.REQUEST_ATTITUDES_LOAD;
    search: string;
};

export interface ISuccessAttitudesLoadAction {
    type: typeof Types.SUCCESS_ATTITUDES_LOAD;
    payload: AttitudeType[];
};

interface IFailureAttitudesLoadAction {
    type: typeof Types.FAILURE_ATTITUDES_LOAD;
    message: string;
};

type AttitudesLoadAction = IRequestAttitudesLoadAction | ISuccessAttitudesLoadAction | IFailureAttitudesLoadAction;

//GET
export interface IRequestAttitudesLoadByIdAction {
    type: typeof Types.REQUEST_ATTITUDES_LOAD_BY_ID;
    id: number;
};

export interface ISuccessAttitudesLoadByIdAction {
    type: typeof Types.SUCCESS_ATTITUDES_LOAD_BY_ID;
    payload: AttitudeType;
};

interface IFailureAttitudesLoadByIdAction {
    type: typeof Types.FAILURE_ATTITUDES_LOAD_BY_ID;
    message: string;
};

type AttitudesLoadByIdAction = IRequestAttitudesLoadByIdAction | ISuccessAttitudesLoadByIdAction | IFailureAttitudesLoadByIdAction;

//POST
export interface IRequestAttitudesPostAction {
    type: typeof Types.REQUEST_ATTITUDES_ADD;
    attitude: AttitudeType;
};

export interface ISuccessAttitudesPostAction {
    type: typeof Types.SUCCESS_ATTITUDES_ADD;
    payload: AttitudeType;
};

interface IFailureAttitudesPostAction {
    type: typeof Types.FAILURE_ATTITUDES_ADD;
    message: string;
};

type AttitudesPostAction = IRequestAttitudesPostAction | ISuccessAttitudesPostAction | IFailureAttitudesPostAction;

//POST
export interface IRequestAttitudesEditAction {
    type: typeof Types.REQUEST_ATTITUDE_EDIT;
    attitude: AttitudeType;
};

export interface ISuccessAttitudesEditAction {
    type: typeof Types.SUCCESS_ATTITUDE_EDIT;
    payload: AttitudeType;
};

interface IFailureAttitudesEditAction {
    type: typeof Types.FAILURE_ATTITUDE_EDIT;
    message: string;
};

type AttitudesEditAction = IRequestAttitudesEditAction | ISuccessAttitudesEditAction | IFailureAttitudesEditAction;

//DELETE
export interface IRequestAttitudesDeleteAction {
    type: typeof Types.REQUEST_ATTITUDE_DELETE;
    id: number;
};

export interface ISuccessAttitudesDeleteAction {
    type: typeof Types.SUCCESS_ATTITUDE_DELETE;
    id: number;
};

interface IFailureAttitudesDeleteAction {
    type: typeof Types.FAILURE_ATTITUDE_DELETE;
    message: string;
};

type AttitudesDeleteAction = IRequestAttitudesDeleteAction | ISuccessAttitudesDeleteAction | IFailureAttitudesDeleteAction;

export type AttitudesActionTypes =
    AttitudesLoadAction |
    AttitudesLoadByIdAction |
    AttitudesPostAction |
    AttitudesEditAction |
    AttitudesDeleteAction;