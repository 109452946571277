import { ITextStyles } from 'office-ui-fabric-react';
import styled from 'styled-components';
import Colors from '~/assets/js/colors';

export const Table = styled.table`
    box-sizing: border-box;
    margin: 20px 0;
    border-collapse: separate;
    border-spacing: 0 10px;
    overflow: auto;
    display: block;
`

export const Gap = styled.td`
    width: 10px;
    min-width: 10px;
    border: none !important;
    background-color: #FFFFFF;
`

export const Tbody = styled.tbody`

`
export const TdAvaliacao = styled.td`
    width: 180px;
    max-width: 180px;
    min-width: 180px;
`
export const textStyles: ITextStyles = {
    root: {
        fontWeight: 500,
        display: 'block'
    }
}

export const TdResult = styled.td<{ isTotal?: boolean }>`
    border: 1px solid #CCCCCC;
    font-size: 12px;
    width: 130px;
    min-width: 130px;
    height: 60px;
    box-sizing: border-box;
    padding: 0 10px;
    border-radius: 2px;
    ${({ isTotal }) => isTotal ? `
        background-color: #E1DEDE;
     ` : ''}
`
export const WrapperForm = styled.div`
    padding: 10px 0 20px;
    box-sizing: border-box;
    width: 100%;
`

export const TdResultAtitudes = styled.td`
border: 1px solid #CCCCCC;
height: 60px;
background-color: #E1DEDE;
font-size: 12px;
box-sizing: border-box;
text-align: left;
border-radius: 2px;
max-height: 60px;
text-align: center;
`

export const ThReal = styled.th`
    font-size: 12px;
    box-sizing: border-box;
    border-radius: 2px;
    background-color: ${Colors.black};
    color: #FFFFFF;
    padding: 0 5px;
    font-weight: 600;
    width: 90px;
`

export const Th = styled.th`
    font-size: 12px;
    box-sizing: border-box;
    border-radius: 2px;
    background-color: #757575;
    color: #FFFFFF;
    font-weight: 600;
    text-align: center;
`

export const TdReal = styled.td<{ isTotal?: boolean }>`
   border: 1px solid #CCCCCC;
    font-size: 12px;
    width: 90px;
    min-width: 90px;
    height: 60px;
    box-sizing: border-box;
    text-align: center;
    border-radius: 2px;
    padding: 10px 0;
    ${({ isTotal }) => isTotal ? `
        background-color: #E1DEDE;
     ` : ''}
`