import axios from "axios";
import errorHandler, { refreshToken } from "./errorHandler";
import { version as versionBuild } from '../../package.json';

export const version = versionBuild;

const baseURL = process.env.REACT_APP_BASE_URL;

const api = axios.create({
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Accept-Language": "pt-BR",
  },
  timeout: 30000,
  baseURL,
});

function successHandler(response: any) {
  return response;
}

async function responseError(error: object) {
  await errorHandler(error);
  return error;
}

api.interceptors.response.use(
  (response) => successHandler(response),
  async function (error) {
    const access_token = localStorage.getItem("user/token");
    if (error.response.status === 401 && access_token) {
      const response = await refreshToken(error);
      return response;
    }
    return responseError(error)
  }
);

api.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("user/token");
  if (token) {
    config.headers.tk = token;
  }
  return config;
});

export default api;
