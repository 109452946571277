// #region Imports
import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

//FluentUI
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import {
  DetailsListLayoutMode,
  SelectionMode,
  Selection,
  IColumn,
} from 'office-ui-fabric-react/lib/DetailsList';
import { ShimmeredDetailsList } from 'office-ui-fabric-react/lib/ShimmeredDetailsList';
import { PrimaryButton, DetailsHeader, Spinner } from 'office-ui-fabric-react';
import { ScrollablePane } from 'office-ui-fabric-react/lib/ScrollablePane';
import { Sticky } from 'office-ui-fabric-react/lib/Sticky';
import CustomDialog from "~/components/CustomDialog";
import { InputNumber, InputText } from '~/components/Forms';
//Estilos

import { Creators as getPositions } from "~/store/ducks/admin/positions";
import { Creators as clearSearch } from "~/store/ducks/admin/positions";
import { DataTypes as DataTypesPosition } from "~/store/ducks/admin/positions/types";

import { RootState } from "~/store/ducks";
import { PositionTypes } from "~/store/ducks/admin/positions/types";
//#endregion

interface ISearchPositionsState {
  columns: IColumn[];
  items: PositionTypes[];
  selectionDetails: string;
  selectionCount: number;
  tituloSearch: string;
  gsSearch: string;
};

interface IPropsSearchPositions {
  positions: DataTypesPosition;
  isOpen: boolean;
  optionButton?: {
    text: string;
    handleClick: () => void;
  }
  onClose: () => void;
  onAdd: (values: PositionTypes[]) => void;
  getPositions: (search?: string, isSearch?: boolean, filter?: boolean | null, gs?: string | null) => void;
  clearSearch: () => void;
}


class SearchPositions extends Component<IPropsSearchPositions, ISearchPositionsState> {
  private _selection: Selection;
  private timeout: number;

  constructor(props: IPropsSearchPositions) {
    super(props);
    //#region Colunas
    const columns: IColumn[] = [
      {
        key: 'column1',
        name: 'ID',
        fieldName: 'idCargo',
        minWidth: 50,
        maxWidth: 50,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: true,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
      },
      {
        key: 'column2',
        name: 'Cargo',
        fieldName: 'titulo',
        minWidth: 300,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: true,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
      },
      {
        key: 'column3',
        name: 'GS',
        fieldName: 'gs',
        minWidth: 100,
        maxWidth: 100,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: true,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
      }
    ];
    //#endregion

    this.state = {
      columns: columns,
      items: [],
      selectionDetails: "",
      selectionCount: 0,
      tituloSearch: "",
      gsSearch: ''
    }

    this._selection = new Selection({
      onSelectionChanged: () => {
        this.setState({
          selectionDetails: this._getSelectionDetails(),
        });
      },
    });

    this.timeout = 0;
  };

  componentDidUpdate(prevProps: IPropsSearchPositions, _: ISearchPositionsState) {
    const { isOpen: prevIsOpen } = prevProps;
    const { isOpen: nextIsOpen } = this.props;

    if (prevIsOpen !== nextIsOpen) {
      if (nextIsOpen) {
        this.props.clearSearch();
        this.setState({ tituloSearch: '', gsSearch: '' })
      }
    }
  }

  _getSelectionDetails(): any {
    const selectionCount = this._selection.getSelectedCount();
    this.setState({ selectionCount });
    this._selection.getSelection();
  };

  //#endregion

  handleEnter = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const enterClick = event.key === 'Enter';
    if (enterClick) {
      this._selection.setAllSelected(false);
      this.props.getPositions(this.state.tituloSearch, true, true, this.state.gsSearch)
    }
  }

  render() {
    const { columns, tituloSearch, gsSearch } = this.state;
    const { positions, isOpen, onClose, onAdd, getPositions, optionButton } = this.props;

    return (
      <>
        <CustomDialog
          open={isOpen}
          title="Pesquisa de Cargos"
          btnConfirmText="Selecionar"
          onClickCancel={onClose}
          onClickConfirm={() => {
            onAdd(this._selection.getSelection() as PositionTypes[]);
          }}
          disabledBtnConfirm={this._selection.getSelectedCount() === 0}
          height={580}
          width={850}
          optionButton={optionButton}
        >
          <Stack horizontalAlign="end" styles={{ root: { width: 802, position: 'fixed', zIndex: 9, backgroundColor: '#FFFFFF' } }}>
            <Stack horizontal horizontalAlign="space-between" styles={{ root: { width: '100%' } }} >
              <InputText
                label="Título"
                value={tituloSearch}
                onChange={(e) => this.setState({ tituloSearch: e.target.value })}
                onKeyUp={this.handleEnter}
                variant="outlined"
                height_container={40}
                fullWidth
                size="small"
                smaller="small"
                styles={{ marginTop: 10, width: 590, marginRight: 20 }}
                autoFocus
              />
              <InputNumber
                label="GS"
                value={gsSearch ?? ''}
                variant="outlined"
                height_container={40}
                fullWidth
                smaller="small"
                styles={{ marginTop: 10, width: 360 }}
                decimalScale={0}
                fixedDecimalScale
                onValueChange={val => this.setState({ gsSearch: val.formattedValue })}
              />
            </Stack>
            <PrimaryButton
              disabled={positions.loadingDataSearch}
              text="Pesquisar"
              styles={{ root: { width: 110, marginTop: '15px !important' } }}
              onClick={() => {
                this._selection.setAllSelected(false);
                getPositions(tituloSearch, true, true, this.state.gsSearch)
              }}
            />
          </Stack>
          {
            positions.loadingDataSearch ?
              <Spinner label="Pesquisando" styles={{
                root: {
                  position: 'absolute',
                  top: 325,
                  left: 0,
                  right: 0,
                }
              }}
              />
              :
              <ScrollablePane styles={{
                root: {
                  top: 170,
                  height: 335,
                  margin: '0 20px'
                }
              }}>
                <ShimmeredDetailsList
                  items={positions.dataSearch ?? []}
                  enableShimmer={positions.loadingData}
                  columns={columns}
                  selectionMode={SelectionMode.single}
                  selection={this._selection}
                  getKey={this._getKey}
                  selectionPreservedOnEmptyClick={true}
                  setKey="multiple"
                  layoutMode={DetailsListLayoutMode.justified}
                  isHeaderVisible={true}
                  styles={{ root: { overflowX: 'hidden' } }}
                  onRenderDetailsHeader={
                    detailsHeaderProps => {
                      return (
                        <Sticky>
                          <DetailsHeader
                            {...detailsHeaderProps}
                            layoutMode={DetailsListLayoutMode.justified}
                          />
                        </Sticky>
                      );
                    }
                  }
                />
              </ScrollablePane>
          }
        </CustomDialog>
      </>
    );
  };

  private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    // const { columns } = this.state;
    // const newColumns: IColumn[] = columns.slice();
    // let items: PesquisaFuncType[] = [];
    // items = this.props.items ?? [];
    // const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    // newColumns.forEach((newCol: IColumn) => {
    //   if (newCol === currColumn) {
    //     currColumn.isSortedDescending = !currColumn.isSortedDescending;
    //     currColumn.isSorted = true;
    //   } else {
    //     newCol.isSorted = false;
    //     newCol.isSortedDescending = true;
    //   };
    // });
    // const newItems = this._sort(items, currColumn.fieldName!, currColumn.isSortedDescending);
    // this.setState({
    //   columns: newColumns,
    //   items: newItems,
    // });
  };

  private _getKey(item: any, index?: number): any {
    if (item !== undefined)
      return item.key;
  };

  private _sort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
    const key = columnKey as keyof T;
    const itemsSorted = items.sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
    return itemsSorted;
  };

}

const mapStateToProps = (state: RootState) => ({
  positions: state.positionsReducer
});

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  ...getPositions,
  ...clearSearch,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SearchPositions);
