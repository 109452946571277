import { takeEvery, put, call, all } from "redux-saga/effects";
import {
  Types,
  MotivosDesligamentosLoadDTO,
  MotivoDesligamentoType,
  IRequestAddMotivoDesligamentoAction,
  IRequestGetByIdMotivoDesligamentoAction,
  IRequestEditMotivoDesligamentoAction,
  IRequestDelMotivoDesligamentoAction,
  ISuccessGetMotivosDesligamentosAction,
  ISuccessAddMotivoDesligamentoAction,
  ISuccessGetByIdMotivoDesligamentoAction,
  ISuccessEditMotivoDesligamentoAction,
  ISuccessDelMotivoDesligamentoAction,
} from "../../ducks/admin/motivoDesligamento/types";
import api from "~/services/api";

const apiCallGet = () => {
  return api
    .get("admin/motivosDesligamentos")
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallGetById = (idMotivoDesligamento: number) => {
  return api
    .get(`admin/motivosDesligamentos/${idMotivoDesligamento}`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallAdd = (item: IRequestAddMotivoDesligamentoAction["payload"]) => {
  return api
    .post("admin/motivosDesligamentos", item)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallEdit = (item: IRequestEditMotivoDesligamentoAction["payload"]) => {
  return api
    .put(`admin/motivosDesligamentos/${item.idMotivoDesligamento}`, item)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallDel = (idMotivoDesligamento: number) => {
  return api
    .delete(`admin/motivosDesligamentos/${idMotivoDesligamento}`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

function* load() {
  try {
    const items: MotivosDesligamentosLoadDTO[] = yield call(apiCallGet);
    yield put({
      type: Types.SUCCESS_LOAD_MOTIVOS_DESLIGAMENTOS,
      payload: items,
    } as ISuccessGetMotivosDesligamentosAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível carregar os motivos de desligamento";
    yield put({ type: Types.FAILURE_LOAD_MOTIVOS_DESLIGAMENTOS, message });
  }
}

function* loadById(action: IRequestGetByIdMotivoDesligamentoAction) {
  try {
    const item: MotivoDesligamentoType = yield call(
      apiCallGetById,
      action.idMotivoDesligamento
    );
    yield put({
      type: Types.SUCCESS_LOAD_MOTIVO_DESLIGAMENTO_BY_ID,
      payload: item,
    } as ISuccessGetByIdMotivoDesligamentoAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível carregar o motivo de desligamento";
    yield put({ type: Types.FAILURE_LOAD_MOTIVO_DESLIGAMENTO_BY_ID, message });
  }
}

function* add(action: IRequestAddMotivoDesligamentoAction) {
  try {
    const data: { idMotivoDesligamento: number } = yield call(
      apiCallAdd,
      action.payload
    );
    yield put({
      type: Types.SUCCESS_ADD_MOTIVO_DESLIGAMENTO,
      payload: {
        idMotivoDesligamento: data.idMotivoDesligamento,
        descMotivo: action.payload.descMotivo,
      },
    } as ISuccessAddMotivoDesligamentoAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível adicionar o motivo de desligamento";
    yield put({ type: Types.FAILURE_ADD_MOTIVO_DESLIGAMENTO, message });
  }
}

function* edit(action: IRequestEditMotivoDesligamentoAction) {
  try {
    yield call(apiCallEdit, action.payload);
    yield put({
      type: Types.SUCCESS_EDIT_MOTIVO_DESLIGAMENTO,
      payload: action.payload,
    } as ISuccessEditMotivoDesligamentoAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível editar o motivo de desligamento";
    yield put({ type: Types.FAILURE_EDIT_MOTIVO_DESLIGAMENTO, message });
  }
}

function* del(action: IRequestDelMotivoDesligamentoAction) {
  try {
    yield call(apiCallDel, action.idMotivoDesligamento);
    yield put({
      type: Types.SUCCESS_DEL_MOTIVO_DESLIGAMENTO,
      idMotivoDesligamento: action.idMotivoDesligamento,
    } as ISuccessDelMotivoDesligamentoAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível excluír o motivo de desligamento";
    yield put({ type: Types.FAILURE_DEL_MOTIVO_DESLIGAMENTO, message });
  }
}

export default function* motivoDesligamentoRoot() {
  yield all([
    yield takeEvery(Types.REQUEST_LOAD_MOTIVOS_DESLIGAMENTOS, load),
    yield takeEvery(Types.REQUEST_LOAD_MOTIVO_DESLIGAMENTO_BY_ID, loadById),
    yield takeEvery(Types.REQUEST_ADD_MOTIVO_DESLIGAMENTO, add),
    yield takeEvery(Types.REQUEST_EDIT_MOTIVO_DESLIGAMENTO, edit),
    yield takeEvery(Types.REQUEST_DEL_MOTIVO_DESLIGAMENTO, del),
  ]);
}
