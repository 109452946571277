import { takeEvery, put, call, all } from "redux-saga/effects";
import {
  Types,
  IRequestCycleAttitudesLoadAction,
  IRequestCycleAttitudesAddAction,
  IRequestCycleAttitudesEditAction,
  IRequestCycleAttitudesDelAction,
  IRequestCycleAttitudesNextAction,
  IRequestCycleAttitudesPriorAction,
  ISuccessCycleAttitudesLoadAction,
  ISuccessCycleAttitudesAddAction,
  ISuccessCycleAttitudesEditAction,
  ISuccessCycleAttitudesDelAction,
  ISuccessCycleAttitudesNextAction,
  ISuccessCycleAttitudesPriorAction
} from "../ducks/cycles/attitude/types";
import api from "~/services/api";

const apiCallGet = (idCiclo: number) => {
  return api
    .get(`ciclos/${idCiclo}/atitudes`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err.response;
    });
};

const apiCallAdd = (payload: IRequestCycleAttitudesAddAction["payload"]) => {
  let body: any = {
    idAtitude: payload.idAtitude,
    flagLideranca: payload.flagLideranca
  }

  if (payload.ordem) body.ordem = payload.ordem

  return api
    .post(`ciclos/${payload.idCiclo}/atitudes`, {
      ...body
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err.response;
    });
};

const apiCallEdit = (payload: IRequestCycleAttitudesEditAction["payload"]) => {
  return api
    .put(`ciclos/${payload.idCiclo}/atitudes/${payload.idCicloAtitude}`, {
      flagLideranca: payload.flagLideranca
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err.response;
    });
};

const apiCallDel = (payload: IRequestCycleAttitudesDelAction["payload"]) => {
  return api
    .delete(`ciclos/${payload.idCiclo}/atitudes/${payload.idCicloAtitude}`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err.response;
    });
};

const apiCallNext = (payload: IRequestCycleAttitudesNextAction["payload"]) => {
  return api
    .get(`ciclos/${payload.idCiclo}/atitudes/${payload.idCicloAtitude}/next`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err.response;
    });
}
const apiCallPrior = (payload: IRequestCycleAttitudesPriorAction["payload"]) => {
  return api
    .get(`ciclos/${payload.idCiclo}/atitudes/${payload.idCicloAtitude}/prior`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err.response;
    });
}


function* load(action: IRequestCycleAttitudesLoadAction) {
  try {
    let items: any = yield call(apiCallGet, action.idCiclo);
    yield put({
      type: Types.SUCCESS_CYCLE_ATTITUDES_LOAD,
      payload: items
    } as ISuccessCycleAttitudesLoadAction);
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível carregar as atitudes'
    yield put({
      type: Types.FAILURE_CYCLE_ATTITUDES_LOAD,
      message
    });
  }
}

function* add(action: IRequestCycleAttitudesAddAction) {
  try {
    const idCicloAtitude: any = yield call(apiCallAdd, action.payload);
    yield put({
      type: Types.SUCCESS_CYCLE_ATTITUDES_ADD,
      payload: { ...action.payload, ...idCicloAtitude }
    } as ISuccessCycleAttitudesAddAction);
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível inserir a atitude ao ciclo'
    yield put({
      type: Types.FAILURE_CYCLE_ATTITUDES_ADD,
      message
    });
  }
}

function* editLideranca(action: IRequestCycleAttitudesEditAction) {
  try {
    yield call(apiCallEdit, action.payload);
    yield put({
      type: Types.SUCCESS_CYCLE_ATTITUDES_EDIT,
      payload: { 
        idCicloAtitude: action.payload.idCicloAtitude,
        flagLideranca: action.payload.flagLideranca
       }
    } as ISuccessCycleAttitudesEditAction);
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível editar atitude'
    yield put({
      type: Types.FAILURE_CYCLE_ATTITUDES_EDIT,
      message
    });
  }
}

function* del(action: IRequestCycleAttitudesDelAction) {
  try {
    yield call(apiCallDel, action.payload);
    yield put({
      type: Types.SUCCESS_CYCLE_ATTITUDES_DEL,
      idCicloAtitude: action.payload.idCicloAtitude
    } as ISuccessCycleAttitudesDelAction);
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível excluir a atitude do ciclo'
    yield put({
      type: Types.FAILURE_CYCLE_ATTITUDES_DEL,
      message
    });
  }
}

function* next(action: IRequestCycleAttitudesNextAction) {
  try {
    yield call(apiCallNext, action.payload);
    yield put({
      type: Types.SUCCESS_CYCLE_ATITUDES_NEXT,
      idCicloAtitude: action.payload.idCicloAtitude
    } as ISuccessCycleAttitudesNextAction);
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível mover para cima a atitude'
    yield put({
      type: Types.FAILURE_CYCLE_ATITUDES_NEXT,
      message
    });
  }
}

function* prior(action: IRequestCycleAttitudesPriorAction) {
  try {
    yield call(apiCallPrior, action.payload);
    yield put({
      type: Types.SUCCESS_CYCLE_ATITUDES_PRIOR,
      idCicloAtitude: action.payload.idCicloAtitude
    } as ISuccessCycleAttitudesPriorAction);
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível mover para baixo a atitude'
    yield put({
      type: Types.FAILURE_CYCLE_ATITUDES_PRIOR,
      message
    });
  }
}

export default function* companiesRoot() {
  yield all([
    yield takeEvery(Types.REQUEST_CYCLE_ATTITUDES_LOAD, load),
    yield takeEvery(Types.REQUEST_CYCLE_ATTITUDES_ADD, add),
    yield takeEvery(Types.REQUEST_CYCLE_ATTITUDES_EDIT, editLideranca),
    yield takeEvery(Types.REQUEST_CYCLE_ATTITUDES_DEL, del),
    yield takeEvery(Types.REQUEST_CYCLE_ATITUDES_NEXT, next),
    yield takeEvery(Types.REQUEST_CYCLE_ATITUDES_PRIOR, prior),
  ]);
}
