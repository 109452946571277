import {
  Types,
  DataTypes,
  VinculosActionTypes,
  VinculosAddOrEditDTO,
} from "./types";
import customToast from "~/components/Toast/index";
/**
 * Reducers
 */
const INITIAL_STATE: DataTypes = {
  data: [],
  loadingData: false,
  loadingAction: false,
  success: false,
  error: false,
  itemSelected: {
    item: null,
    error: false,
    loading: false,
    success: false,
  },
};

/**
 * Actions
 */

export const Creators = {
  getVinculos: (search?: string): VinculosActionTypes => {
    return {
      type: Types.REQUEST_LOAD_VINCULOS,
      search,
    };
  },

  getVinculoById: (idVinculo: number): VinculosActionTypes => ({
    type: Types.REQUEST_LOAD_VINCULO_BY_ID,
    idVinculo,
  }),

  addVinculo: (vinculo: VinculosAddOrEditDTO): VinculosActionTypes => ({
    type: Types.REQUEST_ADD_VINCULO,
    payload: vinculo,
  }),

  editVinculo: (vinculo: VinculosAddOrEditDTO): VinculosActionTypes => ({
    type: Types.REQUEST_EDIT_VINCULO,
    payload: vinculo,
  }),

  delVinculo: (idVinculo: number): VinculosActionTypes => ({
    type: Types.REQUEST_DEL_VINCULO,
    idVinculo,
  }),
};

export default function vinculosReducer(
  state = INITIAL_STATE,
  action: VinculosActionTypes
): DataTypes {
  switch (action.type) {
    // LOAD
    case Types.REQUEST_LOAD_VINCULOS:
      return {
        ...state,
        loadingData: true,
        error: false,
        success: false,
        itemSelected: { ...state.itemSelected, item: null },
      };
    case Types.SUCCESS_LOAD_VINCULOS:
      return {
        ...state,
        loadingData: false,
        error: false,
        success: true,
        data: action.payload,
      };
    case Types.FAILURE_LOAD_VINCULOS:
      customToast.error(action.message);
      return {
        ...state,
        data: [],
        loadingData: false,
        success: false,
        error: true,
      };

    //LOAD BY ID
    case Types.REQUEST_LOAD_VINCULO_BY_ID:
      return {
        ...state,
        itemSelected: {
          item: null,
          loading: true,
          success: false,
          error: false,
        },
      };
    case Types.SUCCESS_LOAD_VINCULO_BY_ID:
      return {
        ...state,
        itemSelected: {
          item: action.payload,
          loading: false,
          success: true,
          error: false,
        },
      };
    case Types.FAILURE_LOAD_VINCULO_BY_ID:
      customToast.error(action.message);
      return {
        ...state,
        itemSelected: {
          item: null,
          loading: false,
          success: false,
          error: true,
        },
      };

    // ADD
    case Types.REQUEST_ADD_VINCULO:
      return { ...state, loadingAction: true, error: false, success: false };
    case Types.SUCCESS_ADD_VINCULO:
      return {
        ...state,
        loadingAction: false,
        error: false,
        success: true,
        data: [...state.data, action.payload],
      };
    case Types.FAILURE_ADD_VINCULO:
      customToast.error(action.message);
      return {
        ...state,
        loadingAction: false,
        success: false,
        error: true,
      };

    // EDIT
    case Types.REQUEST_EDIT_VINCULO:
      return { ...state, loadingAction: true, error: false, success: false };
    case Types.SUCCESS_EDIT_VINCULO:
      return {
        ...state,
        loadingAction: false,
        error: false,
        success: true,
        data: state.data.map((item) => {
          if (item.idVinculo === action.payload.idVinculo) {
            return action.payload;
          }
          return item;
        }),
      };
    case Types.FAILURE_EDIT_VINCULO:
      customToast.error(action.message);
      return {
        ...state,
        loadingAction: false,
        success: false,
        error: true,
      };

    // // DEL
    case Types.REQUEST_ADD_VINCULO:
      return { ...state, error: false, success: false };
    case Types.SUCCESS_DEL_VINCULO:
      return {
        ...state,
        error: false,
        success: true,
        data: state.data.filter((item) => item.idVinculo !== action.idVinculo),
      };
    case Types.FAILURE_DEL_VINCULO:
      customToast.error(action.message);
      return {
        ...state,
        success: false,
        error: true,
      };

    default:
      return state;
  }
}
