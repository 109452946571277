import React from 'react';
import { Text } from "office-ui-fabric-react/lib/Text";
import {
    GroupItems,
    GroupItemUmTerco,
} from '~/components/FormGroup';
import { InputCheckbox } from '~/components/Forms';

const divStyle = {
    marginTop: 36
}

interface IInfoAccessPros {
    email: string;
    password: string;
    grantedAccess: boolean;
    handleGrantedAccess: () => void;
}

const InfoAccess: React.FC<IInfoAccessPros> = (props) => {
    const { email, password, grantedAccess, handleGrantedAccess } = props;
    
    return (
        <div>
            <InputCheckbox
                checked={grantedAccess}
                onChange={handleGrantedAccess}
                name="inactivate"
                color="primary"
                label={<span style={{ fontSize: 14 }}>Conceder acesso</span>}
            />
            <div style={divStyle}>
                <GroupItems title="Login de acesso">
                    <GroupItemUmTerco>
                        <Text variant="medium">{email}</Text>
                    </GroupItemUmTerco>
                </GroupItems>
                <GroupItems title="Senha temporária">
                    <GroupItemUmTerco>
                        <Text variant="medium">{password}</Text>
                    </GroupItemUmTerco>
                </GroupItems>
                <Text variant="medium">A senha deverá ser trocada no primeiro acesso.</Text>
            </div>
        </div>
    );
};

export default InfoAccess;