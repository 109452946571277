// #region Imports
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter, RouteComponentProps } from "react-router-dom";

import moment from "moment";
//FluentUI
import { Stack } from "office-ui-fabric-react/lib/Stack";
import {
  DetailsListLayoutMode,
  SelectionMode,
  Selection,
  IColumn,
  IDetailsListProps,
  IDetailsRowStyles,
  DetailsRow,
} from "office-ui-fabric-react/lib/DetailsList";
import { ShimmeredDetailsList } from "office-ui-fabric-react/lib/ShimmeredDetailsList";
import {
  Label,
  Text,
  IconButton,
  PrimaryButton,
  FontIcon,
  ITextStyles,
  Persona,
  PersonaSize,
  IButtonStyles,
  PersonaPresence,
  MessageBar,
  MessageBarType,
} from "office-ui-fabric-react";
import { Spinner, SpinnerSize } from "office-ui-fabric-react/lib/Spinner";

import {
  InputAutocomplete,
  InputCheckbox,
  InputText,
} from "~/components/Forms";
import Chip from "~/components/Chip";
import { CommandBarButton, OutlinedButton } from "~/components/Buttons";
import Dropdown, { MenuDropdown } from "~/components/layout/Dropdown";
import CustomDialog from "~/components/CustomDialog";

import MenuItem from "@material-ui/core/MenuItem";
//Estilos
import { ListContainer, StatusTeam, WrapperAutoComplete } from "./styles";

import {
  AvaliadosType,
  DataTypes as DataTypesCalibration,
} from "~/store/ducks/calibration/types";
import { Creators as getMeetingById } from "~/store/ducks/calibration";
import { Creators as getMeetingByIdNivel } from "~/store/ducks/calibration";
import { Creators as ativaAvaliador } from "~/store/ducks/calibration";
import { Creators as desativaAvaliador } from "~/store/ducks/calibration";
import { Creators as initCalibration } from "~/store/ducks/calibration";
import { Creators as getComments } from "~/store/ducks/calibration";
import { Creators as addComment } from "~/store/ducks/calibration";
import { Creators as toggleVisibilityFunc } from "~/store/ducks/calibration";
import { Creators as forceCalibration } from "~/store/ducks/calibration";
import { Creators as getAvaliationById } from "~/store/ducks/avaliation";
import { Creators as avaliadoUp } from "~/store/ducks/avaliation";
import { Creators as avaliadoDown } from "~/store/ducks/avaliation";
import { DataTypes as DataTypesAvaliation } from "~/store/ducks/avaliation/types";

import { DataTypes as DataTypesChart } from "~/store/ducks/admin/chart/types";
import { Creators as getChart } from "~/store/ducks/admin/chart";

import { RootState } from "~/store/ducks";

import DialogHistory from "../DialogHistory";
import DialogAnotation from "../DialogAnotation";

import MuiAccordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import { withStyles } from "@material-ui/core/styles";
import { FilterType, ICalibrationActive, IDialogCalibrating } from "..";
import Colors from "~/assets/js/colors";
import { getAbbreviatedName } from "~/utils/abbreviatedName";
import { ChartType } from "~/store/ducks/admin/chart/types";
import TreeView from "~/components/TreeView";
import { DialogPerformance } from "../DialogPerformance";
import { GroupItems, TitleField } from "~/components/FormGroup";

//#endregion

interface ICalibrationState {
  columns: IColumn[];
  dialogAnotation: {
    isOpen: boolean;
    idCicloReuniaoAvaliado: number | null;
    nomeCompleto: string;
  };
  selectionDetails: string;
  selectionCount: number;
  indexSelected: number | undefined;
  isLoadingAutocomplete: boolean;
  isOpenHistory: boolean;
  isOpenFilter: boolean;
  funcionarioSelected: {
    idFuncionario: number | null;
    nomeCompleto: string;
  };
  dialogAvaliacao: {
    isOpen: boolean;
    title: string;
    idCicloAvaliacao: number | null;
  };
  isDialogTreeViewOpen: boolean;
  isDialogForcarCalibracaoOpen: boolean;
  itemsTreeViewSelected: Partial<ChartType>[];
}

interface IPropsCalibrations extends RouteComponentProps {
  ano?: number;
  calibrationState: DataTypesCalibration;
  avaliation: DataTypesAvaliation;
  areas: DataTypesChart;

  state: {
    nivel: string;
    lockedNivel: boolean;
    filter: FilterType;
    isFiltering: boolean;
    items: AvaliadosType[];
    calibrationActive: ICalibrationActive;
    clearSelection: boolean;
    _selection: Selection;
  };
  setNivel: (value: string) => void;
  setLockedNivel: (value: boolean) => void;
  setFilter: (value: FilterType) => void;
  setIsFiltering: (value: boolean) => void;
  setItems: (values: AvaliadosType[]) => void;
  setCalibrationActive: (values: ICalibrationActive) => void;
  setComentariosCalib: (values: string) => void;
  setDialogCalibration: (values: IDialogCalibrating) => void;

  getMeetingById: (idCicloReuniao: number) => void;
  getMeetingByIdNivel: (idCicloReuniao: number, nivel: string) => void;
  ativaAvaliador: (
    idCicloReuniao: number,
    nivel: string,
    idCicloReuniaoAvaliador: number
  ) => void;
  desativaAvaliador: (
    idCicloReuniao: number,
    nivel: string,
    idCicloReuniaoAvaliador: number
  ) => void;
  initCalibration: (
    idCicloReuniao: number,
    idCicloReuniaoAvaliado: number,
    isRunning: boolean
  ) => void;
  getComments: (idCicloReuniao: number, idCicloReuniaoAvaliado: number) => void;
  addComment: (
    idCicloReuniao: number,
    idCicloReuniaoAvaliado: number,
    comentarios: string
  ) => void;
  getAvaliationById: (idAvaliacao: number) => void;
  toggleVisibilityFunc: (idCicloReuniaoAvaliado: number) => void;
  avaliadoUp: (idCicloReuniao: number, idCicloReuniaoAvaliado: number) => void;
  avaliadoDown: (
    idCicloReuniao: number,
    idCicloReuniaoAvaliado: number
  ) => void;
  forceCalibration: (idCicloReuniao: number, nivel: string) => void;

  getChart: (
    search?: string,
    filter?: boolean | null,
    treeview?: boolean
  ) => void;
  match: any;
  history: RouteComponentProps["history"];
}

const Accordion = withStyles({
  root: {
    padding: "0px !important",
    paddingBottom: 10,
    boxShadow: "none",

    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {
    borderBottom: 0,
  },
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    borderBottom: "1px solid #CFD3DA",
    height: 50,
    minHeight: "50px !important",
    "&:hover": {
      backgroundColor: "#F3F2F1",
    },
    expanded: {},
  },
})(MuiAccordionSummary);

const baseURL = process.env.REACT_APP_BASE_URL;

class Calibrations extends Component<IPropsCalibrations, ICalibrationState> {
  private timeout: number;

  constructor(props: IPropsCalibrations) {
    super(props);
    //#region Colunas
    const columns: IColumn[] = [
      {
        key: "column1",
        name: "Funcionário",
        fieldName: "funcionario.nomeCompleto",
        minWidth: 300,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "string",
        styles: {
          root: {
            position: "sticky",
            left: 0,
            top: 50,
            backgroundColor: "#FFFFFF",
            zIndex: 9,
          },
        },
        onRender: (values: AvaliadosType) => {
          const { idCicloReuniao } = this.props.match.params;
          const { imagem, nomeCompleto, status, isVisible } =
            values.funcionario ?? {};
          const imagePath = `${baseURL}uploads/${imagem}`;
          const initials = getAbbreviatedName(nomeCompleto);
          const qtdNivel =
            this.props.calibrationState.calibration?.niveis.length ?? 0;
          const nivelSelected = Number(this.props.state.nivel);
          const statusReuniao = this.props.calibrationState.calibration?.reuniao
            .status;

          return (
            <Stack
              horizontal
              horizontalAlign="space-between"
              verticalAlign="center"
              styles={{ root: { whiteSpace: "break-spaces", width: "100%" } }}
            >
              <Stack horizontal verticalAlign="center">
                {
                  // Se o nivelSelected for igual a T o valor será NaN e não irá mostrar as opções de subir/descer nível
                  nivelSelected &&
                    qtdNivel > 1 &&
                    statusReuniao !== "Finalizada" && (
                      <IconButton
                        className="menuChangeNivel"
                        menuProps={{
                          items: [
                            {
                              key: "subirNivel",
                              text: "Subir nível",
                              iconProps: {
                                iconName: "Up",
                                styles: { root: { fontSize: 14 } },
                              },
                              disabled: nivelSelected === 1,
                              onClick: () =>
                                this.props.avaliadoUp(
                                  idCicloReuniao,
                                  values.idCicloReuniaoAvaliado
                                ),
                            },
                            {
                              key: "descerNivel",
                              text: "Descer nível",
                              iconProps: {
                                iconName: "Down",
                                styles: { root: { fontSize: 14 } },
                              },
                              disabled: nivelSelected === qtdNivel,
                              onClick: () =>
                                this.props.avaliadoDown(
                                  idCicloReuniao,
                                  values.idCicloReuniaoAvaliado
                                ),
                            },
                          ],
                        }}
                        iconProps={{ iconName: "MoreVertical" }}
                        title="Opções"
                        ariaLabel="Opções"
                        menuIconProps={{ style: { display: "none" } }}
                        styles={{
                          root: {
                            width: "10px !important",
                            marginRight: 5,
                            visibility: "hidden",
                          },
                        }}
                      />
                    )
                }

                {this.props.calibrationState.loadingChanginNivel ? (
                  <Spinner size={SpinnerSize.medium} />
                ) : (
                  <Persona
                    imageUrl={imagePath}
                    imageInitials={initials}
                    initialsColor={Colors.primary}
                    size={PersonaSize.size32}
                    presence={
                      status === "D"
                        ? PersonaPresence.offline
                        : PersonaPresence.online
                    }
                    styles={{
                      root: {
                        width: 32,
                        marginRight: 10,
                        selectors: {
                          "& .ms-Persona-initials": {
                            filter:
                              status === "D" ? "grayscale(1)" : "grayscale(0)",
                          },
                        },
                      },
                    }}
                  />
                )}

                <Text
                  variant="medium"
                  styles={{ root: { fontWeight: "inherit" } }}
                >
                  {values?.funcionario.nomeCompleto}
                </Text>
              </Stack>
              <Stack horizontal>
                <IconButton
                  className="btnEye"
                  iconProps={{ iconName: isVisible ? "Hide" : "RedEye" }}
                  title={isVisible ? "Ocultar" : "Mostrar"}
                  ariaLabel={isVisible ? "Ocultar" : "Mostrar"}
                  styles={{ root: { visibility: "hidden" } }}
                  onClick={() =>
                    this.props.toggleVisibilityFunc(
                      values.idCicloReuniaoAvaliado
                    )
                  }
                />
                <IconButton
                  iconProps={{
                    iconName: values.possuiComentario
                      ? "CommentActive"
                      : "CommentAdd",
                  }}
                  title={
                    values.possuiComentario
                      ? "Ver comentários"
                      : "Adicionar comentário"
                  }
                  ariaLabel={
                    values.possuiComentario
                      ? "Ver comentários"
                      : "Adicionar comentário"
                  }
                  styles={{
                    root: values.possuiComentario && { color: "#0078D4" },
                  }}
                  onClick={() => {
                    this.props.getComments(
                      idCicloReuniao,
                      values.idCicloReuniaoAvaliado
                    );
                    this.setState({
                      dialogAnotation: {
                        isOpen: true,
                        idCicloReuniaoAvaliado: values.idCicloReuniaoAvaliado,
                        nomeCompleto: values.funcionario.nomeCompleto,
                      },
                    });
                  }}
                />
              </Stack>
            </Stack>
          );
        },
      },
      {
        key: "column2",
        name: "Gestor",
        fieldName: "gestor.nomeCompleto",
        minWidth: 230,
        maxWidth: 230,
        isRowHeader: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        onRender: (item: AvaliadosType) => (
          <Stack
            horizontal
            verticalAlign="center"
            styles={{ root: { whiteSpace: "break-spaces" } }}
          >
            <Text variant="medium" styles={{ root: { fontWeight: "inherit" } }}>
              {item?.gestor?.nomeCompleto}
            </Text>
          </Stack>
        ),
      },
      {
        key: "column3",
        name: "Idade",
        fieldName: "funcionario.idade",
        minWidth: 50,
        maxWidth: 50,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        styles: {
          root: {
            position: "sticky",
            top: 50,
          },
          cellTitle: {
            justifyContent: "center",
          },
        },
        onRender: (values: AvaliadosType) => (
          <Stack
            horizontal
            horizontalAlign="center"
            styles={{ root: { width: "100%" } }}
          >
            {values?.funcionario.idade}
          </Stack>
        ),
      },
      {
        key: "column4",
        name: "Tempo Adm",
        fieldName: "funcionario.tempo",
        minWidth: 80,
        maxWidth: 80,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        styles: {
          root: {
            position: "sticky",
            top: 50,
          },
          cellTitle: {
            justifyContent: "center",
          },
        },
        onRender: (values: AvaliadosType) => (
          <Stack
            horizontal
            horizontalAlign="center"
            styles={{ root: { width: "100%" } }}
          >
            {values?.funcionario.tempo} {values?.funcionario.tempoUnidade}
          </Stack>
        ),
      },
      {
        key: "column5",
        name: "Cargo",
        fieldName: "cargo.titulo",
        minWidth: 200,
        maxWidth: 250,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        isPadded: true,
        styles: {
          root: {
            position: "sticky",
            top: 50,
          },
        },
        onRender: (item: AvaliadosType) => (
          <Stack
            horizontal
            verticalAlign="center"
            styles={{ root: { whiteSpace: "break-spaces" } }}
          >
            <Text variant="medium" styles={{ root: { fontWeight: "inherit" } }}>
              {item?.cargo.titulo}
            </Text>
          </Stack>
        ),
      },
      {
        key: "column6",
        name: "Tempo (M)",
        fieldName: "cargo.tempo",
        minWidth: 75,
        maxWidth: 75,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        styles: {
          root: {
            position: "sticky",
            top: 50,
          },
          cellTitle: {
            justifyContent: "center",
          },
        },
        onRender: (values: AvaliadosType) => (
          <Stack
            horizontal
            horizontalAlign="center"
            styles={{ root: { width: "100%" } }}
          >
            {values?.cargo.tempo}
          </Stack>
        ),
      },
      {
        key: "column7",
        name: "Histórico",
        fieldName: "avaliacaoAnterior",
        minWidth: 60,
        maxWidth: 60,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        styles: {
          root: {
            backgroundColor: "#F8F0F0",
            position: "sticky",
            top: 50,
          },
        },
        onRender: (item: AvaliadosType) => {
          return (
            <Stack
              horizontal
              verticalAlign="center"
              horizontalAlign="center"
              styles={{ root: { width: "100%" } }}
            >
              <IconButton
                iconProps={{ iconName: "View" }}
                title={"Visualizar avaliação anterior"}
                ariaLabel={"Visualizar avaliação anterior"}
                styles={{ root: { fontSize: 14 } }}
                onClick={() =>
                  this.setState({
                    isOpenHistory: true,
                    funcionarioSelected: {
                      idFuncionario: item.idFuncionario,
                      nomeCompleto: item.funcionario.nomeCompleto,
                    },
                  })
                }
              />
            </Stack>
          );
        },
      },
      {
        key: "column8",
        name: "Performance Gestor",
        fieldName: "avaliacaoGestor.performancegestor.nivel",
        minWidth: 150,
        maxWidth: 150,
        isRowHeader: true,
        isResizable: true,
        isSorted: true,
        isSortedDescending: true,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        styles: {
          root: {
            backgroundColor: "#E8F3ED",
            position: "sticky",
            top: 50,
            selectors: {
              "&:hover": {
                backgroundColor: "#DFEBE5",
              },
            },
          },
        },
        onRender: (item: AvaliadosType) => {
          if (item.avaliacaoGestor?.performancegestor) {
            return (
              <Stack
                horizontal
                verticalAlign="center"
                horizontalAlign="space-between"
                styles={{ root: { width: "100%" } }}
              >
                <Stack horizontal>
                  <StatusTeam
                    bgColor={item.avaliacaoGestor.performancegestor?.cor}
                  />
                  <Text
                    variant="medium"
                    styles={{ root: { fontWeight: "inherit" } }}
                  >
                    {item.avaliacaoGestor.performancegestor?.descNivel}
                  </Text>
                </Stack>
                <IconButton
                  iconProps={{ iconName: "View" }}
                  onClick={() => {
                    this.setState({
                      dialogAvaliacao: {
                        isOpen: true,
                        title: `${item.funcionario.nomeCompleto} - ${this.props.ano}`,
                        idCicloAvaliacao:
                          item.avaliacaoGestor?.idCicloAvaliacao ?? null,
                      },
                    });
                  }}
                />
              </Stack>
            );
          } else {
            return "";
          }
        },
      },
      {
        key: "column9",
        name: "Potencial",
        fieldName: "avaliacaoGestor.potencial",
        minWidth: 100,
        maxWidth: 100,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        isPadded: true,
        styles: {
          root: {
            backgroundColor: "#E8F3ED",
            position: "sticky",
            top: 50,
            selectors: {
              "&:hover": {
                backgroundColor: "#DFEBE5",
              },
            },
          },
        },
        onRender: (item: AvaliadosType) => {
          if (item.avaliacaoGestor) {
            return (
              <Stack
                horizontal
                verticalAlign="center"
                styles={{ root: { whiteSpace: "break-spaces" } }}
              >
                <Text
                  variant="medium"
                  styles={{ root: { fontWeight: "inherit" } }}
                >
                  {item.avaliacaoGestor.potencial}
                </Text>
              </Stack>
            );
          } else {
            return "";
          }
        },
      },
      {
        key: "column10",
        name: "Sucessão",
        fieldName: "avaliacaoGestor.sucessao",
        minWidth: 100,
        maxWidth: 100,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        isPadded: true,
        styles: {
          root: {
            backgroundColor: "#E8F3ED",
            position: "sticky",
            top: 50,
            selectors: {
              "&:hover": {
                backgroundColor: "#DFEBE5",
              },
            },
          },
        },
        onRender: (item: AvaliadosType) => {
          if (item.avaliacaoGestor) {
            return (
              <Stack
                horizontal
                verticalAlign="center"
                styles={{ root: { whiteSpace: "break-spaces" } }}
              >
                <Text
                  variant="medium"
                  styles={{ root: { fontWeight: "inherit" } }}
                >
                  {item.avaliacaoGestor?.sucessao}
                </Text>
              </Stack>
            );
          }
        },
      },
      {
        key: "column11",
        name: "Calibrada",
        fieldName: "performance.descNivel",
        minWidth: 110,
        maxWidth: 110,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        isPadded: true,
        styles: {
          root: {
            position: "sticky",
            top: 50,
          },
        },
        onRender: (item: AvaliadosType) => {
          const { idCicloReuniao } = this.props.match.params;
          const { flagPermiteCalibracao } = item.funcionario;
          if (item.performance) {
            const status = this.props.calibrationState.calibration?.reuniao
              .status;
            return (
              <Stack horizontal verticalAlign="center">
                <StatusTeam bgColor={item.performance?.cor} />
                <Text
                  variant="medium"
                  styles={{ root: { fontWeight: "inherit" } }}
                >
                  {item.performance?.descNivel}
                </Text>
                {flagPermiteCalibracao && (
                  <IconButton
                    iconProps={{
                      iconName: status === "Finalizada" ? "View" : "Edit",
                    }}
                    title={status ? "Visualizar avaliação" : "Editar avaliação"}
                    ariaLabel={
                      status ? "Visualizar avaliação" : "Editar avaliação"
                    }
                    styles={{
                      root: { marginLeft: "5px !important", fontSize: 14 },
                    }}
                    onClick={() => {
                      this.props.initCalibration(
                        idCicloReuniao,
                        item.idCicloReuniaoAvaliado,
                        false
                      );

                      const index = this.props.state.items.findIndex(
                        (it) =>
                          it.idCicloReuniaoAvaliado ===
                          item.idCicloReuniaoAvaliado
                      );
                      this.props.state._selection.setIndexSelected(
                        index,
                        true,
                        false
                      );

                      this.props.setCalibrationActive({
                        isActive: true,
                        idCicloReuniaoAvaliado: item.idCicloReuniaoAvaliado,
                        canFinish: true,
                        collapseInfo: false,
                      });

                      this.props.setComentariosCalib("");
                      this.props.setIsFiltering(true);
                    }}
                  />
                )}
              </Stack>
            );
          } else {
            if (flagPermiteCalibracao) {
              return (
                <Stack horizontal horizontalAlign="center">
                  <OutlinedButton
                    text="Iniciar Calibração"
                    style={{ width: 130 }}
                    onClick={() => {
                      this.props.initCalibration(
                        idCicloReuniao,
                        item.idCicloReuniaoAvaliado,
                        false
                      );

                      const index = this.props.state.items.findIndex(
                        (it) =>
                          it.idCicloReuniaoAvaliado ===
                          item.idCicloReuniaoAvaliado
                      );
                      this.props.state._selection.setIndexSelected(
                        index,
                        true,
                        false
                      );

                      this.props.setCalibrationActive({
                        canFinish: false,
                        isActive: true,
                        idCicloReuniaoAvaliado: item.idCicloReuniaoAvaliado,
                        collapseInfo: false,
                      });
                      this.props.setComentariosCalib("");
                    }}
                  />
                </Stack>
              );
            } else {
              return "";
            }
          }
        },
      },
      {
        key: "column12",
        name: "Potencial",
        fieldName: "potencial",
        minWidth: 100,
        maxWidth: 100,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        isPadded: true,
        styles: {
          root: {
            position: "sticky",
            top: 50,
          },
        },
        onRender: (item: AvaliadosType) => (
          <Stack
            horizontal
            verticalAlign="center"
            styles={{ root: { whiteSpace: "break-spaces" } }}
          >
            <Text variant="medium" styles={{ root: { fontWeight: "inherit" } }}>
              {item.potencial}
            </Text>
          </Stack>
        ),
      },
      {
        key: "column13",
        name: "Sucessão",
        fieldName: "sucessao",
        minWidth: 100,
        maxWidth: 100,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        isPadded: true,
        styles: {
          root: {
            position: "sticky",
            top: 50,
          },
        },
        onRender: (item: AvaliadosType) => (
          <Stack
            horizontal
            verticalAlign="center"
            styles={{ root: { whiteSpace: "break-spaces" } }}
          >
            <Text variant="medium" styles={{ root: { fontWeight: "inherit" } }}>
              {item.sucessao}
            </Text>
          </Stack>
        ),
      },
    ];
    //#endregion

    this.state = {
      columns: columns,
      dialogAnotation: {
        isOpen: false,
        idCicloReuniaoAvaliado: null,
        nomeCompleto: "",
      },
      selectionDetails: "",
      selectionCount: 0,
      indexSelected: undefined,
      isLoadingAutocomplete: false,
      isOpenHistory: false,
      isOpenFilter: false,
      funcionarioSelected: {
        idFuncionario: null,
        nomeCompleto: "",
      },
      dialogAvaliacao: {
        isOpen: false,
        title: "",
        idCicloAvaliacao: null,
      },
      isDialogTreeViewOpen: false,
      isDialogForcarCalibracaoOpen: false,
      itemsTreeViewSelected: [],
    };

    this.timeout = 0;
  }

  componentDidUpdate(
    prevProps: IPropsCalibrations,
    prevState: ICalibrationState
  ) {
    const {
      errorLoading: prevErrorLoading,
    } = prevProps.calibrationState.avaliadoSelected;
    const {
      errorLoading: nextErrorLoading,
    } = this.props.calibrationState.avaliadoSelected;

    if (prevErrorLoading !== nextErrorLoading) {
      if (nextErrorLoading) {
        this.props.setDialogCalibration({
          isOpen: false,
          idCicloReuniaoAvaliado: null,
        });
      }
    }

    // ABRIR NIVEL AUTOMÁTICAMENTE QUANDO TIVER SÓ UM NIVEL
    // if (prevProps.calibrationState.successLoading !== this.props.calibrationState.successLoading) {
    //   if (this.props.calibrationState.successLoading) {
    //     if (prevProps.calibrationState.successLoadingNivel !== this.props.calibrationState.successLoadingNivel) {
    //       if (this.props.calibrationState.successLoadingNivel === true && this.props.calibrationState.calibration?.niveis.length === 1) {
    //         const nivel = this.props.calibrationState.calibration?.niveis[0].nivel;
    //         this.props.getMeetingByIdNivel(this.props.match.params.idCicloReuniao, nivel);
    //         this.props.setNivel(String(nivel))
    //         this.props.setLockedNivel(true)
    //       }
    //     }
    //   }
    // }

    if (
      prevProps.calibrationState.successLoadingNivel !==
      this.props.calibrationState.successLoadingNivel
    ) {
      if (this.props.calibrationState.successLoadingNivel) {
        this.props.setItems(
          this.props.calibrationState.calibration?.avaliados ?? []
        );
      }
    }

    if (
      prevProps.calibrationState.avaliadoSelected.successLoading !==
      this.props.calibrationState.avaliadoSelected.successLoading
    ) {
      if (this.props.calibrationState.avaliadoSelected.successLoading) {
        this.props.setItems(this.props.state.items.map((item) => item) ?? []);
      }
    }

    if (
      prevProps.calibrationState.successAnotations !==
      this.props.calibrationState.successAnotations
    ) {
      if (this.props.calibrationState.successAnotations) {
        this.setState({
          dialogAnotation: {
            idCicloReuniaoAvaliado: null,
            isOpen: false,
            nomeCompleto: "",
          },
        });
        this.props.setComentariosCalib("");
      }
    }

    if (prevProps.state.clearSelection !== this.props.state.clearSelection) {
      this.props.state._selection.setAllSelected(false);
    }

    if (
      prevProps.calibrationState.errorAnotations !==
      this.props.calibrationState.errorAnotations
    ) {
      if (this.props.calibrationState.errorAnotations) {
        this.setState({
          dialogAnotation: {
            isOpen: false,
            idCicloReuniaoAvaliado: null,
            nomeCompleto: "",
          },
        });
      }
    }

    if (
      prevProps.calibrationState.successForcarCalibracao !==
      this.props.calibrationState.successForcarCalibracao
    ) {
      if (this.props.calibrationState.successForcarCalibracao) {
        this.props.setItems(
          this.props.calibrationState.calibration?.avaliados ?? []
        );
      }
    }

    //TOOGLE VISIBILITY
    if (
      prevProps.calibrationState.changeVisibility !==
      this.props.calibrationState.changeVisibility
    ) {
      // console.log(this.props.state.items)
      // console.log(this.props.calibrationState.calibration?.avaliados?.filter(item => {
      //   const showFunc = this.props.state.items.some(it => it.idCicloReuniaoAvaliado === item.idCicloReuniaoAvaliado)
      //   return (item.funcionario.isVisible === true && showFunc)
      // }))
      if (this.props.state.filter.showHidden) {
        this.props.setItems(
          this.props.calibrationState.calibration?.avaliados?.filter((item) => {
            return this.props.state.items.some(
              (it) => it.idCicloReuniaoAvaliado === item.idCicloReuniaoAvaliado
            );
          }) ?? []
        );
      } else {
        this.props.setItems(
          this.props.calibrationState.calibration?.avaliados?.filter((item) => {
            const showFunc = this.props.state.items.some(
              (it) => it.idCicloReuniaoAvaliado === item.idCicloReuniaoAvaliado
            );
            return item.funcionario.isVisible === true && showFunc;
          }) ?? []
        );
      }
    }

    // RE-RENDERING GRID WHEN AVALIADO CHANGE NIVEL
    if (
      prevProps.calibrationState.successChanginNivel !==
      this.props.calibrationState.successChanginNivel
    ) {
      if (this.props.calibrationState.successChanginNivel) {
        this.props.setItems(
          this.props.calibrationState.calibration?.avaliados?.filter((item) => {
            return this.props.state.items.some(
              (it) => it.idCicloReuniaoAvaliado === item.idCicloReuniaoAvaliado
            );
          }) ?? []
        );
      }
    }
  }

  //#region  Funções do filtro

  compareString = (item: string, itemCompared: string): boolean =>
    item.toLowerCase().indexOf(itemCompared.toLowerCase()) > -1;

  clearFilter = () => {
    this.setState({
      isOpenFilter: !this.state.isOpenFilter,
    });
    this.props.setItems(
      this.props.calibrationState.calibration?.avaliados ?? []
    );
    this.props.setFilter({
      gestor: "",
      cargo: "",
      tempo: "",
      idTabPerformance: "",
      idTabPerformanceGestor: "",
      area: {
        idArea: null,
        nomeLocal: "",
      },
      showHidden: false,
    });
    this.props.setIsFiltering(false);
  };

  search = (text: string) => {
    if (text.trim()) {
      clearTimeout(this.timeout);
      this.timeout = window.setTimeout(() => {
        this.props.getChart(text, true, false);
        // this.setState({ allAreasFiltered: this.props.areas.data.filter(item => this.compareString(item.nomeLocal, text)) })
      }, 700);
    }
  };

  handleFilter = () => {
    const { itemsTreeViewSelected } = this.state;
    const {
      cargo,
      gestor,
      tempo,
      idTabPerformance,
      idTabPerformanceGestor,
      area,
      showHidden,
    } = this.props.state.filter;
    const { avaliados } = this.props.calibrationState.calibration ?? {};

    let result = avaliados;

    if (!showHidden) {
      result = result?.filter((item) => item.funcionario.isVisible === true);
    }

    result = result?.filter((item) =>
      this.compareString(item.cargo.titulo, cargo)
    );

    result = result?.filter((item) =>
      this.compareString(item.gestor?.nomeCompleto ?? "", gestor)
    );

    if (Number(tempo)) {
      result = result?.filter((item) => item.funcionario.tempo > Number(tempo));
    }
    if (idTabPerformance) {
      result = result?.filter(
        (item) => item.idTabPerformance === Number(idTabPerformance)
      );
    }
    if (idTabPerformanceGestor) {
      result = result?.filter(
        (item) =>
          item.avaliacaoGestor?.idTabPerformanceGestor ===
          Number(idTabPerformanceGestor)
      );
    }

    if (area?.idArea !== null && area?.idArea !== undefined) {
      if (area?.idArea === 0) {
        result = result?.filter((item) => {
          return itemsTreeViewSelected.some((area) => {
            return item?.areaAtual.idArea === area.idArea;
          });
        });
      } else {
        result = result?.filter(
          (item) => item?.areaAtual.idArea === area?.idArea
        );
      }
    }

    this.props.setItems(result ?? []);
    this.props.setIsFiltering(true);
  };

  //#endregion

  render() {
    const {
      columns,
      isOpenFilter,
      isOpenHistory,
      funcionarioSelected,
      dialogAnotation,
      dialogAvaliacao,
      isDialogTreeViewOpen,
      itemsTreeViewSelected,
      isDialogForcarCalibracaoOpen,
    } = this.state;
    const {
      calibrationState,
      getMeetingByIdNivel,
      desativaAvaliador,
      ativaAvaliador,
      match,
      addComment,
      forceCalibration,
      state,
      areas,
    } = this.props;

    const { nivel, lockedNivel, filter, items } = state;
    const { calibration } = calibrationState;
    const { reuniao, performances, niveis, avaliadores, avaliados, curva } =
      calibration ?? {};
    const { lider, rh, hrbp, status, dataHoraInicio } = reuniao ?? {};

    const { params } = match;
    const { idCicloReuniao } = params;

    const allAvaliadosCurva = curva?.flatMap((item) => item.curvaAjustada);
    const totalAvaliadosCurva = allAvaliadosCurva?.reduce(
      (total, item) => total + (item?.idTabPerformance !== null ? 1 : 0),
      0
    );

    const labelGroup = [
      {
        icon: "DateTime",
        label: "Data/Hora de Início",
        value: dataHoraInicio
          ? moment(dataHoraInicio).utc().format("DD/MM/YYYY HH:mm")
          : "",
      },
      {
        icon: "Contact",
        label: "Líder",
        value: lider?.nomeCompleto ?? "",
      },
      {
        icon: "Flag",
        label: "Status",
        value: status ?? "",
      },
      {
        icon: "Contact",
        label: "HR Business Partner",
        value: hrbp?.nomeCompleto ?? "",
      },
      {
        icon: "Contact",
        label: "Responsável RH",
        value: rh?.nomeCompleto ?? "",
      },
    ];

    if (allAvaliadosCurva?.length) {
      labelGroup.push({
        icon: "ProgressRingDots",
        label: "Calibrados / Total",
        value: `${totalAvaliadosCurva} / ${allAvaliadosCurva?.length}`,
      });
    }

    return (
      <>
        <Stack
          horizontal
          verticalAlign="center"
          styles={{ root: { marginBottom: 10, marginTop: 20 } }}
        >
          <Stack>
            <Label styles={{ root: { marginBottom: 10 } }}>
              Nível Hierárquico
            </Label>
            <Stack horizontal>
              <Stack styles={{ root: { width: 200, marginRight: 10 } }}>
                <Dropdown
                  label=""
                  name="nivel"
                  values={nivel}
                  variant="outlined"
                  handleChange={(e: any) =>
                    this.props.setNivel(String(e.target.value))
                  }
                  disabled={lockedNivel}
                  autofocus
                  fieldHeight={35}
                  styles={{ height: 35 }}
                  paddingSelected="5px 18px"
                >
                  {niveis?.map((item, i) => (
                    <MenuItem key={i} value={item.nivel}>
                      Hierarquia {item.nivel}
                    </MenuItem>
                  ))}
                  <MenuItem value="T">Todos</MenuItem>
                </Dropdown>
              </Stack>
              {!lockedNivel ? (
                <OutlinedButton
                  text="Abrir Nível"
                  style={{ width: 125 }}
                  disabled={nivel === ""}
                  onClick={() => {
                    this.props.setLockedNivel(true);
                    this.props.setCalibrationActive({
                      canFinish: false,
                      idCicloReuniaoAvaliado: null,
                      isActive: false,
                      collapseInfo: false,
                    });
                    getMeetingByIdNivel(idCicloReuniao, nivel);
                  }}
                />
              ) : (
                <OutlinedButton
                  text="Fechar Nível"
                  style={{ width: 125 }}
                  onClick={() => {
                    this.props.setLockedNivel(false);
                    this.props.setIsFiltering(false);
                  }}
                />
              )}
            </Stack>
          </Stack>
        </Stack>
        {lockedNivel && (
          <>
            {calibrationState.loadingData ? (
              <Spinner
                size={SpinnerSize.medium}
                label="Carregando calibração"
                styles={{ root: { height: "calc(100vh - 450px)" } }}
              />
            ) : (
              <>
                {avaliadores?.length! > 0 && (
                  <Accordion defaultExpanded={true}>
                    <AccordionSummary
                      expandIcon={
                        <FontIcon
                          iconName="ChevronDown"
                          style={{ fontSize: 16 }}
                        />
                      }
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Text variant="mediumPlus" styles={textStyles}>
                        Avaliadores
                      </Text>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Stack horizontal styles={{ root: { flexWrap: "wrap" } }}>
                        {avaliadores?.map((item, i) => (
                          <Chip
                            key={i}
                            nome={item.funcionario?.nomeCompleto}
                            imagem={
                              item.funcionario?.imagem
                                ? `${baseURL}uploads/${item.funcionario?.imagem}`
                                : ""
                            }
                            disabled={item.flagEliminado}
                            disabledChip={status === "Finalizada"}
                            handleClick={() => {
                              console.log(nivel);
                              if (item.flagEliminado) {
                                ativaAvaliador(
                                  idCicloReuniao,
                                  nivel,
                                  item.idCicloReuniaoAvaliador
                                );
                              } else {
                                desativaAvaliador(
                                  idCicloReuniao,
                                  nivel,
                                  item.idCicloReuniaoAvaliador
                                );
                              }
                            }}
                          />
                        ))}
                      </Stack>
                    </AccordionDetails>
                  </Accordion>
                )}
                {avaliados?.length! > 0 && (
                  <Accordion defaultExpanded={true}>
                    <AccordionSummary
                      expandIcon={
                        <FontIcon
                          iconName="ChevronDown"
                          style={{ fontSize: 16 }}
                        />
                      }
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Text variant="mediumPlus" styles={textStyles}>
                        Avaliados
                      </Text>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Stack styles={{ root: { width: "100%" } }}>
                        <Stack horizontal horizontalAlign="space-between">
                          {/* <Label >Avaliados</Label> */}
                          <CommandBarButton
                            iconProps={{ iconName: "Processing" }}
                            text="Forçar Calibração"
                            disabled={status === "Finalizada"}
                            onClick={() =>
                              this.setState({
                                isDialogForcarCalibracaoOpen: true,
                              })
                            }
                          />
                          <MenuDropdown close={isOpenFilter}>
                            <Stack>
                              <Stack horizontal horizontalAlign="space-between">
                                <Label styles={{ root: { fontSize: 16 } }}>
                                  Filtros
                                </Label>
                                <IconButton
                                  iconProps={{ iconName: "ClearFilter" }}
                                  title="Limpar filtro"
                                  ariaLabel="Limpar filtro"
                                  onClick={this.clearFilter}
                                />
                              </Stack>
                              <InputText
                                label="Gestor"
                                type="text"
                                value={filter.gestor}
                                onChange={(e) =>
                                  this.props.setFilter({
                                    ...filter,
                                    gestor: e.target.value,
                                  })
                                }
                                autoFocus
                              />
                              <InputText
                                label="Cargo"
                                type="text"
                                value={filter.cargo}
                                onChange={(e) =>
                                  this.props.setFilter({
                                    ...filter,
                                    cargo: e.target.value,
                                  })
                                }
                              />
                              <WrapperAutoComplete>
                                <InputAutocomplete
                                  value={filter.area as ChartType}
                                  onChange={(_, newValue) => {
                                    this.props.setFilter({
                                      ...filter,
                                      area: newValue!,
                                    });
                                  }}
                                  onInputChange={(_, newInputValue) => {
                                    this.search(newInputValue);
                                  }}
                                  getOptionLabel={(area: ChartType) => {
                                    if (area.nomeLocal) {
                                      return area.nomeLocal;
                                    } else {
                                      return "";
                                    }
                                  }}
                                  getOptionSelected={(option, value) => {
                                    return option.idArea === value.idArea;
                                  }}
                                  options={areas.data}
                                  input={{
                                    idInput: "area",
                                    labelInput: "Área",
                                    autoFocus: true,
                                  }}
                                />
                                <IconButton
                                  iconProps={{ iconName: "Org" }}
                                  styles={IconButtonStyle}
                                  onClick={() => {
                                    this.props.getChart("", true);
                                    this.setState({
                                      isDialogTreeViewOpen: true,
                                    });
                                  }}
                                />
                              </WrapperAutoComplete>
                              <InputText
                                label="Tempo mínimo de empresa"
                                type="number"
                                value={filter.tempo ?? ""}
                                onChange={(e) =>
                                  this.props.setFilter({
                                    ...filter,
                                    tempo: e.target.value,
                                  })
                                }
                              />
                              <Dropdown
                                label="Performance calibrada"
                                name="perfomance"
                                values={filter.idTabPerformance}
                                handleChange={(e: any) =>
                                  this.props.setFilter({
                                    ...filter,
                                    idTabPerformance: e.target.value,
                                  })
                                }
                              >
                                <MenuItem value={""}>Nenhum</MenuItem>
                                {performances?.map((item) => (
                                  <MenuItem
                                    key={`${item.idTabPerformance}`}
                                    value={`${item.idTabPerformance}`}
                                  >
                                    {item.descNivel}
                                  </MenuItem>
                                ))}
                              </Dropdown>
                              <Dropdown
                                label="Performance gestor"
                                name="perfomanceGestor"
                                values={filter.idTabPerformanceGestor}
                                handleChange={(e: any) =>
                                  this.props.setFilter({
                                    ...filter,
                                    idTabPerformanceGestor: e.target.value,
                                  })
                                }
                              >
                                <MenuItem value={""}>Nenhum</MenuItem>
                                {performances?.map((item) => (
                                  <MenuItem
                                    key={`${item.idTabPerformance}`}
                                    value={`${item.idTabPerformance}`}
                                  >
                                    {item.descNivel}
                                  </MenuItem>
                                ))}
                              </Dropdown>
                              <InputCheckbox
                                checked={filter.showHidden}
                                onChange={(e) =>
                                  this.props.setFilter({
                                    ...filter,
                                    showHidden: e.target.checked,
                                  })
                                }
                                name="flagAtiva"
                                color="primary"
                                label="Mostrar ocultos"
                              />

                              <PrimaryButton
                                text="Aplicar Filtro"
                                styles={{
                                  root: {
                                    width: 125,
                                    alignSelf: "flex-end",
                                  },
                                }}
                                onClick={() => {
                                  this.handleFilter();
                                  this.setState({
                                    isOpenFilter: !isOpenFilter,
                                  });
                                }}
                              />
                            </Stack>
                          </MenuDropdown>
                        </Stack>
                        <ListContainer>
                          <ShimmeredDetailsList
                            items={items}
                            className="calibrationTable"
                            enableShimmer={calibrationState.loadingData}
                            columns={columns}
                            selectionMode={SelectionMode.none}
                            selection={this.props.state._selection}
                            getKey={this._getKey}
                            selectionPreservedOnEmptyClick={true}
                            setKey="single"
                            layoutMode={DetailsListLayoutMode.justified}
                            isHeaderVisible={true}
                            onRenderRow={this._onRenderRow}
                            // onRenderDetailsHeader={
                            //   detailsHeaderProps => {
                            //     return (
                            //       <Sticky>
                            //         <DetailsHeader
                            //           {...detailsHeaderProps}
                            //           layoutMode={DetailsListLayoutMode.justified}
                            //         />
                            //       </Sticky>
                            //     );
                            //   }
                            // }
                          />
                        </ListContainer>
                      </Stack>
                    </AccordionDetails>
                  </Accordion>
                )}
              </>
            )}
          </>
        )}
        <DialogHistory
          isOpen={isOpenHistory}
          funcionario={funcionarioSelected}
          onClose={() => this.setState({ isOpenHistory: false })}
        />

        <DialogAnotation
          idCicloReuniao={idCicloReuniao}
          idCicloReuniaoAvaliado={dialogAnotation.idCicloReuniaoAvaliado ?? 0}
          isOpen={dialogAnotation.isOpen}
          loadingData={calibrationState.loadingAnotations}
          onClose={() =>
            this.setState({
              dialogAnotation: {
                isOpen: false,
                idCicloReuniaoAvaliado: null,
                nomeCompleto: "",
              },
            })
          }
          onSuccess={(values) =>
            addComment(
              idCicloReuniao,
              dialogAnotation.idCicloReuniaoAvaliado!,
              values
            )
          }
          readonly={status === "Finalizada"}
          initialValues={{
            nomeCompleto: dialogAnotation.nomeCompleto ?? "",
            comentarios: calibrationState.comentarios,
          }}
        />

        <DialogPerformance
          isOpen={dialogAvaliacao.isOpen}
          title={dialogAvaliacao.title}
          onClose={() =>
            this.setState({
              dialogAvaliacao: {
                isOpen: false,
                title: "",
                idCicloAvaliacao: null,
              },
            })
          }
          idCicloAvaliacao={dialogAvaliacao.idCicloAvaliacao}
        />

        <CustomDialog
          open={isDialogTreeViewOpen}
          title="Escolher área"
          width={850}
          btnConfirmText="Definir"
          onClickCancel={() => this.setState({ isDialogTreeViewOpen: false })}
          onClickConfirm={() => {
            const area: Partial<ChartType> =
              itemsTreeViewSelected.length === 1
                ? itemsTreeViewSelected[0]!
                : {
                    idArea: 0,
                    nomeLocal: "Multiplos áreas",
                  };

            this.props.setFilter({ ...filter, area });
            this.setState({ isDialogTreeViewOpen: false });
          }}
          disabledBtnConfirm={itemsTreeViewSelected.length === 0}
          height={500}
        >
          <TreeView
            columns={[
              {
                name: "Área",
                fieldName: "nomeLocal",
              },
            ]}
            selectionMode="multiple"
            state={areas}
            fieldId="idArea"
            changeItemsSelected={(items) =>
              this.setState({ itemsTreeViewSelected: items })
            }
          />
        </CustomDialog>

        <CustomDialog
          open={isDialogForcarCalibracaoOpen}
          title="Forçar Calibração"
          onClickCancel={() =>
            this.setState({
              isDialogForcarCalibracaoOpen: false,
            })
          }
          onClickConfirm={() => {
            forceCalibration(idCicloReuniao, nivel);
            this.setState({
              isDialogForcarCalibracaoOpen: false,
            });
          }}
          width={640}
        >
          <GroupItems
            noMargins
            stylesWrapperItems={{ justifyContent: "space-between" }}
          >
            <GroupItems title="Reunião" titleRegular>
              <TitleField
                text={
                  calibrationState.calibration?.reuniao.grupo.nomeGrupo ?? ""
                }
                bold
              />
            </GroupItems>
          </GroupItems>
          <MessageBar
            styles={{ root: { marginTop: 10 } }}
            messageBarType={MessageBarType.severeWarning}
          >
            Ao confirmar a operação, o sistema aplicará a avaliação, sucessão e
            potencial definida pelo gestor para os funcionários sem calibração
            realizada.
          </MessageBar>
        </CustomDialog>
      </>
    );
  }

  private _onColumnClick = (
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn
  ): void => {
    const { columns } = this.state;
    const newColumns: IColumn[] = columns.slice();
    let items: any[] = [];
    if (this.props.state.isFiltering) {
      items = this.props.state.items;
    } else {
      items =
        this.props.calibrationState.calibration?.avaliados?.sort(
          (a, b) =>
            (a.avaliacaoGestor?.performancegestor?.nivel ?? 0) -
            (b.avaliacaoGestor?.performancegestor?.nivel ?? 0)
        ) ?? [];
    }
    const currColumn: IColumn = newColumns.filter(
      (currCol) => column.key === currCol.key
    )[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    const newItems = this._sort(
      items,
      currColumn.fieldName!,
      currColumn.isSortedDescending
    );
    this.setState({
      columns: newColumns,
    });
    this.props.setItems(newItems);
  };

  private _getKey(item: any, index?: number): any {
    if (item !== undefined) return item.key;
  }

  private _sort<T>(
    items: T[],
    columnKey: string,
    isSortedDescending?: boolean
  ): T[] {
    const result = (prop: string, obj: T) =>
      prop.split(".").reduce((a: any, b: any) => a[b] ?? "", obj);
    return items.sort((a: T, b: T) =>
      (
        isSortedDescending
          ? result(columnKey, a) < result(columnKey, b)
          : result(columnKey, a) > result(columnKey, b)
      )
        ? 1
        : -1
    );
  }

  private _onRenderRow: IDetailsListProps["onRenderRow"] = (props) => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (props) {
      const stylesForRow = (detailsRowStyleProps: any) => {
        customStyles.checkCell = {
          position: "sticky",
          left: 0,
          zIndex: 9,
          backgroundColor: detailsRowStyleProps.isSelected
            ? "#EDEBE9"
            : "#FFFFFF",
          marginTop: 50,
          display: "flex",
          alignItems: "center",
        };
        customStyles.root = {
          selectors: {
            "& .ms-DetailsRow-fields": {
              opacity: props.item.funcionario.isVisible ? 1 : 0.5,
            },
            "&:hover div.ms-DetailsRow-fields div.ms-DetailsRow-cell": {
              backgroundColor: "#F3F2F1",
            },
            "&:hover div.ms-DetailsRow-fields div.ms-DetailsRow-cell:nth-child(7)": {
              backgroundColor: "#EBE4E4",
            },
            "&:hover div.ms-DetailsRow-fields div.ms-DetailsRow-cell:nth-child(8), &:hover div.ms-DetailsRow-fields div.ms-DetailsRow-cell:nth-child(9), &:hover div.ms-DetailsRow-fields div.ms-DetailsRow-cell:nth-child(10)": {
              backgroundColor: "#DFEBE5",
            },
            "&:hover div.ms-DetailsRow-cell": {
              backgroundColor: "#F3F2F1",
            },
            "&:hover .menuChangeNivel": {
              visibility: "visible",
            },
            ".menuChangeNivel": {
              width: "10px !important",
            },
            "&:hover .btnEye": {
              visibility: "visible",
            },
          },
        };
        if (props?.columns[0].key === "column1") {
          customStyles.cell = {
            display: "flex",
            alignItems: "center",
            selectors: {
              "&:first-child": {
                position: "sticky",
                zIndex: 9,
                left: 0,
                backgroundColor: detailsRowStyleProps.isSelected
                  ? "#EDEBE9"
                  : "#FFFFFF",
              },
              "&:nth-child(3), &:nth-child(4), &:nth-child(6)": {
                paddingLeft: 8,
              },
              "&:nth-child(7)": {
                backgroundColor: detailsRowStyleProps.isSelected
                  ? "#E8E1E1"
                  : "#F8F0F0",
                paddingLeft: 8,
              },
              "&:nth-child(7) div, &:nth-child(8) div, &:nth-child(9) div, &:nth-child(10) div, &:nth-child(11) div, &:nth-child(12) div": {
                fontWeight: detailsRowStyleProps.isSelected
                  ? "inherit"
                  : "normal",
              },
              "&:nth-child(8), &:nth-child(9), &:nth-child(10)": {
                backgroundColor: detailsRowStyleProps.isSelected
                  ? "#DCE8E2"
                  : "#E8F3ED",
              },
            },
          };
        }
        return customStyles;
      };
      return (
        <DetailsRow
          {...props}
          styles={stylesForRow}
          itemIndex={props.itemIndex}
        />
      );
    }
    return null;
  };
}

const textStyles: ITextStyles = {
  root: {
    fontWeight: 500,
    display: "block",
  },
};

const IconButtonStyle: Partial<IButtonStyles> = {
  root: {
    position: "absolute",
    top: 20,
    right: 8,
    width: 24,
    height: 24,
    padding: 8,
  },
  icon: {
    fontSize: 12,
  },
};

const mapStateToProps = (state: RootState) => ({
  calibrationState: state.calibrationReducer,
  performance: state.cyclePerformanceReducer,
  avaliation: state.cycleAvaliationReducer,
  areas: state.chartReducer,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      ...getMeetingById,
      ...getMeetingByIdNivel,
      ...desativaAvaliador,
      ...ativaAvaliador,
      ...initCalibration,
      ...getComments,
      ...addComment,
      ...getAvaliationById,
      ...toggleVisibilityFunc,
      ...avaliadoUp,
      ...avaliadoDown,
      ...getChart,
      ...forceCalibration,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Calibrations)
);
