import styled from 'styled-components';
import Colors, { calcConstrast } from "~/assets/js/colors"

export const Container = styled.div<{ modalView?: boolean }>`
    flex-basis: 100%;
    width: ${({ modalView }) => modalView ? '100%' : 'calc(100% - 250px)'};
    height: ${({ modalView }) => modalView ? '100%' : 'calc(100vh - 50px)'};
    display: flex;
    flex-direction: column;
    overflow: auto;
`;

export const Content = styled.div<{ modalView?: boolean }>`
    padding: ${({ modalView }) => modalView ? '0px' : '20px'};
    /* overflow-y: scroll; */
`
export const ContainerTitle = styled.div`
    border-bottom: 1px solid #CFD3DA;
    padding-bottom: 10px;
`

export const WrapperResults = styled.div`
    /* margin-top: 10px; */
    padding-bottom: 20px;
`

export const WrapperForm = styled.div`
    padding: 10px 0 20px;
    box-sizing: border-box;
    width: 100%;
`

export const Table = styled.table`
    box-sizing: border-box;
    margin: 20px 0;
    border-collapse: separate;
    border-spacing: 0 10px;
    overflow: auto;
    display: block;
`

export const TableIniciativa = styled.table`
    box-sizing: border-box;
    border-collapse: separate;
    border-spacing: 0 10px;
    overflow: auto;
    display: block;
    margin-top: 0px;
    margin-bottom: 0px;

    thead{
        height: 25px;
    }
`

export const Th = styled.th`
    font-size: 12px;
    box-sizing: border-box;
    border-radius: 2px;
    background-color: #757575;
    color: #FFFFFF;
    font-weight: 600;
    text-align: center;
`

export const ThReal = styled.th`
    font-size: 12px;
    box-sizing: border-box;
    border-radius: 2px;
    background-color: ${Colors.black};
    color: #FFFFFF;
    padding: 0 5px;
    font-weight: 600;
    width: 90px;
`

export const ThAvaliacao = styled.th`
    font-size: 12px;
    box-sizing: border-box;
    border-radius: 2px;
    background-color: ${Colors.black};
    color: #FFFFFF;
    padding: 0 5px;
    font-weight: 600;
    text-align: center;
    height: 25px;
`

export const ThComentarios = styled.th`
    font-size: 12px;
    box-sizing: border-box;
    border-radius: 2px;
    background-color: ${Colors.black};
    color: #FFFFFF;
    padding: 5px;
    font-weight: 600;
    width: 130px;
`

export const ThResultado = styled.th`
    font-size: 12px;
    box-sizing: border-box;
    border-radius: 2px;
    background-color: ${Colors.black};
    color: #FFFFFF;
    padding: 5px;
    font-weight: 600;
`

export const ThMeta = styled.th`
    width: 130px;
    font-size: 12px;
    box-sizing: border-box;
    border-radius: 2px;
    background-color: #757575;
    color: #FFFFFF;
    padding: 5px;
    font-weight: 600;
`


export const TdNumber = styled.td`
    border: 1px solid #707070;
    width: 50px;
    min-width: 50px;
    box-sizing: border-box;
    text-align: center;
    font-weight: 600;
    font-size: 22px;
    border-radius: 2px;
`

export const TdPillar = styled.td`
    width: 160px;
    min-width: 160px;
    box-sizing: border-box;
    text-align: left;
    padding: 0 10px;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    font-size: 14px;
`

export const TdGoal = styled.td`
    border: 1px solid black;
    font-size: 12px;
    width: 300px;
    min-width: 300px;
    box-sizing: border-box;
    text-align: left;
    padding: 0 10px;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
`

export const TdPeso = styled.td`
    border: 1px solid #707070;
    font-size: 12px;
    width: 40px;
    min-width: 40px;
    box-sizing: border-box;
    text-align: center;
    border-radius: 2px;
`

export const TdCorpGoal = styled.td`
    border: 1px solid #CCCCCC;
    width: 130px;
    min-width: 130px;
    box-sizing: border-box;
    background-color: #EFEEEE;
    border-radius: 2px;
    div:last-child{
        border-bottom: none !important;
    }
`

export const ContainerCorpoGoal = styled.div`
    height: 20px;
    /* box-sizing: border-box; */
    font-size: 12px;
    display: flex;
    justify-content: space-evenly;
    border-bottom: 1px solid #CCCCCC;
`

export const ContainerPesoGoal = styled.div`
    height: 20px;
    box-sizing: border-box;
    font-size: 12px;
    display: flex;
    justify-content: space-evenly;
    border-bottom: 1px solid #CCCCCC;
`
export const Gap = styled.td`
    width: 10px;
    min-width: 10px;
    border: none !important;
    background-color: #FFFFFF;
`

export const Tr = styled.tr<{ pillarColor: string }>`
    height: 60px;
    &:hover{
        background-color: #FAFAFA;
    }
    td:nth-child(3){
        background-color: ${props => props.pillarColor};
        border-color: ${props => props.pillarColor};
        color: ${props => calcConstrast(props.pillarColor) ? "#FFFFFF" : "#000000"};
    }
    td:nth-child(4){
        border: 1px solid ${props => props.pillarColor};
    }
`

export const Tbody = styled.tbody`

`

export const LeftItemMeta = styled.div`
    width: 35px;
    text-align: right;
`

export const RightItemMeta = styled.div`
    width: 35px;
    text-align: left;
`

export const BlankTd = styled.td`
    background-color: #FFFFFF;
`

export const TdReal = styled.td<{ isTotal?: boolean }>`
   border: 1px solid #CCCCCC;
    font-size: 12px;
    width: 90px;
    min-width: 90px;
    height: 60px;
    box-sizing: border-box;
    text-align: center;
    border-radius: 2px;
    padding: 10px 0;
    ${({ isTotal }) => isTotal ? `
        background-color: #E1DEDE;
     ` : ''}
`

export const TdResult = styled.td<{ isTotal?: boolean }>`
    border: 1px solid #CCCCCC;
    font-size: 12px;
    width: 130px;
    min-width: 130px;
    height: 60px;
    box-sizing: border-box;
    padding: 0 10px;
    border-radius: 2px;
    ${({ isTotal }) => isTotal ? `
        background-color: #E1DEDE;
     ` : ''}
`

export const TdButton = styled.td`
    border: 1px solid ${Colors.primary};
    color: ${Colors.white};
    background-color: ${Colors.primary};
    font-size: 13px;
    font-weight: 600;
    width: 130px;
    box-sizing: border-box;
    text-align: center;
    border-radius: 2px;
    text-align: center;
    padding: 0 10px;
    cursor: pointer;

    &:hover{
        background-color: #264c32;
    }
`

export const TdAvaliacao = styled.td`
    width: 180px;
    max-width: 180px;
    min-width: 180px;
`

export const TdAtitude = styled.td`
    border: 1px solid #CCCCCC;
    border-right: none;
    font-size: 14px;
    font-weight: 600;
    width: 180px;
    max-width: 180px;
    box-sizing: border-box;
    text-align: left;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    padding: 0 10px;
    line-height: 1.5;
`
export const TdDesc = styled.td`
    border: 1px solid #CCCCCC;
    font-size: 11px;
    width: 340px;
    max-width: 340px;
    box-sizing: border-box;
    text-align: left;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    max-height: 60px;
`
export const TrAtitude = styled.tr`
    height: 60px;
`

export const TrComentario = styled.td`
    font-size: 11px;
    width: 230px;
    box-sizing: border-box;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    max-height: 60px;
    display: flex;
`

export const TextComentario = styled.textarea`
    box-sizing: border-box;
    width: 100%;
    min-width: 100%;
    height: 60px;
    min-height: 60px;
    max-height: 60px;
    padding: 4px;
    border-color: #CCCCCC;
    border-radius: 2px;
    resize: none;
    font-family: 'Segoe UI';
`

export const TextAtitude = styled.p`
    padding: 5px 10px;
    box-sizing: border-box;
    width: 340px;
    height: 52px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
`

export const TdResultAtitudes = styled.td`
    border: 1px solid #CCCCCC;
    height: 60px;
    background-color: #E1DEDE;
    font-size: 12px;
    box-sizing: border-box;
    text-align: left;
    border-radius: 2px;
    max-height: 60px;
    text-align: center;
    `

export const TdIniciativa = styled.td`
    border: 1px solid #CCCCCC;
    height: 60px;
    font-size: 14px;
    box-sizing: border-box;
    text-align: left;
    border-radius: 2px;
    text-align: left;
    padding: 0 10px;
    width: 100%;
`

export const TdValidacao = styled.td`
    border: 1px solid #CCCCCC;
    height: 60px;
    font-size: 14px;
    box-sizing: border-box;
    text-align: left;
    border-radius: 2px;
    text-align: center;
    width: 80px;
    min-width: 80px;
`

export const WrapperIniciativa = styled.div`
    overflow: auto;
    padding: 30px 0;
    max-height: 560px;
`

export const StatusTeam = styled.div<{ bgColor: string }>`
    width: 17px;
    min-width: 17px;
    height: 17px;
    min-height: 17px;
    background-color: ${({ bgColor }) => bgColor};
    border-radius: 50%;
    margin-right: 12px;
`

export const WrapperLabelGroup = styled.div`
    padding-bottom:16px;
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-wrap: wrap;
`
export const Divider = styled.div`
    margin-top: 5px;
    border-bottom: 1px solid #CFD3DA;
`