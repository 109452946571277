import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 55px;
  padding: 20px 20px 0px;
  height: 100%;
  display: flex;
  flex-direction: column;
`
export const ListContainer = styled.div`
  width: 100%;
  height: calc(100% - 82px);
`;