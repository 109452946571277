import React from "react";
import {
  Dialog,
  DialogFooter,
  IDialogProps,
} from "office-ui-fabric-react/lib/Dialog";

interface ICustomDialogZProps {
  zIndex?: number;
  onRender?: JSX.Element;
}

interface ICustomDialogProps {
  onRender?: JSX.Element;
  minHeight?: number;
}

const CustomDialog: React.FC<IDialogProps & ICustomDialogProps> = (props) => {
  return (
    <Dialog
      minWidth={420}
      {...props}
      styles={{ main: { minHeight: props?.minHeight ?? 165, maxHeight: 800 } }}
    >
      {props.onRender}
      <DialogFooter>{props.children}</DialogFooter>
    </Dialog>
  );
};

export const DialogZ: React.FC<IDialogProps & ICustomDialogZProps> = (
  props
) => {
  return (
    <Dialog
      minWidth={420}
      {...props}
      modalProps={{ styles: { layer: { zIndex: props.zIndex ?? 1000 } } }}
    >
      {props.onRender ? (
        <>
          {props.onRender}
          <DialogFooter>{props.children}</DialogFooter>
        </>
      ) : (
        props.children
      )}
    </Dialog>
  );
};

export default CustomDialog;
