import styled from "styled-components";

export const Container = styled.div`
    width: calc(100% - 290px);
    height: calc(100vh - 50px);
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
`;

export const ListContainer = styled.div`
  width: 100%;
  position: relative;
  height: 100%;
  /* overflow: auto; */
`;

export const WrapperStatus = styled.div`
  display: flex;
`

export const StatusTeam = styled.div<{ bgColor: string }>`
    width: 17px;
    min-width: 17px;
    height: 17px;
    min-height: 17px;
    background-color: ${({ bgColor }) => bgColor};
    border-radius: 50%;
    margin-right: 12px;
`

export const WrapperAutoComplete = styled.div`
  position: relative;
  width: 100%;
  .MuiAutocomplete-inputRoot  .MuiAutocomplete-endAdornment{
    right: 35px;
  }
`
