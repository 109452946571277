// #region Imports
import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik } from "formik";
import * as yup from 'yup';

//FluentUI
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import {
  DetailsListLayoutMode,
  SelectionMode,
  Selection,
  IColumn,
} from 'office-ui-fabric-react/lib/DetailsList';
import { ShimmeredDetailsList } from 'office-ui-fabric-react/lib/ShimmeredDetailsList';
import { CommandBarButton, ICommandBarStyles } from 'office-ui-fabric-react';
import { IDialogContentProps } from 'office-ui-fabric-react/lib/Dialog';
import { FontIcon } from 'office-ui-fabric-react/lib/Icon';

//MaterialUI
import MenuItem from '@material-ui/core/MenuItem';
import Dropdown from "~/components/layout/Dropdown"
//Componentes
import CustomDialog from "~/components/CustomDialogFluentUI";

import Dialog from '~/components/CustomDialog';
import { InputText, InputCheckbox } from '~/components/Forms';

import { DeleteButton } from '~/components/Buttons';
import NoItems from '~/components/layout/NoItems';
//Estilos
import {
  Wrapper,
  ListContainer,
  DefaultButton,
  ContainerContent,
} from "./styles";

import { Creators as getCycleAttitudes } from "~/store/ducks/cycles/attitude";
import { Creators as addCycleAttitude } from "~/store/ducks/cycles/attitude";
import { Creators as editFlagLiderancaCycleAttitude } from "~/store/ducks/cycles/attitude";
import { Creators as delCycleAttitude } from "~/store/ducks/cycles/attitude";
import { Creators as nextAttitude } from "~/store/ducks/cycles/attitude";
import { Creators as priorAttitude } from "~/store/ducks/cycles/attitude";
import { Creators as getAttitudes } from "~/store/ducks/attitudes";
import { DataTypes, CycloAttitudeType } from "~/store/ducks/cycles/attitude/types";

import { AttitudeType } from "~/store/ducks/attitudes/types";
import { RootState } from "~/store/ducks";
//#endregion

/**
 * Validação do formulário
 */

interface IFormInicialValues {
  idCicloAtitude: number | null;
  idAtitude: string,
  ordem: string | number,
  flagLideranca: boolean
}

interface IAttitudesState {
  columns: IColumn[];
  items: CycloAttitudeType[];
  isDialogAttitudeOpen: boolean;
  isFiltering: boolean;
  selectionDetails: string;
  selectionCount: number;
  isDialogExcludeOpen: boolean;
  initialValues: IFormInicialValues;
};

interface IPropsAttitudes {
  idCycle: number | null;
  attitudes: AttitudeType[];
  cycleAttitudes: DataTypes;
  getCycleAttitudes: (idCiclo: number) => void;
  addCycleAttitude: (idCiclo: number, idAtitude: number, ordem: number | null, nomeAtitude: string, flagLideranca: boolean | null) => void;
  editFlagLiderancaCycleAttitude: (idCiclo: number, idAtitude: number, flagLideranca: boolean) => void;
  delCycleAttitude: (idCiclo: number, idCicloAtitude: number) => void;
  nextAttitude: (idCiclo: number, idCicloAtitude: number) => void;
  priorAttitude: (idCiclo: number, idCicloAtitude: number) => void;
  getAttitudes: (search?: string) => void;
}

const defaultValues: IFormInicialValues = {
  idCicloAtitude: null,
  flagLideranca: false,
  idAtitude: '',
  ordem: ''
}

class CycleAttitudes extends Component<IPropsAttitudes, IAttitudesState> {
  private formRef: any;
  private _selection: Selection;

  constructor(props: IPropsAttitudes) {
    super(props);
    //#region Colunas
    const columns: IColumn[] = [

      {
        key: 'column0',
        name: 'Liderança',
        minWidth: 16,
        maxWidth: 16,
        iconName: 'Flag',
        fieldName: 'flagLideranca',
        isRowHeader: true,
        isPadded: true,
        isIconOnly: true,
        onRender: (item: CycloAttitudeType) => {
          if (item.flagLideranca) {
            return <FontIcon iconName="flag" title="Somente para lideranças" />
          }
        }
      },
      {
        key: 'column1',
        name: 'Ordem',
        fieldName: 'ordem',
        minWidth: 30,
        maxWidth: 50,
        isRowHeader: true,
        isResizable: true,
        data: 'string',
        isPadded: true,
      },
      {
        key: 'column2',
        name: 'Nome',
        fieldName: 'nomeAtitude',
        minWidth: 210,
        maxWidth: 350,
        isRowHeader: true,
        isResizable: true,
        data: 'string',
        isPadded: true,
        onRender: (item: CycloAttitudeType) => item.atitude.nomeAtitude
      },
    ];
    //#endregion

    this.state = {
      columns: columns,
      items: [],
      isDialogAttitudeOpen: false,
      selectionDetails: "",
      isFiltering: false,
      selectionCount: 0,
      isDialogExcludeOpen: false,
      initialValues: defaultValues
    }

    this._selection = new Selection({
      onSelectionChanged: () => {
        this.setState({
          selectionDetails: this._getSelectionDetails(),
        });
      },
    });

    this.formRef = React.createRef();
  };


  componentDidMount() {
    this.props.getCycleAttitudes(this.props.idCycle!);
    this.props.getAttitudes();
  }

  componentDidUpdate(prevProps: IPropsAttitudes, _: any) {
    if (prevProps.idCycle !== this.props.idCycle) {
      if (this.props.idCycle) {
        this.props.getCycleAttitudes(this.props.idCycle!);
      }
    }

    if (this.state.isDialogAttitudeOpen) {
      if (prevProps.cycleAttitudes.success !== this.props.cycleAttitudes.success) {
        if (this.props.cycleAttitudes.success) {
          this.setState({ isDialogAttitudeOpen: false })
        }
      }
    }

  }

  // Submit do formulário Formik
  handleSubmit = () => {
    if (this.formRef.current) {
      this.formRef.current.handleSubmit();
    };
  };

  _onItemInvoked = (item: AttitudeType): void => {
    this.handleEdit();
  };

  _getSelectionDetails(): any {
    const selectionCount = this._selection.getSelectedCount();
    this.setState({ selectionCount });
    this._selection.getSelection();
  };

  excludeCycleAttitude = () => {
    const id = ((this._selection.getSelection()[0] as CycloAttitudeType).idCicloAtitude!);
    this.props.delCycleAttitude(this.props.idCycle!, id)
    this.setState({ isDialogExcludeOpen: false });
    this._selection.setAllSelected(false);
  };

  handleEdit = () => {
    const idCicloAtitude = ((this._selection.getSelection()[0] as CycloAttitudeType).idCicloAtitude);
    const item = this.props.cycleAttitudes.data.find(item => item.idCicloAtitude === idCicloAtitude);
    this.setState({
      isDialogAttitudeOpen: true,
      initialValues: {
        idCicloAtitude: item?.idCicloAtitude ?? null,
        flagLideranca: item?.flagLideranca ?? false,
        idAtitude: String(item?.idAtitude) ?? '',
        ordem: item?.ordem ?? ''
      }
    })
  }

  handleMoveUp = () => {
    const cicloAtitude = ((this._selection.getSelection()[0] as CycloAttitudeType));
    this.props.priorAttitude(this.props.idCycle!, cicloAtitude?.idCicloAtitude!);
    this._selection.setIndexSelected(cicloAtitude.ordem - 1, false, false)
    this._selection.setIndexSelected(cicloAtitude.ordem - 2, true, false)
  }

  handleMoveDown = () => {
    const cicloAtitude = ((this._selection.getSelection()[0] as CycloAttitudeType));
    this.props.nextAttitude(this.props.idCycle!, cicloAtitude?.idCicloAtitude!);
    this._selection.setIndexSelected(cicloAtitude.ordem - 1, false, false)
    this._selection.setIndexSelected(cicloAtitude.ordem, true, false)
  }

  commandBarRender = () => {
    const { selectionCount } = this.state;
    if (selectionCount === 1) {
      return (
        <Stack horizontal verticalAlign="center">
          <CommandBarButton
            styles={btnStyle}
            iconProps={{ iconName: 'Edit' }}
            text="Editar"
            onClick={this.handleEdit}
          />
          <CommandBarButton
            styles={btnStyle}
            iconProps={{ iconName: 'Delete' }}
            text="Excluir"
            onClick={() => this.setState({ isDialogExcludeOpen: true })}
          />
          <CommandBarButton
            styles={btnStyle}
            iconProps={{ iconName: 'Up' }}
            onClick={this.handleMoveUp}
            disabled={this._selection.getSelectedIndices()[0] === 0}
          />
          <CommandBarButton
            styles={btnStyle}
            iconProps={{ iconName: 'Down' }}
            onClick={this.handleMoveDown}
            disabled={(this._selection.getSelectedIndices()[0] + 1) === this.props.cycleAttitudes.data.length}
          />
        </Stack>
      );
    }
  };

  render() {
    const { isDialogAttitudeOpen, isDialogExcludeOpen, selectionCount, isFiltering, items, columns, initialValues } = this.state;
    const { attitudes, cycleAttitudes } = this.props;

    const dialogContentProps: IDialogContentProps = {
      title: 'Excluir?',
      closeButtonAriaLabel: 'Close',
      subText: selectionCount <= 1 ? 'Tem certeza de que deseja excluir este item?' : 'Tem certeza de que deseja excluir estes items?',
    };

    const validationAttitudeSchema = yup.object().shape({
      idAtitude: yup.string().required("Campo obrigatório"),
      ordem: yup.number()
        .nullable()
        .min(1, 'A ordem não pode ser menor que 1')
        .max(cycleAttitudes.data.length + 1, `A ordem não pode ser maior que ${cycleAttitudes.data.length + 1}`)
        .notRequired(),
      flagLideranca: yup.bool()
    })

    return (
      <>
        <CustomDialog
          hidden={!isDialogExcludeOpen}
          onDismiss={() => this.setState({ isDialogExcludeOpen: false })}
          dialogContentProps={dialogContentProps}
        >
          <DefaultButton onClick={() => this.setState({ isDialogExcludeOpen: false })} text="Cancelar" />
          <DeleteButton onClick={() => this.excludeCycleAttitude()} text="Excluir" />
        </CustomDialog>
        <Wrapper>
          <ContainerContent>
            <Stack horizontal horizontalAlign="space-between">
              <Stack horizontal verticalAlign="center">
                <CommandBarButton
                  styles={btnStyle}
                  iconProps={{ iconName: 'Add' }}
                  text="Adicionar Atitude"
                  onClick={() => this.setState({ isDialogAttitudeOpen: true, initialValues: defaultValues })}
                />
                {this.commandBarRender()}
              </Stack>
              <CommandBarButton
                styles={btnStyle}
                iconProps={{ iconName: 'Refresh' }}
                text="Atualizar"
                onClick={() => this.props.getCycleAttitudes(this.props.idCycle!)}
              />
            </Stack>

            {!cycleAttitudes.loading && cycleAttitudes.data.length === 0 ?
              <NoItems
                error={cycleAttitudes.error}
                text="Não há atitudes cadastradas"
                icon="ReminderPerson"
              />
              :
              <ListContainer>
                <ShimmeredDetailsList
                  items={isFiltering ? items : cycleAttitudes.data}
                  enableShimmer={cycleAttitudes.loading}
                  columns={columns}
                  selectionMode={SelectionMode.single}
                  selection={this._selection}
                  getKey={this._getKey}
                  selectionPreservedOnEmptyClick={true}
                  setKey="single"
                  layoutMode={DetailsListLayoutMode.justified}
                  isHeaderVisible={true}
                  onItemInvoked={this._onItemInvoked}
                />
              </ListContainer>
            }
          </ContainerContent>
        </Wrapper>

        <Dialog
          title={!initialValues.idCicloAtitude ? "Definir Atitude" : "Editar Atitude"}
          open={isDialogAttitudeOpen}
          onClickCancel={() => this.setState({ isDialogAttitudeOpen: false })}
          onClickConfirm={this.handleSubmit}
          disabledBtnConfirm={cycleAttitudes.loadingAction}
          isLoadingConfirm={cycleAttitudes.loadingAction}
          btnConfirmText={!initialValues.idCicloAtitude ? "Adicionar" : "Salvar"}
          zIndex={1000000}
        >
          <Formik
            innerRef={this.formRef}
            initialValues={initialValues}
            validationSchema={validationAttitudeSchema}
            validateOnChange={false}
            validateOnBlur={true}
            enableReinitialize={true}
            onSubmit={(values: IFormInicialValues) => {
              const attitude = this.props.attitudes.find(att => att.idAtitude === Number(values.idAtitude))
              const ordem = values.ordem !== "" ? Number(values.ordem) : null;

              if (!values.idCicloAtitude) {
                this.props.addCycleAttitude(Number(this.props.idCycle!), attitude?.idAtitude!, ordem, attitude!.nomeAtitude, values.flagLideranca)
              } else {
                this.props.editFlagLiderancaCycleAttitude(this.props.idCycle!, values.idCicloAtitude, values.flagLideranca);
              }
            }}
          >
            {({ handleSubmit, handleChange, values, errors }) => (
              <form onSubmit={handleSubmit}>
                <Dropdown
                  errors={errors.idAtitude}
                  errorText={errors.idAtitude}
                  label="Atitude"
                  name="idAtitude"
                  values={values.idAtitude}
                  handleChange={handleChange}
                  autofocus={true}
                  disabled={values.idCicloAtitude ? true : false}
                >
                  {
                    attitudes
                      .map((attitude, i) =>
                        <MenuItem key={i} value={`${attitude.idAtitude}`}>
                          {attitude.nomeAtitude}
                        </MenuItem>
                      )
                  }
                </Dropdown>
                <InputText
                  type="number"
                  label="Ordem"
                  name="ordem"
                  value={values.ordem}
                  onChange={handleChange}
                  helperText={errors.ordem}
                  error={errors.ordem ? true : false}
                  hasinnerspin={true}
                  disabled={values.idCicloAtitude ? true : false}
                  InputProps={{ inputProps: { min: 1, max: cycleAttitudes.data.length + 1 } }}
                />
                <InputCheckbox
                  checked={values.flagLideranca}
                  onChange={handleChange}
                  name="flagLideranca"
                  color="primary"
                  label="Apenas para lideranças"
                />
              </form>
            )}
          </Formik>
        </Dialog>
      </>
    );
  };

  private _getKey(item: any, index?: number): any {
    if (item !== undefined)
      return item.key;
  };
}

const btnStyle: Partial<ICommandBarStyles> = {
  root: {
    height: 44,
  }
}

const mapStateToProps = (state: RootState) => ({
  attitudes: state.attitudesReducer.data,
  cycleAttitudes: state.cycleAttitudeReducer
});

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  ...getCycleAttitudes,
  ...addCycleAttitude,
  ...editFlagLiderancaCycleAttitude,
  ...delCycleAttitude,
  ...nextAttitude,
  ...priorAttitude,
  ...getAttitudes
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CycleAttitudes);
