import React, { useEffect, useState } from 'react';
import { useFormik } from "formik";
import * as yup from 'yup';
import moment from 'moment';

import CustomDialog from "~/components/CustomDialog";
import {
  withStyles,
} from '@material-ui/core/styles';
import {
  TitleField,
  GroupItems,
  GroupItemUmTerco,
  GroupItemDoisTerco,
} from '~/components/FormGroup';
import MenuItem from '@material-ui/core/MenuItem';
import Dropdown from "~/components/layout/Dropdown";
import { InputText } from '~/components/Forms';
import { AvaliadoCommentType, PerformanceAvaliado } from '~/store/ducks/calibration/types';
import { TabPerfomanceType } from '~/store/ducks/tasks/types';
import { Spinner, SpinnerSize, Stack } from 'office-ui-fabric-react';
import { Pivot, PivotItem } from 'office-ui-fabric-react/lib/Pivot';

import TextField from '@material-ui/core/TextField';

const validationSchema = yup.object().shape({
  idTabPerformance: yup.number().nullable()
    .required("Campo obrigatório"),
  potencial: yup.string()
    .required("Campo obrigatório"),
  sucessao: yup.string()
    .notRequired(),
  carreira: yup.string().required("Campo obrigatório"),
  proximoCiclo: yup.string().notRequired(),
  comentarios: yup.string().notRequired(),
});

interface CalibrationValues {
  nomeCompleto: string;
  idTabPerformance: number | null;
  performance?: {
    descNivel: string;
    cor: string;
    nivel: number | null;
  };
  potencial: string;
  sucessao: string;
  carreira: string;
  proximoCiclo: string;
  avaliacaoGestor: {
    idTabPerformance: number | null;
    potencial: string;
    sucessao: string;
    performancegestor?: {
      descNivel: string;
      cor: string;
    };
  },
  anotacoes: AvaliadoCommentType[];
  aspiracoesCarreira: string;
}

interface IDialogCalibrationProps {
  isOpen: boolean;
  initialValues: CalibrationValues;
  loadingData: boolean;
  success: boolean;
  performances: TabPerfomanceType[];
  readonly: boolean;
  modoHidden?: boolean;
  onClose: () => void;
  onSuccess: (values: any) => void;
}

const initial: PerformanceAvaliado = {
  carreira: '',
  proximoCiclo: '',
  idTabPerformance: null,
  performance: {
    cor: '',
    descNivel: '',
    nivel: null
  },
  potencial: '',
  sucessao: ''
}

const DialogCalibration: React.FC<IDialogCalibrationProps> = (props) => {
  const { isOpen, onClose, onSuccess, loadingData, success, readonly, performances, initialValues } = props;
  const [errorForm, setErrorForm] = useState(false);
 
  useEffect(() => {
    if (success && isOpen) {
      resetForm({
        values: {
          carreira: initialValues.carreira,
          proximoCiclo: initialValues.proximoCiclo,
          idTabPerformance: initialValues.idTabPerformance,
          performance: initialValues?.performance,
          potencial: initialValues.potencial,
          sucessao: initialValues.sucessao,
          comentarios: ''
        }
      })
      setErrorForm(false)
    }
  }, [success, isOpen])


  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    isValid,
    isSubmitting,
    resetForm,
    setFieldValue,
    setFieldError,
  } = useFormik({
    initialValues: { ...initial, comentarios: '' },
    validationSchema,
    validateOnBlur: true,
    validateOnChange: false,
    validate(values) {
      const errors: any = {};
      if ((values.potencial === 'Alto potencial para crescer' || values.potencial === 'Potencial para crescer') && values.sucessao === '') {
        errors.sucessao = "Campo obrigatório";
      }

      return errors;
    },
    onSubmit(values) {
      const performance: PerformanceAvaliado = {
        carreira: values.carreira,
        proximoCiclo: values.proximoCiclo,
        idTabPerformance: Number(values.idTabPerformance),
        performance: performances.find(item => item.idTabPerformance === Number(values.idTabPerformance)),
        potencial: values.potencial,
        sucessao: values.sucessao !== '' && values.sucessao !== 'blank' ? values.sucessao : null,
      }
      onSuccess({ ...performance, comentarios: values.comentarios });
    }
  });

  useEffect(() => {
      if(isSubmitting){
        if(!isValid){
          setErrorForm(true)
        }else{
          setErrorForm(false)
        }
      }
  }, [isSubmitting, isValid])

  return (
    <CustomDialog
      open={isOpen}
      title={`Calibração de ${initialValues?.nomeCompleto}`}
      btnConfirmText="Salvar"
      onClickCancel={onClose}
      disabledBtnConfirm={loadingData || readonly}
      onClickConfirm={() => handleSubmit()}
      height={685}
      width={640}
      error={{
        hasError: errorForm,
        message: 'Verifique os campos obrigatórios'
      }}
      zIndex={1300}
    >
      {
        loadingData ?
          <Spinner size={SpinnerSize.medium} label="Carregando avaliado" styles={{ root: { height: '100%' } }} />
          :
          <form onSubmit={handleSubmit}>
            <GroupItems noMargins stylesWrapperItems={{ justifyContent: 'space-between' }}>
              <GroupItemUmTerco>
                <GroupItems title="Performance Gestor" titleRegular>
                  <TitleField text={initialValues?.avaliacaoGestor.performancegestor?.descNivel ?? ''} bold />
                </GroupItems>
              </GroupItemUmTerco>
              <GroupItemDoisTerco width={360}>
                <Dropdown
                  errors={errors.idTabPerformance}
                  label="Performance calibrada"
                  name="idTabPerformance"
                  values={values.idTabPerformance ? String(values.idTabPerformance) : ''}
                  handleChange={(e: any) => { handleChange(e); setFieldError("perfomance", "") }}
                  errorText={errors.idTabPerformance}
                  disabled={readonly}
                  autofocus
                >
                  {
                    performances.map((item, i) => (
                      <MenuItem key={i} value={`${item.idTabPerformance}`}>{item.descNivel}</MenuItem>
                    ))
                  }
                </Dropdown>
              </GroupItemDoisTerco>
            </GroupItems>

            <GroupItems noMargins stylesWrapperItems={{ justifyContent: 'space-between' }}>
              <GroupItemUmTerco>
                <GroupItems title="Potencial Gestor" titleRegular>
                  <TitleField text={initialValues?.avaliacaoGestor.potencial ?? ''} bold />
                </GroupItems>
              </GroupItemUmTerco>
              <GroupItemDoisTerco width={360}>
                <Dropdown
                  errors={errors.potencial}
                  label="Potencial calibrado"
                  name="potencial"
                  values={values.potencial ?? ''}
                  handleChange={(e: any) => {
                    handleChange(e);
                    setFieldError("potencial", "")
                    if (e.target.value === 'Potencial lateral' || e.target.value === 'Cedo demais para avaliar') {
                      setFieldValue('sucessao', 'blank')
                    } else {
                      setFieldValue('sucessao', '')
                    }
                  }}
                  errorText={errors.potencial}
                  disabled={readonly}
                >
                  <MenuItem value={"Alto potencial para crescer"}>Alto Potencial para Crescer</MenuItem>
                  <MenuItem value={"Potencial para crescer"}>Potencial para Crescer</MenuItem>
                  <MenuItem value={"Potencial lateral"}>Potencial Lateral</MenuItem>
                  <MenuItem value={"Cedo demais para avaliar"}>Cedo Demais para Avaliar</MenuItem>
                </Dropdown>
              </GroupItemDoisTerco>
            </GroupItems>

            <GroupItems noMargins stylesWrapperItems={{ justifyContent: 'space-between' }}>
              <GroupItemUmTerco>
                <GroupItems title="Sucessão Gestor" titleRegular>
                  <TitleField text={initialValues?.avaliacaoGestor.sucessao ?? ''} bold />
                </GroupItems>
              </GroupItemUmTerco>
              <GroupItemDoisTerco width={360}>
                <Dropdown
                  errors={errors.sucessao}
                  label="Sucessão Calibrada"
                  name="sucessao"
                  values={values.sucessao ?? ''}
                  handleChange={(e: any) => { handleChange(e); setFieldError("sucessao", "") }}
                  errorText={errors.sucessao}
                  disabled={readonly || values.potencial === 'Cedo demais para avaliar' || values.potencial === 'Potencial lateral'}
                >
                  <MenuItem value={"blank"} disabled></MenuItem>
                  <MenuItem value={"Imediato"}>Imediato</MenuItem>
                  <MenuItem value={"Curto Prazo - 1 a 2 anos"}>Curto Prazo - 1 a 2 anos</MenuItem>
                  <MenuItem value={"Médio Prazo - 3 a 5 anos"}>Médio Prazo - 3 a 5 anos</MenuItem>
                  <MenuItem value={"Longo Prazo - acima 5 anos"}>Longo Prazo - acima 5 anos</MenuItem>
                </Dropdown>
              </GroupItemDoisTerco>
            </GroupItems>

            <Pivot aria-label="Basic Pivot Example" styles={{ itemContainer: { marginTop: 10 } }}>
              <PivotItem
                headerText="Observações"
              >
                <Stack styles={{ root: { maxHeight: 160, height: 160, overflow: 'auto', marginBottom: 5, borderBottom: '1px dotted rgba(0, 0, 0, 0.42)' } }}>
                  {
                    initialValues.anotacoes.map((comentario, i) => (
                      <GroupItems key={i} title={moment(comentario.dataHoraAnotacao).format('DD/MM/YYYY HH:mm')} titleRegular stylesTitle={{ color: "#00000050", fontSize: 12 }}>
                        <TitleField text={comentario.comentarios} />
                      </GroupItems>
                    ))
                  }
                </Stack>

                <InputText
                  name="comentarios"
                  label="Comentários"
                  value={values.comentarios ?? ''}
                  onChange={handleChange}
                  multiline
                  rows={3}
                  rowsMax={3}
                  error={errors.comentarios ? true : false}
                  helperText={errors.comentarios}
                  disabled={readonly}
                />
              </PivotItem>
              <PivotItem headerText="Carreira">
                <CssTextField
                  name="carreira"
                  label="Aspirações de desenvolvimento da carreira"
                  value={initialValues.aspiracoesCarreira ?? ''}
                  style={{ color: '#008050 !important' }}
                  disabled
                  multiline
                  rows={4}
                  rowsMax={4}
                />
                <Stack styles={{ root: { marginTop: '10px !important' } }}>
                  <InputText
                    name="carreira"
                    label="Próximos passos da carreira"
                    value={values.carreira ?? ''}
                    onChange={handleChange}
                    multiline
                    rows={5}
                    rowsMax={5}
                    error={errors.carreira ? true : false}
                    helperText={errors.carreira}
                    disabled={readonly}
                  />
                </Stack>
              </PivotItem>
              <PivotItem headerText="Ações imediatas">
                <InputText
                  name="proximoCiclo"
                  label="Ações imediadas"
                  value={values.proximoCiclo ?? ''}
                  onChange={handleChange}
                  multiline
                  rows={11}
                  rowsMax={11}
                  error={errors.proximoCiclo ? true : false}
                  helperText={errors.proximoCiclo}
                  disabled={readonly}
                />
              </PivotItem>
            </Pivot>
          </form>
      }
    </CustomDialog>
  );
}

const CssTextField = withStyles({
  root: {
    width: '100%',
    '& .MuiInputBase-input': {
      color: '#000000DE !important',
    },
  },
})(TextField);

export default DialogCalibration;
