import React from "react";
import { Text } from "office-ui-fabric-react/lib/Text";
import { Link as LinkUI, ILinkStyles } from "office-ui-fabric-react/lib/Link";

import {
  TitleFieldStyles,
  TitleHeaderStyles,
  WrapperGroupItemLeft,
  WrapperGroupItemRight,
  WrapperGroupItems,
  WrapperItems,
  WrapperGroupItemUmTerco,
  WrapperGroupItemDoisTerco,
  TitleGroupStyles,
  WrapperGroupItemFull,
  WrapperGroupItemMini,
} from "./styles";
import { Stack } from "office-ui-fabric-react";

interface IFieldProps {
  text: string | number | undefined | null;
  color?: string;
  bold?: boolean;
  titleRegular?: boolean;
  styles?: React.CSSProperties;
  isValue?: boolean;
}

export const TitleGroup: React.FC<IFieldProps> = (props) => (
  <Text
    variant="medium"
    styles={TitleGroupStyles}
    style={{ ...props.styles, fontWeight: props.titleRegular ? 400 : 600 }}
  >
    {props.text}
  </Text>
);

export const TitleField: React.FC<IFieldProps> = (props) => (
  <Text
    variant="medium"
    styles={TitleFieldStyles}
    style={{
      color: props.color ?? "#000000DE",
      fontWeight: props.bold ? 600 : 400,
      whiteSpace: "break-spaces",
      opacity: props.isValue ? 0.6 : 1,
    }}
  >
    {props.text}
  </Text>
);

export const GroupItemLeft: React.FC = (props) => {
  return <WrapperGroupItemLeft>{props.children}</WrapperGroupItemLeft>;
};

export const GroupItemRight: React.FC = (props) => {
  return <WrapperGroupItemRight>{props.children}</WrapperGroupItemRight>;
};

export const GroupItemUmTerco: React.FC = (props) => {
  return (
    <WrapperGroupItemUmTerco>
      {props.children}
    </WrapperGroupItemUmTerco>
  );
};

export const GroupItemFull: React.FC = (props) => {
  return <WrapperGroupItemFull>{props.children}</WrapperGroupItemFull>;
};

export const GroupItemMini: React.FC = (props) => {
  return <WrapperGroupItemMini>{props.children}</WrapperGroupItemMini>;
};

interface IGroupItemDoisTerco {
  width?: 390 | 360;
}

export const GroupItemDoisTerco: React.FC<IGroupItemDoisTerco> = (props) => {
  return (
    <WrapperGroupItemDoisTerco width={props?.width}>
      {props.children}
    </WrapperGroupItemDoisTerco>
  );
};

interface IGroupItemsProps {
  title?: string;
  hasMultiItems?: boolean;
  noMargins?: boolean;
  titleRegular?: boolean;
  styles?: React.CSSProperties;
  stylesWrapperItems?: React.CSSProperties;
  stylesTitle?: React.CSSProperties;
  link?: {
    text: string;
    handleClick: () => void;
  };
}

export const GroupItems: React.FC<IGroupItemsProps> = (props) => {
  return (
    <WrapperGroupItems margin={props.noMargins} style={props.styles}>
      {props.title && (
        <TitleGroup
          styles={props.stylesTitle}
          text={props.title!}
          titleRegular={props.titleRegular}
        />
      )}
      <WrapperItems
        style={props.stylesWrapperItems}
        hasMultiItems={props.hasMultiItems ? props.hasMultiItems : false}
      >
        {props.children}
      </WrapperItems>
      {props.link && (
        <LinkUI styles={linkStyles} onClick={props.link.handleClick}>
          {props.link.text}
        </LinkUI>
      )}
    </WrapperGroupItems>
  );
};

interface IContainerGroupsProps {
  link?: {
    text: string;
    handleClick: () => void;
  };
}

export const ContainerGrups: React.FC<IContainerGroupsProps> = (props) => {
  return (
    <Stack styles={{ root: { marginBottom: 20 } }}>
      <Stack horizontal>{props.children}</Stack>
      {props.link && (
        <LinkUI styles={linkStyles} onClick={props.link.handleClick}>
          {props.link.text}
        </LinkUI>
      )}
    </Stack>
  );
};

export const FormHeaderStyle: React.FC<IFieldProps> = (props) => {
  return (
    <TitleHeaderStyles>
      <Text variant="xxLarge">{props.text}</Text>
    </TitleHeaderStyles>
  );
};

const linkStyles: Partial<ILinkStyles> = {
  root: {
    fontSize: 14,
    marginTop: 4,
    width: "fit-content",
  },
};
