import React from 'react';
import * as yup from "yup";
import { Formik } from "formik";
import { isValidCPF, } from "@brazilian-utils/brazilian-utils";
import InputMask from "react-input-mask";
import { onlyDigits } from '~/utils/texts';

import MenuItem from '@material-ui/core/MenuItem';
import Dropdown from '~/components/layout/Dropdown';
import { InputText, InputDateDialog } from '~/components/Forms';

export type InfoBasicType = {
    nomeCompleto: string;
    cpf: string;
    dataNascimento: Date | null;
    sexo: string;
    email: string;
};

interface IFormInfoBasicProps {
    formRef: any;
    initialValues: InfoBasicType;
    success: (values: InfoBasicType) => any;
};

const validationSchemaBasicInfo = yup.object().shape({
    nomeCompleto:
        yup.string()
            .min(3, "O nome não pode ter menos do que 3 caracteres")
            .max(80, "O nome não pode ter mais do que 80 caracteres")
            .required("Campo obrigatório"),
    cpf:
        yup.string()
            .test('Validar CPF', 'CPF inválido', value => isValidCPF(value || ''))
            .required("Campo obrigatório"),
    email:
        yup.string()
            .email("E-mail inválido")
            .max(100, "O e-mail não pode ter mais do que 100 caracteres")
            .required("Campo obrigatório"),
    dataNascimento:
        yup.date()
            .min(new Date("01/01/1900"), "Data de nascimento inválida")
            .max(new Date(), "Data de nascimento inválida")
            .typeError('Data de nascimento inválida')
            .required("Campo obrigatório"),
    sexo:
        yup.string()
            .required("Campo obrigatório")
});

const FormContentInfoBasic: React.FC<IFormInfoBasicProps> = (props) => {
    return (
        <>
            <Formik
                enableReinitialize={true}
                innerRef={props.formRef}
                initialValues={props.initialValues}
                validationSchema={validationSchemaBasicInfo}
                validateOnChange={false}
                validateOnBlur={true}
                onSubmit={(values: any) => {
                    values.cpf = onlyDigits(values.cpf);
                    props.success(values);
                }}
            >
                {({ handleSubmit, handleChange, values, errors, setFieldError, setFieldValue }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <div className="ms-Grid" dir="ltr">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 paddingZero">
                                        <InputText
                                            value={values.nomeCompleto}
                                            onChange={(e) => { handleChange(e); setFieldError("nomeCompleto", "") }}
                                            id="nomeCompleto"
                                            error={errors.nomeCompleto ? true : false}
                                            name="nomeCompleto"
                                            type="text"
                                            label="Nome Completo"
                                            helperText={errors.nomeCompleto}
                                            className="mt-2"
                                            inputProps={{
                                                maxLength: 80
                                            }}
                                            autoFocus
                                        />
                                    </div>
                                    <div className="ms-Grid-col ms-sm-12 ms-md6" style={{ paddingLeft: 0, paddingRight: 8 }}>
                                        <InputMask
                                            mask='999.999.999-99'
                                            maskChar={null}
                                            onChange={(e) => { handleChange(e); setFieldError("cpf", ""); }}
                                            value={values.cpf}
                                        >
                                            {() =>
                                                <InputText
                                                    id="cpf"
                                                    error={errors.cpf ? true : false}
                                                    name="cpf"
                                                    type="text"
                                                    label="CPF"
                                                    className="mt-2"
                                                    helperText={errors.cpf} />
                                            }
                                        </InputMask>
                                    </div>
                                    <div className="ms-Grid-col ms-sm-12 ms-md6" style={{ paddingLeft: 8, paddingRight: 0 }}>
                                        <InputDateDialog
                                            id="dataNascimento"
                                            error={errors.dataNascimento ? true : false}
                                            name="dataNascimento"
                                            label="Data de Nascimento"
                                            value={values.dataNascimento}
                                            onChange={value => {
                                                setFieldError('dataNascimento', '');
                                                setFieldValue('dataNascimento', value);
                                            }}
                                            className="mt-2"
                                            helperText={errors.dataNascimento}
                                        />
                                    </div>
                                    <div className="ms-Grid-col ms-sm-12 ms-md6 paddingZero">
                                        <Dropdown
                                            errors={errors.sexo}
                                            label="Sexo"
                                            name="sexo"
                                            values={values.sexo}
                                            handleChange={(e: any) => { handleChange(e); setFieldError("sexo", ""); }}
                                            errorText={errors.sexo}
                                        >
                                            <MenuItem value={"M"}>Masculino</MenuItem>
                                            <MenuItem value={"F"}>Feminino</MenuItem>
                                        </Dropdown>
                                    </div>
                                    <div className="ms-Grid-col ms-sm12 paddingZero">
                                        <InputText
                                            value={values.email}
                                            onChange={(e) => { handleChange(e); setFieldError("email", "") }}
                                            id="email"
                                            error={errors.email ? true : false}
                                            name="email"
                                            type="text"
                                            label="E-mail"
                                            helperText={errors.email}
                                            className="mt-2"
                                            inputProps={{
                                                maxLength: 100
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    )
                }}
            </Formik>
        </>
    );
};

export default FormContentInfoBasic;