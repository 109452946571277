import { RegrasPDIType } from "../tasks/types";

/**
 * Types
 */
export enum Types {
  //SET CURRENT TAB
  SET_CURRENT_TAB = "profile/SET_CURRENT_TAB",
  // GET PERFIL
  REQUEST_PROFILE_LOAD = "profile/REQUEST_PROFILE_LOAD",
  SUCCESS_PROFILE_LOAD = "profile/SUCCESS_PROFILE_LOAD",
  FAILURE_PROFILE_LOAD = "profile/FAILURE_PROFILE_LOAD",
  // GET
  REQUEST_PROFILE_METAS_LOAD = "profile/REQUEST_PROFILE_METAS_LOAD",
  SUCCESS_PROFILE_METAS_LOAD = "profile/SUCCESS_PROFILE_METAS_LOAD",
  FAILURE_PROFILE_METAS_LOAD = "profile/FAILURE_PROFILE_METAS_LOAD",
  // PUT - FLAG ACEITE
  REQUEST_ACCEPT_META = "profile/REQUEST_ACCEPT_META",
  SUCCESS_ACCEPT_METAS = "profile/SUCCESS_ACCEPT_METAS",
  FAILURE_ACCEPT_METAS = "profile/FAILURE_ACCEPT_METAS",
  // GET ALL - PROJETO
  REQUEST_GET_PROJECTS = "profile/REQUEST_GET_PROJECTS",
  SUCCESS_GET_PROJECTS = "profile/SUCCESS_GET_PROJECTS",
  FAILURE_GET_PROJECTS = "profile/FAILURE_GET_PROJECTS",
  // GET BY ID - PROJETO
  REQUEST_GET_PROJECT_BY_ID = "profile/REQUEST_GET_PROJECT_BY_ID",
  SUCCESS_GET_PROJECT_BY_ID = "profile/SUCCESS_GET_PROJECT_BY_ID",
  FAILURE_GET_PROJECT_BY_ID = "profile/FAILURE_GET_PROJECT_BY_ID",
  // POST - PROJETO
  REQUEST_ADD_PROJECT = "profile/REQUEST_ADD_PROJECT",
  SUCCESS_ADD_PROJECT = "profile/SUCCESS_ADD_PROJECT",
  FAILURE_ADD_PROJECT = "profile/FAILURE_ADD_PROJECT",
  // PUT - PROJETO
  REQUEST_EDIT_PROJECT = "profile/REQUEST_EDIT_PROJECT",
  SUCCESS_EDIT_PROJECT = "profile/SUCCESS_EDIT_PROJECT",
  FAILURE_EDIT_PROJECT = "profile/FAILURE_EDIT_PROJECT",
  // DELETE - PROJETO
  REQUEST_DEL_PROJECT = "profile/REQUEST_DEL_PROJECT",
  SUCCESS_DEL_PROJECT = "profile/SUCCESS_DEL_PROJECT",
  FAILURE_DEL_PROJECT = "profile/FAILURE_DEL_PROJECT",
  // GET - SOLICITAR APROVAÇÃO
  REQUEST_APROVACAO = "profile/REQUEST_APROVACAO",
  SUCCESS_APROVACAO = "profile/SUCCESS_APROVACAO",
  FAILURE_APROVACAO = "profile/FAILURE_APROVACAO",
  // GET - SOLICITAR APROVAÇÃO
  REQUEST_LOAD_PERFORMANCE = "profile/REQUEST_LOAD_PERFORMANCE",
  SUCCESS_LOAD_PERFORMANCE = "profile/SUCCESS_LOAD_PERFORMANCE",
  FAILURE_LOAD_PERFORMANCE = "profile/FAILURE_LOAD_PERFORMANCE",
  // GET - PDI
  REQUEST_PROFILE_PDI_LOAD = "profile/REQUEST_PROFILE_PDI_LOAD",
  SUCCESS_PROFILE_PDI_LOAD = "profile/SUCCESS_PROFILE_PDI_LOAD",
  FAILURE_PROFILE_PDI_LOAD = "profile/FAILURE_PROFILE_PDI_LOAD",
  // ADD - PDI
  REQUEST_PROFILE_PDI_ADD = "profile/REQUEST_PROFILE_PDI_ADD",
  SUCCESS_PROFILE_PDI_ADD = "profile/SUCCESS_PROFILE_PDI_ADD",
  FAILURE_PROFILE_PDI_ADD = "profile/FAILURE_PROFILE_PDI_ADD",
  // EDIT - PDI
  REQUEST_PROFILE_PDI_EDIT = "profile/REQUEST_PROFILE_PDI_EDIT",
  SUCCESS_PROFILE_PDI_EDIT = "profile/SUCCESS_PROFILE_PDI_EDIT",
  FAILURE_PROFILE_PDI_EDIT = "profile/FAILURE_PROFILE_PDI_EDIT",
  // DEL - PDI
  REQUEST_PROFILE_PDI_DEL = "profile/REQUEST_PROFILE_PDI_DEL",
  SUCCESS_PROFILE_PDI_DEL = "profile/SUCCESS_PROFILE_PDI_DEL",
  FAILURE_PROFILE_PDI_DEL = "profile/FAILURE_PROFILE_PDI_DEL",
  // GET - MEDIDAS
  REQUEST_PROFILE_MEDIDAS_LOAD = "profile/REQUEST_PROFILE_MEDIDAS_LOAD",
  SUCCESS_PROFILE_MEDIDAS_LOAD = "profile/SUCCESS_PROFILE_MEDIDAS_LOAD",
  FAILURE_PROFILE_MEDIDAS_LOAD = "profile/FAILURE_PROFILE_MEDIDAS_LOAD",

  // GET - FEEDBACKS
  REQUEST_PROFILE_FEEDBACKS_LOAD = "profile/REQUEST_PROFILE_FEEDBACKS_LOAD",
  SUCCESS_PROFILE_FEEDBACKS_LOAD = "profile/SUCCESS_PROFILE_FEEDBACKS_LOAD",
  FAILURE_PROFILE_FEEDBACKS_LOAD = "profile/FAILURE_PROFILE_FEEDBACKS_LOAD",

  // GET - FEEDBACKS NEW
  REQUEST_PROFILE_NEWFEEDBACKS_LOAD = "profile/REQUEST_PROFILE_NEWFEEDBACKS_LOAD",
  SUCCESS_PROFILE_NEWFEEDBACKS_LOAD = "profile/SUCCESS_PROFILE_NEWFEEDBACKS_LOAD",
  FAILURE_PROFILE_NEWFEEDBACKS_LOAD = "profile/FAILURE_PROFILE_NEWFEEDBACKS_LOAD",

  // POST - FEEDBACKS NEW ADD
  REQUEST_PROFILE_NEWFEEDBACKS_ADD = "profile/REQUEST_PROFILE_NEWFEEDBACKS_ADD",
  SUCCESS_PROFILE_NEWFEEDBACKS_ADD = "profile/SUCCESS_PROFILE_NEWFEEDBACKS_ADD",
  FAILURE_PROFILE_NEWFEEDBACKS_ADD = "profile/FAILURE_PROFILE_NEWFEEDBACKS_ADD",
 
  // GET - FEEDBACKS BY ID
  REQUEST_PROFILE_NEWFEEDBACK_LOAD_BY_ID = "profile/REQUEST_PROFILE_NEWFEEDBACK_LOAD_BY_ID",
  SUCCESS_PROFILE_NEWFEEDBACK_LOAD_BY_ID = "profile/SUCCESS_PROFILE_NEWFEEDBACK_LOAD_BY_ID",
  FAILURE_PROFILE_NEWFEEDBACK_LOAD_BY_ID = "profile/FAILURE_PROFILE_NEWFEEDBACK_LOAD_BY_ID",
}

interface IHeadcountState {
  readonly currentTab: string;
  readonly data: IProfileMetasType;
  readonly dataProfile: IProfileType;
  readonly dataPerformance: ProfilePerformanceType[];
  readonly dataMedidas: ProfileMedidasType[];
  readonly dataPdi: ProfilePdiType;
  readonly dataFeedbacks: ProfileFeedbacksType | null;
  readonly dataNewFeedback: NewFeedbackDataType | null;
  readonly dataFeedbackSelected: IFeedbackDataType | null;
  readonly success: boolean;
  readonly successAction: boolean;
  readonly loadingProject: boolean;
  readonly loadingData: boolean;
  readonly loadingAprovacao: boolean;
  readonly loadingAction: boolean;
  readonly error: boolean;
  readonly hasMetas: boolean;
}

export interface ITemporadaType {
  idCiclo: number;
  ano: number;
  status: string;
}

export interface IProfileType {
  idFuncionario: number | null;
  matricula: string;
  nomeCompleto: string;
  status: string;
  imagem: string | null;
  Cargo: {
    idCargo: number | null;
    codCargo: string;
    titulo: string;
  };
  Area: {
    idArea: number | null;
    codArea: string;
    nomeLocal: string;
  };
  cc: {
    idCC: number | null;
    nomeCC: string;
  };
  Unidade: {
    idUnidade: number | null;
    nomeUnidade: string;
  };
}

export interface ICicloPesoPerfil {
  idObjetivo: number;
  peso: string | number;
  objetivo: {
    descObjetivo: string;
    mecanicaCalculo: string;
    unidade: string;
    descricaoMecanica: string;
    casasDecimais: number | null;
  };
}

export interface IMetaPerfil {
  idCicloMeta: number;
  // idCiclo: number;
  idObjetivo: number;
  minimo: number | string;
  target: number | string;
  maximo: number | string;
  // habilitarReferencia: boolean;
  // minimoRef: number;
  // targetRef: number;
  // maximoRef: number;
  ating: null | string;
  flagInativo: boolean | null;
  avaliacao: string | null;
  metaBonus: string | null;
  resultado: string | null;
  percPeso: null | number;
}

interface ICicloPesoPaiTypes {
  idCicloPeso: number;
  idObjetivo: number;
  objetivo: {
    descObjetivo: string;
  };
}

export interface ICicloPesosPerfil {
  idCicloPeso: number;
  contMeta: number;
  idCicloColaboradorMeta: number;
  flagIniciativas: boolean;
  cicloPeso: ICicloPesoPerfil;
  meta: IMetaPerfil[];
  pilar: {
    nomePilar: string;
    corPilar: string;
  };
  cicloPesoPai: ICicloPesoPaiTypes;
}

export interface IProjectType {
  idCicloColaboradorProjeto: number | null;
  idCiclo: number | null;
  idFuncionario?: number | null;
  idCicloPesoPai: number | null;
  nomeProjeto: string;
  descProjeto: string;
  peso: string | number;
  prazoEntrega: Date | null;
  cicloPeso: {
    idObjetivo: number | null;
    objetivo: {
      descObjetivo: string;
    };
  };
}

export interface IProjectSelectedState {
  item: IProjectType;
  loading: boolean;
  loadingAction: boolean;
  success: boolean;
}

export interface IObjetivosPesosAreas {
  idCicloPeso: number | null;
  idObjetivo: number | null;
  idArea: number | null;
  objetivo: {
    descObjetivo: string;
  };
}

export interface IAllPositionsType {
  idCicloColaborador: number;
  idArea: number;
  status: string;
  area: {
    nomeLocal: string;
    idAreaPai: number;
  };
}

export interface IPositionType {
  idCicloColaborador: number | null;
  vigencia: Date | null;
  idArea: number | null;
  idVaga: number | null;
  status: string;
  obsStatus: string | null;
  area: {
    nomeLocal: string;
    idAreaPai: number | null;
  };
  unidade: {
    nomeUnidade: string;
  };
  cc: {
    nomeCC: string;
  };
  vaga: {
    status: string;
    idArea: number | null;
    idCargo: number | null;
    idUnidade: number | null;
    idFuncionario: number | null;
    area: {
      idAreaPai: number | null;
      nomeLocal: string;
    };
    cargo: {
      codCargo: string;
      titulo: string;
    };
  };
  metas: ICicloPesosPerfil[];
  projetos: IProjectType[];
  descPeriodo: string;
  flagSolicitarAprovacao: boolean;
}

export interface IProfileMetasType {
  temporadas: ITemporadaType[];
  posicoes: IAllPositionsType[];
  posicao: IPositionType;
  totalPesos: null | number;
  objetivosPesoAreas: IObjetivosPesosAreas[];
  FuncionarioOwner: {
    idFuncionario: number | null;
    matricula: string;
    nomeCompleto: string;
    status: string;
  };
  projectSelected: IProjectSelectedState;
}

export type DataTypes = IHeadcountState;

export interface ProfilePerformanceType {
  idCiclo: number;
  idFuncionario: number;
  idTabPerformance: number;
  potencial: string;
  sucessao: string | null;
  ciclo: {
    ano: number;
  };
  performance: {
    descNivel: string;
    cor: string;
    nivel: number;
  };
  cargo: {
    idCargo: number;
    codCargo: string;
    titulo: string;
    dataHist: Date;
  };
  autoAvaliacao: {
    descNivel: string;
    cor: string;
  } | null;
}

export interface ProfilePdiType {
  anos: {
    ano: string;
  }[];
  dataPDI: {
    idPDI: number;
    comentarios: string;
    ano: number;
    acoes: ProfilePdiAcoesTypes[];
    Regras: RegrasPDIType[];
    permiteEditar: boolean;
  } | null;
}

export interface ProfileFeedbacksType {
  permiteInserirFeedback: boolean;
  dados: FeedbackDataType[];
}

export interface FeedbackDataType {
  idFeedback: number;
  dataOcorrencia: Date;
  status: string;
  descricaoResumida: string;
  owner: {
    nomeCompleto: string;
  }
}

export interface NewFeedbackDataType {
  dataOcorrencia: Date;
  status: string;
  funcionario: string;
}

export interface AddNewFeedbackDataType {
  dataOcorrencia: Date | null;
  descricao: string
}

export interface IFeedbackDataType {
  idFeedback: number;
  idFuncionario: number;
  idFuncionarioOwner: number;
  descricao: string;
  status: string;
  comentariosDeAcordo: string;
  dataHoraRecebimento: Date | null;
  dataOcorrencia: Date | null;
  deAcordo: "Não recebi feedback" | "Recebi e CONCORDO" | "Recebi e NÃO CONCORDO" | null;
  owner:{
    nomeCompleto: string;
  };
  funcionario:{
    nomeCompleto: string;
  }
}

export type ProfilePdiAcoesTypes = {
  idPDIAcao: number | null;
  idAcaoPDI?: number | null;
  obs: string;
  complemento?: string;
  idFuncionarioRelacionado?: number | null;
  acaoPDI: {
    nomeAcaoPDI: string;
    prazo: number | null;
    flagRelacionarFuncionario: boolean;
    tipoPDI: {
      cor: string;
      idTipoPDI: number | null;
    };
  };
  funcionario: {
    idFuncionario: number | null;
    nomeCompleto: string;
  };
};

export type ProfileMedidasType = {
  idMedidaEducativa: number;
  idFuncionario: number;
  dataOcorrencia: string;
  statusmedida: string;
  idSancao: number;
  createdAt: Date;
  sancao: {
    descSancao: string;
  };
};

/**
 * Actions
 */

//GET PROFILE
export interface IRequestLoadProfileAction {
  type: typeof Types.REQUEST_PROFILE_LOAD;
  id?: number;
}

export interface ISuccessLoadProfileAction {
  type: typeof Types.SUCCESS_PROFILE_LOAD;
  payload: IProfileType;
}

export interface IFailureLoadProfileAction {
  type: typeof Types.FAILURE_PROFILE_LOAD;
  message: string;
}

type ProfileLoadAction =
  | IRequestLoadProfileAction
  | ISuccessLoadProfileAction
  | IFailureLoadProfileAction;

//GET
export interface ISetCurrentTabAction {
  type: typeof Types.SET_CURRENT_TAB;
  indexTab: string;
}

export interface IRequestProfileMetasAction {
  type: typeof Types.REQUEST_PROFILE_METAS_LOAD;
  payload: {
    idFuncionario?: number | null;
    idCiclo?: number;
    idCicloColaborador?: number;
  };
}

export interface ISuccessProfileMetasAction {
  type: typeof Types.SUCCESS_PROFILE_METAS_LOAD;
  payload: IProfileMetasType;
}

export interface IFailureProfileMetasAction {
  type: typeof Types.FAILURE_PROFILE_METAS_LOAD;
  message: string;
}

type GetProfileMetasAction =
  | IRequestProfileMetasAction
  | ISuccessProfileMetasAction
  | IFailureProfileMetasAction;

//PUT - ACCEPT META
export interface IRequestAcceptMetaAction {
  type: typeof Types.REQUEST_ACCEPT_META;
  payload: {
    idCicloMetaColaborador: number;
    flagAceite: boolean;
  };
}

export interface ISuccessAcceptMetaAction {
  type: typeof Types.SUCCESS_ACCEPT_METAS;
  payload: {
    idCicloMetaColaborador: number;
    flagAceite: boolean;
  };
}

export interface IFailureAcceptMetaAction {
  type: typeof Types.FAILURE_ACCEPT_METAS;
  message: string;
}

type AcceptMetaAction =
  | IRequestAcceptMetaAction
  | ISuccessAcceptMetaAction
  | IFailureAcceptMetaAction;

//GET ALL - PROJECT
export interface IRequestGetProjectsAction {
  type: typeof Types.REQUEST_GET_PROJECTS;
  payload: {
    idCiclo: number;
    idCicloColaborador: number;
    idFuncionario?: number;
  };
}

export interface ISuccessGetProjectsAction {
  type: typeof Types.SUCCESS_GET_PROJECTS;
  payload: IProjectType[];
}

export interface IFailureGetProjectsAction {
  type: typeof Types.FAILURE_GET_PROJECTS;
  message: string;
}

type GetProjectsAction =
  | IRequestGetProjectsAction
  | ISuccessGetProjectsAction
  | IFailureGetProjectsAction;

//GET BY ID - PROJECT
export interface IRequestGetProjectsByIdAction {
  type: typeof Types.REQUEST_GET_PROJECT_BY_ID;
  payload: {
    idCicloColaboradorProjeto: number;
    idFuncionario?: number;
    idCicloColaborador: number;
  };
}

export interface ISuccessGetProjectsByIdAction {
  type: typeof Types.SUCCESS_GET_PROJECT_BY_ID;
  payload: IProjectType;
}

export interface IFailureGetProjectsByIdAction {
  type: typeof Types.FAILURE_GET_PROJECT_BY_ID;
  message: string;
}

type GetProjectsByIdAction =
  | IRequestGetProjectsByIdAction
  | ISuccessGetProjectsByIdAction
  | IFailureGetProjectsByIdAction;

//POST - PROJECT
export interface IRequestAddProjectAction {
  type: typeof Types.REQUEST_ADD_PROJECT;
  payload: {
    project: Partial<IProjectType>;
    idCicloColaborador: number;
  };
}

export interface ISuccessAddProjectAction {
  type: typeof Types.SUCCESS_ADD_PROJECT;
  payload: IProjectType;
}

export interface IFailureAddProjectAction {
  type: typeof Types.FAILURE_ADD_PROJECT;
  message: string;
}

type AddProjectAction =
  | IRequestAddProjectAction
  | ISuccessAddProjectAction
  | IFailureAddProjectAction;

//EDIT - PROJECT
export interface IRequestEditProjectAction {
  type: typeof Types.REQUEST_EDIT_PROJECT;
  payload: {
    project: IProjectType;
    idCicloColaborador: number;
  };
}

export interface ISuccessEditProjectAction {
  type: typeof Types.SUCCESS_EDIT_PROJECT;
  payload: IProjectType;
}

export interface IFailureEditProjectAction {
  type: typeof Types.FAILURE_EDIT_PROJECT;
  message: string;
}

type EditProjectAction =
  | IRequestEditProjectAction
  | ISuccessEditProjectAction
  | IFailureEditProjectAction;

//DELETE - PROJECT
export interface IRequestDelProjectAction {
  type: typeof Types.REQUEST_DEL_PROJECT;
  payload: {
    idCicloColaboradorProjeto: number;
    idFuncionario?: number;
    idCicloColaborador: number;
  };
}

export interface ISuccessDelProjectAction {
  type: typeof Types.SUCCESS_DEL_PROJECT;
  idCicloColaboradorProjeto: number;
}

export interface IFailureDelProjectAction {
  type: typeof Types.FAILURE_DEL_PROJECT;
  message: string;
}

type DelProjectAction =
  | IRequestDelProjectAction
  | ISuccessDelProjectAction
  | IFailureDelProjectAction;

// APROVAÇÃO
export interface IRequestAprovacaoAction {
  type: typeof Types.REQUEST_APROVACAO;
  payload: {
    idCiclo: number;
    idCicloColaborador: number;
    idFuncionario?: number;
  };
}

export interface ISuccessAprovacaoAction {
  type: typeof Types.SUCCESS_APROVACAO;
}

export interface IFailureAprovacaoAction {
  type: typeof Types.FAILURE_APROVACAO;
  message: string;
}

type AprovacaoAction =
  | IRequestAprovacaoAction
  | ISuccessAprovacaoAction
  | IFailureAprovacaoAction;

// LOAD PERFORMANCE
export interface IRequestProfilePerformanceAction {
  type: typeof Types.REQUEST_LOAD_PERFORMANCE;
  idFuncionario?: number;
}

export interface ISuccessProfilePerformanceAction {
  type: typeof Types.SUCCESS_LOAD_PERFORMANCE;
  payload: ProfilePerformanceType[];
}

export interface IFailureProfilePerformanceAction {
  type: typeof Types.FAILURE_LOAD_PERFORMANCE;
  message: string;
}

type ProfilePerformanceAction =
  | IRequestProfilePerformanceAction
  | ISuccessProfilePerformanceAction
  | IFailureProfilePerformanceAction;

// LOAD PDI
export interface IRequestProfilePdiAction {
  type: typeof Types.REQUEST_PROFILE_PDI_LOAD;
  payload: {
    idFuncionario?: number;
    ano?: string;
  };
}

export interface ISuccessProfilePdiAction {
  type: typeof Types.SUCCESS_PROFILE_PDI_LOAD;
  payload: ProfilePdiType;
}

export interface IFailureProfilePdiAction {
  type: typeof Types.FAILURE_PROFILE_PDI_LOAD;
  message: string;
}

type ProfilePdiAction =
  | IRequestProfilePdiAction
  | ISuccessProfilePdiAction
  | IFailureProfilePdiAction;

// ADD PDI
export interface IRequestProfileAddPdiAction {
  type: typeof Types.REQUEST_PROFILE_PDI_ADD;
  payload: {
    idFuncionario?: number;
    ano: string;
    pdiAction: Omit<ProfilePdiAcoesTypes, "idPDI">;
  };
}

export interface ISuccessProfileAddPdiAction {
  type: typeof Types.SUCCESS_PROFILE_PDI_ADD;
  payload: ProfilePdiAcoesTypes;
}

export interface IFailureProfileAddPdiAction {
  type: typeof Types.FAILURE_PROFILE_PDI_ADD;
  message: string;
}

type ProfileAddPdiAction =
  | IRequestProfileAddPdiAction
  | ISuccessProfileAddPdiAction
  | IFailureProfileAddPdiAction;

// ADD PDI
export interface IRequestProfileEditPdiAction {
  type: typeof Types.REQUEST_PROFILE_PDI_EDIT;
  payload: {
    idFuncionario?: number;
    ano: string;
    pdiAction: ProfilePdiAcoesTypes;
  };
}

export interface ISuccessProfileEditPdiAction {
  type: typeof Types.SUCCESS_PROFILE_PDI_EDIT;
  payload: ProfilePdiAcoesTypes;
}

export interface IFailureProfileEditPdiAction {
  type: typeof Types.FAILURE_PROFILE_PDI_EDIT;
  message: string;
}

type ProfileEditPdiAction =
  | IRequestProfileEditPdiAction
  | ISuccessProfileEditPdiAction
  | IFailureProfileEditPdiAction;

// DEL PDI
export interface IRequestProfileDelPdiAction {
  type: typeof Types.REQUEST_PROFILE_PDI_DEL;
  payload: {
    idFuncionario?: number;
    ano: string;
    idPDIAcao: number;
  };
}

export interface ISuccessProfileDelPdiAction {
  type: typeof Types.SUCCESS_PROFILE_PDI_DEL;
  idPDIAcao: number;
}

export interface IFailureProfileDelPdiAction {
  type: typeof Types.FAILURE_PROFILE_PDI_DEL;
  message: string;
}

type ProfileDelPdiAction =
  | IRequestProfileDelPdiAction
  | ISuccessProfileDelPdiAction
  | IFailureProfileDelPdiAction;

// DEL PDI
export interface IRequestProfileLoadMedidasAction {
  type: typeof Types.REQUEST_PROFILE_MEDIDAS_LOAD;
  idFuncionario?: number | string;
}

export interface ISuccessProfileLoadMedidasAction {
  type: typeof Types.SUCCESS_PROFILE_MEDIDAS_LOAD;
  payload: ProfileMedidasType[];
}

export interface IFailureProfileLoadMedidasAction {
  type: typeof Types.FAILURE_PROFILE_MEDIDAS_LOAD;
  message: string;
}

type ProfileLoadMedidasAction =
  | IRequestProfileLoadMedidasAction
  | ISuccessProfileLoadMedidasAction
  | IFailureProfileLoadMedidasAction;


// LOAD FEEDBACKS
export interface IRequestProfileFeedbacksAction {
  type: typeof Types.REQUEST_PROFILE_FEEDBACKS_LOAD;
  idFuncionario: number;
}

export interface ISuccessProfileFeedbacksAction {
  type: typeof Types.SUCCESS_PROFILE_FEEDBACKS_LOAD;
  payload: ProfileFeedbacksType;
}

export interface IFailureProfileFeedbacksAction {
  type: typeof Types.FAILURE_PROFILE_FEEDBACKS_LOAD;
  message: string;
}

type ProfileFeedbacksAction =
  | IRequestProfileFeedbacksAction
  | ISuccessProfileFeedbacksAction
  | IFailureProfileFeedbacksAction;

// LOAD NEW FEEDBACKS
export interface IRequestProfileNewFeedbacksAction {
  type: typeof Types.REQUEST_PROFILE_NEWFEEDBACKS_LOAD;
  idFuncionario: number;
}

export interface ISuccessProfileNewFeedbacksAction {
  type: typeof Types.SUCCESS_PROFILE_NEWFEEDBACKS_LOAD;
  payload: NewFeedbackDataType;
}

export interface IFailureProfileNewFeedbacksAction {
  type: typeof Types.FAILURE_PROFILE_NEWFEEDBACKS_LOAD;
  message: string;
}

type ProfileNewFeedbacksAction =
  | IRequestProfileNewFeedbacksAction
  | ISuccessProfileNewFeedbacksAction
  | IFailureProfileNewFeedbacksAction;

// ADD NEW FEEDBACKS
export interface IRequestProfileAddNewFeedbacksAction {
  type: typeof Types.REQUEST_PROFILE_NEWFEEDBACKS_ADD;
  payload: {
    idFuncionario: number;
    data: AddNewFeedbackDataType;
  };
}

export interface ISuccessProfileAddNewFeedbacksAction {
  type: typeof Types.SUCCESS_PROFILE_NEWFEEDBACKS_ADD;
}

export interface IFailureProfileAddNewFeedbacksAction {
  type: typeof Types.FAILURE_PROFILE_NEWFEEDBACKS_ADD;
  message: string;
}

type ProfileAddNewFeedbacksAction =
  | IRequestProfileAddNewFeedbacksAction
  | ISuccessProfileAddNewFeedbacksAction
  | IFailureProfileAddNewFeedbacksAction;

// REQUEST_PROFILE_NEWFEEDBACKS_LOAD_BY_ID
export interface IRequestProfileGetNewFeedbackByIdAction {
  type: typeof Types.REQUEST_PROFILE_NEWFEEDBACK_LOAD_BY_ID;
  payload: {
    idFuncionario: number;
    idFeedback: number;
  };
}

export interface ISuccessProfileGetNewFeedbackByIdAction {
  type: typeof Types.SUCCESS_PROFILE_NEWFEEDBACK_LOAD_BY_ID;
  payload: IFeedbackDataType;
}

export interface IFailureProfileGetNewFeedbackByIdAction {
  type: typeof Types.FAILURE_PROFILE_NEWFEEDBACK_LOAD_BY_ID;
  message: string;
}

type ProfileGetNewFeedbackByIdAction =
  | IRequestProfileGetNewFeedbackByIdAction
  | ISuccessProfileGetNewFeedbackByIdAction
  | IFailureProfileGetNewFeedbackByIdAction;


export type ProfileMetasActionTypes =
  | ProfileLoadAction
  | ISetCurrentTabAction
  | GetProfileMetasAction
  | AcceptMetaAction
  | GetProjectsAction
  | GetProjectsByIdAction
  | AddProjectAction
  | EditProjectAction
  | DelProjectAction
  | AprovacaoAction
  | ProfilePerformanceAction
  | ProfilePdiAction
  | ProfileAddPdiAction
  | ProfileDelPdiAction
  | ProfileEditPdiAction
  | ProfileLoadMedidasAction
  | ProfileFeedbacksAction
  | ProfileNewFeedbacksAction
  | ProfileAddNewFeedbacksAction
  | ProfileGetNewFeedbackByIdAction;
