import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from "yup";
import { Formik } from "formik";

import { InputText, InputAutocomplete, InputDateDialog, InputTimeDialog, InputCheckbox } from '~/components/Forms';

import { EmployeeType, DataTypes as DataTypesEmployee } from "~/store/ducks/employees/types";
import { Creators as CreatorsEmployees } from "~/store/ducks/employees";
import { FormHeaderStyle } from '~/components/FormGroup';

import { CycleGroupType } from "~/store/ducks/cycle/groups/types";

import { RootState } from "~/store/ducks";
import moment from 'moment';

interface IFormEditInfoBasicProps {
  formRef: any;
  initialValues: CycleGroupType;
  success: (values: CycleGroupType) => any;
};

const validationSchemaInfo = yup.object().shape({
  nomeGrupo: yup.string().required("Campo obrigatório"),
  idFuncionarioLider: yup.number().required('Campo obrigatório'),
  idFuncionarioHRBP: yup.number().required('Campo obrigatório'),
  date: yup.date().nullable().typeError('Data inválida').required("Campo obrigatório"),
  time: yup.date().nullable().typeError("Hora inválida").required("Campo obrigatório"),
  minutos: yup.number().typeError('Campo obrigatório').moreThan(0, 'o tempo precisa ser maior que zero').required("Campo obrigatório"),
  flagIgnorarCurva: yup.bool().notRequired()
});

let timeout: number = 0;

const FormEditInfoBasic: React.FC<IFormEditInfoBasicProps> = (props) => {
  const [lider, setLider] = useState<Partial<EmployeeType>>({
    idFuncionario: props.initialValues.idFuncionarioLider ?? null,
    nomeCompleto: props.initialValues?.lider?.nomeCompleto ?? ''
  });
  const [hrbp, setHrbp] = useState<Partial<EmployeeType>>({
    idFuncionario: props.initialValues.idFuncionarioHRBP ?? null,
    nomeCompleto: props.initialValues?.hrbp?.nomeCompleto ?? ''
  });
  const [hr, setHr] = useState<Partial<EmployeeType>>({
    idFuncionario: props.initialValues.idFuncionarioRH ?? null,
    nomeCompleto: props.initialValues?.rh?.nomeCompleto ?? ''
  });

  const dispatch = useDispatch();

  const employeesState = useSelector<RootState, DataTypesEmployee>(state => state.employeesReducer);

  const search = (text: string) => {
    if (text.trim()) {
      clearTimeout(timeout);
      timeout = window.setTimeout(() => {
        dispatch(CreatorsEmployees.getEmployees(text));
      }, 700);
    }
  };

  return (
    <>
      <FormHeaderStyle text="Gerenciar informações principais" />
      <Formik
        enableReinitialize={true}
        innerRef={props.formRef}
        initialValues={
          {
            ...props.initialValues!,
            date: props.initialValues.dataHoraPrevista ? new Date(props.initialValues.dataHoraPrevista) : null,
            time: props.initialValues.dataHoraPrevista ? new Date(props.initialValues.dataHoraPrevista) : null
          }}
        validationSchema={validationSchemaInfo}
        validateOnChange={false}
        validateOnBlur={true}
        onSubmit={(values) => {
          const dataFormatada = `${moment(values.date).format('YYYY/MM/DD')} ${moment(values.time).format('HH:mm')}`
          values = {
            ...values,
            idFuncionarioLider: lider.idFuncionario!,
            lider: {
              nomeCompleto: lider.nomeCompleto!
            },
            idFuncionarioHRBP: hrbp.idFuncionario!,
            hrbp: {
              nomeCompleto: hrbp.nomeCompleto!
            },
            idFuncionarioRH: hr.idFuncionario!,
            rh: {
              nomeCompleto: hr.nomeCompleto!
            },
            dataHoraPrevista: new Date(dataFormatada)
          }
          props.success(values);
        }}
      >
        {({ handleSubmit, handleChange, values, errors, setFieldError, setFieldValue }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm12" style={{ paddingLeft: 0, paddingRight: 8 }}>
                    <InputText
                      value={values.nomeGrupo}
                      onChange={(e) => { handleChange(e); setFieldError("nomeGrupo", "") }}
                      id="nomeGrupo"
                      error={errors.nomeGrupo ? true : false}
                      name="nomeGrupo"
                      type="text"
                      label="Nome do grupo"
                      helperText={errors.nomeGrupo}
                      className="mt-2"
                      inputProps={{
                        maxLength: 100
                      }}
                      autoFocus
                    />
                  </div>
                  <div className="ms-Grid-col ms-sm12 paddingZero" style={{ height: 70 }}>
                    <InputAutocomplete
                      value={(lider as EmployeeType)}
                      onChange={(_, newValue) => {
                        setLider(newValue!)
                        setFieldValue("lider", { idFuncionario: newValue?.idFuncionario });
                      }}
                      onInputChange={(_, newInputValue) => {
                        setFieldError("lider", "");
                        search(newInputValue);
                      }}
                      getOptionLabel={(employee: EmployeeType) => employee.nomeCompleto}
                      getOptionSelected={(option, value) => option.idFuncionario === value.idFuncionario}
                      options={employeesState.data}
                      input={{
                        idInput: "lider",
                        labelInput: "Líder",
                        helperTextInput: errors.idFuncionarioLider,
                        errorInput: errors.idFuncionarioLider ? true : false
                      }}
                    />
                  </div>
                  <div className="ms-Grid-col ms-sm12 paddingZero" style={{ height: 70 }}>
                    <InputAutocomplete
                      value={(hrbp as EmployeeType)}
                      onChange={(_, newValue) => {
                        setHrbp(newValue!)
                        setFieldValue("hrbp", { idFuncionario: newValue?.idFuncionario });
                      }}
                      onInputChange={(_, newInputValue) => {
                        setFieldError("hrbp", "");
                        search(newInputValue);
                      }}
                      getOptionLabel={(employee: EmployeeType) => employee.nomeCompleto}
                      getOptionSelected={(option, value) => option.idFuncionario === value.idFuncionario}
                      options={employeesState.data}
                      input={{
                        idInput: "hrbp",
                        labelInput: "HR Business Partner",
                        helperTextInput: errors.idFuncionarioHRBP,
                        errorInput: errors.idFuncionarioHRBP ? true : false
                      }}
                    />
                  </div>
                  <div className="ms-Grid-col ms-sm12 paddingZero" style={{ height: 70 }}>
                    <InputAutocomplete
                      value={(hr as EmployeeType)}
                      onChange={(_, newValue) => {
                        setHr(newValue!)
                        setFieldValue("hr", { idFuncionario: newValue?.idFuncionario });
                      }}
                      onInputChange={(_, newInputValue) => {
                        setFieldError("hr", "");
                        search(newInputValue);
                      }}
                      getOptionLabel={(employee: EmployeeType) => employee.nomeCompleto}
                      getOptionSelected={(option, value) => option.idFuncionario === value.idFuncionario}
                      options={employeesState.data}
                      input={{
                        idInput: "hrbp",
                        labelInput: "Assistente do RH",
                        helperTextInput: errors.idFuncionarioRH,
                        errorInput: errors.idFuncionarioRH ? true : false
                      }}
                    />
                  </div>
                  <div className="ms-Grid-col ms-sm6" style={{ paddingLeft: 0, paddingRight: '5px !important' }}>
                    <InputDateDialog
                      id="date"
                      error={errors.date ? true : false}
                      name="date"
                      label="Data prevista"
                      value={values.date}
                      onChange={value => {
                        setFieldError('date', '');
                        setFieldValue('date', value);
                      }}
                      helperText={errors.date}
                    />
                  </div>
                  <div className="ms-Grid-col ms-sm6" style={{ paddingLeft: '5px !important', paddingRight: '0px !important' }}>
                    <InputTimeDialog
                      id="time"
                      error={errors.time ? true : false}
                      name="time"
                      label="Hora prevista"
                      value={values.time}
                      onChange={value => {
                        setFieldError('time', '');
                        setFieldValue('time', value);
                      }}
                      helperText={errors.time}
                    />
                  </div>
                  <div className="ms-Grid-col ms-sm6" style={{ paddingLeft: 0, paddingRight: '5px !important' }}>
                    <InputText
                      value={values.minutos ?? ''}
                      onChange={(e) => { handleChange(e); setFieldError("minutos", "") }}
                      id="minutos"
                      error={errors.minutos ? true : false}
                      name="minutos"
                      type="number"
                      label="Tempo de avaliação individual"
                      helperText={errors.minutos}
                      className="mt-2"
                    />
                  </div>
                  <div className="ms-Grid-col ms-sm12" style={{ paddingLeft: 0, paddingRight: '5px !important' }}>
                    <InputCheckbox
                      checked={values.flagIgnorarCurva}
                      onChange={handleChange}
                      name="flagIgnorarCurva"
                      color="primary"
                      label="Ignorar curva de performance"
                    />
                  </div>
                </div>
              </div>
            </form>
          )
        }}
      </Formik>
    </>
  );
};

export default FormEditInfoBasic;
