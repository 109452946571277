import React from 'react';

import Colors from '~/assets/js/colors';

import { FontIcon, IDropdownOption, Stack, Icon } from 'office-ui-fabric-react';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import { withStyles } from '@material-ui/core/styles';

export const AcimaExpectativa = (text?: string) => (
    <>
        <FontIcon iconName="SkypeCircleArrow" style={{ fontSize: 16, marginRight: 5, color: Colors.blue, transform: 'rotate(90deg)' }} />
        {text ?? "ACIMA DA EXPECTATIVA"}
    </>
)

export const AbaixoExpectativa = (text?: string) => (
    <>
        <FontIcon iconName="SkypeCircleArrow" style={{ fontSize: 16, marginRight: 5, color: Colors.error, transform: 'rotate(-90deg)' }} />
        {text ?? "ABAIXO DA EXPECTATIVA"}
    </>
)

export const Parcialmente = (text?: string) => (
    <>
        <FontIcon iconName="CircleHalfFull" style={{ fontSize: 16, marginRight: 5, color: "#FB6B20" }} />
        {text ?? "PARCIALMENTE ATINGIDO"}
    </>
)

export const DentroExpectativa = (text?: string) => (
    <>
        <FontIcon iconName="SkypeCircleCheck" style={{ fontSize: 16, marginRight: 5, color: Colors.primary }} />
        {text ?? "DENTRO DA EXPECTATIVA"}
    </>
)

export const onRenderTitle = (options: IDropdownOption[]): JSX.Element => {
    const option = options[0];

    return (
        <Stack horizontal verticalAlign="center">
            {option.data && option.data.icon && (
                <Icon iconName={option.data.icon} aria-hidden="true" title={option.data.icon} styles={{ root: { ...option.data.style, marginRight: 10 } }} />
            )}
            <Stack>
                <span style={{ fontSize: 12, whiteSpace: 'break-spaces', lineHeight: 1.5, textAlign: 'left', ...option.data.styleOption }}>{option.text}</span>
            </Stack>
        </Stack>
    );
};


export const Accordion = withStyles({
    root: {
        padding: '0px !important',
        paddingBottom: 10,
        boxShadow: 'none',
        display: 'contents',

        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {
        borderBottom: 0,
    },
})(MuiAccordion);

export const AccordionSummary = withStyles({
    root: {
        borderBottom: "1px solid #CFD3DA",
        height: 50,
        minHeight: '50px !important',
        '&:hover': {
            backgroundColor: '#F3F2F1'
        },
        expanded: {
        },
    }
})(MuiAccordionSummary);

export const optionsIniciativas: (IDropdownOption | any)[] = [
    { key: 'Selecione uma opção', text: 'Selecione uma opção', disabled: true, hidden: true, data: { styleOption: { fontSize: 14 } } },
    { key: 'Superado', text: 'SUPERADO', data: { icon: 'SkypeCircleArrow', style: { color: Colors.blue, transform: 'rotate(90deg)' } } },
    { key: 'Atingido', text: 'ATINGIDO', data: { icon: 'SkypeCircleCheck', style: { color: Colors.primary } } },
    { key: 'Parcialmente atingido', text: 'PARCIALMENTE ATINGIDO', data: { icon: 'CircleHalfFull', style: { color: "#FB6B20" } } },
    { key: 'Não atingido', text: 'NÃO ATINGIDO', data: { icon: 'SkypeCircleArrow', style: { color: Colors.error, transform: 'rotate(-90deg)' } } },
];
