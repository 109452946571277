/**
 * Types
 */
export enum Types {
    //GET
    REQUEST_VACANCIES_LOAD = "vacancies/REQUEST_VACANCIES_LOAD",
    SUCCESS_VACANCIES_LOAD = "vacancies/SUCCESS_VACANCIES_LOAD",
    FAILURE_VACANCIES_LOAD = "vacancies/FAILURE_VACANCIES_LOAD",
}


export type VacancyType = {
    idVaga: number | null;
    nomeVaga: string;
}

interface IVacanciesState {
    readonly data: VacancyType[];
    readonly loading: boolean;
    readonly error: boolean;
    readonly success: boolean;
};

export type DataTypes = IVacanciesState;

/**
 * Actions
 */

//GET
export interface IRequestVacancyLoadAction {
    type: typeof Types.REQUEST_VACANCIES_LOAD;
    payload: {
        idArea: number;
    }
};

export interface ISuccessVacancyLoadAction {
    type: typeof Types.SUCCESS_VACANCIES_LOAD;
    payload: VacancyType[];
};

interface IFailureVacancyLoadAction {
    type: typeof Types.FAILURE_VACANCIES_LOAD;
    message: string;
};

type VacancyLoadAction = IRequestVacancyLoadAction | ISuccessVacancyLoadAction | IFailureVacancyLoadAction;

export type VacancyActionTypes = VacancyLoadAction;