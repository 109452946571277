import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import { Formik } from "formik";
import * as yup from "yup";

import {
  DetailsListLayoutMode,
  SelectionMode,
  Selection,
  IColumn,
} from "office-ui-fabric-react/lib/DetailsList";
import { ShimmeredDetailsList } from "office-ui-fabric-react/lib/ShimmeredDetailsList";
import {
  CommandBarButton,
  DefaultButton,
  IButtonStyles,
  ICommandBarStyles,
  IconButton,
  IDialogContentProps,
} from "office-ui-fabric-react";
import { Stack } from "office-ui-fabric-react";

import Panel from "~/components/layout/Panel";
import { DeleteButton, PrimaryButton } from "~/components/Buttons";
import { InputAutocomplete, InputDate } from "~/components/Forms";
import NoItems from "~/components/layout/NoItems";
import CustomDialog from "~/components/CustomDialogFluentUI";

import { WrapperAutoComplete } from "../styles";

import { Creators as loadEmployeeCargos } from "~/store/ducks/employees";
import { Creators as addEmployeeCargo } from "~/store/ducks/employees";
import { Creators as editEmployeeCargo } from "~/store/ducks/employees";
import { Creators as delEmployeeCargo } from "~/store/ducks/employees";
import {
  EmployeeCargoType,
  EmployeeSelected,
} from "~/store/ducks/employees/types";

import {
  PositionTypes,
  DataTypes as DataTypesCargo,
} from "~/store/ducks/admin/positions/types";
import { Creators as getPositions } from "~/store/ducks/admin/positions";

import { RootState } from "~/store/ducks";
import SearchPosition from "~/pages/Admin/Positions/SearchPosition";
import { Creators as getTiposMov } from "~/store/ducks/admin/tipomov";
import {
  DataTypes as DataTypesTipoMov,
  TipoMovType,
} from "~/store/ducks/admin/tipomov/types";
import { Text } from "@fluentui/react";

interface IEmployeeAreasState {
  columns: IColumn[];
  items: EmployeeCargoType[];
  selectionDetails: string;
  selectionCount: number;
  cargoSelected: Partial<PositionTypes> | null;
  tipoMovSelected: Partial<TipoMovType> | null;
  isPanelOpen: boolean;
  isDialogExcludeOpen: boolean;
  isDialogSearchCargoOpen: boolean;
  initialValues: EmployeeCargoType;
}

interface IEmployeeCargosProps {
  employee: EmployeeSelected;
  idFuncionario: number;
  cargoState: DataTypesCargo;
  tipoMovState: DataTypesTipoMov;
  loadEmployeeCargos: (idFuncionario: number) => void;
  addEmployeeCargo: (idFuncionario: number, cargo: EmployeeCargoType) => void;
  editEmployeeCargo: (idFuncionario: number, cargo: EmployeeCargoType) => void;
  delEmployeeCargo: (idFuncionario: number, idHistCargo: number) => void;
  getTiposMov: (search?: string) => void;
  getPositions: (
    search?: string,
    isSearch?: boolean,
    filter?: boolean | null,
    gs?: string | null
  ) => void;
}

class EmployeeCargos extends Component<
  IEmployeeCargosProps,
  IEmployeeAreasState
> {
  private _selection: Selection;
  private formRef: any;
  private timeout: number;

  constructor(props: IEmployeeCargosProps) {
    super(props);

    const columns: IColumn[] = [
      {
        key: "column1",
        name: "Data",
        fieldName: "dataHist",
        minWidth: 75,
        maxWidth: 75,
        isRowHeader: true,
        isResizable: true,
        // isSortedDescending: false,
        // sortAscendingAriaLabel: 'Sorted A to Z',
        // sortDescendingAriaLabel: 'Sorted Z to A',
        // onColumnClick: this._onColumnClick,
        data: "data",
        isPadded: true,
        onRender: (item: EmployeeCargoType) =>
          moment(item.dataHist).format("DD/MM/YYYY"),
      },
      {
        key: "column2",
        name: "ID",
        ariaLabel: "Código id hist Cargo",
        fieldName: "idCargo",
        isRowHeader: true,
        minWidth: 50,
        maxWidth: 50,
        isResizable: true,
        // isSortedDescending: false,
        // onColumnClick: this._onColumnClick
      },
      {
        key: "column3",
        name: "Cargo",
        fieldName: "cargo",
        minWidth: 200,
        isRowHeader: true,
        isResizable: true,
        // isSortedDescending: false,
        // sortAscendingAriaLabel: 'Sorted A to Z',
        // sortDescendingAriaLabel: 'Sorted Z to A',
        // onColumnClick: this._onColumnClick,
        data: "string",
        isPadded: true,
        onRender: (item: EmployeeCargoType) => (
          <Stack styles={{ root: { whiteSpace: "break-spaces" } }}>
            <Text>{item.cargo.titulo}</Text>
          </Stack>
        ),
      },
      {
        key: "column4",
        name: "Motivo",
        fieldName: "tipomov.descTipoMov",
        minWidth: 150,
        isRowHeader: true,
        isResizable: true,
        // isSortedDescending: false,
        // sortAscendingAriaLabel: 'Sorted A to Z',
        // sortDescendingAriaLabel: 'Sorted Z to A',
        // onColumnClick: this._onColumnClick,
        data: "string",
        isPadded: true,
        onRender: (item: EmployeeCargoType) => (
          <Stack styles={{ root: { whiteSpace: "break-spaces" } }}>
            <Text>{item.tipomov?.descTipoMov}</Text>
          </Stack>
        ),
      },
    ];

    this.state = {
      columns,
      items: [],
      selectionCount: 0,
      selectionDetails: "",
      cargoSelected: null,
      tipoMovSelected: null,
      isPanelOpen: false,
      isDialogExcludeOpen: false,
      isDialogSearchCargoOpen: false,
      initialValues: initialValuesCargo,
    };

    this._selection = new Selection({
      onSelectionChanged: () => {
        this.setState({
          selectionDetails: this._getSelectionDetails(),
        });
      },
    });

    this.formRef = React.createRef();
    this.timeout = 0;
  }

  componentDidMount() {
    const { loadEmployeeCargos, idFuncionario, employee } = this.props;

    if (employee.cargos?.length === 0 || employee.cargos === null) {
      loadEmployeeCargos(idFuncionario);
    }
  }

  componentDidUpdate(prevProps: IEmployeeCargosProps) {
    if (prevProps.employee.success !== this.props.employee.success) {
      if (this.props.employee.success) {
        this.setState({ isPanelOpen: false });
      }
    }
  }

  _getSelectionDetails(): any {
    const selectionCount = this._selection.getSelectedCount();
    this.setState({ selectionCount });
    this._selection.getSelection();
  }

  cancelPanel = () => {
    this._selection.setAllSelected(false);
    this.setState({ isPanelOpen: false });
  };

  handleSubmit = () => {
    if (this.formRef.current) {
      this.formRef.current.handleSubmit();
    }
  };

  search = (text: string, type: string) => {
    if (text.trim()) {
      clearTimeout(this.timeout);
      this.timeout = window.setTimeout(() => {
        if (type === "cargo") {
          this.props.getPositions(text, false, true);
        }
        if (type === "tipoMov") {
          this.props.getTiposMov(text);
        }
      }, 700);
    }
  };

  _onItemInvoked = (item: EmployeeCargoType): void => {
    this.openEdit();
  };

  openEdit = () => {
    const selected = this._selection.getSelection()[0] as EmployeeCargoType;

    this.setState({
      isPanelOpen: true,
      initialValues: selected,
      cargoSelected: selected.cargo,
      tipoMovSelected: selected.tipomov,
    });
  };

  excludeArea = () => {
    const { idFuncionario } = this.props;
    const idHistCargo = (this._selection.getSelection()[0] as EmployeeCargoType)
      .idHistCargo!;
    this.props.delEmployeeCargo(idFuncionario, idHistCargo);
    this.setState({ isDialogExcludeOpen: false });
    this._selection.setAllSelected(false);
  };
  render() {
    const {
      columns,
      cargoSelected,
      tipoMovSelected,
      isPanelOpen,
      isDialogExcludeOpen,
      isDialogSearchCargoOpen,
      initialValues,
    } = this.state;
    const {
      employee,
      loadEmployeeCargos,
      idFuncionario,
      addEmployeeCargo,
      editEmployeeCargo,
      cargoState,
      tipoMovState,
    } = this.props;
    const { cargos, loadingItems, loadingAction } = employee;
    return (
      <>
        <Stack
          horizontal
          horizontalAlign="space-between"
          styles={{ root: { width: "100%" } }}
        >
          <Stack horizontal verticalAlign="center">
            <CommandBarButton
              styles={btnStyle}
              iconProps={{ iconName: "Add" }}
              text="Adicionar Cargo"
              onClick={() =>
                this.setState({
                  isPanelOpen: true,
                  cargoSelected: null,
                  tipoMovSelected: null,
                  initialValues: initialValuesCargo,
                })
              }
            />
            {this._selection.getSelectedCount() > 0 && (
              <CommandBarButton
                styles={btnStyle}
                iconProps={{ iconName: "Edit" }}
                text="Editar"
                onClick={this.openEdit}
              />
            )}
            {this._selection.getSelectedCount() > 0 && (
              <CommandBarButton
                styles={btnStyle}
                iconProps={{ iconName: "Delete" }}
                text="Excluir"
                onClick={() => this.setState({ isDialogExcludeOpen: true })}
              />
            )}
          </Stack>
          <Stack>
            <CommandBarButton
              styles={btnStyle}
              iconProps={{ iconName: "Refresh" }}
              text="Atualizar"
              onClick={() => loadEmployeeCargos(idFuncionario)}
            />
          </Stack>
        </Stack>
        {cargos?.length === 0 ? (
          <NoItems text="Não há histórico de cargos" icon="Work" />
        ) : (
          <ShimmeredDetailsList
            items={cargos ?? []}
            enableShimmer={loadingItems}
            columns={columns}
            selectionMode={SelectionMode.single}
            selection={this._selection}
            getKey={this._getKey}
            selectionPreservedOnEmptyClick={true}
            setKey="single"
            layoutMode={DetailsListLayoutMode.justified}
            onItemInvoked={this._onItemInvoked}
            isHeaderVisible={true}
            styles={{ root: { paddingTop: 0 } }}
          />
        )}

        <Panel
          title={
            !initialValues.idHistCargo
              ? "Novo Cargo"
              : initialValues.cargo.titulo
          }
          open={isPanelOpen}
          onClose={() => this.cancelPanel()}
          footer={
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <DefaultButton onClick={() => this.cancelPanel()}>
                Cancelar
              </DefaultButton>
              <PrimaryButton
                onClick={this.handleSubmit}
                isLoading={loadingAction}
                text="Salvar"
              />
            </Stack>
          }
        >
          <Formik
            innerRef={this.formRef}
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnChange={false}
            validateOnBlur={true}
            enableReinitialize
            onSubmit={(values) => {
              values.cargo!.titulo = cargoSelected?.titulo ?? "";
              if (!!tipoMovSelected) {
                values.tipomov = {
                  descTipoMov: tipoMovSelected.descTipoMov ?? "",
                  idTipoMov: tipoMovSelected.idTipoMov ?? null,
                };
              }
              if (!values.idHistCargo) {
                addEmployeeCargo(idFuncionario, values);
              } else {
                editEmployeeCargo(idFuncionario, values);
              }
            }}
          >
            {({
              handleSubmit,
              handleChange,
              values,
              errors,
              setFieldError,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <InputDate
                  label="Data"
                  name="dataHist"
                  value={values.dataHist ? moment(values.dataHist) : null}
                  onChange={(value) => {
                    setFieldValue("dataHist", value);
                    setFieldError("dataHist", "");
                  }}
                  className="mt-2"
                  error={!!errors.dataHist}
                  helperText={errors.dataHist}
                  autoFocus
                />
                <WrapperAutoComplete>
                  <InputAutocomplete
                    value={cargoSelected as PositionTypes}
                    onChange={(_, newValue) => {
                      this.setState({ cargoSelected: newValue });
                      setFieldValue("idCargo", newValue?.idCargo);
                    }}
                    onInputChange={(_, newInputValue) => {
                      setFieldError("idCargo", "");
                      this.search(newInputValue, "cargo");
                    }}
                    getOptionLabel={(position: PositionTypes) =>
                      `${position.idCargo} - ${position.titulo}`
                    }
                    getOptionSelected={(option, value) =>
                      option.idCargo === value.idCargo
                    }
                    options={cargoState.data}
                    input={{
                      idInput: "idCargo",
                      labelInput: "Cargo",
                      helperTextInput: errors.idCargo,
                      errorInput: !!errors.idCargo,
                    }}
                  />
                  <IconButton
                    iconProps={{ iconName: "Search" }}
                    styles={IconButtonStyle}
                    onClick={() => {
                      this.setState({ isDialogSearchCargoOpen: true });
                    }}
                  />
                </WrapperAutoComplete>

                <InputAutocomplete
                  value={tipoMovSelected as TipoMovType}
                  onChange={(_, newValue) => {
                    this.setState({ tipoMovSelected: newValue });
                    setFieldValue("idTipoMov", newValue?.idTipoMov);
                  }}
                  onInputChange={(_, newInputValue) => {
                    setFieldError("idTipoMov", "");
                    this.search(newInputValue, "tipoMov");
                  }}
                  getOptionLabel={(tipoMov: TipoMovType) =>
                    `${tipoMov.idTipoMov} - ${tipoMov.descTipoMov}`
                  }
                  getOptionSelected={(option, value) =>
                    option.idTipoMov === value.idTipoMov
                  }
                  options={tipoMovState.data}
                  input={{
                    idInput: "idTipoMov",
                    labelInput: "Motivo",
                    helperTextInput: errors.idTipoMov,
                    errorInput: !!errors.idTipoMov,
                  }}
                />

                <SearchPosition
                  isOpen={isDialogSearchCargoOpen}
                  onAdd={(cargo) => {
                    setFieldValue("idCargo", cargo[0].idCargo);
                    setFieldError("idCargo", "");
                    this.setState({
                      cargoSelected: cargo[0],
                      isDialogSearchCargoOpen: false,
                    });
                  }}
                  onClose={() =>
                    this.setState({ isDialogSearchCargoOpen: false })
                  }
                />
              </form>
            )}
          </Formik>
        </Panel>

        <CustomDialog
          hidden={!isDialogExcludeOpen}
          onDismiss={() => this.setState({ isDialogExcludeOpen: false })}
          dialogContentProps={dialogContentProps}
        >
          <DefaultButton
            onClick={() => this.setState({ isDialogExcludeOpen: false })}
            text="Cancelar"
          />
          <DeleteButton onClick={() => this.excludeArea()} text="Excluir" />
        </CustomDialog>
      </>
    );
  }

  private _onColumnClick = (
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn
  ): void => {
    const { columns } = this.state;
    const newColumns: IColumn[] = columns.slice();
    let items: EmployeeCargoType[] = this.props.employee.cargos ?? [];
    const currColumn: IColumn = newColumns.filter(
      (currCol) => column.key === currCol.key
    )[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    const newItems = this._sort(
      items,
      currColumn.fieldName!,
      currColumn.isSortedDescending
    );
    this.setState({
      columns: newColumns,
      items: newItems,
    });
  };

  private _getKey(item: any, index?: number): any {
    if (item !== undefined) return item.key;
  }

  private _sort<T>(
    items: T[],
    columnKey: string,
    isSortedDescending?: boolean
  ): T[] {
    const key = columnKey as keyof T;
    const itemsSorted = items.sort((a: T, b: T) =>
      (isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1
    );
    return itemsSorted;
  }
}

const btnStyle: Partial<ICommandBarStyles> = {
  root: {
    height: 44,
  },
};

const IconButtonStyle: Partial<IButtonStyles> = {
  root: {
    position: "absolute",
    top: 20,
    right: 8,
    width: 24,
    height: 24,
    padding: 8,
  },
  icon: {
    fontSize: 12,
  },
};

const initialValuesCargo: EmployeeCargoType = {
  dataHist: null,
  idCargo: null,
  cargo: {
    idCargo: null,
    titulo: "",
  },
  tipomov: null,
  idTipoMov: null,
};

const validationSchema = yup.object().shape({
  dataHist: yup
    .date()
    .nullable()
    .typeError("Data inválida")
    .required("Campo obrigatório"),
  idCargo: yup.number().nullable().required("Campo obrigatório"),
  idTipoMov: yup.number().nullable(),
});

const dialogContentProps: IDialogContentProps = {
  title: "Excluir?",
  closeButtonAriaLabel: "Close",
  subText: "Tem certeza de que deseja excluir este item?",
};

const mapStateToProps = (state: RootState) => ({
  employee: state.employeesReducer.itemSelected,
  cargoState: state.positionsReducer,
  tipoMovState: state.tipoMovReducer,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      ...loadEmployeeCargos,
      ...addEmployeeCargo,
      ...editEmployeeCargo,
      ...delEmployeeCargo,
      ...getPositions,
      ...getTiposMov,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeCargos);
