import { Types, DataTypes, CarreirasActionTypes } from "./types";
import customToast from "~/components/Toast/index";
/**
 * Reducers
 */
const INITIAL_STATE: DataTypes = {
  data: [],
  loading: false,
  success: false,
  error: false,
};

/**
 * Actions
 */

export const Creators = {
  getCarreiras: (): CarreirasActionTypes => {
    return {
      type: Types.REQUEST_LOAD_CARREIRAS,
    };
  },
};

export default function carreirasReducer(
  state = INITIAL_STATE,
  action: CarreirasActionTypes
): DataTypes {
  switch (action.type) {
    // LOAD
    case Types.REQUEST_LOAD_CARREIRAS:
      return {
        ...state,
        loading: true,
        error: false,
        success: false,
      };
    case Types.SUCCESS_LOAD_CARREIRAS:
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        data: action.payload,
      };
    case Types.FAILURE_LOAD_CARREIRAS:
      customToast.error(action.message);
      return {
        ...state,
        data: [],
        loading: false,
        success: false,
        error: true,
      };

    default:
      return state;
  }
}
