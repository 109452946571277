import React, { MutableRefObject, useEffect, useState } from "react";

import { IconButton, Text, ITextStyles } from "office-ui-fabric-react";
import {
  WrapperRow,
  WrapperIconSelect,
  IconCheck,
  Check,
  WrapperColumn,
  WrapperContent,
  WrapperColumnFlexWidth,
  WrapperColumnGap,
  WrapperHeader,
  WrapperColumnStatus,
  Ul,
} from "./styles";

import Status from "~/components/Status";
import Shimmer from "~/components/Shimmer/shimmerTreeview";

import "./styles.css";

interface StatusType {
  hasStatus: boolean;
  fieldName: string;
  component?: (item: any) => JSX.Element;
}

interface IStateProps {
  data: any[];
  success: boolean;
  loadingData: boolean;
  error?: boolean;
}

interface IColumnType {
  name: string;
  fieldName: string;
  width?: number;
  position?: "left" | "right";
  justifyHeader?: "center" | "flex-start" | "flex-end";
  justifyValue?: "center" | "flex-start" | "flex-end";
  onRender?: (text: string, values: any) => string | JSX.Element;
}

interface ITreeViewProps<T = IStateProps> {
  state: T;
  status?: StatusType;
  statusFieldName?: string;
  fieldId: string;
  columns: IColumnType[];
  selectionMode?: "single" | "none" | "multiple";
  clearSelection?: boolean;
  handleOnItemInvoked?: (item: any) => void;
  changeItemsSelected: (items: any[]) => void;
}

let allItemsSelected: any[] = [];

const TreeView: React.FC<ITreeViewProps> = (props) => {
  const {
    state,
    fieldId,
    status,
    columns,
    statusFieldName,
    selectionMode = "single",
    clearSelection,
    changeItemsSelected,
    handleOnItemInvoked,
  } = props;

  useEffect(() => {

   // allItemsSelected = [];
    // Add class disableClick to all items on button expand/collapse
    const allBtnToggle = document.getElementsByClassName(
      "ms-Button-flexContainer"
    );
    const size = allBtnToggle.length;
    for (let i = 0; i < size; i++) {
      allBtnToggle[i].classList.add("disableClick");
    }

    var toggler = document.getElementsByClassName("group");
    for (let i = 0; i < toggler.length; i++) {
      const ulElement = toggler[
        i
      ].parentElement?.parentElement?.parentElement!.querySelector(".nested")!;

      if (ulElement.getAttribute("loaded") !== "true") {
        toggler[i].addEventListener("click", function () {
          ulElement.classList.toggle("active");
        });
        ulElement.setAttribute("loaded", "true");
      }
    }

  }, [state]);

  useEffect(() => {
    if (clearSelection) {
      allItemsSelected.forEach((item) => {
        document
          .getElementById(item[fieldId])
          ?.classList.remove("itemSelected");
      });
    }
  }, [clearSelection]);

  const renderItemsMemo = React.useMemo(() => {
    const renderTreeView = (data: any[], level: number = 0) => {
      return data.map((item, i) => {
        if (item) {
          if (item.children && item[fieldId] !== undefined) {
            const id = item[fieldId];
            return (
              <li key={i}>
                <Row
                  status={status}
                  statusFieldName={statusFieldName}
                  hasChildren={item.children.length > 0 ? true : false}
                  id={id}
                  level={level}
                  values={item}
                  columns={columns}
                  allItemsSelected={allItemsSelected}
                  changeItemsSelected={changeItemsSelected}
                  fieldId={fieldId}
                  selectionMode={selectionMode}
                  handleOnItemInvoked={handleOnItemInvoked}
                />
                {item.children.length > 0 && (
                  <Ul className="nested">
                    {renderTreeView(item.children, level + 1)}
                  </Ul>
                )}
              </li>
            );
          } else {
            return null;
          }
        } else {
          return null;
        }
      });
    };

    const data = state.data;
    return renderTreeView(data);
  }, [state.success, state?.error]);

  return (
    <>
      <Header
        columns={columns}
        status={status}
        isNotSelectable={selectionMode === "none"}
      />
      {state.loadingData ? (
        <Shimmer />
      ) : (
        <Ul id="treeview">{renderItemsMemo}</Ul>
      )}
    </>
  );
};

interface IRowProps {
  hasChildren?: boolean;
  status?: StatusType;
  statusFieldName?: string;
  id: string;
  level: number;
  values: any;
  allItemsSelected: any[];
  columns: IColumnType[];
  selectionMode: "single" | "none" | "multiple" | undefined;
  fieldId: string;
  changeItemsSelected: (items: any[]) => void;
  handleOnItemInvoked?: (item: any) => void;
}

const Row: React.FC<IRowProps> = (props) => {
  const [isExpanded, setIsExpanded] = useState(false);
  let {
    id,
    hasChildren,
    level,
    values,
    columns,
    status,
    allItemsSelected,
    selectionMode,
    fieldId,
    changeItemsSelected,
    handleOnItemInvoked,
  } = props;
  const ref: any = React.useRef();

  const handleIcon = () => {
    setIsExpanded(!isExpanded);
  };

  const selectFilhos = (ref: any, itemSelected: any) => {
    console.log("selectFilhos")
    const areasFilhos = ref.parentElement.querySelectorAll(
      "ul div.row"
    ) as HTMLDivElement[];

    areasFilhos.forEach((filho) => {
      filho.classList.add("itemSelected");
    });

    selectAllChildren(itemSelected);
  };

  const selectAllChildren = (itemSelected: any) => {
    console.log("selectAllChildren")
    itemSelected?.children.forEach((filho: any) => {
      const findedItem = allItemsSelected.find(
        (selItem: any) => selItem[fieldId] === filho[fieldId]
      );
      if (!findedItem) {
        allItemsSelected.push(filho);
      }
      if (filho.children) {
        selectAllChildren(filho);
      }
    });
  };

  const diselectFilhos = (ref: any, itemSelected: any) => {
    console.log('diselectFilhos')

    const areasFilhos = ref.parentElement.querySelectorAll(
      "ul div.row"
    ) as HTMLDivElement[];

    areasFilhos.forEach((filho) => {
      filho.classList.remove("itemSelected");
    });

    diselectAllChildren(itemSelected);
  };

  const diselectAllChildren = (itemSelected: any) => {
    console.log('diselectAllChildren')

    itemSelected?.children.forEach((filho: any) => {
      const itemIndex = allItemsSelected.findIndex(
        (selItem: any) => selItem[fieldId] === filho[fieldId]
      );

      allItemsSelected.splice(itemIndex, 1);

      if (filho.children.length > 0) {
        diselectAllChildren(filho);
      }
    });

  };

  const handleClick = (e: any) => {
    console.log("Clicou na treeview")
    const alreadyAddedIndex =
      allItemsSelected?.findIndex((item) => item[fieldId] == Number(id)) ?? -1;
    const el = e.target;

    const isBtnToggle = el.classList.contains("disableClick");

    if (!isBtnToggle) {
      const hasItemsSelected = allItemsSelected.length > 0;
      const isIconActive = el.classList.contains("iconActive");

      if (selectionMode === "multiple") {
        if (hasItemsSelected && isIconActive) {
          if (ref.current) {
            //Deselecionar linha
            console.log("Entrou no 1")
            if (alreadyAddedIndex >= 0) {
              ref.current.classList.remove("itemSelected");
              allItemsSelected.splice(alreadyAddedIndex, 1);
              diselectFilhos(ref.current, values);
            } else {
              //Selecionar linha quando já tem outras linhas selecionadas
              ref.current.classList.add("itemSelected");
              selectFilhos(ref.current, values);

              allItemsSelected.push(values);
            }
          }
        } else {
          //Selecionar linha quando não tem outras linhas selecionadas
          allItemsSelected.forEach((item) => {
            document
              .getElementById(item[fieldId])
              ?.classList.remove("itemSelected");
          });
          allItemsSelected.splice(0, allItemsSelected.length);
          ref.current.classList.add("itemSelected");

          allItemsSelected.push(values);
          selectFilhos(ref.current, values);
        }
      } else if (selectionMode === "single") {
        if (hasItemsSelected) {
          if (isIconActive) {
            if (allItemsSelected[0][fieldId] === values[fieldId]) {
              ref?.current.classList.remove("itemSelected");
              allItemsSelected.splice(0, 1);
            } else {
              const lastEl = document.getElementById(
                allItemsSelected[0][fieldId]
              );
              lastEl?.classList.remove("itemSelected");
              allItemsSelected.splice(0, 1);
              ref.current.classList.add("itemSelected");
              allItemsSelected.push(values);
            }
          } else {
            const lastEl = document.getElementById(
              allItemsSelected[0][fieldId]
            );
            lastEl?.classList.remove("itemSelected");
            allItemsSelected.splice(0, 1);
            ref.current.classList.add("itemSelected");
            allItemsSelected.push(values);
          }
        } else {
          ref.current.classList.add("itemSelected");
          allItemsSelected.push(values);
        }
      }

      changeItemsSelected?.(allItemsSelected);
    }
  };

  const handleDoubleClick = () => {
    handleOnItemInvoked?.(values);
  };

  return (
    <WrapperRow
      ref={ref}
      id={id}
      className="row"
      onClick={(e) => handleClick(e)}
      onDoubleClick={handleDoubleClick}
    >
      {!(selectionMode === "none") && (
        <WrapperIconSelect data-icon={id} className="iconActive">
          <IconCheck iconName="CircleRing" className="iconActive" />
          <Check iconName="StatusCircleCheckmark" className="iconActive" />
        </WrapperIconSelect>
      )}
      <WrapperIconSelect className="btn-toggle" level={level}>
        {hasChildren && (
          <IconButton
            iconProps={{
              iconName: isExpanded ? "ChevronDown" : "ChevronRight",
              className: "disableClick",
            }}
            className="group disableClick"
            title="Expandir"
            ariaLabel="Expandir"
            onClick={handleIcon}
            styles={{
              root: {
                height: "100%",
                cursor: "pointer",
                userSelect: "none",
                width: 48,
              },
              icon: { color: "#323130" },
            }}
          />
        )}
      </WrapperIconSelect>
      <WrapperContent data-id={id}>
        {status && (
          <>
            {status.component ? (
              status.component(values)
            ) : (
              <WrapperColumnStatus>
                <Status status={values[status.fieldName]} />
              </WrapperColumnStatus>
            )}
          </>
        )}
        {columns.map((item, i, arr) => {
          const {
            fieldName,
            onRender,
            justifyValue,
            width,
            position = "right",
          } = item;
          let val = values[fieldName];

          if (onRender) {
            val = onRender(values[fieldName], values);
          }

          if (i === 0 || position === "left") {
            const isLast = arr[i + 1]?.position !== "left";
            return (
              <WrapperColumnFlexWidth key={i} last={isLast} width={width}>
                {val}
              </WrapperColumnFlexWidth>
            );
          } else {
            return (
              <WrapperColumn justify={justifyValue} width={width} key={i}>
                {val}
              </WrapperColumn>
            );
          }
        })}
        <WrapperColumnGap numColumn={columns.length} />
      </WrapperContent>
    </WrapperRow>
  );
};

interface IHeaderProps {
  columns: IColumnType[];
  status?: StatusType;
  isNotSelectable?: boolean;
}

const TextStyle: Partial<ITextStyles> = {
  root: {
    fontWeight: 600,
  },
};

const Header: React.FC<IHeaderProps> = (props) => {
  const { columns, isNotSelectable, status } = props;
  const marginHeader = (!isNotSelectable ? 48 : 0) + (status ? 45 : 0) + 49;
  return (
    <WrapperHeader>
      {columns.map((item, i, arr) => {
        const { name, width, justifyHeader, position } = item;
        if (i === 0 || position === "left") {
          const isLast = arr[i + 1]?.position !== "left";
          return (
            <WrapperColumnFlexWidth
              style={{ marginLeft: i === 0 ? marginHeader : 0 }}
              key={i}
              last={isLast}
              width={width}
            >
              <Text variant="medium" styles={TextStyle}>
                {name}
              </Text>
            </WrapperColumnFlexWidth>
          );
        } else {
          return (
            <WrapperColumn width={width} key={i} justify={justifyHeader}>
              <Text variant="medium" styles={TextStyle}>
                {name}
              </Text>
            </WrapperColumn>
          );
        }
      })}
      <WrapperColumnGap numColumn={columns.length} />
    </WrapperHeader>
  );
};

export default TreeView;
