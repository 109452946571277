import { AvaliadoCommentType } from "../calibration/types";
import { EmployeeType } from "../employees/types";
import { TeamEvaluationType } from "../teamEvaluation/types";
/**
 * Types
 */
export enum Types {
  //GET
  REQUEST_TASKS_LOAD = "tasks/REQUEST_TASKS_LOAD",
  SUCCESS_TASKS_LOAD = "tasks/SUCCESS_TASKS_LOAD",
  FAILURE_TASKS_LOAD = "tasks/FAILURE_TASKS_LOAD",

  REQUEST_TASKS_LOAD_ID = "tasks/REQUEST_TASKS_LOAD_ID",
  SUCCESS_TASKS_LOAD_ID = "tasks/SUCCESS_TASKS_LOAD_ID",
  FAILURE_TASKS_LOAD_ID = "tasks/FAILURE_TASKS_LOAD_ID",

  REQUEST_TASKS_FINISH = "tasks/REQUEST_TASKS_FINISH",
  SUCCESS_TASKS_FINISH = "tasks/SUCCESS_TASKS_FINISH",
  FAILURE_TASKS_FINISH = "tasks/FAILURE_TASKS_FINISH",

  // ADD PDI
  REQUEST_TASKS_ACAO_PDI_ADD = "tasks/REQUEST_TASKS_ACAO_PDI_ADD",
  SUCCESS_TASKS_ACAO_PDI_ADD = "tasks/SUCCESS_TASKS_ACAO_PDI_ADD",
  FAILURE_TASKS_ACAO_PDI_ADD = "tasks/FAILURE_TASKS_ACAO_PDI_ADD",
  // EDIT PDI
  REQUEST_TASKS_ACAO_PDI_EDIT = "tasks/REQUEST_TASKS_ACAO_PDI_EDIT",
  SUCCESS_TASKS_ACAO_PDI_EDIT = "tasks/SUCCESS_TASKS_ACAO_PDI_EDIT",
  FAILURE_TASKS_ACAO_PDI_EDIT = "tasks/FAILURE_TASKS_ACAO_PDI_EDIT",
  // DELETE PDI
  REQUEST_TASKS_ACAO_PDI_DEL = "tasks/REQUEST_TASKS_ACAO_PDI_DEL",
  SUCCESS_TASKS_ACAO_PDI_DEL = "tasks/SUCCESS_TASKS_ACAO_PDI_DEL",
  FAILURE_TASKS_ACAO_PDI_DEL = "tasks/FAILURE_TASKS_ACAO_PDI_DEL",

  SAVE_TASK_INICIATIVAS_AUTOAVALICACAO = "tasks/SAVE_TASK_INICIATIVAS_AUTOAVALICACAO",
  SAVE_TASK_ATITUDE_AVALIACAO = "tasks/SAVE_TASK_ATITUDE_AVALIACAO",
  SAVE_TASK_ATITUDE_COMENTARIO = "tasks/SAVE_TASK_ATITUDE_COMENTARIO",
  SAVE_TASK_ASPIRACOES_CARREIRA = "tasks/SAVE_TASK_ASPIRACOES_CARREIRA",
  SAVE_TASK_COMENTARIOS_GERAIS = "tasks/SAVE_TASK_COMENTARIOS_GERAIS",
  // SAVE TAREFA PDI
  REQUEST_TASKS_ACAO_PDI_SAVE = "tasks/REQUEST_TASKS_ACAO_PDI_SAVE",
  SUCCESS_TASKS_ACAO_PDI_SAVE = "tasks/SUCCESS_TASKS_ACAO_PDI_SAVE",
  FAILURE_TASKS_ACAO_PDI_SAVE = "tasks/FAILURE_TASKS_ACAO_PDI_SAVE",
  // SOLICITAR CARTAS DEVOLUTIVA MERITO
  REQUEST_TASKS_DEVOLUTIVA_MERITO_SOLIC_CARTAS = "tasks/REQUEST_TASKS_DEVOLUTIVA_MERITO_SOLIC_CARTAS",
  SUCCESS_TASKS_DEVOLUTIVA_MERITO_SOLIC_CARTAS = "tasks/SUCCESS_TASKS_DEVOLUTIVA_MERITO_SOLIC_CARTAS",
  FAILURE_TASKS_DEVOLUTIVA_MERITO_SOLIC_CARTAS = "tasks/FAILURE_TASKS_DEVOLUTIVA_MERITO_SOLIC_CARTAS",
  // STATUS CARTA DEVOLUTIVA MERITO
  REQUEST_TASKS_DEVOLUTIVA_MERITO_STATUS_CARTAS = "tasks/REQUEST_TASKS_DEVOLUTIVA_MERITO_STATUS_CARTAS",
  SUCCESS_TASKS_DEVOLUTIVA_MERITO_STATUS_CARTAS = "tasks/SUCCESS_TASKS_DEVOLUTIVA_MERITO_STATUS_CARTAS",
  FAILURE_TASKS_DEVOLUTIVA_MERITO_STATUS_CARTAS = "tasks/FAILURE_TASKS_DEVOLUTIVA_MERITO_STATUS_CARTAS",
  // DOWNLOAD CARTA DEVOLUTIVA MERITO
  REQUEST_TASKS_DEVOLUTIVA_MERITO_DOWNLOAD_CARTAS = "tasks/REQUEST_TASKS_DEVOLUTIVA_MERITO_DOWNLOAD_CARTAS",
  SUCCESS_TASKS_DEVOLUTIVA_MERITO_DOWNLOAD_CARTAS = "tasks/SUCCESS_TASKS_DEVOLUTIVA_MERITO_DOWNLOAD_CARTAS",
  FAILURE_TASKS_DEVOLUTIVA_MERITO_DOWNLOAD_CARTAS = "tasks/FAILURE_TASKS_DEVOLUTIVA_MERITO_DOWNLOAD_CARTAS",
  // DEVOLUTIVA REALIZAR
  REQUEST_TASKS_DEVOLUTIVA_REALIZAR = "tasks/REQUEST_TASKS_DEVOLUTIVA_REALIZAR",
  SUCCESS_TASKS_DEVOLUTIVA_REALIZAR = "tasks/SUCCESS_TASKS_DEVOLUTIVA_REALIZAR",
  FAILURE_TASKS_DEVOLUTIVA_REALIZAR = "tasks/FAILURE_TASKS_DEVOLUTIVA_REALIZAR",
  // DEVOLUTIVA CANCELAR
  REQUEST_TASKS_DEVOLUTIVA_CANCELAR = "tasks/REQUEST_TASKS_DEVOLUTIVA_CANCELAR",
  SUCCESS_TASKS_DEVOLUTIVA_CANCELAR = "tasks/SUCCESS_TASKS_DEVOLUTIVA_CANCELAR",
  FAILURE_TASKS_DEVOLUTIVA_CANCELAR = "tasks/FAILURE_TASKS_DEVOLUTIVA_CANCELAR",
  // INFO AVALIACAO COLETIVA
  REQUEST_TASKS_COLETIVA_INFO_AVALIACAO = "tasks/REQUEST_TASKS_COLETIVA_INFO_AVALIACAO",
  SUCCESS_TASKS_COLETIVA_INFO_AVALIACAO = "tasks/SUCCESS_TASKS_COLETIVA_INFO_AVALIACAO",
  FAILURE_TASKS_COLETIVA_INFO_AVALIACAO = "tasks/FAILURE_TASKS_COLETIVA_INFO_AVALIACAO",
  // EDIT AVALIACAO RESULTADO META
  REQUEST_TASKS_COLETIVA_RESULTADO_META_EDIT = "tasks/REQUEST_TASKS_COLETIVA_RESULTADO_META_EDIT",
  SUCCESS_TASKS_COLETIVA_RESULTADO_META_EDIT = "tasks/SUCCESS_TASKS_COLETIVA_RESULTADO_META_EDIT",
  FAILURE_TASKS_COLETIVA_RESULTADO_META_EDIT = "tasks/FAILURE_TASKS_COLETIVA_RESULTADO_META_EDIT",
  // EDIT AVALIACAO RESULTADO ATITUDE
  REQUEST_TASKS_COLETIVA_RESULTADO_ATITUDE_EDIT = "tasks/REQUEST_TASKS_COLETIVA_RESULTADO_ATITUDE_EDIT",
  SUCCESS_TASKS_COLETIVA_RESULTADO_ATITUDE_EDIT = "tasks/SUCCESS_TASKS_COLETIVA_RESULTADO_ATITUDE_EDIT",
  FAILURE_TASKS_COLETIVA_RESULTADO_ATITUDE_EDIT = "tasks/FAILURE_TASKS_COLETIVA_RESULTADO_ATITUDE_EDIT",
  // EDIT AVALIACAO RESULTADO FINAL
  REQUEST_TASKS_COLETIVA_RESULTADO_FINAL_EDIT = "tasks/REQUEST_TASKS_COLETIVA_RESULTADO_FINAL_EDIT",
  SUCCESS_TASKS_COLETIVA_RESULTADO_FINAL_EDIT = "tasks/SUCCESS_TASKS_COLETIVA_RESULTADO_FINAL_EDIT",
  FAILURE_TASKS_COLETIVA_RESULTADO_FINAL_EDIT = "tasks/FAILURE_TASKS_COLETIVA_RESULTADO_FINAL_EDIT",
  // EDIT AVALIACAO RESULTADO FINAL
  REQUEST_TASKS_COLETIVA_FINISH = "tasks/REQUEST_TASKS_COLETIVA_FINISH",
  SUCCESS_TASKS_COLETIVA_FINISH = "tasks/SUCCESS_TASKS_COLETIVA_FINISH",
  FAILURE_TASKS_COLETIVA_FINISH = "tasks/FAILURE_TASKS_COLETIVA_FINISH",
  //TASKS_COLETIVA_FEEDBACK FINAL
  REQUEST_TASKS_COLETIVA_FEEDBACK = "tasks/REQUEST_TASKS_COLETIVA_FEEDBACK",
  SUCCESS_TASKS_COLETIVA_FEEDBACK = "tasks/SUCCESS_TASKS_COLETIVA_FEEDBACK",
  FAILURE_TASKS_COLETIVA_FEEDBACK = "tasks/FAILURE_TASKS_COLETIVA_FEEDBACK",
  //REQUEST_TASKS_COLETIVA_FEEDBACK_FINISH
  REQUEST_TASKS_COLETIVA_FEEDBACK_FINISH = "tasks/REQUEST_TASKS_COLETIVA_FEEDBACK_FINISH",
  SUCCESS_TASKS_COLETIVA_FEEDBACK_FINISH = "tasks/SUCCESS_TASKS_COLETIVA_FEEDBACK_FINISH",
  FAILURE_TASKS_COLETIVA_FEEDBACK_FINISH = "tasks/FAILURE_TASKS_COLETIVA_FEEDBACK_FINISH",
  
  //REQUEST_TASKS_FUNCIONARIO_FEEDBACK_FINISH
  REQUEST_TASKS_FUNCIONARIO_FEEDBACK_FINISH = "tasks/REQUEST_TASKS_FUNCIONARIO_FEEDBACK_FINISH",
  SUCCESS_TASKS_FUNCIONARIO_FEEDBACK_FINISH = "tasks/SUCCESS_TASKS_FUNCIONARIO_FEEDBACK_FINISH",
  FAILURE_TASKS_FUNCIONARIO_FEEDBACK_FINISH = "tasks/FAILURE_TASKS_FUNCIONARIO_FEEDBACK_FINISH",

  RESET_TASK = "tasks/RESET_TASK",

  FILTERS_TASK_CLEAR = "tasks/CLEAR_FILTERS_TASK",
  FILTERS_TASK_EDIT = "tasks/FILTERS_TASK_EDIT",
  SEARCH_TEXT_TASK_EDIT = "tasks/SEARCH_TEXT_TASK_EDIT",
}

/**
 * Data types
 */
export interface TaskType {
  idTarefa: number | null;
  tituloTarefa: string;
  flagFinalizada?: boolean;
  dataExpiracao: Date | null;
  idFuncionarioOwner?: number;
  tipoTarefa: string;
  idFuncionario?: number;
  nomeCompleto: string;
  funcionario: {
    nomeCompleto: string;
  };
  flagEdicao: boolean;
  habilitaFinalizarTarefa?: boolean;
  dataHoraFinalizada?: Date | null;
  statusTarefa: string;
  TarefaMeta?: TaskMetaType;
  TarefaAvaliacao?: TaskAvaliationType;
  TarefaTime?: TaskTeamType;
  TarefaFeedback?: TaskFeedback;
  TarefaPDI?: TaskPDI;
  TarefaMeioAno?: TaskHalfYear;
  TarefaDevMerito?: TaskDevolutivaMerito[];
  TarefaAvaliacaoColetiva?: TaskAvaliacaoColetiva;
  TarefaFeedbackColetivo?: TaskFeedbackColetivo;
  TarefaFeedbackFunc?: TaskFeedbackFuncionario;
  TarefaMerito?: TaskMerit[];
  Feedback?: TaskFeedbackType;
  feedbackGestor?: {
    comentarios: string;
  };
}

export type TaskMerit = {
  idMerito: number;
  idTarefaMerito: number;
}

export type TaskFeedbackColetivo = {
  funcionarios: TaskFeedbackColetivoFuncionario[];
};

export type TaskFeedbackFuncionario = {
  idTarefaFeedbackFunc: number;
  idTarefa: number;
  idFeedback: number;
};

export type TaskFeedbackType = {
  idFeedback: number;
  idFuncionario: number;
  idFuncionarioOwner: number;
  descricao: string;
  status: string;
  comentariosDeAcordo: string | null;
  dataHoraRecebimento: Date | null;
  dataOcorrencia: Date;
  deAcordo: "Não recebi feedback" | "Recebi e CONCORDO" | "Recebi e NÃO CONCORDO" | null;
}

export type TaskFeedbackColetivoFuncionario = {
  idTarefaFeedback: number;
  idCicloAvaliacao: number;
  avaliacao: {
    idFuncionario: number;
    statusAvaliacao: string;
    funcionario: {
      nomeCompleto: string;
      status: string;
      imagem: string | null;
    };
  };
  cargo: {
    idCargo: number;
    codCargo: string;
    titulo: string;
    dataHist: string;
  };
  area: {
    idArea: number;
    codArea: string;
    nomeLocal: string;
  };
};

export type TaskAvaliacaoColetiva = {
  resumo: {
    performances: TaskAvaliacaoColetivaPerformanceType[];
    avaliados: number;
  };
  funcionarios: TaskAvaliacaoColetivaFuncionario[];
  atitudes: {
    idCicloAtitude: number;
    idAtitude: number;
    idCiclo: number;
    ordem: number;
    flagLideranca: boolean | null;
    atitude: {
      nomeAtitude: string;
    };
  }[];
};

export interface TaskAvaliacaoColetivaPerformanceType {
  idTabPerformance: number;
  nivel: number;
  descNivel: string;
  cor: string;
  qtde: number;
  perc: number;
  flagBloquearAvaliacao: boolean;
}

export type TaskAvaliacaoColetivaFuncionario = {
  idTarefaAvaliacao: number;
  idCicloAvaliacao: number;
  avaliacao: {
    idFuncionario: number;
    resultadometas: string | null;
    resultadoatitudes: string | null;
    idTabPerformanceGestor: number | null;
    idTabPerformance: number | null;
    potencial: string | null;
    sucessao: string | null;
    funcionario: {
      nomeCompleto: string;
      status: string;
      imagem: null | string;
      dataAdmissao: string;
    };
    performancegestor: {
      descNivel: string;
    } | null;
  };
  cargo?: {
    idCargo: number;
    codCargo: string;
    titulo: string;
    dataHist: string;
  };
  tempoEmpresa: number;
  area?: {
    idArea: number;
    codArea: string;
    nomeLocal: string;
  };
};

export type TaskAvaliacaoColetivaSelected = {
  idCicloAvaliacao: number;
  idTabPerformanceGestor: null | number;
  idTabPerformance: null | number;
  idTabPerformanceOriginal: null | number;
  statusAvaliacao: string;
  resultadometas: null | string;
  resultadoatitudes: null | string;
  comentariosMetas: null | string;
  comentariosAtitudes: null | string;
  comentariosGerais: null | string;
  potencial: null | string;
  sucessao: null | string;
  funcionario: {
    status: string;
    nomeCompleto: string;
  };
};

export type TaskAvaliacaoColetivaEditResponse = {
  dados: Partial<TaskAvaliacaoColetivaSelected>;
  resumo: {
    performances: TaskAvaliacaoColetivaPerformanceType[];
    avaliados: number;
  };
  avaliacao: TabPerfomanceType;
};

export type TaskDevolutivaMerito = {
  idTarefaDevMerito: string | number;
  idMeritoFuncionario: number;
  flagBaixouCarta: boolean;
  flagRealizouDevolutiva: boolean;
  meritoFun: {
    idFuncionario: number;
    idCargoOrigem: number;
    idAnexoCarta: string | null;
    statusCarta: string | null;
    funcionario: {
      nomeCompleto: string;
      imagem: string | null;
    };
    cargoOrigem: {
      titulo: string;
    };
  };
};

export type TaskDevolutivaMeritoFuncType = {
  nomeCompleto: string;
  imagem: null | string;
  matricula: string;
  statusFuncionario: string;
  cargo: {
    titulo: string;
  };
  flagBaixouCarta: boolean;
  flagRealizouDevolutiva: boolean;
};

type TaskHalfYear = {
  idTarefaMeioAno: number;
  idCiclo: number;
  idFuncionarioAvaliado: number;
  comentarios: string | null;
  funcionario: {
    nomeCompleto: string;
  };
  ciclo: {
    ano: number;
  };
  areas: AreasHalfYear[];
};

export type AreasHalfYear = {
  idCicloColaborador: number;
  idFuncionario: number;
  vigencia: Date;
  idArea: number;
  idCargo: number;
  idVaga: number;
  vigenciaFinal: Date;
  area: {
    nomeLocal: string;
  };
  cargo: {
    titulo: string;
  };
  ciclo: {
    ano: number;
  };
  unidade: {
    nomeUnidade: string;
  };
  cc: {
    nomeCC: string;
  };
  metas: MetaAreaHalfYear[];
  meses: number;
  descPeriodo: string;
  projetos: MetaProjetos[];
};

type MetaAreaHalfYear = {
  contMeta: number;
  idCicloPeso: number;
  flagIniciativas: boolean;
  cicloPeso: {
    idCicloPeso: number;
    idObjetivo: number;
    peso: string | number;
    flagInativo: boolean | null;
    objetivo: {
      descObjetivo: string;
      unidade: string;
    };
    cicloPesoPai: {
      idCicloPeso: number;
      objetivo: {
        descObjetivo: string;
      };
    };
  };
  pilar: {
    nomePilar: string;
    corPilar: string;
  };
  meta: MetaResultadoHalfYear[];
};

interface MetaProjetos {
  idCicloColaboradorProjeto: number;
  peso: number | string;
  nomeProjeto: string;
  descProjeto: string;
  prazoEntrega: Date;
}

interface MetaResultadoHalfYear extends ResultadoMetaType {
  ating: number;
  referencia: Date;
}

type TaskPDI = {
  idTarefaPDI: number;
  idTarefa: number;
  idCargo: number;
  comentarios: string | null;
  ano: string;
  cargo?: {
    titulo: string;
  };
  acoes: AcaoTarefaPDI[];
  Regras: RegrasPDIType[];
};

export type AcaoTarefaPDI = {
  idTarefaPDIAcao: number | null;
  idAcaoPDI: number | null;
  obs: string;
  complemento?: string;
  idFuncionarioRelacionado?: number | null;
  acaoPDI: {
    nomeAcaoPDI: string;
    prazo: number | null;
    flagRelacionarFuncionario: boolean;
    tipoPDI: {
      cor: string;
      idTipoPDI: number | null;
    };
  };
  funcionario: {
    idFuncionario: number | null;
    nomeCompleto: string;
  };
};

export type RegrasPDIType = {
  idTipoPDI: number;
  percent: number | string;
  tipoPDI: {
    descTipoPDI: string;
    cor: string;
    complemento: string;
  };
  percentAtual: number;
};

export interface AvaliationFeedback extends AvaliationType {
  feedback: string | null;
  feedbackGestor: string | null;
  habilitaFeedback: boolean;
  habilitaFeedbackGestor: boolean;
  calibracao: {
    idCicloReuniaoAvaliado: number;
    idTabPerformance: number;
    potencial: string;
    sucessao: string | null;
    carreira: string;
    performance: {
      descNivel: string;
      cor: string;
    };
    reuniao: {
      idCiclo: number;
      ciclo: {
        ano: number;
      };
    };
    comentarios?: AvaliadoCommentType[];
  };
}

export interface TaskFeedback {
  idTarefaFeedback: number;
  idTarefa: number;
  idCicloAvaliacao: number;
  avaliacao: AvaliationFeedback;
}

export interface TaskTeamType {
  idTarefaTime: number;
  idTarefa: number;
  idCicloTime: number;
  ciclotime: {
    idCiclo: number;
  };
  time: TeamEvaluationType;
}

export type AvaliationGestor = {
  idTabPerformance: number | null;
  potencial: string;
  sucessao: string;
  comentariosGestor: string;
};

export type AreasAvaliationType = {
  idCicloAvaliacaoArea: number;
  idCicloColaborador: number;
  somaMetas: number;
  resultadometas: string | null;
  ciclocolaborador: {
    idArea: number;
    vigencia: Date;
    idVaga: number;
    funcionario: {
      nomeCompleto: string;
    };
    area: {
      nomeLocal: string;
    };
    cargo: {
      titulo: string;
    };
    ciclo: {
      ano: number;
    };
    unidade: {
      nomeUnidade: string;
    };
    cc: {
      nomeCC: string;
    };
  };
  iniciativas: IniciativasType[];
  resultados: ResultadosType[];
  descPeriodo: string;
  meses: number;
  comentariosIniciativas: string;
};

export interface AvaliationType {
  idCicloAvaliacao: number | null;
  idCiclo: number;
  idFuncionario: number;
  idFuncionarioAvaliador: number;
  somaMetas: number;
  resultadometas: string | null;
  resultadoatitudes: string | null;
  flagRealizado: boolean;
  dataRealizacao: null;
  aspiracoesCarreira: string | null;
  idTabPerformance: number | null;
  idTabPerformanceGestor: number | null;
  potencial: string | null;
  sucessao: string | null;
  comentariosGestor: string | null;
  comentariosGerais: string | null;
  comentariosMeioAno: string | null;
  comentariosMeioAnoGestor: string | null;
  statusAvaliacao: string;
  tipoAvaliacao: string;
  ciclo: {
    ano: number;
  };
  funcionario: {
    nomeCompleto: string;
    status: string;
  };
  atitudes: AtitudesType[];
  areas: AreasAvaliationType[] | null;
  performance: {
    descNivel: string;
    cor: string;
  };
  flagAutoAvaliacao: boolean;
  isGestor: boolean;
  habilitaAvaliacaoGestor: boolean;
  habilitaVisualizacaoGestor: boolean;
  tabPerformance: TabPerfomanceType[];
}

export interface TabPerfomanceType {
  idTabPerformance: number;
  nivel: number;
  descNivel: string;
  cor: string;
  flagBloquearAvaliacao?: boolean;
}

export interface TaskAvaliationType {
  idCicloAvaliacao: number | null;
  createdAt: Date;
  updatedAt: Date;
  avaliacao: AvaliationType;
}

export interface IniciativasType {
  idCicloAvaliacaoAreaIniciativa: number;
  idCicloColaboradorProjeto: number;
  percent: number | null;
  resultado: string | null;
  colaboradoriniciativa: {
    descProjeto: string;
    nomeProjeto: string;
    peso: number | string;
    prazoEntrega: Date;
  };
}

export interface ResultadosType {
  idCicloAvaliacaoAreaResultado: number;
  idCicloColaboradorMeta: number;
  real: number;
  percent: number;
  resultado: string;
  ating: string | number | null;
  peso: number;
  colaboradormeta: {
    idCicloPeso: number;
    countMeta: number;
    flagIniciativas: boolean | null;
    cicloPeso: {
      idObjetivo: number;
      peso: number;
      objetivo: {
        descObjetivo: string;
        mecanicaCalculo: string;
        unidade: string;
        descricaoMecanica: string;
        casasDecimais: number | null;
      };
    };
  };
  meta: ResultadoMetaType;
  pilar: {
    nomePilar: string;
    corPilar: string;
  };
  cicloPesoPai: {
    idCicloPeso: number;
    idObjetivo: number;
    objetivo: {
      descObjetivo: string;
    } | null;
  };
}

export interface ResultadoMetaType {
  idCicloMeta: number;
  idObjetivo: number;
  minimo: number | string;
  target: number | string;
  maximo: number | string;
  avaliacao: string | null;
  resultado: string | null;
  flagInativo: boolean | null;
}

export interface AtitudesType {
  idCicloAvaliacaoAtitude: number;
  idAtitude: number;
  resultado: string | null;
  comentarios: string | null;
  atitude: {
    nomeAtitude: string;
    descAtitude: string;
    descColetivo: string;
  };
}

export interface TaskMetaType {
  idTarefaMeta: number;
  idTarefa: number;
  idFuncionario: number;
  idVaga: number;
  idFuncionarioLider: number;
  idCicloColaborador: number;
  idCiclo: number;
}

type GroupFilter = {
  a: boolean;
  f: boolean;
  c: boolean;
  e: boolean;
};

export type TaskFilterType = {
  isOpen?: boolean;
  filteredByStatus?: string[];
  filteredByType?: string[];
  group: GroupFilter;
  responsavel: Partial<EmployeeType> | null | undefined;
};

interface ITasksState {
  readonly data: TaskType[];
  readonly filter: TaskFilterType;
  readonly searchText: string;
  readonly selected: TaskType | null;
  readonly selectedColetiva: TaskAvaliacaoColetivaSelected | null;
  readonly selectedColetivaFeedback: AvaliationFeedback | null;
  readonly loadingData: boolean;
  readonly loadingAction: boolean;
  readonly loadingSave: boolean;
  readonly error: boolean;
  readonly errorAction: boolean;
  readonly success: boolean;
  readonly successAction: boolean;
  readonly redirectTo: string | null;
  readonly nextTask: {
    idTarefa: number;
    ano: number;
  } | null;
}

export type DataTypes = ITasksState;

/**
 * Actions
 */

//GET
export interface IRequestTasksLoadAction {
  type: typeof Types.REQUEST_TASKS_LOAD;
  payload: {
    searchText: string;
    filterStatus?: string[];
    filterType?: string[];
    responsavelId?: number | null;
  };
}

export interface ISuccessTasksLoadAction {
  type: typeof Types.SUCCESS_TASKS_LOAD;
  payload: TaskType[];
}

export interface IFailureTasksLoadAction {
  type: typeof Types.FAILURE_TASKS_LOAD;
  message: string;
}

type TaskLoadAction =
  | IRequestTasksLoadAction
  | ISuccessTasksLoadAction
  | IFailureTasksLoadAction;

//GET BY ID
export interface IRequestTasksLoadByIdAction {
  type: typeof Types.REQUEST_TASKS_LOAD_ID;
  idTarefa: number;
}

export interface ISuccessTasksLoadByIdAction {
  type: typeof Types.SUCCESS_TASKS_LOAD_ID;
  payload: TaskType;
}

export interface IFailureTasksLoadByIdAction {
  type: typeof Types.FAILURE_TASKS_LOAD_ID;
  message: string;
}

type TaskLoadByIdAction =
  | IRequestTasksLoadByIdAction
  | ISuccessTasksLoadByIdAction
  | IFailureTasksLoadByIdAction;

//FINISH TASK
export interface IRequestTasksFinishAction {
  type: typeof Types.REQUEST_TASKS_FINISH;
  payload: {
    idTarefa: number;
    data?: object;
  };
}

export interface ISuccessTasksFinishAction {
  type: typeof Types.SUCCESS_TASKS_FINISH;
  data?: {
    idTarefa: number;
    ano: number;
  };
}

export interface IFailureTasksFinishAction {
  type: typeof Types.FAILURE_TASKS_FINISH;
  message: string;
}

type TaskFinishAction =
  | IRequestTasksFinishAction
  | ISuccessTasksFinishAction
  | IFailureTasksFinishAction;

export interface ISaveTasksIniciativaAutoAction {
  type: typeof Types.SAVE_TASK_INICIATIVAS_AUTOAVALICACAO;
  payload: {
    idCicloAvaliacaoArea: number;
    iniciativas: Partial<IniciativasType>[];
    comentarios: string;
    idCicloAvaliacaoAreaResultado: number;
  };
}

export interface ISaveTasksAtitudeAvaliacaoAction {
  type: typeof Types.SAVE_TASK_ATITUDE_AVALIACAO;
  payload: {
    idCicloAvaliacaoAtitude: number;
    resultado: string;
  };
}

export interface ISaveTasksAtitudeComentarioAction {
  type: typeof Types.SAVE_TASK_ATITUDE_COMENTARIO;
  payload: {
    idCicloAvaliacaoAtitude: number;
    comentarios: string;
  };
}

export interface ISaveTasksAtitudeAspiracoesCarreiraAction {
  type: typeof Types.SAVE_TASK_ASPIRACOES_CARREIRA;
  aspiracao: string;
}

export interface ISaveTasksAtitudeComentariosGeraisAction {
  type: typeof Types.SAVE_TASK_COMENTARIOS_GERAIS;
  comentariosGerais: string;
}

export interface IResetTaskAction {
  type: typeof Types.RESET_TASK;
}

// ADD PDI
export interface IRequestTaskAcaoPDIAddAction {
  type: typeof Types.REQUEST_TASKS_ACAO_PDI_ADD;
  payload: {
    idTarefa: number;
    acao: AcaoTarefaPDI;
  };
}

export interface ISuccessTaskAcaoPDIAddAction {
  type: typeof Types.SUCCESS_TASKS_ACAO_PDI_ADD;
  payload: AcaoTarefaPDI;
}

export interface IFailureTaskAcaoPDIAddAction {
  type: typeof Types.FAILURE_TASKS_ACAO_PDI_ADD;
  message: string;
}

type TaskAddPDIAction =
  | IRequestTaskAcaoPDIAddAction
  | ISuccessTaskAcaoPDIAddAction
  | IFailureTaskAcaoPDIAddAction;

// EDIT PDI
export interface IRequestTaskAcaoPDIEditAction {
  type: typeof Types.REQUEST_TASKS_ACAO_PDI_EDIT;
  payload: {
    idTarefa: number;
    acaoPDI: AcaoTarefaPDI;
  };
}

export interface ISuccessTaskAcaoPDIEditAction {
  type: typeof Types.SUCCESS_TASKS_ACAO_PDI_EDIT;
  payload: AcaoTarefaPDI;
}

export interface IFailureTaskAcaoPDIEditAction {
  type: typeof Types.FAILURE_TASKS_ACAO_PDI_EDIT;
  message: string;
}

type TaskEditPDIAction =
  | IRequestTaskAcaoPDIEditAction
  | ISuccessTaskAcaoPDIEditAction
  | IFailureTaskAcaoPDIEditAction;

// DELETE PDI
export interface IRequestTaskAcaoPDIDelAction {
  type: typeof Types.REQUEST_TASKS_ACAO_PDI_DEL;
  payload: {
    idTarefa: number;
    idTarefaPDIAcao: number;
  };
}

export interface ISuccessTaskAcaoPDIDelAction {
  type: typeof Types.SUCCESS_TASKS_ACAO_PDI_DEL;
  idTarefaPDIAcao: number;
}

export interface IFailureTaskAcaoPDIDelAction {
  type: typeof Types.FAILURE_TASKS_ACAO_PDI_DEL;
  message: string;
}

type TaskDelPDIAction =
  | IRequestTaskAcaoPDIDelAction
  | ISuccessTaskAcaoPDIDelAction
  | IFailureTaskAcaoPDIDelAction;

type SaveTaskIniciativaAutoAction =
  | ISaveTasksAtitudeAspiracoesCarreiraAction
  | ISaveTasksIniciativaAutoAction
  | ISaveTasksAtitudeAvaliacaoAction
  | ISaveTasksAtitudeComentarioAction
  | ISaveTasksAtitudeComentariosGeraisAction;

// SAVE PDI TAREFA
export interface IRequestTaskAcaoPDISaveAction {
  type: typeof Types.REQUEST_TASKS_ACAO_PDI_SAVE;
  payload: {
    idTarefa: number;
    comentarios: string;
  };
}

export interface ISuccessTaskAcaoPDISaveAction {
  type: typeof Types.SUCCESS_TASKS_ACAO_PDI_SAVE;
}

export interface IFailureTaskAcaoPDISaveAction {
  type: typeof Types.FAILURE_TASKS_ACAO_PDI_SAVE;
  message: string;
}

type TaskSavePDIAction =
  | IRequestTaskAcaoPDISaveAction
  | ISuccessTaskAcaoPDISaveAction
  | IFailureTaskAcaoPDISaveAction;

export interface IClearFiltersTaskAction {
  type: typeof Types.FILTERS_TASK_CLEAR;
}

export interface IFiltersTaskEditAction {
  type: typeof Types.FILTERS_TASK_EDIT;
  payload: TaskFilterType;
}

export interface IFiltersTaskSearchTextAction {
  type: typeof Types.SEARCH_TEXT_TASK_EDIT;
  payload: string;
}

type TaskFilterAction =
  | IClearFiltersTaskAction
  | IFiltersTaskEditAction
  | IFiltersTaskSearchTextAction;

//REQUEST_TASKS_DEVOLUTIVA_MERITO_SOLIC_CARTAS

export interface IRequestTaskDevolutivaMerSolicCartasAction {
  type: typeof Types.REQUEST_TASKS_DEVOLUTIVA_MERITO_SOLIC_CARTAS;
  payload: {
    idTarefa: number;
    cartas: number[];
  };
}

export interface ISuccessTaskDevolutivaMerSolicCartasAction {
  type: typeof Types.SUCCESS_TASKS_DEVOLUTIVA_MERITO_SOLIC_CARTAS;
  payload: Partial<TaskDevolutivaMerito>[];
}

export interface IFailureTaskDevolutivaMerSolicCartasAction {
  type: typeof Types.FAILURE_TASKS_DEVOLUTIVA_MERITO_SOLIC_CARTAS;
  message: string;
}

type TaskDevolutivaMerSolicCartasAction =
  | IRequestTaskDevolutivaMerSolicCartasAction
  | ISuccessTaskDevolutivaMerSolicCartasAction
  | IFailureTaskDevolutivaMerSolicCartasAction;

//REQUEST_TASKS_DEVOLUTIVA_MERITO_STATUS_CARTAS

export interface IRequestTaskDevolutivaMerStatusCartasAction {
  type: typeof Types.REQUEST_TASKS_DEVOLUTIVA_MERITO_STATUS_CARTAS;
  payload: {
    idTarefa: number;
    idTarefaDevMerito: number | string;
  };
}

export interface ISuccessTaskDevolutivaMerStatusCartasAction {
  type: typeof Types.SUCCESS_TASKS_DEVOLUTIVA_MERITO_STATUS_CARTAS;
  payload: {
    idTarefaDevMerito: number | string;
    statusCarta: string | null;
  };
}

export interface IFailureTaskDevolutivaMerStatusCartasAction {
  type: typeof Types.FAILURE_TASKS_DEVOLUTIVA_MERITO_STATUS_CARTAS;
  message: string;
}

type TaskDevolutivaMerStatusCartasAction =
  | IRequestTaskDevolutivaMerStatusCartasAction
  | ISuccessTaskDevolutivaMerStatusCartasAction
  | IFailureTaskDevolutivaMerStatusCartasAction;

//REQUEST_TASKS_DEVOLUTIVA_MERITO_BAIXAR_CARTAS
export interface IRequestTaskDevolutivaMerBaixarCartasAction {
  type: typeof Types.REQUEST_TASKS_DEVOLUTIVA_MERITO_DOWNLOAD_CARTAS;
  payload: {
    idTarefa: number;
    idTarefaDevMerito: number | string;
  };
}

export interface ISuccessTaskDevolutivaMerBaixarCartasAction {
  type: typeof Types.SUCCESS_TASKS_DEVOLUTIVA_MERITO_DOWNLOAD_CARTAS;
  idTarefaDevMerito: number | string;
}

export interface IFailureTaskDevolutivaMerBaixarCartasAction {
  type: typeof Types.FAILURE_TASKS_DEVOLUTIVA_MERITO_DOWNLOAD_CARTAS;
  message: string;
}

type TaskDevolutivaMerBaixarCartasAction =
  | IRequestTaskDevolutivaMerBaixarCartasAction
  | ISuccessTaskDevolutivaMerBaixarCartasAction
  | IFailureTaskDevolutivaMerBaixarCartasAction;

//REQUEST_TASKS_DEVOLUTIVA_REALIZAR
export interface IRequestTaskDevolutivaRealizarAction {
  type: typeof Types.REQUEST_TASKS_DEVOLUTIVA_REALIZAR;
  payload: {
    idTarefa: number;
    idTarefaDevMerito: number | string;
  };
}

export interface ISuccessTaskDevolutivaRealizarAction {
  type: typeof Types.SUCCESS_TASKS_DEVOLUTIVA_REALIZAR;
  payload: {
    idTarefaDevMerito: number | string;
    dados: {
      flagRealizouDevolutiva: boolean;
      dataDevolutiva: Date;
    };
    habilitaFinalizarTarefa: boolean;
  };
}

export interface IFailureTaskDevolutivaRealizarAction {
  type: typeof Types.FAILURE_TASKS_DEVOLUTIVA_REALIZAR;
  message: string;
}

type TaskDevolutivaRealizarAction =
  | IRequestTaskDevolutivaRealizarAction
  | ISuccessTaskDevolutivaRealizarAction
  | IFailureTaskDevolutivaRealizarAction;

//REQUEST_TASKS_DEVOLUTIVA_CANCELAR
export interface IRequestTaskDevolutivaCancelarAction {
  type: typeof Types.REQUEST_TASKS_DEVOLUTIVA_CANCELAR;
  payload: {
    idTarefa: number;
    idTarefaDevMerito: number | string;
  };
}

export interface ISuccessTaskDevolutivaCancelarAction {
  type: typeof Types.SUCCESS_TASKS_DEVOLUTIVA_CANCELAR;
  payload: {
    idTarefaDevMerito: number | string;
    dados: {
      flagRealizouDevolutiva: boolean;
      dataDevolutiva: Date;
    };
    habilitaFinalizarTarefa: boolean;
  };
}

export interface IFailureTaskDevolutivaCancelarAction {
  type: typeof Types.FAILURE_TASKS_DEVOLUTIVA_CANCELAR;
  message: string;
}

type TaskDevolutivaCancelarAction =
  | IRequestTaskDevolutivaCancelarAction
  | ISuccessTaskDevolutivaCancelarAction
  | IFailureTaskDevolutivaCancelarAction;

//REQUEST_TASKS_COLETIVA_INFO_AVALIACAO
export interface IRequestTaskCollectiveInfoAvaliacaoAction {
  type: typeof Types.REQUEST_TASKS_COLETIVA_INFO_AVALIACAO;
  payload: {
    idTarefa: number;
    idTarefaAvaliacao: number;
  };
}

export interface ISuccessTaskCollectiveInfoAvaliacaoAction {
  type: typeof Types.SUCCESS_TASKS_COLETIVA_INFO_AVALIACAO;
  payload: TaskAvaliacaoColetivaSelected;
}

export interface IFailureTaskCollectiveInfoAvaliacaoAction {
  type: typeof Types.FAILURE_TASKS_COLETIVA_INFO_AVALIACAO;
  message: string;
}

type TaskCollectiveInfoAvaliacaoAction =
  | IRequestTaskCollectiveInfoAvaliacaoAction
  | ISuccessTaskCollectiveInfoAvaliacaoAction
  | IFailureTaskCollectiveInfoAvaliacaoAction;

//REQUEST_TASKS_COLETIVA_RESULTADO_META_EDIT
export interface IRequestTaskCollectiverEditResultadoMetaAction {
  type: typeof Types.REQUEST_TASKS_COLETIVA_RESULTADO_META_EDIT;
  payload: {
    idTarefa: number;
    idTarefaAvaliacao: number;
    resultadometas: string;
    comentariosMetas: string;
  };
}

export interface ISuccessTaskCollectiverEditResultadoMetaAction {
  type: typeof Types.SUCCESS_TASKS_COLETIVA_RESULTADO_META_EDIT;
  payload: {
    idTarefaAvaliacao: number;
    avaliacao: TaskAvaliacaoColetivaEditResponse;
  };
}

export interface IFailureTaskCollectiverEditResultadoMetaAction {
  type: typeof Types.FAILURE_TASKS_COLETIVA_RESULTADO_META_EDIT;
  message: string;
}

type TaskCollectiverEditResultadoMetaAction =
  | IRequestTaskCollectiverEditResultadoMetaAction
  | ISuccessTaskCollectiverEditResultadoMetaAction
  | IFailureTaskCollectiverEditResultadoMetaAction;

//REQUEST_TASKS_COLETIVA_RESULTADO_ATITUDE_EDIT
export interface IRequestTaskCollectiverEditResultadoAtitudeAction {
  type: typeof Types.REQUEST_TASKS_COLETIVA_RESULTADO_ATITUDE_EDIT;
  payload: {
    idTarefa: number;
    idTarefaAvaliacao: number;
    resultadoatitudes: string;
    comentariosAtitudes: string;
  };
}

export interface ISuccessTaskCollectiverEditResultadoAtitudeAction {
  type: typeof Types.SUCCESS_TASKS_COLETIVA_RESULTADO_ATITUDE_EDIT;
  payload: {
    idTarefaAvaliacao: number;
    avaliacao: TaskAvaliacaoColetivaEditResponse;
  };
}

export interface IFailureTaskCollectiverEditResultadoAtitudeAction {
  type: typeof Types.FAILURE_TASKS_COLETIVA_RESULTADO_ATITUDE_EDIT;
  message: string;
}

type TaskCollectiverEditResultadoAtitudeAction =
  | IRequestTaskCollectiverEditResultadoAtitudeAction
  | ISuccessTaskCollectiverEditResultadoAtitudeAction
  | IFailureTaskCollectiverEditResultadoAtitudeAction;

//REQUEST_TASKS_COLETIVA_RESULTADO_FINAL_EDIT
export interface IRequestTaskCollectiverEditResultadoFinalAction {
  type: typeof Types.REQUEST_TASKS_COLETIVA_RESULTADO_FINAL_EDIT;
  payload: {
    idTarefa: number;
    idTarefaAvaliacao: number;
    idTabPerformance: number | null;
    potencial: string;
    sucessao: string | null;
    comentariosGerais: string;
  };
}

export interface ISuccessTaskCollectiverEditResultadoFinalAction {
  type: typeof Types.SUCCESS_TASKS_COLETIVA_RESULTADO_FINAL_EDIT;
  payload: {
    idTarefaAvaliacao: number;
    avaliacao: TaskAvaliacaoColetivaEditResponse;
  };
}

export interface IFailureTaskCollectiverEditResultadoFinalAction {
  type: typeof Types.FAILURE_TASKS_COLETIVA_RESULTADO_FINAL_EDIT;
  message: string;
}

type TaskCollectiverEditResultadoFinalAction =
  | IRequestTaskCollectiverEditResultadoFinalAction
  | ISuccessTaskCollectiverEditResultadoFinalAction
  | IFailureTaskCollectiverEditResultadoFinalAction;

//REQUEST_TASKS_COLETIVA_RESULTADO_FINAL_EDIT
export interface IRequestTaskCollectiverFinishAction {
  type: typeof Types.REQUEST_TASKS_COLETIVA_FINISH;
  idTarefa: number;
}

export interface ISuccessTaskCollectiverFinishAction {
  type: typeof Types.SUCCESS_TASKS_COLETIVA_FINISH;
}

export interface IFailureTaskCollectiverFinishAction {
  type: typeof Types.FAILURE_TASKS_COLETIVA_FINISH;
  message: string;
}

type TaskCollectiverFinishAction =
  | IRequestTaskCollectiverFinishAction
  | ISuccessTaskCollectiverFinishAction
  | IFailureTaskCollectiverFinishAction;

//REQUEST_TASKS_COLETIVA_FEEDBACK
export interface IRequestTaskCollectiveFeedbackAction {
  type: typeof Types.REQUEST_TASKS_COLETIVA_FEEDBACK;
  payload: {
    idTarefa: number;
    idTarefaFeedback: number;
  };
}

export interface ISuccessTaskCollectiveFeedbackAction {
  type: typeof Types.SUCCESS_TASKS_COLETIVA_FEEDBACK;
  payload: AvaliationFeedback;
}

export interface IFailureTaskCollectiveFeedbackAction {
  type: typeof Types.FAILURE_TASKS_COLETIVA_FEEDBACK;
  message: string;
}

type TaskCollectiveFeedbackAction =
  | IRequestTaskCollectiveFeedbackAction
  | ISuccessTaskCollectiveFeedbackAction
  | IFailureTaskCollectiveFeedbackAction;

//REQUEST_TASKS_COLETIVA_FEEDBACK_FINISH
export interface IRequestTaskCollectiveFeedbackFinishAction {
  type: typeof Types.REQUEST_TASKS_COLETIVA_FEEDBACK_FINISH;
  payload: {
    idTarefa: number;
    idTarefaFeedback: number;
    feedbackGestor: string;
  };
}

export interface ISuccessTaskCollectiveFeedbackFinishAction {
  type: typeof Types.SUCCESS_TASKS_COLETIVA_FEEDBACK_FINISH;
  idTarefa: string;
}

export interface IFailureTaskCollectiveFeedbackFinishAction {
  type: typeof Types.FAILURE_TASKS_COLETIVA_FEEDBACK_FINISH;
  message: string;
}

type TaskCollectiveFeedbackFinishAction =
  | IRequestTaskCollectiveFeedbackFinishAction
  | ISuccessTaskCollectiveFeedbackFinishAction
  | IFailureTaskCollectiveFeedbackFinishAction;

//REQUEST_TASKS_FUNCIONARIO_FEEDBACK_FINISH
export interface IRequestTaskFuncionarioFeedbackFinishAction {
  type: typeof Types.REQUEST_TASKS_FUNCIONARIO_FEEDBACK_FINISH;
  payload: {
    idTarefa: number;
    deAcordo: string;
    comentariosDeAcordo: string;
  };
}

export interface ISuccessTaskFuncionarioFeedbackFinishAction {
  type: typeof Types.SUCCESS_TASKS_FUNCIONARIO_FEEDBACK_FINISH;
}

export interface IFailureTaskFuncionarioFeedbackFinishAction {
  type: typeof Types.FAILURE_TASKS_FUNCIONARIO_FEEDBACK_FINISH;
  message: string;
}

type TaskFuncionarioFeedbackFinishAction =
  | IRequestTaskFuncionarioFeedbackFinishAction
  | ISuccessTaskFuncionarioFeedbackFinishAction
  | IFailureTaskFuncionarioFeedbackFinishAction;

export type TaskActionTypes =
  | TaskLoadAction
  | TaskLoadByIdAction
  | SaveTaskIniciativaAutoAction
  | TaskFinishAction
  | IResetTaskAction
  | TaskAddPDIAction
  | TaskEditPDIAction
  | TaskDelPDIAction
  | TaskSavePDIAction
  | TaskFilterAction
  | TaskDevolutivaMerSolicCartasAction
  | TaskDevolutivaMerStatusCartasAction
  | TaskDevolutivaMerBaixarCartasAction
  | TaskDevolutivaRealizarAction
  | TaskDevolutivaCancelarAction
  | TaskCollectiveInfoAvaliacaoAction
  | TaskCollectiverEditResultadoMetaAction
  | TaskCollectiverEditResultadoAtitudeAction
  | TaskCollectiverEditResultadoFinalAction
  | TaskCollectiverFinishAction
  | TaskCollectiveFeedbackAction
  | TaskCollectiveFeedbackFinishAction
  | TaskFuncionarioFeedbackFinishAction;
