import { takeEvery, put, call, all } from "redux-saga/effects";
import {
  Types,
  VinculosLoadDTO,
  VinculoType,
  IRequestGetByIdVinculoAction,
  IRequestAddVinculosAction,
  IRequestEditVinculosAction,
  IRequestDelVinculosAction,
  ISuccessGetVinculosAction,
  ISuccessGetByIdVinculoAction,
  ISuccessAddVinculosAction,
  ISuccessEditVinculosAction,
  ISuccessDelVinculosAction,
} from "../../ducks/admin/vinculo/types";
import api from "~/services/api";

const apiCallGet = () => {
  return api
    .get("admin/vinculos")
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallGetById = (idVinculo: number) => {
  return api
    .get(`admin/vinculos/${idVinculo}`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallAdd = (item: IRequestAddVinculosAction["payload"]) => {
  return api
    .post("admin/vinculos", item)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallEdit = (item: IRequestEditVinculosAction["payload"]) => {
  return api
    .put(`admin/vinculos/${item.idVinculo}`, item)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallDel = (idVinculo: number) => {
  return api
    .delete(`admin/vinculos/${idVinculo}`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

function* load() {
  try {
    const items: VinculosLoadDTO[] = yield call(apiCallGet);
    yield put({
      type: Types.SUCCESS_LOAD_VINCULOS,
      payload: items,
    } as ISuccessGetVinculosAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível carregar os vínculos empregatícios";
    yield put({ type: Types.FAILURE_LOAD_VINCULOS, message });
  }
}

function* loadById(action: IRequestGetByIdVinculoAction) {
  try {
    const item: VinculoType = yield call(apiCallGetById, action.idVinculo);
    yield put({
      type: Types.SUCCESS_LOAD_VINCULO_BY_ID,
      payload: item,
    } as ISuccessGetByIdVinculoAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível carregar o vínculo empregatício";
    yield put({ type: Types.FAILURE_LOAD_VINCULO_BY_ID, message });
  }
}

function* add(action: IRequestAddVinculosAction) {
  try {
    const data: { idVinculo: number } = yield call(apiCallAdd, action.payload);
    yield put({
      type: Types.SUCCESS_ADD_VINCULO,
      payload: {
        ...action.payload,
        idVinculo: data.idVinculo,
      },
    } as ISuccessAddVinculosAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível adicionar o vínculo empregatício";
    yield put({ type: Types.FAILURE_ADD_VINCULO, message });
  }
}

function* edit(action: IRequestEditVinculosAction) {
  try {
    yield call(apiCallEdit, action.payload);
    yield put({
      type: Types.SUCCESS_EDIT_VINCULO,
      payload: action.payload,
    } as ISuccessEditVinculosAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível editar o vínculo empregatício";
    yield put({ type: Types.FAILURE_EDIT_VINCULO, message });
  }
}

function* del(action: IRequestDelVinculosAction) {
  try {
    yield call(apiCallDel, action.idVinculo);
    yield put({
      type: Types.SUCCESS_DEL_VINCULO,
      idVinculo: action.idVinculo,
    } as ISuccessDelVinculosAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível excluír o vínculo empregatício";
    yield put({ type: Types.FAILURE_DEL_VINCULO, message });
  }
}

export default function* vinculosRoot() {
  yield all([
    yield takeEvery(Types.REQUEST_LOAD_VINCULOS, load),
    yield takeEvery(Types.REQUEST_LOAD_VINCULO_BY_ID, loadById),
    yield takeEvery(Types.REQUEST_ADD_VINCULO, add),
    yield takeEvery(Types.REQUEST_EDIT_VINCULO, edit),
    yield takeEvery(Types.REQUEST_DEL_VINCULO, del),
  ]);
}
