import React, { useState } from "react";
import { useHistory } from "react-router";
import { FontIcon, Stack, Spinner } from "office-ui-fabric-react";
import { Text, ITextStyles } from "office-ui-fabric-react/lib/Text";
import {
  Dropdown as DropdownUI,
  IDropdownOption,
} from "office-ui-fabric-react/lib/Dropdown";
import { Icon } from "office-ui-fabric-react/lib/Icon";
import {
  ICommandBarStyles,
  MessageBar,
  MessageBarType,
} from "office-ui-fabric-react";

import MuiAccordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import { withStyles } from "@material-ui/core/styles";

import { CommandBarButton } from "~/components/Buttons";
import { InputText } from "~/components/Forms";
import PainelHeader from "~/components/layout/PainelHeader";

import {
  Container,
  Content,
  WrapperResults,
  WrapperForm,
  Table,
  Gap,
  Tbody,
  TdResultAtitudes,
} from "../Tasks/SelfEvaluation/styles";

import Colors from "~/assets/js/colors";
import { AvaliacaoGestorType } from "~/store/ducks/avaliation/types";
import NoItems from "~/components/layout/NoItems";
import { convertStatusTarefa } from "~/utils/tasks";

interface SelfEvaluationProps {
  onSave: (values?: any) => void;
  onSend?: () => void;
  avaliation: AvaliacaoGestorType;
  statusTarefa?: string;
  loading: boolean;
  error: boolean;
  flagFinalizada?: boolean;
  loadingAction?: boolean;
  loadingSave?: boolean;
  isDesligado: boolean;
  modalView?: boolean;
}

const SelfEvaluationResume: React.FC<SelfEvaluationProps> = (props) => {
  const [errorForm, setErrorForm] = useState(false);
  const avaliacao = props.avaliation;
  const { isDesligado, modalView, statusTarefa } = props;
  const history = useHistory();

  const onRenderResultadoMetas = (options: IDropdownOption[]): JSX.Element => {
    const option = options[0];

    return (
      <Stack
        horizontal
        verticalAlign="center"
        horizontalAlign="center"
        styles={{ root: { width: "100%" } }}
      >
        {option.data && option.data.icon && (
          <Icon
            iconName={option.data.icon}
            aria-hidden="true"
            title={option.data.icon}
            styles={{ root: { ...option.data.style, marginRight: 10 } }}
          />
        )}

        <Stack horizontalAlign="center">
          <span
            style={{
              fontSize: 12,
              whiteSpace: "break-spaces",
              lineHeight: 1.5,
              textAlign: "left",
              ...option.data.styleOption,
            }}
          >
            {option.text}
          </span>
        </Stack>
      </Stack>
    );
  };

  const onRenderTitltePerformance = (
    options: IDropdownOption[]
  ): JSX.Element => {
    const option = options[0];

    return (
      <Stack
        horizontal
        verticalAlign="center"
        horizontalAlign="center"
        styles={{ root: { width: "100%" } }}
      >
        {option.data && !option?.data?.styleOption && (
          <Icon
            iconName="CircleShapeSolid"
            aria-hidden="true"
            styles={{
              root: { ...option.data.style, marginRight: 10, fontSize: 16 },
            }}
          />
        )}
        <Stack horizontalAlign="center">
          <span
            style={{
              fontSize: 12,
              whiteSpace: "break-spaces",
              lineHeight: 1.5,
              textAlign: "left",
              ...option?.data?.styleOption,
            }}
          >
            {option.text}
          </span>
        </Stack>
      </Stack>
    );
  };

  const onRenderTitlePotencial = (options: IDropdownOption[]): JSX.Element => {
    const option = options[0];

    return (
      <Stack
        horizontal
        verticalAlign="center"
        horizontalAlign="center"
        styles={{ root: { width: "100%" } }}
      >
        <Stack horizontalAlign="center">
          <span
            style={{
              fontSize: 12,
              whiteSpace: "break-spaces",
              lineHeight: 1.5,
              textAlign: "left",
              ...option?.data?.styleOption,
            }}
          >
            {option.text}
          </span>
        </Stack>
      </Stack>
    );
  };

  const renderResultados = () => {
    return (
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={
            <FontIcon iconName="ChevronDown" style={{ fontSize: 16 }} />
          }
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Text variant="mediumPlus" styles={textStyles}>
            Avaliação de Performance
          </Text>
        </AccordionSummary>
        <AccordionDetails>
          <Stack>
            <Stack styles={{ root: { width: "100%", marginBottom: 20 } }}>
              <Table>
                <Tbody>
                  <tr>
                    <Gap style={{ minWidth: 370, maxWidth: 370 }} />
                    <td style={{ width: 160, minWidth: 160 }}>
                      <Text variant="small">PERFORMANCE</Text>
                    </td>
                    <TdResultAtitudes
                      style={{ width: 420, minWidth: 420, borderWidth: 0 }}
                    >
                      <DropdownUI
                        placeholder="Selecione uma opção"
                        options={options}
                        selectedKey={
                          avaliacao?.resultadometas ?? "Selecione uma opção"
                        }
                        onRenderTitle={onRenderResultadoMetas as any}
                        disabled={true}
                        styles={{
                          title: {
                            height: 60,
                            display: "flex",
                            alignItems: "center",
                            borderColor: "#CCCCCC",
                          },
                          caretDownWrapper: { top: 16 },
                        }}
                      />
                    </TdResultAtitudes>
                  </tr>
                </Tbody>
              </Table>
              <InputText
                label="Comentários gerais"
                multiline
                rows={3}
                value={avaliacao?.comentariosMetas ?? ""}
                aria-readonly="true"
              />
            </Stack>
          </Stack>
        </AccordionDetails>
      </Accordion>
    );
  };

  return (
    <Container modalView={modalView}>
      {!modalView && (
        <PainelHeader
          title={`Avaliação ${avaliacao?.ciclo.ano ?? ""}`}
          icon={{ icon: "Equalizer" }}
          labelGroups={
            avaliacao
              ? [
                {
                  label: "Funcionário",
                  value: avaliacao?.funcionario.nomeCompleto ?? "",
                  icon: "HomeGroup",
                  size: "full",
                },
                {
                  label: "Status",
                  value: avaliacao?.flagRealizado
                    ? "Finalizado"
                    : convertStatusTarefa(statusTarefa ?? ""),
                  icon: "Flag",
                  size: "full",
                },
              ]
              : []
          }
        />
      )}
      <Content modalView={modalView}>
        {props.loading ? (
          <Spinner
            label="Carregando avaliação"
            styles={{ root: { height: 500 } }}
          />
        ) : (
          <>
            {props.error ? (
              <NoItems text="Tarefa não encontrada" error={true} />
            ) : (
              <>
                {!modalView &&
                  <CommandBarButton
                    iconProps={{ iconName: "ChromeClose" }}
                    onClick={() => history.goBack()}
                    styles={commandButtonStyle}
                  >
                    Fechar
                  </CommandBarButton>
                }
                {errorForm && (
                  <MessageBar
                    messageBarType={MessageBarType.error}
                    isMultiline={false}
                    dismissButtonAriaLabel="Close"
                    onDismiss={() => setErrorForm(false)}
                  >
                    Verifique os campos obrigatórios!
                  </MessageBar>
                )}

                {!isDesligado && renderResultados()}

                <WrapperResults>
                  {!isDesligado && (
                    <>
                      <Accordion defaultExpanded={true}>
                        <AccordionSummary
                          expandIcon={
                            <FontIcon
                              iconName="ChevronDown"
                              style={{ fontSize: 16 }}
                            />
                          }
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <Text variant="mediumPlus" styles={textStyles}>
                            Atitudes Vencedoras
                          </Text>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Stack
                            styles={{
                              root: { width: "100%", marginBottom: 20 },
                            }}
                          >
                            <Table>
                              <Tbody>
                                <tr>
                                  <Gap
                                    style={{ minWidth: 370, maxWidth: 370 }}
                                  />
                                  <td style={{ width: 160, minWidth: 160 }}>
                                    <Text variant="small">AVALIAÇÃO</Text>
                                  </td>
                                  <TdResultAtitudes
                                    style={{
                                      width: 420,
                                      minWidth: 420,
                                      borderWidth: 0,
                                    }}
                                  >
                                    <DropdownUI
                                      placeholder="Selecione uma opção"
                                      options={options}
                                      selectedKey={
                                        avaliacao?.resultadoatitudes ??
                                        "Selecione uma opção"
                                      }
                                      onRenderTitle={
                                        onRenderResultadoMetas as any
                                      }
                                      disabled={true}
                                      styles={{
                                        title: {
                                          height: 60,
                                          display: "flex",
                                          alignItems: "center",
                                          borderColor: "#CCCCCC",
                                        },
                                        caretDownWrapper: { top: 16 },
                                      }}
                                    />
                                  </TdResultAtitudes>
                                </tr>
                              </Tbody>
                            </Table>
                            <InputText
                              label="Comentários gerais"
                              multiline
                              rows={3}
                              value={avaliacao?.comentariosAtitudes ?? ""}
                              aria-readonly="true"
                            />
                          </Stack>
                        </AccordionDetails>
                      </Accordion>
                    </>
                  )}
                  <Accordion defaultExpanded={true}>
                    <AccordionSummary
                      expandIcon={
                        <FontIcon
                          iconName="ChevronDown"
                          style={{ fontSize: 16 }}
                        />
                      }
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Text variant="mediumPlus" styles={textStyles}>
                        Avaliação do Gestor
                      </Text>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Stack styles={{ root: { width: "100%" } }}>
                        <Table>
                          <Tbody>
                            <tr>
                              <Gap style={{ minWidth: 370, maxWidth: 370 }} />
                              <td style={{ width: 160, minWidth: 160 }}>
                                <Text variant="small">PERFORMANCE</Text>
                              </td>
                              <TdResultAtitudes
                                style={{
                                  width: 420,
                                  minWidth: 420,
                                  borderWidth: 0,
                                }}
                              >
                                <DropdownUI
                                  placeholder="Selecione uma opção"
                                  options={[
                                    {
                                      key: -1,
                                      text: "Selecione uma opção",
                                      disabled: true,
                                      hidden: true,
                                      data: { styleOption: { fontSize: 14 } },
                                    },
                                    ...(avaliacao?.tabPerformance.map(
                                      (item) => ({
                                        key: item.idTabPerformance,
                                        text: item.descNivel.toUpperCase(),
                                        data: { style: { color: item.cor } },
                                        hidden:
                                          item.flagBloquearAvaliacao ?? false,
                                      })
                                    ) ?? []),
                                  ]}
                                  selectedKey={
                                    avaliacao?.idTabPerformanceGestor ?? -1
                                  }
                                  onRenderTitle={
                                    onRenderTitltePerformance as any
                                  }
                                  disabled={true}
                                  styles={{
                                    title: {
                                      height: 60,
                                      display: "flex",
                                      alignItems: "center",
                                      borderColor: "#CCCCCC",
                                    },
                                    caretDownWrapper: {
                                      top: 16,
                                    },
                                  }}
                                />
                              </TdResultAtitudes>
                            </tr>
                            {!isDesligado && (
                              <>
                                <tr>
                                  <Gap
                                    style={{ minWidth: 370, maxWidth: 370 }}
                                  />
                                  <td style={{ width: 160, minWidth: 160 }}>
                                    <Text variant="small">POTENCIAL</Text>
                                  </td>
                                  <TdResultAtitudes
                                    style={{
                                      width: 420,
                                      minWidth: 420,
                                      borderWidth: 0,
                                    }}
                                  >
                                    <DropdownUI
                                      options={optionsPotencial}
                                      defaultSelectedKey={
                                        avaliacao?.potencial ??
                                        "Selecione uma opção"
                                      }
                                      selectedKey={
                                        avaliacao?.potencial ??
                                        "Selecione uma opção"
                                      }
                                      onRenderTitle={
                                        onRenderTitlePotencial as any
                                      }
                                      disabled={true}
                                      styles={{
                                        title: {
                                          height: 60,
                                          display: "flex",
                                          alignItems: "center",
                                          borderColor: "#CCCCCC",
                                        },
                                        caretDownWrapper: {
                                          top: 16,
                                        },
                                      }}
                                    />
                                  </TdResultAtitudes>
                                </tr>
                                <tr>
                                  <Gap
                                    style={{ minWidth: 370, maxWidth: 370 }}
                                  />
                                  <td style={{ width: 160, minWidth: 160 }}>
                                    <Text variant="small">SUCESSÃO</Text>
                                  </td>
                                  <TdResultAtitudes
                                    style={{
                                      width: 420,
                                      minWidth: 420,
                                      borderWidth: 0,
                                    }}
                                  >
                                    <DropdownUI
                                      placeholder="Selecione uma opção"
                                      options={optionsSucessao}
                                      defaultSelectedKey={
                                        avaliacao?.sucessao ??
                                        "Selecione uma opção"
                                      }
                                      selectedKey={
                                        avaliacao?.sucessao ??
                                        "Selecione uma opção"
                                      }
                                      onRenderTitle={
                                        onRenderTitlePotencial as any
                                      }
                                      disabled={true}
                                      styles={{
                                        title: {
                                          height: 60,
                                          display: "flex",
                                          alignItems: "center",
                                          borderColor: "#CCCCCC",
                                        },
                                        caretDownWrapper: {
                                          top: 16,
                                        },
                                      }}
                                    />
                                  </TdResultAtitudes>
                                </tr>
                              </>
                            )}
                          </Tbody>
                        </Table>
                        <WrapperForm>
                          <InputText
                            label="Comentários gerais"
                            multiline
                            rows={3}
                            value={avaliacao?.comentariosGerais ?? ""}
                            aria-readonly="true"
                          />
                        </WrapperForm>
                      </Stack>
                    </AccordionDetails>
                  </Accordion>
                </WrapperResults>
              </>
            )}
          </>
        )}
      </Content>
    </Container>
  );
};

const AcimaExpectativa = (text?: string) => (
  <>
    <FontIcon
      iconName="SkypeCircleArrow"
      style={{
        fontSize: 16,
        marginRight: 5,
        color: Colors.blue,
        transform: "rotate(90deg)",
      }}
    />
    {text ?? "ACIMA DA EXPECTATIVA"}
  </>
);

const AbaixoExpectativa = (text?: string) => (
  <>
    <FontIcon
      iconName="SkypeCircleArrow"
      style={{
        fontSize: 16,
        marginRight: 5,
        color: Colors.error,
        transform: "rotate(-90deg)",
      }}
    />
    {text ?? "ABAIXO DA EXPECTATIVA"}
  </>
);

const DentroExpectativa = (text?: string) => (
  <>
    <FontIcon
      iconName="SkypeCircleCheck"
      style={{ fontSize: 16, marginRight: 5, color: Colors.primary }}
    />
    {text ?? "DENTRO DA EXPECTATIVA"}
  </>
);

export const renderAvaliacao = (result: string) => {
  switch (result) {
    case "Abaixo da Expectativa":
      return AbaixoExpectativa();
    case "Dentro da Expectativa":
      return DentroExpectativa();
    case "Acima da Expectativa":
      return AcimaExpectativa();
    default:
      return result;
  }
};

const Accordion = withStyles({
  root: {
    padding: "0px !important",
    paddingBottom: 10,
    boxShadow: "none",
    display: "contents",

    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {
    borderBottom: 0,
  },
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    borderBottom: "1px solid #CFD3DA",
    height: 50,
    minHeight: "50px !important",
    "&:hover": {
      backgroundColor: "#F3F2F1",
    },
    expanded: {},
  },
})(MuiAccordionSummary);

const textStyles: ITextStyles = {
  root: {
    fontWeight: 500,
    display: "block",
  },
};

const options: IDropdownOption[] = [
  {
    key: "Selecione uma opção",
    text: "Selecione uma opção",
    disabled: true,
    hidden: true,
    data: { styleOption: { fontSize: 14 } },
  },
  {
    key: "Acima da Expectativa",
    text: "ACIMA DA EXPECTATIVA",
    data: {
      icon: "SkypeCircleArrow",
      style: { color: Colors.blue, transform: "rotate(90deg)" },
    },
  },
  {
    key: "Dentro da Expectativa",
    text: "DENTRO DA EXPECTATIVA",
    data: { icon: "SkypeCircleCheck", style: { color: Colors.primary } },
  },
  {
    key: "Abaixo da Expectativa",
    text: "ABAIXO DA EXPECTATIVA",
    data: {
      icon: "SkypeCircleArrow",
      style: { color: Colors.error, transform: "rotate(-90deg)" },
    },
  },
];

const optionsPotencial: IDropdownOption[] = [
  {
    key: "Selecione uma opção",
    text: "Selecione uma opção",
    disabled: true,
    hidden: true,
    data: { styleOption: { fontSize: 14 } },
  },
  {
    key: "Alto potencial para crescer",
    text: "ALTO POTENCIAL PARA CRESCER",
    data: { style: { color: "#027636" } },
  },
  {
    key: "Potencial para crescer",
    text: "POTENCIAL PARA CRESCER",
    data: { style: { color: "#88C8A5" } },
  },
  {
    key: "Potencial lateral",
    text: "POTENCIAL LATERAL",
    data: { style: { color: "#D4A31D" } },
  },
  {
    key: "Cedo demais para avaliar",
    text: "CEDO DEMAIS PARA AVALIAR",
    data: { style: { color: "#73AF8E" } },
  },
];

const optionsSucessao: IDropdownOption[] = [
  {
    key: "Selecione uma opção",
    text: "Selecione uma opção",
    disabled: true,
    hidden: true,
    data: { styleOption: { fontSize: 14 } },
  },
  {
    key: "Null",
    text: "",
    disabled: true,
    hidden: true,
    data: { styleOption: { fontSize: 14 } },
  },
  { key: "Imediato", text: "IMEDIATO", data: { style: { color: "#027636" } } },
  {
    key: "Curto Prazo - 1 a 2 anos",
    text: "CURTO PRAZO - 1 A 2 ANOS",
    data: { style: { color: "#73AF8E" } },
  },
  {
    key: "Médio Prazo - 3 a 5 anos",
    text: "MÉDIO PRAZO - 3 A 5 ANOS",
    data: { style: { color: "#88C8A5" } },
  },
  {
    key: "Longo Prazo - acima 5 anos",
    text: "LONGO PRAZO - ACIMA 5 ANOS",
    data: { style: { color: "#D4A31D" } },
  },
];

const commandButtonStyle: Partial<ICommandBarStyles> = {
  root: {
    height: 44,
  },
};

export default SelfEvaluationResume;
