import {
  Types,
  DataTypes,
  MeritGenerateActionTypes,
  MeritGenerateType,
} from "./types";
import customToast from "~/components/Toast/index";

/**
 * Reducers
 */
const INITIAL_STATE: DataTypes = {
  data: [],
  itemSelected: {
    item: null,
    loading: false,
    success: false,
    error: false,
  },
  error: false,
  loadingData: false,
  loadingAction: false,
  success: false,
  successAdd: false,
  idMeritoCreated: null,
};

/**
 * Actions
 */

export const Creators = {
  getMeritGenerate: (): MeritGenerateActionTypes => {
    return {
      type: Types.REQUEST_GENERATE_MERIT_LOAD,
    };
  },

  addMeritGenerate: (
    payload: Partial<MeritGenerateType>
  ): MeritGenerateActionTypes => {
    return {
      type: Types.REQUEST_GENERATE_MERIT_ADD,
      payload,
    };
  },

  delMeritGenerate: (idMerito: number): MeritGenerateActionTypes => {
    return {
      type: Types.REQUEST_GENERATE_MERIT_DEL,
      idMerito,
    };
  },

  finalizarMeritGenerate: (idMerito: number): MeritGenerateActionTypes => {
    return {
      type: Types.REQUEST_GENERATE_MERIT_FINALIZAR,
      idMerito,
    };
  },
  reabrirMeritoGenerate: (idMerito: number): MeritGenerateActionTypes => ({
    type: Types.REQUEST_GENERATE_MERIT_REABRIR,
    payload: {
      idMerito,
    },
  }),
};

export default function meritGenerateReducer(
  state = INITIAL_STATE,
  action: MeritGenerateActionTypes
): DataTypes {
  switch (action.type) {
    //LOAD
    case Types.REQUEST_GENERATE_MERIT_LOAD:
      return { ...state, loadingData: true, error: false, success: false };
    case Types.SUCCESS_GENERATE_MERIT_LOAD:
      return {
        ...state,
        data: action.payload,
        loadingData: false,
        error: false,
        success: true,
      };
    case Types.FAILURE_GENERATE_MERIT_LOAD:
      customToast.error(action.message);
      return {
        ...state,
        data: [],
        loadingData: false,
        error: true,
        success: false,
      };

    //ADD
    case Types.REQUEST_GENERATE_MERIT_ADD:
      return { ...state, loadingAction: true, error: false, successAdd: false };
    case Types.SUCCESS_GENERATE_MERIT_ADD:
      return {
        ...state,
        loadingAction: false,
        error: false,
        successAdd: true,
        idMeritoCreated: action.idMerito,
      };
    case Types.FAILURE_GENERATE_MERIT_ADD:
      customToast.error(action.message);
      return { ...state, loadingAction: false, error: true, successAdd: false };

    //DEL
    case Types.REQUEST_GENERATE_MERIT_DEL:
      return { ...state, loadingAction: true, error: false, success: false };
    case Types.SUCCESS_GENERATE_MERIT_DEL:
      return {
        ...state,
        data: state.data.filter((item) => item.idMerito !== action.idMerito),
        loadingAction: false,
        error: false,
        success: true,
      };
    case Types.FAILURE_GENERATE_MERIT_DEL:
      customToast.error(action.message);
      return { ...state, loadingAction: false, error: true, success: false };

    //FINALIZAR
    case Types.REQUEST_GENERATE_MERIT_FINALIZAR:
      return { ...state, loadingAction: true, error: false, success: false };
    case Types.SUCCESS_GENERATE_MERIT_FINALIZAR:
      customToast.success("Reunião finalizada com sucesso!");
      return {
        ...state,
        data: state.data.map((item) => {
          if (item.idMerito === action.payload.idMerito) {
            return {
              ...item,
              status: action.payload.status,
            };
          }
          return item;
        }),
        loadingAction: false,
        error: false,
        success: true,
      };
    case Types.FAILURE_GENERATE_MERIT_FINALIZAR:
      customToast.error(action.message);
      return { ...state, loadingAction: false, error: true, success: false };

    //FINALIZAR
    case Types.REQUEST_GENERATE_MERIT_REABRIR:
      return { ...state, loadingAction: true, error: false, success: false };
    case Types.SUCCESS_GENERATE_MERIT_REABRIR:
      customToast.success("Reunião reaberta com sucesso!");
      return {
        ...state,
        data: state.data.map((item) => {
          if (item.idMerito === action.payload.idMerito) {
            return {
              ...item,
              status: action.payload.status,
            };
          }
          return item;
        }),
        loadingAction: false,
        error: false,
        success: true,
      };
    case Types.FAILURE_GENERATE_MERIT_REABRIR:
      customToast.error(action.message);
      return { ...state, loadingAction: false, error: true, success: false };

    default:
      return state;
  }
}
