
/**
 * Types
 */
export enum Types {
    //GET COMPETENCIAS
    REQUEST_PDI_COMPETENCE_LOAD = "pdi_competence/REQUEST_PDI_COMPETENCE_LOAD",
    SUCCESS_PDI_COMPETENCE_LOAD = "pdi_competence/SUCCESS_PDI_COMPETENCE_LOAD",
    FAILURE_PDI_COMPETENCE_LOAD = "pdi_competence/FAILURE_PDI_COMPETENCE_LOAD",
    //ADD COMPETENCIAS
    REQUEST_PDI_COMPETENCE_ADD = "pdi_competence/REQUEST_PDI_COMPETENCE_ADD",
    SUCCESS_PDI_COMPETENCE_ADD = "pdi_competence/SUCCESS_PDI_COMPETENCE_ADD",
    FAILURE_PDI_COMPETENCE_ADD = "pdi_competence/FAILURE_PDI_COMPETENCE_ADD",
}

export type PDICompetenceType = {
    idCompetencia: number | null;
    descCompetencia: string;
}

interface IPDIState {
    data: PDICompetenceType[];
    loading: boolean;
    loadingAction: boolean;
    success: boolean;
    error: boolean;
};

export type DataTypes = IPDIState;

//GET PDI COMPETENCE
export interface IRequestPDICompetenceLoadAction {
    type: typeof Types.REQUEST_PDI_COMPETENCE_LOAD;
};

export interface ISuccessPDICompetenceLoadAction {
    type: typeof Types.SUCCESS_PDI_COMPETENCE_LOAD;
    payload: PDICompetenceType[];
};

interface IFailurePDICompetenceLoadAction {
    type: typeof Types.FAILURE_PDI_COMPETENCE_LOAD;
    message: string;
};

type PDICompetenceLoadAction = IRequestPDICompetenceLoadAction | ISuccessPDICompetenceLoadAction | IFailurePDICompetenceLoadAction;

//GET PDI COMPETENCE
export interface IRequestPDICompetenceAddAction {
    type: typeof Types.REQUEST_PDI_COMPETENCE_ADD;
    descCompetencia: string;
};

export interface ISuccessPDICompetenceAddAction {
    type: typeof Types.SUCCESS_PDI_COMPETENCE_ADD;
    payload: PDICompetenceType;
};

interface IFailurePDICompetenceAddAction {
    type: typeof Types.FAILURE_PDI_COMPETENCE_ADD;
    message: string;
};

type PDICompetenceAddAction = IRequestPDICompetenceAddAction | ISuccessPDICompetenceAddAction | IFailurePDICompetenceAddAction;

export type PDIActionTypes = PDICompetenceLoadAction | PDICompetenceAddAction;
