import { Types, DataTypes, PillarsActionTypes, PillarType } from "./types";
import customToast from "~/components/Toast/index";

/**
 * Reducers
 */
const INITIAL_STATE: DataTypes = {
  data: [],
  error: false,
  loadingData: false,
  loadingAction: false,
  success: false
};

/**
 * Actions
 */

export const Creators = {
  getPillars: (search?: string): PillarsActionTypes => {
    search = search ? search : "";
    return {
      type: Types.REQUEST_PILLARS_LOAD,
      search
    }
  },

  addPillar: (pillar: PillarType): PillarsActionTypes => ({
    type: Types.REQUEST_PILLARS_ADD,
    pillar
  }),

  editPillar: (pillar: PillarType): PillarsActionTypes => ({
    type: Types.REQUEST_PILLARS_EDIT,
    pillar
  }),

  delPillar: (id: number): PillarsActionTypes => ({
    type: Types.REQUEST_PILLARS_DELETE,
    id
  })
};

export default function pillarsReducer(state = INITIAL_STATE, action: PillarsActionTypes): DataTypes {

  switch (action.type) {
    case Types.REQUEST_PILLARS_LOAD:
      return { ...state, loadingData: true, error: false, success: false };
    case Types.SUCCESS_PILLARS_LOAD:
      return { ...state, data: action.payload, loadingData: false, error: false, success: true };
    case Types.FAILURE_PILLARS_LOAD:
      customToast.error(action.message);
      return { ...state, data: [], loadingData: false, error: true, success: false };

    case Types.REQUEST_PILLARS_ADD:
      return { ...state, loadingAction: true, error: false, success: false };
    case Types.SUCCESS_PILLARS_ADD:
      customToast.success("Pilar cadastrado com sucesso");
      return { ...state, loadingAction: false, error: false, success: true, data: [...state.data, action.pillar] };
    case Types.FAILURE_PILLARS_ADD:
      customToast.error(action.message);
      return { ...state, loadingAction: false, error: true, success: false };

    case Types.REQUEST_PILLARS_EDIT:
      return { ...state, loadingAction: true, error: false, success: false };
    case Types.SUCCESS_PILLARS_EDIT:
      customToast.success("Pilar editado com sucesso");
      return {
        ...state, loadingAction: false, error: false, success: true, data: state.data.map(item => {
          if (item.idPilar !== action.pillar.idPilar) {
            return item;
          } else {
            return action.pillar;
          }
        })
      };
    case Types.FAILURE_PILLARS_EDIT:
      customToast.error(action.message);
      return { ...state, loadingAction: false, error: true, success: false };

    case Types.REQUEST_PILLARS_DELETE:
      return { ...state, loadingData: true, error: false, success: false };
    case Types.SUCCESS_PILLARS_DELETE:
      customToast.success("Pilar removido com sucesso");
      return { ...state, loadingData: false, error: false, success: true, data: state.data.filter(item => item.idPilar !== action.id), };
    case Types.FAILURE_PILLARS_DELETE:
      customToast.error(action.message);
      return { ...state, loadingData: false, error: true, success: false };

    default:
      return state;
  };
};