import { takeEvery, put, call, all } from "redux-saga/effects";
import {
    Types,
    StepType,
    IRequestStepLoadAction,
    IRequestStepAddAction,
    IRequestStepDelAction,
    ISuccessStepLoadAction,
    ISuccessStepAddAction,
    ISuccessStepDelAction
} from "../../ducks/admin/steps/types";
import api from "~/services/api";

const apiCallGet = () => {
    return api
        .get(`steps`)
        .then((response) => response.data)
        .catch((err) => {
            throw err.response;
        });
};

const apiCallAdd = (payload: IRequestStepAddAction["payload"]) => {
    return api
        .post(`steps`, {
            ...payload
        })
        .then((response) => response.data)
        .catch((err) => {
            throw err.response;
        });
};

const apiCallDel = (idStep: number) => {
    return api
        .delete(`steps/${idStep}`)
        .then((response) => response.data)
        .catch((err) => {
            throw err.response;
        });
};

function* load(action: IRequestStepLoadAction) {
    try {
        let items: StepType[] = yield call(apiCallGet);
        yield put({
            type: Types.SUCCESS_STEP_LOAD,
            payload: items
        } as ISuccessStepLoadAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível carregar os steps'
        yield put({
            type: Types.FAILURE_STEP_LOAD,
            message
        });
    }
}

function* add(action: IRequestStepAddAction) {
    try {
        let idStep: { idStep: number } = yield call(apiCallAdd, action.payload);
        yield put({
            type: Types.SUCCESS_STEP_ADD,
            payload: {
                ...idStep,
                percent: action.payload.percent
            }
        } as ISuccessStepAddAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível adicionar o step'
        yield put({
            type: Types.FAILURE_STEP_ADD,
            message
        });
    }
}

function* del(action: IRequestStepDelAction) {
    try {
        yield call(apiCallDel, action.idStep);
        yield put({
            type: Types.SUCCESS_STEP_DEL,
            idStep: action.idStep
        } as ISuccessStepDelAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível excluír o step'
        yield put({
            type: Types.FAILURE_STEP_DEL,
            message
        });
    }
}

export default function* stepRoot() {
    yield all([
        yield takeEvery(Types.REQUEST_STEP_LOAD, load),
        yield takeEvery(Types.REQUEST_STEP_ADD, add),
        yield takeEvery(Types.REQUEST_STEP_DEL, del),
    ]);
}
