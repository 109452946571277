/**
 * Types
 */
export enum Types {
  //LOAD
  REQUEST_TEAM_EVALUATION_LOAD = "teamEvaluation/REQUEST_TEAM_EVALUATION_LOAD",
  SUCCESS_TEAM_EVALUATION_LOAD = "teamEvaluation/SUCCESS_TEAM_EVALUATION_LOAD",
  FAILURE_TEAM_EVALUATION_LOAD = "teamEvaluation/FAILURE_TEAM_EVALUATION_LOAD",
};

interface ITeamEvalationState {
  readonly data: TeamEvaluationType;
  readonly loading: boolean;
  readonly error: boolean;
  readonly success: boolean;
};

export type DataTypes = ITeamEvalationState;

export interface TeamEvaluationType {
  idCicloTime: number | null;
  membros: MembrosType[];
  funcionario: {
    nomeCompleto: string;
  };
  ciclo: {
    ano: number | null;
  }
}

export interface MembrosType {
  idFuncionario: number;
  funcionario: {
    nomeCompleto: string;
    matricula: string;
    status: string;
  };
  flagRealizarAvaliacao: boolean;
  flagEditarAvaliacao: boolean;
  flagVisualizarAvaliacao: boolean;
  ciclocolaborador: {
    idCicloColaborador: number;
    status: string;
  }
  avaliacao: {
    idTabPerformance: number;
    idCicloAvaliacao: number;
    idTabPerformanceGestor: number;
    potencial: string;
    sucessao: string;
    tipoAvaliacao: string;
    statusAvaliacao: string;
    flagRealizado: boolean;
    performance: {
      descNivel: string;
      cor: string;
    };
    performancegestor:{
      descNivel: string;
      cor: string;
    };
  } | null;

  tarefa: {
    idTarefaAvaliacao: number;
    idTarefa: number;
    idCicloAvaliacao: number;
  }
}


//SAVE
export interface IRequestTeamEvaluationLoadAction {
  type: typeof Types.REQUEST_TEAM_EVALUATION_LOAD;
  idCiclo: number,
};

export interface ISuccessTeamEvaluationLoadAction {
  type: typeof Types.SUCCESS_TEAM_EVALUATION_LOAD,
  payload: TeamEvaluationType;
}

interface IFailureTeamEvaluationLoadAction {
  type: typeof Types.FAILURE_TEAM_EVALUATION_LOAD,
  message: string;
}

export type TeamEvaluationActionTypes =
  IRequestTeamEvaluationLoadAction |
  ISuccessTeamEvaluationLoadAction |
  IFailureTeamEvaluationLoadAction;
