import styled from 'styled-components';


export const TableChild = styled.table<{ hidden: boolean, isInactive: boolean }>`
  margin-top: 15px;
  border-collapse: separate;
  border-spacing: 0 5px;
  position: relative;
  width: 100%;
  display: ${({ hidden }) => hidden ? 'none' : 'inline-table'};
  opacity: ${({ isInactive }) => isInactive ? 0.4 : 1};
  `

export const Container = styled.div`
  position: relative;
  top: 55px;
  padding: 0 20px;
  height: 100%;
  display: flex;
  flex-direction: column;


  ${TableChild}.tbl-collapsed{
    display: none;
  }

  ${TableChild}.tbl-expanded{
    display: block;
  }
`;

export const WrapperInput = styled.div`
    display: flex;
    align-items: center;
    /* position: fixed; */
    /* width: calc(100% - 290px); */
    z-index: 10;
    background-color: #FFFFFF;
    margin: 20px 0;
    box-sizing: border-box;
    /* top: 235px;

    @media screen and (max-width: 960px){
      top: 316px;
    } */
`


export const TableHeader = styled.table`
  margin-top: 20px;
  border-collapse: separate;
  border-spacing: 0px;
  position: relative;
  width: 100%;
`

export const Table = styled.table<{ isInactive: boolean }>`
  margin-top: 15px;
  border-collapse: separate;
  border-spacing: 0px;
  position: relative;
  width: 100%;
  opacity: ${({ isInactive }) => isInactive ? 0.4 : 1};
`

export const TableTotal = styled.table`
  margin-top: 15px;
  border-collapse: separate;
  border-spacing: 0px;
  position: relative;
  width: 100%;
  margin-bottom: 20px;
`


export const TdMeta = styled.td<{ anchored?: boolean, dark?: boolean }>`
  width: 70px;
  min-width: 70px;
  max-width: 70px;
  height: 48px;
  border: 1px solid #CCCCCC;
  border-right: none;
  text-align: center;
  box-sizing: border-box;
  position: relative;
  font-size: 12px;
  background-color: ${({ dark }) => !dark ? '#FFFFFF' : '#CFD3DA'};
  color: ${({ anchored }) => anchored ? '#8E8C8A' : '#323130'};
  cursor: ${({ anchored }) => anchored ? 'normal' : 'pointer'};
  ${({ anchored }) => !anchored ? `
    &:hover{
      background-color: #F5F5F5;
    }

    &:active{
      background-color: #CCCCCC;
    }
  ` : ''};
`

export const TdPeso = styled.td<{ black?: boolean }>`
  width: 70px;
  min-width: 70px;
  height: 48px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  border-radius: 2px;
  border: 1px solid #CCCCCC;
  box-sizing: border-box;
  border-radius: 2px;
  position: relative;
  cursor: pointer;
  ${({ black }) => black ? `
    background-color: #000000 !important;
    color: #FFFFFF !important;
    border: none;
    cursor: default;
  ` : ''};

  &:hover{
        background-color: #F5F5F5;
  }

  &:active{
      background-color: #CCCCCC;
  }

`

export const TBody = styled.tbody`

  tr:last-child{

    ${TdMeta}{
      border-bottom: 1px solid #CCCCCC;
    }

    ${TdPeso}{
      border-bottom: 1px solid #CCCCCC;
    }
  }
`

export const Gap = styled.td`
    width: 10px;
    min-width: 10px;
    background-color: #FFFFFF;
    border: none !important;
`

export const TdIcon = styled.td`
  width: 48px;
  min-width: 48px;
  height: 48px;
  border: none;
  border-radius: 2px;
  text-align: center;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  &:hover{
    background-color: #F8F8F8;
  }

  &:active{
    background-color: #CCCCCC;
  }
`

export const TdParentIcon = styled.td<{ disabled: boolean }>`
  width: 48px;
  min-width: 48px;
  height: 48px;
  border: 1px solid #CCCCCC;
  border-radius: 2px;
  text-align: center;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 600;
  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
  ${({ disabled }) => disabled ? 'background-color: #CCCCCC !important' : ''};

  &:hover{
    background-color: #F8F8F8;
  }

  &:active{
    background-color: #CCCCCC;
  }

`

export const TdAreaHeader = styled.td`
  height: 48px;
  min-width: 209px;
  background-color: #CFD3DA;
  font-size: 12px;
  font-weight: 600;
  padding: 0 15px;
  border-radius: 2px;
  color: #323130;
  text-transform: uppercase;
  box-sizing: border-box;
`

export const TdArea = styled.td`
  min-width: 365px;
  height: 48px;
  font-size: 12px;
  font-weight: 600;
  padding: 0 15px;
  border-radius: 2px;
  color: #323130;
  text-transform: uppercase;
  box-sizing: border-box;
`

export const TdMetaHeaderTitle = styled.td`
  width: 70px;
  min-width: 70px;
  height: 24px;
  border: 1px solid #CCCCCC;
  background-color: #CFD3DA;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  box-sizing: border-box;
  border-bottom: none;
`

export const TdMetaHeader = styled.td`
  width: 70px;
  min-width: 70px;
  height: 24px;
  border: 1px solid #CCCCCC;
  border-right: none;
  font-size: 12px;
  text-align: center;
  box-sizing: border-box;
`

export const TdPesoHeader = styled.td`
  width: 70px;
  min-width: 70px;
  height: 48px;
  background-color: #757575;
  color: #FFFFFF;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  border-radius: 2px;
  box-sizing: border-box;
`
export const TrHeader = styled.tr`

  ${TdMetaHeader}:first-child{
    border-bottom-left-radius: 2px;
  }

  ${TdMetaHeader}:last-child{
    border-right: 1px solid #CCCCCC;
    border-bottom-right-radius: 2px;
  }
`

export const TrParent = styled.tr<{ color: string }>`
  td:nth-child(3){
    background-color: #CFD3DA;
    border: 1px solid #CCCCCC;
    font-size: 14px;
    font-weight: 600;
  }

  td:nth-child(5){
    color: ${({ color }) => color};
    border: 1px solid ${({ color }) => color};
  }

  td:nth-child(7){
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }

  td:nth-child(9){
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    border-right: 1px solid #CCCCCC;
  }
`


export const TdIndication = styled.td<{ rmvHover?: boolean, noBorder?: boolean }>`
  width: 54px;
  min-width: 54px;
  height: 48px;
  border: ${({ noBorder }) => noBorder ? 'none' : '1px solid #CCCCCC'};
  text-align: center;
  border-radius: 2px;
  box-sizing: border-box;
  font-size: 12px;
  text-transform: uppercase;
  ${({ rmvHover }) => !rmvHover ? `
    cursor: pointer;
    &:hover{
      background-color: #F8F8F8;
    }

    &:active{
      background-color: #CCCCCC;
    }
  ` : ''};

`

export const WrapperDelBtn = styled.div`
  position: absolute;
  right: -30px;
  transition: right 0.2s;
`
export const WrapperAnchorIcon = styled.div`
  opacity: 1;
  transition: opacity 0.2s;
`

export const TdAreaChild = styled.td`
  height: 48px;
  font-size: 12px;
  font-weight: 600;
  color: #323130;
  text-transform: uppercase;
  box-sizing: border-box;
  position: relative;
  border-radius: 2px;
  overflow: hidden;

  &:hover{
    ${WrapperDelBtn}{
      right: 8px;
      transition: right 0.2s;
    }
    ${WrapperAnchorIcon}{
      opacity: 0;
      transition: opacity 0.2s;
    }
  }
`

export const TrChild = styled.tr<{ color: string, isInactive: boolean, hadAddDuplicate?: boolean }>`
  opacity: ${({ isInactive }) => isInactive ? 0.3 : 1};

td:nth-child(${({ hadAddDuplicate }) => !hadAddDuplicate ? 8 : 6}){
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }

  td:nth-child(${({ hadAddDuplicate }) => !hadAddDuplicate ? 10 : 8}){
    border-right: 1px solid #CCCCCC;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }

  ${TdAreaChild}{
    border: 1px solid ${({ color }) => color};
    /* border-bottom: none; */
  }
`

export const BiggerGap = styled.td`
  background-color: #FFFFFF;
  border: none !important;
  width: 122px;
  min-width: 122px;

`
export const TBodyChild = styled.tbody`
  width: 100%;
    display: inline-table;
`

export const TdActionBtns = styled.td`
  height: 48px;
  font-size: 12px;
  font-weight: 600;
  padding: 0 15px;
  color: #323130;
  text-transform: uppercase;
  box-sizing: border-box;
  position: relative;
`

export const WrapperAutoComplete = styled.div`
  position: relative;
  width: 100%;
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-endAdornment{
    right: 35px;
  }
`


export const DescArea = styled.p`
  padding-left: 15px;

`

export const GapTotal = styled.td`
    width: 122px;
    min-width: 122px;
    background-color: #FFFFFF;
    border: none !important;
`

export const TdTotal = styled.td`
  background-color: #000000;
  min-width: 365px;
  box-sizing: border-box;
  height: 48px;
  color: #FFFFFF;
  text-transform: uppercase;
  padding: 0 15px;
  font-weight: 600;
  text-align: center;
  font-size: 14px;
  border-radius: 2px;
`

export const Tr = styled.tr`

`
export const WrapperSpinner = styled.div`
  height: calc(100vh - 276px);
  display: flex;
  align-items: center;
  justify-content: center;
`

export const WrapperSearchInput = styled.div`
  margin-top: 5px;
`

export const WrapperOutlineButton = styled.div`
  position: absolute;
  bottom: 24px;
`

export const WrapperDismemberment = styled.div`
    flex-basis: 100%;
    overflow: auto;
    width: 100%;
    padding-right: 20px;
`
