// #region Imports
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Formik } from "formik";
import * as yup from "yup";

//FluentUI
import { Text } from "office-ui-fabric-react/lib/Text";
import { Stack } from "office-ui-fabric-react/lib/Stack";

import {
  DetailsListLayoutMode,
  SelectionMode,
  Selection,
  IColumn,
} from "office-ui-fabric-react/lib/DetailsList";
import { ShimmeredDetailsList } from "office-ui-fabric-react/lib/ShimmeredDetailsList";
import { SearchBox } from "office-ui-fabric-react/lib/SearchBox";
import {
  CommandBarButton,
  IconButton,
  ICommandBarStyles,
  IButtonStyles,
  Toggle,
} from "office-ui-fabric-react";
import { IDialogContentProps } from "office-ui-fabric-react/lib/Dialog";
//MaterialUI
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
//Componentes
import Dropdown from "~/components/layout/Dropdown";
import Panel from "~/components/layout/Panel";
import Status from "~/components/Status";
import CustomDialog from "~/components/CustomDialogFluentUI";
import colors from "~/assets/js/colors";
import { CustomDrawer as Drawer } from "~/components/layout/Drawer";
import { Page, BreadcrumbItems } from "~/store/ducks/home/types";
import {
  InputText,
  InputCheckbox,
  InputDate,
  InputAutocomplete,
} from "~/components/Forms";

import { DeleteButton } from "~/components/Buttons";
import HeaderPage from "~/components/layout/HeaderPage";
import NoItems from "~/components/layout/NoItems";
import { PrimaryButton } from "~/components/Buttons";

//Estilos
import {
  Wrapper,
  ListContainer,
  DefaultButton,
  ContainerContent,
  WrapperAutoComplete,
} from "./styles";

import { Creators as getPositions } from "~/store/ducks/admin/positions";
import { Creators as getPositionById } from "~/store/ducks/admin/positions";
import { Creators as addPosition } from "~/store/ducks/admin/positions";
import { Creators as editPosition } from "~/store/ducks/admin/positions";
import { Creators as delPosition } from "~/store/ducks/admin/positions";
import { Creators as setCurrentPage } from "~/store/ducks/home";
import { PositionTypes, DataTypes } from "~/store/ducks/admin/positions/types";

import { DataTypes as DataTypesCategories } from "~/store/ducks/admin/categoriesPositions/types";
import { Creators as getCategoriesPositions } from "~/store/ducks/admin/categoriesPositions";

import { DataTypes as DataTypesStandards } from "~/store/ducks/admin/standardPosition/types";
import { Creators as getStandardsPositions } from "~/store/ducks/admin/standardPosition";

import { DataTypes as DataTypesCarreira } from "~/store/ducks/admin/carreira/types";
import { Creators as getCarreiras } from "~/store/ducks/admin/carreira";

import { RootState } from "~/store/ducks";

import SearchPosition from "./SearchPosition";
import { MenuItem } from "@material-ui/core";
//#endregion

const btnStyle: Partial<ICommandBarStyles> = {
  root: {
    height: 44,
  },
};

/**
 * Validação do formulário
 */
const validationPositionSchema = yup.object().shape({
  codCargo: yup.string().required("Campo obrigatório"),
  titulo: yup.string().required("Campo obrigatório"),
  dataExtinto: yup
    .date()
    .nullable()
    .when("flagAtivo", {
      is: true,
      then: yup
        .date()
        .typeError("Data de nascimento inválida")
        .required("Campo obrigatório"),
    }),
  gs: yup.number().nullable().required("Campo obrigatório"),
  idCargoSuperior: yup.number().nullable().notRequired(),
  idCategoriaCargo: yup.number().nullable().notRequired(),
  idCarreira: yup.number().nullable().notRequired(),
  idPadraoCargo: yup.number().nullable().notRequired(),
  flagAtivo: yup.bool(),
  flagLideranca: yup.bool().nullable(),
});

type Filter = {
  isOpen?: boolean;
  ativadas: string;
  filterStatus: boolean | null;
};

interface IPositionsState {
  columns: IColumn[];
  items: PositionTypes[];
  isPanelOpen: boolean;
  inicialValues: PositionTypes;
  isFiltering: boolean;
  selectionDetails: string;
  selectionCount: number;
  isDialogExcludeOpen: boolean;
  filter: Filter;
  search: string;
  flagAtivo: boolean;
  isOpenSearchPosition: boolean;
  cargoSelected: Partial<PositionTypes> | null;
  isToggleActive: boolean;
}

interface IPropsPosition {
  positions: DataTypes;
  categories: DataTypesCategories;
  standards: DataTypesStandards;
  carreiras: DataTypesCarreira;
  setCurrentPage: (page: Page) => void;
  getPositions: (
    search?: string,
    isSearch?: boolean,
    filter?: boolean | null,
    gs?: string | null,
    todos?: boolean
  ) => void;
  getPositionById: (idCargo: number) => void;
  addPosition: (position: PositionTypes) => void;
  editPosition: (position: PositionTypes) => void;
  delPosition: (id: number) => void;
  getCategoriesPositions: () => void;
  getStandardsPositions: () => void;
  getCarreiras: () => void;
}

const itemsBreadCrumb: BreadcrumbItems[] = [
  {
    text: "Home",
    isCurrentItem: false,
    icon: "HomeSolid",
    onlyIcon: true,
    url: "/",
  },
  { text: "Cargos", isCurrentItem: true },
];

const initialPosition: PositionTypes = {
  idCargo: null,
  codCargo: "",
  titulo: "",
  gs: null,
  dataExtinto: null,
  idCargoSuperior: null,
  flagAtivo: true,
  flagLideranca: false,
  cargosuperior: null,
  idCategoriaCargo: null,
  idWorklevel: null,
  worklevel: null,
  categoria: null,
  idPadraoCargo: null,
  padraoCargo: null,
  idCarreira: null,
  carreira: null,
};

class Positions extends Component<IPropsPosition, IPositionsState> {
  private formRef: any;
  private inputSearch: any;
  private _selection: Selection;
  private timeout: number;

  constructor(props: IPropsPosition) {
    super(props);
    //#region Colunas
    const columns: IColumn[] = [
      {
        key: "column1",
        name: "",
        ariaLabel: "Status do cargo",
        fieldName: "flagAtivo",
        minWidth: 15,
        maxWidth: 20,
        isResizable: true,
        isSortedDescending: false,
        onRender: (item: PositionTypes) => <Status status={item.flagAtivo!} />,
      },
      {
        key: "column2",
        name: "ID",
        ariaLabel: "ID do cargo",
        fieldName: "codCargo",
        isRowHeader: true,
        minWidth: 75,
        maxWidth: 100,
        isResizable: true,
        isSortedDescending: false,
        onColumnClick: this._onColumnClick,
      },
      {
        key: "column3",
        name: "Título",
        fieldName: "titulo",
        minWidth: 210,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "string",
        isPadded: true,
      },
      {
        key: "column4",
        name: "Categoria",
        fieldName: "categoria.descCategoriaCargo",
        minWidth: 100,
        maxWidth: 100,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "string",
        isPadded: true,
        onRender: (item: PositionTypes) => item.categoria?.descCategoriaCargo,
      },
      {
        key: "column5",
        name: "Grupo Salarial",
        fieldName: "gs",
        minWidth: 100,
        maxWidth: 100,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "number",
        isPadded: true,
      },
    ];
    //#endregion

    this.state = {
      columns: columns,
      items: [],
      isPanelOpen: false,
      selectionDetails: "",
      inicialValues: initialPosition,
      isFiltering: false,
      selectionCount: 0,
      isDialogExcludeOpen: false,
      search: "",
      filter: {
        isOpen: false,
        ativadas: "todos",
        filterStatus: null,
      },
      flagAtivo: false,
      isOpenSearchPosition: false,
      cargoSelected: null,
      isToggleActive: false,
    };

    this._selection = new Selection({
      onSelectionChanged: () => {
        this.setState({
          selectionDetails: this._getSelectionDetails(),
        });
      },
    });

    this.formRef = React.createRef();
    this.inputSearch = React.createRef();
    this.timeout = 0;
  }

  componentDidMount() {
    const page: Page = {
      key: "cargos",
      pages: itemsBreadCrumb,
    };
    this.props.setCurrentPage(page);
    this.props.getCategoriesPositions();
    this.props.getStandardsPositions();
    this.props.getCarreiras();
    this.props.getPositions();
  }

  componentDidUpdate(prevProps: IPropsPosition, _: any) {
    const { itemSelected } = this.props.positions;

    if (this.state.isPanelOpen) {
      if (prevProps.positions.success !== this.props.positions.success) {
        if (this.props.positions.success) {
          this.setState({ isPanelOpen: false });
        }
      }
    }

    if (prevProps.positions.itemSelected.success !== itemSelected.success) {
      if (itemSelected.success) {
        this.setState({
          inicialValues: itemSelected.item!,
          cargoSelected: itemSelected.item!.idCargoSuperior
            ? {
                idCargo: itemSelected.item!.idCargoSuperior,
                titulo: itemSelected.item!.cargosuperior?.titulo,
              }
            : null,
        });
      }
    }
  }

  // Submit do formulário Formik
  handleSubmit = () => {
    if (this.formRef.current) {
      this.formRef.current.handleSubmit();
    }
  };

  _onItemInvoked = (item: PositionTypes): void => {
    this.openEdit();
  };

  _getSelectionDetails(): any {
    const selectionCount = this._selection.getSelectedCount();
    this.setState({ selectionCount });
    this._selection.getSelection();
  }

  openEdit = () => {
    const selectItem: PositionTypes = this._selection.getSelection()[0] as PositionTypes;
    this.props.getPositionById(selectItem.idCargo!);
    this.setState({ isPanelOpen: true, cargoSelected: null });
  };

  cancelPanel = () => {
    this._selection.setAllSelected(false);
    this.setState({ isPanelOpen: false });
  };

  excludePosition = () => {
    this.props.delPosition(
      (this._selection.getSelection()[0] as PositionTypes).idCargo!
    );
    this.setState({ isDialogExcludeOpen: false });
    this._selection.setAllSelected(false);
  };

  //#region  Funções do filtro

  toggleFilter = () => {
    this.setState({
      filter: {
        ...this.state.filter,
        isOpen: !this.state.filter.isOpen,
      },
    });
  };

  toggleParamsFilter = (param: string) => {
    const { filter, search } = this.state;

    switch (param) {
      case "todos":
        this.setState(
          { filter: { ...filter, filterStatus: null, ativadas: param } },
          () => this.props.getPositions(search)
        );
        break;
      case "ativadas":
        this.setState(
          { filter: { ...filter, filterStatus: true, ativadas: param } },
          () => this.props.getPositions(search, true)
        );
        break;
      case "desativas":
        this.setState(
          { filter: { ...filter, filterStatus: false, ativadas: param } },
          () => this.props.getPositions(search, false)
        );
        break;
    }

    if (this.inputSearch.current) {
      this.filter(this.inputSearch.current.state.value);
    }
  };

  handleSearch = (e: any) => {
    const search: string = e?.target.value ? e!.target.value : "";
    this.setState({ search: search });
    if (search === "") {
      this.props.getPositions(
        "",
        false,
        this.state.filter.filterStatus,
        null,
        this.state.isToggleActive
      );
    }
  };

  filter = (key: any) => {
    const { search, filter, isToggleActive } = this.state;

    if (key === "Enter") {
      if (filter.filterStatus !== null) {
        this.props.getPositions(search, false, null, null, true);
      } else {
        this.props.getPositions(search, false);
      }
      if (this.inputSearch.current) {
        this.inputSearch.current.focus();
      }
    }

    if (search === "") {
      this.props.getPositions(
        "",
        false,
        filter.filterStatus,
        null,
        isToggleActive
      );
    }
  };
  //#endregion

  search = (text: string) => {
    if (text.trim()) {
      clearTimeout(this.timeout);
      this.timeout = window.setTimeout(() => {
        this.props.getPositions(text, true);
      }, 700);
    }
  };

  handleToggle = () => {
    const toggleValue = !this.state.isToggleActive;
    this.setState({
      isToggleActive: toggleValue,
      filter: {
        ...this.state.filter,
        filterStatus: toggleValue,
      },
    });
    this.props.getPositions(
      this.state.search,
      false,
      this.state.filter.filterStatus,
      null,
      toggleValue
    );
  };

  commandBarRender = () => {
    const { selectionCount } = this.state;
    if (selectionCount === 1) {
      return (
        <>
          <CommandBarButton
            styles={btnStyle}
            iconProps={{ iconName: "Edit" }}
            text="Editar"
            onClick={() => this.openEdit()}
          />
          <CommandBarButton
            styles={btnStyle}
            iconProps={{ iconName: "Delete" }}
            text="Excluir"
            onClick={() => this.setState({ isDialogExcludeOpen: true })}
          />
        </>
      );
    } else if (selectionCount > 1) {
      return (
        <CommandBarButton
          styles={btnStyle}
          iconProps={{ iconName: "Delete" }}
          text="Excluir"
          onClick={() => this.setState({ isDialogExcludeOpen: true })}
        />
      );
    }
  };

  render() {
    const {
      isPanelOpen,
      isDialogExcludeOpen,
      selectionCount,
      inicialValues,
      isFiltering,
      items,
      columns,
      filter,
      search,
      isOpenSearchPosition,
      cargoSelected,
      isToggleActive,
    } = this.state;
    const {
      positions,
      getPositions,
      addPosition,
      editPosition,
      categories,
      standards,
      carreiras,
    } = this.props;

    const dialogContentProps: IDialogContentProps = {
      title: "Excluir?",
      closeButtonAriaLabel: "Close",
      subText:
        selectionCount <= 1
          ? "Tem certeza de que deseja excluir este item?"
          : "Tem certeza de que deseja excluir estes items?",
    };

    return (
      <>
        <Panel
          title={!inicialValues.idCargo ? "Novo cargo" : inicialValues.titulo}
          open={isPanelOpen}
          onClose={() => this.cancelPanel()}
          footer={
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <DefaultButton onClick={() => this.cancelPanel()}>
                Cancelar
              </DefaultButton>
              <PrimaryButton
                onClick={() => {
                  //                 if (!positions.loadingAction)
                  //                 {

                  this.handleSubmit();
                  //                 }
                }}
                text="Salvar"
                isLoading={positions.loadingAction}
              />
            </Stack>
          }
        >
          <Formik
            innerRef={this.formRef}
            initialValues={{
              ...inicialValues,
              flagAtivo: !inicialValues.flagAtivo,
            }}
            validationSchema={validationPositionSchema}
            validateOnChange={false}
            validateOnBlur={true}
            enableReinitialize
            onSubmit={(values: PositionTypes) => {
              values.idCargoSuperior = values.idCargoSuperior
                ? values.idCargoSuperior
                : null;

              if ((values.idCategoriaCargo as any) === "") {
                values.idCategoriaCargo = null;
              } else {
                values.categoria =
                  categories.data.find(
                    (item) =>
                      Number(item.idCategoriaCargo) === values.idCategoriaCargo
                  ) ?? null;
              }

              if (inicialValues.idCargo === null) {
                addPosition(values);
              } else {
                values.idCargo = inicialValues.idCargo;
                if (!values.flagAtivo) {
                  values.dataExtinto = null;
                }
                editPosition({ ...values, flagAtivo: !values.flagAtivo });
              }
            }}
          >
            {({
              handleSubmit,
              handleChange,
              values,
              errors,
              setFieldError,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <InputText
                  value={values.codCargo}
                  onChange={(e) => {
                    handleChange(e);
                    setFieldError("codCargo", "");
                  }}
                  id="codCargo"
                  error={errors.codCargo ? true : false}
                  name="codCargo"
                  type="text"
                  label="Código"
                  helperText={errors.codCargo}
                  inputProps={{ maxLength: 5 }}
                  className="mt-2"
                  autoFocus
                />
                <InputText
                  value={values.titulo}
                  onChange={(e) => {
                    handleChange(e);
                    setFieldError("titulo", "");
                  }}
                  id="titulo"
                  error={errors.titulo ? true : false}
                  name="titulo"
                  type="text"
                  label="Título"
                  helperText={errors.titulo}
                  inputProps={{ maxLength: 80 }}
                  className="mt-2"
                />
                <Dropdown
                  errors={errors.idCarreira}
                  label="Carreira"
                  name="idCarreira"
                  values={values.idCarreira ?? ""}
                  handleChange={(e: any) => {
                    handleChange(e);
                    setFieldError("idCarreira", "");
                  }}
                  errorText={errors.idCarreira}
                >
                  <MenuItem value={""}>Nenhum</MenuItem>
                  {carreiras.data.map((car) => (
                    <MenuItem key={car.idCarreira} value={car.idCarreira}>
                      {car.nomeCarreira}
                    </MenuItem>
                  ))}
                </Dropdown>
                <InputText
                  value={values.gs ?? ""}
                  onChange={(e) => {
                    handleChange(e);
                    setFieldError("gs", "");
                  }}
                  id="gs"
                  error={!!errors.gs}
                  name="gs"
                  type="text"
                  label="Grupo Salarial"
                  helperText={errors.gs}
                  className="mt-2"
                />
                <WrapperAutoComplete>
                  <InputAutocomplete
                    value={cargoSelected as PositionTypes}
                    onChange={(_, newValue) => {
                      setFieldValue("idCargoSuperior", newValue?.idCargo);
                      this.setState({ cargoSelected: newValue });
                    }}
                    onInputChange={(_, newInputValue) => {
                      this.search(newInputValue);
                    }}
                    getOptionLabel={(cargo: PositionTypes) => cargo.titulo}
                    getOptionSelected={(option, value) =>
                      option.idCargo === value.idCargo
                    }
                    options={positions.dataSearch}
                    input={{
                      idInput: "cargosup",
                      labelInput: "Cargo Superior",
                      errorInput: !!errors.idCargoSuperior,
                      helperTextInput: errors.idCargoSuperior,
                    }}
                  />
                  <IconButton
                    iconProps={{ iconName: "Search" }}
                    styles={IconButtonStyle}
                    onClick={() =>
                      this.setState({ isOpenSearchPosition: true })
                    }
                  />
                </WrapperAutoComplete>
                <Dropdown
                  errors={errors.idCategoriaCargo}
                  label="Categoria"
                  name="idCategoriaCargo"
                  values={values.idCategoriaCargo ?? ""}
                  handleChange={(e: any) => {
                    handleChange(e);
                    setFieldError("idCategoriaCargo", "");
                  }}
                  errorText={errors.idCategoriaCargo}
                >
                  <MenuItem value={""}>Nenhum</MenuItem>
                  {categories.data.map((cat) => (
                    <MenuItem
                      key={cat.idCategoriaCargo}
                      value={cat.idCategoriaCargo}
                    >
                      {cat.descCategoriaCargo}
                    </MenuItem>
                  ))}
                </Dropdown>
                <Dropdown
                  errors={errors.idPadraoCargo}
                  label="Padrão"
                  name="idPadraoCargo"
                  values={values.idPadraoCargo ?? ""}
                  handleChange={(e: any) => {
                    handleChange(e);
                    setFieldError("idPadraoCargo", "");
                  }}
                  errorText={errors.idPadraoCargo}
                >
                  <MenuItem value={""}>Nenhum</MenuItem>
                  {standards.data.map((standard) => (
                    <MenuItem
                      key={standard.idPadraoCargo}
                      value={standard.idPadraoCargo}
                    >
                      {standard.descPadraoCargo}
                    </MenuItem>
                  ))}
                </Dropdown>
                <InputCheckbox
                  checked={values.flagLideranca ?? false}
                  onChange={handleChange}
                  name="flagLideranca"
                  color="primary"
                  label="Cargo de Liderança"
                />
                <br />

                {inicialValues.idCargo && (
                  <>
                    <InputCheckbox
                      checked={values.flagAtivo}
                      onChange={handleChange}
                      name="flagAtivo"
                      color="primary"
                      label="Inativar Cargo"
                    />
                    {values.flagAtivo && (
                      <InputDate
                        value={values.dataExtinto}
                        onChange={(date) => setFieldValue("dataExtinto", date)}
                        id="dataExtinto"
                        error={errors.dataExtinto ? true : false}
                        name="dataExtinto"
                        label="Data de extinção"
                        className="mt-2"
                        helperText={errors.dataExtinto}
                      />
                    )}
                  </>
                )}

                <SearchPosition
                  isOpen={isOpenSearchPosition}
                  onAdd={(value) => {
                    this.setState({
                      cargoSelected: value[0],
                      isOpenSearchPosition: false,
                    });
                    setFieldValue("idCargoSuperior", value[0].idCargo);
                  }}
                  onClose={() => this.setState({ isOpenSearchPosition: false })}
                />
              </form>
            )}
          </Formik>
        </Panel>

        <CustomDialog
          hidden={!isDialogExcludeOpen}
          onDismiss={() => this.setState({ isDialogExcludeOpen: false })}
          dialogContentProps={dialogContentProps}
        >
          <DefaultButton
            onClick={() => this.setState({ isDialogExcludeOpen: false })}
            text="Cancelar"
          />
          <DeleteButton onClick={() => this.excludePosition()} text="Excluir" />
        </CustomDialog>
        <Wrapper>
          <Drawer
            isOpen={filter.isOpen}
            content={
              <ContainerContent>
                <HeaderPage
                  title="Cargos"
                  leftItems={
                    <>
                      <CommandBarButton
                        styles={btnStyle}
                        iconProps={{ iconName: "Add" }}
                        disabled={positions.loadingData}
                        text="Adicionar Cargo"
                        onClick={() =>
                          this.setState({
                            isPanelOpen: true,
                            inicialValues: initialPosition,
                            cargoSelected: null,
                          })
                        }
                      />
                      {this.commandBarRender()}
                    </>
                  }
                  rightItems={
                    <>
                      <SearchBox
                        placeholder="Pesquisar"
                        value={search}
                        onChange={(e) => this.handleSearch(e)}
                        onKeyUp={(e) => this.filter(e.key)}
                        componentRef={this.inputSearch}
                        styles={{
                          root: { border: "none", width: 200, marginRight: 1 },
                        }}
                      />
                      <Toggle
                        checked={isToggleActive}
                        onText="Todos items"
                        offText="Items ativos"
                        onChange={this.handleToggle}
                        styles={{ root: { marginBottom: 0 } }}
                      />
                      <CommandBarButton
                        styles={btnStyle}
                        iconProps={{ iconName: "Refresh" }}
                        text="Atualizar"
                        onClick={() => getPositions()}
                      />
                    </>
                  }
                />
                {!positions.loadingData && positions.data.length === 0 ? (
                  <NoItems
                    error={positions.error}
                    text="Não há cargos cadastrados"
                    icon="Work"
                  />
                ) : (
                  <ListContainer>
                    <ShimmeredDetailsList
                      items={positions.data}
                      enableShimmer={positions.loadingData}
                      columns={columns}
                      selectionMode={SelectionMode.single}
                      selection={this._selection}
                      getKey={this._getKey}
                      selectionPreservedOnEmptyClick={true}
                      setKey="single"
                      layoutMode={DetailsListLayoutMode.justified}
                      isHeaderVisible={true}
                      onItemInvoked={this._onItemInvoked}
                    />
                  </ListContainer>
                )}
              </ContainerContent>
            }
          >
            <>
              <Stack horizontal horizontalAlign="space-between">
                <Text variant="xLarge">Filtros</Text>
                <Stack horizontal>
                  <IconButton
                    iconProps={{ iconName: "ChromeClose" }}
                    styles={{ root: { color: colors.darkGray } }}
                    title="Fechar"
                    ariaLabel="Fechar"
                    onClick={() =>
                      this.setState({ filter: { ...filter, isOpen: false } })
                    }
                  />
                </Stack>
              </Stack>
              <RadioGroup
                aria-label="gender"
                name="gender1"
                style={{ marginTop: 10 }}
                value={filter.ativadas}
                onChange={(e: any) => this.toggleParamsFilter(e.target.value)}
              >
                <Text variant="mediumPlus">Status</Text>
                <FormControlLabel
                  value="todos"
                  control={<Radio color="primary" />}
                  label="Todos"
                />
                <FormControlLabel
                  value="ativadas"
                  control={<Radio color="primary" />}
                  label="Ativadas"
                />
                <FormControlLabel
                  value="desativas"
                  control={<Radio color="primary" />}
                  label="Desativadas"
                />
              </RadioGroup>
            </>
          </Drawer>
        </Wrapper>
      </>
    );
  }

  private _onColumnClick = (
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn
  ): void => {
    const { columns } = this.state;
    const newColumns: IColumn[] = columns.slice();
    let items: PositionTypes[] = [];
    if (this.state.isFiltering) {
      items = this.state.items;
    } else {
      items = this.props.positions.data;
    }
    const currColumn: IColumn = newColumns.filter(
      (currCol) => column.key === currCol.key
    )[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    const newItems = this._sort(
      items,
      currColumn.fieldName!,
      currColumn.isSortedDescending
    );
    this.setState({
      columns: newColumns,
      items: newItems,
    });
  };

  private _getKey(item: any, index?: number): any {
    if (item !== undefined) return item.key;
  }

  private _sort<T>(
    items: T[],
    columnKey: string,
    isSortedDescending?: boolean
  ): T[] {
    const key = columnKey as keyof T;
    const itemsSorted = items.sort((a: T, b: T) =>
      (isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1
    );
    return itemsSorted;
  }
}

const IconButtonStyle: Partial<IButtonStyles> = {
  root: {
    position: "absolute",
    top: 20,
    right: 8,
    width: 24,
    height: 24,
    padding: 8,
  },
  icon: {
    fontSize: 12,
  },
};

const mapStateToProps = (state: RootState) => ({
  positions: state.positionsReducer,
  categories: state.categoriesPositionsReducer,
  standards: state.standardPositionReducer,
  carreiras: state.carreiraReducer,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      ...getPositions,
      ...getPositionById,
      ...addPosition,
      ...delPosition,
      ...editPosition,
      ...getCategoriesPositions,
      ...getStandardsPositions,
      ...getCarreiras,
      ...setCurrentPage,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Positions);
