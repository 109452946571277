import axios from "~/services/api";
import { takeLatest, put, call, all } from "redux-saga/effects";
import {
  Types,
  IRequestSanctionsLoadAction,
  SanctionType,
  IRequestSanctionDeleteAction,
  IRequestSanctionAddAction,
  IRequestSanctionEditAction,
  ISuccessSanctionsLoadAction,
  ISuccessSanctionDeleteAction,
} from "../../ducks/admin/sanctions/types";

const apiCallGet = () => {
  return axios
    .get(`admin/sancoes`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallPost = (item: SanctionType) => {
  return axios
    .post("admin/sancoes", {
      ...item,
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallPut = (item: SanctionType) => {
  return axios
    .put(`admin/sancoes/${item.idSancao}`, {
      ...item,
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallDel = (idSancao: number) => {
  return axios
    .delete(`admin/sancoes/${idSancao}`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

function* load(action: IRequestSanctionsLoadAction) {
  try {
    const items: SanctionType[] = yield call(apiCallGet);
    yield put({
      type: Types.SUCCESS_SANCTIONS_LOAD,
      payload: items,
    } as ISuccessSanctionsLoadAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível carregar as sanções.";
    yield put({ type: Types.FAILURE_SANCTIONS_LOAD, message });
  }
}

function* add(action: IRequestSanctionAddAction) {
  try {
    const idSancao: { idSancao: number } = yield call(
      apiCallPost,
      action.sanction
    );
    yield put({
      type: Types.SUCCESS_SANCTION_ADD,
      sanction: { ...action.sanction, ...idSancao, flagAtivo: true },
    });
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível adicionar sanção";
    yield put({ type: Types.FAILURE_SANCTION_ADD, message });
  }
}

function* edit(action: IRequestSanctionEditAction) {
  try {
    yield call(apiCallPut, action.sanction);
    yield put({ type: Types.SUCCESS_SANCTION_EDIT, sanction: action.sanction });
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível editar sanção";
    yield put({ type: Types.FAILURE_SANCTION_EDIT, message });
  }
}

function* del(action: IRequestSanctionDeleteAction) {
  try {
    yield call(apiCallDel, action.idSancao);
    yield put({
      type: Types.SUCCESS_SANCTION_DELETE,
      idSancao: action.idSancao,
    } as ISuccessSanctionDeleteAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível excluir sanção";
    yield put({ type: Types.FAILURE_SANCTION_DELETE, message });
  }
}

export default function* positionsRoot() {
  yield all([
    yield takeLatest(Types.REQUEST_SANCTIONS_LOAD, load),
    yield takeLatest(Types.REQUEST_SANCTION_ADD, add),
    yield takeLatest(Types.REQUEST_SANCTION_EDIT, edit),
    yield takeLatest(Types.REQUEST_SANCTION_DELETE, del),
  ]);
}
