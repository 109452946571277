import {
  Types,
  DataTypes,
  Peso,
  DismembermentActionTypes,
  DismembermentItemType,
  DismembermentType,
  DismembermentChildrenType,
  ISuccessDismembermentAddInheritedAreaAction,
  ISuccessDismembermentEditMetaAction,
  ISuccessDismembermentAddMetaAction,
  ISuccessDismembermentDeleteMetaAction,
  IDismembermentAddDuplicateAreaAction,
} from "./types";
import { PesoType, MetaType } from "../painel/types";

import { ObjectivesType } from "../../objectives/types";
import customToast from "~/components/Toast/index";

/**
 * Reducers
 */

const initialData: DismembermentType = {
  area: {
    idAreaPai: 0,
    idArea: 0,
    nomeLocal: "",
  },
  respPesos: [],
};

const INITIAL_STATE: DataTypes = {
  data: initialData,
  metaSelected: {
    data: null,
    error: false,
    loading: false,
    success: false,
  },
  success: false,
  successAction: false,
  loadingData: false,
  loadingAction: false,
  loadingChildren: false,
  areaSelected: null,
  idCicloPesoChildrenLoading: null,
  idCicloPesoChildrenError: null,
};

/**
 * Actions
 */

export const Creators = {
  getDismemberment: (
    idCiclo: number,
    idArea: number,
    nomeLocal?: string
  ): DismembermentActionTypes => ({
    type: Types.REQUEST_DISMEMBERMENT_LOAD,
    payload: {
      idCiclo,
      idArea,
      nomeLocal,
    },
  }),

  getDismembermentChildren: (
    idCiclo: number,
    idArea: number,
    idCicloPeso: number
  ): DismembermentActionTypes => ({
    type: Types.REQUEST_DISMEMBERMENT_CHILDREN_LOAD,
    payload: {
      idCiclo,
      idArea,
      idCicloPeso,
    },
  }),

  addPesoDismemberment: (
    idCiclo: number,
    idObjetivo: number,
    peso: Partial<PesoType>,
    idCicloPeso: number
  ): DismembermentActionTypes => ({
    type: Types.REQUEST_DISMEMBERMENT_ADD_PESO,
    payload: {
      idCiclo,
      idObjetivo,
      peso,
      idCicloPeso,
    },
  }),

  editPesoDismemberment: (
    idCiclo: number,
    idObjetivo: number,
    idCicloPeso: number,
    peso: Partial<PesoType>
  ): DismembermentActionTypes => ({
    type: Types.REQUEST_DISMEMBERMENT_EDIT_PESO,
    payload: {
      idCiclo,
      idCicloPeso,
      idObjetivo,
      peso,
    },
  }),

  deletePesoDismemberment: (
    idCiclo: number,
    peso: Partial<PesoType>
  ): DismembermentActionTypes => ({
    type: Types.REQUEST_DISMEMBERMENT_DELETE_PESO,
    payload: {
      idCiclo,
      idCicloPesoPai: peso.idCicloPesoPai!,
      idCicloPeso: peso.idCicloPeso!,
      idObjetivo: peso.idObjetivo!,
      idArea: peso.idArea!,
    },
  }),

  addMetaDismemberment: (
    idCiclo: number,
    itemPeso: Partial<PesoType>,
    meta: Partial<MetaType>
  ): DismembermentActionTypes => {
    return {
      type: Types.REQUEST_DISMEMBERMENT_ADD_META,
      payload: {
        idCiclo,
        idArea: itemPeso.idArea!,
        idCicloPesoPai: itemPeso.idCicloPesoPai!,
        idObjetivo: itemPeso.idObjetivo!,
        meta,
      },
    };
  },

  editMetaDismemberment: (
    idCiclo: number,
    idCicloMeta: number,
    itemPeso: Partial<PesoType>,
    meta: Partial<MetaType>
  ): DismembermentActionTypes => ({
    type: Types.REQUEST_DISMEMBERMENT_EDIT_META,
    payload: {
      idCiclo,
      idCicloMeta,
      idArea: itemPeso.idArea!,
      idCicloPesoPai: itemPeso.idCicloPesoPai!,
      idObjetivo: itemPeso.idObjetivo!,
      meta,
    },
  }),

  deleteMetaDismemberment: (
    idCiclo: number,
    itemPeso: Partial<PesoType>,
    idCicloMeta: number
  ): DismembermentActionTypes => {
    return {
      type: Types.REQUEST_DISMEMBERMENT_DELETE_META,
      payload: {
        idCiclo,
        idCicloMeta,
        idArea: itemPeso.idArea!,
        idCicloPesoPai: itemPeso.idCicloPesoPai!,
        idObjetivo: itemPeso.idObjetivo!,
      },
    };
  },

  addInheritedArea: (
    idCiclo: number,
    idArea: number,
    parentArea: DismembermentItemType,
    objective?: Partial<ObjectivesType>
  ): DismembermentActionTypes => ({
    type: Types.REQUEST_DISMEMBERMENT_ADD_INHERITED_AREA,
    payload: {
      idCiclo,
      idArea,
      parentArea,
      objective,
    },
  }),

  addDuplicateArea: (
    idCicloPesoPai: number,
    idArea: number
  ): DismembermentActionTypes => ({
    type: Types.ADD_DISMEMBERMENT_DUPLICATE_AREA,
    payload: {
      idCicloPesoPai,
      idArea,
    },
  }),

  delInheritedArea: (
    idCicloPesoPai: number,
    idArea: number,
    idObjetivo: number
  ): DismembermentActionTypes => ({
    type: Types.DELETE_DISMEMBERMENT_INHERITED_AREA,
    payload: {
      idCicloPesoPai,
      idArea,
      idObjetivo,
    },
  }),

  getMetaById: (
    idCiclo: number,
    idCicloMeta: number
  ): DismembermentActionTypes => ({
    type: Types.REQUEST_DISMEMBERMENT_META_LOAD_BY_ID,
    payload: {
      idCiclo,
      idCicloMeta,
    },
  }),

  clearDismemberment: () => ({
    type: Types.CLEAR_DISMEMBERMENT,
  }),
};

export default function cycleDismembermentReducer(
  state = INITIAL_STATE,
  action: DismembermentActionTypes
): DataTypes {
  switch (action.type) {
    // REQUEST_DISMEMBERMENT_LOAD
    case Types.REQUEST_DISMEMBERMENT_LOAD:
      return {
        ...state,
        success: false,
        loadingData: true,
      };
    case Types.SUCCESS_DISMEMBERMENT_LOAD:
      return {
        ...state,
        data: action.payload.data,
        success: true,
        loadingData: false,
        areaSelected: {
          idArea: action.payload.idArea,
          nomeLocal: action.payload.nomeLocal,
        },
      };
    case Types.FAILURE_DISMEMBERMENT_LOAD:
      customToast.error(action.message);
      return {
        ...state,
        success: false,
        loadingData: false,
      };

    // REQUEST DISMEMBERMENT CHILDREN LOAD

    case Types.REQUEST_DISMEMBERMENT_CHILDREN_LOAD:
      return {
        ...state,
        loadingChildren: true,
        idCicloPesoChildrenLoading: action.payload.idCicloPeso,
        idCicloPesoChildrenError: null,
      };
    case Types.SUCCESS_DISMEMBERMENT_CHILDREN_LOAD:
      return {
        ...state,
        loadingChildren: false,
        idCicloPesoChildrenLoading: null,
        data: handleChildrenDismemberment(
          state.data,
          action.payload.vFilhos,
          action.payload.idCicloPeso
        ),
        idCicloPesoChildrenError: null,
      };

    case Types.FAILURE_DISMEMBERMENT_CHILDREN_LOAD:
      customToast.error(action.payload.message);
      return {
        ...state,
        loadingChildren: false,
        idCicloPesoChildrenLoading: null,
        idCicloPesoChildrenError: action.payload.idCicloPeso,
      };

    //DISMEMBERMENT_ADD_INHERITED_AREA

    case Types.REQUEST_DISMEMBERMENT_ADD_INHERITED_AREA:
      return state;

    case Types.SUCCESS_DISMEMBERMENT_ADD_INHERITED_AREA:
      return {
        ...state,
        data: addInheritedArea(state.data, action.payload),
      };

    case Types.FAILURE_DISMEMBERMENT_ADD_INHERITED_AREA:
      customToast.error(action.message);
      return state;

    // REQUEST_DISMEMBERMENT_ADD_PESO

    case Types.REQUEST_DISMEMBERMENT_ADD_PESO:
      return {
        ...state,
        successAction: false,
        loadingAction: true,
      };
    case Types.SUCCESS_DISMEMBERMENT_ADD_PESO:
      return {
        ...state,
        data: handleDismembermentPeso(
          state.data,
          action.payload.newPeso,
          action.payload.idCicloPesoPai,
          action.payload.idArea,
          action.payload.idObjetivo
        ),
        successAction: true,
        loadingAction: false,
      };
    case Types.FAILURE_DISMEMBERMENT_ADD_PESO:
      customToast.error(action.message);
      return {
        ...state,
        successAction: false,
        loadingAction: false,
      };

    //REQUEST_DISMEMBERMENT_EDIT_PESO

    case Types.REQUEST_DISMEMBERMENT_EDIT_PESO:
      return {
        ...state,
        successAction: false,
        loadingAction: true,
      };
    case Types.SUCCESS_DISMEMBERMENT_EDIT_PESO:
      return {
        ...state,
        data: handleDismembermentPeso(
          state.data,
          action.payload.newPeso,
          action.payload.idCicloPesoPai,
          action.payload.idArea,
          action.payload.idObjetivo
        ),
        successAction: true,
        loadingAction: false,
      };
    case Types.FAILURE_DISMEMBERMENT_EDIT_PESO:
      customToast.error(action.message);
      return {
        ...state,
        successAction: false,
        loadingAction: false,
      };

    //REQUEST_DISMEMBERMENT_DELETE_PESO

    case Types.REQUEST_DISMEMBERMENT_DELETE_PESO:
      return {
        ...state,
        successAction: false,
        loadingAction: true,
      };
    case Types.SUCCESS_DISMEMBERMENT_DELETE_PESO:
      return {
        ...state,
        data: deleteDismembermentPeso(
          state.data,
          action.payload.idCicloPesoPai,
          action.payload.idArea,
          action.payload.idObjetivo
        ),
        successAction: true,
        loadingAction: false,
      };
    case Types.FAILURE_DISMEMBERMENT_DELETE_PESO:
      customToast.error(action.message);
      return {
        ...state,
        successAction: false,
        loadingAction: false,
      };

    //ADD META DISMEMBERMENT
    case Types.REQUEST_DISMEMBERMENT_ADD_META:
      return {
        ...state,
        successAction: false,
        loadingAction: true,
      };
    case Types.SUCCESS_DISMEMBERMENT_ADD_META:
      return {
        ...state,
        data: handleDismembermentMeta(state.data, action.payload),
        successAction: true,
        loadingAction: false,
      };
    case Types.FAILURE_DISMEMBERMENT_ADD_META:
      customToast.error(action.message);
      return {
        ...state,
        successAction: false,
        loadingAction: false,
      };

    //EDIT META DISMEMBERMENT
    case Types.REQUEST_DISMEMBERMENT_EDIT_META:
      return {
        ...state,
        successAction: false,
        loadingAction: true,
      };
    case Types.SUCCESS_DISMEMBERMENT_EDIT_META:
      return {
        ...state,
        data: handleDismembermentMeta(state.data, action.payload),
        successAction: true,
        loadingAction: false,
      };
    case Types.FAILURE_DISMEMBERMENT_EDIT_META:
      customToast.error(action.message);
      return {
        ...state,
        successAction: false,
        loadingAction: false,
      };

    //DELETE META DISMEMBERMENT
    case Types.REQUEST_DISMEMBERMENT_DELETE_META:
      return {
        ...state,
        successAction: false,
        loadingAction: true,
      };
    case Types.SUCCESS_DISMEMBERMENT_DELETE_META:
      return {
        ...state,
        data: deleteDismembermentMeta(state.data, action.payload),
        successAction: true,
        loadingAction: false,
      };
    case Types.FAILURE_DISMEMBERMENT_DELETE_META:
      customToast.error(action.message);
      return {
        ...state,
        successAction: false,
        loadingAction: false,
      };

    //DELETE_DISMEMBERMENT_INHERITED_AREA
    case Types.DELETE_DISMEMBERMENT_INHERITED_AREA:
      return {
        ...state,
        data: delInheritedArea(
          state.data,
          action.payload.idCicloPesoPai,
          action.payload.idArea,
          action.payload.idObjetivo
        ),
      };

    //ADD_DISMEMBERMENT_INHERITED_AREA
    case Types.ADD_DISMEMBERMENT_DUPLICATE_AREA:
      return {
        ...state,
        data: addDuplicateArea(state.data, action.payload),
      };

    case Types.CLEAR_DISMEMBERMENT:
      return {
        ...state,
        data: initialData,
        success: false,
      };

    // REQUEST_CYCLES_META_LOAD_BY_ID

    case Types.REQUEST_DISMEMBERMENT_META_LOAD_BY_ID:
      return {
        ...state,
        metaSelected: {
          data: null,
          error: false,
          success: false,
          loading: true,
        },
        successAction: false,
      };
    case Types.SUCCESS_DISMEMBERMENT_META_LOAD_BY_ID:
      return {
        ...state,
        metaSelected: {
          data: action.payload,
          error: false,
          success: true,
          loading: false,
        },
      };
    case Types.FAILURE_DISMEMBERMENT_META_LOAD_BY_ID:
      customToast.error(action.message);
      return {
        ...state,
        metaSelected: {
          data: null,
          error: true,
          success: false,
          loading: false,
        },
      };
    // case Types.CYCLES_META_EDIT_MONTH:
    //   const meses: MetaType["meses"] = action.payload.allMonths.map((mes) => {
    //     if (action.payload.month.mes === mes.mes) {
    //       return action.payload.month;
    //     } else {
    //       return mes;
    //     }
    //   });

    //   return {
    //     ...state,
    //     metaSelected:{
    //         ...state.metaSelected,
    //         data:{

    //         }
    //     }
    //     data: {
    //       ...state.data,

    //       metaSelected: {
    //         ...state.data.metaSelected!,
    //         meses: meses,
    //       },
    //     },
    //   };

    default:
      return state;
  }
}

const handleChildrenDismemberment = (
  data: DismembermentType,
  children: DismembermentChildrenType[],
  idCicloPeso: number
): DismembermentType => {
  const newRespPesos: DismembermentItemType[] = data.respPesos.map((item) => {
    if (item.idCicloPeso !== idCicloPeso) {
      return item;
    } else {
      return { ...item, vFilhos: children };
    }
  });

  return {
    area: data.area,
    respPesos: newRespPesos,
  };
};

const addInheritedArea = (
  data: DismembermentType,
  payload: ISuccessDismembermentAddInheritedAreaAction["payload"]
): DismembermentType => {
  const {
    idObjetivo,
    descObjetivo = "",
    mecanicaCalculo = "",
    unidade = "",
    descricaoMecanica = "",
    nivel = "",
  } = payload.objective || {};
  const { meta, idObjetivo: idObjetivoParent } = payload.parentArea;
  const { maximo = "", target = "", minimo = "", avaliacao, flagInativo } =
    meta[0] || {};

  const newRespPesos: DismembermentItemType[] = data.respPesos.map((item) => {
    if (item.idCicloPeso !== payload.parentArea.idCicloPeso) {
      return item;
    } else {
      const itemAlreadyAdded = item.vFilhos.find(
        (item) =>
          item.idArea === payload.idArea &&
          item.objetivo?.idObjetivo === idObjetivo
      );
      return {
        ...item,
        vFilhos: item.vFilhos.map((filho) => {
          if (filho.idArea !== payload.idArea) {
            return filho;
          } else {
            if (
              filho.objetivo?.idObjetivo === 0 ||
              filho.objetivo?.idObjetivo === undefined
            ) {
              return {
                ...filho,
                meta: {
                  ...filho.meta,
                  idCicloMeta: payload.objective
                    ? payload.meta.idCicloMeta
                    : payload.parentArea.meta[0]
                    ? payload.parentArea.meta[0].idCicloMeta
                    : 0,
                  // payload.parentArea.meta[0] ? payload.parentArea.meta[0].idCicloMeta : 0,
                  // idObjetivo: idObjetivo ? idObjetivo : payload.parentArea.idObjetivo,
                  maximo: payload.objective ? payload.meta.maximo : maximo,
                  target: payload.objective ? payload.meta.target : target,
                  minimo: payload.objective ? payload.meta.minimo : minimo,
                  avaliacao: payload.objective
                    ? payload.meta.avaliacao
                    : avaliacao,
                  flagInativo: payload.objective
                    ? payload.meta.flagInativo
                    : flagInativo,
                },
                peso: {
                  idCicloPeso: itemAlreadyAdded
                    ? itemAlreadyAdded.peso.idCicloPeso
                    : 0,
                  idObjetivo: payload.objective
                    ? idObjetivo!
                    : idObjetivoParent,
                  peso: itemAlreadyAdded ? itemAlreadyAdded.peso.peso : "",
                  flagInativo: itemAlreadyAdded
                    ? itemAlreadyAdded.peso.flagInativo
                    : false,
                },
                objetivo: {
                  idObjetivo: idObjetivo ? idObjetivo! : idObjetivoParent,
                  descObjetivo: descObjetivo
                    ? descObjetivo
                    : payload.parentArea.objetivo.descObjetivo,
                  mecanicaCalculo: mecanicaCalculo
                    ? mecanicaCalculo
                    : payload.parentArea.objetivo.mecanicaCalculo,
                  unidade: unidade
                    ? unidade
                    : payload.parentArea.objetivo.unidade,
                  descricaoMecanica: descricaoMecanica
                    ? descricaoMecanica
                    : payload.parentArea.objetivo.descricaoMecanica,
                  nivel: nivel ? nivel : payload.parentArea.objetivo.nivel,
                  codObjetivo: "",
                },
              };
            } else {
              return filho;
            }
          }
        }),
      };
    }
  });

  return {
    area: data.area,
    respPesos: newRespPesos,
  };
};

const delInheritedArea = (
  data: DismembermentType,
  idCicloPesoPai: number,
  idArea: number,
  idObjetivo: number
): DismembermentType => {
  const newRespPesos: DismembermentItemType[] = data.respPesos.map((item) => {
    if (item.idCicloPeso !== idCicloPesoPai) {
      return item;
    } else {
      return {
        ...item,
        vFilhos: item.vFilhos
          .filter((filho) => {
            if (filho.idArea !== idArea) {
              return filho;
            } else {
              if (filho.objetivo?.idObjetivo !== idObjetivo) {
                return filho;
              } else {
                const hasBlankArea = item.vFilhos.some(
                  (filho) => filho.objetivo?.idObjetivo === 0
                );
                const hasMultipleIdAreaItem = item.vFilhos.filter(
                  (filho) => filho.idArea === idArea
                );
                if (!hasBlankArea && hasMultipleIdAreaItem.length <= 1) {
                  return filho;
                }
              }
            }
          })
          .map((item) => {
            if (item.idArea !== idArea) {
              return item;
            } else if (item.objetivo?.idObjetivo !== idObjetivo) {
              return item;
            } else {
              return {
                apelido: item.apelido,
                idArea: item.idArea,
                idCicloPeso: item.idCicloPeso,
                nomeLocal: item.nomeLocal,
                meta: {
                  ...item.meta,
                  idCicloMeta: 0,
                  maximo: "",
                  target: "",
                  minimo: "",
                },
                peso: {
                  idCicloPeso: 0,
                  idObjetivo: 0,
                  peso: "",
                  flagInativo: false,
                },
              };
            }
          }),
      };
    }
  });

  return {
    area: data.area,
    respPesos: newRespPesos,
  };
};

const handleDismembermentPeso = (
  data: DismembermentType,
  newPeso: Peso,
  idCicloPesoPai: number,
  idArea: number,
  idObjetivo: number
): DismembermentType => {
  let isPai = false;

  const newRespPesos: DismembermentItemType[] = data.respPesos.map((item) => {
    if (item.idCicloPeso !== idCicloPesoPai) {
      return item;
    } else {
      if (
        item.idObjetivo === newPeso.idObjetivo &&
        item.idCicloPeso === newPeso.idCicloPeso
      ) {
        item.peso = String(newPeso.peso);
        item.flagInativo = newPeso.flagInativo;
        isPai = true;
      }
      if (item.vFilhos) {
        item.vFilhos = item.vFilhos.map((filho) => {
          if (filho.idArea !== idArea) {
            if (isPai) {
              return {
                ...filho,
                peso: {
                  ...filho.peso,
                  flagInativo: newPeso.flagInativo,
                },
              };
            } else {
              return filho;
            }
          } else {
            if (filho.objetivo?.idObjetivo === idObjetivo) {
              return {
                ...filho,
                objetivo: filho.objetivo,
                peso: {
                  idCicloPeso: newPeso.idCicloPeso,
                  idObjetivo: newPeso.idObjetivo,
                  peso: String(newPeso.peso),
                  flagInativo: newPeso.flagInativo,
                },
              };
            } else {
              if (isPai) {
                return {
                  ...filho,
                  peso: {
                    ...filho.peso,
                    flagInativo: newPeso.flagInativo,
                  },
                };
              } else {
                return filho;
              }
            }
          }
        });
      }
      return item;
    }
  });

  return {
    area: data.area,
    respPesos: newRespPesos,
  };
};

const deleteDismembermentPeso = (
  data: DismembermentType,
  idCicloPesoPai: number,
  idArea: number,
  idObjetivo: number
): DismembermentType => {
  const newRespPesos: DismembermentItemType[] = data.respPesos.map((item) => {
    if (item.idCicloPeso !== idCicloPesoPai) {
      return item;
    } else {
      if (item.idArea === idArea) {
        item.peso = "";
      }
      if (item.vFilhos) {
        item.vFilhos = item.vFilhos.map((filho) => {
          if (filho.idArea !== idArea) {
            return filho;
          } else {
            if (filho.objetivo?.idObjetivo === idObjetivo) {
              return {
                ...filho,
                peso: {
                  ...filho.peso,
                  idCicloPeso: 0,
                  peso: "",
                },
              };
            } else {
              return filho;
            }
          }
        });
      }
      return item;
    }
  });

  return {
    area: data.area,
    respPesos: newRespPesos,
  };
};

const handleDismembermentMeta = (
  data: DismembermentType,
  payload:
    | ISuccessDismembermentEditMetaAction["payload"]
    | ISuccessDismembermentAddMetaAction["payload"]
): DismembermentType => {
  const newRespPesos: DismembermentItemType[] = data.respPesos.map((item) => {
    if (item.idCicloPeso !== payload.idCicloPesoPai) {
      return item;
    } else {
      if (item.idObjetivo === payload.idObjetivo) {
        item.meta[0] = payload.meta;
      }
      if (item.vFilhos) {
        item.vFilhos = item.vFilhos.map((filho) => {
          if (filho.objetivo?.idObjetivo !== payload.idObjetivo) {
            return filho;
          } else {
            return {
              ...filho,
              objetivo: filho.objetivo,
              meta: payload.meta,
            };
          }
        });
      }
      return item;
    }
  });

  return {
    area: data.area,
    respPesos: newRespPesos,
  };
};

const deleteDismembermentMeta = (
  data: DismembermentType,
  payload: ISuccessDismembermentDeleteMetaAction["payload"]
): DismembermentType => {
  const newRespPesos: DismembermentItemType[] = data.respPesos.map((item) => {
    if (item.idCicloPeso !== payload.idCicloPesoPai) {
      return item;
    } else {
      if (item.idObjetivo === payload.idObjetivo) {
        item.meta[0] = {
          idCicloMeta: 0,
          maximo: "",
          target: "",
          minimo: "",
          avaliacao: null,
          flagInativo: null,
        };
      }

      if (item.vFilhos) {
        return {
          ...item,
          vFilhos: item?.vFilhos?.map((filho) => {
            if (filho.objetivo?.idObjetivo !== payload.idObjetivo) {
              return filho;
            } else {
              return {
                ...filho,
                objetivo: filho.objetivo,
                meta: {
                  ...filho.meta,
                  idCicloMeta: 0,
                  maximo: "",
                  target: "",
                  minimo: "",
                },
              };
            }
          }),
        };
      }

      return item;
    }
  });

  return {
    area: data.area,
    respPesos: newRespPesos,
  };
};

const addDuplicateArea = (
  data: DismembermentType,
  payload: IDismembermentAddDuplicateAreaAction["payload"]
): DismembermentType => {
  let index = 0;
  let newItem: DismembermentChildrenType;

  data.respPesos.forEach((item) => {
    if (item.idCicloPeso === payload.idCicloPesoPai) {
      item.vFilhos.forEach((filho, i) => {
        if (filho.idArea === payload.idArea) {
          index = i;
          newItem = {
            ...filho,
            meta: {
              idCicloMeta: 0,
              maximo: "",
              target: "",
              minimo: "",
              avaliacao: null,
              flagInativo: null,
            },
            peso: {
              idCicloPeso: 0,
              idObjetivo: 0,
              peso: "",
              flagInativo: false,
            },
            objetivo: {
              idObjetivo: 0,
              descObjetivo: "",
              mecanicaCalculo: "",
              unidade: "",
              descricaoMecanica: "",
              nivel: "",
              codObjetivo: "",
            },
          };
        }
      });

      item.vFilhos.splice(index + 1, 0, newItem);
    }
  });

  return data;
};
