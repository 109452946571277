import styled from "styled-components";
import {
  DefaultButton as DefaultBtn,
} from "office-ui-fabric-react";

export const Wrapper = styled.div`
  height: calc(100vh - 50px);
  width: calc(100% - 250px);
  flex-basis: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

export const ContainerContent = styled.div`
  padding: 20px;
  height: calc(100% - 50px);
`

export const ListContainer = styled.div`
  width: 100%;
  height: calc(100% - 82px);
  overflow-y: auto;
`;

export const DefaultButton = styled(DefaultBtn)`
  width: 110px;
`
export const WrapperAutoComplete = styled.div`
  position: relative;
  width: 100%;
  .MuiAutocomplete-inputRoot  .MuiAutocomplete-endAdornment{
    right: 35px;
  }
`
export const GroupCheckbox = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`