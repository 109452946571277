import styled from "styled-components";

export const Container = styled.div<{width: number}>`
  height: 100vh;
  width: ${props => props.width}px;
  box-sizing: border-box;
`;

export const Footer = styled.div`
  position: fixed;
  bottom: 30px;
  right: 30px;
  display: flex;
  width: 440px;
  justify-content: flex-end;
`;

export const WrapperContent = styled.div`
  height: calc(100vh - 190px);
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 30px;
`