import styled from "styled-components";
import Colors from "~/assets/js/colors";

export const Wrapper = styled.div`
  height: calc(100vh - 50px);
  width: calc(100% - 250px);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
  flex-basis: 100%;
  a{
    text-decoration: none;
    color: #323130;
  }
`;

export const WrapperLink = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 110px;
  height: 85px;
  padding: 15px;
  border-radius: 2px;
  cursor: pointer;
  &:hover{
    background-color: #fafafa;
  }
`

export const WrapperLinkText = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`

export const WrapperImg = styled.div`
  margin-top: 15px;
  height: 450px;
`

export const WelcomeImg = styled.img`
  height: 100%;
`

export const RedSpan = styled.span`
  color: ${Colors.error};
  font-weight: 600;
`