import React from "react";

import MuiAccordion, { AccordionProps } from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import { withStyles } from "@material-ui/core/styles";
import { FontIcon, ITextStyles, Stack, Text } from "office-ui-fabric-react";

import { Subtitle } from "./styles";

interface IAccordionProps extends AccordionProps {
  title: string;
  subtitle?: string;
  styles?: {
    details?: React.CSSProperties;
  };
}

const Accordion: React.FC<IAccordionProps> = ({
  title,
  subtitle,
  styles,
  children,
  ...props
}) => {
  return (
    <CustomAccordion defaultExpanded={true} {...props}>
      <AccordionSummary
        expandIcon={
          <FontIcon iconName="ChevronDown" style={{ fontSize: 16 }} />
        }
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Text variant="mediumPlus" styles={textStyles}>
          {title}
        </Text>
      </AccordionSummary>
      <AccordionDetails
        style={{
          ...styles?.details,
          padding: "0 16px",
          marginTop: 15,
          marginBottom: 20,
        }}
      >
        <Stack
          styles={{ root: { height: "-webkit-fill-available", width: "100%" } }}
        >
          {!!subtitle && <Subtitle>{subtitle}</Subtitle>}
          {children}
        </Stack>
      </AccordionDetails>
    </CustomAccordion>
  );
};

const CustomAccordion = withStyles({
  root: {
    padding: "0px !important",
    paddingBottom: 10,
    boxShadow: "none",
    display: "contents",

    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {
    borderBottom: 0,
  },
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    borderBottom: "1px solid #CFD3DA",
    height: 50,
    minHeight: "50px !important",
    "&:hover": {
      backgroundColor: "#F3F2F1",
    },
    expanded: {},
  },
})(MuiAccordionSummary);

const textStyles: ITextStyles = {
  root: {
    fontWeight: 500,
    fontSize: 16,
    display: "block",
  },
};

export default Accordion;
