import { takeEvery, put, call, all } from "redux-saga/effects";
import {
  Types,
  IRequestBonusLoadAction,
  ISuccessBonusLoadAction,
  BonusGenerateType,
  IRequestBonusAddAction,
  ISuccessBonusAddAction,
  IRequestBonusDelAction,
  ISuccessBonusDelAction,
} from "../ducks/bonusGenerate/types";
import api from "~/services/api";

const apiCallLoad = () => {
  return api
    .get(`bonus`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err.response;
    });
};

const apiCallAdd = (bonus: IRequestBonusAddAction["bonus"]) => {
  return api
    .post(`bonus`, {
      ...bonus,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err.response;
    });
};

const apiCallDel = (idBonus: number) => {
  return api
    .delete(`bonus/${idBonus}`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err.response;
    });
};

function* load(action: IRequestBonusLoadAction) {
  try {
    const data: BonusGenerateType[] = yield call(apiCallLoad);
    yield put({
      type: Types.SUCCESS_BONUS_LOAD,
      payload: data,
    } as ISuccessBonusLoadAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível carregar os bônus";
    yield put({
      type: Types.FAILURE_BONUS_LOAD,
      message,
    });
  }
}

function* add(action: IRequestBonusAddAction) {
  try {
    const bonus: { idBonus: number, statusbonus: string } = yield call(apiCallAdd, action.bonus);
    yield put({
      type: Types.SUCCESS_BONUS_ADD,
      payload: {
        ...action.bonus,
        ...bonus,
      },
    } as ISuccessBonusAddAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível gerar o bônus";
    yield put({
      type: Types.FAILURE_BONUS_ADD,
      message,
    });
  }
}

function* del(action: IRequestBonusDelAction) {
  try {
    yield call(apiCallDel, action.idBonus);
    yield put({
      type: Types.SUCCESS_BONUS_DEL,
      idBonus: action.idBonus
    } as ISuccessBonusDelAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível excluír o bônus";
    yield put({
      type: Types.FAILURE_BONUS_DEL,
      message,
    });
  }
}

export default function* bonusGenerateRoot() {
  yield all([
    yield takeEvery(Types.REQUEST_BONUS_LOAD, load),
    yield takeEvery(Types.REQUEST_BONUS_ADD, add),
    yield takeEvery(Types.REQUEST_BONUS_DEL, del)
  ])
}
