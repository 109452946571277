import { Types, DataTypes, StepActionTypes, StepType } from "./types";
import customToast from "~/components/Toast/index";

/**
 * Reducers
 */
const INITIAL_STATE: DataTypes = {
    data: [],
    itemSelected: null,
    error: false,
    loadingData: false,
    loadingAction: false,
    success: false,
};

/**
 * Actions
 */

export const Creators = {
    getSteps: (): StepActionTypes => {
        return {
            type: Types.REQUEST_STEP_LOAD,
        }
    },

    addStep: (payload: StepType): StepActionTypes => {
        return {
            type: Types.REQUEST_STEP_ADD,
            payload
        }
    },

    delStep: (idStep: number): StepActionTypes => {
        return {
            type: Types.REQUEST_STEP_DEL,
            idStep
        }
    },
}


export default function salaryStructureReducer(state = INITIAL_STATE, action: StepActionTypes): DataTypes {

    switch (action.type) {

        //LOAD
        case Types.REQUEST_STEP_LOAD:
            return { ...state, loadingData: true, error: false, success: false };
        case Types.SUCCESS_STEP_LOAD:
            return { ...state, data: action.payload, loadingData: false, error: false, success: true };
        case Types.FAILURE_STEP_LOAD:
            customToast.error(action.message);
            return { ...state, data: [], loadingData: false, error: true, success: false };

        //ADD
        case Types.REQUEST_STEP_ADD:
            return { ...state, loadingAction: true, error: false, success: false };
        case Types.SUCCESS_STEP_ADD:
            return { ...state, data: [...state.data, action.payload].sort((a,b) => Number(a.percent) - Number(b.percent)), loadingAction: false, error: false, success: true };
        case Types.FAILURE_STEP_ADD:
            customToast.error(action.message);
            return { ...state, loadingAction: false, error: true, success: false };

        //DEL
        case Types.REQUEST_STEP_DEL:
            return { ...state, loadingAction: true, error: false, success: false };
        case Types.SUCCESS_STEP_DEL:
            return { ...state, data: state.data.filter(item => item.idStep !== action.idStep), loadingAction: false, error: false, success: true };
        case Types.FAILURE_STEP_DEL:
            customToast.error(action.message);
            return { ...state, loadingAction: false, error: true, success: false };

        default:
            return state;
    }
}