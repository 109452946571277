import React, { useCallback } from 'react';
import currency from "currency.js";
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { ptBR } from 'date-fns/locale';

import { TextFieldProps } from '@material-ui/core/TextField';
import { InputContainer, Input } from './styles';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardDatePickerProps,
  KeyboardTimePickerProps,
  KeyboardTimePicker
} from '@material-ui/pickers';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { FontIcon } from 'office-ui-fabric-react/lib/Icon';
import Autocomplete, { AutocompleteProps } from '@material-ui/lab/Autocomplete';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';

interface IInputContainerProps {
  styles?: React.CSSProperties;
  height_container?: number | string;
  min_height_container?: number | string;
  smaller?: "small";
  hasinnerspin?: boolean;
}

const InputText: React.FC<TextFieldProps & IInputContainerProps> = (props) => {
  return (
    <InputContainer
      fixed
      style={props.styles}
      heightcontainer={props.height_container}
      min_height_container={props.min_height_container}
    >
      <Input
        {...props}
        smaller={props.smaller}
        size={props.smaller ? 'small' : props.size ? props.size : undefined}
        heightcontainer={props.height_container}
        hasinnerspin={props.hasinnerspin ? "true" : ""}
        className="mt-2"
      />
    </InputContainer>
  )
}

const InputNumber: React.FC<NumberFormatProps & IInputContainerProps> = (props) => {
  const ref: any = React.useRef();

  return <NumberFormat
    {...props}
    ref={ref}
    thousandSeparator={'.'}
    decimalSeparator={','}
    decimalScale={props.decimalScale !== undefined ? props.decimalScale : 2}
    customInput={InputText}
    fixedDecimalScale={true}
    onFocus={(e) => {
      e.target.select();
      props.onFocus?.(e)
    }}
  />
}


const InputDate: React.FC<KeyboardDatePickerProps> = (props) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR} >
      <KeyboardDatePicker
        {...props}
        autoOk
        disableToolbar
        variant="inline"
        style={{ width: '100%', height: 70 }}
        value={props.value}
        format="dd/MM/yyyy"
        keyboardIcon={<FontIcon iconName="Calendar" style={{ fontSize: 20 }} />}
        KeyboardButtonProps={{
          "aria-label": "change date"
        }}
      />
    </MuiPickersUtilsProvider>
  );
}

const InputDateDialog: React.FC<KeyboardDatePickerProps> = (props) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR} >
      <KeyboardDatePicker
        {...props}
        style={{ width: '100%', height: 70 }}
        value={props.value}
        format="dd/MM/yyyy"
        cancelLabel="Cancelar"
        keyboardIcon={<FontIcon iconName="Calendar" style={{ fontSize: 20 }} />}
        KeyboardButtonProps={{
          "aria-label": "change date"
        }}
      />
    </MuiPickersUtilsProvider>
  );
}

const InputTimeDialog: React.FC<KeyboardTimePickerProps> = (props) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR} >
      <KeyboardTimePicker
        {...props}
        style={{ width: '100%', height: 70 }}
        value={props.value}
        format="HH:mm"
        ampm={false}
        cancelLabel="Cancelar"
        keyboardIcon={<FontIcon iconName="Clock" style={{ fontSize: 20 }} />}
        KeyboardButtonProps={{
          "aria-label": "change date"
        }}
      />
    </MuiPickersUtilsProvider>
  );
}

interface IProps {
  label?: string | JSX.Element;
  styles?: React.CSSProperties;
}

const InputCheckbox: React.FC<CheckboxProps & IProps> = (props) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          {...props}
          color="primary"
        />
      }
      style={props.styles}
      label={<span style={props.styles}>{props.label}</span>}
    />
  )
}

interface IAutocompleteProps {
  input: {
    helperTextInput?: string;
    errorInput?: boolean;
    idInput: string;
    labelInput: string;
    autoFocus?: boolean;
    variant?: "standard" | "outlined" | "filled";
    size?: "medium" | "small";
    styles?: React.CSSProperties;
    smaller?: "small";
    isLoading?: boolean;
    noOptionsText?: string;
    min_height_container?: number | string;
    height_container?: number | string;
  }
}

function InputAutocomplete<T>(props: Partial<AutocompleteProps<T, false, false, false>> & IAutocompleteProps):
  React.ReactElement<AutocompleteProps<T, false, false, false>> {
  return (
    <Autocomplete
      {...props}
      id={props.input.idInput}
      options={props.options!}
      noOptionsText={props.input.noOptionsText ?? "Não há opções"}
      renderInput={(params) => <InputText
        {...params}
        variant={props.input.variant}
        label={props.input.labelInput}
        helperText={props.input.helperTextInput}
        type="text"
        error={props.input.errorInput}
        name={props.input.idInput}
        autoFocus={props.input.autoFocus}
        size={props.input.size}
        styles={props.input.styles}
        smaller={props.input.smaller}
        height_container={props.input.height_container}
        min_height_container={props.input.min_height_container}
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <React.Fragment>
              {props.input.isLoading ? <Spinner size={SpinnerSize.small} styles={{ root: props.input.variant === 'outlined' && { position: 'absolute', right: 65 } }} /> : null}
              {params.InputProps.endAdornment}
            </React.Fragment>
          ),
        }}
      />}
    />
  )
}

function InputAutocompleteMultiple<T>(props: Partial<AutocompleteProps<T, true, false, false>> & IAutocompleteProps):
  React.ReactElement<AutocompleteProps<T, true, false, false>> {
  return (
    <Autocomplete
      {...props}
      id={props.input.idInput}
      options={props.options!}
      noOptionsText={props.input.noOptionsText ?? "Não há opções"}
      renderInput={(params) => <InputText
        {...params}
        variant={props.input.variant}
        label={props.input.labelInput}
        helperText={props.input.helperTextInput}
        type="text"
        error={props.input.errorInput}
        name={props.input.idInput}
        autoFocus={props.input.autoFocus}
        size={props.input.size}
        styles={props.input.styles}
        smaller={props.input.smaller}
        height_container={props.input.height_container}
        min_height_container={props.input.min_height_container}
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <React.Fragment>
              {props.input.isLoading ? <Spinner size={SpinnerSize.small} styles={{ root: props.input.variant === 'outlined' && { position: 'absolute', right: 65 } }} /> : null}
              {params.InputProps.endAdornment}
            </React.Fragment>
          ),
        }}
      />}
    />
  )
}

const VALID_FIRST = /^[1-9]{1}$/;
const VALID_NEXT = /^[0-9]{1}$/;
const DELETE_KEY_CODE = 8;

const CurrencyInput = (props: any) => {
  const {
    className = "",
    style = {},
    height_container = "",
    currencyConfig = {
      locale: "pt-BR",
      currencyCode: "BRL",
      currencyDisplay: "symbol",
      useGrouping: true,
      minimumFractionDigits: undefined
    },
    customInput = InputText,
    name,
    id,
    max = Number.MAX_SAFE_INTEGER,
    onChange,
    value
  } = props;

  const fakeChangeEvent: any = {
    target: {
      type: "number",
      name,
      id,
    }
  };

  const valueInCents = currency(value).intValue;
  const valueAbsTrunc = Math.trunc(Math.abs(valueInCents));
  if (
    valueInCents !== valueAbsTrunc ||
    !Number.isFinite(valueInCents) ||
    Number.isNaN(valueInCents)
  ) {
    throw new Error(`invalid value property`);
  }
  const handleKeyDown = useCallback(
    e => {
      const { key, keyCode } = e;
      if (
        (valueInCents === 0 && !VALID_FIRST.test(key)) ||
        (valueInCents !== 0 &&
          !VALID_NEXT.test(key) &&
          keyCode !== DELETE_KEY_CODE)
      ) {
        return;
      }
      const valueString = valueInCents.toString();
      let nextValue;
      if (keyCode !== DELETE_KEY_CODE) {
        const nextValueString =
          valueInCents === 0 ? key : `${valueString}${key}`;
        nextValue = Number.parseInt(nextValueString, 10);
      } else {
        const nextValueString = valueString.slice(0, -1);
        nextValue =
          nextValueString === "" ? 0 : Number.parseInt(nextValueString, 10);
      }
      if (nextValue > max) {
        return;
      }
      // Enforce our division with currency to prevent rounding errors
      fakeChangeEvent.target.value = currency(nextValue / 100).value;
      onChange(fakeChangeEvent);
    },
    [max, onChange, valueInCents, fakeChangeEvent]
  );
  const handleChange = useCallback(() => {
    // DUMMY TO AVOID REACT WARNING
  }, []);

  const {
    locale,
    currencyCode,
    useGrouping,
    minimumFractionDigits
  } = currencyConfig;

  const valueDisplay = currency(valueInCents / 100).value.toLocaleString(
    locale,
    {
      style: "currency",
      currency: currencyCode,
      useGrouping,
      minimumFractionDigits
    }
  );

  const inputProps: any = {
    "data-testid": "currency-input",
    className: className,
    inputMode: "numeric",
    onChange: handleChange,
    onKeyDown: handleKeyDown,
    style: style,
    height_container: height_container,
    value: props.value !== "" ? valueDisplay.replace("R$", "") : ""
  };

  if (customInput) {
    const customProps = { ...props, ...inputProps };
    delete customProps.customInput;
    delete customProps.currencyConfig;
    const CustomInput = customInput;
    return <CustomInput {...customProps} height_container={height_container} />;
  }

  return <input {...inputProps} />;
};



export { InputText, InputDate, InputDateDialog, InputCheckbox, CurrencyInput, InputAutocomplete, InputTimeDialog, InputAutocompleteMultiple, InputNumber };
