import { Types, DataTypes, BonusActionTypes, BonusGenerateType } from "./types";
import customToast from "~/components/Toast/index";

/**
 * Reducers
 */
const INITIAL_STATE: DataTypes = {
  data: [],
  error: false,
  loading: false,
  loadingAction: false,
  success: false,
  successAdd: false,
  idBonusCreated: null,
};

/**
 * Actions
 */

export const Creators = {
  getBonus: (): BonusActionTypes => {
    return {
      type: Types.REQUEST_BONUS_LOAD,
    };
  },

  addBonus: (bonus: Partial<BonusGenerateType>): BonusActionTypes => ({
    type: Types.REQUEST_BONUS_ADD,
    bonus,
  }),

  delBonus: (idBonus: number): BonusActionTypes => ({
    type: Types.REQUEST_BONUS_DEL,
    idBonus,
  }),
};

export default function bonusReducer(
  state = INITIAL_STATE,
  action: BonusActionTypes
): DataTypes {
  switch (action.type) {
    //LOAD
    case Types.REQUEST_BONUS_LOAD:
      return { ...state, loading: true, error: false, success: false };
    case Types.SUCCESS_BONUS_LOAD:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        success: true,
      };
    case Types.FAILURE_BONUS_LOAD:
      customToast.error(action.message);
      return {
        ...state,
        data: [],
        loading: false,
        error: true,
        success: false,
      };
    // ADD
    case Types.REQUEST_BONUS_ADD:
      return { ...state, loadingAction: true, error: false, successAdd: false };
    case Types.SUCCESS_BONUS_ADD:
      customToast.success("Bônus criado!");
      return {
        ...state,
        data: [...state.data, action.payload],
        loadingAction: false,
        error: false,
        successAdd: true,
        idBonusCreated: action.payload.idBonus!
      };
    case Types.FAILURE_BONUS_ADD:
      customToast.error(action.message);
      return {
        ...state,
        loadingAction: false,
        error: true,
        successAdd: false,
      };

    // DEL
    case Types.REQUEST_BONUS_DEL:
      return { ...state, loadingAction: true, error: false, success: false };
    case Types.SUCCESS_BONUS_DEL:
      customToast.success("Bônus excluído com sucesso!");
      return {
        ...state,
        data: state.data.filter((item) => item.idBonus !== action.idBonus),
        loadingAction: false,
        error: false,
        success: true,
      };
    case Types.FAILURE_BONUS_DEL:
      customToast.error(action.message);
      return {
        ...state,
        loadingAction: false,
        error: true,
        success: false,
      };

    default:
      return state;
  }
}
