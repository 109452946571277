import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";

import { FontIcon, Stack, Spinner, Label } from "office-ui-fabric-react";
import { Text, ITextStyles } from "office-ui-fabric-react/lib/Text";
import {
  Dropdown as DropdownUI,
  IDropdownOption,
} from "office-ui-fabric-react/lib/Dropdown";
import { Icon } from "office-ui-fabric-react/lib/Icon";
import { TooltipHost } from "office-ui-fabric-react/lib/Tooltip";
import { useId } from "@uifabric/react-hooks";
import {
  ICommandBarStyles,
  MessageBar,
  MessageBarType,
} from "office-ui-fabric-react";

import MuiAccordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import { withStyles } from "@material-ui/core/styles";

import { CommandBarButton } from "~/components/Buttons";
import { LabelGroup } from "~/components/label";
import { InputText } from "~/components/Forms";
import PainelHeader from "~/components/layout/PainelHeader";

import {
  Container,
  Content,
  ContainerTitle,
  WrapperResults,
  WrapperForm,
  Table,
  Tr,
  TdNumber,
  TdPillar,
  TdGoal,
  TdPeso,
  TdCorpGoal,
  Th,
  ContainerCorpoGoal,
  Gap,
  Tbody,
  LeftItemMeta,
  RightItemMeta,
  BlankTd,
  TdReal,
  TdResult,
  TdButton,
  TdAtitude,
  TdDesc,
  TrAtitude,
  TrComentario,
  TextComentario,
  TextAtitude,
  TdResultAtitudes,
  Divider,
  TdAvaliacao,
  ThMeta,
  ThReal,
  ThResultado,
  ThAvaliacao,
  ThComentarios,
  TdIniciativa,
  TdValidacao,
  WrapperIniciativa,
  TableIniciativa,
  StatusTeam,
  WrapperLabelGroup,
} from "../Tasks/SelfEvaluation/styles";

import Colors from "~/assets/js/colors";
import { AvaliacaoGestorType } from "~/store/ducks/avaliation/types";
import NoItems from "~/components/layout/NoItems";
import { convertStatusTarefa } from "~/utils/tasks";

interface SelfEvaluationProps {
  onSave: (values?: any) => void;
  onSend?: () => void;
  avaliation: AvaliacaoGestorType;
  statusTarefa?: string;
  loading: boolean;
  error: boolean;
  flagFinalizada?: boolean;
  loadingAction?: boolean;
  loadingSave?: boolean;
  isDesligado: boolean;
  modalView?: boolean;
}

const SelfEvaluationRender: React.FC<SelfEvaluationProps> = (props) => {
  const dispatch = useDispatch();
  const [errorForm, setErrorForm] = useState(false);
  const avaliacao = props.avaliation;
  const { isDesligado, modalView, statusTarefa } = props;
  const tooltipId = useId("tooltip");

  const {
    handleSubmit: handleSubmitColetiva,
    setFieldValue: setFieldValueColetiva,
    setFieldError: setFieldErrorColetiva,
    values: valuesColetiva,
    errors: errorsColetiva,
    resetForm: resetFormColetiva,
    isSubmitting: isSubmittingColetiva,
    isValidating: isValidatingColetiva,
    isValid,
  } = useFormik({
    initialValues: !isDesligado
      ? initialValuesGestorColetiva
      : initialValuesGestorDesligado,
    validationSchema: !isDesligado
      ? validationGestorColetiva
      : validationGestorDesligado,
    validateOnBlur: true,
    validateOnChange: false,
    validate(values) {
      const errors: any = {};
      if (!isDesligado) {
        if (
          (values?.potencial === "Alto potencial para crescer" ||
            values.potencial === "Potencial para crescer") &&
          (values.sucessao === "Selecione uma opção" ||
            values.sucessao === "Null" ||
            values.sucessao === null)
        ) {
          errors.sucessao = "Campo obrigatório";
        }
      }
      return errors;
    },
    onSubmit(values) {
      if (!isDesligado) {
        values.idTabPerformanceGestor = convertResultmetas(
          values?.resultadometas ?? "",
          convertAvaliacao(values?.atitudes ?? [])
        )?.idTabPerformance!;
      }
      // values.idTabPerformanceGestor = convertResultmetas(values.resultadometas, convertAvaliacao(values.atitudes))?.idTabPerformance!
      props.onSave(values);

      // const iniciativasMetas = avaliacao?.areas.find(item => item.idCicloAvaliacaoArea === values.idCicloAvaliacaoArea)?.resultados?.find(item => item.colaboradormeta.flagIniciativas);

      // dispatch(CreatorsTasks.saveIniciativasAutoavaliacao(values.idCicloAvaliacaoArea!, values.avaliacao, values.comentarios, iniciativasMetas!.idCicloAvaliacaoAreaResultado))
      // setErrorIniciativa(false);
      // setIsDialogIniciativaOpen({ isOpen: false, idCicloAvaliacaoArea: null, index: null });
      // setFieldValue("iniciativas", true)
    },
  });

  useEffect(() => {
    if (!isValidatingColetiva && !isSubmittingColetiva && !isValid) {
      setErrorForm(true);
    } else {
      setErrorForm(false);
    }
  }, [isValidatingColetiva, isSubmittingColetiva]);

  useEffect(() => {
    const atitudesMap = avaliacao?.atitudes.map((item) => {
      return {
        resultado: item.resultado,
        comentarios: item.comentarios,
        idCicloAvaliacaoAtitude: item.idCicloAvaliacaoAtitude,
      };
    });

    resetFormColetiva({
      values: {
        atitudes: atitudesMap,
        aspiracoesCarreira: avaliacao?.aspiracoesCarreira ?? "",
        comentariosGerais: avaliacao?.comentariosGerais ?? "",
        comentariosMelhorar: avaliacao?.comentariosMelhorar ?? "",
        comentariosPositivos: avaliacao?.comentariosPositivos ?? "",
        resultadometas: avaliacao?.resultadometas ?? "",
        comentariosGestor: avaliacao?.comentariosGestor ?? "",
        idTabPerformanceGestor: avaliacao?.idTabPerformanceGestor,
        // idTabPerformanceGestor: avaliacao?.idTabPerformanceGestor,
        potencial: avaliacao?.potencial ?? "",
        sucessao: avaliacao?.sucessao
          ? avaliacao?.sucessao
          : avaliacao?.potencial === "Potencial lateral" ||
            avaliacao?.potencial === "Cedo demais para avaliar"
          ? "Null"
          : null,
      },
    });
  }, [dispatch, resetFormColetiva, avaliacao]);

  // const convertIniciativaResult = (idCicloAvaliacaoArea: number, result: any[]) => {
  //   const total = result?.map(item => item).reduce((total, item) => (convertIniciativaResultVal(item.avaliacao.resultado) * item.peso) + total, 0);
  //   const hasChanged = result?.some(item => item.avaliacao.resultado === null);

  //   const iniciativasMetas = avaliacao?.areas.find(item => item.idCicloAvaliacaoArea === idCicloAvaliacaoArea)?.resultados?.find(item => item.colaboradormeta.flagIniciativas);

  //   if (hasChanged) {
  //     return '';
  //   } else if (total < (iniciativasMetas?.meta?.minimo ?? 0)) {
  //     return AbaixoExpectativa("NÃO ATINGIDO");
  //   } else if (total < (iniciativasMetas?.meta?.target ?? 0)) {
  //     return Parcialmente();
  //   } else if (total < (iniciativasMetas?.meta?.maximo ?? 0)) {
  //     return DentroExpectativa("ATINGIDO");
  //   } else {
  //     return AcimaExpectativa("SUPERADO");
  //   }

  // }

  const convertResultmetas = (
    resultadometas: string,
    resultadoAtitudes: string
  ) => {
    const resultMetas = convertAtitudesVal(resultadometas);
    const resultAtitudes = convertAtitudesVal(resultadoAtitudes);

    let nivel = resultMetas + resultAtitudes;

    let resultado = avaliacao?.tabPerformance?.find(
      (perf) => perf.nivel === nivel
    );

    if (resultado) {
      while (resultado?.flagBloquearAvaliacao) {
        nivel = nivel - 1;
        if (nivel < 0) break;

        resultado = avaliacao?.tabPerformance?.find(
          (perf) => perf.nivel - 1 === nivel
        );
      }
    }

    return resultado;
  };

  const onRenderTitle = (options: IDropdownOption[]): JSX.Element => {
    const option = options[0];

    return (
      <Stack horizontal verticalAlign="center">
        {option.data && option.data.icon && (
          <Icon
            iconName={option.data.icon}
            aria-hidden="true"
            title={option.data.icon}
            styles={{ root: { ...option.data.style, marginRight: 10 } }}
          />
        )}
        <Stack>
          <span
            style={{
              fontSize: 12,
              whiteSpace: "break-spaces",
              lineHeight: 1.5,
              textAlign: "left",
              ...option.data.styleOption,
            }}
          >
            {option.text}
          </span>
        </Stack>
      </Stack>
    );
  };

  const onRenderResultadoMetas = (options: IDropdownOption[]): JSX.Element => {
    const option = options[0];

    return (
      <Stack
        horizontal
        verticalAlign="center"
        horizontalAlign="center"
        styles={{ root: { width: "100%" } }}
      >
        {option.data && option.data.icon && (
          <Icon
            iconName={option.data.icon}
            aria-hidden="true"
            title={option.data.icon}
            styles={{ root: { ...option.data.style, marginRight: 10 } }}
          />
        )}

        <Stack horizontalAlign="center">
          <span
            style={{
              fontSize: 12,
              whiteSpace: "break-spaces",
              lineHeight: 1.5,
              textAlign: "left",
              ...option.data.styleOption,
            }}
          >
            {option.text}
          </span>
        </Stack>
      </Stack>
    );
  };

  const onRenderTitltePerformance = (
    options: IDropdownOption[]
  ): JSX.Element => {
    const option = options[0];

    return (
      <Stack
        horizontal
        verticalAlign="center"
        horizontalAlign="center"
        styles={{ root: { width: "100%" } }}
      >
        {option.data && !option?.data?.styleOption && (
          <Icon
            iconName="CircleShapeSolid"
            aria-hidden="true"
            styles={{
              root: { ...option.data.style, marginRight: 10, fontSize: 16 },
            }}
          />
        )}
        <Stack horizontalAlign="center">
          <span
            style={{
              fontSize: 12,
              whiteSpace: "break-spaces",
              lineHeight: 1.5,
              textAlign: "left",
              ...option?.data?.styleOption,
            }}
          >
            {option.text}
          </span>
        </Stack>
      </Stack>
    );
  };

  const onRenderTitlePotencial = (options: IDropdownOption[]): JSX.Element => {
    const option = options[0];

    return (
      <Stack
        horizontal
        verticalAlign="center"
        horizontalAlign="center"
        styles={{ root: { width: "100%" } }}
      >
        <Stack horizontalAlign="center">
          <span
            style={{
              fontSize: 12,
              whiteSpace: "break-spaces",
              lineHeight: 1.5,
              textAlign: "left",
              ...option?.data?.styleOption,
            }}
          >
            {option.text}
          </span>
        </Stack>
      </Stack>
    );
  };

  // const renderMetas = (idCicloAvaliacaoArea: number, index: number, resultados: ResultadosType[]) => {
  //   return resultados?.map((item, i) => {
  //     const { colaboradormeta, pilar, meta, cicloPesoPai, real, ating, resultado, peso } = item;
  //     const { cicloPeso, flagIniciativas } = colaboradormeta;
  //     const { corPilar, nomePilar } = pilar;
  //     const { objetivo } = cicloPeso;
  //     const { objetivo: objPai } = cicloPesoPai ?? {};

  //     return (
  //       <Tr pillarColor={corPilar} key={i}>
  //         <TdNumber>{i + 1}</TdNumber>
  //         <Gap />
  //         {
  //           !flagIniciativas ?
  //             <>
  //               <TdPillar>{nomePilar}</TdPillar>
  //               <TdGoal>
  //                 <Stack>
  //                   {
  //                     objPai &&
  //                     <Text variant="tiny" styles={{ root: { color: "#989898", marginBottom: 5 } }}>{objPai.descObjetivo ?? ''}</Text>
  //                   }
  //                   <Text variant="small" styles={{ root: { textTransform: 'uppercase' } }}>{objetivo.descObjetivo}</Text>
  //                 </Stack>
  //               </TdGoal>

  //               <Gap />
  //               {
  //                 resultado !== 'Não se aplica' ?
  //                   <>
  //                     <TdPeso>{Number(peso)}%</TdPeso>
  //                     <Gap />

  //                     {
  //                       !flagIniciativas ?
  //                         <>
  //                           <TdCorpGoal>
  //                             <ContainerCorpoGoal>
  //                               <LeftItemMeta>Min</LeftItemMeta>
  //                               <RightItemMeta>{formattedMeta(String(meta?.minimo), objetivo.unidade)}</RightItemMeta>
  //                             </ContainerCorpoGoal>
  //                             <ContainerCorpoGoal>
  //                               <LeftItemMeta>Target</LeftItemMeta>
  //                               <RightItemMeta>{formattedMeta(String(meta?.target), objetivo.unidade)}</RightItemMeta>
  //                             </ContainerCorpoGoal>
  //                             <ContainerCorpoGoal>
  //                               <LeftItemMeta>Max</LeftItemMeta>
  //                               <RightItemMeta>{formattedMeta(String(meta?.maximo), objetivo.unidade)}</RightItemMeta>
  //                             </ContainerCorpoGoal>
  //                           </TdCorpGoal>
  //                           <Gap />
  //                           <TdReal>
  //                             {
  //                               Number(real) !== 0 &&
  //                               <Stack verticalAlign="space-between">
  //                                 <Text variant="medium" styles={{ root: { fontWeight: 'bold' } }}>{formattedMeta(real.toString(), objetivo.unidade)}</Text>
  //                                 <Text variant="medium">{(Number(ating) * 100).toFixed()}%</Text>
  //                               </Stack>
  //                             }
  //                           </TdReal>
  //                         </>
  //                         :
  //                         <>
  //                           <BlankTd />
  //                           <Gap />
  //                           <BlankTd />
  //                         </>
  //                     }
  //                   </>
  //                   :
  //                   <>
  //                     <Gap colSpan={5} style={{ width: 280, minWidth: 280 }} />
  //                   </>
  //               }

  //               <Gap />
  //               <TdResult>
  //                 <Stack horizontal verticalAlign="center">
  //                   {
  //                     convertResult(resultado)
  //                   }
  //                 </Stack>
  //               </TdResult>
  //             </>
  //             :
  //             <>
  //               <TdPillar colSpan={2} style={{ borderRadius: 2, width: 460, minWidth: 460 }}>{objetivo.descObjetivo}</TdPillar>
  //               <Gap />
  //               <TdPeso>{Number(peso)}%</TdPeso>
  //               <Gap />
  //               {/* <TdButton onClick={() => setIsDialogIniciativaOpen({ isOpen: true, index, idCicloAvaliacaoArea })} style={{ backgroundColor: errorIniciativa && !resultado ? Colors.error : Colors.primary }}>
  //                 {
  //                   avaliacao?.flagAutoAvaliacao ?
  //                     "AVALIAR INICIATIVAS"
  //                     :
  //                     "VISUALIZAR INICIATIVAS"
  //                 }
  //               </TdButton> */}
  //               <Gap />
  //               <TdReal>
  //                 {
  //                   resultado !== null &&
  //                   <Stack verticalAlign="space-between">
  //                     <Text variant="medium" styles={{ root: { fontWeight: 'bold' } }}>{Number(real)}%</Text>
  //                     <Text variant="medium">{Number(ating) * 100}%</Text>
  //                   </Stack>
  //                 }
  //               </TdReal>
  //               <Gap />
  //               <TdResult>
  //                 <Stack horizontal verticalAlign="center">
  //                   {
  //                     convertResult(resultado)
  //                   }
  //                 </Stack>
  //               </TdResult>
  //             </>
  //         }
  //       </Tr>
  //     )
  //   })
  // }

  const renderResultados = () => {
    let areas = avaliacao?.areas;
    if (avaliacao?.tipoAvaliacao === "Avaliação gestor") {
      areas = areas?.filter((item, i, arr) => arr.length - 1 === i) ?? [];
    }
    return areas?.map((area, i, arr) => {
      const {
        resultados,
        ciclocolaborador,
        idCicloAvaliacaoArea,
        descPeriodo,
        meses,
      } = area;
      const { unidade, cc, cargo, area: areaColab } = ciclocolaborador;
      const isColetiva = avaliacao?.tipoAvaliacao === "Avaliação gestor";
      return (
        <Accordion defaultExpanded={true} key={i}>
          <AccordionSummary
            expandIcon={
              <FontIcon iconName="ChevronDown" style={{ fontSize: 16 }} />
            }
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Text variant="mediumPlus" styles={textStyles}>
              {isColetiva ? "Avaliação de Performance" : `Informações básicas`}
            </Text>
          </AccordionSummary>
          <AccordionDetails>
            <Stack>
              <Stack styles={{ root: { width: "100%" } }}>
                <WrapperLabelGroup>
                  <WrapperLabelGroup>
                    <LabelGroup
                      name="lg"
                      icon="EMI"
                      label="Unidade"
                      value={unidade.nomeUnidade}
                      tooltipMessage={unidade.nomeUnidade}
                    />
                    <LabelGroup
                      name="lg"
                      icon="Org"
                      label="Área"
                      value={areaColab.nomeLocal}
                      tooltipMessage={areaColab.nomeLocal}
                    />
                    <LabelGroup
                      name="lg"
                      icon="Work"
                      label="Cargo"
                      value={cargo.titulo}
                      tooltipMessage={cargo.titulo}
                    />
                    <LabelGroup
                      name="lg"
                      icon="Calendar"
                      label="Período"
                      value={descPeriodo}
                    />
                    <LabelGroup
                      name="lg"
                      styles={{ minWidth: "auto" }}
                      icon="Calendar"
                      label="Tempo na Função"
                      value={`${meses} meses`}
                    />
                  </WrapperLabelGroup>
                </WrapperLabelGroup>
              </Stack>

              {!isDesligado && (
                <Stack styles={{ root: { width: "100%", marginBottom: 20 } }}>
                  <Table>
                    <Tbody>
                      <tr>
                        <Gap style={{ minWidth: 370, maxWidth: 370 }} />
                        <td style={{ width: 160, minWidth: 160 }}>
                          <Text variant="small">PERFORMANCE</Text>
                        </td>
                        <TdResultAtitudes
                          style={{ width: 420, minWidth: 420, borderWidth: 0 }}
                        >
                          <DropdownUI
                            placeholder="Selecione uma opção"
                            options={options}
                            selectedKey={
                              valuesColetiva.resultadometas ??
                              "Selecione uma opção"
                            }
                            onRenderTitle={onRenderResultadoMetas as any}
                            disabled={!avaliacao?.habilitaAvaliacaoGestor}
                            onChange={(e, item: IDropdownOption | any) => {
                              setFieldValueColetiva(
                                `resultadometas`,
                                item?.key
                              );
                              setFieldErrorColetiva(`resultadometas`, "");
                            }}
                            styles={{
                              title: {
                                height: 60,
                                display: "flex",
                                alignItems: "center",
                                borderColor: errorsColetiva.resultadometas
                                  ? Colors.error
                                  : "#CCCCCC",
                              },
                              caretDownWrapper: { top: 16 },
                            }}
                          />
                        </TdResultAtitudes>
                      </tr>
                    </Tbody>
                  </Table>
                  <InputText
                    label="Comentários gerais"
                    multiline
                    rows={3}
                    value={valuesColetiva?.comentariosGerais ?? ""}
                    onChange={(e) => {
                      setFieldValueColetiva(
                        "comentariosGerais",
                        e.target.value
                      );
                      setFieldErrorColetiva("comentariosGerais", "");
                    }}
                    // onBlur={e => dispatch(CreatorsAvaliation.saveComentariosGerais(e.target.value))}
                    error={errorsColetiva.comentariosGerais ? true : false}
                    helperText={errorsColetiva.comentariosGerais}
                    disabled={!avaliacao?.habilitaAvaliacaoGestor}
                  />
                </Stack>
              )}
              {/* :
                  <Table>
                    <thead>
                      <tr>
                        <th colSpan={5}></th>
                        <Th>PESO</Th>
                        <Gap />
                        <ThMeta>META</ThMeta>
                        <Gap />
                        <ThReal>REAL / %</ThReal>
                        <Gap />
                        <ThResultado>RESULTADO</ThResultado>
                        <Gap />
                      </tr>
                    </thead>
                    <Tbody>
                      {
                        renderMetas(idCicloAvaliacaoArea, i, resultados)
                      }
                      <tr>
                        <th colSpan={9}></th>
                        <TdReal isTotal={true}>
                          <Stack verticalAlign="space-between">
                            <Text variant="smallPlus" styles={{ root: { fontWeight: 'bold' } }}></Text>
                            {
                              Number(area?.somaMetas) !== 0 &&
                              <Text variant="smallPlus">{Number(area?.somaMetas).toFixed(1)}%</Text>
                            }
                          </Stack>
                        </TdReal>
                        <Gap />
                        <TdResult isTotal={true}>
                          {
                            area?.resultadometas !== null &&
                            <Stack horizontal verticalAlign="center">

                              {
                                convertResultMetas(area.resultadometas ?? '')
                              }
                            </Stack>
                          }
                        </TdResult>
                      </tr>
                    </Tbody>
                  </Table>
                  ?} */}
            </Stack>
          </AccordionDetails>
        </Accordion>
      );
    });
  };

  return (
    <Container modalView={modalView}>
      {!modalView && (
        <PainelHeader
          title={`Avaliação ${avaliacao?.ciclo.ano ?? ""}`}
          icon={{ icon: "Equalizer" }}
          labelGroups={
            avaliacao
              ? [
                  {
                    label: "Funcionário",
                    value: avaliacao?.funcionario.nomeCompleto ?? "",
                    icon: "HomeGroup",
                    size: "full",
                  },
                  {
                    label: "Status",
                    value: convertStatusTarefa(statusTarefa ?? ""),
                    icon: "Flag",
                    size: "full",
                  },
                ]
              : []
          }
        />
      )}
      <Content modalView={modalView}>
        {props.loading ? (
          <Spinner
            label="Carregando avaliação"
            styles={{ root: { height: 500 } }}
          />
        ) : (
          <>
            {props.error ? (
              <NoItems text="Tarefa não encontrada" error={true} />
            ) : (
              <>
                {avaliacao?.habilitaAvaliacaoGestor && (
                  <>
                    <CommandBarButton
                      iconProps={{ iconName: "Save" }}
                      onClick={() => handleSubmitColetiva()}
                      styles={commandButtonStyle}
                      isLoading={props.loadingSave}
                    >
                      Salvar
                    </CommandBarButton>
                  </>
                )}
                {errorForm && (
                  <MessageBar
                    messageBarType={MessageBarType.error}
                    isMultiline={false}
                    dismissButtonAriaLabel="Close"
                    onDismiss={() => setErrorForm(false)}
                  >
                    Verifique os campos obrigatórios!
                  </MessageBar>
                )}

                {renderResultados()}

                <WrapperResults>
                  <form onSubmit={handleSubmitColetiva}>
                    {!isDesligado && (
                      <>
                        <Accordion defaultExpanded={true}>
                          <AccordionSummary
                            expandIcon={
                              <FontIcon
                                iconName="ChevronDown"
                                style={{ fontSize: 16 }}
                              />
                            }
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                          >
                            <Text variant="mediumPlus" styles={textStyles}>
                              Atitudes Vencedoras
                            </Text>
                          </AccordionSummary>
                          <AccordionDetails>
                            {props.loading ? (
                              <Spinner
                                label="Carregando atitudes"
                                styles={{ root: { height: 200 } }}
                              />
                            ) : (avaliacao?.atitudes?.length ?? []) <= 0 ? (
                              <NoItems
                                text="Não há atitudes"
                                error={false}
                                icon="Feedback"
                              />
                            ) : (
                              <Table>
                                <thead>
                                  <tr>
                                    <th colSpan={3}></th>
                                    <ThAvaliacao>AVALIAÇÃO</ThAvaliacao>
                                    <Gap />
                                    <ThComentarios>COMENTÁRIOS</ThComentarios>
                                  </tr>
                                </thead>
                                <Tbody>
                                  {avaliacao?.atitudes.map((item, i) => {
                                    const {
                                      atitude,
                                      idCicloAvaliacaoAtitude,
                                    } = item;
                                    const {
                                      nomeAtitude,
                                      descColetivo,
                                    } = atitude;
                                    const errorResultado = errorsColetiva?.atitudes
                                      ? (errorsColetiva?.atitudes[i] as any)
                                          ?.resultado
                                      : "";
                                    const errorComentario = errorsColetiva?.atitudes
                                      ? (errorsColetiva?.atitudes[i] as any)
                                          ?.comentarios
                                      : "";

                                    if (
                                      valuesColetiva.atitudes &&
                                      valuesColetiva?.atitudes.length > 0
                                    ) {
                                      return (
                                        <TrAtitude key={i}>
                                          <TdAtitude>
                                            <p>{nomeAtitude}</p>
                                          </TdAtitude>
                                          <TdDesc>
                                            <TooltipHost
                                              content={descColetivo}
                                              id={tooltipId}
                                              styles={{
                                                root: { position: "relative" },
                                              }}
                                            >
                                              <TextAtitude>
                                                {descColetivo}
                                              </TextAtitude>
                                            </TooltipHost>
                                          </TdDesc>
                                          <Gap />
                                          <TdAvaliacao>
                                            <DropdownUI
                                              placeholder="Selecione uma opção"
                                              selectedKey={
                                                valuesColetiva?.atitudes[i]
                                                  ?.resultado ??
                                                "Selecione uma opção"
                                              }
                                              options={options ?? []}
                                              onRenderTitle={
                                                onRenderTitle as any
                                              }
                                              onChange={(e, item) => {
                                                setFieldValueColetiva(
                                                  `atitudes.${i}.resultado`,
                                                  item?.key
                                                );
                                                setFieldErrorColetiva(
                                                  `atitudes.${i}.resultado`,
                                                  ""
                                                );
                                                // dispatch(CreatorsAvaliation.saveAtitudeAvaliacao(idCicloAvaliacaoAtitude, String(item?.key) ?? ''))
                                              }}
                                              disabled={
                                                !avaliacao?.habilitaAvaliacaoGestor
                                              }
                                              styles={{
                                                title: {
                                                  height: 60,
                                                  display: "flex",
                                                  alignItems: "center",
                                                  borderColor: errorResultado
                                                    ? Colors.error
                                                    : "#CCCCCC",
                                                  paddingLeft: 10,
                                                },
                                                caretDownWrapper: { top: 16 },
                                              }}
                                            />
                                          </TdAvaliacao>
                                          <Gap />
                                          <TrComentario>
                                            <TextComentario
                                              rows={2}
                                              value={
                                                valuesColetiva?.atitudes[i]
                                                  ?.comentarios ?? ""
                                              }
                                              onChange={(e) => {
                                                setFieldValueColetiva(
                                                  `atitudes.${i}.comentarios`,
                                                  e.target.value
                                                );
                                                setFieldErrorColetiva(
                                                  `atitudes.${i}.comentarios`,
                                                  ""
                                                );
                                              }}
                                              // onBlur={(e) => dispatch(CreatorsAvaliation.saveAtitudeComentario(idCicloAvaliacaoAtitude, e.target.value))}
                                              style={{
                                                borderColor: errorComentario
                                                  ? Colors.error
                                                  : "#CCCCCC",
                                              }}
                                              disabled={
                                                !avaliacao?.habilitaAvaliacaoGestor
                                              }
                                            />
                                          </TrComentario>
                                        </TrAtitude>
                                      );
                                    }
                                  })}
                                  <tr>
                                    <td colSpan={3} />
                                    <TdResultAtitudes colSpan={3}>
                                      <Stack
                                        horizontal
                                        verticalAlign="center"
                                        horizontalAlign="center"
                                      >
                                        {renderAvaliacao(
                                          convertAvaliacao(
                                            valuesColetiva.atitudes ?? []
                                          )
                                        )}
                                      </Stack>
                                    </TdResultAtitudes>
                                  </tr>
                                </Tbody>
                              </Table>
                            )}
                          </AccordionDetails>
                        </Accordion>
                        <Accordion defaultExpanded={false}>
                          <AccordionSummary
                            expandIcon={
                              <FontIcon
                                iconName="ChevronDown"
                                style={{ fontSize: 16 }}
                              />
                            }
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                          >
                            <Text variant="mediumPlus" styles={textStyles}>
                              Revisão de Meio de Ano
                            </Text>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Stack
                              styles={{
                                root: { width: "100%", marginBottom: 20 },
                              }}
                            >
                              <Label
                                styles={{
                                  root: { fontSize: 11, marginTop: -5 },
                                }}
                              >
                                Momento de avaliar como está seu desempenho e se
                                necessário, realizar correções de curso para o
                                alcance das suas metas e dos objetivos
                                estratégicos do Marche. Lembre-se que você é o
                                protagonista da sua carreira por isso, após essa
                                avaliação, agende seu feedback com seu gestor
                                para que juntos possam traçar as rotas
                                necessárias.
                              </Label>
                              <InputText
                                label="Comentários do funcionário"
                                multiline
                                rows={3}
                                value={avaliacao?.comentariosMeioAno ?? ""}
                                disabled={true}
                              />

                              <InputText
                                label="Comentários do líder"
                                multiline
                                rows={3}
                                value={
                                  avaliacao?.comentariosMeioAnoGestor ?? ""
                                }
                                disabled={true}
                                styles={{ marginTop: 20 }}
                              />
                            </Stack>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion defaultExpanded={true}>
                          <AccordionSummary
                            expandIcon={
                              <FontIcon
                                iconName="ChevronDown"
                                style={{ fontSize: 16 }}
                              />
                            }
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                          >
                            <Text variant="mediumPlus" styles={textStyles}>
                              Carreira
                            </Text>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Stack styles={{ root: { width: "100%" } }}>
                              <Label
                                styles={{
                                  root: { fontSize: 11, marginTop: -5 },
                                }}
                              >
                                Não há ninguém na companhia que saiba mais do
                                que você mesmo sobre os seus objetivos,
                                expectativas e sonhos e por isso você precisa
                                ser o protagonista da sua carreira e para
                                conseguirmos te apoiar em seu desenvolvimento,
                                queremos conhecer suas aspirações de
                                desenvolvimento. Descreva abaixo:
                              </Label>
                              <WrapperForm>
                                <InputText
                                  label="Aspirações de desenvolvimento da carreira"
                                  multiline
                                  rows={3}
                                  value={valuesColetiva.aspiracoesCarreira}
                                  onChange={(e) => {
                                    setFieldValueColetiva(
                                      "aspiracoesCarreira",
                                      e.target.value
                                    );
                                    setFieldErrorColetiva(
                                      "aspiracoesCarreira",
                                      ""
                                    );
                                  }}
                                  // onBlur={e => {
                                  //   dispatch(CreatorsAvaliation.saveAspiracaoCarreira(e.target.value));
                                  // }}
                                  error={
                                    errorsColetiva.aspiracoesCarreira
                                      ? true
                                      : false
                                  }
                                  helperText={errorsColetiva.aspiracoesCarreira}
                                  disabled={!avaliacao?.habilitaAvaliacaoGestor}
                                />
                              </WrapperForm>
                            </Stack>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion defaultExpanded={true}>
                          <AccordionSummary
                            expandIcon={
                              <FontIcon
                                iconName="ChevronDown"
                                style={{ fontSize: 16 }}
                              />
                            }
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                          >
                            <Text variant="mediumPlus" styles={textStyles}>
                              O que está fazendo bem?
                            </Text>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Stack styles={{ root: { width: "100%" } }}>
                              {/* <Label styles={{ root: { fontSize: 11, marginTop: -5 } }}>
                                Não há ninguém na companhia que saiba mais do que você mesmo sobre os seus objetivos, expectativas e sonhos e por isso você precisa
                                ser o protagonista da sua carreira e para conseguirmos te apoiar em seu desenvolvimento, queremos conhecer suas aspirações de
                                desenvolvimento. Descreva abaixo:
                              </Label> */}
                              <WrapperForm>
                                <InputText
                                  label="O que está fazendo bem?"
                                  multiline
                                  rows={3}
                                  value={
                                    valuesColetiva.comentariosPositivos ?? ""
                                  }
                                  onChange={(e) => {
                                    setFieldValueColetiva(
                                      "comentariosPositivos",
                                      e.target.value
                                    );
                                    setFieldErrorColetiva(
                                      "comentariosPositivos",
                                      ""
                                    );
                                  }}
                                  // onBlur={e => dispatch(CreatorsAvaliation.saveComentariosPositivo(e.target.value))}
                                  error={
                                    errorsColetiva.comentariosPositivos
                                      ? true
                                      : false
                                  }
                                  helperText={
                                    errorsColetiva.comentariosPositivos
                                  }
                                  disabled={!avaliacao?.habilitaAvaliacaoGestor}
                                />
                              </WrapperForm>
                            </Stack>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion defaultExpanded={true}>
                          <AccordionSummary
                            expandIcon={
                              <FontIcon
                                iconName="ChevronDown"
                                style={{ fontSize: 16 }}
                              />
                            }
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                          >
                            <Text variant="mediumPlus" styles={textStyles}>
                              O que precisa melhorar? Como posso te ajudar?
                            </Text>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Stack styles={{ root: { width: "100%" } }}>
                              {/* <Label styles={{ root: { fontSize: 11, marginTop: -5 } }}>
                                Não há ninguém na companhia que saiba mais do que você mesmo sobre os seus objetivos, expectativas e sonhos e por isso você precisa
                                ser o protagonista da sua carreira e para conseguirmos te apoiar em seu desenvolvimento, queremos conhecer suas aspirações de
                                desenvolvimento. Descreva abaixo:
                              </Label> */}
                              <WrapperForm>
                                <InputText
                                  label="O que precisa melhorar?"
                                  multiline
                                  rows={3}
                                  value={
                                    valuesColetiva.comentariosMelhorar ?? ""
                                  }
                                  onChange={(e) => {
                                    setFieldValueColetiva(
                                      "comentariosMelhorar",
                                      e.target.value
                                    );
                                    setFieldErrorColetiva(
                                      "comentariosMelhorar",
                                      ""
                                    );
                                  }}
                                  // onBlur={e => dispatch(CreatorsAvaliation.saveComentariosMelhorar(e.target.value))}
                                  error={
                                    errorsColetiva.comentariosMelhorar
                                      ? true
                                      : false
                                  }
                                  helperText={
                                    errorsColetiva.comentariosMelhorar
                                  }
                                  disabled={!avaliacao?.habilitaAvaliacaoGestor}
                                />
                              </WrapperForm>
                            </Stack>
                          </AccordionDetails>
                        </Accordion>
                      </>
                    )}
                    <Accordion defaultExpanded={true}>
                      <AccordionSummary
                        expandIcon={
                          <FontIcon
                            iconName="ChevronDown"
                            style={{ fontSize: 16 }}
                          />
                        }
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Text variant="mediumPlus" styles={textStyles}>
                          Avaliação do Gestor
                        </Text>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Stack styles={{ root: { width: "100%" } }}>
                          <Table>
                            <Tbody>
                              <tr>
                                <Gap style={{ minWidth: 370, maxWidth: 370 }} />
                                <td style={{ width: 160, minWidth: 160 }}>
                                  <Text variant="small">PERFORMANCE</Text>
                                </td>
                                <TdResultAtitudes
                                  style={{
                                    width: 420,
                                    minWidth: 420,
                                    borderWidth: 0,
                                  }}
                                >
                                  <DropdownUI
                                    placeholder="Selecione uma opção"
                                    options={[
                                      {
                                        key: -1,
                                        text: "Selecione uma opção",
                                        disabled: true,
                                        hidden: true,
                                        data: { styleOption: { fontSize: 14 } },
                                      },
                                      ...(avaliacao?.tabPerformance.map(
                                        (item) => ({
                                          key: item.idTabPerformance,
                                          text: item.descNivel.toUpperCase(),
                                          data: { style: { color: item.cor } },
                                          hidden: item.flagBloquearAvaliacao ?? false,
                                        })
                                      ) ?? []),
                                    ]}
                                    selectedKey={
                                      !isDesligado
                                        ? valuesColetiva.resultadometas !==
                                            "" &&
                                          convertAvaliacao(
                                            valuesColetiva.atitudes ?? []
                                          ) !== ""
                                          ? convertResultmetas(
                                              valuesColetiva.resultadometas ??
                                                "",
                                              convertAvaliacao(
                                                valuesColetiva.atitudes ?? []
                                              )
                                            )?.idTabPerformance ?? -1
                                          : -1
                                        : valuesColetiva.idTabPerformanceGestor ??
                                          -1
                                    }
                                    onRenderTitle={
                                      onRenderTitltePerformance as any
                                    }
                                    disabled={
                                      !isDesligado
                                        ? true
                                        : !avaliacao?.habilitaAvaliacaoGestor
                                    }
                                    onChange={(e, item) => {
                                      setFieldValueColetiva(
                                        `idTabPerformanceGestor`,
                                        item?.key
                                      );
                                      setFieldErrorColetiva(
                                        `idTabPerformanceGestor`,
                                        ""
                                      );
                                    }}
                                    styles={{
                                      title: {
                                        height: 60,
                                        display: "flex",
                                        alignItems: "center",
                                        borderColor: !isDesligado
                                          ? "#CCCCCC"
                                          : errorsColetiva.idTabPerformanceGestor
                                          ? Colors.error
                                          : "#CCCCCC",
                                      },
                                      caretDownWrapper: {
                                        top: 16,
                                      },
                                    }}
                                  />
                                </TdResultAtitudes>
                              </tr>
                              {!isDesligado && (
                                <>
                                  <tr>
                                    <Gap
                                      style={{ minWidth: 370, maxWidth: 370 }}
                                    />
                                    <td style={{ width: 160, minWidth: 160 }}>
                                      <Text variant="small">POTENCIAL</Text>
                                    </td>
                                    <TdResultAtitudes
                                      style={{
                                        width: 420,
                                        minWidth: 420,
                                        borderWidth: 0,
                                      }}
                                    >
                                      <DropdownUI
                                        options={optionsPotencial}
                                        defaultSelectedKey={
                                          avaliacao?.potencial ??
                                          "Selecione uma opção"
                                        }
                                        selectedKey={
                                          valuesColetiva.potencial ??
                                          "Selecione uma opção"
                                        }
                                        onRenderTitle={
                                          onRenderTitlePotencial as any
                                        }
                                        disabled={
                                          !avaliacao?.habilitaAvaliacaoGestor
                                        }
                                        onChange={(e, item) => {
                                          if (
                                            item?.key === "Potencial lateral" ||
                                            item?.key ===
                                              "Cedo demais para avaliar"
                                          ) {
                                            setFieldValueColetiva(
                                              `sucessao`,
                                              "Null"
                                            );
                                          } else if (
                                            valuesColetiva.sucessao === "Null"
                                          ) {
                                            setFieldValueColetiva(
                                              "sucessao",
                                              "Selecione uma opção"
                                            );
                                          }
                                          setFieldValueColetiva(
                                            `potencial`,
                                            item?.key
                                          );
                                          setFieldErrorColetiva(
                                            `potencial`,
                                            ""
                                          );
                                        }}
                                        styles={{
                                          title: {
                                            height: 60,
                                            display: "flex",
                                            alignItems: "center",
                                            borderColor: errorsColetiva.potencial
                                              ? Colors.error
                                              : "#CCCCCC",
                                          },
                                          caretDownWrapper: {
                                            top: 16,
                                          },
                                        }}
                                      />
                                    </TdResultAtitudes>
                                  </tr>
                                  <tr>
                                    <Gap
                                      style={{ minWidth: 370, maxWidth: 370 }}
                                    />
                                    <td style={{ width: 160, minWidth: 160 }}>
                                      <Text variant="small">SUCESSÃO</Text>
                                    </td>
                                    <TdResultAtitudes
                                      style={{
                                        width: 420,
                                        minWidth: 420,
                                        borderWidth: 0,
                                      }}
                                    >
                                      <DropdownUI
                                        placeholder="Selecione uma opção"
                                        options={optionsSucessao}
                                        defaultSelectedKey={
                                          avaliacao?.sucessao ??
                                          "Selecione uma opção"
                                        }
                                        selectedKey={
                                          valuesColetiva.sucessao ??
                                          "Selecione uma opção"
                                        }
                                        onRenderTitle={
                                          onRenderTitlePotencial as any
                                        }
                                        disabled={
                                          !avaliacao?.habilitaAvaliacaoGestor ||
                                          valuesColetiva.potencial ===
                                            "Potencial lateral" ||
                                          valuesColetiva.potencial ===
                                            "Cedo demais para avaliar"
                                        }
                                        onChange={(e, item) => {
                                          setFieldValueColetiva(
                                            `sucessao`,
                                            item?.key
                                          );
                                          setFieldErrorColetiva(`sucessao`, "");
                                        }}
                                        styles={{
                                          title: {
                                            height: 60,
                                            display: "flex",
                                            alignItems: "center",
                                            borderColor: errorsColetiva.sucessao
                                              ? Colors.error
                                              : "#CCCCCC",
                                          },
                                          caretDownWrapper: {
                                            top: 16,
                                          },
                                        }}
                                      />
                                    </TdResultAtitudes>
                                  </tr>
                                </>
                              )}
                            </Tbody>
                          </Table>
                          <WrapperForm>
                            <InputText
                              label="Faça aqui os comentários para a calibração"
                              multiline
                              rows={3}
                              disabled={!avaliacao?.habilitaAvaliacaoGestor}
                              value={valuesColetiva.comentariosGestor}
                              onChange={(e) => {
                                setFieldValueColetiva(
                                  "comentariosGestor",
                                  e.target.value
                                );
                                setFieldErrorColetiva("comentariosGestor", "");
                              }}
                              error={
                                errorsColetiva.comentariosGestor ? true : false
                              }
                              helperText={errorsColetiva.comentariosGestor}
                            />
                          </WrapperForm>
                        </Stack>
                      </AccordionDetails>
                    </Accordion>
                  </form>
                  {avaliacao?.habilitaAvaliacaoGestor && (
                    <>
                      <Divider />
                      <CommandBarButton
                        iconProps={{ iconName: "Save" }}
                        onClick={() => {
                          handleSubmitColetiva();
                        }}
                        styles={commandButtonStyle}
                        isLoading={props.loadingSave}
                      >
                        Salvar
                      </CommandBarButton>
                    </>
                  )}
                  {errorForm && (
                    <MessageBar
                      messageBarType={MessageBarType.error}
                      isMultiline={false}
                      dismissButtonAriaLabel="Close"
                      onDismiss={() => setErrorForm(false)}
                    >
                      Verifique os campos obrigatórios!
                    </MessageBar>
                  )}
                </WrapperResults>
              </>
            )}
          </>
        )}
      </Content>
    </Container>
  );
};

const AcimaExpectativa = (text?: string) => (
  <>
    <FontIcon
      iconName="SkypeCircleArrow"
      style={{
        fontSize: 16,
        marginRight: 5,
        color: Colors.blue,
        transform: "rotate(90deg)",
      }}
    />
    {text ?? "ACIMA DA EXPECTATIVA"}
  </>
);

const AbaixoExpectativa = (text?: string) => (
  <>
    <FontIcon
      iconName="SkypeCircleArrow"
      style={{
        fontSize: 16,
        marginRight: 5,
        color: Colors.error,
        transform: "rotate(-90deg)",
      }}
    />
    {text ?? "ABAIXO DA EXPECTATIVA"}
  </>
);

const Parcialmente = (text?: string) => (
  <>
    <FontIcon
      iconName="CircleHalfFull"
      style={{ fontSize: 16, marginRight: 5, color: "#FB6B20" }}
    />
    {text ?? "PARCIALMENTE ATINGIDO"}
  </>
);

const DentroExpectativa = (text?: string) => (
  <>
    <FontIcon
      iconName="SkypeCircleCheck"
      style={{ fontSize: 16, marginRight: 5, color: Colors.primary }}
    />
    {text ?? "DENTRO DA EXPECTATIVA"}
  </>
);

const convertAvaliacao = (result: any[]) => {
  const total = result
    ?.map((item) => item.resultado)
    .reduce((total, item) => convertAtitudesVal(item) + total, 0);
  const length = result?.length;
  const hasChanged = result?.some((item) => item.resultado === null);
  const valorAcima = Math.round(length * 1.3333);

  if (hasChanged) {
    return "";
  } else if (total >= valorAcima) return "Acima da Expectativa";
  else if (total >= length) return "Dentro da Expectativa";
  else return "Abaixo da Expectativa";
};

export const renderAvaliacao = (result: string) => {
  switch (result) {
    case "Abaixo da Expectativa":
      return AbaixoExpectativa();
    case "Dentro da Expectativa":
      return DentroExpectativa();
    case "Acima da Expectativa":
      return AcimaExpectativa();
    default:
      return result;
  }
};

const Accordion = withStyles({
  root: {
    padding: "0px !important",
    paddingBottom: 10,
    boxShadow: "none",
    display: "contents",

    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {
    borderBottom: 0,
  },
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    borderBottom: "1px solid #CFD3DA",
    height: 50,
    minHeight: "50px !important",
    "&:hover": {
      backgroundColor: "#F3F2F1",
    },
    expanded: {},
  },
})(MuiAccordionSummary);

const textStyles: ITextStyles = {
  root: {
    fontWeight: 500,
    display: "block",
  },
};

const dialogContentProps = {
  title: "Avaliação das Iniciativas",
};

const options: IDropdownOption[] = [
  {
    key: "Selecione uma opção",
    text: "Selecione uma opção",
    disabled: true,
    hidden: true,
    data: { styleOption: { fontSize: 14 } },
  },
  {
    key: "Acima da Expectativa",
    text: "ACIMA DA EXPECTATIVA",
    data: {
      icon: "SkypeCircleArrow",
      style: { color: Colors.blue, transform: "rotate(90deg)" },
    },
  },
  {
    key: "Dentro da Expectativa",
    text: "DENTRO DA EXPECTATIVA",
    data: { icon: "SkypeCircleCheck", style: { color: Colors.primary } },
  },
  {
    key: "Abaixo da Expectativa",
    text: "ABAIXO DA EXPECTATIVA",
    data: {
      icon: "SkypeCircleArrow",
      style: { color: Colors.error, transform: "rotate(-90deg)" },
    },
  },
];

const convertAtitudesVal = (result: string) => {
  switch (result) {
    case "Selecione uma opção":
      return 0;
    case "Acima da Expectativa":
      return 2;
    case "Dentro da Expectativa":
      return 1;
    case "Abaixo da Expectativa":
      return 0;
    default:
      return 0;
  }
};
const convertIniciativaResultVal = (result: string) => {
  switch (result) {
    case "Selecione uma opção":
      return 0;
    case "Superado":
      return 1.5;
    case "Atingido":
      return 1;
    case "Parcialmente atingido":
      return 0.5;
    case "Não atingido":
      return 0;
    case "Não se aplica":
      return 0;
    default:
      return 0;
  }
};

const optionsIniciativas: (IDropdownOption | any)[] = [
  {
    key: "Selecione uma opção",
    text: "Selecione uma opção",
    disabled: true,
    hidden: true,
    data: { styleOption: { fontSize: 14 } },
  },
  {
    key: "Superado",
    text: "SUPERADO",
    data: {
      icon: "SkypeCircleArrow",
      style: { color: Colors.blue, transform: "rotate(90deg)" },
    },
  },
  {
    key: "Atingido",
    text: "ATINGIDO",
    data: { icon: "SkypeCircleCheck", style: { color: Colors.primary } },
  },
  {
    key: "Parcialmente atingido",
    text: "PARCIALMENTE ATINGIDO",
    data: { icon: "CircleHalfFull", style: { color: "#FB6B20" } },
  },
  {
    key: "Não atingido",
    text: "NÃO ATINGIDO",
    data: {
      icon: "SkypeCircleArrow",
      style: { color: Colors.error, transform: "rotate(-90deg)" },
    },
  },
];

const optionsPotencial: IDropdownOption[] = [
  {
    key: "Selecione uma opção",
    text: "Selecione uma opção",
    disabled: true,
    hidden: true,
    data: { styleOption: { fontSize: 14 } },
  },
  {
    key: "Alto potencial para crescer",
    text: "ALTO POTENCIAL PARA CRESCER",
    data: { style: { color: "#027636" } },
  },
  {
    key: "Potencial para crescer",
    text: "POTENCIAL PARA CRESCER",
    data: { style: { color: "#88C8A5" } },
  },
  {
    key: "Potencial lateral",
    text: "POTENCIAL LATERAL",
    data: { style: { color: "#D4A31D" } },
  },
  {
    key: "Cedo demais para avaliar",
    text: "CEDO DEMAIS PARA AVALIAR",
    data: { style: { color: "#73AF8E" } },
  },
];

const optionsSucessao: IDropdownOption[] = [
  {
    key: "Selecione uma opção",
    text: "Selecione uma opção",
    disabled: true,
    hidden: true,
    data: { styleOption: { fontSize: 14 } },
  },
  {
    key: "Null",
    text: "",
    disabled: true,
    hidden: true,
    data: { styleOption: { fontSize: 14 } },
  },
  { key: "Imediato", text: "IMEDIATO", data: { style: { color: "#027636" } } },
  {
    key: "Curto Prazo - 1 a 2 anos",
    text: "CURTO PRAZO - 1 A 2 ANOS",
    data: { style: { color: "#73AF8E" } },
  },
  {
    key: "Médio Prazo - 3 a 5 anos",
    text: "MÉDIO PRAZO - 3 A 5 ANOS",
    data: { style: { color: "#88C8A5" } },
  },
  {
    key: "Longo Prazo - acima 5 anos",
    text: "LONGO PRAZO - ACIMA 5 ANOS",
    data: { style: { color: "#D4A31D" } },
  },
];

const validationGestorColetiva = yup.object({
  idCicloAvaliacaoAtitude: yup.number().notRequired(),
  atitudes: yup.array().of(
    yup.object().shape({
      resultado: yup.string().required("Campo obrigatório"),
      comentarios: yup.string().nullable().notRequired(),
    })
  ),
  aspiracoesCarreira: yup.string().required("Campo obrigatório"),
  comentariosGerais: yup.string().required("Campo obrigatório"),
  // idTabPerformanceGestor: yup.number().nullable().required("Campo obrigatório"),
  potencial: yup.string().required("Campo obrigatório"),
  sucessao: yup.string().nullable().notRequired(),
  comentariosGestor: yup.string().required("Campo obrigatório"),
  resultadometas: yup.string().required("Campo obrigatório"),
  comentariosPositivos: yup.string().required("Campo obrigatório"),
  comentariosMelhorar: yup.string().required("Campo obrigatório"),
});

const validationGestorDesligado = yup.object({
  idTabPerformanceGestor: yup.number().nullable().required("Campo obrigatório"),
  comentariosGestor: yup.string().required("Campo obrigatório"),
});

interface Istate {
  idCicloAvaliacaoAtitude: number;
  resultado: string | null;
  comentarios: string | null;
}

interface IFormGestorColetiva {
  atitudes: Istate[];
  aspiracoesCarreira: string;
  comentariosGerais: string;
  idTabPerformanceGestor: number | null;
  potencial: string;
  sucessao: string | null;
  comentariosGestor: string;
  resultadometas: string;
  comentariosPositivos: string;
  comentariosMelhorar: string;
}

const initialValuesGestorColetiva: IFormGestorColetiva = {
  atitudes: [],
  aspiracoesCarreira: "",
  comentariosPositivos: "",
  comentariosGerais: "",
  comentariosGestor: "",
  comentariosMelhorar: "",
  idTabPerformanceGestor: null,
  potencial: "",
  resultadometas: "",
  sucessao: "",
};

interface IFormGestorDesligado {
  idTabPerformanceGestor: number | null;
  comentariosGestor: string;
  atitudes?: [];
  aspiracoesCarreira?: "";
  comentariosPositivos?: "";
  comentariosGerais?: "";
  comentariosMelhorar?: "";
  potencial?: "";
  resultadometas?: "";
  sucessao?: "";
}

const initialValuesGestorDesligado: IFormGestorDesligado = {
  comentariosGestor: "",
  idTabPerformanceGestor: null,
};

const commandButtonStyle: Partial<ICommandBarStyles> = {
  root: {
    height: 44,
  },
};

export default SelfEvaluationRender;
