import { CycleType } from "../cycles/types";
import { PillarType } from "../pillars/types";
import { AvaliationType } from '../tasks/types';

/**
 * Types
 */
export enum Types {
    //GET
    REQUEST_CYCLE_LOAD = "cycle/REQUEST_CYCLE_LOAD",
    SUCCESS_CYCLE_LOAD = "cycle/SUCCESS_CYCLE_LOAD",
    FAILURE_CYCLE_LOAD = "cycle/FAILURE_CYCLE_LOAD",
    //UPDATE STATUS
    UPDATE_CYCLE_STATUS_TO_VALIDATION = "cycle/UPDATE_CYCLE_STATUS_TO_VALIDATION",
    //GET PILLARS
    REQUEST_CYCLE_PILLARS_LOAD = "cycle/REQUEST_CYCLE_PILLARS_LOAD",
    SUCCESS_CYCLE_PILLARS_LOAD = "cycle/SUCCESS_CYCLE_PILLARS_LOAD",
    FAILURE_CYCLE_PILLARS_LOAD = "cycle/FAILURE_CYCLE_PILLARS_LOAD",
    //ADD PILLAR
    REQUEST_CYCLES_ADD_PILLAR = "cycle/REQUEST_CYCLES_ADD_PILLAR",
    SUCCESS_CYCLES_ADD_PILLAR = "cycle/SUCCESS_CYCLES_ADD_PILLAR",
    FAILURE_CYCLES_ADD_PILLAR = "cycle/FAILURE_CYCLES_ADD_PILLAR",
    //DELETE PILLAR
    REQUEST_CYCLES_DELETE_PILLAR = "cycle/REQUEST_CYCLES_DELETE_PILLAR",
    SUCCESS_CYCLES_DELETE_PILLAR = "cycle/SUCCESS_CYCLES_DELETE_PILLAR",
    FAILURE_CYCLES_DELETE_PILLAR = "cycle/FAILURE_CYCLES_DELETE_PILLAR",
    //ADD OBJECTIVE
    REQUEST_CYCLES_ADD_OBJECTIVE = "cycle/REQUEST_CYCLES_ADD_OBJECTIVE",
    SUCCESS_CYCLES_ADD_OBJECTIVE = "cycle/SUCCESS_CYCLES_ADD_OBJECTIVE",
    FAILURE_CYCLES_ADD_OBJECTIVE = "cycle/FAILURE_CYCLES_ADD_OBJECTIVE",
    //DELETE OBJECTIVE
    REQUEST_CYCLES_DELETE_OBJECTIVE = "cycle/REQUEST_CYCLES_DELETE_OBJECTIVE",
    SUCCESS_CYCLES_DELETE_OBJECTIVE = "cycle/SUCCESS_CYCLES_DELETE_OBJECTIVE",
    FAILURE_CYCLES_DELETE_OBJECTIVE = "cycle/FAILURE_CYCLES_DELETE_OBJECTIVE",
    // NEXT PILLAR
    REQUEST_CYCLES_PILLAR_NEXT = "cycle/REQUEST_CYCLES_PILLAR_NEXT",
    SUCCESS_CYCLES_PILLAR_NEXT = "cycle/SUCCESS_CYCLES_PILLAR_NEXT",
    FAILURE_CYCLES_PILLAR_NEXT = "cycle/FAILURE_CYCLES_PILLAR_NEXT",
    // PRIOR PILLAR
    REQUEST_CYCLES_PILLAR_PRIOR = "cycle/REQUEST_CYCLES_PILLAR_PRIOR",
    SUCCESS_CYCLES_PILLAR_PRIOR = "cycle/SUCCESS_CYCLES_PILLAR_PRIOR",
    FAILURE_CYCLES_PILLAR_PRIOR = "cycle/FAILURE_CYCLES_PILLAR_PRIOR",
    //START VALIDATION
    REQUEST_VALIDATION_CYCLE = "cycles/REQUEST_VALIDATION_CYCLE",
    SUCCESS_VALIDATION_CYCLE = "cycles/SUCCESS_VALIDATION_CYCLE",
    FAILURE_VALIDATION_CYCLE = "cycles/FAILURE_VALIDATION_CYCLE",
    // RESET PESQUISA_FUNC
    RESET_PESQUISA_FUNC = "cycles/RESET_PESQUISA_FUNC",
    //GET AVALIACAO
    REQUEST_PESQUISA_FUNC = "cycles/REQUEST_PESQUISA_FUNC",
    SUCCESS_PESQUISA_FUNC = "cycles/SUCCESS_PESQUISA_FUNC",
    FAILURE_PESQUISA_FUNC = "cycles/FAILURE_PESQUISA_FUNC",

};
/**
 * Data types
 */
export type ObjectiveCycleType = {
    idCicloObjetivo: number | null;
    idObjetivo: number;
    objetivo: {
        descObjetivo: string;
    };
};

export type PillarsType = {
    idCiclo: number;
    idPilar: number;
    idCicloPilar: number;
    pilar: {
        nomePilar: string;
        corPilar: string;
    };
    objetivos: ObjectiveCycleType[];
}

export type Cycle = {
    cycle: Partial<CycleType>;
    pillars: PillarsType[];
};

export type AvaliationGestor = {
    idTabPerformance: number | null;
    potencial: string;
    sucessao: string;
    comentariosGestor: string;
}

export type PesquisaFuncType = {
    idFuncionario: number;
    nomeCompleto: string;
    idArea: number;
    nomeLocal: string;
    tipoLocal: string;
    titulo: string;
    flagLideranca: boolean;
    idWorklevel: number;
    nomeChefe: string;
}

interface ICycleState {
    readonly data: Cycle;
    readonly loading: boolean;
    readonly error: boolean;
    readonly successData: boolean;
    readonly loadingValidation: boolean;
    readonly successValidation: boolean;
    readonly avaliationSelected: AvaliationType | null;
    readonly pesquisaFunc: PesquisaFuncType[];
};

export type DataTypes = ICycleState;

/**
 * Actions
 */

//GET
export interface IRequestCycleLoadAction {
    type: typeof Types.REQUEST_CYCLE_LOAD;
    id: number;
};

export interface ISuccessCycleLoadAction {
    type: typeof Types.SUCCESS_CYCLE_LOAD;
    payload: Partial<CycleType>;
};

interface IFailureCycleLoadAction {
    type: typeof Types.FAILURE_CYCLE_LOAD;
    message: string;
};

export interface IUpdateCycleStatusAction {
    type: typeof Types.UPDATE_CYCLE_STATUS_TO_VALIDATION;
    idCiclo: number;
}

type CycleLoadAction = IRequestCycleLoadAction | ISuccessCycleLoadAction | IFailureCycleLoadAction | IUpdateCycleStatusAction;

//GET
export interface IRequestCyclePillarsLoadAction {
    type: typeof Types.REQUEST_CYCLE_PILLARS_LOAD;
    id: number;
};

interface ISuccessCyclePillarsLoadAction {
    type: typeof Types.SUCCESS_CYCLE_PILLARS_LOAD;
    payload: PillarsType[];
};

interface IFailureCyclePillarsLoadAction {
    type: typeof Types.FAILURE_CYCLE_PILLARS_LOAD;
    message: string;
};

type CycleLoadPillarsAction = IRequestCyclePillarsLoadAction | ISuccessCyclePillarsLoadAction | IFailureCyclePillarsLoadAction;

//ADD PILAR
export interface IRequestCyclesAddPillarAction {
    type: typeof Types.REQUEST_CYCLES_ADD_PILLAR;
    payload: {
        idCiclo: number;
        pillar: PillarType;
    };
};

export interface ISuccessCyclesAddPillarAction {
    type: typeof Types.SUCCESS_CYCLES_ADD_PILLAR;
    pillar: PillarsType;
};

interface IFailureCyclesAddPillarAction {
    type: typeof Types.FAILURE_CYCLES_ADD_PILLAR;
    message: string;
};

type CyclesAddPillarAction = IRequestCyclesAddPillarAction | ISuccessCyclesAddPillarAction | IFailureCyclesAddPillarAction;

//ADD OBJETIVO
export interface IRequestCyclesAddObjectiveAction {
    type: typeof Types.REQUEST_CYCLES_ADD_OBJECTIVE;
    payload: {
        idCiclo: number;
        idCicloPilar: number;
        objetivo: ObjectiveCycleType;
    };
};

export interface ISuccessCyclesAddObjectiveAction {
    type: typeof Types.SUCCESS_CYCLES_ADD_OBJECTIVE;
    payload: {
        objective: ObjectiveCycleType;
        idCicloPilar: number;
    }
};

interface IFailureCyclesAddObjectiveAction {
    type: typeof Types.FAILURE_CYCLES_ADD_OBJECTIVE;
    message: string;
};

type CyclesAddObjectiveAction = IRequestCyclesAddObjectiveAction | ISuccessCyclesAddObjectiveAction | IFailureCyclesAddObjectiveAction;

//DELETE OBJETIVO
export interface IRequestCyclesDeleteObjectiveAction {
    type: typeof Types.REQUEST_CYCLES_DELETE_OBJECTIVE;
    payload: {
        idCiclo: number;
        idCicloObjetivo: number;
    };
};

export interface ISuccessCyclesDeleteObjectiveAction {
    type: typeof Types.SUCCESS_CYCLES_DELETE_OBJECTIVE;
    idCicloObjetivo: number;
};

interface IFailureCyclesDeleteObjectiveAction {
    type: typeof Types.FAILURE_CYCLES_DELETE_OBJECTIVE;
    message: string;
};

type CyclesDeleteObjectiveAction = IRequestCyclesDeleteObjectiveAction | ISuccessCyclesDeleteObjectiveAction | IFailureCyclesDeleteObjectiveAction;

//DELETE PILLAR
export interface IRequestCyclesDeletePillarAction {
    type: typeof Types.REQUEST_CYCLES_DELETE_PILLAR;
    payload: {
        idCiclo: number;
        idCicloPilar: number;
    };
};

export interface ISuccessCyclesDeletePillarAction {
    type: typeof Types.SUCCESS_CYCLES_DELETE_PILLAR;
    idCicloPilar: number;
};

interface IFailureCyclesDeletePillarAction {
    type: typeof Types.FAILURE_CYCLES_DELETE_PILLAR;
    message: string;
};

type CyclesDeletePillarAction = IRequestCyclesDeletePillarAction | ISuccessCyclesDeletePillarAction | IFailureCyclesDeletePillarAction;

//NEXT PILLAR
export interface IRequestCyclesPillarNextAction {
    type: typeof Types.REQUEST_CYCLES_PILLAR_NEXT;
    payload: {
        idCiclo: number;
        idCicloPilar: number;
    };
};

export interface ISuccessCyclesPillarNextAction {
    type: typeof Types.SUCCESS_CYCLES_PILLAR_NEXT;
    idCicloPilar: number;
};

interface IFailureCyclesPillarNextAction {
    type: typeof Types.FAILURE_CYCLES_PILLAR_NEXT;
    message: string;
};

type CyclesPillarNextAction = IRequestCyclesPillarNextAction | ISuccessCyclesPillarNextAction | IFailureCyclesPillarNextAction;

//PRIOR PILLAR
export interface IRequestCyclesPillarPriorAction {
    type: typeof Types.REQUEST_CYCLES_PILLAR_PRIOR;
    payload: {
        idCiclo: number;
        idCicloPilar: number;
    };
};

export interface ISuccessCyclesPillarPriorAction {
    type: typeof Types.SUCCESS_CYCLES_PILLAR_PRIOR;
    idCicloPilar: number;
};

interface IFailureCyclesPillarPriorAction {
    type: typeof Types.FAILURE_CYCLES_PILLAR_PRIOR;
    message: string;
};

type CyclesPillaPriorAction = IRequestCyclesPillarPriorAction | ISuccessCyclesPillarPriorAction | IFailureCyclesPillarPriorAction;


//REQUEST_VALIDATION_CYCLE
export interface IRequestCycleValidationAction {
    type: typeof Types.REQUEST_VALIDATION_CYCLE;
    idCiclo: number;
};

export interface ISuccessCycleValidationAction {
    type: typeof Types.SUCCESS_VALIDATION_CYCLE;
    status: string;
};

export interface IFailureCycleValidationAction {
    type: typeof Types.FAILURE_VALIDATION_CYCLE;
    message: string;
};

type CycleStartValidationAction = IRequestCycleValidationAction | ISuccessCycleValidationAction | IFailureCycleValidationAction;

//REQUEST AVALIACAO SAVE
export interface IRequestCyclePesquisaFuncAction {
    type: typeof Types.REQUEST_PESQUISA_FUNC;
    payload: {
        idCiclo: number;
        nomePesq?: string;
        idArea?: number | null;
        idChefe?: number | null;
        worklevel?: number | null;
    }
};

export interface ISuccessCyclePesquisaFuncAction {
    type: typeof Types.SUCCESS_PESQUISA_FUNC;
    payload: PesquisaFuncType[];
};

export interface IFailureCyclePesquisaFuncAction {
    type: typeof Types.FAILURE_PESQUISA_FUNC;
    message: string;
};

type CyclePesquisaFuncAction = IRequestCyclePesquisaFuncAction | ISuccessCyclePesquisaFuncAction | IFailureCyclePesquisaFuncAction;

interface IResetResetPesquisaFuncAction {
    type: typeof Types.RESET_PESQUISA_FUNC
}

//#endregion

export type CycleActionTypes =
    CycleLoadAction |
    CycleLoadPillarsAction |
    CyclesAddPillarAction |
    CyclesAddObjectiveAction |
    CyclesDeleteObjectiveAction |
    CyclesDeletePillarAction |
    CyclesPillarNextAction |
    CyclesPillaPriorAction |
    CycleStartValidationAction |
    CyclePesquisaFuncAction |
    IResetResetPesquisaFuncAction;

