import { takeEvery, put, call, all } from "redux-saga/effects";
import {
  Types,
  IRequestMeritLoadAction,
  IRequestMeritSeasonsAddAction,
  IRequestMeritLoadByIdAction,
  IRequestParametroEditAction,
  IRequestMeritPerformanceLoadByIdAction,
  IRequestMeritPerformanceEditAction,
  ISuccessMeritLoadAction,
  ISuccessMeritSeasonsLoadAction,
  ISuccessMeritSeasonsAddAction,
  ISuccessMeritLoadByIdAction,
  ISuccessParametroEditAction,
  ISuccessMeritPerformanceLoadByIdAction,
  ISuccessMeritPerformanceEditAction,
  MeritSeasonType,
  MeritType,
  ParametroType,
  MeritPerformanceType,
} from "../../ducks/admin/merit/types";
import api from "~/services/api";

const apiCallGet = (idConfigMerito: number) => {
  return api
    .get(`admin/merito/temporadas/${idConfigMerito}`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallGetParametroById = (
  payload: IRequestMeritLoadByIdAction["payload"]
) => {
  return api
    .get(
      `admin/merito/temporadas/${payload.idConfigMerito}/parametro/${payload.idConfigMeritoParametro}`
    )
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallEditParametro = (
  payload: IRequestParametroEditAction["payload"]
) => {
  return api
    .put(
      `admin/merito/temporadas/${payload.idConfigMerito}/parametro/${payload.idConfigMeritoParametro}`,
      {
        ...payload,
      }
    )
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallLoadPerformance = (
  payload: IRequestMeritPerformanceLoadByIdAction["payload"]
) => {
  return api
    .get(
      `admin/merito/temporadas/${payload.idConfigMerito}/performance/${payload.idConfigMeritoPerformance}`
    )
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallEditPerformance = (
  payload: IRequestMeritPerformanceEditAction["payload"]
) => {
  return api
    .put(
      `admin/merito/temporadas/${payload.idConfigMerito}/performance/${payload.performance.idConfigMeritoPerformance}`,
      {
        ...payload.performance,
      }
    )
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallGetSeason = () => {
  return api
    .get(`admin/merito/temporadas`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallAdd = (payload: IRequestMeritSeasonsAddAction["payload"]) => {
  return api
    .post(`admin/merito/temporadas`, {
      ...payload,
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

function* load(action: IRequestMeritLoadAction) {
  try {
    let items: MeritType = yield call(apiCallGet, action.idConfigMerito);
    yield put({
      type: Types.SUCCESS_MERIT_LOAD,
      payload: items,
    } as ISuccessMeritLoadAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível carregar as configurações de mérito";
    yield put({
      type: Types.FAILURE_MERIT_LOAD,
      message,
    });
  }
}

function* loadParametroById(action: IRequestMeritLoadByIdAction) {
  try {
    let item: ParametroType = yield call(
      apiCallGetParametroById,
      action.payload
    );
    yield put({
      type: Types.SUCCESS_MERIT_LOAD_BY_ID,
      payload: item,
    } as ISuccessMeritLoadByIdAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível carregar a configuração";
    yield put({
      type: Types.FAILURE_MERIT_LOAD_BY_ID,
      message,
    });
  }
}

function* editParametro(action: IRequestParametroEditAction) {
  try {
    yield call(apiCallEditParametro, action.payload);
    yield put({
      type: Types.SUCCESS_PARAMETRO_EDIT,
      payload: {
        idConfigMeritoParametro: action.payload.idConfigMeritoParametro,
        valor: action.payload.valor,
      },
    } as ISuccessParametroEditAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível editar a configuração";
    yield put({
      type: Types.FAILURE_PARAMETRO_EDIT,
      message,
    });
  }
}

function* loadPerformanceById(action: IRequestMeritPerformanceLoadByIdAction) {
  try {
    const data: MeritPerformanceType = yield call(
      apiCallLoadPerformance,
      action.payload
    );
    yield put({
      type: Types.SUCCESS_MERIT_PERFORMANCE_LOAD_BY_ID,
      payload: data,
    } as ISuccessMeritPerformanceLoadByIdAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível carregar a performance";
    yield put({
      type: Types.FAILURE_MERIT_PERFORMANCE_LOAD_BY_ID,
      message,
    });
  }
}

function* editPerformance(action: IRequestMeritPerformanceEditAction) {
  try {
    yield call(apiCallEditPerformance, action.payload);
    yield put({
      type: Types.SUCCESS_MERIT_PERFORMANCE_EDIT,
      payload: action.payload.performance,
    } as ISuccessMeritPerformanceEditAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível editar a performance";
    yield put({
      type: Types.FAILURE_MERIT_PERFORMANCE_EDIT,
      message,
    });
  }
}

function* loadSeason() {
  try {
    let items: MeritSeasonType[] = yield call(apiCallGetSeason);
    yield put({
      type: Types.SUCCESS_MERIT_SEASONS_LOAD,
      payload: items,
    } as ISuccessMeritSeasonsLoadAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível carregar as temporadas";
    yield put({
      type: Types.FAILURE_MERIT_SEASONS_LOAD,
      message,
    });
  }
}

function* addSeason(action: IRequestMeritSeasonsAddAction) {
  try {
    let item: MeritType = yield call(apiCallAdd, action.payload);
    yield put({
      type: Types.SUCCESS_MERIT_SEASONS_ADD,
      payload: item,
    } as ISuccessMeritSeasonsAddAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível adicionar temporada";
    yield put({
      type: Types.FAILURE_MERIT_SEASONS_ADD,
      message,
    });
  }
}

export default function* meritRoot() {
  yield all([
    yield takeEvery(Types.REQUEST_MERIT_LOAD, load),
    yield takeEvery(Types.REQUEST_MERIT_LOAD_BY_ID, loadParametroById),
    yield takeEvery(Types.REQUEST_PARAMETRO_EDIT, editParametro),
    yield takeEvery(
      Types.REQUEST_MERIT_PERFORMANCE_LOAD_BY_ID,
      loadPerformanceById
    ),
    yield takeEvery(Types.REQUEST_MERIT_PERFORMANCE_EDIT, editPerformance),
    yield takeEvery(Types.REQUEST_MERIT_SEASONS_LOAD, loadSeason),
    yield takeEvery(Types.REQUEST_MERIT_SEASONS_ADD, addSeason),
  ]);
}
