import customToast from "~/components/Toast/index"

import {
  Types,
  DataTypes,
  ValidationCycleActionTypes,
  ISuccessValidationAreaAction,
  ValidationsType
} from './types';

/**
 * Reducers
 */
const INITIAL_STATE: DataTypes = {
  data: {
    status: '',
    validacoes: []
  },
  error: false,
  loadingData: false,
  success: false,
  flagStartValidation: false,
  loadingArea: false,
  finishedValidation: false
}

/**
 * Actions
 */
export const Creators = {
  getValidationCycle: (idCiclo: number): ValidationCycleActionTypes => ({
    type: Types.REQUEST_CYCLE_VALIDATION,
    idCiclo
  }),

  getValidationArea: (idCiclo: number, idArea: number): ValidationCycleActionTypes => ({
    type: Types.REQUEST_CYCLE_VALIDATION_AREA,
    payload: {
      idArea,
      idCiclo
    }
  }),

  getPublishingArea: (idCiclo: number, idArea: number): ValidationCycleActionTypes => ({
    type: Types.REQUEST_CYCLE_PUBLISH_AREA,
    payload: {
      idCiclo,
      idArea
    }
  }),

  getPublishingVisualizationArea: (idCiclo: number, idArea: number): ValidationCycleActionTypes => ({
    type: Types.REQUEST_CYCLE_PUBLISH_VISUALIZATION,
    payload: {
      idCiclo,
      idArea
    }
  })
}


export default function cycleReducer(state = INITIAL_STATE, action: ValidationCycleActionTypes): DataTypes {
  switch (action.type) {

    case Types.REQUEST_CYCLE_VALIDATION:
      return {
        ...state,
        error: false,
        success: false,
        loadingData: state.data.validacoes.length > 0 ? false : true
      };
    case Types.SUCCESS_CYCLE_VALIDATION:
      return { ...state, data: action.payload.data, error: false, success: true, loadingData: false };
    case Types.FAILURE_CYCLE_VALIDATION:
      customToast.error(action.message);
      return { ...state, data: { status: '', validacoes: [] }, error: true, success: false, loadingData: false };

    case Types.REQUEST_CYCLE_VALIDATION_AREA:
      return { ...state, loadingArea: true, success: false, error: false };
    case Types.SUCCESS_CYCLE_VALIDATION_AREA:
      if (action.payload.areaTree.flagValidado && !action.payload.areaTree.flagError) {
        customToast.success("Área validada com sucesso!");
      } else {
        customToast.warning("Área validada com erro!");
      }
      return { ...state, data: { ...state.data, validacoes: validateData(state.data.validacoes, action.payload) }, loadingArea: false, success: true, error: false };
    case Types.FAILURE_CYCLE_VALIDATION_AREA:
      customToast.error(action.message);
      return { ...state, loadingArea: false, success: false, error: true };

    case Types.REQUEST_CYCLE_PUBLISH_AREA:
      return { ...state, loadingArea: true, success: false, error: false };
    case Types.SUCCESS_CYCLE_PUBLISH_AREA:
      customToast.success("Área publicada com sucesso!");
      return { ...state, data: { ...state.data, validacoes: validateData(state.data.validacoes, action.payload) }, loadingArea: false, success: true, error: false };
    case Types.FAILURE_CYCLE_PUBLISH_AREA:
      customToast.error(action.message);
      return { ...state, loadingArea: false, success: false, error: true };

    //REQUEST_CYCLE_PUBLISH_VISUALIZATION
    case Types.REQUEST_CYCLE_PUBLISH_VISUALIZATION:
      return { ...state, loadingArea: true, success: false, error: false };
    case Types.SUCCESS_CYCLE_PUBLISH_VISUALIZATION:
      customToast.success("Área disponibilizada com sucesso!");
      return { ...state, data: { ...state.data, validacoes: validateData(state.data.validacoes, action.payload) }, loadingArea: false, success: true, error: false };
    case Types.FAILURE_CYCLE_PUBLISH_VISUALIZATION:
      customToast.error(action.message);
      return { ...state, loadingArea: false, success: false, error: true };

    default:
      return state;
  }
}

const validateData = (data: ValidationsType[], payload: ISuccessValidationAreaAction["payload"]): ValidationsType[] => {
  const result = data.map(item => {
    if (item.idArea !== payload.idArea) {
      return { ...item, children: findTree(item.children, payload) }
    } else {
      return item;
    }
  })
  return result;
}

const findTree = (children: ValidationsType[], payload: ISuccessValidationAreaAction["payload"]): ValidationsType[] => {
  const { idArea, areaTree } = payload;
  return children.map(item => {
    if (item.idArea !== idArea) {
      if (item.children.length > 0) {
        return { ...item, children: findTree(item.children, payload) };
      } else {
        return item;
      }
    } else {
      return areaTree;
    }
  })
}