import { ICompany } from "../companies/types";

/**
 * Types
 */
export enum Types {
    //GET
    REQUEST_UNITS_LOAD = "units/REQUEST_UNITS_LOAD",
    SUCCESS_UNITS_LOAD = "units/SUCCESS_UNITS_LOAD",
    FAILURE_UNITS_LOAD = "units/FAILURE_UNITS_LOAD",
    //GET BY ID
    REQUEST_UNITS_LOAD_ID = "units/REQUEST_UNITS_LOAD_ID",
    SUCCESS_UNITS_LOAD_ID = "units/SUCCESS_UNITS_LOAD_ID",
    FAILURE_UNITS_LOAD_ID = "units/FAILURE_UNITS_LOAD_ID",
    //ADD
    REQUEST_UNIT_ADD = "units/REQUEST_UNIT_ADD",
    SUCCESS_UNIT_ADD = "units/SUCCESS_UNIT_ADD",
    FAILURE_UNIT_ADD = "units/FAILURE_UNIT_ADD",
    //PUT
    REQUEST_UNIT_EDIT = "units/REQUEST_UNIT_EDIT",
    SUCCESS_UNIT_EDIT = "units/SUCCESS_UNIT_EDIT",
    FAILURE_UNIT_EDIT = "units/FAILURE_UNIT_EDIT",
    //DELETE
    REQUEST_UNIT_DELETE = "units/REQUEST_UNIT_DELETE",
    SUCCESS_UNIT_DELETE = "units/SUCCESS_UNIT_DELETE",
    FAILURE_UNIT_DELETE = "units/FAILURE_UNIT_DELETE",
    //GET ES
    REQUEST_UNIT_LOAD_ES_BY_ID = "units/REQUEST_UNIT_LOAD_ES_BY_ID",
    SUCCESS_UNIT_LOAD_ES_BY_ID = "units/SUCCESS_UNIT_LOAD_ES_BY_ID",
    FAILURE_UNIT_LOAD_ES_BY_ID = "units/FAILURE_UNIT_LOAD_ES_BY_ID",
    //ADD ES
    REQUEST_UNIT_ADD_ES = "units/REQUEST_UNIT_ADD_ES",
    SUCCESS_UNIT_ADD_ES = "units/SUCCESS_UNIT_ADD_ES",
    FAILURE_UNIT_ADD_ES = "units/FAILURE_UNIT_ADD_ES",
    //EDIT ES
    REQUEST_UNIT_EDIT_ES = "units/REQUEST_UNIT_EDIT_ES",
    SUCCESS_UNIT_EDIT_ES = "units/SUCCESS_UNIT_EDIT_ES",
    FAILURE_UNIT_EDIT_ES = "units/FAILURE_UNIT_EDIT_ES",
    //DEL ES
    REQUEST_UNIT_DEL_ES = "units/REQUEST_UNIT_DEL_ES",
    SUCCESS_UNIT_DEL_ES = "units/SUCCESS_UNIT_DEL_ES",
    FAILURE_UNIT_DEL_ES = "units/FAILURE_UNIT_DEL_ES",
};
/**
 * Data types
 */


export interface IUnit {
    idUnidade: number | null;
    idEmpresa: number | null;
    empresa: ICompany;
    nomeUnidade: string;
    CNPJ: string;
    tipoEndereco: string;
    logEndereco: string;
    numEndereco: string;
    compEndereco: string;
    bairro: string;
    cidade?: string;
    uf: string;
    cep: string;
    flagAtiva: boolean;
    es: UnitESType[];
};

export type UnitESType = {
    idUnidadeES: number | null;
    idCarreira?: number | null;
    idES: number | null;
    es: {
        nomeES: string;
    }
    carreira: {
        idCarreira: number;
        nomeCarreira: string;
    } | null;
}

interface ItemSelected {
    item: IUnit | null;
    loading: boolean;
    success: boolean;
    error: boolean;
}

interface ItemESSelected {
    item: UnitESType | null;
    loading: boolean;
    success: boolean;
    error: boolean;
}

interface IUnitsState {
    readonly data: IUnit[];
    readonly loadingData: boolean;
    readonly loading: boolean;
    readonly error: boolean;
    readonly success: boolean;
    readonly itemSelected: ItemSelected;
    readonly itemEsSelected: ItemESSelected;
};

export type DataTypes = IUnitsState;

/**
 * Actions
 */

//GET
export interface IRequestUnitsLoadAction {
    type: typeof Types.REQUEST_UNITS_LOAD;
    search: object;
};

export interface ISuccessUnitsLoadAction {
    type: typeof Types.SUCCESS_UNITS_LOAD;
    payload: IUnit[];
};

interface IFailureUnitsLoadAction {
    type: typeof Types.FAILURE_UNITS_LOAD;
    message: string;
};

type UnitsLoadAction = IRequestUnitsLoadAction | ISuccessUnitsLoadAction | IFailureUnitsLoadAction;

//GET
export interface IRequestUnitsLoadByIdAction {
    type: typeof Types.REQUEST_UNITS_LOAD_ID;
    unit: IUnit;
};

export interface ISuccessUnitsLoadByIdAction {
    type: typeof Types.SUCCESS_UNITS_LOAD_ID;
    unit: IUnit;
};

interface IFailureUnitsLoadByIdAction {
    type: typeof Types.FAILURE_UNITS_LOAD_ID;
    message: string;
};

type UnitsLoadByIdAction = IRequestUnitsLoadByIdAction | ISuccessUnitsLoadByIdAction | IFailureUnitsLoadByIdAction;

//ADD
export interface IRequestUnitAddAction {
    type: typeof Types.REQUEST_UNIT_ADD;
    unit: IUnit;
};

interface ISuccessUnitAddAction {
    type: typeof Types.SUCCESS_UNIT_ADD;
    unit: IUnit;
};

interface IFailureUnitAddAction {
    type: typeof Types.FAILURE_UNIT_ADD;
    message: string;
};

type UnitAddAction = IRequestUnitAddAction | ISuccessUnitAddAction | IFailureUnitAddAction;

//PUT
export interface IRequestUnitEditAction {
    type: typeof Types.REQUEST_UNIT_EDIT;
    unit: IUnit;
};

export interface ISuccessUnitEditAction {
    type: typeof Types.SUCCESS_UNIT_EDIT;
    unit: IUnit;
};

export interface IFailureUnitEditAction {
    type: typeof Types.FAILURE_UNIT_EDIT;
    message: string;
};

type UnitEditAction = IRequestUnitEditAction | ISuccessUnitEditAction | IFailureUnitEditAction;


//DELETE
export interface IRequestUnitDeleteAction {
    type: typeof Types.REQUEST_UNIT_DELETE;
    id: number;
};

export interface ISuccessUnitDeleteAction {
    type: typeof Types.SUCCESS_UNIT_DELETE;
    id: number;
};

export interface IFailureUnitDeleteAction {
    type: typeof Types.FAILURE_UNIT_DELETE;
    message: string;
};

type UnitDeleteAction = IRequestUnitDeleteAction | ISuccessUnitDeleteAction | IFailureUnitDeleteAction;

//GET ES BY ID
export interface IRequestUnitLoadEsByIdAction {
    type: typeof Types.REQUEST_UNIT_LOAD_ES_BY_ID;
    payload: {
        idUnidade: number;
        idUnidadeES: number;
    }
};

export interface ISuccessUnitLoadEsByIdAction {
    type: typeof Types.SUCCESS_UNIT_LOAD_ES_BY_ID;
    payload: UnitESType;
};

export interface IFailureUnitLoadEsByIdAction {
    type: typeof Types.FAILURE_UNIT_LOAD_ES_BY_ID;
    message: string;
};

type UnitLoadEsByIdAction = IRequestUnitLoadEsByIdAction | ISuccessUnitLoadEsByIdAction | IFailureUnitLoadEsByIdAction;

//ADD ES
export interface IRequestUnitAddEsAction {
    type: typeof Types.REQUEST_UNIT_ADD_ES;
    payload: {
        idUnidade: number;
        es: UnitESType;
    }
};

export interface ISuccessUnitAddEsAction {
    type: typeof Types.SUCCESS_UNIT_ADD_ES;
    payload: UnitESType;
};

export interface IFailureUnitAddEsAction {
    type: typeof Types.FAILURE_UNIT_ADD_ES;
    message: string;
};

type UnitAddEsAction = IRequestUnitAddEsAction | ISuccessUnitAddEsAction | IFailureUnitAddEsAction;

//EDIT ES
export interface IRequestUnitEditEsAction {
    type: typeof Types.REQUEST_UNIT_EDIT_ES;
    payload: {
        idUnidade: number;
        es: UnitESType;
    }
};

export interface ISuccessUnitEditEsAction {
    type: typeof Types.SUCCESS_UNIT_EDIT_ES;
    payload: UnitESType;
};

export interface IFailureUnitEditEsAction {
    type: typeof Types.FAILURE_UNIT_EDIT_ES;
    message: string;
};

type UnitEditEsAction = IRequestUnitEditEsAction | ISuccessUnitEditEsAction | IFailureUnitEditEsAction;

//DEL ES
export interface IRequestUnitDelEsAction {
    type: typeof Types.REQUEST_UNIT_DEL_ES;
    payload: {
        idUnidade: number;
        idUnidadeES: number;
    }
};

export interface ISuccessUnitDelEsAction {
    type: typeof Types.SUCCESS_UNIT_DEL_ES;
    idUnidadeES: number;
};

export interface IFailureUnitDelEsAction {
    type: typeof Types.FAILURE_UNIT_DEL_ES;
    message: string;
};

type UnitDelEsAction = IRequestUnitDelEsAction | ISuccessUnitDelEsAction | IFailureUnitDelEsAction;


export type UnitsActionTypes =
    UnitsLoadAction |
    UnitAddAction |
    UnitEditAction |
    UnitDeleteAction |
    UnitsLoadByIdAction |
    UnitAddEsAction |
    UnitEditEsAction |
    UnitDelEsAction |
    UnitLoadEsByIdAction;