import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { Fabric } from "office-ui-fabric-react/lib/Fabric";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { ToastContainer } from "react-toastify";
import { loadTheme } from "@fluentui/react";
import { store, persistor } from "./store";
import Routes from "./services/routes";
import "~/assets/css/global.css";
import "react-toastify/dist/ReactToastify.css";
import "office-ui-fabric-react/dist/css/fabric.css";

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      "'Segoe UI'",
      "Tahoma",
      "Geneva",
      "Verdana",
      "sans-serif",
    ].join(","),
  },
  palette: {
    primary: {
      main: "#275937",
    },
  },
});

function App() {
  loadTheme({
    palette: {
      themePrimary: "#275937",
      themeLighterAlt: "#f3f8f5",
      themeLighter: "#d0e4d6",
      themeLight: "#abcdb6",
      themeTertiary: "#679c78",
      themeSecondary: "#376d48",
      themeDarkAlt: "#235032",
      themeDark: "#1e442a",
      themeDarker: "#16321f",
      neutralLighterAlt: "#faf9f8",
      neutralLighter: "#f3f2f1",
      neutralLight: "#edebe9",
      neutralQuaternaryAlt: "#e1dfdd",
      neutralQuaternary: "#d0d0d0",
      neutralTertiaryAlt: "#c8c6c4",
      neutralTertiary: "#a19f9d",
      neutralSecondary: "#605e5c",
      neutralPrimaryAlt: "#3b3a39",
      neutralPrimary: "#323130",
      neutralDark: "#201f1e",
      black: "#000000",
      white: "#ffffff",
    },
  });

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <Fabric>
            <ToastContainer position="bottom-center" />
            <Routes />
          </Fabric>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
