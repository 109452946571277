/**
 * Types
 */
export enum Types {
  //GET
  REQUEST_OBJECTIVES_LOAD = "objectives/REQUEST_OBJECTIVES_LOAD",
  SUCCESS_OBJECTIVES_LOAD = "objectives/SUCCESS_OBJECTIVES_LOAD",
  FAILURE_OBJECTIVES_LOAD = "objectives/FAILURE_OBJECTIVES_LOAD",
  //GET BY ID
  REQUEST_OBJECTIVES_LOAD_ID = "objectives/REQUEST_OBJECTIVES_LOAD_ID",
  SUCCESS_OBJECTIVES_LOAD_ID = "objectives/SUCCESS_OBJECTIVES_LOAD_ID",
  FAILURE_OBJECTIVES_LOAD_ID = "objectives/FAILURE_OBJECTIVES_LOAD_ID",
  //ADD
  REQUEST_OBJECTIVES_ADD = "objectives/REQUEST_OBJECTIVES_ADD",
  SUCCESS_OBJECTIVES_ADD = "objectives/SUCCESS_OBJECTIVES_ADD",
  FAILURE_OBJECTIVES_ADD = "objectives/FAILURE_OBJECTIVES_ADD",
  //PUT
  REQUEST_OBJECTIVES_EDIT = "objectives/REQUEST_OBJECTIVES_EDIT",
  SUCCESS_OBJECTIVES_EDIT = "objectives/SUCCESS_OBJECTIVES_EDIT",
  FAILURE_OBJECTIVES_EDIT = "objectives/FAILURE_OBJECTIVES_EDIT",
  //DELETE
  REQUEST_OBJECTIVES_DELETE = "objectives/REQUEST_OBJECTIVES_DELETE",
  SUCCESS_OBJECTIVES_DELETE = "objectives/SUCCESS_OBJECTIVES_DELETE",
  FAILURE_OBJECTIVES_DELETE = "objectives/FAILURE_OBJECTIVES_DELETE",
  //RESET
  RESET_OBJETIVES = "objectives/RESET_OBJECTIVES",
}

/**
 * Data types
 */

export type ItemSelectedType = {
  item: ObjectivesType;
  loading: boolean;
  success: boolean;
  error: boolean;
};

type ItemParent = {
  descObjetivo: string;
};

export type ObjectivesType = {
  idObjetivo: number | null;
  codObjetivo: string;
  descObjetivo: string;
  descIngles: string;
  nivel?: string;
  unidade: string;
  abrangencia?: string;
  descricaoMecanica: string;
  idObjetivoPai: number | null;
  children: ObjectivesType[];
  flagMostrarTodos: boolean;
  objPai: ItemParent | null;
  mecanicaCalculo: string;
  flagAtivo: boolean;
  casasDecimais?: number | null;
};

interface IObjectivesState {
  readonly data: ObjectivesType[];
  readonly loading: boolean;
  readonly loadingData: boolean;
  readonly error: boolean;
  readonly errorLoading: boolean;
  readonly success: boolean;
  readonly itemSelected: ItemSelectedType;
}

export type DataTypes = IObjectivesState;

/**
 * Actions
 */

//GET
export interface IRequestObjectivesLoadAction {
  type: typeof Types.REQUEST_OBJECTIVES_LOAD;
  payload: {
    search: string;
    treeview: boolean;
    filterAtivo?: boolean;
    filterPai?: number;
  };
}

interface ISuccessObjectivesLoadAction {
  type: typeof Types.SUCCESS_OBJECTIVES_LOAD;
  payload: ObjectivesType[];
}

interface IFailureObjectivesLoadAction {
  type: typeof Types.FAILURE_OBJECTIVES_LOAD;
  message: string;
}

type ObjectivesLoadAction =
  | IRequestObjectivesLoadAction
  | ISuccessObjectivesLoadAction
  | IFailureObjectivesLoadAction;

//GET
export interface IRequestObjectiveLoadByIdAction {
  type: typeof Types.REQUEST_OBJECTIVES_LOAD_ID;
  id: number;
}

export interface ISuccessObjectiveLoadByIdAction {
  type: typeof Types.SUCCESS_OBJECTIVES_LOAD_ID;
  objective: ObjectivesType;
}

interface IFailureObjectiveLoadByIdAction {
  type: typeof Types.FAILURE_OBJECTIVES_LOAD_ID;
  message: string;
}

type ObjectiveLoadByIdAction =
  | IRequestObjectiveLoadByIdAction
  | ISuccessObjectiveLoadByIdAction
  | IFailureObjectiveLoadByIdAction;

//ADD
export interface IRequestObjectivesAddAction {
  type: typeof Types.REQUEST_OBJECTIVES_ADD;
  payload: {
    objective: ObjectivesType | Partial<ObjectivesType>;
    showInItemSelected?: boolean;
  };
}

interface ISuccessObjectivesAddAction {
  type: typeof Types.SUCCESS_OBJECTIVES_ADD;
  payload: {
    objective: ObjectivesType;
    showInItemSelected?: boolean;
  };
}

interface IFailureObjectivesAddAction {
  type: typeof Types.FAILURE_OBJECTIVES_ADD;
  message: string;
}

type ObjectivesAddAction =
  | IRequestObjectivesAddAction
  | ISuccessObjectivesAddAction
  | IFailureObjectivesAddAction;

//PUT
export interface IRequestObjectivesEditAction {
  type: typeof Types.REQUEST_OBJECTIVES_EDIT;
  objective: ObjectivesType;
}

export interface ISuccessObjectivesEditAction {
  type: typeof Types.SUCCESS_OBJECTIVES_EDIT;
  objective: ObjectivesType;
}

export interface IFailureObjectivesEditAction {
  type: typeof Types.FAILURE_OBJECTIVES_EDIT;
  message: string;
}

type ObjectivesEditAction =
  | IRequestObjectivesEditAction
  | ISuccessObjectivesEditAction
  | IFailureObjectivesEditAction;

//DELETE
export interface IRequestObjectivesDeleteAction {
  type: typeof Types.REQUEST_OBJECTIVES_DELETE;
  id: number;
}

export interface ISuccessObjectivesDeleteAction {
  type: typeof Types.SUCCESS_OBJECTIVES_DELETE;
  id: number;
}

export interface IFailureObjectivesDeleteAction {
  type: typeof Types.FAILURE_OBJECTIVES_DELETE;
  message: string;
}

//RESET
export interface IResetObjectivesAction {
  type: typeof Types.RESET_OBJETIVES;
}

type ResetObjectives = IResetObjectivesAction;

type ObjectivesDeleteAction =
  | IRequestObjectivesDeleteAction
  | ISuccessObjectivesDeleteAction
  | IFailureObjectivesDeleteAction;

export type ObjectivesActionTypes =
  | ObjectivesLoadAction
  | ObjectivesAddAction
  | ObjectivesEditAction
  | ObjectivesDeleteAction
  | ObjectiveLoadByIdAction
  | ResetObjectives;
