import React, { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Page, BreadcrumbItems } from "~/store/ducks/home/types";
import { Creators } from "~/store/ducks/home";
import { Creators as CreatorsAvaliation } from "~/store/ducks/avaliation";
import { DataTypes as DataTypesCycleAvaliation } from "~/store/ducks/avaliation/types";
import { RootState } from "~/store/ducks";

import SelfEvaluationRender from "../Tasks/SelfEvaluation/selfEvaluationRender";
import SelfEvaluationCollectiveResume from "../SelfEvaluationCollectiveResume/selfEvaluationCollectiveResume";
import SelfEvaluationCollective from "../SelfEvaluationCollective/selfEvaluationCollectiveRender";

const SelfEvaluationManager: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams() as any;

  const { id: idCicloAvaliacao, idTarefa } = params;
  const cycleAvaliationState = useSelector<RootState, DataTypesCycleAvaliation>(
    (state) => state.cycleAvaliationReducer
  );

  const itemsBreadCrumb: BreadcrumbItems[] = [
    {
      text: "Home",
      isCurrentItem: false,
      icon: "HomeSolid",
      onlyIcon: true,
      url: "/",
    },
    {
      text: "Time Avaliação",
      isCurrentItem: false,
      url: `/tarefas/${idTarefa}`,
    },
    { text: "Avaliação", isCurrentItem: true },
  ];

  const page: Page = {
    key: "autoavaliacao",
    pages: itemsBreadCrumb,
  };

  useEffect(() => {
    // if (!idCicloAvaliacao) {
    //   history.push('/timeavaliacao')
    // }

    dispatch(Creators.setCurrentPage(page));
    dispatch(CreatorsAvaliation.getAvaliationById(idCicloAvaliacao));
  }, []);

  useEffect(() => {
    if (cycleAvaliationState.redirectTo) {
      history.push(cycleAvaliationState.redirectTo);
    }
  }, [cycleAvaliationState.redirectTo]);

  const handleSave = (values: any) => {
    let dados: any = {
      comentariosGestor: values.comentariosGestor,
      idTabPerformanceGestor: values.idTabPerformanceGestor,
      potencial: values.potencial,
    };

    if (values.sucessao !== "Null") {
      dados.sucessao = values.sucessao;
    }
    dispatch(
      CreatorsAvaliation.saveAvaliation(idTarefa, idCicloAvaliacao, dados)
    );
  };

  const handleSaveColetiva = (values: any) => {
    if (cycleAvaliationState.data?.tipoAvaliacao === "Desligado") {
      const dados: any = {
        idTabPerformanceGestor: values.idTabPerformanceGestor,
        comentariosGestor: values.comentariosGestor,
      };
      dispatch(
        CreatorsAvaliation.saveAvaliation(idTarefa, idCicloAvaliacao, dados)
      );
    } else {
      if (values.sucessao === "Null") {
        values.sucessao = null;
      }
      dispatch(
        CreatorsAvaliation.saveAvaliation(idTarefa, idCicloAvaliacao, values)
      );
    }
  };

  if (cycleAvaliationState.data?.tipoAvaliacao === "Avaliação coletiva") {
    return (
      <SelfEvaluationCollectiveResume
        onSave={handleSaveColetiva}
        avaliation={cycleAvaliationState?.data!}
        error={cycleAvaliationState.error}
        loading={cycleAvaliationState.loading}
        loadingSave={cycleAvaliationState.loadingSave}
        isDesligado={cycleAvaliationState.data?.funcionario.status === "D"}
        flagFinalizada={true}
        statusTarefa={cycleAvaliationState.data?.statusAvaliacao}
      />
    );
  }

  return (
    <>
      {cycleAvaliationState.data?.tipoAvaliacao === "Avaliação gestor" ||
      cycleAvaliationState.data?.tipoAvaliacao === "Desligado" ||
      cycleAvaliationState.data?.tipoAvaliacao === "Afastado" ? (
        <SelfEvaluationCollective
          onSave={handleSaveColetiva}
          avaliation={cycleAvaliationState?.data!}
          error={cycleAvaliationState.error}
          loading={cycleAvaliationState.loading}
          loadingSave={cycleAvaliationState.loadingSave}
          isDesligado={cycleAvaliationState.data?.funcionario.status === "D"}
          flagFinalizada={true}
          statusTarefa={cycleAvaliationState.data?.statusAvaliacao}
        />
      ) : (
        <SelfEvaluationRender
          onSave={handleSave}
          avaliation={cycleAvaliationState?.data!}
          error={cycleAvaliationState.error}
          loading={cycleAvaliationState.loading}
          loadingSave={cycleAvaliationState.loadingSave}
          flagFinalizada={true}
          statusTarefa={cycleAvaliationState.data?.statusAvaliacao}
        />
      )}
    </>
  );
};

export default SelfEvaluationManager;
