import { Types, DataTypes, ReportMeritActionTypes } from "./types";
import customToast from "~/components/Toast/index";

/**
 * Reducers
 */
const INITIAL_STATE: DataTypes = {
  data: {
    reunioes: [],
    temporadas: [],
  },
  dataDetalhes: null,
  loading: false,
  loadingAction: false,
  success: false,
  error: false,
};

/**
 * Actions
 */

export const Creators = {
  getReport: (Ano?: string): ReportMeritActionTypes => ({
    type: Types.REQUEST_REPORT_MERIT_LOAD,
    payload: { Ano },
  }),

  getDetalhes: (Ano: string, Grupos?: number[]): ReportMeritActionTypes => ({
    type: Types.REQUEST_REPORT_MERIT_LOAD_DETALHES,
    payload: {
      Ano,
      Decisao: "G",
      Grupos,
    },
  }),

  clearReportMerit: (): ReportMeritActionTypes => ({
    type: Types.CLEAR_REPORT_MERIT,
  }),
};

export default function reportMeritReducer(
  state = INITIAL_STATE,
  action: ReportMeritActionTypes
): DataTypes {
  switch (action.type) {
    case Types.REQUEST_REPORT_MERIT_LOAD:
      return { ...state, loading: true, error: false, success: false };
    case Types.SUCCESS_REPORT_MERIT_LOAD:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        success: true,
      };
    case Types.FAILURE_REPORT_MERIT_LOAD:
      customToast.error(action.message);
      return {
        ...state,
        data: {
          reunioes: [],
          temporadas: [],
        },
        loading: false,
        error: true,
        success: false,
      };

    case Types.REQUEST_REPORT_MERIT_LOAD_DETALHES:
      return {
        ...state,
        dataDetalhes: null,
        loadingAction: true,
        error: false,
        success: false,
      };
    case Types.SUCCESS_REPORT_MERIT_LOAD_DETALHES:
      return {
        ...state,
        dataDetalhes: action.payload,
        loadingAction: false,
        error: false,
        success: true,
      };
    case Types.FAILURE_REPORT_MERIT_LOAD_DETALHES:
      customToast.error(action.message);
      return {
        ...state,
        dataDetalhes: null,
        loadingAction: false,
        error: true,
        success: false,
      };

    case Types.CLEAR_REPORT_MERIT:
      return {
        ...state,
        dataDetalhes: null,
        loadingAction: false,
        error: false,
        success: false,
      };

    default:
      return state;
  }
}
