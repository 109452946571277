import { PersonaPresence } from "@fluentui/react";

export const getPresence = (status: string): PersonaPresence => {
  switch (status) {
    case "D":
      return PersonaPresence.offline;
    case "F":
      return PersonaPresence.away;
    case "A":
      return PersonaPresence.online;
    default:
      return PersonaPresence.online;
  }
};
