import styled from 'styled-components';
import { FontIcon, IconButton } from 'office-ui-fabric-react';
import Colors from '~/assets/js/colors';

export const Title = styled.p`
    font-size: 11px;
    font-weight: 800;
    text-align: right;
    text-transform: uppercase;
`

export const Value = styled.p`
    font-size: 30px;
    font-weight: 300;
    text-align: right;
`

export const Subtitle = styled.p`
    font-size: 11px;
    font-weight: 600;
    text-align: right;
`

export const Icon = styled(FontIcon)`
    font-size: 30px;
    position: absolute;
    opacity: 0.3;
`
export const EditIcon = styled(IconButton)`
    position: absolute;
    bottom: 10px;
    border-radius: 100%;
    background-color: white;
`

export const Wrapper = styled.div<{ bg?: string, color?: string, isNegative?: boolean }>`
    width: 250px;
    height: 90px;
    background-color: ${({ bg }) => bg ?? '#FFFFFF'};
    border-radius: 4px;
    padding: 10px;
    box-sizing: border-box;
    position: relative;
    border: 1px solid ${({ bg, color }) => bg ? '#FFFFFF' : color};

    ${Title}{
        color: ${({ bg, color }) => bg ? '#FFFFFF' : color};
    }

    ${Value}{
        color: ${({ bg, color, isNegative }) => isNegative ? Colors.error : bg ? '#FFFFFF' : color};
    }

    ${Subtitle}{
        color: ${({ bg, color }) => bg ? '#FFFFFF' : color};
    }

    ${Icon}{
        color: ${({ bg, color }) => bg ? '#FFFFFF' : color};
    }
`;
