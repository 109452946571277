
/**
 * Types
 */
export enum Types {
  REQUEST_CYCLE_VALIDATION = "cycleValidation/REQUEST_CYCLE_VALIDATION",
  SUCCESS_CYCLE_VALIDATION = "cycleValidation/SUCCESS_CYCLE_VALIDATION",
  FAILURE_CYCLE_VALIDATION = "cycleValidation/FAILURE_CYCLE_VALIDATION",

  REQUEST_CYCLE_VALIDATION_AREA = "cycleValidation/REQUEST_CYCLE_VALIDATION_AREA",
  SUCCESS_CYCLE_VALIDATION_AREA = "cycleValidation/SUCCESS_CYCLE_VALIDATION_AREA",
  FAILURE_CYCLE_VALIDATION_AREA = "cycleValidation/FAILURE_CYCLE_VALIDATION_AREA",

  REQUEST_CYCLE_PUBLISH_AREA = "cycleValidation/REQUEST_CYCLE_PUBLISH_AREA",
  SUCCESS_CYCLE_PUBLISH_AREA = "cycleValidation/SUCCESS_CYCLE_PUBLISH_AREA",
  FAILURE_CYCLE_PUBLISH_AREA = "cycleValidation/FAILURE_CYCLE_PUBLISH_AREA",

  REQUEST_CYCLE_PUBLISH_VISUALIZATION = "cycleValidation/REQUEST_CYCLE_PUBLISH_VISUALIZATION",
  SUCCESS_CYCLE_PUBLISH_VISUALIZATION = "cycleValidation/SUCCESS_CYCLE_PUBLISH_VISUALIZATION",
  FAILURE_CYCLE_PUBLISH_VISUALIZATION = "cycleValidation/FAILURE_CYCLE_PUBLISH_VISUALIZATION",
};

export type ValidationsType = {
  idCiclo: number;
  idArea: number;
  idAreaPai: number;
  qtdePesos: number;
  somaPesos: number;
  statusQtde: string;
  statusSoma: string;
  flagError: boolean | null;
  flagValidado: boolean | null;
  flagPublicado: boolean | null;
  flagDisponibilizado: boolean | null;
  Area: {
    nomeLocal: string;
    tipoLocal: string;
  };

  idCicloValidacao: number;
  // tipoValidacao: {
  //   descValidacao: string;
  // };
  // status: string;
  children: ValidationsType[];
}

export type ValidationType = {
  status: 'V' | 'P' | 'F' | '';
  validacoes: ValidationsType[]
}

interface ICycleValidationState {
  readonly data: ValidationType;
  readonly loadingData: boolean;
  readonly finishedValidation: boolean;
  readonly loadingArea: boolean;
  readonly success: boolean;
  readonly error: boolean;
  readonly flagStartValidation: boolean;
};

export type DataTypes = ICycleValidationState;


//GET
export interface IRequestValidationCycleAction {
  type: typeof Types.REQUEST_CYCLE_VALIDATION;
  idCiclo: number;
};

export interface ISuccessValidationCycleAction {
  type: typeof Types.SUCCESS_CYCLE_VALIDATION;
  payload: {
    data: ValidationType;
    // flagStartValidation: boolean;
  }
};

interface IFailureValidationCycleAction {
  type: typeof Types.FAILURE_CYCLE_VALIDATION;
  message: string;
};

type ValidationCycleAction = IRequestValidationCycleAction | ISuccessValidationCycleAction | IFailureValidationCycleAction;

//VALIDATE AREA
export interface IRequestValidationAreaAction {
  type: typeof Types.REQUEST_CYCLE_VALIDATION_AREA;
  payload: {
    idCiclo: number;
    idArea: number;
  }
};

export interface ISuccessValidationAreaAction {
  type: typeof Types.SUCCESS_CYCLE_VALIDATION_AREA;
  payload: {
    areaTree: ValidationsType;
    idArea: number;
  }
};

interface IFailureValidationAreaAction {
  type: typeof Types.FAILURE_CYCLE_VALIDATION_AREA;
  message: string;
};

type ValidationAreaAction = IRequestValidationAreaAction | ISuccessValidationAreaAction | IFailureValidationAreaAction;

//PUBLISHED AREA
export interface IRequestPublishingAreaAction {
  type: typeof Types.REQUEST_CYCLE_PUBLISH_AREA;
  payload: {
    idCiclo: number;
    idArea: number;
  }
};

export interface ISuccessPublishingAreaAction {
  type: typeof Types.SUCCESS_CYCLE_PUBLISH_AREA;
  payload: {
    areaTree: ValidationsType;
    idArea: number;
  }
};

interface IFailurePublishingAreaAction {
  type: typeof Types.FAILURE_CYCLE_PUBLISH_AREA;
  message: string;
};

type PublishingAreaAction = IRequestPublishingAreaAction | ISuccessPublishingAreaAction | IFailurePublishingAreaAction;

//REQUEST_CYCLE_PUBLISH_VISUALIZATION
export interface IRequestPublishingVisualizationAction {
  type: typeof Types.REQUEST_CYCLE_PUBLISH_VISUALIZATION;
  payload: {
    idCiclo: number;
    idArea: number;
  }
};

export interface ISuccessPublishingVisualizationAction {
  type: typeof Types.SUCCESS_CYCLE_PUBLISH_VISUALIZATION;
  payload: {
    areaTree: ValidationsType;
    idArea: number;
  }
};

interface IFailurePublishingVisualizationAction {
  type: typeof Types.FAILURE_CYCLE_PUBLISH_VISUALIZATION;
  message: string;
};

type PublishingVisualizationAction = IRequestPublishingVisualizationAction | ISuccessPublishingVisualizationAction | IFailurePublishingVisualizationAction;

export type ValidationCycleActionTypes =
  ValidationCycleAction |
  ValidationAreaAction |
  PublishingAreaAction |
  PublishingVisualizationAction;
