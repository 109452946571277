import customToast from "~/components/Toast";
import {
  Types,
  DataTypes,
  BonusActionTypes,
  BonusType,
  BonusFaltaType,
  BonusFuncType,
} from "./types";

/**
 * Reducers
 */
const INITIAL_STATE: DataTypes = {
  data: null,
  // dataResumo: null,
  error: false,
  errorData: false,
  loadingData: false,
  loadingAction: false,
  success: false,
  redirect: false,
};

/**
 * Actions
 */

export const Creators = {
  getBonusById: (idBonus: number): BonusActionTypes => {
    return {
      type: Types.REQUEST_BONUS_LOAD_BY_ID,
      idBonus,
    };
  },

  editBonus: (bonus: Partial<BonusType>): BonusActionTypes => ({
    type: Types.REQUEST_BONUS_EDIT,
    bonus,
  }),

  editBonusFunc: (bonus: Partial<BonusFuncType>): BonusActionTypes => ({
    type: Types.BONUS_FUNC_EDIT,
    payload: bonus,
  }),

  editBonusPerformance: (
    idBonus: string,
    idBonusPerformance: string,
    multiplicador: string
  ): BonusActionTypes => ({
    type: Types.REQUEST_BONUS_PERFORMANCE_EDIT,
    payload: {
      idBonus,
      idBonusPerformance,
      multiplicador,
    },
  }),

  atualizaComentarios: (
    idBonus: number,
    idBonusFuncionario: string,
    comentarios: string
  ): BonusActionTypes => ({
    type: Types.REQUEST_BONUS_ATUALIZA_COMENTARIO,
    payload: {
      idBonus,
      idBonusFuncionario,
      comentarios,
    },
  }),

  finalizar: (
    idBonus: number,
    idBonusFuncionario: string
  ): BonusActionTypes => ({
    type: Types.REQUEST_BONUS_FINALIZAR,
    payload: {
      idBonus,
      idBonusFuncionario,
    },
  }),

  reativar: (
    idBonus: number,
    idBonusFuncionario: string
  ): BonusActionTypes => ({
    type: Types.REQUEST_BONUS_REATIVAR,
    payload: {
      idBonus,
      idBonusFuncionario,
    },
  }),

  aplicar: (idBonus: number, idBonusFuncionario: string): BonusActionTypes => ({
    type: Types.REQUEST_BONUS_APLICAR,
    payload: {
      idBonus,
      idBonusFuncionario,
    },
  }),

  ignorar: (idBonus: number, idBonusFuncionario: string): BonusActionTypes => ({
    type: Types.REQUEST_BONUS_IGNORAR,
    payload: {
      idBonus,
      idBonusFuncionario,
    },
  }),

  atualizaCalibracao: (
    idBonus: number,
    idBonusFuncionario: string,
    calibracaoAplicada: number
  ): BonusActionTypes => ({
    type: Types.REQUEST_BONUS_ATUALIZA_CALIBRACAO,
    payload: {
      idBonus,
      idBonusFuncionario,
      calibracaoAplicada,
    },
  }),

  atualizaResidual: (
    idBonus: number,
    idBonusFuncionario: string,
    residualAnoAnterior: number
  ): BonusActionTypes => ({
    type: Types.REQUEST_BONUS_ATUALIZA_RESIDUAL,
    payload: {
      idBonus,
      idBonusFuncionario,
      residualAnoAnterior,
    },
  }),

  atualizaOutrasRemuneracoes: (
    idBonus: number,
    idBonusFuncionario: string,
    outrasRemuneracoes: number
  ): BonusActionTypes => ({
    type: Types.REQUEST_BONUS_ATUALIZA_REMUNERACAO,
    payload: {
      idBonus,
      idBonusFuncionario,
      outrasRemuneracoes,
    },
  }),

  gerar: (idBonus: number): BonusActionTypes => ({
    type: Types.REQUEST_BONUS_GERAR,
    idBonus,
  }),

  publicar: (idBonus: number): BonusActionTypes => ({
    type: Types.REQUEST_BONUS_PUBLICAR,
    idBonus,
  }),

  addFalta: (idBonus: number, falta: BonusFaltaType): BonusActionTypes => ({
    type: Types.REQUEST_BONUS_FALTA_ADD,
    payload: {
      idBonus,
      falta,
    },
  }),

  editFalta: (idBonus: number, falta: BonusFaltaType): BonusActionTypes => ({
    type: Types.REQUEST_BONUS_FALTA_EDIT,
    payload: {
      idBonus,
      falta,
    },
  }),

  delFalta: (idBonus: number, idBonusFalta: number): BonusActionTypes => ({
    type: Types.REQUEST_BONUS_FALTA_DEL,
    payload: {
      idBonus,
      idBonusFalta,
    },
  }),
};

export default function bonusReducer(
  state = INITIAL_STATE,
  action: BonusActionTypes
): DataTypes {
  switch (action.type) {
    //LOAD
    case Types.REQUEST_BONUS_LOAD_BY_ID:
      return {
        ...state,
        data: null,
        loadingData: true,
        errorData: false,
        success: false,
      };
    case Types.SUCCESS_BONUS_LOAD_BY_ID:
      return {
        ...state,
        data: {
          ...action.payload,
          bonus: {
            ...action.payload.bonus,
            funcionarios: action.payload.bonus.funcionarios.map((func) => ({
              ...func,
              flagCalibEdit: false,
              flagResidualEdit: false,
              flagOutrasRemEdit: false,
            })),
          },
        },
        loadingData: false,
        errorData: false,
        success: true,
      };
    case Types.FAILURE_BONUS_LOAD_BY_ID:
      customToast.error(action.message);
      return {
        ...state,
        data: null,
        loadingData: false,
        errorData: true,
        success: false,
      };

    //EDIT
    case Types.REQUEST_BONUS_EDIT:
      return {
        ...state,
        loadingAction: true,
        error: false,
        success: false,
      };
    case Types.SUCCESS_BONUS_EDIT:
      customToast.success("Regra do bônus editado com sucesso!");
      return {
        ...state,
        data: {
          ...state.data!,
          bonus: {
            ...state.data?.bonus!,
            ...action.payload,
          },
        },
        loadingAction: false,
        error: false,
        success: true,
      };
    case Types.FAILURE_BONUS_EDIT:
      customToast.error(action.message);
      return {
        ...state,
        loadingAction: false,
        error: true,
        success: false,
      };

    //EDIT PERFORMANCE
    case Types.REQUEST_BONUS_PERFORMANCE_EDIT:
      return {
        ...state,
        loadingAction: true,
        error: false,
        success: false,
      };
    case Types.SUCCESS_BONUS_PERFORMANCE_EDIT:
      customToast.success("Performance editado com sucesso!");
      return {
        ...state,
        data: {
          ...state.data!,
          bonus: {
            ...state.data?.bonus!,
            performances: state.data!.bonus!.performances.map((item) => {
              if (
                item.idBonusPerformance === action.payload.idBonusPerformance
              ) {
                return {
                  ...item,
                  multiplicador: action.payload.multiplicador,
                };
              }
              return item;
            }),
          },
        },
        loadingAction: false,
        error: false,
        success: true,
      };
    case Types.FAILURE_BONUS_PERFORMANCE_EDIT:
      customToast.error(action.message);
      return {
        ...state,
        loadingAction: false,
        error: true,
        success: false,
      };

    //ATUALIZA COMENTÁRIO
    case Types.REQUEST_BONUS_ATUALIZA_COMENTARIO:
    case Types.REQUEST_BONUS_FINALIZAR:
    case Types.REQUEST_BONUS_REATIVAR:
    case Types.REQUEST_BONUS_ATUALIZA_CALIBRACAO:
    case Types.REQUEST_BONUS_ATUALIZA_RESIDUAL:
    case Types.REQUEST_BONUS_ATUALIZA_REMUNERACAO:
    case Types.REQUEST_BONUS_APLICAR:
    case Types.REQUEST_BONUS_IGNORAR:
      return { ...state, success: false, error: false };
    case Types.SUCCESS_BONUS_ATUALIZA_COMENTARIO:
    case Types.SUCCESS_BONUS_FINALIZAR:
    case Types.SUCCESS_BONUS_REATIVAR:
    case Types.SUCCESS_BONUS_ATUALIZA_CALIBRACAO:
    case Types.SUCCESS_BONUS_ATUALIZA_RESIDUAL:
    case Types.SUCCESS_BONUS_ATUALIZA_REMUNERACAO:
    case Types.SUCCESS_BONUS_APLICAR:
    case Types.SUCCESS_BONUS_IGNORAR:
      return {
        ...state,
        data: {
          ...state.data!,
          bonus: {
            ...state.data?.bonus!,
            funcionarios: state.data!.bonus.funcionarios.map((func) => {
              if (
                func.idBonusFuncionario === action.payload.idBonusFuncionario
              ) {
                return { ...func, ...action.payload };
              }
              return func;
            }),
          },
        },
        success: true,
        error: false,
      };
    case Types.FAILURE_BONUS_ATUALIZA_COMENTARIO:
    case Types.FAILURE_BONUS_FINALIZAR:
    case Types.FAILURE_BONUS_REATIVAR:
    case Types.FAILURE_BONUS_ATUALIZA_CALIBRACAO:
    case Types.FAILURE_BONUS_ATUALIZA_RESIDUAL:
    case Types.FAILURE_BONUS_ATUALIZA_REMUNERACAO:
    case Types.FAILURE_BONUS_APLICAR:
    case Types.FAILURE_BONUS_IGNORAR:
      customToast.error(action.message);
      return { ...state, success: false, error: true };

    case Types.REQUEST_BONUS_GERAR:
      return {
        ...state,
        loadingAction: true,
        success: false,
        error: false,
      };
    case Types.SUCCESS_BONUS_GERAR:
      customToast.success("Bônus gerado com sucesso!");
      return {
        ...state,
        loadingAction: false,
        success: true,
        error: false,
      };
    case Types.FAILURE_BONUS_GERAR:
      customToast.error(action.message);
      return {
        ...state,
        loadingAction: false,
        success: false,
        error: true,
      };

    case Types.REQUEST_BONUS_PUBLICAR:
      return {
        ...state,
        loadingAction: true,
        success: false,
        error: false,
      };
    case Types.SUCCESS_BONUS_PUBLICAR:
      customToast.success("Bônus publicado com sucesso!");
      return {
        ...state,
        loadingAction: false,
        success: true,
        error: false,
      };
    case Types.FAILURE_BONUS_PUBLICAR:
      customToast.error(action.message);
      return {
        ...state,
        loadingAction: false,
        success: false,
        error: true,
      };

    //REQUEST_BONUS_FALTA_ADD
    case Types.REQUEST_BONUS_FALTA_ADD:
      return {
        ...state,
        loadingAction: true,
        success: false,
        error: false,
      };
    case Types.SUCCESS_BONUS_FALTA_ADD:
      return {
        ...state,
        data: {
          ...state.data!,
          bonus: {
            ...state.data?.bonus!,
            faltas: [...state.data?.bonus.faltas!, action.payload],
          },
        },
        loadingAction: false,
        success: true,
        error: false,
      };
    case Types.FAILURE_BONUS_FALTA_ADD:
      customToast.error(action.message);
      return {
        ...state,
        loadingAction: false,
        success: false,
        error: true,
      };

    //REQUEST_BONUS_FALTA_EDIT
    case Types.REQUEST_BONUS_FALTA_EDIT:
      return {
        ...state,
        loadingAction: true,
        success: false,
        error: false,
      };
    case Types.SUCCESS_BONUS_FALTA_EDIT:
      return {
        ...state,
        data: {
          ...state.data!,
          bonus: {
            ...state.data?.bonus!,
            faltas:
              state.data?.bonus.faltas.map((item) => {
                if (item.idBonusFalta === action.payload.idBonusFalta) {
                  return action.payload;
                }
                return item;
              }) ?? [],
          },
        },
        loadingAction: false,
        success: true,
        error: false,
      };
    case Types.FAILURE_BONUS_FALTA_EDIT:
      customToast.error(action.message);
      return {
        ...state,
        loadingAction: false,
        success: false,
        error: true,
      };

    //REQUEST_BONUS_FALTA_DEL
    case Types.REQUEST_BONUS_FALTA_DEL:
      return {
        ...state,
        loadingAction: true,
        success: false,
        error: false,
      };
    case Types.SUCCESS_BONUS_FALTA_DEL:
      return {
        ...state,
        data: {
          ...state.data!,
          bonus: {
            ...state.data?.bonus!,
            faltas:
              state.data?.bonus.faltas.filter(
                (item) => item.idBonusFalta !== action.idBonusFalta
              ) ?? [],
          },
        },
        loadingAction: false,
        success: true,
        error: false,
      };
    case Types.FAILURE_BONUS_FALTA_DEL:
      customToast.error(action.message);
      return {
        ...state,
        loadingAction: false,
        success: false,
        error: true,
      };

    case Types.BONUS_FUNC_EDIT:
      return {
        ...state,
        data: {
          ...state.data!,
          bonus: {
            ...state.data!.bonus,
            funcionarios: state.data!.bonus.funcionarios.map((func) => {
              if (
                func.idBonusFuncionario === action.payload.idBonusFuncionario
              ) {
                return { ...func, ...action.payload };
              }
              return func;
            }),
          },
        },
      };

    default:
      return state;
  }
}
