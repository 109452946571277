/**
 * Types
 */
export enum Types {
    //GET
    SET_CURRENT_PAGE = "home/SET_CURRENT_PAGE",
    SET_MENU_EXPANDED = "home/SET_MENU_EXPANDED",
    // GET HOME TAREFAS
    REQUEST_HOME_LOAD = "home/REQUEST_HOME_LOAD",
    SUCCESS_HOME_LOAD = "home/SUCCESS_HOME_LOAD",
    FAILURE_HOME_LOAD = "home/FAILURE_HOME_LOAD",
}
/**
 * Data types
 */

export type BreadcrumbItems = {
    isCurrentItem?: boolean;
    text: string;
    url?: string;
    onlyIcon?: boolean;
    icon?: string;
}

export type Page = {
    key: string;
    pages: BreadcrumbItems[];
}

export type HomeType = {
    page: Page;
}

interface IHomeState {
    readonly page: Page;
    readonly isMenuExpanded: boolean;
    readonly tarefasPendentes: string;
}

export type DataTypes = IHomeState

/**
 * Actions
 */

//GET
interface ICurrentPageAction {
    type: typeof Types.SET_CURRENT_PAGE,
    payload: Page
}

type HomeAction = ICurrentPageAction

interface IMenuExpandedAction {
    type: typeof Types.SET_MENU_EXPANDED,
    isExpanded: boolean
}

type MenuAction = IMenuExpandedAction;

//ADD
export interface IRequestHomeLoadAction {
    type: typeof Types.REQUEST_HOME_LOAD,
}

export interface ISuccessHomeLoadAction {
    type: typeof Types.SUCCESS_HOME_LOAD;
    tarefasPendentes: string;
}

interface IFailureHomeLoadAction {
    type: typeof Types.FAILURE_HOME_LOAD
}

type HomeLoadAction = IRequestHomeLoadAction | ISuccessHomeLoadAction | IFailureHomeLoadAction

// //PUT
// export interface IRequestCyclesEditAction {
//     type: typeof Types.REQUEST_CYCLES_EDIT,
//     cycle: CycleType
// }

// export interface ISuccessCyclesEditAction {
//     type: typeof Types.SUCCESS_CYCLES_EDIT,
// }

// export interface IFailureCyclesEditAction {
//     type: typeof Types.FAILURE_CYCLES_EDIT
// }

// type CyclesEditAction = IRequestCyclesEditAction | ISuccessCyclesEditAction | IFailureCyclesEditAction


// //DELETE
// export interface IRequestCyclesDeleteAction {
//     type: typeof Types.REQUEST_CYCLES_DELETE,
//     id: number
// }

// export interface ISuccessCyclesDeleteAction {
//     type: typeof Types.SUCCESS_CYCLES_DELETE,
// }

// export interface IFailureCyclesDeleteAction {
//     type: typeof Types.FAILURE_CYCLES_DELETE
// }

//type CyclesDeleteAction = IRequestCyclesDeleteAction | ISuccessCyclesDeleteAction | IFailureCyclesDeleteAction

export type HomeActionTypes = HomeAction | MenuAction | HomeLoadAction;