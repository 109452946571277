import { takeEvery, put, call, all } from "redux-saga/effects";
import {
  Types,
  TipoMovType,
  IRequestTipoMovLoadAction,
  IRequestTipoMovLoadByIdAction,
  IRequestTipoMovAddAction,
  IRequestTipoMovEditAction,
  IRequestTipoMovDelAction,
  ISuccessTipoMovLoadAction,
  ISuccessTipoMovLoadByIdAction,
  ISuccessTipoMovAddAction,
  ISuccessTipoMovEditAction,
  ISuccessTipoMovDelAction,
} from "../../ducks/admin/tipomov/types";
import api from "~/services/api";

const apiCallGet = (seach?: string) => {
  return api
    .get(`tiposmov`, {
      params: {
        SearchText: seach,
      },
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallGetById = (idTipoMov: number) => {
  return api
    .get(`tiposmov/${idTipoMov}`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallAdd = (item: IRequestTipoMovAddAction["payload"]) => {
  return api
    .post("tiposmov", item)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallEdit = (item: IRequestTipoMovEditAction["payload"]) => {
  return api
    .put(`tiposmov/${item.idTipoMov}`, item)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallDel = (idTipoMov: number) => {
  return api
    .delete(`tiposmov/${idTipoMov}`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

function* load(action: IRequestTipoMovLoadAction) {
  try {
    let items: TipoMovType[] = yield call(apiCallGet, action.search);
    yield put({
      type: Types.SUCCESS_TIPO_MOV_LOAD,
      payload: items,
    } as ISuccessTipoMovLoadAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível carregar os tipos de MOVs";
    yield put({
      type: Types.FAILURE_TIPO_MOV_LOAD,
      message,
    });
  }
}

function* loadById(action: IRequestTipoMovLoadByIdAction) {
  try {
    const item: TipoMovType = yield call(apiCallGetById, action.idTipoMov);
    yield put({
      type: Types.SUCCESS_TIPO_MOV_LOAD_BY_ID,
      payload: item,
    } as ISuccessTipoMovLoadByIdAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível carregar o tipo de movimentação";
    yield put({ type: Types.FAILURE_TIPO_MOV_LOAD_BY_ID, message });
  }
}

function* add(action: IRequestTipoMovAddAction) {
  try {
    const data: { idTipoMov: number } = yield call(apiCallAdd, action.payload);
    yield put({
      type: Types.SUCCESS_TIPO_MOV_ADD,
      payload: {
        idTipoMov: data.idTipoMov,
        descTipoMov: action.payload.descTipoMov,
      },
    } as ISuccessTipoMovAddAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível adicionar tipo de movimentação";
    yield put({ type: Types.FAILURE_TIPO_MOV_ADD, message });
  }
}

function* edit(action: IRequestTipoMovEditAction) {
  try {
    yield call(apiCallEdit, action.payload);
    yield put({
      type: Types.SUCCESS_TIPO_MOV_EDIT,
      payload: action.payload,
    } as ISuccessTipoMovEditAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível editar o tipo de movimentação";
    yield put({ type: Types.FAILURE_TIPO_MOV_EDIT, message });
  }
}

function* del(action: IRequestTipoMovDelAction) {
  try {
    yield call(apiCallDel, action.idTipoMov);
    yield put({
      type: Types.SUCCESS_TIPO_MOV_DEL,
      idTipoMov: action.idTipoMov,
    } as ISuccessTipoMovDelAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível excluír o tipo de movimentação";
    yield put({ type: Types.FAILURE_TIPO_MOV_DEL, message });
  }
}

export default function* tipoMovRoot() {
  yield all([
    yield takeEvery(Types.REQUEST_TIPO_MOV_LOAD, load),
    yield takeEvery(Types.REQUEST_TIPO_MOV_LOAD_BY_ID, loadById),
    yield takeEvery(Types.REQUEST_TIPO_MOV_ADD, add),
    yield takeEvery(Types.REQUEST_TIPO_MOV_EDIT, edit),
    yield takeEvery(Types.REQUEST_TIPO_MOV_DEL, del),
  ]);
}
