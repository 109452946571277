/**
 * Types
 */
export enum Types {
  //GET
  REQUEST_ADM_LOAD_CONFIG_MOTIVOS = "admin/REQUEST_ADM_LOAD_CONFIG_MOTIVOS",
  SUCCESS_ADM_LOAD_CONFIG_MOTIVOS = "admin/SUCCESS_ADM_LOAD_CONFIG_MOTIVOS",
  FAILURE_ADM_LOAD_CONFIG_MOTIVOS = "admin/FAILURE_ADM_LOAD_CONFIG_MOTIVOS",
  //EDIT
  REQUEST_ADM_EDIT_CONFIG_MOTIVOS = "admin/REQUEST_ADM_EDIT_CONFIG_MOTIVOS",
  SUCCESS_ADM_EDIT_CONFIG_MOTIVOS = "admin/SUCCESS_ADM_EDIT_CONFIG_MOTIVOS",
  FAILURE_ADM_EDIT_CONFIG_MOTIVOS = "admin/FAILURE_ADM_EDIT_CONFIG_MOTIVOS",
  //GET
  REQUEST_ADM_LOAD_CONFIG_JUSTA_CAUSA = "admin/REQUEST_ADM_LOAD_CONFIG_JUSTA_CAUSA",
  SUCCESS_ADM_LOAD_CONFIG_JUSTA_CAUSA = "admin/SUCCESS_ADM_LOAD_CONFIG_JUSTA_CAUSA",
  FAILURE_ADM_LOAD_CONFIG_JUSTA_CAUSA = "admin/FAILURE_ADM_LOAD_CONFIG_JUSTA_CAUSA",
  //EDIT
  REQUEST_ADM_EDIT_CONFIG_JUSTA_CAUSA = "admin/REQUEST_ADM_EDIT_CONFIG_JUSTA_CAUSA",
  SUCCESS_ADM_EDIT_CONFIG_JUSTA_CAUSA = "admin/SUCCESS_ADM_EDIT_CONFIG_JUSTA_CAUSA",
  FAILURE_ADM_EDIT_CONFIG_JUSTA_CAUSA = "admin/FAILURE_ADM_EDIT_CONFIG_JUSTA_CAUSA",
}

export type ConfigTipoMovType = {
  idConfig?: number;
  idTipoMovAdmissao: number | null;
  idTipoMovMerito: number | null;
  idTipoMovEnquadramento: number | null;
  idTipoMovPromocao: number | null;
  tipoMovAdm: {
    descTipoMov: string;
  } | null;
  tipoMovPro: {
    descTipoMov: string;
  } | null;
  tipoMovMer: {
    descTipoMov: string;
  } | null;
  tipoMovEnq: {
    descTipoMov: string;
  } | null;
};

export type ConfigJustaCausaType = {
  idConfig: number;
  diasZerar: number;
  periodoSoma: number;
  pontosJustaCausa: number;
};

interface IAdminState {
  readonly tipoMovData: ConfigTipoMovType | null;
  readonly justaCausaConfig: ConfigJustaCausaType | null;
  readonly loading: boolean;
  readonly error: boolean;
  readonly success: boolean;
}

export type DataTypes = IAdminState;

/**
 * Actions
 */

//GET
export interface IRequestAdminLoadConfigMotivosAction {
  type: typeof Types.REQUEST_ADM_LOAD_CONFIG_MOTIVOS;
}

export interface ISuccessAdminLoadConfigMotivosAction {
  type: typeof Types.SUCCESS_ADM_LOAD_CONFIG_MOTIVOS;
  tipoMov: ConfigTipoMovType;
}

interface IFailureAdminLoadConfigMotivosAction {
  type: typeof Types.FAILURE_ADM_LOAD_CONFIG_MOTIVOS;
  message: string;
}

type AdminLoadConfigMotivosAction =
  | IRequestAdminLoadConfigMotivosAction
  | ISuccessAdminLoadConfigMotivosAction
  | IFailureAdminLoadConfigMotivosAction;

//EDIT
export interface IRequestAdminEditConfigMotivosAction {
  type: typeof Types.REQUEST_ADM_EDIT_CONFIG_MOTIVOS;
  tipoMov: ConfigTipoMovType;
}

export interface ISuccessAdminEditConfigMotivosAction {
  type: typeof Types.SUCCESS_ADM_EDIT_CONFIG_MOTIVOS;
  tipoMov: ConfigTipoMovType;
}

interface IFailureAdminEditConfigMotivosAction {
  type: typeof Types.FAILURE_ADM_EDIT_CONFIG_MOTIVOS;
  message: string;
}

type AdminEditConfigMotivosAction =
  | IRequestAdminEditConfigMotivosAction
  | ISuccessAdminEditConfigMotivosAction
  | IFailureAdminEditConfigMotivosAction;

//LOAD JUSTA CAUSA CONFIG
export interface IRequestAdminLoadConfigJustaCausaAction {
  type: typeof Types.REQUEST_ADM_LOAD_CONFIG_JUSTA_CAUSA;
}

export interface ISuccessAdminLoadConfigJustaCausaAction {
  type: typeof Types.SUCCESS_ADM_LOAD_CONFIG_JUSTA_CAUSA;
  justaCausaConfig: ConfigJustaCausaType;
}

interface IFailureAdminLoadConfigJustaCausaAction {
  type: typeof Types.FAILURE_ADM_LOAD_CONFIG_JUSTA_CAUSA;
  message: string;
}

type AdminLoadConfigJustaCausaAction =
  | IRequestAdminLoadConfigJustaCausaAction
  | ISuccessAdminLoadConfigJustaCausaAction
  | IFailureAdminLoadConfigJustaCausaAction;

//EDIT CONFIG JUSTA CAUSA
export interface IRequestAdminEditConfigJustaCausaAction {
  type: typeof Types.REQUEST_ADM_EDIT_CONFIG_JUSTA_CAUSA;
  payload: ConfigJustaCausaType;
}

export interface ISuccessAdminEditConfigJustaCausaAction {
  type: typeof Types.SUCCESS_ADM_EDIT_CONFIG_JUSTA_CAUSA;
  payload: ConfigJustaCausaType;
}

interface IFailureAdminEditConfigJustaCausaAction {
  type: typeof Types.FAILURE_ADM_EDIT_CONFIG_JUSTA_CAUSA;
  message: string;
}

type AdminEditConfigJustaCausaAction =
  | IRequestAdminEditConfigJustaCausaAction
  | ISuccessAdminEditConfigJustaCausaAction
  | IFailureAdminEditConfigJustaCausaAction;

export type AdminActionTypes =
  | AdminEditConfigMotivosAction
  | AdminLoadConfigMotivosAction
  | AdminLoadConfigJustaCausaAction
  | AdminEditConfigJustaCausaAction;
