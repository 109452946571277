import { takeEvery, put, call, all } from "redux-saga/effects";
import {
  Types,
  BonusType,
  IRequestBonusLoadByIdAction,
  ISuccessBonusLoadByIdAction,
  IRequestBonusEditAction,
  ISuccessBonusEditAction,
  IRequestBonusPerformanceEditAction,
  ISuccessBonusPerformanceEditAction,
  IRequestBonusAtualizaComentarioAction,
  ISuccessBonusAtualizaComentarioAction,
  IRequestBonusFinalizarAction,
  ISuccessBonusFinalizarAction,
  IRequestBonusReativarAction,
  ISuccessBonusReativarAction,
  IRequestBonusGerarAction,
  ISuccessBonusGerarAction,
  IRequestBonusPublicarAction,
  ISuccessBonusPublicarAction,
  BonusFuncType,
  BonusData,
  IRequestBonusAddFaltaAction,
  ISuccessBonusAddFaltaAction,
  IRequestBonusEditFaltaAction,
  ISuccessBonusEditFaltaAction,
  IRequestBonusDelFaltaAction,
  ISuccessBonusDelFaltaAction,
  IRequestBonusAplicarAction,
  IRequestBonusIgnorarAction,
  ISuccessBonusAplicarAction,
  ISuccessBonusIgnorarAction,
  IRequestBonusAtualizaCalibracaoAction,
  ISuccessBonusAtualizaCalibracaoAction,
  IRequestBonusAtualizaResidualAction,
  ISuccessBonusAtualizaResidualAction,
  IRequestBonusAtualizaOutrasRemAction,
  ISuccessBonusAtualizaOutrasRemAction,
} from "../ducks/bonus/types";
import api from "~/services/api";

const apiCallLoadById = (idBonus: number) => {
  return api
    .get(`bonus/${idBonus}`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err.response;
    });
};

const apiCallEdit = (payload: IRequestBonusEditAction["bonus"]) => {
  return api
    .put(`bonus/${payload.idBonus}`, {
      ...payload,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err.response;
    });
};

const apiCallEditPerformance = (
  payload: IRequestBonusPerformanceEditAction["payload"]
) => {
  return api
    .put(`bonus/${payload.idBonus}/performance/${payload.idBonusPerformance}`, {
      ...payload,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err.response;
    });
};

const apiCallFinalizar = (payload: IRequestBonusFinalizarAction["payload"]) => {
  return api
    .put(`bonus/${payload.idBonus}/finalizar/${payload.idBonusFuncionario}`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallReativar = (payload: IRequestBonusReativarAction["payload"]) => {
  return api
    .put(`bonus/${payload.idBonus}/reativar/${payload.idBonusFuncionario}`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallAtualizaComentarios = (
  payload: IRequestBonusAtualizaComentarioAction["payload"]
) => {
  return api
    .put(
      `bonus/${payload.idBonus}/atualizaComentario/${payload.idBonusFuncionario}`,
      {
        ...payload,
      }
    )
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallGerar = (idBonus: number) => {
  return api
    .post(`bonus/${idBonus}/gerar`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallPublicar = (idBonus: number) => {
  return api
    .post(`bonus/${idBonus}/publicar`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallAtualizaCalibracao = (
  payload: IRequestBonusAtualizaCalibracaoAction["payload"]
) => {
  return api
    .put(
      `bonus/${payload.idBonus}/atualizaCalibracao/${payload.idBonusFuncionario}`,
      {
        ...payload,
      }
    )
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallAtualizaResidual = (
  payload: IRequestBonusAtualizaResidualAction["payload"]
) => {
  return api
    .put(
      `bonus/${payload.idBonus}/atualizaResidual/${payload.idBonusFuncionario}`,
      {
        ...payload,
      }
    )
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallAtualizaRemuneracao = (
  payload: IRequestBonusAtualizaOutrasRemAction["payload"]
) => {
  return api
    .put(
      `bonus/${payload.idBonus}/atualizaRemuneracao/${payload.idBonusFuncionario}`,
      {
        ...payload,
      }
    )
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallAplicar = (payload: IRequestBonusAplicarAction["payload"]) => {
  return api
    .put(`bonus/${payload.idBonus}/aplicarBonus/${payload.idBonusFuncionario}`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallIgnorar = (payload: IRequestBonusIgnorarAction["payload"]) => {
  return api
    .put(`bonus/${payload.idBonus}/ignorarBonus/${payload.idBonusFuncionario}`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallAddFalta = (data: IRequestBonusAddFaltaAction["payload"]) => {
  return api
    .post(`bonus/${data.idBonus}/faltas`, {
      ...data.falta,
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallEditFalta = (data: IRequestBonusEditFaltaAction["payload"]) => {
  return api
    .put(`bonus/${data.idBonus}/faltas/${data.falta.idBonusFalta}`, {
      ...data.falta,
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallDelFalta = (data: IRequestBonusDelFaltaAction["payload"]) => {
  return api
    .delete(`bonus/${data.idBonus}/faltas/${data.idBonusFalta}`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

function* loadById(action: IRequestBonusLoadByIdAction) {
  try {
    const data: BonusData = yield call(apiCallLoadById, action.idBonus);
    yield put({
      type: Types.SUCCESS_BONUS_LOAD_BY_ID,
      payload: data,
    } as ISuccessBonusLoadByIdAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível carregar a renião do bônus";
    yield put({
      type: Types.FAILURE_BONUS_LOAD_BY_ID,
      message,
    });
  }
}

function* edit(action: IRequestBonusEditAction) {
  try {
    yield call(apiCallEdit, action.bonus);
    yield put({
      type: Types.SUCCESS_BONUS_EDIT,
      payload: action.bonus,
    } as ISuccessBonusEditAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível editar a renião do bônus";
    yield put({
      type: Types.FAILURE_BONUS_EDIT,
      message,
    });
  }
}

function* editPerformance(action: IRequestBonusPerformanceEditAction) {
  try {
    yield call(apiCallEditPerformance, action.payload);
    yield put({
      type: Types.SUCCESS_BONUS_PERFORMANCE_EDIT,
      payload: action.payload,
    } as ISuccessBonusPerformanceEditAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível editar a performance do bônus";
    yield put({
      type: Types.FAILURE_BONUS_PERFORMANCE_EDIT,
      message,
    });
  }
}

function* atualizaComentarios(action: IRequestBonusAtualizaComentarioAction) {
  try {
    const data = yield call(apiCallAtualizaComentarios, action.payload);

    yield put({
      type: Types.SUCCESS_BONUS_ATUALIZA_COMENTARIO,
      payload: {
        idBonusFuncionario: action.payload.idBonusFuncionario,
        ...data,
      },
    } as ISuccessBonusAtualizaComentarioAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível atualizar o comentário";
    yield put({
      type: Types.FAILURE_BONUS_ATUALIZA_COMENTARIO,
      message,
    });
  }
}

function* finalizar(action: IRequestBonusFinalizarAction) {
  try {
    const data = yield call(apiCallFinalizar, action.payload);

    yield put({
      type: Types.SUCCESS_BONUS_FINALIZAR,
      payload: {
        idBonusFuncionario: action.payload.idBonusFuncionario,
        ...data,
      },
    } as ISuccessBonusFinalizarAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível finalizar o bônus";
    yield put({
      type: Types.FAILURE_BONUS_FINALIZAR,
      message,
    });
  }
}

function* reativar(action: IRequestBonusReativarAction) {
  try {
    const data = yield call(apiCallReativar, action.payload);

    yield put({
      type: Types.SUCCESS_BONUS_REATIVAR,
      payload: {
        idBonusFuncionario: action.payload.idBonusFuncionario,
        ...data,
      },
    } as ISuccessBonusReativarAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível reativar o bônus";
    yield put({
      type: Types.FAILURE_BONUS_REATIVAR,
      message,
    });
  }
}

function* gerar(action: IRequestBonusGerarAction) {
  try {
    const funcionarios: BonusFuncType[] = yield call(
      apiCallGerar,
      action.idBonus
    );

    yield put({
      type: Types.SUCCESS_BONUS_GERAR,
      payload: funcionarios,
    } as ISuccessBonusGerarAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível gerar o bônus";
    yield put({
      type: Types.FAILURE_BONUS_GERAR,
      message,
    });
  }
}

function* publicar(action: IRequestBonusPublicarAction) {
  try {
    const funcionarios: BonusFuncType[] = yield call(
      apiCallPublicar,
      action.idBonus
    );

    yield put({
      type: Types.SUCCESS_BONUS_PUBLICAR,
      payload: funcionarios,
    } as ISuccessBonusPublicarAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível publicar o bônus";
    yield put({
      type: Types.FAILURE_BONUS_PUBLICAR,
      message,
    });
  }
}

function* atualizaCalibracao(action: IRequestBonusAtualizaCalibracaoAction) {
  try {
    const data = yield call(apiCallAtualizaCalibracao, action.payload);

    yield put({
      type: Types.SUCCESS_BONUS_ATUALIZA_CALIBRACAO,
      payload: {
        ...data,
        idBonusFuncionario: action.payload.idBonusFuncionario,
      },
    } as ISuccessBonusAtualizaCalibracaoAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível atualizar a calibracao";
    yield put({
      type: Types.FAILURE_BONUS_ATUALIZA_CALIBRACAO,
      message,
    });
  }
}

function* atualizaResidual(action: IRequestBonusAtualizaResidualAction) {
  try {
    const data = yield call(apiCallAtualizaResidual, action.payload);

    yield put({
      type: Types.SUCCESS_BONUS_ATUALIZA_RESIDUAL,
      payload: {
        ...data,
        idBonusFuncionario: action.payload.idBonusFuncionario,
      },
    } as ISuccessBonusAtualizaResidualAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível atualizar o residual";
    yield put({
      type: Types.FAILURE_BONUS_ATUALIZA_RESIDUAL,
      message,
    });
  }
}

function* atualizaRemuneracao(action: IRequestBonusAtualizaOutrasRemAction) {
  try {
    const data = yield call(apiCallAtualizaRemuneracao, action.payload);

    yield put({
      type: Types.SUCCESS_BONUS_ATUALIZA_REMUNERACAO,
      payload: {
        ...data,
        idBonusFuncionario: action.payload.idBonusFuncionario,
      },
    } as ISuccessBonusAtualizaOutrasRemAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível atualizar as outras remunerações";
    yield put({
      type: Types.FAILURE_BONUS_ATUALIZA_REMUNERACAO,
      message,
    });
  }
}

function* aplicar(action: IRequestBonusAplicarAction) {
  try {
    const data = yield call(apiCallAplicar, action.payload);

    yield put({
      type: Types.SUCCESS_BONUS_APLICAR,
      payload: {
        ...data,
        idBonusFuncionario: action.payload.idBonusFuncionario,
      },
    } as ISuccessBonusAplicarAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível aplicar o bônus";
    yield put({
      type: Types.FAILURE_BONUS_APLICAR,
      message,
    });
  }
}

function* ignorar(action: IRequestBonusIgnorarAction) {
  try {
    const data = yield call(apiCallIgnorar, action.payload);

    yield put({
      type: Types.SUCCESS_BONUS_IGNORAR,
      payload: {
        ...data,
        idBonusFuncionario: action.payload.idBonusFuncionario,
      },
    } as ISuccessBonusIgnorarAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível ignorar o bônus";
    yield put({
      type: Types.FAILURE_BONUS_IGNORAR,
      message,
    });
  }
}

function* addFalta(action: IRequestBonusAddFaltaAction) {
  try {
    const idBonusFalta: { idBonusFalta: number } = yield call(
      apiCallAddFalta,
      action.payload
    );

    yield put({
      type: Types.SUCCESS_BONUS_FALTA_ADD,
      payload: {
        ...action.payload.falta,
        ...idBonusFalta,
      },
    } as ISuccessBonusAddFaltaAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível adicionar falta";
    yield put({
      type: Types.FAILURE_BONUS_FALTA_ADD,
      message,
    });
  }
}

function* editFalta(action: IRequestBonusEditFaltaAction) {
  try {
    yield call(apiCallEditFalta, action.payload);
    yield put({
      type: Types.SUCCESS_BONUS_FALTA_EDIT,
      payload: action.payload.falta,
    } as ISuccessBonusEditFaltaAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível editar falta";
    yield put({
      type: Types.FAILURE_BONUS_FALTA_EDIT,
      message,
    });
  }
}

function* delFalta(action: IRequestBonusDelFaltaAction) {
  try {
    yield call(apiCallDelFalta, action.payload);
    yield put({
      type: Types.SUCCESS_BONUS_FALTA_DEL,
      idBonusFalta: action.payload.idBonusFalta,
    } as ISuccessBonusDelFaltaAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível excluír falta";
    yield put({
      type: Types.FAILURE_BONUS_FALTA_DEL,
      message,
    });
  }
}

export default function* bonusRoot() {
  yield all([
    yield takeEvery(Types.REQUEST_BONUS_LOAD_BY_ID, loadById),
    yield takeEvery(Types.REQUEST_BONUS_EDIT, edit),
    yield takeEvery(Types.REQUEST_BONUS_PERFORMANCE_EDIT, editPerformance),
    yield takeEvery(
      Types.REQUEST_BONUS_ATUALIZA_COMENTARIO,
      atualizaComentarios
    ),
    yield takeEvery(Types.REQUEST_BONUS_FINALIZAR, finalizar),
    yield takeEvery(Types.REQUEST_BONUS_REATIVAR, reativar),
    yield takeEvery(Types.REQUEST_BONUS_GERAR, gerar),
    yield takeEvery(Types.REQUEST_BONUS_PUBLICAR, publicar),
    yield takeEvery(
      Types.REQUEST_BONUS_ATUALIZA_CALIBRACAO,
      atualizaCalibracao
    ),
    yield takeEvery(Types.REQUEST_BONUS_ATUALIZA_RESIDUAL, atualizaResidual),
    yield takeEvery(
      Types.REQUEST_BONUS_ATUALIZA_REMUNERACAO,
      atualizaRemuneracao
    ),
    yield takeEvery(Types.REQUEST_BONUS_APLICAR, aplicar),
    yield takeEvery(Types.REQUEST_BONUS_IGNORAR, ignorar),
    yield takeEvery(Types.REQUEST_BONUS_FALTA_ADD, addFalta),
    yield takeEvery(Types.REQUEST_BONUS_FALTA_EDIT, editFalta),
    yield takeEvery(Types.REQUEST_BONUS_FALTA_DEL, delFalta),
  ]);
}
