import { takeEvery, put, call, all } from "redux-saga/effects";
import {
  Types,
  ISignInLoginAction,
  LoginType,
  IRequestEmployeeUploadImageAction,
  IRequestLoginNewPasswordAction,
  IRequestLoginResetPasswordAction,
  ISuccessEmployeeUploadImageAction,
  ISuccessEmployeeDelImageAction,
  ISuccessLoginNewPasswordAction,
  ISuccessLoginResetPasswordAction
} from "../ducks/login/types";
import { getAbbreviatedName } from "~/utils/abbreviatedName"
import api from "~/services/api";
import jwt from 'jwt-decode';
import getDeviceInfo from "~/utils/deviceInfo";

const info = getDeviceInfo()
const os = info.e.os;
const browser = info.e.browser;

const signIn = (data: ISignInLoginAction) => {
  let deviceName;

  switch (os.name) {
    case 'Windows':
      deviceName = `Desktop ${os.name} ${os.version}`
      break;
    case 'Linux':
      deviceName = `Desktop ${os.name} ${os.version}`
      break;
    case 'Macintosh':
      deviceName = `Mac ${os.name} ${os.version}`
      break;
    default:
      deviceName = os.name;
  }

  return api.post('login', {
    email: data.payload.email,
    password: data.payload.password,
    os: `${os.name} ${os.version}`,
    browser: browser.name,
    deviceName
  })
    .then(response => response.data)
    .catch(err => {
      throw err.response;
    });
};

const signInNewPassword = (data: IRequestLoginNewPasswordAction["payload"]) => {
  let deviceName;

  switch (os.name) {
    case 'Windows':
      deviceName = `Desktop ${os.name} ${os.version}`
      break;
    case 'Linux':
      deviceName = `Desktop ${os.name} ${os.version}`
      break;
    case 'Macintosh':
      deviceName = `Mac ${os.name} ${os.version}`
      break;
    default:
      deviceName = os.name;
  }

  return api.post('login', {
    email: data.email,
    password: data.password,
    novasenha: data.novaSenha,
    os: `${os.name} ${os.version}`,
    browser: browser.name,
    deviceName
  })
    .then(response => response.data)
    .catch(err => {
      throw err.response;
    });
};


const apiCallUploadImage = (payload: IRequestEmployeeUploadImageAction["payload"]) => {
  return api
    .post('funcionarios/upload',
      { imagem: payload.imagem })
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
}

const apiCallDeleteImage = () => {
  return api
    .delete('/funcionarios/upload')
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
}

const apiCallResetPassword = (payload: IRequestLoginResetPasswordAction["payload"]) => {
  return api
    .put(`login/password/${payload.email}`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
}

const apiCallLogout = () => {
  const refreshToken = localStorage.getItem("user/refresh-token");
  return api.delete('login', {
    headers: {
      resfreshtoken: refreshToken
    }
  })
}

function* login(action: ISignInLoginAction) {
  try {
    const response = yield call(signIn, action);
    let valueToken: any;

    if (response.token)
      valueToken = jwt(response.token);

    if (response.RequestNovaSenha) {
      yield put({ type: Types.REQUEST_NEW_PASSWORD });
    } else {

      const result: LoginType = {
        name: response.data.name,
        email: action.payload.email,
        abbreviation: getAbbreviatedName(response.data.name),
        imagem: response.data.imagem,
        flagAdministrador: valueToken.flagAdministrador,
        flagRH: valueToken.flagRH,
        flagBP: valueToken.flagBP,
        flagEfetividade: valueToken.flagEfetividade
      };

      yield localStorage.setItem("user/token", response.token);
      yield localStorage.setItem("user/refresh-token", response.refreshToken);

      yield put({ type: Types.SUCCESS_USER_LOGIN, payload: result });
    }
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Tente novamente mais tarde. Caso contrário, entre em contato com o seu administrador.';
    yield put({ type: Types.FAILURE_USER_LOGIN, message });
  }
};

function* loginNewPassword(action: IRequestLoginNewPasswordAction) {
  try {
    const response = yield call(signInNewPassword, action.payload);
    let valueToken: any;

    if (response.token)
      valueToken = jwt(response.token);
    const result: LoginType = {
      name: response.data.name,
      email: action.payload.email,
      abbreviation: getAbbreviatedName(response.data.name),
      imagem: response.data.imagem,
      flagAdministrador: valueToken.flagAdministrador,
      flagRH: valueToken.flagRH,
      flagBP: valueToken.flagBP,
      flagEfetividade: valueToken.flagEfetividade
    };

    yield localStorage.setItem("user/token", response.token);
    yield localStorage.setItem("user/refresh-token", response.refreshToken);

    yield put({
      type: Types.SUCCESS_LOGIN_NEW_PASSWORD,
      payload: result
    } as ISuccessLoginNewPasswordAction);

  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Tente novamente mais tarde. Caso contrário, entre em contato com o seu administrador.';
    yield put({ type: Types.FAILURE_LOGIN_NEW_PASSWORD, message });
  }
};

function* uploadImg(action: IRequestEmployeeUploadImageAction) {
  try {
    yield call(apiCallUploadImage, action.payload);
    yield put({
      type: Types.SUCCESS_EMPLOYEE_UPLOAD,
      payload: {
        ...action.payload
      }
    } as ISuccessEmployeeUploadImageAction);
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível salvar a imagem';
    yield put({ type: Types.FAILURE_EMPLOYEE_UPLOAD, message });
  }
}

function* delImg() {
  try {
    yield call(apiCallDeleteImage);
    yield put({
      type: Types.SUCCESS_EMPLOYEE_DEL_UPLOAD
    } as ISuccessEmployeeDelImageAction);
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível remover a imagem';
    yield put({ type: Types.FAILURE_EMPLOYEE_DEL_UPLOAD, message });
  }
}

function* resetPassword(action: IRequestLoginResetPasswordAction) {
  try {
    yield call(apiCallResetPassword, action.payload);
    yield put({
      type: Types.SUCCESS_LOGIN_RESET_PASSWORD,
    } as ISuccessLoginResetPasswordAction);
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível redefinir a senha';
    yield put({ type: Types.FAILURE_LOGIN_RESET_PASSWORD, message });
  }
}

function* signOut() {
  try {
    yield call(apiCallLogout);
    localStorage.clear();
    yield put({
      type: Types.CLEAR_STORE
    });
  }
  catch {
    localStorage.clear();
    yield put({
      type: Types.CLEAR_STORE
    });
  }
}

export default function* loginRoot() {
  yield all([
    yield takeEvery(Types.REQUEST_USER_LOGIN, login),
    yield takeEvery(Types.REQUEST_EMPLOYEE_UPLOAD, uploadImg),
    yield takeEvery(Types.REQUEST_EMPLOYEE_DEL_UPLOAD, delImg),
    yield takeEvery(Types.USER_SIGNOUT, signOut),
    yield takeEvery(Types.REQUEST_LOGIN_NEW_PASSWORD, loginNewPassword),
    yield takeEvery(Types.REQUEST_LOGIN_RESET_PASSWORD, resetPassword),
  ])
}
