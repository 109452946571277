/**
 * Types
 */
 export enum Types {
    REQUEST_LOAD_STANDARDS_POSITIONS = "standardsPositions/REQUEST_LOAD_STANDARDS_POSITIONS",
    SUCCESS_LOAD_STANDARDS_POSITIONS = "standardsPositions/SUCCESS_LOAD_STANDARDS_POSITIONS",
    FAILURE_LOAD_STANDARDS_POSITIONS = "standardsPositions/FAILURE_LOAD_STANDARDS_POSITIONS",
  
    // REQUEST_LOAD_STANDARD_POSITION_BY_ID = "standardsPositions/REQUEST_LOAD_STANDARD_POSITION_BY_ID",
    // SUCCESS_LOAD_STANDARD_POSITION_BY_ID = "standardsPositions/SUCCESS_LOAD_STANDARD_POSITION_BY_ID",
    // FAILURE_LOAD_STANDARD_POSITION_BY_ID = "standardsPositions/FAILURE_LOAD_STANDARD_POSITION_BY_ID",
  
    REQUEST_ADD_STANDARD_POSITION = "standardsPositions/REQUEST_ADD_STANDARD_POSITION",
    SUCCESS_ADD_STANDARD_POSITION = "standardsPositions/SUCCESS_ADD_STANDARD_POSITION",
    FAILURE_ADD_STANDARD_POSITION = "standardsPositions/FAILURE_ADD_STANDARD_POSITION",
  
    REQUEST_EDIT_STANDARD_POSITION = "standardsPositions/REQUEST_EDIT_STANDARD_POSITION",
    SUCCESS_EDIT_STANDARD_POSITION = "standardsPositions/SUCCESS_EDIT_STANDARD_POSITION",
    FAILURE_EDIT_STANDARD_POSITION = "standardsPositions/FAILURE_EDIT_STANDARD_POSITION",
  
    REQUEST_DEL_STANDARD_POSITION = "standardsPositions/REQUEST_DEL_STANDARD_POSITION",
    SUCCESS_DEL_STANDARD_POSITION = "standardsPositions/SUCCESS_DEL_STANDARD_POSITION",
    FAILURE_DEL_STANDARD_POSITION = "standardsPositions/FAILURE_DEL_STANDARD_POSITION",
  }
  
  export type StandardPositionLoadDTO = {
    idPadraoCargo: number;
    descPadraoCargo: string;
  };
  
  export type StandardPositionAddOrEditDTO = {
    idPadraoCargo?: number;
    descPadraoCargo: string;
  };
  
  export type StandardPositionType = {
    idPadraoCargo: number;
    descPadraoCargo: string;
  };
  
  interface IStandardsPositionsState {
    readonly data: StandardPositionLoadDTO[];
    readonly loadingData: boolean;
    readonly loadingAction: boolean;
    readonly success: boolean;
    readonly error: boolean;
  }
  
  export type DataTypes = IStandardsPositionsState;
  
  /**
   * Actions
   */
  
  // LOAD
  export interface IRequestGetStandardsPositionsAction {
    type: typeof Types.REQUEST_LOAD_STANDARDS_POSITIONS;
  }
  
  export interface ISuccessGetStandardsPositionsAction {
    type: typeof Types.SUCCESS_LOAD_STANDARDS_POSITIONS;
    payload: StandardPositionLoadDTO[];
  }
  
  export interface IFailureGetStandardsPositionsAction {
    type: typeof Types.FAILURE_LOAD_STANDARDS_POSITIONS;
    message: string;
  }
  
  type StandardsPositionGetActions =
    | IRequestGetStandardsPositionsAction
    | ISuccessGetStandardsPositionsAction
    | IFailureGetStandardsPositionsAction;
  
  // //LOAD BY ID
  
  // export interface IRequestGetByIdStandardsPositionsAction {
  //   type: typeof Types.REQUEST_LOAD_STANDARD_POSITION_BY_ID;
  //   idPadraoCargo: number;
  // }
  
  // export interface ISuccessGetByIdStandardsPositionsAction {
  //   type: typeof Types.SUCCESS_LOAD_STANDARD_POSITION_BY_ID;
  //   payload: StandardPositionType;
  // }
  
  // export interface IFailureGetByIdStandardsPositionsAction {
  //   type: typeof Types.FAILURE_LOAD_STANDARD_POSITION_BY_ID;
  //   message: string;
  // }
  
  // type StandardsGetByIdPositionGetActions =
  //   | IRequestGetByIdStandardsPositionsAction
  //   | ISuccessGetByIdStandardsPositionsAction
  //   | IFailureGetByIdStandardsPositionsAction;
  
  //ADD
  
  export interface IRequestAddStandardsPositionsAction {
    type: typeof Types.REQUEST_ADD_STANDARD_POSITION;
    payload: StandardPositionAddOrEditDTO;
  }
  
  export interface ISuccessAddStandardsPositionsAction {
    type: typeof Types.SUCCESS_ADD_STANDARD_POSITION;
    payload: StandardPositionLoadDTO;
  }
  
  export interface IFailureAddStandardsPositionsAction {
    type: typeof Types.FAILURE_ADD_STANDARD_POSITION;
    message: string;
  }
  
  type StandardsPositionAddActions =
    | IRequestAddStandardsPositionsAction
    | ISuccessAddStandardsPositionsAction
    | IFailureAddStandardsPositionsAction;
  
  //ADD
  
  export interface IRequestEditStandardsPositionsAction {
    type: typeof Types.REQUEST_EDIT_STANDARD_POSITION;
    payload: StandardPositionAddOrEditDTO;
  }
  
  export interface ISuccessEditStandardsPositionsAction {
    type: typeof Types.SUCCESS_EDIT_STANDARD_POSITION;
    payload: StandardPositionLoadDTO;
  }
  
  export interface IFailureEditStandardsPositionsAction {
    type: typeof Types.FAILURE_EDIT_STANDARD_POSITION;
    message: string;
  }
  
  type StandardsPositionEditActions =
    | IRequestEditStandardsPositionsAction
    | ISuccessEditStandardsPositionsAction
    | IFailureEditStandardsPositionsAction;
  
  //DELETE
  
  export interface IRequestDelStandardsPositionsAction {
    type: typeof Types.REQUEST_DEL_STANDARD_POSITION;
    idPadraoCargo: number;
  }
  
  export interface ISuccessDelStandardsPositionsAction {
    type: typeof Types.SUCCESS_DEL_STANDARD_POSITION;
    idPadraoCargo: number;
  }
  
  export interface IFailureDelStandardsPositionsAction {
    type: typeof Types.FAILURE_DEL_STANDARD_POSITION;
    message: string;
  }
  
  type StandardsPositionDelActions =
    | IRequestDelStandardsPositionsAction
    | ISuccessDelStandardsPositionsAction
    | IFailureDelStandardsPositionsAction;
  
  export type StandardsPositionsActionTypes =
    | StandardsPositionGetActions
    // | StandardsGetByIdPositionGetActions
    | StandardsPositionAddActions
    | StandardsPositionEditActions
    | StandardsPositionDelActions;