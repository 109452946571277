// #region Imports
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Formik } from "formik";
import * as yup from "yup";

//FluentUI
import { Stack } from "office-ui-fabric-react/lib/Stack";
import {
  DetailsListLayoutMode,
  SelectionMode,
  Selection,
  IColumn,
} from "office-ui-fabric-react/lib/DetailsList";
import { ShimmeredDetailsList } from "office-ui-fabric-react/lib/ShimmeredDetailsList";
import { SearchBox } from "office-ui-fabric-react/lib/SearchBox";
import { CommandBarButton, ICommandBarStyles } from "office-ui-fabric-react";
import { IDialogContentProps } from "office-ui-fabric-react/lib/Dialog";
import { Spinner, SpinnerSize } from "office-ui-fabric-react/lib/Spinner";

import Panel from "~/components/layout/Panel";
import CustomDialog from "~/components/CustomDialogFluentUI";
import { Page, BreadcrumbItems } from "~/store/ducks/home/types";
import { InputText, InputCheckbox } from "~/components/Forms";

import { DeleteButton } from "~/components/Buttons";
import HeaderPage from "~/components/layout/HeaderPage";
import NoItems from "~/components/layout/NoItems";
import { PrimaryButton } from "~/components/Buttons";

//Estilos
import {
  Wrapper,
  ListContainer,
  DefaultButton,
  ContainerContent,
} from "./styles";

import { Creators as getVinculos } from "~/store/ducks/admin/vinculo";
import { Creators as getVinculoById } from "~/store/ducks/admin/vinculo";
import { Creators as addVinculo } from "~/store/ducks/admin/vinculo";
import { Creators as editVinculo } from "~/store/ducks/admin/vinculo";
import { Creators as delVinculo } from "~/store/ducks/admin/vinculo";

import {
  DataTypes as DataTypesVinculo,
  VinculosAddOrEditDTO,
  VinculosLoadDTO,
} from "~/store/ducks/admin/vinculo/types";

import { Creators as setCurrentPage } from "~/store/ducks/home";
import { RootState } from "~/store/ducks";
//#endregion

interface IVinculosState {
  columns: IColumn[];
  items: VinculosLoadDTO[];
  isPanelOpen: boolean;
  inicialValues: VinculosAddOrEditDTO;
  isFiltering: boolean;
  selectionDetails: string;
  selectionCount: number;
  isDialogCompetenceOpen: boolean;
  isDialogTypeOpen: boolean;
  isDialogExcludeOpen: boolean;
  search: string;
  newCompetenceText: string;
  newTypePDIText: string;
}

interface IPropsVinculos {
  vinculos: DataTypesVinculo;
  getVinculos: () => void;
  getVinculoById: (idVinculo: number) => void;
  addVinculo: (vinculo: VinculosAddOrEditDTO) => void;
  editVinculo: (vinculo: VinculosAddOrEditDTO) => void;
  delVinculo: (idVinculo: number) => void;
  setCurrentPage: (page: Page) => void;
}

class Vinculos extends Component<IPropsVinculos, IVinculosState> {
  private formRef: any;
  private inputSearch: any;
  private _selection: Selection;

  constructor(props: IPropsVinculos) {
    super(props);
    //#region Colunas
    const columns: IColumn[] = [
      {
        key: "column1",
        name: "ID",
        ariaLabel: "ID do Vínculo",
        fieldName: "idVinculo",
        isRowHeader: true,
        minWidth: 50,
        maxWidth: 75,
        isResizable: true,
        isSortedDescending: false,
        onColumnClick: this._onColumnClick,
      },
      {
        key: "column2",
        name: "Descrição",
        fieldName: "descVinculo",
        minWidth: 180,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "string",
        isPadded: true,
      },
      {
        key: "column3",
        name: "Nome Amigável",
        fieldName: "nomeAmigavel",
        minWidth: 210,
        maxWidth: 210,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "string",
        isPadded: true,
      },
      {
        key: "column4",
        name: "Eleg. Bônus",
        fieldName: "elegivelBonus",
        minWidth: 50,
        maxWidth: 50,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "string",
        isPadded: true,
        onRender: (item: VinculosLoadDTO) =>
          item.elegivelBonus ? "SIM" : "NÃO",
      },
      {
        key: "column5",
        name: "Eleg. Mérito",
        fieldName: "elegivelMerito",
        minWidth: 50,
        maxWidth: 50,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "string",
        isPadded: true,
        onRender: (item: VinculosLoadDTO) =>
          item.elegivelMerito ? "SIM" : "NÃO",
      },
    ];
    //#endregion

    this.state = {
      columns: columns,
      items: [],
      isPanelOpen: false,
      selectionDetails: "",
      inicialValues: initialVinculo,
      isFiltering: false,
      selectionCount: 0,
      isDialogExcludeOpen: false,
      search: "",
      isDialogCompetenceOpen: false,
      isDialogTypeOpen: false,
      newCompetenceText: "",
      newTypePDIText: "",
    };

    this._selection = new Selection({
      onSelectionChanged: () => {
        this.setState({
          selectionDetails: this._getSelectionDetails(),
        });
      },
    });

    this.formRef = React.createRef();
    this.inputSearch = React.createRef();
  }

  componentDidMount() {
    const page: Page = {
      key: "vinculos",
      pages: itemsBreadCrumb,
    };
    this.props.setCurrentPage(page);
    this.props.getVinculos();
  }

  componentDidUpdate(prevProps: IPropsVinculos, _: any) {
    if (this.state.isPanelOpen) {
      if (prevProps.vinculos.success !== this.props.vinculos.success) {
        if (this.props.vinculos.success) {
          this.setState({ isPanelOpen: false });
        }
      }
    }

    if (
      this.props.vinculos.itemSelected.success !==
      prevProps.vinculos.itemSelected.success
    ) {
      if (this.props.vinculos.itemSelected.success) {
        this.setState({
          inicialValues: this.props.vinculos.itemSelected.item!,
        });
      }
    }

    // if (this.props.pdi.error !== prevProps.pdi.error) {
    //     if (this.props.pdi.error) {
    //         this.setState({ isPanelOpen: false, inicialValues: initialVinculo })
    //     }
    // }
  }

  // Submit do formulário Formik
  handleSubmit = () => {
    if (this.formRef.current) {
      this.formRef.current.handleSubmit();
    }
  };

  _onItemInvoked = (item: VinculosLoadDTO): void => {
    this.props.getVinculoById(item.idVinculo);
    this.setState({
      isPanelOpen: true,
    });
  };

  _getSelectionDetails(): any {
    const selectionCount = this._selection.getSelectedCount();
    this.setState({ selectionCount });
    this._selection.getSelection();
  }

  openEdit = () => {
    const selected = this._selection.getSelection()[0] as VinculosLoadDTO;

    this.props.getVinculoById(selected.idVinculo);
    this.setState({
      isPanelOpen: true,
    });
  };

  cancelPanel = () => {
    this._selection.setAllSelected(false);
    this.setState({ isPanelOpen: false });
  };

  exclude = () => {
    this.props.delVinculo(
      (this._selection.getSelection()[0] as VinculosLoadDTO).idVinculo
    );
    this.setState({ isDialogExcludeOpen: false });
    this._selection.setAllSelected(false);
  };

  //#region  Funções do filtro

  handleSearch = (e: any) => {
    const data = this.props.vinculos.data;
    const text = e?.target?.value;

    let items = text
      ? data.filter(
          (item) =>
            item.descVinculo!.toLowerCase().indexOf(text.toLowerCase()) > -1
        )
      : data;

    this.setState({ items, isFiltering: true, search: text });
  };

  //#endregion

  commandBarRender = () => {
    const { selectionCount } = this.state;
    if (selectionCount === 1) {
      return (
        <>
          <CommandBarButton
            styles={btnStyle}
            iconProps={{ iconName: "Edit" }}
            text="Editar"
            onClick={() => this.openEdit()}
          />
          <CommandBarButton
            styles={btnStyle}
            iconProps={{ iconName: "Delete" }}
            text="Excluir"
            onClick={() => this.setState({ isDialogExcludeOpen: true })}
          />
        </>
      );
    }
  };

  render() {
    const {
      isPanelOpen,
      isDialogExcludeOpen,
      inicialValues,
      isFiltering,
      items,
      columns,
      search,
    } = this.state;
    const { vinculos, getVinculos, addVinculo, editVinculo } = this.props;

    return (
      <>
        <Panel
          title={
            !inicialValues?.idVinculo
              ? "Novo Vínculo"
              : inicialValues?.descVinculo
          }
          open={isPanelOpen}
          onClose={() => this.cancelPanel()}
          footer={
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <DefaultButton onClick={() => this.cancelPanel()}>
                Cancelar
              </DefaultButton>
              <PrimaryButton
                onClick={this.handleSubmit}
                isLoading={vinculos.loadingAction}
                text="Salvar"
              />
            </Stack>
          }
        >
          {vinculos.itemSelected.loading ? (
            <Spinner
              size={SpinnerSize.medium}
              styles={{ root: { height: "100%" } }}
            />
          ) : (
            <Formik
              innerRef={this.formRef}
              initialValues={inicialValues}
              validationSchema={validationVinculoSchema}
              validateOnChange={false}
              validateOnBlur={true}
              enableReinitialize
              onSubmit={(values: VinculosAddOrEditDTO) => {
                if (!values.idVinculo) {
                  addVinculo(values);
                } else {
                  editVinculo(values);
                }
              }}
            >
              {({
                handleSubmit,
                handleChange,
                values,
                errors,
                setFieldValue,
                setFieldError,
              }) => (
                <form
                  onSubmit={handleSubmit}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <InputText
                    value={values?.descVinculo ?? ""}
                    onChange={(e) => {
                      handleChange(e);
                      setFieldError("descVinculo", "");
                    }}
                    id="descVinculo"
                    error={errors.descVinculo ? true : false}
                    name="descVinculo"
                    type="text"
                    label="Descrição"
                    helperText={errors.descVinculo}
                    className="mt-2"
                    autoFocus
                  />
                  <InputText
                    value={values?.nomeAmigavel ?? ""}
                    onChange={(e) => {
                      handleChange(e);
                      setFieldError("nomeAmigavel", "");
                    }}
                    id="nomeAmigavel"
                    error={errors.nomeAmigavel ? true : false}
                    name="nomeAmigavel"
                    type="text"
                    label="Descrição Amigável"
                    helperText={errors.nomeAmigavel}
                    className="mt-2"
                  />

                  <InputCheckbox
                    checked={values.elegivelBonus ?? false}
                    onChange={handleChange}
                    name="elegivelBonus"
                    color="primary"
                    label="Elegível ao PPR"
                  />

                  <InputCheckbox
                    checked={values.elegivelMerito ?? false}
                    onChange={handleChange}
                    name="elegivelMerito"
                    color="primary"
                    label="Elegível Mérito"
                  />
                </form>
              )}
            </Formik>
          )}
        </Panel>

        <Wrapper>
          <ContainerContent>
            <HeaderPage
              title="Vínculos Empregatícios"
              leftItems={
                <>
                  <CommandBarButton
                    styles={btnStyle}
                    iconProps={{ iconName: "Add" }}
                    disabled={vinculos.loadingData}
                    text="Adicionar Vínculo"
                    onClick={() =>
                      this.setState({
                        isPanelOpen: true,
                        inicialValues: initialVinculo,
                      })
                    }
                  />
                  {this.commandBarRender()}
                </>
              }
              rightItems={
                <>
                  <SearchBox
                    placeholder="Pesquisar"
                    value={search}
                    onChange={(e) => this.handleSearch(e)}
                    // onKeyUp={(e) => this.filter(e.key)}
                    componentRef={this.inputSearch}
                    styles={{
                      root: {
                        border: "none",
                        width: 200,
                        marginRight: 1,
                      },
                    }}
                  />
                  <CommandBarButton
                    styles={btnStyle}
                    iconProps={{ iconName: "Refresh" }}
                    text="Atualizar"
                    onClick={() => getVinculos()}
                  />
                </>
              }
            />
            {!vinculos.loadingData && vinculos.data.length === 0 ? (
              <NoItems
                error={vinculos.error}
                text="Não há vínculos cadastrados"
                icon="Work"
              />
            ) : (
              <ListContainer>
                <ShimmeredDetailsList
                  items={isFiltering ? items : vinculos.data}
                  enableShimmer={vinculos.loadingData}
                  columns={columns}
                  selectionMode={SelectionMode.single}
                  selection={this._selection}
                  getKey={this._getKey}
                  selectionPreservedOnEmptyClick={true}
                  setKey="single"
                  layoutMode={DetailsListLayoutMode.justified}
                  isHeaderVisible={true}
                  onItemInvoked={this._onItemInvoked}
                />
              </ListContainer>
            )}
          </ContainerContent>
        </Wrapper>

        <CustomDialog
          hidden={!isDialogExcludeOpen}
          onDismiss={() => this.setState({ isDialogExcludeOpen: false })}
          dialogContentProps={dialogContentProps}
        >
          <DefaultButton
            onClick={() => this.setState({ isDialogExcludeOpen: false })}
            text="Cancelar"
          />
          <DeleteButton onClick={() => this.exclude()} text="Excluir" />
        </CustomDialog>
      </>
    );
  }

  private _onColumnClick = (
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn
  ): void => {
    const { columns } = this.state;
    const newColumns: IColumn[] = columns.slice();
    let items: VinculosLoadDTO[] = [];
    if (this.state.isFiltering) {
      items = this.state.items;
    } else {
      items = this.props.vinculos.data;
    }
    const currColumn: IColumn = newColumns.filter(
      (currCol) => column.key === currCol.key
    )[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    const newItems = this._sort(
      items,
      currColumn.fieldName!,
      currColumn.isSortedDescending
    );
    this.setState({
      columns: newColumns,
      items: newItems,
    });
  };

  private _getKey(item: any, index?: number): any {
    if (item !== undefined) return item.key;
  }

  private _sort<T>(
    items: T[],
    columnKey: string,
    isSortedDescending?: boolean
  ): T[] {
    const result = (prop: string, obj: T) =>
      prop.split(".").reduce((a: any, b: any) => a[b] ?? "", obj);
    return items.sort((a: T, b: T) =>
      (
        isSortedDescending
          ? result(columnKey, a) < result(columnKey, b)
          : result(columnKey, a) > result(columnKey, b)
      )
        ? 1
        : -1
    );
  }
}

/**
 * Validação do formulário
 */
const validationVinculoSchema = yup.object().shape({
  descVinculo: yup
    .string()
    .max(100, "Máximo de 100 caracteres")
    .required("Campo obrigatório"),
  nomeAmigavel: yup
    .string()
    .max(100, "Máximo de 100 caracteres")
    .required("Campo obrigatório"),
  elegivelBonus: yup.bool().nullable(),
  elegivelMerito: yup.bool().nullable(),
});

const dialogContentProps: IDialogContentProps = {
  title: "Excluir?",
  closeButtonAriaLabel: "Close",
  subText: "Tem certeza de que deseja excluir este item?",
};

const itemsBreadCrumb: BreadcrumbItems[] = [
  {
    text: "Home",
    isCurrentItem: false,
    icon: "HomeSolid",
    onlyIcon: true,
    url: "/",
  },
  { text: "Vínculos", isCurrentItem: true },
];

const initialVinculo: VinculosAddOrEditDTO = {
  descVinculo: "",
  nomeAmigavel: "",
  elegivelBonus: false,
  elegivelMerito: false,
};

const btnStyle: Partial<ICommandBarStyles> = {
  root: {
    height: 44,
  },
};

const mapStateToProps = (state: RootState) => ({
  vinculos: state.vinculoReducer,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      ...getVinculos,
      ...getVinculoById,
      ...addVinculo,
      ...editVinculo,
      ...delVinculo,
      ...setCurrentPage,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Vinculos);
