import axios from "~/services/api";
import { takeLatest, put, call, all } from "redux-saga/effects";
import {
  Types,
  TypeConductType,
  IRequestTypeConductLoadAction,
  IRequestTypeConductAddAction,
  IRequestTypeConductEditAction,
  IRequestTypeConductDeleteAction,
  ISuccessTypeConductLoadAction,
  ISuccessTypeConductAddAction,
  ISuccessTypeConductEditAction,
  ISuccessTypeConductDeleteAction,
} from "../../ducks/admin/typeConducts/types";

const apiCallGet = () => {
  return axios
    .get(`admin/tiposCondutas`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallPost = (item: TypeConductType) => {
  return axios
    .post("admin/tiposCondutas", {
      ...item,
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallPut = (item: TypeConductType) => {
  return axios
    .put(`admin/tiposCondutas/${item.idTipoConduta}`, {
      ...item,
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallDel = (idTipoConduta: number) => {
  return axios
    .delete(`admin/tiposCondutas/${idTipoConduta}`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

function* load(action: IRequestTypeConductLoadAction) {
  try {
    const items: TypeConductType[] = yield call(apiCallGet);
    yield put({
      type: Types.SUCCESS_TYPES_CONDUCTS_LOAD,
      payload: items,
    } as ISuccessTypeConductLoadAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível carregar tipos de conduta.";
    yield put({ type: Types.FAILURE_TYPES_CONDUCTS_LOAD, message });
  }
}

function* add(action: IRequestTypeConductAddAction) {
  try {
    const idTipoConduta: { idTipoConduta: number } = yield call(
      apiCallPost,
      action.typeConduct
    );
    yield put({
      type: Types.SUCCESS_TYPE_CONDUCT_ADD,
      typeConduct: {
        ...action.typeConduct,
        ...idTipoConduta,
        flagAtivo: true,
      },
    } as ISuccessTypeConductAddAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível adicionar o tipo de conduta";
    yield put({ type: Types.FAILURE_TYPE_CONDUCT_ADD, message });
  }
}

function* edit(action: IRequestTypeConductEditAction) {
  try {
    yield call(apiCallPut, action.typeConduct);
    yield put({
      type: Types.SUCCESS_TYPE_CONDUCT_EDIT,
      typeConduct: action.typeConduct,
    } as ISuccessTypeConductEditAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível editar o tipo da conduta";
    yield put({ type: Types.FAILURE_TYPE_CONDUCT_EDIT, message });
  }
}

function* del(action: IRequestTypeConductDeleteAction) {
  try {
    yield call(apiCallDel, action.idTipoConduta);
    yield put({
      type: Types.SUCCESS_TYPE_CONDUCT_DELETE,
      idTipoConduta: action.idTipoConduta,
    } as ISuccessTypeConductDeleteAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível excluir o tipo da conduta";
    yield put({ type: Types.FAILURE_TYPE_CONDUCT_DELETE, message });
  }
}

export default function* positionsRoot() {
  yield all([
    yield takeLatest(Types.REQUEST_TYPES_CONDUCTS_LOAD, load),
    yield takeLatest(Types.REQUEST_TYPE_CONDUCT_ADD, add),
    yield takeLatest(Types.REQUEST_TYPE_CONDUCT_EDIT, edit),
    yield takeLatest(Types.REQUEST_TYPE_CONDUCT_DELETE, del),
  ]);
}
