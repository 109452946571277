import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
 

import {
  DetailsListLayoutMode,
  SelectionMode,
  Selection,
  IColumn,
  DetailsRow,
  IDetailsListProps,
  IDetailsRowStyles,
} from "office-ui-fabric-react/lib/DetailsList";
import { ShimmeredDetailsList } from "office-ui-fabric-react/lib/ShimmeredDetailsList";

import { ListContainer } from "./styles";

import { Creators as loadTeam } from "~/store/ducks/team";
import { DataTypes as DataTypesTeam, TeamType } from "~/store/ducks/team/types";
import { RootState } from "~/store/ducks";
import { getAbbreviatedName } from "~/utils/abbreviatedName";
import Colors from "~/assets/js/colors";
import { Persona, PersonaSize } from "@fluentui/react";
import { getPresence } from "~/utils/statusFunc";

const baseURL = process.env.REACT_APP_BASE_URL;

interface IListTeamState {
  columns: IColumn[];
  selectionDetails: string;
  selectionCount: number;
  items: TeamType[];
  isFiltering: boolean;
}

interface IListTeamProps extends RouteComponentProps {
  teamState: DataTypesTeam;
  handleSelectItem: (item: TeamType | null) => void;
  loadTeam: () => void;
  history: RouteComponentProps["history"];
}

class ListTeam extends Component<IListTeamProps, IListTeamState> {
  private _selection: Selection;

  constructor(props: IListTeamProps) {
    super(props);
    //#region Colunas
    const columns: IColumn[] = [
      {
        key: "column1",
        name: "",
        ariaLabel: "Status funcionário",
        fieldName: "nomeCompleto",
        minWidth: 30,
        maxWidth: 30,
        isSortedDescending: false,
        onRender: (item: TeamType) => (
          <Persona
            imageUrl={`${baseURL}uploads/${item.imagem}`}
            imageInitials={getAbbreviatedName(item.nomeCompleto)}
            initialsColor={Colors.primary}
            size={PersonaSize.size32}
            presence={getPresence(item.status ? "A" : "D")}
            styles={{
              root: {
                width: 32,
                marginRight: 10,
                selectors: {
                  "& .ms-Persona-initials": {
                    filter: "grayscale(0)",
                    // filter: status === 'D' ? 'grayscale(1)' : 'grayscale(0)'
                  },
                },
              },
            }}
          />
        ),
      },
      {
        key: "column2",
        name: "ID",
        ariaLabel: "Id funcionário",
        fieldName: "idFuncionario",
        isRowHeader: true,
        minWidth: 50,
        maxWidth: 70,
        isResizable: true,
        isSortedDescending: false,
        onColumnClick: this._onColumnClick,
      },
      {
        key: "column3",
        name: "Nome",
        fieldName: "nomeCompleto",
        minWidth: 210,
        maxWidth: 350,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "string",
      },
      {
        key: "column4",
        name: "Cargo",
        fieldName: "titulo",
        minWidth: 210,
        maxWidth: 350,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        onRender: (item: TeamType) => `${item.idCargo} - ${item.titulo}`,
      },
    ];
    //#endregion

    this.state = {
      columns,
      items: [],
      isFiltering: false,
      selectionDetails: "",
      selectionCount: 0,
    };

    this._selection = new Selection({
      onSelectionChanged: () => {
        this.setState({
          selectionDetails: this._getSelectionDetails(),
        });
      },
    });
  }

  _getSelectionDetails(): any {
    const selectionCount = this._selection.getSelectedCount();
    this.props.handleSelectItem(this._selection.getSelection()[0] as TeamType);
    this.setState({ selectionCount });
    this._selection.getSelection();
  }

  _onItemInvoked = (item: TeamType): void => {
    const { idFuncionario } = item;
    this.props.history.push(`/perfil/${idFuncionario}`);
  };

  render() {
    const { columns, isFiltering, items } = this.state;
    const { teamState } = this.props;
    const { data: team, loadingData } = teamState;

    return (
      <ListContainer>
        <ShimmeredDetailsList
          items={isFiltering ? items : team}
          enableShimmer={loadingData}
          columns={columns}
          selectionMode={SelectionMode.none}
          selection={this._selection}
          getKey={this._getKey}
          selectionPreservedOnEmptyClick={true}
          setKey="single"
          layoutMode={DetailsListLayoutMode.justified}
          isHeaderVisible={true}
          onItemInvoked={this._onItemInvoked}
          onRenderRow={this._onRenderRow}
        />
      </ListContainer>
    );
  }

  private _onColumnClick = (
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn
  ): void => {
    const { columns } = this.state;
    const newColumns: IColumn[] = columns.slice();
    let items: TeamType[] = [];
    if (this.state.isFiltering) {
      items = this.state.items;
    } else {
      items = this.props.teamState.data;
    }
    const currColumn: IColumn = newColumns.filter(
      (currCol) => column.key === currCol.key
    )[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    const newItems = this._sort(
      items,
      currColumn.fieldName!,
      currColumn.isSortedDescending
    );
    this.setState({
      columns: newColumns,
      items: newItems,
    });
  };

  private _getKey(item: any, index?: number): any {
    if (item !== undefined) return item.key;
  }

  private _sort<T>(
    items: T[],
    columnKey: string,
    isSortedDescending?: boolean
  ): T[] {
    const result = (prop: string, obj: T) =>
      prop.split(".").reduce((a: any, b: any) => a[b] ?? "", obj);
    return items.sort((a: T, b: T) =>
      (
        isSortedDescending
          ? result(columnKey, a) < result(columnKey, b)
          : result(columnKey, a) > result(columnKey, b)
      )
        ? 1
        : -1
    );
  }

  private _onRenderRow: IDetailsListProps["onRenderRow"] = (props) => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (props) {
      const stylesForRow = (detailsRowStyleProps: any) => {
        customStyles.cell = {
          padding: 0,
          paddingLeft: 12,
          display: "flex",
          alignItems: "center",
        };
        // customStyles.root = {
        //   selectors: {
        //     "& .ms-DetailsRow-fields": {
        //       display: "flex",
        //       alignItems: "center",
        //     },
        //   },
        // };
        return customStyles;
      };
      return (
        <DetailsRow
          {...props}
          styles={stylesForRow}
          itemIndex={props.itemIndex}
        />
      );
    }
    return null;
  };
}

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      ...loadTeam,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ListTeam)
);
