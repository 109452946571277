import { EmployeeType } from "~/store/ducks/employees/types";

/**
 * Types
 */
export enum Types {
  //GET
  REQUEST_CYCLES_LOAD = "cycles/REQUEST_CYCLES_LOAD",
  SUCCESS_CYCLES_LOAD = "cycles/SUCCESS_CYCLES_LOAD",
  FAILURE_CYCLES_LOAD = "cycles/FAILURE_CYCLES_LOAD",
  //GET BY ID
  REQUEST_CYCLES_LOAD_ID = "cycles/REQUEST_CYCLES_LOAD_ID",
  SUCCESS_CYCLES_LOAD_ID = "cycles/SUCCESS_CYCLES_LOAD_ID",
  FAILURE_CYCLES_LOAD_ID = "cycles/FAILURE_CYCLES_LOAD_ID",
  //ADD
  REQUEST_CYCLES_ADD = "cycles/REQUEST_CYCLES_EMAIL",
  SUCCESS_CYCLES_ADD = "cycles/SUCCESS_CYCLES_ADD",
  FAILURE_CYCLES_ADD = "cycles/FAILURE_CYCLES_ADD",
  //PUT
  REQUEST_CYCLES_EDIT = "cycles/REQUEST_CYCLES_EDIT",
  SUCCESS_CYCLES_EDIT = "cycles/SUCCESS_CYCLES_EDIT",
  FAILURE_CYCLES_EDIT = "cycles/FAILURE_CYCLES_EDIT",
  //DELETE
  REQUEST_CYCLES_DELETE = "cycles/REQUEST_CYCLES_DELETE",
  SUCCESS_CYCLES_DELETE = "cycles/SUCCESS_CYCLES_DELETE",
  FAILURE_CYCLES_DELETE = "cycles/FAILURE_CYCLES_DELETE",
  //ATUALIZAR ORG
  REQUEST_CYCLES_ATUALIZAR_ORG = "cycles/REQUEST_CYCLES_ATUALIZAR_ORG",
  SUCCESS_CYCLES_ATUALIZAR_ORG = "cycles/SUCCESS_CYCLES_ATUALIZAR_ORG",
  FAILURE_CYCLES_ATUALIZAR_ORG = "cycles/FAILURE_CYCLES_ATUALIZAR_ORG",
}
/**
 * Data types
 */

type ItemSelected = {
  item: CycleType | null;
  loading: boolean;
  error: boolean;
  success: boolean;
};

export type CycleType = {
  idCiclo: number | null;
  idAreaPai: number | null;
  ano: number | null;
  status: string;
  area: {
    nomeLocal: string;
  };
  responsavel: Partial<EmployeeType>;
  prazoPlanejamento: Date | null;
  dataPublicacao: Date | null;
  minimoIniciativas: number | null;
  targetIniciativas: number | null;
  maximoIniciativas: number | null;
  idObjetivoIniciativa: number | null;
  objetivo: {
    descObjetivo: string;
  };
  limiteAdm: Date | null;
  mesesTrabalhados: number | null;
  gerarProjeto: boolean;
  descProjeto: string;
  nomeProjeto: string;
  dataHoraAtuOrg: Date | null;
};

interface ICyclesState {
  readonly data: CycleType[];
  readonly loadingData: boolean;
  readonly loadingAction: boolean;
  readonly success: boolean;
  readonly error: boolean;
  readonly itemSelected: ItemSelected;
}

export type DataTypes = ICyclesState;

/**
 * Actions
 */

//GET
export interface IRequestCyclesLoadAction {
  type: typeof Types.REQUEST_CYCLES_LOAD;
  search: string;
}

interface ISuccessCyclesLoadAction {
  type: typeof Types.SUCCESS_CYCLES_LOAD;
  payload: CycleType[];
}

interface IFailureCyclesLoadAction {
  type: typeof Types.FAILURE_CYCLES_LOAD;
  message: string;
}

type CyclesLoadAction =
  | IRequestCyclesLoadAction
  | ISuccessCyclesLoadAction
  | IFailureCyclesLoadAction;

//GET BY ID
export interface IRequestCyclesLoadByIdAction {
  type: typeof Types.REQUEST_CYCLES_LOAD_ID;
  id: number;
}

interface ISuccessCyclesLoadByIdAction {
  type: typeof Types.SUCCESS_CYCLES_LOAD_ID;
  payload: CycleType;
}

interface IFailureCyclesLoadByIdAction {
  type: typeof Types.FAILURE_CYCLES_LOAD_ID;
  message: string;
}

type CyclesLoadByIdAction =
  | IRequestCyclesLoadByIdAction
  | ISuccessCyclesLoadByIdAction
  | IFailureCyclesLoadByIdAction;

//ADD
export interface IRequestCyclesAddAction {
  type: typeof Types.REQUEST_CYCLES_ADD;
  cycle: CycleType;
}

interface ISuccessCyclesAddAction {
  type: typeof Types.SUCCESS_CYCLES_ADD;
  cycle: CycleType;
}

interface IFailureCyclesAddAction {
  type: typeof Types.FAILURE_CYCLES_ADD;
  message: string;
}

type CyclesAddAction =
  | IRequestCyclesAddAction
  | ISuccessCyclesAddAction
  | IFailureCyclesAddAction;

//PUT
export interface IRequestCyclesEditAction {
  type: typeof Types.REQUEST_CYCLES_EDIT;
  cycle: Partial<CycleType>;
}

export interface ISuccessCyclesEditAction {
  type: typeof Types.SUCCESS_CYCLES_EDIT;
  cycle: CycleType;
}

export interface IFailureCyclesEditAction {
  type: typeof Types.FAILURE_CYCLES_EDIT;
  message: string;
}

type CyclesEditAction =
  | IRequestCyclesEditAction
  | ISuccessCyclesEditAction
  | IFailureCyclesEditAction;

//DELETE
export interface IRequestCyclesDeleteAction {
  type: typeof Types.REQUEST_CYCLES_DELETE;
  id: number;
}

export interface ISuccessCyclesDeleteAction {
  type: typeof Types.SUCCESS_CYCLES_DELETE;
  id: number;
}

export interface IFailureCyclesDeleteAction {
  type: typeof Types.FAILURE_CYCLES_DELETE;
  message: string;
}

type CyclesDeleteAction =
  | IRequestCyclesDeleteAction
  | ISuccessCyclesDeleteAction
  | IFailureCyclesDeleteAction;

//REQUEST_CYCLES_ATUALIZAR_ORG
export interface IRequestCyclesAtualizarOrgAction {
  type: typeof Types.REQUEST_CYCLES_ATUALIZAR_ORG;
  idCiclo: number;
}

export interface ISuccessCyclesAtualizarOrgAction {
  type: typeof Types.SUCCESS_CYCLES_ATUALIZAR_ORG;
  payload: Date | null;
}

export interface IFailureCyclesAtualizarOrgAction {
  type: typeof Types.FAILURE_CYCLES_ATUALIZAR_ORG;
  message: string;
}

type CyclesAtualizarOrgAction =
  | IRequestCyclesAtualizarOrgAction
  | ISuccessCyclesAtualizarOrgAction
  | IFailureCyclesAtualizarOrgAction;

export type CyclesActionTypes =
  | CyclesLoadAction
  | CyclesAddAction
  | CyclesEditAction
  | CyclesDeleteAction
  | CyclesLoadByIdAction
  | CyclesAtualizarOrgAction;
