import { TabPerfomanceType } from "../tasks/types";

/**
 * Types
 */
export enum Types {
  //GET BY ID
  REQUEST_CALIBRATION_LOAD_BY_ID = "calibration/REQUEST_CALIBRATION_LOAD_BY_ID",
  SUCCESS_CALIBRATION_LOAD_BY_ID = "calibration/SUCCESS_CALIBRATION_LOAD_BY_ID",
  FAILURE_CALIBRATION_LOAD_BY_ID = "calibration/FAILURE_CALIBRATION_LOAD_BY_ID",
  //GET BY ID NIVEL
  REQUEST_CALIBRATION_LOAD_BY_ID_NIVEL = "calibration/REQUEST_CALIBRATION_LOAD_BY_ID_NIVEL",
  SUCCESS_CALIBRATION_LOAD_BY_ID_NIVEL = "calibration/SUCCESS_CALIBRATION_LOAD_BY_ID_NIVEL",
  FAILURE_CALIBRATION_LOAD_BY_ID_NIVEL = "calibration/FAILURE_CALIBRATION_LOAD_BY_ID_NIVEL",
  //ATIVA AVALIADOR
  REQUEST_CALIBRATION_ATIVA_AVALIADOR = "calibration/REQUEST_CALIBRATION_ATIVA_AVALIADOR",
  SUCCESS_CALIBRATION_ATIVA_AVALIADOR = "calibration/SUCCESS_CALIBRATION_ATIVA_AVALIADOR",
  FAILURE_CALIBRATION_ATIVA_AVALIADOR = "calibration/FAILURE_CALIBRATION_ATIVA_AVALIADOR",
  //DESATIVA AVALIADOR
  REQUEST_CALIBRATION_DESATIVA_AVALIADOR = "calibration/REQUEST_CALIBRATION_DESATIVA_AVALIADOR",
  SUCCESS_CALIBRATION_DESATIVA_AVALIADOR = "calibration/SUCCESS_CALIBRATION_DESATIVA_AVALIADOR",
  FAILURE_CALIBRATION_DESATIVA_AVALIADOR = "calibration/FAILURE_CALIBRATION_DESATIVA_AVALIADOR",
  //FINALIZAR
  REQUEST_CALIBRATION_FINALIZAR = "calibration/REQUEST_CALIBRATION_FINALIZAR",
  SUCCESS_CALIBRATION_FINALIZAR = "calibration/SUCCESS_CALIBRATION_FINALIZAR",
  FAILURE_CALIBRATION_FINALIZAR = "calibration/FAILURE_CALIBRATION_FINALIZAR",
  //GET AVALIADO
  REQUEST_CALIBRATION_LOAD_AVALIADO = "calibration/REQUEST_CALIBRATION_LOAD_AVALIADO",
  SUCCESS_CALIBRATION_LOAD_AVALIADO = "calibration/SUCCESS_CALIBRATION_LOAD_AVALIADO",
  FAILURE_CALIBRATION_LOAD_AVALIADO = "calibration/FAILURE_CALIBRATION_LOAD_AVALIADO",
  //SALVA AVALIADO
  REQUEST_CALIBRATION_SAVE_AVALIADO = "calibration/REQUEST_CALIBRATION_SAVE_AVALIADO",
  SUCCESS_CALIBRATION_SAVE_AVALIADO = "calibration/SUCCESS_CALIBRATION_SAVE_AVALIADO",
  FAILURE_CALIBRATION_SAVE_AVALIADO = "calibration/FAILURE_CALIBRATION_SAVE_AVALIADO",
  // REQUEST_INICIA_CALIBRACAO
  REQUEST_CALIBRATION_INIT = "calibration/REQUEST_CALIBRATION_INIT",
  SUCCESS_CALIBRATION_INIT = "calibration/SUCCESS_CALIBRATION_INIT",
  FAILURE_CALIBRATION_INIT = "calibration/FAILURE_CALIBRATION_INIT",
  // REQUEST_FINISHED_CALIBRACAO
  REQUEST_CALIBRATION_STOP = "calibration/REQUEST_CALIBRATION_STOP",
  SUCCESS_CALIBRATION_STOP = "calibration/SUCCESS_CALIBRATION_STOP",
  FAILURE_CALIBRATION_STOP = "calibration/FAILURE_CALIBRATION_STOP",
  // REQUEST AVALIADO UP
  REQUEST_CALIBRATION_AVALIADO_UP = "calibration/REQUEST_CALIBRATION_AVALIADO_UP",
  SUCCESS_CALIBRATION_AVALIADO_UP = "calibration/SUCCESS_CALIBRATION_AVALIADO_UP",
  FAILURE_CALIBRATION_AVALIADO_UP = "calibration/FAILURE_CALIBRATION_AVALIADO_UP",
  // REQUEST AVALIADO DOWN
  REQUEST_CALIBRATION_AVALIADO_DOWN = "calibration/REQUEST_CALIBRATION_AVALIADO_DOWN",
  SUCCESS_CALIBRATION_AVALIADO_DOWN = "calibration/SUCCESS_CALIBRATION_AVALIADO_DOWN",
  FAILURE_CALIBRATION_AVALIADO_DOWN = "calibration/FAILURE_CALIBRATION_AVALIADO_DOWN",
  // REQUEST GET COMENTARIOS
  REQUEST_COMMENT_AVALIADO_LOAD = "calibration/REQUEST_COMMENT_AVALIADO_LOAD",
  SUCCESS_COMMENT_AVALIADO_LOAD = "calibration/SUCCESS_COMMENT_AVALIADO_LOAD",
  FAILURE_COMMENT_AVALIADO_LOAD = "calibration/FAILURE_COMMENT_AVALIADO_LOAD",
  // REQUEST ADD COMENTARIO
  REQUEST_COMMENT_AVALIADO_ADD = "calibration/REQUEST_COMMENT_AVALIADO_ADD",
  SUCCESS_COMMENT_AVALIADO_ADD = "calibration/SUCCESS_COMMENT_AVALIADO_ADD",
  FAILURE_COMMENT_AVALIADO_ADD = "calibration/FAILURE_COMMENT_AVALIADO_ADD",
  // REQUEST ADD SUCESSAO
  REQUEST_SUCESSAO_ADD = "calibration/REQUEST_SUCESSAO_ADD",
  SUCCESS_SUCESSAO_ADD = "calibration/SUCCESS_SUCESSAO_ADD",
  FAILURE_SUCESSAO_ADD = "calibration/FAILURE_SUCESSAO_ADD",
  // REQUEST DEL SUCESSAO
  REQUEST_SUCESSAO_DEL = "calibration/REQUEST_SUCESSAO_DEL",
  SUCCESS_SUCESSAO_DEL = "calibration/SUCCESS_SUCESSAO_DEL",
  FAILURE_SUCESSAO_DEL = "calibration/FAILURE_SUCESSAO_DEL",
  // REQUEST PESQUISAFUNC REUNIAO
  REQUEST_CALIBRATION_PESQUISAFUNC = "calibration/REQUEST_CALIBRATION_PESQUISAFUNC",
  SUCCESS_CALIBRATION_PESQUISAFUNC = "calibration/SUCCESS_CALIBRATION_PESQUISAFUNC",
  FAILURE_CALIBRATION_PESQUISAFUNC = "calibration/FAILURE_CALIBRATION_PESQUISAFUNC",
  // CLEAR PESQUISAFUNC
  CLEAR_CALIBRATION_PESQUISAFUNC = "calibration/CLEAR_CALIBRATION_PESQUISAFUNC",
  // RELOAD SUCESSAO
  REQUEST_SUCESSAO_LOAD = "calibration/REQUEST_SUCESSAO_LOAD",
  FAILURE_SUCESSAO_LOAD = "calibration/FAILURE_SUCESSAO_LOAD",
  SUCCESS_SUCESSAO_LOAD = "calibration/SUCCESS_SUCESSAO_LOAD",
  // FORÇAR CALIBRAÇÃO
  REQUEST_SUCESSAO_FORCE_CALIBRATION = "calibration/REQUEST_SUCESSAO_FORCE_CALIBRATION",
  FAILURE_SUCESSAO_FORCE_CALIBRATION = "calibration/FAILURE_SUCESSAO_FORCE_CALIBRATION",
  SUCCESS_SUCESSAO_FORCE_CALIBRATION = "calibration/SUCCESS_SUCESSAO_FORCE_CALIBRATION",

  //TOOGLE VISIBILITY FUNCIONARIO
  TOGGLE_VISIBILITY_FUNC = "calibration/TOGGLE_VISIBILITY_FUNC",

  CLEAR_SUCESSAO = "calibration/CLEAR_SUCESSAO",
}

export type AvaliadoType = {
  idCicloReuniaoAvaliado: number;
  idCicloReuniao: number;
  idFuncionario: number;
  nivel: number;
  idTabPerformance: number;
  potencial: string;
  sucessao: string;
  comentarios: string;
  carreira: string;
  proximoCiclo: string;
  funcionario: {
    nomeCompleto: string;
    tempo: number;
    idade: number;
  };
  performance: {
    descNivel: string;
    cor: string;
    nivel: number;
  };
  avaliacaoGestor: {
    idTabPerformanceGestor: string;
    potencial: string | null;
    sucessao: string | null;
    aspiracoesCarreira: string;
    performancegestor: {
      descNivel: string;
      cor: string;
      nivel: number;
    };
    ciclo: {
      ano: number;
    };
  };
};

export interface AvaliadoInitType extends AvaliadoType {
  info: {
    idFuncionario: number;
    idArea: number;
    idCargo: number;
    titulo: string;
    idVaga: number;
    dataHist: Date;
    nomeLocal: string;
    idCC: number;
    nomeCC: string;
    idUnidade: number;
    nomeUnidade: string;
  };
}

export type AvaliadoCalibType = {
  avaliado: AvaliadoInitType;
  tempoAcumulado?: number;
  dataHoraAberta?: Date;
  dataHoraAtual?: Date;
  dataHoraInicial?: Date;
  dataHoraFinal?: Date;
};

export type PerformanceCurvaAvaliado = {
  deslocamento: number;
  potencial: string;
  sucessao: string;
  comentarios: string;
  performance: {
    idTabPerformance: number;
    performance: {
      nivel: number;
    };
  };
  avaliacao: {
    idCicloReuniaoAvaliado: number;
    idCicloReuniao: number;
    idTabPerformance: number;
    potencial: string;
    sucessao: string;
    performance: {
      nivel: number;
    };
  };
};

export type ReuniaoType = {
  idCicloReuniao: number;
  idCiclo: number;
  dataHoraInicio: string;
  idFuncionarioLider: number;
  idFuncionarioRH: number;
  idFuncionarioHRBP: number;
  status: string;
  lider: {
    nomeCompleto: string;
  };
  hrbp: {
    nomeCompleto: string;
  };
  rh: {
    nomeCompleto: string;
  };
  ciclo: {
    ano: number;
  };
  grupo: {
    nomeGrupo: string;
    minutos: number | null;
  };
  flagHabilitaFinalizar: boolean;
};

export type NivelType = {
  nivel: number;
};

type AvaliadoresType = {
  idCicloReuniaoAvaliador: number;
  idFuncionario: number;
  nivel: number;
  flagEliminado: boolean;
  funcionario: {
    nomeCompleto: string;
    imagem: string | null;
  };
};

type Avaliacao = {
  idTabPerformance: number;
  potencial: string;
  sucessao: string;
  ciclo: {
    ano: number;
  };
  performance: {
    descNivel: string;
    cor: string;
  };
};

type AvaliacaoGestor = {
  idCicloAvaliacao: number;
  idTabPerformanceGestor: number;
  potencial: string;
  sucessao: string;
  ciclo: {
    ano: number;
  };
  performancegestor: {
    descNivel: string;
    cor: string;
    nivel: number;
  };
};

export type AvaliadosType = {
  idCicloReuniaoAvaliado: number;
  idFuncionario: number;
  nivel: number;
  idTabPerformance: number | null;
  potencial: string | null;
  sucessao: string | null;
  possuiComentario: boolean;
  funcionario: {
    isVisible: boolean;
    status: string;
    nomeCompleto: string;
    dataNascimento: string;
    dataAdmissao: string;
    idade: number;
    tempo: number;
    imagem: string | null;
    flagPermiteCalibracao: boolean;
    tempoUnidade: string;
  };
  performance: {
    descNivel: string;
    cor: string;
  } | null;
  gestor: {
    idFuncionario: number;
    nomeCompleto: string;
  };
  avaliacaoGestor: AvaliacaoGestor | null;
  areaAtual: {
    idArea: number | null;
  };
  avaliacaoAnterior: Avaliacao | null;
  cargo: {
    idCargo: number;
    codCargo: number;
    titulo: string;
    dataHist: Date;
    tempo: number;
  };
};

export type AvaliadosCurvaType = {
  deslocamento?: number;
  funcionario: {
    nomeCompleto: string;
    imagem: string | null;
  };
  idCicloReuniaoAvaliado: number;
  idFuncionario: number;
  idTabPerformance: number | null;
  nivel: number;
  potencial?: string;
  cargo?: {
    idCargo: number;
    codCargo: string;
    titulo: string;
  };
};

export type PerformanceAvaliado = {
  idTabPerformance: number | null;
  potencial: string;
  sucessao: string | null;
  carreira: string;
  proximoCiclo: string;
  performance?: {
    descNivel: string;
    cor: string;
    nivel: number | null;
  };
};

export type CurvaType = {
  idTabPerformance: number;
  nivel: number;
  descNivel: string;
  descIngles: string;
  cor: string;
  percentual: number;
  qtdeTarget: number;
  curvaInicial: AvaliadosCurvaType[];
  curvaAjustada: AvaliadosCurvaType[];
  percentInicial?: number;
};

export type SuccessionNivelType = {
  idCicloReuniaoSucessaoGrupoAvaliado: number;
  idCicloReuniaoSucessaoGrupo: number;
  idCicloReuniaoAvaliado: number;
  sucessao: string | null;
  flagInicial: true;
  avaliado: {
    idFuncionario: number | null;
    funcionario: {
      nomeCompleto: string;
    };
  };
};

export type SuccesstionType = {
  idCicloReuniaoSucessaoGrupo: number;
  nomeGrupo: string;
  flagOutros: boolean | null;
  chefe: {
    nomeCompleto: string;
  } | null;
  nivel0: SuccessionNivelType[];
  nivel1: SuccessionNivelType[];
  nivel2: SuccessionNivelType[];
  nivel3: SuccessionNivelType[];
  nivel4: SuccessionNivelType[];
};

export type CalibrationMeetingType = {
  reuniao: ReuniaoType;
  niveis: NivelType[];
  performances: TabPerfomanceType[];
  avaliadores?: AvaliadoresType[];
  avaliados?: AvaliadosType[];
  curva: CurvaType[] | null;
  sucessao: SuccesstionType[];
};

export interface IAvaliadoCalibType {
  avaliado: AvaliadoType | null;
  comentarios: AvaliadoCommentType[];
}

export interface AvaliadoState {
  dataInit: AvaliadoCalibType | null;
  avaliadoCalib: IAvaliadoCalibType | null;
  loading: boolean;
  loadingAction: boolean;
  success: boolean;
  successLoading: boolean;
  successLoadingTimer: boolean;
  error: boolean;
  errorLoading: boolean;
}

export type AvaliadoCommentsType = {
  anos: CommentYearType[];
  comentarios: AvaliadoCommentType[];
};

export type AvaliadoCommentType = {
  idCicloReuniaoAnotacao: number;
  dataHoraAnotacao: Date;
  comentarios: string;
};

export type CommentYearType = {
  idCicloReuniaoAvaliado: number;
  ano: number;
};

export type CalibrationPesquisaFuncState = {
  avaliados: CalibrationPesquisaFuncType[];
  success: boolean;
  error: boolean;
  loading: boolean;
};

export type CalibrationPesquisaFuncType = {
  idCicloReuniaoAvaliado: number;
  idFuncionario: number;
  nomeCompleto: string;
  idArea: number;
  nomeLocal: string;
  flagLideranca: boolean;
  tipoLocal: string;
  idWorklevel: number;
  titulo: string;
  idFuncionarioChefe: number;
  nomeChefe: string;
};

interface ICalibrationState {
  readonly calibration: CalibrationMeetingType | null;
  readonly avaliadoSelected: AvaliadoState;
  readonly comentarios: AvaliadoCommentsType | null;
  readonly pesquisaFunc: CalibrationPesquisaFuncState;
  readonly loadingData: boolean;
  readonly loadingNivel: boolean;
  readonly loadingSuccession: boolean;
  readonly loadingAction: boolean;
  readonly loadingAnotations: boolean;
  readonly loadingChanginNivel: boolean;
  readonly error: boolean;
  readonly success: boolean;
  readonly successLoading: boolean;
  readonly successLoadingNivel: boolean;
  readonly successForcarCalibracao: boolean;
  readonly successSucessao: boolean;
  readonly successAnotations: boolean;
  readonly successChanginNivel: boolean;
  readonly errorAnotations: boolean;
  readonly changeVisibility: boolean;
}

export type DataTypes = ICalibrationState;

//GET BY ID
export interface IRequestCalibrationLoadByIdAction {
  type: typeof Types.REQUEST_CALIBRATION_LOAD_BY_ID;
  payload: {
    idCicloReuniao: number;
  };
}

export interface ISuccessCalibrationLoadByIdAction {
  type: typeof Types.SUCCESS_CALIBRATION_LOAD_BY_ID;
  payload: CalibrationMeetingType;
}

interface IFailureCalibrationLoadByIdAction {
  type: typeof Types.FAILURE_CALIBRATION_LOAD_BY_ID;
  message: string;
}

type CalibrationLoadByIdAction =
  | IRequestCalibrationLoadByIdAction
  | ISuccessCalibrationLoadByIdAction
  | IFailureCalibrationLoadByIdAction;

//GET BY ID
export interface IRequestCalibrationLoadByIdNivelAction {
  type: typeof Types.REQUEST_CALIBRATION_LOAD_BY_ID_NIVEL;
  payload: {
    idCicloReuniao: number;
    nivel: string;
  };
}

export interface ISuccessCalibrationLoadByIdNivelAction {
  type: typeof Types.SUCCESS_CALIBRATION_LOAD_BY_ID_NIVEL;
  payload: {
    avaliadores: AvaliadoresType[];
    avaliados: AvaliadosType[];
  };
}

interface IFailureCalibrationLoadByIdNivelAction {
  type: typeof Types.FAILURE_CALIBRATION_LOAD_BY_ID_NIVEL;
  message: string;
}

type CalibrationLoadByIdNivelAction =
  | IRequestCalibrationLoadByIdNivelAction
  | ISuccessCalibrationLoadByIdNivelAction
  | IFailureCalibrationLoadByIdNivelAction;

//ATIVA AVALIADOR
export interface IRequestCalibrationAtivaAvaliadorAction {
  type: typeof Types.REQUEST_CALIBRATION_ATIVA_AVALIADOR;
  payload: {
    idCicloReuniao: number;
    nivel: string;
    idCicloReuniaoAvaliador: number;
  };
}

export interface ISuccessCalibrationAtivaAvaliadorAction {
  type: typeof Types.SUCCESS_CALIBRATION_ATIVA_AVALIADOR;
  payload: {
    idCicloReuniaoAvaliador: number;
  };
}

interface IFailureCalibrationAtivaAvaliadorAction {
  type: typeof Types.FAILURE_CALIBRATION_ATIVA_AVALIADOR;
  message: string;
}

type CalibrationAtivaAvaliadorAction =
  | IRequestCalibrationAtivaAvaliadorAction
  | ISuccessCalibrationAtivaAvaliadorAction
  | IFailureCalibrationAtivaAvaliadorAction;

//DESATIVA AVALIADOR
export interface IRequestCalibrationDelAvaliadorAction {
  type: typeof Types.REQUEST_CALIBRATION_DESATIVA_AVALIADOR;
  payload: {
    idCicloReuniao: number;
    nivel: string;
    idCicloReuniaoAvaliador: number;
  };
}

export interface ISuccessCalibrationDelAvaliadorAction {
  type: typeof Types.SUCCESS_CALIBRATION_DESATIVA_AVALIADOR;
  payload: {
    idCicloReuniaoAvaliador: number;
  };
}

interface IFailureCalibrationDelAvaliadorAction {
  type: typeof Types.FAILURE_CALIBRATION_DESATIVA_AVALIADOR;
  message: string;
}

type CalibrationDelAvaliadorAction =
  | IRequestCalibrationDelAvaliadorAction
  | ISuccessCalibrationDelAvaliadorAction
  | IFailureCalibrationDelAvaliadorAction;

//FINALIZAR
export interface IRequestCalibrationFinalizarAction {
  type: typeof Types.REQUEST_CALIBRATION_FINALIZAR;
  payload: {
    idCicloReuniao: number;
    comentariosFinais: string;
  };
}

export interface ISuccessCalibrationFinalizarAction {
  type: typeof Types.SUCCESS_CALIBRATION_FINALIZAR;
}

interface IFailureCalibrationFinalizarAction {
  type: typeof Types.FAILURE_CALIBRATION_FINALIZAR;
  message: string;
}

type CalibrationFinalizarAction =
  | IRequestCalibrationFinalizarAction
  | ISuccessCalibrationFinalizarAction
  | IFailureCalibrationFinalizarAction;

//LOAD AVALIADO
export interface IRequestCalibrationLoadAvaliadoAction {
  type: typeof Types.REQUEST_CALIBRATION_LOAD_AVALIADO;
  payload: {
    idCicloReuniao: number;
    idCicloReuniaoAvaliado: number;
  };
}

export interface ISuccessCalibrationLoadAvaliadoAction {
  type: typeof Types.SUCCESS_CALIBRATION_LOAD_AVALIADO;
  payload: {
    avaliado: AvaliadoType;
    comentarios: AvaliadoCommentType[];
  };
}

interface IFailureCalibrationLoadAvaliadoAction {
  type: typeof Types.FAILURE_CALIBRATION_LOAD_AVALIADO;
  message: string;
}

type CalibrationLoadAvaliadoAction =
  | IRequestCalibrationLoadAvaliadoAction
  | ISuccessCalibrationLoadAvaliadoAction
  | IFailureCalibrationLoadAvaliadoAction;

//SAVE AVALIADO
export interface IRequestCalibrationSaveAvaliadoAction {
  type: typeof Types.REQUEST_CALIBRATION_SAVE_AVALIADO;
  payload: {
    idCicloReuniao: number;
    idCicloReuniaoAvaliado: number;
    performance: PerformanceAvaliado;
    nivelOriginal: number | null;
    sucessaoOriginal: string;
  };
}

export interface ISuccessCalibrationSaveAvaliadoAction {
  type: typeof Types.SUCCESS_CALIBRATION_SAVE_AVALIADO;
  payload: {
    idCicloReuniaoAvaliado: number;
    performance: PerformanceAvaliado;
    nivelOriginal: number | null;
    sucessaoOriginal: string;
  };
}

export interface IFailureCalibrationSaveAvaliadoAction {
  type: typeof Types.FAILURE_CALIBRATION_SAVE_AVALIADO;
  message: string;
}

type CalibrationSaveAvaliadoAction =
  | IRequestCalibrationSaveAvaliadoAction
  | ISuccessCalibrationSaveAvaliadoAction
  | IFailureCalibrationSaveAvaliadoAction;

//CALIBRATION INIT
export interface IRequestCalibrationInitAction {
  type: typeof Types.REQUEST_CALIBRATION_INIT;
  payload: {
    idCicloReuniao: number;
    idCicloReuniaoAvaliado: number;
    isRunning: boolean;
  };
}

export interface ISuccessCalibrationInitAction {
  type: typeof Types.SUCCESS_CALIBRATION_INIT;
  payload: {
    data: AvaliadoCalibType;
    isRunning: boolean;
  };
}

export interface IFailureCalibrationInitAction {
  type: typeof Types.FAILURE_CALIBRATION_INIT;
  message: string;
}

type CalibrationCalibrationInitAction =
  | IRequestCalibrationInitAction
  | ISuccessCalibrationInitAction
  | IFailureCalibrationInitAction;

//CALIBRATION STOP
export interface IRequestCalibrationStopAction {
  type: typeof Types.REQUEST_CALIBRATION_STOP;
  payload: {
    idCicloReuniao: number;
    idCicloReuniaoAvaliado: number;
  };
}

export interface ISuccessCalibrationStopAction {
  type: typeof Types.SUCCESS_CALIBRATION_STOP;
  payload: {
    tempoAcumulado: number;
    dataHoraFinal: Date;
  };
}

export interface IFailureCalibrationStopAction {
  type: typeof Types.FAILURE_CALIBRATION_STOP;
  message: string;
}

type CalibrationCalibrationStopAction =
  | IRequestCalibrationStopAction
  | ISuccessCalibrationStopAction
  | IFailureCalibrationStopAction;

//AVALIADO UP
export interface IRequestCalibrationAvaliadoUpAction {
  type: typeof Types.REQUEST_CALIBRATION_AVALIADO_UP;
  payload: {
    idCicloReuniao: number;
    idCicloReuniaoAvaliado: number;
  };
}

export interface ISuccessCalibrationAvaliadoUpAction {
  type: typeof Types.SUCCESS_CALIBRATION_AVALIADO_UP;
  idCicloReuniaoAvaliado: number;
}

export interface IFailureCalibrationAvaliadoUpAction {
  type: typeof Types.FAILURE_CALIBRATION_AVALIADO_UP;
  message: string;
}

type CalibrationAvaliadoUpAction =
  | IRequestCalibrationAvaliadoUpAction
  | ISuccessCalibrationAvaliadoUpAction
  | IFailureCalibrationAvaliadoUpAction;

//AVALIADO DONW
export interface IRequestCalibrationAvaliadoDownAction {
  type: typeof Types.REQUEST_CALIBRATION_AVALIADO_DOWN;
  payload: {
    idCicloReuniao: number;
    idCicloReuniaoAvaliado: number;
  };
}

export interface ISuccessCalibrationAvaliadoDownAction {
  type: typeof Types.SUCCESS_CALIBRATION_AVALIADO_DOWN;
  idCicloReuniaoAvaliado: number;
}

export interface IFailureCalibrationAvaliadoDownAction {
  type: typeof Types.FAILURE_CALIBRATION_AVALIADO_DOWN;
  message: string;
}

type CalibrationAvaliadoDownAction =
  | IRequestCalibrationAvaliadoDownAction
  | ISuccessCalibrationAvaliadoDownAction
  | IFailureCalibrationAvaliadoDownAction;

// REQUEST COMMENT LOAD
export interface IRequestCommentAvaliadoAction {
  type: typeof Types.REQUEST_COMMENT_AVALIADO_LOAD;
  payload: {
    idCicloReuniao: number;
    idCicloReuniaoAvaliado: number;
    exibir?: number;
  };
}

export interface ISuccessCommentAvaliadoAction {
  type: typeof Types.SUCCESS_COMMENT_AVALIADO_LOAD;
  payload: AvaliadoCommentsType;
}

export interface IFailureCommentAvaliadoAction {
  type: typeof Types.FAILURE_COMMENT_AVALIADO_LOAD;
  message: string;
}

type CommentAvaliadoAction =
  | IRequestCommentAvaliadoAction
  | ISuccessCommentAvaliadoAction
  | IFailureCommentAvaliadoAction;

// REQUEST COMMENT LOAD
export interface IRequestCommentAvaliadoAddAction {
  type: typeof Types.REQUEST_COMMENT_AVALIADO_ADD;
  payload: {
    idCicloReuniao: number;
    idCicloReuniaoAvaliado: number;
    comentarios: string;
  };
}

export interface ISuccessCommentAvaliadoAddAction {
  type: typeof Types.SUCCESS_COMMENT_AVALIADO_ADD;
  payload: {
    idCicloReuniaoAvaliado: number;
    comentarios: AvaliadoCommentType;
  };
}

export interface IFailureCommentAvaliadoAddAction {
  type: typeof Types.FAILURE_COMMENT_AVALIADO_ADD;
  message: string;
}

type CommentAvaliadoAddAction =
  | IRequestCommentAvaliadoAddAction
  | ISuccessCommentAvaliadoAddAction
  | IFailureCommentAvaliadoAddAction;

// REQUEST SUCESSÃO ADD
export interface IRequestCalibrationSucessaoAddAction {
  type: typeof Types.REQUEST_SUCESSAO_ADD;
  payload: {
    idCicloReuniao: number;
    idCicloReuniaoAvaliado?: number;
    idFuncionario?: number;
    idCicloReuniaoSucessaoGrupo: number;
    nomeCompleto: string;
    sucessao: string;
  };
}

export interface ISuccessCalibrationSucessaoAddAction {
  type: typeof Types.SUCCESS_SUCESSAO_ADD;
  payload: {
    idCicloReuniaoAvaliado: number;
    idCicloReuniaoSucessaoGrupo: number;
    idFuncionario?: number;
    nomeCompleto: string;
    data: SuccessionNivelType;
    sucessao: string;
  };
}

export interface IFailureCalibrationSucessaoAddAction {
  type: typeof Types.FAILURE_SUCESSAO_ADD;
  message: string;
}

type CalibrationSucessaoAddAction =
  | IRequestCalibrationSucessaoAddAction
  | ISuccessCalibrationSucessaoAddAction
  | IFailureCalibrationSucessaoAddAction;

// REQUEST SUCESSÃO DEL
export interface IRequestCalibrationSucessaoDelAction {
  type: typeof Types.REQUEST_SUCESSAO_DEL;
  payload: {
    idCicloReuniao: number;
    idCicloReuniaoSucessaoGrupo: number;
    idCicloReuniaoSucessaoGrupoAvaliado: number;
    sucessao: string;
  };
}

export interface ISuccessCalibrationSucessaoDelAction {
  type: typeof Types.SUCCESS_SUCESSAO_DEL;
  payload: {
    idCicloReuniaoSucessaoGrupo: number;
    idCicloReuniaoSucessaoGrupoAvaliado: number;
    sucessao: string;
  };
}

export interface IFailureCalibrationSucessaoDelAction {
  type: typeof Types.FAILURE_SUCESSAO_DEL;
  message: string;
}

type CalibrationSucessaoDelAction =
  | IRequestCalibrationSucessaoDelAction
  | ISuccessCalibrationSucessaoDelAction
  | IFailureCalibrationSucessaoDelAction;

// REQUEST CALIBRATION PESQUISAFUNC
export interface IRequestCalibrationPesquisaFuncAction {
  type: typeof Types.REQUEST_CALIBRATION_PESQUISAFUNC;
  payload: {
    idCicloReuniao: number;
    nomePesq?: string;
    idArea?: number | null;
    idChefe?: number | null;
    worklevel?: number | null;
    flagReuniao?: boolean;
  };
}

export interface ISuccessCalibrationPesquisaFuncAction {
  type: typeof Types.SUCCESS_CALIBRATION_PESQUISAFUNC;
  payload: CalibrationPesquisaFuncType[];
}

export interface IFailureCalibrationPesquisaFuncAction {
  type: typeof Types.FAILURE_CALIBRATION_PESQUISAFUNC;
  message: string;
}

interface IClearCalibrationPesquisaFuncAction {
  type: typeof Types.CLEAR_CALIBRATION_PESQUISAFUNC;
}

type CalibrationPesquisaFuncAction =
  | IRequestCalibrationPesquisaFuncAction
  | ISuccessCalibrationPesquisaFuncAction
  | IFailureCalibrationPesquisaFuncAction
  | IClearCalibrationPesquisaFuncAction;

// REQUEST RELOAD SUCESSAO
export interface IRequestLoadSucessaoAction {
  type: typeof Types.REQUEST_SUCESSAO_LOAD;
  payload: {
    idCicloReuniao: number;
    nivel: string;
  };
}

export interface ISuccessLoadSucessaoAction {
  type: typeof Types.SUCCESS_SUCESSAO_LOAD;
  payload: SuccesstionType[];
}

export interface IFailureLoadSucessaoAction {
  type: typeof Types.FAILURE_SUCESSAO_LOAD;
  message: string;
}

interface IClearSucessaoAction {
  type: typeof Types.CLEAR_SUCESSAO;
}

type LoadSucessaoAction =
  | IRequestLoadSucessaoAction
  | ISuccessLoadSucessaoAction
  | IFailureLoadSucessaoAction
  | IClearSucessaoAction;

// REQUEST RELOAD SUCESSAO
export interface IRequestCalibrationForceAction {
  type: typeof Types.REQUEST_SUCESSAO_FORCE_CALIBRATION;
  payload: {
    idCicloReuniao: number;
    nivel: string;
  };
}

export interface ISuccessCalibrationForceAction {
  type: typeof Types.SUCCESS_SUCESSAO_FORCE_CALIBRATION;
  payload: {
    avaliadores: AvaliadoresType[];
    avaliados: AvaliadosType[];
  };
}

export interface IFailureCalibrationForceAction {
  type: typeof Types.FAILURE_SUCESSAO_FORCE_CALIBRATION;
  message: string;
}

type CalibrationForceAction =
  | IRequestCalibrationForceAction
  | ISuccessCalibrationForceAction
  | IFailureCalibrationForceAction;

interface IToggleVisibilityFunc {
  type: typeof Types.TOGGLE_VISIBILITY_FUNC;
  idCicloReuniaoAvaliado: number;
}

export type CalibrationActionTypes =
  | CalibrationLoadByIdAction
  | CalibrationLoadByIdNivelAction
  | CalibrationDelAvaliadorAction
  | CalibrationAtivaAvaliadorAction
  | CalibrationFinalizarAction
  | CalibrationLoadAvaliadoAction
  | CalibrationSaveAvaliadoAction
  | CalibrationCalibrationInitAction
  | CalibrationAvaliadoUpAction
  | CalibrationAvaliadoDownAction
  | CommentAvaliadoAction
  | CommentAvaliadoAddAction
  | CalibrationCalibrationStopAction
  | CalibrationSucessaoAddAction
  | CalibrationSucessaoDelAction
  | CalibrationPesquisaFuncAction
  | LoadSucessaoAction
  | IToggleVisibilityFunc
  | CalibrationForceAction;
