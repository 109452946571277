import { ParametroType, MeritPerformanceType } from "../admin/merit/types";
import { PositionTypes } from "../admin/positions/types";
import { GS, SalaryStructureType } from "../admin/salaryStructure/types";
import { StepType } from "../admin/steps/types";

/**
 * Types
 */
export enum Types {
  //GET BY ID
  REQUEST_MERIT_LOAD_BY_ID = "merit/REQUEST_MERIT_LOAD_BY_ID",
  SUCCESS_MERIT_LOAD_BY_ID = "merit/SUCCESS_MERIT_LOAD_BY_ID",
  FAILURE_MERIT_LOAD_BY_ID = "merit/FAILURE_MERIT_LOAD_BY_ID",

  //EDIT COMENTARIO
  REQUEST_MERIT_PERCMERITO_EDIT = "merit/REQUEST_MERIT_PERCMERITO_EDIT",
  SUCCESS_MERIT_PERCMERITO_EDIT = "merit/SUCCESS_MERIT_PERCMERITO_EDIT",
  FAILURE_MERIT_PERCMERITO_EDIT = "merit/FAILURE_MERIT_PERCMERITO_EDIT",

  // EDIT MERIT
  REQUEST_MERIT_EDIT = "merit/REQUEST_MERIT_EDIT",
  SUCCESS_MERIT_EDIT = "merit/SUCCESS_MERIT_EDIT",
  FAILURE_MERIT_EDIT = "merit/FAILURE_MERIT_EDIT",

  // ATUALIZA PERCENTUAL
  REQUEST_MERIT_ATUALIZA_PERCENTUAL = "merit/REQUEST_MERIT_ATUALIZA_PERCENTUAL",
  SUCCESS_MERIT_ATUALIZA_PERCENTUAL = "merit/SUCCESS_MERIT_ATUALIZA_PERCENTUAL",
  FAILURE_MERIT_ATUALIZA_PERCENTUAL = "merit/FAILURE_MERIT_ATUALIZA_PERCENTUAL",

  // ATUALIZA PERCENTUAL
  REQUEST_MERIT_ATUALIZA_PROMOCAO = "merit/REQUEST_MERIT_ATUALIZA_PROMOCAO",
  SUCCESS_MERIT_ATUALIZA_PROMOCAO = "merit/SUCCESS_MERIT_ATUALIZA_PROMOCAO",
  FAILURE_MERIT_ATUALIZA_PROMOCAO = "merit/FAILURE_MERIT_ATUALIZA_PROMOCAO",

  // ATUALIZA PERCENTUAL
  REQUEST_MERIT_ATUALIZA_SALARIO = "merit/REQUEST_MERIT_ATUALIZA_SALARIO",
  SUCCESS_MERIT_ATUALIZA_SALARIO = "merit/SUCCESS_MERIT_ATUALIZA_SALARIO",
  FAILURE_MERIT_ATUALIZA_SALARIO = "merit/FAILURE_MERIT_ATUALIZA_SALARIO",

  // ATUALIZA COMENTARIO
  REQUEST_MERIT_ATUALIZA_COMENTARIO = "merit/REQUEST_MERIT_ATUALIZA_COMENTARIO",
  SUCCESS_MERIT_ATUALIZA_COMENTARIO = "merit/SUCCESS_MERIT_ATUALIZA_COMENTARIO",
  FAILURE_MERIT_ATUALIZA_COMENTARIO = "merit/FAILURE_MERIT_ATUALIZA_COMENTARIO",

  // ATUALIZA FINALIZAR
  REQUEST_MERIT_FINALIZAR = "merit/REQUEST_MERIT_FINALIZAR",
  SUCCESS_MERIT_FINALIZAR = "merit/SUCCESS_MERIT_FINALIZAR",
  FAILURE_MERIT_FINALIZAR = "merit/FAILURE_MERIT_FINALIZAR",

  // ATUALIZA FINALIZAR
  REQUEST_MERIT_REATIVAR = "merit/REQUEST_MERIT_REATIVAR",
  SUCCESS_MERIT_REATIVAR = "merit/SUCCESS_MERIT_REATIVAR",
  FAILURE_MERIT_REATIVAR = "merit/FAILURE_MERIT_REATIVAR",

  // APLICAR MERITO
  REQUEST_MERIT_APLICAR = "merit/REQUEST_MERIT_APLICAR",
  SUCCESS_MERIT_APLICAR = "merit/SUCCESS_MERIT_APLICAR",
  FAILURE_MERIT_APLICAR = "merit/FAILURE_MERIT_APLICAR",

  // IGNORAR MERITO
  REQUEST_MERIT_IGNORAR = "merit/REQUEST_MERIT_IGNORAR",
  SUCCESS_MERIT_IGNORAR = "merit/SUCCESS_MERIT_IGNORAR",
  FAILURE_MERIT_IGNORAR = "merit/FAILURE_MERIT_IGNORAR",

  // GET RESUMO
  REQUEST_MERIT_RESUMO_LOAD = "merit/REQUEST_MERIT_RESUMO_LOAD",
  SUCCESS_MERIT_RESUMO_LOAD = "merit/SUCCESS_MERIT_RESUMO_LOAD",
  FAILURE_MERIT_RESUMO_LOAD = "merit/FAILURE_MERIT_RESUMO_LOAD",

  // FINALIZAR MERITO
  REQUEST_MERIT_SOLICITAR_APROVACAO = "merit/REQUEST_MERIT_SOLICITAR_APROVACAO",
  SUCCESS_MERIT_SOLICITAR_APROVACAO = "merit/SUCCESS_MERIT_SOLICITAR_APROVACAO",
  FAILURE_MERIT_SOLICITAR_APROVACAO = "merit/FAILURE_MERIT_SOLICITAR_APROVACAO",

  // GET BUDGETS
  REQUEST_MERIT_GET_BUDGETS = "merit/REQUEST_MERIT_GET_BUDGETS",
  SUCCESS_MERIT_GET_BUDGETS = "merit/SUCCESS_MERIT_GET_BUDGETS",
  FAILURE_MERIT_GET_BUDGETS = "merit/FAILURE_MERIT_GET_BUDGETS",

  // EDIT BUDGET
  REQUEST_MERIT_EDIT_BUDGET = "merit/REQUEST_MERIT_EDIT_BUDGET",
  SUCCESS_MERIT_EDIT_BUDGET = "merit/SUCCESS_MERIT_EDIT_BUDGET",
  FAILURE_MERIT_EDIT_BUDGET = "merit/FAILURE_MERIT_EDIT_BUDGET",

  // LOAD SALDO
  REQUEST_MERIT_LOAD_SALDO = "merit/REQUEST_MERIT_LOAD_SALDO",
  SUCCESS_MERIT_LOAD_SALDO = "merit/SUCCESS_MERIT_LOAD_SALDO",
  FAILURE_MERIT_LOAD_SALDO = "merit/FAILURE_MERIT_LOAD_SALDO",

  // REPROVAR
  REQUEST_MERIT_REPROVAR = "merit/REQUEST_MERIT_REPROVAR",
  SUCCESS_MERIT_REPROVAR = "merit/SUCCESS_MERIT_REPROVAR",
  FAILURE_MERIT_REPROVAR = "merit/FAILURE_MERIT_REPROVAR",

  // APROVAR
  REQUEST_MERIT_APROVAR = "merit/REQUEST_MERIT_APROVAR",
  SUCCESS_MERIT_APROVAR = "merit/SUCCESS_MERIT_APROVAR",
  FAILURE_MERIT_APROVAR = "merit/FAILURE_MERIT_APROVAR",

  // REABRIR
  REQUEST_MERIT_FINALIZAR_REUNIAO = "merit/REQUEST_MERIT_FINALIZAR_REUNIAO",
  SUCCESS_MERIT_FINALIZAR_REUNIAO = "merit/SUCCESS_MERIT_FINALIZAR_REUNIAO",
  FAILURE_MERIT_FINALIZAR_REUNIAO = "merit/FAILURE_MERIT_FINALIZAR_REUNIAO",

  // REABRIR
  REQUEST_MERIT_REABRIR = "merit/REQUEST_MERIT_REABRIR",
  SUCCESS_MERIT_REABRIR = "merit/SUCCESS_MERIT_REABRIR",
  FAILURE_MERIT_REABRIR = "merit/FAILURE_MERIT_REABRIR",

  // LISTAR CARGOS
  REQUEST_MERIT_LISTAR_CARGOS = "merit/REQUEST_MERIT_LISTAR_CARGOS",
  SUCCESS_MERIT_LISTAR_CARGOS = "merit/SUCCESS_MERIT_LISTAR_CARGOS",
  FAILURE_MERIT_LISTAR_CARGOS = "merit/FAILURE_MERIT_LISTAR_CARGOS",
  CLEAR_MERIT_LISTAR_CARGOS = "merit/CLEAR_MERIT_LISTAR_CARGOS",

  // LISTAR SALARIOS
  REQUEST_MERIT_LISTAR_SALARIOS = "merit/REQUEST_MERIT_LISTAR_SALARIOS",
  SUCCESS_MERIT_LISTAR_SALARIOS = "merit/SUCCESS_MERIT_LISTAR_SALARIOS",
  FAILURE_MERIT_LISTAR_SALARIOS = "merit/FAILURE_MERIT_LISTAR_SALARIOS",
  CLEAR_MERIT_LISTAR_SALARIOS = "merit/CLEAR_MERIT_LISTAR_SALARIOS",

  // LISTAR AVALIACOES
  REQUEST_MERIT_LISTAR_AVALIACOES = "merit/REQUEST_MERIT_LISTAR_AVALIACOES",
  SUCCESS_MERIT_LISTAR_AVALIACOES = "merit/SUCCESS_MERIT_LISTAR_AVALIACOES",
  FAILURE_MERIT_LISTAR_AVALIACOES = "merit/FAILURE_MERIT_LISTAR_AVALIACOES",
  CLEAR_MERIT_LISTAR_AVALIACOES = "merit/CLEAR_MERIT_LISTAR_AVALIACOES",

  // LISTAR OCORRENCIAS
  REQUEST_MERIT_LISTAR_OCORRENCIAS = "merit/REQUEST_MERIT_LISTAR_OCORRENCIAS",
  SUCCESS_MERIT_LISTAR_OCORRENCIAS = "merit/SUCCESS_MERIT_LISTAR_OCORRENCIAS",
  FAILURE_MERIT_LISTAR_OCORRENCIAS = "merit/FAILURE_MERIT_LISTAR_OCORRENCIAS",
  CLEAR_MERIT_LISTAR_OCORRENCIAS = "merit/CLEAR_MERIT_LISTAR_OCORRENCIAS",

  // DOWNLOAD CARTA
  REQUEST_MERIT_DOWNLOAD_CARTA = "merit/REQUEST_MERIT_DOWNLOAD_CARTA",
  SUCCESS_MERIT_DOWNLOAD_CARTA = "merit/SUCCESS_MERIT_DOWNLOAD_CARTA",
  FAILURE_MERIT_DOWNLOAD_CARTA = "merit/FAILURE_MERIT_DOWNLOAD_CARTA",

}

export type MeritType = {
  merito: {
    idMerito: number;
    ano: number;
    status: string;
    dataReferencia: Date;
    idFuncionarioResp: number;
    idFuncionarioAprovador: number;
    requerAprovacao: number;
    dataReuniao: Date;
    area: {
      nomeLocal: string;
    };
  };
  config: {
    Meses: number;
    percMerito: number;
    percInflacao: number;
    percEncargos: number;
    mesesEmpresa: number;
    tempoCargo: number;
    faixaEnquadramento: number;
    percMaxEnquadramento: number;
    percMinromocao: number;
    percMaxPromocao: number;
  };
  controls: MeritControls;
  funcionarios: MeritEmployee[];
  saldo: MeritSaldoType;
  steps: Partial<StepType>[];
  performances: {
    idTabPerformance: number;
    nivel: number;
    descNivel: string;
    color: string;
  }[];
  segurancaMerito: {
    permiteVerOrcamento: boolean;
    permiteTodasAreas: boolean;
  };
};

export type MeritControls = {
  habilitaAprovarReprovar: boolean;
  habilitaBaixarCarta: boolean;
  habilitaFinalizarMerito: boolean;
  habilitaSolicitacarAprovacao: boolean;
  permiteEditar: boolean;
  permiteEditarSaldo: boolean;
}

export type MeritSaldoType = {
  budgetAprovado: number;
  valorUsado: number;
  percent: number;
  saldo: number;
};

export type MeritEmployee = {
  idMeritoFuncionario: number;
  idArea: number;
  idFuncionario: number;
  tempoEmpresa: number;
  idade: number;
  idCargoOrigem: number;
  tempoCargo: number;
  gsOrigem: number;
  idESOrigem: number;
  salarioOrigem: number;
  faixaOrigem: number;
  ultimaAlteracao: Date;
  percReajuste: number;
  tempoUltimaAlteracao: number;
  idTipoMov: number;
  idTabPerformance: number;
  potencial: string;
  flagElegivel: boolean;
  min: number;
  med: number;
  max: number;
  percEnquadramentoAprovado: number;
  percMeritoAprovado: number;
  percTotalAprovado: number;
  salarioAprovado: number;
  faixaAprovada: number;
  percEnquadramentoSugerido: number;
  percMeritoSugerido: number;
  percTotalSugerido: number;
  salarioSugerido: number;
  faixaSugerida: number;
  percEnquadramento: number;
  percMerito: number;
  aplicarMerito: boolean;
  flagPromover: boolean;
  percPromocao: number | string;
  totalPercent: number;
  idCargoDestino: number | null;
  tituloCargoDestino: null | string;
  gsDestino: number | null;
  idESDestino: number | null;
  salarioRef: number | string;
  novoSalario: number | string;
  novaFaixa: number;
  comentarios: null | string;
  flagFinalizado: boolean;
  statusFuncionario: string;
  flagAfastado: boolean;
  ignorarAfastamento: boolean;
  alertarAfastamento: boolean;
  retornoBreve: boolean;
  afastouRecentemente: boolean;
  aumentoAntecipado: string | number;
  valorAumento: number | string;
  saldoOrcamento: number;
  valorUtilizadoReal: number;
  valorTotalAprovado: number;
  funcionario: {
    matricula: number;
    nomeCompleto: string;
    dataAdmissao: Date;
    dataNascimento: Date;
    imagem: null | string;
  };
  gestor: {
    nomeCompleto: string;
  };
  cargoOrigem: {
    titulo: string;
    gs?: number;
    idCategoriaCargo?: number;
    categoria?: {
      descCategoriaCargo: string;
      nivel: number;
    };
    cargosuperior: {
      idCargo: number;
      titulo: string;
      gs: number;
    } | null;
  };
  area: {
    nomeLocal: string;
  };
  cc: {
    nomeCC: string;
  } | null;
  performance: {
    descNivel: string;
    cor: string;
  };
  idTabPerformanceAnterior: number | null;
  performanceanterior: {
    descNivel: string;
    cor: string;
  } | null;
  tipomov: {
    descTipoMov: string;
    nomeAmigavel: string;
  };
  esOrigem: {
    nomeES: string;
  };
  cargoDestino: {
    idCargo: number;
    titulo: string;
    gs: number;
  };
  esDestino: {
    idES: number | null;
    nomeES: string;
  };
  infoGSDestino?: {
    valorMedio: number;
    flagPiso: boolean;
    flagTeto: boolean;
    flagDissidio: boolean;
    flagPermiteMov: boolean;
    steps: GsDestinoStep[];
  };
  // politica: {
  //   statusPolitica: boolean;
  //   validacoes: PoliticValidation[];
  // };
  statusPolitica: boolean;
  observacoesPolitica: string;
  flagPercMeritoEdit: boolean;
  flagPercEnqEdit: boolean;
  flagPercPromocaoEdit: boolean;
};

interface GsDestinoStep {
  percent: number;
  valor: number;
}

export type MeritResume = {
  total: MeritTableResume[];
  merito: MeritTableResume[];
  enquadramento: MeritTableResume[];
  promocoes: MeritTableResume[];
};

export type MeritTableResume = {
  idTabPerformance: number | null;
  nivel: {
    nivel: number;
    descNivel: string;
    cor: string;
  } | null;
  qtdeGestor: number;
  percGestor: number;
  valorGestor: number | null;
  qtdePro: number | null;
  percPro: number;
  valorPro: number | null;
  qtdeTotal: number | null;
  percTotal: number | null;
  valorTotal: number | null;
};

export type Budget = {
  valor: number | null;
  comentarios: string;
}

export type BudgetType = {
  valor: number;
  comentarios: string;
  CreatedAt: Date;
}

export type CargoHistType = {
  idHistCargo: number;
  dataHist: Date;
  idCargo: number;
  idTipoMov: number;
  cargo?: {
    idCargo: number;
    titulo: string;
  };
  tipomov?: {
    idTipoMov: string;
    descTipoMov: string;
  }
}

export type SalariosHistType = {
  idHistSalario: number;
  dataHist: Date;
  sequencia: number;
  idTipoMov: number;
  perc: number;
  salario: number;
  tipomov: {
    descTipoMov: string;
    nomeAmigavel: string;
  }
}

export type AvaliacoesHistType = {
  idCiclo: number;
  idFuncionario: number;
  idTabPerformance: number;
  potencial: string;
  sucessao: string;
  ciclo: {
     ano: number;
  },
  performance: {
      descNivel: string;
      nivel: number;
      cor: string;
  },
  cargo: {
      idCargo: number;
      codCargo: string;
      titulo: string;
      dataHist: Date;
  },
  idCicloAvaliacao: number;
}

export type OcorrenciasHistType = {
  idFuncOcorrencia: number;
  dataInicio: Date;
  dataRetorno: Date;
  cid: string;
  idMotivoAfastamento: number;
  motivoAfastamento: {
      idMotivoAfastamento: string;
      descMotivoAfastamento: string;
  }
}

interface IMeritState {
  readonly data: MeritType | null;
  readonly dataResumo: MeritResume | null;
  readonly budgetHistory: BudgetType[] | null
  readonly cargosHistory: CargoHistType[] | null
  readonly salariosHistory: SalariosHistType[] | null
  readonly avaliacoesHistory: AvaliacoesHistType[] | null
  readonly ocorrenciasHistory: OcorrenciasHistType[] | null
  readonly loadingData: boolean;
  readonly loadingAction: boolean;
  readonly loadingReprovar: boolean;
  readonly loadingBudgetHistory: boolean;
  readonly loadingGridsHistory: boolean;
  readonly error: boolean;
  readonly errorData: boolean;
  readonly success: boolean;
  readonly successFinalizar: boolean;
  readonly successBudget: boolean;
  readonly successAprovarReprovar: boolean;
  readonly successReabrir: boolean;
}

export type DataTypes = IMeritState;

//GET BY ID
export interface IRequestMeritLoadByIdAction {
  type: typeof Types.REQUEST_MERIT_LOAD_BY_ID;
  idMerito: number;
}

export interface ISuccessMeritLoadByIdAction {
  type: typeof Types.SUCCESS_MERIT_LOAD_BY_ID;
  payload: MeritType;
}

interface IFailureMeritLoadByIdAction {
  type: typeof Types.FAILURE_MERIT_LOAD_BY_ID;
  message: string;
}

type MeritLoadByIdAction =
  | IRequestMeritLoadByIdAction
  | ISuccessMeritLoadByIdAction
  | IFailureMeritLoadByIdAction;

//PERCMERITO EDIT
export interface IRequestMeritPercMeritoEditAction {
  type: typeof Types.REQUEST_MERIT_PERCMERITO_EDIT;
  payload: {
    idMerito: number;
    idMeritoFuncionario: number;
    percMerito: number;
  };
}

export interface ISuccessMeritPercMeritoEditAction {
  type: typeof Types.SUCCESS_MERIT_PERCMERITO_EDIT;
  payload: {
    idMeritoFuncionario: number;
    percMerito: number;
  };
}

interface IFailureMeritPercMeritoEditAction {
  type: typeof Types.FAILURE_MERIT_PERCMERITO_EDIT;
  message: string;
}

type MeritPercMeritoEditAction =
  | IRequestMeritPercMeritoEditAction
  | ISuccessMeritPercMeritoEditAction
  | IFailureMeritPercMeritoEditAction;

//PERCMERITO EDIT
export interface IRequestMeritEditAction {
  type: typeof Types.REQUEST_MERIT_EDIT;
  payload: {
    idMerito: number;
    merit: Partial<MeritEmployee>;
  };
}

export interface ISuccessMeritEditAction {
  type: typeof Types.SUCCESS_MERIT_EDIT;
  payload: {
    merit: Partial<MeritEmployee>;
  };
}

interface IFailureMeritEditAction {
  type: typeof Types.FAILURE_MERIT_EDIT;
  message: string;
}

type MeritEditAction =
  | IRequestMeritEditAction
  | ISuccessMeritEditAction
  | IFailureMeritEditAction;

//ATUALIZA_PERCENTUAL EDIT
export interface IRequestMeritAtualizaPercentualAction {
  type: typeof Types.REQUEST_MERIT_ATUALIZA_PERCENTUAL;
  payload: {
    idMerito: number;
    idMeritoFuncionario: number;
    percMerito: number;
    percEnquadramento: number;
    percPromocao: number;
  };
}

export interface ISuccessMeritAtualizaPercentualAction {
  type: typeof Types.SUCCESS_MERIT_ATUALIZA_PERCENTUAL;
  payload: {
    funcionario: {
      idMeritoFuncionario: number;
      percMerito: number;
      percEnquadramento: number;
      percPromocao: number;
      totalPercent: number;
      novoSalario: number;
      novaFaixa: number;
      salarioOrigem: number;
    };
    saldo: MeritSaldoType;
  };
}

interface IFailureMeritAtualizaPercentualAction {
  type: typeof Types.FAILURE_MERIT_ATUALIZA_PERCENTUAL;
  message: string;
}

type MeritAtualizaPercentualAction =
  | IRequestMeritAtualizaPercentualAction
  | ISuccessMeritAtualizaPercentualAction
  | IFailureMeritAtualizaPercentualAction;

//ATUALIZA_PROMOCAO EDIT
export interface IRequestMeritAtualizaPromocaoAction {
  type: typeof Types.REQUEST_MERIT_ATUALIZA_PROMOCAO;
  payload: {
    idMerito: number;
    merito: {
      idMeritoFuncionario: number;
      flagPromover: boolean;
      idCargoDestino: number | null;
      gsDestino: number;
      tituloCargoDestino: string | null;
      idESDestino: number;
    };
  };
}

export interface ISuccessMeritAtualizaPromocaoAction {
  type: typeof Types.SUCCESS_MERIT_ATUALIZA_PROMOCAO;
  payload: {
    funcionario: {
      idMeritoFuncionario: number;
      percEnquadramento: number;
      percMerito: number;
      flagPromover: boolean;
      gsDestino: number;
      idESDestino: number;
      percPromocao: number;
      novoSalario: number;
      totalPercent: number;
      novaFaixa: number;
      infoGSDestino: {
        valorMedio: number;
        flagPiso: boolean;
        flagTeto: boolean;
        flagDissidio: boolean;
        flagPermiteMov: boolean;
        steps: GsDestinoStep[];
      };
    };
    saldo: MeritSaldoType;
  };
}

interface IFailureMeritAtualizaPromocaoAction {
  type: typeof Types.FAILURE_MERIT_ATUALIZA_PROMOCAO;
  message: string;
}

type MeritAtualizaPromocaoAction =
  | IRequestMeritAtualizaPromocaoAction
  | ISuccessMeritAtualizaPromocaoAction
  | IFailureMeritAtualizaPromocaoAction;

//ATUALIZA_SALARIO EDIT
export interface IRequestMeritAtualizaSalarioAction {
  type: typeof Types.REQUEST_MERIT_ATUALIZA_SALARIO;
  payload: {
    idMerito: number;
    idMeritoFuncionario: number;
    novoSalario: number;
  };
}

export interface ISuccessMeritAtualizaSalarioAction {
  type: typeof Types.SUCCESS_MERIT_ATUALIZA_SALARIO;
  payload: {
    funcionario: {
      idMeritoFuncionario: number;
      novoSalario: number;
      totalPercent: number;
      novaFaixa: number;
      percEnquadramento: number;
      percMerito: number;
      percPromocao: number;
    };
    saldo: MeritSaldoType;
  };
}

interface IFailureMeritAtualizaSalarioAction {
  type: typeof Types.FAILURE_MERIT_ATUALIZA_SALARIO;
  message: string;
}

type MeritAtualizaSalarioAction =
  | IRequestMeritAtualizaSalarioAction
  | ISuccessMeritAtualizaSalarioAction
  | IFailureMeritAtualizaSalarioAction;

//ATUALIZA_COMENTARIO EDIT
export interface IRequestMeritAtualizaComentarioAction {
  type: typeof Types.REQUEST_MERIT_ATUALIZA_COMENTARIO;
  payload: {
    idMerito: number;
    idMeritoFuncionario: number;
    comentarios: string;
  };
}

export interface ISuccessMeritAtualizaComentarioAction {
  type: typeof Types.SUCCESS_MERIT_ATUALIZA_COMENTARIO;
  payload: {
    funcionario: {
      idMeritoFuncionario: number;
      comentarios: string;
    };
    saldo: MeritSaldoType;
  };
}

interface IFailureMeritAtualizaComentarioAction {
  type: typeof Types.FAILURE_MERIT_ATUALIZA_COMENTARIO;
  message: string;
}

type MeritAtualizaComentarioAction =
  | IRequestMeritAtualizaComentarioAction
  | ISuccessMeritAtualizaComentarioAction
  | IFailureMeritAtualizaComentarioAction;

//REQUEST_MERIT_FINALIZAR
export interface IRequestMeritFinalizarAction {
  type: typeof Types.REQUEST_MERIT_FINALIZAR;
  payload: {
    idMerito: number;
    idMeritoFuncionario: number;
  };

}

export interface ISuccessMeritFinalizarAction {
  type: typeof Types.SUCCESS_MERIT_FINALIZAR;
  payload: {
    funcionario: {
      idMeritoFuncionario: number;
      flagFinalizado: boolean;
    };
    controls: MeritControls;
  };
}

interface IFailureMeritFinalizarAction {
  type: typeof Types.FAILURE_MERIT_FINALIZAR;
  message: string;
}

type MeritFinalizarAction =
  | IRequestMeritFinalizarAction
  | ISuccessMeritFinalizarAction
  | IFailureMeritFinalizarAction;

//REQUEST_MERIT_REATIVAR
export interface IRequestMeritReativarAction {
  type: typeof Types.REQUEST_MERIT_REATIVAR;
  payload: {
    idMerito: number;
    idMeritoFuncionario: number;
  };
}

export interface ISuccessMeritReativarAction {
  type: typeof Types.SUCCESS_MERIT_REATIVAR;
  payload: {
    funcionario: {
      idMeritoFuncionario: number;
      flagFinalizado: boolean;
    };
    controls: MeritControls;
  };
}

interface IFailureMeritReativarAction {
  type: typeof Types.FAILURE_MERIT_REATIVAR;
  message: string;
}

type MeritReativarAction =
  | IRequestMeritReativarAction
  | ISuccessMeritReativarAction
  | IFailureMeritReativarAction;

//APLICAR MERITO
export interface IRequestMeritAplicarAction {
  type: typeof Types.REQUEST_MERIT_APLICAR;
  payload: {
    idMerito: number;
    idMeritoFuncionario: number;
  };
}

export interface ISuccessMeritAplicarAction {
  type: typeof Types.SUCCESS_MERIT_APLICAR;
  payload: { funcionario: Partial<MeritEmployee>; saldo: MeritSaldoType };
}

interface IFailureMeritAplicarAction {
  type: typeof Types.FAILURE_MERIT_APLICAR;
  message: string;
}

type MeritAplicarAction =
  | IRequestMeritAplicarAction
  | ISuccessMeritAplicarAction
  | IFailureMeritAplicarAction;

//IGNORAR MERITO
export interface IRequestMeritIgnorarAction {
  type: typeof Types.REQUEST_MERIT_IGNORAR;
  payload: {
    idMerito: number;
    idMeritoFuncionario: number;
  };
}

export interface ISuccessMeritIgnorarAction {
  type: typeof Types.SUCCESS_MERIT_IGNORAR;
  payload: { funcionario: Partial<MeritEmployee>; saldo: MeritSaldoType };
}

interface IFailureMeritIgnorarAction {
  type: typeof Types.FAILURE_MERIT_IGNORAR;
  message: string;
}

type MeritIgnorarAction =
  | IRequestMeritIgnorarAction
  | ISuccessMeritIgnorarAction
  | IFailureMeritIgnorarAction;

//RESUMO_LOAD
export interface IRequestMeritResumoLoadAction {
  type: typeof Types.REQUEST_MERIT_RESUMO_LOAD;
  payload: {
    idMerito: number;
    decisao: string;
  };
}

export interface ISuccessMeritResumoLoadAction {
  type: typeof Types.SUCCESS_MERIT_RESUMO_LOAD;
  payload: MeritResume;
}

interface IFailureMeritResumoLoadAction {
  type: typeof Types.FAILURE_MERIT_RESUMO_LOAD;
  message: string;
}

type MeritResumoLoadAction =
  | IRequestMeritResumoLoadAction
  | ISuccessMeritResumoLoadAction
  | IFailureMeritResumoLoadAction;

//REQUEST_MERIT_SOLICITAR_APROVACAO
export interface IRequestMeritSolicitarAprovacaoAction {
  type: typeof Types.REQUEST_MERIT_SOLICITAR_APROVACAO;
  payload: {
    idMerito: number;
    comentariosFinais: string;
  };
}

export interface ISuccessMeritSolicitarAprovacaoAction {
  type: typeof Types.SUCCESS_MERIT_SOLICITAR_APROVACAO;
  payload: {
    data: {
      status: string;
      dataHoraFinal: Date;
      comentariosFinais: string;
      idFuncionarioAprovador: number;
    };
    controls: MeritControls;
  };
}

interface IFailureMeritSolicitarAprovacaoAction {
  type: typeof Types.FAILURE_MERIT_SOLICITAR_APROVACAO;
  message: string;
}

type MeritSolicitarAprovacaoAction =
  | IRequestMeritSolicitarAprovacaoAction
  | ISuccessMeritSolicitarAprovacaoAction
  | IFailureMeritSolicitarAprovacaoAction;

//REQUEST_MERIT_GET_BUDGETS
export interface IRequestMeriteGetBudgetsAction {
  type: typeof Types.REQUEST_MERIT_GET_BUDGETS;
  payload: {
    idMerito: number;
  }
}

export interface ISuccessMeritGetBudgetsAction {
  type: typeof Types.SUCCESS_MERIT_GET_BUDGETS;
  payload: BudgetType[];
}

interface IFailureMeritGetBudgetsAction {
  type: typeof Types.FAILURE_MERIT_GET_BUDGETS;
  message: string;
}

type MeritGetBudgetsAction =
  | IRequestMeriteGetBudgetsAction
  | ISuccessMeritGetBudgetsAction
  | IFailureMeritGetBudgetsAction;

//REQUEST_MERIT_EDIT_BUDGET
export interface IRequestMeriteEditBudgetAction {
  type: typeof Types.REQUEST_MERIT_EDIT_BUDGET;
  payload: {
    idMerito: number;
    budget: Budget;
  };
}

export interface ISuccessMeritEditBudgetAction {
  type: typeof Types.SUCCESS_MERIT_EDIT_BUDGET;
  payload: BudgetType;
}

interface IFailureMeritEditBudgetAction {
  type: typeof Types.FAILURE_MERIT_EDIT_BUDGET;
  message: string;
}

type MeritEditBudgetAction =
  | IRequestMeriteEditBudgetAction
  | ISuccessMeritEditBudgetAction
  | IFailureMeritEditBudgetAction;

//REQUEST_MERIT_LOAD_SALDO
export interface IRequestMeritLoadSaldoAction {
  type: typeof Types.REQUEST_MERIT_LOAD_SALDO;
  payload: {
    idMerito: number;
  };
}

export interface ISuccessMeritLoadSaldoAction {
  type: typeof Types.SUCCESS_MERIT_LOAD_SALDO;
  payload: MeritSaldoType;
}

interface IFailureMeritLoadSaldoAction {
  type: typeof Types.FAILURE_MERIT_LOAD_SALDO;
  message: string;
}

type MeritLoadSaldoAction =
  | IRequestMeritLoadSaldoAction
  | ISuccessMeritLoadSaldoAction
  | IFailureMeritLoadSaldoAction;

//REQUEST_MERIT_REPROVAR
export interface IRequestMeritReprovarAction {
  type: typeof Types.REQUEST_MERIT_REPROVAR;
  payload: {
    idMerito: number;
  };
}

export interface ISuccessMeritReprovarAction {
  type: typeof Types.SUCCESS_MERIT_REPROVAR;
  payload: {
    data: {
      status: string;
      dataHoraFinal: Date;
      comentariosFinais: string;
    };
    controls: MeritControls;
  };
}

interface IFailureMeritReprovarAction {
  type: typeof Types.FAILURE_MERIT_REPROVAR;
  message: string;
}

type MeritReprovarAction =
  | IRequestMeritReprovarAction
  | ISuccessMeritReprovarAction
  | IFailureMeritReprovarAction;


//REQUEST_MERIT_REPROVAR
export interface IRequestMeritAprovarAction {
  type: typeof Types.REQUEST_MERIT_APROVAR;
  payload: {
    idMerito: number;
  };
}

export interface ISuccessMeritAprovarAction {
  type: typeof Types.SUCCESS_MERIT_APROVAR;
  payload: {
    data: {
      status: string;
      dataHoraFinal: Date;
    };
    controls: MeritControls;
  };
}

interface IFailureMeritAprovarAction {
  type: typeof Types.FAILURE_MERIT_APROVAR;
  message: string;
}

type MeritAprovarAction =
  | IRequestMeritAprovarAction
  | ISuccessMeritAprovarAction
  | IFailureMeritAprovarAction;

//REQUEST_MERIT_REABRIR
export interface IRequestMeritReabrirAction {
  type: typeof Types.REQUEST_MERIT_REABRIR;
  payload: {
    idMerito: number;
  };
}

export interface ISuccessMeritReabrirAction {
  type: typeof Types.SUCCESS_MERIT_REABRIR;
  payload: {
    dados: {
      status: string;
    };
    controls: MeritControls;
  };
}

interface IFailureMeritReabrirAction {
  type: typeof Types.FAILURE_MERIT_REABRIR;
  message: string;
}

type MeritReabrirAction =
  | IRequestMeritReabrirAction
  | ISuccessMeritReabrirAction
  | IFailureMeritReabrirAction;

//REQUEST_MERIT_FINALIZAR_REUNIAO
export interface IRequestMeritFinalizarReuniaoAction {
  type: typeof Types.REQUEST_MERIT_FINALIZAR_REUNIAO;
  payload: {
    idMerito: number;
  };
}

export interface ISuccessMeritFinalizarReuniaoAction {
  type: typeof Types.SUCCESS_MERIT_FINALIZAR_REUNIAO;
  payload: {
    data: {
      status: string;
    };
    controls: MeritControls;
  };
}

interface IFailureMeritFinalizarReuniaoAction {
  type: typeof Types.FAILURE_MERIT_FINALIZAR_REUNIAO;
  message: string;
}

type MeritFinalizarReuniaoAction =
  | IRequestMeritFinalizarReuniaoAction
  | ISuccessMeritFinalizarReuniaoAction
  | IFailureMeritFinalizarReuniaoAction;

//REQUEST_MERIT_LISTAR_CARGOS
export interface IRequestMeritListarCargosAction {
  type: typeof Types.REQUEST_MERIT_LISTAR_CARGOS;
  payload: {
    idMerito: number;
    idMeritoFuncionario: number;
  };
}

export interface ISuccessMeritListarCargosAction {
  type: typeof Types.SUCCESS_MERIT_LISTAR_CARGOS;
  payload: CargoHistType[]
}

interface IFailureMeritListarCargosAction {
  type: typeof Types.FAILURE_MERIT_LISTAR_CARGOS;
  message: string;
}

interface IClearMeritListarCargosAction {
  type: typeof Types.CLEAR_MERIT_LISTAR_CARGOS;
}

type MeritListarCargosAction =
  | IRequestMeritListarCargosAction
  | ISuccessMeritListarCargosAction
  | IFailureMeritListarCargosAction
  | IClearMeritListarCargosAction;

//REQUEST_MERIT_LISTAR_SALARIOS
export interface IRequestMeritListarSalariosAction {
  type: typeof Types.REQUEST_MERIT_LISTAR_SALARIOS;
  payload: {
    idMerito: number;
    idMeritoFuncionario: number;
  };
}

export interface ISuccessMeritListarSalariosAction {
  type: typeof Types.SUCCESS_MERIT_LISTAR_SALARIOS;
  payload: SalariosHistType[]
}

interface IFailureMeritListarSalariosAction {
  type: typeof Types.FAILURE_MERIT_LISTAR_SALARIOS;
  message: string;
}

interface IClearMeritListarSalariosAction {
  type: typeof Types.CLEAR_MERIT_LISTAR_SALARIOS;
}

type MeritListarSalariosAction =
  | IRequestMeritListarSalariosAction
  | ISuccessMeritListarSalariosAction
  | IFailureMeritListarSalariosAction
  | IClearMeritListarSalariosAction;

//REQUEST_MERIT_LISTAR_AVALIACOES
export interface IRequestMeritListarAvaliacoesAction {
  type: typeof Types.REQUEST_MERIT_LISTAR_AVALIACOES;
  payload: {
    idMerito: number;
    idMeritoFuncionario: number;
  };
}

export interface ISuccessMeritListarAvaliacoesAction {
  type: typeof Types.SUCCESS_MERIT_LISTAR_AVALIACOES;
  payload: AvaliacoesHistType[]
}

interface IFailureMeritListarAvaliacoesAction {
  type: typeof Types.FAILURE_MERIT_LISTAR_AVALIACOES;
  message: string;
}

interface IClearMeritListarAvaliacoesAction {
  type: typeof Types.CLEAR_MERIT_LISTAR_AVALIACOES;
}

type MeritListarAvaliacoesAction =
  | IRequestMeritListarAvaliacoesAction
  | ISuccessMeritListarAvaliacoesAction
  | IFailureMeritListarAvaliacoesAction
  | IClearMeritListarAvaliacoesAction;

//REQUEST_MERIT_LISTAR_OCORRENCIAS
export interface IRequestMeritListarOcorrenciasAction {
  type: typeof Types.REQUEST_MERIT_LISTAR_OCORRENCIAS;
  payload: {
    idMerito: number;
    idMeritoFuncionario: number;
  };
}

export interface ISuccessMeritListarOcorrenciasAction {
  type: typeof Types.SUCCESS_MERIT_LISTAR_OCORRENCIAS;
  payload: OcorrenciasHistType[]
}

interface IFailureMeritListarOcorrenciasAction {
  type: typeof Types.FAILURE_MERIT_LISTAR_OCORRENCIAS;
  message: string;
}

interface IClearMeritListarOcorrenciasAction {
  type: typeof Types.CLEAR_MERIT_LISTAR_OCORRENCIAS;
}

type MeritListarOcorrenciasAction =
  | IRequestMeritListarOcorrenciasAction
  | ISuccessMeritListarOcorrenciasAction
  | IFailureMeritListarOcorrenciasAction
  | IClearMeritListarOcorrenciasAction;

//REQUEST_MERIT_DOWNLOAD_CARTA
export interface IRequestMeritDownloadCartaAction {
  type: typeof Types.REQUEST_MERIT_DOWNLOAD_CARTA;
  payload: {
    idMerito: number;
    idMeritoFuncionario: number;
  };
}

export interface ISuccessMeritDownloadCartaAction {
  type: typeof Types.SUCCESS_MERIT_DOWNLOAD_CARTA;
}

interface IFailureMeritDownloadCartaAction {
  type: typeof Types.FAILURE_MERIT_DOWNLOAD_CARTA;
  message: string;
}

type MeritDownloadCartaAction =
  | IRequestMeritDownloadCartaAction
  | ISuccessMeritDownloadCartaAction
  | IFailureMeritDownloadCartaAction;

export type MeritActionTypes =
  | MeritLoadByIdAction
  | MeritPercMeritoEditAction
  | MeritEditAction
  | MeritAtualizaPercentualAction
  | MeritAtualizaPromocaoAction
  | MeritAtualizaSalarioAction
  | MeritAtualizaComentarioAction
  | MeritFinalizarAction
  | MeritReativarAction
  | MeritResumoLoadAction
  | MeritAplicarAction
  | MeritIgnorarAction
  | MeritSolicitarAprovacaoAction
  | MeritGetBudgetsAction
  | MeritEditBudgetAction
  | MeritLoadSaldoAction
  | MeritReprovarAction
  | MeritAprovarAction
  | MeritReabrirAction
  | MeritFinalizarReuniaoAction
  | MeritListarCargosAction
  | MeritListarSalariosAction
  | MeritListarAvaliacoesAction
  | MeritListarOcorrenciasAction
  | MeritDownloadCartaAction;
