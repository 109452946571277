import axios from "~/services/api";
import { takeLatest, put, call, all } from "redux-saga/effects";
import {
  Types,
  IRequestConductsLoadAction,
  ConductType,
  IRequestConductDeleteAction,
  IRequestConductAddAction,
  IRequestConductEditAction,
  ISuccessConductsLoadAction,
  ISuccessConductEditAction,
  ISuccessConductAddAction,
  ISuccessConductDeleteAction,
} from "../../ducks/admin/conducts/types";

const apiCallGet = () => {
  return axios
    .get(`admin/condutas`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallGetById = (idSancao: number) => {
  return axios
    .get(`admin/condutas/${idSancao}`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallPost = (item: ConductType) => {
  return axios
    .post("admin/condutas", {
      ...item,
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallPut = (item: ConductType) => {
  return axios
    .put(`admin/condutas/${item.idConduta}`, {
      ...item,
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallDel = (idConduta: number) => {
  return axios
    .delete(`admin/condutas/${idConduta}`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

function* load(action: IRequestConductsLoadAction) {
  try {
    const items: ConductType[] = yield call(apiCallGet);
    yield put({
      type: Types.SUCCESS_CONDUCTS_LOAD,
      payload: items,
    } as ISuccessConductsLoadAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível carregar as condutas.";
    yield put({ type: Types.FAILURE_CONDUCTS_LOAD, message });
  }
}

function* add(action: IRequestConductAddAction) {
  try {
    const idSancao: { idSancao: number } = yield call(
      apiCallPost,
      action.conduct
    );
    yield put({
      type: Types.SUCCESS_CONDUCT_ADD,
      conduct: { ...action.conduct, ...idSancao, flagAtivo: true },
    } as ISuccessConductAddAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível adicionar a conduta";
    yield put({ type: Types.FAILURE_CONDUCT_ADD, message });
  }
}

function* edit(action: IRequestConductEditAction) {
  try {
    yield call(apiCallPut, action.conduct);
    yield put({
      type: Types.SUCCESS_CONDUCT_EDIT,
      conduct: action.conduct,
    } as ISuccessConductEditAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível editar a conduta";
    yield put({ type: Types.FAILURE_CONDUCT_EDIT, message });
  }
}

function* del(action: IRequestConductDeleteAction) {
  try {
    yield call(apiCallDel, action.idConduta);
    yield put({
      type: Types.SUCCESS_CONDUCT_DELETE,
      idConduta: action.idConduta,
    } as ISuccessConductDeleteAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível excluir a conduta";
    yield put({ type: Types.FAILURE_CONDUCT_DELETE, message });
  }
}

export default function* positionsRoot() {
  yield all([
    yield takeLatest(Types.REQUEST_CONDUCTS_LOAD, load),
    yield takeLatest(Types.REQUEST_CONDUCT_ADD, add),
    yield takeLatest(Types.REQUEST_CONDUCT_EDIT, edit),
    yield takeLatest(Types.REQUEST_CONDUCT_DELETE, del),
  ]);
}
