import { Types, DataTypes, SelfEvaluationActionTypes, SelfEvaluationType } from "./types";
import customToast from "~/components/Toast/index";

/**
 * Reducers
 */
const INITIAL_STATE: DataTypes = {
  loading: false,
  error: false,
  success: false,
  redirectTo: null
};

/**
 * Actions
 */

export const Creators = {
  saveSelfEvaluation: (idCicloAvaliacao: number, data: SelfEvaluationType): SelfEvaluationActionTypes => {
    return ({
      type: Types.REQUEST_SELF_EVALUATION_SAVE,
      payload: {
        data,
        idCicloAvaliacao
      }
    })
  },

  sendSelfEvaluation: (idCicloAvaliacao: number, data: SelfEvaluationType): SelfEvaluationActionTypes => {
    return ({
      type: Types.REQUEST_SELF_EVALUATION_SEND,
      payload: {
        data,
        idCicloAvaliacao
      }
    })
  },
}

export default function selfEvaluationReducer(state = INITIAL_STATE, action: SelfEvaluationActionTypes): DataTypes {
  switch (action.type) {

    case Types.REQUEST_SELF_EVALUATION_SAVE:
      return { ...state, loading: true, success: false };
    case Types.SUCCESS_SELF_EVALUATION_SAVE:
      customToast.success("Tarefa salva com sucesso")
      return { ...state, loading: false, success: true };
    case Types.FAILURE_SELF_EVALUATION_SAVE:
      customToast.error(action.message)
      return { ...state, loading: false, success: false };

    // case Types.REQUEST_SELF_EVALUATION_SEND:
    //   return { ...state, redirectTo: null };
    // case Types.SUCCESS_SELF_EVALUATION_SEND:
    //   customToast.success("Tarefa salva com sucesso")
    //   return { ...state, redirectTo: '/workflow' };
    // case Types.FAILURE_SELF_EVALUATION_SEND:
    //   customToast.error(action.message)
    //   return { ...state, redirectTo: null };

    default:
      return state;
  }
}
