// #region Imports
import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik } from "formik";
import * as yup from 'yup';

//FluentUI
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import {
    DetailsListLayoutMode,
    SelectionMode,
    Selection,
    IColumn,
} from 'office-ui-fabric-react/lib/DetailsList';
import { ShimmeredDetailsList } from 'office-ui-fabric-react/lib/ShimmeredDetailsList';
import { SearchBox } from 'office-ui-fabric-react/lib/SearchBox';
import { CommandBarButton, FontIcon, ICommandBarStyles, Text } from 'office-ui-fabric-react';
import { IDialogContentProps } from 'office-ui-fabric-react/lib/Dialog';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';

import Panel from "~/components/layout/Panel";
import CustomDialog from "~/components/CustomDialogFluentUI";
import Dialog from "~/components/CustomDialog";
import { Page, BreadcrumbItems } from "~/store/ducks/home/types";
import { InputText, InputCheckbox } from '~/components/Forms';

import { DeleteButton } from '~/components/Buttons';
import HeaderPage from '~/components/layout/HeaderPage';
import NoItems from '~/components/layout/NoItems';
import { PrimaryButton } from '~/components/Buttons';
import Dropdown from "~/components/layout/Dropdown";
import MenuItem from '@material-ui/core/MenuItem';

//Estilos
import {
    Wrapper,
    ListContainer,
    DefaultButton,
    ContainerContent,
} from "./styles";

import { Creators as getPdi } from "~/store/ducks/admin/pdi";
import { Creators as addPdi } from "~/store/ducks/admin/pdi";
import { Creators as editPDI } from "~/store/ducks/admin/pdi";
import { Creators as delPDI } from "~/store/ducks/admin/pdi";

import { Creators as getPdiCompetences } from "~/store/ducks/competences";
import { Creators as addPdiCompetence } from "~/store/ducks/competences";
import { DataTypes as DataTypesCompetence } from "~/store/ducks/competences/types";

import { Creators as getPdiTypes } from "~/store/ducks/typesPDI";
import { Creators as addPdiType } from "~/store/ducks/typesPDI";
import { DataTypes as DataTypesTiposPDI } from "~/store/ducks/typesPDI/types";


import { DataTypes as DataTypesPDI, PDIType } from "~/store/ducks/admin/pdi/types";

import { Creators as setCurrentPage } from "~/store/ducks/home";
import { RootState } from "~/store/ducks";
import { IconButton } from "@fluentui/react";
//#endregion

interface IDevelopmentActionsState {
    columns: IColumn[];
    items: PDIType[];
    isPanelOpen: boolean;
    inicialValues: PDIType;
    isFiltering: boolean;
    selectionDetails: string;
    selectionCount: number;
    isDialogCompetenceOpen: boolean;
    isDialogTypeOpen: boolean;
    isDialogExcludeOpen: boolean;
    search: string;
    newCompetenceText: string;
    newTypePDIText: string;
};

interface IPropsDevelopmentActions {
    pdi: DataTypesPDI;
    competence: DataTypesCompetence;
    tiposPDI: DataTypesTiposPDI;
    getPdi: (SearchText?: string, TipoPDI?: number, Competencia?: number) => void;
    addPdi: (pdi: PDIType) => void;
    editPDI: (pdi: PDIType) => void;
    delPDI: (idAcaoPDI: number) => void;
    getPdiCompetences: () => void;
    addPdiCompetence: (descCompetencia: string) => void;
    getPdiTypes: () => void;
    addPdiType: (descTipoPDI: string) => void;
    setCurrentPage: (page: Page) => void;
}

class DevelopmentActions extends Component<IPropsDevelopmentActions, IDevelopmentActionsState> {
    private formRef: any;
    private inputSearch: any;
    private _selection: Selection;

    constructor(props: IPropsDevelopmentActions) {
        super(props);
        //#region Colunas
        const columns: IColumn[] = [
            {
                key: 'column1',
                name: 'ID',
                ariaLabel: 'ID do PDI',
                fieldName: 'idAcaoPDI',
                isRowHeader: true,
                minWidth: 75,
                maxWidth: 100,
                isResizable: true,
                isSortedDescending: false,
                onColumnClick: this._onColumnClick
            },
            // {
            //     key: 'column2',
            //     name: 'Competência',
            //     fieldName: 'competencia.descCompetencia',
            //     minWidth: 150,
            //     maxWidth: 200,
            //     isRowHeader: true,
            //     isResizable: true,
            //     isSortedDescending: false,
            //     sortAscendingAriaLabel: 'Sorted A to Z',
            //     sortDescendingAriaLabel: 'Sorted Z to A',
            //     onColumnClick: this._onColumnClick,
            //     data: 'string',
            //     isPadded: true,
            //     onRender: (value: PDIType) => value.competencia.descCompetencia
            // },
            {
                key: 'column3',
                name: 'Nome',
                fieldName: 'nomeAcaoPDI',
                minWidth: 210,
                isRowHeader: true,
                isResizable: true,
                isSortedDescending: false,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                onColumnClick: this._onColumnClick,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column4',
                name: 'Tipo',
                fieldName: 'tipoPDI.descTipoPDI',
                minWidth: 200,
                maxWidth: 250,
                isRowHeader: true,
                isResizable: true,
                isSortedDescending: false,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                onColumnClick: this._onColumnClick,
                data: 'string',
                isPadded: true,
                onRender: (value: PDIType) => (
                    <Stack horizontal verticalAlign="center">
                        <FontIcon iconName="CircleFill" style={{ color: value.tipoPDI.cor, marginRight: 10 }} />
                        <Text>{value.tipoPDI.descTipoPDI}</Text>
                    </Stack>
                )
            },
            {
                key: 'column5',
                name: 'Meses',
                fieldName: 'prazo',
                minWidth: 100,
                maxWidth: 150,
                isRowHeader: true,
                isResizable: true,
                isSortedDescending: false,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                onColumnClick: this._onColumnClick,
                data: 'string',
                isPadded: true,
                onRender: (value: PDIType) => value?.prazo ? value?.prazo > 1 ? value.prazo + ' MESES' : value.prazo + ' MÊS' : ''
            }
        ];
        //#endregion

        this.state = {
            columns: columns,
            items: [],
            isPanelOpen: false,
            selectionDetails: "",
            inicialValues: initialPDI,
            isFiltering: false,
            selectionCount: 0,
            isDialogExcludeOpen: false,
            search: "",
            isDialogCompetenceOpen: false,
            isDialogTypeOpen: false,
            newCompetenceText: '',
            newTypePDIText: ''
        }

        this._selection = new Selection({
            onSelectionChanged: () => {
                this.setState({
                    selectionDetails: this._getSelectionDetails(),
                });
            },
        });

        this.formRef = React.createRef();
        this.inputSearch = React.createRef();
    };

    componentDidMount() {
        const page: Page = {
            key: 'acoesDesenvolvimento',
            pages: itemsBreadCrumb
        };
        this.props.setCurrentPage(page);
        this.props.getPdi();
        this.props.getPdiCompetences();
        this.props.getPdiTypes();
    };

    componentDidUpdate(prevProps: IPropsDevelopmentActions, _: any) {
        if (this.state.isPanelOpen) {
            if (prevProps.pdi.success !== this.props.pdi.success) {
                if (this.props.pdi.success) {
                    this.setState({ isPanelOpen: false })
                }
            }
        }

        // if (this.props.pdi.itemSelected.success !== prevProps.pdi.itemSelected.success) {
        //     if (this.props.pdi.itemSelected.success) {
        //         this.setState({ inicialValues: this.props.pdi.itemSelected.item! })
        //     }
        // }

        // if (this.props.pdi.error !== prevProps.pdi.error) {
        //     if (this.props.pdi.error) {
        //         this.setState({ isPanelOpen: false, inicialValues: initialPDI })
        //     }
        // }
    }

    // Submit do formulário Formik
    handleSubmit = () => {
        if (this.formRef.current) {
            this.formRef.current.handleSubmit();
        };
    };

    _onItemInvoked = (pdiSelected: PDIType): void => {
        this.openEdit()
    };

    _getSelectionDetails(): any {
        const selectionCount = this._selection.getSelectedCount();
        this.setState({ selectionCount });
        this._selection.getSelection();
    };

    openEdit = () => {
        const selectPdi: number = (this._selection.getSelection()[0] as PDIType).idAcaoPDI!;
        const pdi = this.props.pdi.data.find(item => item.idAcaoPDI === selectPdi)!
        this.setState({ isPanelOpen: true, inicialValues: { ...pdi, flagOutros: pdi.flagOutros !== null ? pdi.flagOutros : false } });
    };

    cancelPanel = () => {
        this._selection.setAllSelected(false);
        this.setState({ isPanelOpen: false });
    };

    exclude = () => {
        this.props.delPDI((this._selection.getSelection()[0] as PDIType).idAcaoPDI!);
        this.setState({ isDialogExcludeOpen: false });
        this._selection.setAllSelected(false);
    };

    handleClickCompetence = () => {
        this.props.addPdiCompetence(this.state.newCompetenceText);
        this.setState({ isDialogCompetenceOpen: false });
    };

    handleClickTypePDI = () => {
        this.props.addPdiType(this.state.newTypePDIText);
        this.setState({ isDialogTypeOpen: false });
    };

    //#region  Funções do filtro

    handleSearch = (e: any) => {
        const pdi = this.props.pdi.data;
        const text = e?.target?.value;


        let items = text
            ? pdi.filter(
                item =>
                    item.nomeAcaoPDI!.toLowerCase().indexOf(text.toLowerCase()) > -1
            )
            : pdi;

        this.setState({ items, isFiltering: true, search: text });
    };

    //#endregion

    commandBarRender = () => {
        const { selectionCount } = this.state;
        if (selectionCount === 1) {
            return (
                <>
                    <CommandBarButton styles={btnStyle} iconProps={{ iconName: 'Edit' }} text="Editar" onClick={() => this.openEdit()} />
                    <CommandBarButton styles={btnStyle} iconProps={{ iconName: 'Delete' }} text="Excluir" onClick={() => this.setState({ isDialogExcludeOpen: true })} />
                </>
            );
        }
    };

    render() {
        const { isPanelOpen, isDialogExcludeOpen, isDialogTypeOpen, inicialValues, isFiltering, items, columns, search, isDialogCompetenceOpen, newCompetenceText, newTypePDIText } = this.state;
        const { pdi, competence, tiposPDI, getPdi, addPdi, editPDI } = this.props;

        return (
            <>
                <Panel
                    title={!inicialValues?.idAcaoPDI ? "Nova ação" : inicialValues?.nomeAcaoPDI}
                    open={isPanelOpen}
                    onClose={() => this.cancelPanel()}
                    footer={
                        <Stack horizontal tokens={{ childrenGap: 10 }}>
                            <DefaultButton onClick={() => this.cancelPanel()}>Cancelar</DefaultButton>
                            <PrimaryButton onClick={this.handleSubmit} isLoading={pdi.loadingAction} text="Salvar" />
                        </Stack>
                    }
                >
                    {
                        pdi.itemSelected.loading ?
                            <Spinner size={SpinnerSize.medium} styles={{ root: { height: '100%' } }} />
                            :
                            <Formik
                                innerRef={this.formRef}
                                initialValues={inicialValues}
                                validationSchema={validationPDISchema}
                                validateOnChange={false}
                                validateOnBlur={true}
                                enableReinitialize
                                onSubmit={(values: PDIType) => {
                                    const competencia = competence.data.find(item => item.idCompetencia === Number(values.idCompetencia))?.descCompetencia ?? "";
                                    const tipoPDI = tiposPDI.data.find(item => item.idTipoPDI === Number(values.idTipoPDI));
                                    const val: PDIType = {
                                        ...values,
                                        // competencia: {
                                        //     descCompetencia: competencia
                                        // },
                                        tipoPDI: {
                                            descTipoPDI: tipoPDI?.descTipoPDI ?? '',
                                            cor: tipoPDI?.cor ?? '',
                                        }
                                    }   

                                    if (val.idAcaoPDI === null) {
                                        addPdi(val);
                                    } else {
                                        editPDI(val);
                                    }
                                }}
                            >
                                {({ handleSubmit, handleChange, values, errors, setFieldValue, setFieldError }) => (
                                    <form onSubmit={handleSubmit}>
                                        <InputText
                                            value={values?.nomeAcaoPDI ?? ''}
                                            onChange={(e) => { handleChange(e); setFieldError("nomeAcaoPDI", "") }}
                                            id="nomeAcaoPDI"
                                            error={errors.nomeAcaoPDI ? true : false}
                                            name="nomeAcaoPDI"
                                            type="text"
                                            label="Nome"
                                            helperText={errors.nomeAcaoPDI}
                                            inputProps={{ maxLength: 80 }}
                                            className="mt-2"
                                            autoFocus
                                        />
                                        {/* <Stack horizontal verticalAlign="center">

                                            <Dropdown
                                                errors={errors.idCompetencia}
                                                label="Competência"
                                                name="idCompetencia"
                                                values={values?.idCompetencia ? String(values.idCompetencia) : ''}
                                                handleChange={(e: any) => { handleChange(e); setFieldError("idCompetencia", "") }}
                                                errorText={errors.idCompetencia}
                                            >
                                                {
                                                    competence.data.map((item, i) => (
                                                        <MenuItem key={i} value={`${item.idCompetencia}`}>{item.descCompetencia}</MenuItem>
                                                    ))
                                                }
                                            </Dropdown>
                                            <IconButton
                                                iconProps={{ iconName: 'Add' }}
                                                styles={{ root: { marginLeft: '10px !important', marginBottom: 8 }, icon: { fontSize: 14 } }}
                                                onClick={() => this.setState({ isDialogCompetenceOpen: true, newCompetenceText: '' })}
                                                title="Adicionar competência"
                                            />
                                        </Stack> */}
                                        <Stack horizontal verticalAlign="center">
                                            <Dropdown
                                                errors={errors.idTipoPDI}
                                                label="Tipo"
                                                name="idTipoPDI"
                                                values={values?.idTipoPDI ? String(values.idTipoPDI) : ''}
                                                handleChange={(e: any) => { handleChange(e); setFieldError("idTipoPDI", "") }}
                                                errorText={errors.idTipoPDI}
                                            >
                                                {
                                                    tiposPDI.data.map((item, i) => (
                                                        <MenuItem key={i} value={`${item.idTipoPDI}`}>{item.descTipoPDI}</MenuItem>
                                                    ))
                                                }
                                            </Dropdown>
                                            <IconButton
                                                iconProps={{ iconName: 'Add' }}
                                                styles={{ root: { marginLeft: '10px !important', marginBottom: 8 }, icon: { fontSize: 14 } }}
                                                onClick={() => this.setState({ isDialogTypeOpen: true, newTypePDIText: '' })}
                                                title="Adicionar Tipo PDI"
                                            />
                                        </Stack>
                                        <InputText
                                            value={values?.prazo ?? ''}
                                            onChange={(e) => { handleChange(e); setFieldError("prazo", "") }}
                                            id="prazo"
                                            error={errors.prazo ? true : false}
                                            name="prazo"
                                            type="number"
                                            label="Prazo"
                                            helperText={errors.prazo ? errors.prazo : 'Meses'}
                                            className="mt-2"
                                            disabled={values.flagOutros ?? false}
                                        />
                                        <InputCheckbox
                                            checked={values.flagOutros ?? false}
                                            onChange={e => {
                                                handleChange(e);
                                                setFieldValue('prazo', null)
                                            }}
                                            name="flagOutros"
                                            color="primary"
                                            label="Ação Genérica"
                                        />
                                        <InputCheckbox
                                            checked={values.flagRelacionarFuncionario ?? false}
                                            onChange={handleChange}
                                            name="flagRelacionarFuncionario"
                                            color="primary"
                                            label="Relacionar Funcionário com a Ação"
                                        />
                                    </form>
                                )}
                            </Formik>
                    }
                </Panel>

                <Wrapper>
                    <ContainerContent>
                        <HeaderPage
                            title="Ações de Desenvolvimento"
                            leftItems={
                                <>
                                    <CommandBarButton
                                        styles={btnStyle}
                                        iconProps={{ iconName: 'Add' }}
                                        disabled={pdi.loadingData}
                                        text="Adicionar Ação"
                                        onClick={() => this.setState(
                                            {
                                                isPanelOpen: true,
                                                inicialValues: initialPDI
                                            }
                                        )}
                                    />
                                    {this.commandBarRender()}
                                </>
                            }
                            rightItems={
                                <>
                                    <SearchBox
                                        placeholder="Pesquisar"
                                        value={search}
                                        onChange={(e) => this.handleSearch(e)}
                                        // onKeyUp={(e) => this.filter(e.key)}
                                        componentRef={this.inputSearch}
                                        styles={{
                                            root:
                                            {
                                                border: 'none',
                                                width: 200,
                                                marginRight: 1
                                            }
                                        }}
                                    />
                                    <CommandBarButton
                                        styles={btnStyle}
                                        iconProps={{ iconName: 'Refresh' }}
                                        text="Atualizar"
                                        onClick={() => getPdi()}
                                    />
                                </>
                            }
                        />
                        {!pdi.loadingData && pdi.data.length === 0 ?
                            <NoItems
                                error={pdi.error}
                                text="Não há ações de desenvolvimento cadastradas"
                                icon="PublishCourse"
                            />
                            :
                            <ListContainer>
                                <ShimmeredDetailsList
                                    items={isFiltering ? items : pdi.data}
                                    enableShimmer={pdi.loadingData}
                                    columns={columns}
                                    selectionMode={SelectionMode.single}
                                    selection={this._selection}
                                    getKey={this._getKey}
                                    selectionPreservedOnEmptyClick={true}
                                    setKey="single"
                                    layoutMode={DetailsListLayoutMode.justified}
                                    isHeaderVisible={true}
                                    onItemInvoked={this._onItemInvoked}
                                />
                            </ListContainer>
                        }
                    </ContainerContent>
                </Wrapper>


                <CustomDialog
                    hidden={!isDialogExcludeOpen}
                    onDismiss={() => this.setState({ isDialogExcludeOpen: false })}
                    dialogContentProps={dialogContentProps}
                >
                    <DefaultButton onClick={() => this.setState({ isDialogExcludeOpen: false })} text="Cancelar" />
                    <DeleteButton onClick={() => this.exclude()} text="Excluir" />
                </CustomDialog>

                <Dialog
                    open={isDialogCompetenceOpen}
                    title="Nova Competência"
                    btnConfirmText="Adicionar"
                    onClickConfirm={() => this.handleClickCompetence()}
                    onClickCancel={() => this.setState({ isDialogCompetenceOpen: false })}
                >
                    <InputText
                        type="text"
                        label="Competência"
                        value={newCompetenceText}
                        onChange={e => this.setState({ newCompetenceText: e.target.value })}
                        autoFocus
                    />
                </Dialog>

                <Dialog
                    open={isDialogTypeOpen}
                    title="Novo Tipo"
                    btnConfirmText="Adicionar"
                    onClickConfirm={() => this.handleClickTypePDI()}
                    onClickCancel={() => this.setState({ isDialogTypeOpen: false })}
                >
                    <InputText
                        type="text"
                        label="Tipo"
                        value={newTypePDIText}
                        onChange={e => this.setState({ newTypePDIText: e.target.value })}
                        autoFocus
                    />
                </Dialog>
            </>
        );
    };

    private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const { columns } = this.state;
        const newColumns: IColumn[] = columns.slice();
        let items: PDIType[] = [];
        if (this.state.isFiltering) {
            items = this.state.items;
        } else {
            items = this.props.pdi.data;
        };
        const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            };
        });
        const newItems = this._sort(items, currColumn.fieldName!, currColumn.isSortedDescending);
        this.setState({
            columns: newColumns,
            items: newItems,
        });
    };

    private _getKey(item: any, index?: number): any {
        if (item !== undefined)
            return item.key;
    };

    private _sort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
        const result = (prop: string, obj: T) => (prop.split('.').reduce((a: any, b: any) => a[b] ?? '', obj))
        return items.sort((a: T, b: T) => ((isSortedDescending ? result(columnKey, a) < result(columnKey, b) : result(columnKey, a) > result(columnKey, b)) ? 1 : -1));
    };

}


/**
 * Validação do formulário
 */
const validationPDISchema = yup.object().shape({
    // idCompetencia: yup.number().nullable().required("Campo obrigatório"),
    idTipoPDI: yup.number().nullable().required("Campo obrigatório"),
    flagOutros: yup.bool().nullable().notRequired(),
    flagRelacionarFuncionario: yup.bool().nullable().notRequired(),
    nomeAcaoPDI:
        yup.string()
            .required("Campo obrigatório"),
    prazo: yup.number().nullable().when("flagOutros", {
        is: false,
        then: yup.number().required("Campo obrigatório")
    }),
});

const dialogContentProps: IDialogContentProps = {
    title: 'Excluir?',
    closeButtonAriaLabel: 'Close',
    subText: 'Tem certeza de que deseja excluir este item?'
};

const itemsBreadCrumb: BreadcrumbItems[] = [
    { text: "Home", isCurrentItem: false, icon: "HomeSolid", onlyIcon: true, url: "/" },
    { text: "Ações de Desenvolvimento", isCurrentItem: true },
];

const initialPDI: PDIType = {
    idAcaoPDI: null,
    idCompetencia: null,
    idTipoPDI: null,
    nomeAcaoPDI: '',
    prazo: null,
    flagOutros: false,
    flagRelacionarFuncionario: false,
    // competencia: {
    //     descCompetencia: ''
    // },
    tipoPDI: {
        descTipoPDI: '',
        cor: ''
    }
};

const btnStyle: Partial<ICommandBarStyles> = {
    root: {
        height: 44
    }
}

const mapStateToProps = (state: RootState) => ({
    pdi: state.pdiReducer,
    competence: state.competenceReducer,
    tiposPDI: state.typesPDIReducer
});

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    ...getPdi,
    ...addPdi,
    ...editPDI,
    ...delPDI,
    ...getPdiCompetences,
    ...addPdiCompetence,
    ...getPdiTypes,
    ...addPdiType,
    ...setCurrentPage
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DevelopmentActions);
