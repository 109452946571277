import { Types, DataTypes, VacancyActionTypes } from "./types";
import customToast from "~/components/Toast/index";

/**
 * Reducers
 */
const INITIAL_STATE: DataTypes = {
    data: [],
    error: false,
    loading: false,
    success: false,
};

/**
 * Actions
 */

export const Creators = {
    pesquisaVagas: (idArea: number): VacancyActionTypes => {
        return {
            type: Types.REQUEST_VACANCIES_LOAD,
            payload: {
                idArea
            }
        }
    },
}


export default function vacancyReducer(state = INITIAL_STATE, action: VacancyActionTypes): DataTypes {

    switch (action.type) {

        //LOAD
        case Types.REQUEST_VACANCIES_LOAD:
            return { ...state, loading: true, error: false, success: false };
        case Types.SUCCESS_VACANCIES_LOAD:
            return { ...state, data: action.payload, loading: false, error: false, success: true };
        case Types.FAILURE_VACANCIES_LOAD:
            customToast.error(action.message);
            return { ...state, data: [], loading: false, error: true, success: false };

        default:
            return state;
    }
}