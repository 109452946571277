/**
 * Types
 */
export enum Types {
    //GET
    REQUEST_CC_LOAD = "cc/REQUEST_CC_LOAD",
    SUCCESS_CC_LOAD = "cc/SUCCESS_CC_LOAD",
    FAILURE_CC_LOAD = "cc/FAILURE_CC_LOAD",
}

export type CCType = {
    idCC: number | null;
    nomeCC: string;
    flagAtivo: boolean;
}

interface ICCState {
    readonly data: CCType[];
    readonly loading: boolean;
    readonly error: boolean;
    readonly success: boolean;
};

export type DataTypes = ICCState;

/**
 * Actions
 */

//GET
export interface IRequestCCLoadAction {
    type: typeof Types.REQUEST_CC_LOAD;
    payload: {
        SearchText?: string;
    }
};

export interface ISuccessCCLoadAction {
    type: typeof Types.SUCCESS_CC_LOAD;
    payload: CCType[];
};

interface IFailureCCLoadAction {
    type: typeof Types.FAILURE_CC_LOAD;
    message: string;
};

type CCLoadAction = IRequestCCLoadAction | ISuccessCCLoadAction | IFailureCCLoadAction;

export type CCActionTypes =
    CCLoadAction;