import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Page, BreadcrumbItems } from "~/store/ducks/home/types";
import { Creators as CreatorsTasks } from "~/store/ducks/tasks";
import { DataTypes as DataTypesTasks } from "~/store/ducks/tasks/types";
import { Creators as CreatorsSelfEvaluations } from "~/store/ducks/selfEvaluations";
import { DataTypes as DataTypesSelfEval, SelfEvaluationType } from "~/store/ducks/selfEvaluations/types";
import { Creators } from '~/store/ducks/home'
import { RootState } from "~/store/ducks";

import SelfEvaluationRender from './selfEvaluationRender';
import SelfEvaluationRenderModules from './selfEvaluationRenderModules';

interface ISelfEvaluationProps {
  taskState: DataTypesTasks;
}

const SelfEvaluation: React.FC<ISelfEvaluationProps> = (props) => {
  const { taskState } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams() as any;
  const selfEvaluationState = useSelector<RootState, DataTypesSelfEval>(state => state.selfEvaluationsReducer);

  const { id: idTarefa } = params;

  const getDataToSave = () => {
    const avaliacao = taskState?.selected?.TarefaAvaliacao?.avaliacao;
    const idCicloAvaliacao = taskState?.selected?.TarefaAvaliacao?.avaliacao.idCicloAvaliacao!
    const data: SelfEvaluationType = {
      aspiracoesCarreira: avaliacao?.aspiracoesCarreira ?? '',
      comentariosGerais: avaliacao?.comentariosGerais ?? '',
      atitudes: avaliacao?.atitudes?.map(item => ({
        idCicloAvaliacaoAtitude: item.idCicloAvaliacaoAtitude,
        idAtitude: item.idAtitude,
        resultado: item.resultado,
        comentarios: item?.comentarios
      })) ?? [],
      areas: avaliacao?.areas?.map(area => {
        return {
          idCicloAvaliacaoArea: area.idCicloAvaliacaoArea,
          comentariosIniciativas: area.comentariosIniciativas,
          iniciativas: area.iniciativas!.map(item => ({
            idCicloAvaliacaoAreaIniciativa: item.idCicloAvaliacaoAreaIniciativa,
            idCicloColaboradorProjeto: item.idCicloColaboradorProjeto,
            resultado: item.resultado,
            percent: item.percent
          })) ?? [],
        };
      })!
    }

    return {
      idCicloAvaliacao,
      data
    }
  }

  const handleSave = () => {
    const data = getDataToSave();
    dispatch(CreatorsSelfEvaluations.saveSelfEvaluation(data.idCicloAvaliacao, data.data))
  }

  const handleSend = () => {
    const data = getDataToSave();
    dispatch(CreatorsSelfEvaluations.sendSelfEvaluation(data.idCicloAvaliacao, data.data))
  }

  useEffect(() => {
    // console.log(idTarefa)
    // if (!idTarefa) {
    //   history.push('/workflow')
    // }

    dispatch(Creators.setCurrentPage(page));
    // dispatch(CreatorsTasks.getTasksById(idTarefa))
  }, []);

  useEffect(() => {
    if (taskState.redirectTo) {
      history.push(taskState.redirectTo)
    }
  }, [taskState])

  return (
    <SelfEvaluationRender
      onSave={handleSave}
      onSend={handleSend}
      avaliation={taskState.selected?.TarefaAvaliacao?.avaliacao!}
      statusTarefa={taskState.selected?.statusTarefa ?? ''}
      error={taskState.error}
      loading={taskState.loadingData}
      loadingAction={taskState.loadingAction}
      loadingSave={selfEvaluationState.loading}
    />
  )

}


const itemsBreadCrumb: BreadcrumbItems[] = [
  { text: "Home", isCurrentItem: false, icon: "HomeSolid", onlyIcon: true, url: "/" },
  { text: "Workflow", isCurrentItem: false, url: "/workflow" },
  { text: "Avaliação", isCurrentItem: true },
];

const page: Page = {
  key: 'autoavaliacao',
  pages: itemsBreadCrumb
};

export default SelfEvaluation;
