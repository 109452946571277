import {
    Types,
    DataTypes,
    CategoriesPositionsActionTypes,
    CategoryPositionAddOrEditDTO,
  } from "./types";
  import customToast from "~/components/Toast/index";
  /**
   * Reducers
   */
  const INITIAL_STATE: DataTypes = {
    data: [],
    loadingData: false,
    loadingAction: false,
    success: false,
    error: false,
    itemSelected: {
      item: null,
      error: false,
      loading: false,
      success: false,
    },
  };
  
  /**
   * Actions
   */
  
  export const Creators = {
    getCategoriesPositions: (): CategoriesPositionsActionTypes => {
      return {
        type: Types.REQUEST_LOAD_CATEGORIES_POSITIONS,
      };
    },
  
    getCategoryPositionById: (
      idCategoriaCargo: number
    ): CategoriesPositionsActionTypes => ({
      type: Types.REQUEST_LOAD_CATEGORY_POSITION_BY_ID,
      idCategoriaCargo,
    }),
  
    addCategoryPosition: (
      category: CategoryPositionAddOrEditDTO
    ): CategoriesPositionsActionTypes => ({
      type: Types.REQUEST_ADD_CATEGORY_POSITION,
      payload: category,
    }),
  
    editCategoryPosition: (
      category: CategoryPositionAddOrEditDTO
    ): CategoriesPositionsActionTypes => ({
      type: Types.REQUEST_EDIT_CATEGORY_POSITION,
      payload: category,
    }),
  
    delCategoryPosition: (
      idCategoriaCargo: number
    ): CategoriesPositionsActionTypes => ({
      type: Types.REQUEST_DEL_CATEGORY_POSITION,
      idCategoriaCargo,
    }),
  };
  
  export default function categoriesPositionsReducer(
    state = INITIAL_STATE,
    action: CategoriesPositionsActionTypes
  ): DataTypes {
    switch (action.type) {
      // LOAD
      case Types.REQUEST_LOAD_CATEGORIES_POSITIONS:
        return {
          ...state,
          loadingData: true,
          error: false,
          success: false,
          itemSelected: { ...state.itemSelected, item: null },
        };
      case Types.SUCCESS_LOAD_CATEGORIES_POSITIONS:
        return {
          ...state,
          loadingData: false,
          error: false,
          success: true,
          data: action.payload,
        };
      case Types.FAILURE_LOAD_CATEGORIES_POSITIONS:
        customToast.error(action.message);
        return {
          ...state,
          data: [],
          loadingData: false,
          success: false,
          error: true,
        };
  
      //LOAD BY ID
      case Types.REQUEST_LOAD_CATEGORY_POSITION_BY_ID:
        return {
          ...state,
          itemSelected: {
            item: null,
            loading: true,
            success: false,
            error: false,
          },
        };
      case Types.SUCCESS_LOAD_CATEGORY_POSITION_BY_ID:
        return {
          ...state,
          itemSelected: {
            item: action.payload,
            loading: false,
            success: true,
            error: false,
          },
        };
      case Types.FAILURE_LOAD_CATEGORY_POSITION_BY_ID:
        customToast.error(action.message);
        return {
          ...state,
          itemSelected: {
            item: null,
            loading: false,
            success: false,
            error: true,
          },
        };
  
      // ADD
      case Types.REQUEST_ADD_CATEGORY_POSITION:
        return { ...state, loadingAction: true, error: false, success: false };
      case Types.SUCCESS_ADD_CATEGORY_POSITION:
        return {
          ...state,
          loadingAction: false,
          error: false,
          success: true,
          data: [...state.data, action.payload],
        };
      case Types.FAILURE_ADD_CATEGORY_POSITION:
        customToast.error(action.message);
        return {
          ...state,
          loadingAction: false,
          success: false,
          error: true,
        };
  
      // EDIT
      case Types.REQUEST_EDIT_CATEGORY_POSITION:
        return { ...state, loadingAction: true, error: false, success: false };
      case Types.SUCCESS_EDIT_CATEGORY_POSITION:
        return {
          ...state,
          loadingAction: false,
          error: false,
          success: true,
          data: state.data.map((item) => {
            if (item.idCategoriaCargo === action.payload.idCategoriaCargo) {
              return action.payload;
            }
            return item;
          }),
        };
      case Types.FAILURE_EDIT_CATEGORY_POSITION:
        customToast.error(action.message);
        return {
          ...state,
          loadingAction: false,
          success: false,
          error: true,
        };
  
      // DEL
      case Types.REQUEST_DEL_CATEGORY_POSITION:
        return { ...state, error: false, success: false };
      case Types.SUCCESS_DEL_CATEGORY_POSITION:
        return {
          ...state,
          error: false,
          success: true,
          data: state.data.filter(
            (item) => item.idCategoriaCargo !== action.idCategoriaCargo
          ),
        };
      case Types.FAILURE_DEL_CATEGORY_POSITION:
        customToast.error(action.message);
        return {
          ...state,
          success: false,
          error: true,
        };
  
      default:
        return state;
    }
  }