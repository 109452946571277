import { Types, DataTypes, Page, HomeActionTypes } from "./types"

/**
 * Reducers
 */
const INITIAL_STATE: DataTypes = {
    page: {
        key: 'home',
        pages: [{ text: 'Página Inicial', isCurrentItem: true }]
    },
    isMenuExpanded: true,
    tarefasPendentes: '0'
};


/**
 * Actions
 */

export const Creators = {
    setCurrentPage: (page: Page): HomeActionTypes => ({
        type: Types.SET_CURRENT_PAGE,
        payload: page
    }),

    setMenuExpanded: (isExpanded: boolean): HomeActionTypes => ({
        type: Types.SET_MENU_EXPANDED,
        isExpanded
    }),

    getHome: (): HomeActionTypes => ({
        type: Types.REQUEST_HOME_LOAD
    })
};

export default function homeReducer(state = INITIAL_STATE, action: HomeActionTypes): DataTypes {
    switch (action.type) {

        case Types.SET_CURRENT_PAGE:
            return { ...state, page: action.payload }

        case Types.SET_MENU_EXPANDED:
            return { ...state, isMenuExpanded: action.isExpanded }

        case Types.REQUEST_HOME_LOAD:
            return { ...state, tarefasPendentes: '0' }
        case Types.SUCCESS_HOME_LOAD:
            return { ...state, tarefasPendentes: action.tarefasPendentes }
        case Types.FAILURE_HOME_LOAD:
            return { ...state, tarefasPendentes: '0' }

        default:
            return state;
    }
}