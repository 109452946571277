import styled from "styled-components";
import {
  IButtonStyles,
  IDetailsColumnStyles,
  IHoverCardStyles,
  IPivotStyles,
} from "@fluentui/react";

export const Wrapper = styled.div`
  height: calc(100vh - 50px);
  width: calc(100% - 250px);
  flex-basis: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow: auto;
`;

export const ContainerContent = styled.div`
  padding: 20px 20px 0;
  height: calc(100% - 50px);
`;

export const ListContainer = styled.div`
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 100px);
  overflow-y: auto;
  position: relative;
`;

export const Comentario = styled.p`
  text-overflow: ellipsis;
  white-space: break-spaces;
  width: 200px;
  max-height: 40px;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  height: auto;
`;

export const WrapperPersonaInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 350px;
  min-height: 50px;
  height: auto;
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  padding: 10px;
`;

export const WrapperAutoComplete = styled.div`
  position: relative;
  width: 100%;
  .MuiAutocomplete-inputRoot .MuiAutocomplete-endAdornment {
    right: 35px;
  }
`;

export const pivotStyles: Partial<IPivotStyles> = {
  root: {
    borderBottom: "1px solid #CFD3DA",
    padding: "10px 20px 0px",
    backgroundColor: "#FFFFFF",
    width: "100%",
  },
  linkContent: {
    width: 130,
  },
};

export const hoverCardStyles: Partial<IHoverCardStyles> = {
  host: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "default",
  },
};

export const IconButtonStyle: Partial<IButtonStyles> = {
  root: {
    position: "absolute",
    top: 20,
    right: 8,
    width: 24,
    height: 24,
    padding: 8,
  },
  icon: {
    fontSize: 12,
  },
};

export const cellGreenStyle: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    backgroundColor: "#D3EADF",
    justifyContent: "center",
    selectors: {
      "&:hover": {
        backgroundColor: "#CAE0D6",
      },
    },
  },
};

export const cellGreenDarkStyle: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    backgroundColor: "#BEDBCA",
    justifyContent: "center",
    selectors: {
      "&:hover": {
        backgroundColor: "#acccb9",
      },
    },
  },
};

export const cellGrayStyle: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    backgroundColor: "#F6F6F6",
    selectors: {
      "&:hover": {
        backgroundColor: "#EBEBEB",
      },
    },
  },
};

export const iconButtonStyle: Partial<IButtonStyles> = {
  rootHovered: { backgroundColor: "rgba(0, 0, 0, 0.05)" },
  rootPressed: { backgroundColor: "rgba(0, 0, 0, 0.1)" },
};

export const commandBarStyle: Partial<IButtonStyles> = {
  root: {
    height: 40,
    marginLeft: "15px !important",
    padding: "0 5px",
  },
};
