import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import { Formik } from "formik";
import * as yup from "yup";

import {
  DetailsListLayoutMode,
  SelectionMode,
  Selection,
  IColumn,
} from "office-ui-fabric-react/lib/DetailsList";
import { ShimmeredDetailsList } from "office-ui-fabric-react/lib/ShimmeredDetailsList";
import {
  CommandBarButton,
  DefaultButton,
  ICommandBarStyles,
  IDialogContentProps,
} from "office-ui-fabric-react";

import NoItems from "~/components/layout/NoItems";
import { Stack } from "office-ui-fabric-react";
import { RootState } from "~/store/ducks";
import Panel from "~/components/layout/Panel";
import { DeleteButton, PrimaryButton } from "~/components/Buttons";
import { InputDate, InputNumber } from "~/components/Forms";
import CustomDialog from "~/components/CustomDialogFluentUI";
import Dropdown from "~/components/layout/Dropdown";

import { Creators as loadEmployeeOcorrencias } from "~/store/ducks/employees";
import { Creators as addEmployeeOcorrencia } from "~/store/ducks/employees";
import { Creators as editEmployeeOcorrencia } from "~/store/ducks/employees";
import { Creators as delEmployeeOcorrencia } from "~/store/ducks/employees";
import {
  EmployeeUnidadeType,
  EmployeeSelected,
  EmployeeOccurrenceType,
} from "~/store/ducks/employees/types";

import motivoAfastamentosReducer, {
  Creators as getMotivosAfastamentos,
} from "~/store/ducks/admin/motivoAfastamento";
import { DataTypes as DataTypesAfastamento } from "~/store/ducks/admin/motivoAfastamento/types";

import { MenuItem } from "@material-ui/core";
import GridOcorrencias from "./GridOcorrencias";

interface IEmployeeOcorrenciasState {
  columns: IColumn[];
  items: EmployeeOccurrenceType[];
  selectionDetails: string;
  selectionCount: number;
  isPanelOpen: boolean;
  isDialogExcludeOpen: boolean;
  ocorrenciaSelected: EmployeeOccurrenceType | null;
  initialValues: EmployeeOccurrenceType;
}

interface IEmployeeOcorrenciasProps {
  employee: EmployeeSelected;
  motivosAfastamentos: DataTypesAfastamento;
  idFuncionario: number;
  getMotivosAfastamentos: () => void;
  loadEmployeeOcorrencias: (idFuncionario: number) => void;
  addEmployeeOcorrencia: (
    idFuncionario: number,
    ocorrencia: EmployeeOccurrenceType
  ) => void;
  editEmployeeOcorrencia: (
    idFuncionario: number,
    ocorrencia: EmployeeOccurrenceType
  ) => void;
  delEmployeeOcorrencia: (
    idFuncionario: number,
    idFuncOcorrencia: number
  ) => void;
}

class EmployeeOcorrencias extends Component<
  IEmployeeOcorrenciasProps,
  IEmployeeOcorrenciasState
> {
  private _selection: Selection;
  private formRef: any;
  private timeout: number;

  constructor(props: IEmployeeOcorrenciasProps) {
    super(props);

    const columns: IColumn[] = [
      {
        key: "column1",
        name: "Data Afastamento",
        fieldName: "dataInicio",
        minWidth: 100,
        maxWidth: 100,
        isRowHeader: true,
        isResizable: true,
        // isSortedDescending: false,
        // sortAscendingAriaLabel: 'Sorted A to Z',
        // sortDescendingAriaLabel: 'Sorted Z to A',
        // onColumnClick: this._onColumnClick,
        data: "data",
        isPadded: true,
        onRender: (item: EmployeeOccurrenceType) =>
          moment(item.dataInicio).format("DD/MM/YYYY"),
      },
      {
        key: "column2",
        name: "Data Retorno",
        fieldName: "dataRetorno",
        isRowHeader: true,
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
        // isSortedDescending: false,
        // onColumnClick: this._onColumnClick
        onRender: (item: EmployeeOccurrenceType) =>
          item.dataRetorno ? moment(item.dataRetorno).format("DD/MM/YYYY") : "",
      },
      {
        key: "column3",
        name: "Motivo Afastamento",
        fieldName: "motivoAfastamento",
        minWidth: 75,
        isRowHeader: true,
        isResizable: true,
        // isSortedDescending: false,
        // sortAscendingAriaLabel: 'Sorted A to Z',
        // sortDescendingAriaLabel: 'Sorted Z to A',
        // onColumnClick: this._onColumnClick,
        isPadded: true,
        onRender: (item: EmployeeOccurrenceType) =>
          item.motivoAfastamento?.descMotivoAfastamento ?? "",
      },
    ];

    this.state = {
      columns,
      items: [],
      selectionCount: 0,
      selectionDetails: "",
      isPanelOpen: false,
      isDialogExcludeOpen: false,
      ocorrenciaSelected: null,
      initialValues: inicialValues,
    };

    this._selection = new Selection({
      onSelectionChanged: () => {
        this.setState({
          selectionDetails: this._getSelectionDetails(),
        });
      },
    });

    this.formRef = React.createRef();
    this.timeout = 0;
  }

  componentDidMount() {
    const {
      loadEmployeeOcorrencias,
      idFuncionario,
      employee,
      getMotivosAfastamentos,
    } = this.props;

    getMotivosAfastamentos();

    // if (employee.ocorrencias?.length === 0 || employee.ocorrencias === null) {
    //   loadEmployeeOcorrencias(idFuncionario);
    // }
  }

  componentDidUpdate(prevProps: IEmployeeOcorrenciasProps) {
    if (prevProps.employee.success !== this.props.employee.success) {
      if (this.props.employee.success) {
        this.setState({ isPanelOpen: false });
      }
    }
  }

  _getSelectionDetails(): any {
    const selectionCount = this._selection.getSelectedCount();
    this.setState({ selectionCount });
    this._selection.getSelection();
  }

  excludeOcorrencia = () => {
    const { idFuncionario } = this.props;
    const idFuncOcorrencia = (this._selection.getSelection()[0] as EmployeeOccurrenceType)
      .idFuncOcorrencia!;
    this.props.delEmployeeOcorrencia(idFuncionario, idFuncOcorrencia);
    this.setState({ isDialogExcludeOpen: false });
    this._selection.setAllSelected(false);
  };

  cancelPanel = () => {
    this._selection.setAllSelected(false);
    this.setState({ isPanelOpen: false });
  };

  handleSubmit = () => {
    if (this.formRef.current) {
      this.formRef.current.handleSubmit();
    }
  };

  _onItemInvoked = () => {
    this.handleEdit();
  };

  handleEdit = () => {
    const ocorrencia = (this._selection.getSelection()[0] as EmployeeOccurrenceType)!;
    this.setState({
      initialValues: ocorrencia,
      isPanelOpen: true,
    });
  };

  render() {
    const {
      columns,
      isPanelOpen,
      isDialogExcludeOpen,
      initialValues,
    } = this.state;
    const {
      employee,
      loadEmployeeOcorrencias,
      idFuncionario,
      editEmployeeOcorrencia,
      addEmployeeOcorrencia,
      motivosAfastamentos,
    } = this.props;
    const { ocorrencias, loadingItems } = employee;
    return (
      <>
        <Stack
          horizontal
          horizontalAlign="space-between"
          styles={{ root: { width: "100%" } }}
        >
          <Stack horizontal verticalAlign="center">
            <CommandBarButton
              styles={btnStyle}
              iconProps={{ iconName: "Add" }}
              text="Adicionar Ocorrência"
              onClick={() =>
                this.setState({
                  isPanelOpen: true,
                  initialValues: inicialValues,
                })
              }
            />
            {this._selection.getSelectedCount() > 0 && (
              <>
                <CommandBarButton
                  styles={btnStyle}
                  iconProps={{ iconName: "Edit" }}
                  text="Editar"
                  onClick={this.handleEdit}
                />
                <CommandBarButton
                  styles={btnStyle}
                  iconProps={{ iconName: "Delete" }}
                  text="Excluir"
                  onClick={() => this.setState({ isDialogExcludeOpen: true })}
                />
              </>
            )}
          </Stack>
          <Stack>
            <CommandBarButton
              styles={btnStyle}
              iconProps={{ iconName: "Refresh" }}
              text="Atualizar"
              onClick={() => loadEmployeeOcorrencias(idFuncionario)}
            />
          </Stack>
        </Stack>
        {ocorrencias?.length === 0 ? (
          <NoItems text="Não há histórico de ocorrências" icon="EditNote" />
        ) : (
          <GridOcorrencias
            idFuncionario={idFuncionario}
            selectionMode={SelectionMode.single}
            selection={this._selection}
            getKey={this._getKey}
            selectionPreservedOnEmptyClick={true}
            onItemInvoked={this._onItemInvoked}
          />
        )}

        <Panel
          title={
            !initialValues.idFuncOcorrencia
              ? "Nova Ocorrência"
              : "Editar Ocorrência"
          }
          open={isPanelOpen}
          onClose={() => this.cancelPanel()}
          footer={
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <DefaultButton onClick={() => this.cancelPanel()}>
                Cancelar
              </DefaultButton>
              <PrimaryButton
                onClick={this.handleSubmit}
                isLoading={employee.loadingAction}
                text="Salvar"
              />
            </Stack>
          }
        >
          <Formik
            innerRef={this.formRef}
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnChange={false}
            validateOnBlur={true}
            enableReinitialize
            onSubmit={(values) => {
              if (values.idMotivoAfastamento) {
                const newMotivoAfastamento = motivosAfastamentos.data.find(
                  (item) =>
                    item.idMotivoAfastamento === values.idMotivoAfastamento
                );
                if (newMotivoAfastamento) {
                  values.motivoAfastamento = newMotivoAfastamento;
                }
              }
              if (!values.idFuncOcorrencia) {
                addEmployeeOcorrencia(idFuncionario, values);
              } else {
                editEmployeeOcorrencia(idFuncionario, values);
              }
            }}
          >
            {({
              handleSubmit,
              handleChange,
              values,
              errors,
              setFieldError,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <InputDate
                  label="Data de Afastamento"
                  name="dataInicio"
                  value={values.dataInicio ? moment(values.dataInicio) : null}
                  onChange={(value) => {
                    setFieldValue("dataInicio", value);
                    setFieldError("dataInicio", "");
                  }}
                  className="mt-2"
                  error={!!errors.dataInicio}
                  helperText={errors.dataInicio}
                  autoFocus
                />
                <InputDate
                  label="Data de Retorno"
                  name="dataRetorno"
                  value={values.dataRetorno ? moment(values.dataRetorno) : null}
                  onChange={(value) => {
                    setFieldValue("dataRetorno", value);
                    setFieldError("dataRetorno", "");
                  }}
                  className="mt-2"
                  error={!!errors.dataRetorno}
                  helperText={errors.dataRetorno}
                />
                <InputNumber
                  value={values.cid ? Number(values.cid) : ""}
                  onValueChange={(value) => {
                    setFieldValue("cid", value.floatValue);
                    setFieldError("cid", "");
                  }}
                  decimalScale={0}
                  id="cid"
                  name="cid"
                  label="Cid"
                  helperText={errors.cid}
                  error={!!errors.cid}
                />
                <Dropdown
                  errors={errors.idMotivoAfastamento}
                  label="Motivo de afastamento"
                  name="idMotivoAfastamento"
                  values={values.idMotivoAfastamento ?? ""}
                  handleChange={(e: any) => {
                    handleChange(e);
                    setFieldError("idMotivoAfastamento", "");
                  }}
                  errorText={errors.idMotivoAfastamento}
                >
                  {motivosAfastamentos.data.map((item) => (
                    <MenuItem
                      key={item.idMotivoAfastamento}
                      value={item.idMotivoAfastamento}
                    >
                      {item.descMotivoAfastamento}
                    </MenuItem>
                  ))}
                </Dropdown>
              </form>
            )}
          </Formik>
        </Panel>

        <CustomDialog
          hidden={!isDialogExcludeOpen}
          onDismiss={() => this.setState({ isDialogExcludeOpen: false })}
          dialogContentProps={dialogContentProps}
        >
          <DefaultButton
            onClick={() => this.setState({ isDialogExcludeOpen: false })}
            text="Cancelar"
          />
          <DeleteButton
            onClick={() => this.excludeOcorrencia()}
            text="Excluir"
          />
        </CustomDialog>
      </>
    );
  }

  private _onColumnClick = (
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn
  ): void => {
    const { columns } = this.state;
    const newColumns: IColumn[] = columns.slice();
    let items: EmployeeOccurrenceType[] = this.props.employee.ocorrencias ?? [];
    const currColumn: IColumn = newColumns.filter(
      (currCol) => column.key === currCol.key
    )[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    const newItems = this._sort(
      items,
      currColumn.fieldName!,
      currColumn.isSortedDescending
    );
    this.setState({
      columns: newColumns,
      items: newItems,
    });
  };

  private _getKey(item: any, index?: number): any {
    if (item !== undefined) return item.key;
  }

  private _sort<T>(
    items: T[],
    columnKey: string,
    isSortedDescending?: boolean
  ): T[] {
    const key = columnKey as keyof T;
    const itemsSorted = items.sort((a: T, b: T) =>
      (isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1
    );
    return itemsSorted;
  }
}

const btnStyle: Partial<ICommandBarStyles> = {
  root: {
    height: 44,
  },
};

const inicialValues: EmployeeOccurrenceType = {
  dataInicio: null,
  dataRetorno: null,
  cid: "",
  idMotivoAfastamento: null,
  motivoAfastamento: null,
};

const validationSchema = yup.object().shape({
  dataInicio: yup
    .date()
    .nullable()
    .typeError("Data inválida")
    .required("Campo obrigatório"),
  dataRetorno: yup
    .date()
    .nullable()
    .when("dataInicio", (started: Date, yup: any) =>
      started
        ? yup.min(
            started,
            "A data não pode ser menor que a data de afastamento"
          )
        : yup.notRequired()
    )
    .typeError("Data inválida")
    .notRequired(),
  cid: yup.number().nullable().required("Campo obrigatório"),
});

const dialogContentProps: IDialogContentProps = {
  title: "Excluir?",
  closeButtonAriaLabel: "Close",
  subText: "Tem certeza de que deseja excluir este item?",
};

const mapStateToProps = (state: RootState) => ({
  employee: state.employeesReducer.itemSelected,
  motivosAfastamentos: state.motivoAfastamentoReducer,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      ...getMotivosAfastamentos,
      ...loadEmployeeOcorrencias,
      ...addEmployeeOcorrencia,
      ...editEmployeeOcorrencia,
      ...delEmployeeOcorrencia,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployeeOcorrencias);
