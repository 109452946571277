import styled from "styled-components";

export const ContainerContent = styled.div`
  padding: 20px;
  height: 100%;
`;

export const ListContainer = styled.div`
  width: 100%;
  height:100%;
  overflow-y: auto;
`;
