import { takeEvery, put, call, all } from "redux-saga/effects";
import api from "~/services/api";

import {
    Types,
    ValidationType,
    IRequestValidationCycleAction,
    IRequestValidationAreaAction,
    IRequestPublishingAreaAction,
    ISuccessValidationCycleAction,
    ISuccessValidationAreaAction,
    ISuccessPublishingAreaAction,
    IRequestPublishingVisualizationAction,
    ISuccessPublishingVisualizationAction
} from "../ducks/cycle/validation/types";

const apiCallValidation = (idCiclo: number) => {
    return api.get(`ciclos/${idCiclo}/validar`)
        .then(response => response.data)
        .catch(err => {
            throw err.response;
        });
};


const apiCallValidationArea = (payload: IRequestPublishingAreaAction["payload"]) => {
    return api.get(`ciclos/${payload.idCiclo}/validar/${payload.idArea}`)
        .then(response => response.data)
        .catch(err => {
            throw err.response;
        });
};

const apiCallPublishArea = (payload: IRequestValidationAreaAction["payload"]) => {
    return api.get(`ciclos/${payload.idCiclo}/publicar/${payload.idArea}`)
        .then(response => response.data)
        .catch(err => {
            throw err.response;
        });
};

const apiCallPublishVisualization = (payload: IRequestPublishingVisualizationAction["payload"]) => {
    return api.get(`ciclos/${payload.idCiclo}/publicarVisualizacao/${payload.idArea}`)
        .then(response => response.data)
        .catch(err => {
            throw err.response;
        });
};

function* validation(action: IRequestValidationCycleAction) {
    try {
        const data: ValidationType = yield call(apiCallValidation, action.idCiclo);

        yield put({
            type: Types.SUCCESS_CYCLE_VALIDATION,
            payload: {
                data: {
                    status: data.status,
                    validacoes: data.validacoes ?? []
                },
                flagStartValidation: false
            }
        } as ISuccessValidationCycleAction);

    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível carregar a validação do ciclo.';
        yield put({ type: Types.FAILURE_CYCLE_VALIDATION, message });
    };
};

function* validationArea(action: IRequestValidationAreaAction) {
    try {
        const data = yield call(apiCallValidationArea, action.payload);

        yield put({
            type: Types.SUCCESS_CYCLE_VALIDATION_AREA,
            payload:{
                areaTree: data[0],
                idArea: action.payload.idArea
            }
        } as ISuccessValidationAreaAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível validar a área.';
        yield put({ type: Types.FAILURE_CYCLE_VALIDATION_AREA, message });
    };
};

function* publishingArea(action: IRequestPublishingAreaAction) {
    try {
        const data = yield call(apiCallPublishArea, action.payload);

        yield put({
            type: Types.SUCCESS_CYCLE_PUBLISH_AREA,
            payload:{
                areaTree: data,
                idArea: action.payload.idArea
            }
        } as ISuccessPublishingAreaAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível publicar a área.';
        yield put({ type: Types.FAILURE_CYCLE_PUBLISH_AREA, message });
    };
};

function* publishingVisualizationArea(action: IRequestPublishingVisualizationAction) {
    try {
        const data = yield call(apiCallPublishVisualization, action.payload);

        yield put({
            type: Types.SUCCESS_CYCLE_PUBLISH_VISUALIZATION,
            payload:{
                areaTree: data,
                idArea: action.payload.idArea
            }
        } as ISuccessPublishingVisualizationAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível disponibilizar a área.';
        yield put({ type: Types.FAILURE_CYCLE_PUBLISH_VISUALIZATION, message });
    };
};

export default function* cycleValidationRoot() {
    yield all([
        yield takeEvery(Types.REQUEST_CYCLE_VALIDATION, validation),
        yield takeEvery(Types.REQUEST_CYCLE_VALIDATION_AREA, validationArea),
        yield takeEvery(Types.REQUEST_CYCLE_PUBLISH_AREA, publishingArea),
        yield takeEvery(Types.REQUEST_CYCLE_PUBLISH_VISUALIZATION, publishingVisualizationArea),
    ]);
};
