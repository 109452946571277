import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  DetailsListLayoutMode,
  IColumn,
  SelectionMode,
} from "office-ui-fabric-react/lib/DetailsList";
import {
  ShimmeredDetailsList,
  IShimmeredDetailsListProps,
} from "office-ui-fabric-react/lib/ShimmeredDetailsList";

import {
  EmployeeOccurrenceType,
} from "~/store/ducks/employees/types";
import { RootState } from "~/store/ducks";
import { DetailsHeader } from "office-ui-fabric-react";
import { Sticky } from "@fluentui/react";
import { OcorrenciasHistType } from "~/store/ducks/merit/types";

interface IGridOcorrenciasProps
  extends Omit<IShimmeredDetailsListProps, "items"> {
  ocorrencias: OcorrenciasHistType[];
  isLoading: boolean;
}

interface IGridOcorrenciasState {
  columns: IColumn[];
}

class GridOcorrencias extends React.Component<
  IGridOcorrenciasProps,
  IGridOcorrenciasState
> {
  constructor(props: IGridOcorrenciasProps) {
    super(props);

    const columns: IColumn[] = [
      {
        key: "column1",
        name: "Data Afastamento",
        fieldName: "dataInicio",
        minWidth: 100,
        maxWidth: 100,
        isRowHeader: true,
        isResizable: true,
        data: "data",
        isPadded: true,
        onRender: (item: EmployeeOccurrenceType) =>
          moment(item.dataInicio).format("DD/MM/YYYY"),
      },
      {
        key: "column2",
        name: "Data Retorno",
        fieldName: "dataRetorno",
        isRowHeader: true,
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
        onRender: (item: EmployeeOccurrenceType) =>
          item.dataRetorno ? moment(item.dataRetorno).format("DD/MM/YYYY") : "",
      },
      {
        key: "column3",
        name: "Motivo Afastamento",
        fieldName: "motivoAfastamento",
        minWidth: 75,
        isRowHeader: true,
        isResizable: true,
        isPadded: true,
        onRender: (item: EmployeeOccurrenceType) =>
          item.motivoAfastamento?.descMotivoAfastamento ?? "",
      },
    ];

    this.state = {
      columns,
    };
  }

  render() {
    const { columns } = this.state;
    const { ocorrencias, isLoading, ...rest } = this.props;
    return (
      <ShimmeredDetailsList
        {...rest}
        items={ocorrencias ?? []}
        enableShimmer={isLoading}
        selectionMode={SelectionMode.none}
        columns={columns}
        styles={{ root: { paddingTop: 0 } }}
        onRenderDetailsHeader={(detailsHeaderProps) => {
          return (
            <Sticky>
              <DetailsHeader
                {...detailsHeaderProps}
                layoutMode={DetailsListLayoutMode.justified}
              />
            </Sticky>
          );
        }}
      />
    );
  }
}

const mapStateToProps = (state: RootState) => ({
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(GridOcorrencias);
