/**
 * Types
 */
export enum Types {
    REQUEST_LOAD_CHART = "chart/REQUEST_LOAD_CHART",
    SUCCESS_LOAD_CHART = "chart/SUCCESS_LOAD_CHART",
    FAILURE_LOAD_CHART = "chart/FAILURE_LOAD_CHART",

    REQUEST_CHART_LOAD_ID = "chart/REQUEST_CHART_LOAD_ID",
    SUCCESS_CHART_LOAD_ID = "chart/SUCCESS_CHART_LOAD_ID",
    FAILURE_CHART_LOAD_ID = "chart/FAILURE_CHART_LOAD_ID",

    REQUEST_ADD_CHART = "chart/REQUEST_ADD_CHART",
    SUCCESS_ADD_CHART = "chart/SUCCESS_ADD_CHART",
    FAILURE_ADD_CHART = "chart/FAILURE_ADD_CHART",

    REQUEST_EDIT_CHART = "chart/REQUEST_EDIT_CHART",
    SUCCESS_EDIT_CHART = "chart/SUCCESS_EDIT_CHART",
    FAILURE_EDIT_CHART = "chart/FAILURE_EDIT_CHART",

    REQUEST_DEL_CHART = "chart/REQUEST_DEL_CHART",
    SUCCESS_DEL_CHART = "chart/SUCCESS_DEL_CHART",
    FAILURE_DEL_CHART = "chart/FAILURE_DEL_CHART",

    CLEAR_CHART_SELECTED = "chart/CLEAR_CHART_SELECTED"
};

/**
 * Data types
 */

export type ItemSelectedType = {
    item: ChartType;
    loading: boolean;
    success: boolean;
    error: boolean;
}

type ItemParent = {
    nomeLocal: string;
}

export type ChartType = {
    idArea: number | null;
    codArea: string;
    nomeLocal: string;
    tipoLocal: string;
    apelido: string;
    nivel: string;
    idAreaPai: number | null;
    children: ChartType[];
    flagAtiva: boolean;
    // flagMostrarTodos: boolean;
    areaPai: ItemParent | null;
}

interface IChartState {
    readonly data: ChartType[];
    readonly loadingData: boolean;
    readonly loadingAction: boolean;
    readonly success: boolean;
    readonly error: boolean;
    readonly itemSelected: ItemSelectedType;
}

export type DataTypes = IChartState

/**
 * Actions
 */

export interface IRequestChartAction {
    type: typeof Types.REQUEST_LOAD_CHART;
    payload:{
        search: string;
        treeview: boolean;
        filterAtivo?: boolean | null;
    }
}

export interface ISuccessChartAction {
    type: typeof Types.SUCCESS_LOAD_CHART
    payload: ChartType[];
}

export interface IFailureChartAction {
    type: typeof Types.FAILURE_LOAD_CHART;
    message: string;
}

//GET
export interface IRequestChartLoadByIdAction {
    type: typeof Types.REQUEST_CHART_LOAD_ID;
    id: number;
};

export interface ISuccessChartLoadByIdAction {
    type: typeof Types.SUCCESS_CHART_LOAD_ID;
    chart: ChartType;
};

interface IFailureChartLoadByIdAction {
    type: typeof Types.FAILURE_CHART_LOAD_ID;
    message: string;
};

type ChartLoadByIdAction = IRequestChartLoadByIdAction | ISuccessChartLoadByIdAction | IFailureChartLoadByIdAction;


type ChartLoadActions = IRequestChartAction | ISuccessChartAction | IFailureChartAction;

export interface IRequestChartAddAction {
    type: typeof Types.REQUEST_ADD_CHART;
    chart: ChartType;
}

interface ISuccessChartAddAction {
    type: typeof Types.SUCCESS_ADD_CHART;
    chart: ChartType;
}

interface IFailureChartAddAction {
    type: typeof Types.FAILURE_ADD_CHART;
    message: string;
}

type ChartAddActions = IRequestChartAddAction | ISuccessChartAddAction | IFailureChartAddAction;

export interface IRequestChartEditAction {
    type: typeof Types.REQUEST_EDIT_CHART;
    payload:{
        chart: ChartType;
        oldIdAreaPai?: number | null;
    }
}

export interface ISuccessChartEditAction {
    type: typeof Types.SUCCESS_EDIT_CHART;
    payload:{
        chart: ChartType;
        oldIdAreaPai?: number | null;
    }
}

interface IFailureChartEditAction {
    type: typeof Types.FAILURE_EDIT_CHART;
    message: string;
}

type ChartEditActions = IRequestChartEditAction | ISuccessChartEditAction | IFailureChartEditAction;

export interface IRequestChartDelAction {
    type: typeof Types.REQUEST_DEL_CHART;
    id: number;
}

interface ISuccessChartDelAction {
    type: typeof Types.SUCCESS_DEL_CHART;
    id: number;
}

interface IFailureChartDelAction {
    type: typeof Types.FAILURE_DEL_CHART;
    message: string;
}

interface IClearChartAction {
    type: typeof Types.CLEAR_CHART_SELECTED;
}

type ChartDelActions = IRequestChartDelAction | ISuccessChartDelAction | IFailureChartDelAction | IClearChartAction;

export type ChartActionTypes = ChartLoadActions | ChartLoadByIdAction | ChartAddActions | ChartEditActions | ChartDelActions;