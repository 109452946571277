import styled from "styled-components";
import { TextField, Container as MaterialContainer } from "@material-ui/core";

export const InputContainer = styled(MaterialContainer) <{ heightcontainer?: number | string, min_height_container?: number | string }>`
    margin-left: 0px !important;
    padding: 0px !important;
    height: ${({ heightcontainer }) => heightcontainer ? typeof heightcontainer === 'number' ? `${heightcontainer}px` : heightcontainer : '70px'};
    ${({ min_height_container }) => min_height_container ?
        `min-height: ${min_height_container};`
        :
        ''
    }
    max-width: 100% !important;
    
    & .MuiAutocomplete-popupIndicator{
        display: none;        
    }
`;

export const Input = styled(TextField) <{ smaller?: string, hasinnerspin?: string, heightcontainer?: string | number }>`
    width: 100%;

    ${({ smaller, heightcontainer }) => smaller === "small" && `
    label.MuiInputLabel-outlined{
        transform: translate(14px, 10px) scale(1);
    }
    label.MuiInputLabel-outlined.MuiInputLabel-shrink{
        transform: translate(14px, -6px) scale(0.75);
    }
    div.MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd{
        padding: 0px;
        height: ${heightcontainer ? heightcontainer : 32}px;
        input.MuiInputBase-input.MuiOutlinedInput-input.MuiAutocomplete-input.MuiAutocomplete-inputFocused.MuiInputBase-inputAdornedEnd.MuiOutlinedInput-inputAdornedEnd{
            padding-left: 15px;
            padding-top: 8px;
            font-size: 14px;
        }
    }
  `}


  ${({ hasinnerspin }) => hasinnerspin === 'true' ? `
     input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
        -webkit-appearance: textfield;
        margin-right: 5px;
        background: #FFF !important;
        color: red;
        height: 30px;
        border-width: 0px;
        cursor: pointer;
    }
  ` :
        ''}

`;
