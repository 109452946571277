import { Types, DataTypes, FilesActionTypes } from "./types";
import customToast from "~/components/Toast/index";

/**
 * Reducers
 */
const INITIAL_STATE: DataTypes = {
  loading: false,
  error: false,
  success: false,
};

/**
 * Actions
 */

export const Creators = {
  download: (key: string): FilesActionTypes => {
    return {
      type: Types.REQUEST_FILES_LOAD,
      key,
    };
  },
};

export default function filesReducer(
  state = INITIAL_STATE,
  action: FilesActionTypes
): DataTypes {
  switch (action.type) {
    case Types.REQUEST_FILES_LOAD:
      return {
        ...state,
        loading: true,
        error: false,
        success: false,
      };
    case Types.SUCCESS_FILES_LOAD:
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
      };
    case Types.FAILURE_FILES_LOAD:
      customToast.error(action.message);
      return {
        ...state,
        loading: false,
        error: true,
        success: false,
      };

    default:
      return state;
  }
}
