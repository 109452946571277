import { Types, DataTypes, ObjectivesActionTypes, ObjectivesType } from "./types";
import customToast from "~/components/Toast/index";

/**
 * Reducers
 */
const INITIAL_STATE: DataTypes = {
    data: [],
    error: false,
    errorLoading: false,
    loadingData: false,
    loading: false,
    success: false,
    itemSelected: {
        item: {
            idObjetivo: null,
            codObjetivo: "",
            descObjetivo: "",
            descIngles: "",
            nivel: "",
            unidade: "",
            abrangencia: "",
            descricaoMecanica: "",
            idObjetivoPai: null,
            children: [],
            flagMostrarTodos: false,
            objPai: {
                descObjetivo: ""
            },
            mecanicaCalculo: "",
            flagAtivo: true
        },
        error: false,
        success: false,
        loading: false
    },
};

/**
 * Actions
 */

export const Creators = {
    getObjectives: (search?: string, treeview: boolean = true, filterAtivo?: boolean, filterPai?: number): ObjectivesActionTypes => {
        search = search ? search : "";
        return {
            type: Types.REQUEST_OBJECTIVES_LOAD,
            payload: {
                search,
                treeview,
                filterAtivo,
                filterPai
            }
        }
    },

    getObjectiveById: (id: number): ObjectivesActionTypes => ({
        type: Types.REQUEST_OBJECTIVES_LOAD_ID,
        id
    }),

    addObjectives: (objective: ObjectivesType | Partial<ObjectivesType>, showInItemSelected?: boolean): ObjectivesActionTypes => ({
        type: Types.REQUEST_OBJECTIVES_ADD,
        payload: {
            objective,
            showInItemSelected
        }
    }),

    editObjective: (objective: ObjectivesType): ObjectivesActionTypes => ({
        type: Types.REQUEST_OBJECTIVES_EDIT,
        objective
    }),

    delObjective: (id: number): ObjectivesActionTypes => ({
        type: Types.REQUEST_OBJECTIVES_DELETE,
        id
    }),

    resetObjectives: (): ObjectivesActionTypes => ({
        type: Types.RESET_OBJETIVES
    })
};

export default function objectivesReducer(state = INITIAL_STATE, action: ObjectivesActionTypes): DataTypes {

    switch (action.type) {
        case Types.REQUEST_OBJECTIVES_LOAD:
            return { ...state, loadingData: true, error: false, success: false };
        case Types.SUCCESS_OBJECTIVES_LOAD:
            return { ...state, data: action.payload, loadingData: false, error: false, success: true };
        case Types.FAILURE_OBJECTIVES_LOAD:
            customToast.error(action.message);
            return { ...state, data: [], loadingData: false, error: true, success: false };

        case Types.REQUEST_OBJECTIVES_LOAD_ID:
            return { ...state, errorLoading: false, itemSelected: { ...state.itemSelected, loading: true, success: false, error: false } };
        case Types.SUCCESS_OBJECTIVES_LOAD_ID:
            return { ...state, errorLoading: false, itemSelected: { loading: false, success: true, error: false, item: action.objective } };
        case Types.FAILURE_OBJECTIVES_LOAD_ID:
            customToast.error(action.message);
            return { ...state, errorLoading: true, itemSelected: { ...state.itemSelected, loading: false, success: false, error: true } };

        case Types.REQUEST_OBJECTIVES_ADD:
            return { ...state, loading: true, error: false, success: false };
        case Types.SUCCESS_OBJECTIVES_ADD:
            customToast.success("Objetivo cadastrado com sucesso");
            return {
                ...state,
                loading: false,
                error: false,
                success: true,
                data: addItem(action.payload.objective, state.data),
                itemSelected: action.payload.showInItemSelected ? { ...state.itemSelected, item: action.payload.objective } : state.itemSelected
            };
        case Types.FAILURE_OBJECTIVES_ADD:
            customToast.error(action.message);
            return { ...state, loading: false, error: true, success: false };

        case Types.REQUEST_OBJECTIVES_EDIT:
            return { ...state, loading: true, error: false, success: false };
        case Types.SUCCESS_OBJECTIVES_EDIT:
            customToast.success("Objetivo editado com sucesso");
            return { ...state, loading: false, error: false, success: true, data: editItem(action.objective, state.data) };
        case Types.FAILURE_OBJECTIVES_EDIT:
            customToast.error(action.message);
            return { ...state, loading: false, error: true, success: false };

        case Types.REQUEST_OBJECTIVES_DELETE:
            return { ...state, loadingData: true, error: false, success: false };
        case Types.SUCCESS_OBJECTIVES_DELETE:
            customToast.success("Objetivo removido com sucesso");
            return { ...state, loadingData: false, error: false, data: removeItem(action.id, state.data), success: true };
        case Types.FAILURE_OBJECTIVES_DELETE:
            customToast.error(action.message);
            return { ...state, loadingData: false, error: true, success: false };

        case Types.RESET_OBJETIVES:
            return { ...state, data: [] };
        default:
            return state;
    };
};

const addItem = (objective: ObjectivesType, data: ObjectivesType[]): ObjectivesType[] => {
    if (objective.idObjetivoPai !== 0 && objective.idObjetivoPai !== null) {
        recursiveAdd(objective, data);
    } else {
        data.push(objective);
    };
    return data;
};

const recursiveAdd = (objective: ObjectivesType, data: ObjectivesType[]): ObjectivesType[] => {
    data.forEach(item => {
        if (item.idObjetivo === objective.idObjetivoPai) {
            item.children.push(objective);
        } else {
            if (item.children) {
                recursiveAdd(objective, item.children);
            };
        };
    });

    return data;
};

const editItem = (objective: ObjectivesType, data: ObjectivesType[]): ObjectivesType[] => {
    return recursiveEdit(objective, data);
};

const recursiveEdit = (objective: ObjectivesType, data: ObjectivesType[]): ObjectivesType[] => {
    return data.map(item => {
        if (item.idObjetivo === objective.idObjetivo) {
            return { ...objective, children: item.children };
        } else {
            if (item.children.length > 0) {
                return { ...item, children: recursiveEdit(objective, item.children) };
            } else {
                return item;
            };
        };
    });
};

const removeItem = (id: number, data: ObjectivesType[]): ObjectivesType[] => {
    return recusiveRemove(id, data);
};

const recusiveRemove = (id: number, data: ObjectivesType[]): ObjectivesType[] => {
    data.forEach((item, i) => {
        if (item.idObjetivo === id) {
            data.splice(i, 1);
            return;
        } else {
            if (item.children.length > 0) {
                recusiveRemove(id, item.children);
            };
        };
    });
    return data;
}