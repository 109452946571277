import api from "~/services/api";
import { takeEvery, put, call, all } from "redux-saga/effects";
import {
  Types,
  IRequestLoadProfileAction,
  IRequestProfileMetasAction,
  IRequestAcceptMetaAction,
  IRequestGetProjectsAction,
  IRequestAddProjectAction,
  IRequestEditProjectAction,
  IRequestDelProjectAction,
  IRequestGetProjectsByIdAction,
  IRequestAprovacaoAction,
  IRequestProfilePerformanceAction,
  IRequestProfilePdiAction,
  ISuccessLoadProfileAction,
  ISuccessGetProjectsAction,
  ISuccessProfileMetasAction,
  ISuccessAcceptMetaAction,
  ISuccessAddProjectAction,
  ISuccessEditProjectAction,
  ISuccessDelProjectAction,
  ISuccessGetProjectsByIdAction,
  ISuccessAprovacaoAction,
  ISuccessProfilePerformanceAction,
  ISuccessProfilePdiAction,
  ProfilePerformanceType,
  ProfilePdiType,
  IRequestProfileAddPdiAction,
  ISuccessProfileAddPdiAction,
  IRequestProfileDelPdiAction,
  ISuccessProfileDelPdiAction,
  IRequestProfileEditPdiAction,
  ISuccessProfileEditPdiAction,
  IRequestProfileLoadMedidasAction,
  ISuccessProfileLoadMedidasAction,
  ProfileMedidasType,
  IRequestProfileFeedbacksAction,
  ProfileFeedbacksType,
  ISuccessProfileFeedbacksAction,
  IRequestProfileNewFeedbacksAction,
  NewFeedbackDataType,
  ISuccessProfileNewFeedbacksAction,
  IRequestProfileAddNewFeedbacksAction,
  ISuccessProfileAddNewFeedbacksAction,
  IFeedbackDataType,
  ISuccessProfileGetNewFeedbackByIdAction,
  IRequestProfileGetNewFeedbackByIdAction,
} from "../ducks/profile/types";
import moment from "moment";

const apiLoadProfile = (id?: number) => {
  return api
    .get(`perfil${id ? `/${id}` : ""}`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallGetMetas = (payload: IRequestProfileMetasAction["payload"]) => {
  const { idFuncionario, idCicloColaborador, idCiclo } = payload;

  return api
    .get(
      `perfil${idFuncionario ? `/${idFuncionario}` : ""}/metas${idCiclo
        ? `/${idCiclo}${idCicloColaborador ? `/${idCicloColaborador}` : ""}`
        : ""
      }`
    )
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallGetProjects = (payload: IRequestGetProjectsAction["payload"]) => {
  const { idFuncionario, idCicloColaborador, idCiclo } = payload;

  return api
    .get(
      `perfil${idFuncionario ? `/${idFuncionario}` : ""
      }/metas/${idCiclo}/${idCicloColaborador}/projetos`
    )
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallGetProjectById = (
  payload: IRequestGetProjectsByIdAction["payload"]
) => {
  const {
    idFuncionario,
    idCicloColaborador,
    idCicloColaboradorProjeto,
  } = payload;
  return api
    .get(
      `perfil${idFuncionario ? `/${idFuncionario}` : ""
      }/metas/${idCicloColaborador}/projetos/${idCicloColaboradorProjeto}`
    )
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallAddProject = (payload: IRequestAddProjectAction["payload"]) => {
  const { idFuncionario } = payload.project;
  return api
    .post(
      `perfil${idFuncionario ? `/${idFuncionario}` : ""}/metas/${payload.idCicloColaborador
      }/projetos`,
      {
        ...payload.project,
      }
    )
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallEditProject = (payload: IRequestEditProjectAction["payload"]) => {
  const { idFuncionario, idCicloColaboradorProjeto } = payload.project;
  return api
    .put(
      `perfil${idFuncionario ? `/${idFuncionario}` : ""}/metas/${payload.idCicloColaborador
      }/projetos/${idCicloColaboradorProjeto}`,
      {
        ...payload.project,
      }
    )
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallDelProject = (payload: IRequestDelProjectAction["payload"]) => {
  const {
    idFuncionario,
    idCicloColaborador,
    idCicloColaboradorProjeto,
  } = payload;
  return api
    .delete(
      `perfil${idFuncionario ? `/${idFuncionario}` : ""
      }/metas/${idCicloColaborador}/projetos/${idCicloColaboradorProjeto}`
    )
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallReqAprovacao = (payload: IRequestAprovacaoAction["payload"]) => {
  const { idFuncionario, idCiclo, idCicloColaborador } = payload;
  return api
    .get(
      `perfil${idFuncionario ? `/${idFuncionario}` : ""
      }/metas/${idCiclo}/${idCicloColaborador}/SolicitarAprovacao`
    )
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallLoadPerformance = (
  idFuncionario: IRequestProfilePerformanceAction["idFuncionario"]
) => {
  return api
    .get(`perfil${idFuncionario ? `/${idFuncionario}` : ""}/desempenhos`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallLoadPdi = (payload: IRequestProfilePdiAction["payload"]) => {
  return api
    .get(
      `perfil${payload.idFuncionario ? `/${payload.idFuncionario}` : ""}/pdi/${payload.ano
      }`
    )
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};


const apiCallAddPdi = (payload: IRequestProfileAddPdiAction["payload"]) => {
  return api
    .post(
      `perfil${payload.idFuncionario ? `/${payload.idFuncionario}` : ""}/pdi/${payload.ano
      }/acaoPDI`,
      {
        ...payload.pdiAction,
      }
    )
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallEditPdi = (payload: IRequestProfileEditPdiAction["payload"]) => {
  delete payload.pdiAction.idAcaoPDI;
  return api
    .put(
      `perfil${payload.idFuncionario ? `/${payload.idFuncionario}` : ""}/pdi/${payload.ano
      }/acaoPDI/${payload.pdiAction.idPDIAcao}`,
      {
        ...payload.pdiAction,
      }
    )
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallDelPdi = (payload: IRequestProfileDelPdiAction["payload"]) => {
  return api
    .delete(
      `perfil${payload.idFuncionario ? `/${payload.idFuncionario}` : ""}/pdi/${payload.ano
      }/acaoPDI/${payload.idPDIAcao}`
    )
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallLoadMedidas = (idFuncionario?: number | string) => {
  return api
    .get(`perfil${idFuncionario ? `/${idFuncionario}` : ""}/medida`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallLoadFeedbacks = (idFuncionario: number) => {
  return api
    .get(
      `perfil${idFuncionario ? `/${idFuncionario}` : ""}/feedback`
    )
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallLoadNewFeedbacks = (idFuncionario: number) => {
  return api
    .get(
      `perfil${idFuncionario ? `/${idFuncionario}` : ""}/feedback/add`
    )
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallAddNewFeedbacks = (payload: IRequestProfileAddNewFeedbacksAction["payload"]) => {
  return api
    .post(
      `perfil${payload.idFuncionario ? `/${payload.idFuncionario}` : ""}/feedback`,
      {
        ...payload.data,
        dataOcorrencia:  moment(payload.data.dataOcorrencia).format("YYYY-MM-DD"),
      }
    )
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallGetNewFeedbackById = (payload: IRequestProfileGetNewFeedbackByIdAction["payload"]) => {
  return api
    .get(
      `perfil${payload.idFuncionario ? `/${payload.idFuncionario}` : ""}/feedback/${payload.idFeedback}`
    )
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

function* loadProfileData(action: IRequestLoadProfileAction) {
  try {
    const profileInfo = yield call(apiLoadProfile, action.id);
    yield put({
      type: Types.SUCCESS_PROFILE_LOAD,
      payload: profileInfo,
    } as ISuccessLoadProfileAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível as informações do perfil.";
    yield put({ type: Types.FAILURE_PROFILE_LOAD, message });
  }
}

function* loadProfileMetas(action: IRequestProfileMetasAction) {
  try {
    const item = yield call(apiCallGetMetas, action.payload);
    yield put({
      type: Types.SUCCESS_PROFILE_METAS_LOAD,
      payload: item,
    } as ISuccessProfileMetasAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível carregar as metas.";
    yield put({ type: Types.FAILURE_PROFILE_METAS_LOAD, message });
  }
}

function* acceptMeta(action: IRequestAcceptMetaAction) {
  try {
    // const item = yield call(apiCallGetMetas, action.id);
    yield put({
      type: Types.SUCCESS_ACCEPT_METAS,
      payload: action.payload,
    } as ISuccessAcceptMetaAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível aceitar ou recusar a meta.";
    yield put({ type: Types.FAILURE_ACCEPT_METAS, message });
  }
}

function* getProjects(action: IRequestGetProjectsAction) {
  try {
    const projects = yield call(apiCallGetProjects, action.payload);
    yield put({
      type: Types.SUCCESS_GET_PROJECTS,
      payload: projects,
    } as ISuccessGetProjectsAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível adicionar iniciativa chave.";
    yield put({ type: Types.FAILURE_ACCEPT_METAS, message });
  }
}

function* getProjectsById(action: IRequestGetProjectsByIdAction) {
  try {
    const project = yield call(apiCallGetProjectById, action.payload);
    yield put({
      type: Types.SUCCESS_GET_PROJECT_BY_ID,
      payload: project,
    } as ISuccessGetProjectsByIdAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível carregar informações da iniciativa chave.";
    yield put({ type: Types.FAILURE_GET_PROJECT_BY_ID, message });
  }
}

function* addProject(action: IRequestAddProjectAction) {
  try {
    const idCicloMetaProjeto = yield call(apiCallAddProject, action.payload);
    yield put({
      type: Types.SUCCESS_ADD_PROJECT,
      payload: {
        ...action.payload.project,
        ...idCicloMetaProjeto,
      },
    } as ISuccessAddProjectAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível adicionar iniciativa chave.";
    yield put({ type: Types.FAILURE_ADD_PROJECT, message });
  }
}

function* editProject(action: IRequestEditProjectAction) {
  try {
    yield call(apiCallEditProject, action.payload);
    yield put({
      type: Types.SUCCESS_EDIT_PROJECT,
      payload: action.payload.project,
    } as ISuccessEditProjectAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível editar a iniciativa chave.";
    yield put({ type: Types.FAILURE_EDIT_PROJECT, message });
  }
}

function* delProject(action: IRequestGetProjectsByIdAction) {
  try {
    yield call(apiCallDelProject, action.payload);

    yield put({
      type: Types.SUCCESS_DEL_PROJECT,
      idCicloColaboradorProjeto: action.payload.idCicloColaboradorProjeto,
    } as ISuccessDelProjectAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível excluir a iniciativa chave.";
    yield put({ type: Types.FAILURE_DEL_PROJECT, message });
  }
}

function* reqAprovacao(action: IRequestAprovacaoAction) {
  try {
    yield call(apiCallReqAprovacao, action.payload);

    yield put({
      type: Types.SUCCESS_APROVACAO,
    } as ISuccessAprovacaoAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível aprovar a solicitação.";
    yield put({ type: Types.FAILURE_APROVACAO, message });
  }
}

function* loadPerformanceProfile(action: IRequestProfilePerformanceAction) {
  try {
    const data: ProfilePerformanceType[] = yield call(
      apiCallLoadPerformance,
      action.idFuncionario
    );
    yield put({
      type: Types.SUCCESS_LOAD_PERFORMANCE,
      payload: data,
    } as ISuccessProfilePerformanceAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível carregar o desempenho.";
    yield put({ type: Types.FAILURE_LOAD_PERFORMANCE, message });
  }
}

function* loadPdiProfile(action: IRequestProfilePdiAction) {
  try {
    const data: ProfilePdiType = yield call(apiCallLoadPdi, action.payload);
    yield put({
      type: Types.SUCCESS_PROFILE_PDI_LOAD,
      payload: data,
    } as ISuccessProfilePdiAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível carregar o pdi.";
    yield put({ type: Types.FAILURE_PROFILE_PDI_LOAD, message });
  }
}

function* addPdiProfile(action: IRequestProfileAddPdiAction) {
  try {
    const idPDIAcao: { idPDIAcao: number } = yield call(
      apiCallAddPdi,
      action.payload
    );
    yield put({
      type: Types.SUCCESS_PROFILE_PDI_ADD,
      payload: {
        ...action.payload.pdiAction,
        ...idPDIAcao,
      },
    } as ISuccessProfileAddPdiAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível adicionar o pdi.";
    yield put({ type: Types.FAILURE_PROFILE_PDI_ADD, message });
  }
}

function* editPdiProfile(action: IRequestProfileEditPdiAction) {
  try {
    const pdiResponse: { idPDIAcao: number; idPDI: number } = yield call(
      apiCallEditPdi,
      action.payload
    );
    yield put({
      type: Types.SUCCESS_PROFILE_PDI_EDIT,
      payload: {
        ...pdiResponse,
        ...action.payload.pdiAction,
      },
    } as ISuccessProfileEditPdiAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível editar o pdi.";
    yield put({ type: Types.FAILURE_PROFILE_PDI_EDIT, message });
  }
}

function* delPdiProfile(action: IRequestProfileDelPdiAction) {
  try {
    yield call(apiCallDelPdi, action.payload);
    yield put({
      type: Types.SUCCESS_PROFILE_PDI_DEL,
      idPDIAcao: action.payload.idPDIAcao,
    } as ISuccessProfileDelPdiAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível excluír o pdi.";
    yield put({ type: Types.FAILURE_PROFILE_PDI_DEL, message });
  }
}

function* loadMedidasProfile(action: IRequestProfileLoadMedidasAction) {
  try {
    const medidas: ProfileMedidasType[] = yield call(
      apiCallLoadMedidas,
      action.idFuncionario
    );
    yield put({
      type: Types.SUCCESS_PROFILE_MEDIDAS_LOAD,
      payload: medidas,
    } as ISuccessProfileLoadMedidasAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível carregar as medidas";
    yield put({ type: Types.FAILURE_PROFILE_MEDIDAS_LOAD, message });
  }
}


function* loadFeedbacksProfile(action: IRequestProfileFeedbacksAction) {
  try {
    const data: ProfileFeedbacksType = yield call(apiCallLoadFeedbacks, action.idFuncionario);
    yield put({
      type: Types.SUCCESS_PROFILE_FEEDBACKS_LOAD,
      payload: data,
    } as ISuccessProfileFeedbacksAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível carregar os feedbacks.";
    yield put({ type: Types.FAILURE_PROFILE_FEEDBACKS_LOAD, message });
  }
}

function* loadNewFeedbacksProfile(action: IRequestProfileNewFeedbacksAction) {
  try {
    const data: NewFeedbackDataType = yield call(apiCallLoadNewFeedbacks, action.idFuncionario);
    yield put({
      type: Types.SUCCESS_PROFILE_NEWFEEDBACKS_LOAD,
      payload: data,
    } as ISuccessProfileNewFeedbacksAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível carregar os dados do feedback.";
    yield put({ type: Types.FAILURE_PROFILE_NEWFEEDBACKS_LOAD, message });
  }
}

function* addNewFeedbacksProfile(action: IRequestProfileAddNewFeedbacksAction) {
  try {
    yield call(apiCallAddNewFeedbacks, action.payload);
    yield put({
      type: Types.SUCCESS_PROFILE_NEWFEEDBACKS_ADD,
    } as ISuccessProfileAddNewFeedbacksAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível salvar os dados do feedback.";
    yield put({ type: Types.FAILURE_PROFILE_NEWFEEDBACKS_ADD, message });
  }
}

function* getFeedbackProfileById(action: IRequestProfileGetNewFeedbackByIdAction) {
  try {
    const data: IFeedbackDataType = yield call(apiCallGetNewFeedbackById, action.payload);
    console.log("data")
    console.log(data)
    yield put({
      type: Types.SUCCESS_PROFILE_NEWFEEDBACK_LOAD_BY_ID,
      payload: data
    } as ISuccessProfileGetNewFeedbackByIdAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível carregar os dados do feedback.";
    yield put({ type: Types.FAILURE_PROFILE_NEWFEEDBACK_LOAD_BY_ID, message });
  }
}

export default function* positionsRoot() {
  yield all([
    yield takeEvery(Types.REQUEST_PROFILE_LOAD, loadProfileData),
    yield takeEvery(Types.REQUEST_PROFILE_METAS_LOAD, loadProfileMetas),
    yield takeEvery(Types.REQUEST_ACCEPT_META, acceptMeta),
    yield takeEvery(Types.REQUEST_GET_PROJECTS, getProjects),
    yield takeEvery(Types.REQUEST_GET_PROJECT_BY_ID, getProjectsById),
    yield takeEvery(Types.REQUEST_ADD_PROJECT, addProject),
    yield takeEvery(Types.REQUEST_EDIT_PROJECT, editProject),
    yield takeEvery(Types.REQUEST_DEL_PROJECT, delProject),
    yield takeEvery(Types.REQUEST_APROVACAO, reqAprovacao),
    yield takeEvery(Types.REQUEST_LOAD_PERFORMANCE, loadPerformanceProfile),
    yield takeEvery(Types.REQUEST_PROFILE_PDI_LOAD, loadPdiProfile),
    yield takeEvery(Types.REQUEST_PROFILE_PDI_ADD, addPdiProfile),
    yield takeEvery(Types.REQUEST_PROFILE_PDI_EDIT, editPdiProfile),
    yield takeEvery(Types.REQUEST_PROFILE_PDI_DEL, delPdiProfile),
    yield takeEvery(Types.REQUEST_PROFILE_MEDIDAS_LOAD, loadMedidasProfile),
    yield takeEvery(Types.REQUEST_PROFILE_FEEDBACKS_LOAD, loadFeedbacksProfile),
    yield takeEvery(Types.REQUEST_PROFILE_NEWFEEDBACKS_LOAD, loadNewFeedbacksProfile),
    yield takeEvery(Types.REQUEST_PROFILE_NEWFEEDBACKS_ADD, addNewFeedbacksProfile),
    yield takeEvery(Types.REQUEST_PROFILE_NEWFEEDBACK_LOAD_BY_ID, getFeedbackProfileById),
  ]);
}
