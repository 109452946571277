import { EmployeeType } from "../../employees/types";
import { WorklevelType } from "../../worklevel/types";
import { PesquisaFuncType } from "../types";

/**
 * Types
 */
export enum Types {
  //GET
  REQUEST_CYCLE_GROUPS_LOAD = "cycle_groups/REQUEST_CYCLE_GROUPS_LOAD",
  SUCCESS_CYCLE_GROUPS_LOAD = "cycle_groups/SUCCESS_CYCLE_GROUPS_LOAD",
  FAILURE_CYCLE_GROUPS_LOAD = "cycle_groups/FAILURE_CYCLE_GROUPS_LOAD",
  //GET BY
  REQUEST_CYCLE_GROUP_LOAD_BY_ID = "cycle_groups/REQUEST_CYCLE_GROUPS_LOAD_BY_ID",
  SUCCESS_CYCLE_GROUP_LOAD_BY_ID = "cycle_groups/SUCCESS_CYCLE_GROUPS_LOAD_BY_ID",
  FAILURE_CYCLE_GROUP_LOAD_BY_ID = "cycle_groups/FAILURE_CYCLE_GROUPS_LOAD_BY_ID",
  //POST
  REQUEST_CYCLE_GROUPS_ADD = "cycle_groups/REQUEST_CYCLE_GROUPS_ADD",
  SUCCESS_CYCLE_GROUPS_ADD = "cycle_groups/SUCCESS_CYCLE_GROUPS_ADD",
  FAILURE_CYCLE_GROUPS_ADD = "cycle_groups/FAILURE_CYCLE_GROUPS_ADD",
  //PUT
  REQUEST_CYCLE_GROUPS_EDIT = "cycle_groups/REQUEST_CYCLE_GROUPS_EDIT",
  SUCCESS_CYCLE_GROUPS_EDIT = "cycle_groups/SUCCESS_CYCLE_GROUPS_EDIT",
  FAILURE_CYCLE_GROUPS_EDIT = "cycle_groups/FAILURE_CYCLE_GROUPS_EDIT",
  //DELETE
  REQUEST_CYCLE_GROUPS_DEL = "cycle_groups/REQUEST_CYCLE_GROUPS_DEL",
  SUCCESS_CYCLE_GROUPS_DEL = "cycle_groups/SUCCESS_CYCLE_GROUPS_DEL",
  FAILURE_CYCLE_GROUPS_DEL = "cycle_groups/FAILURE_CYCLE_GROUPS_DEL",
  //ADD PARTICIPANTES
  REQUEST_CYCLE_GROUPS_ADD_PARTICIPANTS = "cycle_groups/REQUEST_CYCLE_GROUPS_ADD_PARTICIPANTS",
  SUCCESS_CYCLE_GROUPS_ADD_PARTICIPANTS = "cycle_groups/SUCCESS_CYCLE_GROUPS_ADD_PARTICIPANTS",
  FAILURE_CYCLE_GROUPS_ADD_PARTICIPANTS = "cycle_groups/FAILURE_CYCLE_GROUPS_ADD_PARTICIPANTS",
  //DEL PARTICIPANTES
  REQUEST_CYCLE_GROUPS_DEL_PARTICIPANTS = "cycle_groups/REQUEST_CYCLE_GROUPS_DEL_PARTICIPANTS",
  SUCCESS_CYCLE_GROUPS_DEL_PARTICIPANTS = "cycle_groups/SUCCESS_CYCLE_GROUPS_DEL_PARTICIPANTS",
  FAILURE_CYCLE_GROUPS_DEL_PARTICIPANTS = "cycle_groups/FAILURE_CYCLE_GROUPS_DEL_PARTICIPANTS",
  //ADD GUEST
  REQUEST_CYCLE_GROUPS_ADD_GUEST = "cycle_groups/REQUEST_CYCLE_GROUPS_ADD_GUEST",
  SUCCESS_CYCLE_GROUPS_ADD_GUEST = "cycle_groups/SUCCESS_CYCLE_GROUPS_ADD_GUEST",
  FAILURE_CYCLE_GROUPS_ADD_GUEST = "cycle_groups/FAILURE_CYCLE_GROUPS_ADD_GUEST",
  //DEL GUEST
  REQUEST_CYCLE_GROUPS_DEL_GUEST = "cycle_groups/REQUEST_CYCLE_GROUPS_DEL_GUEST",
  SUCCESS_CYCLE_GROUPS_DEL_GUEST = "cycle_groups/SUCCESS_CYCLE_GROUPS_DEL_GUEST",
  FAILURE_CYCLE_GROUPS_DEL_GUEST = "cycle_groups/FAILURE_CYCLE_GROUPS_DEL_GUEST",
};

/**
 * Data types
 */

export type CycleGroupType = {
  idCicloGrupo: number | null;
  idCiclo: number | null;
  nomeGrupo: string;
  idFuncionarioLider: number | null;
  idFuncionarioRH: number | null;
  idFuncionarioHRBP: number | null;
  minutos: number | null;
  flagIgnorarCurva: boolean;
  lider: {
    nomeCompleto: string;
  };
  rh: {
    nomeCompleto: string;
  } | null;
  hrbp: {
    nomeCompleto: string;
  };
  convidados: GuestType[];
  avaliados: WorklevelType[] | ParticipantsType[];
  dataHoraPrevista: Date | null;
};

export type ParticipantsType = {
  idCicloGrupoParticipante?: number | null;
  tipoFuncionario?: string;
  tipoParticipante?: string;
  idFuncionario: number;
  idArea: number;
  tipoLocal: string;
  idFuncionarioChefe: number;
  idCargo: number;
  idWorklevel: number;
  nomeCompleto: string;
}

type GuestType = {
  idCicloGrupoConvidado?: number | null;
  idFuncionario: number;
  funcionario?: {
    nomeCompleto: string;
  };
}

type ItemSelected = {
  item: CycleGroupType | null;
  loading: boolean;
  error: boolean;
  success: boolean;
}

interface ICycleGroupState {
  readonly data: CycleGroupType[];
  readonly itemSelected: ItemSelected;
  readonly loadingData: boolean;
  readonly loadingAction: boolean;
  readonly error: boolean;
  readonly success: boolean;
};

export type DataTypes = ICycleGroupState;

/**
 * Actions
 */

//GET
export interface IRequestCycleGroupLoadAction {
  type: typeof Types.REQUEST_CYCLE_GROUPS_LOAD;
  idCiclo: number;
};

export interface ISuccessCycleGroupLoadAction {
  type: typeof Types.SUCCESS_CYCLE_GROUPS_LOAD;
  payload: CycleGroupType[];
};

interface IFailureCycleGroupLoadAction {
  type: typeof Types.FAILURE_CYCLE_GROUPS_LOAD;
  message: string;
};

type CycleGroupLoadAction = IRequestCycleGroupLoadAction | ISuccessCycleGroupLoadAction | IFailureCycleGroupLoadAction;

//POST
export interface IRequestCycleGroupAddAction {
  type: typeof Types.REQUEST_CYCLE_GROUPS_ADD;
  payload: {
    idCiclo: number;
    group: CycleGroupType;
  }
};

export interface ISuccessCycleGroupAddAction {
  type: typeof Types.SUCCESS_CYCLE_GROUPS_ADD;
  payload: CycleGroupType;
};

interface IFailureCycleGroupAddAction {
  type: typeof Types.FAILURE_CYCLE_GROUPS_ADD;
  message: string;
};

type CycleGroupAddAction = IRequestCycleGroupAddAction | ISuccessCycleGroupAddAction | IFailureCycleGroupAddAction;

// GET BY ID
export interface IRequestCycleGroupLoadByIdAction {
  type: typeof Types.REQUEST_CYCLE_GROUP_LOAD_BY_ID;
  payload: {
    idCiclo: number;
    idCicloGrupo: number;
  }
};

export interface ISuccessCycleGroupLoadByIdAction {
  type: typeof Types.SUCCESS_CYCLE_GROUP_LOAD_BY_ID;
  payload: CycleGroupType;
};

interface IFailureCycleGroupLoadByIdAction {
  type: typeof Types.FAILURE_CYCLE_GROUP_LOAD_BY_ID;
  message: string;
};

type CycleGroupLoadByIdAction = IRequestCycleGroupLoadByIdAction | ISuccessCycleGroupLoadByIdAction | IFailureCycleGroupLoadByIdAction;

// GET BY ID
export interface IRequestCycleGroupEditAction {
  type: typeof Types.REQUEST_CYCLE_GROUPS_EDIT;
  payload: {
    idCiclo: number;
    group: CycleGroupType
  }
};

export interface ISuccessCycleGroupEditAction {
  type: typeof Types.SUCCESS_CYCLE_GROUPS_EDIT;
  payload: CycleGroupType;
};

interface IFailureCycleGroupEditAction {
  type: typeof Types.FAILURE_CYCLE_GROUPS_EDIT;
  message: string;
};

type CycleGroupEditAction = IRequestCycleGroupEditAction | ISuccessCycleGroupEditAction | IFailureCycleGroupEditAction;

// GET BY ID
export interface IRequestCycleGroupDelAction {
  type: typeof Types.REQUEST_CYCLE_GROUPS_DEL;
  payload: {
    idCiclo: number;
    idCicloGrupo: number;
  }
};

export interface ISuccessCycleGroupDelAction {
  type: typeof Types.SUCCESS_CYCLE_GROUPS_DEL;
  idCicloGrupo: number;
};

interface IFailureCycleGroupDelAction {
  type: typeof Types.FAILURE_CYCLE_GROUPS_DEL;
  message: string;
};

type CycleGroupDelAction = IRequestCycleGroupDelAction | ISuccessCycleGroupDelAction | IFailureCycleGroupDelAction;

// ADD PARTICIPANTS
export interface IRequestCycleGroupAddParticipantsAction {
  type: typeof Types.REQUEST_CYCLE_GROUPS_ADD_PARTICIPANTS;
  payload: {
    idCiclo: number;
    idCicloGrupo: number;
    participantes: PesquisaFuncType[];
  }
};

export interface ISuccessCycleGroupAddParticipantsAction {
  type: typeof Types.SUCCESS_CYCLE_GROUPS_ADD_PARTICIPANTS;
  payload: ParticipantsType[];
};

interface IFailureCycleGroupAddParticipantsAction {
  type: typeof Types.FAILURE_CYCLE_GROUPS_ADD_PARTICIPANTS;
  message: string;
};

type CycleGroupAddParticipantsAction = IRequestCycleGroupAddParticipantsAction | ISuccessCycleGroupAddParticipantsAction | IFailureCycleGroupAddParticipantsAction;

// DEL PARTICIPANT
export interface IRequestCycleGroupDelParticipantAction {
  type: typeof Types.REQUEST_CYCLE_GROUPS_DEL_PARTICIPANTS;
  payload: {
    idCiclo: number;
    idCicloGrupo: number;
    idCicloGrupoParticipante: number;
    tipoFuncionario: string;
  }
};

export interface ISuccessCycleGroupDelParticipantAction {
  type: typeof Types.SUCCESS_CYCLE_GROUPS_DEL_PARTICIPANTS;
  payload: {
    idCicloGrupoParticipante: number;
    tipoFuncionario: string;
  }
};

interface IFailureCycleGroupDelParticipantAction {
  type: typeof Types.FAILURE_CYCLE_GROUPS_DEL_PARTICIPANTS;
  message: string;
};

type CycleGroupDelParticipantAction = IRequestCycleGroupDelParticipantAction | ISuccessCycleGroupDelParticipantAction | IFailureCycleGroupDelParticipantAction;

// ADD GUEST
export interface IRequestCycleGroupAddGuestAction {
  type: typeof Types.REQUEST_CYCLE_GROUPS_ADD_GUEST;
  payload: {
    idCiclo: number;
    idCicloGrupo: number;
    avaliador: Partial<EmployeeType>;
  }
};

export interface ISuccessCycleGroupAddGuestAction {
  type: typeof Types.SUCCESS_CYCLE_GROUPS_ADD_GUEST;
  avaliador: GuestType;
};

interface IFailureCycleGroupAddGuestAction {
  type: typeof Types.FAILURE_CYCLE_GROUPS_ADD_GUEST;
  message: string;
};

type CycleGroupAddGuestAction = IRequestCycleGroupAddGuestAction | ISuccessCycleGroupAddGuestAction | IFailureCycleGroupAddGuestAction;

// DELETE GUEST
export interface IRequestCycleGroupDelGuestAction {
  type: typeof Types.REQUEST_CYCLE_GROUPS_DEL_GUEST;
  payload: {
    idCiclo: number;
    idCicloGrupo: number;
    idCicloGrupoConvidado: number;
  }
};

export interface ISuccessCycleGroupDelGuestAction {
  type: typeof Types.SUCCESS_CYCLE_GROUPS_DEL_GUEST;
  idCicloGrupoConvidado: number;
};

interface IFailureCycleGroupDelGuestAction {
  type: typeof Types.FAILURE_CYCLE_GROUPS_DEL_GUEST;
  message: string;
};

type CycleGroupDelGuestAction = IRequestCycleGroupDelGuestAction | ISuccessCycleGroupDelGuestAction | IFailureCycleGroupDelGuestAction;

export type CycleGroupActionTypes =
  CycleGroupLoadAction |
  CycleGroupAddAction |
  CycleGroupLoadByIdAction |
  CycleGroupEditAction |
  CycleGroupDelAction |
  CycleGroupAddParticipantsAction |
  CycleGroupDelParticipantAction |
  CycleGroupAddGuestAction |
  CycleGroupDelGuestAction;
