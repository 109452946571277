import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { RouteComponentProps, withRouter } from "react-router-dom";

import moment from "moment";
import jwt from "jwt-decode";

import {
  Wrapper,
  ListContainer,
  GroupCheckbox,
  ContainerContent,
} from "./styles";

//FluentUI
import { Text } from "office-ui-fabric-react/lib/Text";
import { Stack } from "office-ui-fabric-react/lib/Stack";
import {
  DetailsListLayoutMode,
  SelectionMode,
  Selection,
  IColumn,
} from "office-ui-fabric-react/lib/DetailsList";
import { ShimmeredDetailsList } from "office-ui-fabric-react/lib/ShimmeredDetailsList";
import { CommandBarButton, IconButton } from "office-ui-fabric-react";
//MaterialUI
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
//Componentes
import { RootState } from "~/store/ducks";
import { CustomDrawer as Drawer } from "~/components/layout/Drawer";
import { InputAutocomplete, InputText } from "~/components/Forms";
import NoItems from "~/components/layout/NoItems";
import Status from "~/components/Status";
import colors from "~/assets/js/colors";
import { Creators as getTasks } from "~/store/ducks/tasks";
import { Creators as resetTask } from "~/store/ducks/tasks";
import { Creators as clearFilters } from "~/store/ducks/tasks";
import { Creators as editFilters } from "~/store/ducks/tasks";
import { Creators as editSearchText } from "~/store/ducks/tasks";
import { TaskType, DataTypes, TaskFilterType } from "~/store/ducks/tasks/types";
import { DataTypes as DataTypesLogin } from "~/store/ducks/login/types";

import { Creators as setCurrentTab } from "~/store/ducks/profile";
import { Creators as setCurrentPage } from "~/store/ducks/home";
import { Page } from "~/store/ducks/home/types";
import Colors from "~/assets/js/colors";
import { Creators as getEmployees } from "~/store/ducks/employees";
import {
  EmployeeType,
  DataTypes as DataTypesEmployees,
} from "~/store/ducks/employees/types";
import { ListItemText, MenuItem, Select } from "@material-ui/core";
import { PrimaryButton } from "~/components/Buttons";

interface ICycleState {
  columns: IColumn[];
  items: TaskType[];
  isPanelOpen: boolean;
  selectionDetails: string;
  selectionCount: number;
  isFiltering: boolean;
}

interface ICyclesProps extends RouteComponentProps {
  tasks: DataTypes;
  login: DataTypesLogin;
  employees: DataTypesEmployees;
  getEmployees: (search?: string, filter?: boolean | null) => void;
  getTasks: (
    responsavelId?: number | null,
    searchText?: string,
    status?: string[],
    filterType?: string[]
  ) => void;
  setCurrentPage: (page: Page) => void;
  setCurrentTab: (indexTab: string) => void;
  resetTask: () => void;
  clearFilters: () => void;
  editFilters: (filter: TaskFilterType) => void;
  editSearchText: (searchText: string) => void;
  history: RouteComponentProps["history"];
}

class Tasks extends Component<ICyclesProps, ICycleState> {
  private formRef: any;
  private _selection: Selection;
  private timeout: number;
  private idFuncionarioOwner: number;

  constructor(props: ICyclesProps) {
    super(props);

    const token = localStorage.getItem("user/token");
    this.idFuncionarioOwner = token
      ? (jwt(token ?? "") as any)?.idFuncionario
      : null;

    const columns: IColumn[] = [
      {
        key: "column1",
        name: "",
        ariaLabel: "Status do cargo",
        fieldName: "flagAtivo",
        minWidth: 15,
        maxWidth: 20,
        isResizable: true,
        isSortedDescending: false,
        onRender: (item: TaskType) => (
          <Status
            statusLetter={item.statusTarefa.toLowerCase()}
            color={convertTipoTarefaColor(item.statusTarefa)}
          />
        ),
      },
      {
        key: "column2",
        name: "ID",
        ariaLabel: "ID",
        fieldName: "idTarefa",
        isRowHeader: true,
        minWidth: 50,
        maxWidth: 50,
        isSortedDescending: false,
        onColumnClick: this._onColumnClick,
      },
      {
        key: "column3",
        name: "Descrição",
        fieldName: "tituloTarefa",
        minWidth: 150,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "string",
        isPadded: true,
      },
      {
        key: "column4",
        name: "Tipo",
        fieldName: "tipoTarefa",
        minWidth: 180,
        maxWidth: 210,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "string",
        isPadded: true,
        onRender: (item: TaskType) => convertTipoTarefa(item.tipoTarefa),
      },
      {
        key: "column5",
        name: "Prazo",
        fieldName: "dataExpiracao",
        minWidth: 75,
        maxWidth: 75,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        isPadded: true,
        onRender: (item: TaskType) =>
          moment(item.dataExpiracao).format("DD/MM/YYYY"),
      },
      {
        key: "column6",
        name: "Responsável",
        fieldName: "nomeCompleto",
        minWidth: 150,
        maxWidth: 210,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        isPadded: true,
      },
    ];

    this.state = {
      columns: columns,
      items: [],
      isPanelOpen: false,
      selectionDetails: "",
      isFiltering: false,
      selectionCount: 0,
      // filter: {
      //   isOpen: false,
      //   filteredByStatus: [],
      //   filteredByType: [],
      //   group: {
      //     a: false,
      //     f: false,
      //     e: false,
      //     c: false,
      //   },
      //   responsavel: {
      //     idFuncionario: this.idFuncionarioOwner,
      //     nomeCompleto: this.props.login.data.name,
      //   },
      // },
    };
    this.formRef = React.createRef();
    this.timeout = 0;

    this._selection = new Selection({
      onSelectionChanged: () => {
        this.setState({
          selectionDetails: this._getSelectionDetails(),
        });
      },
    });
  }

  componentDidMount() {
    const { filter, searchText } = this.props.tasks;

    this.props.getTasks(
      filter.responsavel?.idFuncionario === undefined
        ? this.idFuncionarioOwner
        : filter.responsavel?.idFuncionario,
      searchText,
      filter.filteredByStatus,
      filter.filteredByType
    );

    if (filter.responsavel?.idFuncionario === undefined) {
      this.props.editFilters({
        ...filter,
        responsavel: {
          idFuncionario: this.idFuncionarioOwner,
          nomeCompleto: this.props.login.data.name,
        },
      });
    }

    if (filter.responsavel?.idFuncionario === this.idFuncionarioOwner) {
      this.props.editFilters({
        ...filter,
        responsavel: {
          idFuncionario: this.idFuncionarioOwner,
          nomeCompleto: this.props.login.data.name,
        },
      });
    }
  }

  componentDidUpdate(prevProps: ICyclesProps) {
    const { filter } = this.props.tasks;

    if (
      prevProps.tasks.filter.responsavel?.nomeCompleto !==
      filter.responsavel?.nomeCompleto
    ) {
      if (filter.responsavel?.idFuncionario === this.idFuncionarioOwner) {
        this.props.editFilters({
          ...filter,
          responsavel: {
            idFuncionario: this.idFuncionarioOwner,
            nomeCompleto: this.props.login.data.name,
          },
        });
      }
    }
  }

  _getSelectionDetails(): any {
    const selectionCount = this._selection.getSelectedCount();
    this.setState({ selectionCount });
    this._selection.getSelection();
  }

  // Submit do formulário Formik
  handleSubmit = () => {
    if (this.formRef.current) {
      this.formRef.current.handleSubmit();
    }
  };

  cancelPanel = () => {
    this._selection.setAllSelected(false);
    this.setState({ isPanelOpen: false });
  };

  handleFilterStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { filter } = this.props.tasks;

    const newFilter = {
      ...filter,
      group: {
        ...filter.group,
        [e.target.name]: e.target.checked,
      },
    };

    this.props.editFilters(newFilter);

    const status = Object.entries({
      ...filter.group,
      [e.target.name]: e.target.checked,
    })
      .filter((item) => item[1])
      .map((item) => item[0]);

    this.props.editFilters({
      ...newFilter,
      filteredByStatus: status,
    });

    // this.props.getTasks(filter.responsavel?.idFuncionario, searchText, status, filter.filteredByType)
  };

  handleChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    const search = e.target.value;
    this.props.editSearchText(search);
  };

  handleSearch = (e: React.KeyboardEvent<Element>) => {
    const { filter, searchText } = this.props.tasks;

    if (e.keyCode === 13) {
      this.props.getTasks(
        filter.responsavel?.idFuncionario,
        searchText,
        filter.filteredByStatus,
        filter.filteredByType
      );
    }
  };

  toggleFilter = () => {
    this.props.editFilters({
      ...this.props.tasks.filter,
      isOpen: !this.props.tasks.filter.isOpen,
    });
  };

  clearFilters = () => {
    this.props.clearFilters();
    this.props.getTasks(this.idFuncionarioOwner, this.props.tasks.searchText);
  };

  search = (text: string) => {
    if (text.trim()) {
      clearTimeout(this.timeout);
      this.timeout = window.setTimeout(() => {
        this.props.getEmployees(text);
      }, 700);
    }
  };

  handleChangeMultiple = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.props.editFilters({
      ...this.props.tasks.filter,
      filteredByType: event.target.value as string[],
    });
  };

  _onItemInvoked = (item: TaskType) => {
    this.props.resetTask();
    console.log(item);

    if (item.tipoTarefa === "ME") {
      this.props.setCurrentTab("3");
      this.props.history.push({
        pathname: `/perfil/${item.idFuncionario}`,
        state: { idTarefa: item.idTarefa },
      });
    } else {
      this.props.history.push(`/tarefas/${item.idTarefa}`);
    }
  };

  render() {
    const { columns, isFiltering, items } = this.state;
    const { tasks, getTasks, employees, login, editFilters } = this.props;
    const { filter, searchText } = tasks;
    const tiposTarefa = [
      {
        tipo: "AA",
        descricao: "Autoavaliação",
      },
      {
        tipo: "AC",
        descricao: "Avaliação Coletiva",
      },
      {
        tipo: "AT",
        descricao: "Avaliação do time",
      },
      {
        tipo: "ME",
        descricao: "Definição de metas",
      },
      {
        tipo: "DM",
        descricao: "Devolutiva de Mérito",
      },
      {
        tipo: "FF",
        descricao: "Feedback de Funcionário",
      },
      {
        tipo: "FB",
        descricao: "Feedback gestor",
      },
      {
        tipo: "FC",
        descricao: "Feedback do ciclo",
      },
      {
        tipo: "FO",
        descricao: "Feedback do time",
      },
      {
        tipo: "MT",
        descricao: "Mérito",
      },
      {
        tipo: "PD",
        descricao: "PDI",
      },
    ];

    return (
      <Wrapper>
        <Drawer
          diffHeight={105}
          isOpen={filter.isOpen}
          content={
            <ContainerContent>
              <Stack horizontal verticalAlign="center">
                <InputText
                  label="Entre com a descrição para a busca"
                  value={searchText}
                  onChange={this.handleChangeText}
                  onKeyUp={(e: React.KeyboardEvent<Element>) =>
                    this.handleSearch(e)
                  }
                  variant="outlined"
                  height_container={40}
                  fullWidth
                  size="small"
                  smaller="small"
                />
                <CommandBarButton
                  styles={{
                    root: {
                      height: 40,
                      marginLeft: "15px !important",
                      padding: "0 5px",
                    },
                  }}
                  iconProps={{ iconName: "Refresh" }}
                  text="Atualizar"
                  onClick={() =>
                    getTasks(
                      filter.responsavel?.idFuncionario,
                      searchText,
                      filter.filteredByStatus,
                      filter.filteredByType
                    )
                  }
                />
                <CommandBarButton
                  styles={{
                    root: {
                      height: 40,
                      marginLeft: "15px !important",
                      padding: "0 5px",
                    },
                  }}
                  iconProps={{ iconName: "filter" }}
                  text="Filtrar"
                  onClick={() => this.toggleFilter()}
                />
              </Stack>
              {!tasks.loadingData && tasks.data.length === 0 ? (
                <NoItems
                  error={tasks.error}
                  text="Não há tarefas cadastradas"
                  icon="IssueTracking"
                  alt="Tarefas"
                />
              ) : (
                <ListContainer>
                  <ShimmeredDetailsList
                    items={isFiltering ? items : tasks.data}
                    enableShimmer={tasks.loadingData}
                    columns={columns}
                    selectionMode={SelectionMode.single}
                    selection={this._selection}
                    getKey={this._getKey}
                    selectionPreservedOnEmptyClick={true}
                    setKey="single"
                    layoutMode={DetailsListLayoutMode.justified}
                    isHeaderVisible={true}
                    onItemInvoked={this._onItemInvoked}
                  />
                </ListContainer>
              )}
            </ContainerContent>
          }
        >
          <Stack
            verticalAlign="space-between"
            styles={{ root: { height: "100%" } }}
          >
            <Stack>
              <Stack horizontal horizontalAlign="space-between">
                <Text variant="xLarge">Filtros</Text>
                <Stack horizontal>
                  <IconButton
                    iconProps={{ iconName: "ClearFilter" }}
                    title="Limpar Filtros"
                    disabled={
                      filter.filteredByStatus!.length > 0 ||
                      filter.filteredByType!.length > 0 ||
                      filter.responsavel?.idFuncionario !==
                        this.idFuncionarioOwner
                        ? false
                        : true
                    }
                    onClick={() => this.clearFilters()}
                    ariaLabel="Limpar Filtros"
                  />
                  <IconButton
                    iconProps={{ iconName: "ChromeClose" }}
                    styles={{ root: { color: colors.darkGray } }}
                    title="Fechar"
                    ariaLabel="Fechar"
                    onClick={() => editFilters({ ...filter, isOpen: false })}
                  />
                </Stack>
              </Stack>
              <GroupCheckbox>
                <Text
                  variant="mediumPlus"
                  styles={{ root: { marginBottom: 10 } }}
                >
                  Status
                </Text>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filter.group?.a}
                      onChange={this.handleFilterStatus}
                      name="a"
                      color="primary"
                    />
                  }
                  label={<span style={{ fontSize: 14 }}>Aberta</span>}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filter.group?.e}
                      onChange={this.handleFilterStatus}
                      name="e"
                      color="primary"
                    />
                  }
                  label={<span style={{ fontSize: 14 }}>Expirada</span>}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filter.group?.f}
                      onChange={this.handleFilterStatus}
                      name="f"
                      color="primary"
                    />
                  }
                  label={<span style={{ fontSize: 14 }}>Finalizada</span>}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filter.group?.c}
                      onChange={this.handleFilterStatus}
                      name="c"
                      color="primary"
                    />
                  }
                  label={<span style={{ fontSize: 14 }}>Cancelada</span>}
                />
              </GroupCheckbox>
              <Stack>
                <Text
                  variant="mediumPlus"
                  style={{ marginBottom: 10, marginTop: 10 }}
                >
                  Tipo de tarefa
                </Text>
                <Select
                  label=""
                  value={filter.filteredByType}
                  multiple
                  onChange={this.handleChangeMultiple}
                  renderValue={(selected) => {
                    const descricao = tiposTarefa
                      .filter((item) => {
                        return (selected as any[]).some(
                          (sel) => sel === item.tipo
                        );
                      })
                      .map((item) => item.descricao);
                    return (descricao as string[]).join(", ");
                  }}
                >
                  {tiposTarefa.map((item, i) => (
                    <MenuItem key={i} value={item.tipo} style={{ height: 40 }}>
                      <Checkbox
                        checked={
                          (filter?.filteredByType
                            ?.map((tt) => tt)
                            .indexOf(item.tipo) ?? -1) > -1
                        }
                        style={{ color: Colors.primary }}
                      />
                      <ListItemText primary={item.descricao} />
                    </MenuItem>
                  ))}
                </Select>
              </Stack>
              <Stack>
                <Text
                  variant="mediumPlus"
                  style={{ marginBottom: 10, marginTop: 10 }}
                >
                  Responsável
                </Text>
                <InputAutocomplete
                  value={filter.responsavel as EmployeeType}
                  onChange={(_, newValue) => {
                    let responsavel;
                    if (newValue === null) {
                      responsavel = {
                        idFuncionario: null,
                        nomeCompleto: "",
                      };
                    } else {
                      responsavel = newValue;
                    }
                    editFilters({ ...filter, responsavel });
                  }}
                  onInputChange={(_, newInputValue) => {
                    this.search(newInputValue);
                  }}
                  getOptionLabel={(employee: EmployeeType) =>
                    employee.nomeCompleto
                  }
                  getOptionSelected={(option, value) =>
                    option.idFuncionario === value.idFuncionario
                  }
                  options={employees.data}
                  disabled={!login.data.flagAdministrador}
                  input={{
                    idInput: "responsavel",
                    labelInput: "",
                  }}
                />
              </Stack>
            </Stack>
            <PrimaryButton
              onClick={() =>
                getTasks(
                  filter.responsavel?.idFuncionario,
                  searchText,
                  filter.filteredByStatus,
                  filter.filteredByType
                )
              }
              text="Aplicar Filtros"
            />
          </Stack>
        </Drawer>
      </Wrapper>
    );
  }

  private _onColumnClick = (
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn
  ): void => {
    const { columns } = this.state;
    const newColumns: IColumn[] = columns.slice();
    let items: TaskType[] = [];
    if (this.state.isFiltering) {
      items = this.state.items;
    } else {
      items = this.props.tasks.data;
    }
    const currColumn: IColumn = newColumns.filter(
      (currCol) => column.key === currCol.key
    )[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    const newItems = this._sort(
      items,
      currColumn.fieldName!,
      currColumn.isSortedDescending
    );
    this.setState({
      columns: newColumns,
      items: newItems,
    });
  };

  private _getKey(item: any, index?: number): any {
    if (item !== undefined) return item.key;
  }

  private _sort<T>(
    items: T[],
    columnKey: string,
    isSortedDescending?: boolean
  ): T[] {
    const key = columnKey as keyof T;
    const itemsSorted = items.sort((a: T, b: T) =>
      (isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1
    );
    return itemsSorted;
  }
}

export const convertTipoTarefa = (tipo: string): string => {
  switch (tipo) {
    case "ME":
      return "DEFINIÇÃO DE METAS";
    case "AA":
      return "AUTOAVALIAÇÃO";
    case "AT":
      return "AVALIAÇÃO DO TIME";
    case "FB":
      return "FEEDBACK GESTOR";
    case "FF":
      return "FEEDBACK DE FUNCIONÁRIO";
    case "FC":
      return "FEEDBACK DO CICLO";
    case "MA":
      return "FEEDBACK";
    case "MF":
      return "FEEDBACK";
    case "PD":
      return "PDI";
    case "DM":
      return "DEVOLUTIVA DE MÉRITO";
    case "AC":
      return "AVALIAÇÃO COLETIVA";
    case "FO":
      return "FEEDBACK DO TIME";
    case "MT":
      return "MÉRITO";
    default:
      return tipo;
  }
};

const convertTipoTarefaColor = (tipo: string): string => {
  switch (tipo) {
    case "a":
      return "#1FB964";
    case "e":
      return Colors.darkGray;
    case "f":
      return Colors.darkGray;
    case "c":
      return Colors.error;
    case "*":
      return Colors.darkGray;
    default:
      return tipo;
  }
};

const mapStateToProps = (state: RootState) => ({
  tasks: state.tasksReducer,
  login: state.loginReducer,
  employees: state.employeesReducer,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      ...setCurrentPage,
      ...getTasks,
      ...setCurrentTab,
      ...resetTask,
      ...getEmployees,
      ...clearFilters,
      ...editFilters,
      ...editSearchText,
    },
    dispatch
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Tasks));
