import { Types, DataTypes, ConductActionTypes, ConductType } from "./types";
import customToast from "~/components/Toast/index";

/**
 * Reducers
 */
const INITIAL_STATE: DataTypes = {
  data: [],
  loading: false,
  loadingAction: false,
  error: false,
  success: false,
};

/**
 * Actions
 */

export const Creators = {
  getConducts: (): ConductActionTypes => {
    return {
      type: Types.REQUEST_CONDUCTS_LOAD,
    };
  },

  addConduct: (conduct: ConductType): ConductActionTypes => ({
    type: Types.REQUEST_CONDUCT_ADD,
    conduct,
  }),

  editConduct: (conduct: ConductType): ConductActionTypes => ({
    type: Types.REQUEST_CONDUCT_EDIT,
    conduct,
  }),

  delConduct: (idConduta: number): ConductActionTypes => ({
    type: Types.REQUEST_CONDUCT_DELETE,
    idConduta,
  }),
};

export default function conductsReducer(
  state = INITIAL_STATE,
  action: ConductActionTypes
): DataTypes {
  switch (action.type) {
    case Types.REQUEST_CONDUCTS_LOAD:
      return {
        ...state,
        loading: true,
        error: false,
        success: false,
      };
    case Types.SUCCESS_CONDUCTS_LOAD:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        success: true,
      };
    case Types.FAILURE_CONDUCTS_LOAD:
      customToast.error(action.message);
      return {
        ...state,
        data: [],
        loading: false,
        loadingAction: false,
        error: true,
        success: false,
      };
    case Types.REQUEST_CONDUCT_ADD:
      return {
        ...state,
        error: false,
        success: false,
        loadingAction: true,
      };
    case Types.SUCCESS_CONDUCT_ADD:
      customToast.success("Tipo de Conduta cadastrada com sucesso");
      return {
        ...state,
        error: false,
        success: true,
        data: [...state.data, action.conduct],
        loadingAction: false,
      };
    case Types.FAILURE_CONDUCT_ADD:
      customToast.error(action.message);
      return {
        ...state,
        error: true,
        success: false,
        loadingAction: false,
      };

    case Types.REQUEST_CONDUCT_EDIT:
      return {
        ...state,
        error: false,
        success: false,
        loadingAction: true,
      };
    case Types.SUCCESS_CONDUCT_EDIT:
      customToast.success("Tipo de Conduta editada com sucesso");
      return {
        ...state,
        error: false,
        success: true,
        loadingAction: false,
        data: state.data.map((item) => {
          if (item.idConduta !== action.conduct.idConduta) {
            return item;
          }
          return action.conduct;
        }),
      };
    case Types.FAILURE_CONDUCT_EDIT:
      customToast.error(action.message);
      return {
        ...state,
        error: true,
        success: false,
        loadingAction: false,
      };

    case Types.REQUEST_CONDUCT_DELETE:
      return {
        ...state,
        error: false,
        success: false,
        loading: true,
      };
    case Types.SUCCESS_CONDUCT_DELETE:
      customToast.success("Item excluído com sucesso");
      return {
        ...state,
        error: false,
        success: true,
        data: state.data.filter((item) => item.idConduta !== action.idConduta),
        loading: false,
      };
    case Types.FAILURE_CONDUCT_DELETE:
      customToast.error(action.message);
      return {
        ...state,
        error: true,
        success: false,
        loading: false,
      };

    default:
      return state;
  }
}
