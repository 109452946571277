import styled from "styled-components";

export const Wrapper = styled.div`
  height: calc(100vh - 105px);
  width: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

export const ContainerContent = styled.div`
  padding: 20px;
  height: 100%;
`;

export const ListContainer = styled.div`
  width: 1010px;
  height:100%;
  overflow-y: auto;
`;

export const GroupCheckbox = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;
