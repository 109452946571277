import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spinner, SpinnerSize, Stack, Text } from "@fluentui/react";
import jwt from "jwt-decode";

import {
  ContainerDropdownInput,
  InputContainer,
  ContainerHeader,
  WrapperForm,
} from "./styles";

import Accordion from "~/components/Accordion";
import Dropdown from "~/components/layout/Dropdown";
import MenuItem from "@material-ui/core/MenuItem";
import DevelopmentActionsGrid from "~/pages/Tasks/PDI/DevelopmentActionsGrid";

import { Creators } from "~/store/ducks/profile";
import {
  DataTypes as DataTypesProfile,
  ProfilePdiType,
} from "~/store/ducks/profile/types";
import { RootState } from "~/store/ducks";
import NoItems from "~/components/layout/NoItems";
import { InputText } from "~/components/Forms";

interface IPdiProps {
  idFuncionario?: number;
}

const Pdi: React.FC<IPdiProps> = ({ idFuncionario }) => {
  const [season, setSeason] = useState("");
  const dispatch = useDispatch();

  const profileState = useSelector<RootState, DataTypesProfile>(
    (state) => state.profileReducer
  );
  const pdiData: ProfilePdiType = profileState.dataPdi;

  const token = localStorage.getItem("user/token");
  const idFunc = idFuncionario ?? (jwt(token!) as any)?.idFuncionario;

  useEffect(() => {
    dispatch(Creators.getPdi(idFuncionario));
  }, []);

  useEffect(() => {
    if (pdiData.anos.length > 0) {
      const ano = pdiData.anos[0];
      setSeason(ano.ano ?? "");
    }
  }, [pdiData.anos]);

  const handleChange = (event: React.ChangeEvent<{ value: string }>) => {
    const ano: string = event.target.value;
    setSeason(ano);
    dispatch(Creators.getPdi(idFuncionario, ano));
  };

  return (
    <>
      {profileState.loadingData ? (
        <Spinner
          size={SpinnerSize.medium}
          label="Carregando PDI"
          styles={{ root: { height: 400 } }}
        />
      ) : pdiData.anos.length === 0 ? (
        <NoItems text="Não há ações cadastradas" icon="PublishCourse" />
      ) : (
        <>
          <ContainerHeader>
            <ContainerDropdownInput style={{ marginRight: 40 }}>
              <Text variant="medium" styles={{ root: { marginRight: 20 } }}>
                Ciclo
              </Text>
              <InputContainer>
                <Dropdown
                  errors=""
                  variant="outlined"
                  label=""
                  name="season"
                  values={season}
                  handleChange={handleChange}
                  fieldHeight={32}
                  styles={{
                    boxSizing: "border-box",
                    height: 32,
                    backgroundColor: "transparent",
                  }}
                  autofocus
                  customPadding="6.5px 14px"
                >
                  {pdiData.anos.map((ano) => (
                    <MenuItem key={ano.ano} value={ano.ano}>
                      {ano.ano}
                    </MenuItem>
                  ))}
                </Dropdown>
              </InputContainer>
            </ContainerDropdownInput>
          </ContainerHeader>
          <Accordion title="Ações de Desenvolvimento">
            {!!pdiData.dataPDI && Object.keys(pdiData.dataPDI).length > 0 && (
              <DevelopmentActionsGrid
                acoes={pdiData.dataPDI.acoes.map((item) => ({
                  id: item.idPDIAcao,
                  idAcaoPDI: item.idAcaoPDI ?? null,
                  acaoPDI: item.acaoPDI,
                  idFuncionarioRelacionado: item.idFuncionarioRelacionado,
                  obs: item.obs,
                  complemento: item.complemento,
                  funcionario: item.funcionario,
                }))}
                regras={pdiData.dataPDI.Regras}
                onAdd={(pdi) =>
                  dispatch(
                    Creators.addPdi(
                      season,
                      {
                        idPDIAcao: null,
                        ...pdi,
                      },
                      idFunc
                    )
                  )
                }
                onEdit={(pdi) =>
                  dispatch(
                    Creators.editPdi(
                      season,
                      {
                        idPDIAcao: pdi.id!,
                        ...pdi,
                      },
                      idFunc
                    )
                  )
                }
                onDel={(idPDIAcao) =>
                  dispatch(Creators.delPdi(season, idPDIAcao, idFunc))
                }
                loading={profileState.loadingAction}
                success={profileState.success}
                error={profileState.error}
                readOnly={!pdiData.dataPDI?.permiteEditar || !!idFuncionario}
              />
            )}
          </Accordion>
          <Accordion title="Comentários do PDI">
            <Stack styles={{ root: { width: "100%" } }}>
              <WrapperForm>
                <InputText
                  label="Entre com os comentários do PDI"
                  name="comentarios"
                  multiline
                  rows={3}
                  value={pdiData.dataPDI?.comentarios ?? ""}
                  disabled={true}
                />
              </WrapperForm>
            </Stack>
          </Accordion>
        </>
      )}
    </>
  );
};

export default Pdi;
