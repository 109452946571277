import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";

import CustomDialog from "~/components/CustomDialog";
import { TitleField, GroupItems } from "~/components/FormGroup";
import { InputText } from "~/components/Forms";
import {
  AvaliadoCommentsType,
  CommentYearType,
} from "~/store/ducks/calibration/types";
import { Spinner, SpinnerSize, Stack } from "office-ui-fabric-react";
import moment from "moment";
import Dropdown from "~/components/layout/Dropdown";
import { MenuItem } from "@material-ui/core";
import { Creators as CreatorsCalib } from "~/store/ducks/calibration";

const validationSchema = yup.object().shape({
  comentarios: yup.string().required("Campo obrigatório"),
});

interface CalibrationValues {
  nomeCompleto: string;
  comentarios: AvaliadoCommentsType | null;
}

interface IDialogCalibrationProps {
  idCicloReuniao: number;
  idCicloReuniaoAvaliado: number;
  isOpen: boolean;
  initialValues: CalibrationValues;
  loadingData?: boolean;
  success?: boolean;
  readonly: boolean;
  onClose: () => void;
  onSuccess: (values: string) => void;
}

const DialogAnotation: React.FC<IDialogCalibrationProps> = (props) => {
  const {
    idCicloReuniao,
    idCicloReuniaoAvaliado,
    isOpen,
    onClose,
    onSuccess,
    loadingData,
    success,
    readonly,
    initialValues,
  } = props;
  const { comentarios } = initialValues;

  const dispatch = useDispatch();

  const [comentarioAno, setComentarioAno] = useState<
    CommentYearType | undefined
  >();
  const isCurrentYear =
    idCicloReuniaoAvaliado === comentarioAno?.idCicloReuniaoAvaliado ||
    comentarioAno?.ano === undefined;

  const { handleSubmit, handleChange, values, errors, resetForm } = useFormik({
    initialValues: { comentarios: "" },
    validationSchema,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit(values) {
      onSuccess(values.comentarios);
    },
  });

  useEffect(() => {
    if (
      !!comentarios?.anos &&
      comentarios?.anos.length > 0 &&
      comentarioAno === undefined
    ) {
      setComentarioAno(
        comentarios?.anos.find(
          (ano) => ano.idCicloReuniaoAvaliado === idCicloReuniaoAvaliado
        )
      );
    }
  }, [comentarios]);

  useEffect(() => {
    if (isOpen) {
      setComentarioAno(undefined);
      resetForm({
        values: {
          comentarios: "",
        },
      });
    }
  }, [isOpen, resetForm]);

  const handleChangeAno = (e: any) => {
    const newIdCicloreuniaoAvaliado = e.target.value;
    dispatch(
      CreatorsCalib.getComments(
        idCicloReuniao,
        idCicloReuniaoAvaliado,
        newIdCicloreuniaoAvaliado
      )
    );
    setComentarioAno(
      comentarios?.anos.find(
        (ano) => ano.idCicloReuniaoAvaliado === newIdCicloreuniaoAvaliado
      )
    );
  };

  return (
    <CustomDialog
      open={isOpen}
      title="Anotações"
      btnConfirmText="Salvar"
      onClickCancel={onClose}
      disabledBtnConfirm={loadingData || readonly || !isCurrentYear}
      onClickConfirm={() => handleSubmit()}
      height={600}
      width={640}
      zIndex={1300}
    >
      <div
        style={{
          position: "absolute",
          top: 16,
          right: 24,
        }}
      >
        <Dropdown
          name="ano"
          label=""
          values={comentarioAno?.idCicloReuniaoAvaliado ?? ""}
          variant="outlined"
          fieldHeight={32}
          styles={{
            boxSizing: "border-box",
            height: 32,
            backgroundColor: "transparent",
            width: "115px",
          }}
          autofocus
          customPadding="6.5px 14px"
          handleChange={handleChangeAno}
        >
          {comentarios?.anos.map((ano) => (
            <MenuItem
              key={ano.idCicloReuniaoAvaliado}
              value={ano.idCicloReuniaoAvaliado}
            >
              {ano.ano}
            </MenuItem>
          ))}
        </Dropdown>
      </div>
      {loadingData ? (
        <Spinner
          size={SpinnerSize.medium}
          label="Carregando anotações"
          styles={{ root: { height: "100%" } }}
        />
      ) : (
        <form onSubmit={handleSubmit}>
          <GroupItems title="Funcionário">
            <TitleField
              text={initialValues?.nomeCompleto ?? ""}
              // color={colors.primary}
              bold={true}
            />
          </GroupItems>
          <Stack
            verticalAlign="space-between"
            styles={{ root: { height: 318 } }}
          >
            <Stack styles={{ root: { maxHeight: 275, overflow: "auto" } }}>
              {comentarios?.comentarios.map((comentario, i) => (
                <GroupItems
                  key={i}
                  title={moment(comentario.dataHoraAnotacao).format(
                    "DD/MM/YYYY HH:mm"
                  )}
                  titleRegular
                  stylesTitle={{ color: "#00000050", fontSize: 12 }}
                >
                  <TitleField text={comentario.comentarios} />
                </GroupItems>
              ))}
            </Stack>

            <InputText
              name="comentarios"
              label="Comentários"
              value={values.comentarios ?? ""}
              onChange={handleChange}
              multiline
              rows={3}
              rowsMax={3}
              error={errors.comentarios ? true : false}
              helperText={errors.comentarios}
              disabled={readonly || !isCurrentYear}
            />
          </Stack>
        </form>
      )}
    </CustomDialog>
  );
};

export default DialogAnotation;
