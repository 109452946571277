import styled from "styled-components";

export const TitleContainer = styled.div`
  width: 100%;
  background-color: #ffffff;
  padding-bottom: 10px;
`;

export const ContainerContent = styled.div`
  width: 100%;
  padding: 20px;
  height: calc(100% - 50px);
`;

export const WrapperItemDownload = styled.div`
  display: flex;
  padding: 16px;
  border-radius: 2px;
  justify-content: space-between;

  & button {
      display: none;
  }
  
  &:hover {
      background-color: #F3F2F1;
      & button {
          display: block;
      }
  }
`;
