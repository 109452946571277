// #region Imports
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Formik } from "formik";
import * as yup from "yup";

//FluentUI
import { Stack } from "office-ui-fabric-react/lib/Stack";
import {
  DetailsListLayoutMode,
  SelectionMode,
  Selection,
  IColumn,
} from "office-ui-fabric-react/lib/DetailsList";
import { ShimmeredDetailsList } from "office-ui-fabric-react/lib/ShimmeredDetailsList";
import {
  CommandBarButton,
  ICommandBarStyles,
  Pivot,
  PivotItem,
  Text,
  TooltipHost,
} from "office-ui-fabric-react";
import { IDialogContentProps } from "office-ui-fabric-react/lib/Dialog";
import { FontIcon } from "office-ui-fabric-react/lib/Icon";

//MaterialUI

import InputAdornment from "@material-ui/core/InputAdornment";
//Componentes
import CustomDialog from "~/components/CustomDialogFluentUI";

import Dialog from "~/components/CustomDialog";
import { InputCheckbox, InputNumber, InputText } from "~/components/Forms";

import { DeleteButton } from "~/components/Buttons";
import NoItems from "~/components/layout/NoItems";
//Estilos
import {
  Wrapper,
  ListContainer,
  DefaultButton,
  ContainerContent,
} from "./styles";

import { Creators as getCyclePerformance } from "~/store/ducks/cycles/performance";
import { Creators as addCyclePerformance } from "~/store/ducks/cycles/performance";
import { Creators as editCyclePerformance } from "~/store/ducks/cycles/performance";
import { Creators as delCyclePerformance } from "~/store/ducks/cycles/performance";
import {
  DataTypes as DataTypesPerformance,
  CycloPerformanceType,
} from "~/store/ducks/cycles/performance/types";

import { RootState } from "~/store/ducks";
import { Checkbox, FormControlLabel } from "@material-ui/core";
//#endregion

/**
 * Validação do formulário
 */

interface ICyclePerformanceState {
  columns: IColumn[];
  items: DataTypesPerformance[];
  isDialogPerformanceOpen: boolean;
  isFiltering: boolean;
  selectionDetails: string;
  selectionCount: number;
  isDialogExcludeOpen: boolean;
  initialValues: CycloPerformanceType;
}

interface IPropsPerformance {
  idCycle: number | null;
  cyclePerformance: DataTypesPerformance;
  getCyclePerformance: (idCiclo: number) => void;
  addCyclePerformance: (performance: CycloPerformanceType) => void;
  editCyclePerformance: (
    idCiclo: number,
    performance: CycloPerformanceType
  ) => void;
  delCyclePerformance: (idCiclo: number, idTabPerformance: number) => void;
}

const defaultValues: CycloPerformanceType = {
  idTabPerformance: null,
  idCiclo: null,
  cor: "",
  descNivel: "",
  percentual: null,
  descIngles: "",
  nivel: null,
  flagBloquearAvaliacao: false,
  multiplicador: null,
  multiplicadorBonus: null,
  flagEnquadramento: false,
  flagPromocao: false,
  flagZerarReajusteMinimo: false,
};

class CyclePerformance extends Component<
  IPropsPerformance,
  ICyclePerformanceState
> {
  private formRef: any;
  private _selection: Selection;

  constructor(props: IPropsPerformance) {
    super(props);
    //#region Colunas
    const columns: IColumn[] = [
      {
        key: "column2",
        name: "Descrição",
        fieldName: "descNivel",
        minWidth: 150,
        maxWidth: 170,
        isRowHeader: true,
        isResizable: true,
        data: "string",
        onRender: (value: CycloPerformanceType) => {
          return (
            <TooltipHost
              content={value.descIngles ?? ""}
              styles={{
                root: {
                  display: "flex",
                  height: 42,
                  position: "absolute",
                  top: 0,
                  alignItems: "center",
                  width: "100%",
                },
              }}
            >
              <Stack>
                <Text>{value.descNivel}</Text>
              </Stack>
            </TooltipHost>
          );
        },
      },
      {
        key: "column1",
        name: "Nível",
        fieldName: "nivel",
        minWidth: 30,
        maxWidth: 50,
        isRowHeader: true,
        isResizable: true,
        data: "string",
        isPadded: true,
      },
      {
        key: "column3",
        name: "Percentual",
        fieldName: "percentual",
        minWidth: 30,
        maxWidth: 50,
        isRowHeader: true,
        isResizable: true,
        data: "string",
        isPadded: true,
        onRender: (item: CycloPerformanceType) => item.percentual + "%",
      },
      {
        key: "column4",
        name: "Cor",
        fieldName: "cor",
        minWidth: 30,
        maxWidth: 50,
        isRowHeader: true,
        isResizable: true,
        data: "string",
        isPadded: true,
        onRender: (item: CycloPerformanceType) => (
          <Stack horizontal verticalAlign="center">
            <FontIcon
              iconName="CircleShapeSolid"
              style={{ color: item.cor, marginRight: 5 }}
            />
            {item.cor}
          </Stack>
        ),
      },
    ];
    //#endregion

    this.state = {
      columns: columns,
      items: [],
      isDialogPerformanceOpen: false,
      selectionDetails: "",
      isFiltering: false,
      selectionCount: 0,
      isDialogExcludeOpen: false,
      initialValues: defaultValues,
    };

    this._selection = new Selection({
      onSelectionChanged: () => {
        this.setState({
          selectionDetails: this._getSelectionDetails(),
        });
      },
    });

    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.props.getCyclePerformance(this.props.idCycle!);
  }

  componentDidUpdate(prevProps: IPropsPerformance, _: any) {
    if (this.state.isDialogPerformanceOpen) {
      if (
        prevProps.cyclePerformance.success !==
        this.props.cyclePerformance.success
      ) {
        if (this.props.cyclePerformance.success) {
          this.setState({ isDialogPerformanceOpen: false });
        }
      }
    }
  }

  // Submit do formulário Formik
  handleSubmit = () => {
    if (this.formRef.current) {
      this.formRef.current.handleSubmit();
    }
  };

  _onItemInvoked = (): void => {
    this.handleEdit();
  };

  _getSelectionDetails(): any {
    const selectionCount = this._selection.getSelectedCount();
    this.setState({ selectionCount });
    this._selection.getSelection();
  }

  excludePerformance = () => {
    const id = (this._selection.getSelection()[0] as CycloPerformanceType)
      .idTabPerformance!;
    this.props.delCyclePerformance(this.props.idCycle!, id);
    this.setState({ isDialogExcludeOpen: false });
    this._selection.setAllSelected(false);
  };

  handleEdit = () => {
    const idTabPerformance = (this._selection.getSelection()[0] as CycloPerformanceType)
      .idTabPerformance;
    const item = this.props.cyclePerformance.data.find(
      (item) => item.idTabPerformance === idTabPerformance
    );
    this.setState({
      isDialogPerformanceOpen: true,
      initialValues: {
        ...item!,
        cor: item!.cor.replace("#", ""),
      },
    });
  };

  commandBarRender = () => {
    const { selectionCount } = this.state;
    if (selectionCount === 1) {
      return (
        <Stack horizontal verticalAlign="center">
          <CommandBarButton
            styles={btnStyle}
            iconProps={{ iconName: "Edit" }}
            text="Editar"
            onClick={this.handleEdit}
          />
          <CommandBarButton
            styles={btnStyle}
            iconProps={{ iconName: "Delete" }}
            text="Excluir"
            onClick={() => this.setState({ isDialogExcludeOpen: true })}
          />
        </Stack>
      );
    }
  };

  render() {
    const {
      isDialogPerformanceOpen,
      isDialogExcludeOpen,
      selectionCount,
      isFiltering,
      items,
      columns,
      initialValues,
    } = this.state;
    const { cyclePerformance, idCycle } = this.props;

    const dialogContentProps: IDialogContentProps = {
      title: "Excluir?",
      closeButtonAriaLabel: "Close",
      subText:
        selectionCount <= 1
          ? "Tem certeza de que deseja excluir este item?"
          : "Tem certeza de que deseja excluir estes items?",
    };

    return (
      <>
        <CustomDialog
          hidden={!isDialogExcludeOpen}
          onDismiss={() => this.setState({ isDialogExcludeOpen: false })}
          dialogContentProps={dialogContentProps}
        >
          <DefaultButton
            onClick={() => this.setState({ isDialogExcludeOpen: false })}
            text="Cancelar"
          />
          <DeleteButton
            onClick={() => this.excludePerformance()}
            text="Excluir"
          />
        </CustomDialog>
        <Wrapper>
          <ContainerContent>
            <Stack horizontal horizontalAlign="space-between">
              <Stack horizontal verticalAlign="center">
                <CommandBarButton
                  styles={btnStyle}
                  iconProps={{ iconName: "Add" }}
                  text="Adicionar Performance"
                  onClick={() =>
                    this.setState({
                      isDialogPerformanceOpen: true,
                      initialValues: defaultValues,
                    })
                  }
                />
                {this.commandBarRender()}
              </Stack>
              <CommandBarButton
                styles={btnStyle}
                iconProps={{ iconName: "Refresh" }}
                text="Atualizar"
                onClick={() =>
                  this.props.getCyclePerformance(this.props.idCycle!)
                }
              />
            </Stack>
            {!cyclePerformance.loadingData &&
            cyclePerformance.data.length === 0 ? (
              <NoItems
                error={cyclePerformance.error}
                text="Não há performance cadastrada"
                icon="FinancialSolid"
              />
            ) : (
              <ListContainer>
                <ShimmeredDetailsList
                  items={cyclePerformance.data.sort(
                    (a, b) => (b?.nivel ?? 0) - (a?.nivel ?? 0)
                  )}
                  enableShimmer={cyclePerformance.loadingData}
                  columns={columns}
                  selectionMode={SelectionMode.single}
                  selection={this._selection}
                  getKey={this._getKey}
                  selectionPreservedOnEmptyClick={true}
                  setKey="single"
                  layoutMode={DetailsListLayoutMode.justified}
                  isHeaderVisible={true}
                  onItemInvoked={this._onItemInvoked}
                />
              </ListContainer>
            )}
          </ContainerContent>
        </Wrapper>

        <Dialog
          title={
            !initialValues.idTabPerformance
              ? "Nova Performance"
              : "Editar Performance"
          }
          open={isDialogPerformanceOpen}
          onClickCancel={() =>
            this.setState({ isDialogPerformanceOpen: false })
          }
          onClickConfirm={this.handleSubmit}
          disabledBtnConfirm={cyclePerformance.loadingAction}
          isLoadingConfirm={cyclePerformance.loadingAction}
          btnConfirmText={
            !initialValues.idTabPerformance ? "Adicionar" : "Salvar"
          }
          width={640}
          height={625}
        >
          <Formik
            innerRef={this.formRef}
            initialValues={initialValues}
            validationSchema={validationPerformanceSchema}
            validateOnChange={false}
            validateOnBlur={true}
            enableReinitialize={true}
            onSubmit={(values: CycloPerformanceType) => {
              values.idCiclo = idCycle;

              if (!values.idTabPerformance) {
                this.props.addCyclePerformance({
                  ...values,
                  cor: `#${values.cor}`,
                });
              } else {
                this.props.editCyclePerformance(this.props.idCycle!, {
                  ...values,
                  cor: `#${values.cor}`,
                });
              }
            }}
          >
            {({
              handleSubmit,
              handleChange,
              values,
              errors,
              setFieldValue,
              setFieldError,
            }) => (
              <form onSubmit={handleSubmit}>
                <Stack horizontal>
                  <Stack styles={{ root: { minWidth: 380, marginRight: 16 } }}>
                    <InputText
                      type="text"
                      label="Descrição"
                      name="descNivel"
                      value={values.descNivel}
                      onChange={(e) => {
                        handleChange(e);
                        setFieldError("descNivel", "");
                      }}
                      helperText={errors.descNivel}
                      error={errors.descNivel ? true : false}
                    />
                    <InputText
                      type="text"
                      label="Descrição Inglês"
                      name="descIngles"
                      value={values.descIngles}
                      onChange={(e) => {
                        handleChange(e);
                        setFieldError("descIngles", "");
                      }}
                      helperText={errors.descIngles}
                      error={errors.descIngles ? true : false}
                    />

                    <InputText
                      type="number"
                      label="Porcentagem"
                      name="percentual"
                      value={values.percentual ?? ""}
                      onChange={(e) => {
                        handleChange(e);
                        setFieldError("percentual", "");
                      }}
                      helperText={errors.percentual}
                      error={errors.percentual ? true : false}
                      InputProps={{
                        autoComplete: "off",
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            style={{ paddingRight: 20 }}
                          >
                            %
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Stack>
                  <Stack>
                    <InputText
                      type="number"
                      label="Nível"
                      name="nivel"
                      value={values.nivel ?? ""}
                      onChange={(e) => {
                        handleChange(e);
                        setFieldError("nivel", "");
                      }}
                      helperText={errors.nivel}
                      error={errors.nivel ? true : false}
                    />
                    <InputText
                      type="text"
                      label="Cor"
                      name="cor"
                      value={values.cor ?? ""}
                      onChange={(e) => {
                        handleChange(e);
                        setFieldError("cor", "");
                      }}
                      helperText={errors.cor}
                      error={errors.cor ? true : false}
                      InputProps={{
                        autoComplete: "off",
                        startAdornment: (
                          <InputAdornment position="start">#</InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            style={{ marginRight: 20 }}
                          >
                            <FontIcon
                              iconName="CircleShapeSolid"
                              style={{ color: `#${values.cor}` }}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.flagBloquearAvaliacao}
                          onChange={handleChange}
                          name="flagBloquearAvaliacao"
                          color="primary"
                        />
                      }
                      label={
                        <span style={{ fontSize: 14 }}>Bloquear Avaliação</span>
                      }
                    />
                  </Stack>
                </Stack>
                <Pivot
                  aria-label="Configurações"
                  styles={{ root: { marginBottom: 32 } }}
                >
                  <PivotItem headerText="Config. Mérito">
                    <InputNumber
                      value={
                        !!values.multiplicador
                          ? Number(values.multiplicador)
                          : null
                      }
                      styles={{ width: 380 }}
                      onValueChange={(value) => {
                        setFieldValue(
                          "multiplicador",
                          value.floatValue ?? null
                        );
                        setFieldError("multiplicador", "");
                      }}
                      id="multiplicador"
                      name="multiplicador"
                      label="Multiplicador"
                      helperText={errors.multiplicador}
                      error={!!errors.multiplicador}
                    />
                    <Stack>
                      <InputCheckbox
                        checked={values.flagPromocao ?? false}
                        onChange={handleChange}
                        name="flagPromocao"
                        color="primary"
                        label="Permite Promoção"
                      />
                      <InputCheckbox
                        checked={values.flagEnquadramento ?? false}
                        onChange={handleChange}
                        name="flagEnquadramento"
                        color="primary"
                        label="Permite Enquadramento"
                      />
                      <InputCheckbox
                        checked={values.flagZerarReajusteMinimo ?? false}
                        onChange={handleChange}
                        name="flagZerarReajusteMinimo"
                        color="primary"
                        label="Zerar Reajuste Mínimo"
                      />
                    </Stack>
                  </PivotItem>
                  <PivotItem headerText="Config. Bônus">
                    <InputNumber
                      value={
                        !!values.multiplicadorBonus
                          ? Number(values.multiplicadorBonus)
                          : null
                      }
                      styles={{ width: 380 }}
                      onValueChange={(value) => {
                        setFieldValue(
                          "multiplicadorBonus",
                          value.floatValue ?? null
                        );
                        setFieldError("multiplicadorBonus", "");
                      }}
                      id="multiplicadorBonus"
                      name="multiplicadorBonus"
                      label="Multiplicador do Bônus"
                      helperText={errors.multiplicadorBonus}
                      error={!!errors.multiplicadorBonus}
                    />
                  </PivotItem>
                </Pivot>
              </form>
            )}
          </Formik>
        </Dialog>
      </>
    );
  }

  private _getKey(item: any, index?: number): any {
    if (item !== undefined) return item.key;
  }
}

const btnStyle: Partial<ICommandBarStyles> = {
  root: {
    height: 44,
  },
};
const validationPerformanceSchema = yup.object().shape({
  descNivel: yup.string().required("Campo obrigatório"),
  descIngles: yup.string().required("Campo obrigatório"),
  percentual: yup.number().nullable().required("Campo obrigatório"),
  cor: yup.string().required("Campo obrigatório"),
  nivel: yup.number().nullable().required("Campo obrigatório"),
  multiplicador: yup.number().nullable(),
  multiplicadorBonus: yup.number().nullable(),
  flagEnquadramento: yup.bool().nullable(),
  flagPromocao: yup.bool().nullable(),
  flagZerarReajusteMinimo: yup.bool().nullable(),
});

const mapStateToProps = (state: RootState) => ({
  cyclePerformance: state.cyclePerformanceReducer,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      ...getCyclePerformance,
      ...addCyclePerformance,
      ...editCyclePerformance,
      ...delCyclePerformance,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CyclePerformance);
