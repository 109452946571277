import { takeEvery, put, call, all } from "redux-saga/effects";
import {
    Types,
    ISuccessHomeLoadAction
} from "../ducks/home/types";
import api from "~/services/api";


const apiCallLoad = () => {
    return api
        .get(`home`)
        .then((response) => {
            return response.data;
        }).catch((err) => {
            throw err.response;
        });
};


function* load() {
    try {
        const tarefasPendentes: any = yield call(apiCallLoad);
        yield put({
            type: Types.SUCCESS_HOME_LOAD,
            ...tarefasPendentes
        } as ISuccessHomeLoadAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível carregar as tarefas pendentes'
        yield put({
            type: Types.FAILURE_HOME_LOAD,
            message
        });
    }
}

export default function* attitudesRoot() {
    yield all([
        yield takeEvery(Types.REQUEST_HOME_LOAD, load),
    ]);
}
