// #region Imports
import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

//FluentUI
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import {
  DetailsListLayoutMode,
  SelectionMode,
  Selection,
  IColumn,
} from 'office-ui-fabric-react/lib/DetailsList';
import { ShimmeredDetailsList } from 'office-ui-fabric-react/lib/ShimmeredDetailsList';
import { IconButton, PrimaryButton, DetailsHeader, IButtonStyles, Text, Spinner, TooltipHost } from 'office-ui-fabric-react';
import { ScrollablePane } from 'office-ui-fabric-react/lib/ScrollablePane';
import { Sticky } from 'office-ui-fabric-react/lib/Sticky';
import CustomDialog from "~/components/CustomDialog";
import { InputText, InputAutocomplete, InputCheckbox } from '~/components/Forms';
import MenuItem from '@material-ui/core/MenuItem';
import Dropdown from "~/components/layout/Dropdown";
import TreeView from '~/components/TreeView';
//Estilos

import { Creators as getEmployees } from "~/store/ducks/employees";

import { ChartType, DataTypes as DataTypesChart } from "~/store/ducks/admin/chart/types";
import { Creators as getChart } from "~/store/ducks/admin/chart";

import { Creators as pesquisaFunc } from '~/store/ducks/cycle'
import { Creators as resetPesquisaFunc } from '~/store/ducks/cycle'
import { PesquisaFuncType } from '~/store/ducks/cycle/types'

import { EmployeeType, DataTypes as DataTypesEmployee } from '~/store/ducks/employees/types'
import { DataTypes as DataTypesWorklevel } from '~/store/ducks/worklevel/types'

import { RootState } from "~/store/ducks";
//#endregion

interface IFormSearchState {
  columns: IColumn[];
  items: any[];
  selectionDetails: string;
  selectionCount: number;
  nomeSearch: string;
  area: Partial<ChartType>;
  isOpenArea: boolean;
  isLoading: boolean;
  lider: Partial<EmployeeType>;
  isOpenLider: boolean;
  worklevelSelected: string;
  isDialogTreeViewOpen: boolean;
  itemAreaTreeViewSelected: Partial<ChartType> | null;
};

interface IPropsFormSearch {
  areas: DataTypesChart;
  isOpen: boolean;
  employees: DataTypesEmployee;
  worklevel: DataTypesWorklevel;
  items: any[];
  loading: boolean;
  selectionMode?: SelectionMode;
  checkbox?: {
    text: string;
    value: boolean;
    setValue: (value: boolean) => void;
    disabled?: boolean;
    tooltip?: string;
  }
  searchAPI: (nomeSearh?: string, idArea?: number | null, idWorkLevel?: number | null, liderId?: number | null) => void;
  onClose: () => void;
  onAdd: (values: any[]) => void;
  getChart: (search?: string, filter?: boolean | null, treeview?: boolean) => void;
  getEmployees: (search?: string, filter?: boolean | null) => void;
  resetPesquisaFunc: () => void;
  pesquisaFunc: (idCiclo: number, nomePesq?: string, idArea?: number | null, worklevel?: number | null, idChefe?: number | null) => void;
}


class FormSearch extends Component<IPropsFormSearch, IFormSearchState> {
  private _selection: Selection;
  private timeout: number;

  constructor(props: IPropsFormSearch) {
    super(props);
    //#region Colunas
    const columns: IColumn[] = [
      {
        key: 'column3',
        name: 'Nome',
        fieldName: 'nomeCompleto',
        minWidth: 210,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: true,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
      },
      {
        key: 'column',
        name: 'Cargo',
        fieldName: 'titulo',
        minWidth: 300,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: true,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
      }
    ];
    //#endregion

    this.state = {
      columns: columns,
      items: [],
      selectionDetails: "",
      selectionCount: 0,
      nomeSearch: "",
      area: {
        idArea: null,
        nomeLocal: ""
      },
      isOpenArea: false,
      isLoading: false,
      lider: {
        idFuncionario: null,
        nomeCompleto: ''
      },
      isOpenLider: false,
      worklevelSelected: '',
      isDialogTreeViewOpen: false,
      itemAreaTreeViewSelected: null
    }

    this._selection = new Selection({
      onSelectionChanged: () => {
        this.setState({
          selectionDetails: this._getSelectionDetails(),
        });
      },
    });

    this.timeout = 0;
  };

  componentDidUpdate(prevProps: IPropsFormSearch, _: IFormSearchState) {
    const { isOpen: prevIsOpen } = prevProps;
    const { isOpen: nextIsOpen } = this.props;

    if (prevIsOpen !== nextIsOpen) {
      if (nextIsOpen) {
        this.setState({ nomeSearch: '', area: { idArea: null, nomeLocal: '' }, lider: { idFuncionario: null, nomeCompleto: '' }, worklevelSelected: '' })
        this.props.checkbox?.setValue(true)
        this.props.resetPesquisaFunc();
      }
    }
  }

  _getSelectionDetails(): any {
    const selectionCount = this._selection.getSelectedCount();
    this.setState({ selectionCount });
    this._selection.getSelection();
  };

  search = (text: string, type: string) => {
    if (text.trim()) {
      clearTimeout(this.timeout);
      this.setState({ isLoading: true })
      this.timeout = window.setTimeout(() => {
        switch (type) {
          case 'lider':
            this.props.getEmployees(text);
            break;
          case 'area':
            this.props.getChart(text, true, false)
            break;
        }
        this.setState({ isLoading: false })
      }, 700);
    }
  };

  handleOpenDialogSearch = () => {
    this.props.getChart('', true);
    this.setState({
      isDialogTreeViewOpen: true, area: {
        idArea: null,
        nomeLocal: ''
      }
    })
  }

  //#endregion

  handleEnter = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const enterClick = event.key === 'Enter';
    if (enterClick) {
      const { nomeSearch, area, worklevelSelected, lider } = this.state;
      this._selection.setAllSelected(false);
      this.props.searchAPI?.(nomeSearch, area?.idArea, worklevelSelected ? Number(worklevelSelected) : null, lider?.idFuncionario ?? null)
    }
  }

  render() {
    const { columns, nomeSearch, area, isOpenArea, isLoading, lider, isOpenLider, worklevelSelected, isDialogTreeViewOpen, itemAreaTreeViewSelected } = this.state;
    const { isOpen, onClose, onAdd, areas, employees, worklevel, loading, selectionMode, items } = this.props;

    return (
      <>
        <CustomDialog
          open={isOpen}
          title="Pesquisa de Funcionários"
          btnConfirmText="Adicionar"
          onClickCancel={onClose}
          onClickConfirm={() => {
            onAdd(this._selection.getSelection());
            // ! Tirar close()
            onClose();
          }}
          disabledBtnConfirm={this._selection.getSelectedCount() === 0}
          height={580}
          width={850}
          zIndex={1000000}
        >
          <Stack horizontalAlign="end" styles={{ root: { width: 802, position: 'fixed', zIndex: 9, backgroundColor: '#FFFFFF' } }}>
            <Stack horizontal horizontalAlign="space-between" styles={{ root: { width: '100%' } }} >
              <Stack>
                <InputText
                  label="Nome do funcionário"
                  value={nomeSearch}
                  onChange={(e) => this.setState({ nomeSearch: e.target.value })}
                  onKeyUp={this.handleEnter}
                  variant="outlined"
                  height_container={40}
                  fullWidth
                  size="small"
                  smaller="small"
                  styles={{ marginTop: 10, width: 360 }}
                  autoFocus
                />
                <InputAutocomplete
                  value={(lider as EmployeeType)}
                  onChange={(_, newValue) => {
                    this.setState({ lider: newValue! })
                  }}
                  onInputChange={(_, newInputValue) => {
                    this.search(newInputValue, 'lider');
                  }}
                  onOpen={() => this.setState({ isOpenLider: true })}
                  onClose={() => this.setState({ isOpenLider: false })}
                  loading={employees.loadingData}
                  getOptionLabel={(employee: EmployeeType) => employee.nomeCompleto}
                  getOptionSelected={(option, value) => option.idFuncionario === value.idFuncionario}
                  options={employees.data}
                  input={{
                    idInput: "funcionario",
                    labelInput: "Líder",
                    variant: 'outlined',
                    isLoading: isOpenLider && isLoading,
                    smaller: "small",
                    size: 'small',
                    height_container: 40,
                    styles: { marginTop: 10, width: 360 }
                  }}
                />
              </Stack>
              <Stack>
                <WrapperAutoComplete>
                  <InputAutocomplete
                    value={(area as ChartType)}
                    onChange={(_, newValue) => {
                      this.setState({ area: newValue! })
                    }}
                    onInputChange={(_, newInputValue) => {
                      this.search(newInputValue, 'area');
                    }}
                    onOpen={() => this.setState({ isOpenArea: true })}
                    onClose={() => this.setState({ isOpenArea: false })}
                    loading={areas.loadingData}
                    getOptionLabel={(employee: ChartType) => employee.nomeLocal}
                    getOptionSelected={(option, value) => option.idArea === value.idArea}
                    options={areas.data}
                    input={{
                      idInput: "area",
                      labelInput: "Entre com a área",
                      variant: 'outlined',
                      isLoading: isOpenArea && isLoading,
                      smaller: "small",
                      size: 'small',
                      height_container: 40,
                      styles: { marginTop: 10, width: 360 }
                    }}
                  />
                  <IconButton
                    iconProps={{ iconName: "Search" }}
                    styles={IconButtonStyle}
                    onClick={this.handleOpenDialogSearch}
                  />
                </WrapperAutoComplete>
                <Dropdown
                  label="Worklevel"
                  name="worklevel"
                  variant="outlined"
                  size="small"
                  values={worklevelSelected}
                  styles={{ height: 40, marginTop: 10 }}
                  handleChange={(e: any) => this.setState({ worklevelSelected: e.target.value })}
                >
                  <MenuItem value="">Todos</MenuItem>
                  {
                    worklevel.data.map((item, i) => (
                      <MenuItem key={i} id={`${item.idWorklevel}`} value={`${item.idWorklevel}`}>{item.descricao}</MenuItem>
                    ))
                  }
                </Dropdown>
              </Stack>
            </Stack>
            <Stack horizontal horizontalAlign="space-between" verticalAlign="end" styles={{ root: { width: '100%' } }}>
              {
                this.props.checkbox ?
                  <TooltipHost
                    content={this.props.checkbox.tooltip}
                    id="Checkbox"
                  >

                    <InputCheckbox
                      checked={this.props.checkbox.value}
                      onChange={e => this.props.checkbox?.setValue(e.target.checked)}
                      name={this.props.checkbox.text}
                      color="primary"
                      size="small"
                      disabled={this.props.checkbox.disabled}
                      label={<span style={{ fontSize: 14 }}>{this.props.checkbox.text}</span>}
                    />
                  </TooltipHost>
                  :
                  this.state.selectionCount === 0 ?
                    <Text variant="small">Nenhum funcionário selecionado</Text>
                    :
                    <Text variant="small">{`${this.state.selectionCount} funcionário${this.state.selectionCount > 1 ? 's' : ''} selecionado${this.state.selectionCount > 1 ? 's' : ''}`}</Text>

              }
              <PrimaryButton
                disabled={loading}
                text="Pesquisar"
                styles={{ root: { width: 110, marginTop: '15px !important' } }}
                onClick={() => {
                  this._selection.setAllSelected(false);

                  this.props.searchAPI?.(nomeSearch, area?.idArea, worklevelSelected ? Number(worklevelSelected) : null, lider?.idFuncionario ?? null)
                }}
              />
            </Stack>
          </Stack>
          {
            loading ?
              <Spinner label="Pesquisando" styles={{
                root: {
                  position: 'absolute',
                  top: 325,
                  left: 0,
                  right: 0,
                }
              }}
              />
              :
              <ScrollablePane styles={{
                root: {
                  top: 215,
                  height: 285,
                  margin: '0 20px'
                }
              }}>
                <ShimmeredDetailsList
                  items={items ?? []}
                  enableShimmer={loading}
                  columns={columns}
                  selectionMode={selectionMode ? selectionMode : SelectionMode.multiple}
                  selection={this._selection}
                  getKey={this._getKey}
                  selectionPreservedOnEmptyClick={true}
                  setKey="multiple"
                  layoutMode={DetailsListLayoutMode.justified}
                  isHeaderVisible={true}
                  styles={{ root: { overflowX: 'hidden' } }}
                  onRenderDetailsHeader={
                    detailsHeaderProps => {
                      return (
                        <Sticky>
                          <DetailsHeader
                            {...detailsHeaderProps}
                            layoutMode={DetailsListLayoutMode.justified}
                          />
                        </Sticky>
                      );
                    }
                  }
                />
              </ScrollablePane>
          }
        </CustomDialog>
        <CustomDialog
          open={isDialogTreeViewOpen}
          title="Escolher área"
          width={850}
          btnConfirmText="Escolher"
          onClickCancel={() => this.setState({ isDialogTreeViewOpen: false })}
          onClickConfirm={() => this.setState({
            isDialogTreeViewOpen: false,
            area: this.state.itemAreaTreeViewSelected ?? { idArea: null, nomeLocal: '' }
          })}
          disabledBtnConfirm={itemAreaTreeViewSelected === null}
          height={580}
        >
          <TreeView
            columns={[{
              name: 'Área',
              fieldName: 'nomeLocal',
            }]}
            state={areas}
            fieldId="idArea"
            changeItemsSelected={items => this.setState({ itemAreaTreeViewSelected: items[0] })}
          />
        </CustomDialog>
      </>
    );
  };

  private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    const { columns } = this.state;
    const newColumns: IColumn[] = columns.slice();
    let items: PesquisaFuncType[] = [];
    items = this.props.items ?? [];
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      };
    });
    const newItems = this._sort(items, currColumn.fieldName!, currColumn.isSortedDescending);
    this.setState({
      columns: newColumns,
      items: newItems,
    });
  };

  private _getKey(item: any, index?: number): any {
    if (item !== undefined)
      return item.key;
  };

  private _sort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
    const key = columnKey as keyof T;
    const itemsSorted = items.sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
    return itemsSorted;
  };

}

const WrapperAutoComplete = styled.div`
  position: relative;
  width: 100%;
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-endAdornment{
    right: 35px;
  }
`

const IconButtonStyle: Partial<IButtonStyles> = {
  root: {
    position: 'absolute',
    top: 18,
    right: 8,
    width: 24,
    height: 24,
    padding: 8
  },
  icon: {
    fontSize: 12,
  }
};

const mapStateToProps = (state: RootState) => ({
  employees: state.employeesReducer,
  areas: state.chartReducer,
  worklevel: state.worklevelReducer
});

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  ...getEmployees,
  ...pesquisaFunc,
  ...getChart,
  ...resetPesquisaFunc
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FormSearch);
