import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from "formik";
import * as yup from 'yup';
import colors from '~/assets/js/colors';

import { FontIcon } from 'office-ui-fabric-react/lib/Icon';
import { Persona, PersonaSize, IPersonaStyles } from 'office-ui-fabric-react/lib/Persona';
import { Text } from 'office-ui-fabric-react/lib/Text';
import { HoverCard, HoverCardType, IFontWeight, IHoverCard } from 'office-ui-fabric-react';


import { Container, WrapperBoxCheck, WrapperBox, TrFooter, Tr, TdFull, Table, TdFooter, Div, WrapperPersona, WrapperPerson, TdBox, WrapperPersonaInfo } from './styles';
import Colors from '~/assets/js/colors';
import { Creators } from '~/store/ducks/calibration';
import { CurvaType, AvaliadosCurvaType, DataTypes as DataTypesCalibration } from '~/store/ducks/calibration/types';
import { TabPerfomanceType } from '~/store/ducks/tasks/types';
import { getAbbreviatedName } from '~/utils/abbreviatedName';
import { RootState } from '~/store/ducks';

import DialogCalibration from '../DialogCalibration';
import { Stack } from 'office-ui-fabric-react';
import Dropdown from '~/components/layout/Dropdown';
import { MenuItem } from '@material-ui/core';
import { InputCheckbox } from '~/components/Forms';

interface IPerformanceProps {
  idCicloReuniao: number;
  curva: CurvaType[];
  readOnly: boolean;
  performances: TabPerfomanceType[];
  curveType: "calibrada" | "inicial";
  setCurveType: (values: "calibrada" | "inicial") => void;
  showIdentification: boolean;
  setShowIdentification: (value: boolean) => void;
}

const validationSchema = yup.object().shape({
  perfomance: yup.string()
    .required("Campo obrigatório"),
  potencial: yup.string()
    .required("Campo obrigatório"),
  observacao: yup.string().required("Campo obrigatório")
});

interface AvaliadoCurvaSelectedType {
  avaliadoCurva: AvaliadosCurvaType;
  idTabPerformance: number;
  performance: {
    cor: string;
    descNivel: string;
  }
}

const Perfomance: React.FC<IPerformanceProps> = (props) => {
  const { curva, performances, curveType, setCurveType, showIdentification, setShowIdentification, readOnly } = props;

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [avaliadoCurva, setAvaliadoCurva] = useState<AvaliadoCurvaSelectedType | null>(null);
  const calibrationState = useSelector<RootState, DataTypesCalibration>(state => state.calibrationReducer)
  const { avaliadoSelected } = calibrationState;
  const { avaliadoCalib } = avaliadoSelected;
  const dispatch = useDispatch();


  useEffect(() => {
    if (calibrationState.avaliadoSelected.success) {
      setIsDialogOpen(false);
    }
  }, [calibrationState.avaliadoSelected])

  useEffect(() => {
    props.setShowIdentification(false)
  }, [])

  const {
    resetForm,
  } = useFormik({
    initialValues: {
      perfomance: '',
      potencial: '',
      observacao: ''
    },
    validationSchema,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit(values) {
      setIsDialogOpen(false)
      resetForm()
    }
  });

  return (
    <Container>
      <Stack horizontal horizontalAlign="space-between" styles={{ root: { width: '100%' } }}>
        <Stack horizontal verticalAlign="center">
          <Text variant="medium" styles={{ root: { marginRight: 20, marginLeft: 10 } }}>Curva</Text>
          <Stack styles={{ root: { width: 200 } }}>
            <Dropdown
              label=""
              name="curva"
              values={curveType}
              variant="outlined"
              handleChange={(e: any) => setCurveType(e.target.value)}
              autofocus
              fieldHeight={35}
              noHelperText
              styles={{ height: 35 }}
              paddingSelected="5px 18px"
            >
              <MenuItem value="inicial">Inicial</MenuItem>
              <MenuItem value="calibrada">Calibrada</MenuItem>
            </Dropdown>
          </Stack>
        </Stack>
        <InputCheckbox
          checked={showIdentification}
          onChange={(e) => setShowIdentification(e.target.checked)}
          styles={{ fontSize: 14, margin: 0 }}
          name="identification"
          size="small"
          color="primary"
          label="Exibir identificação"
        />
      </Stack>
      <Stack styles={{ root: { marginTop: 20 } }}>
        <TableCurvaPerformance
          curveType={curveType}
          curva={curva}
          readOnly={false}
          showNivel={true}
          showIdentification={showIdentification}
          handleClick={(idCicloReuniaoAvaliado, avaliado, funcionario, performance) => {
            if (showIdentification && funcionario) {
              dispatch(Creators.getComments(props.idCicloReuniao, idCicloReuniaoAvaliado));
              dispatch(Creators.loadAvaliado(props.idCicloReuniao, idCicloReuniaoAvaliado))
              setAvaliadoCurva({
                avaliadoCurva: avaliado,
                idTabPerformance: performance.idTabPerformance,
                performance: {
                  cor: performance.cor,
                  descNivel: performance.descNivel
                }
              })
              setIsDialogOpen(true);
            }
          }}
        />
      </Stack>

      <DialogCalibration
        isOpen={isDialogOpen}
        loadingData={calibrationState.avaliadoSelected.loading}
        success={calibrationState.avaliadoSelected.successLoading}
        initialValues={{
          nomeCompleto: avaliadoCalib?.avaliado?.funcionario?.nomeCompleto ?? '',
          idTabPerformance: avaliadoCalib?.avaliado?.idTabPerformance ?? null,
          potencial: avaliadoCalib?.avaliado?.potencial ?? '',
          sucessao: avaliadoCalib?.avaliado?.sucessao ?? '',
          carreira: avaliadoCalib?.avaliado?.carreira ?? '',
          proximoCiclo: avaliadoCalib?.avaliado?.proximoCiclo ?? '',
          performance: {
            descNivel: avaliadoCalib?.avaliado?.avaliacaoGestor?.performancegestor?.descNivel ?? '',
            cor: avaliadoCalib?.avaliado?.avaliacaoGestor?.performancegestor?.cor ?? '',
            nivel: avaliadoCalib?.avaliado?.performance?.nivel ?? null,
          },
          avaliacaoGestor: {
            idTabPerformance: Number(avaliadoCalib?.avaliado?.avaliacaoGestor?.idTabPerformanceGestor) ?? null,
            performancegestor: avaliadoCalib?.avaliado?.avaliacaoGestor?.performancegestor,
            potencial: avaliadoCalib?.avaliado?.avaliacaoGestor?.potencial ?? '',
            sucessao: avaliadoCalib?.avaliado?.avaliacaoGestor?.sucessao ?? ''
          },
          aspiracoesCarreira: avaliadoCalib?.avaliado?.avaliacaoGestor?.aspiracoesCarreira ?? '',
          anotacoes: avaliadoCalib?.comentarios ?? [],
        }}
        onClose={() => setIsDialogOpen(false)}
        onSuccess={(values) => {
          const nivelPerformance = avaliadoCalib?.avaliado?.performance?.nivel;
          const nivelOriginal = (nivelPerformance !== null && nivelPerformance !== undefined) ? nivelPerformance : Number(avaliadoCalib?.avaliado?.avaliacaoGestor?.performancegestor.nivel) ?? null;
          const sucessaoOriginal = avaliadoCalib?.avaliado?.sucessao ? avaliadoCalib?.avaliado?.sucessao : avaliadoCalib?.avaliado?.avaliacaoGestor?.sucessao ?? "";

          dispatch(Creators.saveAvaliado(props.idCicloReuniao, avaliadoCurva?.avaliadoCurva.idCicloReuniaoAvaliado!, values, nivelOriginal!, sucessaoOriginal))
        }}
        readonly={readOnly}
        performances={performances}
      />
    </Container>
  );
}

interface IBoxProps {
  thinFont?: boolean;
  fontSize?: number;
  color?: string;
  title?: string;
  value: string;
  subText?: {
    text: string | JSX.Element;
    color?: string;
    fontWeight?: IFontWeight;
  };
}

const Box: React.FC<IBoxProps> = (props) => {
  let { color, title, value, subText, thinFont, fontSize } = props;

  return (
    <WrapperBox color={color}>
      {title &&
        <Text variant="smallPlus" styles={{ root: { fontWeight: 'bold', textTransform: 'uppercase' } }}>{title}</Text>
      }
      <Text style={{ fontSize: fontSize ? fontSize : 50, fontWeight: thinFont ? 400 : 'bold' }}>{value}</Text>
      {subText &&
        <Text variant="medium" styles={{ root: { color: subText?.color ?? '', fontWeight: subText?.fontWeight } }}>{subText.text}</Text>
      }
    </WrapperBox>
  )
}

const BoxCheck: React.FC = () => {
  return (
    <WrapperBoxCheck>
      <FontIcon iconName="VerifiedBrandSolid" style={{ fontSize: 50, color: colors.primary }} />
    </WrapperBoxCheck>
  )
}

interface IPersonProps {
  deslocamento: number;
  imagePath: string;
  initials: string;
  name: string;
  isChecked: boolean;
  showIdentification: boolean;
  readonly: boolean;
  showNivel: boolean;
  handleClick: () => void;
}

const Person: React.FC<IPersonProps> = (props) => {
  const { imagePath, initials, name, handleClick, deslocamento, isChecked, showIdentification, readonly, showNivel } = props;

  const personaStyle: Partial<IPersonaStyles> = {
    root: {
      width: 32,
      marginRight: 5,
      marginBottom: 5,
      cursor: showIdentification && !readonly ? 'pointer' : 'normal',
      // filter: deslocamento !== 0 || isChecked ? 'grayscale(1)' : '',
      // opacity: deslocamento !== 0 || isChecked ? 0.5 : 1,
    }
  }

  return (
    <WrapperPerson onClick={handleClick} showIdentification={showIdentification}>
      <Persona
        imageUrl={imagePath}
        imageInitials={initials}
        initialsColor={Colors.primary}
        size={PersonaSize.size32}
        imageAlt={name}
        //title={name}
        styles={personaStyle}
      />

      {
        showNivel &&
        <>
          {
            deslocamento !== 0 ?
              <div style={{
                backgroundColor: deslocamento < 0 ? '#B30000' : '#07C81A',
                top: -5,
                right: 0,
                position: 'absolute',
                width: 16,
                height: 16,
                borderRadius: '50%',
                fontSize: 10,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: '#FFFFFF'
              }}>
                {Math.abs(deslocamento)}
              </div>
              :
              isChecked &&
              <div style={{
                backgroundColor: '#07C81A',
                top: -5,
                right: 0,
                position: 'absolute',
                width: 16,
                height: 16,
                borderRadius: '50%',
                fontSize: 10,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: '#FFFFFF'
              }}>
                <FontIcon iconName="SkypeCheck" style={{ color: '#FFFFFF', position: 'absolute' }} />
              </div>
          }
        </>
      }
    </WrapperPerson >
  )
}

interface ICurvaPerformanceProps {
  curveType: 'calibrada' | 'inicial';
  curva: CurvaType[];
  showIdentification: boolean;
  readOnly: boolean;
  showNivel: boolean;
  handleClick?: (idCicloReuniaoAvaliado: number, avaliado: AvaliadosCurvaType, funcionario: AvaliadosCurvaType["funcionario"], performance: TabPerfomanceType) => void;
  language?: 'PT' | 'EN';
}

interface ILanguageHeader {
  current: string;
  employees: string;
  diff: string;
}

export const TableCurvaPerformance: React.FC<ICurvaPerformanceProps> = (props) => {
  const { curveType, curva, showIdentification, handleClick, readOnly, showNivel, language } = props;
  const headersPT: ILanguageHeader = {
    current: 'ATUAL',
    employees: 'Funcionários',
    diff: 'Diferença'
  }
  const headersEN: ILanguageHeader = {
    current: 'CURRENT',
    employees: 'Employees',
    diff: 'Diff'
  }

  const headers = language === 'EN' ? headersEN : headersPT;

  const baseURL = process.env.REACT_APP_BASE_URL;
  const hoverCard = React.useRef<IHoverCard>(null);


  const renderCurvaRows = () => {
    return curva.map((item, i) => {
      const { qtdeTarget, percentual, curvaAjustada, curvaInicial, idTabPerformance, percentInicial, descNivel, cor, descIngles } = item;
      const totalAvaliadosInicial = curvaInicial.length;
      const curvaSelected = curveType === "calibrada" ? curvaAjustada : curvaInicial;
      const totalAvaliados = curvaSelected.length - qtdeTarget;
      const signal = totalAvaliados > 0 ? '+' : '';
      const allAvaliadosCurva = curva?.flatMap(item => item.curvaAjustada);
      const descricao = language === 'EN' ? descIngles : descNivel;

      return (
        <Tr key={i}>
          <TdBox
            bgColor={Colors.white}
            borderColor={"#666666"}>
            <Box
              color={"#666666"}
              title={descricao}
              value={curveType === 'calibrada' ? `${curvaSelected.length}` : `${totalAvaliadosInicial}`}
              subText={{
                text: curveType === 'calibrada' ? <p>
                  <span style={{ color: '#323130', fontWeight: 400 }}>
                    {`${Math.round(curvaSelected.length * 100 / allAvaliadosCurva.length)}%`}
                  </span>
                </p>
                  :
                  `${percentInicial}%`,
              }}
            />
          </TdBox>
          <TdFull>
            <WrapperPersona>
              {
                curvaSelected.map((avaliado, j) => {
                  const { funcionario, deslocamento, idCicloReuniaoAvaliado, idTabPerformance: idTabPerformanceAvaliado, potencial, cargo, nivel } = avaliado;
                  const imagePath = `${baseURL}uploads/${funcionario?.imagem}`;
                  const initials = getAbbreviatedName(funcionario?.nomeCompleto);

                  const onRenderPlainCard = (): JSX.Element => {
                    return (
                      <WrapperPersonaInfo>
                        <Stack styles={{ root: { height: 'auto' } }}>
                          <Stack horizontal>
                            <Persona
                              imageUrl={imagePath}
                              imageInitials={initials}
                              initialsColor={Colors.primary}
                              size={PersonaSize.size56}
                              styles={{ root: { width: 56, marginRight: 10 } }}
                            />
                            <Stack>
                              <Text variant="medium" styles={{ root: { fontWeight: 'bold' } }}>{funcionario?.nomeCompleto}</Text>
                              <Text variant="smallPlus">{cargo?.titulo}</Text>
                            </Stack>
                          </Stack>
                          {
                            potencial &&
                            <Stack horizontal horizontalAlign="end" verticalAlign="center" styles={{ root: { height: '100%', marginTop: '10px !important' } }}>
                              <FontIcon iconName="ChevronUpSmall" style={{ marginRight: 5 }} />
                              <Text styles={{ root: { fontSize: 11, fontWeight: '100', lineHeight: 20 } }}>{potencial}</Text>
                            </Stack>
                          }
                        </Stack>
                      </WrapperPersonaInfo>
                    );
                  };

                  return (
                    <HoverCard
                      key={j}
                      type={HoverCardType.plain}
                      plainCardProps={{ onRenderPlainCard }}
                      componentRef={hoverCard}
                      hidden={!showIdentification}
                    >

                      <Person
                        showIdentification={showIdentification}
                        name={showIdentification ? funcionario?.nomeCompleto : ''}
                        initials={showIdentification ? initials : '?'}
                        imagePath={showIdentification ? imagePath ?? '' : ''}
                        deslocamento={deslocamento ?? 0}
                        readonly={readOnly}
                        isChecked={idTabPerformanceAvaliado !== null}
                        showNivel={showNivel}
                        handleClick={() => handleClick?.(idCicloReuniaoAvaliado, avaliado, funcionario, { cor, descNivel, idTabPerformance, nivel })}
                      />
                    </HoverCard>
                  )
                })
              }
            </WrapperPersona>
          </TdFull>
          <TdBox
            bgColor="#FFFFFF"
            borderColor={cor}>
            {
              totalAvaliados === 0 ?
                <BoxCheck />
                :
                <Box
                  color={cor}
                  subText={{
                    text: curveType === 'inicial' ? (<p>
                      <span>{`${curvaSelected.length}`} </span>
                      <span style={{ color: '#323130', fontWeight: 400 }}>
                        {`(${Math.round(curvaSelected.length * 100 / allAvaliadosCurva.length)}%)`}
                      </span>
                    </p>)
                      :
                      ''
                    ,
                    color: colors.error,
                    fontWeight: '600'
                  }}
                  value={`${signal}${totalAvaliados}`}
                />
            }
          </TdBox>
          <TdBox
            bgColor={cor}
            borderColor={cor}>
            <Box
              color={colors.white}
              title={descricao}
              value={String(qtdeTarget)}
              subText={{ text: `${percentual}%` }}
            />
          </TdBox>
        </Tr>
      )
    })
  }

  return (
    <Table>
      <tbody>
        <TrFooter>
          <TdFooter>
            <Div>
              {
                curveType === 'calibrada' ?
                  <Text styles={{ root: { color: '#FFFFFF', fontWeight: 500 } }}>{headers.current}</Text>
                  :
                  <img src="/static/icons/finish-flag.svg" alt="Ícone" />
              }
            </Div>
          </TdFooter>
          <TdFooter bgColor="#FFFFFF">
            <Div>
              {headers.employees}
            </Div>
          </TdFooter>
          <TdFooter bgColor="#FFFFFF">
            <Div>
              {headers.diff}
            </Div>
          </TdFooter>
          <TdFooter>
            <Div>
              <img src="/static/icons/dot-and-circle.svg" alt="Ícone diferença" />
            </Div>
          </TdFooter>
        </TrFooter>
        {
          renderCurvaRows()
        }
        <TrFooter>
          <TdFooter>
            <Div>
              {
                curveType === 'calibrada' ?
                  <Text styles={{ root: { color: '#FFFFFF', fontWeight: 500 } }}>{headers.current}</Text>
                  :
                  <img src="/static/icons/finish-flag.svg" alt="Ícone" />
              }
            </Div>
          </TdFooter>
          <TdFooter bgColor="#FFFFFF" style={{ width: '100%' }}>
            <Div>
              {headers.employees}
            </Div>
          </TdFooter>
          <TdFooter bgColor="#FFFFFF">
            <Div>
              {headers.diff}
            </Div>
          </TdFooter>
          <TdFooter>
            <Div>
              <img src="/static/icons/dot-and-circle.svg" alt="Ícone diferença" />
            </Div>
          </TdFooter>
        </TrFooter>
      </tbody>
    </Table>
  )
}

export default Perfomance;
