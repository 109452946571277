import { takeEvery, put, call, all } from "redux-saga/effects";
import {
    Types,
    DismembermentType,
    MetaDismembermentType,
    IRequestDismembermentLoadAction,
    IRequestDismembermentChildrenLoadAction,
    IRequestDismembermentAddPesoAction,
    IRequestDismembermentEditPesoAction,
    IRequestDismembermentDeletePesoAction,
    IRequestDismembermentAddMetaAction,
    IRequestDismembermentEditMetaAction,
    IRequestDismembermentDeleteMetaAction,
    IRequestDismembermentAddInheritedAreaAction,
    IRequestDismembermentGetMetaIDAction,
    ISuccessDismembermentLoadAction,
    ISuccessDismembermentChildrenLoadAction,
    ISuccessDismembermentAddPesoAction,
    ISuccessDismembermentEditPesoAction,
    ISuccessDismembermentDeletePesoAction,
    ISuccessDismembermentAddMetaAction,
    ISuccessDismembermentEditMetaAction,
    ISuccessDismembermentDeleteMetaAction,
    ISuccessDismembermentAddInheritedAreaAction,
    ISuccessDismembermentGetMetaIDAction
} from "../ducks/cycle/dismemberment/types";

import {
    IRequestCyclesGetMetaIdObjectiveAction,
    MetaType
} from "../ducks/cycle/painel/types";

import {
    apiAddMeta,
    apiEditMeta,
    apiDelMeta,
    apiAddPeso,
    apiEditPeso,
    apiDeletePeso,

} from "./cyclePainel";

import api from "~/services/api";

const apiLoadDismemberment = (payload: IRequestDismembermentLoadAction["payload"]) => {
    return api.get(`ciclos/${payload.idCiclo}/desmembramento/${payload.idArea}`)
        .then(response => response.data)
        .catch(err => {
            throw err.response;
        });
}

const apiLoadDismembermentChildren = (payload: IRequestDismembermentChildrenLoadAction["payload"]) => {
    const { idCiclo, idArea, idCicloPeso } = payload;
    return api.get(`ciclos/${idCiclo}/desmembramento/${idArea}/${idCicloPeso}`)
        .then(response => response.data)
        .catch(err => {
            throw err.response;
        });
}

const apiGetMetaIdObjective = (payload: IRequestCyclesGetMetaIdObjectiveAction["payload"]) => {
    return api.get(`ciclos/${payload.idCiclo}/objetivo/${payload.idObjective}`)
        .then(response => response.data)
        .catch(err => {
            throw err.response;
        });
};


const apiGetMetaId = (payload: IRequestDismembermentGetMetaIDAction["payload"]) => {
    return api.get(`ciclos/${payload.idCiclo}/metas/${payload.idCicloMeta}`)
        .then(response => response.data)
        .catch(err => {
            throw err.response;
        });
};


function* loadDismemberment(action: IRequestDismembermentLoadAction) {
    try {
        const data: DismembermentType = yield call(apiLoadDismemberment, action.payload);
        const { idArea, nomeLocal } = action.payload;
        yield put({
            type: Types.SUCCESS_DISMEMBERMENT_LOAD,
            payload: {
                data,
                idArea,
                nomeLocal
            }
        } as ISuccessDismembermentLoadAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível carregar o desmembramento dos pesos';
        yield put({
            type: Types.FAILURE_DISMEMBERMENT_LOAD,
            message
        });
    };
};

function* loadDismembermentChildren(action: IRequestDismembermentChildrenLoadAction) {
    try {
        const vFilhos = yield call(apiLoadDismembermentChildren, action.payload);
        yield put({
            type: Types.SUCCESS_DISMEMBERMENT_CHILDREN_LOAD,
            payload: {
                ...vFilhos,
                idCicloPeso: action.payload.idCicloPeso,
            }
        } as ISuccessDismembermentChildrenLoadAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível carregar as áreas filhos';
        yield put({
            type: Types.FAILURE_DISMEMBERMENT_CHILDREN_LOAD,
            payload: {
                message,
                idCicloPeso: action.payload.idCicloPeso
            }
        });
    };
};

function* addDismemberment(action: IRequestDismembermentAddPesoAction) {
    try {
        const idCicloPeso = yield call(apiAddPeso, action.payload);
        const { peso } = action.payload;
        yield put({
            type: Types.SUCCESS_DISMEMBERMENT_ADD_PESO,
            payload: {
                newPeso: {
                    ...idCicloPeso,
                    idObjetivo: peso.idObjetivo,
                    peso: peso.peso,
                    flagInativo: peso.flagInativo
                },
                idArea: peso.idArea,
                idCicloPesoPai: peso.idCicloPesoPai,
                idObjetivo: peso.idObjetivo,
            }
        } as ISuccessDismembermentAddPesoAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível adicionar peso';
        yield put({
            type: Types.FAILURE_DISMEMBERMENT_ADD_PESO,
            message
        });
    };
};

function* editDismemberment(action: IRequestDismembermentEditPesoAction) {
    try {
        yield call(apiEditPeso, action.payload);
        const { peso } = action.payload;
        yield put({
            type: Types.SUCCESS_DISMEMBERMENT_EDIT_PESO,
            payload: {
                newPeso: {
                    idCicloPeso: peso.idCicloPeso,
                    idObjetivo: peso.idObjetivo,
                    peso: peso.peso,
                    flagInativo: peso.flagInativo
                },
                idCicloPesoPai: peso.idCicloPesoPai,
                idArea: peso.idArea,
                idObjetivo: peso.idObjetivo
            }
        } as ISuccessDismembermentEditPesoAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível editar o peso';
        yield put({
            type: Types.FAILURE_DISMEMBERMENT_EDIT_PESO,
            message
        });
    };
};

function* deleteDismemberment(action: IRequestDismembermentDeletePesoAction) {
    try {
        yield call(apiDeletePeso, action.payload);
        const { idArea, idCicloPesoPai, idObjetivo } = action.payload;
        yield put({
            type: Types.SUCCESS_DISMEMBERMENT_DELETE_PESO,
            payload: {
                idCicloPesoPai: idCicloPesoPai,
                idArea,
                idObjetivo
            }
        } as ISuccessDismembermentDeletePesoAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível excluír o peso';
        yield put({
            type: Types.FAILURE_DISMEMBERMENT_DELETE_PESO,
            message
        });
    };
};

function* addMetaDismemberment(action: IRequestDismembermentAddMetaAction) {
    try {
        const idCicloMeta = yield call(apiAddMeta, action.payload);
        const { meta: metaPayload, idArea, idCicloPesoPai, idObjetivo } = action.payload;
        const { minimo, target, maximo } = metaPayload;
        const meta: MetaDismembermentType = {
            ...metaPayload,
            ...idCicloMeta,
            minimo: minimo!,
            target: target!,
            maximo: maximo!
        }
        yield put({
            type: Types.SUCCESS_DISMEMBERMENT_ADD_META,
            payload: {
                meta,
                idCicloPesoPai,
                idArea,
                idObjetivo
            }
        } as ISuccessDismembermentAddMetaAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível adicionar a meta';
        yield put({
            type: Types.FAILURE_DISMEMBERMENT_ADD_META,
            message
        });
    };
};

function* editMetaDismemberment(action: IRequestDismembermentEditMetaAction) {
    try {
        yield call(apiEditMeta, action.payload);
        const { meta: metaPayload, idArea, idCicloPesoPai, idObjetivo, } = action.payload;
        const { minimo, target, maximo, idCicloMeta, avaliacao, flagInativo} = metaPayload;
        const meta: MetaDismembermentType = {
            ...metaPayload,
            idCicloMeta: idCicloMeta!,
            minimo: minimo!,
            target: target!,
            maximo: maximo!,
            avaliacao: avaliacao!,
            flagInativo: flagInativo!
        }
        yield put({
            type: Types.SUCCESS_DISMEMBERMENT_EDIT_META,
            payload: {
                meta,
                idCicloPesoPai,
                idArea,
                idObjetivo
            }
        } as ISuccessDismembermentEditMetaAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível editar a meta';
        yield put({
            type: Types.FAILURE_DISMEMBERMENT_EDIT_META,
            message
        });
    };
};

function* deleteMetaDismemberment(action: IRequestDismembermentDeleteMetaAction) {
    try {
        yield call(apiDelMeta, action.payload);
        const { idArea, idCicloPesoPai, idCicloMeta, idObjetivo } = action.payload;
        yield put({
            type: Types.SUCCESS_DISMEMBERMENT_DELETE_META,
            payload: {
                idCicloPesoPai,
                idArea,
                idCicloMeta,
                idObjetivo
            }
        } as ISuccessDismembermentDeleteMetaAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível excluír a meta';
        yield put({
            type: Types.FAILURE_DISMEMBERMENT_DELETE_META,
            message
        });
    };
};

function* dismembermentAddInheritedArea(action: IRequestDismembermentAddInheritedAreaAction) {
    try {
        let meta: MetaDismembermentType[] = [{
            idCicloMeta: 0,
            maximo: '',
            target: '',
            minimo: '',
            avaliacao: null,
            flagInativo: null
        }]

        if (action.payload.objective && action.payload.objective?.idObjetivo !== 0) {
            const resultMeta = yield call(apiGetMetaIdObjective, {
                idCiclo: action.payload.idCiclo,
                idObjective: action.payload.objective.idObjetivo
            } as IRequestCyclesGetMetaIdObjectiveAction["payload"]);

            if (resultMeta.length === 1) {
                meta = resultMeta;
            }
        }

        const { idArea, parentArea, objective } = action.payload;
        yield put({
            type: Types.SUCCESS_DISMEMBERMENT_ADD_INHERITED_AREA,
            payload: {
                idArea,
                parentArea,
                objective,
                meta: meta[0]
            }
        } as ISuccessDismembermentAddInheritedAreaAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível adicionar objetivo';
        yield put({
            type: Types.FAILURE_DISMEMBERMENT_ADD_INHERITED_AREA,
            message
        });
    };
};

function* loadMetaID(action: IRequestDismembermentGetMetaIDAction) {
    try {
        const meta: MetaType = yield call(apiGetMetaId, action.payload);
        yield put({
            type: Types.SUCCESS_DISMEMBERMENT_META_LOAD_BY_ID,
            payload: meta
        } as ISuccessDismembermentGetMetaIDAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível carregar a meta';
        yield put({
            type: Types.FAILURE_DISMEMBERMENT_META_LOAD_BY_ID,
            message
        });
    };
};

export default function* companiesRoot() {
    yield all([
        yield takeEvery(Types.REQUEST_DISMEMBERMENT_LOAD, loadDismemberment),
        yield takeEvery(Types.REQUEST_DISMEMBERMENT_CHILDREN_LOAD, loadDismembermentChildren),
        yield takeEvery(Types.REQUEST_DISMEMBERMENT_ADD_PESO, addDismemberment),
        yield takeEvery(Types.REQUEST_DISMEMBERMENT_EDIT_PESO, editDismemberment),
        yield takeEvery(Types.REQUEST_DISMEMBERMENT_DELETE_PESO, deleteDismemberment),
        yield takeEvery(Types.REQUEST_DISMEMBERMENT_ADD_META, addMetaDismemberment),
        yield takeEvery(Types.REQUEST_DISMEMBERMENT_EDIT_META, editMetaDismemberment),
        yield takeEvery(Types.REQUEST_DISMEMBERMENT_DELETE_META, deleteMetaDismemberment),
        yield takeEvery(Types.REQUEST_DISMEMBERMENT_ADD_INHERITED_AREA, dismembermentAddInheritedArea),
        yield takeEvery(Types.REQUEST_DISMEMBERMENT_META_LOAD_BY_ID, loadMetaID),

    ]);
};