import {
  Types,
  DataTypes,
  MeasureActionTypes,
  EducationalMeasureType,
} from "./types";
import customToast from "~/components/Toast/index";

/**
 * Reducers
 */
const initialData = {
  assinaturaNecessaria: false,
  baixouFormulario: false,
  dataOcorrencia: null,
  descricao: "",
  idConduta: null,
  idSancao: null,
  justificativa: "",
  orientacao: "",
  planoAcao: "",
  testemunhas: [],
  anexos: [],
};

const INITIAL_STATE: DataTypes = {
  data: initialData,
  suggestion: {
    flagAlertaJustaCausa: false,
    idSancaoSugerida: null,
    somaSancoes: 0,
  },
  loading: false,
  loadingAction: false,
  loadingFicha: false,
  errorAnexos: false,
  error: false,
  success: false,
  redirect: false,
};

/**
 * Actions
 */

export const Creators = {
  loadMedida: (
    idFuncionario: number | string,
    idMedidaEducativa: number | string
  ): MeasureActionTypes => ({
    type: Types.REQUEST_MEASURE_LOAD,
    payload: {
      idFuncionario,
      idMedidaEducativa,
    },
  }),

  addMedida: (
    measure: EducationalMeasureType,
    files: File[]
  ): MeasureActionTypes => ({
    type: Types.REQUEST_MEASURE_ADD,
    payload: {
      medida: measure,
      files,
    },
  }),

  loadSuggestionSanction: (
    idFuncionario: number,
    dataOcorrencia: Date,
    idConduta: number
  ): MeasureActionTypes => ({
    type: Types.REQUEST_MEASURE_SUGGESTED_SANCTION_LOAD,
    payload: {
      idFuncionario,
      dataOcorrencia,
      idConduta,
    },
  }),

  addEvidence: (
    idFuncionario: number,
    idMedidaEducativa: number,
    files: File[]
  ): MeasureActionTypes => ({
    type: Types.REQUEST_MEASURE_EVIDENCE_ADD,
    payload: {
      idFuncionario,
      idMedidaEducativa,
      files,
    },
  }),

  addAssinatura: (
    idFuncionario: number | string,
    idMedidaEducativa: number | string,
    file: File
  ): MeasureActionTypes => ({
    type: Types.REQUEST_MEASURE_ASSINATURA_ADD,
    payload: {
      idFuncionario,
      idMedidaEducativa,
      file,
    },
  }),

  geraForm: (
    idFuncionario: number | string,
    idMedidaEducativa: number | string
  ): MeasureActionTypes => ({
    type: Types.REQUEST_MEASURE_GERA_FORM,
    payload: {
      idFuncionario,
      idMedidaEducativa,
    },
  }),

  clear: (): MeasureActionTypes => ({
    type: Types.REQUEST_MEASURE_CLEAR,
  }),
};

export default function measureReducer(
  state = INITIAL_STATE,
  action: MeasureActionTypes
): DataTypes {
  switch (action.type) {
    case Types.REQUEST_MEASURE_LOAD:
      return {
        ...state,
        error: false,
        success: false,
        loading: true,
        redirect: false,
      };
    case Types.SUCCESS_MEASURE_LOAD:
      return {
        ...state,
        error: false,
        success: true,
        data: action.payload,
        loading: false,
        redirect: false,
      };
    case Types.FAILURE_MEASURE_LOAD:
      customToast.error(action.message);
      return {
        ...state,
        error: true,
        success: false,
        loading: false,
        redirect: true,
      };

    case Types.REQUEST_MEASURE_ADD:
      return {
        ...state,
        error: false,
        success: false,
        loadingAction: true,
        redirect: false,
      };
    case Types.SUCCESS_MEASURE_ADD:
      return {
        ...state,
        error: false,
        success: true,
        data: action.measure,
        loadingAction: false,
        redirect: true,
      };
    case Types.FAILURE_MEASURE_ADD:
      customToast.error(action.message);
      return {
        ...state,
        error: true,
        success: false,
        loadingAction: false,
        redirect: false,
      };

    case Types.REQUEST_MEASURE_SUGGESTED_SANCTION_LOAD:
      return {
        ...state,
        error: false,
        success: false,
        loadingAction: true,
        redirect: false,
      };
    case Types.SUCCESS_MEASURE_SUGGESTED_SANCTION_LOAD:
      return {
        ...state,
        error: false,
        success: true,
        suggestion: action.suggestion,
        loadingAction: false,
      };
    case Types.FAILURE_MEASURE_SUGGESTED_SANCTION_LOAD:
      customToast.error(action.message);
      return {
        ...state,
        error: true,
        success: false,
        loadingAction: false,
      };

    //EVIDENCE_ADD
    case Types.REQUEST_MEASURE_EVIDENCE_ADD:
      return {
        ...state,
        errorAnexos: false,
        success: false,
        loadingAction: true,
        redirect: false,
      };
    case Types.SUCCESS_MEASURE_EVIDENCE_ADD:
      return {
        ...state,
        errorAnexos: false,
        success: true,
        data: {
          ...state.data,
          statusmedida: action.evidences.novoStatus,
          anexos: action.evidences.arquivosAnexados,
        },
        loadingAction: false,
      };
    case Types.FAILURE_MEASURE_EVIDENCE_ADD:
      customToast.error(action.message);
      return {
        ...state,
        errorAnexos: true,
        success: false,
        loadingAction: false,
      };

    //REQUEST_MEASURE_ASSINATURA_ADD
    case Types.REQUEST_MEASURE_ASSINATURA_ADD:
      return {
        ...state,
        errorAnexos: false,
        success: false,
        loadingAction: true,
        redirect: false,
      };
    case Types.SUCCESS_MEASURE_ASSINATURA_ADD:
      customToast.success("Medida educativa registrada com sucesso!");
      return {
        ...state,
        errorAnexos: false,
        success: true,
        data: {
          ...state.data,
          statusmedida: action.payload.novoStatus,
          anexoassinatura: action.payload.anexoassinatura,
        },
        loadingAction: false,
      };
    case Types.FAILURE_MEASURE_ASSINATURA_ADD:
      customToast.error(action.message);
      return {
        ...state,
        errorAnexos: true,
        success: false,
        loadingAction: false,
      };

    //GERA FORM
    case Types.REQUEST_MEASURE_GERA_FORM:
      return { ...state, loadingFicha: true };
    case Types.SUCCESS_MEASURE_GERA_FORM:
      return { ...state, loadingFicha: false };
    case Types.FAILURE_MEASURE_GERA_FORM:
      customToast.error(action.message);
      return { ...state, loadingFicha: false };
    //CLEAR
    case Types.REQUEST_MEASURE_CLEAR:
      return { ...state, redirect: false, data: initialData, suggestion: {
        idSancaoSugerida: null,
        flagAlertaJustaCausa: false,
        somaSancoes: 0
      }};

    default:
      return state;
  }
}
