import React from "react";
import * as yup from "yup";
import { Formik } from "formik";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { EmployeeType } from "~/store/ducks/employees/types";

import { FormHeaderStyle } from "~/components/FormGroup";
import Dropdown from "~/components/layout/Dropdown";
import MenuItem from '@material-ui/core/MenuItem';

interface IFormAcesso {
    initialValues: EmployeeType;
    formRef: any;
    success: (flagAcessoRHR: boolean, descAcesso: string) => void;
}

const validationFormAcessoSchema = yup.object().shape({
    flagAcessoRHR: yup.bool().notRequired(),
    descAcesso: yup.string().notRequired(),

});

const FormAcesso: React.FC<IFormAcesso> = (props) => {
    const { formRef, initialValues } = props;

    return (
        <>
            <FormHeaderStyle text="Gerenciar acesso" />
            <Formik
                enableReinitialize
                innerRef={formRef}
                initialValues={initialValues}
                validationSchema={validationFormAcessoSchema}
                validateOnChange={false}
                validateOnBlur
                onSubmit={(values: EmployeeType) => {
                    props.success(values.flagAcessoRHR, values.descAcesso);
                }}
            >
                {({
                    handleSubmit, values, errors, setFieldError, setFieldValue,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <FormControlLabel
                            control={(
                                <Checkbox
                                    checked={values.flagAcessoRHR ?? false}
                                    onChange={(e) => {
                                        setFieldValue("flagAcessoRHR", e.target.checked);
                                        if (!e.target.checked) {
                                            setFieldValue("descAcesso", "")
                                        }
                                    }}
                                    name="status"
                                    color="primary"
                                />
                            )}
                            style={{ marginBottom: 10 }}
                            label="Acesso ao Portal"
                        />
                        <Dropdown
                            errors={errors.descAcesso}
                            label="Tipo de acesso"
                            name="nivel"
                            values={values.descAcesso}
                            handleChange={(e: any) => { setFieldValue("descAcesso", e.target.value); setFieldError("descAcesso", "") }}
                            errorText={errors.descAcesso}
                            disabled={!values.flagAcessoRHR}
                        >
                            {
                                !values.flagAcessoRHR &&
                                <MenuItem value={""}></MenuItem>
                            }
                            <MenuItem value={"Administrador"}>Administrador</MenuItem>
                            <MenuItem value={"Efetividade Organizacional"}>Efetividade Organizacional</MenuItem>
                            <MenuItem value={"Remuneração & Benefícios"}>Remuneração & Benefícios</MenuItem>
                            <MenuItem value={"Business Partner"}>Business Partner</MenuItem>
                            <MenuItem value={"Usuário"}>Usuário</MenuItem>
                        </Dropdown>
                    </form>
                )}
            </Formik>
        </>
    );
};

export default FormAcesso;
