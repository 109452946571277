import { CycleGroupType } from "../cycle/groups/types";

/**
 * Types
 */
export enum Types {
    //GET
    REQUEST_MEETINGS_LOAD = "meetings/REQUEST_MEETINGS_LOAD",
    SUCCESS_MEETINGS_LOAD = "meetings/SUCCESS_MEETINGS_LOAD",
    FAILURE_MEETINGS_LOAD = "meetings/FAILURE_MEETINGS_LOAD",
    //GET BY ID
    REQUEST_MEETINGS_LOAD_BY_ID = "meetings/REQUEST_MEETINGS_LOAD_BY_ID",
    SUCCESS_MEETINGS_LOAD_BY_ID = "meetings/SUCCESS_MEETINGS_LOAD_BY_ID",
    FAILURE_MEETINGS_LOAD_BY_ID = "meetings/FAILURE_MEETINGS_LOAD_BY_ID",
    //GET BY ID NIVEL
    REQUEST_MEETINGS_LOAD_BY_ID_NIVEL = "meetings/REQUEST_MEETINGS_LOAD_BY_ID_NIVEL",
    SUCCESS_MEETINGS_LOAD_BY_ID_NIVEL = "meetings/SUCCESS_MEETINGS_LOAD_BY_ID_NIVEL",
    FAILURE_MEETINGS_LOAD_BY_ID_NIVEL = "meetings/FAILURE_MEETINGS_LOAD_BY_ID_NIVEL",
    //GET CRIAR
    REQUEST_MEETINGS_LOAD_CRIAR = "meetings/REQUEST_MEETINGS_LOAD_CRIAR",
    SUCCESS_MEETINGS_LOAD_CRIAR = "meetings/SUCCESS_MEETINGS_LOAD_CRIAR",
    FAILURE_MEETINGS_LOAD_CRIAR = "meetings/FAILURE_MEETINGS_LOAD_CRIAR",
    //POST
    REQUEST_MEETINGS_ADD = "meetings/REQUEST_MEETINGS_ADD",
    SUCCESS_MEETINGS_ADD = "meetings/SUCCESS_MEETINGS_ADD",
    FAILURE_MEETINGS_ADD = "meetings/FAILURE_MEETINGS_ADD",
    //DELETE
    REQUEST_MEETINGS_DEL = "meetings/REQUEST_MEETINGS_DEL",
    SUCCESS_MEETINGS_DEL = "meetings/SUCCESS_MEETINGS_DEL",
    FAILURE_MEETINGS_DEL = "meetings/FAILURE_MEETINGS_DEL",
};

export type MeetingType = {
    idCicloReuniao?: number;
    idCiclo?: number;
    idCicloGrupo?: number;
    dataHoraInicio: Date | null;
    nivel: string;
    status: "Em andamento" | "Finalizada" | "Cancelada";
    ciclo: {
        ano: number;
    };
    grupo: {
        nomeGrupo: string;
    };
};

type CycleMeetingType = {
    idCiclo: number;
    ano: number;
    area: {
        nomeLocal: string;
    }
}

export type MeetingCriarType = {
    ciclos: CycleMeetingType[];
    grupos: Partial<CycleGroupType>[];
};

export type ReuniaoType = {
    idCicloReuniao: number;
    idCiclo: number;
    dataHoraInicio: string;
    idFuncionarioLider: number;
    idFuncionarioRH: number;
    idFuncionarioHRBP: number;
    status: string;
    lider: {
        nomeCompleto: string;
    };
    hrbp: {
        nomeCompleto: string;
    };
    rh: {
        nomeCompleto: string;
    };
    ciclo: {
        ano: number;
    };
    grupo: {
        nomeGrupo: string;
    };
}

interface IMeetingsState {
    readonly data: MeetingType[];
    readonly dataCriar: Partial<MeetingCriarType> | null;
    readonly idCicloReuniaoCreated: number | null;
    readonly loadingData: boolean;
    readonly loadingAction: boolean;
    readonly loadingCriar: boolean;
    readonly error: boolean;
    readonly success: boolean;
    readonly successLoading: boolean;
    readonly successAdd: boolean;
};

export type DataTypes = IMeetingsState;

/**
 * Actions
 */

//GET
export interface IRequestMeetingsLoadAction {
    type: typeof Types.REQUEST_MEETINGS_LOAD;
};

export interface ISuccessMeetingsLoadAction {
    type: typeof Types.SUCCESS_MEETINGS_LOAD;
    payload: MeetingType[];
};

interface IFailureMeetingsLoadAction {
    type: typeof Types.FAILURE_MEETINGS_LOAD;
    message: string;
};

type MeetingsLoadAction = IRequestMeetingsLoadAction | ISuccessMeetingsLoadAction | IFailureMeetingsLoadAction;

//GET CRIAR
export interface IRequestMeetingsLoadCriarAction {
    type: typeof Types.REQUEST_MEETINGS_LOAD_CRIAR;
    idCiclo?: number;
};

export interface ISuccessMeetingsLoadCriarAction {
    type: typeof Types.SUCCESS_MEETINGS_LOAD_CRIAR;
    payload: MeetingCriarType;
};

interface IFailureMeetingsLoadCriarAction {
    type: typeof Types.FAILURE_MEETINGS_LOAD_CRIAR;
    message: string;
};

type MeetingsLoadCriarAction = IRequestMeetingsLoadCriarAction | ISuccessMeetingsLoadCriarAction | IFailureMeetingsLoadCriarAction;

//POST
export interface IRequestMeetingsAddAction {
    type: typeof Types.REQUEST_MEETINGS_ADD;
    payload: {
        idCicloGrupo: number;
    }
};

export interface ISuccessMeetingsAddAction {
    type: typeof Types.SUCCESS_MEETINGS_ADD;
    idCicloReuniao: number;
};

interface IFailureMeetingsAddAction {
    type: typeof Types.FAILURE_MEETINGS_ADD;
    message: string;
};

type MeetingsAddAction = IRequestMeetingsAddAction | ISuccessMeetingsAddAction | IFailureMeetingsAddAction;

//DEL
export interface IRequestMeetingsDelAction {
    type: typeof Types.REQUEST_MEETINGS_DEL;
    payload: {
        idCicloReuniao: number;
    }
};

export interface ISuccessMeetingsDelAction {
    type: typeof Types.SUCCESS_MEETINGS_DEL;
    payload: {
        idCiclo: number;
        idCicloReuniao: number;
    }
};

interface IFailureMeetingsDelAction {
    type: typeof Types.FAILURE_MEETINGS_DEL;
    message: string;
};

type MeetingsDelAction = IRequestMeetingsDelAction | ISuccessMeetingsDelAction | IFailureMeetingsDelAction;

export type MeetingsActionTypes =
    MeetingsLoadAction |
    MeetingsLoadCriarAction |
    MeetingsAddAction |
    MeetingsDelAction;