import api from "~/services/api";
import { takeEvery, put, call, all } from "redux-saga/effects";
import {
  Types,
  IRequestTeamLoadAction,
  ISuccessTeamLoadAction,
  TeamType,
} from "../ducks/team/types";

const apiCallLoad = (search?: IRequestTeamLoadAction["search"]) => {
  return api
    .get(`time`, {
      params: {
        SearchText: search,
      },
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

function* load(action: IRequestTeamLoadAction) {
  try {
    const data: TeamType[] = yield call(apiCallLoad, action.search);
    yield put({
      type: Types.SUCCESS_TEAM_LOAD,
      payload: data,
    } as ISuccessTeamLoadAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível carregar o time.";
    yield put({ type: Types.FAILURE_TEAM_LOAD, message });
  }
}

export default function* teamRoot() {
  yield all([yield takeEvery(Types.REQUEST_TEAM_LOAD, load)]);
}
