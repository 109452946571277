import React from "react";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  DefaultButton,
  IButtonStyles,
  Spinner,
  SpinnerSize,
  Stack,
  MessageBar,
  MessageBarType,
} from "office-ui-fabric-react";
import {
  DeleteButton,
  OutlinedButton,
  PrimaryButton,
} from "~/components/Buttons";
import { Text } from "office-ui-fabric-react/lib/Text";

import { Modal, WrapperModalEdit } from "./styles";

const btnStyles: Partial<IButtonStyles> = {
  root: {
    width: 110,
    padding: "0 8px",
  },
};

interface IProps {
  open: boolean;
  onClickConfirm: (_?: any) => any;
  onClickCancel?: () => any;
  title: string;
  subText?: string;
  btnConfirmText?: string;
  btnCancelText?: string;
  width?: 420 | 640 | 850 | 1120;
  height?: number;
  deleteButton?: boolean;
  disabledBtnConfirm?: boolean;
  isLoadingConfirm?: boolean;
  error?: {
    hasError: boolean;
    message: string;
  };
  optionButton?: {
    text: string;
    handleClick: () => void;
  };
  zIndex?: number;
}

const CustomDialog: React.FC<IProps> = (props) => {
  const {
    width,
    title,
    subText,
    children,
    btnCancelText,
    btnConfirmText,
    open,
    onClickCancel,
    onClickConfirm,
    deleteButton,
    height,
    disabledBtnConfirm,
    isLoadingConfirm,
    error,
    optionButton,
    zIndex,
  } = props;

  return (
    <Modal
      open={open}
      // onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      width={width ?? 420}
      height={height}
      style={{ zIndex: zIndex ?? 99999999 }}
    >
      <DialogTitle id="alert-dialog-title">
        <Text variant="xLarge">{title}</Text>
      </DialogTitle>
      <DialogContent>
        <Text variant="medium">{subText}</Text>
        {children}
      </DialogContent>
      <DialogActions>
        <Stack
          horizontal
          horizontalAlign="space-between"
          styles={{ root: { width: "100%" } }}
        >
          {optionButton && (
            <OutlinedButton
              text={optionButton.text}
              onClick={optionButton.handleClick}
            />
          )}
          {error?.hasError ? (
            <MessageBar
              messageBarType={MessageBarType.error}
              isMultiline={false}
              dismissButtonAriaLabel="Close"
              styles={{ root: { width: "auto" } }}
            >
              {error.message}
            </MessageBar>
          ) : (
            <div></div>
          )}
          <div>
            {onClickCancel && (
              <DefaultButton
                styles={{ root: { width: 110, marginRight: 10 } }}
                onClick={onClickCancel}
                color="primary"
              >
                {btnCancelText ? btnCancelText : "Cancelar"}
              </DefaultButton>
            )}
            {!deleteButton ? (
              <PrimaryButton
                styles={btnStyles}
                onClick={onClickConfirm}
                color="primary"
                disabled={disabledBtnConfirm || isLoadingConfirm}
                isLoading={isLoadingConfirm}
                text={btnConfirmText ? btnConfirmText : "Confirmar"}
              />
            ) : (
              <DeleteButton onClick={onClickConfirm}>
                {btnConfirmText ? btnConfirmText : "Confirmar"}
                {isLoadingConfirm && (
                  <Spinner
                    size={SpinnerSize.small}
                    styles={{ root: { marginLeft: 10 } }}
                  />
                )}
              </DeleteButton>
            )}
          </div>
        </Stack>
      </DialogActions>
    </Modal>
  );
};

interface IModalEditProps {
  open: boolean;
  onClickConfirm: (_?: any) => any;
  onClickCancel?: () => any;
  title: string;
  subText?: string;
  btnConfirmText?: string;
  btnCancelText?: string;
  height?: number;
  deleteButton?: boolean;
  disabledBtnConfirm?: boolean;
  isLoadingConfirm?: boolean;
  error?: {
    hasError: boolean;
    message: string;
  };
}

export const ModalEdit: React.FC<IModalEditProps> = (props) => {
  const {
    title,
    subText,
    children,
    btnCancelText,
    btnConfirmText,
    open,
    onClickCancel,
    onClickConfirm,
    deleteButton,
    height,
    disabledBtnConfirm,
    isLoadingConfirm,
    error,
  } = props;

  return (
    <WrapperModalEdit
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      width={654}
      height={height}
    >
      <DialogTitle id="alert-dialog-title">
        <Text variant="xLarge">{title}</Text>
      </DialogTitle>
      <DialogContent>
        <Text variant="medium">{subText}</Text>
        {children}
      </DialogContent>
      <DialogActions>
        <Stack
          horizontal
          horizontalAlign="space-between"
          styles={{ root: { width: "100%" } }}
        >
          {error?.hasError ? (
            <MessageBar
              messageBarType={MessageBarType.error}
              isMultiline={false}
              dismissButtonAriaLabel="Close"
              styles={{ root: { width: "auto" } }}
            >
              {error.message}
            </MessageBar>
          ) : (
            <div></div>
          )}
          <div>
            {onClickCancel && (
              <DefaultButton
                styles={{ root: { width: 110, marginRight: 10 } }}
                onClick={onClickCancel}
                color="primary"
              >
                {btnCancelText ? btnCancelText : "Cancelar"}
              </DefaultButton>
            )}
            {!deleteButton ? (
              <PrimaryButton
                styles={btnStyles}
                onClick={onClickConfirm}
                color="primary"
                disabled={disabledBtnConfirm || isLoadingConfirm}
                isLoading={isLoadingConfirm}
                text={btnConfirmText ? btnConfirmText : "Confirmar"}
              />
            ) : (
              <DeleteButton onClick={onClickConfirm}>
                {btnConfirmText ? btnConfirmText : "Confirmar"}
                {isLoadingConfirm && (
                  <Spinner
                    size={SpinnerSize.small}
                    styles={{ root: { marginLeft: 10 } }}
                  />
                )}
              </DeleteButton>
            )}
          </div>
        </Stack>
      </DialogActions>
    </WrapperModalEdit>
  );
};

export default CustomDialog;
