/**
 * Types
 */
export enum Types {
  REQUEST_LOAD_VINCULOS = "vinculos/REQUEST_LOAD_VINCULOS",
  SUCCESS_LOAD_VINCULOS = "vinculos/SUCCESS_LOAD_VINCULOS",
  FAILURE_LOAD_VINCULOS = "vinculos/FAILURE_LOAD_VINCULOS",

  REQUEST_LOAD_VINCULO_BY_ID = "vinculos/REQUEST_LOAD_VINCULO_BY_ID",
  SUCCESS_LOAD_VINCULO_BY_ID = "vinculos/SUCCESS_LOAD_VINCULO_BY_ID",
  FAILURE_LOAD_VINCULO_BY_ID = "vinculos/FAILURE_LOAD_VINCULO_BY_ID",

  REQUEST_ADD_VINCULO = "vinculos/REQUEST_ADD_VINCULO",
  SUCCESS_ADD_VINCULO = "vinculos/SUCCESS_ADD_VINCULO",
  FAILURE_ADD_VINCULO = "vinculos/FAILURE_ADD_VINCULO",

  REQUEST_EDIT_VINCULO = "vinculos/REQUEST_EDIT_VINCULO",
  SUCCESS_EDIT_VINCULO = "vinculos/SUCCESS_EDIT_VINCULO",
  FAILURE_EDIT_VINCULO = "vinculos/FAILURE_EDIT_VINCULO",

  REQUEST_DEL_VINCULO = "vinculos/REQUEST_DEL_VINCULO",
  SUCCESS_DEL_VINCULO = "vinculos/SUCCESS_DEL_VINCULO",
  FAILURE_DEL_VINCULO = "vinculos/FAILURE_DEL_VINCULO",
}

export type VinculosLoadDTO = {
  idVinculo: number;
  descVinculo: string;
  nomeAmigavel: string;
  elegivelBonus: boolean;
  elegivelMerito: boolean;
};

export type VinculosAddOrEditDTO = {
  idVinculo?: number;
  descVinculo: string;
  nomeAmigavel: string;
  elegivelBonus: boolean;
  elegivelMerito: boolean | null;
};

export type VinculoType = {
  idVinculo: number;
  descVinculo: string;
  nomeAmigavel: string;
  elegivelBonus: boolean;
  elegivelMerito: boolean | null;
};

export type ItemSelectedType = {
  item: VinculoType | null;
  loading: boolean;
  success: boolean;
  error: boolean;
};

interface IVinculoState {
  readonly data: VinculosLoadDTO[];
  readonly loadingData: boolean;
  readonly loadingAction: boolean;
  readonly success: boolean;
  readonly error: boolean;
  readonly itemSelected: ItemSelectedType;
}

export type DataTypes = IVinculoState;

/**
 * Actions
 */

// LOAD
export interface IRequestGetVinculosAction {
  type: typeof Types.REQUEST_LOAD_VINCULOS;
  search?: string;
}

export interface ISuccessGetVinculosAction {
  type: typeof Types.SUCCESS_LOAD_VINCULOS;
  payload: VinculosLoadDTO[];
}

export interface IFailureGetVinculosAction {
  type: typeof Types.FAILURE_LOAD_VINCULOS;
  message: string;
}

type VinculosActionGetActions =
  | IRequestGetVinculosAction
  | ISuccessGetVinculosAction
  | IFailureGetVinculosAction;

//LOAD BY ID

export interface IRequestGetByIdVinculoAction {
  type: typeof Types.REQUEST_LOAD_VINCULO_BY_ID;
  idVinculo: number;
}

export interface ISuccessGetByIdVinculoAction {
  type: typeof Types.SUCCESS_LOAD_VINCULO_BY_ID;
  payload: VinculoType;
}

export interface IFailureGetByIdVinculoAction {
  type: typeof Types.FAILURE_LOAD_VINCULO_BY_ID;
  message: string;
}

type VinculosGetByIdActions =
  | IRequestGetByIdVinculoAction
  | ISuccessGetByIdVinculoAction
  | IFailureGetByIdVinculoAction;

// //ADD

export interface IRequestAddVinculosAction {
  type: typeof Types.REQUEST_ADD_VINCULO;
  payload: VinculosAddOrEditDTO;
}

export interface ISuccessAddVinculosAction {
  type: typeof Types.SUCCESS_ADD_VINCULO;
  payload: VinculosLoadDTO;
}

export interface IFailureAddVinculosAction {
  type: typeof Types.FAILURE_ADD_VINCULO;
  message: string;
}

type VinculosAddActions =
  | IRequestAddVinculosAction
  | ISuccessAddVinculosAction
  | IFailureAddVinculosAction;

//EDIT

export interface IRequestEditVinculosAction {
  type: typeof Types.REQUEST_EDIT_VINCULO;
  payload: VinculosAddOrEditDTO;
}

export interface ISuccessEditVinculosAction {
  type: typeof Types.SUCCESS_EDIT_VINCULO;
  payload: VinculosLoadDTO;
}

export interface IFailureEditVinculosAction {
  type: typeof Types.FAILURE_EDIT_VINCULO;
  message: string;
}

type VinculosEditActions =
  | IRequestEditVinculosAction
  | ISuccessEditVinculosAction
  | IFailureEditVinculosAction;

//DELETE

export interface IRequestDelVinculosAction {
  type: typeof Types.REQUEST_DEL_VINCULO;
  idVinculo: number;
}

export interface ISuccessDelVinculosAction {
  type: typeof Types.SUCCESS_DEL_VINCULO;
  idVinculo: number;
}

export interface IFailureDelVinculosAction {
  type: typeof Types.FAILURE_DEL_VINCULO;
  message: string;
}

type VinculosDelActions =
  | IRequestDelVinculosAction
  | ISuccessDelVinculosAction
  | IFailureDelVinculosAction;

export type VinculosActionTypes =
  | VinculosActionGetActions
  | VinculosGetByIdActions
  | VinculosAddActions
  | VinculosEditActions
  | VinculosDelActions;
