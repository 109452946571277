import { Types, DataTypes, CycleGroupActionTypes, CycleGroupType, ParticipantsType } from "./types";
import customToast from "~/components/Toast/index";
import { PesquisaFuncType } from "../types";
import { EmployeeType } from "../../employees/types";

/**
 * Reducers
 */
const INITIAL_STATE: DataTypes = {
    data: [],
    itemSelected: {
        item: null,
        error: false,
        loading: false,
        success: false
    },
    loadingData: false,
    loadingAction: false,
    success: false,
    error: false
};

/**
 * Actions
 */

export const Creators = {
    getCycleGroups: (idCiclo: number): CycleGroupActionTypes => ({
        type: Types.REQUEST_CYCLE_GROUPS_LOAD,
        idCiclo
    }),

    getCycleGroupById: (idCiclo: number, idCicloGrupo: number): CycleGroupActionTypes => ({
        type: Types.REQUEST_CYCLE_GROUP_LOAD_BY_ID,
        payload: {
            idCiclo,
            idCicloGrupo
        }
    }),

    addCycleGroup: (idCiclo: number, group: CycleGroupType): CycleGroupActionTypes => ({
        type: Types.REQUEST_CYCLE_GROUPS_ADD,
        payload: {
            idCiclo,
            group
        }
    }),

    editCycleGroup: (idCiclo: number, group: CycleGroupType): CycleGroupActionTypes => ({
        type: Types.REQUEST_CYCLE_GROUPS_EDIT,
        payload: {
            idCiclo,
            group
        }
    }),

    delCycleGroup: (idCiclo: number, idCicloGrupo: number): CycleGroupActionTypes => ({
        type: Types.REQUEST_CYCLE_GROUPS_DEL,
        payload: {
            idCiclo,
            idCicloGrupo
        }
    }),

    addCycleGroupParticipants: (idCiclo: number, idCicloGrupo: number, participantes: PesquisaFuncType[]): CycleGroupActionTypes => ({
        type: Types.REQUEST_CYCLE_GROUPS_ADD_PARTICIPANTS,
        payload: {
            idCiclo,
            idCicloGrupo,
            participantes
        }
    }),

    delCycleGroupParticipant: (idCiclo: number, idCicloGrupo: number, idCicloGrupoParticipante: number, tipoFuncionario: string): CycleGroupActionTypes => ({
        type: Types.REQUEST_CYCLE_GROUPS_DEL_PARTICIPANTS,
        payload: {
            idCiclo,
            idCicloGrupo,
            idCicloGrupoParticipante,
            tipoFuncionario
        }
    }),

    addCycleGroupGuest: (idCiclo: number, idCicloGrupo: number, avaliador: Partial<EmployeeType>): CycleGroupActionTypes => ({
        type: Types.REQUEST_CYCLE_GROUPS_ADD_GUEST,
        payload: {
            idCiclo,
            idCicloGrupo,
            avaliador,
        }
    }),

    delCycleGroupGuest: (idCiclo: number, idCicloGrupo: number, idCicloGrupoConvidado: number): CycleGroupActionTypes => ({
        type: Types.REQUEST_CYCLE_GROUPS_DEL_GUEST,
        payload: {
            idCiclo,
            idCicloGrupo,
            idCicloGrupoConvidado
        }
    }),
}

export default function cycleGroupsReducer(state = INITIAL_STATE, action: CycleGroupActionTypes): DataTypes {

    switch (action.type) {
        case Types.REQUEST_CYCLE_GROUPS_LOAD:
            return { ...state, loadingData: true, error: false, success: false };
        case Types.SUCCESS_CYCLE_GROUPS_LOAD:
            return { ...state, data: action.payload, loadingData: false, error: false, success: true };
        case Types.FAILURE_CYCLE_GROUPS_LOAD:
            customToast.error(action.message);
            return { ...state, data: [], loadingData: false, error: true, success: false };

        case Types.REQUEST_CYCLE_GROUPS_ADD:
            return { ...state, error: false, loadingAction: true, success: false }
        case Types.SUCCESS_CYCLE_GROUPS_ADD:
            customToast.success("Grupo adicionado com sucesso");
            return { ...state, error: false, loadingAction: false, success: true, data: [...state.data, action.payload] }
        case Types.FAILURE_CYCLE_GROUPS_ADD: {
            customToast.error(action.message);
            return { ...state, error: true, loadingAction: false, success: false }
        }

        case Types.REQUEST_CYCLE_GROUP_LOAD_BY_ID:
            return { ...state, itemSelected: { item: null, error: false, loading: true, success: false } }
        case Types.SUCCESS_CYCLE_GROUP_LOAD_BY_ID:
            return { ...state, error: false, loadingAction: false, success: true, itemSelected: { item: action.payload, error: false, loading: false, success: true } }
        case Types.FAILURE_CYCLE_GROUP_LOAD_BY_ID: {
            customToast.error(action.message);
            return { ...state, itemSelected: { item: null, error: true, loading: false, success: false } }
        }

        case Types.REQUEST_CYCLE_GROUPS_EDIT:
            return { ...state, error: false, loadingAction: true, success: false }
        case Types.SUCCESS_CYCLE_GROUPS_EDIT:
            customToast.success("Grupo editado com sucesso");
            return {
                ...state,
                error: false,
                loadingAction: false,
                success: true,
                data: state.data.map(item => {
                    if (item.idCicloGrupo !== action.payload.idCicloGrupo) {
                        return item;
                    } else {
                        return action.payload;
                    }
                }),
                itemSelected: {
                    ...state.itemSelected,
                    item: action.payload,
                }
            }
        case Types.FAILURE_CYCLE_GROUPS_EDIT: {
            customToast.error(action.message);
            return { ...state, error: true, loadingAction: false, success: false }
        }

        case Types.REQUEST_CYCLE_GROUPS_DEL:
            return { ...state }
        case Types.SUCCESS_CYCLE_GROUPS_DEL:
            customToast.success("Grupo excluído com sucesso");
            return { ...state, data: state.data.filter(item => item.idCicloGrupo !== action.idCicloGrupo) }
        case Types.FAILURE_CYCLE_GROUPS_DEL:
            customToast.error(action.message);
            return { ...state }

        case Types.REQUEST_CYCLE_GROUPS_ADD_PARTICIPANTS:
            return { ...state, loadingAction: true, error: false, success: false };
        case Types.SUCCESS_CYCLE_GROUPS_ADD_PARTICIPANTS:

            return {
                ...state, loadingAction: false, error: false, success: true, itemSelected: {
                    ...state.itemSelected,
                    item: {
                        ...state.itemSelected.item!,
                        avaliados: [...(state.itemSelected.item!.avaliados as any)!, ...action.payload]
                    }
                }
            };
        case Types.FAILURE_CYCLE_GROUPS_ADD_PARTICIPANTS:
            customToast.error(action.message);
            return { ...state, loadingAction: false, error: true, success: false };

        case Types.REQUEST_CYCLE_GROUPS_DEL_PARTICIPANTS:
            return state;
        case Types.SUCCESS_CYCLE_GROUPS_DEL_PARTICIPANTS:
            //let participantes: ParticipantsType[];

            // switch (action.payload.tipoFuncionario) {
            //     case 'diretores':
            //         participantes = state.itemSelected.item?.diretores?.filter(item => item.idCicloGrupoParticipante !== action.payload.idCicloGrupoParticipante)!;
            //         break;
            //     case 'gerentes':
            //         participantes = state.itemSelected.item?.gerentes?.filter(item => item.idCicloGrupoParticipante !== action.payload.idCicloGrupoParticipante)!;
            //         break;
            //     case 'lideres':
            //         participantes = state.itemSelected.item?.lideres?.filter(item => item.idCicloGrupoParticipante !== action.payload.idCicloGrupoParticipante)!;
            //         break;
            //     case 'analistas':
            //         participantes = state.itemSelected.item?.analistas?.filter(item => item.idCicloGrupoParticipante !== action.payload.idCicloGrupoParticipante)!;
            //         break;
            // }

            return {
                ...state, itemSelected: {
                    ...state.itemSelected,
                    item: {
                        ...state.itemSelected.item!,
                        avaliados: (state.itemSelected.item?.avaliados! as ParticipantsType[]).filter(item => item.idCicloGrupoParticipante !== action.payload.idCicloGrupoParticipante)
                    }
                }
            };
        case Types.FAILURE_CYCLE_GROUPS_DEL_PARTICIPANTS:
            customToast.error(action.message);
            return state;

        case Types.REQUEST_CYCLE_GROUPS_ADD_GUEST:
            return state;
        case Types.SUCCESS_CYCLE_GROUPS_ADD_GUEST:
            return {
                ...state, itemSelected: {
                    ...state.itemSelected,
                    item: {
                        ...state.itemSelected.item!,
                        convidados: [...state.itemSelected.item?.convidados!, action.avaliador]
                    }
                }
            };
        case Types.FAILURE_CYCLE_GROUPS_ADD_GUEST:
            customToast.error(action.message);
            return state;

        case Types.REQUEST_CYCLE_GROUPS_DEL_GUEST:
            return state;
        case Types.SUCCESS_CYCLE_GROUPS_DEL_GUEST:
            return {
                ...state,
                itemSelected: {
                    ...state.itemSelected,
                    item: {
                        ...state.itemSelected.item!,
                        convidados: state.itemSelected.item?.convidados.filter(item => item.idCicloGrupoConvidado !== action.idCicloGrupoConvidado)!
                    }
                }
            };
        case Types.FAILURE_CYCLE_GROUPS_DEL_GUEST:
            customToast.error(action.message);
            return state;

        default:
            return state;
    }
}
