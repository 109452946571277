import {
  Types,
  DataTypes,
  TypeConductActionTypes,
  TypeConductType,
} from "./types";
import customToast from "~/components/Toast/index";

/**
 * Reducers
 */
const INITIAL_STATE: DataTypes = {
  data: [],
  loading: false,
  loadingAction: false,
  error: false,
  success: false,
};

/**
 * Actions
 */

export const Creators = {
  getTypeConducts: (): TypeConductActionTypes => {
    return {
      type: Types.REQUEST_TYPES_CONDUCTS_LOAD,
    };
  },

  addTypeConduct: (typeConduct: TypeConductType): TypeConductActionTypes => ({
    type: Types.REQUEST_TYPE_CONDUCT_ADD,
    typeConduct,
  }),

  editTypeConduct: (typeConduct: TypeConductType): TypeConductActionTypes => ({
    type: Types.REQUEST_TYPE_CONDUCT_EDIT,
    typeConduct,
  }),

  delTypeConduct: (idTipoConduta: number): TypeConductActionTypes => ({
    type: Types.REQUEST_TYPE_CONDUCT_DELETE,
    idTipoConduta,
  }),
};

export default function typeConductReducer(
  state = INITIAL_STATE,
  action: TypeConductActionTypes
): DataTypes {
  switch (action.type) {
    case Types.REQUEST_TYPES_CONDUCTS_LOAD:
      return {
        ...state,
        loading: true,
        error: false,
        success: false,
      };
    case Types.SUCCESS_TYPES_CONDUCTS_LOAD:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        success: true,
      };
    case Types.FAILURE_TYPES_CONDUCTS_LOAD:
      customToast.error(action.message);
      return {
        ...state,
        data: [],
        loading: false,
        loadingAction: false,
        error: true,
        success: false,
      };
    case Types.REQUEST_TYPE_CONDUCT_ADD:
      return {
        ...state,
        error: false,
        success: false,
        loadingAction: true,
      };
    case Types.SUCCESS_TYPE_CONDUCT_ADD:
      customToast.success("Tipo de conduta cadastrada com sucesso");
      return {
        ...state,
        error: false,
        success: true,
        data: [...state.data, action.typeConduct],
        loadingAction: false,
      };
    case Types.FAILURE_TYPE_CONDUCT_ADD:
      customToast.error(action.message);
      return {
        ...state,
        error: true,
        success: false,
        loadingAction: false,
      };

    case Types.REQUEST_TYPE_CONDUCT_EDIT:
      return {
        ...state,
        error: false,
        success: false,
        loadingAction: true,
      };
    case Types.SUCCESS_TYPE_CONDUCT_EDIT:
      customToast.success("Tipo de conduta editada com sucesso");
      return {
        ...state,
        error: false,
        success: true,
        loadingAction: false,
        data: state.data.map((item) => {
          if (item.idTipoConduta !== action.typeConduct.idTipoConduta) {
            return item;
          }
          return action.typeConduct;
        }),
      };
    case Types.FAILURE_TYPE_CONDUCT_EDIT:
      customToast.error(action.message);
      return {
        ...state,
        error: true,
        success: false,
        loadingAction: false,
      };

    case Types.REQUEST_TYPE_CONDUCT_DELETE:
      return {
        ...state,
        error: false,
        success: false,
        loading: true,
      };
    case Types.SUCCESS_TYPE_CONDUCT_DELETE:
      customToast.success("Item excluído com sucesso");
      return {
        ...state,
        error: false,
        success: true,
        data: state.data.filter(
          (item) => item.idTipoConduta !== action.idTipoConduta
        ),
        loading: false,
      };
    case Types.FAILURE_TYPE_CONDUCT_DELETE:
      customToast.error(action.message);
      return {
        ...state,
        error: true,
        success: false,
        loading: false,
      };

    default:
      return state;
  }
}
