import styled from 'styled-components';
import Colors, { calcConstrast } from "~/assets/js/colors"

export const Table = styled.table`
    box-sizing: border-box;
    margin: 20px 0;
    border-collapse: separate;
    border-spacing: 0 10px;
`

export const Th = styled.th`
    border: 1px solid #757575;
    font-size: 12px;
    box-sizing: border-box;
    border-radius: 2px;
    background-color: #757575;
    color: #FFFFFF;
    padding: 5px;
    font-weight: 600;
`
export const TdNumber = styled.td`
    border: 1px solid #707070;
    width: 50px;
    min-width: 50px;
    box-sizing: border-box;
    text-align: center;
    font-weight: 600;
    font-size: 22px;
    border-radius: 2px;
`

export const TdPillar = styled.td`
    width: 180px;
    min-width: 180px;
    box-sizing: border-box;
    text-align: left;
    padding: 0 10px;
`

export const TdGoal = styled.td`
    border: 1px solid black;
    font-size: 12px;
    width: 400px;
    min-width: 400px;
    box-sizing: border-box;
    text-align: left;
    padding: 0 10px;
    position: relative;
`

export const TdPeso = styled.td`
    border: 1px solid #707070;
    font-size: 12px;
    width: 40px;
    box-sizing: border-box;
    text-align: center;
    border-radius: 2px;
`

export const TdCorpGoal = styled.td`
    border: 1px solid #CCCCCC;
    width: 155px;
    min-width: 155px;
    box-sizing: border-box;
    background-color: #EFEEEE;
    border-radius: 2px;
    div:last-child{
        border-bottom: none !important;
    }
`

export const ContainerCorpoGoal = styled.div`
    height: 20px;
    /* box-sizing: border-box; */
    font-size: 12px;
    display: flex;
    justify-content: space-evenly;
    border-bottom: 1px solid #CCCCCC;
`

export const ContainerPesoGoal = styled.div`
    height: 20px;
    box-sizing: border-box;
    font-size: 12px;
    display: flex;
    justify-content: space-evenly;
    border-bottom: 1px solid #CCCCCC;
`
export const Gap = styled.td`
    width: 10px;
    min-width: 10px;
    border: none !important;
    background-color: #FFFFFF;
`

export const Tr = styled.tr<{ pillarColor: string }>`
    height: 60px;
    &:hover{
        background-color: #FAFAFA;
    }
    td:nth-child(3){
        background-color: ${props => props.pillarColor};
        border-color: ${props => props.pillarColor};
        color: ${props => calcConstrast(props.pillarColor) ? "#FFFFFF" : "#000000"};
    }
    td:nth-child(4){
        border: 1px solid ${props => props.pillarColor};
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
    }
`

export const Tbody = styled.tbody`

`

export const LeftItemMeta = styled.div`
    width: 35px;
    text-align: right;
`

export const RightItemMeta = styled.div`
    width: 35px;
    text-align: left;
`

export const BlankTd = styled.td`
    background-color: #FFFFFF;
`

export const ThMeta = styled.th`
    width: 130px;
    font-size: 12px;
    box-sizing: border-box;
    border-radius: 2px;
    background-color: #757575;
    color: #FFFFFF;
    padding: 5px;
    font-weight: 600;
`

export const ThReal = styled.th`
    font-size: 12px;
    box-sizing: border-box;
    border-radius: 2px;
    background-color: ${Colors.black};
    color: #FFFFFF;
    padding: 0 5px;
    font-weight: 600;
    width: 90px;
`

export const ThResultado = styled.th`
    font-size: 12px;
    box-sizing: border-box;
    border-radius: 2px;
    background-color: ${Colors.black};
    color: #FFFFFF;
    padding: 5px;
    font-weight: 600;
`


export const TdReal = styled.td<{ isTotal?: boolean }>`
   border: 1px solid #CCCCCC;
    font-size: 12px;
    width: 90px;
    min-width: 90px;
    height: 60px;
    box-sizing: border-box;
    text-align: center;
    border-radius: 2px;
    padding: 10px 0;
    ${({ isTotal }) => isTotal ? `
        background-color: #E1DEDE;
     ` : ''}
`

export const TdResult = styled.td<{ isTotal?: boolean }>`
    border: 1px solid #CCCCCC;
    font-size: 12px;
    width: 130px;
    min-width: 130px;
    height: 60px;
    box-sizing: border-box;
    padding: 0 10px;
    border-radius: 2px;
    ${({ isTotal }) => isTotal ? `
        background-color: #E1DEDE;
     ` : ''}
`

export const TdBonus = styled.td<{ isTotal?: boolean }>`
   border: 1px solid #CCCCCC;
    font-size: 12px;
    width: 90px;
    min-width: 90px;
    height: 60px;
    box-sizing: border-box;
    text-align: center;
    border-radius: 2px;
    padding: 10px 0;
    ${({ isTotal }) => isTotal ? `
        background-color: #E1DEDE;
     ` : ''}
`

export const ThBonus = styled.th`
    font-size: 12px;
    box-sizing: border-box;
    border-radius: 2px;
    background-color: ${Colors.black};
    color: #FFFFFF;
    padding: 0 5px;
    font-weight: 600;
    width: 90px;
`