const onlyDigits = (text: string) => text.replace(/[^\d]+/g, '');

const convertToMoney = (text: string | number): string => {
    let value: number = 0;

    if (typeof text === "string") {
        if (text.includes(",")) {
            value = parseFloat(text.replaceAll(".", ""));
        } else {
            value = parseFloat(text);
        }
    }

    const formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    return formatter.format(value).replace("R$", "");
}

const convertToNumber = (val: string | number): number => {

    let value: number = 0;
    if (typeof val === "string") {
        if (val.includes(",")) {
            const v = val.replaceAll(".", "").replace(",", ".");
            value = parseFloat(v);
        } else {
            value = parseFloat(val);
        }
    }else{
        value = val;
    }

    return value;
}

export { onlyDigits, convertToMoney, convertToNumber };