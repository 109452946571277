import { takeEvery, put, call, all } from "redux-saga/effects";
import {
  Types,
  IRequestTabPerformanceLoadAction,
  IRequestTabPerformanceAddAction,
  IRequestTabPerformanceDelAction,
  IRequestTabPerformanceLoadByIdAction,
  IRequestTabPerformanceEditAction,
  IRequestTabPerformanceAddNivelAction,
  IRequestTabPerformanceEditNivelAction,
  IRequestTabPerformanceDelNivelAction,
  ISuccessTabPerformanceLoadAction,
  ISuccessTabPerformanceAddAction,
  ISuccessTabPerformanceDelAction,
  ISuccessTabPerformanceLoadByIdAction,
  ISuccessTabPerformanceEditAction,
  ISuccessTabPerformanceAddNivelAction,
  ISuccessTabPerformanceEditNivelAction,
  ISuccessTabPerformanceDelNivelAction,
  TabPerformanceType
} from "../../ducks/admin/tabPerformances/types";
import api from "~/services/api";

const apiCallGet = (payload: IRequestTabPerformanceLoadAction["payload"]) => {
  return api
    .get(`tabperformances`, {
      params: {
        ...payload
      }
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallGetById = (idTabPerformance: number) => {
  return api
    .get(`tabperformances/${idTabPerformance}`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallAdd = (payload: IRequestTabPerformanceAddAction["payload"]) => {
  return api
    .post(`tabperformances`, {
      ...payload
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallEdit = (payload: IRequestTabPerformanceEditAction["payload"]) => {
  return api
    .put(`tabperformances/${payload.idTabPerformance}`, {
      ...payload
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallDel = (idTabPerformance: number) => {
  return api
    .delete(`tabperformances/${idTabPerformance}`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallAddNivel = (payload: IRequestTabPerformanceAddNivelAction["payload"]) => {
  return api
    .post(`tabperformances/${payload.idTabPerformance}/niveis`, {
      ...payload.nivel
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallEditNivel = (payload: IRequestTabPerformanceEditNivelAction["payload"]) => {
  return api
    .put(`tabperformances/${payload.idTabPerformance}/niveis/${payload.nivel.idTabPerformanceNivel}`, {
      ...payload.nivel
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallDelNivel = (payload: IRequestTabPerformanceDelNivelAction["payload"]) => {
  return api
    .delete(`tabperformances/${payload.idTabPerformance}/niveis/${payload.idTabPerformanceNivel}`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

function* load(action: IRequestTabPerformanceLoadAction) {
  try {
    let items: TabPerformanceType[] = yield call(apiCallGet, action.payload);
    yield put({
      type: Types.SUCCESS_TAB_PERFORMANCES_LOAD,
      payload: items
    } as ISuccessTabPerformanceLoadAction);
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível carregar as tabelas de performance'
    yield put({
      type: Types.FAILURE_TAB_PERFORMANCES_LOAD,
      message
    });
  }
}

function* loadById(action: IRequestTabPerformanceLoadByIdAction) {
  try {
    let item: TabPerformanceType = yield call(apiCallGetById, action.idTabPerformance);
    yield put({
      type: Types.SUCCESS_TAB_PERFORMANCES_LOAD_BY_ID,
      payload: item
    } as ISuccessTabPerformanceLoadByIdAction);
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível carregar a tabela de performance'
    yield put({
      type: Types.FAILURE_TAB_PERFORMANCES_LOAD_BY_ID,
      message
    });
  }
}

function* add(action: IRequestTabPerformanceAddAction) {
  try {
    let idTabPerformance: { idTabPerformance: number } = yield call(apiCallAdd, action.payload);
    yield put({
      type: Types.SUCCESS_TAB_PERFORMANCES_ADD,
      payload: {
        ...action.payload,
        ...idTabPerformance
      }
    } as ISuccessTabPerformanceAddAction);
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível adicionar a tabela de performance'
    yield put({
      type: Types.FAILURE_TAB_PERFORMANCES_ADD,
      message
    });
  }
}

function* edit(action: IRequestTabPerformanceEditAction) {
  try {
    yield call(apiCallEdit, action.payload);
    yield put({
      type: Types.SUCCESS_TAB_PERFORMANCES_EDIT,
      payload: action.payload
    } as ISuccessTabPerformanceEditAction);
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível editar a tabela de performance'
    yield put({
      type: Types.FAILURE_TAB_PERFORMANCES_EDIT,
      message
    });
  }
}

function* del(action: IRequestTabPerformanceDelAction) {
  try {
    yield call(apiCallDel, action.idTabPerformance);
    yield put({
      type: Types.SUCCESS_TAB_PERFORMANCES_DEL,
      idTabPerformance: action.idTabPerformance
    } as ISuccessTabPerformanceDelAction);
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível excluír a tabela de performance'
    yield put({
      type: Types.FAILURE_TAB_PERFORMANCES_DEL,
      message
    });
  }
}

function* addNivel(action: IRequestTabPerformanceAddNivelAction) {
  try {
    const idTabPerformanceNivel: { idTabPerformanceNivel: number } = yield call(apiCallAddNivel, action.payload);
    yield put({
      type: Types.SUCCESS_TAB_PERFORMANCES_ADD_NIVEL,
      payload: {
        ...idTabPerformanceNivel,
        ...action.payload.nivel,
      }
    } as ISuccessTabPerformanceAddNivelAction);
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível adicionar o nível'
    yield put({
      type: Types.FAILURE_TAB_PERFORMANCES_ADD_NIVEL,
      message
    });
  }
}

function* editNivel(action: IRequestTabPerformanceEditNivelAction) {
  try {
    yield call(apiCallEditNivel, action.payload);
    yield put({
      type: Types.SUCCESS_TAB_PERFORMANCES_EDIT_NIVEL,
      payload: action.payload.nivel,
    } as ISuccessTabPerformanceEditNivelAction);
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível editar o nível'
    yield put({
      type: Types.FAILURE_TAB_PERFORMANCES_EDIT_NIVEL,
      message
    });
  }
}

function* delNivel(action: IRequestTabPerformanceDelNivelAction) {
  try {
    yield call(apiCallDelNivel, action.payload);
    yield put({
      type: Types.SUCCESS_TAB_PERFORMANCES_DEL_NIVEL,
      idTabPerformanceNivel: action.payload.idTabPerformanceNivel
    } as ISuccessTabPerformanceDelNivelAction);
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível excluír o nível'
    yield put({
      type: Types.FAILURE_TAB_PERFORMANCES_DEL_NIVEL,
      message
    });
  }
}

export default function* tabPerformanceRoot() {
  yield all([
    yield takeEvery(Types.REQUEST_TAB_PERFORMANCES_LOAD, load),
    yield takeEvery(Types.REQUEST_TAB_PERFORMANCES_LOAD_BY_ID, loadById),
    yield takeEvery(Types.REQUEST_TAB_PERFORMANCES_ADD, add),
    yield takeEvery(Types.REQUEST_TAB_PERFORMANCES_EDIT, edit),
    yield takeEvery(Types.REQUEST_TAB_PERFORMANCES_DEL, del),
    yield takeEvery(Types.REQUEST_TAB_PERFORMANCES_ADD_NIVEL, addNivel),
    yield takeEvery(Types.REQUEST_TAB_PERFORMANCES_EDIT_NIVEL, editNivel),
    yield takeEvery(Types.REQUEST_TAB_PERFORMANCES_DEL_NIVEL, delNivel),
  ]);
}
