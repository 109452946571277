// #region Imports
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { RouteComponentProps, withRouter } from "react-router-dom";

//FluentUI
import { Stack } from "office-ui-fabric-react/lib/Stack";
import {
  DetailsListLayoutMode,
  SelectionMode,
  Selection,
  IColumn,
} from "office-ui-fabric-react/lib/DetailsList";
import { ShimmeredDetailsList } from "office-ui-fabric-react/lib/ShimmeredDetailsList";
import { CommandBarButton, ICommandBarStyles } from "office-ui-fabric-react";

import NoItems from "~/components/layout/NoItems";
//Estilos
import { ListContainer } from "./styles";

import { Creators as getFeedbacks } from "~/store/ducks/profile";
import {
  DataTypes as DataTypesPerfil,
  FeedbackDataType,
  ProfilePerformanceType,
} from "~/store/ducks/profile/types";
import { RootState } from "~/store/ducks";
import moment from "moment";

//#endregion

interface IFeedbacksState {
  columns: IColumn[];
  items: ProfilePerformanceType[];
  selectionDetails: string;
  selectionCount: number;
  dialogAvaliacao: {
    isOpen: boolean;
    nomeCompleto: string;
  };
}

interface IPropsFeedbacks extends RouteComponentProps {
  idFuncionario?: number;
  perfil: DataTypesPerfil;
  getFeedbacks: (idFuncionario: number) => void;
  history: RouteComponentProps["history"];
}

const btnStyle: Partial<ICommandBarStyles> = {
  root: {
    height: 44,
  },
};


class ProfileFeedbacks extends Component<IPropsFeedbacks, IFeedbacksState> {
  private _selection: Selection;

  constructor(props: IPropsFeedbacks) {
    super(props);
    //#region Colunas
    const columns: IColumn[] = [
      {
        key: "column1",
        name: "Data",
        ariaLabel: "Data",
        fieldName: "dataOcorrencia",
        isRowHeader: true,
        minWidth: 75,
        maxWidth: 100,
        isResizable: true,
        isSortedDescending: false,
        onColumnClick: this._onColumnClick,
        onRender: (item: FeedbackDataType) => moment(item.dataOcorrencia).format("DD/MM/YYYY")
      },
      {
        key: "column2",
        name: "Descrição",
        ariaLabel: "Descrição",
        fieldName: "descricaoResumida",
        isRowHeader: true,
        minWidth: 200,
        maxWidth: 500,
        isResizable: true,
        isSortedDescending: false,
        onColumnClick: this._onColumnClick,
      },
      {
        key: "column3",
        name: "Status",
        ariaLabel: "Status",
        fieldName: "status",
        isRowHeader: true,
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        isSortedDescending: false,
        onColumnClick: this._onColumnClick,
      },
      {
        key: "column3",
        name: "Responsável",
        ariaLabel: "Responsável",
        fieldName: "owner.nomeCompleto",
        isRowHeader: true,
        minWidth: 200,
        maxWidth: 500,
        isResizable: true,
        isSortedDescending: false,
        onColumnClick: this._onColumnClick,
        onRender: (item: FeedbackDataType) => item.owner.nomeCompleto,
      },
    ];
    //#endregion

    this.state = {
      columns: columns,
      items: [],
      selectionDetails: "",
      selectionCount: 0,
      dialogAvaliacao: {
        isOpen: false,
        nomeCompleto: "",
      },
    };

    this._selection = new Selection({
      onSelectionChanged: () => {
        this.setState({
          selectionDetails: this._getSelectionDetails(),
        });
      },
    });
  }

  componentDidMount() {
    if (this.props.idFuncionario != undefined)
      this.props.getFeedbacks(this.props.idFuncionario); //ID Funcionario do parametro da URL
    else
      this.props.getFeedbacks(this.props.perfil.dataProfile.idFuncionario!);
  }

  _getSelectionDetails(): any {
    const selectionCount = this._selection.getSelectedCount();
    this.setState({ selectionCount });
    this._selection.getSelection();
  }

  _onItemInvoked = (item: FeedbackDataType): void => {
    if (this.props.idFuncionario != undefined)
      this.props.history.push({
        pathname: `/perfil/${this.props.idFuncionario}/feedback/${item.idFeedback}`
      });
    else
      this.props.history.push({
        pathname: `/perfil/${this.props.perfil.dataProfile.idFuncionario!}/feedback/${item.idFeedback}`
      });
  }

  render() {
    const {
      columns,
    } = this.state;
    const { perfil } = this.props;
    const { dataFeedbacks } = perfil;

    return (
      <>
        {dataFeedbacks?.permiteInserirFeedback! &&
          <Stack
            horizontal
            horizontalAlign="space-between"
            verticalAlign="center"
          >
            <Stack horizontal>
              <CommandBarButton
                styles={btnStyle}
                iconProps={{ iconName: "Add" }}
                text="Adicionar Feedback"
                onClick={() => this.props.history.push({
                  pathname: `/perfil/${this.props.idFuncionario}/feedback-adicionar`,
                })}
              />
            </Stack>
          </Stack>
        }
        {!perfil.loadingData && dataFeedbacks?.dados.length === 0 ? (
          <NoItems
            error={perfil.error}
            text="Não há nenhum feedback publicado"
            icon="Feedback"
          />
        ) : (
          <ListContainer>
            <ShimmeredDetailsList
              items={dataFeedbacks?.dados ?? []}
              enableShimmer={perfil.loadingData}
              columns={columns}
              selectionMode={SelectionMode.none}
              selection={this._selection}
              getKey={this._getKey}
              selectionPreservedOnEmptyClick={true}
              setKey="single"
              layoutMode={DetailsListLayoutMode.justified}
              isHeaderVisible={true}
              onItemInvoked={this._onItemInvoked}
            />
          </ListContainer>
        )}

      </>
    );
  }

  private _onColumnClick = (
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn
  ): void => {
    const { columns } = this.state;
    const newColumns: IColumn[] = columns.slice();
    let items: ProfilePerformanceType[] = this.props.perfil.dataPerformance;

    const currColumn: IColumn = newColumns.filter(
      (currCol) => column.key === currCol.key
    )[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    const newItems = this._sort(
      items,
      currColumn.fieldName!,
      currColumn.isSortedDescending
    );
    this.setState({
      columns: newColumns,
      items: newItems,
    });
  };

  private _getKey(item: any, index?: number): any {
    if (item !== undefined) return item.key;
  }

  private _sort<T>(
    items: T[],
    columnKey: string,
    isSortedDescending?: boolean
  ): T[] {
    const result = (prop: string, obj: T) =>
      prop.split(".").reduce((a: any, b: any) => a[b] ?? "", obj);
    return items.sort((a: T, b: T) =>
      (
        isSortedDescending
          ? result(columnKey, a) < result(columnKey, b)
          : result(columnKey, a) > result(columnKey, b)
      )
        ? 1
        : -1
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  perfil: state.profileReducer,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      ...getFeedbacks,
    },
    dispatch
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProfileFeedbacks));
