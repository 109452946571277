import { Types, DataTypes, WorkflowActions } from "./types"

/**
 * Reducers
 */
const INITIAL_STATE: DataTypes = {
    pivotSelected: "0"
};

/**
 * Actions
 */

export const Creators = {
    setCurrentPage: (pivotSelected: string): WorkflowActions => ({
        type: Types.SET_WORKFLOW_PIVOT,
        pivotSelected
    }),
};


export default function workflowReducer(state = INITIAL_STATE, action: WorkflowActions): DataTypes {
    switch (action.type) {

        case Types.SET_WORKFLOW_PIVOT:
            return { ...state, pivotSelected: action.pivotSelected }
            
        default:
            return state;
    }
}