/**
 * Types
 */
export enum Types {
  //LOAD
  REQUEST_MEASURE_LOAD = "measure/REQUEST_MEASURE_LOAD",
  SUCCESS_MEASURE_LOAD = "measure/SUCCESS_MEASURE_LOAD",
  FAILURE_MEASURE_LOAD = "measure/FAILURE_MEASURE_LOAD",
  //ADD
  REQUEST_MEASURE_ADD = "measure/REQUEST_MEASURE_ADD",
  SUCCESS_MEASURE_ADD = "measure/SUCCESS_MEASURE_ADD",
  FAILURE_MEASURE_ADD = "measure/FAILURE_MEASURE_ADD",
  //LOAD SUGGESTED SANCTION
  REQUEST_MEASURE_SUGGESTED_SANCTION_LOAD = "measure/REQUEST_MEASURE_SUGGESTED_SANCTION_LOAD",
  SUCCESS_MEASURE_SUGGESTED_SANCTION_LOAD = "measure/SUCCESS_MEASURE_SUGGESTED_SANCTION_LOAD",
  FAILURE_MEASURE_SUGGESTED_SANCTION_LOAD = "measure/FAILURE_MEASURE_SUGGESTED_SANCTION_LOAD",
  //ADD EVIDENCE
  REQUEST_MEASURE_EVIDENCE_ADD = "measure/REQUEST_MEASURE_EVIDENCE_ADD",
  SUCCESS_MEASURE_EVIDENCE_ADD = "measure/SUCCESS_MEASURE_EVIDENCE_ADD",
  FAILURE_MEASURE_EVIDENCE_ADD = "measure/FAILURE_MEASURE_EVIDENCE_ADD",
  //ADD ASSINATURA
  REQUEST_MEASURE_ASSINATURA_ADD = "measure/REQUEST_MEASURE_ASSINATURA_ADD",
  SUCCESS_MEASURE_ASSINATURA_ADD = "measure/SUCCESS_MEASURE_ASSINATURA_ADD",
  FAILURE_MEASURE_ASSINATURA_ADD = "measure/FAILURE_MEASURE_ASSINATURA_ADD",
  //GERA_FORM
  REQUEST_MEASURE_GERA_FORM = "measure/REQUEST_MEASURE_GERA_FORM",
  SUCCESS_MEASURE_GERA_FORM = "measure/SUCCESS_MEASURE_GERA_FORM",
  FAILURE_MEASURE_GERA_FORM = "measure/FAILURE_MEASURE_GERA_FORM",
  //CLEAR
  REQUEST_MEASURE_CLEAR = "measure/REQUEST_MEASURE_CLEAR",
}

export type EducationalMeasureType = {
  idMedidaEducativa?: number;
  idFuncionario?: number;
  dataOcorrencia: Date | null;
  statusmedida?: string;
  idConduta: number | null;
  idSancao: number | null;
  descricao: string;
  justificativa: string;
  orientacao: string;
  planoAcao: string;
  assinaturaNecessaria: boolean;
  baixouFormulario: boolean;
  funcionario?: {
    idFuncionario: number;
    nomeCompleto: string;
  };
  conduta?: {
    titulo: string;
    gravidade: string;
  };
  testemunhas: TestemunhaType[];
  anexoassinatura?: {
    name: string;
    size: number;
  };
  anexos: AnexoType[];
};

export type TestemunhaType = {
  idMedidaEducativaTestemunha?: number;
  idMedidaEducativa?: number;
  idFuncionario?: number;
  nomeTestemunhaExterna?: string;
  obs: string;
  funcionario?: {
    nomeCompleto: string;
  };
};

export type SuggestionType = {
  idSancaoSugerida: number | null;
  flagAlertaJustaCausa: boolean;
  somaSancoes: number;
};

export type AnexoType = {
  idMedidaEducativaAnexo: string;
  idMedidaEducativa: number;
  idAnexo: string;
  anexo: {
    idAnexo: string;
    name: string;
    size: number;
    key: string;
    url: string;
  };
};

export type EvidenceType = {
  novoStatus: string;
  arquivosAnexados: AnexoType[];
};

export type AssinaturaType = {
  novoStatus: string;
  anexoassinatura: {
    name: string;
    size: number;
  };
};

interface IMeasureState {
  readonly data: EducationalMeasureType;
  readonly suggestion: SuggestionType;
  readonly loading: boolean;
  readonly loadingAction: boolean;
  readonly loadingFicha: boolean;
  readonly error: boolean;
  readonly errorAnexos: boolean;
  readonly success: boolean;
  readonly redirect: boolean;
}

export type DataTypes = IMeasureState;

//LOAD
export interface IRequestMeasureLoadAction {
  type: typeof Types.REQUEST_MEASURE_LOAD;
  payload: {
    idMedidaEducativa: number | string;
    idFuncionario: number | string;
  };
}

export interface ISuccessMeasureLoadAction {
  type: typeof Types.SUCCESS_MEASURE_LOAD;
  payload: EducationalMeasureType;
}

interface IFailureMeasureLoadAction {
  type: typeof Types.FAILURE_MEASURE_LOAD;
  message: string;
}

type MeasureLoadAction =
  | IRequestMeasureLoadAction
  | ISuccessMeasureLoadAction
  | IFailureMeasureLoadAction;

//ADD
export interface IRequestMeasureAddAction {
  type: typeof Types.REQUEST_MEASURE_ADD;
  payload: {
    medida: EducationalMeasureType;
    files: File[];
  };
}

export interface ISuccessMeasureAddAction {
  type: typeof Types.SUCCESS_MEASURE_ADD;
  measure: EducationalMeasureType;
}

interface IFailureMeasureAddAction {
  type: typeof Types.FAILURE_MEASURE_ADD;
  message: string;
}

type MeasureAddAction =
  | IRequestMeasureAddAction
  | ISuccessMeasureAddAction
  | IFailureMeasureAddAction;

//LOAD SUGGESTED SANCTION
export interface IRequestMeasureLoadSuggestedSanctionAction {
  type: typeof Types.REQUEST_MEASURE_SUGGESTED_SANCTION_LOAD;
  payload: {
    idFuncionario: number;
    dataOcorrencia: Date;
    idConduta: number;
  };
}

export interface ISuccessMeasureLoadSuggestedSanctionAction {
  type: typeof Types.SUCCESS_MEASURE_SUGGESTED_SANCTION_LOAD;
  suggestion: SuggestionType;
}

interface IFailureMeasureLoadSuggestedSanctionAction {
  type: typeof Types.FAILURE_MEASURE_SUGGESTED_SANCTION_LOAD;
  message: string;
}

type MeasureLoadSuggestedSanctionAction =
  | IRequestMeasureLoadSuggestedSanctionAction
  | ISuccessMeasureLoadSuggestedSanctionAction
  | IFailureMeasureLoadSuggestedSanctionAction;

//ADD EVIDENCE
export interface IRequestMeasureAddEvidenceAction {
  type: typeof Types.REQUEST_MEASURE_EVIDENCE_ADD;
  payload: {
    idFuncionario: number;
    idMedidaEducativa: number;
    files: File[];
  };
}

export interface ISuccessMeasureAddEvidenceAction {
  type: typeof Types.SUCCESS_MEASURE_EVIDENCE_ADD;
  evidences: EvidenceType;
}

interface IFailureMeasureAddEvidenceAction {
  type: typeof Types.FAILURE_MEASURE_EVIDENCE_ADD;
  message: string;
}

type MeasureAddEvidenceAction =
  | IRequestMeasureAddEvidenceAction
  | ISuccessMeasureAddEvidenceAction
  | IFailureMeasureAddEvidenceAction;

//ADD EVIDENCE
export interface IRequestMeasureAddAssinaturaAction {
  type: typeof Types.REQUEST_MEASURE_ASSINATURA_ADD;
  payload: {
    idFuncionario: number | string;
    idMedidaEducativa: number | string;
    file: File;
  };
}

export interface ISuccessMeasureAddAssinaturaAction {
  type: typeof Types.SUCCESS_MEASURE_ASSINATURA_ADD;
  payload: AssinaturaType;
}

interface IFailureMeasureAddAssinaturaAction {
  type: typeof Types.FAILURE_MEASURE_ASSINATURA_ADD;
  message: string;
}

type MeasureAddAssinaturaAction =
  | IRequestMeasureAddAssinaturaAction
  | ISuccessMeasureAddAssinaturaAction
  | IFailureMeasureAddAssinaturaAction;

//GERA_FORM
export interface IRequestMeasureGeraFormAction {
  type: typeof Types.REQUEST_MEASURE_GERA_FORM;
  payload: {
    idFuncionario: number | string;
    idMedidaEducativa: number | string;
  };
}

export interface ISuccessMeasureGeraFormAction {
  type: typeof Types.SUCCESS_MEASURE_GERA_FORM;
  key: string;
}

interface IFailureMeasureGeraFormAction {
  type: typeof Types.FAILURE_MEASURE_GERA_FORM;
  message: string;
}

type MeasureGeraFormAction =
  | IRequestMeasureGeraFormAction
  | ISuccessMeasureGeraFormAction
  | IFailureMeasureGeraFormAction;

interface IMeasureClearAction {
  type: typeof Types.REQUEST_MEASURE_CLEAR;
}

export type MeasureActionTypes =
  | MeasureLoadAction
  | MeasureAddAction
  | MeasureLoadSuggestedSanctionAction
  | MeasureAddEvidenceAction
  | MeasureAddAssinaturaAction
  | MeasureGeraFormAction
  | IMeasureClearAction;
