import { Types, DataTypes, CCActionTypes } from "./types";
import customToast from "~/components/Toast/index";

/**
 * Reducers
 */
const INITIAL_STATE: DataTypes = {
    data: [],
    error: false,
    loading: false,
    success: false,
};

/**
 * Actions
 */

export const Creators = {
    getCC: (SearchText?: string): CCActionTypes => {
        return {
            type: Types.REQUEST_CC_LOAD,
            payload: {
                SearchText
            }
        }
    },
}

export default function ccReducer(state = INITIAL_STATE, action: CCActionTypes): DataTypes {

    switch (action.type) {
        //LOAD
        case Types.REQUEST_CC_LOAD:
            return { ...state, loading: true, error: false, success: false };
        case Types.SUCCESS_CC_LOAD:
            return { ...state, data: action.payload, loading: false, error: false, success: true };
        case Types.FAILURE_CC_LOAD:
            customToast.error(action.message);
            return { ...state, data: [], loading: false, error: true, success: false };

        default:
            return state;
    }
}
