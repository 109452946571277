import {
  Types,
  DataTypes,
  CycleAvaliationActionTypes,
  AvaliacaoGestorType
} from "./types";

import customToast from "~/components/Toast/index"

/**
 * Reducers
 */
const INITIAL_STATE: DataTypes = {
  data: null,
  dataHistory: [],
  loading: false,
  error: false,
  success: false,
  redirectTo: null,
  loadingSave: false
};



/**
 * Actions
 */

export const Creators = {

  getAvaliationById: (idAvaliacao: number): CycleAvaliationActionTypes => ({
    type: Types.REQUEST_AVALIACAO_BY_ID,
    idAvaliacao
  }),

  getAvaliationHistory: (idFuncionario: number): CycleAvaliationActionTypes => ({
    type: Types.REQUEST_AVALIACAO_HISTORY,
    idFuncionario
  }),

  saveAvaliation: (idTarefa: number, idAvaliacao: number, data: AvaliacaoGestorType): CycleAvaliationActionTypes => ({
    type: Types.REQUEST_AVALIACAO_SAVE,
    payload: {
      idTarefa,
      idAvaliacao,
      data
    }
  }),


  saveAtitudeAvaliacao: (idCicloAvaliacaoAtitude: number, resultado: string): CycleAvaliationActionTypes => ({
    type: Types.SAVE_AVALIATION_ATITUDE_AVALIACAO,
    payload: {
      idCicloAvaliacaoAtitude,
      resultado
    }
  }),

  saveAtitudeComentario: (idCicloAvaliacaoAtitude: number, comentarios: string): CycleAvaliationActionTypes => ({
    type: Types.SAVE_AVALIATION_ATITUDE_COMENTARIO,
    payload: {
      idCicloAvaliacaoAtitude,
      comentarios
    }
  }),

  saveAspiracaoCarreira: (aspiracao: string): CycleAvaliationActionTypes => ({
    type: Types.SAVE_AVALIATION_ASPIRACOES_CARREIRA,
    aspiracao
  }),

  saveComentariosGerais: (comentariosGerais: string): CycleAvaliationActionTypes => ({
    type: Types.SAVE_AVALIATION_COMENTARIOS_GERAIS,
    comentariosGerais
  }),

  saveComentariosPositivo: (comentariosPositivos: string): CycleAvaliationActionTypes => ({
    type: Types.SAVE_AVALIATION_COMENTARIOS_POSITIVO,
    comentariosPositivos
  }),

  saveComentariosMelhorar: (comentariosMelhorar: string): CycleAvaliationActionTypes => ({
    type: Types.SAVE_AVALIATION_COMENTARIOS_MELHORAR,
    comentariosMelhorar
  }),
}

export default function cycleAvaliationReducer(state = INITIAL_STATE, action: CycleAvaliationActionTypes): DataTypes {
  switch (action.type) {
    case Types.REQUEST_AVALIACAO_BY_ID:
      return { ...state, data: null, loading: true, redirectTo: null };
    case Types.SUCCESS_AVALIACAO_BY_ID:
      return { ...state, data: action.payload, loading: false, redirectTo: null };
    case Types.FAILURE_AVALIACAO_BY_ID:
      customToast.error(action.message);
      return { ...state, data: null, loading: false, redirectTo: null };

    case Types.REQUEST_AVALIACAO_SAVE:
      return { ...state, redirectTo: null, loadingSave: true };
    case Types.SUCCESS_AVALIACAO_SAVE:
      customToast.success("Avaliação salva com sucesso");
      return { ...state, redirectTo: `/tarefas/${action.idTarefa}`, loadingSave: false };
    case Types.FAILURE_AVALIACAO_SAVE:
      customToast.error(action.message);
      return { ...state, redirectTo: null, loadingSave: false };

    case Types.REQUEST_AVALIACAO_HISTORY:
      return { ...state, dataHistory: [], loading: true, success: false, error: false };
    case Types.SUCCESS_AVALIACAO_HISTORY:
      return { ...state, dataHistory: action.payload, loading: false, success: true, error: false };
    case Types.FAILURE_AVALIACAO_HISTORY:
      customToast.error(action.message);
      return { ...state, dataHistory: [], loading: false, success: false, error: true };

    case Types.RESET_REDIRECT:
      return { ...state, redirectTo: null }

    case Types.SAVE_AVALIATION_ATITUDE_AVALIACAO:
      return {
        ...state,
        data: {
          ...state.data!,
          atitudes: state.data?.atitudes?.map(item => {
            if (item.idCicloAvaliacaoAtitude !== action.payload.idCicloAvaliacaoAtitude) {
              return item;
            } else {
              return {
                ...item,
                resultado: action.payload.resultado
              };
            }
          })!
        },
        redirectTo: null
      }


    case Types.SAVE_AVALIATION_ATITUDE_COMENTARIO:
      return {
        ...state,
        data: {
          ...state.data!,
          atitudes: state.data?.atitudes?.map(item => {
            if (item.idCicloAvaliacaoAtitude !== action.payload.idCicloAvaliacaoAtitude) {
              return item;
            } else {
              return {
                ...item,
                comentarios: action.payload.comentarios
              };
            }
          })!
        },
        redirectTo: null
      }


    case Types.SAVE_AVALIATION_ASPIRACOES_CARREIRA:
      return {
        ...state,
        redirectTo: null,
        data: {
          ...state.data!,
          aspiracoesCarreira: action.aspiracao
        }
      }
    case Types.SAVE_AVALIATION_COMENTARIOS_GERAIS:
      return {
        ...state,
        redirectTo: null,
        data: {
          ...state.data!,
          comentariosGerais: action.comentariosGerais
        }
      }
    case Types.SAVE_AVALIATION_COMENTARIOS_POSITIVO:
      return {
        ...state,
        redirectTo: null,
        data: {
          ...state.data!,
          comentariosPositivos: action.comentariosPositivos
        }
      }
    case Types.SAVE_AVALIATION_COMENTARIOS_MELHORAR:
      return {
        ...state,
        redirectTo: null,
        data: {
          ...state.data!,
          comentariosMelhorar: action.comentariosMelhorar
        }
      }

    default:
      return state;

  }
}
