import React from 'react';

import { Shimmer, ShimmerElementType, ShimmerElementsGroup } from 'office-ui-fabric-react/lib/Shimmer';


const ShimmerTreeview: React.FC = () => {

    const getCustomElementsExampleOne = (): JSX.Element => {
        return (
            <div style={{display: 'flex'}}>
                <div style={{ width: '57%', minWidth: 430 }}>
                    <ShimmerElementsGroup
                        shimmerElements={[
                            { type: ShimmerElementType.gap, width: 50, height: 7 },
                            { type: ShimmerElementType.line, width: 40, height: 7 },
                        ]}
                    />
                </div>
                <div style={{ width: 20 }}>
                    <ShimmerElementsGroup
                        shimmerElements={[
                            { type: ShimmerElementType.gap, width: 20, height: 7 },
                        ]}
                    />
                </div>
                <div style={{ width: '100%' }}>
                    <ShimmerElementsGroup
                        flexWrap
                        shimmerElements={[
                            { type: ShimmerElementType.line, width: 300, height: 7 },
                        ]}
                    />
                </div>
            </div>
        );
    };

    return (
        <>
            <Shimmer customElementsGroup={getCustomElementsExampleOne()} width="100%" styles={{root:{marginTop: 30}}} />
            <Shimmer customElementsGroup={getCustomElementsExampleOne()} width="100%" styles={{root:{marginTop: 30}}} />
            <Shimmer customElementsGroup={getCustomElementsExampleOne()} width="100%" styles={{root:{marginTop: 30}}} />
            <Shimmer customElementsGroup={getCustomElementsExampleOne()} width="100%" styles={{root:{marginTop: 30}}} />
            <Shimmer customElementsGroup={getCustomElementsExampleOne()} width="100%" styles={{root:{marginTop: 30}}} />
            <Shimmer customElementsGroup={getCustomElementsExampleOne()} width="100%" styles={{root:{marginTop: 30}}} />
            <Shimmer customElementsGroup={getCustomElementsExampleOne()} width="100%" styles={{root:{marginTop: 30}}} />
            <Shimmer customElementsGroup={getCustomElementsExampleOne()} width="100%" styles={{root:{marginTop: 30}}} />
            <Shimmer customElementsGroup={getCustomElementsExampleOne()} width="100%" styles={{root:{marginTop: 30}}} />
            <Shimmer customElementsGroup={getCustomElementsExampleOne()} width="100%" styles={{root:{marginTop: 30}}} />
        </>
    )
}

export default ShimmerTreeview;