import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PainelHeader from '~/components/layout/PainelHeader';

import { Spinner, SpinnerSize, Stack, Text } from 'office-ui-fabric-react';


import { Div, Wrapper } from './styles';
import { PrimaryButton } from '~/components/Buttons';
import { Checkbox, ListItemText, MenuItem, Select } from '@material-ui/core';
import Colors from '~/assets/js/colors';
import { makeStyles } from '@material-ui/core/styles';

import { BreadcrumbItems, Page } from '~/store/ducks/home/types';
import { Creators as CreatorsHome } from '~/store/ducks/home'
import { Creators as CreatorsReport } from '~/store/ducks/reports'
import { DataTypes as DataTypesReport } from '~/store/ducks/reports/types'
import { RootState } from '~/store/ducks';
import Dropdown from '~/components/layout/Dropdown';
import { TableSuccession } from '~/pages/Calibration/Succession';

const useStyles = makeStyles({
    root: {
        height: 32,
        width: 360,
        backgroundColor: 'white',
        '& .MuiOutlinedInput-input': {
            padding: "6px 14.5px"
        },
        '& legend span': {
            display: 'none',
        },
    },
});

const Succession: React.FC = () => {
    const [ano, setAno] = useState('');
    const [grupos, setGrupos] = useState<number[]>([]);
    const reportState = useSelector<RootState, DataTypesReport>(state => state.reportsReducer);
    const { data: report } = reportState;

    const dispatch = useDispatch();

    const classes = useStyles();

    useEffect(() => {
        dispatch(CreatorsHome.setCurrentPage(page))
        dispatch(CreatorsReport.resetReports())
        dispatch(CreatorsReport.getReport())
    }, [dispatch])

    useEffect(() => {
        if (report.temporadas.length > 0 && ano === '') {
            const temporada = report.temporadas[0];
            setAno(String(temporada.ano) ?? '')
        }
    }, [report.temporadas, ano])


    const handleChange = (event: React.ChangeEvent<{ value: string }>) => {
        const ano: string = event.target.value;
        setAno(ano);
        setGrupos([])
        dispatch(CreatorsReport.getReport(ano))

    };

    const handleChangeMultiple = (event: React.ChangeEvent<{ value: unknown }>) => {
        setGrupos(event.target.value as number[]);
    };

    const handleGenerateReport = () => {
        dispatch(CreatorsReport.getReportSuccession(ano, grupos))
    }


    return (
        <Div>
            <PainelHeader
                title="Sucessão"
                icon={{ icon: "ReportDocument" }}
                notHasMarginLabelGroups={true}
            >
                <Stack horizontal horizontalAlign="space-between" verticalAlign="end" styles={{ root: { marginTop: 30, width: '100%' } }}>
                    <Stack styles={{ root: { width: '100%' } }}>
                        <Stack horizontal verticalAlign="center" style={{ height: 32 }}>
                            <Stack styles={{ root: { minWidth: 120 } }}>
                                <Text variant="medium">Ano</Text>
                            </Stack>
                            <Dropdown
                                errors=""
                                variant="outlined"
                                label=""
                                name="season"
                                values={ano}
                                handleChange={handleChange}
                                fieldHeight={32}
                                styles={{
                                    boxSizing: 'border-box',
                                    height: 32,
                                    backgroundColor: 'white',
                                    width: 170
                                }}
                                autofocus
                                customPadding="6.5px 14px"
                            >
                                {
                                    report.temporadas.map(temp =>
                                        <MenuItem
                                            key={temp.ano}
                                            value={temp.ano}
                                        >
                                            {temp.ano}
                                        </MenuItem>
                                    )
                                }
                            </Dropdown>
                        </Stack>
                        <Stack horizontal verticalAlign="center" styles={{ root: { marginTop: '10px !important' } }}>
                            <Stack styles={{ root: { minWidth: 120 } }}>
                                <Text variant="medium">Grupo</Text>
                            </Stack>
                            <Select
                                className={classes.root}
                                variant="outlined"
                                label=""
                                name="grupos"
                                value={grupos}
                                multiple
                                onChange={handleChangeMultiple}
                                disabled={reportState.loading}
                                renderValue={(selected) => {
                                    const nomeGrupos = report.reunioes.filter(item => {
                                        return (selected as any[]).some(idCicloReuniao => idCicloReuniao === item.idCicloReuniao)
                                    }).map(item => item.grupo.nomeGrupo);
                                    return (nomeGrupos as string[]).join(', ')
                                }}
                            >
                                {report.reunioes.map((item, i) => (
                                    <MenuItem key={i} value={item.idCicloReuniao} style={{ height: 40 }}>
                                        <Checkbox checked={grupos.indexOf(item.idCicloReuniao) > -1} style={{ color: Colors.primary }} />
                                        <ListItemText primary={item.grupo.nomeGrupo} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </Stack>
                    </Stack>
                    <PrimaryButton text="Gerar" onClick={handleGenerateReport} />
                </Stack>
            </PainelHeader>
            <Wrapper>
                {
                    reportState.loadingAction ?
                        <Spinner size={SpinnerSize.medium} label="Carregando Curva" styles={{ root: { height: '100%' } }} />
                        :
                        reportState.dataSuccession.length > 0 &&
                        <TableSuccession
                            sucessao={reportState.dataSuccession}
                            readOnly={true}
                        />
                }
            </Wrapper>
        </Div >
    );
}


const itemsBreadCrumb: BreadcrumbItems[] = [
    { text: "Home", isCurrentItem: false, icon: "HomeSolid", onlyIcon: true, url: "/" },
    { text: 'Relatório de Sucessão ', isCurrentItem: true },
];

const page: Page = {
    key: 'relatorioSuccession',
    pages: itemsBreadCrumb
};


export default Succession;