// #region Imports
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
 
import moment from "moment";
//FluentUI
import {
  Pivot,
  PivotItem,
  IPivotStyles,
} from "office-ui-fabric-react/lib/Pivot";
import { Selection } from "office-ui-fabric-react/lib/DetailsList";

import { Page } from "~/store/ducks/home/types";
import PainelHeader from "~/components/layout/PainelHeader";
import CustomDialog from "~/components/CustomDialog";
//Estilos
import { Wrapper, ContainerContent, WrapperCalib } from "./styles";

import { Creators as setCurrentPage } from "~/store/ducks/home";
import {
  AvaliadosType,
  DataTypes as DataTypesCalibration,
  PerformanceAvaliado,
} from "~/store/ducks/calibration/types";
import { Creators as getMeetingById } from "~/store/ducks/calibration";
import { Creators as finalizar } from "~/store/ducks/calibration";
import { Creators as addComment } from "~/store/ducks/calibration";
import { Creators as loadAvaliado } from "~/store/ducks/calibration";
import { Creators as stopCalibration } from "~/store/ducks/calibration";
import { Creators as saveAvaliado } from "~/store/ducks/calibration";
import { Creators as getWorklevel } from "~/store/ducks/worklevel";

import { Creators as getChart } from "~/store/ducks/admin/chart";

import { RootState } from "~/store/ducks";

import CurvePerformance from "./Perfomance";
import CurveSuccession from "./Succession";
import Calibrations from "./Calibrations";
import {
  DefaultButton,
  FontIcon,
  IconButton,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Stack,
  Text,
} from "office-ui-fabric-react";
import CounterDown from "~/components/CountDown";
import { InputText } from "~/components/Forms";
import DialogCalibration from "./DialogCalibration";
import { GroupItems, TitleField } from "~/components/FormGroup";
import NoItems from "~/components/layout/NoItems";
import { ChartType } from "~/store/ducks/admin/chart/types";

export interface FilterType {
  gestor: string;
  cargo: string;
  tempo: string;
  idTabPerformance: string;
  idTabPerformanceGestor: string;
  area: Partial<ChartType>;
  showHidden: boolean;
}

export interface ICalibrationActive {
  isActive: boolean;
  idCicloReuniaoAvaliado: number | null;
  canFinish: boolean;
  collapseInfo: boolean;
}

export interface IDialogCalibrating {
  isOpen: boolean;
  idCicloReuniaoAvaliado: number | null;
}

interface ICalibrationState {
  selectionDetails: string;
  nivel: string;
  lockedNivel: boolean;
  filter: FilterType;
  isFiltering: boolean;
  items: AvaliadosType[];
  calibrationActive: ICalibrationActive;
  comentariosCalib: string;
  dialogCalibration: IDialogCalibrating;
  curveType: "calibrada" | "inicial";
  showIdentification: boolean;
  clearSelection: boolean;
  selectionCount: number;
  indexSelected: number | undefined;
  nivelSucessao: string;
  lockedNivelSucessao: boolean;
  isDialogFinishOpen: boolean;
  comentarioFinal: {
    value: string;
    error: boolean;
  };
}

interface IPropsCalibration extends RouteComponentProps {
  calibrationState: DataTypesCalibration;
  getChart: (
    search?: string,
    filter?: boolean | null,
    treeview?: boolean
  ) => void;

  setCurrentPage: (page: Page) => void;
  getMeetingById: (idCicloReuniao: number) => void;
  finalizar: (idCicloReuniao: number, comentariosFinais: string) => void;
  addComment: (
    idCicloReuniao: number,
    idCicloReuniaoAvaliado: number,
    comentarios: string
  ) => void;
  loadAvaliado: (
    idCicloReuniao: number,
    idCicloReuniaoAvaliado: number
  ) => void;
  stopCalibration: (
    idCicloReuniao: number,
    idCicloReuniaoAvaliado: number
  ) => void;
  saveAvaliado: (
    idCicloReuniao: number,
    idCicloReuniaoAvaliado: number,
    performance: PerformanceAvaliado,
    idTabPerformanceOriginal: number,
    sucessao: string
  ) => void;
  getWorklevel: () => void;

  match: any;
  history: RouteComponentProps["history"];
}

class Calibration extends Component<IPropsCalibration, ICalibrationState> {
  private _selection: Selection;

  constructor(props: IPropsCalibration) {
    super(props);
    this.state = {
      nivel: "",
      lockedNivel: false,
      filter: {
        gestor: "",
        cargo: "",
        tempo: "",
        idTabPerformance: "",
        idTabPerformanceGestor: "",
        area: {
          idArea: null,
          nomeLocal: "",
        },
        showHidden: false,
      },
      items: [],
      isFiltering: false,
      calibrationActive: {
        isActive: false,
        idCicloReuniaoAvaliado: null,
        canFinish: false,
        collapseInfo: false,
      },
      comentariosCalib: "",
      dialogCalibration: {
        idCicloReuniaoAvaliado: null,
        isOpen: false,
      },
      selectionDetails: "",

      curveType: "calibrada",
      showIdentification: false,
      clearSelection: false,
      selectionCount: 0,
      indexSelected: undefined,

      nivelSucessao: "",
      lockedNivelSucessao: false,
      isDialogFinishOpen: false,
      comentarioFinal: {
        value: "",
        error: false,
      },
    };

    this._selection = new Selection({
      onSelectionChanged: () => {
        this.setState({
          selectionDetails: this._getSelectionDetails(),
        });
      },
    });
  }

  componentDidMount() {
    const { idCicloReuniao } = this.props.match.params;
    if (idCicloReuniao) {
      const page: Page = {
        key: "calibracao",
        pages: [
          {
            text: "Home",
            isCurrentItem: false,
            icon: "HomeSolid",
            onlyIcon: true,
            url: "/",
          },
          { text: "Reuniões", url: "/reunioes" },
          { text: "Calibração", isCurrentItem: true },
        ],
      };
      this.props.setCurrentPage(page);
      this.props.getMeetingById(idCicloReuniao);
      this.props.getWorklevel();
      this.props.getChart("", true, false);
    } else {
      this.props.history.push("/reunioes");
    }
  }

  componentDidUpdate(
    prevProps: IPropsCalibration,
    prevState: ICalibrationState
  ) {
    const {
      success: prevSuccessAvaliado,
    } = prevProps.calibrationState.avaliadoSelected;
    const {
      success: nextSuccessAvaliado,
    } = this.props.calibrationState.avaliadoSelected;

    if (prevSuccessAvaliado !== nextSuccessAvaliado) {
      if (nextSuccessAvaliado) {
        this._selection.setAllSelected(false);
        this.setState({
          dialogCalibration: {
            isOpen: false,
            idCicloReuniaoAvaliado: null,
          },
          calibrationActive: {
            ...this.state.calibrationActive,
            canFinish: true,
          },
        });
      }
    }

    if (
      prevProps.calibrationState.avaliadoSelected.success !==
      this.props.calibrationState.avaliadoSelected.success
    ) {
      if (this.props.calibrationState.avaliadoSelected.success) {
        //this.props.stopCalibration(this.props.match.params.idCicloReuniao!, this.state.calibrationActive.idCicloReuniaoAvaliado!)
        this._selection.setAllSelected(false);
        this.setState({
          calibrationActive: {
            canFinish: false,
            isActive: false,
            idCicloReuniaoAvaliado: null,
            collapseInfo: false,
          },
          items:
            this.props.calibrationState.calibration?.avaliados?.filter(
              (item) =>
                item.idCicloReuniaoAvaliado ===
                this.state.items.find(
                  (it) =>
                    it.idCicloReuniaoAvaliado === item.idCicloReuniaoAvaliado
                )?.idCicloReuniaoAvaliado
            ) ?? [],
        });
      }
    }

    if (
      prevProps.calibrationState.avaliadoSelected.errorLoading !==
      this.props.calibrationState.avaliadoSelected.errorLoading
    ) {
      if (this.props.calibrationState.avaliadoSelected.errorLoading) {
        this._selection.setAllSelected(false);
        this.setState({
          calibrationActive: {
            canFinish: false,
            isActive: false,
            idCicloReuniaoAvaliado: null,
            collapseInfo: false,
          },
        });
      }
    }

    if (
      prevProps.calibrationState.successAnotations !==
      this.props.calibrationState.successAnotations
    ) {
      if (this.props.calibrationState.successAnotations) {
        this.setState({
          items: this.state.items.map(prevItem => {
           const itemUpdated = this.props.calibrationState.calibration?.avaliados?.find(newItem => 
            prevItem.idFuncionario === newItem.idFuncionario) ?? [];
          return {...prevItem, ...itemUpdated};
          })
        });
      }
    }

    if (
      prevProps.calibrationState.successLoadingNivel !==
      this.props.calibrationState.successLoadingNivel
    ) {
      if (this.props.calibrationState.successLoadingNivel) {
        this.setState({
          items: this.props.calibrationState.calibration?.avaliados ?? [],
        });
      }
    }
    // if (prevState.curveType !== this.state.curveType) {
    //   if (this.state.curveType === 'inicial') {
    //     this.setState({ showIdentification: false })
    //   }
    // }
  }
  //#region  Funções do filtro

  _getSelectionDetails(): any {
    const selectionCount = this._selection.getSelectedCount();
    const selectionItem = this._selection.getSelectedIndices()[0];
    this.setState({ selectionCount, indexSelected: selectionItem });
    this._selection.getSelection();
  }

  //#endregion

  render() {
    const {
      nivel,
      lockedNivel,
      filter,
      isFiltering,
      items,
      calibrationActive,
      comentariosCalib,
      dialogCalibration,
      curveType,
      showIdentification,
      clearSelection,
      nivelSucessao,
      lockedNivelSucessao,
      isDialogFinishOpen,
      comentarioFinal,
    } = this.state;
    const {
      calibrationState,
      match,
      finalizar,
      addComment,
      loadAvaliado,
      stopCalibration,
      saveAvaliado,
    } = this.props;
    const { calibration, avaliadoSelected } = calibrationState;
    const { reuniao, performances, niveis, curva } = calibration ?? {};
    const {
      grupo,
      ciclo,
      lider,
      rh,
      hrbp,
      status,
      dataHoraInicio,
      flagHabilitaFinalizar,
    } = reuniao ?? {};
    const { dataInit: avaliadoDataInit, avaliadoCalib } =
      avaliadoSelected ?? {};

    const { params } = match;
    const { idCicloReuniao } = params;

    const allAvaliadosCurva = curva?.flatMap((item) => item.curvaAjustada);
    const totalAvaliadosCurva = allAvaliadosCurva?.reduce(
      (total, item) => total + (item?.idTabPerformance !== null ? 1 : 0),
      0
    );

    const readOnly = !flagHabilitaFinalizar || status === "Finalizada";

    const labelGroup = [
      {
        icon: "DateTime",
        label: "Data/Hora de Início",
        value: dataHoraInicio
          ? moment(dataHoraInicio).utc().format("DD/MM/YYYY HH:mm")
          : "",
      },
      {
        icon: "Contact",
        label: "Líder",
        value: lider?.nomeCompleto ?? "",
      },
      {
        icon: "Flag",
        label: "Status",
        value: status ?? "",
      },
      {
        icon: "Contact",
        label: "HR Business Partner",
        value: hrbp?.nomeCompleto ?? "",
      },
      {
        icon: "Contact",
        label: "Responsável RH",
        value: rh?.nomeCompleto ?? "",
      },
    ];

    if (
      readOnly ||
      (allAvaliadosCurva?.length &&
        totalAvaliadosCurva !== allAvaliadosCurva?.length)
    ) {
      labelGroup.push({
        icon: "ProgressRingDots",
        label: "Calibrados / Total",
        value: `${totalAvaliadosCurva} / ${allAvaliadosCurva?.length}`,
      });
    }

    return (
      <>
        <Wrapper>
          {calibrationState.loadingData ? (
            <Spinner
              size={SpinnerSize.medium}
              label="Carregando reunião"
              styles={{ root: { height: "100%" } }}
            />
          ) : calibrationState.error ? (
            <NoItems error={true} text="Não foi possível carregar a reunião" />
          ) : (
            <>
              <PainelHeader
                title={`${grupo?.nomeGrupo ?? ""} - ${ciclo?.ano ?? ""}`}
                icon={{ icon: "Equalizer" }}
                labelGroups={calibration ? labelGroup : []}
                button={
                  !readOnly && totalAvaliadosCurva === allAvaliadosCurva?.length
                    ? {
                        text: "Finalizar",
                        handleButton: () =>
                          this.setState({
                            isDialogFinishOpen: true,
                            comentarioFinal: {
                              value: comentarioFinal.value,
                              error: false,
                            },
                          }),
                        isLoadingAction: calibrationState.loadingAction,
                        disabled: readOnly,
                      }
                    : null
                }
              />
              <ContainerContent>
                <Pivot aria-label="Calibração" styles={pivotStyles}>
                  <PivotItem headerText="Calibrações">
                    {calibrationState.loadingNivel ? (
                      <Spinner
                        size={SpinnerSize.medium}
                        label="Carregando avaliados"
                        styles={{ root: { height: 340 } }}
                      />
                    ) : (
                      <Calibrations
                        ano={ciclo?.ano}
                        state={{
                          nivel,
                          lockedNivel,
                          filter,
                          isFiltering,
                          items,
                          calibrationActive,
                          clearSelection,
                          _selection: this._selection,
                        }}
                        setFilter={(values) =>
                          this.setState({ filter: values })
                        }
                        setIsFiltering={(value) =>
                          this.setState({ isFiltering: value })
                        }
                        setLockedNivel={(value) =>
                          this.setState({ lockedNivel: value })
                        }
                        setNivel={(value) => this.setState({ nivel: value })}
                        setItems={(values) => this.setState({ items: values })}
                        setCalibrationActive={(values) =>
                          this.setState({ calibrationActive: values })
                        }
                        setComentariosCalib={(values) =>
                          this.setState({ comentariosCalib: values })
                        }
                        setDialogCalibration={(values) =>
                          this.setState({ dialogCalibration: values })
                        }
                      />
                    )}
                  </PivotItem>
                  <PivotItem headerText="Curva Performance">
                    <CurvePerformance
                      idCicloReuniao={idCicloReuniao}
                      curva={curva ?? []}
                      performances={performances ?? []}
                      readOnly={readOnly}
                      curveType={curveType}
                      setCurveType={(value) =>
                        this.setState({ curveType: value })
                      }
                      showIdentification={showIdentification}
                      setShowIdentification={(value) =>
                        this.setState({ showIdentification: value })
                      }
                    />
                  </PivotItem>
                  <PivotItem headerText="Sucessão">
                    <CurveSuccession
                      idCicloReuniao={idCicloReuniao}
                      niveis={niveis}
                      state={{
                        nivelSucessao,
                        lockedNivelSucessao,
                        avaliadoSelected,
                        performances: performances ?? [],
                        readOnly,
                      }}
                      setNivelSucessao={(value) =>
                        this.setState({ nivelSucessao: value })
                      }
                      setLockedNivelSucessao={(value) =>
                        this.setState({ lockedNivelSucessao: value })
                      }
                    />
                  </PivotItem>
                </Pivot>
              </ContainerContent>

              <WrapperCalib isOpen={calibrationActive.isActive}>
                {!(
                  avaliadoSelected.successLoadingTimer &&
                  calibrationActive.isActive
                ) ? (
                  <Spinner
                    size={SpinnerSize.medium}
                    label="Carregando calibração"
                    styles={{ root: { height: 259 } }}
                  />
                ) : (
                  <>
                    <Stack
                      horizontal
                      horizontalAlign="space-between"
                      styles={{ root: { width: "100%" } }}
                    >
                      <Stack>
                        <Text
                          variant="medium"
                          styles={{ root: { fontWeight: 600 } }}
                        >
                          Calibração Ativa
                        </Text>
                        <Stack
                          horizontal
                          verticalAlign="center"
                          styles={{ root: { marginTop: "15px !important" } }}
                        >
                          <FontIcon
                            iconName="Contact"
                            style={{ marginRight: 10 }}
                          />
                          <Text
                            variant="mediumPlus"
                            styles={{ root: { fontWeight: 600 } }}
                          >
                            {
                              avaliadoDataInit?.avaliado?.funcionario
                                ?.nomeCompleto
                            }
                          </Text>
                        </Stack>

                        <Stack styles={{ root: { marginLeft: 25 } }}>
                          <Stack
                            horizontal
                            styles={{ root: { marginTop: "10px !important" } }}
                          >
                            <Stack
                              horizontal
                              verticalAlign="center"
                              styles={{ root: { width: 150 } }}
                            >
                              <FontIcon
                                iconName="BirthdayCake"
                                style={{ marginRight: 10 }}
                              />
                              <Text
                                variant="medium"
                                styles={{ root: { fontWeight: 500 } }}
                              >
                                {avaliadoDataInit?.avaliado?.funcionario?.idade}
                              </Text>
                            </Stack>
                            <Stack horizontal verticalAlign="center">
                              <FontIcon
                                iconName="HourGlass"
                                style={{ marginRight: 10 }}
                              />
                              <Text
                                variant="medium"
                                styles={{ root: { fontWeight: 500 } }}
                              >
                                {avaliadoDataInit?.avaliado?.funcionario?.tempo}
                              </Text>
                            </Stack>
                          </Stack>
                          <Stack
                            horizontal
                            verticalAlign="center"
                            styles={{ root: { marginTop: "10px !important" } }}
                          >
                            <FontIcon
                              iconName="Work"
                              style={{ marginRight: 10 }}
                            />
                            <Text
                              variant="medium"
                              styles={{ root: { fontWeight: 500 } }}
                            >
                              {avaliadoDataInit?.avaliado?.info?.titulo}
                            </Text>
                          </Stack>
                        </Stack>
                      </Stack>
                      <Stack>
                        {!readOnly && (
                          <CounterDown
                            limit={
                              reuniao?.grupo.minutos
                                ? reuniao?.grupo.minutos * 60
                                : 10 * 60
                            }
                            acumulated={avaliadoDataInit?.tempoAcumulado ?? 0}
                            isActive={calibrationActive.isActive}
                          />
                        )}
                      </Stack>
                    </Stack>
                    <Stack
                      horizontal
                      styles={{
                        root: {
                          flexWrap: "wrap",
                          marginTop: "10px !important",
                        },
                      }}
                    >
                      <Stack
                        horizontal
                        styles={{ root: { width: "100%", marginTop: 16 } }}
                      >
                        <FontIcon
                          iconName="CommentAdd"
                          style={{ marginRight: 6, fontSize: 14 }}
                        />
                        <Stack
                          styles={{
                            root: { width: "100%", position: "relative" },
                          }}
                        >
                          <IconButton
                            iconProps={{ iconName: "Save" }}
                            styles={{
                              root: {
                                fontSize: 16,
                                position: "absolute",
                                top: 0,
                                right: 0,
                                zIndex: 10,
                              },
                              rootDisabled: {
                                fontSize: 16,
                                position: "absolute",
                                top: 0,
                                right: 0,
                                zIndex: 10,
                              },
                            }}
                            disabled={readOnly}
                            onClick={() =>
                              addComment(
                                Number(idCicloReuniao),
                                calibrationActive.idCicloReuniaoAvaliado!,
                                comentariosCalib
                              )
                            }
                          />
                          <InputText
                            name="comentarios"
                            label="Comentários"
                            value={comentariosCalib}
                            onChange={(e) =>
                              this.setState({
                                comentariosCalib: e.target.value,
                              })
                            }
                            multiline
                            height_container={"100%"}
                            rows={3}
                            rowsMax={3}
                            disabled={readOnly}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Stack>
                      </Stack>
                    </Stack>
                    <Stack
                      horizontal
                      horizontalAlign="end"
                      styles={{ root: { marginTop: "16px !important" } }}
                    >
                      <DefaultButton
                        text="Fechar"
                        styles={{ root: { width: 110, marginRight: 10 } }}
                        onClick={() => {
                          this.setState({
                            calibrationActive: {
                              canFinish: false,
                              idCicloReuniaoAvaliado: null,
                              isActive: false,
                              collapseInfo: false,
                            },
                          });
                          this.setState({
                            items: items.map((it) => it),
                            isFiltering: true,
                            clearSelection: !clearSelection,
                          });
                          stopCalibration(
                            idCicloReuniao,
                            calibrationActive.idCicloReuniaoAvaliado!
                          );
                        }}
                      />
                      <PrimaryButton
                        text={readOnly ? "Visualizar" : "Calibrar"}
                        styles={{ root: { width: 110 } }}
                        onClick={() => {
                          if (comentariosCalib) {
                            addComment(
                              Number(idCicloReuniao),
                              calibrationActive.idCicloReuniaoAvaliado!,
                              comentariosCalib
                            );
                            this.setState({
                              comentariosCalib: "",
                            });
                          }
                          loadAvaliado(
                            Number(idCicloReuniao),
                            calibrationActive.idCicloReuniaoAvaliado!
                          );
                          this.setState({
                            dialogCalibration: {
                              isOpen: true,
                              idCicloReuniaoAvaliado:
                                calibrationActive.idCicloReuniaoAvaliado,
                            },
                          });
                        }}
                      />
                    </Stack>
                  </>
                )}
              </WrapperCalib>

              <DialogCalibration
                isOpen={dialogCalibration.isOpen}
                loadingData={avaliadoSelected.loading}
                success={avaliadoSelected.successLoading}
                initialValues={{
                  nomeCompleto:
                    avaliadoCalib?.avaliado?.funcionario?.nomeCompleto ?? "",
                  idTabPerformance:
                    avaliadoCalib?.avaliado?.idTabPerformance ?? null,
                  potencial: avaliadoCalib?.avaliado?.potencial ?? "",
                  sucessao: avaliadoCalib?.avaliado?.sucessao ?? "",
                  carreira: avaliadoCalib?.avaliado?.carreira ?? "",
                  proximoCiclo: avaliadoCalib?.avaliado?.proximoCiclo ?? "",
                  performance: {
                    descNivel:
                      avaliadoCalib?.avaliado?.avaliacaoGestor
                        ?.performancegestor?.descNivel ?? "",
                    cor:
                      avaliadoCalib?.avaliado?.avaliacaoGestor
                        ?.performancegestor?.cor ?? "",
                    nivel: avaliadoCalib?.avaliado?.performance?.nivel ?? null,
                  },
                  avaliacaoGestor: {
                    idTabPerformance:
                      Number(
                        avaliadoCalib?.avaliado?.avaliacaoGestor
                          ?.idTabPerformanceGestor
                      ) ?? null,
                    performancegestor:
                      avaliadoCalib?.avaliado?.avaliacaoGestor
                        ?.performancegestor,
                    potencial:
                      avaliadoCalib?.avaliado?.avaliacaoGestor?.potencial ?? "",
                    sucessao:
                      avaliadoCalib?.avaliado?.avaliacaoGestor?.sucessao ?? "",
                  },
                  anotacoes: avaliadoCalib?.comentarios ?? [],
                  aspiracoesCarreira:
                    avaliadoCalib?.avaliado?.avaliacaoGestor
                      ?.aspiracoesCarreira ?? "",
                }}
                performances={performances ?? []}
                readonly={readOnly}
                onClose={() =>
                  this.setState({
                    dialogCalibration: {
                      isOpen: false,
                      idCicloReuniaoAvaliado: null,
                    },
                  })
                }
                onSuccess={(values) => {
                  const nivelPerformance =
                    avaliadoCalib?.avaliado?.performance?.nivel;
                  const nivelOriginal =
                    nivelPerformance !== null && nivelPerformance !== undefined
                      ? nivelPerformance
                      : Number(
                          avaliadoCalib?.avaliado?.avaliacaoGestor
                            ?.performancegestor.nivel
                        ) ?? null;
                  const sucessaoOriginal = avaliadoCalib?.avaliado?.sucessao
                    ? avaliadoCalib?.avaliado?.sucessao
                    : avaliadoCalib?.avaliado?.avaliacaoGestor?.sucessao ?? "";

                  saveAvaliado(
                    idCicloReuniao,
                    dialogCalibration?.idCicloReuniaoAvaliado!,
                    values,
                    nivelOriginal!,
                    sucessaoOriginal
                  );
                }}
              />

              <CustomDialog
                open={isDialogFinishOpen}
                title="Finalizar Reunião"
                onClickCancel={() =>
                  this.setState({
                    isDialogFinishOpen: false,
                    comentarioFinal: { value: "", error: false },
                  })
                }
                onClickConfirm={() => {
                  if (comentarioFinal.value) {
                    finalizar(idCicloReuniao, comentarioFinal.value);
                    this.setState({ isDialogFinishOpen: false });
                  } else {
                    this.setState({
                      comentarioFinal: { ...comentarioFinal, error: true },
                    });
                  }
                }}
                width={640}
              >
                <GroupItems
                  noMargins
                  stylesWrapperItems={{ justifyContent: "space-between" }}
                >
                  <GroupItems title="Reunião" titleRegular>
                    <TitleField text={grupo?.nomeGrupo ?? ""} bold />
                  </GroupItems>
                </GroupItems>
                <InputText
                  name="comentarios"
                  label="Comentários sobre o final da calibração"
                  value={comentarioFinal.value}
                  onChange={(e) =>
                    this.setState({
                      comentarioFinal: {
                        value: e.target.value,
                        error: false,
                      },
                    })
                  }
                  height_container="calc(100% + 20px)"
                  multiline
                  rows={3}
                  rowsMax={3}
                  error={comentarioFinal.error}
                  helperText={comentarioFinal.error ? "Campo obrigatório" : " "}
                />

                <MessageBar
                  styles={{ root: { marginTop: 10 } }}
                  messageBarType={MessageBarType.severeWarning}
                >
                  Ao finalizar a reunião, não será mais possível reverter as
                  calibrações. Os gestores dos funcionários avaliados receberão
                  uma tarefa de FEEDBACK para cada funcionário avaliado.
                </MessageBar>
              </CustomDialog>
            </>
          )}
        </Wrapper>
      </>
    );
  }
}

const pivotStyles: Partial<IPivotStyles> = {
  root: {
    borderBottom: "1px solid #CFD3DA",
    backgroundColor: "#FFFFFF",
    width: "100%",
    zIndex: 999,
  },
  linkContent: {
    width: 130,
  },
};

const mapStateToProps = (state: RootState) => ({
  calibrationState: state.calibrationReducer,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      ...setCurrentPage,
      ...getMeetingById,
      ...finalizar,
      ...addComment,
      ...loadAvaliado,
      ...stopCalibration,
      ...saveAvaliado,
      ...getWorklevel,
      ...getChart,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Calibration)
);
