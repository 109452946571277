/**
 * Types
 */
export enum Types {
  REQUEST_LOAD_MOTIVOS_AFASTAMENTOS = "motivosAfastamento/REQUEST_LOAD_MOTIVOS_AFASTAMENTOS",
  SUCCESS_LOAD_MOTIVOS_AFASTAMENTOS = "motivosAfastamento/SUCCESS_LOAD_MOTIVOS_AFASTAMENTOS",
  FAILURE_LOAD_MOTIVOS_AFASTAMENTOS = "motivosAfastamento/FAILURE_LOAD_MOTIVOS_AFASTAMENTOS",

  REQUEST_LOAD_MOTIVO_AFASTAMENTO_BY_ID = "motivosAfastamento/REQUEST_LOAD_MOTIVO_AFASTAMENTO_BY_ID",
  SUCCESS_LOAD_MOTIVO_AFASTAMENTO_BY_ID = "motivosAfastamento/SUCCESS_LOAD_MOTIVO_AFASTAMENTO_BY_ID",
  FAILURE_LOAD_MOTIVO_AFASTAMENTO_BY_ID = "motivosAfastamento/FAILURE_LOAD_MOTIVO_AFASTAMENTO_BY_ID",

  REQUEST_ADD_MOTIVO_AFASTAMENTO = "motivosAfastamento/REQUEST_ADD_MOTIVO_AFASTAMENTO",
  SUCCESS_ADD_MOTIVO_AFASTAMENTO = "motivosAfastamento/SUCCESS_ADD_MOTIVO_AFASTAMENTO",
  FAILURE_ADD_MOTIVO_AFASTAMENTO = "motivosAfastamento/FAILURE_ADD_MOTIVO_AFASTAMENTO",

  REQUEST_EDIT_MOTIVO_AFASTAMENTO = "motivosAfastamento/REQUEST_EDIT_MOTIVO_AFASTAMENTO",
  SUCCESS_EDIT_MOTIVO_AFASTAMENTO = "motivosAfastamento/SUCCESS_EDIT_MOTIVO_AFASTAMENTO",
  FAILURE_EDIT_MOTIVO_AFASTAMENTO = "motivosAfastamento/FAILURE_EDIT_MOTIVO_AFASTAMENTO",

  REQUEST_DEL_MOTIVO_AFASTAMENTO = "motivosAfastamento/REQUEST_DEL_MOTIVO_AFASTAMENTO",
  SUCCESS_DEL_MOTIVO_AFASTAMENTO = "motivosAfastamento/SUCCESS_DEL_MOTIVO_AFASTAMENTO",
  FAILURE_DEL_MOTIVO_AFASTAMENTO = "motivosAfastamento/FAILURE_DEL_MOTIVO_AFASTAMENTO",
}

export type MotivosAfastamentosLoadDTO = {
  idMotivoAfastamento: number;
  descMotivoAfastamento: string;
};

export type MotivoAfastamentoAddOrEditDTO = {
  idMotivoAfastamento?: number;
  descMotivoAfastamento: string;
  flagMerito: boolean;
};

export type MotivoAfastamentoType = {
  idMotivoAfastamento: number;
  descMotivoAfastamento: string;
  flagMerito: boolean;
};

export type ItemSelectedType = {
  item: MotivoAfastamentoType | null;
  loading: boolean;
  success: boolean;
  error: boolean;
};

interface IMotivosAfastamentoState {
  readonly data: MotivosAfastamentosLoadDTO[];
  readonly loadingData: boolean;
  readonly loadingAction: boolean;
  readonly success: boolean;
  readonly error: boolean;
  readonly itemSelected: ItemSelectedType;
}

export type DataTypes = IMotivosAfastamentoState;

/**
 * Actions
 */

// LOAD
export interface IRequestGetMotivosAfastamentosAction {
  type: typeof Types.REQUEST_LOAD_MOTIVOS_AFASTAMENTOS;
}

export interface ISuccessGetMotivosAfastamentosAction {
  type: typeof Types.SUCCESS_LOAD_MOTIVOS_AFASTAMENTOS;
  payload: MotivosAfastamentosLoadDTO[];
}

export interface IFailureGetMotivosAfastamentosAction {
  type: typeof Types.FAILURE_LOAD_MOTIVOS_AFASTAMENTOS;
  message: string;
}

type MotivosAfastamentosActionGetActions =
  | IRequestGetMotivosAfastamentosAction
  | ISuccessGetMotivosAfastamentosAction
  | IFailureGetMotivosAfastamentosAction;

//LOAD BY ID

export interface IRequestGetByIdMotivoAfastamentoAction {
  type: typeof Types.REQUEST_LOAD_MOTIVO_AFASTAMENTO_BY_ID;
  idMotivoAfastamento: number;
}

export interface ISuccessGetByIdMotivoAfastamentoAction {
  type: typeof Types.SUCCESS_LOAD_MOTIVO_AFASTAMENTO_BY_ID;
  payload: MotivoAfastamentoType;
}

export interface IFailureGetByIdMotivoAfastamentoAction {
  type: typeof Types.FAILURE_LOAD_MOTIVO_AFASTAMENTO_BY_ID;
  message: string;
}

type MotivoAfastamentoGetByIdActions =
  | IRequestGetByIdMotivoAfastamentoAction
  | ISuccessGetByIdMotivoAfastamentoAction
  | IFailureGetByIdMotivoAfastamentoAction;

// //ADD

export interface IRequestAddMotivoAfastamentoAction {
  type: typeof Types.REQUEST_ADD_MOTIVO_AFASTAMENTO;
  payload: MotivoAfastamentoAddOrEditDTO;
}

export interface ISuccessAddMotivoAfastamentoAction {
  type: typeof Types.SUCCESS_ADD_MOTIVO_AFASTAMENTO;
  payload: MotivosAfastamentosLoadDTO;
}

export interface IFailureAddMotivoAfastamentoAction {
  type: typeof Types.FAILURE_ADD_MOTIVO_AFASTAMENTO;
  message: string;
}

type MotivoAfastamentoAddActions =
  | IRequestAddMotivoAfastamentoAction
  | ISuccessAddMotivoAfastamentoAction
  | IFailureAddMotivoAfastamentoAction;

//EDIT

export interface IRequestEditMotivoAfastamentoAction {
  type: typeof Types.REQUEST_EDIT_MOTIVO_AFASTAMENTO;
  payload: MotivoAfastamentoAddOrEditDTO;
}

export interface ISuccessEditMotivoAfastamentoAction {
  type: typeof Types.SUCCESS_EDIT_MOTIVO_AFASTAMENTO;
  payload: MotivosAfastamentosLoadDTO;
}

export interface IFailureEditMotivoAfastamentoAction {
  type: typeof Types.FAILURE_EDIT_MOTIVO_AFASTAMENTO;
  message: string;
}

type MotivoAfastamentoEditActions =
  | IRequestEditMotivoAfastamentoAction
  | ISuccessEditMotivoAfastamentoAction
  | IFailureEditMotivoAfastamentoAction;

//DELETE

export interface IRequestDelMotivoAfastamentoAction {
  type: typeof Types.REQUEST_DEL_MOTIVO_AFASTAMENTO;
  idMotivoAfastamento: number;
}

export interface ISuccessDelMotivoAfastamentoAction {
  type: typeof Types.SUCCESS_DEL_MOTIVO_AFASTAMENTO;
  idMotivoAfastamento: number;
}

export interface IFailureDelMotivoAfastamentoAction {
  type: typeof Types.FAILURE_DEL_MOTIVO_AFASTAMENTO;
  message: string;
}

type MotivoAfastamentoDelActions =
  | IRequestDelMotivoAfastamentoAction
  | ISuccessDelMotivoAfastamentoAction
  | IFailureDelMotivoAfastamentoAction;

export type MotivosAfastamentosActionTypes =
  | MotivosAfastamentosActionGetActions
  | MotivoAfastamentoGetByIdActions
  | MotivoAfastamentoAddActions
  | MotivoAfastamentoEditActions
  | MotivoAfastamentoDelActions;
