import React, { useEffect, useState } from 'react';
import { useFormik } from "formik";
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import moment from 'moment';

import TableGoals from "~/pages/Profile/Goals/TableGoals";
import KeyProjects from "~/pages/Profile/Goals/KeyProjects";

import { Text, ITextStyles } from 'office-ui-fabric-react/lib/Text';
import { Spinner, SpinnerSize, Stack } from 'office-ui-fabric-react';
import { FontIcon } from 'office-ui-fabric-react/lib/Icon';
import { CommandBarButton } from 'office-ui-fabric-react';
import { DefaultButton, PrimaryButton } from 'office-ui-fabric-react';

import MuiAccordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import { withStyles } from '@material-ui/core/styles';

import PainelHeader from '~/components/layout/PainelHeader'
import { InputText } from '~/components/Forms';
import { DeleteButton } from '~/components/Buttons'
import NoItems from '~/components/layout/NoItems'

import { Page, BreadcrumbItems } from "~/store/ducks/home/types";
import { Creators } from "~/store/ducks/home";
import { Creators as CreatorsSolicitation } from "~/store/ducks/solicitation";
import { DataTypes as DataTypesSolicitation, IMetaSolicitation } from "~/store/ducks/solicitation/types";
import { Creators as CreatorsWorkflow } from "~/store/ducks/workflow";
import { RootState } from "~/store/ducks/index";

import {
  Wrapper,
  Div,
} from "./styles";

import Colors from '~/assets/js/colors';

const Accordion = withStyles({
  root: {
    padding: '0px !important',
    paddingBottom: 10,
    boxShadow: 'none',

    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 0,
    },
  },
  expanded: {
    borderBottom: 0,
  },
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    borderBottom: "1px solid #CFD3DA",
    height: 50,
    minHeight: '50px !important',
    '&:hover': {
      backgroundColor: '#F3F2F1'
    },
    expanded: {
    },
  }
})(MuiAccordionSummary);

const textStyles: ITextStyles = {
  root: {
    fontWeight: 500
  }
}

const validationSchemaReprovado = yup.object().shape({
  motivoCancelamento: yup.string().required("Campo obrigatório"),
});

const validationSchemaAprovado = yup.object().shape({
  motivoCancelamento: yup.string(),
});


const IndividualTask: React.FC = () => {
  const [approval, setApproval] = useState<string>('n')
  const dispatch = useDispatch();
  const params = useParams() as any;
  const { id: idSolicitacao } = params;
  const history = useHistory();

  const solicitationState = useSelector<RootState, DataTypesSolicitation>(state => state.solicitationReducer);
  const solicitationData: IMetaSolicitation | null = solicitationState.metaSolicitationSelected;

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    resetForm
  } = useFormik({
    initialValues: { motivoCancelamento: '' },
    validationSchema: approval === 'r' ? validationSchemaReprovado : validationSchemaAprovado,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit(values) {
      if (approval === 'a') {
        dispatch(CreatorsSolicitation.ApprovalSolicitationRequest(idSolicitacao, values.motivoCancelamento))
      } else if (approval === 'r') {
        dispatch(CreatorsSolicitation.DisapprovalSolicitation(idSolicitacao, values.motivoCancelamento))
      }

    }
  });

  useEffect(() => {
    const items: BreadcrumbItems[] = [
      { text: "Home", isCurrentItem: false, icon: "HomeSolid", onlyIcon: true, url: "/" },
      { text: 'Painel WorkFlow', url: "/workflow" },
      { text: `Solicitação ${idSolicitacao}`, isCurrentItem: true }
    ];

    const page: Page = {
      key: 'workflow',
      pages: items
    };

    dispatch(Creators.setCurrentPage(page));
    dispatch(CreatorsSolicitation.getSolicitationById(idSolicitacao));
  }, [dispatch, idSolicitacao])

  useEffect(() => {
    if (solicitationState.canRedirect) {
      dispatch(CreatorsWorkflow.setCurrentPage("1"))
      history.push('/workflow')
    }
  }, [dispatch, history, solicitationState.canRedirect])

  useEffect(() => {
    if (solicitationState.error) {
      setApproval('n');
    }

  }, [solicitationState.error])

  return (
    <Div>
      {
        solicitationState.loading ?
          <Spinner size={SpinnerSize.medium} label="Carregando a solicitação" styles={{ root: { height: '100%' } }} />
          :
          <>
            <PainelHeader
              title={solicitationData?.tituloSolicitacao ?? ''}
              icon={{ icon: "Equalizer" }}
              labelGroups={[
                {
                  icon: "Calendar",
                  label: "Ciclo",
                  value: `${solicitationData?.solicitacaoMeta.ciclo.ano}` ?? ''
                },
                {
                  icon: "contact",
                  label: "Líder",
                  value: solicitationData?.FuncionarioOwner.nomeCompleto ?? ''
                },
                {
                  icon: "Flag",
                  label: "Status",
                  value: renderStatusSolicitation(solicitationData)
                },
                {
                  icon: "EMI",
                  label: "Unidade",
                  value: solicitationData?.solicitacaoMeta.cicloColaborador.unidade.nomeUnidade ?? ''
                },
                {
                  icon: "MapPin",
                  label: "Centro de Custo",
                  value: solicitationData?.solicitacaoMeta.cicloColaborador.cc.nomeCC ?? ''
                },
              ]}
            />
            <Wrapper>
              {
                solicitationData?.FlagPermiteAprovar &&
                <>
                  {
                    approval === 'n' &&
                    <Stack horizontal styles={{ root: { paddingTop: 10 } }}>
                      <CommandBarButton
                        styles={{ root: { height: 44 } }}
                        iconProps={{ iconName: 'LikeSolid' }}
                        text="Aprovar"
                        onClick={() => setApproval('a')}
                      />
                      <CommandBarButton
                        styles={{ root: { height: 44 } }}
                        iconProps={{ iconName: 'Dislike' }}
                        text="Reprovar"
                        onClick={() => setApproval('r')}
                      />
                    </Stack>
                  }

                  {
                    approval !== 'n' &&
                    solicitationData?.status === 'a' &&
                    <Stack styles={{ root: { maxWidth: 680, marginTop: 20, marginBottom: 20 } }}>
                      {
                        approval === 'a' &&
                        <Text variant="medium" styles={{ root: { marginBottom: 5 } }}>
                          Você escolheu <span style={{ color: Colors.primary }}>APROVAR</span>.
                            Se desejar, inclua um comentário em sua aprovação e clique no botão
                            <span style={{ color: Colors.primary }}>CONFIRMAR</span>.
                          </Text>
                      }

                      {
                        approval === 'r' &&
                        <Text variant="medium" styles={{ root: { marginBottom: 5 } }}>
                          Você escolheu <span style={{ color: Colors.error }}>REPROVAR</span>. Entre com o motivo e clique no botão <span style={{ color: Colors.error }}>CONFIRMAR</span>.
                          </Text>
                      }
                      <form onSubmit={handleSubmit}>
                        <InputText
                          type="text"
                          id="motivoCancelamento"
                          label="Comentário"
                          name="motivoCancelamento"
                          value={values.motivoCancelamento}
                          onChange={handleChange}
                          error={errors.motivoCancelamento ? true : false}
                          helperText={errors.motivoCancelamento}
                          multiline
                          rows={3}
                        />
                        <Stack horizontal horizontalAlign="end" styles={{ root: { marginTop: 40 } }}>
                          <DefaultButton text="Cancelar" onClick={() => { setApproval('n'); resetForm() }} styles={{ root: { marginRight: 10, width: 110 } }} />
                          {
                            approval === 'a' &&
                            <PrimaryButton text="Confirmar" styles={{ root: { width: 110 } }} onClick={(e: any) => handleSubmit(e)} />
                          }
                          {
                            approval === 'r' &&
                            <DeleteButton text="Confirmar" width={110} onClick={(e: any) => handleSubmit(e)} />
                          }
                        </Stack>
                      </form>
                    </Stack>
                  }
                </>
              }

              <Accordion defaultExpanded={true} style={{ marginTop: 10 }}>
                <AccordionSummary
                  expandIcon={<FontIcon iconName="ChevronDown" style={{ fontSize: 16 }} />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Text variant="mediumPlus" styles={textStyles}>Metas</Text>
                </AccordionSummary>
                <AccordionDetails style={{ overflow: 'auto' }}>
                  {
                    solicitationData?.solicitacaoMeta.solMetaPeso.length === 0 ?
                      <Stack styles={{ root: { width: '100%' } }}>
                        <NoItems
                          error={false}
                          text="Não há metas cadastradas"
                          icon="Bullseye"
                        />
                      </Stack>
                      :
                      <TableGoals cicloPesos={solicitationData?.solicitacaoMeta.solMetaPeso.map(item => item.metaColab) ?? []} disabled={true} />
                  }
                </AccordionDetails>
              </Accordion>

              <Accordion defaultExpanded={true}>
                <AccordionSummary
                  aria-controls="panel2d-content"
                  id="panel2d-header"
                  expandIcon={<FontIcon iconName="ChevronDown" style={{ fontSize: 16 }} />}
                >
                  <Text variant="mediumPlus" styles={textStyles}>Iniciativas Chave</Text>
                </AccordionSummary>
                <AccordionDetails>
                  <KeyProjects
                    idCiclo={solicitationData?.solicitacaoMeta.idCiclo!}
                    idCicloColaborador={solicitationData?.solicitacaoMeta.idCicloColaborador!}
                    readOnly={true}
                    allProjects={solicitationData?.solicitacaoMeta.solicitacaoMetaProjeto ?? []}
                    errorProjects={solicitationState.error}
                    isLoadingProjects={solicitationState.loading}
                    successProjects={solicitationState.success}
                  />
                </AccordionDetails>
              </Accordion>
            </Wrapper>
          </>
      }
    </Div >
  );
}

const renderStatusSolicitation = (solicitacao: IMetaSolicitation | null): string => {
  const { status, createdAt, dataExpiracao, dataHoraAprovacao, dataHoraFinalizada } = solicitacao ?? {};

  switch (status) {
    case 'a':
      return `Solicitado em ${moment(createdAt).format('DD/MM/YYYY')}`;
    case 'n':
      return 'Negado';
    case 'e':
      return `Expirado em ${moment(dataExpiracao).format('DD/MM/YYYY')}`;
    case 'k':
      return `Aprovado em ${moment(dataHoraAprovacao).format('DD/MM/YYYY')}`;
    case 'f':
      return `Finalizado em ${moment(dataHoraFinalizada).format('DD/MM/YYYY')}`;
    case 'c':
      return 'Cancelado';
    default:
      return '';
  }
}

export default IndividualTask;
