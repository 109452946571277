import { takeEvery, put, call, all } from "redux-saga/effects";
import {
  Types,
  IRequestAdminEditConfigMotivosAction,
  IRequestAdminLoadConfigMotivosAction,
  ISuccessAdminEditConfigMotivosAction,
  ISuccessAdminLoadConfigMotivosAction,
  ConfigTipoMovType,
  IRequestAdminLoadConfigJustaCausaAction,
  ISuccessAdminLoadConfigJustaCausaAction,
  ConfigJustaCausaType,
  IRequestAdminEditConfigJustaCausaAction,
  ISuccessAdminEditConfigJustaCausaAction,
} from "../../ducks/admin/admin/types";
import api from "~/services/api";

const apiCallGet = () => {
  return api
    .get(`admin/configmotivos`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallEdit = (tipoMov: ConfigTipoMovType) => {
  return api
    .put(`admin/configmotivos`, {
      ...tipoMov,
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallLoadConfigJustaCausa = () => {
  return api
    .get(`admin/ConfigJustaCausa`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallEditConfigJustaCausa = (
  payload: IRequestAdminEditConfigJustaCausaAction["payload"]
) => {
  return api
    .put(`admin/ConfigJustaCausa`, {
      ...payload,
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

function* loadConfigMotivo(action: IRequestAdminLoadConfigMotivosAction) {
  try {
    const data: ConfigTipoMovType = yield call(apiCallGet);
    yield put({
      type: Types.SUCCESS_ADM_LOAD_CONFIG_MOTIVOS,
      tipoMov: data,
    } as ISuccessAdminLoadConfigMotivosAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível carregar a configuração do motivo";
    yield put({
      type: Types.FAILURE_ADM_LOAD_CONFIG_MOTIVOS,
      message,
    });
  }
}

function* editConfigMotivo(action: IRequestAdminEditConfigMotivosAction) {
  try {
    yield call(apiCallEdit, action.tipoMov);
    yield put({
      type: Types.SUCCESS_ADM_EDIT_CONFIG_MOTIVOS,
    } as ISuccessAdminEditConfigMotivosAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível editar a configuração do motivo";
    yield put({
      type: Types.FAILURE_ADM_EDIT_CONFIG_MOTIVOS,
      message,
    });
  }
}

function* loadConfigJustaCausa(
  action: IRequestAdminLoadConfigJustaCausaAction
) {
  try {
    const justaCausaConfig: ConfigJustaCausaType = yield call(
      apiCallLoadConfigJustaCausa
    );
    yield put({
      type: Types.SUCCESS_ADM_LOAD_CONFIG_JUSTA_CAUSA,
      justaCausaConfig,
    } as ISuccessAdminLoadConfigJustaCausaAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível carregar a configuração da justa causa";
    yield put({
      type: Types.FAILURE_ADM_LOAD_CONFIG_JUSTA_CAUSA,
      message,
    });
  }
}

function* editConfigJustaCausa(
  action: IRequestAdminEditConfigJustaCausaAction
) {
  try {
    yield call(apiCallEditConfigJustaCausa, action.payload);
    yield put({
      type: Types.SUCCESS_ADM_EDIT_CONFIG_JUSTA_CAUSA,
      payload: action.payload,
    } as ISuccessAdminEditConfigJustaCausaAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível editar a configuração da justa causa";
    yield put({
      type: Types.FAILURE_ADM_EDIT_CONFIG_JUSTA_CAUSA,
      message,
    });
  }
}

export default function* tipoMovRoot() {
  yield all([
    yield takeEvery(Types.REQUEST_ADM_LOAD_CONFIG_MOTIVOS, loadConfigMotivo),
    yield takeEvery(Types.REQUEST_ADM_EDIT_CONFIG_MOTIVOS, editConfigMotivo),
    yield takeEvery(
      Types.REQUEST_ADM_LOAD_CONFIG_JUSTA_CAUSA,
      loadConfigJustaCausa
    ),
    yield takeEvery(
      Types.REQUEST_ADM_EDIT_CONFIG_JUSTA_CAUSA,
      editConfigJustaCausa
    ),
  ]);
}
