import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Formik } from "formik";
import * as yup from "yup";
import moment from "moment";

import { MenuItem } from "@material-ui/core";
import {
  ShimmeredDetailsList,
  Stack,
  Text,
  Selection,
  DetailsListLayoutMode,
  ICommandBarStyles,
  DefaultButton,
  IColumn,
  IDialogContentProps,
} from "office-ui-fabric-react";
import {
  CommandBarButton,
  DeleteButton,
  OutlinedButton,
  PrimaryButton,
} from "~/components/Buttons";
import Dropdown from "~/components/layout/Dropdown";
import Dialog from "~/components/CustomDialog";
import CustomDialog from "~/components/CustomDialogFluentUI";

import { RootState } from "~/store/ducks";
import { Creators as getTabGs } from "~/store/ducks/admin/salaryStructure";
import { Creators as addEsTab } from "~/store/ducks/admin/salaryStructure";
import { Creators as loadGsById } from "~/store/ducks/admin/salaryStructure";
import { Creators as addGS } from "~/store/ducks/admin/salaryStructure";
import { Creators as editGS } from "~/store/ducks/admin/salaryStructure";
import { Creators as delGS } from "~/store/ducks/admin/salaryStructure";
import {
  DataTypes as DataValuesSalary,
  GS,
} from "~/store/ducks/admin/salaryStructure/types";

import { ListContainer } from "./styles";
import { SelectionMode } from "@uifabric/utilities";
import PanelDisplay from "~/components/PanelDisplay";
import {
  GroupItemDoisTerco,
  GroupItems,
  GroupItemUmTerco,
  TitleField,
} from "~/components/FormGroup";
import {
  InputDate,
  InputText,
  InputCheckbox,
  InputNumber,
} from "~/components/Forms";

interface ITableSalaryState {
  columns: IColumn[];
  idDataTabSelected: number | null;
  selectionDetails: string;
  selectionCount: number;
  isPanelAddOpen: boolean;
  isDialogAddTableOpen: {
    isOpen: boolean;
    newData: Date | null;
  };
  isDialogExcludeOpen: boolean;
  vigenciaTable: Date | null;
  initialValuesGS: Partial<GS>;
}

interface ITableSalaryProps {
  idES: number;
  salaryState: DataValuesSalary;
  getTabGs: (idES: number, idESTab: number) => void;
  addEsTab: (idES: number, dataTab: Date, percent: number) => void;
  loadGsById: (idES: number, idESTab: number, idGS: number) => void;
  addGS: (idES: number, idESTab: number, gs: Partial<GS>) => void;
  editGS: (idES: number, idESTab: number, gs: Partial<GS>) => void;
  delGS: (idES: number, idESTab: number, idGS: number) => void;
}

const initialGS: Partial<GS> = {
  gs: null,
  valorMedio: null,
  flagPermiteMov: false,
  flagPiso: false,
  flagTeto: false,
  flagDissidio: false,
};

class Table extends Component<ITableSalaryProps, ITableSalaryState> {
  private _selection: Selection;
  private formRef: any;
  private formAddSalaryStructureRef: any;

  constructor(props: ITableSalaryProps) {
    super(props);
    this.state = {
      columns: [],
      idDataTabSelected: this.props.salaryState.itemSelected.item?.tabs
        ? this.props.salaryState.itemSelected.item?.tabs[0].idESTab
        : null,
      selectionDetails: "",
      selectionCount: 0,
      isPanelAddOpen: false,
      isDialogAddTableOpen: {
        isOpen: false,
        newData: null,
      },
      isDialogExcludeOpen: false,
      vigenciaTable: null,
      initialValuesGS: initialGS,
    };

    this._selection = new Selection({
      onSelectionChanged: () => {
        this.setState({
          selectionDetails: this._getSelectionDetails(),
        });
      },
    });

    this.formRef = React.createRef();
    this.formAddSalaryStructureRef = React.createRef();
  }

  componentDidMount() {
    if (this.state.idDataTabSelected)
      this.props.getTabGs(this.props.idES, this.state.idDataTabSelected);
  }

  componentDidUpdate(
    prevProps: ITableSalaryProps,
    prevState: ITableSalaryState
  ) {
    if (prevProps.salaryState.success !== this.props.salaryState.success) {
      if (this.props.salaryState.success) {
        this.setState({
          isDialogAddTableOpen: {
            isOpen: false,
            newData: null,
          },
          isPanelAddOpen: false,
        });
      }
    }

    // RENDER COLUMNS
    if (
      prevProps.salaryState.dataGS?.steps !==
      this.props.salaryState.dataGS?.steps
    ) {
      const columns: IColumn[] = [
        {
          key: "column1",
          name: "GS",
          ariaLabel: "GS",
          fieldName: "gs",
          isRowHeader: true,
          minWidth: 75,
          maxWidth: 100,
          isResizable: true,
          // isSortedDescending: false,
          // onColumnClick: this._onColumnClick
        },
      ];
      this.props.salaryState.dataGS?.steps.forEach((item, i) => {
        const column: IColumn = {
          key: `column${i + 2}`,
          name: `${Number(item.percent)}%`,
          ariaLabel: `${item.percent}%`,
          fieldName: "Valor",
          isRowHeader: true,
          minWidth: 75,
          maxWidth: 100,
          isResizable: true,
          // isSortedDescending: false,
          // onColumnClick: this._onColumnClick,
          styles: {
            cellTitle: {
              justifyContent: "flex-end",
            },
          },
          onRender: (value: GS) => (
            <Stack horizontalAlign="end">
              {value.steps[i]
                .Valor!.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })
                .replace("R$", "")}
            </Stack>
          ),
        };
        columns.push(column);
      });

      columns.push({
        key: "column0",
        name: "",
        ariaLabel: "",
        fieldName: "",
        isRowHeader: true,
        minWidth: 75,
        // isSortedDescending: false,
        // onColumnClick: this._onColumnClick
      });

      this.setState({ columns });
    }

    if (prevProps.salaryState.successGs !== this.props.salaryState.successGs) {
      if (this.props.salaryState.successGs) {
        this.setState({ initialValuesGS: this.props.salaryState.gsSelected! });
      }
    }

    if (
      prevProps.salaryState.itemSelected.success !==
      this.props.salaryState.itemSelected.success
    ) {
      if (this.props.salaryState.itemSelected.success) {
        const idDataTabSelected =
          this.props.salaryState.itemSelected.item?.tabs?.find(
            (item) => item.dataTab === this.state.isDialogAddTableOpen.newData
          )?.idESTab ?? null;

        if (idDataTabSelected) {
          this.props.getTabGs(this.props.idES, idDataTabSelected);

          this.setState({
            idDataTabSelected,
          });
        }
      }
    }
  }

  _getSelectionDetails(): any {
    const selectionCount = this._selection.getSelectedCount();
    this.setState({ selectionCount });
    this._selection.getSelection();
  }

  _onItemInvoked = (item: any): void => {
    this.openEdit();
  };

  openEdit = () => {
    const idGS = (this._selection.getSelection()[0] as GS).idGS!;
    this.props.loadGsById(this.props.idES, this.state.idDataTabSelected!, idGS);
    this.setState({ isPanelAddOpen: true });
  };

  commandBarRender = () => {
    const { selectionCount } = this.state;
    if (selectionCount === 1) {
      return (
        <>
          <CommandBarButton
            styles={btnStyle}
            iconProps={{ iconName: "Edit" }}
            text="Editar"
            onClick={() => this.openEdit()}
          />
          <CommandBarButton
            styles={btnStyle}
            iconProps={{ iconName: "Delete" }}
            text="Excluir"
            onClick={() => this.setState({ isDialogExcludeOpen: true })}
          />
        </>
      );
    }
  };

  handleSubmitAddTab = () => {
    if (this.formRef.current) {
      this.formRef.current.handleSubmit();
    }
  };

  handleSubmitAddSalaryStructure = () => {
    if (this.formAddSalaryStructureRef.current) {
      this.formAddSalaryStructureRef.current.handleSubmit();
    }
  };

  excludeGS = () => {
    this.props.delGS(
      this.props.idES,
      this.state.idDataTabSelected!,
      (this._selection.getSelection()[0] as GS).idGS!
    );
    this.setState({ isDialogExcludeOpen: false });
    this._selection.setAllSelected(false);
  };

  render() {
    const {
      idDataTabSelected,
      isPanelAddOpen,
      isDialogAddTableOpen,
      columns,
      isDialogExcludeOpen,
      initialValuesGS,
    } = this.state;
    const { salaryState, getTabGs, idES, addEsTab, addGS, editGS } = this.props;
    const { itemSelected } = salaryState;

    return (
      <Stack>
        <Stack
          horizontal
          horizontalAlign="space-between"
          styles={{ root: { width: "100%" } }}
        >
          <Stack horizontal verticalAlign="center">
            <Text
              variant="medium"
              styles={{ root: { marginRight: 20, marginLeft: 10 } }}
            >
              Tabela
            </Text>
            <Stack styles={{ root: { width: 200, marginRight: 20 } }}>
              <Dropdown
                label=""
                name="dataTab"
                values={idDataTabSelected ?? ""}
                variant="outlined"
                handleChange={(e: any) => {
                  this.setState({ idDataTabSelected: e.target.value });
                  getTabGs(idES, e.target.value);
                }}
                autofocus
                fieldHeight={35}
                noHelperText
                styles={{ height: 35 }}
                paddingSelected="5px 18px"
              >
                {itemSelected.item?.tabs?.map((item, i) => (
                  <MenuItem key={i} value={item.idESTab!}>
                    {moment(item.dataTab).format("DD/MM/YYYY")}
                  </MenuItem>
                ))}
              </Dropdown>
            </Stack>
            <OutlinedButton
              text="Adicionar Tabela"
              onClick={() =>
                this.setState({
                  isDialogAddTableOpen: { isOpen: true, newData: null },
                })
              }
            />
          </Stack>
        </Stack>
        <Stack
          horizontal
          horizontalAlign="space-between"
          style={{ marginTop: 15 }}
          styles={{ root: { width: "100%" } }}
        >
          <Stack horizontal>
            <CommandBarButton
              styles={btnStyle}
              iconProps={{ iconName: "Add" }}
              text="Adicionar GS"
              onClick={() =>
                this.setState({
                  isPanelAddOpen: true,
                  initialValuesGS: initialGS,
                })
              }
            />
            {this.commandBarRender()}
          </Stack>
          <Stack>
            <CommandBarButton
              styles={btnStyle}
              iconProps={{ iconName: "Refresh" }}
              text="Atualizar"
              onClick={() => getTabGs(idES, idDataTabSelected ?? 0)}
            />
          </Stack>
        </Stack>
        <ListContainer>
          <ShimmeredDetailsList
            items={salaryState.dataGS?.resp ?? []}
            // items={isFiltering ? items : salaryState.dataGS?.resp ?? []}
            enableShimmer={salaryState.loadingDataGS}
            columns={columns}
            selectionMode={SelectionMode.single}
            selection={this._selection}
            getKey={this._getKey}
            selectionPreservedOnEmptyClick={true}
            setKey="single"
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible={true}
            onItemInvoked={this._onItemInvoked}
          />
        </ListContainer>

        <CustomDialog
          hidden={!isDialogExcludeOpen}
          onDismiss={() => this.setState({ isDialogExcludeOpen: false })}
          dialogContentProps={dialogContentProps}
        >
          <DefaultButton
            onClick={() => this.setState({ isDialogExcludeOpen: false })}
            text="Cancelar"
          />
          <DeleteButton onClick={() => this.excludeGS()} text="Excluir" />
        </CustomDialog>

        <PanelDisplay
          open={isPanelAddOpen}
          isLoading={false}
          onClose={() => this.setState({ isPanelAddOpen: false })}
          panelHeader={<Text variant="xxLarge">Adicionar Grupo Salarial</Text>}
          footer={
            <>
              <DefaultButton
                styles={{ root: { marginRight: 10 } }}
                onClick={() => this.setState({ isPanelAddOpen: false })}
              >
                Cancelar
              </DefaultButton>
              <PrimaryButton
                onClick={this.handleSubmitAddSalaryStructure}
                text="Salvar"
                isLoading={salaryState.loadingAction}
              />
            </>
          }
        >
          <GroupItems>
            <GroupItemDoisTerco>
              <TitleField text="Estrutura Salarial" />
              <TitleField text={itemSelected.item?.nomeES} isValue />
            </GroupItemDoisTerco>
            <GroupItemUmTerco>
              <TitleField text="Data da Tabela" />
              <TitleField
                text={
                  moment(
                    itemSelected.item?.tabs?.find(
                      (item) => item.idESTab === idDataTabSelected
                    )?.dataTab
                  ).format("DD/MM/YYYY") ?? ""
                }
                isValue
              />
            </GroupItemUmTerco>
          </GroupItems>
          <Formik
            innerRef={this.formAddSalaryStructureRef}
            initialValues={initialValuesGS}
            validateOnChange={false}
            validateOnBlur={true}
            enableReinitialize
            validationSchema={validationAddSalaryStructure}
            onSubmit={(values) => {
              if (!values.idGS) {
                addGS(idES, idDataTabSelected!, values);
              } else {
                editGS(idES, idDataTabSelected!, values);
              }
            }}
          >
            {({
              handleSubmit,
              handleChange,
              values,
              errors,
              setFieldValue,
              setFieldError,
            }) => (
              <form onSubmit={handleSubmit}>
                <Stack>
                  <InputText
                    value={values.gs ?? ""}
                    onChange={(e) => {
                      handleChange(e);
                      setFieldError("gs", "");
                    }}
                    id="gs"
                    error={!!errors.gs}
                    name="gs"
                    type="number"
                    label="Grupo Salarial"
                    helperText={errors.gs}
                    disabled={!!values.idGS}
                    className="mt-2"
                    autoFocus={!values.idGS}
                  />
                  <InputNumber
                    value={values.valorMedio ? Number(values.valorMedio) : ""}
                    onValueChange={(value) => {
                      setFieldValue("valorMedio", value.floatValue);
                      setFieldError("valorMedio", "");
                    }}
                    id="valorMedio"
                    name="valorMedio"
                    label="Valor Médio"
                    helperText={errors.valorMedio}
                    error={!!errors.valorMedio}
                    autoFocus={!!values.idGS}
                  />
                  <InputCheckbox
                    checked={values.flagPiso}
                    onChange={handleChange}
                    name="flagPiso"
                    color="primary"
                    label="O valor informado é um PISO SALARIAL"
                  />
                  <InputCheckbox
                    checked={values.flagTeto}
                    onChange={handleChange}
                    name="flagTeto"
                    color="primary"
                    label="O valor informado é um TETO SALARIAL"
                  />
                  <InputCheckbox
                    checked={values.flagPermiteMov}
                    onChange={handleChange}
                    name="flagPermiteMov"
                    color="primary"
                    label="Permite movimentações no PORTAL"
                  />
                  <InputCheckbox
                    checked={values.flagDissidio}
                    onChange={handleChange}
                    name="flagDissidio"
                    color="primary"
                    label="O grupo salarial é elegível à CCT"
                  />
                </Stack>
              </form>
            )}
          </Formik>
        </PanelDisplay>

        <Dialog
          open={isDialogAddTableOpen.isOpen}
          title="Nova Competência"
          btnConfirmText="Adicionar"
          onClickConfirm={() => this.handleSubmitAddTab()}
          onClickCancel={() =>
            this.setState({
              isDialogAddTableOpen: {
                isOpen: false,
                newData: null,
              },
            })
          }
        >
          <Formik
            innerRef={this.formRef}
            initialValues={{ dataTab: null, percent: null }}
            validateOnChange={false}
            validateOnBlur={true}
            enableReinitialize
            validationSchema={validationTableGSSchema}
            onSubmit={(values) => {
              addEsTab(idES, values.dataTab!, values.percent!);
              this.setState({
                isDialogAddTableOpen: {
                  isOpen: false,
                  newData: values.dataTab,
                },
              });
            }}
          >
            {({
              handleSubmit,
              values,
              errors,
              setFieldValue,
              setFieldError,
            }) => (
              <form onSubmit={handleSubmit}>
                <InputDate
                  style={{ marginTop: 15 }}
                  label="Vigência"
                  name="dataTab"
                  value={values.dataTab ? moment(values.dataTab) : null}
                  onChange={(value) => setFieldValue("dataTab", value)}
                  className="mt-2"
                  error={!!errors.dataTab}
                  helperText={errors.dataTab}
                  autoFocus
                />
                <InputNumber
                  value={!!values.percent ? Number(values.percent) : null}
                  onValueChange={(value) => {
                    setFieldValue("percent", value.floatValue);
                    setFieldError("percent", "");
                  }}
                  id="percent"
                  name="percent"
                  label="Percentual"
                  decimalScale={2}
                  helperText={errors.percent}
                  error={!!errors.percent}
                />
              </form>
            )}
          </Formik>
        </Dialog>
      </Stack>
    );
  }

  private _onColumnClick = (
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn
  ): void => {
    const { columns } = this.state;
    const newColumns: IColumn[] = columns.slice();
    let items: GS[] = this.props.salaryState.dataGS?.resp ?? [];
    // if (this.state.isFiltering) {
    //     items = this.state.items;
    // } else {
    //     items = this.props.salary.data;
    // };
    const currColumn: IColumn = newColumns.filter(
      (currCol) => column.key === currCol.key
    )[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    const newItems = this._sort(
      items,
      currColumn.fieldName!,
      currColumn.isSortedDescending
    );
    this.setState({
      columns: newColumns,
      // items: newItems,
    });
  };

  private _getKey(item: any, index?: number): any {
    if (item !== undefined) return item.key;
  }

  private _sort<T>(
    items: T[],
    columnKey: string,
    isSortedDescending?: boolean
  ): T[] {
    const result = (prop: string, obj: T) =>
      prop.split(".").reduce((a: any, b: any) => a[b] ?? "", obj);
    return items.sort((a: T, b: T) =>
      (
        isSortedDescending
          ? result(columnKey, a) < result(columnKey, b)
          : result(columnKey, a) > result(columnKey, b)
      )
        ? 1
        : -1
    );
  }
}

const btnStyle: Partial<ICommandBarStyles> = {
  root: {
    height: 44,
  },
};

const dialogContentProps: IDialogContentProps = {
  title: "Excluir?",
  closeButtonAriaLabel: "Close",
  subText: "Tem certeza de que deseja excluir este item?",
};

const validationTableGSSchema = yup.object().shape({
  dataTab: yup
    .date()
    .nullable()
    .typeError("Data inválida")
    .required("Campo obrigatório"),
  percent: yup.number().nullable().required("Campo obrigatório"),
});

const validationAddSalaryStructure = yup.object().shape({
  gs: yup.number().nullable().required("Campo obrigatório"),
  valorMedio: yup.number().nullable().required("Campo obrigatório"),
  flagPermiteMov: yup.bool().notRequired(),
  flagPiso: yup.bool().notRequired(),
  flagTeto: yup.bool().notRequired(),
  flagDissidio: yup.bool().notRequired(),
});

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      ...getTabGs,
      ...addEsTab,
      ...loadGsById,
      ...addGS,
      ...editGS,
      ...delGS,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Table);
