// #region Imports
import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

//FluentUI
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import {
  DetailsListLayoutMode,
  SelectionMode,
  Selection,
  IColumn,
} from 'office-ui-fabric-react/lib/DetailsList';
import { ShimmeredDetailsList } from 'office-ui-fabric-react/lib/ShimmeredDetailsList';
import { PrimaryButton, DetailsHeader, IButtonStyles, Spinner, Text } from 'office-ui-fabric-react';
import { ScrollablePane } from 'office-ui-fabric-react/lib/ScrollablePane';
import { Sticky } from 'office-ui-fabric-react/lib/Sticky';
import CustomDialog from "~/components/CustomDialog";
import { InputText } from '~/components/Forms';
//Estilos

import { Creators as getSalaryStructure } from "~/store/ducks/admin/salaryStructure";
import { Creators as clearES } from "~/store/ducks/admin/positions";
import { DataTypes as DataTypesES, SalaryStructureType } from "~/store/ducks/admin/salaryStructure/types";

import { RootState } from "~/store/ducks";
//#endregion

interface IDialogComentarioProps {
  comentario: string;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (comentario: string) => void;
}

const DialogComentario: React.FC<IDialogComentarioProps> = (props) => {
  const { isOpen, onClose, onConfirm } = props;
  const [comentario, setComentario] = useState(props.comentario)

  useEffect(() => {
    if (isOpen) {
      setComentario(props.comentario)
    }
  }, [isOpen])

  return (
    <CustomDialog
      open={isOpen}
      title="Comentários"
      btnConfirmText="Salvar"
      onClickCancel={onClose}
      onClickConfirm={() => {
        onConfirm(comentario);
      }}
      // disabledBtnConfirm={}
      height={260}
      width={850}
    >
      <InputText
        value={comentario}
        type="text"
        onChange={e => setComentario(e.target.value)}
        multiline
        rows={5}
        autoFocus
      />
    </CustomDialog>
  );
}

export default DialogComentario;