// #region Imports
import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik } from "formik";
import * as yup from 'yup';

//FluentUI
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import {
  DetailsListLayoutMode,
  SelectionMode,
  Selection,
  IColumn,
} from 'office-ui-fabric-react/lib/DetailsList';
import { ShimmeredDetailsList } from 'office-ui-fabric-react/lib/ShimmeredDetailsList';
import { CommandBarButton, ICommandBarStyles, Link, Pivot, Text } from 'office-ui-fabric-react';
import { IDialogContentProps } from 'office-ui-fabric-react/lib/Dialog';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';

import Panel from "~/components/layout/Panel";
import CustomDialog from "~/components/CustomDialogFluentUI";
import Dialog from "~/components/CustomDialog";
import { Page, BreadcrumbItems } from "~/store/ducks/home/types";
import { InputText } from '~/components/Forms';

import { DeleteButton } from '~/components/Buttons';
import HeaderPage from '~/components/layout/HeaderPage';
import NoItems from '~/components/layout/NoItems';
import { PrimaryButton } from '~/components/Buttons';
//Estilos
import {
  Wrapper,
  ListContainer,
  DefaultButton,
  ContainerContent,
} from "./styles";

import { DataTypes as DataTypesTabPerformance, TabPerformanceType } from "~/store/ducks/admin/tabPerformances/types";
import { Creators as getTabPerformances } from "~/store/ducks/admin/tabPerformances";
import { Creators as getTabPerformanceById } from "~/store/ducks/admin/tabPerformances";
import { Creators as addTabPerformance } from "~/store/ducks/admin/tabPerformances";
import { Creators as editTabPerformance } from "~/store/ducks/admin/tabPerformances";
import { Creators as delTabPerformance } from "~/store/ducks/admin/tabPerformances";

import { Creators as setCurrentPage } from "~/store/ducks/home";
import { RootState } from "~/store/ducks";
import PanelDisplay from "~/components/PanelDisplay";
import { PivotItem } from "@fluentui/react";
import { GroupItemDoisTerco, GroupItems, GroupItemUmTerco, TitleField } from "~/components/FormGroup";
import Nivel from "./Nivel";
//#endregion

const btnStyle: Partial<ICommandBarStyles> = {
  root: {
    height: 44
  }
}

/**
 * Validação do formulário
 */
const validationSchema = yup.object().shape({
  nomeTabPerformance: yup.string().required("Campo obrigatório"),
});

interface IPerformanceTablesState {
  columns: IColumn[];
  items: TabPerformanceType[];
  isPanelDisplayOpen: boolean;
  isPanelEditOpen: boolean;
  isDialogAddOpen: boolean;
  isFiltering: boolean;
  selectionDetails: string;
  selectionCount: number;
  isDialogExcludeOpen: boolean;
  search: string;
};

interface IPropsPerformanceTables {
  tabPerformances: DataTypesTabPerformance;
  setCurrentPage: (page: Page) => void;
  getTabPerformances: (search?: string) => void;
  getTabPerformanceById: (idTabPerformance: number) => void;
  addTabPerformance: (tabPerformance: TabPerformanceType) => void;
  editTabPerformance: (tabPerformance: TabPerformanceType) => void;
  delTabPerformance: (idTabPerformance: number) => void;
}

const itemsBreadCrumb: BreadcrumbItems[] = [
  { text: "Home", isCurrentItem: false, icon: "HomeSolid", onlyIcon: true, url: "/" },
  { text: "Tabelas de Performances", isCurrentItem: true },
];

class PerformanceTables extends Component<IPropsPerformanceTables, IPerformanceTablesState> {
  private formRef: any;
  private _selection: Selection;

  constructor(props: IPropsPerformanceTables) {
    super(props);
    //#region Colunas
    const columns: IColumn[] = [
      {
        key: 'column1',
        name: 'ID',
        ariaLabel: 'Id da tabela de performance',
        fieldName: 'idTabPerformance',
        isRowHeader: true,
        minWidth: 75,
        maxWidth: 100,
        isResizable: true,
        isSortedDescending: false,
        onColumnClick: this._onColumnClick
      },
      {
        key: 'column2',
        name: 'Nome',
        fieldName: 'nomeTabPerformance',
        minWidth: 210,
        maxWidth: 350,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
      }
    ];
    //#endregion

    this.state = {
      columns: columns,
      items: [],
      isPanelDisplayOpen: false,
      isPanelEditOpen: false,
      isDialogAddOpen: false,
      selectionDetails: "",
      isFiltering: false,
      selectionCount: 0,
      isDialogExcludeOpen: false,
      search: "",
    }

    this._selection = new Selection({
      onSelectionChanged: () => {
        this.setState({
          selectionDetails: this._getSelectionDetails(),
        });
      },
    });

    this.formRef = React.createRef();
  };

  componentDidMount() {
    const page: Page = {
      key: 'tabelasPerformance',
      pages: itemsBreadCrumb
    };
    this.props.setCurrentPage(page);
    this.props.getTabPerformances();
  };

  componentDidUpdate(prevProps: IPropsPerformanceTables, _: any) {
    if (this.state.isPanelEditOpen) {
      if (prevProps.tabPerformances.success !== this.props.tabPerformances.success) {
        if (this.props.tabPerformances.success) {
          this.setState({ isPanelEditOpen: false })
        }
      }
    }

    if (this.props.tabPerformances.itemSelected.error !== prevProps.tabPerformances.itemSelected.error) {
      if (this.props.tabPerformances.itemSelected.error) {
        this.setState({ isPanelDisplayOpen: false })
      }
    }
  }

  // Submit do formulário Formik
  handleSubmit = () => {
    if (this.formRef.current) {
      this.formRef.current.handleSubmit();
    };
  };

  _onItemInvoked = (): void => {
    this.openEdit()
  };

  _getSelectionDetails(): any {
    const selectionCount = this._selection.getSelectedCount();
    this.setState({ selectionCount });
    this._selection.getSelection();
  };

  openEdit = () => {
    const idTabPerformance: number = (this._selection.getSelection()[0] as TabPerformanceType).idTabPerformance!;
    this.props.getTabPerformanceById(idTabPerformance)
    this.setState({ isPanelDisplayOpen: true });
  };

  cancelPanel = () => {
    this._selection.setAllSelected(false);
    this.setState({ isPanelEditOpen: false });
  };

  excludeTabPerformance = () => {
    this.props.delTabPerformance((this._selection.getSelection()[0] as TabPerformanceType).idTabPerformance!);
    this.setState({ isDialogExcludeOpen: false });
    this._selection.setAllSelected(false);
  };

  commandBarRender = () => {
    const { selectionCount } = this.state;
    if (selectionCount === 1) {
      return (
        <>
          <CommandBarButton styles={btnStyle} iconProps={{ iconName: 'Edit' }} text="Editar" onClick={() => this.openEdit()} />
          <CommandBarButton styles={btnStyle} iconProps={{ iconName: 'Delete' }} text="Excluir" onClick={() => this.setState({ isDialogExcludeOpen: true })} />
        </>
      );
    }
  };

  render() {
    const { isPanelDisplayOpen, isDialogExcludeOpen, selectionCount, isFiltering, items, columns, isDialogAddOpen, isPanelEditOpen } = this.state;
    const { tabPerformances, getTabPerformances, addTabPerformance, editTabPerformance } = this.props;
    const { item: tabPerformanceSelected } = tabPerformances.itemSelected

    const dialogContentProps: IDialogContentProps = {
      title: 'Excluir?',
      closeButtonAriaLabel: 'Close',
      subText: selectionCount <= 1 ? 'Tem certeza de que deseja excluir este item?' : 'Tem certeza de que deseja excluir estes items?',
    };

    return (
      <>
        <CustomDialog
          hidden={!isDialogExcludeOpen}
          onDismiss={() => this.setState({ isDialogExcludeOpen: false })}
          dialogContentProps={dialogContentProps}
        >
          <DefaultButton onClick={() => this.setState({ isDialogExcludeOpen: false })} text="Cancelar" />
          <DeleteButton onClick={() => this.excludeTabPerformance()} text="Excluir" />
        </CustomDialog>
        <Wrapper>
          <ContainerContent>
            <HeaderPage
              title="Tabelas de Performances"
              leftItems={
                <>
                  <CommandBarButton
                    styles={btnStyle}
                    iconProps={{ iconName: 'Add' }}
                    disabled={tabPerformances.loadingData}
                    text="Adicionar Tabela"
                    onClick={() => this.setState({ isDialogAddOpen: true })}
                  />
                  {this.commandBarRender()}
                </>
              }
              rightItems={
                <CommandBarButton
                  styles={btnStyle}
                  iconProps={{ iconName: 'Refresh' }}
                  text="Atualizar"
                  onClick={() => getTabPerformances()}
                />
              }
            />
            {!tabPerformances.loadingData && tabPerformances.data.length === 0 ?
              <NoItems
                error={tabPerformances.error}
                text="Não há tabelas de performances cadastradas"
                icon="ReminderPerson"
              />
              :
              <ListContainer>
                <ShimmeredDetailsList
                  items={isFiltering ? items : tabPerformances.data}
                  enableShimmer={tabPerformances.loadingData}
                  columns={columns}
                  selectionMode={SelectionMode.single}
                  selection={this._selection}
                  getKey={this._getKey}
                  selectionPreservedOnEmptyClick={true}
                  setKey="single"
                  layoutMode={DetailsListLayoutMode.justified}
                  isHeaderVisible={true}
                  onItemInvoked={this._onItemInvoked}
                />
              </ListContainer>
            }
          </ContainerContent>
        </Wrapper>

        <Dialog
          title="Adicionar Tabela"
          open={isDialogAddOpen}
          onClickCancel={() => this.setState({ isDialogAddOpen: false })}
          onClickConfirm={this.handleSubmit}
          btnConfirmText="Adicionar"
        >
          <Formik
            innerRef={this.formRef}
            initialValues={initialTabPerformance}
            validationSchema={validationSchema}
            validateOnChange={false}
            validateOnBlur={true}
            onSubmit={(values) => {
              addTabPerformance(values)
              this.setState({ isDialogAddOpen: false })
            }}
          >
            {({ handleSubmit, handleChange, values, errors, setFieldError }) => (
              <form onSubmit={handleSubmit}>
                <InputText
                  value={values.nomeTabPerformance ?? ""}
                  onChange={(e) => { handleChange(e); setFieldError("nomeTabPerformance", "") }}
                  id="nomeTabPerformance"
                  error={!!errors.nomeTabPerformance}
                  name="nomeTabPerformance"
                  type="text"
                  label="Nome da Tabela"
                  helperText={errors.nomeTabPerformance}
                  autoFocus
                  className="mt-2"
                />
              </form>
            )}
          </Formik>
        </Dialog>

        <PanelDisplay
          open={isPanelDisplayOpen}
          isLoading={tabPerformances.itemSelected.loading}
          onClose={() => this.setState({ isPanelDisplayOpen: false })}
          panelHeader={
            <Text variant="xxLarge">{tabPerformanceSelected?.nomeTabPerformance}</Text>
          }
        >
          <Pivot aria-label="Basic Pivot Example" styles={{ root: { marginBottom: 32 } }}>
            <PivotItem headerText="Dados Principais">
              <GroupItems title="Identificação" hasMultiItems>
                <GroupItems>
                  <GroupItemUmTerco>
                    <TitleField text="Id" />
                    <TitleField text={tabPerformanceSelected?.idTabPerformance} isValue />
                  </GroupItemUmTerco>
                  <GroupItemDoisTerco>
                    <TitleField text="Nome" />
                    <TitleField text={tabPerformanceSelected?.nomeTabPerformance} isValue />
                  </GroupItemDoisTerco>
                  <Link
                    styles={{ root: { fontSize: 14 } }}
                    onClick={() => this.setState({ isPanelEditOpen: true })}
                  >
                    Gerenciar identificação
                                    </Link>
                </GroupItems>
              </GroupItems>
            </PivotItem>
            <PivotItem headerText="Níveis">
              <Nivel
                idTabPerformance={tabPerformanceSelected?.idTabPerformance!}
                tabPerformances={tabPerformances}
              />
            </PivotItem>
          </Pivot>
        </PanelDisplay>

        <Panel
          title={tabPerformanceSelected?.nomeTabPerformance}
          open={isPanelEditOpen}
          onClose={() => this.cancelPanel()}
          footer={
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <DefaultButton onClick={() => this.cancelPanel()}>Cancelar</DefaultButton>
              <PrimaryButton onClick={this.handleSubmit} isLoading={tabPerformances.loadingAction} text="Salvar" />
            </Stack>
          }
        >
          {
            tabPerformances.itemSelected.loading ?
              <Spinner size={SpinnerSize.medium} styles={{ root: { height: '100%' } }} />
              :
              <Formik
                innerRef={this.formRef}
                initialValues={tabPerformanceSelected!}
                validationSchema={validationSchema}
                validateOnChange={false}
                validateOnBlur={true}
                enableReinitialize
                onSubmit={(values) => {
                  editTabPerformance(values)
                }}
              >
                {({ handleSubmit, handleChange, values, errors, setFieldError, setFieldValue }) => (
                  <form onSubmit={handleSubmit}>
                    <InputText
                      value={values.nomeTabPerformance ?? ''}
                      onChange={(e) => { handleChange(e); setFieldError("nomeTabPerformance", "") }}
                      id="nomeTabPerformance"
                      error={errors.nomeTabPerformance ? true : false}
                      name="nomeTabPerformance"
                      type="text"
                      label="Nome"
                      helperText={errors.nomeTabPerformance}
                      inputProps={{ maxLength: 100 }}
                      className="mt-2"
                      autoFocus
                    />

                  </form>
                )}
              </Formik>
          }
        </Panel>
      </>
    );
  };

  private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    const { columns } = this.state;
    const newColumns: IColumn[] = columns.slice();
    let items: TabPerformanceType[] = [];
    if (this.state.isFiltering) {
      items = this.state.items;
    } else {
      items = this.props.tabPerformances.data;
    };
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      };
    });
    const newItems = this._sort(items, currColumn.fieldName!, currColumn.isSortedDescending);
    this.setState({
      columns: newColumns,
      items: newItems,
    });
  };

  private _getKey(item: any, index?: number): any {
    if (item !== undefined)
      return item.key;
  };

  private _sort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
    const result = (prop: string, obj: T) => (prop.split('.').reduce((a: any, b: any) => a[b] ?? '', obj))
    return items.sort((a: T, b: T) => ((isSortedDescending ? result(columnKey, a) < result(columnKey, b) : result(columnKey, a) > result(columnKey, b)) ? 1 : -1));
  };

}

const initialTabPerformance: TabPerformanceType = {
  idTabPerformance: null,
  nomeTabPerformance: ''
}

const mapStateToProps = (state: RootState) => ({
  tabPerformances: state.tabPerformancesReducer
});

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  ...getTabPerformances,
  ...getTabPerformanceById,
  ...addTabPerformance,
  ...editTabPerformance,
  ...delTabPerformance,
  ...setCurrentPage
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PerformanceTables);
