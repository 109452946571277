import { takeEvery, put, call, all } from "redux-saga/effects";
import {
  Types,
  IRequestCycleGroupLoadAction,
  IRequestCycleGroupAddAction,
  IRequestCycleGroupLoadByIdAction,
  IRequestCycleGroupEditAction,
  IRequestCycleGroupAddParticipantsAction,
  IRequestCycleGroupDelParticipantAction,
  IRequestCycleGroupAddGuestAction,
  IRequestCycleGroupDelGuestAction,
  IRequestCycleGroupDelAction,
  ISuccessCycleGroupLoadAction,
  ISuccessCycleGroupAddAction,
  ISuccessCycleGroupLoadByIdAction,
  ISuccessCycleGroupEditAction,
  ISuccessCycleGroupAddParticipantsAction,
  ISuccessCycleGroupDelParticipantAction,
  ISuccessCycleGroupAddGuestAction,
  ISuccessCycleGroupDelGuestAction,
  ISuccessCycleGroupDelAction,
  CycleGroupType,
  ParticipantsType
} from "../ducks/cycle/groups/types";
import api from "~/services/api";

const apiCallGet = (idCiclo: number) => {
  return api
    .get(`ciclos/${idCiclo}/grupos`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err.response;
    });
};

const apiCallGetById = (payload: IRequestCycleGroupLoadByIdAction["payload"]) => {
  return api
    .get(`ciclos/${payload.idCiclo}/grupos/${payload.idCicloGrupo}`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err.response;
    });
};

const apiCallAdd = (payload: IRequestCycleGroupAddAction["payload"]) => {
  return api
    .post(`ciclos/${payload.idCiclo}/grupos`, {
      ...payload.group
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err.response;
    });
};

const apiCallEdit = (payload: IRequestCycleGroupEditAction["payload"]) => {
  return api
    .put(`ciclos/${payload.idCiclo}/grupos/${payload.group.idCicloGrupo}`, {
      ...payload.group
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err.response;
    });
};

const apiCallDel = (payload: IRequestCycleGroupDelAction["payload"]) => {
  return api
    .delete(`ciclos/${payload.idCiclo}/grupos/${payload.idCicloGrupo}`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err.response;
    });
};

const apiCallAddParticipants = (payload: IRequestCycleGroupAddParticipantsAction["payload"]) => {
  return api
    .post(`ciclos/${payload.idCiclo}/grupos/${payload.idCicloGrupo}/participantes`,
      payload.participantes.map(item => ({ idFuncionario: item.idFuncionario }))
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err.response;
    });
};

const apiCallDelParticipant = (payload: IRequestCycleGroupDelParticipantAction["payload"]) => {
  return api
    .delete(`ciclos/${payload.idCiclo}/grupos/${payload.idCicloGrupo}/participantes/${payload.idCicloGrupoParticipante}`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err.response;
    });
};

const apiCallAddGuest = (payload: IRequestCycleGroupAddGuestAction["payload"]) => {
  return api
    .post(`ciclos/${payload.idCiclo}/grupos/${payload.idCicloGrupo}/convidados`, {
      idFuncionario: payload.avaliador.idFuncionario
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err.response;
    });
};

const apiCallDelGuest = (payload: IRequestCycleGroupDelGuestAction["payload"]) => {
  return api
    .delete(`ciclos/${payload.idCiclo}/grupos/${payload.idCicloGrupo}/convidados/${payload.idCicloGrupoConvidado}`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err.response;
    });
};

function* load(action: IRequestCycleGroupLoadAction) {
  try {
    let items: any = yield call(apiCallGet, action.idCiclo);
    yield put({
      type: Types.SUCCESS_CYCLE_GROUPS_LOAD,
      payload: items
    } as ISuccessCycleGroupLoadAction);
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível carregar os grupos'
    yield put({
      type: Types.FAILURE_CYCLE_GROUPS_LOAD,
      message
    });
  }
}

function* loadById(action: IRequestCycleGroupLoadByIdAction) {
  try {
    let items: CycleGroupType = yield call(apiCallGetById, action.payload);
    yield put({
      type: Types.SUCCESS_CYCLE_GROUP_LOAD_BY_ID,
      payload: {
        ...items,
        // participantes: [...items.diretores ?? [], ...items.gerentes ?? [], ...items.analistas ?? [], ...items.lideres ?? []]
      }
    } as ISuccessCycleGroupLoadByIdAction);
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível carregar o grupo'
    yield put({
      type: Types.FAILURE_CYCLE_GROUP_LOAD_BY_ID,
      message
    });
  }
}

function* add(action: IRequestCycleGroupAddAction) {
  try {
    let items: CycleGroupType = yield call(apiCallAdd, action.payload);
    items.lider = {
      nomeCompleto: action.payload.group.lider.nomeCompleto
    }
    yield put({
      type: Types.SUCCESS_CYCLE_GROUPS_ADD,
      payload: items
    } as ISuccessCycleGroupAddAction);
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível adicionar grupo'
    yield put({
      type: Types.FAILURE_CYCLE_GROUPS_ADD,
      message
    });
  }
}

function* edit(action: IRequestCycleGroupEditAction) {
  try {
    yield call(apiCallEdit, action.payload);
    yield put({
      type: Types.SUCCESS_CYCLE_GROUPS_EDIT,
      payload: action.payload.group
    } as ISuccessCycleGroupEditAction);
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível editar o grupo'
    yield put({
      type: Types.FAILURE_CYCLE_GROUPS_EDIT,
      message
    });
  }
}

function* del(action: IRequestCycleGroupDelAction) {
  try {
    yield call(apiCallDel, action.payload);
    yield put({
      type: Types.SUCCESS_CYCLE_GROUPS_DEL,
      idCicloGrupo: action.payload.idCicloGrupo
    } as ISuccessCycleGroupDelAction);
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível excluir o grupo'
    yield put({
      type: Types.FAILURE_CYCLE_GROUPS_DEL,
      message
    });
  }
}

function* addParticipants(action: IRequestCycleGroupAddParticipantsAction) {
  try {
    const participantes: ParticipantsType[] = yield call(apiCallAddParticipants, action.payload);
    const participantsComplete = participantes.map(item => {
      const func = action.payload.participantes.find(part => part.idFuncionario === item.idFuncionario);
      return {
        ...item,
        funcionario: {
          nomeCompleto: func?.nomeCompleto ?? ""
        }
      }
    })
    yield put({
      type: Types.SUCCESS_CYCLE_GROUPS_ADD_PARTICIPANTS,
      payload: participantsComplete
    } as ISuccessCycleGroupAddParticipantsAction);
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível adicionar os participantes'
    yield put({
      type: Types.FAILURE_CYCLE_GROUPS_ADD_PARTICIPANTS,
      message
    });
  }
}

function* delParticipant(action: IRequestCycleGroupDelParticipantAction) {
  try {
    yield call(apiCallDelParticipant, action.payload);
    yield put({
      type: Types.SUCCESS_CYCLE_GROUPS_DEL_PARTICIPANTS,
      payload: {
        idCicloGrupoParticipante: action.payload.idCicloGrupoParticipante,
        tipoFuncionario: action.payload.tipoFuncionario
      }
    } as ISuccessCycleGroupDelParticipantAction);
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível excluir o participante'
    yield put({
      type: Types.FAILURE_CYCLE_GROUPS_DEL_PARTICIPANTS,
      message
    });
  }
}

function* addGuest(action: IRequestCycleGroupAddGuestAction) {
  try {
    const idCicloGrupoConvidado: any = yield call(apiCallAddGuest, action.payload);
    yield put({
      type: Types.SUCCESS_CYCLE_GROUPS_ADD_GUEST,
      avaliador: {
        ...idCicloGrupoConvidado,
        idFuncionario: action.payload.avaliador.idFuncionario!,
        funcionario: {
          nomeCompleto: action.payload.avaliador.nomeCompleto
        },
      }
    } as ISuccessCycleGroupAddGuestAction);
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível adicionar o convidado'
    yield put({
      type: Types.FAILURE_CYCLE_GROUPS_ADD_GUEST,
      message
    });
  }
}

function* delGuest(action: IRequestCycleGroupDelGuestAction) {
  try {
    yield call(apiCallDelGuest, action.payload);
    yield put({
      type: Types.SUCCESS_CYCLE_GROUPS_DEL_GUEST,
      idCicloGrupoConvidado: action.payload.idCicloGrupoConvidado
    } as ISuccessCycleGroupDelGuestAction);
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível excluir o convidado'
    yield put({
      type: Types.FAILURE_CYCLE_GROUPS_DEL_GUEST,
      message
    });
  }
}

export default function* cycleGroupsRoot() {
  yield all([
    yield takeEvery(Types.REQUEST_CYCLE_GROUPS_LOAD, load),
    yield takeEvery(Types.REQUEST_CYCLE_GROUP_LOAD_BY_ID, loadById),
    yield takeEvery(Types.REQUEST_CYCLE_GROUPS_ADD, add),
    yield takeEvery(Types.REQUEST_CYCLE_GROUPS_EDIT, edit),
    yield takeEvery(Types.REQUEST_CYCLE_GROUPS_DEL, del),
    yield takeEvery(Types.REQUEST_CYCLE_GROUPS_ADD_PARTICIPANTS, addParticipants),
    yield takeEvery(Types.REQUEST_CYCLE_GROUPS_DEL_PARTICIPANTS, delParticipant),
    yield takeEvery(Types.REQUEST_CYCLE_GROUPS_ADD_GUEST, addGuest),
    yield takeEvery(Types.REQUEST_CYCLE_GROUPS_DEL_GUEST, delGuest),
  ]);
}
