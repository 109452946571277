// #region Imports
import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
//FluentUI
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import {
  DetailsListLayoutMode,
  SelectionMode,
  Selection,
  DetailsHeader,
  IColumn,
} from 'office-ui-fabric-react/lib/DetailsList';
import { ShimmeredDetailsList } from 'office-ui-fabric-react/lib/ShimmeredDetailsList';
import { ScrollablePane } from 'office-ui-fabric-react/lib/ScrollablePane';
import { Sticky } from 'office-ui-fabric-react/lib/Sticky';
import CustomDialog from "~/components/CustomDialog";
import { Label } from 'office-ui-fabric-react/lib/Label';
import MenuItem from '@material-ui/core/MenuItem';
import Dropdown from "~/components/layout/Dropdown";
import NoItems from '~/components/layout/NoItems';
//Estilos

import { Creators as getCycleGroups } from "~/store/ducks/cycle/groups";
import { Creators as getMeetingsCriar } from "~/store/ducks/meetings";
import { DataTypes as DataTypesMeetings } from "~/store/ducks/meetings/types";
import { CycleGroupType } from "~/store/ducks/cycle/groups/types";

import { RootState } from "~/store/ducks";
//#endregion

interface IFormSearchState {
  columns: IColumn[];
  idCiclo: string;
  items: Partial<CycleGroupType>[];
  selectionDetails: string;
  selectionCount: number;
};

interface IPropsFormSearch {
  meetings: DataTypesMeetings;
  isLoadingAction: boolean;
  isOpen: boolean;
  onClose: () => void;
  onAdd: (values: CycleGroupType) => void;
  getCycleGroups: (idCiclo: number) => void;
  getMeetingsCriar: (idCiclo?: number) => void;
}


class DialogMeetings extends Component<IPropsFormSearch, IFormSearchState> {
  private _selection: Selection;

  constructor(props: IPropsFormSearch) {
    super(props);
    //#region Colunas
    const columns: IColumn[] = [
      {
        key: 'column2',
        name: 'Id',
        ariaLabel: 'Id',
        fieldName: 'idCicloGrupo',
        isRowHeader: true,
        minWidth: 35,
        maxWidth: 50,
        isResizable: true,
        onColumnClick: this._onColumnClick
      },
      {
        key: 'column3',
        name: 'Nome do Grupo',
        fieldName: 'nomeGrupo',
        minWidth: 210,
        isRowHeader: true,
        isResizable: true,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
      },
    ];
    //#endregion

    this.state = {
      columns: columns,
      items: [],
      selectionDetails: "",
      selectionCount: 0,
      idCiclo: ""
    }

    this._selection = new Selection({
      onSelectionChanged: () => {
        this.setState({
          selectionDetails: this._getSelectionDetails(),
        });
      },
    });
  };

  componentDidUpdate(prevProps: IPropsFormSearch, prevState: IFormSearchState) {
    if (prevProps.isOpen !== this.props.isOpen) {
      if (this.props.isOpen) {
        this.props.getMeetingsCriar();
        this.setState({ idCiclo: '' })
      }
    }

    if (prevProps.meetings.dataCriar !== this.props.meetings.dataCriar) {
      if (this.props.meetings.dataCriar) {
        if (!this.state.idCiclo) {
          this.setState({ idCiclo: String(this.props.meetings.dataCriar?.ciclos?.[0].idCiclo) })
        }
      }
    }

    if (prevProps.isOpen !== this.props.isOpen) {
      if (this.props.isOpen) {
        this._selection.setAllSelected(false);
      }
    }
  }

  _onItemInvoked = (item: CycleGroupType): void => {

  };

  _getSelectionDetails(): any {
    const selectionCount = this._selection.getSelectedCount();
    this.setState({ selectionCount });
    this._selection.getSelection();
  };

  //#endregion

  render() {
    const { columns, idCiclo } = this.state;
    const { meetings, isOpen, onClose, onAdd, getMeetingsCriar, isLoadingAction } = this.props;

    return (
      <CustomDialog
        open={isOpen}
        title="Nova reunião"
        btnConfirmText="Criar"
        onClickCancel={onClose}
        disabledBtnConfirm={this._selection.getSelectedCount() === 0 || meetings.loadingAction}
        isLoadingConfirm={isLoadingAction}
        onClickConfirm={() => {
          onAdd(this._selection.getSelection()[0] as CycleGroupType)
          // onClose()
        }}
        width={640}
        height={460}
        zIndex={1300}
      >
        <Stack horizontalAlign="end" styles={{ root: { marginBottom: 10, width: '100%' } }}>
          <Stack styles={{ root: { width: 150, marginTop: 5 } }}>
            <Dropdown
              label=""
              name="ciclo"
              values={idCiclo}
              handleChange={(e: any) => {
                this.setState({ idCiclo: e.target.value });
                getMeetingsCriar(Number(e.target.value));
              }}
              autofocus
              variant="outlined"
              size="small"
            >
              {
                meetings.dataCriar?.ciclos?.map(item => (
                  <MenuItem
                    key={item.idCiclo}
                    value={`${item.idCiclo}`}>
                    {`${item.ano} - ${item.area.nomeLocal}`}
                  </MenuItem>
                ))
              }
            </Dropdown>
          </Stack>
        </Stack>
        {
          meetings.dataCriar?.grupos?.length === 0 && !meetings.loadingCriar ?
            <NoItems
              text="Não há grupos cadastrados"
              error={false}
              icon="Group"
              styles={{ margin: 0, height: 150, display: 'flex', justifyContent: 'center' }}
            />
            :
            <>
              <Label>Selecione o grupo desejado:</Label>
              <Stack styles={{ root: { overflowY: 'auto', height: 232 } }}>
                <ScrollablePane styles={{
                  root: {
                    position: 'relative',
                    height: '100%',
                    top: 0
                  }
                }}>


                  <ShimmeredDetailsList
                    items={meetings.dataCriar?.grupos ?? []}
                    enableShimmer={meetings.loadingData}
                    columns={columns}
                    selectionMode={SelectionMode.single}
                    selection={this._selection}
                    getKey={this._getKey}
                    selectionPreservedOnEmptyClick={true}
                    setKey="multiple"
                    layoutMode={DetailsListLayoutMode.justified}
                    isHeaderVisible={true}
                    onItemInvoked={this._onItemInvoked}
                    onRenderDetailsHeader={
                      detailsHeaderProps => {
                        return (
                          <Sticky>
                            <DetailsHeader
                              {...detailsHeaderProps}
                              layoutMode={DetailsListLayoutMode.justified}
                            />
                          </Sticky>
                        );
                      }
                    }
                  />
                </ScrollablePane>
              </Stack>
            </>
        }
      </CustomDialog >
    );
  };

  private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    const { columns } = this.state;
    const newColumns: IColumn[] = columns.slice();
    let items: Partial<CycleGroupType>[] = [];
    items = this.props.meetings.dataCriar?.grupos ?? [];
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      };
    });
    const newItems = this._sort(items, currColumn.fieldName!, currColumn.isSortedDescending);
    this.setState({
      columns: newColumns,
      items: newItems,
    });
  };

  private _getKey(item: any, index?: number): any {
    if (item !== undefined)
      return item.key;
  };

  private _sort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
    const key = columnKey as keyof T;
    const itemsSorted = items.sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
    return itemsSorted;
  };

}
const mapStateToProps = (state: RootState) => ({
  meetings: state.meetingsReducer
});

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  ...getCycleGroups,
  ...getMeetingsCriar,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DialogMeetings);
