/**
 * Types
 */
export enum Types {
  //GET
  REQUEST_GENERATE_MERIT_LOAD = "generate_merit/REQUEST_GENERATE_MERIT_LOAD",
  SUCCESS_GENERATE_MERIT_LOAD = "generate_merit/SUCCESS_GENERATE_MERIT_LOAD",
  FAILURE_GENERATE_MERIT_LOAD = "generate_merit/FAILURE_GENERATE_MERIT_LOAD",
  //ADD
  REQUEST_GENERATE_MERIT_ADD = "generate_merit/REQUEST_GENERATE_MERIT_ADD",
  SUCCESS_GENERATE_MERIT_ADD = "generate_merit/SUCCESS_GENERATE_MERIT_ADD",
  FAILURE_GENERATE_MERIT_ADD = "generate_merit/FAILURE_GENERATE_MERIT_ADD",
  //DEL
  REQUEST_GENERATE_MERIT_DEL = "generate_merit/REQUEST_GENERATE_MERIT_DEL",
  SUCCESS_GENERATE_MERIT_DEL = "generate_merit/SUCCESS_GENERATE_MERIT_DEL",
  FAILURE_GENERATE_MERIT_DEL = "generate_merit/FAILURE_GENERATE_MERIT_DEL",
  //FINALIZAR
  REQUEST_GENERATE_MERIT_FINALIZAR = "generate_merit/REQUEST_GENERATE_MERIT_FINALIZAR",
  SUCCESS_GENERATE_MERIT_FINALIZAR = "generate_merit/SUCCESS_GENERATE_MERIT_FINALIZAR",
  FAILURE_GENERATE_MERIT_FINALIZAR = "generate_merit/FAILURE_GENERATE_MERIT_FINALIZAR",
  //FINALIZAR
  REQUEST_GENERATE_MERIT_REABRIR = "generate_merit/REQUEST_GENERATE_MERIT_REABRIR",
  SUCCESS_GENERATE_MERIT_REABRIR = "generate_merit/SUCCESS_GENERATE_MERIT_REABRIR",
  FAILURE_GENERATE_MERIT_REABRIR = "generate_merit/FAILURE_GENERATE_MERIT_REABRIR",
}

export type MeritGenerateType = {
  idMerito: number | null;
  ano: string;
  idArea: number | null;
  area: {
    nomeLocal: string;
  };
  diretoria: {
    nomeLocal: string;
  };
  resp: {
    nomeCompleto: string;
  };
  dataReferencia: Date | null | string;
  dataReuniao: Date | null | string;
  status: string;
  flagUnicoNivel: boolean;
};

type MeritGenerateSelectedState = {
  item: MeritGenerateType | null;
  loading: boolean;
  success: boolean;
  error: boolean;
};

interface IMeritGenerateState {
  readonly data: MeritGenerateType[];
  readonly itemSelected: MeritGenerateSelectedState;
  readonly loadingData: boolean;
  readonly loadingAction: boolean;
  readonly error: boolean;
  readonly success: boolean;
  readonly successAdd: boolean;
  readonly idMeritoCreated: number | null;
}

export type DataTypes = IMeritGenerateState;

/**
 * Actions
 */

//GET
export interface IRequestMeritGenerateLoadAction {
  type: typeof Types.REQUEST_GENERATE_MERIT_LOAD;
}

export interface ISuccessMeritGenerateLoadAction {
  type: typeof Types.SUCCESS_GENERATE_MERIT_LOAD;
  payload: MeritGenerateType[];
}

interface IFailureMeritGenerateLoadAction {
  type: typeof Types.FAILURE_GENERATE_MERIT_LOAD;
  message: string;
}

type MeritGenerateLoadAction =
  | IRequestMeritGenerateLoadAction
  | ISuccessMeritGenerateLoadAction
  | IFailureMeritGenerateLoadAction;

//ADD
export interface IRequestMeritGenerateAddAction {
  type: typeof Types.REQUEST_GENERATE_MERIT_ADD;
  payload: Partial<MeritGenerateType>;
}

export interface ISuccessMeritGenerateAddAction {
  type: typeof Types.SUCCESS_GENERATE_MERIT_ADD;
  idMerito: number;
}

interface IFailureMeritGenerateAddAction {
  type: typeof Types.FAILURE_GENERATE_MERIT_ADD;
  message: string;
}

type MeritGenerateAddAction =
  | IRequestMeritGenerateAddAction
  | ISuccessMeritGenerateAddAction
  | IFailureMeritGenerateAddAction;

//DEL
export interface IRequestMeritGenerateDelAction {
  type: typeof Types.REQUEST_GENERATE_MERIT_DEL;
  idMerito: number;
}

export interface ISuccessMeritGenerateDelAction {
  type: typeof Types.SUCCESS_GENERATE_MERIT_DEL;
  idMerito: number;
}

interface IFailureMeritGenerateDelAction {
  type: typeof Types.FAILURE_GENERATE_MERIT_DEL;
  message: string;
}

type MeritGenerateDelAction =
  | IRequestMeritGenerateDelAction
  | ISuccessMeritGenerateDelAction
  | IFailureMeritGenerateDelAction;

//DEL
export interface IRequestMeritGenerateFinalizarAction {
  type: typeof Types.REQUEST_GENERATE_MERIT_FINALIZAR;
  idMerito: number;
}

export interface ISuccessMeritGenerateFinalizarAction {
  type: typeof Types.SUCCESS_GENERATE_MERIT_FINALIZAR;
  payload: {
    idMerito: number;
    status: string;
    dataHoraAprovacao: Date;
  };
}

interface IFailureMeritGenerateFinalizarAction {
  type: typeof Types.FAILURE_GENERATE_MERIT_FINALIZAR;
  message: string;
}

type MeritGenerateFinalizarAction =
  | IRequestMeritGenerateFinalizarAction
  | ISuccessMeritGenerateFinalizarAction
  | IFailureMeritGenerateFinalizarAction;

//REQUEST_MERIT_REABRIR_MERITO
export interface IRequestMeritReabrirReuniaoAction {
  type: typeof Types.REQUEST_GENERATE_MERIT_REABRIR;
  payload: {
    idMerito: number;
  };
}

export interface ISuccessMeritReabrirReuniaoAction {
  type: typeof Types.SUCCESS_GENERATE_MERIT_REABRIR;
  payload: {
    idMerito: number;
    status: string;
  };
}

interface IFailureMeritReabrirReuniaoAction {
  type: typeof Types.FAILURE_GENERATE_MERIT_REABRIR;
  message: string;
}

type MeritGenerateReabrirReuniaoAction =
  | IRequestMeritReabrirReuniaoAction
  | ISuccessMeritReabrirReuniaoAction
  | IFailureMeritReabrirReuniaoAction;



export type MeritGenerateActionTypes =
  | MeritGenerateLoadAction
  | MeritGenerateAddAction
  | MeritGenerateDelAction
  | MeritGenerateFinalizarAction
  | MeritGenerateReabrirReuniaoAction;
