import { Types, DataTypes, PositionTypes, PositionActionTypes } from "./types";
import customToast from "~/components/Toast/index";

/**
 * Reducers
 */
const INITIAL_STATE: DataTypes = {
  data: [],
  dataSearch: [],
  itemSelected: {
    item: null,
    loading: false,
    success: false,
    error: false,
  },
  loadingData: false,
  loadingDataSearch: false,
  loadingAction: false,
  error: false,
  success: false,
  successDataSearch: false,
  
};

/**
 * Actions
 */

export const Creators = {
  getPositions: (
    search?: string,
    isSearch?: boolean,
    filter?: boolean | null,
    gs?: string | null,
    todos?: boolean
  ): PositionActionTypes => {
    const pesquisa = search !== "" && search !== undefined ? search : "";
    const filtro = filter !== null && filter !== undefined ? filter : null;
    const grupoSalarial = gs !== null && gs !== undefined ? gs : null;
    const todosCargos = todos !== null && todos !== undefined ? todos : null;
    return {
      type: Types.REQUEST_POSITIONS_LOAD,
      payload: {
        isSearch,
        search: {
          SearchText: pesquisa,
          filterAtivo: filtro,
          gs: grupoSalarial,
          Todos: todosCargos,
        },
      },
    };
  },

  getPositionById: (idCargo: number): PositionActionTypes => ({
    type: Types.REQUEST_POSITIONS_LOAD_BY_ID,
    idCargo,
  }),

  addPosition: (position: PositionTypes): PositionActionTypes => ({
    type: Types.REQUEST_POSITION_ADD,
    position,
  }),

  editPosition: (position: PositionTypes): PositionActionTypes => ({
    type: Types.REQUEST_POSITION_EDIT,
    position,
  }),

  delPosition: (id: number): PositionActionTypes => ({
    type: Types.REQUEST_POSITION_DELETE,
    id,
  }),

  clearSearch: (): PositionActionTypes => ({
    type: Types.CLEAR_POSITION_SEARCH,
  }),
};

export default function positionsReducer(
  state = INITIAL_STATE,
  action: PositionActionTypes
): DataTypes {
  switch (action.type) {
    case Types.REQUEST_POSITIONS_LOAD:
      return {
        ...state,
        loadingData: !action.payload.isSearch ? true : state.loadingData,
        loadingDataSearch: action.payload.isSearch
          ? true
          : state.loadingDataSearch,
        error: false,
        success: !action.payload.isSearch ? false : state.success,
        successDataSearch: action.payload.isSearch
          ? false
          : state.successDataSearch,
      };
    case Types.SUCCESS_POSITIONS_LOAD:
      console.log(action.payload)
      return {
        ...state,
        data: !action.payload.isSearch ? action.payload.position : state.data,
        dataSearch: action.payload.isSearch
          ? action.payload.position
          : state.dataSearch,
        loadingDataSearch: false,
        loadingData: false,
        error: false,
        success: !action.payload.isSearch ? true : state.success,
        successDataSearch: action.payload.isSearch
          ? true
          : state.successDataSearch,
      };
    case Types.FAILURE_POSITIONS_LOAD:
      customToast.error(action.message);
      return {
        ...state,
        data: [],
        dataSearch: [],
        loadingData: false,
        loadingDataSearch: false,
        error: true,
        success: false,
        successDataSearch: false,
      };

    case Types.REQUEST_POSITIONS_LOAD_BY_ID:
      return {
        ...state,
        itemSelected: {
          item: null,
          loading: true,
          success: false,
          error: false,
        },
      };
    case Types.SUCCESS_POSITIONS_LOAD_BY_ID:
      return {
        ...state,
        itemSelected: {
          item: action.payload,
          loading: false,
          success: true,
          error: false,
        },
      };
    case Types.FAILURE_POSITIONS_LOAD_BY_ID:
      customToast.error(action.message);
      return {
        ...state,
        itemSelected: {
          item: null,
          loading: false,
          success: false,
          error: true,
        },
      };

    case Types.REQUEST_POSITION_ADD:
      return {
        ...state,
        error: false,
        success: false,
        loadingAction: true,
      };
    case Types.SUCCESS_POSITION_ADD:
      customToast.success("Cargo cadastrado com sucesso");
      return {
        ...state,
        error: false,
        success: true,
        data: [...state.data, action.position],
        loadingAction: false,
      };
    case Types.FAILURE_POSITION_ADD:
      customToast.error(action.message);
      return {
        ...state,
        error: true,
        success: false,
        loadingAction: false,
      };

    case Types.REQUEST_POSITION_EDIT:
      return {
        ...state,
        error: false,
        success: false,
        loadingAction: true,
      };
    case Types.SUCCESS_POSITION_EDIT:
      customToast.success("Cargo editado com sucesso");
      return {
        ...state,
        error: false,
        success: true,
        loadingAction: false,
        data: state.data.map((item) => {
          if (item.idCargo !== action.position.idCargo) {
            return item;
          }
          return action.position;
        }),
      };
    case Types.FAILURE_POSITION_EDIT:
      customToast.error(action.message);
      return {
        ...state,
        error: true,
        success: false,
        loadingAction: false,
      };

    case Types.REQUEST_POSITION_DELETE:
      return {
        ...state,
        error: false,
        success: false,
        loadingData: true,
      };
    case Types.SUCCESS_POSITION_DELETE:
      customToast.success("Item excluído com sucesso");
      return {
        ...state,
        error: false,
        success: true,
        data: state.data.filter((item) => item.idCargo !== action.id),
        loadingData: false,
      };
    case Types.FAILURE_POSITION_DELETE:
      customToast.error(action.message);
      return {
        ...state,
        error: true,
        success: false,
        loadingData: false,
      };

    case Types.CLEAR_POSITION_SEARCH:
      return { ...state, dataSearch: [] };

    default:
      return state;
  }
}
