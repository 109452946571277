import { StepType } from "../admin/steps/types";

/**
 * Types
 */
export enum Types {
  //GET BY ID
  REQUEST_BONUS_LOAD_BY_ID = "bonus/REQUEST_BONUS_LOAD_BY_ID",
  SUCCESS_BONUS_LOAD_BY_ID = "bonus/SUCCESS_BONUS_LOAD_BY_ID",
  FAILURE_BONUS_LOAD_BY_ID = "bonus/FAILURE_BONUS_LOAD_BY_ID",
  //EDIT
  REQUEST_BONUS_EDIT = "bonus/REQUEST_BONUS_EDIT",
  SUCCESS_BONUS_EDIT = "bonus/SUCCESS_BONUS_EDIT",
  FAILURE_BONUS_EDIT = "bonus/FAILURE_BONUS_EDIT",
  //EDIT_FUNC
  BONUS_FUNC_EDIT = "bonus/BONUS_FUNC_EDIT",
  //EDIT PERFORMANCE
  REQUEST_BONUS_PERFORMANCE_EDIT = "bonus/REQUEST_BONUS_PERFORMANCE_EDIT",
  SUCCESS_BONUS_PERFORMANCE_EDIT = "bonus/SUCCESS_BONUS_PERFORMANCE_EDIT",
  FAILURE_BONUS_PERFORMANCE_EDIT = "bonus/FAILURE_BONUS_PERFORMANCE_EDIT",
  // ATUALIZA COMENTARIO
  REQUEST_BONUS_ATUALIZA_COMENTARIO = "bonus/REQUEST_BONUS_ATUALIZA_COMENTARIO",
  SUCCESS_BONUS_ATUALIZA_COMENTARIO = "bonus/SUCCESS_BONUS_ATUALIZA_COMENTARIO",
  FAILURE_BONUS_ATUALIZA_COMENTARIO = "bonus/FAILURE_BONUS_ATUALIZA_COMENTARIO",
  // ATUALIZA FINALIZAR
  REQUEST_BONUS_FINALIZAR = "bonus/REQUEST_BONUS_FINALIZAR",
  SUCCESS_BONUS_FINALIZAR = "bonus/SUCCESS_BONUS_FINALIZAR",
  FAILURE_BONUS_FINALIZAR = "bonus/FAILURE_BONUS_FINALIZAR",
  // ATUALIZA REATIVAR
  REQUEST_BONUS_REATIVAR = "bonus/REQUEST_BONUS_REATIVAR",
  SUCCESS_BONUS_REATIVAR = "bonus/SUCCESS_BONUS_REATIVAR",
  FAILURE_BONUS_REATIVAR = "bonus/FAILURE_BONUS_REATIVAR",
  // GERAR
  REQUEST_BONUS_GERAR = "bonus/REQUEST_BONUS_GERAR",
  SUCCESS_BONUS_GERAR = "bonus/SUCCESS_BONUS_GERAR",
  FAILURE_BONUS_GERAR = "bonus/FAILURE_BONUS_GERAR",
  // PUBLICAR
  REQUEST_BONUS_PUBLICAR = "bonus/REQUEST_BONUS_PUBLICAR",
  SUCCESS_BONUS_PUBLICAR = "bonus/SUCCESS_BONUS_PUBLICAR",
  FAILURE_BONUS_PUBLICAR = "bonus/FAILURE_BONUS_PUBLICAR",
  // ADD FALTA
  REQUEST_BONUS_FALTA_ADD = "bonus/REQUEST_BONUS_FALTA_ADD",
  SUCCESS_BONUS_FALTA_ADD = "bonus/SUCCESS_BONUS_FALTA_ADD",
  FAILURE_BONUS_FALTA_ADD = "bonus/FAILURE_BONUS_FALTA_ADD",
  // EDIT FALTA
  REQUEST_BONUS_FALTA_EDIT = "bonus/REQUEST_BONUS_FALTA_EDIT",
  SUCCESS_BONUS_FALTA_EDIT = "bonus/SUCCESS_BONUS_FALTA_EDIT",
  FAILURE_BONUS_FALTA_EDIT = "bonus/FAILURE_BONUS_FALTA_EDIT",
  // DEL FALTA
  REQUEST_BONUS_FALTA_DEL = "bonus/REQUEST_BONUS_FALTA_DEL",
  SUCCESS_BONUS_FALTA_DEL = "bonus/SUCCESS_BONUS_FALTA_DEL",
  FAILURE_BONUS_FALTA_DEL = "bonus/FAILURE_BONUS_FALTA_DEL",

  // ATUALIZA CALIBRACAO
  REQUEST_BONUS_ATUALIZA_CALIBRACAO = "bonus/REQUEST_BONUS_ATUALIZA_CALIBRACAO",
  SUCCESS_BONUS_ATUALIZA_CALIBRACAO = "bonus/SUCCESS_BONUS_ATUALIZA_CALIBRACAO",
  FAILURE_BONUS_ATUALIZA_CALIBRACAO = "bonus/FAILURE_BONUS_ATUALIZA_CALIBRACAO",

  // ATUALIZA RESIDUAL
  REQUEST_BONUS_ATUALIZA_RESIDUAL = "bonus/REQUEST_BONUS_ATUALIZA_RESIDUAL",
  SUCCESS_BONUS_ATUALIZA_RESIDUAL = "bonus/SUCCESS_BONUS_ATUALIZA_RESIDUAL",
  FAILURE_BONUS_ATUALIZA_RESIDUAL = "bonus/FAILURE_BONUS_ATUALIZA_RESIDUAL",

  // ATUALIZA OUTRAS REMUNERACAO
  REQUEST_BONUS_ATUALIZA_REMUNERACAO = "bonus/REQUEST_BONUS_ATUALIZA_REMUNERACAO",
  SUCCESS_BONUS_ATUALIZA_REMUNERACAO = "bonus/SUCCESS_BONUS_ATUALIZA_REMUNERACAO",
  FAILURE_BONUS_ATUALIZA_REMUNERACAO = "bonus/FAILURE_BONUS_ATUALIZA_REMUNERACAO",

  // APLICAR BONUS
  REQUEST_BONUS_APLICAR = "bonus/REQUEST_BONUS_APLICAR",
  SUCCESS_BONUS_APLICAR = "bonus/SUCCESS_BONUS_APLICAR",
  FAILURE_BONUS_APLICAR = "bonus/FAILURE_BONUS_APLICAR",

  // IGNORAR BONUSO
  REQUEST_BONUS_IGNORAR = "bonus/REQUEST_BONUS_IGNORAR",
  SUCCESS_BONUS_IGNORAR = "bonus/SUCCESS_BONUS_IGNORAR",
  FAILURE_BONUS_IGNORAR = "bonus/FAILURE_BONUS_IGNORAR",
}

export type BonusData = {
  bonus: BonusType;
  steps: Partial<StepType>[];
};

export type BonusType = {
  idBonus: string;
  ano: string;
  statusbonus:
    | "Parametrização"
    | "Gerando"
    | "Erro"
    | "Em conferência"
    | "Publicado";
  dataReferencia: string;
  mesesTrabalhados: number;
  resultadoAtingido: number | null;
  resultadoPercentual: number | null;
  acelerador: number | null;
  salariosDiretor: number | null;
  salariosGerente: number | null;
  salariosCoordenador: number | null;
  salariosSupervisor: number | null;
  performances: BonusPerformanceType[];
  faltas: BonusFaltaType[];
  funcionarios: BonusFuncType[];
};

export type BonusFaltaType = {
  idBonusFalta?: number;
  qtdeMinima?: number;
  desconto?: number;
};

export type BonusFuncType = {
  idBonusFuncionario: string;
  idUnidade: number | null;
  idFuncionario: number;
  statusFuncionario: string;
  dataDemissao: Date;
  idMotivoDesligamento: number | null;
  idCargo: number | null;
  idCategoriaCargo: number | null;
  idFuncionarioGestor: number | null;
  salarioRef: number;
  idArea: number;
  afastamento: number;
  faltas: number;
  elegibilidadeCargo: boolean;
  elegibilidadeDemissao: boolean;
  flagElegivel: boolean;
  periodoElegibilidade: number;
  targetSugerido: number;
  calibracaoSugerida: number;
  salarioMultiplicadorSugerido: number;
  calibracaoFinalSugerida: number;
  bonusSugerido: number;
  aplicarBonus: boolean;
  calibracaoAplicada: number;
  bonusAplicado: number;
  statusPolitica: boolean;
  observacoesPolitica: string | null;
  comentarios: string | null;
  flagFinalizado: boolean;
  residualAnoAnterior: number;
  outrasRemuneracoes: number;
  unidade: {
    nomeUnidade: string;
  } | null;
  funcionario: {
    matricula: string;
    nomeCompleto: string;
    dataAdmissao: Date | string;
    imagem: string | null;
  };
  motivoDesligamento: {
    descMotivo: string;
  } | null;
  gestor: {
    nomeCompleto: string;
  } | null;
  cargo: {
    titulo: string;
  };
  categoriaCargo: {
    descCategoriaCargo: string;
  } | null;
  area: {
    nomeLocal: string;
  } | null;

  flagCalibEdit: boolean;
  flagResidualEdit: boolean;
  flagOutrasRemEdit: boolean;

  // tempoEmpresa: number;
  // idade: number;
  // idCargoOrigem: number;
  // tempoCargo: number;
  // gsOrigem: number;
  // idESOrigem: string;
  // salarioOrigem: string;
  // faixaOrigem: string;
  // ultimaAlteracao: string;
  // percReajuste: string;
  // tempoUltimaAlteracao: number;
  // idTipoMov: number;
  // idTabPerformanceNivel: number | null;
  // potencial: string | null;
  // salariosMultiplicador: string;
  // cargoOrigem: {
  //   titulo: string;
  //   cargosuperior: {
  //     idCargo: number;
  //     titulo: string;
  //     gs: number;
  //   } | null;
  // };
  // performance: { descNivel: string; cor: string } | null;
  // tipomov: {
  //   descTipoMov: string;
  // };
  // esOrigem: {
  //   nomeES: string;
  // };
};

export type BonusPerformanceType = {
  idBonusPerformance: string;
  multiplicador: string;
  nivel: {
    descNivel: string;
    nivel: number;
    idTabPerformance: number;
  };
};

interface IBonusState {
  readonly data: BonusData | null;
  // readonly dataResumo: MeritResume | null;
  readonly loadingData: boolean;
  readonly loadingAction: boolean;
  readonly error: boolean;
  readonly errorData: boolean;
  readonly success: boolean;
  readonly redirect: boolean;
}

export type DataTypes = IBonusState;

//GET BY ID
export interface IRequestBonusLoadByIdAction {
  type: typeof Types.REQUEST_BONUS_LOAD_BY_ID;
  idBonus: number;
}

export interface ISuccessBonusLoadByIdAction {
  type: typeof Types.SUCCESS_BONUS_LOAD_BY_ID;
  payload: BonusData;
}

interface IFailureBonusLoadByIdAction {
  type: typeof Types.FAILURE_BONUS_LOAD_BY_ID;
  message: string;
}

type BonusLoadByIdAction =
  | IRequestBonusLoadByIdAction
  | ISuccessBonusLoadByIdAction
  | IFailureBonusLoadByIdAction;

//EDIT
export interface IRequestBonusEditAction {
  type: typeof Types.REQUEST_BONUS_EDIT;
  bonus: Partial<BonusType>;
}

export interface ISuccessBonusEditAction {
  type: typeof Types.SUCCESS_BONUS_EDIT;
  payload: Partial<BonusType>;
}

interface IFailureBonusEditAction {
  type: typeof Types.FAILURE_BONUS_EDIT;
  message: string;
}

type BonusEditAction =
  | IRequestBonusEditAction
  | ISuccessBonusEditAction
  | IFailureBonusEditAction;

//EDIT PERFORMANCE
export interface IRequestBonusPerformanceEditAction {
  type: typeof Types.REQUEST_BONUS_PERFORMANCE_EDIT;
  payload: {
    idBonus: string;
    idBonusPerformance: string;
    multiplicador: string;
  };
}

export interface ISuccessBonusPerformanceEditAction {
  type: typeof Types.SUCCESS_BONUS_PERFORMANCE_EDIT;
  payload: {
    idBonusPerformance: string;
    multiplicador: string;
  };
}

interface IFailureBonusPerformanceEditAction {
  type: typeof Types.FAILURE_BONUS_PERFORMANCE_EDIT;
  message: string;
}

type BonusPerformanceEditAction =
  | IRequestBonusPerformanceEditAction
  | ISuccessBonusPerformanceEditAction
  | IFailureBonusPerformanceEditAction;

//ATUALIZA_COMENTARIO EDIT
export interface IRequestBonusAtualizaComentarioAction {
  type: typeof Types.REQUEST_BONUS_ATUALIZA_COMENTARIO;
  payload: {
    idBonus: number;
    idBonusFuncionario: string;
    comentarios: string;
  };
}

export interface ISuccessBonusAtualizaComentarioAction {
  type: typeof Types.SUCCESS_BONUS_ATUALIZA_COMENTARIO;
  payload: {
    idBonusFuncionario: string;
    comentarios: string;
  };
}

interface IFailureBonusAtualizaComentarioAction {
  type: typeof Types.FAILURE_BONUS_ATUALIZA_COMENTARIO;
  message: string;
}

type BonusAtualizaComentarioAction =
  | IRequestBonusAtualizaComentarioAction
  | ISuccessBonusAtualizaComentarioAction
  | IFailureBonusAtualizaComentarioAction;

//REQUEST BONUS FINALIZAR
export interface IRequestBonusFinalizarAction {
  type: typeof Types.REQUEST_BONUS_FINALIZAR;
  payload: {
    idBonus: number;
    idBonusFuncionario: string;
  };
}

export interface ISuccessBonusFinalizarAction {
  type: typeof Types.SUCCESS_BONUS_FINALIZAR;
  payload: {
    idBonusFuncionario: string;
  };
}

interface IFailureBonusFinalizarAction {
  type: typeof Types.FAILURE_BONUS_FINALIZAR;
  message: string;
}

type BonusFinalizarAction =
  | IRequestBonusFinalizarAction
  | ISuccessBonusFinalizarAction
  | IFailureBonusFinalizarAction;

//REQUEST_MERIT_REATIVAR
export interface IRequestBonusReativarAction {
  type: typeof Types.REQUEST_BONUS_REATIVAR;
  payload: {
    idBonus: number;
    idBonusFuncionario: string;
  };
}

export interface ISuccessBonusReativarAction {
  type: typeof Types.SUCCESS_BONUS_REATIVAR;
  payload: {
    idBonusFuncionario: string;
  };
}

interface IFailureBonusReativarAction {
  type: typeof Types.FAILURE_BONUS_REATIVAR;
  message: string;
}

type BonusReativarAction =
  | IRequestBonusReativarAction
  | ISuccessBonusReativarAction
  | IFailureBonusReativarAction;

//GERAR
export interface IRequestBonusGerarAction {
  type: typeof Types.REQUEST_BONUS_GERAR;
  idBonus: number;
}

export interface ISuccessBonusGerarAction {
  type: typeof Types.SUCCESS_BONUS_GERAR;
  payload: BonusFuncType[];
}

interface IFailureBonusGerarAction {
  type: typeof Types.FAILURE_BONUS_GERAR;
  message: string;
}

type BonusGerarAction =
  | IRequestBonusGerarAction
  | ISuccessBonusGerarAction
  | IFailureBonusGerarAction;

//PUBLICAR
export interface IRequestBonusPublicarAction {
  type: typeof Types.REQUEST_BONUS_PUBLICAR;
  idBonus: number;
}

export interface ISuccessBonusPublicarAction {
  type: typeof Types.SUCCESS_BONUS_PUBLICAR;
  payload: BonusFuncType[];
}

interface IFailureBonusPublicarAction {
  type: typeof Types.FAILURE_BONUS_PUBLICAR;
  message: string;
}

type BonusPublicarAction =
  | IRequestBonusPublicarAction
  | ISuccessBonusPublicarAction
  | IFailureBonusPublicarAction;

//REQUEST_BONUS_FALTA_ADD
export interface IRequestBonusAddFaltaAction {
  type: typeof Types.REQUEST_BONUS_FALTA_ADD;
  payload: {
    idBonus: number;
    falta: BonusFaltaType;
  };
}

export interface ISuccessBonusAddFaltaAction {
  type: typeof Types.SUCCESS_BONUS_FALTA_ADD;
  payload: BonusFaltaType;
}

interface IFailureBonusAddFaltaAction {
  type: typeof Types.FAILURE_BONUS_FALTA_ADD;
  message: string;
}

type BonusAddFaltaAction =
  | IRequestBonusAddFaltaAction
  | ISuccessBonusAddFaltaAction
  | IFailureBonusAddFaltaAction;

//REQUEST_BONUS_FALTA_EDIT
export interface IRequestBonusEditFaltaAction {
  type: typeof Types.REQUEST_BONUS_FALTA_EDIT;
  payload: {
    idBonus: number;
    falta: BonusFaltaType;
  };
}

export interface ISuccessBonusEditFaltaAction {
  type: typeof Types.SUCCESS_BONUS_FALTA_EDIT;
  payload: BonusFaltaType;
}

interface IFailureBonusEditFaltaAction {
  type: typeof Types.FAILURE_BONUS_FALTA_EDIT;
  message: string;
}

type BonusEditFaltaAction =
  | IRequestBonusEditFaltaAction
  | ISuccessBonusEditFaltaAction
  | IFailureBonusEditFaltaAction;

//REQUEST_BONUS_FALTA_DEL
export interface IRequestBonusDelFaltaAction {
  type: typeof Types.REQUEST_BONUS_FALTA_DEL;
  payload: {
    idBonus: number;
    idBonusFalta: number;
  };
}

export interface ISuccessBonusDelFaltaAction {
  type: typeof Types.SUCCESS_BONUS_FALTA_DEL;
  idBonusFalta: number;
}

interface IFailureBonusDelFaltaAction {
  type: typeof Types.FAILURE_BONUS_FALTA_DEL;
  message: string;
}

type BonusDelFaltaAction =
  | IRequestBonusDelFaltaAction
  | ISuccessBonusDelFaltaAction
  | IFailureBonusDelFaltaAction;

//BONUS_ATUALIZA_CALIBRACAO EDIT
export interface IRequestBonusAtualizaCalibracaoAction {
  type: typeof Types.REQUEST_BONUS_ATUALIZA_CALIBRACAO;
  payload: {
    idBonus: number;
    idBonusFuncionario: string;
    calibracaoAplicada: number;
  };
}

export interface ISuccessBonusAtualizaCalibracaoAction {
  type: typeof Types.SUCCESS_BONUS_ATUALIZA_CALIBRACAO;
  payload: {
    idBonusFuncionario: string;
    calibracaoAplicada: number;
  };
}

interface IFailureBonusAtualizaCalibracaoAction {
  type: typeof Types.FAILURE_BONUS_ATUALIZA_CALIBRACAO;
  message: string;
}

type BonusAtualizaCalibracaoAction =
  | IRequestBonusAtualizaCalibracaoAction
  | ISuccessBonusAtualizaCalibracaoAction
  | IFailureBonusAtualizaCalibracaoAction;

//REQUEST_BONUS_ATUALIZA_RESIDUAL EDIT
export interface IRequestBonusAtualizaResidualAction {
  type: typeof Types.REQUEST_BONUS_ATUALIZA_RESIDUAL;
  payload: {
    idBonus: number;
    idBonusFuncionario: string;
    residualAnoAnterior: number;
  };
}

export interface ISuccessBonusAtualizaResidualAction {
  type: typeof Types.SUCCESS_BONUS_ATUALIZA_RESIDUAL;
  payload: {
    idBonusFuncionario: string;
    residualAnoAnterior: number;
  };
}

interface IFailureBonusAtualizaResidualAction {
  type: typeof Types.FAILURE_BONUS_ATUALIZA_RESIDUAL;
  message: string;
}

type BonusAtualizaResidualAction =
  | IRequestBonusAtualizaResidualAction
  | ISuccessBonusAtualizaResidualAction
  | IFailureBonusAtualizaResidualAction;

//REQUEST_BONUS_ATUALIZA_RESIDUAL EDIT
export interface IRequestBonusAtualizaOutrasRemAction {
  type: typeof Types.REQUEST_BONUS_ATUALIZA_REMUNERACAO;
  payload: {
    idBonus: number;
    idBonusFuncionario: string;
    outrasRemuneracoes: number;
  };
}

export interface ISuccessBonusAtualizaOutrasRemAction {
  type: typeof Types.SUCCESS_BONUS_ATUALIZA_REMUNERACAO;
  payload: {
    idBonusFuncionario: string;
    outrasRemuneracoes: number;
  };
}

interface IFailureBonusAtualizaOutrasRemAction {
  type: typeof Types.FAILURE_BONUS_ATUALIZA_REMUNERACAO;
  message: string;
}

type BonusAtualizaOutrasRemAction =
  | IRequestBonusAtualizaOutrasRemAction
  | ISuccessBonusAtualizaOutrasRemAction
  | IFailureBonusAtualizaOutrasRemAction;

//REQUEST_BONUS_FALTA_DEL
export interface IBonusEditFuncAction {
  type: typeof Types.BONUS_FUNC_EDIT;
  payload: Partial<BonusFuncType>;
}

//APLICAR BONUS
export interface IRequestBonusAplicarAction {
  type: typeof Types.REQUEST_BONUS_APLICAR;
  payload: {
    idBonus: number;
    idBonusFuncionario: string;
  };
}

export interface ISuccessBonusAplicarAction {
  type: typeof Types.SUCCESS_BONUS_APLICAR;
  payload: Partial<BonusFuncType>;
}

interface IFailureBonusAplicarAction {
  type: typeof Types.FAILURE_BONUS_APLICAR;
  message: string;
}

type BonusAplicarAction =
  | IRequestBonusAplicarAction
  | ISuccessBonusAplicarAction
  | IFailureBonusAplicarAction;

//IGNORAR BONUS
export interface IRequestBonusIgnorarAction {
  type: typeof Types.REQUEST_BONUS_IGNORAR;
  payload: {
    idBonus: number;
    idBonusFuncionario: string;
  };
}

export interface ISuccessBonusIgnorarAction {
  type: typeof Types.SUCCESS_BONUS_IGNORAR;
  payload: Partial<BonusFuncType>;
}

interface IFailureBonusIgnorarAction {
  type: typeof Types.FAILURE_BONUS_IGNORAR;
  message: string;
}

type BonusIgnorarAction =
  | IRequestBonusIgnorarAction
  | ISuccessBonusIgnorarAction
  | IFailureBonusIgnorarAction;

export type BonusActionTypes =
  | BonusLoadByIdAction
  | BonusEditAction
  | BonusPerformanceEditAction
  | BonusAtualizaComentarioAction
  | BonusFinalizarAction
  | BonusReativarAction
  | BonusGerarAction
  | BonusPublicarAction
  | BonusAddFaltaAction
  | BonusEditFaltaAction
  | BonusDelFaltaAction
  | IBonusEditFuncAction
  | BonusAplicarAction
  | BonusIgnorarAction
  | BonusAtualizaCalibracaoAction
  | BonusAtualizaResidualAction
  | BonusAtualizaOutrasRemAction;
