/**
 * Types
 */
export enum Types {
  //GET
  REQUEST_BONUS_LOAD = "bonus/REQUEST_BONUS_LOAD",
  SUCCESS_BONUS_LOAD = "bonus/SUCCESS_BONUS_LOAD",
  FAILURE_BONUS_LOAD = "bonus/FAILURE_BONUS_LOAD",
  //ADD
  REQUEST_BONUS_ADD = "bonus/REQUEST_BONUS_ADD",
  SUCCESS_BONUS_ADD = "bonus/SUCCESS_BONUS_ADD",
  FAILURE_BONUS_ADD = "bonus/FAILURE_BONUS_ADD",
  //DEL
  REQUEST_BONUS_DEL = "bonus/REQUEST_BONUS_DEL",
  SUCCESS_BONUS_DEL = "bonus/SUCCESS_BONUS_DEL",
  FAILURE_BONUS_DEL = "bonus/FAILURE_BONUS_DEL",
}

export type BonusGenerateType = {
  idBonus?: number;
  ano: string;
  dataReferencia: Date | null | string;
  statusbonus:
    | "Parametrização"
    | "Gerando"
    | "Erro"
    | "Em conferência"
    | "Publicado"
    | "";
  idTabPerformance: number | null;
  mesesTrabalhados: number | null;
};

interface IBonusState {
  readonly data: BonusGenerateType[];
  readonly loading: boolean;
  readonly loadingAction: boolean;
  readonly error: boolean;
  readonly success: boolean;
  readonly successAdd: boolean;
  readonly idBonusCreated: number | null;
}

export type DataTypes = IBonusState;

/**
 * Actions
 */

//GET
export interface IRequestBonusLoadAction {
  type: typeof Types.REQUEST_BONUS_LOAD;
  // payload: {
  //     SearchText?: string;
  // }
}

export interface ISuccessBonusLoadAction {
  type: typeof Types.SUCCESS_BONUS_LOAD;
  payload: BonusGenerateType[];
}

interface IFailureBonusLoadAction {
  type: typeof Types.FAILURE_BONUS_LOAD;
  message: string;
}

type BonusLoadAction =
  | IRequestBonusLoadAction
  | ISuccessBonusLoadAction
  | IFailureBonusLoadAction;

//ADD
export interface IRequestBonusAddAction {
  type: typeof Types.REQUEST_BONUS_ADD;
  bonus: Partial<BonusGenerateType>;
}

export interface ISuccessBonusAddAction {
  type: typeof Types.SUCCESS_BONUS_ADD;
  payload: BonusGenerateType;
}

interface IFailureBonusAddAction {
  type: typeof Types.FAILURE_BONUS_ADD;
  message: string;
}

type BonusAddAction =
  | IRequestBonusAddAction
  | ISuccessBonusAddAction
  | IFailureBonusAddAction;

//DEL
export interface IRequestBonusDelAction {
  type: typeof Types.REQUEST_BONUS_DEL;
  idBonus: number;
}

export interface ISuccessBonusDelAction {
  type: typeof Types.SUCCESS_BONUS_DEL;
  idBonus: number;
}

interface IFailureBonusDelAction {
  type: typeof Types.FAILURE_BONUS_DEL;
  message: string;
}

type BonusDelAction =
  | IRequestBonusDelAction
  | ISuccessBonusDelAction
  | IFailureBonusDelAction;

export type BonusActionTypes =
  | BonusLoadAction
  | BonusAddAction
  | BonusDelAction;
