import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { Formik } from "formik";
import * as yup from 'yup';

import {
  DetailsListLayoutMode,
  SelectionMode,
  Selection,
  IColumn,
} from 'office-ui-fabric-react/lib/DetailsList';
import { ShimmeredDetailsList } from 'office-ui-fabric-react/lib/ShimmeredDetailsList';
import { CommandBarButton, DefaultButton, IButtonStyles, ICommandBarStyles, IconButton, IDialogContentProps } from 'office-ui-fabric-react';

import { WrapperAutoComplete } from '../styles';

import NoItems from '~/components/layout/NoItems';
import { Stack } from 'office-ui-fabric-react';
import Panel from '~/components/layout/Panel';
import { DeleteButton, PrimaryButton } from '~/components/Buttons';
import { InputAutocomplete, InputDate } from '~/components/Forms';

import { ChartType, DataTypes as DataTypesChart } from '~/store/ducks/admin/chart/types';
import { Creators as getChart } from '~/store/ducks/admin/chart';

import { Creators as loadEmployeeArea } from '~/store/ducks/employees';
import { Creators as addEmployeeArea } from '~/store/ducks/employees';
import { Creators as delEmployeeArea } from '~/store/ducks/employees';
import { EmployeeAreaType, EmployeeSelected } from '~/store/ducks/employees/types';
import { RootState } from "~/store/ducks";
import CustomDialog from '~/components/CustomDialogFluentUI';
import Dialog from '~/components/CustomDialog';
import TreeView from '~/components/TreeView';

interface IEmployeeAreasState {
  columns: IColumn[];
  items: EmployeeAreaType[];
  selectionDetails: string;
  selectionCount: number;
  isPanelOpen: boolean;
  areaSelected: Partial<ChartType> | null;
  itemAreaTreeViewSelected: Partial<ChartType> | null;
  isDialogExcludeOpen: boolean;
  isDialogTreeViewOpen: boolean;
}

interface IEmployeeAreasProps {
  employee: EmployeeSelected;
  chartState: DataTypesChart;
  idFuncionario: number;
  loadEmployeeArea: (idFuncionario: number) => void;
  addEmployeeArea: (idFuncionario: number, area: EmployeeAreaType) => void;
  delEmployeeArea: (idFuncionario: number, idDataHist: number) => void;
  getChart: (search?: string, filter?: boolean | null, treeview?: boolean) => void;
}

const initialValues: EmployeeAreaType = {
  dataHist: null,
  idArea: null,
  area: {
    nomeLocal: ''
  }
}

class EmployeeAreas extends Component<IEmployeeAreasProps, IEmployeeAreasState> {
  private _selection: Selection;
  private formRef: any;
  private timeout: number;

  constructor(props: IEmployeeAreasProps) {
    super(props)

    const columns: IColumn[] = [
      {
        key: 'column1',
        name: 'Data',
        fieldName: 'dataHist',
        minWidth: 75,
        maxWidth: 75,
        isRowHeader: true,
        isResizable: true,
        // isSortedDescending: false,
        // sortAscendingAriaLabel: 'Sorted A to Z',
        // sortDescendingAriaLabel: 'Sorted Z to A',
        // onColumnClick: this._onColumnClick,
        data: 'data',
        isPadded: true,
        onRender: (item: EmployeeAreaType) => moment(item.dataHist).format('DD/MM/YYYY')
      },
      {
        key: 'column2',
        name: 'ID',
        ariaLabel: 'Código id hist área',
        fieldName: 'idArea',
        isRowHeader: true,
        minWidth: 50,
        maxWidth: 50,
        isResizable: true,
        // isSortedDescending: false,
        // onColumnClick: this._onColumnClick
      },
      {
        key: 'column3',
        name: 'Área',
        fieldName: 'area',
        minWidth: 210,
        isRowHeader: true,
        isResizable: true,
        // isSortedDescending: false,
        // sortAscendingAriaLabel: 'Sorted A to Z',
        // sortDescendingAriaLabel: 'Sorted Z to A',
        // onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
        onRender: (item: EmployeeAreaType) => item.area.nomeLocal
      },


    ];

    this.state = {
      columns,
      items: [],
      selectionCount: 0,
      selectionDetails: '',
      isPanelOpen: false,
      areaSelected: null,
      itemAreaTreeViewSelected: null,
      isDialogExcludeOpen: false,
      isDialogTreeViewOpen: false
    }

    this._selection = new Selection({
      onSelectionChanged: () => {
        this.setState({
          selectionDetails: this._getSelectionDetails(),
        });
      },
    });

    this.formRef = React.createRef();
    this.timeout = 0;
  }

  componentDidMount() {
    const { loadEmployeeArea, idFuncionario, employee } = this.props;
    if (employee.areas?.length === 0 || employee.areas === null) {
      loadEmployeeArea(idFuncionario);
    }
  }

  componentDidUpdate(prevProps: IEmployeeAreasProps) {
    if (prevProps.employee.success !== this.props.employee.success) {
      if (this.props.employee.success) {
        this.setState({ isPanelOpen: false })
      }
    }
  }

  _getSelectionDetails(): any {
    const selectionCount = this._selection.getSelectedCount();
    this.setState({ selectionCount });
    this._selection.getSelection();
  };

  cancelPanel = () => {
    this._selection.setAllSelected(false);
    this.setState({ isPanelOpen: false });
  };

  handleSubmit = () => {
    if (this.formRef.current) {
      this.formRef.current.handleSubmit();
    };
  };


  search = (text: string) => {
    if (text.trim()) {
      clearTimeout(this.timeout);
      this.timeout = window.setTimeout(() => {
        this.props.getChart(text, true, false)
      }, 700);
    };
  }

  excludeArea = () => {
    const { idFuncionario } = this.props;
    const idHistArea = (this._selection.getSelection()[0] as EmployeeAreaType).idHistArea!
    this.props.delEmployeeArea(idFuncionario, idHistArea);
    this.setState({ isDialogExcludeOpen: false });
    this._selection.setAllSelected(false);
  }

  render() {
    const { columns, isPanelOpen, areaSelected, isDialogExcludeOpen, isDialogTreeViewOpen, itemAreaTreeViewSelected } = this.state;
    const { employee, loadEmployeeArea, idFuncionario, chartState, addEmployeeArea } = this.props;
    const { areas, loadingItems, loadingAction } = employee;
    return (
      <>
        <Stack horizontal horizontalAlign="space-between" styles={{ root: { width: '100%' } }}>
          <Stack horizontal verticalAlign="center">
            <CommandBarButton styles={btnStyle} iconProps={{ iconName: 'Add' }} text="Adicionar Área" onClick={() => this.setState({ isPanelOpen: true, areaSelected: null })} />
            {
              this._selection.getSelectedCount() > 0 &&
              <CommandBarButton styles={btnStyle} iconProps={{ iconName: 'Delete' }} text="Excluir" onClick={() => this.setState({ isDialogExcludeOpen: true })} />
            }
          </Stack>
          <Stack>
            <CommandBarButton styles={btnStyle} iconProps={{ iconName: 'Refresh' }} text="Atualizar" onClick={() => loadEmployeeArea(idFuncionario)} />
          </Stack>
        </Stack>
        {
          areas?.length === 0 ?
            <NoItems
              text="Não há histórico de áreas"
              icon="Org"
            />
            :
            <ShimmeredDetailsList
              items={areas ?? []}
              enableShimmer={loadingItems}
              columns={columns}
              selectionMode={SelectionMode.single}
              selection={this._selection}
              getKey={this._getKey}
              selectionPreservedOnEmptyClick={true}
              setKey="single"
              layoutMode={DetailsListLayoutMode.justified}
              isHeaderVisible={true}
              styles={{ root: { paddingTop: 0 } }}
            />
        }

        <Panel
          title={"Nova Área"}
          open={isPanelOpen}
          onClose={() => this.cancelPanel()}
          footer={
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <DefaultButton onClick={() => this.cancelPanel()}>Cancelar</DefaultButton>
              <PrimaryButton onClick={this.handleSubmit} isLoading={loadingAction} text="Salvar" />
            </Stack>
          }
        >
          <Formik
            innerRef={this.formRef}
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnChange={false}
            validateOnBlur={true}
            enableReinitialize
            onSubmit={(values) => {
              values.area!.nomeLocal = areaSelected?.nomeLocal ?? '';
              addEmployeeArea(idFuncionario, values)
            }}
          >
            {({ handleSubmit, handleChange, values, errors, setFieldError, setFieldValue }) => (
              <form onSubmit={handleSubmit}>
                <InputDate
                  label="Data"
                  name='dataHist'
                  value={values.dataHist ? moment(values.dataHist) : null}
                  onChange={value => {
                    setFieldValue('dataHist', value)
                    setFieldError('dataHist', '')
                  }}
                  className="mt-2"
                  error={!!errors.dataHist}
                  helperText={errors.dataHist}
                  autoFocus
                />
                <WrapperAutoComplete>
                  <InputAutocomplete
                    value={(areaSelected as ChartType)}
                    onChange={(_, newValue) => {
                      this.setState({ areaSelected: newValue })
                      setFieldValue("idArea", newValue?.idArea);
                    }}
                    onInputChange={(_, newInputValue) => {
                      setFieldError("idArea", "");
                      this.search(newInputValue);
                    }}
                    getOptionLabel={(area: ChartType) => `${area.idArea} - ${area.nomeLocal}`}
                    getOptionSelected={(option, value) => option.idArea === value.idArea}
                    options={chartState.data}
                    input={{
                      idInput: "area",
                      labelInput: "Área",
                      helperTextInput: errors.idArea,
                      errorInput: !!errors.idArea
                    }}
                  />
                  <IconButton
                    iconProps={{ iconName: "Org" }}
                    styles={IconButtonStyle}
                    onClick={() => {
                      this.props.getChart('', true, true);
                      this.setState({ isDialogTreeViewOpen: true })
                    }}
                  />
                </WrapperAutoComplete>

                <Dialog
                  open={isDialogTreeViewOpen}
                  title="Escolher área"
                  width={850}
                  btnConfirmText="Selecionar"
                  onClickCancel={() => this.setState({ isDialogTreeViewOpen: false })}
                  onClickConfirm={() => {
                    setFieldValue("idArea", itemAreaTreeViewSelected?.idArea);
                    setFieldError("idArea", '')
                    this.setState({ isDialogTreeViewOpen: false, areaSelected: itemAreaTreeViewSelected })
                  }}
                  disabledBtnConfirm={itemAreaTreeViewSelected === null}
                  height={500}
                >
                  <TreeView
                    columns={[{
                      name: 'Área',
                      fieldName: 'nomeLocal'
                    }]}
                    selectionMode="single"
                    state={chartState}
                    fieldId="idArea"
                    changeItemsSelected={items => this.setState({ itemAreaTreeViewSelected: items[0] })}
                  />
                </Dialog>
              </form>
            )}
          </Formik>
        </Panel>

        <CustomDialog
          hidden={!isDialogExcludeOpen}
          onDismiss={() => this.setState({ isDialogExcludeOpen: false })}
          dialogContentProps={dialogContentProps}
        >
          <DefaultButton onClick={() => this.setState({ isDialogExcludeOpen: false })} text="Cancelar" />
          <DeleteButton onClick={() => this.excludeArea()} text="Excluir" />
        </CustomDialog>
      </>
    )
  }

  private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    const { columns } = this.state;
    const newColumns: IColumn[] = columns.slice();
    let items: EmployeeAreaType[] = this.props.employee.areas ?? [];
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      };
    });
    const newItems = this._sort(items, currColumn.fieldName!, currColumn.isSortedDescending);
    this.setState({
      columns: newColumns,
      items: newItems,
    });
  };

  private _getKey(item: any, index?: number): any {
    if (item !== undefined)
      return item.key;
  };

  private _sort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
    const key = columnKey as keyof T;
    const itemsSorted = items.sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
    return itemsSorted;
  };
}

const validationSchema = yup.object().shape({
  dataHist: yup.date().nullable().typeError('Data inválida').required("Campo obrigatório"),
  idArea: yup.number().nullable().required("Campo obrigatório")
});

const dialogContentProps: IDialogContentProps = {
  title: 'Excluir?',
  closeButtonAriaLabel: 'Close',
  subText: 'Tem certeza de que deseja excluir este item?'
};

const btnStyle: Partial<ICommandBarStyles> = {
  root: {
    height: 44
  }
}

const IconButtonStyle: Partial<IButtonStyles> = {
  root: {
    position: 'absolute',
    top: 20,
    right: 8,
    width: 24,
    height: 24,
    padding: 8
  },
  icon: {
    fontSize: 12,
  }
};

const mapStateToProps = (state: RootState) => ({
  employee: state.employeesReducer.itemSelected,
  chartState: state.chartReducer
});

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  ...loadEmployeeArea,
  ...getChart,
  ...addEmployeeArea,
  ...delEmployeeArea,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeAreas);
