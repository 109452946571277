import React from 'react';

import { FontIcon, Icon, Stack, Text } from 'office-ui-fabric-react';

import AccordionDetails from '@material-ui/core/AccordionDetails';
import { Dropdown as DropdownUI, IDropdownOption } from 'office-ui-fabric-react/lib/Dropdown';

import { Table, Gap, Tbody, TdAvaliacao, textStyles, TdResultAtitudes, WrapperForm } from '../allStyles';
import Colors from '~/assets/js/colors';

import { AbaixoExpectativa, AcimaExpectativa, DentroExpectativa, onRenderTitle, Accordion, AccordionSummary } from '../resultsCompontent';
import { InputText } from '~/components/Forms';
import { TabPerfomanceType } from '~/store/ducks/tasks/types';

interface IPerformanceProps {
    idTabPerformance: number | null;
    tabPerformance: TabPerfomanceType[];
    readonly: boolean;
    potencial: string | null;
    sucessao: string | null;
    titleAccordion?: string;
}

const Performance: React.FC<IPerformanceProps> = (props) => {
    const { tabPerformance, idTabPerformance, readonly, potencial, sucessao, titleAccordion } = props;

    const onRenderTitltePerformance = (options: IDropdownOption[]): JSX.Element => {
        const option = options[0];

        return (
            <Stack horizontal verticalAlign="center" horizontalAlign="center" styles={{ root: { width: '100%' } }}>
                {option.data && !option?.data?.styleOption &&
                    <Icon iconName="CircleShapeSolid" aria-hidden="true" styles={{ root: { ...option.data.style, marginRight: 10, fontSize: 16 } }} />
                }
                <Stack horizontalAlign="center">
                    <span style={{ fontSize: 12, whiteSpace: 'break-spaces', lineHeight: 1.5, textAlign: 'left', ...option?.data?.styleOption }}>{option.text}</span>
                </Stack>
            </Stack>
        );
    };


    const onRenderTitlePotencial = (options: IDropdownOption[]): JSX.Element => {
        const option = options[0];

        return (
            <Stack horizontal verticalAlign="center" horizontalAlign="center" styles={{ root: { width: '100%' } }}>
                <Stack horizontalAlign="center">
                    <span style={{ fontSize: 12, whiteSpace: 'break-spaces', lineHeight: 1.5, textAlign: 'left', ...option?.data?.styleOption }}>{option.text}</span>
                </Stack>
            </Stack>
        );
    };



    return (
        <Accordion defaultExpanded={true}>
            <AccordionSummary
                expandIcon={<FontIcon iconName="ChevronDown" style={{ fontSize: 16 }} />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
            >
                <Text variant="mediumPlus" styles={textStyles}>{titleAccordion ? titleAccordion : 'Avaliação do Gestor'}</Text>
            </AccordionSummary>
            <AccordionDetails>
                <Stack styles={{ root: { width: '100%' } }}>
                    <Table>
                        <Tbody>
                            <tr>
                                <Gap style={{ minWidth: 370, maxWidth: 370 }} />
                                <td style={{ width: 160, minWidth: 160 }}>
                                    <Text variant="small">PERFORMANCE</Text>
                                </td>
                                <TdResultAtitudes style={{ width: 420, minWidth: 420, borderWidth: 0 }}>
                                    <DropdownUI
                                        placeholder="Selecione uma opção"
                                        options={[
                                            { key: -1, text: 'Selecione uma opção', disabled: true, hidden: true, data: { styleOption: { fontSize: 14 } } },
                                            ...tabPerformance.map(item => ({
                                                key: item.idTabPerformance,
                                                text: item.descNivel.toUpperCase(),
                                                data: { style: { color: item.cor } }
                                            }))
                                        ]}
                                        // defaultSelectedKey={avaliacao?.idTabPerformanceGestor ?? -1}
                                        selectedKey={idTabPerformance ?? -1}
                                        // selectedKey={valuesGestor.idTabPerformanceGestor ?? -1}
                                        onRenderTitle={onRenderTitltePerformance as any}
                                        disabled={readonly}
                                        // disabled={!avaliacao?.habilitaAvaliacaoGestor}
                                        onChange={(e, item) => {
                                            // setFieldValueGestor(`idTabPerformanceGestor`, item?.key)
                                            // setFieldErrorGestor(`idTabPerformanceGestor`, '')
                                        }}
                                        styles={{
                                            title: {
                                                height: 60,
                                                display: 'flex',
                                                alignItems: 'center',
                                                borderColor: '#CCCCCC'
                                                // borderColor: errorsGestor.idTabPerformanceGestor ? Colors.error : '#CCCCCC'
                                            },
                                            caretDownWrapper: {
                                                top: 16
                                            }
                                        }}
                                    />
                                </TdResultAtitudes>
                            </tr>
                            <tr>
                                <Gap style={{ minWidth: 370, maxWidth: 370 }} />
                                <td style={{ width: 160, minWidth: 160 }}>
                                    <Text variant="small">POTENCIAL</Text>
                                </td>
                                <TdResultAtitudes style={{ width: 420, minWidth: 420, borderWidth: 0 }}>
                                    <DropdownUI
                                        options={optionsPotencial}
                                        defaultSelectedKey={potencial ?? 'Selecione uma opção'}
                                        selectedKey={potencial ?? 'Selecione uma opção'}
                                        onRenderTitle={onRenderTitlePotencial as any}
                                        disabled={readonly}
                                        // onChange={(e, item) => {
                                        //     if (item?.key === 'Potencial lateral' || item?.key === 'Cedo demais para avaliar') {
                                        //         setFieldValueGestor(`sucessao`, "Null")
                                        //     } else if (valuesGestor.sucessao === "Null") {
                                        //         setFieldValueGestor('sucessao', "Selecione uma opção")
                                        //     }
                                        //     setFieldValueGestor(`potencial`, item?.key)
                                        //     setFieldErrorGestor(`potencial`, '')
                                        // }}
                                        styles={{
                                            title: {
                                                height: 60,
                                                display: 'flex',
                                                alignItems: 'center',
                                                borderColor: '#CCCCCC'
                                                // borderColor: errorsGestor.potencial ? Colors.error : '#CCCCCC'
                                            },
                                            caretDownWrapper: {
                                                top: 16
                                            }
                                        }}
                                    />
                                </TdResultAtitudes>
                            </tr>
                            <tr>
                                <Gap style={{ minWidth: 370, maxWidth: 370 }} />
                                <td style={{ width: 160, minWidth: 160 }}>
                                    <Text variant="small">SUCESSÃO</Text>
                                </td>
                                <TdResultAtitudes style={{ width: 420, minWidth: 420, borderWidth: 0 }}>
                                    <DropdownUI
                                        placeholder="Selecione uma opção"
                                        options={optionsSucessao}
                                        // defaultSelectedKey={sucessao ?? 'Selecione uma opção'}
                                        selectedKey={sucessao !== null ? sucessao : (potencial === 'Potencial lateral' || potencial === 'Cedo demais para avaliar') ? "Null" : 'Selecione uma opção'}
                                        onRenderTitle={onRenderTitlePotencial as any}
                                        disabled={readonly}
                                        // disabled={!avaliacao?.habilitaAvaliacaoGestor || valuesGestor.potencial === 'Potencial lateral' || valuesGestor.potencial === 'Cedo demais para avaliar'}
                                        // onChange={(e, item) => {
                                        //     setFieldValueGestor(`sucessao`, item?.key)
                                        //     setFieldErrorGestor(`sucessao`, '')
                                        // }}
                                        styles={{
                                            title: {
                                                height: 60,
                                                display: 'flex',
                                                alignItems: 'center',
                                                borderColor: '#CCCCCC'
                                                // borderColor: errorsGestor.sucessao ? Colors.error : '#CCCCCC'
                                            },
                                            caretDownWrapper: {
                                                top: 16
                                            }
                                        }}
                                    />
                                </TdResultAtitudes>
                            </tr>

                        </Tbody>
                    </Table>
                    <WrapperForm>
                        {
                            props.children
                        }
                        {/* <InputText
                            label="Faça aqui os comentários para a calibração"
                            multiline
                            rows={3}
                            disabled={!avaliacao?.habilitaAvaliacaoGestor}
                            value={valuesGestor.comentariosGestor}

                            onChange={e => {
                                setFieldValueGestor('comentariosGestor', e.target.value)
                                setFieldErrorGestor('comentariosGestor', '')
                            }}
                            error={errorsGestor.comentariosGestor ? true : false}
                            helperText={errorsGestor.comentariosGestor}
                        /> */}
                    </WrapperForm>
                </Stack>
            </AccordionDetails>
        </Accordion>
    );
}

const optionsPotencial: IDropdownOption[] = [
    { key: "Selecione uma opção", text: 'Selecione uma opção', disabled: true, hidden: true, data: { styleOption: { fontSize: 14 } } },
    { key: "Alto potencial para crescer", text: 'ALTO POTENCIAL PARA CRESCER', data: { style: { color: "#027636" } } },
    { key: "Potencial para crescer", text: 'POTENCIAL PARA CRESCER', data: { style: { color: "#88C8A5" } } },
    { key: "Potencial lateral", text: 'POTENCIAL LATERAL', data: { style: { color: "#D4A31D" } } },
    { key: "Cedo demais para avaliar", text: 'CEDO DEMAIS PARA AVALIAR', data: { style: { color: "#73AF8E" } } },
]


const optionsSucessao: IDropdownOption[] = [
    { key: "Selecione uma opção", text: 'Selecione uma opção', disabled: true, hidden: true, data: { styleOption: { fontSize: 14 } } },
    { key: "Null", text: '', disabled: true, hidden: true, data: { styleOption: { fontSize: 14 } } },
    { key: 'Imediato', text: 'IMEDIATO', data: { style: { color: "#027636" } } },
    { key: "Curto Prazo - 1 a 2 anos", text: 'CURTO PRAZO - 1 A 2 ANOS', data: { style: { color: "#73AF8E" } } },
    { key: "Médio Prazo - 3 a 5 anos", text: 'MÉDIO PRAZO - 3 A 5 ANOS', data: { style: { color: "#88C8A5" } } },
    { key: "Longo Prazo - acima 5 anos", text: 'LONGO PRAZO - ACIMA 5 ANOS', data: { style: { color: "#D4A31D" } } },
]


export default Performance;