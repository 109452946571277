import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { Formik } from "formik";
import { withRouter, RouteComponentProps } from "react-router-dom";
 
import * as yup from "yup";
import moment from "moment";

import {
  Wrapper,
  DefaultButton,
  ListContainer,
  ContainerContent,
} from "./styles";

//FluentUI
import { Text } from "office-ui-fabric-react/lib/Text";
import { Stack } from "office-ui-fabric-react/lib/Stack";
import {
  DetailsListLayoutMode,
  SelectionMode,
  Selection,
  IColumn,
} from "office-ui-fabric-react/lib/DetailsList";
import { ShimmeredDetailsList } from "office-ui-fabric-react/lib/ShimmeredDetailsList";
import { CommandBarButton, ICommandBarStyles } from "office-ui-fabric-react";
import CustomDialog from "~/components/CustomDialogFluentUI";
import { Spinner, SpinnerSize } from "office-ui-fabric-react/lib/Spinner";
import { Pivot, PivotItem } from "office-ui-fabric-react/lib/Pivot";
import { Link as LinkUI, ILinkStyles } from "office-ui-fabric-react/lib/Link";
import { InputText, InputDate } from "~/components/Forms";
import HeaderPage from "~/components/layout/HeaderPage";
import NoItems from "~/components/layout/NoItems";
import { DeleteButton } from "~/components/Buttons";
import {
  TitleField,
  GroupItems,
  GroupItemUmTerco,
  GroupItemDoisTerco,
} from "~/components/FormGroup";
import Autocomplete from "@material-ui/lab/Autocomplete";
//MaterialUI
//Componentes
import { PrimaryButton } from "~/components/Buttons";
import PanelDisplay from "~/components/PanelDisplay";
import Panel from "~/components/layout/Panel";
import { CycleType, DataTypes } from "~/store/ducks/cycles/types";
import {
  DataTypes as DataTypesEmployees,
  EmployeeType,
} from "~/store/ducks/employees/types";
import { RootState } from "~/store/ducks";
import { Creators as getEmployees } from "~/store/ducks/employees";

import {
  Creators as getCycles,
  Creators as getCycleById,
} from "~/store/ducks/cycles";
import { Creators as addCycle } from "~/store/ducks/cycles";
import { Creators as delCycle } from "~/store/ducks/cycles";
import { Creators as editCycle } from "~/store/ducks/cycles";
import { Creators as updateOrg } from "~/store/ducks/cycles";
import { Creators as setCurrentPage } from "~/store/ducks/home";
import { Page, BreadcrumbItems } from "~/store/ducks/home/types";
import {
  ChartType,
  DataTypes as DataTypesChart,
} from "~/store/ducks/admin/chart/types";
import {
  ObjectivesType,
  DataTypes as DataTypesObjectives,
} from "~/store/ducks/objectives/types";
import { Creators as getObjectives } from "~/store/ducks/objectives";
import { Creators as getChart } from "~/store/ducks/admin/chart";
import { DataTypes as DataTypesLogin } from "~/store/ducks/login/types";

import FormInfo from "./Forms/FormInfo";
import FormRegras from "./Forms/FormRegras";
import FormIniciativa from "./Forms/FormIniciativa";
import FormGeracao from "./Forms/FormGeracao";

import Atitudes from "./Attitudes";
import Performance from "./Perfomance";

const btnStyle: Partial<ICommandBarStyles> = {
  root: {
    height: 44,
  },
};

enum TypeContentPanel {
  info = "INFO",
  cortes = "CORTES",
  iniciativas = "INICIATIVAS",
  geracao = "GERACAO",
}

interface ICycleState {
  columns: IColumn[];
  items: CycleType[];
  panelContent: TypeContentPanel | null;
  isPanelOpen: boolean;
  isPanelEditOpen: boolean;
  isPanelEditDetailsOpen: boolean;
  selectionDetails: string;
  inicialValues: CycleType;
  selectionCount: number;
  isFiltering: boolean;
  isDialogExcludeOpen: boolean;
  employee: Partial<EmployeeType>;
  chart: Partial<ChartType>;
  objetivo: Partial<ObjectivesType>;
  itemSelected: CycleType;
}

const validationCyclesSchema = yup.object().shape({
  ano: yup.number().nullable().required("Campo obrigatório"),
  idAreaPai: yup.number().nullable().required("Campo obrigatório"),
  prazoPlanejamento: yup.date().nullable().required("Campo obrigatório"),
  responsavel: yup.object().shape({
    idFuncionario: yup.number().nullable().required("Campo obrigatório"),
  }),
});

const inicialValueCycle: CycleType = {
  idCiclo: null,
  idAreaPai: null,
  ano: null,
  status: "",
  responsavel: {
    idFuncionario: null,
    nomeCompleto: "",
  },
  area: {
    nomeLocal: "",
  },
  idObjetivoIniciativa: null,
  objetivo: {
    descObjetivo: "",
  },
  prazoPlanejamento: null,
  dataPublicacao: null,
  minimoIniciativas: 0,
  targetIniciativas: 0,
  maximoIniciativas: 0,
  descProjeto: "",
  gerarProjeto: false,
  limiteAdm: null,
  mesesTrabalhados: null,
  nomeProjeto: "",
  dataHoraAtuOrg: null,
};

const itemsBreadCrumb: BreadcrumbItems[] = [
  {
    text: "Home",
    isCurrentItem: false,
    icon: "HomeSolid",
    onlyIcon: true,
    url: "/",
  },
  { text: "Ciclos", isCurrentItem: true },
];

const page: Page = {
  key: "ciclos",
  pages: itemsBreadCrumb,
};

interface ICyclesProps extends RouteComponentProps {
  cycles: DataTypes;
  employees: DataTypesEmployees;
  charts: DataTypesChart;
  objectives: DataTypesObjectives;
  login: DataTypesLogin;
  setCurrentPage: (page: Page) => void;
  getCycles: (year?: string) => void;
  getCycleById: (id: number) => void;
  addCycle: (cycle: CycleType) => void;
  editCycle: (cycle: Partial<CycleType>) => void;
  delCycle: (id: number) => void;
  getEmployees: (search?: string, filter?: boolean | null) => void;
  getChart: (
    search?: string,
    filter?: boolean | null,
    treeview?: boolean
  ) => void;
  getObjectives: (
    search?: string,
    treeview?: boolean,
    filterAtivo?: boolean,
    filterPai?: number
  ) => void;
  updateOrg: (idCiclo: number) => void;
  history: RouteComponentProps["history"];
}

class Cycles extends Component<ICyclesProps, ICycleState> {
  private formRefInfo: any;
  private formRefRegras: any;
  private formRefIniciativas: any;
  private formRefGeracao: any;

  private formRef: any;
  private _selection: Selection;
  private timeout: number;

  constructor(props: ICyclesProps) {
    super(props);

    const columns: IColumn[] = [
      {
        key: "column1",
        name: "Ano",
        ariaLabel: "Ano",
        fieldName: "ano",
        isRowHeader: true,
        minWidth: 75,
        maxWidth: 100,
        isSortedDescending: false,
        onColumnClick: this._onColumnClick,
      },
      {
        key: "column2",
        name: "Área Principal",
        fieldName: "area",
        minWidth: 150,
        maxWidth: 210,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "string",
        isPadded: true,
        onRender: (item: CycleType) => {
          return item.area ? item.area.nomeLocal : "";
        },
      },
      {
        key: "column3",
        name: "Status",
        fieldName: "status",
        minWidth: 150,
        maxWidth: 210,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "string",
        isPadded: true,
        onRender: (item: CycleType) => formattedStatus(item.status),
      },
    ];

    this.state = {
      columns: columns,
      items: [],
      panelContent: null,
      isPanelOpen: false,
      isDialogExcludeOpen: false,
      isPanelEditOpen: false,
      isPanelEditDetailsOpen: false,
      selectionDetails: "",
      isFiltering: false,
      inicialValues: inicialValueCycle,
      selectionCount: 0,
      employee: {
        idFuncionario: null,
        nomeCompleto: "",
      },
      chart: {
        idArea: null,
        nomeLocal: "",
      },
      objetivo: {
        idObjetivo: null,
        descObjetivo: "",
      },
      itemSelected: inicialValueCycle,
    };
    this.formRef = createRef();
    this.timeout = 0;

    this.formRefInfo = createRef();
    this.formRefRegras = createRef();
    this.formRefIniciativas = createRef();
    this.formRefGeracao = createRef();

    this._selection = new Selection({
      onSelectionChanged: () => {
        this.setState({
          selectionDetails: this._getSelectionDetails(),
        });
      },
    });
  }

  componentDidMount() {
    this.props.setCurrentPage(page);
    this.props.getCycles();
  }

  componentDidUpdate(prevProps: ICyclesProps, prevState: ICycleState) {
    const { itemSelected: prevItem, success: prevSuccess } = prevProps.cycles;
    const { itemSelected: nextItem, success: nextSuccess } = this.props.cycles;

    if (prevItem.item !== nextItem.item) {
      if (nextItem.success) {
        const { responsavel, area, idAreaPai, objetivo } = nextItem.item!;
        this.setState({
          inicialValues: {
            ...nextItem.item!,
            area: {
              nomeLocal: area ? area.nomeLocal : "",
            },
            objetivo: {
              descObjetivo: objetivo?.descObjetivo ?? "",
            },
          },
          employee: {
            idFuncionario: responsavel.idFuncionario,
            nomeCompleto: responsavel.nomeCompleto,
          },
          chart: {
            idArea: idAreaPai,
            nomeLocal: area ? area.nomeLocal : "",
          },
          objetivo: {
            descObjetivo: objetivo?.descObjetivo ?? "",
          },
        });
      }
    }

    if (prevSuccess !== nextSuccess) {
      if (nextSuccess) {
        this.setState({ isPanelEditDetailsOpen: false, isPanelOpen: false });
      }
    }

    if (prevItem.error !== nextItem.error) {
      if (nextItem.error) {
        this.setState({ isPanelEditOpen: false });
      }
    }
  }

  _getSelectionDetails(): any {
    const selectionCount = this._selection.getSelectedCount();
    this.setState({ selectionCount });
    this._selection.getSelection();
  }

  handleSave = () => {
    const formRefInfo = this.formRefInfo.current;
    const formRefRegras = this.formRefRegras.current;
    const formRefIniciativas = this.formRefIniciativas.current;
    const formRefGeracao = this.formRefGeracao.current;

    if (formRefInfo) {
      formRefInfo.handleSubmit();
    }

    if (formRefRegras) {
      formRefRegras.handleSubmit();
    }

    if (formRefIniciativas) {
      formRefIniciativas.handleSubmit();
    }

    if (formRefGeracao) {
      formRefGeracao.handleSubmit();
    }
  };

  // Submit do formulário Formik
  handleSubmit = () => {
    if (this.formRef.current) {
      this.formRef.current.handleSubmit();
    }
  };

  cancelPanel = () => {
    this._selection.setAllSelected(false);
    this.setState({ isPanelOpen: false });
  };

  openAdd = () => {
    this.setState({
      isPanelOpen: true,
      inicialValues: inicialValueCycle,
      employee: {
        idFuncionario: null,
        nomeCompleto: "",
      },
      chart: {
        idArea: null,
        nomeLocal: "",
      },
    });
  };

  openEdit = () => {
    this.setState({ isPanelEditOpen: true });
    const idSelected = (this._selection.getSelection()[0] as CycleType)
      .idCiclo!;
    this.props.getCycleById(idSelected);
  };

  excludeCompanies = () => {
    this.props.delCycle(
      (this._selection.getSelection()[0] as CycleType).idCiclo!
    );
    this.setState({ isDialogExcludeOpen: false });
    this._selection.setAllSelected(false);
  };

  search = (text: string, type: string) => {
    clearTimeout(this.timeout);
    if (text.trim() !== "") {
      this.timeout = window.setTimeout(() => {
        if (type === "responsavel") {
          this.props.getEmployees(text);
        } else if (type === "areaSearch") {
          this.props.getChart(text, true, false);
        } else if (type === "objetivoIniciativa") {
          this.props.getObjectives(text, false, true);
        }
      }, 700);
    }
  };

  filter = (text: any) => {
    // const params = this.state.filter.filteredBy;
    // const cycles = this.props.cycles.data;
    // let items = text ? cycles.filter((i: CycleType) => i.FuncionarioOwner!.NomeFuncionario.toLowerCase().indexOf(text.toLowerCase()) > -1) : cycles;
    // if (params!.length > 0) {
    //     items = items.filter((item: CycleType) => {
    //         for (let i = 0; i < params!.length; i++) {
    //             if (item.status === params![i]) {
    //                 return item;
    //             };
    //         };
    //     });
    // };
    // this.setState({ items, isFiltering: true });
  };

  _onItemInvoked = (item: CycleType) => {
    this.props.history.push({
      pathname: `/ciclos/${
        (this._selection.getSelection()[0] as CycleType).idCiclo
      }`,
    });
  };

  commandBarRender = () => {
    const { selectionCount } = this.state;
    if (selectionCount === 1) {
      return (
        <>
          <CommandBarButton
            styles={btnStyle}
            iconProps={{ iconName: "Edit" }}
            text="Editar"
            onClick={() => this.openEdit()}
          />
          <CommandBarButton
            styles={btnStyle}
            iconProps={{ iconName: "Delete" }}
            text="Excluir"
            onClick={() => this.setState({ isDialogExcludeOpen: true })}
          />
          <Link
            to={`/ciclos/${
              (this._selection.getSelection()[0] as CycleType).idCiclo
            }`}
          >
            <CommandBarButton
              styles={btnStyle}
              iconProps={{ iconName: "Settings" }}
              text="Definições"
            />
          </Link>
        </>
      );
    }
  };

  render() {
    const {
      isPanelOpen,
      inicialValues,
      selectionCount,
      columns,
      isFiltering,
      isDialogExcludeOpen,
      items,
      employee,
      chart,
      isPanelEditDetailsOpen,
      panelContent,
      isPanelEditOpen,
    } = this.state;
    const {
      cycles,
      addCycle,
      editCycle,
      getCycles,
      employees,
      charts,
      login,
      updateOrg,
    } = this.props;
    const { itemSelected } = cycles;

    const dialogContentProps = {
      title: "Excluir?",
      closeButtonAriaLabel: "Close",
      subText:
        selectionCount <= 1
          ? "Tem certeza de que deseja excluir este item?"
          : "Tem certeza de que deseja excluir estes items?",
    };

    return (
      <>
        <Panel
          title={
            !inicialValues.idCiclo
              ? "Novo Ciclo"
              : inicialValues.ano!.toString()
          }
          open={isPanelOpen}
          loading={itemSelected.loading}
          onClose={() => this.cancelPanel()}
          footer={
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <DefaultButton onClick={() => this.cancelPanel()}>
                Cancelar
              </DefaultButton>
              <PrimaryButton
                onClick={this.handleSubmit}
                text="Salvar"
                isLoading={cycles.loadingAction}
              />
            </Stack>
          }
        >
          <Formik
            innerRef={this.formRef}
            initialValues={inicialValueCycle}
            validationSchema={validationCyclesSchema}
            enableReinitialize
            validateOnChange={false}
            validateOnBlur={true}
            onSubmit={(values: any) => {
              values.area.nomeLocal = this.state.chart.nomeLocal;

              if (inicialValues.idCiclo === null) {
                addCycle(values);
              } else {
                values.id = inicialValues.idCiclo;
                editCycle(values);
              }
            }}
          >
            {({
              handleSubmit,
              handleChange,
              values,
              errors,
              setFieldValue,
              setFieldError,
            }) => (
              <form onSubmit={handleSubmit}>
                <InputText
                  value={values.ano !== null ? values.ano : ""}
                  onChange={(e) => {
                    handleChange(e);
                    setFieldError("ano", "");
                  }}
                  id="ano"
                  error={errors.ano ? true : false}
                  name="ano"
                  type="number"
                  label="Ano"
                  helperText={errors.ano}
                  className="mt-2"
                  autoFocus
                />

                <Autocomplete
                  value={employee as EmployeeType}
                  onChange={(_, newValue) => {
                    this.setState({ employee: newValue! });
                    setFieldValue("responsavel", {
                      idFuncionario: newValue?.idFuncionario!,
                    });
                  }}
                  onInputChange={(_, newInputValue) => {
                    setFieldError("responsavel", "");
                    this.search(newInputValue, "responsavel");
                  }}
                  getOptionLabel={(employee: EmployeeType) =>
                    employee.nomeCompleto
                  }
                  getOptionSelected={(option, value) =>
                    option.idFuncionario === value.idFuncionario
                  }
                  id="responsavel"
                  noOptionsText="Não há opções"
                  options={employees.data}
                  renderInput={(params) => (
                    <InputText
                      {...params}
                      label="Funcionário Responsável"
                      helperText={errors.responsavel?.idFuncionario}
                      type="text"
                      error={errors.responsavel?.idFuncionario ? true : false}
                      name="funcionarioResponsavel"
                    />
                  )}
                />

                <Autocomplete
                  value={chart as ChartType}
                  onChange={(_, newValue) => {
                    this.setState({ chart: newValue! });
                    setFieldValue("idAreaPai", newValue?.idArea!);
                  }}
                  onInputChange={(_, newInputValue) => {
                    setFieldError("idAreaPai", "");
                    this.search(newInputValue, "areaSearch");
                  }}
                  getOptionLabel={(chart: ChartType) => chart.nomeLocal}
                  getOptionSelected={(option, value) =>
                    option.idArea === value.idArea
                  }
                  id="idAreaPai"
                  noOptionsText="Não há opções"
                  options={charts.data}
                  renderInput={(params) => (
                    <InputText
                      {...params}
                      label="Área Principal"
                      helperText={errors.idAreaPai}
                      type="text"
                      error={errors.idAreaPai ? true : false}
                      name="idAreaPai"
                    />
                  )}
                />
                <InputDate
                  value={values.prazoPlanejamento}
                  onChange={(value) =>
                    setFieldValue("prazoPlanejamento", value)
                  }
                  onKeyDown={() => setFieldError("prazoPlanejamento", "")}
                  id="prazoPlanejamento"
                  error={errors.prazoPlanejamento ? true : false}
                  name="prazoPlanejamento"
                  label="Prazo do planejamento"
                  helperText={errors.prazoPlanejamento}
                />
              </form>
            )}
          </Formik>
        </Panel>
        <CustomDialog
          hidden={!isDialogExcludeOpen}
          onDismiss={() => this.setState({ isDialogExcludeOpen: false })}
          dialogContentProps={dialogContentProps}
        >
          <DefaultButton
            onClick={() => this.setState({ isDialogExcludeOpen: false })}
            text="Cancelar"
          />
          <DeleteButton
            onClick={() => this.excludeCompanies()}
            text="Excluir"
          />
        </CustomDialog>
        <Wrapper>
          <ContainerContent>
            <HeaderPage
              title="Ciclos"
              leftItems={
                <>
                  <CommandBarButton
                    styles={btnStyle}
                    iconProps={{ iconName: "Add" }}
                    text="Adicionar Ciclo"
                    onClick={this.openAdd}
                    disabled={cycles.loadingData}
                  />
                  {login.data.flagAdministrador && this.commandBarRender()}
                </>
              }
              rightItems={
                <CommandBarButton
                  styles={btnStyle}
                  iconProps={{ iconName: "Refresh" }}
                  text="Atualizar"
                  onClick={() => getCycles()}
                />
              }
            />
            {!cycles.loadingData && cycles.data.length === 0 ? (
              <NoItems
                error={cycles.error}
                text="Não há ciclos cadastrados"
                img="/static/icons/cycle.svg"
                alt="Ciclos"
              />
            ) : (
              <ListContainer>
                <ShimmeredDetailsList
                  items={isFiltering ? items : cycles.data}
                  enableShimmer={cycles.loadingData}
                  columns={columns}
                  selectionMode={SelectionMode.single}
                  selection={this._selection}
                  getKey={this._getKey}
                  selectionPreservedOnEmptyClick={true}
                  setKey="single"
                  layoutMode={DetailsListLayoutMode.justified}
                  isHeaderVisible={true}
                  onItemInvoked={this._onItemInvoked}
                />
              </ListContainer>
            )}
          </ContainerContent>
        </Wrapper>

        <PanelDisplay
          open={isPanelEditOpen}
          onClose={() => this.setState({ isPanelEditOpen: false })}
          isLoading={employees.itemSelected.loading}
          panelHeader={
            <Stack>
              <Text variant="xxLarge" styles={{ root: { fontWeight: "bold" } }}>
                {itemSelected?.item?.ano}
              </Text>
              <Text variant="medium">
                {formattedStatus(itemSelected?.item?.status ?? "")}
              </Text>
            </Stack>
          }
        >
          {itemSelected.loading ? (
            <Spinner
              size={SpinnerSize.medium}
              label="Carregando Ciclo"
              styles={{ root: { height: "100%" } }}
            />
          ) : (
            <Pivot
              aria-label="Basic Pivot Example"
              styles={{ root: { marginBottom: 32 } }}
            >
              <PivotItem headerText="Dados Principais">
                <GroupItems title="Dados Principais" hasMultiItems>
                  <GroupItems>
                    <GroupItemUmTerco>
                      <TitleField text="Ano" />
                      <TitleField
                        text={String(itemSelected.item?.ano) ?? ""}
                        isValue
                      />
                    </GroupItemUmTerco>
                    <GroupItemDoisTerco>
                      <TitleField text="Funcionário Responsável" />
                      <TitleField
                        text={itemSelected.item?.responsavel.nomeCompleto ?? ""}
                        isValue
                      />
                    </GroupItemDoisTerco>
                  </GroupItems>
                  <GroupItems>
                    <GroupItemUmTerco>
                      <TitleField text="Área Principal" />
                      <TitleField
                        text={itemSelected.item?.area.nomeLocal ?? ""}
                        isValue
                      />
                      <LinkUI
                        styles={linkStyles}
                        onClick={() =>
                          this.setState({
                            isPanelEditDetailsOpen: true,
                            panelContent: TypeContentPanel.info,
                          })
                        }
                      >
                        Editar informações principais
                      </LinkUI>
                    </GroupItemUmTerco>
                  </GroupItems>
                </GroupItems>
                <GroupItems title="Regras de Cortes" hasMultiItems>
                  <GroupItems>
                    <GroupItemUmTerco>
                      <TitleField text="Prazo de Planejamento" />
                      <TitleField
                        text={moment(
                          new Date(itemSelected.item?.prazoPlanejamento ?? "")
                        ).format("DD/MM/YYYY")}
                        isValue
                      />
                    </GroupItemUmTerco>
                  </GroupItems>
                  <GroupItems>
                    <GroupItemUmTerco>
                      <TitleField text="Prazo de admitidos" />
                      <TitleField
                        text={
                          itemSelected.item?.limiteAdm
                            ? moment(
                                new Date(itemSelected.item?.limiteAdm)
                              ).format("DD/MM/YYYY")
                            : ""
                        }
                        isValue
                      />
                      <LinkUI
                        styles={linkStyles}
                        onClick={() =>
                          this.setState({
                            isPanelEditDetailsOpen: true,
                            panelContent: TypeContentPanel.cortes,
                          })
                        }
                      >
                        Editar regras de cortes
                      </LinkUI>
                    </GroupItemUmTerco>
                    <GroupItemDoisTerco>
                      <TitleField text="Meses trabalhados" />
                      <TitleField
                        text={
                          itemSelected.item?.mesesTrabalhados
                            ? String(itemSelected.item?.mesesTrabalhados)
                            : ""
                        }
                        isValue
                      />
                    </GroupItemDoisTerco>
                  </GroupItems>
                </GroupItems>
              </PivotItem>
              <PivotItem headerText="Iniciativas">
                <GroupItems title="Metas para Iniciativas" hasMultiItems>
                  <GroupItems>
                    <GroupItemUmTerco>
                      <TitleField text="Objetivo Relacionado" />
                      <TitleField
                        text={itemSelected.item?.objetivo?.descObjetivo ?? ""}
                        isValue
                      />
                    </GroupItemUmTerco>
                  </GroupItems>
                  <GroupItems styles={{ margin: 0 }}>
                    <GroupItemUmTerco>
                      <TitleField text="Mínimo" />
                      <TitleField
                        text={
                          itemSelected.item?.minimoIniciativas
                            ? `${Number(itemSelected.item?.minimoIniciativas)}%`
                            : ""
                        }
                        isValue
                      />
                    </GroupItemUmTerco>
                    <GroupItemUmTerco>
                      <TitleField text="Target" />
                      <TitleField
                        text={
                          itemSelected.item?.targetIniciativas
                            ? `${Number(itemSelected.item?.targetIniciativas)}%`
                            : ""
                        }
                        isValue
                      />
                    </GroupItemUmTerco>
                    <GroupItemUmTerco>
                      <TitleField text="Máximo" />
                      <TitleField
                        text={
                          itemSelected.item?.maximoIniciativas
                            ? `${Number(itemSelected.item?.maximoIniciativas)}%`
                            : ""
                        }
                        isValue
                      />
                    </GroupItemUmTerco>
                  </GroupItems>
                  <LinkUI
                    styles={linkStyles}
                    onClick={() =>
                      this.setState({
                        isPanelEditDetailsOpen: true,
                        panelContent: TypeContentPanel.iniciativas,
                      })
                    }
                  >
                    Editar informações da iniciativa
                  </LinkUI>
                </GroupItems>
                <GroupItems title="Geração automática" hasMultiItems>
                  <GroupItems>
                    <GroupItemDoisTerco>
                      <TitleField text="Gerar iniciativas automaticamente" />
                      <TitleField
                        text={itemSelected.item?.gerarProjeto ? "SIM" : "NÃO"}
                        isValue
                      />
                    </GroupItemDoisTerco>
                  </GroupItems>
                  <GroupItems>
                    <GroupItemDoisTerco>
                      <TitleField text="Nome da Iniciativa" />
                      <TitleField
                        text={itemSelected.item?.nomeProjeto ?? ""}
                        isValue
                      />
                    </GroupItemDoisTerco>
                  </GroupItems>
                  <GroupItems>
                    <GroupItemDoisTerco>
                      <TitleField text="Descrição da iniciativa" />
                      <TitleField
                        text={itemSelected.item?.descProjeto ?? ""}
                        isValue
                      />
                      <LinkUI
                        styles={linkStyles}
                        onClick={() =>
                          this.setState({
                            isPanelEditDetailsOpen: true,
                            panelContent: TypeContentPanel.geracao,
                          })
                        }
                      >
                        Editar geração automática
                      </LinkUI>
                    </GroupItemDoisTerco>
                  </GroupItems>
                </GroupItems>
              </PivotItem>
              <PivotItem headerText="Atitudes">
                <Atitudes idCycle={cycles.itemSelected.item?.idCiclo!} />
              </PivotItem>
              <PivotItem headerText="Performance">
                <Performance idCycle={cycles.itemSelected.item?.idCiclo!} />
              </PivotItem>
              <PivotItem headerText="Organograma">
                <GroupItems
                  title="Data/Hora da Última atualização"
                  hasMultiItems
                >
                  <GroupItemDoisTerco>
                    <TitleField
                      text={
                        cycles.itemSelected.item?.dataHoraAtuOrg
                          ? moment(
                              cycles.itemSelected.item?.dataHoraAtuOrg
                            ).format("DD/MM/YYYY")
                          : ""
                      }
                      isValue
                    />
                  </GroupItemDoisTerco>
                </GroupItems>
                <Stack>
                  <Text>
                    A rotina de ATUALIZAR busca as modificações realizadas no
                    organograma para o desmembramento dentro do ciclo.
                  </Text>
                  <PrimaryButton
                    text="Atualizar"
                    isLoading={cycles.loadingAction}
                    styles={{
                      root: { marginTop: "16px !important", maxWidth: 120 },
                    }}
                    onClick={() =>
                      updateOrg(cycles.itemSelected.item?.idCiclo!)
                    }
                  />
                </Stack>
              </PivotItem>
            </Pivot>
          )}
        </PanelDisplay>

        <PanelDisplay
          open={isPanelEditDetailsOpen}
          onClose={() => this.setState({ isPanelEditDetailsOpen: false })}
          isLoading={false}
          footer={
            <>
              <DefaultButton
                styles={{ root: { marginRight: 10 } }}
                onClick={() => this.setState({ isPanelEditDetailsOpen: false })}
              >
                Cancelar
              </DefaultButton>
              <PrimaryButton
                onClick={this.handleSave}
                text="Salvar"
                isLoading={cycles.loadingAction}
              />
            </>
          }
        >
          {panelContent === TypeContentPanel.info && (
            <FormInfo
              formRef={this.formRefInfo}
              success={(values) => this.props.editCycle(values!)}
              initialValues={itemSelected.item!}
            />
          )}
          {panelContent === TypeContentPanel.cortes && (
            <FormRegras
              formRef={this.formRefRegras}
              success={(values) => this.props.editCycle(values!)}
              initialValues={itemSelected.item!}
            />
          )}
          {panelContent === TypeContentPanel.iniciativas && (
            <FormIniciativa
              formRef={this.formRefIniciativas}
              success={(values) => this.props.editCycle(values!)}
              initialValues={itemSelected.item!}
            />
          )}
          {panelContent === TypeContentPanel.geracao && (
            <FormGeracao
              formRef={this.formRefGeracao}
              success={(values) => this.props.editCycle(values!)}
              initialValues={itemSelected.item!}
            />
          )}
        </PanelDisplay>
      </>
    );
  }

  private _onColumnClick = (
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn
  ): void => {
    const { columns } = this.state;
    const newColumns: IColumn[] = columns.slice();
    let items: CycleType[] = [];
    if (this.state.isFiltering) {
      items = this.state.items;
    } else {
      items = this.props.cycles.data;
    }
    const currColumn: IColumn = newColumns.filter(
      (currCol) => column.key === currCol.key
    )[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    const newItems = this._sort(
      items,
      currColumn.fieldName!,
      currColumn.isSortedDescending
    );
    this.setState({
      columns: newColumns,
      items: newItems,
    });
  };

  private _getKey(item: any, index?: number): any {
    if (item !== undefined) return item.key;
  }

  private _sort<T>(
    items: T[],
    columnKey: string,
    isSortedDescending?: boolean
  ): T[] {
    const key = columnKey as keyof T;
    const itemsSorted = items.sort((a: T, b: T) =>
      (isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1
    );
    return itemsSorted;
  }
}

const linkStyles: Partial<ILinkStyles> = {
  root: {
    fontSize: 14,
  },
};

const mapStateToProps = (state: RootState) => ({
  cycles: state.cyclesReducer,
  employees: state.employeesReducer,
  objectives: state.objectivesReducer,
  charts: state.chartReducer,
  login: state.loginReducer,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      ...getCycles,
      ...getCycleById,
      ...addCycle,
      ...delCycle,
      ...editCycle,
      ...getEmployees,
      ...setCurrentPage,
      ...getChart,
      ...getObjectives,
      ...updateOrg,
    },
    dispatch
  );

export const formattedStatus = (status: string): string => {
  switch (status) {
    case "P":
      return "Em planejamento";
    case "V":
      return "Em validação";
    case "I":
      return "Inconsistência na validação";
    case "A":
      return "Aguardando publicação";
    case "N":
      return "Em publicação";
    case "E":
      return "Erro na publicação";
    case "D":
      return "Publicado";
    case "F":
      return "Finalizado";
    default:
      return "";
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Cycles));
