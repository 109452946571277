import { takeEvery, put, call, all } from "redux-saga/effects";
import {
  Types,
  IRequestCalibrationLoadByIdAction,
  IRequestCalibrationLoadByIdNivelAction,
  IRequestCalibrationDelAvaliadorAction,
  IRequestCalibrationAtivaAvaliadorAction,
  IRequestCalibrationLoadAvaliadoAction,
  IRequestCalibrationFinalizarAction,
  IRequestCalibrationSaveAvaliadoAction,
  IRequestCalibrationInitAction,
  IRequestCommentAvaliadoAction,
  IRequestCommentAvaliadoAddAction,
  IRequestCalibrationStopAction,
  IRequestCalibrationSucessaoAddAction,
  IRequestCalibrationSucessaoDelAction,
  IRequestCalibrationPesquisaFuncAction,
  IRequestLoadSucessaoAction,
  IRequestCalibrationAvaliadoUpAction,
  IRequestCalibrationAvaliadoDownAction,
  ISuccessCalibrationLoadByIdAction,
  ISuccessCalibrationLoadByIdNivelAction,
  ISuccessCalibrationDelAvaliadorAction,
  ISuccessCalibrationAtivaAvaliadorAction,
  ISuccessCalibrationLoadAvaliadoAction,
  ISuccessCalibrationFinalizarAction,
  ISuccessCalibrationSaveAvaliadoAction,
  ISuccessCalibrationInitAction,
  ISuccessCommentAvaliadoAction,
  ISuccessCommentAvaliadoAddAction,
  ISuccessCalibrationStopAction,
  ISuccessCalibrationSucessaoAddAction,
  ISuccessCalibrationSucessaoDelAction,
  ISuccessCalibrationPesquisaFuncAction,
  ISuccessLoadSucessaoAction,
  ISuccessCalibrationAvaliadoUpAction,
  ISuccessCalibrationAvaliadoDownAction,
  IFailureCalibrationSaveAvaliadoAction,
  IRequestCalibrationForceAction,
  ISuccessCalibrationForceAction,

} from "../ducks/calibration/types";
import api from "~/services/api";


const apiCallGetById = (payload: IRequestCalibrationLoadByIdAction["payload"]) => {
  const { idCicloReuniao } = payload;
  return api.get(`reunioes/${idCicloReuniao}`)
    .then((response) => {
      return response.data;
    }).catch((err) => {
      throw err.response;
    });
};

const apiCallGetByIdNivel = (payload: IRequestCalibrationLoadByIdNivelAction["payload"]) => {
  const { idCicloReuniao, nivel } = payload;
  return api.get(`reunioes/${idCicloReuniao}/${nivel}`)
    .then((response) => {
      return response.data;
    }).catch((err) => {
      throw err.response;
    });
};


const apiCallAtivaAvalidador = (payload: IRequestCalibrationAtivaAvaliadorAction["payload"]) => {
  const { idCicloReuniao, idCicloReuniaoAvaliador, nivel } = payload;
  return api.post(`reunioes/${idCicloReuniao}/${nivel}/avaliador/${idCicloReuniaoAvaliador}`)
    .then((response) => {
      return response.data;
    }).catch((err) => {
      throw err.response;
    });
};

const apiCallDesativaAvalidador = (payload: IRequestCalibrationDelAvaliadorAction["payload"]) => {
  const { idCicloReuniao, idCicloReuniaoAvaliador, nivel } = payload;
  return api.delete(`reunioes/${idCicloReuniao}/${nivel}/avaliador/${idCicloReuniaoAvaliador}`)
    .then((response) => {
      return response.data;
    }).catch((err) => {
      throw err.response;
    });
};


const apiCallLoadAvaliado = (payload: IRequestCalibrationLoadAvaliadoAction["payload"]) => {
  const { idCicloReuniao, idCicloReuniaoAvaliado } = payload;
  return api.get(`reunioes/${idCicloReuniao}/avaliado/${idCicloReuniaoAvaliado}`)
    .then((response) => {
      return response.data;
    }).catch((err) => {
      throw err.response;
    });
};

const apiCallSaveAvaliado = (payload: IRequestCalibrationSaveAvaliadoAction["payload"]) => {
  const { idCicloReuniao, idCicloReuniaoAvaliado, performance } = payload;
  return api.put(`reunioes/${idCicloReuniao}/avaliado/${idCicloReuniaoAvaliado}`, {
    ...performance
  })
    .then((response) => {
      return response.data;
    }).catch((err) => {
      throw err.response;
    });
};


const apiCallAvaliadoUp = (payload: IRequestCalibrationAvaliadoUpAction["payload"]) => {
  return api.put(`reunioes/${payload.idCicloReuniao}/avaliado/${payload.idCicloReuniaoAvaliado}/up`)
    .then((response) => {
      return response.data;
    }).catch((err) => {
      throw err.response;
    });
};

const apiCallAvaliadoDown = (payload: IRequestCalibrationAvaliadoDownAction["payload"]) => {
  return api.put(`reunioes/${payload.idCicloReuniao}/avaliado/${payload.idCicloReuniaoAvaliado}/down`)
    .then((response) => {
      return response.data;
    }).catch((err) => {
      throw err.response;
    });
};

const apiCallCalibrationInit = (payload: IRequestCalibrationInitAction["payload"]) => {
  const { idCicloReuniao, idCicloReuniaoAvaliado } = payload;
  return api.get(`reunioes/${idCicloReuniao}/iniciar/${idCicloReuniaoAvaliado}`)
    .then((response) => {
      return response.data;
    }).catch((err) => {
      throw err.response;
    });
};

const apiCallCalibrationStop = (payload: IRequestCalibrationStopAction["payload"]) => {
  const { idCicloReuniao, idCicloReuniaoAvaliado } = payload;
  return api.get(`reunioes/${idCicloReuniao}/pausar/${idCicloReuniaoAvaliado}`)
    .then((response) => {
      return response.data;
    }).catch((err) => {
      throw err.response;
    });
};

const apiCallFinalizar = (payload: IRequestCalibrationFinalizarAction["payload"]) => {
  return api.post(`reunioes/${payload.idCicloReuniao}/finalizar`, {
    ...payload
  })
    .then((response) => {
      return response.data;
    }).catch((err) => {
      throw err.response;
    });
};

const apiCallLoadComments = (payload: IRequestCommentAvaliadoAction["payload"]) => {
  return api.get(`reunioes/${payload.idCicloReuniao}/comentarios/${payload.idCicloReuniaoAvaliado}`,{
    params:{
      exibir: payload.exibir
    }
  })
    .then((response) => {
      return response.data;
    }).catch((err) => {
      throw err.response;
    });
};

const apiCallAddComments = (payload: IRequestCommentAvaliadoAction["payload"]) => {
  return api.post(`reunioes/${payload.idCicloReuniao}/comentarios/${payload.idCicloReuniaoAvaliado}`, {
    ...payload
  })
    .then((response) => {
      return response.data;
    }).catch((err) => {
      throw err.response;
    });
};

const apiCallAddSucessao = (payload: IRequestCalibrationSucessaoAddAction["payload"]) => {
  return api.post(`reunioes/${payload.idCicloReuniao}/sucessao`, {
    ...payload
  })
    .then((response) => {
      return response.data;
    }).catch((err) => {
      throw err.response;
    });
};

const apiCallDelSucessao = (payload: IRequestCalibrationSucessaoDelAction["payload"]) => {
  return api.delete(`reunioes/${payload.idCicloReuniao}/sucessao/${payload.idCicloReuniaoSucessaoGrupoAvaliado}`)
    .then((response) => {
      return response.data;
    }).catch((err) => {
      throw err.response;
    });
};

const apiCallPesquisaFunc = (payload: IRequestCalibrationPesquisaFuncAction["payload"]) => {
  return api.get(`reunioes/${payload.idCicloReuniao}/pesquisafunc`, {
    params: {
      nomePesq: payload.nomePesq,
      idArea: payload.idArea,
      idChefe: payload.idChefe,
      worklevel: payload.worklevel ?? '',
      flagReuniao: payload.flagReuniao
    }
  })
    .then((response) => {
      return response.data;
    }).catch((err) => {
      throw err.response;
    });
};

const apiCallLoadSucessao = (payload: IRequestLoadSucessaoAction["payload"]) => {
  return api.get(`reunioes/${payload.idCicloReuniao}/sucessao/${payload.nivel}`)
    .then((response) => {
      return response.data;
    }).catch((err) => {
      throw err.response;
    });
};

const apiCallForceCalibration = (payload: IRequestCalibrationForceAction["payload"]) => {
  return api.get(`reunioes/${payload.idCicloReuniao}/${payload.nivel}/forcarCalibracao`)
    .then((response) => {
      return response.data;
    }).catch((err) => {
      throw err.response;
    });
};


function* loadById(action: IRequestCalibrationLoadByIdAction) {
  try {
    let item: any = yield call(apiCallGetById, action.payload);
    yield put({
      type: Types.SUCCESS_CALIBRATION_LOAD_BY_ID,
      payload: item
    } as ISuccessCalibrationLoadByIdAction);
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível carregar a reunião'
    yield put({
      type: Types.FAILURE_CALIBRATION_LOAD_BY_ID,
      message
    });
  }
}

function* loadByIdNivel(action: IRequestCalibrationLoadByIdNivelAction) {
  try {
    let items: any = yield call(apiCallGetByIdNivel, action.payload);
    yield put({
      type: Types.SUCCESS_CALIBRATION_LOAD_BY_ID_NIVEL,
      payload: {
        avaliadores: items.avaliadores,
        avaliados: items.avaliados
      }
    } as ISuccessCalibrationLoadByIdNivelAction);
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível carregar o nivel'
    yield put({
      type: Types.FAILURE_CALIBRATION_LOAD_BY_ID_NIVEL,
      message
    });
  }
}

function* desativaAvaliador(action: IRequestCalibrationDelAvaliadorAction) {
  try {
    yield call(apiCallDesativaAvalidador, action.payload);
    yield put({
      type: Types.SUCCESS_CALIBRATION_DESATIVA_AVALIADOR,
      payload: {
        idCicloReuniaoAvaliador: action.payload.idCicloReuniaoAvaliador
      }
    } as ISuccessCalibrationDelAvaliadorAction);
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível ativar o avaliador'
    yield put({
      type: Types.FAILURE_CALIBRATION_ATIVA_AVALIADOR,
      message
    });
  }
}

function* ativaAvaliador(action: IRequestCalibrationAtivaAvaliadorAction) {
  try {
    yield call(apiCallAtivaAvalidador, action.payload);
    yield put({
      type: Types.SUCCESS_CALIBRATION_ATIVA_AVALIADOR,
      payload: {
        idCicloReuniaoAvaliador: action.payload.idCicloReuniaoAvaliador
      }
    } as ISuccessCalibrationAtivaAvaliadorAction);
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível desativar o avaliador'
    yield put({
      type: Types.FAILURE_CALIBRATION_DESATIVA_AVALIADOR,
      message
    });
  }
}

function* finalizaCalib(action: IRequestCalibrationFinalizarAction) {
  try {
    yield call(apiCallFinalizar, action.payload);
    yield put({
      type: Types.SUCCESS_CALIBRATION_FINALIZAR,
    } as ISuccessCalibrationFinalizarAction);
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível finalizar a calibração'
    yield put({
      type: Types.FAILURE_CALIBRATION_FINALIZAR,
      message
    });
  }
}

function* loadAvaliado(action: IRequestCalibrationLoadAvaliadoAction) {
  try {
    const avaliado: any = yield call(apiCallLoadAvaliado, action.payload);
    yield put({
      type: Types.SUCCESS_CALIBRATION_LOAD_AVALIADO,
      payload: avaliado
    } as ISuccessCalibrationLoadAvaliadoAction);
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível carregar avaliado'
    yield put({
      type: Types.FAILURE_CALIBRATION_LOAD_AVALIADO,
      message
    });
  }
}

function* saveAvaliado(action: IRequestCalibrationSaveAvaliadoAction) {
  try {
    yield call(apiCallSaveAvaliado, action.payload);
    yield put({
      type: Types.SUCCESS_CALIBRATION_SAVE_AVALIADO,
      payload: {
        idCicloReuniaoAvaliado: action.payload.idCicloReuniaoAvaliado,
        performance: action.payload.performance,
        nivelOriginal: action.payload.nivelOriginal,
        sucessaoOriginal: action.payload.sucessaoOriginal
      }
    } as ISuccessCalibrationSaveAvaliadoAction);
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível salvar o avaliado'
    yield put({
      type: Types.FAILURE_CALIBRATION_SAVE_AVALIADO,
      message,
    } as IFailureCalibrationSaveAvaliadoAction);
  }
}

function* initCalibration(action: IRequestCalibrationInitAction) {
  try {
    const data = yield call(apiCallCalibrationInit, action.payload);
    yield put({
      type: Types.SUCCESS_CALIBRATION_INIT,
      payload: {
        data,
        isRunning: action.payload.isRunning
      }
    } as ISuccessCalibrationInitAction);
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível iniciar a calibração'
    yield put({
      type: Types.FAILURE_CALIBRATION_INIT,
      message,
    });
  }
}

function* stopCalibration(action: IRequestCalibrationStopAction) {
  try {
    const data: any = yield call(apiCallCalibrationStop, action.payload);
    yield put({
      type: Types.SUCCESS_CALIBRATION_STOP,
      payload: {
        ...data,
      }
    } as ISuccessCalibrationStopAction);
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível pausar a calibração'
    yield put({
      type: Types.FAILURE_CALIBRATION_STOP,
      message,
    });
  }
}

function* avaliadoUp(action: IRequestCalibrationAvaliadoUpAction) {
  try {
    yield call(apiCallAvaliadoUp, action.payload);
    yield put({
      type: Types.SUCCESS_CALIBRATION_AVALIADO_UP,
      idCicloReuniaoAvaliado: action.payload.idCicloReuniaoAvaliado
    } as ISuccessCalibrationAvaliadoUpAction);
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível subir o avaliado de nível'
    yield put({
      type: Types.FAILURE_CALIBRATION_AVALIADO_UP,
      message,
    });
  }
}

function* avaliadoDown(action: IRequestCalibrationAvaliadoDownAction) {
  try {
    yield call(apiCallAvaliadoDown, action.payload);
    yield put({
      type: Types.SUCCESS_CALIBRATION_AVALIADO_DOWN,
      idCicloReuniaoAvaliado: action.payload.idCicloReuniaoAvaliado
    } as ISuccessCalibrationAvaliadoDownAction);
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível descer o avaliado de nível'
    yield put({
      type: Types.FAILURE_CALIBRATION_AVALIADO_DOWN,
      message,
    });
  }
}

function* loadComments(action: IRequestCommentAvaliadoAction) {
  try {
    const data = yield call(apiCallLoadComments, action.payload);
    yield put({
      type: Types.SUCCESS_COMMENT_AVALIADO_LOAD,
      payload: data
    } as ISuccessCommentAvaliadoAction);
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível carregar os comentários'
    yield put({
      type: Types.FAILURE_COMMENT_AVALIADO_LOAD,
      message,
    });
  }
}

function* addComment(action: IRequestCommentAvaliadoAddAction) {
  try {
    const data = yield call(apiCallAddComments, action.payload);
    yield put({
      type: Types.SUCCESS_COMMENT_AVALIADO_ADD,
      payload: {
        idCicloReuniaoAvaliado: action.payload.idCicloReuniaoAvaliado,
        comentarios: data
      }
    } as ISuccessCommentAvaliadoAddAction);
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível salvar o comentário'
    yield put({
      type: Types.FAILURE_COMMENT_AVALIADO_ADD,
      message,
    });
  }
}

function* addSucessao(action: IRequestCalibrationSucessaoAddAction) {
  try {
    const data = yield call(apiCallAddSucessao, action.payload);
    yield put({
      type: Types.SUCCESS_SUCESSAO_ADD,
      payload: {
        sucessao: action.payload.sucessao,
        data: data,
        idCicloReuniaoSucessaoGrupo: action.payload.idCicloReuniaoSucessaoGrupo,
        idCicloReuniaoAvaliado: action.payload.idCicloReuniaoAvaliado,
        nomeCompleto: action.payload.nomeCompleto,
        idFuncionario: action.payload.idFuncionario
      }
    } as ISuccessCalibrationSucessaoAddAction);
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível salvar a sucessão'
    yield put({
      type: Types.FAILURE_SUCESSAO_ADD,
      message,
    });
  }
}

function* delSucessao(action: IRequestCalibrationSucessaoDelAction) {
  try {
    yield call(apiCallDelSucessao, action.payload);

    yield put({
      type: Types.SUCCESS_SUCESSAO_DEL,
      payload: {
        idCicloReuniaoSucessaoGrupo: action.payload.idCicloReuniaoSucessaoGrupo,
        idCicloReuniaoSucessaoGrupoAvaliado: action.payload.idCicloReuniaoSucessaoGrupoAvaliado,
        sucessao: action.payload.sucessao
      }
    } as ISuccessCalibrationSucessaoDelAction);
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível remover a sucessão'
    yield put({
      type: Types.FAILURE_SUCESSAO_DEL,
      message,
    });
  }
}

function* pesquisaFunc(action: IRequestCalibrationPesquisaFuncAction) {
  try {
    const data = yield call(apiCallPesquisaFunc, action.payload);

    yield put({
      type: Types.SUCCESS_CALIBRATION_PESQUISAFUNC,
      payload: data
    } as ISuccessCalibrationPesquisaFuncAction);
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível pesquisar os avaliados'
    yield put({
      type: Types.FAILURE_CALIBRATION_PESQUISAFUNC,
      message,
    });
  }
}

function* reloadSucessao(action: IRequestLoadSucessaoAction) {
  try {
    const data = yield call(apiCallLoadSucessao, action.payload);

    yield put({
      type: Types.SUCCESS_SUCESSAO_LOAD,
      payload: data
    } as ISuccessLoadSucessaoAction);
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível carregar a sucessão'
    yield put({
      type: Types.FAILURE_SUCESSAO_LOAD,
      message,
    });
  }
}

function* forceCalibration(action: IRequestCalibrationForceAction) {
  try {
    const data = yield call(apiCallForceCalibration, action.payload);

    yield put({
      type: Types.SUCCESS_SUCESSAO_FORCE_CALIBRATION,
      payload: data
    } as ISuccessCalibrationForceAction);
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível forçar a calibração'
    yield put({
      type: Types.FAILURE_SUCESSAO_FORCE_CALIBRATION,
      message,
    });
  }
}


export default function* CalibrationRoot() {
  yield all([
    yield takeEvery(Types.REQUEST_CALIBRATION_LOAD_BY_ID, loadById),
    yield takeEvery(Types.REQUEST_CALIBRATION_LOAD_BY_ID_NIVEL, loadByIdNivel),
    yield takeEvery(Types.REQUEST_CALIBRATION_ATIVA_AVALIADOR, ativaAvaliador),
    yield takeEvery(Types.REQUEST_CALIBRATION_DESATIVA_AVALIADOR, desativaAvaliador),
    yield takeEvery(Types.REQUEST_CALIBRATION_FINALIZAR, finalizaCalib),
    yield takeEvery(Types.REQUEST_CALIBRATION_LOAD_AVALIADO, loadAvaliado),
    yield takeEvery(Types.REQUEST_CALIBRATION_SAVE_AVALIADO, saveAvaliado),
    yield takeEvery(Types.REQUEST_CALIBRATION_INIT, initCalibration),
    yield takeEvery(Types.REQUEST_CALIBRATION_STOP, stopCalibration),
    yield takeEvery(Types.REQUEST_CALIBRATION_AVALIADO_UP, avaliadoUp),
    yield takeEvery(Types.REQUEST_CALIBRATION_AVALIADO_DOWN, avaliadoDown),
    yield takeEvery(Types.REQUEST_COMMENT_AVALIADO_LOAD, loadComments),
    yield takeEvery(Types.REQUEST_COMMENT_AVALIADO_ADD, addComment),
    yield takeEvery(Types.REQUEST_SUCESSAO_ADD, addSucessao),
    yield takeEvery(Types.REQUEST_SUCESSAO_DEL, delSucessao),
    yield takeEvery(Types.REQUEST_CALIBRATION_PESQUISAFUNC, pesquisaFunc),
    yield takeEvery(Types.REQUEST_SUCESSAO_LOAD, reloadSucessao),
    yield takeEvery(Types.REQUEST_SUCESSAO_FORCE_CALIBRATION, forceCalibration),
  ]);
}
