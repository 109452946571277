/**
 * Types
 */
export enum Types {
  //GET TAB PERFORMANCES
  REQUEST_TAB_PERFORMANCES_LOAD = "adm_tab_performances/REQUEST_TAB_PERFORMANCES_LOAD",
  SUCCESS_TAB_PERFORMANCES_LOAD = "adm_tab_performances/SUCCESS_TAB_PERFORMANCES_LOAD",
  FAILURE_TAB_PERFORMANCES_LOAD = "adm_tab_performances/FAILURE_TAB_PERFORMANCES_LOAD",
  //GET BY ID TAB PERFORMANCES
  REQUEST_TAB_PERFORMANCES_LOAD_BY_ID = "adm_tab_performances/REQUEST_TAB_PERFORMANCES_LOAD_BY_ID",
  SUCCESS_TAB_PERFORMANCES_LOAD_BY_ID = "adm_tab_performances/SUCCESS_TAB_PERFORMANCES_LOAD_BY_ID",
  FAILURE_TAB_PERFORMANCES_LOAD_BY_ID = "adm_tab_performances/FAILURE_TAB_PERFORMANCES_LOAD_BY_ID",
  //ADD TAB PERFORMANCES
  REQUEST_TAB_PERFORMANCES_ADD = "adm_tab_performances/REQUEST_TAB_PERFORMANCES_ADD",
  SUCCESS_TAB_PERFORMANCES_ADD = "adm_tab_performances/SUCCESS_TAB_PERFORMANCES_ADD",
  FAILURE_TAB_PERFORMANCES_ADD = "adm_tab_performances/FAILURE_TAB_PERFORMANCES_ADD",
  //EDIT TAB PERFORMANCES
  REQUEST_TAB_PERFORMANCES_EDIT = "adm_tab_performances/REQUEST_TAB_PERFORMANCES_EDIT",
  SUCCESS_TAB_PERFORMANCES_EDIT = "adm_tab_performances/SUCCESS_TAB_PERFORMANCES_EDIT",
  FAILURE_TAB_PERFORMANCES_EDIT = "adm_tab_performances/FAILURE_TAB_PERFORMANCES_EDIT",
  //DEL TAB PERFORMANCES
  REQUEST_TAB_PERFORMANCES_DEL = "adm_tab_performances/REQUEST_TAB_PERFORMANCES_DEL",
  SUCCESS_TAB_PERFORMANCES_DEL = "adm_tab_performances/SUCCESS_TAB_PERFORMANCES_DEL",
  FAILURE_TAB_PERFORMANCES_DEL = "adm_tab_performances/FAILURE_TAB_PERFORMANCES_DEL",

  //ADD NIVEL
  REQUEST_TAB_PERFORMANCES_ADD_NIVEL = "adm_tab_performances/REQUEST_TAB_PERFORMANCES_ADD_NIVEL",
  SUCCESS_TAB_PERFORMANCES_ADD_NIVEL = "adm_tab_performances/SUCCESS_TAB_PERFORMANCES_ADD_NIVEL",
  FAILURE_TAB_PERFORMANCES_ADD_NIVEL = "adm_tab_performances/FAILURE_TAB_PERFORMANCES_ADD_NIVEL",

  //EDIT NIVEL
  REQUEST_TAB_PERFORMANCES_EDIT_NIVEL = "adm_tab_performances/REQUEST_TAB_PERFORMANCES_EDIT_NIVEL",
  SUCCESS_TAB_PERFORMANCES_EDIT_NIVEL = "adm_tab_performances/SUCCESS_TAB_PERFORMANCES_EDIT_NIVEL",
  FAILURE_TAB_PERFORMANCES_EDIT_NIVEL = "adm_tab_performances/FAILURE_TAB_PERFORMANCES_EDIT_NIVEL",

  //DEL NIVEL
  REQUEST_TAB_PERFORMANCES_DEL_NIVEL = "adm_tab_performances/REQUEST_TAB_PERFORMANCES_DEL_NIVEL",
  SUCCESS_TAB_PERFORMANCES_DEL_NIVEL = "adm_tab_performances/SUCCESS_TAB_PERFORMANCES_DEL_NIVEL",
  FAILURE_TAB_PERFORMANCES_DEL_NIVEL = "adm_tab_performances/FAILURE_TAB_PERFORMANCES_DEL_NIVEL",
}

export type TabPerformanceType = {
  idTabPerformance: number | null;
  nomeTabPerformance: string;
  niveis?: NivelType[];
}

export type NivelType = {
  idTabPerformanceNivel?: number;
  nivel: number | null;
  descNivel: string;
  descIngles: string;
  cor: string;
}

type TabPerformanceSelectedState = {
  item: TabPerformanceType | null;
  loading: boolean;
  success: boolean;
  error: boolean;
}

interface ITabPerformanceState {
  readonly data: TabPerformanceType[];
  readonly itemSelected: TabPerformanceSelectedState;
  readonly loadingData: boolean;
  readonly loadingAction: boolean;
  readonly error: boolean;
  readonly success: boolean;
};

export type DataTypes = ITabPerformanceState;


/**
 * Actions
 */

//GET
export interface IRequestTabPerformanceLoadAction {
  type: typeof Types.REQUEST_TAB_PERFORMANCES_LOAD;
  payload: {
    SearchText?: string;
  }
};

export interface ISuccessTabPerformanceLoadAction {
  type: typeof Types.SUCCESS_TAB_PERFORMANCES_LOAD;
  payload: TabPerformanceType[];
};

interface IFailureTabPerformanceLoadAction {
  type: typeof Types.FAILURE_TAB_PERFORMANCES_LOAD;
  message: string;
};

type TabPerformanceLoadAction = IRequestTabPerformanceLoadAction | ISuccessTabPerformanceLoadAction | IFailureTabPerformanceLoadAction;

//GET BY ID
export interface IRequestTabPerformanceLoadByIdAction {
  type: typeof Types.REQUEST_TAB_PERFORMANCES_LOAD_BY_ID;
  idTabPerformance: number;
};

export interface ISuccessTabPerformanceLoadByIdAction {
  type: typeof Types.SUCCESS_TAB_PERFORMANCES_LOAD_BY_ID;
  payload: TabPerformanceType;
};

interface IFailureTabPerformanceLoadByIdAction {
  type: typeof Types.FAILURE_TAB_PERFORMANCES_LOAD_BY_ID;
  message: string;
};

type TabPerformanceLoadByIdAction = IRequestTabPerformanceLoadByIdAction | ISuccessTabPerformanceLoadByIdAction | IFailureTabPerformanceLoadByIdAction;

//ADD
export interface IRequestTabPerformanceAddAction {
  type: typeof Types.REQUEST_TAB_PERFORMANCES_ADD;
  payload: TabPerformanceType;
};

export interface ISuccessTabPerformanceAddAction {
  type: typeof Types.SUCCESS_TAB_PERFORMANCES_ADD;
  payload: TabPerformanceType;
};

interface IFailureTabPerformanceAddAction {
  type: typeof Types.FAILURE_TAB_PERFORMANCES_ADD;
  message: string;
};

type TabPerformanceAddAction = IRequestTabPerformanceAddAction | ISuccessTabPerformanceAddAction | IFailureTabPerformanceAddAction;

//EDIT
export interface IRequestTabPerformanceEditAction {
  type: typeof Types.REQUEST_TAB_PERFORMANCES_EDIT;
  payload: TabPerformanceType;
};

export interface ISuccessTabPerformanceEditAction {
  type: typeof Types.SUCCESS_TAB_PERFORMANCES_EDIT;
  payload: TabPerformanceType;
};

interface IFailureTabPerformanceEditAction {
  type: typeof Types.FAILURE_TAB_PERFORMANCES_EDIT;
  message: string;
};

type TabPerformanceEditAction = IRequestTabPerformanceEditAction | ISuccessTabPerformanceEditAction | IFailureTabPerformanceEditAction;

//DEL
export interface IRequestTabPerformanceDelAction {
  type: typeof Types.REQUEST_TAB_PERFORMANCES_DEL;
  idTabPerformance: number;
};

export interface ISuccessTabPerformanceDelAction {
  type: typeof Types.SUCCESS_TAB_PERFORMANCES_DEL;
  idTabPerformance: number;
};

interface IFailureTabPerformanceDelAction {
  type: typeof Types.FAILURE_TAB_PERFORMANCES_DEL;
  message: string;
};

type TabPerformanceDelAction = IRequestTabPerformanceDelAction | ISuccessTabPerformanceDelAction | IFailureTabPerformanceDelAction;

//ADD_NIVEL
export interface IRequestTabPerformanceAddNivelAction {
  type: typeof Types.REQUEST_TAB_PERFORMANCES_ADD_NIVEL;
  payload: {
    idTabPerformance: number;
    nivel: NivelType;
  }
};

export interface ISuccessTabPerformanceAddNivelAction {
  type: typeof Types.SUCCESS_TAB_PERFORMANCES_ADD_NIVEL;
  payload: NivelType
};

interface IFailureTabPerformanceAddNivelAction {
  type: typeof Types.FAILURE_TAB_PERFORMANCES_ADD_NIVEL;
  message: string;
};

type TabPerformanceAddNivelAction = IRequestTabPerformanceAddNivelAction | ISuccessTabPerformanceAddNivelAction | IFailureTabPerformanceAddNivelAction;

//EDIT NIVEL
export interface IRequestTabPerformanceEditNivelAction {
  type: typeof Types.REQUEST_TAB_PERFORMANCES_EDIT_NIVEL;
  payload: {
    idTabPerformance: number;
    nivel: NivelType;
  }
};

export interface ISuccessTabPerformanceEditNivelAction {
  type: typeof Types.SUCCESS_TAB_PERFORMANCES_EDIT_NIVEL;
  payload: NivelType
};

interface IFailureTabPerformanceEditNivelAction {
  type: typeof Types.FAILURE_TAB_PERFORMANCES_EDIT_NIVEL;
  message: string;
};

type TabPerformanceEditNivelAction = IRequestTabPerformanceEditNivelAction | ISuccessTabPerformanceEditNivelAction | IFailureTabPerformanceEditNivelAction;

//DEL NIVEL
export interface IRequestTabPerformanceDelNivelAction {
  type: typeof Types.REQUEST_TAB_PERFORMANCES_DEL_NIVEL;
  payload:{
    idTabPerformance: number;
    idTabPerformanceNivel: number;
  }
};

export interface ISuccessTabPerformanceDelNivelAction {
  type: typeof Types.SUCCESS_TAB_PERFORMANCES_DEL_NIVEL;
  idTabPerformanceNivel: number;
};

interface IFailureTabPerformanceDelNivelAction {
  type: typeof Types.FAILURE_TAB_PERFORMANCES_DEL_NIVEL;
  message: string;
};

type TabPerformanceDelNivelAction = IRequestTabPerformanceDelNivelAction | ISuccessTabPerformanceDelNivelAction | IFailureTabPerformanceDelNivelAction;

export type TabPerformanceActionTypes =
  TabPerformanceLoadAction |
  TabPerformanceLoadByIdAction |
  TabPerformanceAddAction |
  TabPerformanceEditAction |
  TabPerformanceDelAction |
  TabPerformanceAddNivelAction |
  TabPerformanceEditNivelAction |
  TabPerformanceDelNivelAction;
