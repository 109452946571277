import styled from "styled-components";
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';

export const SelectInput = styled(Select) <{ custompadding?: string }>`
    width: 100%;
    .MuiSelect-select:focus{
        padding: ${props => props.custompadding ? props.custompadding : ''};
    }
`
export const WrapperField = styled(FormControl) <{ height?: number, size?: string, padding_selected?: string }>`
    width: 100%;
    height: ${props => props.height ? props.height : 70}px;

    ${({ size }) => size ? `
    .MuiInputLabel-shrink{
        transform: translate(0, 3.5px) scale(0.75);
    }
    ` : ''}
    
    ${({ padding_selected }) => padding_selected ? `
    .MuiOutlinedInput-input{
        padding: ${padding_selected};
    }
    ` : ''}

`

export const WrapperMenuDropdown = styled.div`
    position: relative;
`

export const WrapperMenu = styled.div<{ isOpen: boolean, top: number }>`
    box-shadow: rgb(0 0 0 / 13%) 0px 3.2px 7.2px 0px, rgb(0 0 0 / 11%) 0px 0.6px 1.8px 0px;
    padding: 15px;
    box-sizing: border-box;
    border-radius: 2px;
    position: absolute;
    display: ${({ isOpen }) => isOpen ? 'block' : 'none'};
    top: ${({ top }) => top}px;
    right: 0;
    z-index: 999;
    background-color: white;
    width: 380px;
`
export const Overlay = styled.div<{ isOpen: boolean }>`
    ${({ isOpen }) => isOpen ? `
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index:999;
    ` : ''};
`

export const WrapperContent = styled.div`
    outline: none;
`