import { Types, DataTypes, PDIActionTypes, PDIType } from "./types";
import customToast from "~/components/Toast/index";

/**
 * Reducers
 */
const INITIAL_STATE: DataTypes = {
    data: [],
    itemSelected: {
        item: null,
        error: false,
        loading: false,
        loadingAction: false,
        success: false
    },
    loadingData: false,
    loadingAction: false,
    success: false,
    error: false,
    errorAction: false
};


/**
 * Actions
 */

export const Creators = {
    /**
     * REQUEST GET: Carregar todas os PDIs
     * @param SearchText Nome PDI para pesquisa
     * @returns 
     */
    getPdi: (SearchText?: string, TipoPDI?: number | string, Competencia?: number | string): PDIActionTypes => ({
        type: Types.REQUEST_PDI_LOAD,
        payload: {
            SearchText,
            TipoPDI,
            Competencia
        }
    }),

    /**
     * REQUEST POST: Adicionar PDI
     * @param pdi 
     * @returns 
     */
    addPdi: (pdi: PDIType): PDIActionTypes => ({
        type: Types.REQUEST_PDI_ADD,
        payload: {
            pdi,
        }
    }),

    /**
     * REQUEST PUT: EDITAR PDI
     * @param pdi Objeto PDI
     * @returns 
     */
    editPDI: (pdi: PDIType): PDIActionTypes => ({
        type: Types.REQUEST_PDI_EDIT,
        payload: {
            pdi
        }
    }),

    /**
     * REQUEST DEL: EXCLUIR PDI
     * @param idAcaoPDI 
     * @returns 
     */
    delPDI: (idAcaoPDI: number): PDIActionTypes => ({
        type: Types.REQUEST_PDI_DELETE,
        idAcaoPDI
    }),

    /**
     * Apaga todos os PDIs
     * @returns 
     */
    resetPDI: (): PDIActionTypes => ({
        type: Types.RESET_PDI
    })
}


export default function pdiReducer(state = INITIAL_STATE, action: PDIActionTypes): DataTypes {

    switch (action.type) {
        // GET PDI
        case Types.REQUEST_PDI_LOAD:
            return { ...state, loadingData: true, error: false, success: false };
        case Types.SUCCESS_PDI_LOAD:
            return { ...state, data: action.payload, loadingData: false, error: false, success: true };
        case Types.FAILURE_PDI_LOAD:
            customToast.error(action.message);
            return { ...state, data: [], loadingData: false, error: true, success: false };

        // ADD PDI
        case Types.REQUEST_PDI_ADD:
            return { ...state, loadingAction: true, errorAction: false, success: false };
        case Types.SUCCESS_PDI_ADD:
            return { ...state, data: [...state.data, action.payload], loadingAction: false, errorAction: false, success: true };
        case Types.FAILURE_PDI_ADD:
            customToast.error(action.message);
            return { ...state, loadingAction: false, errorAction: true, success: false };

        case Types.REQUEST_PDI_EDIT:
            return { ...state, loadingAction: true, success: false, errorAction: false }

        case Types.SUCCESS_PDI_EDIT:
            return {
                ...state,
                data: state.data.map(item => {
                    if (item.idAcaoPDI !== action.payload.idAcaoPDI) {
                        return item;
                    } else {
                        return action.payload
                    }
                }),
                loadingAction: false,
                success: true,
                errorAction: false
            }

        case Types.FAILURE_PDI_EDIT:
            customToast.error(action.message);
            return { ...state, loadingAction: false, success: false, errorAction: true }

        case Types.REQUEST_PDI_DELETE:
            return { ...state, loadingAction: true, success: false, errorAction: false }
        case Types.SUCCESS_PDI_DELETE:
            return { ...state, data: state.data.filter(item => item.idAcaoPDI !== action.idAcaoPDI), loadingAction: false, success: true, errorAction: false }
        case Types.FAILURE_PDI_DELETE:
            customToast.error(action.message);
            return { ...state, loadingAction: false, success: false, errorAction: true }

        case Types.RESET_PDI:
            return { ...state, data: [] }

        default:
            return state;
    }
}