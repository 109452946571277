/**
 * Types
 */
export enum Types {
    //GET
    REQUEST_CYCLE_ATTITUDES_LOAD = "cycle_attitudes/REQUEST_CYCLE_ATTITUDES_LOAD",
    SUCCESS_CYCLE_ATTITUDES_LOAD = "cycle_attitudes/SUCCESS_CYCLE_ATTITUDES_LOAD",
    FAILURE_CYCLE_ATTITUDES_LOAD = "cycle_attitudes/FAILURE_CYCLE_ATTITUDES_LOAD",
    //POST
    REQUEST_CYCLE_ATTITUDES_ADD = "cycle_attitudes/REQUEST_CYCLE_ATTITUDES_ADD",
    SUCCESS_CYCLE_ATTITUDES_ADD = "cycle_attitudes/SUCCESS_CYCLE_ATTITUDES_ADD",
    FAILURE_CYCLE_ATTITUDES_ADD = "cycle_attitudes/FAILURE_CYCLE_ATTITUDES_ADD",
    //PUT
    REQUEST_CYCLE_ATTITUDES_EDIT = "cycle_attitudes/REQUEST_CYCLE_ATTITUDES_EDIT",
    SUCCESS_CYCLE_ATTITUDES_EDIT = "cycle_attitudes/SUCCESS_CYCLE_ATTITUDES_EDIT",
    FAILURE_CYCLE_ATTITUDES_EDIT = "cycle_attitudes/FAILURE_CYCLE_ATTITUDES_EDIT",
    //DELETE
    REQUEST_CYCLE_ATTITUDES_DEL = "cycle_attitudes/REQUEST_CYCLE_ATTITUDES_DEL",
    SUCCESS_CYCLE_ATTITUDES_DEL = "cycle_attitudes/SUCCESS_CYCLE_ATTITUDES_DEL",
    FAILURE_CYCLE_ATTITUDES_DEL = "cycle_attitudes/FAILURE_CYCLE_ATTITUDES_DEL",
    // NEXT
    REQUEST_CYCLE_ATITUDES_NEXT = "cycle_attitudes/REQUEST_CYCLE_ATITUDES_NEXT",
    SUCCESS_CYCLE_ATITUDES_NEXT = "cycle_attitudes/SUCCESS_CYCLE_ATITUDES_NEXT",
    FAILURE_CYCLE_ATITUDES_NEXT = "cycle_attitudes/FAILURE_CYCLE_ATITUDES_NEXT",
    // PRIOR
    REQUEST_CYCLE_ATITUDES_PRIOR = "cycle_attitudes/REQUEST_CYCLE_ATITUDES_PRIOR",
    SUCCESS_CYCLE_ATITUDES_PRIOR = "cycle_attitudes/SUCCESS_CYCLE_ATITUDES_PRIOR",
    FAILURE_CYCLE_ATITUDES_PRIOR = "cycle_attitudes/FAILURE_CYCLE_ATITUDES_PRIOR",
};


/**
 * Data types
 */

export type CycloAttitudeType = {
    idCicloAtitude: number | null,
    idAtitude: number,
    idCiclo: number,
    ordem: number,
    flagLideranca: boolean | null;
    atitude: {
        nomeAtitude: string;
    }
};

interface ICycleAttitudeState {
    readonly data: CycloAttitudeType[];
    readonly loading: boolean;
    readonly loadingAction: boolean;
    readonly error: boolean;
    readonly success: boolean;
};

export type DataTypes = ICycleAttitudeState;


/**
 * Actions
 */

//GET
export interface IRequestCycleAttitudesLoadAction {
    type: typeof Types.REQUEST_CYCLE_ATTITUDES_LOAD;
    idCiclo: number;
};

export interface ISuccessCycleAttitudesLoadAction {
    type: typeof Types.SUCCESS_CYCLE_ATTITUDES_LOAD;
    payload: CycloAttitudeType[];
};

interface IFailureCycleAttitudesLoadAction {
    type: typeof Types.FAILURE_CYCLE_ATTITUDES_LOAD;
    message: string;
};

type CycleAttitudesLoadAction = IRequestCycleAttitudesLoadAction | ISuccessCycleAttitudesLoadAction | IFailureCycleAttitudesLoadAction;

//POST
export interface IRequestCycleAttitudesAddAction {
    type: typeof Types.REQUEST_CYCLE_ATTITUDES_ADD;
    payload: {
        idCiclo: number;
        idAtitude: number;
        ordem: number | null;
        flagLideranca: boolean | null;
        atitude: {
            nomeAtitude: string;
        }
    }
};

export interface ISuccessCycleAttitudesAddAction {
    type: typeof Types.SUCCESS_CYCLE_ATTITUDES_ADD;
    payload: CycloAttitudeType;
};

interface IFailureCycleAttitudesAddAction {
    type: typeof Types.FAILURE_CYCLE_ATTITUDES_ADD;
    message: string;
};

type CycleAttitudesAddAction = IRequestCycleAttitudesAddAction | ISuccessCycleAttitudesAddAction | IFailureCycleAttitudesAddAction;

//POST
export interface IRequestCycleAttitudesEditAction {
    type: typeof Types.REQUEST_CYCLE_ATTITUDES_EDIT;
    payload: {
        idCiclo: number;
        idCicloAtitude: number;
        flagLideranca: boolean | null;
    }
};

export interface ISuccessCycleAttitudesEditAction {
    type: typeof Types.SUCCESS_CYCLE_ATTITUDES_EDIT;
    payload: {
        idCicloAtitude: number;
        flagLideranca: boolean | null;
    }
};

interface IFailureCycleAttitudesEditAction {
    type: typeof Types.FAILURE_CYCLE_ATTITUDES_EDIT;
    message: string;
};

type CycleAttitudesEditAction = IRequestCycleAttitudesEditAction | ISuccessCycleAttitudesEditAction | IFailureCycleAttitudesEditAction;

//DELETE
export interface IRequestCycleAttitudesDelAction {
    type: typeof Types.REQUEST_CYCLE_ATTITUDES_DEL;
    payload: {
        idCiclo: number;
        idCicloAtitude: number;
    }
};

export interface ISuccessCycleAttitudesDelAction {
    type: typeof Types.SUCCESS_CYCLE_ATTITUDES_DEL;
    idCicloAtitude: number;
};

interface IFailureCycleAttitudesDelAction {
    type: typeof Types.FAILURE_CYCLE_ATTITUDES_DEL;
    message: string;
};

type CycleAttitudesDelAction = IRequestCycleAttitudesDelAction | ISuccessCycleAttitudesDelAction | IFailureCycleAttitudesDelAction;

// NEXT
export interface IRequestCycleAttitudesNextAction {
    type: typeof Types.REQUEST_CYCLE_ATITUDES_NEXT;
    payload: {
        idCiclo: number;
        idCicloAtitude: number;
    }
};

export interface ISuccessCycleAttitudesNextAction {
    type: typeof Types.SUCCESS_CYCLE_ATITUDES_NEXT;
    idCicloAtitude: number;
};

interface IFailureCycleAttitudesNextAction {
    type: typeof Types.FAILURE_CYCLE_ATITUDES_NEXT;
    message: string;
};

type CycleAttitudesNextAction = IRequestCycleAttitudesNextAction | ISuccessCycleAttitudesNextAction | IFailureCycleAttitudesNextAction;

// PRIOR
export interface IRequestCycleAttitudesPriorAction {
    type: typeof Types.REQUEST_CYCLE_ATITUDES_PRIOR;
    payload: {
        idCiclo: number;
        idCicloAtitude: number;
    }
};

export interface ISuccessCycleAttitudesPriorAction {
    type: typeof Types.SUCCESS_CYCLE_ATITUDES_PRIOR;
    idCicloAtitude: number;
};

interface IFailureCycleAttitudesPriorAction {
    type: typeof Types.FAILURE_CYCLE_ATITUDES_PRIOR;
    message: string;
};

type CycleAttitudesPriorAction = IRequestCycleAttitudesPriorAction | ISuccessCycleAttitudesPriorAction | IFailureCycleAttitudesPriorAction;

export type CycleAttitudesActionTypes =
    CycleAttitudesLoadAction |
    CycleAttitudesAddAction |
    CycleAttitudesDelAction |
    CycleAttitudesNextAction |
    CycleAttitudesPriorAction |
    CycleAttitudesEditAction;
