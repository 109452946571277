// #region Imports
import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik } from "formik";
import * as yup from 'yup';
import InputMask from "react-input-mask";
import { isValidCNPJ } from "@brazilian-utils/brazilian-utils"
//FluentUI
import { Text } from 'office-ui-fabric-react/lib/Text';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import {
  DetailsListLayoutMode,
  SelectionMode,
  Selection,
  IColumn,
} from 'office-ui-fabric-react/lib/DetailsList';
import { ShimmeredDetailsList } from 'office-ui-fabric-react/lib/ShimmeredDetailsList';
import { SearchBox } from 'office-ui-fabric-react/lib/SearchBox';
import { CommandBarButton, IconButton, ICommandBarStyles, Pivot } from 'office-ui-fabric-react';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';

//Componentes
import CustomDialog from "~/components/CustomDialogFluentUI";
import Panel from "~/components/layout/Panel";
import Status from "~/components/Status";
import { InputText, InputCheckbox, InputAutocomplete } from '~/components/Forms';
import HeaderPage from '~/components/layout/HeaderPage';
import NoItems from '~/components/layout/NoItems';
import { DeleteButton } from '~/components/Buttons';

import colors from "~/assets/js/colors";
import { CustomDrawer as Drawer } from "~/components/layout/Drawer";
import { PrimaryButton } from '~/components/Buttons';
import { Page, BreadcrumbItems } from "~/store/ducks/home/types";

//Estilos
import {
  Wrapper,
  ListContainer,
  DefaultButton,
  GroupCheckbox,
  ContainerContent,
} from "./styles";

import { Creators as getCompanies } from "~/store/ducks/admin/companies";
import { Creators as getUnits } from "~/store/ducks/admin/units";
import { Creators as getUnitById } from "~/store/ducks/admin/units";
import { Creators as addUnit } from "~/store/ducks/admin/units";
import { Creators as delUnit } from "~/store/ducks/admin/units";
import { Creators as editUnit } from "~/store/ducks/admin/units";
import { Creators as setCurrentPage } from "~/store/ducks/home";
import { DataTypes, IUnit } from "~/store/ducks/admin/units/types";
import { DataTypes as DataTypesCompanies, ICompany } from "~/store/ducks/admin/companies/types";
import { RootState } from "~/store/ducks";
import PanelDisplay, { PanelHeaderDisplay } from "~/components/PanelDisplay";
import { PivotItem } from "@fluentui/react";
import { ContainerGrups, GroupItemDoisTerco, GroupItemMini, GroupItems, GroupItemUmTerco, TitleField } from "~/components/FormGroup";
import { WrapperGroupItemDoisTerco, WrapperGroupItemFull } from "~/components/FormGroup/styles";
import UnitSalaryStructure from "./UnitSalaryStructure";
//#endregion

const btnStyle: Partial<ICommandBarStyles> = {
  root: {
    height: 44
  }
}

/**
 * Validação do formulário
 */
const validationUnitSchema = yup.object().shape({
  nomeUnidade: yup.string()
    .min(3, "O nome da unidade deve conter pelo menos 3 caracteres")
    .max(100, "O nome da unidade não pode ter mais do que 100 caracteres")
    .required("Campo obrigatório"),
  idEmpresa:
    yup.string()
      .nullable()
      .required("Campo obrigatório"),
  CNPJ:
    yup.string()
      .test('Validar CNPJ', 'CNPJ inválido', value => isValidCNPJ(value || ''))
      .required("Campo obrigatório"),
  cep:
    yup.string()
      .max(9)
      .notRequired(),
  numEndereco:
    yup.string()
      .max(10, "O número do endereço não pode ter mais do que 10 caracteres")
      .notRequired(),
  tipoEndereco:
    yup.string()
      .max(15, "O tipo do endereço não pode ter mais do que 15 caracteres")
      .notRequired(),
  logEndereco:
    yup.string()
      .max(80, "O logradouro não pode ter mais do que 80 caracteres")
      .notRequired(),
  compEndereco:
    yup.string()
      // .max(20, "O complemento não pode ter mais do que 20 caracteres")
      .notRequired(),
  bairro:
    yup.string()
      .max(45, "O bairro não pode ter mais do que 45 caracteres")
      .notRequired(),
  cidade:
    yup.string()
      .max(60, "A cidade não pode ter mais do que 60 caracteres")
      .notRequired(),
  uf:
    yup.string()
      .min(2, "A unidade federativa não pode ter menos do que 2 caracteres")
      .max(2, "A unidade federativa não pode ter mais do que 2 caracteres")
      .notRequired(),
  flagAtiva:
    yup.bool()
});

type Filter = {
  isOpen?: boolean;
  ativadas?: string;
  filterStatus?: boolean | null;
  companies?: any;
};


interface IUnitsState {
  columns: IColumn[];
  items: IUnit[];
  isPanelOpen: boolean;
  isPanelDisplayOpen: boolean;
  inicialValues: IUnit;
  isFiltering: boolean;
  selectionDetails: string;
  selectionCount: number;
  isDialogExcludeOpen: boolean;
  filter: Filter;
  search: string;
  autocompleteIsOpen: boolean;
  autocompleteValue: ICompany;
  isPanelEditIdentificationOpen: boolean;
  isPanelEditAddressOpen: boolean;
};

interface IPropsUnits {
  units: DataTypes;
  companies: DataTypesCompanies;
  getCompanies: (search?: string) => void;
  getUnits: (search?: string, filter?: boolean | null) => void;
  getUnitById: (unit: IUnit) => void;
  addUnit: (unit: IUnit) => void;
  editUnit: (unit: IUnit) => void;
  delUnit: (id: number) => void;
  setCurrentPage: (page: Page) => void;
}

const itemsBreadCrumb: BreadcrumbItems[] = [
  { text: "Home", isCurrentItem: false, icon: "HomeSolid", onlyIcon: true, url: "/" },
  { text: "Unidades", isCurrentItem: true },
];

const page: Page = {
  key: 'unidades',
  pages: itemsBreadCrumb
};

const initialValues: IUnit = {
  idUnidade: null,
  idEmpresa: null,
  empresa: {
    idEmpresa: null,
    nomeEmpresa: "",
    codEmpresa: "",
    flagAtiva: false
  },
  nomeUnidade: "",
  CNPJ: "",
  flagAtiva: true,
  tipoEndereco: "",
  logEndereco: "",
  numEndereco: "",
  compEndereco: "",
  cidade: "",
  bairro: "",
  uf: "",
  cep: "",
  es: []
}

const initialCompany: ICompany = {
  codEmpresa: "",
  flagAtiva: true,
  idEmpresa: null,
  nomeEmpresa: ""
}

class Units extends Component<IPropsUnits, IUnitsState> {
  private formRef: any;
  private inputSearch: any;
  private _selection: Selection;
  private timeout: number;

  constructor(props: IPropsUnits) {
    super(props);
    //#region Colunas
    const columns: IColumn[] = [
      {
        key: 'column1',
        name: '',
        ariaLabel: 'Status da empresa',
        fieldName: 'flagAtiva',
        minWidth: 15,
        maxWidth: 20,
        isResizable: true,
        isSortedDescending: false,
        onRender: (item: IUnit) => <Status status={item.flagAtiva!} />,
      },
      {
        key: 'column2',
        name: 'ID',
        ariaLabel: 'ID do Local',
        fieldName: 'idUnidade',
        minWidth: 75,
        maxWidth: 100,
        isRowHeader: true,
        isSortedDescending: false,
        onColumnClick: this._onColumnClick
      },
      {
        key: 'column3',
        name: 'Unidade',
        fieldName: 'nomeUnidade',
        minWidth: 210,
        maxWidth: 350,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
      },
      {
        key: 'column4',
        name: 'Empresa',
        fieldName: 'empresa.nomeEmpresa',
        minWidth: 210,
        maxWidth: 350,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
        onRender: (item: IUnit) => {
          return item.empresa!.nomeEmpresa;
        },
      }
    ];
    //#endregion

    this.state = {
      columns: columns,
      items: [],
      isPanelOpen: false,
      isPanelDisplayOpen: false,
      selectionDetails: "",
      inicialValues: initialValues,
      isFiltering: false,
      selectionCount: 0,
      isDialogExcludeOpen: false,
      search: "",
      filter: {
        isOpen: false,
        ativadas: 'todos',
        filterStatus: null,
      },
      autocompleteIsOpen: false,
      autocompleteValue: initialCompany,
      isPanelEditIdentificationOpen: false,
      isPanelEditAddressOpen: false
    }

    this._selection = new Selection({
      onSelectionChanged: () => {
        this.setState({
          selectionDetails: this._getSelectionDetails(),
        });
      },
    });

    this.formRef = React.createRef();
    this.inputSearch = React.createRef();
    this.timeout = 0;

  };

  componentDidMount() {
    this.props.setCurrentPage(page);
    this.props.getUnits();
  };

  componentDidUpdate(prevProps: IPropsUnits, prevState: IUnitsState) {
    const { success, item, error } = this.props.units.itemSelected;

    if (prevProps.units.success !== this.props.units.success) {
      if (this.props.units.success) {
        const companies = this.props.units.data.map(item => item.empresa?.nomeEmpresa)
          .filter((item, pos, self) => self.indexOf(item) === pos)
          .map(item => [item, false]);

        const filters = Object.fromEntries(companies);
        this.setState({
          filter: {
            ...this.state.filter,
            companies: {
              ...filters
            }
          }
        });
      }
    }

    if (prevProps.units.itemSelected.success !== success) {
      if (success) {
        this.setState({
          inicialValues: item!,
          autocompleteValue: {
            idEmpresa: item!.idEmpresa!,
            nomeEmpresa: item!.empresa!.nomeEmpresa,
            codEmpresa: "",
            flagAtiva: true
          },
          // isPanelOpen: true
        });
      };
    }

    if (prevProps.units.itemSelected.error !== error) {
      if (error) {
        this.setState({
          isPanelOpen: false,
          isPanelDisplayOpen: false
        });
      };
    }

    if (this.state.isPanelOpen) {
      if (prevProps.units.success !== this.props.units.success) {
        if (this.props.units.success) {
          this.setState({ isPanelOpen: false, autocompleteValue: initialCompany })
        }
      }
    }

    if (prevState.isPanelEditIdentificationOpen !== this.state.isPanelEditIdentificationOpen) {
      if (this.state.isPanelEditIdentificationOpen) {
        this.setState({
          autocompleteValue: {
            idEmpresa: item?.idEmpresa!,
            nomeEmpresa: item!.empresa!.nomeEmpresa,
            codEmpresa: "",
            flagAtiva: true
          },
          // isPanelOpen: true
        });
      }
    }
  };

  // Submit do formulário Formik
  handleSubmit = () => {
    if (this.formRef.current) {
      this.formRef.current.handleSubmit();
    };
  };

  _onItemInvoked = (item: IUnit): void => {
    this.openEdit()
  };

  _getSelectionDetails(): any {
    const selectionCount = this._selection.getSelectedCount();
    this.setState({ selectionCount });
    this._selection.getSelection();
  };

  openEdit = () => {
    const selectItem: IUnit = (this._selection.getSelection()[0] as IUnit)
    this.setState({
      isPanelDisplayOpen: true
    })
    this.props.getUnitById(selectItem);
  };

  cancelPanel = () => {
    this._selection.setAllSelected(false);
    this.setState({ autocompleteValue: initialCompany, isPanelOpen: false });
  };

  excludeUnit = () => {
    this.props.delUnit((this._selection.getSelection()[0] as IUnit).idUnidade!);
    this.setState({ isDialogExcludeOpen: false });
    this._selection.setAllSelected(false);
  };

  //#region  Funções do filtro

  toggleFilter = () => {
    this.setState({
      filter: {
        ...this.state.filter,
        isOpen: !this.state.filter.isOpen,
      }
    });
  };


  toggleParamsFilter = (param: string) => {
    const { filter, search } = this.state;

    switch (param) {
      case 'todos':
        this.setState({ filter: { ...filter, filterStatus: null, ativadas: param } },
          () => this.props.getUnits(search)
        );
        break;
      case 'ativadas':
        this.setState({ filter: { ...filter, filterStatus: true, ativadas: param } },
          () => this.props.getUnits(search, true)
        );
        break;
      case 'desativas':
        this.setState({ filter: { ...filter, filterStatus: false, ativadas: param } },
          () => this.props.getUnits(search, false)
        );
        break;
    }

    if (this.inputSearch.current) {
      this.filter(this.inputSearch.current.state.value);
    };

  };

  filter = (text: any) => {
    const units = this.props.units.data;
    let items = text ? units.filter((i: IUnit) => i.nomeUnidade.toLowerCase().indexOf(text.toLowerCase()) > -1) : units;
    this.setState({ items, isFiltering: true });
  };

  renderFilters = () => {
    const units = this.props.units.data;
    const empresas = units.map(item => item.empresa!.nomeEmpresa)
      .filter((item, pos, self) => self.indexOf(item) === pos);

    return empresas.map((item, i) => {
      try {
        return (
          <InputCheckbox
            key={i}
            checked={this.state.filter.companies[item]}
            onChange={() => this.filterCompanies(item)}
            name={item}
            color="primary"
            label={<span style={{ fontSize: 14 }}>{item}</span>}
          />
        )
      } catch {
        return null;
      }
    });
  };

  filterCompanies = (filtro: string) => {
    const comp = this.state.filter.companies;
    this.setState({
      filter: {
        ...this.state.filter,
        companies: {
          ...comp,
          [filtro]: !comp[filtro]
        }
      }
    }, () => this.setCompanies());
  };

  setCompanies = () => {
    const comp = this.state.filter.companies;
    const items = this.props.units.data;
    let newItems: any[] = [];

    const isFiltered = Object.values(comp).some((company: any) => company === true);
    if (isFiltered) {
      Object.keys(comp).forEach((company, i) => {
        if (Object.values(comp)[i]) {
          items.forEach(item => {
            if (company === item.empresa?.nomeEmpresa) {
              newItems.push(item);
            };
          });
        }
      });
      this.setState({ items: newItems, isFiltering: true });
    } else {
      this.setState({ items: items, isFiltering: false });
    };
  };
  //#endregion

  searchCompanies = (text: string) => {
    if (text.trim()) {
      clearTimeout(this.timeout);
      this.timeout = window.setTimeout(() => {
        this.props.getCompanies(text);
      }, 700);
    }
  };

  commandBarRender = () => {
    const { selectionCount } = this.state;
    if (selectionCount === 1) {
      return (
        <>
          <CommandBarButton styles={btnStyle} iconProps={{ iconName: 'Edit' }} text="Editar" onClick={() => this.openEdit()} />
          <CommandBarButton styles={btnStyle} iconProps={{ iconName: 'Delete' }} text="Excluir" onClick={() => this.setState({ isDialogExcludeOpen: true })} />
        </>
      );
    } else if (selectionCount > 1) {
      return <CommandBarButton styles={btnStyle} iconProps={{ iconName: 'Delete' }} text="Excluir" onClick={() => this.setState({ isDialogExcludeOpen: true })} />
    };
  };

  render() {
    const {
      isPanelOpen,
      isPanelDisplayOpen,
      isDialogExcludeOpen,
      selectionCount,
      inicialValues,
      isFiltering,
      items,
      columns,
      filter,
      isPanelEditIdentificationOpen,
      isPanelEditAddressOpen
    } = this.state;
    const { units, addUnit, editUnit, getUnits, companies } = this.props;
    const { item: unitSelected } = units.itemSelected;

    const dialogContentProps = {
      title: 'Excluir?',
      closeButtonAriaLabel: 'Close',
      subText: selectionCount <= 1 ? 'Tem certeza de que deseja excluir este item?' : 'Tem certeza de que deseja excluir estes items?',
    };
    // const loading = autocompleteIsOpen && companies.data.length === 0 && autocompleteSearchText === "";
    return (
      <>
        <Panel
          title={!inicialValues.idUnidade ? "Nova unidade" : inicialValues.nomeUnidade}
          open={isPanelOpen}
          loading={units.itemSelected.loading}
          onClose={() => this.cancelPanel()}
          footer={
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <DefaultButton onClick={() => this.cancelPanel()}>Cancelar</DefaultButton>
              <PrimaryButton onClick={this.handleSubmit} text="Salvar" isLoading={units.loading} />
            </Stack>
          }
        >
          {
            units.itemSelected.loading ?
              <Spinner size={SpinnerSize.large} styles={{ root: { height: 'calc(100% - 50px)' } }} />
              :
              <>
                <Formik
                  innerRef={this.formRef}
                  initialValues={{ ...inicialValues, flagAtiva: !inicialValues.flagAtiva }}
                  validationSchema={validationUnitSchema}
                  validateOnChange={false}
                  validateOnBlur={true}
                  enableReinitialize
                  onSubmit={(values: IUnit) => {
                    values.empresa!.nomeEmpresa = this.state.autocompleteValue.nomeEmpresa;
                    if (inicialValues.idUnidade === null) {
                      values.flagAtiva = true;
                      addUnit(values);
                    } else {
                      values.idUnidade = inicialValues.idUnidade;
                      editUnit({ ...values, flagAtiva: !values.flagAtiva });
                    };
                  }}
                >
                  {({ handleSubmit, handleChange, values, errors, setFieldError, setValues, setFieldValue }) => (
                    <form onSubmit={handleSubmit}>
                      {
                        (!values.idEmpresa || isPanelEditIdentificationOpen) &&
                        <>
                          <InputText
                            value={values.nomeUnidade}
                            onChange={(e) => { handleChange(e); setFieldError("nomeUnidade", "") }}
                            id="nomeUnidade"
                            error={errors.nomeUnidade ? true : false}
                            type="text"
                            label="Nome"
                            helperText={errors.nomeUnidade}
                            inputProps={{ maxLength: 100 }}
                            className="mt-2"
                            autoFocus
                          />
                          <InputAutocomplete
                            value={this.state.autocompleteValue}
                            onChange={(_, newValue) => {
                              this.setState({ autocompleteValue: newValue! });
                              setFieldValue("idEmpresa", newValue?.idEmpresa);
                            }}
                            onInputChange={(_, newInputValue) => {
                              setFieldError("idEmpresa", "");
                              this.searchCompanies(newInputValue);
                            }}
                            getOptionLabel={(company: ICompany) => company.nomeEmpresa}
                            getOptionSelected={(option, value) => option.idEmpresa === value.idEmpresa}
                            options={companies.data}
                            input={{
                              idInput: "idEmpresa",
                              labelInput: "Empresa",
                              helperTextInput: errors.idEmpresa as string,
                              errorInput: errors.idEmpresa ? true : false,
                            }}
                          />
                          <InputMask
                            mask='99.999.999/9999-99'
                            maskChar={null}
                            onChange={(e) => { handleChange(e); setFieldError("CNPJ", ""); }}
                            value={values.CNPJ}
                          >
                            {() =>
                              <InputText
                                id="CNPJ"
                                error={errors.CNPJ ? true : false}
                                name="CNPJ"
                                type="text"
                                label="CNPJ"
                                className="mt-2"
                                helperText={errors.CNPJ} />
                            }
                          </InputMask>
                          {
                            inicialValues.idUnidade &&
                            <InputCheckbox
                              checked={values.flagAtiva}
                              onChange={handleChange}
                              name="flagAtiva"
                              color="primary"
                              label="Inativar Unidade"
                            />
                          }
                        </>
                      }
                      {
                        (!values.idEmpresa || isPanelEditAddressOpen) &&
                        <>
                          <InputMask
                            mask='99999-999'
                            maskChar={null}
                            onChange={(e) => { handleChange(e); setFieldError("cep", ""); }}
                            value={values.cep}
                          >
                            {() =>
                              <InputText
                                id="cep"
                                error={errors.cep ? true : false}
                                name="cep"
                                type="text"
                                label="CEP"
                                className="mt-2"
                                helperText={errors.cep} />
                            }
                          </InputMask>
                          <InputText
                            value={values.numEndereco}
                            onChange={(e) => { handleChange(e); setFieldError("numEndereco", "") }}
                            id="numEndereco"
                            error={errors.numEndereco ? true : false}
                            name="numEndereco"
                            type="text"
                            label="Número"
                            helperText={errors.numEndereco}
                            inputProps={{ maxLength: 80 }}
                            className="mt-2"
                          />
                          <InputText
                            value={values.bairro}
                            onChange={(e) => { handleChange(e); setFieldError("bairro", "") }}
                            id="bairro"
                            error={errors.bairro ? true : false}
                            name="bairro"
                            type="text"
                            label="Bairro"
                            helperText={errors.bairro}
                            inputProps={{ maxLength: 45 }}
                            className="mt-2"
                          />
                          <InputText
                            value={values.tipoEndereco}
                            onChange={(e) => { handleChange(e); setFieldError("tipoEndereco", "") }}
                            id="tipoEndereco"
                            error={errors.tipoEndereco ? true : false}
                            name="tipoEndereco"
                            type="text"
                            label="Tipo endereço"
                            helperText={errors.tipoEndereco}
                            inputProps={{ maxLength: 15 }}
                            className="mt-2"
                          />
                          <InputText
                            value={values.logEndereco}
                            onChange={(e) => { handleChange(e); setFieldError("logEndereco", "") }}
                            id="logEndereco"
                            error={errors.logEndereco ? true : false}
                            name="logEndereco"
                            type="text"
                            label="Logradouro"
                            helperText={errors.logEndereco}
                            inputProps={{ maxLength: 80 }}
                            className="mt-2"
                          />

                          <InputText
                            value={values.compEndereco}
                            onChange={(e) => { handleChange(e); setFieldError("compEndereco", "") }}
                            id="compEndereco"
                            error={errors.compEndereco ? true : false}
                            name="compEndereco"
                            type="text"
                            label="Complemento"
                            helperText={errors.compEndereco}
                            inputProps={{ maxLength: 80 }}
                            className="mt-2"
                          />
                          <InputText
                            value={values.cidade}
                            onChange={(e) => { handleChange(e); setFieldError("cidade", "") }}
                            id="cidade"
                            error={errors.cidade ? true : false}
                            name="cidade"
                            type="text"
                            label="Cidade"
                            helperText={errors.cidade}
                            inputProps={{ maxLength: 80 }}
                            className="mt-2"
                          />
                          <InputText
                            value={values.uf}
                            onChange={(e) => { handleChange(e); setFieldError("uf", "") }}
                            id="uf"
                            error={errors.uf ? true : false}
                            name="uf"
                            type="text"
                            label="UF"
                            helperText={errors.uf}
                            inputProps={{ maxLength: 80 }}
                            className="mt-2"
                          />
                        </>
                      }
                    </form>
                  )}
                </Formik>
              </>
          }
        </Panel>

        <CustomDialog
          hidden={!isDialogExcludeOpen}
          onDismiss={() => this.setState({ isDialogExcludeOpen: false })}
          dialogContentProps={dialogContentProps}
        >
          <DefaultButton onClick={() => this.setState({ isDialogExcludeOpen: false })} text="Cancelar" />
          <DeleteButton onClick={() => this.excludeUnit()} text="Excluir" />
        </CustomDialog>

        <Wrapper>
          <Drawer
            isOpen={filter.isOpen}
            content={
              <ContainerContent>
                <HeaderPage
                  title="Unidades"
                  leftItems={
                    <>
                      <CommandBarButton
                        styles={btnStyle}
                        iconProps={{ iconName: 'Add' }}
                        disabled={units.loadingData}
                        text="Adicionar Unidade"
                        onClick={() => {
                          initialValues.empresa!.idEmpresa = null
                          initialValues.empresa!.nomeEmpresa = ""
                          this.setState(
                            {
                              isPanelOpen: true,
                              inicialValues: initialValues
                            }
                          )
                        }}
                      />
                      {this.commandBarRender()}
                    </>
                  }
                  rightItems={
                    <>
                      <SearchBox
                        placeholder="Pesquisar"
                        onChange={(e) => this.filter(e?.target.value)}
                        componentRef={this.inputSearch}
                        styles={{
                          root:
                            { border: 'none', width: 200, marginRight: 1 }
                        }}
                      />
                      <CommandBarButton
                        styles={btnStyle}
                        iconProps={{ iconName: 'Refresh' }}
                        text="Atualizar"
                        onClick={() => getUnits()}
                      />
                      <CommandBarButton
                        styles={btnStyle}
                        iconProps={{ iconName: 'filter' }}
                        text="Filtrar"
                        onClick={() => this.toggleFilter()}
                      />
                    </>
                  }
                />
                {!units.loadingData && units.data.length === 0 ?
                  <NoItems
                    error={units.error}
                    text="Não há unidades cadastradas"
                    img="/static/icons/supermarket.svg"
                    alt="Unidades"
                  />
                  :
                  <ListContainer>
                    <ShimmeredDetailsList
                      items={isFiltering ? items : units.data}
                      enableShimmer={units.loadingData}
                      columns={columns}
                      selectionMode={SelectionMode.single}
                      selection={this._selection}
                      getKey={this._getKey}
                      selectionPreservedOnEmptyClick={true}
                      setKey="single"
                      layoutMode={DetailsListLayoutMode.justified}
                      isHeaderVisible={true}
                      onItemInvoked={this._onItemInvoked}
                    />
                  </ListContainer>
                }
              </ContainerContent>
            }>
            <>
              <Stack horizontal horizontalAlign="space-between">
                <Text variant="xLarge">Filtros</Text>
                <Stack horizontal>
                  <IconButton
                    iconProps={{ iconName: 'ChromeClose' }}
                    styles={{ root: { color: colors.darkGray } }}
                    title="Fechar"
                    ariaLabel="Fechar"
                    onClick={() => this.setState({ filter: { ...filter, isOpen: false } })}
                  />
                </Stack>
              </Stack>
              <GroupCheckbox>
                <Text variant="mediumPlus" styles={{ root: { marginBottom: 10 } }}>Empresas</Text>
                {
                  this.renderFilters()
                }
              </GroupCheckbox >
            </>
          </Drawer>
        </Wrapper>

        <PanelDisplay
          open={isPanelDisplayOpen}
          isLoading={false}
          onClose={() => this.setState({ isPanelDisplayOpen: false })}
          panelHeader={
            !units.itemSelected.loading ?
              <PanelHeaderDisplay
                persona={{icon: 'EMI'}}
                title={unitSelected?.nomeUnidade ?? ''}
                isActive={unitSelected?.flagAtiva}
                buttonTabs={[
                  {
                    icon: !unitSelected?.flagAtiva ? "CheckMark" : "Blocked",
                    text: !unitSelected?.flagAtiva ? "Ativar Unidade" : "Inativar Unidade",
                    handleClick: () => { }
                  }
                ]}
              />
              :
              undefined
          }
        >
          {
            units.itemSelected.loading ?
              <Spinner size={SpinnerSize.medium} label="Carregando Unidade" styles={{ root: { height: '100%' } }} />
              :
              <Pivot aria-label="Definições do ciclo" styles={{ root: { marginBottom: 32 } }}>
                <PivotItem headerText="Dados Principais">

                  <GroupItems title="Identificação" hasMultiItems>
                    <ContainerGrups>
                      <GroupItemUmTerco>
                        <TitleField text="ID" />
                        <TitleField text={String(unitSelected?.idEmpresa) ?? ''} isValue />
                      </GroupItemUmTerco>
                      <GroupItemDoisTerco>
                        <TitleField text="Nome" />
                        <TitleField text={unitSelected?.nomeUnidade ?? ''} isValue />
                      </GroupItemDoisTerco>
                    </ContainerGrups>
                    <ContainerGrups>
                      <GroupItemDoisTerco>
                        <TitleField text="Empresa" />
                        <TitleField text={unitSelected?.empresa.nomeEmpresa ?? ''} isValue />
                      </GroupItemDoisTerco>
                    </ContainerGrups>
                    <ContainerGrups
                      link={{
                        text: 'Gerenciar Identificação',
                        handleClick: () => {
                          this.setState({ isPanelEditIdentificationOpen: true, isPanelEditAddressOpen: false, isPanelOpen: true })
                        }
                      }}
                    >
                      <GroupItemDoisTerco>
                        <TitleField text="CNPJ" />
                        <TitleField text={unitSelected?.CNPJ ?? ''} isValue />
                      </GroupItemDoisTerco>
                    </ContainerGrups>
                  </GroupItems>

                  <GroupItems title="Endereço" hasMultiItems>
                    <ContainerGrups>
                      <WrapperGroupItemFull>
                        <TitleField text="Endereço" />
                        <TitleField text={`${unitSelected?.tipoEndereco} ${unitSelected?.logEndereco}, ${unitSelected?.numEndereco} ${unitSelected?.compEndereco} - ${unitSelected?.bairro}`} isValue />
                      </WrapperGroupItemFull>
                    </ContainerGrups>
                    <ContainerGrups
                      link={{
                        text: "Editar Endereço",
                        handleClick: () => this.setState({ isPanelOpen: true, isPanelEditAddressOpen: true, isPanelEditIdentificationOpen: false })
                      }}
                    >
                      <WrapperGroupItemDoisTerco>
                        <TitleField text="Cidade" />
                        <TitleField text={unitSelected?.cidade ?? ''} isValue />
                      </WrapperGroupItemDoisTerco>
                      <GroupItemMini>
                        <TitleField text="UF" />
                        <TitleField text={unitSelected?.uf ?? ''} isValue />
                      </GroupItemMini>
                      <GroupItemMini>
                        <TitleField text="CEP" />
                        <TitleField text={unitSelected?.cep ?? ''} isValue />
                      </GroupItemMini>
                    </ContainerGrups>
                  </GroupItems>
                </PivotItem>
                <PivotItem headerText="Estrutura Salarial">
                  <UnitSalaryStructure unitState={units} />
                </PivotItem>
              </Pivot>
          }
        </PanelDisplay>
      </>
    );
  };

  private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    const { columns } = this.state;
    const newColumns: IColumn[] = columns.slice();
    let items: IUnit[] = [];
    if (this.state.isFiltering) {
      items = this.state.items;
    } else {
      items = this.props.units.data;
    };
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      };
    });
    const newItems = this._sort(items, currColumn.fieldName!, currColumn.isSortedDescending);
    this.setState({
      columns: newColumns,
      items: newItems,
    });
  };

  private _getKey(item: any, index?: number): any {
    if (item !== undefined)
      return item.key;
  };

  private _sort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
    const result = (prop: string, obj: T) => (prop.split('.').reduce((a: any, b: any) => a[b] ?? '', obj))
    return items.sort((a: T, b: T) => ((isSortedDescending ? result(columnKey, a) < result(columnKey, b) : result(columnKey, a) > result(columnKey, b)) ? 1 : -1));
  };

}
const mapStateToProps = (state: RootState) => ({
  units: state.unitsReducer,
  companies: state.companiesReducer
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      ...getUnits,
      ...getUnitById,
      ...addUnit,
      ...delUnit,
      ...editUnit,
      ...getCompanies,
      ...setCurrentPage
    }
    , dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Units);
