import styled from 'styled-components';
import Colors from '~/assets/js/colors';

export const ThAvaliacao = styled.th`
    font-size: 12px;
    box-sizing: border-box;
    border-radius: 2px;
    background-color: ${Colors.black};
    color: #FFFFFF;
    padding: 0 5px;
    font-weight: 600;
    text-align: center;
    height: 25px;
`

export const ThComentarios = styled.th`
    font-size: 12px;
    box-sizing: border-box;
    border-radius: 2px;
    background-color: ${Colors.black};
    color: #FFFFFF;
    padding: 5px;
    font-weight: 600;
    width: 130px;
`

export const TrAtitude = styled.tr`
    height: 60px;
`
export const TdAtitude = styled.td`
    border: 1px solid #CCCCCC;
    border-right: none;
    font-size: 14px;
    font-weight: 600;
    width: 180px;
    max-width: 180px;
    box-sizing: border-box;
    text-align: left;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    padding: 0 10px;
    line-height: 1.5;
`
export const TdDesc = styled.td`
    border: 1px solid #CCCCCC;
    font-size: 11px;
    width: 340px;
    max-width: 340px;
    box-sizing: border-box;
    text-align: left;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    max-height: 60px;
`

export const TextAtitude = styled.p`
    padding: 5px 10px;
    box-sizing: border-box;
    width: 340px;
    height: 52px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
`

export const TrComentario = styled.td`
    font-size: 11px;
    width: 230px;
    box-sizing: border-box;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    max-height: 60px;
    display: flex;
`

export const TextComentario = styled.textarea`
    box-sizing: border-box;
    width: 100%;
    min-width: 100%;
    height: 60px;
    min-height: 60px;
    max-height: 60px;
    padding: 4px;
    border-color: #CCCCCC;
    border-radius: 2px;
    resize: none;
    font-family: 'Segoe UI';
`