import {
  Types,
  DataTypes,
  AdminActionTypes,
  ConfigTipoMovType,
  ConfigJustaCausaType,
} from "./types";
import customToast from "~/components/Toast/index";

/**
 * Reducers
 */
const INITIAL_STATE: DataTypes = {
  tipoMovData: null,
  justaCausaConfig: null,
  error: false,
  loading: false,
  success: false,
};

/**
 * Actions
 */

export const Creators = {
  loadConfigMotivos: (): AdminActionTypes => {
    return {
      type: Types.REQUEST_ADM_LOAD_CONFIG_MOTIVOS,
    };
  },

  editConfigMotivos: (tipoMov: ConfigTipoMovType): AdminActionTypes => {
    return {
      type: Types.REQUEST_ADM_EDIT_CONFIG_MOTIVOS,
      tipoMov,
    };
  },

  loadConfigJustaCausa: (): AdminActionTypes => ({
    type: Types.REQUEST_ADM_LOAD_CONFIG_JUSTA_CAUSA,
  }),

  editConfigJustaCausa: (
    configJustaCausa: ConfigJustaCausaType
  ): AdminActionTypes => ({
    type: Types.REQUEST_ADM_EDIT_CONFIG_JUSTA_CAUSA,
    payload: configJustaCausa,
  }),
};

export default function adminReducer(
  state = INITIAL_STATE,
  action: AdminActionTypes
): DataTypes {
  switch (action.type) {
    //LOAD
    case Types.REQUEST_ADM_LOAD_CONFIG_MOTIVOS:
      return { ...state, loading: true, error: false, success: false };
    case Types.SUCCESS_ADM_LOAD_CONFIG_MOTIVOS:
      return {
        ...state,
        tipoMovData: action.tipoMov,
        loading: false,
        error: false,
        success: true,
      };
    case Types.FAILURE_ADM_LOAD_CONFIG_MOTIVOS:
      customToast.error(action.message);
      return {
        ...state,
        tipoMovData: null,
        loading: false,
        error: true,
        success: false,
      };

    //EDIT
    case Types.REQUEST_ADM_EDIT_CONFIG_MOTIVOS:
      return { ...state, loading: true, error: false, success: false };
    case Types.SUCCESS_ADM_EDIT_CONFIG_MOTIVOS:
      return {
        ...state,
        tipoMovData: action.tipoMov,
        loading: false,
        error: false,
        success: true,
      };
    case Types.FAILURE_ADM_EDIT_CONFIG_MOTIVOS:
      customToast.error(action.message);
      return {
        ...state,
        tipoMovData: null,
        loading: false,
        error: true,
        success: false,
      };

    //LOAD
    case Types.REQUEST_ADM_LOAD_CONFIG_JUSTA_CAUSA:
      return { ...state, loading: true, error: false, success: false };
    case Types.SUCCESS_ADM_LOAD_CONFIG_JUSTA_CAUSA:
      return {
        ...state,
        justaCausaConfig: action.justaCausaConfig,
        loading: false,
        error: false,
        success: true,
      };
    case Types.FAILURE_ADM_LOAD_CONFIG_JUSTA_CAUSA:
      customToast.error(action.message);
      return {
        ...state,
        justaCausaConfig: null,
        loading: false,
        error: true,
        success: false,
      };

    //EDIT
    case Types.REQUEST_ADM_EDIT_CONFIG_JUSTA_CAUSA:
      return { ...state, loading: true, error: false, success: false };
    case Types.SUCCESS_ADM_EDIT_CONFIG_JUSTA_CAUSA:
      return {
        ...state,
        justaCausaConfig: action.payload,
        loading: false,
        error: false,
        success: true,
      };
    case Types.FAILURE_ADM_EDIT_CONFIG_JUSTA_CAUSA:
      customToast.error(action.message);
      return {
        ...state,
        tipoMovData: null,
        loading: false,
        error: true,
        success: false,
      };

    default:
      return state;
  }
}
