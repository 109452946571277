// #region Imports
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Formik } from "formik";
import * as yup from "yup";

//FluentUI
import { Stack } from "office-ui-fabric-react/lib/Stack";
import {
  DetailsListLayoutMode,
  SelectionMode,
  Selection,
  IColumn,
} from "office-ui-fabric-react/lib/DetailsList";
import { ShimmeredDetailsList } from "office-ui-fabric-react/lib/ShimmeredDetailsList";
import { SearchBox } from "office-ui-fabric-react/lib/SearchBox";
import {
  CommandBarButton,
  FontIcon,
  ICommandBarStyles,
  Text,
} from "office-ui-fabric-react";
import { IDialogContentProps } from "office-ui-fabric-react/lib/Dialog";
import { Spinner, SpinnerSize } from "office-ui-fabric-react/lib/Spinner";

import Panel from "~/components/layout/Panel";
import CustomDialog from "~/components/CustomDialogFluentUI";
import Dialog from "~/components/CustomDialog";
import { Page, BreadcrumbItems } from "~/store/ducks/home/types";
import { InputText, InputCheckbox, InputNumber } from "~/components/Forms";

import { DeleteButton } from "~/components/Buttons";
import HeaderPage from "~/components/layout/HeaderPage";
import NoItems from "~/components/layout/NoItems";
import { PrimaryButton } from "~/components/Buttons";
import Dropdown from "~/components/layout/Dropdown";
import MenuItem from "@material-ui/core/MenuItem";

//Estilos
import {
  Wrapper,
  ListContainer,
  DefaultButton,
  ContainerContent,
} from "./styles";

import {
  CategoryPositionLoadDTO,
  CategoryPositionAddOrEditDTO,
  DataTypes as CategoriesPositionsData,
} from "~/store/ducks/admin/categoriesPositions/types";
import { Creators as getCategoriesPositions } from "~/store/ducks/admin/categoriesPositions";
import { Creators as getCategoryPositionById } from "~/store/ducks/admin/categoriesPositions";
import { Creators as addCategoryPosition } from "~/store/ducks/admin/categoriesPositions";
import { Creators as editCategoryPosition } from "~/store/ducks/admin/categoriesPositions";
import { Creators as delCategoryPosition } from "~/store/ducks/admin/categoriesPositions";

import { Creators as setCurrentPage } from "~/store/ducks/home";
import { RootState } from "~/store/ducks";
import Status from "~/components/Status";
//#endregion

interface ICategoriesPositionsState {
  columns: IColumn[];
  items: CategoryPositionLoadDTO[];
  isPanelOpen: boolean;
  inicialValues: CategoryPositionAddOrEditDTO;
  isFiltering: boolean;
  selectionDetails: string;
  selectionCount: number;
  isDialogCompetenceOpen: boolean;
  isDialogTypeOpen: boolean;
  isDialogExcludeOpen: boolean;
  search: string;
  newCompetenceText: string;
  newTypePDIText: string;
}

interface IPropsCategoriesPositions {
  categoriesPositions: CategoriesPositionsData;
  getCategoriesPositions: () => void;
  getCategoryPositionById: (idCategoriaCargo: number) => void;
  addCategoryPosition: (category: CategoryPositionAddOrEditDTO) => void;
  editCategoryPosition: (category: CategoryPositionAddOrEditDTO) => void;
  delCategoryPosition: (idCategoriaCargo: number) => void;
  setCurrentPage: (page: Page) => void;
}

class CategoriesPositions extends Component<
  IPropsCategoriesPositions,
  ICategoriesPositionsState
> {
  private formRef: any;
  private inputSearch: any;
  private _selection: Selection;

  constructor(props: IPropsCategoriesPositions) {
    super(props);
    //#region Colunas
    const columns: IColumn[] = [
      {
        key: "column1",
        name: "",
        ariaLabel: "Status da categoria",
        fieldName: "flagAtivo",
        minWidth: 15,
        maxWidth: 20,
        isResizable: true,
        isSortedDescending: false,
        onRender: (item: CategoryPositionLoadDTO) => (
          <Status status={item.flagAtivo} />
        ),
      },
      {
        key: "column2",
        name: "ID",
        ariaLabel: "ID da categoria",
        fieldName: "idCategoriaCargo",
        isRowHeader: true,
        minWidth: 75,
        maxWidth: 100,
        isResizable: true,
        isSortedDescending: false,
        onColumnClick: this._onColumnClick,
      },
      {
        key: "column3",
        name: "Nível",
        ariaLabel: "Nível da categoria",
        fieldName: "nivel",
        isRowHeader: true,
        minWidth: 75,
        maxWidth: 100,
        isResizable: true,
        isSortedDescending: false,
        onColumnClick: this._onColumnClick,
      },
      {
        key: "column4",
        name: "Descrição",
        fieldName: "descCategoriaCargo",
        minWidth: 150,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "string",
        isPadded: true,
      },
      {
        key: "column5",
        name: "Bônus Target",
        fieldName: "bonusTarget",
        minWidth: 75,
        maxWidth: 75,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "string",
        isPadded: true,
      },
      {
        key: "column6",
        name: "Descontar Falta",
        fieldName: "flagBonusDescontarFaltas",
        minWidth: 75,
        maxWidth: 75,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "string",
        isPadded: true,
        onRender: (item: CategoryPositionLoadDTO) =>
          item.flagBonusDescontarFaltas ? "SIM" : "NÃO",
      },
      {
        key: "column7",
        name: "Eleg. Mérito",
        fieldName: "flagMerito",
        minWidth: 75,
        maxWidth: 75,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "string",
        isPadded: true,
        onRender: (item: CategoryPositionLoadDTO) =>
          item.flagMerito ? "SIM" : "NÃO",
      },
      {
        key: "column8",
        name: "Ign. INPC",
        fieldName: "flagIgnorarINPC",
        minWidth: 50,
        maxWidth: 50,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "string",
        isPadded: true,
        onRender: (item: CategoryPositionLoadDTO) =>
          item.flagIgnorarINPC ? "SIM" : "NÃO",
      },
    ];
    //#endregion

    this.state = {
      columns: columns,
      items: [],
      isPanelOpen: false,
      selectionDetails: "",
      inicialValues: initialCategory,
      isFiltering: false,
      selectionCount: 0,
      isDialogExcludeOpen: false,
      search: "",
      isDialogCompetenceOpen: false,
      isDialogTypeOpen: false,
      newCompetenceText: "",
      newTypePDIText: "",
    };

    this._selection = new Selection({
      onSelectionChanged: () => {
        this.setState({
          selectionDetails: this._getSelectionDetails(),
        });
      },
    });

    this.formRef = React.createRef();
    this.inputSearch = React.createRef();
  }

  componentDidMount() {
    const page: Page = {
      key: "categoriasCargos",
      pages: itemsBreadCrumb,
    };
    this.props.setCurrentPage(page);
    this.props.getCategoriesPositions();
  }

  componentDidUpdate(prevProps: IPropsCategoriesPositions, _: any) {
    if (this.state.isPanelOpen) {
      if (
        prevProps.categoriesPositions.success !==
        this.props.categoriesPositions.success
      ) {
        if (this.props.categoriesPositions.success) {
          this.setState({ isPanelOpen: false });
        }
      }
    }

    if (
      this.props.categoriesPositions.itemSelected.success !==
      prevProps.categoriesPositions.itemSelected.success
    ) {
      if (this.props.categoriesPositions.itemSelected.success) {
        this.setState({
          inicialValues: {
            ...this.props.categoriesPositions.itemSelected.item!,
            flagAtivo: !this.props.categoriesPositions.itemSelected.item
              ?.flagAtivo,
          },
        });
      }
    }

    // if (this.props.pdi.error !== prevProps.pdi.error) {
    //     if (this.props.pdi.error) {
    //         this.setState({ isPanelOpen: false, inicialValues: initialCategory })
    //     }
    // }
  }

  // Submit do formulário Formik
  handleSubmit = () => {
    if (this.formRef.current) {
      this.formRef.current.handleSubmit();
    }
  };

  _onItemInvoked = (item: CategoryPositionLoadDTO): void => {
    this.props.getCategoryPositionById(item.idCategoriaCargo);
    this.setState({
      isPanelOpen: true,
    });
  };

  _getSelectionDetails(): any {
    const selectionCount = this._selection.getSelectedCount();
    this.setState({ selectionCount });
    this._selection.getSelection();
  }

  openEdit = () => {
    const selected = this._selection.getSelection()[0] as CategoryPositionLoadDTO;

    this.props.getCategoryPositionById(selected.idCategoriaCargo);
    this.setState({
      isPanelOpen: true,
    });
  };

  cancelPanel = () => {
    this._selection.setAllSelected(false);
    this.setState({ isPanelOpen: false });
  };

  exclude = () => {
    this.props.delCategoryPosition(
      (this._selection.getSelection()[0] as CategoryPositionLoadDTO)
        .idCategoriaCargo
    );
    this.setState({ isDialogExcludeOpen: false });
    this._selection.setAllSelected(false);
  };

  //#region  Funções do filtro

  handleSearch = (e: any) => {
    const data = this.props.categoriesPositions.data;
    const text = e?.target?.value;

    let items = text
      ? data.filter(
          (item) =>
            item.descCategoriaCargo!.toLowerCase().indexOf(text.toLowerCase()) >
            -1
        )
      : data;

    this.setState({ items, isFiltering: true, search: text });
  };

  //#endregion

  commandBarRender = () => {
    const { selectionCount } = this.state;
    if (selectionCount === 1) {
      return (
        <>
          <CommandBarButton
            styles={btnStyle}
            iconProps={{ iconName: "Edit" }}
            text="Editar"
            onClick={() => this.openEdit()}
          />
          <CommandBarButton
            styles={btnStyle}
            iconProps={{ iconName: "Delete" }}
            text="Excluir"
            onClick={() => this.setState({ isDialogExcludeOpen: true })}
          />
        </>
      );
    }
  };

  render() {
    const {
      isPanelOpen,
      isDialogExcludeOpen,
      inicialValues,
      isFiltering,
      items,
      columns,
      search,
    } = this.state;
    const {
      categoriesPositions,
      getCategoriesPositions,
      addCategoryPosition,
      editCategoryPosition,
    } = this.props;

    return (
      <>
        <Panel
          title={
            !inicialValues?.idCategoriaCargo
              ? "Nova categoria"
              : inicialValues?.descCategoriaCargo
          }
          open={isPanelOpen}
          onClose={() => this.cancelPanel()}
          footer={
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <DefaultButton onClick={() => this.cancelPanel()}>
                Cancelar
              </DefaultButton>
              <PrimaryButton
                onClick={this.handleSubmit}
                isLoading={categoriesPositions.loadingAction}
                text="Salvar"
              />
            </Stack>
          }
        >
          {categoriesPositions.itemSelected.loading ? (
            <Spinner
              size={SpinnerSize.medium}
              styles={{ root: { height: "100%" } }}
            />
          ) : (
            <Formik
              innerRef={this.formRef}
              initialValues={inicialValues}
              validationSchema={validationCategorySchema}
              validateOnChange={false}
              validateOnBlur={true}
              enableReinitialize
              onSubmit={(values: CategoryPositionAddOrEditDTO) => {
                if (!values.flagMerito) {
                  values.flagIgnorarINPC = false;
                }
                if (!values.idCategoriaCargo) {
                  addCategoryPosition(values);
                } else {
                  editCategoryPosition({
                    ...values,
                    flagAtivo: !values.flagAtivo,
                  });
                }
              }}
            >
              {({
                handleSubmit,
                handleChange,
                values,
                errors,
                setFieldValue,
                setFieldError,
              }) => (
                <form
                  onSubmit={handleSubmit}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <InputText
                    value={values.nivel ?? ""}
                    onChange={(e) => {
                      handleChange(e);
                      setFieldError("nivel", "");
                    }}
                    type="number"
                    id="nivel"
                    name="nivel"
                    label="Nível"
                    helperText={errors.nivel}
                    error={!!errors.nivel}
                    autoFocus
                  />
                  <InputText
                    value={values?.descCategoriaCargo ?? ""}
                    onChange={(e) => {
                      handleChange(e);
                      setFieldError("descCategoriaCargo", "");
                    }}
                    id="descCategoriaCargo"
                    error={errors.descCategoriaCargo ? true : false}
                    name="descCategoriaCargo"
                    type="text"
                    label="Descrição"
                    helperText={errors.descCategoriaCargo}
                    className="mt-2"
                  />
                  <InputNumber
                    value={
                      !!values.bonusTarget ? Number(values.bonusTarget) : null
                    }
                    onValueChange={(value) => {
                      setFieldValue("bonusTarget", value.floatValue);
                      setFieldError("bonusTarget", "");
                    }}
                    id="bonusTarget"
                    name="bonusTarget"
                    label="Bônus Target"
                    helperText={errors.bonusTarget}
                    error={!!errors.bonusTarget}
                  />
                  <InputCheckbox
                    checked={values.flagBonusDescontarFaltas ?? false}
                    onChange={handleChange}
                    name="flagBonusDescontarFaltas"
                    color="primary"
                    label="Descontar faltas do Bônus"
                  />
                  <InputCheckbox
                    checked={values.flagMerito ?? false}
                    onChange={(e) => {
                      handleChange(e);
                      if (!e.target.checked) {
                        setFieldValue("flagIgnorarINPC", false);
                      }
                    }}
                    name="flagMerito"
                    color="primary"
                    label="Elegível ao Mérito"
                  />
                  <InputCheckbox
                    checked={values.avaliacaoColetiva ?? false}
                    onChange={(e) => {
                      handleChange(e);
                      if (!e.target.checked) {
                        setFieldValue("avaliacaoColetiva", false);
                      }
                    }}
                    name="avaliacaoColetiva"
                    color="primary"
                    label="Avaliação Coletiva"
                  />
                  {values.flagMerito && (
                    <InputCheckbox
                      checked={values.flagIgnorarINPC ?? false}
                      onChange={handleChange}
                      name="flagIgnorarINPC"
                      color="primary"
                      label="Ignorar INPC no Mérito"
                    />
                  )}

                  {values.idCategoriaCargo && (
                    <InputCheckbox
                      checked={values.flagAtivo ?? false}
                      onChange={handleChange}
                      name="flagAtivo"
                      color="primary"
                      label="Inativar"
                    />
                  )}
                </form>
              )}
            </Formik>
          )}
        </Panel>

        <Wrapper>
          <ContainerContent>
            <HeaderPage
              title="Categorias de Cargo"
              leftItems={
                <>
                  <CommandBarButton
                    styles={btnStyle}
                    iconProps={{ iconName: "Add" }}
                    disabled={categoriesPositions.loadingData}
                    text="Adicionar Categoria"
                    onClick={() =>
                      this.setState({
                        isPanelOpen: true,
                        inicialValues: initialCategory,
                      })
                    }
                  />
                  {this.commandBarRender()}
                </>
              }
              rightItems={
                <>
                  <SearchBox
                    placeholder="Pesquisar"
                    value={search}
                    onChange={(e) => this.handleSearch(e)}
                    // onKeyUp={(e) => this.filter(e.key)}
                    componentRef={this.inputSearch}
                    styles={{
                      root: {
                        border: "none",
                        width: 200,
                        marginRight: 1,
                      },
                    }}
                  />
                  <CommandBarButton
                    styles={btnStyle}
                    iconProps={{ iconName: "Refresh" }}
                    text="Atualizar"
                    onClick={() => getCategoriesPositions()}
                  />
                </>
              }
            />
            {!categoriesPositions.loadingData &&
            categoriesPositions.data.length === 0 ? (
              <NoItems
                error={categoriesPositions.error}
                text="Não há categorias de cargos cadastradas"
                icon="Work"
              />
            ) : (
              <ListContainer>
                <ShimmeredDetailsList
                  items={isFiltering ? items : categoriesPositions.data}
                  enableShimmer={categoriesPositions.loadingData}
                  columns={columns}
                  selectionMode={SelectionMode.single}
                  selection={this._selection}
                  getKey={this._getKey}
                  selectionPreservedOnEmptyClick={true}
                  setKey="single"
                  layoutMode={DetailsListLayoutMode.justified}
                  isHeaderVisible={true}
                  onItemInvoked={this._onItemInvoked}
                />
              </ListContainer>
            )}
          </ContainerContent>
        </Wrapper>

        <CustomDialog
          hidden={!isDialogExcludeOpen}
          onDismiss={() => this.setState({ isDialogExcludeOpen: false })}
          dialogContentProps={dialogContentProps}
        >
          <DefaultButton
            onClick={() => this.setState({ isDialogExcludeOpen: false })}
            text="Cancelar"
          />
          <DeleteButton onClick={() => this.exclude()} text="Excluir" />
        </CustomDialog>
      </>
    );
  }

  private _onColumnClick = (
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn
  ): void => {
    const { columns } = this.state;
    const newColumns: IColumn[] = columns.slice();
    let items: CategoryPositionLoadDTO[] = [];
    if (this.state.isFiltering) {
      items = this.state.items;
    } else {
      items = this.props.categoriesPositions.data;
    }
    const currColumn: IColumn = newColumns.filter(
      (currCol) => column.key === currCol.key
    )[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    const newItems = this._sort(
      items,
      currColumn.fieldName!,
      currColumn.isSortedDescending
    );
    this.setState({
      columns: newColumns,
      items: newItems,
    });
  };

  private _getKey(item: any, index?: number): any {
    if (item !== undefined) return item.key;
  }

  private _sort<T>(
    items: T[],
    columnKey: string,
    isSortedDescending?: boolean
  ): T[] {
    const result = (prop: string, obj: T) =>
      prop.split(".").reduce((a: any, b: any) => a[b] ?? "", obj);
    return items.sort((a: T, b: T) =>
      (
        isSortedDescending
          ? result(columnKey, a) < result(columnKey, b)
          : result(columnKey, a) > result(columnKey, b)
      )
        ? 1
        : -1
    );
  }
}

/**
 * Validação do formulário
 */
const validationCategorySchema = yup.object().shape({
  descCategoriaCargo: yup
    .string()
    .max(50, "Máximo de 50 caracteres")
    .required("Campo obrigatório"),
  nivel: yup.number().min(0).nullable().required("Campo obrigatório"),
  bonusTarget: yup.number().min(0).nullable().required("Campo obrigatório"),
  flagBonusDescontarFaltas: yup.bool().required("Campo obrigatório"),
  flagMerito: yup.bool().required("Campo obrigatório"),
  flagIgnorarINPC: yup.bool().required("Campo obrigatório"),
  flagAtivo: yup.bool(),
});

const dialogContentProps: IDialogContentProps = {
  title: "Excluir?",
  closeButtonAriaLabel: "Close",
  subText: "Tem certeza de que deseja excluir este item?",
};

const itemsBreadCrumb: BreadcrumbItems[] = [
  {
    text: "Home",
    isCurrentItem: false,
    icon: "HomeSolid",
    onlyIcon: true,
    url: "/",
  },
  { text: "Categorias de Cargo", isCurrentItem: true },
];

const initialCategory: CategoryPositionAddOrEditDTO = {
  descCategoriaCargo: "",
  bonusTarget: null,
  flagAtivo: true,
  flagBonusDescontarFaltas: false,
  nivel: null,
  flagIgnorarINPC: false,
  flagMerito: false,
  avaliacaoColetiva: false,
};

const btnStyle: Partial<ICommandBarStyles> = {
  root: {
    height: 44,
  },
};

const mapStateToProps = (state: RootState) => ({
  categoriesPositions: state.categoriesPositionsReducer,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      ...getCategoriesPositions,
      ...getCategoryPositionById,
      ...addCategoryPosition,
      ...editCategoryPosition,
      ...delCategoryPosition,
      ...setCurrentPage,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CategoriesPositions);
