import { Types, DataTypes, CycleAttitudesActionTypes, CycloAttitudeType } from "./types";
import customToast from "~/components/Toast/index";

/**
 * Reducers
 */
const INITIAL_STATE: DataTypes = {
  data: [],
  loading: false,
  loadingAction: false,
  success: false,
  error: false
};

/**
 * Actions
 */

export const Creators = {
  getCycleAttitudes: (idCiclo: number): CycleAttitudesActionTypes => ({
    type: Types.REQUEST_CYCLE_ATTITUDES_LOAD,
    idCiclo
  }),

  addCycleAttitude: (idCiclo: number, idAtitude: number, ordem: number | null, nomeAtitude: string, flagLideranca: boolean | null): CycleAttitudesActionTypes => ({
    type: Types.REQUEST_CYCLE_ATTITUDES_ADD,
    payload: {
      idCiclo,
      idAtitude,
      ordem,
      flagLideranca,
      atitude: {
        nomeAtitude
      }
    }
  }),

  editFlagLiderancaCycleAttitude: (idCiclo: number, idCicloAtitude: number, flagLideranca: boolean): CycleAttitudesActionTypes => ({
    type: Types.REQUEST_CYCLE_ATTITUDES_EDIT,
    payload: {
      idCiclo,
      idCicloAtitude,
      flagLideranca
    }
  }),

  delCycleAttitude: (idCiclo: number, idCicloAtitude: number): CycleAttitudesActionTypes => ({
    type: Types.REQUEST_CYCLE_ATTITUDES_DEL,
    payload: {
      idCiclo,
      idCicloAtitude
    }
  }),

  nextAttitude: (idCiclo: number, idCicloAtitude: number): CycleAttitudesActionTypes => ({
    type: Types.REQUEST_CYCLE_ATITUDES_NEXT,
    payload: {
      idCiclo,
      idCicloAtitude
    }
  }),

  priorAttitude: (idCiclo: number, idCicloAtitude: number): CycleAttitudesActionTypes => ({
    type: Types.REQUEST_CYCLE_ATITUDES_PRIOR,
    payload: {
      idCiclo,
      idCicloAtitude
    }
  })
};


export default function cycleAttitudesReducer(state = INITIAL_STATE, action: CycleAttitudesActionTypes): DataTypes {

  switch (action.type) {
    case Types.REQUEST_CYCLE_ATTITUDES_LOAD:
      return { ...state, loading: true, error: false, success: false };
    case Types.SUCCESS_CYCLE_ATTITUDES_LOAD:
      return { ...state, data: action.payload, loading: false, error: false, success: true };
    case Types.FAILURE_CYCLE_ATTITUDES_LOAD:
      customToast.error(action.message);
      return { ...state, data: [], loading: false, error: true, success: false };

    case Types.REQUEST_CYCLE_ATTITUDES_ADD:
      return { ...state, success: false, loadingAction: true };
    case Types.SUCCESS_CYCLE_ATTITUDES_ADD:
      return {
        ...state, data: addCycleAttitude(state.data, action.payload), success: true, loadingAction: false
      };
    case Types.FAILURE_CYCLE_ATTITUDES_ADD:
      customToast.error(action.message);
      return { ...state, loadingAction: false, success: false };

    case Types.REQUEST_CYCLE_ATTITUDES_EDIT:
      return {...state, success: false, loadingAction: true};
    case Types.SUCCESS_CYCLE_ATTITUDES_EDIT:
      return {
        ...state, 
        success: true,
        loadingAction: false,
        data: state.data.map(item => {
          if (item.idCicloAtitude !== action.payload.idCicloAtitude) {
            return item;
          } else {
            return { ...item, flagLideranca: action.payload.flagLideranca ?? false }
          }
        })
      };
    case Types.FAILURE_CYCLE_ATTITUDES_EDIT:
      customToast.error(action.message);
      return {...state, success: false, loadingAction: false};


    case Types.REQUEST_CYCLE_ATTITUDES_DEL:
      return { ...state, success: false };
    case Types.SUCCESS_CYCLE_ATTITUDES_DEL:
      return { ...state, data: delCycleAttitude(state.data, action.idCicloAtitude), success: true };
    case Types.FAILURE_CYCLE_ATTITUDES_DEL:
      customToast.error(action.message);
      return { ...state, loading: false, success: false };

    case Types.REQUEST_CYCLE_ATITUDES_NEXT:
      return state;
    case Types.SUCCESS_CYCLE_ATITUDES_NEXT:
      return { ...state, data: moveAttitude(state.data, action.idCicloAtitude, 'next') };
    case Types.FAILURE_CYCLE_ATITUDES_NEXT:
      customToast.error(action.message);
      return state;

    case Types.REQUEST_CYCLE_ATITUDES_PRIOR:
      return state;
    case Types.SUCCESS_CYCLE_ATITUDES_PRIOR:
      return { ...state, data: moveAttitude(state.data, action.idCicloAtitude, 'prior') };
    case Types.FAILURE_CYCLE_ATITUDES_PRIOR:
      customToast.error(action.message);
      return state;

    default:
      return state;
  }
}

const addCycleAttitude = (allAttitudes: CycloAttitudeType[], attitude: CycloAttitudeType): CycloAttitudeType[] => {
  const { ordem } = attitude;
  if (ordem !== null && ordem <= allAttitudes.length) {
    for (let i = ordem - 1; i < allAttitudes.length; i++) {
      allAttitudes[i].ordem = allAttitudes[i].ordem + 1;
    }

    return [...allAttitudes, attitude].sort((a, b) => a.ordem - b.ordem);

  } else {
    return [...allAttitudes, { ...attitude, ordem: allAttitudes.length + 1 }]
  }
}

const delCycleAttitude = (allAttitudes: CycloAttitudeType[], idCicloAtitude: number): CycloAttitudeType[] => {
  const ordem = allAttitudes.find(item => item.idCicloAtitude === idCicloAtitude)!.ordem;

  if (ordem <= allAttitudes.length) {
    for (let i = ordem - 1; i < allAttitudes.length; i++) {
      allAttitudes[i].ordem = allAttitudes[i].ordem - 1;
    }

    return [...allAttitudes].filter(item => item.idCicloAtitude !== idCicloAtitude).sort((a, b) => a.ordem - b.ordem);

  } else {
    return [...allAttitudes].filter(item => item.idCicloAtitude !== idCicloAtitude);
  }
}

const moveAttitude = (cicloAtitudes: CycloAttitudeType[], idCicloAtitude: number, direction: "next" | "prior"): CycloAttitudeType[] => {
  const atitudeAtual = cicloAtitudes.find(item => item.idCicloAtitude === idCicloAtitude);
  const indexAtitudeAtual = cicloAtitudes.findIndex(item => item.idCicloAtitude === idCicloAtitude);
  if (direction === 'next') {
    atitudeAtual!.ordem = atitudeAtual?.ordem! + 1 ?? atitudeAtual?.ordem;
    const atitudeNext = cicloAtitudes[indexAtitudeAtual + 1];
    return cicloAtitudes.map((cicloAtitude, i) => {
      if (indexAtitudeAtual === i) {
        return { ...atitudeNext, ordem: atitudeNext.ordem - 1 };
      } if (indexAtitudeAtual + 1 === i) {
        return atitudeAtual ?? cicloAtitude;
      } else {
        return cicloAtitude;
      }
    })
  } else {
    atitudeAtual!.ordem = atitudeAtual?.ordem! - 1 ?? atitudeAtual?.ordem;
    const atitudePrior = cicloAtitudes[indexAtitudeAtual - 1]
    return cicloAtitudes.map((cicloAtitude, i) => {
      if (indexAtitudeAtual - 1 === i) {
        return atitudeAtual ?? cicloAtitude;
      } else if (indexAtitudeAtual === i) {
        return { ...atitudePrior!, ordem: atitudePrior!.ordem + 1 };
      } else {
        return cicloAtitude;
      }
    })
  }
}