import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { Formik } from "formik"
import { RootState } from "~/store/ducks"
import { Link } from "office-ui-fabric-react";
import { useSelector, useDispatch } from "react-redux";
import { ActionButton } from 'office-ui-fabric-react';
import colors from "~/assets/js/colors";

import { PrimaryButton } from '~/components/Buttons'

import {
  Container,
  Content,
  Card,
  CardContent,
  RomasoftLogo,
  Title,
  ImageContainer,
  InputContainer,
  Input,
  ForgotLogin,
  ButtonContainer,
  ErrorMessage,
} from "./styles";

import { Creators as LoginActions } from "~/store/ducks/login";
import { DataTypes } from "~/store/ducks/login/types"

const emailSchema = yup.object().shape({
  email: yup
    .string()
    .email("E-mail inválido")
    .required("E-mail obrigatório"),
})

const passwordSchema = yup.object().shape({
  password: yup
    .string()
    .required("Senha obrigatória")
})

const newPasswordSchema = yup.object().shape({
  newPassword: yup
    .string()
    .required("Senha obrigatória"),

  confirmPassword: yup.string()
    .oneOf([yup.ref('newPassword'), undefined], 'A senha não confere').required('Confirmação obrigatória')
})

function AcessarScreen() {
  const [validEmail, setValidEmail] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState(false);
  const containerRef: any = React.useRef();
  const { current } = containerRef;

  const history = useHistory();

  const loginState = useSelector<RootState, DataTypes>(state => state.loginReducer)
  const dispatch = useDispatch()

  useEffect(() => {
    setNewPassword(false)
  }, [current]);

  useEffect(() => {
    dispatch(LoginActions.clearError());
  }, [dispatch])

  useEffect(() => {
    setNewPassword(false)
  }, [])

  useEffect(() => {
    setNewPassword(loginState?.RequestNovaSenha ?? false)
  }, [loginState.RequestNovaSenha])

  const login = () => {
    return (
      <CardContent>
        <Title>Entrar</Title>
        {/* {email.error && <ErrorMessage>{errorMessage}</ErrorMessage>} */}
        <Formik
          initialValues={{ email: "" }}
          initialErrors={{ email: "" }}
          validationSchema={emailSchema}
          validateOnChange={false}
          onSubmit={values => {
            dispatch(LoginActions.clearError())
            setEmail(values.email)
            setValidEmail(true)
          }}
        >
          {({ handleSubmit, handleChange, values, errors, setFieldError }) => (
            <form onSubmit={handleSubmit}>
              <InputContainer fixed>
                <Input
                  error={errors.email ? true : false}
                  id="email"
                  name="email"
                  label="Seu e-mail"
                  value={values.email}
                  className="mt-2"
                  autoFocus
                  helperText={errors.email ? errors.email : null}
                  onChange={(e) => { handleChange(e); setFieldError("email", "") }}
                  spellCheck="false"
                />
              </InputContainer>
              {/* <ForgotLogin>
                <Link onClick={() => history.push("/recuperar/conta")}>
                  Esqueceu seu login?
                </Link>
              </ForgotLogin> */}
              <ButtonContainer>
                <PrimaryButton text="Próximo" type="submit" styles={{ root: { width: 100 } }} />
              </ButtonContainer>
            </form>
          )}
        </Formik>
      </CardContent>
    )
  }

  const passwordRender = () => (
    <CardContent>
      <ActionButton iconProps={{ iconName: 'Back' }} text={`${email}`} styles={{ label: { fontSize: "1rem", color: colors.darkGray }, icon: { color: colors.darkGray, marginLeft: 0 }, root: { paddingLeft: 23, width: 'fit-content' } }} onClick={() => setValidEmail(false)} />
      <Title marginTop={"20px"} >Insira a Senha</Title>
      {loginState.error ? <ErrorMessage>{loginState.error}</ErrorMessage> : null}
      <Formik
        initialValues={{ password: "" }}
        validationSchema={passwordSchema}
        onSubmit={(values) => {
          setPassword(values.password)
          dispatch(LoginActions.signIn(email, values.password))
        }}
      >
        {({ handleSubmit, handleChange, values, errors }) => (
          <form onSubmit={handleSubmit}>
            <InputContainer fixed>
              <Input
                autoFocus
                error={errors.password ? true : false}
                id="password"
                name="password"
                label="Senha"
                value={values.password}
                autoComplete="username new-password"
                type="password"
                className="mt-2"
                helperText={errors.password ? errors.password : null}
                onChange={handleChange}
              />
            </InputContainer>
            <ForgotLogin>
              <Link onClick={() => history.push({ pathname: "/recuperar/senha", state: { email } })}>
                Esqueci minha senha?
              </Link>
            </ForgotLogin>
            <ButtonContainer>
              <PrimaryButton text="Entrar" isLoading={loginState.loading} type="submit" styles={{ root: { width: 100 } }} onClick={e => { e.preventDefault(); handleSubmit() }} />
            </ButtonContainer>
          </form>
        )}
      </Formik>
    </CardContent>
  )

  const RequestNewPasswordRender: React.FC = () => (
    <CardContent>
      <ActionButton
        iconProps={{ iconName: 'Back' }}
        text={`${email}`}
        styles={{
          label: {
            fontSize: "1rem",
            color: colors.darkGray
          },
          icon: {
            color: colors.darkGray,
            marginLeft: 0
          },
          root: {
            paddingLeft: 23,
            width: 'fit-content'
          }
        }} onClick={() => {
          setNewPassword(false);
          setValidEmail(false);
        }} />
      <Title marginTop={"20px"} >Insira a nova senha</Title>
      <Formik
        initialValues={{ newPassword: "", confirmPassword: "" }}
        validationSchema={newPasswordSchema}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={(values) => {
          dispatch(LoginActions.signInNewPassword(email, password, values.newPassword))
        }}
      >
        {({ handleSubmit, handleChange, values, errors }) => (
          <form onSubmit={handleSubmit}>
            <InputContainer fixed>
              <Input
                autoFocus
                error={errors.newPassword ? true : false}
                id="newPassword"
                name="newPassword"
                label="Nova senha"
                value={values.newPassword}
                autoComplete="password"
                type="password"
                className="mt-2"
                helperText={errors.newPassword ? errors.newPassword : null}
                onChange={handleChange}
              />
            </InputContainer>
            <InputContainer fixed>
              <Input
                error={errors.confirmPassword ? true : false}
                id="confirmPassword"
                name="confirmPassword"
                label="Confirmação da nova senha"
                value={values.confirmPassword}
                autoComplete="password"
                type="password"
                className="mt-2"
                helperText={errors.confirmPassword ? errors.confirmPassword : null}
                onChange={handleChange}
              />
            </InputContainer>
            <ButtonContainer>
              <PrimaryButton text="Entrar" isLoading={loginState.loading} type="submit" styles={{ root: { width: 100 } }} onClick={e => { e.preventDefault(); handleSubmit() }} />
            </ButtonContainer>
          </form>
        )}
      </Formik>
    </CardContent>
  )

  return (
    <div ref={containerRef}>
      <Container>
        <Content>
          <Card>
            <ImageContainer>
              <RomasoftLogo src="./static/img/st-marche-green.png" />
            </ImageContainer>
            {
              newPassword ?
                <RequestNewPasswordRender />
                :
                <>
                  {validEmail ? passwordRender() : login()}
                </>
            }
          </Card>
        </Content>
      </Container>
    </div>
  );
}

export default AcessarScreen;
