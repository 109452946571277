/**
 * Types
 */
export enum Types {
  REQUEST_LOAD_CARREIRAS = "carreira/REQUEST_LOAD_CARREIRAS",
  SUCCESS_LOAD_CARREIRAS = "carreira/SUCCESS_LOAD_CARREIRAS",
  FAILURE_LOAD_CARREIRAS = "carreira/FAILURE_LOAD_CARREIRAS",
}

export type CarreiraLoadDTO = {
  idCarreira: number;
  nomeCarreira: string;
};

interface ICarreiraState {
  readonly data: CarreiraLoadDTO[];
  readonly loading: boolean;
  readonly success: boolean;
  readonly error: boolean;
}

export type DataTypes = ICarreiraState;

/**
 * Actions
 */

// LOAD
export interface IRequestGetCarreirasAction {
  type: typeof Types.REQUEST_LOAD_CARREIRAS;
  search?: string;
}

export interface ISuccessGetCarreirasAction {
  type: typeof Types.SUCCESS_LOAD_CARREIRAS;
  payload: CarreiraLoadDTO[];
}

export interface IFailureGetCarreirasAction {
  type: typeof Types.FAILURE_LOAD_CARREIRAS;
  message: string;
}

type CarreirasActionGetActions =
  | IRequestGetCarreirasAction
  | ISuccessGetCarreirasAction
  | IFailureGetCarreirasAction;

export type CarreirasActionTypes = CarreirasActionGetActions;
