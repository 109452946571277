import React, { Component } from "react";
import { Formik } from "formik";
import * as yup from "yup";

import {
  DefaultButton,
  DetailsListLayoutMode,
  DetailsRow,
  IColumn,
  IDetailsListProps,
  IDetailsRowStyles,
  ShimmeredDetailsList,
  Stack,
  Text,
} from "@fluentui/react";
import { Selection, SelectionMode } from "office-ui-fabric-react";
import { Dialog, DialogFooter } from "office-ui-fabric-react/lib/Dialog";

import { ListContainer } from "./styles";
import { MetaMonthType, MetaType } from "~/store/ducks/cycle/painel/types";
import moment from "moment";
import "moment/locale/pt-br";
import { calcMax, CalculaAting, renderAvaliacao } from ".";
import { PrimaryButton } from "~/components/Buttons";
import { InputNumber, InputText } from "~/components/Forms";

import { convertToNumber } from "~/utils/texts";
import { FormControlLabel, InputAdornment, Switch } from "@material-ui/core";
import { toCurrency } from "~/utils/numbers";

moment.locale("pt-br");

interface IFormaMetaMonthsProps {
  months: MetaMonthType[];
  unidade: string;
  isCurrency: boolean;
  objetivo: MetaType["objetivo"];
  editMonth: (month: MetaMonthType) => void;
}

interface IFormMetaMonthsState {
  columns: IColumn[];
  selectionDetails: string;
  isOpenAlteracao: boolean;
  itemSelected: MetaMonthType;
}

class FormMetaMonths extends Component<
  IFormaMetaMonthsProps,
  IFormMetaMonthsState
> {
  private _selection: any;

  constructor(props: IFormaMetaMonthsProps) {
    super(props);

    const columns: IColumn[] = [
      {
        key: "column1",
        name: "Mês",
        ariaLabel: "Mês",
        fieldName: "referencia",
        isRowHeader: true,
        minWidth: 30,
        maxWidth: 30,
        onRender: (item: MetaMonthType) =>
          moment()
            .month(item.mes - 1)
            .format("MMM")
            .toUpperCase(),
      },
      {
        key: "column2",
        name: `Mínimo ${this.props.unidade === "P" ? "(%)" : ""}`,
        fieldName: "minimo",
        minWidth: 110,
        maxWidth: 110,
        isRowHeader: true,
        data: "string",
        styles: { cellTitle: { justifyContent: "flex-end" } },
        onRender: (item: MetaMonthType) => formatValueMonth(item.minimo),
      },
      {
        key: "column3",
        name: `Target ${this.props.unidade === "P" ? "(%)" : ""}`,
        fieldName: "target",
        minWidth: 110,
        maxWidth: 110,
        isRowHeader: true,
        data: "string",
        styles: { cellTitle: { justifyContent: "flex-end" } },
        onRender: (item: MetaMonthType) => formatValueMonth(item.target),
      },
      {
        key: "column4",
        name: `Máximo ${this.props.unidade === "P" ? "(%)" : ""}`,
        fieldName: "maximo",
        minWidth: 110,
        maxWidth: 110,
        isRowHeader: true,
        data: "string",
        styles: { cellTitle: { justifyContent: "flex-end", opacity: 0.5 } },
        onRender: (item: MetaMonthType) => formatValueMonth(item.maximo),
      },
      {
        key: "column5",
        name: `Resultado ${this.props.unidade === "P" ? "(%)" : ""}`,
        fieldName: "resultado",
        minWidth: 110,
        maxWidth: 110,
        isRowHeader: true,
        data: "string",
        styles: { cellTitle: { justifyContent: "flex-end" } },
        onRender: (item: MetaMonthType) =>
          Number.isFinite(parseFloat(item.resultado?.toString() ?? "")) && (
            <Stack verticalAlign="center" horizontalAlign="end" horizontal>
              {renderAvaliacao(
                CalculaAting(
                  Number(item.minimo),
                  Number(item.target),
                  Number(item.maximo),
                  Number(item.resultado),
                  false
                ).avaliacao,
                false
              )}
              {formatValueMonth(item.resultado)}
            </Stack>
          ),
      },
    ];

    this.state = {
      columns,
      selectionDetails: "",
      isOpenAlteracao: false,
      itemSelected: {
        idCicloMetaMes: null,
        idCicloMeta: null,
        minimo: null,
        target: null,
        maximo: null,
        avaliacao: null,
        referencia: "",
        resultado: null,
        mes: 0,
      },
    };

    this._selection = new Selection({
      onSelectionChanged: () => {
        this.setState({
          selectionDetails: this._getSelectionDetails(),
        });
      },
    });
  }

  _getSelectionDetails(): any {
    this._selection.getSelection();
  }

  _onItemInvoked = (item: MetaMonthType): void => {
    this.setState({ itemSelected: item });
    this.handleOpenAlteracao();
  };

  handleOpenAlteracao = () => {
    this.setState({ isOpenAlteracao: true });
  };

  handleCloseAlteracao = () => {
    this.setState({ isOpenAlteracao: false });
  };

  render() {
    const { columns, isOpenAlteracao, itemSelected } = this.state;
    const { unidade, isCurrency, objetivo, months, editMonth } = this.props;
    return (
      <>
        <ListContainer>
          <ShimmeredDetailsList
            items={months}
            enableShimmer={false}
            columns={columns}
            selectionMode={SelectionMode.none}
            selection={this._selection}
            getKey={this._getKey}
            selectionPreservedOnEmptyClick={true}
            setKey="single"
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible={true}
            onItemInvoked={this._onItemInvoked}
            onRenderRow={this._onRenderRow}
          />
        </ListContainer>
        <Dialog
          hidden={!isOpenAlteracao}
          dialogContentProps={dialogContentProps}
          minWidth={420}
        >
          <Formik
            initialValues={{
              ...itemSelected,
              habilitarResultado:
                itemSelected.resultado !== null ? true : false,
            }}
            validationSchema={validationDefineGoalSchema}
            validateOnChange={false}
            enableReinitialize
            validateOnBlur={true}
            validate={(values) => {
              const { minimo, target } = values;
              const errors: any = {};

              const minimoNum = convertToNumber(minimo ?? "");
              const targetNum = convertToNumber(target ?? "");

              if (target !== "") {
                if (
                  objetivo.mecanicaCalculo === "-" &&
                  minimoNum <= targetNum
                ) {
                  errors.target =
                    "O target não pode ser maior ou igual que o valor mínimo";
                } else if (
                  objetivo.mecanicaCalculo === "+" &&
                  minimoNum >= targetNum
                ) {
                  errors.target =
                    "O target não pode ser menor ou igual que o valor mínimo";
                }
              }
              return errors;
            }}
            onSubmit={(values) => {
              if (
                Number.isFinite(parseFloat(values.resultado?.toString() ?? ""))
              ) {
                values.avaliacao = CalculaAting(
                  Number(values.minimo),
                  Number(values.target),
                  Number(values.maximo),
                  Number(values.resultado),
                  false
                ).avaliacao;
              }else{
                values.resultado = null
              }

              editMonth(values);

              this.handleCloseAlteracao();
            }}
          >
            {({
              handleSubmit,
              handleChange,
              values,
              errors,
              setFieldValue,
              setFieldError,
            }) => (
              <form onSubmit={handleSubmit}>
                <InputText
                  value={moment()
                    .month(itemSelected.mes)
                    .format("MMMM")
                    .toUpperCase()}
                  type="text"
                  label="Referência"
                  disabled={true}
                />
                {isCurrency ? (
                  <>
                    <InputNumber
                      value={!!values.minimo ? Number(values.minimo) : null}
                      onValueChange={(value) => {
                        setFieldValue(
                          "maximo",
                          calcMax(
                            value.floatValue ?? 0,
                            values.target ?? "",
                            objetivo
                          )
                        );
                        setFieldValue("minimo", value.floatValue);
                        setFieldError("minimo", "");
                      }}
                      id="minimo"
                      name="minimo"
                      label="Mínimo"
                      helperText={errors.minimo}
                      error={errors.minimo ? true : false}
                      autoFocus
                    />
                    <InputNumber
                      value={!!values.target ? Number(values.target) : null}
                      onValueChange={(value) => {
                        setFieldValue(
                          "maximo",
                          calcMax(
                            values.minimo ?? "",
                            value.floatValue ?? 0,
                            objetivo
                          )
                        );
                        setFieldValue("target", value.floatValue);
                        setFieldError("target", "");
                      }}
                      id="target"
                      name="target"
                      label="Target"
                      helperText={errors.target}
                      error={!!errors.target}
                    />
                    <InputNumber
                      value={Number(values.maximo)}
                      id="maximo"
                      name="maximo"
                      label="Máximo"
                      helperText={errors.maximo}
                      error={!!errors.maximo}
                      disabled={true}
                    />
                  </>
                ) : (
                  <>
                    <InputText
                      value={values.minimo ?? ""}
                      onChange={(e) => {
                        setFieldValue(
                          "maximo",
                          calcMax(e.target.value, values.target ?? "", objetivo)
                        );
                        handleChange(e);
                        setFieldError("minimo", "");
                      }}
                      id="minimo"
                      error={errors.minimo ? true : false}
                      name="minimo"
                      type="number"
                      label="Mínimo"
                      className="mt-2"
                      autoFocus
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            style={{ paddingRight: 20 }}
                          >
                            {unidade === "P" ? "%" : ""}
                          </InputAdornment>
                        ),
                        autoComplete: "off",
                      }}
                      helperText={errors.minimo}
                    />
                    <InputText
                      value={values.target ?? ""}
                      onChange={(e: any) => {
                        setFieldValue(
                          "maximo",
                          calcMax(values.minimo ?? "", e.target.value, objetivo)
                        );
                        handleChange(e);
                        setFieldError("target", "");
                      }}
                      id="target"
                      error={errors.target ? true : false}
                      name="target"
                      type="number"
                      label="Target"
                      className="mt-2"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            style={{ paddingRight: 20 }}
                          >
                            {unidade === "P" ? "%" : ""}
                          </InputAdornment>
                        ),
                        autoComplete: "off",
                      }}
                      helperText={errors.target}
                    />
                    <InputText
                      value={values.maximo ?? ""}
                      onChange={handleChange}
                      id="maximo"
                      error={errors.maximo ? true : false}
                      name="maximo"
                      type="text"
                      label="Máximo"
                      className="mt-2"
                      disabled={true}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            style={{ paddingRight: 20 }}
                          >
                            {unidade === "P" ? "%" : ""}
                          </InputAdornment>
                        ),
                      }}
                      helperText={errors.maximo}
                    />
                  </>
                )}
                <FormControlLabel
                  style={{ padding: "5px 0 15px" }}
                  control={
                    <Switch
                      size="small"
                      type="checkbox"
                      checked={values.habilitarResultado}
                      onChange={(e) => {
                        handleChange(e);
                        if (!e.target.checked) {
                          setFieldValue("resultado", null);
                          setFieldValue("ating", null);
                        }
                      }}
                      name="habilitarResultado"
                      id="habilitarResultado"
                      color="primary"
                      //   disabled={values.flagInativo ?? false}
                    />
                  }
                  label="Habilitar Resultado"
                />

                <div className="ms-Grid-row">
                  <Stack horizontal>
                    {isCurrency ? (
                      <InputNumber
                        value={
                          values.habilitarResultado &&
                          !!Number.isFinite(
                            parseFloat(values.resultado?.toString() ?? "")
                          )
                            ? Number(values.resultado) ?? ""
                            : ""
                        }
                        onValueChange={(value) => {
                          setFieldValue("resultado", value.floatValue ?? "");
                          setFieldError("resultado", "");
                        }}
                        id="resultado"
                        name="resultado"
                        label="Resultado"
                        helperText={errors.resultado}
                        error={!!errors.resultado}
                        disabled={!values.habilitarResultado}
                      />
                    ) : (
                      <InputText
                        value={
                          values.habilitarResultado &&
                          !!Number.isFinite(values.resultado)
                            ? Number(values.resultado) ?? ""
                            : ""
                        }
                        onChange={handleChange}
                        id="resultado"
                        error={errors.resultado ? true : false}
                        name="resultado"
                        type="number"
                        label="Resultado"
                        className="mt-2"
                        InputProps={{
                          autoComplete: "off",
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              style={{ paddingRight: 20 }}
                            >
                              {unidade === "P" ? "%" : ""}
                            </InputAdornment>
                          ),
                        }}
                        disabled={!values.habilitarResultado}
                        helperText={errors.resultado}
                      />
                    )}
                    <div className="ms-Grid-col ms-sm12 ms-md6">
                      {values.habilitarResultado &&
                        values.resultado !== null &&
                        values.resultado !== undefined && (
                          <Stack verticalAlign="start">
                            <Text
                              variant="smallPlus"
                              styles={{ root: { marginBottom: 7 } }}
                            >
                              Avaliação
                            </Text>
                            <Stack
                              horizontal
                              verticalAlign="center"
                              styles={{ root: { fontSize: 13 } }}
                            >
                              {renderAvaliacao(
                                CalculaAting(
                                  Number(values.minimo),
                                  Number(values.target),
                                  Number(values.maximo),
                                  Number(values.resultado),
                                  false
                                ).avaliacao
                              )}
                            </Stack>
                          </Stack>
                        )}
                    </div>
                  </Stack>
                </div>
                <DialogFooter>
                  <DefaultButton
                    styles={{ root: { width: 110 } }}
                    onClick={this.handleCloseAlteracao}
                    text="Cancelar"
                  />
                  <PrimaryButton text="Fechar" type="submit" />
                </DialogFooter>
              </form>
            )}
          </Formik>
        </Dialog>
      </>
    );
  }

  private _onRenderRow: IDetailsListProps["onRenderRow"] = (props) => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (props) {
      const stylesForRow = (detailsRowStyleProps: any) => {
        customStyles.fields = {
          alignItems: "center",
        };
        customStyles.cell = {
          selectors: {
            "&:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5)": {
              textAlign: "right",
            },
            " &:nth-child(4)": {
              opacity: 0.5,
            },
          },
        };
        return customStyles;
      };
      return (
        <DetailsRow
          {...props}
          styles={stylesForRow}
          itemIndex={props.itemIndex}
        />
      );
    }
    return null;
  };

  private _getKey(item: any, index?: number): any {
    if (item !== undefined) return item.key;
  }
}

const dialogContentProps = {
  title: "Alteração",
  closeButtonAriaLabel: "Close",
  subText: "",
};

const validationDefineGoalSchema = yup.object().shape({
  definirMeta: yup.bool(),
  mecanicaCalculo: yup.string(),
  minimo: yup.number().nullable().required("Campo obrigatório"),
  target: yup.number().nullable().required("Campo obrigatório"),
  habilitarResultado: yup.bool().nullable(),
  resultado: yup
    .number()
    .nullable()
    .when("habilitarResultado", {
      is: true,
      then: yup.number().required("Campo obrigatório"),
    }),
});

const formatValueMonth = (value: string | number | null): string => {
  value = parseFloat(value?.toString() ?? "");
  if (!Number.isFinite(value)) {
    return "";
  }
  return toCurrency(value, true);
};

export default FormMetaMonths;
