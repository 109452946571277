//#region IMPORTS
import { takeEvery, put, call, all } from "redux-saga/effects";
import {
  Types,
  IRequestCycleAvaliacaoByIdAction,
  IRequestCycleAvaliacaoSaveAction,
  IRequestCycleAvaliacaoHistoryAction,
  ISuccessCycleAvaliacaoByIdAction,
  ISuccessCycleAvaliacaoSaveAction,
  ISuccessCycleAvaliacaoHistoryAction
} from "../ducks/avaliation/types";

import api from "~/services/api";

//#endregion

const apiCallAvaliationById = (idAvaliacao: number) => {
  return api.get(`avaliacao/${idAvaliacao}`)
    .then(response => response.data)
    .catch(err => {
      throw err.response;
    });
};

const apiCallAvaliationSave = (payload: IRequestCycleAvaliacaoSaveAction["payload"]) => {
  return api.put(`avaliacao/${payload.idAvaliacao}`, {
    ...payload.data
  })
    .then(response => response.data)
    .catch(err => {
      throw err.response;
    });
};

const apiCallAvaliationHistory = (idFuncionario: number) => {
  return api.get(`avaliacao`, {
    params: {
      idFuncionario
    }
  })
    .then(response => response.data)
    .catch(err => {
      throw err.response;
    });
};

function* loadAvaliationId(action: IRequestCycleAvaliacaoByIdAction) {
  try {
    const data = yield call(apiCallAvaliationById, action.idAvaliacao);

    yield put({
      type: Types.SUCCESS_AVALIACAO_BY_ID,
      payload: data
    } as ISuccessCycleAvaliacaoByIdAction);

  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível carregar a avaliação.';
    yield put({ type: Types.FAILURE_AVALIACAO_BY_ID, message });
  };
};

function* saveAvaliation(action: IRequestCycleAvaliacaoSaveAction) {
  try {
    yield call(apiCallAvaliationSave, action.payload);

    yield put({
      type: Types.SUCCESS_AVALIACAO_SAVE,
      idTarefa: action.payload.idTarefa
    } as ISuccessCycleAvaliacaoSaveAction);

  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível salvar a avaliação.';
    yield put({ type: Types.FAILURE_AVALIACAO_SAVE, message });
  };
};


function* getHistory(action: IRequestCycleAvaliacaoHistoryAction) {
  try {
    const data = yield call(apiCallAvaliationHistory, action.idFuncionario);

    yield put({
      type: Types.SUCCESS_AVALIACAO_HISTORY,
      payload: data
    } as ISuccessCycleAvaliacaoHistoryAction);

  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível carregar o histório de avaliações.';
    yield put({ type: Types.FAILURE_AVALIACAO_HISTORY, message });
  };
};


export default function* companiesRoot() {
  yield all([
    yield takeEvery(Types.REQUEST_AVALIACAO_BY_ID, loadAvaliationId),
    yield takeEvery(Types.REQUEST_AVALIACAO_SAVE, saveAvaliation),
    yield takeEvery(Types.REQUEST_AVALIACAO_HISTORY, getHistory),
  ]);
};
