import { Types, DataTypes, ReportActionTypes } from "./types";
import customToast from "~/components/Toast/index";

/**
 * Reducers
 */
const INITIAL_STATE: DataTypes = {
    data: {
        reunioes: [],
        temporadas: [],
        anteriores: []
    },
    dataCurve: null,
    dataSuccession: [],
    dataTalent: null,
    loading: false,
    loadingAction: false,
    success: false,
    error: false
};


/**
 * Actions
 */

export const Creators = {
    getReport: (Ano?: string): ReportActionTypes => ({
        type: Types.REQUEST_REPORT_LOAD,
        payload: {
            Ano
        }
    }),

    getReportCurve: (Ano?: string, Reuniao?: number[], comparar?: string[]): ReportActionTypes => ({
        type: Types.REQUEST_REPORT_CURVE_LOAD,
        payload: {
            Ano,
            Reuniao,
            comparar
        }
    }),

    getReportSuccession: (Ano?: string, Reuniao?: number[]): ReportActionTypes => ({
        type: Types.REQUEST_REPORT_SUCCESSION_LOAD,
        payload: {
            Ano,
            Reuniao
        }
    }),

    getReportTalent: (Ano?: string, Reuniao?: number[], TipoCargo?: string): ReportActionTypes => ({
        type: Types.REQUEST_REPORT_TALENT_LOAD,
        payload: {
            Ano,
            Reuniao,
            TipoCargo
        }
    }),

    resetReports: (): ReportActionTypes => ({
        type: Types.RESET_REPORT
    }),
}


export default function reportReducer(state = INITIAL_STATE, action: ReportActionTypes): DataTypes {

    switch (action.type) {
        case Types.REQUEST_REPORT_LOAD:
            return { ...state, loading: true, error: false, success: false };
        case Types.SUCCESS_REPORT_LOAD:
            return { ...state, data: action.payload, loading: false, error: false, success: true };
        case Types.FAILURE_REPORT_LOAD:
            customToast.error(action.message);
            return {
                ...state,
                data: {
                    reunioes: [],
                    temporadas: [],
                    anteriores: []
                },
                loading: false, error: true, success: false
            };

        case Types.REQUEST_REPORT_CURVE_LOAD:
            return { ...state, dataCurve: null, loadingAction: true, error: false, success: false };
        case Types.SUCCESS_REPORT_CURVE_LOAD:
            return { ...state, dataCurve: action.payload, loadingAction: false, error: false, success: true };
        case Types.FAILURE_REPORT_CURVE_LOAD:
            customToast.error(action.message);
            return {
                ...state,
                dataCurve: null,
                dataSuccession: [],
                loadingAction: false, error: true, success: false
            };

        case Types.RESET_REPORT:
            return { ...state, dataCurve: null, dataSuccession: [], dataTalent: null }

        case Types.REQUEST_REPORT_SUCCESSION_LOAD:
            return { ...state, dataSuccession: [], loadingAction: true, error: false, success: false };
        case Types.SUCCESS_REPORT_SUCCESSION_LOAD:
            return { ...state, dataSuccession: action.payload, loadingAction: false, error: false, success: true };
        case Types.FAILURE_REPORT_SUCCESSION_LOAD:
            customToast.error(action.message);
            return {
                ...state,
                dataSuccession: [],
                loadingAction: false, error: true, success: false
            };

        case Types.REQUEST_REPORT_TALENT_LOAD:
            return { ...state, dataTalent: null, loadingAction: true, error: false, success: false };
        case Types.SUCCESS_REPORT_TALENT_LOAD:
            return { ...state, dataTalent: action.payload, loadingAction: false, error: false, success: true };
        case Types.FAILURE_REPORT_TALENT_LOAD:
            customToast.error(action.message);
            return {
                ...state,
                dataTalent: null,
                loadingAction: false, error: true, success: false
            };

        default:
            return state;
    }
}