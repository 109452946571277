import {
  Types,
  DataTypes,
  CalibrationActionTypes,
  PerformanceAvaliado,
  AvaliadosCurvaType,
  SuccessionNivelType,
  SuccesstionType,
} from "./types";
import customToast from "~/components/Toast/index";

/**
 * Reducers
 */
const INITIAL_STATE: DataTypes = {
  calibration: null,
  avaliadoSelected: {
    dataInit: null,
    avaliadoCalib: null,
    error: false,
    loading: false,
    loadingAction: false,
    success: false,
    errorLoading: false,
    successLoading: false,
    successLoadingTimer: false,
  },
  pesquisaFunc: {
    avaliados: [],
    error: false,
    loading: false,
    success: false,
  },
  comentarios: null,
  error: false,
  loadingData: false,
  loadingAction: false,
  loadingNivel: false,
  loadingSuccession: false,
  loadingAnotations: false,
  loadingChanginNivel: false,
  success: false,
  successLoading: false,
  successLoadingNivel: false,
  successSucessao: false,
  successForcarCalibracao: false,
  successAnotations: false,
  successChanginNivel: false,
  errorAnotations: false,
  changeVisibility: false,
};

/**
 * Actions
 */

export const Creators = {
  getMeetingById: (idCicloReuniao: number): CalibrationActionTypes => {
    return {
      type: Types.REQUEST_CALIBRATION_LOAD_BY_ID,
      payload: {
        idCicloReuniao,
      },
    };
  },

  getMeetingByIdNivel: (
    idCicloReuniao: number,
    nivel: string
  ): CalibrationActionTypes => {
    return {
      type: Types.REQUEST_CALIBRATION_LOAD_BY_ID_NIVEL,
      payload: {
        idCicloReuniao,
        nivel,
      },
    };
  },

  getSucessao: (
    idCicloReuniao: number,
    nivel: string
  ): CalibrationActionTypes => {
    return {
      type: Types.REQUEST_SUCESSAO_LOAD,
      payload: {
        idCicloReuniao,
        nivel,
      },
    };
  },

  clearSucessao: (): CalibrationActionTypes => ({
    type: Types.CLEAR_SUCESSAO,
  }),

  ativaAvaliador: (
    idCicloReuniao: number,
    nivel: string,
    idCicloReuniaoAvaliador: number
  ): CalibrationActionTypes => ({
    type: Types.REQUEST_CALIBRATION_ATIVA_AVALIADOR,
    payload: {
      idCicloReuniao,
      nivel,
      idCicloReuniaoAvaliador,
    },
  }),

  desativaAvaliador: (
    idCicloReuniao: number,
    nivel: string,
    idCicloReuniaoAvaliador: number
  ): CalibrationActionTypes => ({
    type: Types.REQUEST_CALIBRATION_DESATIVA_AVALIADOR,
    payload: {
      idCicloReuniao,
      nivel,
      idCicloReuniaoAvaliador,
    },
  }),

  finalizar: (
    idCicloReuniao: number,
    comentariosFinais: string
  ): CalibrationActionTypes => ({
    type: Types.REQUEST_CALIBRATION_FINALIZAR,
    payload: {
      idCicloReuniao,
      comentariosFinais,
    },
  }),

  loadAvaliado: (
    idCicloReuniao: number,
    idCicloReuniaoAvaliado: number
  ): CalibrationActionTypes => ({
    type: Types.REQUEST_CALIBRATION_LOAD_AVALIADO,
    payload: {
      idCicloReuniao,
      idCicloReuniaoAvaliado,
    },
  }),

  saveAvaliado: (
    idCicloReuniao: number,
    idCicloReuniaoAvaliado: number,
    performance: PerformanceAvaliado,
    nivelOriginal: number | null,
    sucessaoOriginal: string
  ): CalibrationActionTypes => ({
    type: Types.REQUEST_CALIBRATION_SAVE_AVALIADO,
    payload: {
      idCicloReuniao,
      idCicloReuniaoAvaliado,
      performance,
      nivelOriginal,
      sucessaoOriginal,
    },
  }),

  initCalibration: (
    idCicloReuniao: number,
    idCicloReuniaoAvaliado: number,
    isRunning: boolean
  ): CalibrationActionTypes => ({
    type: Types.REQUEST_CALIBRATION_INIT,
    payload: {
      idCicloReuniao,
      idCicloReuniaoAvaliado,
      isRunning,
    },
  }),

  stopCalibration: (
    idCicloReuniao: number,
    idCicloReuniaoAvaliado: number
  ): CalibrationActionTypes => ({
    type: Types.REQUEST_CALIBRATION_STOP,
    payload: {
      idCicloReuniao,
      idCicloReuniaoAvaliado,
    },
  }),

  avaliadoUp: (
    idCicloReuniao: number,
    idCicloReuniaoAvaliado: number
  ): CalibrationActionTypes => ({
    type: Types.REQUEST_CALIBRATION_AVALIADO_UP,
    payload: {
      idCicloReuniao,
      idCicloReuniaoAvaliado,
    },
  }),

  avaliadoDown: (
    idCicloReuniao: number,
    idCicloReuniaoAvaliado: number
  ): CalibrationActionTypes => ({
    type: Types.REQUEST_CALIBRATION_AVALIADO_DOWN,
    payload: {
      idCicloReuniao,
      idCicloReuniaoAvaliado,
    },
  }),

  getComments: (
    idCicloReuniao: number,
    idCicloReuniaoAvaliado: number,
    exibir?: number
  ): CalibrationActionTypes => ({
    type: Types.REQUEST_COMMENT_AVALIADO_LOAD,
    payload: {
      idCicloReuniao,
      idCicloReuniaoAvaliado,
      exibir,
    },
  }),

  addComment: (
    idCicloReuniao: number,
    idCicloReuniaoAvaliado: number,
    comentarios: string
  ): CalibrationActionTypes => ({
    type: Types.REQUEST_COMMENT_AVALIADO_ADD,
    payload: {
      idCicloReuniao,
      idCicloReuniaoAvaliado,
      comentarios,
    },
  }),

  addSucessao: (
    idCicloReuniao: number,
    idCicloReuniaoSucessaoGrupo: number,
    sucessao: string,
    nomeCompleto: string,
    idCicloReuniaoAvaliado?: number,
    idFuncionario?: number
  ): CalibrationActionTypes => ({
    type: Types.REQUEST_SUCESSAO_ADD,
    payload: {
      idCicloReuniao,
      idCicloReuniaoAvaliado,
      idFuncionario,
      idCicloReuniaoSucessaoGrupo,
      sucessao,
      nomeCompleto,
    },
  }),

  delSucessao: (
    idCicloReuniao: number,
    idCicloReuniaoSucessaoGrupo: number,
    idCicloReuniaoSucessaoGrupoAvaliado: number,
    sucessao: string
  ): CalibrationActionTypes => ({
    type: Types.REQUEST_SUCESSAO_DEL,
    payload: {
      idCicloReuniao,
      idCicloReuniaoSucessaoGrupo,
      idCicloReuniaoSucessaoGrupoAvaliado,
      sucessao,
    },
  }),

  calibrationPesquisaFunc: (
    idCicloReuniao: number,
    idArea?: number | null,
    idChefe?: number | null,
    nomePesq?: string,
    worklevel?: number | null,
    flagReuniao?: boolean
  ): CalibrationActionTypes => ({
    type: Types.REQUEST_CALIBRATION_PESQUISAFUNC,
    payload: {
      idCicloReuniao,
      idArea,
      idChefe,
      nomePesq,
      worklevel,
      flagReuniao,
    },
  }),

  toggleVisibilityFunc: (
    idCicloReuniaoAvaliado: number
  ): CalibrationActionTypes => ({
    type: Types.TOGGLE_VISIBILITY_FUNC,
    idCicloReuniaoAvaliado,
  }),

  clearCalibrationPesquisaFunc: () => ({
    type: Types.CLEAR_CALIBRATION_PESQUISAFUNC,
  }),

  forceCalibration: (
    idCicloReuniao: number,
    nivel: string
  ): CalibrationActionTypes => ({
    type: Types.REQUEST_SUCESSAO_FORCE_CALIBRATION,
    payload: {
      idCicloReuniao,
      nivel,
    },
  }),
};

export default function calibrationReducer(
  state = INITIAL_STATE,
  action: CalibrationActionTypes
): DataTypes {
  switch (action.type) {
    case Types.REQUEST_CALIBRATION_LOAD_BY_ID:
      return {
        ...state,
        calibration: null,
        loadingData: true,
        error: false,
        success: false,
        successLoading: false,
        successLoadingNivel: false,
      };
    case Types.SUCCESS_CALIBRATION_LOAD_BY_ID:
      return {
        ...state,
        calibration: action.payload,
        loadingData: false,
        error: false,
        success: true,
        successLoading: true,
        successLoadingNivel: true,
      };
    case Types.FAILURE_CALIBRATION_LOAD_BY_ID:
      customToast.error(action.message);
      return {
        ...state,
        calibration: null,
        loadingData: false,
        error: true,
        success: false,
        successLoading: false,
        successLoadingNivel: false,
      };

    case Types.REQUEST_CALIBRATION_LOAD_BY_ID_NIVEL:
      return {
        ...state,
        successLoadingNivel: false,
        successLoading: false,
        loadingNivel: true,
      };
    case Types.SUCCESS_CALIBRATION_LOAD_BY_ID_NIVEL:
      const avaliados = action.payload.avaliados.sort(
        (a, b) =>
          (b.avaliacaoGestor?.performancegestor?.nivel ?? 0) -
          (a.avaliacaoGestor?.performancegestor?.nivel ?? 0)
      );
      return {
        ...state,
        successLoading: false,
        successLoadingNivel: true,
        loadingNivel: false,
        calibration: {
          ...state.calibration!,
          avaliadores: action.payload.avaliadores,
          avaliados: avaliados.map((item) => {
            return {
              ...item,
              funcionario: {
                ...item.funcionario,
                isVisible: true,
              },
            };
          }),
        },
      };
    case Types.FAILURE_CALIBRATION_LOAD_BY_ID_NIVEL:
      customToast.error(action.message);
      return {
        ...state,
        successLoading: false,
        successLoadingNivel: false,
        loadingNivel: false,
        calibration: {
          ...state.calibration!,
          avaliadores: [],
          avaliados: [],
        },
      };

    case Types.REQUEST_CALIBRATION_ATIVA_AVALIADOR:
      return { ...state };
    case Types.SUCCESS_CALIBRATION_ATIVA_AVALIADOR:
      return {
        ...state,
        calibration: {
          ...state.calibration!,
          avaliadores: state.calibration?.avaliadores?.map((item) => {
            if (
              item.idCicloReuniaoAvaliador !==
              action.payload.idCicloReuniaoAvaliador
            ) {
              return item;
            } else {
              return {
                ...item,
                flagEliminado: false,
              };
            }
          }),
        },
      };
    case Types.FAILURE_CALIBRATION_ATIVA_AVALIADOR:
      customToast.error(action.message);
      return { ...state };

    case Types.REQUEST_CALIBRATION_DESATIVA_AVALIADOR:
      return { ...state };
    case Types.SUCCESS_CALIBRATION_DESATIVA_AVALIADOR:
      return {
        ...state,
        calibration: {
          ...state.calibration!,
          avaliadores: state.calibration?.avaliadores?.map((item) => {
            if (
              item.idCicloReuniaoAvaliador !==
              action.payload.idCicloReuniaoAvaliador
            ) {
              return item;
            } else {
              return {
                ...item,
                flagEliminado: true,
              };
            }
          }),
        },
      };
    case Types.FAILURE_CALIBRATION_DESATIVA_AVALIADOR:
      customToast.error(action.message);
      return { ...state };

    case Types.REQUEST_CALIBRATION_FINALIZAR:
      return { ...state, loadingAction: true };
    case Types.SUCCESS_CALIBRATION_FINALIZAR:
      customToast.success("Reunião finalizada com sucesso!");
      return {
        ...state,
        loadingAction: false,
        calibration: {
          ...state.calibration!,
          reuniao: {
            ...state.calibration?.reuniao!,
            status: "Finalizada",
            flagHabilitaFinalizar: false,
          },
        },
      };
    case Types.FAILURE_CALIBRATION_FINALIZAR:
      customToast.error(action.message);
      return { ...state, loadingAction: false };

    case Types.REQUEST_CALIBRATION_INIT:
      return {
        ...state,
        avaliadoSelected: {
          ...state.avaliadoSelected,
          dataInit: action.payload.isRunning
            ? state.avaliadoSelected.dataInit
            : null,
          successLoading: false,
          successLoadingTimer: false,
          errorLoading: false,
          loadingAction: action.payload.isRunning ? false : true,
        },
      };
    case Types.SUCCESS_CALIBRATION_INIT:
      return {
        ...state,
        avaliadoSelected: {
          ...state.avaliadoSelected,
          dataInit: action.payload.data,
          successLoading: action.payload.isRunning ? false : true,
          errorLoading: false,
          loadingAction: false,
          successLoadingTimer: true,
        },
      };
    case Types.FAILURE_CALIBRATION_INIT:
      customToast.error(action.message);
      return {
        ...state,
        avaliadoSelected: {
          ...state.avaliadoSelected,
          dataInit: null,
          successLoading: false,
          errorLoading: true,
          loadingAction: false,
          successLoadingTimer: false,
        },
      };

    case Types.REQUEST_CALIBRATION_STOP:
      return {
        ...state,
        avaliadoSelected: {
          ...state.avaliadoSelected,
          successLoadingTimer: false,
        },
      };
    case Types.SUCCESS_CALIBRATION_STOP:
      return {
        ...state,
        avaliadoSelected: {
          ...state.avaliadoSelected!,
          avaliadoCalib: null,
          successLoadingTimer: false,
          // data: {
          //     ...state.avaliadoSelected.data!,
          //     // tempoAcumulado: action.payload.tempoAcumulado,
          //     dataHoraFinal: action.payload.dataHoraFinal!
          // }
        },
      };
    case Types.FAILURE_CALIBRATION_STOP:
      customToast.error(action.message);
      return {
        ...state,
        avaliadoSelected: {
          ...state.avaliadoSelected,
          successLoadingTimer: false,
        },
      };

    case Types.REQUEST_CALIBRATION_LOAD_AVALIADO:
      return {
        ...state,
        avaliadoSelected: {
          ...state.avaliadoSelected,
          avaliadoCalib: null,
          loading: true,
          errorLoading: false,
          successLoading: false,
          success: false,
        },
      };
    case Types.SUCCESS_CALIBRATION_LOAD_AVALIADO:
      return {
        ...state,
        avaliadoSelected: {
          ...state.avaliadoSelected,
          avaliadoCalib: {
            ...state.avaliadoSelected.avaliadoCalib!,
            avaliado: action.payload.avaliado,
            comentarios: action.payload.comentarios,
          },
          errorLoading: false,
          loading: false,
          successLoading: true,
          success: false,
        },
      };
    case Types.FAILURE_CALIBRATION_LOAD_AVALIADO:
      customToast.error(action.message);
      return {
        ...state,
        avaliadoSelected: {
          ...state.avaliadoSelected,
          avaliadoCalib: null,
          loading: false,
          errorLoading: true,
          successLoading: false,
          success: false,
        },
      };

    case Types.REQUEST_CALIBRATION_SAVE_AVALIADO:
      return {
        ...state,
        avaliadoSelected: {
          ...state.avaliadoSelected,
          loadingAction: true,
          success: false,
          error: false,
        },
      };
    case Types.SUCCESS_CALIBRATION_SAVE_AVALIADO:
      // const nivelOriginal: number = state.calibration?.performances.find(item => item.idTabPerformance === action.payload.idTabPerformanceOriginal)?.nivel!;
      const nivelCalibrado: number = state.calibration?.performances.find(
        (item) =>
          item.idTabPerformance ===
          Number(action.payload.performance.idTabPerformance)
      )?.nivel!;

      let avaliado: AvaliadosCurvaType;

      state.calibration?.curva?.forEach((curva) => {
        if (curva.nivel === action.payload.nivelOriginal) {
          curva.curvaAjustada.forEach((ajustada, i, arr) => {
            if (
              ajustada.idCicloReuniaoAvaliado ===
              action.payload.idCicloReuniaoAvaliado
            ) {
              ajustada = {
                ...ajustada,
                nivel: nivelCalibrado,
                deslocamento:
                  ajustada!.deslocamento! +
                  (nivelCalibrado - action.payload.nivelOriginal!),
                idTabPerformance: action.payload.performance.idTabPerformance,
              };
              avaliado = ajustada;

              if (
                action.payload.nivelOriginal !==
                action.payload.performance.performance?.nivel
              ) {
                arr.splice(i, 1);
              } else {
                arr[i] = ajustada;
              }
            }
          });
        }
      });
      if (
        action.payload.nivelOriginal !==
        action.payload.performance.performance?.nivel
      ) {
        state.calibration?.curva?.forEach((curva) => {
          if (curva.nivel === action.payload.performance.performance?.nivel) {
            curva.curvaAjustada.push(avaliado);
          }
        });
      }

      if (
        action.payload.sucessaoOriginal !== action.payload.performance.sucessao
      ) {
        const currentSuccession = findAvaliadoSucessao(
          state.calibration?.sucessao ?? [],
          "idCicloReuniaoAvaliado",
          action.payload.idCicloReuniaoAvaliado
        );
        if (currentSuccession) {
          const oldSuccession =
            state.calibration?.sucessao[currentSuccession.indexGrupo][
              currentSuccession.nivel
            ][currentSuccession.indexEl];
          state.calibration?.sucessao[currentSuccession.indexGrupo][
            currentSuccession.nivel
          ].splice(currentSuccession.indexEl, 1);
          if (oldSuccession) {
            switch (action.payload.performance.sucessao) {
              case "Imediato":
                state.calibration?.sucessao[currentSuccession.indexGrupo][
                  "nivel1"
                ].push(oldSuccession);
                break;
              case "Curto Prazo - 1 a 2 anos":
                state.calibration?.sucessao[currentSuccession.indexGrupo][
                  "nivel2"
                ].push(oldSuccession);
                break;
              case "Médio Prazo - 3 a 5 anos":
                state.calibration?.sucessao[currentSuccession.indexGrupo][
                  "nivel3"
                ].push(oldSuccession);
                break;
              case "Longo Prazo - acima 5 anos":
                state.calibration?.sucessao[currentSuccession.indexGrupo][
                  "nivel4"
                ].push(oldSuccession);
                break;
              default:
                state.calibration?.sucessao[currentSuccession.indexGrupo][
                  "nivel0"
                ].push(oldSuccession);
            }
          }
        }
        // state.calibration?.sucessao.forEach(item => {
        //   if (item.idCicloReuniaoSucessaoGrupo !==)
        // })
      }

      return {
        ...state,
        calibration: {
          ...state.calibration!,
          avaliados: state.calibration?.avaliados?.map((item) => {
            if (
              item.idCicloReuniaoAvaliado !==
              action.payload.idCicloReuniaoAvaliado
            ) {
              return item;
            } else {
              return {
                ...item,
                ...action.payload.performance,
              };
            }
          }),
          curva: state.calibration?.curva!,
        },
        avaliadoSelected: {
          ...state.avaliadoSelected,
          loadingAction: false,
          success: true,
          error: false,
        },
      };
    case Types.FAILURE_CALIBRATION_SAVE_AVALIADO:
      customToast.error(action.message);
      return {
        ...state,
        avaliadoSelected: {
          ...state.avaliadoSelected,
          loadingAction: false,
          success: false,
          error: true,
        },
      };

    case Types.REQUEST_CALIBRATION_AVALIADO_UP:
      return {
        ...state,
        loadingChanginNivel: true,
        successChanginNivel: false,
      };
    case Types.SUCCESS_CALIBRATION_AVALIADO_UP:
      customToast.success("Avaliado subiu de nível com sucesso");
      return {
        ...state,
        loadingChanginNivel: false,
        successChanginNivel: true,
        calibration: {
          ...state.calibration!,
          avaliados: state.calibration?.avaliados?.filter(
            (item) =>
              item.idCicloReuniaoAvaliado !== action.idCicloReuniaoAvaliado
          ),
        },
      };
    case Types.FAILURE_CALIBRATION_AVALIADO_UP:
      customToast.error(action.message);
      return {
        ...state,
        loadingChanginNivel: false,
        successChanginNivel: false,
      };

    case Types.REQUEST_CALIBRATION_AVALIADO_DOWN:
      return {
        ...state,
        loadingChanginNivel: true,
        successChanginNivel: false,
      };
    case Types.SUCCESS_CALIBRATION_AVALIADO_DOWN:
      customToast.success("Avaliado desceu de nível com sucesso");
      return {
        ...state,
        loadingChanginNivel: false,
        successChanginNivel: true,
        calibration: {
          ...state.calibration!,
          avaliados: state.calibration?.avaliados?.filter(
            (item) =>
              item.idCicloReuniaoAvaliado !== action.idCicloReuniaoAvaliado
          ),
        },
      };
    case Types.FAILURE_CALIBRATION_AVALIADO_DOWN:
      customToast.error(action.message);
      return {
        ...state,
        loadingChanginNivel: false,
        successChanginNivel: false,
      };

    case Types.REQUEST_COMMENT_AVALIADO_LOAD:
      return {
        ...state,
        comentarios: null,
        loadingAnotations: true,
        errorAnotations: false,
      };
    case Types.SUCCESS_COMMENT_AVALIADO_LOAD:
      return {
        ...state,
        comentarios: action.payload,
        loadingAnotations: false,
        errorAnotations: false,
      };
    case Types.FAILURE_COMMENT_AVALIADO_LOAD:
      customToast.error(action.message);
      return {
        ...state,
        comentarios: null,
        loadingAnotations: false,
        errorAnotations: true,
      };

    case Types.REQUEST_COMMENT_AVALIADO_ADD:
      return { ...state, loadingAction: true, successAnotations: false };
    case Types.SUCCESS_COMMENT_AVALIADO_ADD:
      customToast.success("Comentário salvo com sucesso");
      console.log(action.payload.comentarios);
      return {
        ...state,
        loadingAction: false,
        successAnotations: true,
        calibration: {
          ...state.calibration!,
          avaliados: state.calibration?.avaliados!.map((item) => {
            if (
              item.idCicloReuniaoAvaliado !==
              action.payload.idCicloReuniaoAvaliado
            ) {
              return item;
            } else {
              return {
                ...item,
                possuiComentario: true,
              };
            }
          }),
        },
        comentarios: {
          anos: [...(state.comentarios?.anos ?? [])],
          comentarios: [
            ...(state.comentarios?.comentarios ?? []),
            action.payload.comentarios,
          ],
        },
      };
    case Types.FAILURE_COMMENT_AVALIADO_ADD:
      customToast.error(action.message);
      return { ...state, loadingAction: false, successAnotations: false };

    case Types.REQUEST_SUCESSAO_ADD:
      return { ...state, loadingAction: true, successSucessao: false };
    case Types.SUCCESS_SUCESSAO_ADD:
      customToast.success("Sucessão adicionada com sucesso");
      return {
        ...state,
        loadingAction: false,
        successSucessao: true,
        calibration: {
          ...state.calibration!,
          sucessao: state.calibration!.sucessao.map((item) => {
            if (
              item.idCicloReuniaoSucessaoGrupo !==
              action.payload.idCicloReuniaoSucessaoGrupo
            ) {
              return item;
            } else {
              let sucessao: SuccesstionType = item;
              switch (action.payload.sucessao) {
                case "Imediato":
                  sucessao.nivel1 = [
                    ...item.nivel1!,
                    {
                      ...action.payload.data,
                      avaliado: {
                        funcionario: {
                          nomeCompleto: action.payload.nomeCompleto,
                        },
                        idFuncionario: action.payload.idFuncionario!,
                      },
                    },
                  ];
                  break;
                case "Curto Prazo - 1 a 2 anos":
                  sucessao.nivel2 = [
                    ...item.nivel2!,
                    {
                      ...action.payload.data,
                      avaliado: {
                        funcionario: {
                          nomeCompleto: action.payload.nomeCompleto,
                        },
                        idFuncionario: action.payload.idFuncionario!,
                      },
                    },
                  ];
                  break;
                case "Médio Prazo - 3 a 5 anos":
                  sucessao.nivel3 = [
                    ...item.nivel3!,
                    {
                      ...action.payload.data,
                      avaliado: {
                        funcionario: {
                          nomeCompleto: action.payload.nomeCompleto,
                        },
                        idFuncionario: action.payload.idFuncionario!,
                      },
                    },
                  ];
                  break;
                case "Longo Prazo - acima 5 anos":
                  sucessao.nivel4 = [
                    ...item.nivel4!,
                    {
                      ...action.payload.data,
                      avaliado: {
                        funcionario: {
                          nomeCompleto: action.payload.nomeCompleto,
                        },
                        idFuncionario: action.payload.idFuncionario!,
                      },
                    },
                  ];
                  break;
              }
              return sucessao;
            }
          }),
        },
      };
    case Types.FAILURE_SUCESSAO_ADD:
      customToast.error(action.message);
      return { ...state, loadingAction: false, successSucessao: false };

    case Types.REQUEST_SUCESSAO_DEL:
      return { ...state, loadingAction: true, successSucessao: false };
    case Types.SUCCESS_SUCESSAO_DEL:
      customToast.success("Sucessão removida com sucesso");
      return {
        ...state,
        loadingAction: false,
        successSucessao: true,
        calibration: {
          ...state.calibration!,
          sucessao: state.calibration!.sucessao.map((item) => {
            if (
              item.idCicloReuniaoSucessaoGrupo !==
              action.payload.idCicloReuniaoSucessaoGrupo
            ) {
              return item;
            } else {
              let sucessao: SuccesstionType = item;
              switch (action.payload.sucessao) {
                case "Imediato":
                  sucessao.nivel1 = item.nivel1.filter(
                    (item) =>
                      item.idCicloReuniaoSucessaoGrupoAvaliado !==
                      action.payload.idCicloReuniaoSucessaoGrupoAvaliado
                  );
                  break;
                case "Curto Prazo - 1 a 2 anos":
                  sucessao.nivel2 = item.nivel2.filter(
                    (item) =>
                      item.idCicloReuniaoSucessaoGrupoAvaliado !==
                      action.payload.idCicloReuniaoSucessaoGrupoAvaliado
                  );
                  break;
                case "Médio Prazo - 3 a 5 anos":
                  sucessao.nivel3 = item.nivel3.filter(
                    (item) =>
                      item.idCicloReuniaoSucessaoGrupoAvaliado !==
                      action.payload.idCicloReuniaoSucessaoGrupoAvaliado
                  );
                  break;
                case "Longo Prazo - acima 5 anos":
                  sucessao.nivel4 = item.nivel4.filter(
                    (item) =>
                      item.idCicloReuniaoSucessaoGrupoAvaliado !==
                      action.payload.idCicloReuniaoSucessaoGrupoAvaliado
                  );
                  break;
              }
              return sucessao;
            }
          }),
        },
      };
    case Types.FAILURE_SUCESSAO_DEL:
      customToast.error(action.message);
      return {
        ...state,
        loadingAction: false,
        successSucessao: false,
      };

    case Types.REQUEST_CALIBRATION_PESQUISAFUNC:
      return {
        ...state,
        pesquisaFunc: {
          avaliados: [],
          error: false,
          loading: true,
          success: false,
        },
      };
    case Types.SUCCESS_CALIBRATION_PESQUISAFUNC:
      return {
        ...state,
        pesquisaFunc: {
          avaliados: action.payload,
          error: false,
          loading: false,
          success: true,
        },
      };
    case Types.FAILURE_CALIBRATION_PESQUISAFUNC:
      customToast.error(action.message);
      return {
        ...state,
        pesquisaFunc: {
          avaliados: [],
          error: true,
          loading: false,
          success: false,
        },
      };

    case Types.CLEAR_CALIBRATION_PESQUISAFUNC:
      return {
        ...state,
        pesquisaFunc: {
          avaliados: [],
          error: false,
          loading: false,
          success: false,
        },
      };

    case Types.REQUEST_SUCESSAO_LOAD:
      return { ...state, loadingSuccession: true };
    case Types.SUCCESS_SUCESSAO_LOAD:
      return {
        ...state,
        calibration: {
          ...state.calibration!,
          sucessao: action.payload,
        },
        loadingSuccession: false,
      };
    case Types.FAILURE_SUCESSAO_LOAD:
      customToast.error(action.message);
      return { ...state, loadingSuccession: false };

    case Types.CLEAR_SUCESSAO:
      return {
        ...state,
        calibration: {
          ...state.calibration!,
          sucessao: [],
        },
      };

    case Types.TOGGLE_VISIBILITY_FUNC:
      return {
        ...state,
        calibration: {
          ...state.calibration!,
          avaliados: state.calibration!.avaliados?.map((item) => {
            if (item.idCicloReuniaoAvaliado !== action.idCicloReuniaoAvaliado) {
              return item;
            } else {
              return {
                ...item,
                funcionario: {
                  ...item.funcionario,
                  isVisible: !item.funcionario.isVisible,
                },
              };
            }
          }),
        },
        changeVisibility: !state.changeVisibility,
      };

    case Types.REQUEST_SUCESSAO_FORCE_CALIBRATION:
      return {
        ...state,
        successForcarCalibracao: false,
      };
    case Types.SUCCESS_SUCESSAO_FORCE_CALIBRATION:
      return {
        ...state,
        successForcarCalibracao: true,
        calibration: {
          ...state.calibration!,
          curva: state.calibration!.curva!.map((item) => {
            return {
              ...item,
              curvaAjustada: item.curvaAjustada.map((aj) => {
                const isAvaliado = action.payload.avaliados.find(
                  (avaliado) =>
                    avaliado.idCicloReuniaoAvaliado ===
                    aj.idCicloReuniaoAvaliado
                );

                if (isAvaliado) {
                  return {
                    ...aj,
                    idTabPerformance: isAvaliado.idTabPerformance,
                  };
                }

                return aj;
              }),
            };
          }),
          avaliados: action.payload.avaliados.map((item) => {
            return {
              ...item,
              funcionario: {
                ...item.funcionario,
                isVisible: true,
              },
            };
          }),
          avaliadores: action.payload.avaliadores,
          reuniao: {
            ...state.calibration?.reuniao!,
            flagHabilitaFinalizar: true,
          },
        },
      };
    case Types.FAILURE_SUCESSAO_FORCE_CALIBRATION:
      customToast.error(action.message);
      return {
        ...state,
        successForcarCalibracao: false,
      };

    default:
      return state;
  }
}

interface IFindAvaliadoSucessao {
  indexGrupo: number;
  nivel: "nivel0" | "nivel1" | "nivel2" | "nivel3" | "nivel4";
  indexEl: number;
}

const findAvaliadoSucessao = (
  arr: any[],
  field: string,
  value: any
): IFindAvaliadoSucessao | null => {
  let result: IFindAvaliadoSucessao | null = null;

  arr.forEach((v, positionArr) => {
    Object.keys(v).map((key, index) => {
      if (Array.isArray(v[key])) {
        const elIndex = v[key].findIndex(
          (item: any) => item[field] == value && item["flagInicial"] === true
        );
        if (elIndex > -1) {
          if (
            key === "nivel0" ||
            key === "nivel1" ||
            key === "nivel2" ||
            key === "nivel3" ||
            key === "nivel4"
          ) {
            result = {
              indexGrupo: positionArr,
              nivel: key,
              indexEl: elIndex,
            };
            return true;
          }
        }
      }
    });
  });
  return result;
};
