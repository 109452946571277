/**
 * Types
 */
export enum Types {
  //GET
  REQUEST_CYCLE_PERFORMANCE_LOAD = "cycle_performance/REQUEST_CYCLE_PERFORMANCE_LOAD",
  SUCCESS_CYCLE_PERFORMANCE_LOAD = "cycle_performance/SUCCESS_CYCLE_PERFORMANCE_LOAD",
  FAILURE_CYCLE_PERFORMANCE_LOAD = "cycle_performance/FAILURE_CYCLE_PERFORMANCE_LOAD",
  //POST
  REQUEST_CYCLE_PERFORMANCE_ADD = "cycle_performance/REQUEST_CYCLE_PERFORMANCE_ADD",
  SUCCESS_CYCLE_PERFORMANCE_ADD = "cycle_performance/SUCCESS_CYCLE_PERFORMANCE_ADD",
  FAILURE_CYCLE_PERFORMANCE_ADD = "cycle_performance/FAILURE_CYCLE_PERFORMANCE_ADD",
  //EDIT
  REQUEST_CYCLE_PERFORMANCE_EDIT = "cycle_performance/REQUEST_CYCLE_PERFORMANCE_EDIT",
  SUCCESS_CYCLE_PERFORMANCE_EDIT = "cycle_performance/SUCCESS_CYCLE_PERFORMANCE_EDIT",
  FAILURE_CYCLE_PERFORMANCE_EDIT = "cycle_performance/FAILURE_CYCLE_PERFORMANCE_EDIT",
  //DEL
  REQUEST_CYCLE_PERFORMANCE_DEL = "cycle_performance/REQUEST_CYCLE_PERFORMANCE_DEL",
  SUCCESS_CYCLE_PERFORMANCE_DEL = "cycle_performance/SUCCESS_CYCLE_PERFORMANCE_DEL",
  FAILURE_CYCLE_PERFORMANCE_DEL = "cycle_performance/FAILURE_CYCLE_PERFORMANCE_DEL",
}

/**
 * Data types
 */

export type CycloPerformanceType = {
  idTabPerformance: number | null;
  idCiclo: number | null;
  nivel: number | null;
  descNivel: string;
  percentual: number | null;
  cor: string;
  descIngles: string | null;
  flagBloquearAvaliacao: boolean;
  multiplicador: number | null;
  multiplicadorBonus: number | null;  
  flagPromocao: boolean | null;
  flagEnquadramento: boolean | null;
  flagZerarReajusteMinimo: boolean | null;
};

interface ICyclePerformanceState {
  readonly data: CycloPerformanceType[];
  readonly loadingData: boolean;
  readonly loadingAction: boolean;
  readonly error: boolean;
  readonly success: boolean;
}

export type DataTypes = ICyclePerformanceState;

/**
 * Actions
 */

//GET
export interface IRequestCyclePerformanceLoadAction {
  type: typeof Types.REQUEST_CYCLE_PERFORMANCE_LOAD;
  idCiclo: number;
}

export interface ISuccessCyclePerformanceLoadAction {
  type: typeof Types.SUCCESS_CYCLE_PERFORMANCE_LOAD;
  payload: CycloPerformanceType[];
}

interface IFailureCyclePerformanceLoadAction {
  type: typeof Types.FAILURE_CYCLE_PERFORMANCE_LOAD;
  message: string;
}

type CyclePerformanceLoadAction =
  | IRequestCyclePerformanceLoadAction
  | ISuccessCyclePerformanceLoadAction
  | IFailureCyclePerformanceLoadAction;

//POST
export interface IRequestCyclePerformanceAddAction {
  type: typeof Types.REQUEST_CYCLE_PERFORMANCE_ADD;
  payload: CycloPerformanceType;
}

export interface ISuccessCyclePerformanceAddAction {
  type: typeof Types.SUCCESS_CYCLE_PERFORMANCE_ADD;
  payload: CycloPerformanceType;
}

interface IFailureCyclePerformanceAddAction {
  type: typeof Types.FAILURE_CYCLE_PERFORMANCE_ADD;
  message: string;
}

type CyclePerformanceAddAction =
  | IRequestCyclePerformanceAddAction
  | ISuccessCyclePerformanceAddAction
  | IFailureCyclePerformanceAddAction;

//EDIT
export interface IRequestCyclePerformanceEditAction {
  type: typeof Types.REQUEST_CYCLE_PERFORMANCE_EDIT;
  payload: {
    idCiclo: number;
    performance: CycloPerformanceType;
  };
}

export interface ISuccessCyclePerformanceEditAction {
  type: typeof Types.SUCCESS_CYCLE_PERFORMANCE_EDIT;
  payload: CycloPerformanceType;
}

interface IFailureCyclePerformanceEditAction {
  type: typeof Types.FAILURE_CYCLE_PERFORMANCE_EDIT;
  message: string;
}

type CyclePerformanceEditAction =
  | IRequestCyclePerformanceEditAction
  | ISuccessCyclePerformanceEditAction
  | IFailureCyclePerformanceEditAction;

//DEL
export interface IRequestCyclePerformanceDelAction {
  type: typeof Types.REQUEST_CYCLE_PERFORMANCE_DEL;
  payload: {
    idCiclo: number;
    idTabPerformance: number;
  };
}

export interface ISuccessCyclePerformanceDelAction {
  type: typeof Types.SUCCESS_CYCLE_PERFORMANCE_DEL;
  idTabPerformance: number;
}

interface IFailureCyclePerformanceDelAction {
  type: typeof Types.FAILURE_CYCLE_PERFORMANCE_DEL;
  message: string;
}

type CyclePerformanceDelAction =
  | IRequestCyclePerformanceDelAction
  | ISuccessCyclePerformanceDelAction
  | IFailureCyclePerformanceDelAction;

export type CyclePerformanceActionTypes =
  | CyclePerformanceLoadAction
  | CyclePerformanceAddAction
  | CyclePerformanceDelAction
  | CyclePerformanceEditAction;
