import React from "react";
import {
  PrimaryButton as Button,
  DefaultButton,
  IButtonProps,
  Spinner,
  SpinnerSize,
  CommandBarButton as CommandButton,
  FontIcon,
} from "office-ui-fabric-react";
import colors from "~/assets/js/colors";
import Colors from "~/assets/js/colors";

interface IDeleteButtonProps {
  minWidth?: string | number;
}

const DeleteButton: React.FC<IButtonProps & IPrimaryButtonProps & IDeleteButtonProps> = (props) => {
  const { isLoading, minWidth } = props;
  return (
    <Button
      styles={{
        root: {
          backgroundColor: colors.error,
          width: 110,
          borderColor: colors.error,
          minWidth
        },
        rootHovered: {
          backgroundColor: "#DF4336",
          borderColor: "#DF4336",
        },
        rootPressed: {
          backgroundColor: "#B74336",
          borderColor: "#B74336",
        },
      }}
      {...props}
    >
      <Spinner
        size={SpinnerSize.small}
        styles={{
          root: {
            width: !isLoading ? 0 : 20,
            overflow: "hidden",
            opacity: !isLoading ? 0 : 1,
            transition: "all 0.2s",
          },
        }}
      />
    </Button>
  );
};

interface IOutlinedButtonProps {
  colorBtn?: string;
}

const OutlinedButton: React.FC<IButtonProps & IOutlinedButtonProps & IPrimaryButtonProps> = (
  props
) => {
  const color = props.colorBtn ? props.colorBtn : colors.primary;
  const { isLoading } = props;

  return (
    <DefaultButton
      styles={{
        ...props.styles,
        root: {
          backgroundColor: "transparent",
          minWidth: 120,
          borderColor: color,
          color: color,
        },
        rootHovered: {
          backgroundColor: "rgba(100,100,100,0.1)",
          borderColor: color,
          color: color,
        },
        rootPressed: {
          backgroundColor: "rgba(0,0,0,0.2)",
          borderColor: color,
          color: color,
        },
      }}
      {...props}
    >
      <Spinner
        size={SpinnerSize.small}
        styles={{
          root: {
            width: !isLoading ? 0 : 20,
            overflow: "hidden",
            opacity: !isLoading ? 0 : 1,
            transition: "all 0.2s",
          },
        }}
      />
    </DefaultButton>
  );
};

export { DeleteButton, OutlinedButton };

interface IPrimaryButtonProps extends IButtonProps {
  isLoading?: boolean;
}

export const PrimaryButton: React.FC<IPrimaryButtonProps> = (props) => {
  const { isLoading } = props;
  return (
    <Button
      {...props}
      color="primary"
      style={{ minWidth: 110 }}
      disabled={props.disabled || isLoading}
      text={props.text ? props.text : "Confirmar"}
    >
      <Spinner
        size={SpinnerSize.small}
        styles={{
          root: {
            width: !isLoading ? 0 : 20,
            overflow: "hidden",
            opacity: !isLoading ? 0 : 1,
            transition: "all 0.2s",
          },
        }}
      />
    </Button>
  );
};

interface ICommandButtonProps extends IButtonProps {
  isLoading?: boolean;
}

export const CommandBarButton: React.FC<ICommandButtonProps> = (props) => {
  const { isLoading } = props;
  return (
    <CommandButton
      {...props}
      disabled={props.disabled || isLoading}
      onRenderIcon={() =>
        isLoading ? (
          <Spinner
            size={SpinnerSize.small}
            styles={{
              root: {
                width: !isLoading ? 0 : 16,
                overflow: "hidden",
                opacity: !isLoading ? 0 : 1,
                transition: "all 0.2s",
                margin: "0 4px",
              },
            }}
          />
        ) : (
          <FontIcon
            iconName={props.iconProps?.iconName}
            style={{ color: Colors.primary, fontSize: 16, margin: "0 4px" }}
          />
        )
      }
    />
  );
};
