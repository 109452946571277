import styled from "styled-components";
import {
  DefaultButton as DefaultBtn,
} from "office-ui-fabric-react";

export const Wrapper = styled.div`
  height: calc(100% - 55px);
  width: 100%;
  flex-basis: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

export const ContainerContent = styled.div`
  height: 100%;
`

export const ListContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

export const DefaultButton = styled(DefaultBtn)`
  width: 110px;
`
