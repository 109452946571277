import styled from 'styled-components';
import { Container as MaterialContainer } from "@material-ui/core";

export const ContainerDropdownInput = styled.div`
    width: auto;
    display: flex;
    align-items: center;

`

export const ContainerHeader = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    @media screen and (max-width: 992px){
      flex-direction: column;
      align-items: flex-start;

     ${ContainerDropdownInput}:last-child{
       margin-top: 10px;
     }
    }
`;

export const InputContainer = styled(MaterialContainer)`
  margin-left: 0px !important;
  padding: 0px !important;
  width: 115px !important;
  margin-right: 0px !important;
`;


export const WrapperForm = styled.div`
  padding: 10px 0 20px;
  box-sizing: border-box;
  width: 100%;
`;
