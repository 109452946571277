import currency from "currency.js";

export const toCurrency = (value: number | string, noSymbol?: boolean): string => {
  if (!noSymbol) {
    return Intl.NumberFormat("pt-br", { style: "currency", currency: "BRL" }).format(Number(value));
  }
  return currency(Number(value), { pattern: "#", separator: ".", decimal: "," }).format();
};

export function arredondaSalario(valor: number | string, casas: number = 2) {
  if (typeof valor === "string") {
    valor = Number(valor);
  }

  const vExp = Math.pow(10, casas);

  let xValor = valor * vExp;

  xValor = Math.ceil(xValor) / vExp;

  return xValor;
}

export const toNumber = (value: string): number => {
  const numberValue = value.replaceAll(".", "").replace(",", ".");
  return Number(numberValue);
};
