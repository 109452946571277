import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Div, Wrapper, Table, TrHead, Th, Tr, TrSubtitle, TdSubtitle, Box, TdSubtitleHorizontal, DetailBox, NumberDetailBox, NumberBox, PercentBox } from './styles';

import { FontIcon, Spinner, SpinnerSize, Stack, Text } from 'office-ui-fabric-react';

import PainelHeader from '~/components/layout/PainelHeader';
import { PrimaryButton } from '~/components/Buttons';
import { Checkbox, ListItemText, MenuItem, Select } from '@material-ui/core';
import Colors from '~/assets/js/colors';
import { makeStyles } from '@material-ui/core/styles';

import { BreadcrumbItems, Page } from '~/store/ducks/home/types';
import { Creators as CreatorsHome } from '~/store/ducks/home'
import { Creators as CreatorsReport } from '~/store/ducks/reports'
import { DataTypes as DataTypesReport } from '~/store/ducks/reports/types'

import { RootState } from '~/store/ducks';
import Dropdown from '~/components/layout/Dropdown';

const useStyles = makeStyles({
    root: {
        height: 32,
        width: 360,
        backgroundColor: 'white',
        '& .MuiOutlinedInput-input': {
            padding: "6px 14.5px"
        },
        '& legend span': {
            display: 'none',
        },
    },
});

interface ILegend {
    talentMatrix: string;
    potencial: string;
    hight: string;
    forGrowth: string;
    careerLevel: string;
    unsatisfactory: string;
    good: string;
    veryGood: string;
    performance: string;
    peopleCalibrated: string;
    talentPopulation: string;
    talentRisk: string;
    inconsistent: string;
    roughDiamond: string;
    solidPro: string;
    highPro: string;
    keyPlayer: string;
    currentStar: string;
    futureStar: string;
    consistentStar: string;
}

const MatrizTalentos: React.FC = () => {
    const [ano, setAno] = useState('');
    const [isCargoLideranca, setIsCargoLideranca] = useState('todos');
    const [grupos, setGrupos] = useState<number[]>([]);
    const [language, setLanguage] = useState<'EN' | 'PT'>('EN');
    const reportState = useSelector<RootState, DataTypesReport>(state => state.reportsReducer);
    const { data: report, dataTalent } = reportState;

    const dispatch = useDispatch();

    const classes = useStyles();

    useEffect(() => {
        dispatch(CreatorsHome.setCurrentPage(page))
        dispatch(CreatorsReport.resetReports())
        dispatch(CreatorsReport.getReport())
    }, [dispatch])

    useEffect(() => {
        if (report.temporadas.length > 0 && ano === '') {
            const temporada = report.temporadas[0];
            setAno(String(temporada.ano) ?? '')
        }
    }, [report.temporadas, ano])

    const handleChange = (event: React.ChangeEvent<{ value: string }>) => {
        const ano: string = event.target.value;
        setAno(ano);
        setGrupos([]);
        dispatch(CreatorsReport.getReport(ano))

    };

    const handleChangeMultiple = (event: React.ChangeEvent<{ value: unknown }>) => {
        setGrupos(event.target.value as number[]);
    };

    const handleGenerateReport = () => {
        dispatch(CreatorsReport.getReportTalent(ano, grupos, isCargoLideranca !== 'todos' ? isCargoLideranca : ''))
    }

    const calcPercent = (value: number = 0): string => {
        const { qtdeTotal = 1 } = reportState.dataTalent ?? {};
        const result = ((value / qtdeTotal) * 100);

        if (!isNaN(result)) {
            return `${result.toFixed(1)}%`;
        } else {
            return ''
        }
    }

    const talentPorcent = calcPercent(dataTalent?.qtdeTalents)

    const legendPT: ILegend = {
        talentMatrix: "MATRIZ DE TALENTOS",
        potencial: "POTENCIAL",
        hight: "ALTO",
        forGrowth: "POTENCIAL",
        careerLevel: "LATERAL (OU)\n CEDO DEMAIS",
        unsatisfactory: "REGULAR/ INSATISFATÓRIO",
        good: "BOM",
        veryGood: "EXCEPCIONAL/ MUITO BOM",
        performance: "PERFORMANCE",
        peopleCalibrated: "CALIBRADOS",
        talentPopulation: "TALENTS",
        consistentStar: "TOP TALENT",
        currentStar: "TALENTO DE ALTO IMPACTO",
        futureStar: "TALENTO EM CRESCIMENTO",
        highPro: "TALENTO COMPROMETIDO",
        inconsistent: "TALENTO EM PROVAÇÃO",
        keyPlayer: "TALENTO EFETIVO",
        roughDiamond: "DIAMANTE BRUTO",
        solidPro: "TALENTO MANTENEDOR",
        talentRisk: "MELHORIA REQUERIDA"
    }

    const legendEN: ILegend = {
        talentMatrix: "TALENTS MATRIX",
        potencial: "POTENCIAL",
        hight: "HIGHT",
        forGrowth: "FOR GROWTH",
        careerLevel: "AT CAREER LEVEL",
        unsatisfactory: "NOT GOOD/ UNSATISFACTORY",
        good: "GOOD",
        veryGood: "OUT STANDING/ VERY GOOD",
        performance: "PERFORMANCE",
        peopleCalibrated: "PEOPLE CALIBRATED",
        talentPopulation: "TALENT POPULATION",
        consistentStar: "CONSISTENT STAR",
        currentStar: "CURRENT STAR",
        futureStar: "FUTURE STAR",
        highPro: "HIGH PROFESSIONAL",
        inconsistent: "INCONSISTENT PLAYER",
        keyPlayer: "KEY PLAYER",
        roughDiamond: "ROUGH DIAMOND",
        solidPro: "SOLID PROFESSIONAL",
        talentRisk: "TALENT RISK",
    }

    const legend = language === 'EN' ? legendEN : legendPT;

    return (
        <Div>
            <PainelHeader
                title="Matriz de Talentos"
                icon={{ icon: "ReportDocument" }}
                notHasMarginLabelGroups={true}
            >
                <Stack horizontal horizontalAlign="space-between" verticalAlign="end" styles={{ root: { marginTop: 30, width: '100%' } }}>
                    <Stack styles={{ root: { width: '100%' } }}>
                        <Stack horizontal verticalAlign="center" style={{ height: 32 }}>
                            <Stack styles={{ root: { minWidth: 120 } }}>
                                <Text variant="medium">Ano</Text>
                            </Stack>
                            <Dropdown
                                errors=""
                                variant="outlined"
                                label=""
                                name="season"
                                values={ano}
                                handleChange={handleChange}
                                fieldHeight={32}
                                styles={{
                                    boxSizing: 'border-box',
                                    height: 32,
                                    backgroundColor: 'white',
                                    width: 170
                                }}
                                autofocus
                                customPadding="6.5px 14px"
                            >
                                {
                                    report.temporadas.map(temp =>
                                        <MenuItem
                                            key={temp.ano}
                                            value={temp.ano}
                                        >
                                            {temp.ano}
                                        </MenuItem>
                                    )
                                }
                            </Dropdown>
                        </Stack>
                        <Stack horizontal verticalAlign="center" styles={{ root: { marginTop: '10px !important' } }}>
                            <Stack horizontal verticalAlign="center">
                                <Stack styles={{ root: { minWidth: 120 } }}>
                                    <Text variant="medium">Tipo de Cargo</Text>
                                </Stack>
                                <Dropdown
                                    errors=""
                                    variant="outlined"
                                    label=""
                                    name="cargo"
                                    values={isCargoLideranca}
                                    handleChange={(e: any) => setIsCargoLideranca(e.target.value)}
                                    fieldHeight={32}
                                    styles={{
                                        boxSizing: 'border-box',
                                        height: 32,
                                        backgroundColor: 'white',
                                        width: 170
                                    }}
                                    customPadding="6.5px 14px"
                                >
                                    <MenuItem value={'todos'}>Todos</MenuItem>
                                    <MenuItem value={'true'}>Com Liderança</MenuItem>
                                    <MenuItem value={'false'}>Sem Liderança</MenuItem>
                                </Dropdown>
                            </Stack>
                            <Stack horizontal verticalAlign="center" style={{ marginLeft: 70 }}>
                                <Stack styles={{ root: { minWidth: 120 } }}>
                                    <Text variant="medium">Grupo</Text>
                                </Stack>
                                <Select
                                    className={classes.root}
                                    variant="outlined"
                                    label=""
                                    name="grupos"
                                    value={grupos}
                                    multiple
                                    onChange={handleChangeMultiple}
                                    disabled={reportState.loading}
                                    renderValue={(selected) => {
                                        const nomeGrupos = report.reunioes.filter(item => {
                                            return (selected as any[]).some(idCicloReuniao => idCicloReuniao === item.idCicloReuniao)
                                        }).map(item => item.grupo.nomeGrupo);
                                        return (nomeGrupos as string[]).join(', ')
                                    }}
                                >
                                    {report.reunioes.map((item, i) => (
                                        <MenuItem key={i} value={item.idCicloReuniao} style={{ height: 40 }}>
                                            <Checkbox checked={grupos.indexOf(item.idCicloReuniao) > -1} style={{ color: Colors.primary }} />
                                            <ListItemText primary={item.grupo.nomeGrupo} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Stack>
                        </Stack>
                    </Stack>
                    <PrimaryButton text="Gerar" onClick={handleGenerateReport} />
                </Stack>
            </PainelHeader>
            {
                reportState.loadingAction ?
                    <Spinner size={SpinnerSize.medium} label="Carregando Curva" styles={{ root: { height: '100%' } }} />
                    :
                    dataTalent &&
                    <Wrapper>
                        <Stack horizontal horizontalAlign="end" styles={{ root: { width: '100%', marginBottom: 10 } }}>
                            <Stack styles={{ root: { width: 100 } }}>
                                <Dropdown
                                    label=""
                                    name="language"
                                    values={language}
                                    variant="outlined"
                                    handleChange={(e: any) => setLanguage(e.target.value)}
                                    autofocus
                                    fieldHeight={35}
                                    noHelperText
                                    styles={{ height: 35 }}
                                    paddingSelected="5px 18px"
                                >
                                    <MenuItem value="EN">EN</MenuItem>
                                    <MenuItem value="PT">PT</MenuItem>
                                </Dropdown>
                            </Stack>
                        </Stack>
                        <Table>
                            <thead>
                                <TrHead bg="#000000" color="#FFFFFF">
                                    <Th colSpan={5}>{legend.talentMatrix} - {ano}</Th>
                                </TrHead>
                                <TrHead color="#707070">
                                    <Th colSpan={3}>
                                        <Stack horizontal verticalAlign="center" style={{ marginLeft: 15 }}>
                                            <FontIcon iconName="Group" style={{ color: '#707070', fontSize: 20, marginRight: 5 }} />
                                            <Text styles={{ root: { fontWeight: 600 } }}>{legend.peopleCalibrated} <span style={{ color: '#201F1E' }}>{dataTalent?.qtdeTotal}</span></Text>
                                        </Stack>
                                    </Th>
                                    <Th colSpan={2}>
                                        <Stack horizontal verticalAlign="center" horizontalAlign="end" style={{ marginRight: 15 }}>
                                            <FontIcon iconName="Diamond" style={{ color: '#707070', fontSize: 20, marginRight: 5 }} />
                                            <Text styles={{ root: { fontWeight: 600 } }}>{legend.talentPopulation} <span style={{ color: '#201F1E' }}>{dataTalent?.qtdeTalents}</span> {dataTalent?.qtdeTalents > 0 ? `(${talentPorcent})` : ''}</Text>
                                        </Stack>
                                    </Th>
                                </TrHead>
                            </thead>
                            <tbody>
                                <Tr>
                                    <TdSubtitle rowSpan={5} bg="#FFFFFF" color="#323130">
                                        <Text variant="mediumPlus" styles={{ root: { transform: 'rotate(180deg)', writingMode: 'tb-rl', fontWeight: 600 } }}>{legend.potencial}</Text>
                                    </TdSubtitle>
                                    <TdSubtitle>
                                        <Text styles={{ root: { transform: 'rotate(180deg)', writingMode: 'tb-rl', fontSize: 11 } }}>{legend.hight}</Text>
                                    </TdSubtitle>
                                    <Box bg="#FDE9D9">
                                        <Stack styles={{ root: { width: '100%', height: 86 } }}>
                                            <Stack horizontal verticalAlign="center">
                                                <NumberDetailBox color="#000000">3</NumberDetailBox>
                                                <DetailBox>{legend.roughDiamond}</DetailBox>
                                            </Stack>
                                            <Stack horizontalAlign="center" styles={{ root: { alignSelf: 'center' } }}>
                                                <NumberBox>{dataTalent?.cel3}</NumberBox>
                                                <PercentBox>{calcPercent(dataTalent?.cel3)}</PercentBox>
                                            </Stack>
                                        </Stack>
                                    </Box>
                                    <Box bg="#76933D" color="#FFFFFF">
                                        <Stack styles={{ root: { width: '100%', height: 86 } }}>
                                            <Stack horizontal verticalAlign="center">
                                                <NumberDetailBox>8</NumberDetailBox>
                                                <DetailBox>{legend.futureStar}</DetailBox>
                                            </Stack>
                                            <Stack horizontalAlign="center" styles={{ root: { alignSelf: 'center' } }}>
                                                <NumberBox>{dataTalent?.cel8}</NumberBox>
                                                <PercentBox>{calcPercent(dataTalent?.cel8)}</PercentBox>
                                            </Stack>
                                        </Stack>
                                    </Box>
                                    <Box bg="#4F6229" color="#FFFFFF">
                                        <Stack styles={{ root: { width: '100%', height: 86 } }}>
                                            <Stack horizontal verticalAlign="center">
                                                <NumberDetailBox color="#000000">9</NumberDetailBox>
                                                <DetailBox>{legend.consistentStar}</DetailBox>
                                            </Stack>
                                            <Stack horizontalAlign="center" styles={{ root: { alignSelf: 'center' } }}>
                                                <NumberBox>{dataTalent?.cel9}</NumberBox>
                                                <PercentBox>{calcPercent(dataTalent?.cel9)}</PercentBox>
                                            </Stack>
                                        </Stack>
                                    </Box>
                                </Tr>
                                <Tr>
                                    <TdSubtitle>
                                        <Text styles={{ root: { transform: 'rotate(180deg)', writingMode: 'tb-rl', fontSize: 11 } }}>{legend.forGrowth}</Text>
                                    </TdSubtitle>
                                    <Box bg="#FCD4B4">
                                        <Stack styles={{ root: { width: '100%', height: 86 } }}>
                                            <Stack horizontal verticalAlign="center">
                                                <NumberDetailBox color="#000000">2</NumberDetailBox>
                                                <DetailBox>{legend.inconsistent}</DetailBox>
                                            </Stack>
                                            <Stack horizontalAlign="center" styles={{ root: { alignSelf: 'center' } }}>
                                                <NumberBox>{dataTalent?.cel2}</NumberBox>
                                                <PercentBox>{calcPercent(dataTalent?.cel2)}</PercentBox>
                                            </Stack>
                                        </Stack>
                                    </Box>
                                    <Box bg="#C4D79B">
                                        <Stack styles={{ root: { width: '100%', height: 86 } }}>
                                            <Stack horizontal verticalAlign="center">
                                                <NumberDetailBox color="#000000">6</NumberDetailBox>
                                                <DetailBox>{legend.keyPlayer}</DetailBox>
                                            </Stack>
                                            <Stack horizontalAlign="center" styles={{ root: { alignSelf: 'center' } }}>
                                                <NumberBox>{dataTalent?.cel6}</NumberBox>
                                                <PercentBox>{calcPercent(dataTalent?.cel6)}</PercentBox>
                                            </Stack>
                                        </Stack>
                                    </Box>
                                    <Box bg="#76933D" color="#FFFFFF">
                                        <Stack styles={{ root: { width: '100%', height: 86 } }}>
                                            <Stack horizontal verticalAlign="center">
                                                <NumberDetailBox color="#000000">7</NumberDetailBox>
                                                <DetailBox>{legend.currentStar}</DetailBox>
                                            </Stack>
                                            <Stack horizontalAlign="center" styles={{ root: { alignSelf: 'center' } }}>
                                                <NumberBox>{dataTalent?.cel7}</NumberBox>
                                                <PercentBox>{calcPercent(dataTalent?.cel7)}</PercentBox>
                                            </Stack>
                                        </Stack>
                                    </Box>
                                </Tr>
                                <Tr>
                                    <TdSubtitle>
                                        <Text styles={{ root: { transform: 'rotate(180deg)', writingMode: 'tb-rl', fontSize: 11, whiteSpace: 'break-spaces' } }}>{legend.careerLevel}</Text>
                                    </TdSubtitle>
                                    <Box bg="#C00C11" color="#FFFFFF">
                                        <Stack styles={{ root: { width: '100%', height: 86 } }}>
                                            <Stack horizontal verticalAlign="center">
                                                <NumberDetailBox color="#000000">1</NumberDetailBox>
                                                <DetailBox>{legend.talentRisk}</DetailBox>
                                            </Stack>
                                            <Stack horizontalAlign="center" styles={{ root: { alignSelf: 'center' } }}>
                                                <NumberBox>{dataTalent?.cel1}</NumberBox>
                                                <PercentBox>{calcPercent(dataTalent?.cel1)}</PercentBox>
                                            </Stack>
                                        </Stack>
                                    </Box>
                                    <Box bg="#EBF0DE">
                                        <Stack styles={{ root: { width: '100%', height: 86 } }}>
                                            <Stack horizontal verticalAlign="center">
                                                <NumberDetailBox color="#000000">4</NumberDetailBox>
                                                <DetailBox>{legend.solidPro}</DetailBox>
                                            </Stack>
                                            <Stack horizontalAlign="center" styles={{ root: { alignSelf: 'center' } }}>
                                                <NumberBox>{dataTalent?.cel4}</NumberBox>
                                                <PercentBox>{calcPercent(dataTalent?.cel4)}</PercentBox>
                                            </Stack>
                                        </Stack>
                                    </Box>
                                    <Box bg="#EBF0DE">
                                        <Stack styles={{ root: { width: '100%', height: 86 } }}>
                                            <Stack horizontal verticalAlign="center">
                                                <NumberDetailBox color="#000000">5</NumberDetailBox>
                                                <DetailBox>{legend.highPro}</DetailBox>
                                            </Stack>
                                            <Stack horizontalAlign="center" styles={{ root: { alignSelf: 'center' } }}>
                                                <NumberBox>{dataTalent?.cel5}</NumberBox>
                                                <PercentBox>{calcPercent(dataTalent?.cel5)}</PercentBox>
                                            </Stack>
                                        </Stack>
                                    </Box>
                                </Tr>
                                <TrSubtitle>
                                    <td></td>
                                    <TdSubtitleHorizontal>{legend.unsatisfactory}</TdSubtitleHorizontal>
                                    <TdSubtitleHorizontal>{legend.good}</TdSubtitleHorizontal>
                                    <TdSubtitleHorizontal>{legend.veryGood}</TdSubtitleHorizontal>
                                </TrSubtitle>
                                <TrSubtitle>
                                    <td colSpan={4}>
                                        <Text variant="mediumPlus" styles={{ root: { fontWeight: 600 } }}>{legend.performance}</Text>
                                    </td>
                                </TrSubtitle>
                            </tbody>
                        </Table>
                    </Wrapper>
            }
        </Div >
    );
}


const itemsBreadCrumb: BreadcrumbItems[] = [
    { text: "Home", isCurrentItem: false, icon: "HomeSolid", onlyIcon: true, url: "/" },
    { text: 'Relatório Matriz de Talentos ', isCurrentItem: true },
];

const page: Page = {
    key: 'relatorioMatrizTalentos',
    pages: itemsBreadCrumb
};


export default MatrizTalentos;