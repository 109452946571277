import { takeEvery, put, call, all } from "redux-saga/effects";
import {
  Types,
  IRequestMeritLoadByIdAction,
  IRequestMeritEditAction,
  IRequestMeritAtualizaPercentualAction,
  IRequestMeritAtualizaPromocaoAction,
  IRequestMeritAtualizaSalarioAction,
  IRequestMeritAtualizaComentarioAction,
  IRequestMeritFinalizarAction,
  IRequestMeritReativarAction,
  IRequestMeritResumoLoadAction,
  IRequestMeriteGetBudgetsAction,
  IRequestMeriteEditBudgetAction,
  IRequestMeritLoadSaldoAction,
  IRequestMeritAprovarAction,
  IRequestMeritReprovarAction,
  IRequestMeritReabrirAction,
  IRequestMeritFinalizarReuniaoAction,
  ISuccessMeritLoadByIdAction,
  ISuccessMeritEditAction,
  ISuccessMeritAtualizaPercentualAction,
  ISuccessMeritAtualizaPromocaoAction,
  ISuccessMeritAtualizaSalarioAction,
  ISuccessMeritAtualizaComentarioAction,
  ISuccessMeritFinalizarAction,
  ISuccessMeritReativarAction,
  ISuccessMeritResumoLoadAction,
  ISuccessMeritGetBudgetsAction,
  ISuccessMeritEditBudgetAction,
  ISuccessMeritLoadSaldoAction,
  ISuccessMeritAprovarAction,
  ISuccessMeritReprovarAction,
  ISuccessMeritReabrirAction,
  ISuccessMeritFinalizarReuniaoAction,
  MeritType,
  MeritEmployee,
  MeritResume,
  IRequestMeritAplicarAction,
  ISuccessMeritAplicarAction,
  IRequestMeritIgnorarAction,
  ISuccessMeritIgnorarAction,
  ISuccessMeritSolicitarAprovacaoAction,
  IRequestMeritSolicitarAprovacaoAction,
  Budget,
  MeritSaldoType,
  BudgetType,
  ISuccessMeritListarCargosAction,
  IRequestMeritListarCargosAction,
  IRequestMeritDownloadCartaAction,
  ISuccessMeritDownloadCartaAction,
  IRequestMeritListarSalariosAction,
  ISuccessMeritListarSalariosAction,
  IRequestMeritListarAvaliacoesAction,
  ISuccessMeritListarAvaliacoesAction,
  IRequestMeritListarOcorrenciasAction,
  ISuccessMeritListarOcorrenciasAction
} from "../ducks/merit/types";

import api from "~/services/api";
import fileDownload from "js-file-download";

const apiCallGet = (idMerito: number) => {
  return api
    .get(`merito/${idMerito}`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallAtualizaPercentual = (
  payload: IRequestMeritAtualizaPercentualAction["payload"]
) => {
  return api
    .put(
      `merito/${payload.idMerito}/atualizarPercentual/${payload.idMeritoFuncionario}`,
      {
        ...payload,
      }
    )
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallAtualizaPromocao = (
  payload: IRequestMeritAtualizaPromocaoAction["payload"]
) => {
  return api
    .put(
      `merito/${payload.idMerito}/atualizaPromocao/${payload.merito.idMeritoFuncionario}`,
      {
        ...payload.merito,
      }
    )
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallAtualizaSalario = (
  payload: IRequestMeritAtualizaSalarioAction["payload"]
) => {
  return api
    .put(
      `merito/${payload.idMerito}/atualizaSalario/${payload.idMeritoFuncionario}`,
      {
        ...payload,
      }
    )
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallAtualizaComentarios = (
  payload: IRequestMeritAtualizaComentarioAction["payload"]
) => {
  return api
    .put(
      `merito/${payload.idMerito}/atualizaComentario/${payload.idMeritoFuncionario}`,
      {
        ...payload,
      }
    )
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallFinalizar = (payload: IRequestMeritFinalizarAction["payload"]) => {
  return api
    .put(`merito/${payload.idMerito}/finalizar/${payload.idMeritoFuncionario}`)
    .then((response) => response.data
    )
    .catch((err) => {
      throw err.response;
    });
};

const apiCallReativar = (payload: IRequestMeritReativarAction["payload"]) => {
  return api
    .put(`merito/${payload.idMerito}/reativar/${payload.idMeritoFuncionario}`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallAplicar = (payload: IRequestMeritAplicarAction["payload"]) => {
  return api
    .put(
      `merito/${payload.idMerito}/aplicarMerito/${payload.idMeritoFuncionario}`
    )
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallIgnorar = (payload: IRequestMeritIgnorarAction["payload"]) => {
  return api
    .put(
      `merito/${payload.idMerito}/ignorarMerito/${payload.idMeritoFuncionario}`
    )
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallLoadResume = (
  payload: IRequestMeritResumoLoadAction["payload"]
) => {
  return api
    .get(`merito/${payload.idMerito}/resumo`, {
      params: {
        decisao: payload.decisao,
      },
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallSolicitarAprovacao = (
  payload: IRequestMeritSolicitarAprovacaoAction["payload"]
) => {
  return api
    .post(`merito/${payload.idMerito}/solicitarAprovacao`, {
      ...payload,
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallGetBudgets = (
  payload: IRequestMeriteGetBudgetsAction["payload"]
) => {
  return api
    .get(`merito/${payload.idMerito}/budget`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallEditBudget = (
  payload: IRequestMeriteEditBudgetAction["payload"]
) => {
  return api
    .post(`merito/${payload.idMerito}/budget`, {
      ...payload.budget,
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallGetSaldo = (
  payload: IRequestMeritLoadSaldoAction["payload"]
) => {
  return api
    .get(`merito/${payload.idMerito}/saldo`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallAprovar = (
  payload: IRequestMeritAprovarAction["payload"]
) => {
  return api
    .post(`merito/${payload.idMerito}/aprovar`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallReprovar = (
  payload: IRequestMeritReprovarAction["payload"]
) => {
  return api
    .post(`merito/${payload.idMerito}/reprovar`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallReabrir = (
  payload: IRequestMeritReabrirAction["payload"]
) => {
  return api
    .post(`merito/${payload.idMerito}/reabrir`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallFinalizarReuniao = (
  payload: IRequestMeritFinalizarReuniaoAction["payload"]
) => {
  return api
    .post(`merito/${payload.idMerito}/finalizar`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallListarCargos = (
  payload: IRequestMeritListarCargosAction["payload"]
) => {
  return api
    .get(`merito/${payload.idMerito}/listarCargos/${payload.idMeritoFuncionario}`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallListarSalarios = (
  payload: IRequestMeritListarSalariosAction["payload"]
) => {
  return api
    .get(`merito/${payload.idMerito}/listarSalarios/${payload.idMeritoFuncionario}`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallListarAvaliacoes = (
  payload: IRequestMeritListarAvaliacoesAction["payload"]
) => {
  return api
    .get(`merito/${payload.idMerito}/listarAvaliacoes/${payload.idMeritoFuncionario}`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallListarOcorrencias = (
  payload: IRequestMeritListarOcorrenciasAction["payload"]
) => {
  return api
    .get(`merito/${payload.idMerito}/listarOcorrencias/${payload.idMeritoFuncionario}`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};


const apiCallDownloadCarta = (
  payload: IRequestMeritDownloadCartaAction["payload"]
) => {
  return api
    .get(
      `merito/${payload.idMerito}/downloadCarta/${payload.idMeritoFuncionario}`,
      {
        responseType: "blob",
      }
    )
    .then((response: any) => {
      const filename = response.headers["content-disposition"].split("=")[1];
      fileDownload(response.data, filename);

      //Create a Blob from the PDF Stream
      // const file = new Blob([response.data], { type: "application/pdf" });
      // //Build a URL from the file
      // const fileURL = URL.createObjectURL(file);
      // //Open the URL on new Window
      // window.open(fileURL);

      return response.data;
    })
    .catch((err) => {
      throw err.response;
    });
};

function* loadByID(action: IRequestMeritLoadByIdAction) {
  try {
    const data: MeritType = yield call(apiCallGet, action.idMerito);

    data.funcionarios.sort((a, b) => b.idTabPerformance - a.idTabPerformance);

    yield put({
      type: Types.SUCCESS_MERIT_LOAD_BY_ID,
      payload: data,
    } as ISuccessMeritLoadByIdAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível carregar os méritos";
    yield put({
      type: Types.FAILURE_MERIT_LOAD_BY_ID,
      message,
    });
  }
}

function* edit(action: IRequestMeritEditAction) {
  try {
    // yield call(apiCallPromote, action.payload);
    yield put({
      type: Types.SUCCESS_MERIT_EDIT,
      payload: {
        merit: action.payload.merit,
      },
    } as ISuccessMeritEditAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível editar o funcionário";
    yield put({
      type: Types.FAILURE_MERIT_EDIT,
      message,
    });
  }
}

function* atualizaPercentual(action: IRequestMeritAtualizaPercentualAction) {
  try {
    const data = yield call(apiCallAtualizaPercentual, action.payload);

    yield put({
      type: Types.SUCCESS_MERIT_ATUALIZA_PERCENTUAL,
      payload: {
        ...data,
        funcionario: {
          idMeritoFuncionario: action.payload.idMeritoFuncionario,
          ...data.funcionario,
        },
      },
    } as ISuccessMeritAtualizaPercentualAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível carregar os percentuais";
    yield put({
      type: Types.FAILURE_MERIT_ATUALIZA_PERCENTUAL,
      message,
    });
  }
}

function* atualizaPromocao(action: IRequestMeritAtualizaPromocaoAction) {
  try {
    const data = yield call(apiCallAtualizaPromocao, action.payload);

    yield put({
      type: Types.SUCCESS_MERIT_ATUALIZA_PROMOCAO,
      payload: {
        ...data,
        funcionario: {
          ...action.payload.merito,
          ...data.funcionario,
        },
      },
    } as ISuccessMeritAtualizaPromocaoAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível atualizar a promoção";
    yield put({
      type: Types.FAILURE_MERIT_ATUALIZA_PROMOCAO,
      message,
    });
  }
}

function* atualizaSalario(action: IRequestMeritAtualizaSalarioAction) {
  try {
    const data = yield call(apiCallAtualizaSalario, action.payload);

    yield put({
      type: Types.SUCCESS_MERIT_ATUALIZA_SALARIO,
      payload: {
        ...data,
        funcionario: {
          idMeritoFuncionario: action.payload.idMeritoFuncionario,
          ...data.funcionario,
        },
      },
    } as ISuccessMeritAtualizaSalarioAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível atualizar o salário";
    yield put({
      type: Types.FAILURE_MERIT_ATUALIZA_SALARIO,
      message,
    });
  }
}

function* atualizaComentarios(action: IRequestMeritAtualizaComentarioAction) {
  try {
    const data = yield call(apiCallAtualizaComentarios, action.payload);

    yield put({
      type: Types.SUCCESS_MERIT_ATUALIZA_COMENTARIO,
      payload: {
        funcionario: {
          idMeritoFuncionario: action.payload.idMeritoFuncionario,
          ...data,
        },
      },
    } as ISuccessMeritAtualizaComentarioAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível atualizar o comentário";
    yield put({
      type: Types.FAILURE_MERIT_ATUALIZA_COMENTARIO,
      message,
    });
  }
}

function* finalizar(action: IRequestMeritFinalizarAction) {
  try {
    const data = yield call(apiCallFinalizar, action.payload);

    yield put({
      type: Types.SUCCESS_MERIT_FINALIZAR,
      payload: {
        ...data,
        funcionario: {
          idMeritoFuncionario: action.payload.idMeritoFuncionario,
          ...data.funcionario,
        },
      },
    } as ISuccessMeritFinalizarAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível finalizar o mérito";
    yield put({
      type: Types.FAILURE_MERIT_FINALIZAR,
      message,
    });
  }
}

function* reativar(action: IRequestMeritReativarAction) {
  try {
    const data = yield call(apiCallReativar, action.payload);

    yield put({
      type: Types.SUCCESS_MERIT_REATIVAR,
      payload: {
        ...data,
        funcionario: {
          idMeritoFuncionario: action.payload.idMeritoFuncionario,
          ...data.funcionario,
        },
      },
    } as ISuccessMeritReativarAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível reativar o mérito";
    yield put({
      type: Types.FAILURE_MERIT_REATIVAR,
      message,
    });
  }
}

function* aplicar(action: IRequestMeritAplicarAction) {
  try {
    const data = yield call(apiCallAplicar, action.payload);

    yield put({
      type: Types.SUCCESS_MERIT_APLICAR,
      payload: {
        ...data,
        funcionario: {
          idMeritoFuncionario: action.payload.idMeritoFuncionario,
          ...data.funcionario,
        },
      },
    } as ISuccessMeritAplicarAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível aplicar o mérito";
    yield put({
      type: Types.FAILURE_MERIT_APLICAR,
      message,
    });
  }
}

function* ignorar(action: IRequestMeritIgnorarAction) {
  try {
    const data = yield call(apiCallIgnorar, action.payload);

    yield put({
      type: Types.SUCCESS_MERIT_IGNORAR,
      payload: {
        ...data,
        funcionario: {
          idMeritoFuncionario: action.payload.idMeritoFuncionario,
          ...data.funcionario,
        },
      },
    } as ISuccessMeritIgnorarAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível ignorar o mérito";
    yield put({
      type: Types.FAILURE_MERIT_IGNORAR,
      message,
    });
  }
}

function* getResumo(action: IRequestMeritResumoLoadAction) {
  try {
    const data: MeritResume = yield call(apiCallLoadResume, action.payload);

    yield put({
      type: Types.SUCCESS_MERIT_RESUMO_LOAD,
      payload: data,
    } as ISuccessMeritResumoLoadAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível carregar o resumo";
    yield put({
      type: Types.FAILURE_MERIT_RESUMO_LOAD,
      message,
    });
  }
}

function* solicitarAprovacao(action: IRequestMeritSolicitarAprovacaoAction) {
  try {
    const data = yield call(apiCallSolicitarAprovacao, action.payload);

    yield put({
      type: Types.SUCCESS_MERIT_SOLICITAR_APROVACAO,
      payload: data
    } as ISuccessMeritSolicitarAprovacaoAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível solicitar a aprovação";
    yield put({
      type: Types.FAILURE_MERIT_SOLICITAR_APROVACAO,
      message,
    });
  }
}

function* getBudgets(action: IRequestMeriteGetBudgetsAction) {
  try {
    const budgets: Budget[] = yield call(apiCallGetBudgets, action.payload);

    yield put({
      type: Types.SUCCESS_MERIT_GET_BUDGETS,
      payload: budgets,
    } as ISuccessMeritGetBudgetsAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível carregar o budget";
    yield put({
      type: Types.FAILURE_MERIT_GET_BUDGETS,
      message,
    });
  }
}

function* editBudget(action: IRequestMeriteEditBudgetAction) {
  try {
    const budget: BudgetType = yield call(apiCallEditBudget, action.payload);
    yield put({
      type: Types.SUCCESS_MERIT_EDIT_BUDGET,
      payload: budget,
    } as ISuccessMeritEditBudgetAction);

  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível atualizar o budget";
    yield put({
      type: Types.FAILURE_MERIT_EDIT_BUDGET,
      message,
    });
  }
}

function* getSaldo(action: IRequestMeritLoadSaldoAction) {
  try {
    const saldo: MeritSaldoType = yield call(apiCallGetSaldo, action.payload);
    yield put({
      type: Types.SUCCESS_MERIT_LOAD_SALDO,
      payload: saldo,
    } as ISuccessMeritLoadSaldoAction);

  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível carregar o saldo";
    yield put({
      type: Types.FAILURE_MERIT_LOAD_SALDO,
      message,
    });
  }
}

function* aprovar(action: IRequestMeritAprovarAction) {
  try {
    const data = yield call(apiCallAprovar, action.payload);
    yield put({
      type: Types.SUCCESS_MERIT_APROVAR,
      payload: data,
    } as ISuccessMeritAprovarAction);

  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível aprovar";
    yield put({
      type: Types.FAILURE_MERIT_APROVAR,
      message,
    });
  }
}

function* reprovar(action: IRequestMeritReprovarAction) {
  try {
    const data = yield call(apiCallReprovar, action.payload);
    yield put({
      type: Types.SUCCESS_MERIT_REPROVAR,
      payload: data,
    } as ISuccessMeritReprovarAction);

  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível reprovar";
    yield put({
      type: Types.FAILURE_MERIT_REPROVAR,
      message,
    });
  }
}

function* reabrir(action: IRequestMeritReabrirAction) {
  try {
    const data = yield call(apiCallReabrir, action.payload);
    yield put({
      type: Types.SUCCESS_MERIT_REABRIR,
      payload: data,
    } as ISuccessMeritReabrirAction);

  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível reabrir a reunião";
    yield put({
      type: Types.FAILURE_MERIT_REABRIR,
      message,
    });
  }
}

function* finalizarReuniao(action: IRequestMeritFinalizarReuniaoAction) {
  try {
    const data = yield call(apiCallFinalizarReuniao, action.payload);
    yield put({
      type: Types.SUCCESS_MERIT_FINALIZAR_REUNIAO,
      payload: data,
    } as ISuccessMeritFinalizarReuniaoAction);

  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível finalizar a reunião";
    yield put({
      type: Types.FAILURE_MERIT_FINALIZAR_REUNIAO,
      message,
    });
  }
}

function* listarCargosByFuncionario(action: IRequestMeritListarCargosAction) {
  try {
    const data = yield call(apiCallListarCargos, action.payload);
    yield put({
      type: Types.SUCCESS_MERIT_LISTAR_CARGOS,
      payload: data,
    } as ISuccessMeritListarCargosAction);

  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível listar o histórico de cargos";
    yield put({
      type: Types.FAILURE_MERIT_LISTAR_CARGOS,
      message,
    });
  }
}

function* listarSalariosByFuncionario(action: IRequestMeritListarSalariosAction) {
  try {
    const data = yield call(apiCallListarSalarios, action.payload);
    yield put({
      type: Types.SUCCESS_MERIT_LISTAR_SALARIOS,
      payload: data,
    } as ISuccessMeritListarSalariosAction);

  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível listar o histórico de salários";
    yield put({
      type: Types.FAILURE_MERIT_LISTAR_SALARIOS,
      message,
    });
  }
}

function* listarAvaliacoesByFuncionario(action: IRequestMeritListarAvaliacoesAction) {
  try {
    const data = yield call(apiCallListarAvaliacoes, action.payload);
    yield put({
      type: Types.SUCCESS_MERIT_LISTAR_AVALIACOES,
      payload: data,
    } as ISuccessMeritListarAvaliacoesAction);

  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível listar o histórico de avaliações";
    yield put({
      type: Types.FAILURE_MERIT_LISTAR_AVALIACOES,
      message,
    });
  }
}

function* listarOcorrenciasByFuncionario(action: IRequestMeritListarOcorrenciasAction) {
  try {
    const data = yield call(apiCallListarOcorrencias, action.payload);
    yield put({
      type: Types.SUCCESS_MERIT_LISTAR_OCORRENCIAS,
      payload: data,
    } as ISuccessMeritListarOcorrenciasAction);

  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível listar o histórico de ocorrências";
    yield put({
      type: Types.FAILURE_MERIT_LISTAR_OCORRENCIAS,
      message,
    });
  }
}

function* downloadCarta(action: IRequestMeritDownloadCartaAction) {
  try {
    yield call(apiCallDownloadCarta, action.payload);
    yield put({
      type: Types.SUCCESS_MERIT_DOWNLOAD_CARTA,
    } as ISuccessMeritDownloadCartaAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível baixar a carta.";
    yield put({
      type: Types.FAILURE_MERIT_DOWNLOAD_CARTA,
      message,
    });
  }
}

export default function* meritRoot() {
  yield all([
    yield takeEvery(Types.REQUEST_MERIT_LOAD_BY_ID, loadByID),
    yield takeEvery(Types.REQUEST_MERIT_EDIT, edit),
    yield takeEvery(
      Types.REQUEST_MERIT_ATUALIZA_PERCENTUAL,
      atualizaPercentual
    ),
    yield takeEvery(Types.REQUEST_MERIT_ATUALIZA_PROMOCAO, atualizaPromocao),
    yield takeEvery(Types.REQUEST_MERIT_ATUALIZA_SALARIO, atualizaSalario),
    yield takeEvery(
      Types.REQUEST_MERIT_ATUALIZA_COMENTARIO,
      atualizaComentarios
    ),
    yield takeEvery(Types.REQUEST_MERIT_FINALIZAR, finalizar),
    yield takeEvery(Types.REQUEST_MERIT_REATIVAR, reativar),
    yield takeEvery(Types.REQUEST_MERIT_APLICAR, aplicar),
    yield takeEvery(Types.REQUEST_MERIT_IGNORAR, ignorar),
    yield takeEvery(Types.REQUEST_MERIT_RESUMO_LOAD, getResumo),
    yield takeEvery(Types.REQUEST_MERIT_SOLICITAR_APROVACAO, solicitarAprovacao),
    yield takeEvery(Types.REQUEST_MERIT_GET_BUDGETS, getBudgets),
    yield takeEvery(Types.REQUEST_MERIT_EDIT_BUDGET, editBudget),
    yield takeEvery(Types.REQUEST_MERIT_LOAD_SALDO, getSaldo),
    yield takeEvery(Types.REQUEST_MERIT_APROVAR, aprovar),
    yield takeEvery(Types.REQUEST_MERIT_REPROVAR, reprovar),
    yield takeEvery(Types.REQUEST_MERIT_REABRIR, reabrir),
    yield takeEvery(Types.REQUEST_MERIT_FINALIZAR_REUNIAO, finalizarReuniao),
    yield takeEvery(Types.REQUEST_MERIT_LISTAR_CARGOS, listarCargosByFuncionario),
    yield takeEvery(Types.REQUEST_MERIT_LISTAR_SALARIOS, listarSalariosByFuncionario),
    yield takeEvery(Types.REQUEST_MERIT_LISTAR_AVALIACOES, listarAvaliacoesByFuncionario),
    yield takeEvery(Types.REQUEST_MERIT_LISTAR_OCORRENCIAS, listarOcorrenciasByFuncionario),
    yield takeEvery(Types.REQUEST_MERIT_DOWNLOAD_CARTA, downloadCarta),

  ]);
}
