import React from "react";

import { Text } from "office-ui-fabric-react/lib/Text";
import { Stack } from "office-ui-fabric-react/lib/Stack";

import { formattedMeta } from "~/pages/Cycle/Strategic";

import {
  Table,
  Tr,
  TdNumber,
  TdPillar,
  TdGoal,
  TdPeso,
  TdCorpGoal,
  Th,
  ContainerCorpoGoal,
  Gap,
  Tbody,
  LeftItemMeta,
  RightItemMeta,
  BlankTd,
  ThMeta,
  ThReal,
  ThResultado,
  TdReal,
  TdResult,
  ThBonus,
  TdBonus
} from "./styles";

import { ICicloPesosPerfil } from "~/store/ducks/profile/types";
import Colors from "~/assets/js/colors";
import { convertResult } from "~/pages/Tasks/SelfEvaluation/selfEvaluationRenderModules";

interface ITableGoalsProps {
  cicloPesos: ICicloPesosPerfil[];
  totalPesos?: null | number;
  disabled?: boolean;
  removeMetas?: boolean;
}

const TableGoals: React.FC<ITableGoalsProps> = (props) => {
  const cicloPesosData: ICicloPesosPerfil[] = props.cicloPesos;
  const renderMetas = () => {
    return cicloPesosData.map((item, i) => {
      const {
        contMeta,
        cicloPeso,
        pilar,
        meta,
        cicloPesoPai,
        flagIniciativas,
      } = item;
      const { corPilar, nomePilar } = pilar;
      const { objetivo, peso } = cicloPeso;
      const { objetivo: objPai } = cicloPesoPai ?? {};
      return (
        <Tr pillarColor={corPilar} key={i}>
          <TdNumber>{contMeta}</TdNumber>
          <Gap />
          {!flagIniciativas ? (
            <>
              <TdPillar>{nomePilar}</TdPillar>
              <TdGoal>
                <Stack
                  styles={{
                    root: {
                      position: "absolute",
                      inset: 0,
                      justifyContent: "center",
                      padding: "16px",
                    },
                  }}
                >
                  {objPai && (
                    <Text
                      variant="tiny"
                      styles={{ root: { color: "#989898", marginBottom: 5 } }}
                    >
                      {objPai.descObjetivo ?? ""}
                    </Text>
                  )}
                  <Text
                    variant="small"
                    styles={{ root: { textTransform: "uppercase" } }}
                  >
                    {objetivo.descObjetivo}
                  </Text>
                  {objetivo.descricaoMecanica && (
                    <Text
                      variant="small"
                      styles={{
                        root: {
                          textTransform: "uppercase",
                          color: Colors.darkGray,
                          fontSize: 11
                        },
                      }}
                    >
                      ({objetivo.descricaoMecanica})
                    </Text>
                  )}
                </Stack>
              </TdGoal>

              <Gap />
              <TdPeso>{Number(peso)}%</TdPeso>
              <Gap />

              {meta.length > 0 ? (
                <>
                  <TdCorpGoal>
                    <ContainerCorpoGoal>
                      <LeftItemMeta>Min</LeftItemMeta>
                      <RightItemMeta>
                        {formattedMeta(String(meta[0].minimo), objetivo.unidade, objetivo.casasDecimais)}
                      </RightItemMeta>
                    </ContainerCorpoGoal>
                    <ContainerCorpoGoal>
                      <LeftItemMeta>Target</LeftItemMeta>
                      <RightItemMeta>
                        {formattedMeta(String(meta[0].target), objetivo.unidade, objetivo.casasDecimais)}
                      </RightItemMeta>
                    </ContainerCorpoGoal>
                    <ContainerCorpoGoal>
                      <LeftItemMeta>Max</LeftItemMeta>
                      <RightItemMeta>
                        {formattedMeta(String(meta[0].maximo), objetivo.unidade, objetivo.casasDecimais)}
                      </RightItemMeta>
                    </ContainerCorpoGoal>
                  </TdCorpGoal>
                  <Gap />
                  {/* <TdReal>              <--------------- !DESCOMENTAR! (1/3) ---------------> 
                    {
                      meta[0].resultado !== null &&
                      <Stack verticalAlign="space-between">
                        <Text variant="medium" styles={{ root: { fontWeight: 'bold', fontSize: 12 } }}>
                          {formattedMeta(meta[0].resultado ?? "0", objetivo.unidade, objetivo.casasDecimais)}</Text>
                        <Text variant="medium" styles={{ root: { fontSize: 12 } }}>
                          {meta[0]?.metaBonus !== null ? `${meta[0].metaBonus}` : ""}%
                        </Text>
                      </Stack>
                    }
                  </TdReal>
                  <Gap />
                  <TdResult>
                    <Stack horizontal verticalAlign="center">
                      {
                        meta[0]?.avaliacao !== null ? convertResult(meta[0].avaliacao) : ""
                      }
                    </Stack>
                  </TdResult>
                  <Gap />
                  <TdBonus>
                    {meta[0]?.percPeso !== null ? `${(Number(meta[0]?.percPeso)).toFixed(2)}%` : ""}
                  </TdBonus> */}
                </>
              ) : (
                <BlankTd />
              )}


            </>
          ) : (
            <>
              <TdPillar style={{ width: 580, minWidth: 580 }} colSpan={2}>
                {objetivo.descObjetivo}
              </TdPillar>
              <Gap />
              <TdPeso>{Number(peso)}%</TdPeso>
              <Gap />
            </>
          )}
        </Tr>
      );
    });
  };

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th colSpan={5}></th>
            <Th>PESO</Th>
            <Gap />
            <ThMeta>META</ThMeta>
            <Gap />
            {/* <ThReal>REAL / %</ThReal>       <--------------- !DESCOMENTAR! (2/3) ---------------> 
            <Gap />
            <ThResultado>RESULTADO</ThResultado>
            <Gap />
            <ThBonus>%</ThBonus>
            <Gap /> */}
          </tr>
        </thead>
        <Tbody>{renderMetas()}
          {/* {!!props.totalPesos && <tr>    <--------------- !DESCOMENTAR! (3/3) ---------------> 
            <th colSpan={13}></th>
            <TdBonus>{props.totalPesos.toFixed(2)}%</TdBonus>
          </tr>
          } */}
        </Tbody>
      </Table>
    </>
  );
};

export default TableGoals;
