import { takeEvery, put, call, all } from "redux-saga/effects";
import {
    Types,
    PDIType,
    IRequestPDILoadAction,
    IRequestPDIAddAction,
    IRequestPDIEditAction,
    IRequestPDIDeleteAction,
    ISuccessPDIAddAction,
    ISuccessPDILoadAction,
    ISuccessPDIEditAction,
    ISuccessPDIDeleteAction,
} from "../../ducks/admin/pdi/types";
import api from "~/services/api";

const apiCallLoad = (payload: IRequestPDILoadAction["payload"]) => {
    return api
        .get(`acoespdi/`, {
            params: {
                SearchText: payload.SearchText,
                TipoPDI: payload.TipoPDI,
                Competencia: payload.Competencia
            }
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            throw err.response;
        });
};

const apiCallAdd = (payload: IRequestPDIAddAction["payload"]) => {
    return api
        .post(`acoespdi`, {
            ...payload.pdi
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            throw err.response;
        });
};

const apiCallEdit = (payload: IRequestPDIEditAction["payload"]) => {
    return api
        .put(`acoespdi/${payload.pdi.idAcaoPDI}`, {
            ...payload.pdi
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            throw err.response;
        });
};

const apiCallDel = (idAcaoPDI: number) => {
    return api
        .delete(`acoespdi/${idAcaoPDI}`)
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            throw err.response;
        });
};

function* load(action: IRequestPDILoadAction) {
    try {
        const data: PDIType[] = yield call(apiCallLoad, action.payload);
        yield put({
            type: Types.SUCCESS_PDI_LOAD,
            payload: data
        } as ISuccessPDILoadAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível carregar os PDIs'
        yield put({
            type: Types.FAILURE_PDI_LOAD,
            message
        });
    }
}

function* add(action: IRequestPDIAddAction) {
    try {
        const acaoPDI: PDIType = yield call(apiCallAdd, action.payload);

        yield put({
            type: Types.SUCCESS_PDI_ADD,
            payload: {
                ...action.payload.pdi,
                idAcaoPDI: acaoPDI.idAcaoPDI,
            }
        } as ISuccessPDIAddAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível adicionar o PDI'
        yield put({
            type: Types.FAILURE_PDI_ADD,
            message
        });
    }
}

function* edit(action: IRequestPDIEditAction) {
    try {
        yield call(apiCallEdit, action.payload);
        yield put({
            type: Types.SUCCESS_PDI_EDIT,
            payload: {
                ...action.payload.pdi
            }
        } as ISuccessPDIEditAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível editar o PDI'
        yield put({
            type: Types.FAILURE_PDI_EDIT,
            message
        });
    }
}

function* del(action: IRequestPDIDeleteAction) {
    try {
        yield call(apiCallDel, action.idAcaoPDI);
        yield put({
            type: Types.SUCCESS_PDI_DELETE,
            idAcaoPDI: action.idAcaoPDI
        } as ISuccessPDIDeleteAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível excluir o PDI'
        yield put({
            type: Types.FAILURE_PDI_DELETE,
            message
        });
    }
}


export default function* pdiRoot() {
    yield all([
        yield takeEvery(Types.REQUEST_PDI_LOAD, load),
        yield takeEvery(Types.REQUEST_PDI_ADD, add),
        yield takeEvery(Types.REQUEST_PDI_EDIT, edit),
        yield takeEvery(Types.REQUEST_PDI_DELETE, del),
    ]);
}
