import React, { useState, useEffect } from 'react';

import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { FontIcon, ICommandBarStyles, IconButton, Persona, PersonaPresence, PersonaSize, Text } from 'office-ui-fabric-react';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { Panel } from '@fluentui/react/lib/Panel';

import {
    Container,
    FormPanelCommand,
    FormPanelHeader,
    FormPanelContentInner,
    Footer
} from './styles';

import colors from "~/assets/js/colors";
import { CommandBarButton } from '../Buttons';

interface IPanel {
    open: boolean;
    onClose: () => void;
    panelHeader?: JSX.Element;
    footer?: JSX.Element;
    itemsHeader?: JSX.Element | null;
    hideClose?: boolean;
    isLoading: boolean;
};

const PanelDisplay: React.FC<IPanel> = props => {

    const [panel, setPanel] = useState(false);

    useEffect(() => {
        // gerencia o panel
        if (props.open) {
            setPanel(true);
        } else {
            setPanel(false);
        };

    }, [props.open]);

    /**
     * Fecha o Dialog e fecha o Panel
     */
    const handle = () => {
        props.onClose();
    };

    return (
        <>
            <Panel
                isOpen={panel}
                hasCloseButton={false}
                styles={{ main: { width: '654px !important' }, commands: { margin: 0 }, content: { padding: 0 } }}
            >
                <Container>
                    <FormPanelCommand>
                        <IconButton
                            iconProps={{ iconName: 'ChromeClose' }}
                            styles={{
                                root: {
                                    color: colors.darkGray,
                                    display: props.hideClose ? 'none' : 'block'
                                }
                            }}
                            title="Fechar"
                            ariaLabel="Fechar"
                            onClick={handle}
                        />
                    </FormPanelCommand>

                    {props.panelHeader &&
                        <FormPanelHeader>
                            {props.panelHeader}
                        </FormPanelHeader>
                    }

                    <FormPanelContentInner>
                        {
                            props.isLoading ?
                                <Stack styles={{ root: { height: '100%' } }}>
                                    <Spinner size={SpinnerSize.large} styles={{ root: { height: '100%' } }} />
                                </Stack>
                                :
                                <>
                                    {props.children}
                                </>
                        }
                    </FormPanelContentInner>
                    {props.footer &&
                        <Footer>
                            {props.footer}
                        </Footer>
                    }


                </Container>
            </Panel>
        </>
    );
};

interface IPanelHeaderDisplayProps {
    title: string;
    persona: {
        name?: string;
        icon?: string;
    };
    subtitle?: string;
    imageUrl?: string;
    isActive?: boolean;
    buttonTabs?: {
        icon: string;
        text: string;
        disabled?: boolean;
        visible?: boolean;
        handleClick: () => void;
    }[];
}

export const PanelHeaderDisplay: React.FC<IPanelHeaderDisplayProps> = (props) => {
    const { title, buttonTabs, subtitle, imageUrl, persona, isActive } = props;
    const baseURL = process.env.REACT_APP_BASE_URL;

    return (
        <Stack horizontal verticalAlign="center">
            <Persona
                size={PersonaSize.size100}
                initialsColor={colors.primary}
                imageInitials={persona.name}
                imageUrl={imageUrl ? `${baseURL}uploads/${imageUrl}` : ''}
                styles={{ root: { width: 100, marginRight: 32 } }}
                presence={isActive !== undefined ? isActive ? PersonaPresence.online : PersonaPresence.offline : PersonaPresence.none}
                onRenderInitials={() => <FontIcon iconName={persona.icon} style={{ fontWeight: 500 }} />}
            />
            <Stack>
                <Text variant="xxLarge" styles={{ root: { fontWeight: 'bold', textTransform: 'uppercase', color: "#535357" } }}>{title}</Text>
                <Text variant="medium">{subtitle}</Text>
                <Stack horizontal>
                    {
                        buttonTabs?.map((item, i) =>
                            <CommandBarButton
                                key={i}
                                styles={btnStyle}
                                iconProps={{ iconName: item.icon }}
                                text={item.text}
                                disabled={item.disabled}
                                onClick={item.handleClick}
                            />
                        )
                    }
                </Stack>
            </Stack>
        </Stack>
    )
}

const btnStyle: Partial<ICommandBarStyles> = {
    root: {
        height: 44,
        marginTop: 5
    }
};

export default PanelDisplay;