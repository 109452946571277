/**
 * Types
 */
export enum Types {
    //GET
    REQUEST_ES_LOAD = "salaryStructure/REQUEST_ES_LOAD",
    SUCCESS_ES_LOAD = "salaryStructure/SUCCESS_ES_LOAD",
    FAILURE_ES_LOAD = "salaryStructure/FAILURE_ES_LOAD",
    //GET BY ID
    REQUEST_ES_LOAD_BY_ID = "salaryStructure/REQUEST_ES_LOAD_BY_ID",
    SUCCESS_ES_LOAD_BY_ID = "salaryStructure/SUCCESS_ES_LOAD_BY_ID",
    FAILURE_ES_LOAD_BY_ID = "salaryStructure/FAILURE_ES_LOAD_BY_ID",
    //ADD
    REQUEST_ES_ADD = "salaryStructure/REQUEST_ES_ADD",
    SUCCESS_ES_ADD = "salaryStructure/SUCCESS_ES_ADD",
    FAILURE_ES_ADD = "salaryStructure/FAILURE_ES_ADD",
    //EDIT
    REQUEST_ES_EDIT = "salaryStructure/REQUEST_ES_EDIT",
    SUCCESS_ES_EDIT = "salaryStructure/SUCCESS_ES_EDIT",
    FAILURE_ES_EDIT = "salaryStructure/FAILURE_ES_EDIT",
    //DELETE
    REQUEST_ES_DEL = "salaryStructure/REQUEST_ES_DEL",
    SUCCESS_ES_DEL = "salaryStructure/SUCCESS_ES_DEL",
    FAILURE_ES_DEL = "salaryStructure/FAILURE_ES_DEL",
    //GET GS
    REQUEST_ES_LOAD_GS = "salaryStructure/REQUEST_ES_LOAD_GS",
    SUCCESS_ES_LOAD_GS = "salaryStructure/SUCCESS_ES_LOAD_GS",
    FAILURE_ES_LOAD_GS = "salaryStructure/FAILURE_ES_LOAD_GS",
    //GET GS BY ID 
    REQUEST_ES_LOAD_GS_BY_ID = "salaryStructure/REQUEST_ES_LOAD_GS_BY_ID",
    SUCCESS_ES_LOAD_GS_BY_ID = "salaryStructure/SUCCESS_ES_LOAD_GS_BY_ID",
    FAILURE_ES_LOAD_GS_BY_ID = "salaryStructure/FAILURE_ES_LOAD_GS_BY_ID",
    //ADD GS
    REQUEST_ES_ADD_GS = "salaryStructure/REQUEST_ES_ADD_GS",
    SUCCESS_ES_ADD_GS = "salaryStructure/SUCCESS_ES_ADD_GS",
    FAILURE_ES_ADD_GS = "salaryStructure/FAILURE_ES_ADD_GS",
    //EDIT GS
    REQUEST_ES_EDIT_GS = "salaryStructure/REQUEST_ES_EDIT_GS",
    SUCCESS_ES_EDIT_GS = "salaryStructure/SUCCESS_ES_EDIT_GS",
    FAILURE_ES_EDIT_GS = "salaryStructure/FAILURE_ES_EDIT_GS",
    //DELETE GS
    REQUEST_ES_DEL_GS = "salaryStructure/REQUEST_ES_DEL_GS",
    SUCCESS_ES_DEL_GS = "salaryStructure/SUCCESS_ES_DEL_GS",
    FAILURE_ES_DEL_GS = "salaryStructure/FAILURE_ES_DEL_GS",
    //ADD TAB
    REQUEST_ES_TAB = "salaryStructure/REQUEST_ES_TAB",
    SUCCESS_ES_TAB = "salaryStructure/SUCCESS_ES_TAB",
    FAILURE_ES_TAB = "salaryStructure/FAILURE_ES_TAB",
    //GET INFO SALARIAL
    REQUEST_ES_INFO_SALARIAL = "salaryStructure/REQUEST_ES_INFO_SALARIAL",
    SUCCESS_ES_INFO_SALARIAL = "salaryStructure/SUCCESS_ES_INFO_SALARIAL",
    FAILURE_ES_INFO_SALARIAL = "salaryStructure/FAILURE_ES_INFO_SALARIAL",
    //CLEAR ES
    CLEAR_ES = "salaryStructure/CLEAR_ES"
};


/**
 * Data types
 */

export type SalaryStructureType = {
    idES: number | null;
    codFolha: string;
    nomeES: string;
    flagAtiva: boolean;
    tipoES: string;
    horasTrab: number | null;
    vigencia: Date | null;
    tabs?: TabSalaryStructureType[];
};

type TabSalaryStructureType = {
    idESTab: number | null;
    dataTab: Date;
    percent: string;
}

export type SalaryStructureSelectedState = {
    item: SalaryStructureType | null;
    loading: boolean;
    success: boolean;
    error: boolean;
}

export type GS = {
    idGS: number;
    gs: number | null;
    valorMedio: number | null;
    flagPiso?: boolean;
    flagTeto?: boolean;
    flagPermiteMov?: boolean;
    flagDissidio?: boolean;
    steps: Step[];
}

type Step = {
    percent: string | number;
    Valor?: number;
}

export type SalaryStructureGS = {
    resp: GS[];
    steps: Step[];
}

interface ISalaryStructureState {
    readonly data: SalaryStructureType[];
    readonly dataGS: SalaryStructureGS | null;
    readonly gsSelected: GS | null;
    readonly itemSelected: SalaryStructureSelectedState;
    readonly infoSalarial: Partial<GS> | null;
    readonly loadingData: boolean;
    readonly loadingDataGS: boolean;
    readonly loadingAction: boolean;
    readonly error: boolean;
    readonly success: boolean;
    readonly successGs: boolean;
};

export type DataTypes = ISalaryStructureState;


/**
 * Actions
 */

//GET
export interface IRequestSalaryStructureLoadAction {
    type: typeof Types.REQUEST_ES_LOAD;
    search: string;
};

export interface ISuccessSalaryStructureLoadAction {
    type: typeof Types.SUCCESS_ES_LOAD;
    payload: SalaryStructureType[];
};

interface IFailureSalaryStructureLoadAction {
    type: typeof Types.FAILURE_ES_LOAD;
    message: string;
};

type SalaryStructureLoadAction = IRequestSalaryStructureLoadAction | ISuccessSalaryStructureLoadAction | IFailureSalaryStructureLoadAction;

//GET BY ID
export interface IRequestSalaryStructureLoadByIdAction {
    type: typeof Types.REQUEST_ES_LOAD_BY_ID;
    idES: number;
};

export interface ISuccessSalaryStructureLoadByIdAction {
    type: typeof Types.SUCCESS_ES_LOAD_BY_ID;
    payload: SalaryStructureType;
};

interface IFailureSalaryStructureLoadByIdAction {
    type: typeof Types.FAILURE_ES_LOAD_BY_ID;
    message: string;
};

type SalaryStructureLoadByIdAction = IRequestSalaryStructureLoadByIdAction | ISuccessSalaryStructureLoadByIdAction | IFailureSalaryStructureLoadByIdAction;


//ADD
export interface IRequestSalaryStructureAddAction {
    type: typeof Types.REQUEST_ES_ADD;
    payload: SalaryStructureType;
};

export interface ISuccessSalaryStructureAddAction {
    type: typeof Types.SUCCESS_ES_ADD;
    payload: SalaryStructureType;
};

interface IFailureSalaryStructureAddAction {
    type: typeof Types.FAILURE_ES_ADD;
    message: string;
};

type SalaryStructureAddAction = IRequestSalaryStructureAddAction | ISuccessSalaryStructureAddAction | IFailureSalaryStructureAddAction;

//EDIT
export interface IRequestSalaryStructureEditAction {
    type: typeof Types.REQUEST_ES_EDIT;
    payload: SalaryStructureType;
};

export interface ISuccessSalaryStructureEditAction {
    type: typeof Types.SUCCESS_ES_EDIT;
    payload: SalaryStructureType;
};

interface IFailureSalaryStructureEditAction {
    type: typeof Types.FAILURE_ES_EDIT;
    message: string;
};

type SalaryStructureEditAction = IRequestSalaryStructureEditAction | ISuccessSalaryStructureEditAction | IFailureSalaryStructureEditAction;

//DELETE
export interface IRequestSalaryStructureDelAction {
    type: typeof Types.REQUEST_ES_DEL;
    idES: number;
};

export interface ISuccessSalaryStructureDelAction {
    type: typeof Types.SUCCESS_ES_DEL;
    idES: number;
};

interface IFailureSalaryStructureDelAction {
    type: typeof Types.FAILURE_ES_DEL;
    message: string;
};

type SalaryStructureDelAction = IRequestSalaryStructureDelAction | ISuccessSalaryStructureDelAction | IFailureSalaryStructureDelAction;

//LOAD GS
export interface IRequestSalaryStructureLoadGSAction {
    type: typeof Types.REQUEST_ES_LOAD_GS;
    payload: {
        idES: number;
        idESTab: number;
    }
};

export interface ISuccessSalaryStructureLoadGSAction {
    type: typeof Types.SUCCESS_ES_LOAD_GS;
    payload: SalaryStructureGS;
};

interface IFailureSalaryStructureLoadGSAction {
    type: typeof Types.FAILURE_ES_LOAD_GS;
    message: string;
};

type SalaryStructureLoadGSAction = IRequestSalaryStructureLoadGSAction | ISuccessSalaryStructureLoadGSAction | IFailureSalaryStructureLoadGSAction;

//LOAD GS BY ID
export interface IRequestSalaryStructureLoadGSByIdAction {
    type: typeof Types.REQUEST_ES_LOAD_GS_BY_ID;
    payload: {
        idES: number;
        idESTab: number;
        idGS: number;
    }
};

export interface ISuccessSalaryStructureLoadGSByIdAction {
    type: typeof Types.SUCCESS_ES_LOAD_GS_BY_ID;
    payload: GS;
};

interface IFailureSalaryStructureLoadGSByIdAction {
    type: typeof Types.FAILURE_ES_LOAD_GS_BY_ID;
    message: string;
};

type SalaryStructureLoadGSByIdAction = IRequestSalaryStructureLoadGSByIdAction | ISuccessSalaryStructureLoadGSByIdAction | IFailureSalaryStructureLoadGSByIdAction;

//ADD GS
export interface IRequestSalaryStructureAddGSAction {
    type: typeof Types.REQUEST_ES_ADD_GS;
    payload: {
        idES: number;
        idESTab: number;
        gs: Partial<GS>;
    }
};

export interface ISuccessSalaryStructureAddGSAction {
    type: typeof Types.SUCCESS_ES_ADD_GS;
    payload: GS;
};

interface IFailureSalaryStructureAddGSAction {
    type: typeof Types.FAILURE_ES_ADD_GS;
    message: string;
};

type SalaryStructureAddGSAction = IRequestSalaryStructureAddGSAction | ISuccessSalaryStructureAddGSAction | IFailureSalaryStructureAddGSAction;

//EDIT GS
export interface IRequestSalaryStructureEditGSAction {
    type: typeof Types.REQUEST_ES_EDIT_GS;
    payload: {
        idES: number;
        idESTab: number;
        gs: Partial<GS>;
    }
};

export interface ISuccessSalaryStructureEditGSAction {
    type: typeof Types.SUCCESS_ES_EDIT_GS;
    payload: GS;
};

interface IFailureSalaryStructureEditGSAction {
    type: typeof Types.FAILURE_ES_EDIT_GS;
    message: string;
};

type SalaryStructureEditGSAction = IRequestSalaryStructureEditGSAction | ISuccessSalaryStructureEditGSAction | IFailureSalaryStructureEditGSAction;

//DEL GS
export interface IRequestSalaryStructureDelGSAction {
    type: typeof Types.REQUEST_ES_DEL_GS;
    payload: {
        idES: number;
        idESTab: number;
        idGS: number;
    }
};

export interface ISuccessSalaryStructureDelGSAction {
    type: typeof Types.SUCCESS_ES_DEL_GS;
    idGS: number;
};

interface IFailureSalaryStructureDelGSAction {
    type: typeof Types.FAILURE_ES_DEL_GS;
    message: string;
};

type SalaryStructureDelGSAction = IRequestSalaryStructureDelGSAction | ISuccessSalaryStructureDelGSAction | IFailureSalaryStructureDelGSAction;

//ADD TAB
export interface IRequestSalaryStructureAddTabAction {
    type: typeof Types.REQUEST_ES_TAB;
    payload: {
        idES: number;
        dataTab: Date;
        percent: number;
    }
};

export interface ISuccessSalaryStructureAddTabAction {
    type: typeof Types.SUCCESS_ES_TAB;
    payload: TabSalaryStructureType;
};

interface IFailureSalaryStructureAddTabAction {
    type: typeof Types.FAILURE_ES_TAB;
    message: string;
};

interface ClearES {
    type: typeof Types.CLEAR_ES
}

type SalaryStructureAddTabAction = IRequestSalaryStructureAddTabAction | ISuccessSalaryStructureAddTabAction | IFailureSalaryStructureAddTabAction | ClearES;

//ADD TAB
export interface IRequestEsGetInfoSalarialAction {
    type: typeof Types.REQUEST_ES_INFO_SALARIAL;
    payload: {
        idES: number;
        gs: number;
    }
};

export interface ISuccessEsGetInfoSalarialAction {
    type: typeof Types.SUCCESS_ES_INFO_SALARIAL;
    payload: Partial<GS>;
};

interface IFailureEsGetInfoSalarialAction {
    type: typeof Types.FAILURE_ES_INFO_SALARIAL;
    message: string;
};

type EsGetInfoSalarialAction = IRequestEsGetInfoSalarialAction | ISuccessEsGetInfoSalarialAction | IFailureEsGetInfoSalarialAction;


export type SalaryStructureActionTypes =
    SalaryStructureLoadAction |
    SalaryStructureLoadByIdAction |
    SalaryStructureAddAction |
    SalaryStructureEditAction |
    SalaryStructureDelAction |
    SalaryStructureLoadGSAction |
    SalaryStructureAddGSAction |
    SalaryStructureAddTabAction |
    SalaryStructureEditGSAction |
    SalaryStructureDelGSAction |
    SalaryStructureLoadGSByIdAction |
    EsGetInfoSalarialAction;