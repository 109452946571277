import { takeEvery, put, call, all } from "redux-saga/effects";
import {
    Types,
    CycleTeamType,
    IRequestCycleTeamsLoadAction,
    IRequestCycleTeamsTransferAction,
    ISuccessCycleTeamsLoadAction,
    ISuccessCycleTeamsTransferAction
} from "../ducks/cycle/teams/types";
import api from "~/services/api";

const apiCallLoad = (payload: IRequestCycleTeamsLoadAction["payload"]) => {
    return api
        .get(`ciclos/${payload.idCiclo}/times/pesquisa`, {
            params: {
                idLider: payload.idLider,
                idLiderado: payload.idLiderado
            }
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            throw err.response;
        });
};

const apiCallTransfer = (payload: IRequestCycleTeamsTransferAction["payload"]) => {
    return api
        .put(`ciclos/${payload.idCiclo}/times/transferir`, {
            ...payload
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            throw err.response;
        });
};

function* load(action: IRequestCycleTeamsLoadAction) {
    try {
        const data: CycleTeamType[] = yield call(apiCallLoad, action.payload);
        yield put({
            type: Types.SUCCESS_CYCLE_TEAMS_LOAD,
            payload: data
        } as ISuccessCycleTeamsLoadAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível carregar o time'
        yield put({
            type: Types.FAILURE_CYCLE_TEAMS_LOAD,
            message
        });
    }
}

function* transfer(action: IRequestCycleTeamsTransferAction) {
    try {
        yield call(apiCallTransfer, action.payload);
        yield put({
            type: Types.SUCCESS_CYCLE_TEAMS_TRANSFER,
            payload: {
                membros: action.payload.membros,
                liderNome: action.payload.liderNome
            }
        } as ISuccessCycleTeamsTransferAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível fazer a transferência'
        yield put({
            type: Types.FAILURE_CYCLE_TEAMS_TRANSFER,
            message
        });
    }
}


export default function* cycleTeamsRoot() {
    yield all([
        yield takeEvery(Types.REQUEST_CYCLE_TEAMS_LOAD, load),
        yield takeEvery(Types.REQUEST_CYCLE_TEAMS_TRANSFER, transfer),
    ]);
}
