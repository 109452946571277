import styled from 'styled-components';


export const TitleContainer = styled.div`
  width: 100%;
  background-color: #ffffff;
  border-bottom: solid 1px #cfd3da;
  margin-bottom: 5px;
  padding-bottom: 10px;
`;

export const WrapperCommandBar = styled.div`
  padding: 0 !important;
  margin:0 !important;
  position: relative;
  z-index: 999;
`