import styled from 'styled-components';

export const Div = styled.div`
    flex-basis: 100%;
    width: calc(100% - 250px);
    height: calc(100vh - 50px);
    display: flex;
    flex-direction: column;
    overflow: auto;
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  z-index: 1;
  padding: 20px;
`;

export const Table = styled.table`
  border-collapse: separate;
  border-spacing: 10px;
  width: calc(100% + 20px);
  margin: 0 -10px;
`

export const Th = styled.th`
  font-size: 16px;
  font-weight: 600;
  background-color: #201F1E;
  color: #FFFFFF;
  border-radius: 2px;
  height: 40px;
`

export const TrHead = styled.tr`

`

export const Tr = styled.tr`

`

export const TitleBox = styled.p`
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
`
export const PercentBox = styled.p`
  font-size: 14px;
  font-weight: 200;  
`

export const ValueBox = styled.p`
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
`

export const TdBox = styled.td<{ color: string, bg?: string }>`
  height: 90px;
  max-height: 90px;
  min-height: 90px;
  width: 110px;
  min-width: 110px;
  max-width: 110px;
  border: 1px solid ${({ color }) => color};
  background-color: ${({ bg }) => bg ? bg : '#FFFFFF'};
  color: ${({ bg }) => bg ? '#FFFFFF' : '#323130'};
  border-radius: 2px;
  box-sizing: border-box;

  ${TitleBox}{
    color: ${({ color, bg }) => bg ? '#FFFFFF' : color};
  };

  ${PercentBox}{
    color: ${({ bg }) => bg ? '#FFFFFF' : '#323130'};
  };

  ${ValueBox}{
    color: ${({ bg }) => bg ? '#FFFFFF' : '#323130'};
  }

`
export const TdLegend = styled.td`
  background-color: #201F1E;
  font-size: 12;
  font-weight: 600;
  text-align: center;
  color: #FFFFFF;
  border-radius: 2px;
  box-sizing: border-box;
  height: 40px;
`
