import styled from 'styled-components';

export const ContainerStatus = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`
export const StatusItem = styled.span<{bgColor: string}>`
    width: 15px;
    height: 15px;
    background-color: ${props => props.bgColor};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    color: #FFFFFF;
    line-height: 1px;
    padding-bottom: 1px;
    font-weight: 400 !important;
    font-size: 12px;
`