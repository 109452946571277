import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
//FluentUI
import { Pivot, PivotItem, IPivotStyles } from 'office-ui-fabric-react/lib/Pivot';

//Componentes
import PainelHeader from '~/components/layout/PainelHeader';
import { RootState } from "~/store/ducks";

import Pillars from "./Pillars";
import Strategic from "./Strategic";
import Dismemberment from "./Dismemberment";
import Validation from "./Validation";
import CalibrationGroup from "./CalibrationGroup";
import Teams from './Teams';

import {
  Wrapper,
  Div,
} from "./styles";

import { Creators as getCycle } from "~/store/ducks/cycle";
import { Creators as getPillarsCycle } from "~/store/ducks/cycle";
import { Creators as setCurrentPage } from "~/store/ducks/home";
import { Creators as startValidationCycle } from "~/store/ducks/cycle";
import { Creators as clearDismemberment } from "~/store/ducks/cycle/dismemberment";
import { Creators as getCycleGroups } from "~/store/ducks/cycle/groups";
import { Creators as clearTeam } from "~/store/ducks/cycle/teams";
import { Creators as getWorklevel } from "~/store/ducks/worklevel";
import { DataTypes as DataTypesLogin } from '~/store/ducks/login/types';

import { Page, BreadcrumbItems } from "~/store/ducks/home/types";
import { DataTypes } from "~/store/ducks/cycle/types";
import { formattedStatus } from "../Cycles"

interface ICycleState {
  isDialogOpen: boolean;
  redirect: boolean;
  idCycle: number | null;
  currentPivot: string;
  currentPainel: string | null;
};

interface ICycleProps {
  cycle: DataTypes;
  login: DataTypesLogin;
  getCycle: (year: number) => void;
  setCurrentPage: (page: Page) => void;
  match: any;
  getPillarsCycle: (id: number) => void;
  startValidationCycle: (idCiclo: number) => void;
  clearDismemberment: () => void;
  getCycleGroups: (idCiclo: number) => void;
  getWorklevel: () => void;
  clearTeam: () => void;
};

const pivotStyles: Partial<IPivotStyles> = {
  root: {
    borderBottom: '1px solid #CFD3DA',
    padding: '10px 20px 0px',
    position: 'fixed',
    backgroundColor: "#FFFFFF",
    width: '100%',
    zIndex: 1000,
  },
  linkContent: {
    width: 130
  }
}

class Cycle extends Component<ICycleProps, ICycleState> {
  constructor(props: ICycleProps) {
    super(props);
    this.state = {
      isDialogOpen: false,
      redirect: false,
      idCycle: null,
      currentPivot: "0",
      currentPainel: null
    };
  };

  componentDidMount() {
    const { idCiclo } = this.props.match.params;
    this.props.getCycle(idCiclo);
    this.props.clearDismemberment();
    this.props.getWorklevel();
    this.props.clearTeam();

    if (idCiclo !== null && this.props.login.data.flagAdministrador) {
      this.props.getPillarsCycle(idCiclo);
      this.props.getCycleGroups(idCiclo);
    }

  }

  componentDidUpdate(prevProps: ICycleProps, prevState: ICycleState) {
    const { ano: nextAno } = this.props.cycle.data.cycle;
    const { error: prevError } = prevProps.cycle;
    const { error: nextError } = this.props.cycle;

    if (prevError !== nextError) {
      if (nextError && nextAno === null) {
        this.setState({ redirect: true });
      };
    };
    if (prevProps.cycle.successData !== this.props.cycle.successData) {
      this.setState({ idCycle: this.props.match.params.idCiclo })
      if (nextAno !== null) {
        const items: BreadcrumbItems[] = [
          { text: "Home", isCurrentItem: false, icon: "HomeSolid", onlyIcon: true, url: "/" },
          { text: 'Ciclos', url: "/ciclos" },
          { text: nextAno!.toString(), isCurrentItem: true },
        ];

        const page: Page = {
          key: 'ciclos',
          pages: items
        };

        this.props.setCurrentPage(page);
      }
    }

    if (prevProps.cycle.successValidation !== this.props.cycle.successValidation) {
      if (this.props.cycle.successValidation) {
        this.handlePivot('5')
      }
    }
  }

  toggleDialog = () => {
    this.setState({ isDialogOpen: !this.state.isDialogOpen });
  };

  handlePivot = (idPivot: string) => {
    this.setState({ currentPivot: idPivot })
  }

  handleChangePainel = (nivel: string) => {
    this.setState({ currentPainel: nivel })
  }

  handleValidarCiclo = () => {
    const { idCiclo } = this.props.match.params;

    this.props.startValidationCycle(idCiclo);
  }

  handlePublicarCiclo = () => {
    console.log("Publicar Ciclo")
  }

  render() {
    const { idCycle, currentPivot } = this.state;
    const { cycle, login } = this.props;
    const { cycle: ciclo } = cycle.data;
    const { flagEfetividade, flagAdministrador } = login.data;

    if (this.state.redirect) return (
      <Redirect to="/ciclos" />
    );

    return (
      <Div>
        <PainelHeader
          title={ciclo.ano ? `Ciclo ${String(ciclo.ano)}` : ""}
          icon={{ svgIcon: "/static/icons/ciclo.svg" }}
          labelGroups={[
            {
              icon: "contact",
              label: "Responsável",
              value: ciclo.responsavel ? ciclo.responsavel!.nomeCompleto! : ""
            },
            {
              icon: "Flag",
              label: "Status",
              value: formattedStatus(ciclo.status!)
            }
          ]}
          button={
            ciclo.status === 'P' ?
              {
                text: "Habilitar Validação",
                handleButton: this.handleValidarCiclo,
                isLoadingAction: cycle.loadingValidation
              }
              :
              ciclo.status === 'A' ?
                {
                  text: "Publicar Ciclo",
                  disabled: true,
                  handleButton: () => console.log("Publicar Ciclo")
                }
                :
                null
          }
        />
        <Wrapper>
          <Pivot aria-label="Definições do ciclo"
            selectedKey={String(currentPivot)}
            onLinkClick={(e) => this.handlePivot(e?.props.itemKey!)}
            styles={pivotStyles}
          >
            {
              flagAdministrador &&
              <PivotItem
                headerText="Pilares"
                style={{
                  position: 'relative',
                  top: 55,
                }}
                itemKey="2"
                key="2"
              >
                <Pillars idCycle={idCycle} />
              </PivotItem>
            }
            {
              (flagAdministrador || flagEfetividade) &&
              <PivotItem
                headerText="Painel estratégico"
                itemKey="3"
                key="3"
              >
                <Strategic
                  idCycle={this.props.match.params.idCiclo}
                  changePivot={this.handlePivot}
                />
              </PivotItem>
            }
            {
              (flagAdministrador || flagEfetividade) &&
              <PivotItem
                headerText="Desmembramento das Metas"
                itemKey="4"
                key="4"
              >
                <Dismemberment idCiclo={idCycle} />
              </PivotItem>
            }
            {
              ((flagAdministrador || flagEfetividade) && ciclo.status === 'V') &&
              <PivotItem
                headerText="Validação"
                itemKey="5"
                key="5"
              >
                <Validation idCiclo={idCycle!} />
              </PivotItem>
            }
            {
              flagAdministrador &&
              <PivotItem
                headerText="Grupos de Calibração"
                itemKey="1"
                key="1"
                style={{
                  height: "100%",
                  overflow: 'auto',
                }}
              >
                <CalibrationGroup idCycle={idCycle} />
              </PivotItem>
            }
            {
              flagAdministrador &&
              <PivotItem
                headerText="Times"
                itemKey="6"
                key="6"
                style={{
                  height: "100%",
                  overflow: 'auto',
                }}
              >
                <Teams idCycle={idCycle!} />
              </PivotItem>
            }

          </Pivot>
        </Wrapper>
      </Div>
    );
  };
};



const mapStateToProps = (state: RootState) => ({
  cycle: state.cycleReducer,
  login: state.loginReducer
});

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  ...getCycle,
  ...setCurrentPage,
  ...getCycleGroups,
  ...getPillarsCycle,
  ...getWorklevel,
  ...clearTeam,
  ...startValidationCycle,
  ...clearDismemberment,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Cycle);