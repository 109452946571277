import { takeEvery, put, call, all } from "redux-saga/effects";
import {
    Types,
    IRequestVacancyLoadAction,
    ISuccessVacancyLoadAction,
    VacancyType
} from "../ducks/vacancies/types";
import api from "~/services/api";

const apiCallEdit = (payload: IRequestVacancyLoadAction["payload"]) => {
    return api
        .get(`funcionarios/pesquisaVaga`, {
            params: {
                ...payload
            }
        })
        .then((response) => response.data)
        .catch((err) => {
            throw err.response;
        });
};

function* load(action: IRequestVacancyLoadAction) {
    try {
        const data: VacancyType[] = yield call(apiCallEdit, action.payload);
        yield put({
            type: Types.SUCCESS_VACANCIES_LOAD,
            payload: data
        } as ISuccessVacancyLoadAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível carregar as vagas'
        yield put({
            type: Types.FAILURE_VACANCIES_LOAD,
            message
        });
    }
}

export default function* vacancyRoot() {
    yield all([
        yield takeEvery(Types.REQUEST_VACANCIES_LOAD, load),
    ]);
}
