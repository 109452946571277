import React from "react";
import CustomChip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import { FontIcon } from "office-ui-fabric-react";

import { getAbbreviatedName } from "~/utils/abbreviatedName";
import Colors from "~/assets/js/colors";

interface IChipProps {
  imagem?: string;
  disabled?: boolean;
  disabledChip?: boolean;
  nome: string;
  handleClick?: () => void;
}

const Chip: React.FC<IChipProps> = (props) => {
  const { imagem, nome, disabled, handleClick, disabledChip } = props;

  const avatarStyle: React.CSSProperties = {
    backgroundColor: disabled ? "#BDBDBD" : Colors.primary,
    color: disabled ? "#333333" : "#FFFFFF",
    width: 24,
    height: 24,
    margin: 0,
    fontSize: 12,
    fontWeight: 500,
    padding: 0,
  };

  return (
    <CustomChip
      size="small"
      avatar={
        imagem ? (
          <Avatar style={avatarStyle} alt={nome} src={imagem} />
        ) : (
          <Avatar style={avatarStyle}>{getAbbreviatedName(nome)}</Avatar>
        )
      }
      variant="outlined"
      label={<p>{nome}</p> ?? ""}
      disabled={disabledChip}
      onDelete={!disabledChip ? handleClick : undefined}
      deleteIcon={
        <FontIcon
          iconName={disabled ? "Add" : "Cancel"}
          style={{
            fontSize: 12,
            color: "#323130",
            display: "flex",
            alignItems: "center",
            marginLeft: 5,
          }}
        />
      }
      style={{
        width: "fit-content",
        marginRight: 20,
        marginTop: 10,
        fontSize: 14,
        backgroundColor: "#F3F2F1",
        border: "none",
        opacity: disabled ? 0.5 : 1,
      }}
    />
  );
};

export default Chip;
