import styled from 'styled-components';
import { FontIcon } from 'office-ui-fabric-react/lib/Icon';

export const DetailsWrapperItem = styled.div<{ noTop?: boolean, size?: string }>`
    display: flex;
    width: ${({ size }) => size === 'half' ? 155 : 320}px;
    flex-direction: row;
    margin-right: 10px;
    margin-top: 10px;
`

export const DetailsWrapperText = styled.div`
    display: flex;
    flex-direction: column;
    width: max-content;
`

export const IconAction = styled(FontIcon)`
    font-size: 16px;
    align-self: center;
    padding: 5px;
    cursor: default;
    border-radius: 50%;

    &:hover{
        background-color: #F3F2F1;
    }
`