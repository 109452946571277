import {
  Types,
  DataTypes,
  EmployeesActionTypes,
  EmployeeType,
  EmployeeAreaType,
  EmployeeCCType,
  EmployeeUnidadeType,
  EmployeeCargoType,
  EmployeeSalaryType,
  EmployeeOccurrenceType,
  EmployeeFaltaType,
} from "./types";
import customToast from "~/components/Toast/index";
import moment from "moment";

/**
 * Reducers
 */
const INITIAL_STATE: DataTypes = {
  data: [],
  error: false,
  loadingData: false,
  loadingAction: false,
  success: false,
  successEdit: false,
  info: {
    idFuncionario: null,
    matricula: null,
    nomeCompleto: "",
  },
  itemSelected: {
    item: {
      descAcesso: "",
      idFuncionario: null,
      cpf: "",
      email: "",
      matricula: "",
      nomeCompleto: "",
      sexo: "",
      password: "",
      flagAcessoRHR: false,
      dataAdmissao: null,
      status: "",
      //unidade: null,
      unidades: null,
      cargo: null,
      area: null,
      cc: null,
      salario: null,
      dataNascimento: null,
      imagem: "",
      flagAdministrador: false,
      flagBP: false,
      flagRH: false,
      idVinculo: null,
      vinculo: null,
    },
    areas: null,
    cc: null,
    unidades: null,
    cargos: null,
    ocorrencias: null,
    salarios: null,
    faltas: null,
    error: false,
    loading: false,
    loadingItems: false,
    loadingAction: false,
    success: false,
  },
};

/**
 * Actions
 */

export const Creators = {
  getEmployees: (
    search?: string,
    filter?: boolean | null,
    onlyActives?: boolean
  ): EmployeesActionTypes => {
    const pesquisa = search !== "" && search !== undefined ? search : "";
    const filtro = filter !== null && filter !== undefined ? filter : null;
    const apenasAtivos =
      onlyActives !== null && onlyActives !== undefined ? onlyActives : null;
    return {
      type: Types.REQUEST_EMPLOYEES_LOAD,
      search: {
        searchText: pesquisa,
        status: filtro,
        apenasAtivos,
      },
    };
  },

  getEmployeeById: (id: number): EmployeesActionTypes => ({
    type: Types.REQUEST_EMPLOYEES_LOAD_ID,
    id,
  }),

  getEmployeeInfo: (id: number): EmployeesActionTypes => ({
    type: Types.REQUEST_EMPLOYEES_LOAD_INFO,
    id,
  }),

  addEmployee: (employee: EmployeeType): EmployeesActionTypes => ({
    type: Types.REQUEST_EMPLOYEES_ADD,
    employee,
  }),

  editEmployee: (employee: EmployeeType): EmployeesActionTypes => ({
    type: Types.REQUEST_EMPLOYEES_EDIT,
    employee,
  }),

  editDadosCadastraisEmployee: (
    idFuncionario: number,
    nomeCompleto: string,
    cpf: string,
    email: string,
    dataNascimento: Date,
    sexo: string
  ): EmployeesActionTypes => ({
    type: Types.REQUEST_EMPLOYEES_EDIT_DADOS_CADASTRAIS,
    payload: {
      idFuncionario,
      cpf,
      dataNascimento,
      email,
      nomeCompleto,
      sexo,
    },
  }),

  editEmployeePassword: (id: number): EmployeesActionTypes => ({
    type: Types.REQUEST_EMPLOYEES_EDIT_PASSWORD,
    payload: {
      id,
    },
  }),

  editEmployeeAccess: (
    id: number,
    flagAcessoRHR: boolean,
    descAcesso: string
  ): EmployeesActionTypes => ({
    type: Types.REQUEST_EMPLOYEES_EDIT_ACCESS,
    payload: {
      id,
      flagAcessoRHR,
      descAcesso,
    },
  }),

  delEmployee: (id: number): EmployeesActionTypes => ({
    type: Types.REQUEST_EMPLOYEES_DELETE,
    id,
  }),

  resetEmployees: (): EmployeesActionTypes => ({
    type: Types.RESET_EMPLOYEES,
  }),

  loadEmployeeArea: (idFuncionario: number): EmployeesActionTypes => ({
    type: Types.REQUEST_EMPLOYEES_AREAS_LOAD,
    idFuncionario,
  }),

  addEmployeeArea: (
    idFuncionario: number,
    area: EmployeeAreaType
  ): EmployeesActionTypes => ({
    type: Types.REQUEST_EMPLOYEES_AREA_ADD,
    payload: {
      idFuncionario,
      area,
    },
  }),

  delEmployeeArea: (
    idFuncionario: number,
    idHistArea: number
  ): EmployeesActionTypes => ({
    type: Types.REQUEST_EMPLOYEES_AREA_DEL,
    payload: {
      idFuncionario,
      idHistArea,
    },
  }),

  loadEmployeeCC: (idFuncionario: number): EmployeesActionTypes => ({
    type: Types.REQUEST_EMPLOYEES_CC_LOAD,
    idFuncionario,
  }),

  addEmployeeCC: (
    idFuncionario: number,
    cc: EmployeeCCType
  ): EmployeesActionTypes => ({
    type: Types.REQUEST_EMPLOYEES_CC_ADD,
    payload: {
      idFuncionario,
      cc,
    },
  }),

  delEmployeeCC: (
    idFuncionario: number,
    idHistCC: number
  ): EmployeesActionTypes => ({
    type: Types.REQUEST_EMPLOYEES_CC_DEL,
    payload: {
      idFuncionario,
      idHistCC,
    },
  }),

  loadEmployeeUnidades: (idFuncionario: number): EmployeesActionTypes => ({
    type: Types.REQUEST_EMPLOYEES_UNIDADES_LOAD,
    idFuncionario,
  }),

  addEmployeeUnidade: (
    idFuncionario: number,
    unidade: EmployeeUnidadeType
  ): EmployeesActionTypes => ({
    type: Types.REQUEST_EMPLOYEES_UNIDADES_ADD,
    payload: {
      idFuncionario,
      unidade,
    },
  }),

  delEmployeeUnidade: (
    idFuncionario: number,
    idHistUnidade: number
  ): EmployeesActionTypes => ({
    type: Types.REQUEST_EMPLOYEES_UNIDADES_DEL,
    payload: {
      idFuncionario,
      idHistUnidade,
    },
  }),

  loadEmployeeCargos: (idFuncionario: number): EmployeesActionTypes => ({
    type: Types.REQUEST_EMPLOYEES_CARGOS_LOAD,
    idFuncionario,
  }),

  addEmployeeCargo: (
    idFuncionario: number,
    cargo: EmployeeCargoType
  ): EmployeesActionTypes => ({
    type: Types.REQUEST_EMPLOYEES_CARGOS_ADD,
    payload: {
      idFuncionario,
      cargo,
    },
  }),

  editEmployeeCargo: (
    idFuncionario: number,
    cargo: EmployeeCargoType
  ): EmployeesActionTypes => ({
    type: Types.REQUEST_EMPLOYEES_CARGOS_EDIT,
    payload: {
      idFuncionario,
      cargo,
    },
  }),

  delEmployeeCargo: (
    idFuncionario: number,
    idHistCargo: number
  ): EmployeesActionTypes => ({
    type: Types.REQUEST_EMPLOYEES_CARGOS_DEL,
    payload: {
      idFuncionario,
      idHistCargo,
    },
  }),

  loadEmployeeSalarios: (idFuncionario: number): EmployeesActionTypes => ({
    type: Types.REQUEST_EMPLOYEES_SALARIOS_LOAD,
    idFuncionario,
  }),

  addEmployeeSalario: (
    idFuncionario: number,
    salario: EmployeeSalaryType
  ): EmployeesActionTypes => ({
    type: Types.REQUEST_EMPLOYEES_SALARIOS_ADD,
    payload: {
      idFuncionario,
      salario,
    },
  }),

  editEmployeeSalario: (
    idFuncionario: number,
    salario: EmployeeSalaryType
  ): EmployeesActionTypes => ({
    type: Types.REQUEST_EMPLOYEES_SALARIOS_EDIT,
    payload: {
      idFuncionario,
      salario,
    },
  }),

  delEmployeeSalario: (
    idFuncionario: number,
    idHistSalario: number
  ): EmployeesActionTypes => ({
    type: Types.REQUEST_EMPLOYEES_SALARIOS_DEL,
    payload: {
      idFuncionario,
      idHistSalario,
    },
  }),

  loadEmployeeOcorrencias: (idFuncionario: number): EmployeesActionTypes => ({
    type: Types.REQUEST_EMPLOYEES_OCCURRENCES_LOAD,
    idFuncionario,
  }),

  addEmployeeOcorrencia: (
    idFuncionario: number,
    ocorrencia: EmployeeOccurrenceType
  ): EmployeesActionTypes => ({
    type: Types.REQUEST_EMPLOYEES_OCCURRENCES_ADD,
    payload: {
      idFuncionario,
      ocorrencia,
    },
  }),

  editEmployeeOcorrencia: (
    idFuncionario: number,
    ocorrencia: EmployeeOccurrenceType
  ): EmployeesActionTypes => ({
    type: Types.REQUEST_EMPLOYEES_OCCURRENCES_EDIT,
    payload: {
      idFuncionario,
      ocorrencia,
    },
  }),

  delEmployeeOcorrencia: (
    idFuncionario: number,
    idFuncOcorrencia: number
  ): EmployeesActionTypes => ({
    type: Types.REQUEST_EMPLOYEES_OCCURRENCES_DEL,
    payload: {
      idFuncionario,
      idFuncOcorrencia,
    },
  }),

  loadEmployeeFaltas: (idFuncionario: number): EmployeesActionTypes => ({
    type: Types.REQUEST_EMPLOYEES_FALTAS_LOAD,
    idFuncionario,
  }),

  addEmployeeFalta: (
    idFuncionario: number,
    falta: EmployeeFaltaType
  ): EmployeesActionTypes => ({
    type: Types.REQUEST_EMPLOYEES_FALTA_ADD,
    payload: { idFuncionario, falta },
  }),

  editEmployeeFalta: (
    idFuncionario: number,
    falta: EmployeeFaltaType
  ): EmployeesActionTypes => ({
    type: Types.REQUEST_EMPLOYEES_FALTA_EDIT,
    payload: { idFuncionario, falta },
  }),

  delEmployeeFalta: (
    idFuncionario: number,
    idHistFalta: number
  ): EmployeesActionTypes => ({
    type: Types.REQUEST_EMPLOYEES_FALTA_DEL,
    payload: { idFuncionario, idHistFalta },
  }),
};

export default function employeesReducer(
  state = INITIAL_STATE,
  action: EmployeesActionTypes
): DataTypes {
  switch (action.type) {
    case Types.REQUEST_EMPLOYEES_LOAD:
      return {
        ...state,
        loadingData: true,
        error: false,
        success: false,
      };
    case Types.SUCCESS_EMPLOYEES_LOAD:
      return {
        ...state,
        data: action.payload,
        loadingData: false,
        error: false,
        success: true,
      };
    case Types.FAILURE_EMPLOYEES_LOAD:
      customToast.error(action.message);
      return {
        ...state,
        data: [],
        loadingData: false,
        error: true,
        success: false,
      };

    case Types.REQUEST_EMPLOYEES_LOAD_ID:
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          loading: true,
          success: false,
          error: false,
          loadingItems: false,
        },
      };
    case Types.SUCCESS_EMPLOYEES_LOAD_ID:
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          item: action.payload,
          loading: false,
          success: true,
          error: false,
          areas: null,
          cc: null,
          unidades: null,
          cargos: null,
          salarios: null,
          loadingItems: false,
        },
      };
    case Types.FAILURE_EMPLOYEES_LOAD_ID:
      customToast.error(action.message);
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          loading: false,
          success: false,
          error: true,
          loadingItems: false,
      }
    }

    case Types.REQUEST_EMPLOYEES_LOAD_INFO:
      return {
        ...state,
      };
    case Types.SUCCESS_EMPLOYEES_LOAD_INFO:
      return {
        ...state,
        info: action.payload,
      };
    case Types.FAILURE_EMPLOYEES_LOAD_INFO:
      customToast.error(action.message);
      return {
        ...state,
        info: {
          idFuncionario: null,
          matricula: null,
          nomeCompleto: "",
        },
      };

    case Types.REQUEST_EMPLOYEES_ADD:
      return {
        ...state,
        loadingAction: true,
        error: false,
        success: false,
      };
    case Types.SUCCESS_EMPLOYEES_ADD:
      customToast.success("Funcionário cadastrado com sucesso");
      return {
        ...state,
        loadingAction: false,
        error: false,
        success: true,
        data: [...state.data, action.employee],
      };
    case Types.FAILURE_EMPLOYEES_ADD:
      customToast.error(action.message);
      return {
        ...state,
        loadingAction: false,
        error: true,
        success: false,
      };

    case Types.REQUEST_EMPLOYEES_EDIT:
      return {
        ...state,
        loadingAction: true,
        error: false,
        successEdit: false,
      };
    case Types.SUCCESS_EMPLOYEES_EDIT:
      customToast.success("Funcionário editado com sucesso");
      const { employee } = action;
      return {
        ...state,
        loadingAction: false,
        error: false,
        successEdit: true,
        data: state.data.map((item) => {
          if (item.idFuncionario !== employee.idFuncionario) {
            return item;
          }

          return {
            ...item,
            ...employee,
          };
        }),
        itemSelected: {
          ...state.itemSelected,
          item: { ...state.itemSelected.item, ...employee },
        },
      };
    case Types.FAILURE_EMPLOYEES_EDIT:
      customToast.error(action.message);
      return {
        ...state,
        loadingAction: false,
        error: true,
        successEdit: false,
      };
    case Types.REQUEST_EMPLOYEES_EDIT_DADOS_CADASTRAIS:
      return {
        ...state,
        loadingAction: true,
        error: false,
        successEdit: false,
      };
    case Types.SUCCESS_EMPLOYEES_EDIT_DADOS_CADASTRAIS:
      customToast.success("Funcionário editado com sucesso");
      return {
        ...state,
        loadingAction: false,
        error: false,
        successEdit: true,
        data: state.data.map((item) => {
          if (item.idFuncionario !== action.employee.idFuncionario) {
            return item;
          }
          return {
            ...item,
            ...action.employee,
          };
        }),
        itemSelected: {
          ...state.itemSelected,
          item: { ...state.itemSelected.item, ...action.employee },
        },
      };
    case Types.FAILURE_EMPLOYEES_EDIT_DADOS_CADASTRAIS:
      customToast.error(action.message);
      return {
        ...state,
        loadingAction: false,
        error: true,
        successEdit: false,
      };

    case Types.REQUEST_EMPLOYEES_EDIT_PASSWORD:
      return { ...state };
    case Types.SUCCESS_EMPLOYEES_EDIT_PASSWORD:
      customToast.success(
        "A senha foi alterada e enviada para o e-mail do funcionário"
      );
      return { ...state };
    case Types.FAILURE_EMPLOYEES_EDIT_PASSWORD:
      customToast.error(action.message);
      return { ...state };

    case Types.REQUEST_EMPLOYEES_EDIT_ACCESS:
      return {
        ...state,
        successEdit: false,
        itemSelected: {
          ...state.itemSelected,
          error: false,
          loading: true,
          success: false,
        },
      };
    case Types.SUCCESS_EMPLOYEES_EDIT_ACCESS:
      customToast.success("Acesso do funcionário ao RHR alterado com sucesso");
      return {
        ...state,
        loadingAction: false,
        error: false,
        successEdit: true,
        itemSelected: {
          ...state.itemSelected,
          item: {
            ...state.itemSelected.item,
            flagAcessoRHR: action.payload.flagAcessoRHR,
            descAcesso: action.payload.descAcesso,
          },
          error: false,
          loading: false,
          success: true,
        },
      };
    case Types.FAILURE_EMPLOYEES_EDIT_ACCESS:
      customToast.error(action.message);
      return {
        ...state,
        successEdit: false,
        itemSelected: {
          ...state.itemSelected,
          error: true,
          loading: false,
          success: false,
        },
      };

    case Types.REQUEST_EMPLOYEES_DELETE:
      return {
        ...state,
        loadingAction: true,
        error: false,
        success: false,
      };
    case Types.SUCCESS_EMPLOYEES_DELETE:
      customToast.success("Funcionário removido com sucesso");
      return {
        ...state,
        loadingAction: false,
        error: false,
        success: true,
        data: state.data.filter((item) => item.idFuncionario !== action.id),
      };
    case Types.FAILURE_EMPLOYEES_DELETE:
      customToast.error(action.message);
      return {
        ...state,
        loadingAction: false,
        error: true,
        success: false,
      };

    case Types.REQUEST_EMPLOYEES_AREAS_LOAD:
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          areas: null,
          loadingItems: true,
        },
      };
    case Types.SUCCESS_EMPLOYEES_AREAS_LOAD:
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          areas: action.payload,
          loadingItems: false,
        },
      };
    case Types.FAILURE_EMPLOYEES_AREAS_LOAD:
      customToast.error(action.message);
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          areas: null,
          loadingItems: false,
        },
      };

    case Types.REQUEST_EMPLOYEES_AREA_ADD:
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          loadingAction: true,
          success: false,
        },
      };
    case Types.SUCCESS_EMPLOYEES_AREA_ADD:
      customToast.success("A área do funcionário foi adicionada com sucesso");
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          areas: [
            ...(state.itemSelected.areas ?? []),
            action.payload,
          ].sort((a, b) => moment(b.dataHist).diff(moment(a.dataHist))),
          loadingAction: false,
          success: true,
        },
      };
    case Types.FAILURE_EMPLOYEES_AREA_ADD:
      customToast.error(action.message);
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          loadingAction: false,
          success: false,
        },
      };

    case Types.REQUEST_EMPLOYEES_AREA_DEL:
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          loadingAction: true,
          success: false,
        },
      };
    case Types.SUCCESS_EMPLOYEES_AREA_DEL:
      customToast.success("A área do funcionário foi excluída com sucesso");
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          areas:
            state.itemSelected.areas?.filter(
              (item) => item.idHistArea !== action.idHistArea
            ) ?? [],
          loadingAction: false,
          success: true,
        },
      };
    case Types.FAILURE_EMPLOYEES_AREA_DEL:
      customToast.error(action.message);
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          loadingAction: false,
          success: false,
        },
      };

    case Types.REQUEST_EMPLOYEES_CC_LOAD:
      return {
        ...state,
        itemSelected: { ...state.itemSelected, cc: null, loadingItems: true },
      };
    case Types.SUCCESS_EMPLOYEES_CC_LOAD:
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          cc: action.payload,
          loadingItems: false,
        },
      };
    case Types.FAILURE_EMPLOYEES_CC_LOAD:
      customToast.error(action.message);
      return {
        ...state,
        itemSelected: { ...state.itemSelected, cc: null, loadingItems: false },
      };

    case Types.REQUEST_EMPLOYEES_CC_ADD:
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          loadingAction: true,
          success: false,
        },
      };
    case Types.SUCCESS_EMPLOYEES_CC_ADD:
      customToast.success(
        "O centro de custo do funcionário foi adicionado com sucesso"
      );
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          cc: [...(state.itemSelected.cc ?? []), action.payload].sort((a, b) =>
            moment(b.dataHist).diff(moment(a.dataHist))
          ),
          loadingAction: false,
          success: true,
        },
      };
    case Types.FAILURE_EMPLOYEES_CC_ADD:
      customToast.error(action.message);
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          loadingAction: false,
          success: false,
        },
      };

    case Types.REQUEST_EMPLOYEES_CC_DEL:
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          loadingAction: true,
          success: false,
        },
      };
    case Types.SUCCESS_EMPLOYEES_CC_DEL:
      customToast.success(
        "O centro de custo do funcionário foi excluído com sucesso"
      );
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          cc:
            state.itemSelected.cc?.filter(
              (item) => item.idHistCC !== action.idHistCC
            ) ?? [],
          loadingAction: false,
          success: true,
        },
      };
    case Types.FAILURE_EMPLOYEES_CC_DEL:
      customToast.error(action.message);
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          loadingAction: false,
          success: false,
        },
      };

    case Types.REQUEST_EMPLOYEES_UNIDADES_LOAD:
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          unidades: null,
          loadingItems: true,
        },
      };
    case Types.SUCCESS_EMPLOYEES_UNIDADES_LOAD:
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          unidades: action.payload,
          loadingItems: false,
        },
      };
    case Types.FAILURE_EMPLOYEES_UNIDADES_LOAD:
      customToast.error(action.message);
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          unidades: null,
          loadingItems: false,
        },
      };

    case Types.REQUEST_EMPLOYEES_UNIDADES_ADD:
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          loadingAction: true,
          success: false,
        },
      };
    case Types.SUCCESS_EMPLOYEES_UNIDADES_ADD:
      customToast.success(
        "A unidade do funcionário foi adicionada com sucesso"
      );
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          unidades: [
            ...(state.itemSelected.unidades ?? []),
            action.payload,
          ].sort((a, b) => moment(b.dataHist).diff(moment(a.dataHist))),
          loadingAction: false,
          success: true,
        },
      };
    case Types.FAILURE_EMPLOYEES_UNIDADES_ADD:
      customToast.error(action.message);
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          loadingAction: false,
          success: false,
        },
      };

    case Types.REQUEST_EMPLOYEES_UNIDADES_DEL:
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          loadingAction: true,
          success: false,
        },
      };
    case Types.SUCCESS_EMPLOYEES_UNIDADES_DEL:
      customToast.success("A unidade do funcionário foi excluída com sucesso");
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          unidades:
            state.itemSelected.unidades?.filter(
              (item) => item.idHistUnidade !== action.idHistUnidade
            ) ?? [],
          loadingAction: false,
          success: true,
        },
      };
    case Types.FAILURE_EMPLOYEES_UNIDADES_DEL:
      customToast.error(action.message);
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          loadingAction: false,
          success: false,
        },
      };
    case Types.REQUEST_EMPLOYEES_CARGOS_LOAD:
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          cargos: null,
          loadingItems: true,
        },
      };
    case Types.SUCCESS_EMPLOYEES_CARGOS_LOAD:
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          cargos: action.payload,
          loadingItems: false,
        },
      };
    case Types.FAILURE_EMPLOYEES_CARGOS_LOAD:
      customToast.error(action.message);
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          cargos: null,
          loadingItems: false,
        },
      };

    case Types.REQUEST_EMPLOYEES_CARGOS_ADD:
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          loadingAction: true,
          success: false,
        },
      };
    case Types.SUCCESS_EMPLOYEES_CARGOS_ADD:
      customToast.success("O cargo do funcionário foi adicionado com sucesso");
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          cargos: [
            ...(state.itemSelected.cargos ?? []),
            action.payload,
          ].sort((a, b) => moment(b.dataHist).diff(moment(a.dataHist))),
          loadingAction: false,
          success: true,
        },
      };
    case Types.FAILURE_EMPLOYEES_CARGOS_ADD:
      customToast.error(action.message);
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          loadingAction: false,
          success: false,
        },
      };

    case Types.REQUEST_EMPLOYEES_CARGOS_EDIT:
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          loadingAction: true,
          success: false,
        },
      };
    case Types.SUCCESS_EMPLOYEES_CARGOS_EDIT:
      customToast.success("O cargo do funcionário foi editado com sucesso");
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          cargos: (state.itemSelected.cargos ?? [])
            .map((item) => {
              if (item.idHistCargo === action.payload.idHistCargo)
                return action.payload;
              return item;
            })
            .sort((a, b) => moment(b.dataHist).diff(moment(a.dataHist))),
          loadingAction: false,
          success: true,
        },
      };
    case Types.FAILURE_EMPLOYEES_CARGOS_EDIT:
      customToast.error(action.message);
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          loadingAction: false,
          success: false,
        },
      };

    case Types.REQUEST_EMPLOYEES_CARGOS_DEL:
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          loadingAction: true,
          success: false,
        },
      };
    case Types.SUCCESS_EMPLOYEES_CARGOS_DEL:
      customToast.success("O cargo do funcionário foi excluído com sucesso");
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          cargos:
            state.itemSelected.cargos?.filter(
              (item) => item.idHistCargo !== action.idHistCargo
            ) ?? [],
          loadingAction: false,
          success: true,
        },
      };
    case Types.FAILURE_EMPLOYEES_CARGOS_DEL:
      customToast.error(action.message);
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          loadingAction: false,
          success: false,
        },
      };
    // LOAD SALARIO
    case Types.REQUEST_EMPLOYEES_SALARIOS_LOAD:
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          salarios: null,
          loadingItems: true,
        },
      };
    case Types.SUCCESS_EMPLOYEES_SALARIOS_LOAD:
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          salarios: action.payload,
          loadingItems: false,
        },
      };
    case Types.FAILURE_EMPLOYEES_SALARIOS_LOAD:
      customToast.error(action.message);
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          salarios: null,
          loadingItems: false,
        },
      };

    // ADD SALARIO
    case Types.REQUEST_EMPLOYEES_SALARIOS_ADD:
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          loadingAction: true,
          success: false,
        },
      };
    case Types.SUCCESS_EMPLOYEES_SALARIOS_ADD:
      customToast.success(
        "O salário do funcionário foi adicionado com sucesso"
      );
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          salarios: [
            ...(state.itemSelected.salarios ?? []),
            action.payload,
          ].sort((a, b) => moment(b.dataHist).diff(moment(a.dataHist))),
          loadingAction: false,
          success: true,
        },
      };
    case Types.FAILURE_EMPLOYEES_SALARIOS_ADD:
      customToast.error(action.message);
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          loadingAction: false,
          success: false,
        },
      };

    // EDIT SALARIO
    case Types.REQUEST_EMPLOYEES_SALARIOS_EDIT:
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          loadingAction: true,
          success: false,
        },
      };
    case Types.SUCCESS_EMPLOYEES_SALARIOS_EDIT:
      customToast.success("O salário do funcionário foi editado com sucesso");
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          salarios: (state.itemSelected.salarios ?? [])
            .map((item) => {
              if (item.idHistSalario === action.payload.idHistSalario)
                return action.payload;
              return item;
            })
            .sort((a, b) => moment(b.dataHist).diff(moment(a.dataHist))),
          loadingAction: false,
          success: true,
        },
      };
    case Types.FAILURE_EMPLOYEES_SALARIOS_EDIT:
      customToast.error(action.message);
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          loadingAction: false,
          success: false,
        },
      };

    // DEL SALARIO
    case Types.REQUEST_EMPLOYEES_SALARIOS_DEL:
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          loadingAction: true,
          success: false,
        },
      };
    case Types.SUCCESS_EMPLOYEES_SALARIOS_DEL:
      customToast.success("O salário do funcionário foi excluído com sucesso");
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          salarios:
            state.itemSelected.salarios?.filter(
              (item) => item.idHistSalario !== action.idHistSalario
            ) ?? [],
          loadingAction: false,
          success: true,
        },
      };
    case Types.FAILURE_EMPLOYEES_SALARIOS_DEL:
      customToast.error(action.message);
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          loadingAction: false,
          success: false,
        },
      };

    // LOAD OCCORRÊNCIAS
    case Types.REQUEST_EMPLOYEES_OCCURRENCES_LOAD:
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          ocorrencias: null,
          loadingItems: true,
        },
      };
    case Types.SUCCESS_EMPLOYEES_OCCURRENCES_LOAD:
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          ocorrencias: action.payload,
          loadingItems: false,
        },
      };
    case Types.FAILURE_EMPLOYEES_OCCURRENCES_LOAD:
      customToast.error(action.message);
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          ocorrencias: null,
          loadingItems: false,
        },
      };

    case Types.REQUEST_EMPLOYEES_OCCURRENCES_ADD:
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          loadingAction: true,
          success: false,
        },
      };
    case Types.SUCCESS_EMPLOYEES_OCCURRENCES_ADD:
      customToast.success(
        "A ocorrência do funcionário foi adicionado com sucesso"
      );
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          ocorrencias: [
            ...(state.itemSelected.ocorrencias ?? []),
            action.payload,
          ].sort((a, b) => moment(b.dataInicio).diff(moment(a.dataInicio))),
          loadingAction: false,
          success: true,
        },
      };
    case Types.FAILURE_EMPLOYEES_OCCURRENCES_ADD:
      customToast.error(action.message);
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          loadingAction: false,
          success: false,
        },
      };
    case Types.REQUEST_EMPLOYEES_OCCURRENCES_EDIT:
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          loadingAction: true,
          success: false,
        },
      };
    case Types.SUCCESS_EMPLOYEES_OCCURRENCES_EDIT:
      customToast.success(
        "A ocorrência do funcionário foi editada com sucesso"
      );
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          ocorrencias: state.itemSelected.ocorrencias!.map((item) => {
            if (item.idFuncOcorrencia !== action.payload.idFuncOcorrencia) {
              return item;
            } else {
              return action.payload;
            }
          }),
          loadingAction: false,
          success: true,
        },
      };
    case Types.FAILURE_EMPLOYEES_OCCURRENCES_EDIT:
      customToast.error(action.message);
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          loadingAction: false,
          success: false,
        },
      };

    case Types.REQUEST_EMPLOYEES_OCCURRENCES_DEL:
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          loadingAction: true,
          success: false,
        },
      };
    case Types.SUCCESS_EMPLOYEES_OCCURRENCES_DEL:
      customToast.success("O salário do funcionário foi excluído com sucesso");
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          ocorrencias:
            state.itemSelected.ocorrencias?.filter(
              (item) => item.idFuncOcorrencia !== action.idFuncOcorrencia
            ) ?? [],
          loadingAction: false,
          success: true,
        },
      };
    case Types.FAILURE_EMPLOYEES_OCCURRENCES_DEL:
      customToast.error(action.message);
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          loadingAction: false,
          success: false,
        },
      };

    // LOAD OCCORRÊNCIAS
    case Types.REQUEST_EMPLOYEES_FALTAS_LOAD:
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          faltas: null,
          loadingItems: true,
        },
      };
    case Types.SUCCESS_EMPLOYEES_FALTAS_LOAD:
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          faltas: action.payload,
          loadingItems: false,
        },
      };
    case Types.FAILURE_EMPLOYEES_FALTAS_LOAD:
      customToast.error(action.message);
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          faltas: null,
          loadingItems: false,
        },
      };

    // ADD FALTA
    case Types.REQUEST_EMPLOYEES_FALTA_ADD:
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          loadingAction: true,
          success: false,
        },
      };
    case Types.SUCCESS_EMPLOYEES_FALTA_ADD:
      customToast.success("A falta do funcionário foi adicionado com sucesso");
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          faltas: [
            ...(state.itemSelected.faltas ?? []),
            action.payload,
          ].sort((a, b) => moment(b.dataHist).diff(moment(a.dataHist))),
          loadingAction: false,
          success: true,
        },
      };
    case Types.FAILURE_EMPLOYEES_FALTA_ADD:
      customToast.error(action.message);
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          loadingAction: false,
          success: false,
        },
      };

    // EDIT FALTA
    case Types.REQUEST_EMPLOYEES_FALTA_EDIT:
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          loadingAction: true,
          success: false,
        },
      };
    case Types.SUCCESS_EMPLOYEES_FALTA_EDIT:
      customToast.success("A falta do funcionário foi editada com sucesso");
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          faltas: (state.itemSelected.faltas ?? []).map((item) => {
            if (item.idHistFalta === action.payload.idHistFalta) {
              return action.payload;
            }
            return item;
          }),
          loadingAction: false,
          success: true,
        },
      };
    case Types.FAILURE_EMPLOYEES_FALTA_EDIT:
      customToast.error(action.message);
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          loadingAction: false,
          success: false,
        },
      };

    //DEL FALTA
    case Types.REQUEST_EMPLOYEES_FALTA_DEL:
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          loadingAction: true,
          success: false,
        },
      };
    case Types.SUCCESS_EMPLOYEES_FALTA_DEL:
      customToast.success("A falta do funcionário foi excluída com sucesso");
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          faltas:
            state.itemSelected.faltas?.filter(
              (item) => item.idHistFalta !== action.idHistFalta
            ) ?? [],
          loadingAction: false,
          success: true,
        },
      };
    case Types.FAILURE_EMPLOYEES_FALTA_DEL:
      customToast.error(action.message);
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          loadingAction: false,
          success: false,
        },
      };
    case Types.RESET_EMPLOYEES:
      return INITIAL_STATE;

    default:
      return state;
  }
}
