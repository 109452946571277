// #region Imports
import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
 
import { bindActionCreators } from "redux";
import moment from "moment";

//FluentUI
import { Persona, PersonaSize } from "office-ui-fabric-react/lib/Persona";
import { Text } from "office-ui-fabric-react/lib/Text";
import { Stack } from "office-ui-fabric-react/lib/Stack";
import {
  DetailsListLayoutMode,
  SelectionMode,
  Selection,
  IColumn,
} from "office-ui-fabric-react/lib/DetailsList";
import { ShimmeredDetailsList } from "office-ui-fabric-react/lib/ShimmeredDetailsList";
import {
  CommandBarButton,
  ICommandBarStyles,
  FontIcon,
  Spinner,
  SpinnerSize,
} from "office-ui-fabric-react";
import { Label, ILabelStyles } from "office-ui-fabric-react/lib/Label";
import { Pivot, PivotItem } from "@fluentui/react";
import { IStyleSet } from "office-ui-fabric-react/lib/Styling";
import { Link } from "office-ui-fabric-react/lib/Link";
import { IDialogContentProps } from "office-ui-fabric-react/lib/Dialog";
import InputAdornment from "@material-ui/core/InputAdornment";
//MaterialUI
//Componentes
import CustomDialog from "~/components/CustomDialogFluentUI";
import PanelDisplay from "~/components/PanelDisplay";
import PanelWizard from "~/components/PanelWizard";
import {
  TitleField,
  GroupItems,
  GroupItemUmTerco,
  GroupItemDoisTerco,
} from "~/components/FormGroup";
import Status from "~/components/Status";
import HeaderPage from "~/components/layout/HeaderPage";
import NoItems from "~/components/layout/NoItems";
import { DeleteButton, PrimaryButton } from "~/components/Buttons";

import FormIdentification from "./Forms/FormIdentification";
import FormCadastral from "./Forms/FormCadastral";
import FormAcesso from "./Forms/FormAcesso";
import FormContentInfoBasic, { InfoBasicType } from "./Forms/FormBasicInfo";
import FormContentInfoAdmission, {
  InfoAdmissionType,
} from "./Forms/FormInfoAdmission";
import InfoAcess from "./InfoAccess";

import { getAbbreviatedName } from "~/utils/abbreviatedName";
import passwordGenerator from "~/utils/passwordGenerator";
import colors from "~/assets/js/colors";
import { InputText } from "~/components/Forms";
//Estilos
import { Wrapper, ListContainer, DefaultButton } from "./styles";

import { Creators as getEmployees } from "~/store/ducks/employees";
import { Creators as getEmployeeById } from "~/store/ducks/employees";
import { Creators as addEmployee } from "~/store/ducks/employees";
import { Creators as editEmployee } from "~/store/ducks/employees";
import { Creators as editDadosCadastraisEmployee } from "~/store/ducks/employees";
import { Creators as editEmployeePassword } from "~/store/ducks/employees";
import { Creators as editEmployeeAccess } from "~/store/ducks/employees";
import { Creators as delEmployee } from "~/store/ducks/employees";
import { Creators as resetEmployees } from "~/store/ducks/employees";
import { EmployeeType, DataTypes } from "~/store/ducks/employees/types";
import { Creators as setCurrentPage } from "~/store/ducks/home";
import { BreadcrumbItems, Page } from "~/store/ducks/home/types";
import { RootState } from "~/store/ducks";

import Areas from "./Grids/Areas";
import Unidades from "./Grids/Unidades";
import CC from "./Grids/CC";
import Cargos from "./Grids/Cargos";
import Salarios from "./Grids/Salarios";
import Ocorrencias from "./Grids/Ocorrencias";
import Faltas from "./Grids/Faltas";

//#endregion
//#region Styles
const btnStyle: Partial<ICommandBarStyles> = {
  root: {
    height: 44,
  },
};
//#endregion
//#region Interfaces

type Filter = {
  isOpen?: boolean;
  filterStatus: boolean | null;
};

enum TypeContentPanel {
  Identification = "ID",
  Cadastral = "CADASTRAL",
  Acesso = "ACESSO",
}

interface IEmployeesState {
  columns: IColumn[];
  items: EmployeeType[];
  isPanelDisplayOpen: boolean;
  isPanelOpen: boolean;
  isPanelEditOpen: boolean;
  panelContent: TypeContentPanel | null;
  isDialogAccessOpen: boolean;
  isDialogResetPasswordOpen: boolean;
  initialValuesItem: EmployeeType;
  initialValuesBasic: InfoBasicType;
  initialValuesAdmission: InfoAdmissionType;
  partialItemSelected: EmployeeType;
  isFiltering: boolean;
  selectionDetails: string;
  selectionCount: number;
  isDialogExcludeOpen: boolean;
  filter: Filter;
  search: string;
  activeStep: number;
  grantedAccess: boolean;
}

const initialEmployee: EmployeeType = {
  descAcesso: "",
  idFuncionario: null,
  nomeCompleto: "",
  dataNascimento: null,
  email: "",
  cpf: "",
  matricula: "",
  sexo: "",
  dataAdmissao: null,
  dataDemissao: null,
  status: "A",
  unidades: null,
  //unidade: null,
  cargo: null,
  area: null,
  cc: null,
  salario: null,
  flagAcessoRHR: true,
  flagAdministrador: false,
  flagBP: false,
  flagRH: false,
  imagem: "",
  password: "",
  idVinculo: null,
  vinculo: null,
};

const initialEmployeeBasicInfo: InfoBasicType = {
  nomeCompleto: "",
  dataNascimento: null,
  email: "",
  cpf: "",
  sexo: "",
};

const initialEmployeeInfoAdmission: InfoAdmissionType = {
  matricula: "",
  dataAdmissao: null,
  salario: null,
  unidade: null,
  cargo: null,
  area: null,
  cc: null,
};

interface IEmployeeProps {
  employees: DataTypes;
  getEmployees: (search?: string, filter?: boolean | null) => void;
  getEmployeeById: (id: number) => void;
  addEmployee: (employee: EmployeeType) => void;
  editEmployee: (employee: EmployeeType) => void;
  editDadosCadastraisEmployee: (
    idFuncionario: number,
    nomeCompleto: string,
    cpf: string,
    email: string,
    dataNascimento: Date,
    sexo: string
  ) => void;
  editEmployeePassword: (id: number, password?: string) => void;
  editEmployeeAccess: (
    id: number,
    flagAcessoRHR: boolean,
    descAcesso: string
  ) => void;
  delEmployee: (id: number) => void;
  resetEmployees: () => void;
  setCurrentPage: (page: Page) => void;
  history: RouteComponentProps["history"];
  location?: any;
}

//#endregion
//#region InitialValues
const itemsBreadCrumb: BreadcrumbItems[] = [
  {
    text: "Home",
    isCurrentItem: false,
    icon: "HomeSolid",
    onlyIcon: true,
    url: "/",
  },
  { text: "Funcionários", isCurrentItem: true },
];

const stepsTitles = [
  {
    title: "Configurar identificação",
    subText:
      "Para começar, preenche as informações sobre quem você está adicionando como funcionário.",
  },
  {
    title: "Configurar admissão",
    subText: "É importante preencher corretamente as informações da admissão",
  },
  {
    title: "Configurar acesso ao Portal RHR",
    subText:
      "Para acessar o portal o usuário deverá se identificar informando o e-mail e senha.",
  },
];

const page: Page = {
  key: "funcionarios",
  pages: itemsBreadCrumb,
};

const baseURL = process.env.REACT_APP_BASE_URL;
//#endregion

class Employees extends Component<IEmployeeProps, IEmployeesState> {
  private formRefIdentification: any;
  private formRefCadastral: any;
  private formRefInfoBasic: any;
  private formRefInfoAdmission: any;
  private formRefAcesso: any;

  private inputSearch: any;
  private _selection: Selection;
  private steps: string[];

  constructor(props: IEmployeeProps) {
    super(props);
    //#region Colunas
    const columns: IColumn[] = [
      {
        key: "column1",
        name: "",
        ariaLabel: "Status da empresa",
        fieldName: "status",
        minWidth: 15,
        maxWidth: 20,
        isResizable: true,
        isSortedDescending: false,
        onRender: (item: EmployeeType) => (
          <Status status={item.status === "A"} />
        ),
      },
      {
        key: "column2",
        name: "Matrícula",
        ariaLabel: "Matrícula",
        fieldName: "matricula",
        isRowHeader: true,
        minWidth: 75,
        maxWidth: 100,
        isSortedDescending: false,
        onColumnClick: this._onColumnClick,
      },
      {
        key: "column3",
        name: "Nome Completo",
        fieldName: "nomeCompleto",
        ariaLabel: "Nome Completo",
        minWidth: 210,
        maxWidth: 350,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "string",
        isPadded: true,
      },
      {
        key: "column4",
        name: "Unidade",
        fieldName: "unidade.nomeUnidade",
        minWidth: 70,
        maxWidth: 90,
        isRowHeader: true,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        data: "string",
        isPadded: true,
        onRender: (item: EmployeeType) =>
          item.unidades?.[0]?.unidade?.nomeUnidade,
      },
    ];
    //#endregion

    this.state = {
      columns: columns,
      items: [],
      isPanelDisplayOpen: false,
      isPanelOpen: false,
      isPanelEditOpen: false,
      isDialogAccessOpen: false,
      isDialogResetPasswordOpen: false,
      panelContent: null,
      selectionDetails: "",
      initialValuesItem: initialEmployee,
      initialValuesBasic: initialEmployeeBasicInfo,
      initialValuesAdmission: initialEmployeeInfoAdmission,
      partialItemSelected: initialEmployee,
      isFiltering: false,
      selectionCount: 0,
      isDialogExcludeOpen: false,
      filter: {
        isOpen: false,
        filterStatus: null,
      },
      search: "",
      activeStep: 0,
      grantedAccess: true,
    };

    this._selection = new Selection({
      onSelectionChanged: () => {
        this.setState({
          selectionDetails: this._getSelectionDetails(),
        });
      },
    });

    this.steps = [
      "Informações Básicas",
      "Informações de Admissão",
      "Configurações de Acesso",
    ];

    this.formRefIdentification = createRef();
    this.formRefCadastral = createRef();
    this.formRefInfoBasic = createRef();
    this.formRefInfoAdmission = createRef();
    this.formRefAcesso = createRef();

    this.inputSearch = createRef();
  }

  componentDidMount() {
    const { setCurrentPage, resetEmployees } = this.props;
    resetEmployees();
    if (this.props.location.state) {
      const employeeName = this.props.location.state.employeeName ?? "";
      this.setState({
        search: employeeName,
        isPanelOpen: this.props.location.state.isPanelOpen ?? false,
      });
    }

    setCurrentPage(page);
  }

  componentDidUpdate(prevProps: IEmployeeProps, prevState: IEmployeesState) {
    const {
      successEdit: prevSuccessEdit,
      success: prevSuccess,
      itemSelected: { error: prevErrorItem },
    } = prevProps.employees;
    const {
      successEdit: nextSuccessEdit,
      success: nextSuccess,
      itemSelected: { error: nextErrorItem },
    } = this.props.employees;
    if (prevSuccessEdit !== nextSuccessEdit) {
      if (nextSuccessEdit) {
        this.setState({ isPanelEditOpen: false });
      }
    }

    if (prevSuccess !== nextSuccess) {
      if (nextSuccess) {
        this.setState({ isPanelOpen: false });
      }
    }

    if (prevErrorItem !== nextErrorItem) {
      if (nextErrorItem) {
        this.setState({ isPanelDisplayOpen: false });
      }
    }
  }

  _onItemInvoked = (item: EmployeeType): void => {
    // const value = this._selection.getSelection()[0] as EmployeeType;
    // this.props.history.push(`/perfil/${value.idFuncionario}`)
    this.openEdit();
  };

  _getSelectionDetails(): any {
    const selectionCount = this._selection.getSelectedCount();
    this.setState({ selectionCount });
    this._selection.getSelection();
  }

  openEdit = () => {
    const value = this._selection.getSelection()[0] as EmployeeType;
    this.props.getEmployeeById(value.idFuncionario!);
    this.setState({ isPanelDisplayOpen: true, partialItemSelected: value });
  };

  cancelPanel = () => {
    this._selection.setAllSelected(false);
    this.setState({ isPanelOpen: false, initialValuesItem: initialEmployee });
  };

  handleSave = () => {
    const formRefIdentification = this.formRefIdentification.current;
    const formRefCadastral = this.formRefCadastral.current;
    const formRefAcesso = this.formRefAcesso.current;

    if (formRefIdentification) {
      formRefIdentification.handleSubmit();
    }

    if (formRefCadastral) {
      formRefCadastral.handleSubmit();
    }

    if (formRefAcesso) {
      formRefAcesso.handleSubmit();
    }
  };

  handleSaveAddEmployee = () => {
    this.props.addEmployee(this.state.initialValuesItem);
  };

  handleDelEmployee = () => {
    const id = (this._selection.getSelection()[0] as EmployeeType)
      .idFuncionario;
    this.props.delEmployee(id!);
    this.setState({ isDialogExcludeOpen: false });
    this._selection.setAllSelected(false);
  };

  filter = (key: any) => {
    const { search, filter } = this.state;
    if (search.trim().length > 0) {
      if (key === "Enter") {
        if (filter.filterStatus !== null) {
          this.props.getEmployees(search, filter.filterStatus);
        } else {
          this.props.getEmployees(search);
        }
        if (this.inputSearch.current) {
          this.inputSearch.current.focus();
        }
      }
    }
  };

  handleSearch = (e: any) => {
    const search: string = e?.target.value ? e!.target.value : "";
    this.setState({ search: search });
  };
  //#endregion

  handleBack = () => {
    this.setState((prevState) => ({
      activeStep: prevState.activeStep - 1,
    }));
  };

  handleNext = () => {
    const formRefInfoBasic = this.formRefInfoBasic.current;
    const formRefInfoAdmission = this.formRefInfoAdmission.current;

    switch (this.state.activeStep) {
      case 0:
        if (formRefInfoBasic) {
          formRefInfoBasic.handleSubmit();
        }
        break;
      case 1:
        if (formRefInfoAdmission) {
          formRefInfoAdmission.handleSubmit();
        }
        break;
      case 2:
        // console.log(this.state.initialValuesItem)
        break;
    }
  };

  handleReset = () => {
    this.setState({ activeStep: 0 });
  };

  renderForms = () => {
    const {
      initialValuesBasic,
      initialValuesAdmission,
      initialValuesItem,
      grantedAccess,
    } = this.state;

    switch (this.state.activeStep) {
      case 0:
        return (
          <FormContentInfoBasic
            formRef={this.formRefInfoBasic}
            initialValues={initialValuesBasic}
            success={(values: InfoBasicType) => {
              this.setState((prevState) => ({
                initialValuesBasic: values,
                activeStep: prevState.activeStep + 1,
                initialValuesItem: {
                  ...initialValuesItem,
                  ...values,
                },
              }));
            }}
          />
        );
      case 1:
        return (
          <FormContentInfoAdmission
            formRef={this.formRefInfoAdmission}
            initialValues={initialValuesAdmission}
            editValues={(values: Partial<InfoAdmissionType>) => {
              this.setState({
                initialValuesAdmission: {
                  ...this.state.initialValuesAdmission,
                  ...values,
                },
              });
            }}
            success={(values: InfoAdmissionType) => {
              this.setState((prevState) => ({
                initialValuesAdmission: values,
                activeStep: prevState.activeStep + 1,
                initialValuesItem: {
                  ...initialValuesItem,
                  ...values,
                  password: passwordGenerator(),
                },
              }));
            }}
          />
        );
      case 2:
        return (
          <InfoAcess
            email={initialValuesItem.email}
            password={initialValuesItem.password}
            grantedAccess={grantedAccess}
            handleGrantedAccess={() =>
              this.setState({ grantedAccess: !grantedAccess })
            }
          />
        );
    }
  };

  commandBarRender = () => {
    const { selectionCount } = this.state;
    if (selectionCount === 1) {
      return (
        <>
          <CommandBarButton
            styles={btnStyle}
            iconProps={{ iconName: "Edit" }}
            text="Editar"
            onClick={this.openEdit}
          />
          <CommandBarButton
            styles={btnStyle}
            iconProps={{ iconName: "Delete" }}
            text="Excluir"
            onClick={() => this.setState({ isDialogExcludeOpen: true })}
          />
        </>
      );
    } else if (selectionCount > 1) {
      return (
        <CommandBarButton
          styles={btnStyle}
          iconProps={{ iconName: "Delete" }}
          text="Excluir"
          onClick={() => this.setState({ isDialogExcludeOpen: true })}
        />
      );
    }
  };

  render() {
    const {
      isPanelDisplayOpen,
      isPanelOpen,
      isPanelEditOpen,
      isDialogExcludeOpen,
      isDialogResetPasswordOpen,
      isDialogAccessOpen,
      selectionCount,
      partialItemSelected,
      isFiltering,
      items,
      columns,
      panelContent,
      search,
      activeStep,
    } = this.state;
    const {
      employees,
      getEmployees,
      editEmployeePassword,
      editEmployeeAccess,
    } = this.props;
    const {
      itemSelected: { item: itemSelected },
    } = employees;

    const dialogContentProps: IDialogContentProps = {
      title: "Excluir?",
      closeButtonAriaLabel: "Close",
      subText:
        selectionCount <= 1
          ? "Tem certeza de que deseja excluir este item?"
          : "Tem certeza de que deseja excluir estes items?",
    };

    const dialogConfigContentProps: IDialogContentProps = {
      title: itemSelected.flagAcessoRHR
        ? "Bloquear entrada?"
        : "Conceder acesso?",
      closeButtonAriaLabel: "Close",
      subText: `Tem certeza que quer ${
        itemSelected.flagAcessoRHR ? "bloquear" : "conceder"
      } a entrada ao RHR ?`,
    };

    const dialogResetPasswordContentProps: IDialogContentProps = {
      title: "Redefinir senha",
      closeButtonAriaLabel: "Close",
      subText: `Tem certeza que quer redefinir a senha do acesso ao RHR ?`,
    };

    return (
      <>
        <PanelDisplay
          open={isPanelDisplayOpen}
          onClose={() => this.setState({ isPanelDisplayOpen: false })}
          isLoading={employees.itemSelected.loading}
          panelHeader={
            <Stack horizontal verticalAlign="center">
              <Persona
                size={PersonaSize.size100}
                initialsColor={colors.primary}
                imageInitials={getAbbreviatedName(
                  partialItemSelected.nomeCompleto
                )}
                imageUrl={`${baseURL}uploads/${employees.itemSelected.item?.imagem}`}
                styles={{ root: { width: 100, marginRight: 32 } }}
              />
              <Stack>
                <Text
                  variant="xxLarge"
                  styles={{
                    root: { fontWeight: "bold", textTransform: "uppercase" },
                  }}
                >
                  {partialItemSelected.nomeCompleto}
                </Text>
                <Text variant="medium">
                  {!employees.itemSelected.loading ? itemSelected!.email : ""}
                </Text>
                <Stack horizontal>
                  <CommandBarButton
                    styles={btnStyle}
                    iconProps={{ iconName: "Permissions" }}
                    text="Redefinir senha"
                    disabled={
                      employees.itemSelected.loading ||
                      employees.itemSelected.error
                    }
                    onClick={() => {
                      this.setState({ isDialogResetPasswordOpen: true });
                    }}
                  />
                  {/* {
                    itemSelected.flagAcessoRHR ?
                      <CommandBarButton
                        styles={btnStyle}
                        iconProps={{ iconName: "Blocked" }}
                        text="Bloquear entrada"
                        disabled={employees.itemSelected.loading || employees.itemSelected.error}
                        onClick={() => this.setState({ isDialogAccessOpen: true })}
                      />
                      :
                      <CommandBarButton
                        styles={btnStyle}
                        iconProps={{ iconName: "Signin" }}
                        text="Conceder entrada"
                        disabled={employees.itemSelected.loading || employees.itemSelected.error}
                        onClick={() => this.setState({ isDialogAccessOpen: true })}
                      />
                  } */}
                </Stack>
              </Stack>
            </Stack>
          }
        >
          <Pivot
            aria-label="Basic Pivot Example"
            styles={{ root: { marginBottom: 32 } }}
            overflowBehavior="menu"
          >
            <PivotItem headerText="Dados Principais">
              <GroupItems title="Identificação">
                <GroupItemUmTerco>
                  <TitleField text="Matrícula" />
                  <TitleField text={itemSelected!.matricula} isValue />
                  <Link
                    styles={{ root: { fontSize: 14 } }}
                    onClick={() =>
                      this.setState({
                        isPanelEditOpen: true,
                        panelContent: TypeContentPanel.Identification,
                      })
                    }
                  >
                    Gerenciar identificação
                  </Link>
                </GroupItemUmTerco>
                <GroupItemDoisTerco>
                  <TitleField text="Nome Completo" />
                  <TitleField text={itemSelected!.nomeCompleto} isValue />
                </GroupItemDoisTerco>
              </GroupItems>

              <GroupItems title="Situação Cadastral" hasMultiItems>
                <GroupItems>
                  <GroupItemUmTerco>
                    <TitleField text="Data de Admissão" />
                    <TitleField
                      text={moment(new Date(itemSelected!.dataAdmissao!))
                        .utc()
                        .format("DD/MM/YYYY")}
                      isValue
                    />
                    <TitleField text="Vínculo Empregatício" />
                    <TitleField
                      text={itemSelected!.vinculo?.descVinculo ?? ""}
                      isValue
                    />
                    <Link
                      styles={{ root: { fontSize: 14 } }}
                      onClick={() =>
                        this.setState({
                          isPanelEditOpen: true,
                          panelContent: TypeContentPanel.Cadastral,
                        })
                      }
                    >
                      Gerenciar situação cadastral
                    </Link>
                  </GroupItemUmTerco>
                  <GroupItemUmTerco>
                    <TitleField text="Status" />
                    <TitleField
                      text={
                        itemSelected!.status === "A" ? "Ativo" : "Desligado"
                      }
                      isValue
                    />
                  </GroupItemUmTerco>
                  {itemSelected!.status === "D" && (
                    <GroupItemUmTerco>
                      <TitleField text="Data de Desligamento" />
                      <TitleField
                        text={moment(new Date(itemSelected!.dataDemissao!))
                          .utc()
                          .format("DD/MM/YYYY")}
                        isValue
                      />
                    </GroupItemUmTerco>
                  )}
                </GroupItems>
                {/* <GroupItems>
                  <GroupItemUmTerco>
                    <TitleField text="Unidade" />
                    <TitleField text={itemSelected!.unidade?.nomeUnidade} isValue />
                    <Link styles={{ root: { fontSize: 14 } }} onClick={() => this.setState({ isPanelEditOpen: true, panelContent: TypeContentPanel.cadastral })}>
                      Gerenciar situação cadastral
                    </Link>
                  </GroupItemUmTerco>
                </GroupItems> */}
              </GroupItems>
              <GroupItems title="Portal Performance" hasMultiItems>
                <GroupItems>
                  <GroupItemUmTerco>
                    <TitleField text="Acesso liberado" />
                    <TitleField
                      text={itemSelected.flagAcessoRHR ? "Sim" : "Não"}
                      isValue
                    />
                    <Link
                      styles={{ root: { fontSize: 14 } }}
                      onClick={() =>
                        this.setState({
                          isPanelEditOpen: true,
                          panelContent: TypeContentPanel.Acesso,
                        })
                      }
                    >
                      Gerenciar acesso
                    </Link>
                  </GroupItemUmTerco>
                  <GroupItemUmTerco>
                    <TitleField text="Tipo de Acesso" />
                    <TitleField text={itemSelected.descAcesso} isValue />
                  </GroupItemUmTerco>
                </GroupItems>
              </GroupItems>
            </PivotItem>
            <PivotItem headerText="Unidades">
              <Unidades idFuncionario={itemSelected.idFuncionario!} />
            </PivotItem>
            <PivotItem headerText="Áreas">
              <Areas idFuncionario={itemSelected.idFuncionario!} />
            </PivotItem>
            <PivotItem headerText="Centros de custo">
              <CC idFuncionario={itemSelected.idFuncionario!} />
            </PivotItem>
            <PivotItem headerText="Cargos">
              <Cargos idFuncionario={itemSelected.idFuncionario!} />
            </PivotItem>
            <PivotItem headerText="Salários">
              <Salarios idFuncionario={itemSelected.idFuncionario!} />
            </PivotItem>
            <PivotItem headerText="Ocorrências">
              <Ocorrencias idFuncionario={itemSelected.idFuncionario!} />
            </PivotItem>
            <PivotItem headerText="Faltas">
              <Faltas idFuncionario={itemSelected.idFuncionario!} />
            </PivotItem>
          </Pivot>
        </PanelDisplay>

        {/* --------------------------- EDIT PANEL --------------------------------------- */}

        <PanelDisplay
          open={isPanelEditOpen}
          onClose={() => this.setState({ isPanelEditOpen: false })}
          isLoading={false}
          footer={
            <>
              <DefaultButton
                styles={{ root: { marginRight: 10 } }}
                onClick={() => this.setState({ isPanelEditOpen: false })}
              >
                Cancelar
              </DefaultButton>
              <PrimaryButton
                onClick={this.handleSave}
                text="Salvar"
                isLoading={employees.loadingAction}
              />
            </>
          }
        >
          {panelContent === TypeContentPanel.Identification && (
            <FormIdentification
              initialValues={itemSelected!}
              formRef={this.formRefIdentification}
              success={(nomeCompleto, cpf, email, dataNascimento, sexo) =>
                this.props.editDadosCadastraisEmployee(
                  itemSelected.idFuncionario!,
                  nomeCompleto,
                  cpf,
                  email,
                  dataNascimento,
                  sexo
                )
              }
            />
          )}
          {panelContent === TypeContentPanel.Cadastral && (
            <FormCadastral
              initialValues={itemSelected!}
              formRef={this.formRefCadastral}
              success={(values) => this.props.editEmployee(values)}
            />
          )}
          {panelContent === TypeContentPanel.Acesso && (
            <FormAcesso
              initialValues={itemSelected!}
              formRef={this.formRefAcesso}
              success={(flagAcessoRHR, descAcesso) =>
                editEmployeeAccess(
                  itemSelected.idFuncionario!,
                  flagAcessoRHR,
                  descAcesso
                )
              }
            />
          )}
        </PanelDisplay>

        {/* -----------------------------------  PANEL CADASTRADA FUNCIONÁRIO ------------------------------------- */}

        <PanelWizard
          open={isPanelOpen}
          onClose={() => this.setState({ isPanelOpen: false })}
          title="Adicionar Funcionário"
          isLoading={false}
          steps={this.steps}
          titleSteps={stepsTitles}
          activeStep={activeStep}
          handleBack={this.handleBack}
          handleNext={this.handleNext}
          footer={
            <>
              {this.state.activeStep === this.steps.length - 1 && (
                <PrimaryButton
                  onClick={this.handleSaveAddEmployee}
                  styles={{ root: { position: "relative", left: 196 } }}
                  text="Salvar"
                  isLoading={employees.loadingAction}
                />
              )}
            </>
          }
        >
          {this.renderForms()}
        </PanelWizard>

        <CustomDialog
          hidden={!isDialogExcludeOpen}
          onDismiss={() => this.setState({ isDialogExcludeOpen: false })}
          dialogContentProps={dialogContentProps}
        >
          <DefaultButton
            onClick={() => this.setState({ isDialogExcludeOpen: false })}
            text="Cancelar"
          />
          <DeleteButton onClick={this.handleDelEmployee} text="Excluir" />
        </CustomDialog>

        {/* ----------------------------------------- LISTA FUNCIONARIOS ----------------------------------------------- */}

        <Wrapper>
          <HeaderPage
            title="Funcionários"
            leftItems={
              <>
                <CommandBarButton
                  styles={btnStyle}
                  iconProps={{ iconName: "Add" }}
                  disabled={employees.loadingData}
                  text="Adicionar Funcionário"
                  onClick={() =>
                    this.setState({
                      isPanelOpen: true,
                      activeStep: 0,
                      initialValuesBasic: initialEmployeeBasicInfo,
                      initialValuesAdmission: initialEmployeeInfoAdmission,
                    })
                  }
                />
                {this.commandBarRender()}
              </>
            }
          />
          <Stack horizontal styles={{ root: { height: 44, marginTop: 5 } }}>
            <InputText
              autoFocus
              variant="outlined"
              label="Pesquisar"
              value={search}
              onChange={(e) => this.handleSearch(e)}
              onKeyUp={(e) => this.filter(e.key)}
              innerRef={this.inputSearch}
              size="small"
              style={{ maxWidth: "100%", paddingRight: 15, height: 40 }}
              height_container={40}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <FontIcon iconName="Search" />
                  </InputAdornment>
                ),
              }}
            />
            <CommandBarButton
              styles={{
                root: {
                  marginLeft: "15px !important",
                  padding: 10,
                  height: 40,
                },
              }}
              iconProps={{ iconName: "Refresh" }}
              text="Atualizar"
              disabled={search.trim().length === 0}
              onClick={() => getEmployees(search)}
            />
          </Stack>

          {!employees.loadingData && employees.data.length === 0 ? (
            employees.success && (
              <NoItems
                error={employees.error}
                text="Não foi encontrado nenhum funcionário"
                icon="IDBadge"
              />
            )
          ) : (
            <ListContainer>
              <ShimmeredDetailsList
                items={isFiltering ? items : employees.data}
                enableShimmer={employees.loadingData}
                columns={columns}
                selectionMode={SelectionMode.single}
                selection={this._selection}
                getKey={this._getKey}
                selectionPreservedOnEmptyClick={true}
                setKey="single"
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                onItemInvoked={this._onItemInvoked}
              />
            </ListContainer>
          )}
        </Wrapper>

        {/* <CustomDialog
          hidden={!isDialogAccessOpen}
          onDismiss={() => this.setState({ isDialogAccessOpen: false })}
          dialogContentProps={dialogConfigContentProps}
        >
          <DefaultButton
            onClick={() => this.setState({ isDialogAccessOpen: false })}
            text="Cancelar"
          />
          <PrimaryButton
            onClick={() => {
              editEmployeeAccess(itemSelected.idFuncionario!, itemSelected.flagAcessoRHR ? false : true);
              this.setState({ isDialogAccessOpen: false });
            }}
            text={itemSelected.flagAcessoRHR ? "Bloquear" : "Conceder"}
          />
        </CustomDialog> */}

        <CustomDialog
          hidden={!isDialogResetPasswordOpen}
          onDismiss={() => this.setState({ isDialogResetPasswordOpen: false })}
          dialogContentProps={dialogResetPasswordContentProps}
        >
          <>
            <DefaultButton
              onClick={() =>
                this.setState({ isDialogResetPasswordOpen: false })
              }
              text="Cancelar"
            />
            <PrimaryButton
              styles={{ root: { marginLeft: 8 } }}
              onClick={() => {
                this.setState({ isDialogResetPasswordOpen: false }, () =>
                  editEmployeePassword(itemSelected.idFuncionario!)
                );
              }}
              text="Refinir"
            />
          </>
        </CustomDialog>
      </>
    );
  }
  //#region  Table Functions
  private _onColumnClick = (
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn
  ): void => {
    const { columns, isFiltering, items: itemsState } = this.state;
    const { employees } = this.props;

    const newColumns: IColumn[] = columns.slice();
    let items: EmployeeType[] = [];
    if (isFiltering) {
      items = itemsState;
    } else {
      items = employees.data;
    }
    const currColumn: IColumn = newColumns.filter(
      (currCol) => column.key === currCol.key
    )[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    const newItems = this._sort(
      items,
      currColumn.fieldName!,
      currColumn.isSortedDescending
    );
    this.setState({
      columns: newColumns,
      items: newItems,
    });
  };

  private _getKey(item: any, index?: number): any {
    if (item !== undefined) return item.key;
  }

  private _sort<T>(
    items: T[],
    columnKey: string,
    isSortedDescending?: boolean
  ): T[] {
    const result = (prop: string, obj: T) =>
      prop.split(".").reduce((a: any, b: any) => a[b] ?? "", obj);
    return items.sort((a: T, b: T) =>
      (
        isSortedDescending
          ? result(columnKey, a) < result(columnKey, b)
          : result(columnKey, a) > result(columnKey, b)
      )
        ? 1
        : -1
    );
  }

  //#endregion
}

const mapStateToProps = (state: RootState) => ({
  employees: state.employeesReducer,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      ...getEmployees,
      ...getEmployeeById,
      ...addEmployee,
      ...editEmployee,
      ...editDadosCadastraisEmployee,
      ...editEmployeePassword,
      ...editEmployeeAccess,
      ...delEmployee,
      ...resetEmployees,
      ...setCurrentPage,
    },
    dispatch
  );
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Employees)
);
