import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";

import CustomDialog from "~/components/CustomDialog";
import { withStyles } from "@material-ui/core/styles";
import {
  TitleField,
  GroupItems,
  GroupItemUmTerco,
  GroupItemDoisTerco,
} from "~/components/FormGroup";
import MenuItem from "@material-ui/core/MenuItem";
import Dropdown from "~/components/layout/Dropdown";
import { InputText } from "~/components/Forms";
import {
  TabPerfomanceType,
  TaskAvaliacaoColetivaSelected,
} from "~/store/ducks/tasks/types";
import {
  Icon,
  Spinner,
  SpinnerSize,
  Stack,
  Text,
} from "office-ui-fabric-react";

import TextField from "@material-ui/core/TextField";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "~/store/ducks";
import { Creators } from "~/store/ducks/tasks";

const validationSchema = yup.object().shape({
  idTabPerformance: yup.number().nullable().notRequired(),
  potencial: yup.string().notRequired(),
  sucessao: yup.string().nullable().notRequired(),
  comentariosGerais: yup.string().notRequired(),
});

interface IDialogAvaliacaoFinalProps {
  isOpen: boolean;
  idTarefaAvaliacao: number;
  idTarefa: number | null;
  loadingData: boolean;
  performances: TabPerfomanceType[];
  readonly: boolean;
  modoHidden?: boolean;
  onClose: () => void;
  onSuccess: (values: any) => void;
}

const initial: Partial<TaskAvaliacaoColetivaSelected> = {
  idTabPerformance: null,
  potencial: "",
  sucessao: null,
  comentariosGerais: "",
};

const DialogAvaliacaoFinal: React.FC<IDialogAvaliacaoFinalProps> = (props) => {
  const {
    isOpen,
    onClose,
    onSuccess,
    loadingData,
    idTarefa,
    idTarefaAvaliacao,
    readonly,
    performances,
  } = props;
  const dispatch = useDispatch();
  const avaliacao = useSelector<
    RootState,
    TaskAvaliacaoColetivaSelected | null
  >((state) => state.tasksReducer.selectedColetiva);

  useEffect(() => {
    if (idTarefa && idTarefaAvaliacao) {
      dispatch(Creators.getInfoAvaliacaoColetiva(idTarefa, idTarefaAvaliacao));
    }
  }, [idTarefa, idTarefaAvaliacao]);

  useEffect(() => {
    resetForm({
      values: {
        idTabPerformance: avaliacao?.idTabPerformance ?? null,
        potencial: avaliacao?.potencial ?? "",
        sucessao: avaliacao?.sucessao ?? null,
        comentariosGerais: avaliacao?.comentariosGerais ?? "",
      },
    });
  }, [isOpen, avaliacao]);

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    resetForm,
    setFieldValue,
    setFieldError,
  } = useFormik({
    initialValues: initial,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: false,
    validate(values) {
      const errors: any = {};
      if (
        avaliacao?.funcionario.status === "D" &&
        values.idTabPerformance === null
      ) {
        errors.idTabPerformance = "Campo obrigatório";
      }
      if (avaliacao?.funcionario.status !== "D" && !values.potencial) {
        errors.potencial = "Campo obrigatório";
      }
      if (
        (values.potencial === "Alto potencial para crescer" ||
          values.potencial === "Potencial para crescer") &&
        values.sucessao === null
      ) {
        errors.sucessao = "Campo obrigatório";
      }

      return errors;
    },
    onSubmit(values) {
      if (values.sucessao === "blank") values.sucessao = null;
      onSuccess(values);
    },
  });

  return (
    <CustomDialog
      open={isOpen}
      title="Avaliação Final"
      btnConfirmText="Salvar"
      onClickCancel={onClose}
      disabledBtnConfirm={loadingData || readonly}
      onClickConfirm={() => handleSubmit()}
      height={avaliacao?.funcionario.status === "D" ? 440 : 580}
      width={640}
      zIndex={1300}
    >
      {loadingData ? (
        <Spinner
          size={SpinnerSize.medium}
          label="Carregando avaliado"
          styles={{ root: { height: "100%" } }}
        />
      ) : (
        <form onSubmit={handleSubmit}>
          <GroupItemDoisTerco>
            <GroupItems title="Funcionário" titleRegular>
              <TitleField text={avaliacao?.funcionario?.nomeCompleto} bold />
            </GroupItems>
          </GroupItemDoisTerco>
          <GroupItems
            noMargins
            stylesWrapperItems={{
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <GroupItemUmTerco>
              <TitleField text="PERFORMANCE" />
            </GroupItemUmTerco>
            <GroupItemDoisTerco width={360}>
              {avaliacao?.funcionario.status === "D" ? (
                <Dropdown
                  errors={errors.idTabPerformance}
                  label="Performance calibrada"
                  name="idTabPerformance"
                  values={
                    values.idTabPerformance
                      ? String(values.idTabPerformance)
                      : ""
                  }
                  handleChange={(e: any) => {
                    handleChange(e);
                    setFieldError("perfomance", "");
                  }}
                  errorText={errors.idTabPerformance}
                  disabled={readonly}
                  autofocus
                >
                  {performances
                    .filter((perf) => !perf.flagBloquearAvaliacao)
                    .map((item, i) => (
                      <MenuItem key={i} value={`${item.idTabPerformance}`}>
                        {item.descNivel}
                      </MenuItem>
                    ))}
                </Dropdown>
              ) : (
                <Stack
                  horizontal
                  horizontalAlign="center"
                  verticalAlign="center"
                  styles={{ root: { height: 70 } }}
                >
                  {avaliacao?.idTabPerformance && (
                    <>
                      <Icon
                        iconName="CircleFill"
                        styles={{
                          root: {
                            color: performances.find(
                              (perf) =>
                                perf.idTabPerformance ===
                                avaliacao?.idTabPerformance
                            )?.cor,
                            marginRight: 8,
                          },
                        }}
                      />
                      <Text>
                        {
                          performances.find(
                            (perf) =>
                              perf.idTabPerformance ===
                              avaliacao?.idTabPerformance
                          )?.descNivel
                        }
                      </Text>
                    </>
                  )}
                </Stack>
              )}
            </GroupItemDoisTerco>
          </GroupItems>

          {avaliacao?.funcionario.status !== "D" && (
            <>
              <GroupItems
                noMargins
                stylesWrapperItems={{
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <GroupItemUmTerco>
                  <TitleField text="POTENCIAL" />
                </GroupItemUmTerco>
                <GroupItemDoisTerco width={360}>
                  <Dropdown
                    errors={errors.potencial}
                    label="Potencial calibrado"
                    name="potencial"
                    values={values.potencial ?? ""}
                    handleChange={(e: any) => {
                      handleChange(e);
                      setFieldError("potencial", "");
                      if (
                        e.target.value === "Potencial lateral" ||
                        e.target.value === "Cedo demais para avaliar"
                      ) {
                        setFieldValue("sucessao", "blank");
                      } else {
                        setFieldValue("sucessao", null);
                      }
                    }}
                    errorText={errors.potencial}
                    disabled={readonly}
                  >
                    <MenuItem value={"Alto potencial para crescer"}>
                      Alto Potencial para Crescer
                    </MenuItem>
                    <MenuItem value={"Potencial para crescer"}>
                      Potencial para Crescer
                    </MenuItem>
                    <MenuItem value={"Potencial lateral"}>
                      Potencial Lateral
                    </MenuItem>
                    <MenuItem value={"Cedo demais para avaliar"}>
                      Cedo Demais para Avaliar
                    </MenuItem>
                  </Dropdown>
                </GroupItemDoisTerco>
              </GroupItems>

              <GroupItems
                noMargins
                stylesWrapperItems={{
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <GroupItemUmTerco>
                  <TitleField text="SUCESSÃO" />
                </GroupItemUmTerco>
                <GroupItemDoisTerco width={360}>
                  <Dropdown
                    errors={errors.sucessao}
                    label="Sucessão Calibrada"
                    name="sucessao"
                    values={values.sucessao ?? ""}
                    handleChange={(e: any) => {
                      handleChange(e);
                      setFieldError("sucessao", "");
                    }}
                    errorText={errors.sucessao}
                    disabled={
                      readonly ||
                      values.potencial === "Cedo demais para avaliar" ||
                      values.potencial === "Potencial lateral"
                    }
                  >
                    <MenuItem value={"blank"} disabled></MenuItem>
                    <MenuItem value={"Imediato"}>Imediato</MenuItem>
                    <MenuItem value={"Curto Prazo - 1 a 2 anos"}>
                      Curto Prazo - 1 a 2 anos
                    </MenuItem>
                    <MenuItem value={"Médio Prazo - 3 a 5 anos"}>
                      Médio Prazo - 3 a 5 anos
                    </MenuItem>
                    <MenuItem value={"Longo Prazo - acima 5 anos"}>
                      Longo Prazo - acima 5 anos
                    </MenuItem>
                  </Dropdown>
                </GroupItemDoisTerco>
              </GroupItems>
            </>
          )}

          <InputText
            name="comentariosGerais"
            label="Comentários para calibração"
            value={values.comentariosGerais ?? ""}
            onChange={handleChange}
            multiline
            rows={5}
            rowsMax={5}
            error={errors.comentariosGerais ? true : false}
            helperText={errors.comentariosGerais}
            disabled={readonly}
          />
        </form>
      )}
    </CustomDialog>
  );
};

export default DialogAvaliacaoFinal;
