/**
 * Types
 */
export enum Types {
  //GET
  REQUEST_FILES_LOAD = "files/REQUEST_FILES_LOAD",
  SUCCESS_FILES_LOAD = "files/SUCCESS_FILES_LOAD",
  FAILURE_FILES_LOAD = "files/FAILURE_FILES_LOAD",
}

interface IFilesState {
  readonly loading: boolean;
  readonly error: boolean;
  readonly success: boolean;
}

export type DataTypes = IFilesState;

/**
 * Actions
 */

//GET
export interface IRequestFilesLoadAction {
  type: typeof Types.REQUEST_FILES_LOAD;
  key: string;
}

export interface ISuccessFilesLoadAction {
  type: typeof Types.SUCCESS_FILES_LOAD;
}

interface IFailureFilesLoadAction {
  type: typeof Types.FAILURE_FILES_LOAD;
  message: string;
}

type FilesAction =
  | IRequestFilesLoadAction
  | ISuccessFilesLoadAction
  | IFailureFilesLoadAction;

export type FilesActionTypes = FilesAction;
