import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from "yup";
import { Formik } from "formik";
import moment from 'moment';
import { InputText, InputDate } from '~/components/Forms';

import { CycleType } from "~/store/ducks/cycles/types";
import { FormHeaderStyle } from '~/components/FormGroup';

interface IFormRegrasProps {
    formRef: any;
    initialValues: Partial<CycleType>;
    success: (values: Partial<CycleType>) => any;
};

const validationSchemaInfo = yup.object().shape({
    prazoPlanejamento: yup.date().typeError('Data inválida').nullable().required("Campo obrigatório"),
    limiteAdm: yup.date().typeError('Data inválida').nullable().required("Campo obrigatório"),
    mesesTrabalhados: yup.number().nullable().required("Campo obrigatório"),
});

const FormContentRegras: React.FC<IFormRegrasProps> = (props) => {
    return (
        <>
            <FormHeaderStyle text="Regras de Cortes" />
            <Formik
                enableReinitialize={true}
                innerRef={props.formRef}
                initialValues={props.initialValues!}
                validationSchema={validationSchemaInfo}
                validateOnChange={false}
                validateOnBlur={true}
                onSubmit={(values: Partial<CycleType>) => {
                    // values.cargo = {
                    //     idCargo: valuePosition.idCargo,
                    //     titulo: valuePosition.titulo
                    // };
                    // values.local = {
                    //     idArea: valueChart.idArea,
                    //     nomeLocal: valueChart.nomeLocal
                    // }

                    props.success(values);
                }}
            >
                {({ handleSubmit, handleChange, values, errors, setFieldError, setFieldValue }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <div className="ms-Grid" dir="ltr">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12" style={{ paddingLeft: 0, paddingRight: 8 }}>
                                        <InputDate
                                            style={{ marginTop: 15 }}
                                            id="prazoPlanejamento"
                                            error={errors.prazoPlanejamento ? true : false}
                                            name="prazoPlanejamento"
                                            label="Prazo de planejamento"
                                            value={values?.prazoPlanejamento ? moment(values.prazoPlanejamento) : null}
                                            onChange={value => setFieldValue('prazoPlanejamento', value)}
                                            className="mt-2"
                                            helperText={errors.prazoPlanejamento}
                                            autoFocus
                                        />
                                    </div>
                                    <div className="ms-Grid-col ms-sm12" style={{ paddingLeft: 0, paddingRight: 8 }}>
                                        <InputDate
                                            style={{ marginTop: 15 }}
                                            id="limiteAdm"
                                            error={errors.limiteAdm ? true : false}
                                            name="limiteAdm"
                                            label="Prazo de admitidos"
                                            value={values?.limiteAdm ? moment(values.limiteAdm).utc() : null}
                                            onChange={value => setFieldValue('limiteAdm', value)}
                                            className="mt-2"
                                            helperText={errors.limiteAdm}
                                        />
                                    </div>
                                    <div className="ms-Grid-col ms-sm12" style={{ paddingLeft: 0, paddingRight: 8 }}>
                                        <InputText
                                            value={values?.mesesTrabalhados ?? ''}
                                            onChange={(e) => { handleChange(e); setFieldError("mesesTrabalhados", "") }}
                                            id="mesesTrabalhados"
                                            error={errors.mesesTrabalhados ? true : false}
                                            name="mesesTrabalhados"
                                            type="number"
                                            label="Meses trabalhados"
                                            helperText={errors.mesesTrabalhados}
                                            className="mt-2"
                                            inputProps={{
                                                maxLength: 10
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    )
                }}
            </Formik>
        </>
    );
};

export default FormContentRegras;