import React, { useRef, useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  Container,
  Gap,
  Table,
  TdPillar,
  TdGoal,
  TdMeta,
  TdPeso,
  Tr,
  ColumnTitle,
  TdTotal,
  TdItem,
  TdPesoTotal,
  TBody,
  TrHeader,
  TrTotal,
  TdMetaHeader,
  TdPesoHeader,
  TdSemMeta,
  BtnIcon,
  WrapperTblHeader,
  TblHeader,
  Thead,
  GapHeader,
} from "./styles";

import ShimmerTatic from "~/components/Shimmer/shimmerTatic";

import { FontIcon } from "office-ui-fabric-react";
import {
  TooltipHost,
  ITooltipHostStyles,
} from "office-ui-fabric-react/lib/Tooltip";

import { RootState } from "~/store/ducks";
import {
  DataTypes,
  MetaType,
  PesoType,
  ObjectiveCycleType,
  MetaMonthType,
} from "~/store/ducks/cycle/painel/types";
import { Creators } from "~/store/ducks/cycle/painel";
import { Creators as CreatorsObjectives } from "~/store/ducks/objectives";
import { ItemSelectedType } from "~/store/ducks/objectives/types";
import { Creators as CreatorsAreas } from "~/store/ducks/admin/chart";
import { Creators as CreatorsDismemberment } from "~/store/ducks/cycle/dismemberment";
import { ItemSelectedType as AreaSelectedType } from "~/store/ducks/admin/chart/types";

import FormMetas from "../FormMetas";
import FormPesos from "../FormPesos";
import Colors, { calcConstrast } from "~/assets/js/colors";
import NoItems from "~/components/layout/NoItems";

interface IProps {
  idCycle: number;
  changePivot: (id: string) => void;
}

const ToolTipStyles: Partial<ITooltipHostStyles> = {
  root: {
    width: "-webkit-fill-available",
    height: "-webkit-fill-available",
    display: "block",
    position: "absolute",
    top: 0,
    bottom: 0,
  },
};

const valueObjective: Partial<ObjectiveCycleType> = {
  idObjetivo: 0,
  objetivo: {
    descObjetivo: "",
    unidade: "",
    casasDecimais: null
  },
};

const valuesPeso: PesoType = {
  idArea: null,
  peso: "",
  definirResponsabilidade: false,
  idCicloPeso: null,
  area: {
    nomeLocal: "",
  },
  flagInativo: false,
  idObjetivo: null,
  objetivo: {
    descObjetivo: "",
    mecanicaCalculo: "",
  },
};

const initialMes: MetaMonthType = {
  minimo: null,
  target: null,
  maximo: null,
  resultado: null,
  avaliacao: null,
  idCicloMetaMes: null,
  idCicloMeta: null,
  referencia: "",
  mes: 0,
};

const meses = Array<MetaMonthType>(12)
  .fill(initialMes)
  .map((mes, i) => ({
    ...mes,
    mes: i + 1,
  }));

const initialMeta: MetaType = {
  minimo: "",
  target: "",
  maximo: "",
  minimoRef: "",
  targetRef: "",
  maximoRef: "",
  metaBonus: null,
  forcarMetaBonus: false,
  habilitarReferencia: false,
  objetivo: {
    descObjetivo: "",
    mecanicaCalculo: "",
    unidade: "",
  },
  idCicloMeta: null,
  ating: null,
  avaliacao: null,
  flagInativo: null,
  resultado: null,
  meses: meses,
};

const Strategic: React.FC<IProps> = (props) => {
  const ref: any = useRef(null);

  const [isDialogMetaOpen, setIsDialogMetaOpen] = useState(false);
  const [isDialogPesoOpen, setIsDialogPesoOpen] = useState(false);

  const [idObjetivoSelected, setIdObjetivoSelected] = useState<
    Partial<ObjectiveCycleType>
  >(valueObjective);
  const [pillarSelected, setPillarSelected] = useState<number>(0);
  const [pesoSelected, setPesoSelected] = useState<PesoType>(valuesPeso);

  const { idCycle } = props;
  const cycleStrategic = useSelector<RootState, DataTypes>(
    (state) => state.cyclePainelReducer
  );
  const objectiveSelected = useSelector<RootState, ItemSelectedType>(
    (state) => state.objectivesReducer.itemSelected
  );
  const areaSelected = useSelector<RootState, AreaSelectedType>(
    (state) => state.chartReducer.itemSelected
  );

  const [initialValuesMeta, setInitialValuesMeta] = useState<MetaType>(
    initialMeta
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(Creators.getPainel(props.idCycle, "T"));
  }, [dispatch]);

  useEffect(() => {
    if (cycleStrategic.data.metaSelected) {
      const { unidade } = cycleStrategic.data.metaSelected.objetivo;
      const { minimo, target, maximo } = cycleStrategic.data.metaSelected;
      let max = "";
      if (unidade === "M" || unidade === "L") {
        max = formattedMetaForm(maximo, unidade);
      } else {
        max = formattedMetaForm(maximo, unidade);
      }
      setInitialValuesMeta({
        ...cycleStrategic.data.metaSelected,
        minimo: formattedMetaForm(minimo, unidade),
        target: formattedMetaForm(target, unidade),
        maximo: max,
      });
    }

    if (cycleStrategic.data.pesoSelected) {
      setPesoSelected(cycleStrategic.data.pesoSelected!);
    }
  }, [cycleStrategic.data]);

  useEffect(() => {
    if (cycleStrategic.successAction) {
      setIsDialogMetaOpen(false);
      setIsDialogPesoOpen(false);
    }
  }, [cycleStrategic.successAction]);

  useEffect(() => {
    if (objectiveSelected.success) {
      const { item } = objectiveSelected;
      const { descObjetivo, mecanicaCalculo, unidade } = item;
      setInitialValuesMeta({
        ...initialMeta,
        objetivo: {
          descObjetivo: descObjetivo,
          mecanicaCalculo: mecanicaCalculo,
          unidade: unidade,
        },
      });
    }
  }, [objectiveSelected]);

  useEffect(() => {
    if (areaSelected.success) {
      const { item } = areaSelected;
      setPesoSelected({
        ...valuesPeso,
        idArea: item.idArea,
        area: {
          nomeLocal: item.nomeLocal,
        },
      });
    }
  }, [areaSelected]);

  const callDesmemberment = (idArea: number, nomeLocal: string) => {
    dispatch(
      CreatorsDismemberment.getDismemberment(idCycle!, idArea, nomeLocal)
    );
    props.changePivot("4");
  };

  const renderHeaderMemo = useMemo(() => {
    const renderHeader = () => {
      const areas = cycleStrategic.data.painel.header.map((peso, i, array) => {
        return (
          <TdPesoHeader
            key={i}
            onClick={() => callDesmemberment(peso.idArea, peso.nomeLocal)}
          >
            {peso.apelido}
            <TooltipHost content={peso.nomeLocal} styles={ToolTipStyles} />
          </TdPesoHeader>
        );
      });
      return (
        <>
          <tr>
            <ColumnTitle color="#CFD3DA" colSpan={3}>
              METAS
            </ColumnTitle>
            <GapHeader />
            <ColumnTitle
              color="#757575"
              textColor="#FFFFFF"
              colSpan={areas.length}
            >
              PESOS
            </ColumnTitle>
          </tr>
          <TrHeader>
            <TdMetaHeader>Min</TdMetaHeader>
            <TdMetaHeader bold colorText="#666666">
              Target
            </TdMetaHeader>
            <TdMetaHeader className="max">Max</TdMetaHeader>
            <GapHeader />
            {areas}
          </TrHeader>
        </>
      );
    };

    return renderHeader();
  }, [cycleStrategic.data.painel.header]);

  const renderTableMemo = useMemo(() => {
    const handleMeta = (
      idCicloMeta: number,
      idObjetivo: number,
      idCicloPilar: number
    ) => {
      setIdObjetivoSelected({ idObjetivo });
      setPillarSelected(idCicloPilar);

      if (idCicloMeta !== 0) {
        dispatch(Creators.getMetaById(idCycle, idCicloMeta));
      } else {
        setInitialValuesMeta(initialMeta);
        dispatch(CreatorsObjectives.getObjectiveById(idObjetivo));
      }
      setIsDialogMetaOpen(true);
    };

    const handlePeso = (
      idCicloPeso: number,
      idCicloPilar: number,
      idObjetivo: number,
      idArea: number,
      objDescricao: string
    ) => {
      setIdObjetivoSelected({
        idObjetivo,
        objetivo: { descObjetivo: objDescricao, unidade: "", casasDecimais: null },
      });
      setPillarSelected(idCicloPilar);

      if (idCicloPeso !== 0) {
        dispatch(Creators.getPesoById(idCycle, idCicloPeso));
      } else {
        setPesoSelected({
          ...pesoSelected,
          peso: "",
          definirResponsabilidade: false,
          idCicloPeso: null,
          objetivo: {
            ...pesoSelected.objetivo,
            descObjetivo: objDescricao,
          },
        });
        dispatch(CreatorsAreas.getChartById(idArea));
      }
      setIsDialogPesoOpen(true);
    };

    const handleToggleRow = (
      qntdObjs: number,
      j: number,
      pilarIndex: number
    ) => {
      for (let i = 0; i < qntdObjs; i++) {
        const tableParent = document.getElementById(
          `table-parent-${pilarIndex}${j}${i}`
        );
        const tdGoal = document.getElementById(`tdGoal-${pilarIndex}${j}${i}`);
        const tdItem = document.getElementById(`tdItem-${pilarIndex}${j}${i}`);
        const btnExpanded = document.getElementById(
          `btn-expanded-${pilarIndex}${j}${i}`
        );
        const btnCollapsed = document.getElementById(
          `btn-collapsed-${pilarIndex}${j}${i}`
        );

        if (i !== 0) {
          tableParent?.classList.toggle("tbl-expanded");
        } else {
          tableParent?.classList.toggle("tbl-parent-closed");
          if (tableParent?.classList.contains("tbl-parent-closed")) {
            tdGoal?.setAttribute("rowspan", `${qntdObjs}`);
            tdGoal?.setAttribute("colspan", `1`);
            tdGoal!.style.width = "200px";
            tdItem!.style.display = "table-cell";
            btnExpanded!.style.display = "none";
            btnCollapsed!.style.display = "block";
          } else {
            tdGoal?.setAttribute("rowspan", "1");
            tdGoal?.setAttribute("colspan", "2");
            tdGoal!.style.width = "430px";
            tdItem!.style.display = "none";
            btnExpanded!.style.display = "block";
            btnCollapsed!.style.display = "none";
          }
        }
      }
    };

    const renderTable = () => {
      const { body } = cycleStrategic.data.painel;

      return body.map((pillar, index) => {
        const { pilar, idCicloPilar, flagGeral } = pillar;
        const { corPilar, nomePilar } = pilar;
        const totalSpan = pillar.objetivos.reduce(
          (total, obj) => (total += obj.objetivos.length),
          0
        );
        return (
          <Table key={index}>
            <TBody color={corPilar}>
              {pillar.objetivos.map((objective, i, array) => {
                const isFirstObjective = i === 0;
                if (objective.objetivos) {
                  return objective.objetivos.map((subObj, j, arr) => {
                    const isFirstSubObjective = j === 0;
                    const { objetivo, meta, idObjetivo } = subObj;
                    const {
                      minimo,
                      maximo,
                      target,
                      idCicloMeta,
                      avaliacao,
                      flagInativo,
                    } = meta!;
                    const { unidade, descObjetivo, casasDecimais } = objetivo;
                    const isLastParentGoal = j === 0 && i + 1 === array.length;

                    const isLastFirstObj = array.length - 1 === i && j === 0;

                    return (
                      <Tr
                        key={j}
                        className={`${
                          isLastFirstObj ? "tbl-last-first-obj" : ""
                        }  ${j > 0 ? "tbl-expanded" : ""}`}
                        id={`table-parent-${index}${i}${j}`}
                      >
                        {isFirstObjective && isFirstSubObjective && (
                          <TdPillar
                            colorBorder={corPilar}
                            flagGeral={flagGeral}
                            rowSpan={totalSpan}
                          >
                            {nomePilar}
                          </TdPillar>
                        )}
                        {isFirstSubObjective ? (
                          <>
                            {!flagGeral && (
                              <>
                                <TdGoal
                                  id={`tdGoal-${index}${i}${j}`}
                                  colSpan={2}
                                  className={
                                    isLastParentGoal ? "lastParentGoal" : ""
                                  }
                                >
                                  {descObjetivo}
                                  <BtnIcon
                                    bgColor={corPilar}
                                    id={`btn-expanded-${index}${i}${j}`}
                                    onClick={() =>
                                      handleToggleRow(
                                        objective.objetivos.length,
                                        i,
                                        index
                                      )
                                    }
                                  >
                                    <FontIcon
                                      iconName="CirclePlus"
                                      style={{
                                        color: calcConstrast(corPilar)
                                          ? "#FFFFFF"
                                          : "#000000",
                                      }}
                                    />
                                  </BtnIcon>
                                </TdGoal>

                                <TdItem
                                  style={{ display: "none" }}
                                  id={`tdItem-${index}${i}${j}`}
                                  className="item"
                                  color={corPilar}
                                >
                                  {descObjetivo}
                                  <BtnIcon
                                    bgColor={corPilar}
                                    id={`btn-collapsed-${index}${i}${j}`}
                                    className="btnPainelRowCollapse"
                                    style={{ display: "none" }}
                                    onClick={() =>
                                      handleToggleRow(
                                        objective.objetivos.length,
                                        i,
                                        index
                                      )
                                    }
                                  >
                                    <FontIcon
                                      iconName="SkypeCircleMinus"
                                      style={{
                                        color: calcConstrast(corPilar)
                                          ? "#FFFFFF"
                                          : "#000000",
                                      }}
                                    />
                                  </BtnIcon>
                                </TdItem>
                              </>
                            )}
                          </>
                        ) : (
                          <TdItem className="item">
                            {objetivo.descObjetivo}
                          </TdItem>
                        )}
                        <Gap className="gap" />
                        {!flagGeral && unidade !== "A" ? (
                          <>
                            <TdMeta
                              id={isFirstObjective ? `REF-TATIC-${index}` : ""}
                              ref={isFirstObjective ? ref : null}
                              onClick={() =>
                                handleMeta(
                                  idCicloMeta!,
                                  idObjetivo,
                                  idCicloPilar
                                )
                              }
                            >
                              {formattedMeta(minimo, unidade, casasDecimais, idCicloMeta)}
                            </TdMeta>
                            <TdMeta
                              bold
                              onClick={() =>
                                handleMeta(
                                  idCicloMeta!,
                                  idObjetivo,
                                  idCicloPilar
                                )
                              }
                            >
                              {formattedMeta(target, unidade, casasDecimais, idCicloMeta)}
                              {flagInativo ? (
                                <FontIcon
                                  iconName="ChromeClose"
                                  style={{
                                    position: "absolute",
                                    top: 4,
                                    right: 4,
                                    color: Colors.gray,
                                    fontSize: 8,
                                  }}
                                />
                              ) : (
                                avaliacao && (
                                  <FontIcon
                                    iconName="CheckMark"
                                    style={{
                                      position: "absolute",
                                      top: 2,
                                      right: 2,
                                      color: Colors.primary,
                                    }}
                                  />
                                )
                              )}
                            </TdMeta>
                            <TdMeta
                              className="max"
                              darker
                              onClick={() =>
                                handleMeta(
                                  idCicloMeta!,
                                  idObjetivo,
                                  idCicloPilar
                                )
                              }
                            >
                              {formattedMeta(maximo, unidade, casasDecimais, idCicloMeta)}
                            </TdMeta>
                          </>
                        ) : (
                          <>
                            <TdSemMeta
                              id={isFirstObjective ? `REF-TATIC-${index}` : ""}
                              ref={isFirstObjective ? ref : null}
                            />
                            <TdSemMeta />
                            <TdSemMeta />
                          </>
                        )}
                        <Gap className="gap" />
                        {subObj.pesos?.map((pesoItem, i) => {
                          const { peso: pesoObj, idArea } = pesoItem;
                          const {
                            definirResponsabilidade,
                            idCicloPeso,
                            peso,
                            flagInativo: flagInativoPeso,
                          } = pesoObj;
                          return (
                            <TdPeso
                              key={i}
                              color={definirResponsabilidade ? corPilar : ""}
                              onClick={() =>
                                handlePeso(
                                  idCicloPeso!,
                                  idCicloPilar,
                                  idObjetivo,
                                  idArea!,
                                  objetivo.descObjetivo
                                )
                              }
                            >
                              {flagInativoPeso && (
                                <FontIcon
                                  iconName="ChromeClose"
                                  style={{
                                    position: "absolute",
                                    top: 4,
                                    right: 4,
                                    color: Colors.gray,
                                    fontSize: 8,
                                  }}
                                />
                              )}
                              {`${peso !== 0 ? `${~~peso}%` : ""}`}
                            </TdPeso>
                          );
                        })}
                      </Tr>
                    );
                  });
                } else {
                  return null;
                }
              })}
            </TBody>
          </Table>
        );
      });
    };

    return renderTable();
  }, [cycleStrategic.data, dispatch, idCycle, pesoSelected]);

  const renderTableFooter = useMemo(() => {
    const renderFooter = () => {
      const values = cycleStrategic.data.painel.body
        .map((pillar) => {
          return pillar.objetivos.map((obj) => {
            if (obj.objetivos) {
              return obj.objetivos.map((subObj) => {
                return subObj.pesos?.map((pesos) => {
                  if (!pesos.peso.flagInativo) {
                    return parseInt(`${pesos.peso.peso}`);
                  } else {
                    return 0;
                  }
                });
              });
            } else {
              return null;
            }
          });
        })
        .flat(2);

      if (values[0]) {
        const totais = new Array(values[0]!.length);
        totais.fill(0);

        totais.forEach((_, i) => {
          let TotalPeso = 0;

          values.forEach((item) => {
            TotalPeso = TotalPeso + item![i];
          });

          totais[i] = TotalPeso;
        });

        return totais.map((peso, i) => {
          return <TdPesoTotal key={i}>{peso}%</TdPesoTotal>;
        });
      }
    };

    return renderFooter();
  }, [cycleStrategic.data]);

  const hasObjetivos = cycleStrategic.data.painel?.body?.some((item) => {
    if (!item.flagGeral) {
      if (item.objetivos.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  });

  return (
    <Container>
      {cycleStrategic.loadingPainel ? (
        <ShimmerTatic />
      ) : cycleStrategic.data.painel.header.length === 0 || !hasObjetivos ? (
        <NoItems
          text="Não há objetivos vinculados no ciclo"
          error={false}
          icon="Table"
        />
      ) : (
        <WrapperTblHeader>
          <TblHeader>
            <Thead>{renderHeaderMemo}</Thead>
            <tbody>
              <tr>
                <td>{renderTableMemo}</td>
              </tr>
              <tr>
                <td>
                  <Table>
                    <tbody>
                      <TrTotal>
                        <TdTotal>TOTAL</TdTotal>
                        <Gap />
                        {renderTableFooter}
                      </TrTotal>
                    </tbody>
                  </Table>
                </td>
              </tr>
            </tbody>
          </TblHeader>
        </WrapperTblHeader>
      )}

      <FormMetas
        isLoading={cycleStrategic.loadingMeta}
        isLoadingAction={cycleStrategic.loading}
        isOpen={isDialogMetaOpen}
        setIsOpen={() => setIsDialogMetaOpen(false)}
        valuesInitialMeta={initialValuesMeta}
        onAddMeta={(metas: MetaType) =>
          Creators.addMeta(
            idCycle,
            idObjetivoSelected.idObjetivo!,
            pillarSelected,
            metas
          )
        }
        onEditMeta={(metas: MetaType) =>
          Creators.editMeta(
            idCycle,
            idObjetivoSelected.idObjetivo!,
            pillarSelected,
            initialValuesMeta.idCicloMeta!,
            metas
          )
        }
        onDeleteMeta={() =>
          Creators.delMeta(
            idCycle,
            initialValuesMeta.idCicloMeta!,
            pillarSelected,
            idObjetivoSelected.idObjetivo!
          )
        }
      />

      <FormPesos
        isOpen={isDialogPesoOpen}
        setIsOpen={() => setIsDialogPesoOpen(false)}
        descObjetivo={idObjetivoSelected.objetivo?.descObjetivo ?? ""}
        initialValues={pesoSelected}
        isLoadingPeso={cycleStrategic.loadingPeso || areaSelected.loading}
        isLoadingAction={cycleStrategic.loadingPesoAction}
        onAddPeso={(peso) =>
          Creators.addPeso(
            idCycle,
            idObjetivoSelected.idObjetivo!,
            pillarSelected,
            peso as PesoType
          )
        }
        onEditPeso={(peso) =>
          Creators.editPeso(
            idCycle,
            idObjetivoSelected.idObjetivo!,
            pillarSelected,
            pesoSelected.idCicloPeso ?? 0,
            peso as PesoType
          )
        }
        onDeletePeso={() =>
          Creators.deletePeso(
            idCycle,
            pillarSelected,
            idObjetivoSelected.idObjetivo!,
            pesoSelected.idCicloPeso ?? 0
          )
        }
      />
    </Container>
  );
};

export const formattedMeta = (
  value: string,
  unidade: string,
  casasDecimais: number | null = 1,
  idCicloMeta?: number | null
): string => {
  const valueFormatted: number = parseFloat(value);

  if (idCicloMeta === 0) {
    return "";
  }

  if (!Number.isNaN(valueFormatted)) {
    switch (unidade) {
      case "P":
        return `${valueFormatted.toFixed(casasDecimais ?? 1)}%`;
      case "M":
        const moeda: string = parseFloat(value).toLocaleString("pt-br", {
          minimumFractionDigits: 2,
        });
        return moeda;
      case "L":
        const million: string = decimal(
          parseFloat(value) / 1000000
        ).toLocaleString("pt-br");
        return `${million}M`;
      case "N":
        return `${valueFormatted}`;
      case "A":
        return "";
      default:
        return value;
    }
  } else {
    return "";
  }
};

export const formattedMetaForm = (value: string, unidade: string): string => {
  const valueFormatted: number = parseFloat(value);
  const moeda: string = parseFloat(value).toFixed(2);

  if (unidade === "P" || unidade === "N") {
    return `${valueFormatted}`;
  } else if (unidade === "M" || unidade === "L") {
    return moeda;
  } else if (unidade === "A") {
    return "";
  } else {
    return value;
  }
};

const decimal = (num: number) => {
  const og = Math.pow(10, 2);
  return Math.floor(num * og) / og;
};

export default Strategic;
