import { all } from "redux-saga/effects";

import login from "./login";
import companies from "./admin/companies";
import cycles from "./cycles";
import cycle from "./cycle";
import cycleAttitudes from "./cycleAttitudes";
import cyclePainel from "./cyclePainel";
import cycleDismemberment from "./cycleDismemberment";
import cycleValidation from "./cycleValidation";
import cyclePerformance from "./cyclePerformance";
import cycleTeams from "./cycleTeams";
import avaliations from "./avaliations";
import cycleGroups from "./cycleGroups";
import pillars from "./pillars";
import objectives from "./objectives";
import chart from "./admin/chart";
import employees from "./employees";
import units from "./admin/units";
import positions from "./admin/positions";
import tasks from "./tasks";
import profile from "./profile";
import solicitation from "./solicitation";
import attitudes from "./attitudes";
import selfEvaluations from "./selfEvaluations";
import teamEvaluation from "./teamEvaluation";
import meetings from "./meetings";
import calibration from "./calibration";
import worklevels from "./worklevels";
import pdis from "./admin/pdis";
import competences from "./competences";
import typesPDI from "./typesPDI";
import reports from "./reports";
import home from "./home";
import vacancy from "./vacancies";
import merit from "./merit";
import meritGenerate from "./meritGenerate";
import cc from "./cc";
import team from "./team";
import bonus from "./bonus";
import bonusGenerate from "./bonusGenerate";
import reportMerit from "./reportMerit";
import educationalMeasure from "./educationalMeasure";
import files from "./files"

import salaryStructure from "./admin/salaryStructure";
import step from "./admin/step";
import tipoMov from "./admin/tipoMov";
import admin from "./admin/admin";
import adminMerit from "./admin/merit";
import tabPerformances from "./admin/tabPerformances";
import categoriesPositions from "./admin/categoryPosition";
import motivoDesligamento from "./admin/motivoDesligamento";
import motivoFalta from "./admin/motivoFalta";
import standardPosition from "./admin/standardPosition";
import motivoAfastamento from "./admin/motivoAfastamento";
import vinculo from "./admin/vinculo";
import carreira from "./admin/carreira";
import sanctions from "./admin/sanction";
import typeConduct from "./admin/typeConduct";
import conducts from "./admin/conducts";

export default function* rootSaga() {
  yield all([
    login(),
    companies(),
    cycles(),
    cycle(),
    cycleAttitudes(),
    cyclePainel(),
    cycleDismemberment(),
    cycleValidation(),
    cyclePerformance(),
    cycleTeams(),
    avaliations(),
    cycleGroups(),
    pillars(),
    objectives(),
    chart(),
    employees(),
    units(),
    positions(),
    tasks(),
    profile(),
    solicitation(),
    attitudes(),
    selfEvaluations(),
    teamEvaluation(),
    meetings(),
    calibration(),
    worklevels(),
    pdis(),
    competences(),
    typesPDI(),
    reports(),
    home(),
    vacancy(),
    meritGenerate(),
    merit(),
    cc(),
    team(),
    bonus(),
    bonusGenerate(),
    reportMerit(),
    educationalMeasure(),
    files(),

    salaryStructure(),
    step(),
    tipoMov(),
    admin(),
    adminMerit(),
    tabPerformances(),
    categoriesPositions(),
    motivoDesligamento(),
    motivoFalta(),
    standardPosition(),
    motivoAfastamento(),
    vinculo(),
    carreira(),
    sanctions(),
    typeConduct(),
    conducts(),
  ]);
};
