import { takeEvery, put, call, all } from "redux-saga/effects";
import {
    Types,
    IRequestCCLoadAction,
    ISuccessCCLoadAction,
    CCType
} from "../ducks/cc/types";
import api from "~/services/api";

const apiCallLoad = (payload: IRequestCCLoadAction["payload"]) => {
    return api
        .get(`cc`, {
            params: {
                SearchText: payload.SearchText
            }
        })
        .then((response) => {
            return response.data;
        }).catch((err) => {
            throw err.response;
        });
};

function* load(action: IRequestCCLoadAction) {
    try {
        const data: CCType[] = yield call(apiCallLoad, action.payload);
        yield put({
            type: Types.SUCCESS_CC_LOAD,
            payload: data
        } as ISuccessCCLoadAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível carregar os centros de custo'
        yield put({
            type: Types.FAILURE_CC_LOAD,
            message
        });
    }
}

export default function* ccRoot() {
    yield all([
        yield takeEvery(Types.REQUEST_CC_LOAD, load),
    ]);
}
