import { takeEvery, put, call, all } from "redux-saga/effects";
import {
    Types,
    PDITiposType,
    IRequestPDITypesAddAction,
    ISuccessPDITypesLoadAction,
    ISuccessPDITypesAddAction
} from "../ducks/typesPDI/types";
import api from "~/services/api";

const apiCallLoadTypes = () => {
    return api
        .get(`acoespdi/tipospdi`)
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            throw err.response;
        });
};

const apiCallAddType = (descTipoPDI: string) => {
    return api
        .post(`acoespdi/tipospdi`, {
            descTipoPDI
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            throw err.response;
        });
};


function* loadTypes() {
    try {
        const data: PDITiposType[] = yield call(apiCallLoadTypes);
        yield put({
            type: Types.SUCCESS_PDI_TYPES_LOAD,
            payload: data
        } as ISuccessPDITypesLoadAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível carregar os tipos do PDI'
        yield put({
            type: Types.FAILURE_PDI_TYPES_LOAD,
            message
        });
    }
}

function* addType(action: IRequestPDITypesAddAction) {
    try {
        const idTipoPDI = yield call(apiCallAddType, action.descTipoPDI);
        yield put({
            type: Types.SUCCESS_PDI_TYPES_ADD,
            payload: {
                ...idTipoPDI,
                descTipoPDI: action.descTipoPDI
            }
        } as ISuccessPDITypesAddAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível carregar os tipos do PDI'
        yield put({
            type: Types.FAILURE_PDI_TYPES_LOAD,
            message
        });
    }
}

export default function* pdiRoot() {
    yield all([
        yield takeEvery(Types.REQUEST_PDI_TYPES_LOAD, loadTypes),
        yield takeEvery(Types.REQUEST_PDI_TYPES_ADD, addType),
    ]);
}
