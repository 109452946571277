import { MeritResume } from "../merit/types";

/**
 * Types
 */
export enum Types {
  //GET
  REQUEST_REPORT_MERIT_LOAD = "reportMerit/REQUEST_REPORT_MERIT_LOAD",
  SUCCESS_REPORT_MERIT_LOAD = "reportMerit/SUCCESS_REPORT_MERIT_LOAD",
  FAILURE_REPORT_MERIT_LOAD = "reportMerit/FAILURE_REPORT_MERIT_LOAD",
  //GET DETALHES
  REQUEST_REPORT_MERIT_LOAD_DETALHES = "reportMerit/REQUEST_REPORT_MERIT_LOAD_DETALHES",
  SUCCESS_REPORT_MERIT_LOAD_DETALHES = "reportMerit/SUCCESS_REPORT_MERIT_LOAD_DETALHES",
  FAILURE_REPORT_MERIT_LOAD_DETALHES = "reportMerit/FAILURE_REPORT_MERIT_LOAD_DETALHES",
  //CLEAR
  CLEAR_REPORT_MERIT = "reportMerit/CLEAR_REPORT_MERIT",
}

export type ReportMeritType = {
  temporadas: SeasonType[];
  reunioes: ReportMeritMeetingType[];
};

type SeasonType = {
  ano: number;
};

type ReportMeritMeetingType = {
  idMerito: number;
  area: {
    nomeLocal: string;
  };
};

export type ReportMeritDetalhe = {
  result: MeritResume;
};

interface IReportState {
  readonly data: ReportMeritType;
  readonly dataDetalhes: ReportMeritDetalhe | null;
  readonly loading: boolean;
  readonly loadingAction: boolean;
  readonly error: boolean;
  readonly success: boolean;
}

export type DataTypes = IReportState;

//GET
export interface IRequestReportMeritLoadAction {
  type: typeof Types.REQUEST_REPORT_MERIT_LOAD;
  payload?: {
    Ano?: string;
  };
}

export interface ISuccessReportMeritLoadAction {
  type: typeof Types.SUCCESS_REPORT_MERIT_LOAD;
  payload: ReportMeritType;
}

interface IFailureReportMeritLoadAction {
  type: typeof Types.FAILURE_REPORT_MERIT_LOAD;
  message: string;
}

type ReportMeritLoadAction =
  | IRequestReportMeritLoadAction
  | ISuccessReportMeritLoadAction
  | IFailureReportMeritLoadAction;

//GET
export interface IRequestReportMeritLoadDetalhesAction {
  type: typeof Types.REQUEST_REPORT_MERIT_LOAD_DETALHES;
  payload: {
    Ano: string;
    Decisao: string;
    Grupos?: number[];
  };
}

export interface ISuccessReportMeritLoadDetalhesAction {
  type: typeof Types.SUCCESS_REPORT_MERIT_LOAD_DETALHES;
  Decisao: string;
  payload: ReportMeritDetalhe;
}

interface IFailureReportMeritLoadDetalhesAction {
  type: typeof Types.FAILURE_REPORT_MERIT_LOAD_DETALHES;
  message: string;
}

type ReportMeritLoadDetalhesAction =
  | IRequestReportMeritLoadDetalhesAction
  | ISuccessReportMeritLoadDetalhesAction
  | IFailureReportMeritLoadDetalhesAction;

interface IFailureReportMeritClearAction {
  type: typeof Types.CLEAR_REPORT_MERIT;
}

export type ReportMeritActionTypes =
  | ReportMeritLoadAction
  | ReportMeritLoadDetalhesAction
  | IFailureReportMeritClearAction;
