import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import { initializeIcons } from '@uifabric/icons';

initializeIcons();

Sentry.init({
  dsn: "https://c374a12a2bfa4409b025b4bcfcb62c41@o545399.ingest.sentry.io/5667217",
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <App />,
  document.getElementById('root')
);