export const convertStatusTarefa = (status: string): string => {
    switch (status) {
        case 'a':
            return "Aguardando finalização";
        case 'f':
            return "Finalizada";
        case 'c':
            return 'Cancelada';
        case 'e':
            return 'Expirada';
        default:
            return status;
    }
}