/**
 * Types
 */
export enum Types {
  //SAVE
  REQUEST_SELF_EVALUATION_SAVE = "selfEvaluation/REQUEST_SELF_EVALUATION_SAVE",
  SUCCESS_SELF_EVALUATION_SAVE = "selfEvaluation/SUCCESS_SELF_EVALUATION_SAVE",
  FAILURE_SELF_EVALUATION_SAVE = "selfEvaluation/FAILURE_SELF_EVALUATION_SAVE",
  //SEND
  REQUEST_SELF_EVALUATION_SEND = "selfEvaluation/REQUEST_SELF_EVALUATION_SEND",
  SUCCESS_SELF_EVALUATION_SEND = "selfEvaluation/SUCCESS_SELF_EVALUATION_SEND",
  FAILURE_SELF_EVALUATION_SEND = "selfEvaluation/FAILURE_SELF_EVALUATION_SEND",
};


interface ISelfEvalationState {
  readonly loading: boolean;
  readonly error: boolean;
  readonly success: boolean;
  readonly redirectTo: string | null;
};

export type DataTypes = ISelfEvalationState;

export interface SelfEvaluationType {
  aspiracoesCarreira: string;
  comentariosGerais: string;
  atitudes: AtitudesSelfEvaluationType[];
  areas: AreasSelfEvalutation[];
};

interface AreasSelfEvalutation {
  idCicloAvaliacaoArea: number;
  comentariosIniciativas: string;
  iniciativas: IniciativaSelfEvaluationType[];
  // resultados: ResultadoSelfEvaluationType[];
}

interface AtitudesSelfEvaluationType {
  idCicloAvaliacaoAtitude: number;
  idAtitude: number;
  resultado: string | null;
  comentarios: string | null;
}

interface IniciativaSelfEvaluationType {
  idCicloAvaliacaoAreaIniciativa: number;
  idCicloColaboradorProjeto: number;
  resultado: string | null;
  percent: number | null;
}

interface ResultadoSelfEvaluationType {
  idCicloAvaliacaoAreaResultado: number;
  idCicloColaboradorMeta: number;
  real: number | string;
  percent: number;
  resultado: string;
  flagIniciativas: boolean | null;
}

//SAVE
export interface IRequestSaveSelfEvaluationAction {
  type: typeof Types.REQUEST_SELF_EVALUATION_SAVE;
  payload: {
    data: SelfEvaluationType,
    idCicloAvaliacao: number;
  };
};

export interface ISuccessSaveSelfEvaluationAction {
  type: typeof Types.SUCCESS_SELF_EVALUATION_SAVE,
}

interface IFailureSaveSelfEvaluationAction {
  type: typeof Types.FAILURE_SELF_EVALUATION_SAVE,
  message: string;
}

//SEND
export interface IRequestSendSelfEvaluationAction {
  type: typeof Types.REQUEST_SELF_EVALUATION_SEND;
  payload: {
    data: SelfEvaluationType,
    idCicloAvaliacao: number;
  };
};

export interface ISuccessSendSelfEvaluationAction {
  type: typeof Types.SUCCESS_SELF_EVALUATION_SEND,
}

interface IFailureSendSelfEvaluationAction {
  type: typeof Types.FAILURE_SELF_EVALUATION_SEND,
  message: string;
}


export type SelfEvaluationActionTypes =
  IRequestSaveSelfEvaluationAction |
  ISuccessSaveSelfEvaluationAction |
  IFailureSaveSelfEvaluationAction |
  IRequestSendSelfEvaluationAction |
  ISuccessSendSelfEvaluationAction |
  IFailureSendSelfEvaluationAction;
