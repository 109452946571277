import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { withRouter, RouteComponentProps } from 'react-router-dom';
 

import moment from 'moment';

import {
    Wrapper,
    ListContainer,
    GroupCheckbox,
    ContainerContent
} from "./styles";

//FluentUI
import { Text } from 'office-ui-fabric-react/lib/Text';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import {
    DetailsListLayoutMode,
    SelectionMode,
    Selection,
    IColumn,
} from 'office-ui-fabric-react/lib/DetailsList';
import { ShimmeredDetailsList } from 'office-ui-fabric-react/lib/ShimmeredDetailsList';
import { CommandBarButton, IconButton } from 'office-ui-fabric-react';
//MaterialUI
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
//Componentes
import { RootState } from "~/store/ducks";
import { CustomDrawer as Drawer } from "~/components/layout/Drawer";
import { InputText } from '~/components/Forms';
import NoItems from '~/components/layout/NoItems';
import Status from '~/components/Status';
import colors from "~/assets/js/colors";
import { Creators as getSolicitations } from "~/store/ducks/solicitation";
import { ISolicitationType, DataTypes } from "~/store/ducks/solicitation/types";

import { Creators as setCurrentPage } from "~/store/ducks/home";
import { convertTipoTarefa } from '../Tasks';
import Colors from "~/assets/js/colors";

type GroupFilter = {
    a: boolean;
    f: boolean;
    c: boolean;
    e: boolean;
    k: boolean;
    n: boolean;
};

type Filter = {
    isOpen?: boolean;
    filteredBy?: string[];
    group: GroupFilter;
};

interface ICycleState {
    columns: IColumn[];
    items: ISolicitationType[];
    isPanelOpen: boolean;
    selectionDetails: string;
    selectionCount: number;
    isFiltering: boolean;
    filter: Filter;
    searchText: string;
};

interface ICyclesProps extends RouteComponentProps {
    approvals: DataTypes;
    getSolicitations: (searchText?: string, status?: string[]) => void;
    history: RouteComponentProps["history"];
}

class Solicitations extends Component<ICyclesProps, ICycleState>{
    private formRef: any;
    private _selection: Selection;

    constructor(props: ICyclesProps) {
        super(props);

        const columns: IColumn[] = [
            {
                key: 'column1',
                name: '',
                ariaLabel: 'Status da solicitação',
                fieldName: 'status',
                minWidth: 15,
                maxWidth: 20,
                isResizable: true,
                isSortedDescending: false,
                onRender: (item: ISolicitationType) =>
                    <Status
                        color={convertStatusSolicitationColor(item.status)}
                        statusLetter={item.status.toLowerCase()}
                    />
            },
            {
                key: 'column2',
                name: 'ID',
                ariaLabel: 'ID',
                fieldName: 'idSolicitacao',
                isRowHeader: true,
                minWidth: 50,
                maxWidth: 50,
                isSortedDescending: false,
                onColumnClick: this._onColumnClick,
            },
            {
                key: 'column3',
                name: 'Descrição',
                fieldName: 'tituloSolicitacao',
                minWidth: 150,
                isRowHeader: true,
                isResizable: true,
                isSortedDescending: false,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                onColumnClick: this._onColumnClick,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column4',
                name: 'Tipo',
                fieldName: 'tipoSolicitacao',
                minWidth: 150,
                maxWidth: 210,
                isRowHeader: true,
                isResizable: true,
                isSortedDescending: false,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                onColumnClick: this._onColumnClick,
                data: 'string',
                isPadded: true,
                onRender: (values: ISolicitationType) => convertTipoTarefa(values.tipoSolicitacao)
            },
            {
                key: 'column5',
                name: 'Prazo',
                fieldName: 'dataExpiracao',
                minWidth: 150,
                maxWidth: 210,
                isRowHeader: true,
                isResizable: true,
                isSortedDescending: false,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                onColumnClick: this._onColumnClick,
                isPadded: true,
                onRender: (item: ISolicitationType) => moment(item.dataExpiracao).format("DD/MM/YYYY")
            },
        ];

        this.state = {
            columns: columns,
            items: [],
            isPanelOpen: false,
            selectionDetails: "",
            isFiltering: false,
            selectionCount: 0,
            filter: {
                isOpen: false,
                filteredBy: [],
                group: {
                    a: false,
                    f: false,
                    e: false,
                    c: false,
                    k: false,
                    n: false,
                }
            },
            searchText: ""
        };
        this.formRef = React.createRef();

        this._selection = new Selection({
            onSelectionChanged: () => {
                this.setState({
                    selectionDetails: this._getSelectionDetails(),
                });
            },
        });
    };

    componentDidMount() {
        this.props.getSolicitations();
    };

    _getSelectionDetails(): any {
        const selectionCount = this._selection.getSelectedCount();
        this.setState({ selectionCount });
        this._selection.getSelection();
    };

    // Submit do formulário Formik
    handleSubmit = () => {
        if (this.formRef.current) {
            this.formRef.current.handleSubmit();
        };
    };

    cancelPanel = () => {
        this._selection.setAllSelected(false);
        this.setState({ isPanelOpen: false });
    };

    handleChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
        const search = e.target.value;
        this.setState({ searchText: search })
    }

    handleEnterSearch = (e: React.KeyboardEvent<Element>) => {
        const { searchText, filter } = this.state;
        if (e.keyCode === 13) {
            this.props.getSolicitations(searchText, filter.filteredBy)
        }
    }

    handleFilterStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            filter: {
                ...this.state.filter,
                group: {
                    ...this.state.filter.group,
                    [e.target.name]: e.target.checked
                }
            }
        }, () => {
            const status = Object.entries(this.state.filter.group)
                .filter(item => item[1]) //Pega os filtros que estão ativos
                .map(item => item[0]) //Pega a sigla

            this.setState({
                filter: {
                    ...this.state.filter,
                    filteredBy: status
                }
            })

            this.props.getSolicitations(this.state.searchText, status)
        })
    }

    toggleFilter = () => {
        this.setState({
            filter: {
                ...this.state.filter,
                isOpen: !this.state.filter.isOpen,
            }
        });
    };

    clearFilters = () => {
        this.setState({
            filter: {
                ...this.state.filter,
                filteredBy: [],
                group:
                {
                    a: false,
                    f: false,
                    e: false,
                    c: false,
                    k: false,
                    n: false
                }
            }
        }, () => this.props.getSolicitations(this.state.searchText));
    };

    _onItemInvoked = (item: ISolicitationType) => {
        const { idSolicitacao, tipoSolicitacao } = item;
        if (tipoSolicitacao === 'ME') {
            this.props.history.push({
                pathname: `/workflow/solicitacao/${idSolicitacao}`,
                state: { stateLocation: { disabled: true } }
            })
        } else if (tipoSolicitacao === 'TV') {
            this.props.history.push({
                pathname: `/timeavaliacao`,
            })
        } else if (tipoSolicitacao === 'AV') {
            this.props.history.push({
                pathname: `/autoavaliacao`,
            })
        }
    }

    render() {
        const {
            columns,
            isFiltering,
            items,
            filter,
            searchText
        } = this.state;
        const { approvals, getSolicitations } = this.props;

        return (
            <Wrapper >
                <Drawer
                    diffHeight={105}
                    isOpen={filter.isOpen}
                    content={
                        <ContainerContent>
                            <Stack horizontal verticalAlign="center">
                                <InputText
                                    label="Entre com a descrição para a busca"
                                    value={searchText}
                                    onChange={this.handleChangeText}
                                    onKeyUp={this.handleEnterSearch}
                                    variant="outlined"
                                    height_container={40}
                                    fullWidth
                                    size="small"
                                    smaller="small"
                                />
                                <CommandBarButton
                                    styles={{ root: { height: 40, marginLeft: '15px !important', padding: '0 5px' } }}
                                    iconProps={{ iconName: 'Refresh' }}
                                    text="Atualizar"
                                    onClick={() => getSolicitations(searchText, filter.filteredBy)}
                                />
                                <CommandBarButton
                                    styles={{ root: { height: 40, marginLeft: '15px !important', padding: '0 5px' } }}
                                    iconProps={{ iconName: 'filter' }}
                                    text="Filtrar"
                                    onClick={() => this.toggleFilter()}
                                />
                            </Stack>
                            {!approvals.loading && approvals.data.length === 0 ?
                                <NoItems
                                    error={approvals.error}
                                    text="Não há solicitações"
                                    icon="IssueTracking"
                                    alt="Solicitações"
                                />
                                :
                                <ListContainer>
                                    <ShimmeredDetailsList
                                        items={isFiltering ? items : approvals.data}
                                        enableShimmer={approvals.loading}
                                        columns={columns}
                                        selectionMode={SelectionMode.none}
                                        selection={this._selection}
                                        getKey={this._getKey}
                                        selectionPreservedOnEmptyClick={true}
                                        setKey="single"
                                        layoutMode={DetailsListLayoutMode.justified}
                                        isHeaderVisible={true}
                                        onItemInvoked={this._onItemInvoked}
                                    />
                                </ListContainer>
                            }
                        </ContainerContent>
                    }>
                    <>
                        <Stack horizontal horizontalAlign="space-between">
                            <Text variant="xLarge">Filtros</Text>
                            <Stack horizontal>
                                <IconButton iconProps={{ iconName: 'ClearFilter' }} title="Limpar Filtros" disabled={filter.filteredBy!.length > 0 ? false : true} onClick={() => this.clearFilters()} ariaLabel="Limpar Filtros" />
                                <IconButton iconProps={{ iconName: 'ChromeClose' }} styles={{ root: { color: colors.darkGray } }} title="Fechar" ariaLabel="Fechar" onClick={() => this.setState({ filter: { ...filter, isOpen: false } })} />
                            </Stack>
                        </Stack>
                        <GroupCheckbox>
                            <Text variant="mediumPlus" styles={{ root: { marginBottom: 10 } }}>Status</Text>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={filter.group?.a}
                                        onChange={this.handleFilterStatus}
                                        name="a"
                                        color="primary"
                                    />
                                }
                                label={<span style={{ fontSize: 14 }}>Aberta</span>}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={filter.group?.k}
                                        onChange={this.handleFilterStatus}
                                        name="k"
                                        color="primary"
                                    />
                                }
                                label={<span style={{ fontSize: 14 }}>Aprovadas</span>}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={filter.group?.c}
                                        onChange={this.handleFilterStatus}
                                        name="c"
                                        color="primary"
                                    />
                                }
                                label={<span style={{ fontSize: 14 }}>Cancelada</span>}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={filter.group?.e}
                                        onChange={this.handleFilterStatus}
                                        name="e"
                                        color="primary"
                                    />
                                }
                                label={<span style={{ fontSize: 14 }}>Expirada</span>}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={filter.group?.f}
                                        onChange={this.handleFilterStatus}
                                        name="f"
                                        color="primary"
                                    />
                                }
                                label={<span style={{ fontSize: 14 }}>Finalizada</span>}
                            />


                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={filter.group?.n}
                                        onChange={this.handleFilterStatus}
                                        name="n"
                                        color="primary"
                                    />
                                }
                                label={<span style={{ fontSize: 14 }}>Negadas</span>}
                            />
                        </GroupCheckbox >
                    </>
                </Drawer>
            </Wrapper>
        );
    };

    private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const { columns } = this.state;
        const newColumns: IColumn[] = columns.slice();
        let items: ISolicitationType[] = [];
        if (this.state.isFiltering) {
            items = this.state.items;
        } else {
            items = this.props.approvals.data;
        };
        const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            };
        });
        const newItems = this._sort(items, currColumn.fieldName!, currColumn.isSortedDescending);
        this.setState({
            columns: newColumns,
            items: newItems,
        });
    };

    private _getKey(item: any, index?: number): any {
        if (item !== undefined)
            return item.key;
    };

    private _sort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
        const key = columnKey as keyof T;
        const itemsSorted = items.sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
        return itemsSorted;
    };
};

const convertStatusSolicitationColor = (tipo: string): string => {
    switch (tipo) {
        case 'a':
            return "#1FB964";
        case 'e':
            return Colors.darkGray;
        case 'f':
            return Colors.darkGray;
        case 'c':
            return Colors.error;
        case 'n':
            return Colors.error;
        case 'k':
            return "#1FB964";
        default:
            return tipo;
    }
}

const mapStateToProps = (state: RootState) => ({
    approvals: state.solicitationReducer,
});

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    ...setCurrentPage,
    ...getSolicitations,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Solicitations));