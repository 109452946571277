import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { Spinner, SpinnerSize, Stack } from "office-ui-fabric-react";

import { Creators as CreatorsTasks } from "~/store/ducks/tasks";
import { DataTypes as DataTypesTasks } from "~/store/ducks/tasks/types";
import { Creators as CreatorsMerit } from "~/store/ducks/merit";
import { RootState } from "~/store/ducks";

import NoItems from "~/components/layout/NoItems";

import Feedback from "~/pages/Tasks/Feedback";
import SelfEvaluation from "~/pages/Tasks/SelfEvaluation";
import TeamEvaluation from "~/pages/Tasks/TeamEvaluation";
import PDI from "~/pages/Tasks/PDI";
import HalfYearAvaliation from "../HalfYearAvaliation";
import DevolutivaMerito from "../DevolutivaMerito";
import CollectiveEvaluation from "../CollectiveEvaluation";
import FeedbackCollective from "../FeedbackCollective";
import Merit from "~/pages/Merit";
import FeedbackFuncionario from "../FeedbackFuncionario";



const ManageTasks: React.FC = () => {
  const taskState = useSelector<RootState, DataTypesTasks>(
    (state) => state.tasksReducer
  );
  const params: any = useParams();
  const { push } = useHistory()

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(CreatorsTasks.getTasksById(params.idTarefa));
  }, [dispatch, params.idTarefa]);

  useEffect(() => {
    if (taskState.selected?.TarefaMerito) {
      if (taskState.selected?.TarefaMerito.length > 0) {
        const { idMerito } = taskState.selected?.TarefaMerito[0]
        dispatch(CreatorsMerit.getMeritById(idMerito));
      }
    }
  }, [taskState.selected?.TarefaMerito])

  if (taskState.success) {
    switch (taskState.selected?.tipoTarefa) {
      case "AA":
        return <SelfEvaluation taskState={taskState} />;
      case "AT":
        return <TeamEvaluation tasks={taskState} />;
      case "FC":
      case "FB":
        return <Feedback taskState={taskState} />;
      case "FF":
        return <FeedbackFuncionario taskState={taskState} />;
      case "PD":
        return <PDI taskState={taskState} />;
      case "MA":
      case "MF":
        return <HalfYearAvaliation taskState={taskState} />;
      case "DM":
        return <DevolutivaMerito taskState={taskState} />;
      case "AC":
        return <CollectiveEvaluation taskState={taskState} />;
      case "FO":
        return <FeedbackCollective taskState={taskState} />;
      case "MT":

        return <Merit />
      default:
        return <div />;
    }
  } else if (taskState.error) {
    return (
      <Stack styles={{ root: { width: "100%" } }}>
        <NoItems text="Tarefa não encontrada" error={true} />
      </Stack>
    );
  } else {
    return (
      <Stack styles={{ root: { width: "100%" } }}>
        <Spinner
          size={SpinnerSize.medium}
          label="Carregando tarefa"
          styles={{ root: { height: "100%" } }}
        />
      </Stack>
    );
  }
};

export default ManageTasks;
