import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Chip from '~/components/Chip';
import { InputAutocomplete } from '~/components/Forms';

import { Creators } from '~/store/ducks/employees'
import { EmployeeType, DataTypes } from '~/store/ducks/employees/types'
import { DataTypes as DataTypesWorklevel } from '~/store/ducks/worklevel/types'

import { RootState } from "~/store/ducks";
import { Label } from 'office-ui-fabric-react';
import customToast from '~/components/Toast';

interface IFormConvidadoGroupProps {
    formRef: any;
    initialValues: Partial<EmployeeType>[];
    success: (values: any) => any;
};

let timeout: number = 0;

const FormConvidado: React.FC<IFormConvidadoGroupProps> = (props) => {
    const [initialValues, setInitialValues] = useState<Partial<EmployeeType>[]>(props.initialValues);

    const [valueEmployee, setValueEmployee] = useState<Partial<EmployeeType>>({
        idFuncionario: null,
        nomeCompleto: ''
    });


    const employeesState = useSelector<RootState, DataTypes>(state => state.employeesReducer);
    const worklevelState = useSelector<RootState, DataTypesWorklevel>(state => state.worklevelReducer);

    const avaliados = worklevelState.data.flatMap(item => item.avaliados);
    const avaliadores = worklevelState.data.flatMap(item => item.avaliados?.map(avaliado => avaliado.nomeChefe))
        .filter((item, i, arr) => arr.map(part => part).indexOf(item) == i);

    const dispatch = useDispatch();

    useEffect(() => {
        if (valueEmployee?.idFuncionario) {
            const alreadyAdded = avaliadores.some(item => item === valueEmployee.nomeCompleto);
            const alreadyGuestAdded = initialValues.some(item => item.idFuncionario === valueEmployee.idFuncionario);
            const alreadyAvaliadoAdded = avaliados.some(item => item?.idFuncionario === valueEmployee.idFuncionario);

            if (alreadyAvaliadoAdded) {
                customToast.warning("O funcionário será avaliado!")
            } else {
                if (alreadyAdded || alreadyGuestAdded) {
                    customToast.warning("Avaliador já adicionado!")
                } else {
                    setInitialValues([...initialValues, valueEmployee])
                }
            }

            setValueEmployee({
                idFuncionario: null,
                nomeCompleto: ''
            })
        }
    }, [valueEmployee])

    useEffect(() => {
        props.success(initialValues);
    }, [initialValues])

    const search = (text: string) => {
        if (text.trim()) {
            clearTimeout(timeout);
            timeout = window.setTimeout(() => {
                dispatch(Creators.getEmployees(text));
            }, 700);
        }
    };

    const onKeyDown = (keyEvent: React.KeyboardEvent<HTMLDivElement>) => {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault();
        }
    }

    const removeConvidado = (idFuncionario: number) => {
        setInitialValues(initialValues.filter(item => item.idFuncionario !== idFuncionario));
    }

    return (
        <div className="ms-Grid" dir="ltr">
            <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 paddingZero">
                    <InputAutocomplete
                        value={(valueEmployee as EmployeeType)}
                        onChange={(_, newValue) => {
                            setValueEmployee(newValue!)
                        }}
                        onInputChange={(_, newInputValue) => {
                            search(newInputValue);
                        }}
                        onKeyDown={onKeyDown}
                        getOptionLabel={(employee: EmployeeType) => employee.nomeCompleto}
                        getOptionSelected={(option, value) => option.idFuncionario === value.idFuncionario}
                        options={employeesState.data}
                        input={{
                            idInput: "funcionario",
                            labelInput: "Nome do Avaliador",
                            autoFocus: true
                        }}
                    />

                    <Label>Avaliadores líderes</Label>
                    {
                        avaliadores.map((item, i) => (
                            <Chip
                                key={i}
                                nome={item ?? ''}
                            />
                        ))
                    }
                    <Label styles={{ root: { marginTop: 40, paddingTop: 0 } }}>Avaliadores convidados</Label>
                    {
                        initialValues.map((item, i) => (
                            <Chip
                                key={i}
                                nome={item?.nomeCompleto ?? ''}
                                imagem={item.imagem}
                                handleClick={() => removeConvidado(item.idFuncionario!)}
                            />
                        ))
                    }
                </div>
            </div>
        </div>
    );
}

export default FormConvidado;