import {
  Types,
  DataTypes,
  CycleTeamsActionTypes,
  CycleTeamType,
} from "./types";
import customToast from "~/components/Toast/index";

/**
 * Reducers
 */
const INITIAL_STATE: DataTypes = {
  data: [],
  itemSelected: null,
  loadingData: false,
  loadingAction: false,
  success: false,
  error: false,
};

/**
 * Actions
 */

export const Creators = {
  getCycleTeams: (
    idCiclo: number,
    idLider?: number | null,
    idLiderado?: number | null
  ): CycleTeamsActionTypes => ({
    type: Types.REQUEST_CYCLE_TEAMS_LOAD,
    payload: {
      idCiclo,
      idLider,
      idLiderado,
    },
  }),

  transferMemberTeam: (
    idCiclo: number,
    idFuncionario: number,
    liderNome: string,
    membros: Partial<CycleTeamType>[]
  ): CycleTeamsActionTypes => ({
    type: Types.REQUEST_CYCLE_TEAMS_TRANSFER,
    payload: {
      idCiclo,
      idFuncionario,
      liderNome,
      membros,
    },
  }),

  clearTeam: (): CycleTeamsActionTypes => ({
    type: Types.CLEAR_CYCLE_TEAMS,
  }),
};

export default function cycleTeamsReducer(
  state = INITIAL_STATE,
  action: CycleTeamsActionTypes
): DataTypes {
  switch (action.type) {
    case Types.REQUEST_CYCLE_TEAMS_LOAD:
      return { ...state, loadingData: true, error: false, success: false };
    case Types.SUCCESS_CYCLE_TEAMS_LOAD:
      return {
        ...state,
        data: action.payload,
        loadingData: false,
        error: false,
        success: true,
      };
    case Types.FAILURE_CYCLE_TEAMS_LOAD:
      customToast.error(action.message);
      return {
        ...state,
        data: [],
        loadingData: false,
        error: true,
        success: false,
      };

    case Types.REQUEST_CYCLE_TEAMS_TRANSFER:
      return { ...state, loadingAction: true, error: false, success: false };
    case Types.SUCCESS_CYCLE_TEAMS_TRANSFER:
      state.data.forEach((item) => {
        action.payload.membros.forEach((membro) => {
          if (item.idFuncionario === membro.idFuncionario) {
            item.time.funcionario.nomeCompleto = action.payload.liderNome;
          }
        });
      });

      return { ...state, loadingAction: false, error: false, success: true };
    case Types.FAILURE_CYCLE_TEAMS_TRANSFER:
      customToast.error(action.message);
      return { ...state, loadingAction: false, error: true, success: false };

    case Types.CLEAR_CYCLE_TEAMS:
      return { ...state, data: [] };

    default:
      return state;
  }
}
