// #region Imports
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Formik } from "formik";
import * as yup from "yup";

//FluentUI
import { Stack } from "office-ui-fabric-react/lib/Stack";
import {
  DetailsListLayoutMode,
  SelectionMode,
  Selection,
  IColumn,
} from "office-ui-fabric-react/lib/DetailsList";
import { ShimmeredDetailsList } from "office-ui-fabric-react/lib/ShimmeredDetailsList";
import { CommandBarButton, ICommandBarStyles } from "office-ui-fabric-react";
import { IDialogContentProps } from "office-ui-fabric-react/lib/Dialog";
//MaterialUI
//Componentes
import Panel from "~/components/layout/Panel";
import Status from "~/components/Status";
import CustomDialog from "~/components/CustomDialogFluentUI";
import { InputText, InputCheckbox } from "~/components/Forms";
import HeaderPage from "~/components/layout/HeaderPage";
import NoItems from "~/components/layout/NoItems";
import { DeleteButton } from "~/components/Buttons";
import { Page, BreadcrumbItems } from "~/store/ducks/home/types";
//Estilos
import {
  Wrapper,
  ListContainer,
  DefaultButton,
  ContainerContent,
} from "./styles";

import { PrimaryButton } from "~/components/Buttons";
import { Creators as getTypeConducts } from "~/store/ducks/admin/typeConducts";
import { Creators as addTypeConduct } from "~/store/ducks/admin/typeConducts";
import { Creators as delTypeConduct } from "~/store/ducks/admin/typeConducts";
import { Creators as editTypeConduct } from "~/store/ducks/admin/typeConducts";
import { Creators as setCurrentPage } from "~/store/ducks/home";
import {
  TypeConductType,
  DataTypes,
} from "~/store/ducks/admin/typeConducts/types";
import { RootState } from "~/store/ducks";
//#endregion

const btnStyle: Partial<ICommandBarStyles> = {
  root: {
    height: 44,
  },
};

/**
 * Validação do formulário
 */
const validationTypesConductsSchema = yup.object().shape({
  titulo: yup.string().required("Campo obrigatório"),
  descricao: yup.string().required("Campo obrigatório"),
  flagAtiva: yup.bool(),
});

interface ITypesConductsState {
  columns: IColumn[];
  items: TypeConductType[];
  isPanelOpen: boolean;
  inicialValues: TypeConductType;
  isFiltering: boolean;
  selectionDetails: string;
  selectionCount: number;
  isDialogExcludeOpen: boolean;
}

interface IPropsTypesConducts {
  typesConducts: DataTypes;
  setCurrentPage: (page: Page) => void;
  getTypeConducts: () => void;
  addTypeConduct: (typeConduct: TypeConductType) => void;
  editTypeConduct: (typeConduct: TypeConductType) => void;
  delTypeConduct: (id: number) => void;
}

const itemsBreadCrumb: BreadcrumbItems[] = [
  {
    text: "Home",
    isCurrentItem: false,
    icon: "HomeSolid",
    onlyIcon: true,
    url: "/",
  },
  { text: "Tipos de Condutas", isCurrentItem: true },
];

const initialValue: TypeConductType = {
  idTipoConduta: null,
  titulo: "",
  descricao: "",
  flagAtivo: true,
};

class TypesConducts extends Component<
  IPropsTypesConducts,
  ITypesConductsState
> {
  private formRef: any;
  private _selection: Selection;

  constructor(props: IPropsTypesConducts) {
    super(props);
    //#region Colunas
    const columns: IColumn[] = [
      {
        key: "column1",
        name: "",
        ariaLabel: "Status do Tipo de conduta",
        fieldName: "flagAtivo",
        minWidth: 15,
        maxWidth: 20,
        isResizable: true,
        isSortedDescending: false,
        onRender: (item: TypeConductType) => <Status status={item.flagAtivo} />,
      },
      {
        key: "column2",
        name: "ID",
        ariaLabel: "Id do Tipo da conduta",
        fieldName: "idTipoConduta",
        isRowHeader: true,
        minWidth: 75,
        maxWidth: 100,
        isResizable: true,
        isSortedDescending: false,
        onColumnClick: this._onColumnClick,
      },
      {
        key: "column3",
        name: "Título",
        fieldName: "titulo",
        minWidth: 150,
        maxWidth: 200,
        isRowHeader: true,
        isResizable: true,
        isSorted: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "string",
        isPadded: true,
      },
      {
        key: "column4",
        name: "Descrição",
        fieldName: "descricao",
        minWidth: 210,
        isRowHeader: true,
        isResizable: true,
        isSorted: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "string",
        isPadded: true,
      },
    ];
    //#endregion

    this.state = {
      columns: columns,
      items: [],
      isPanelOpen: false,
      selectionDetails: "",
      inicialValues: initialValue,
      isFiltering: false,
      selectionCount: 0,
      isDialogExcludeOpen: false,
    };

    this._selection = new Selection({
      onSelectionChanged: () => {
        this.setState({
          selectionDetails: this._getSelectionDetails(),
        });
      },
    });

    this.formRef = React.createRef();
  }

  componentDidMount() {
    const page: Page = {
      key: "tipoConduta",
      pages: itemsBreadCrumb,
    };
    this.props.setCurrentPage(page);
    this.props.getTypeConducts();
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (this.state.isPanelOpen) {
      if (prevProps.typesConducts.success !== this.props.typesConducts.success) {
        if (this.props.typesConducts.success) {
          this.setState({ isPanelOpen: false });
          this._selection.setAllSelected(false);
        }
      }
    }
  }

  // Submit do formulário Formik
  handleSubmit = () => {
    if (this.formRef.current) {
      this.formRef.current.handleSubmit();
    }
  };

  _onItemInvoked = (item: TypeConductType): void => {
    this.setState({
      isPanelOpen: true,
      inicialValues: {
        ...item,
      },
    });
  };

  _getSelectionDetails(): any {
    const selectionCount = this._selection.getSelectedCount();
    this.setState({ selectionCount });
    this._selection.getSelection();
  }

  openEdit = () => {
    const selectItem: TypeConductType = this._selection.getSelection()[0] as TypeConductType;
    this.setState({ isPanelOpen: true, inicialValues: selectItem });
  };

  cancelPanel = () => {
    this._selection.setAllSelected(false);
    this.setState({ isPanelOpen: false });
  };

  excludeTypeConduct = () => {
    this.props.delTypeConduct(
      (this._selection.getSelection()[0] as TypeConductType).idTipoConduta!
    );
    this.setState({ isDialogExcludeOpen: false });
    this._selection.setAllSelected(false);
  };

  //#endregion

  commandBarRender = () => {
    const { selectionCount } = this.state;
    if (selectionCount === 1) {
      return (
        <>
          <CommandBarButton
            styles={btnStyle}
            iconProps={{ iconName: "Edit" }}
            text="Editar"
            onClick={() => this.openEdit()}
          />
          <CommandBarButton
            styles={btnStyle}
            iconProps={{ iconName: "Delete" }}
            text="Excluir"
            onClick={() => this.setState({ isDialogExcludeOpen: true })}
          />
        </>
      );
    } else if (selectionCount > 1) {
      return (
        <CommandBarButton
          styles={btnStyle}
          iconProps={{ iconName: "Delete" }}
          text="Excluir"
          onClick={() => this.setState({ isDialogExcludeOpen: true })}
        />
      );
    }
  };

  render() {
    const {
      isPanelOpen,
      isDialogExcludeOpen,
      selectionCount,
      inicialValues,
      isFiltering,
      items,
      columns,
    } = this.state;
    const {
      typesConducts,
      addTypeConduct,
      editTypeConduct,
      getTypeConducts,
    } = this.props;

    const dialogContentProps: IDialogContentProps = {
      title: "Excluir?",
      closeButtonAriaLabel: "Close",
      subText:
        selectionCount <= 1
          ? "Tem certeza de que deseja excluir este item?"
          : "Tem certeza de que deseja excluir estes items?",
    };

    return (
      <>
        <Panel
          title={
            !inicialValues.idTipoConduta
              ? "Nova Tipo de Conduta"
              : inicialValues.titulo
          }
          open={isPanelOpen}
          onClose={() => this.cancelPanel()}
          footer={
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <DefaultButton onClick={() => this.cancelPanel()}>
                Cancelar
              </DefaultButton>
              <PrimaryButton
                onClick={this.handleSubmit}
                isLoading={typesConducts.loadingAction}
                text="Salvar"
              />
            </Stack>
          }
        >
          <Formik
            innerRef={this.formRef}
            initialValues={{
              ...inicialValues,
              flagAtivo: !inicialValues.flagAtivo,
            }}
            validationSchema={validationTypesConductsSchema}
            validateOnChange={false}
            validateOnBlur={true}
            onSubmit={(values: TypeConductType) => {
              if (inicialValues.idTipoConduta === null) {
                addTypeConduct(values);
              } else {
                values.idTipoConduta = inicialValues.idTipoConduta;
                editTypeConduct({ ...values, flagAtivo: !values.flagAtivo });
              }
            }}
          >
            {({
              handleSubmit,
              handleChange,
              values,
              errors,
              setFieldError,
            }) => (
              <form onSubmit={handleSubmit}>
                <InputText
                  value={values.titulo}
                  onChange={(e) => {
                    handleChange(e);
                    setFieldError("titulo", "");
                  }}
                  id="titulo"
                  error={errors.titulo ? true : false}
                  name="titulo"
                  type="text"
                  label="Título"
                  helperText={errors.titulo}
                  className="mt-2"
                  autoFocus
                />
                <InputText
                  value={values.descricao}
                  onChange={(e) => {
                    handleChange(e);
                    setFieldError("descricao", "");
                  }}
                  id="descricao"
                  error={errors.descricao ? true : false}
                  name="descricao"
                  type="text"
                  label="Descrição"
                  helperText={errors.descricao}
                  className="mt-2"
                />
                {inicialValues.idTipoConduta && (
                  <InputCheckbox
                    checked={values.flagAtivo}
                    onChange={handleChange}
                    name="flagAtivo"
                    color="primary"
                    label="Inativar Tipo de Conduta"
                  />
                )}
              </form>
            )}
          </Formik>
        </Panel>

        <CustomDialog
          hidden={!isDialogExcludeOpen}
          onDismiss={() => this.setState({ isDialogExcludeOpen: false })}
          dialogContentProps={dialogContentProps}
        >
          <DefaultButton
            onClick={() => this.setState({ isDialogExcludeOpen: false })}
            text="Cancelar"
          />
          <DeleteButton onClick={() => this.excludeTypeConduct()} text="Excluir" />
        </CustomDialog>
        <Wrapper>
          <ContainerContent>
            <HeaderPage
              title="Tipos de Condutas"
              leftItems={
                <>
                  <CommandBarButton
                    styles={btnStyle}
                    iconProps={{ iconName: "Add" }}
                    disabled={typesConducts.loading}
                    text="Adicionar Tipo"
                    onClick={() =>
                      this.setState({
                        isPanelOpen: true,
                        inicialValues: initialValue,
                      })
                    }
                  />
                  {this.commandBarRender()}
                </>
              }
              rightItems={
                <CommandBarButton
                  styles={btnStyle}
                  iconProps={{ iconName: "Refresh" }}
                  text="Atualizar"
                  onClick={() => getTypeConducts()}
                />
              }
            />
            {!typesConducts.loading && typesConducts.data.length === 0 ? (
              <NoItems
                error={typesConducts.error}
                text="Não há tipo de condutas cadastradas"
                img="/static/icons/supermarket.svg"
                alt="Tipos de Condutas"
              />
            ) : (
              <ListContainer>
                <ShimmeredDetailsList
                  items={isFiltering ? items : typesConducts.data}
                  enableShimmer={typesConducts.loading}
                  columns={columns}
                  selectionMode={SelectionMode.single}
                  selection={this._selection}
                  getKey={this._getKey}
                  selectionPreservedOnEmptyClick={true}
                  setKey="single"
                  layoutMode={DetailsListLayoutMode.justified}
                  isHeaderVisible={true}
                  onItemInvoked={this._onItemInvoked}
                />
              </ListContainer>
            )}
          </ContainerContent>
        </Wrapper>
      </>
    );
  }

  private _onColumnClick = (
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn
  ): void => {
    const { columns } = this.state;
    const newColumns: IColumn[] = columns.slice();
    let items: TypeConductType[] = [];
    if (this.state.isFiltering) {
      items = this.state.items;
    } else {
      items = this.props.typesConducts.data;
    }
    const currColumn: IColumn = newColumns.filter(
      (currCol) => column.key === currCol.key
    )[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    const newItems = this._sort(
      items,
      currColumn.fieldName!,
      currColumn.isSortedDescending
    );
    this.setState({
      columns: newColumns,
      items: newItems,
    });
  };

  private _getKey(item: any, index?: number): any {
    if (item !== undefined) return item.key;
  }

  private _sort<T>(
    items: T[],
    columnKey: string,
    isSortedDescending?: boolean
  ): T[] {
    const result = (prop: string, obj: T) =>
      prop.split(".").reduce((a: any, b: any) => a[b] ?? "", obj);
    return items.sort((a: T, b: T) =>
      (
        isSortedDescending
          ? result(columnKey, a) < result(columnKey, b)
          : result(columnKey, a) > result(columnKey, b)
      )
        ? 1
        : -1
    );
  }
}
const mapStateToProps = (state: RootState) => ({
  typesConducts: state.typeConductReducer,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      ...getTypeConducts,
      ...addTypeConduct,
      ...delTypeConduct,
      ...editTypeConduct,
      ...setCurrentPage,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(TypesConducts);
