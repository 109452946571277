import { takeEvery, put, call, all } from "redux-saga/effects";
import {
    Types,
    IRequestReportLoadAction,
    IRequestReportCurveLoadAction,
    IRequestReportSuccessionLoadAction,
    IRequestReportTalentLoadAction,
    ISuccessReportLoadAction,
    ISuccessReportCurveLoadAction,
    ISuccessReportSuccessionLoadAction,
    ISuccessReportTalentLoadAction
} from "../ducks/reports/types";
import api from "~/services/api";

const apiCallLoad = (payload: IRequestReportLoadAction["payload"]) => {
    return api.get(`relatorios`, {
        params: {
            ...payload
        }
    })
        .then(response => response.data)
        .catch(err => {
            throw err.response;
        });
};

const apiCallLoadCurve = (payload: IRequestReportCurveLoadAction["payload"]) => {
    return api.get(`relatorios/curva`, {
        params: {
            Ano: payload?.Ano,
            Reuniao: payload?.Reuniao?.join(','),
            comparar: payload?.comparar?.join(',')
        }
    })
        .then(response => response.data)
        .catch(err => {
            throw err.response;
        });
};

const apiCallLoadSuccession = (payload: IRequestReportSuccessionLoadAction["payload"]) => {
    return api.get(`relatorios/sucessao`, {
        params: {
            Ano: payload?.Ano,
            Reuniao: payload?.Reuniao?.join(',')
        }
    })
        .then(response => response.data)
        .catch(err => {
            throw err.response;
        });
};

const apiCallLoadTalent = (payload: IRequestReportTalentLoadAction["payload"]) => {
    return api.get(`relatorios/talent`, {
        params: {
            Ano: payload?.Ano,
            Reuniao: payload?.Reuniao?.join(','),
            TipoCargo: payload?.TipoCargo
        }
    })
        .then(response => response.data)
        .catch(err => {
            throw err.response;
        });
};

function* load(action: IRequestReportLoadAction) {
    try {
        const data = yield call(apiCallLoad, action.payload);

        yield put({
            type: Types.SUCCESS_REPORT_LOAD,
            payload: data
        } as ISuccessReportLoadAction);

    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível carregar o relatório.';
        yield put({ type: Types.FAILURE_REPORT_LOAD, message });
    };
};

function* loadCurveReport(action: IRequestReportCurveLoadAction) {
    try {
        const data = yield call(apiCallLoadCurve, action.payload);

        yield put({
            type: Types.SUCCESS_REPORT_CURVE_LOAD,
            payload: data
        } as ISuccessReportCurveLoadAction);

    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível carregar o relatório de curva.';
        yield put({ type: Types.FAILURE_REPORT_CURVE_LOAD, message });
    };
};

function* loadSuccessionReport(action: IRequestReportSuccessionLoadAction) {
    try {
        const data = yield call(apiCallLoadSuccession, action.payload);

        yield put({
            type: Types.SUCCESS_REPORT_SUCCESSION_LOAD,
            payload: data
        } as ISuccessReportSuccessionLoadAction);

    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível carregar o relatório de sucessão.';
        yield put({ type: Types.FAILURE_REPORT_SUCCESSION_LOAD, message });
    };
};

function* loadTalentReport(action: IRequestReportTalentLoadAction) {
    try {
        const data = yield call(apiCallLoadTalent, action.payload);

        yield put({
            type: Types.SUCCESS_REPORT_TALENT_LOAD,
            payload: data
        } as ISuccessReportTalentLoadAction);

    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível carregar o relatório de matriz de talentos.';
        yield put({ type: Types.FAILURE_REPORT_TALENT_LOAD, message });
    };
};

export default function* companiesRoot() {
    yield all([
        yield takeEvery(Types.REQUEST_REPORT_LOAD, load),
        yield takeEvery(Types.REQUEST_REPORT_CURVE_LOAD, loadCurveReport),
        yield takeEvery(Types.REQUEST_REPORT_SUCCESSION_LOAD, loadSuccessionReport),
        yield takeEvery(Types.REQUEST_REPORT_TALENT_LOAD, loadTalentReport),
    ]);
};
