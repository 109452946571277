import { takeEvery, put, call, all } from "redux-saga/effects";
import {
  Types,
  PillarType,
  IRequestPillarsLoadAction,
  IRequestPillarsAddAction,
  IRequestPillarsDeleteAction,
} from "../ducks/pillars/types";
import api from "~/services/api";

const apiCallGet = (search: string) => {
  return api
    .get(`pilar?SearchText=${search}`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err.response;
    });
};

const apiCallPost = (item: PillarType) => {
  return api
    .post("pilar", {
      nomePilar: item.nomePilar,
      corPilar: item.corPilar,
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallPut = (item: PillarType) => {
  return api
    .put(`pilar/${item.idPilar}`, {
      ...item,
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallDel = (id: number) => {
  return api
    .delete(`pilar/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

function* load(action: IRequestPillarsLoadAction) {
  try {
    let items: any = yield call(apiCallGet, action.search);
    yield put({ type: Types.SUCCESS_PILLARS_LOAD, payload: items });
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível carregar'
    yield put({
      type: Types.FAILURE_PILLARS_LOAD,
      message
    });
  }
}

function* add(action: IRequestPillarsAddAction) {
  try {
    const idPilar = yield call(apiCallPost, action.pillar);
    yield put({ type: Types.SUCCESS_PILLARS_ADD, pillar: { ...action.pillar, ...idPilar, flagAtiva: true } });
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível adicionar pilar'
    yield put({
      type: Types.FAILURE_PILLARS_ADD,
      message
    });
  }
}

function* edit(action: IRequestPillarsAddAction) {
  try {
    yield call(apiCallPut, action.pillar);
    yield put({ type: Types.SUCCESS_PILLARS_EDIT, pillar: action.pillar });
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível editar pilar';
    yield put({ type: Types.FAILURE_PILLARS_EDIT, message });
  }
}

function* del(action: IRequestPillarsDeleteAction) {
  try {
    yield call(apiCallDel, action.id);
    yield put({ type: Types.SUCCESS_PILLARS_DELETE, id: action.id });
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível editar pilar';
    yield put({ type: Types.FAILURE_PILLARS_DELETE, message });
  }
}

export default function* companiesRoot() {
  yield all([
    yield takeEvery(Types.REQUEST_PILLARS_LOAD, load),
    yield takeEvery(Types.REQUEST_PILLARS_ADD, add),
    yield takeEvery(Types.REQUEST_PILLARS_EDIT, edit),
    yield takeEvery(Types.REQUEST_PILLARS_DELETE, del),
  ]);
}
