/**
 * Types
 */
export enum Types {
  //GET
  REQUEST_SANCTIONS_LOAD = "sanctions/REQUEST_SANCTIONS_LOAD",
  SUCCESS_SANCTIONS_LOAD = "sanctions/SUCCESS_SANCTIONS_LOAD",
  FAILURE_SANCTIONS_LOAD = "sanctions/FAILURE_SANCTIONS_LOAD",
  //ADD
  REQUEST_SANCTION_ADD = "sanctions/REQUEST_SANCTION_ADD",
  SUCCESS_SANCTION_ADD = "sanctions/SUCCESS_SANCTION_ADD",
  FAILURE_SANCTION_ADD = "sanctions/FAILURE_SANCTION_ADD",
  //PUT
  REQUEST_SANCTION_EDIT = "sanctions/REQUEST_SANCTION_EDIT",
  SUCCESS_SANCTION_EDIT = "sanctions/SUfCCESS_SANCTION_EDIT",
  FAILURE_SANCTION_EDIT = "sanctions/FAILURE_SANCTION_EDIT",
  //DELETE
  REQUEST_SANCTION_DELETE = "sanctions/REQUEST_SANCTION_DELETE",
  SUCCESS_SANCTION_DELETE = "sanctions/SUCCESS_SANCTION_DELETE",
  FAILURE_SANCTION_DELETE = "sanctions/FAILURE_SANCTION_DELETE",
}

export type SanctionType = {
  idSancao: number | null;
  descSancao: string;
  flagAtivo: boolean;
  flagRecolherAssinatura: boolean;
  flagJustaCausa: boolean;
};

interface ISanctionState {
  readonly data: SanctionType[];
  readonly loading: boolean;
  readonly loadingAction: boolean;
  readonly error: boolean;
  readonly success: boolean;
}

export type DataTypes = ISanctionState;

/**
 * Actions
 */

//GET
export interface IRequestSanctionsLoadAction {
  type: typeof Types.REQUEST_SANCTIONS_LOAD;
}

export interface ISuccessSanctionsLoadAction {
  type: typeof Types.SUCCESS_SANCTIONS_LOAD;
  payload: SanctionType[];
}

interface IFailureSanctionsLoadAction {
  type: typeof Types.FAILURE_SANCTIONS_LOAD;
  message: string;
}

type SanctionsLoadAction =
  | IRequestSanctionsLoadAction
  | ISuccessSanctionsLoadAction
  | IFailureSanctionsLoadAction;

//ADD
export interface IRequestSanctionAddAction {
  type: typeof Types.REQUEST_SANCTION_ADD;
  sanction: SanctionType;
}

interface ISuccessSanctionAddAction {
  type: typeof Types.SUCCESS_SANCTION_ADD;
  sanction: SanctionType;
}

interface IFailureSanctionAddAction {
  type: typeof Types.FAILURE_SANCTION_ADD;
  message: string;
}

type SanctionAddAction =
  | IRequestSanctionAddAction
  | ISuccessSanctionAddAction
  | IFailureSanctionAddAction;

//PUT
export interface IRequestSanctionEditAction {
  type: typeof Types.REQUEST_SANCTION_EDIT;
  sanction: SanctionType;
}

export interface ISuccessSanctionEditAction {
  type: typeof Types.SUCCESS_SANCTION_EDIT;
  sanction: SanctionType;
}

export interface IFailureSanctionEditAction {
  type: typeof Types.FAILURE_SANCTION_EDIT;
  message: string;
}

type SanctionEditAction =
  | IRequestSanctionEditAction
  | ISuccessSanctionEditAction
  | IFailureSanctionEditAction;

//DELETE
export interface IRequestSanctionDeleteAction {
  type: typeof Types.REQUEST_SANCTION_DELETE;
  idSancao: number;
}

export interface ISuccessSanctionDeleteAction {
  type: typeof Types.SUCCESS_SANCTION_DELETE;
  idSancao: number;
}

export interface IFailureSanctionDeleteAction {
  type: typeof Types.FAILURE_SANCTION_DELETE;
  message: string;
}

type SanctionDeleteAction =
  | IRequestSanctionDeleteAction
  | ISuccessSanctionDeleteAction
  | IFailureSanctionDeleteAction;

export type SanctionActionTypes =
  | SanctionsLoadAction
  | SanctionAddAction
  | SanctionEditAction
  | SanctionDeleteAction;
