import styled from 'styled-components';

export const Container = styled.div`
    flex-basis: 100%;
    width: calc(100% - 250px);
    height: calc(100vh - 50px);
    display: flex;
    flex-direction: column;
    overflow: auto;
`;

export const Content = styled.div`
    padding: 20px;
    /* overflow-y: scroll; */
`

export const WrapperForm = styled.div`
    padding: 10px 0 20px;
    box-sizing: border-box;
    width: 100%;
`

export const Table = styled.table`
    box-sizing: border-box;
    margin: 20px 0;
    border-collapse: separate;
    border-spacing: 0 10px;
    overflow: auto;
    display: block;
`

export const Tbody = styled.tbody`

`

export const TdResultAtitudes = styled.td`
    border: 1px solid #CCCCCC;
    height: 60px;
    background-color: #E1DEDE;
    font-size: 12px;
    box-sizing: border-box;
    text-align: left;
    border-radius: 2px;
    max-height: 60px;
    text-align: center;
    `


export const StatusTeam = styled.div<{ bgColor: string }>`
    width: 17px;
    min-width: 17px;
    height: 17px;
    min-height: 17px;
    background-color: ${({ bgColor }) => bgColor};
    border-radius: 50%;
    margin-right: 12px;
`

export const Divider = styled.div`
    margin-top: 5px;
    border-bottom: 1px solid #CFD3DA;
`

export const WrapperResults = styled.div`
    padding-bottom: 20px;
`
