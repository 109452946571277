import { Types, DataTypes, WorklevelActionTypes } from "./types";
import customToast from "~/components/Toast/index";
import { PesquisaFuncType } from "../cycle/types";

/**
 * Reducers
 */
const INITIAL_STATE: DataTypes = {
    data: [],
    error: false,
    loading: false,
    success: false
};

/**
 * Actions
 */

export const Creators = {
    getWorklevel: (): WorklevelActionTypes => {
        return {
            type: Types.REQUEST_WORKLEVEL_LOAD,
        }
    },

    addAvaliadosWorklevel: (avaliados: PesquisaFuncType[]): WorklevelActionTypes => ({
        type: Types.ADD_AVALIADOS_WORKLEVEL,
        payload: avaliados
    }),

    resetAvaliadosWorklevel: (): WorklevelActionTypes => ({
        type: Types.RESET_AVALIADOS_WORKLEVEL,
    })
}



export default function worklevelsReducer(state = INITIAL_STATE, action: WorklevelActionTypes): DataTypes {

    switch (action.type) {
        case Types.REQUEST_WORKLEVEL_LOAD:
            return { ...state, loading: true, error: false, success: false };
        case Types.SUCCESS_WORKLEVEL_LOAD:
            return { ...state, data: action.payload, loading: false, error: false, success: true };
        case Types.FAILURE_WORKLEVEL_LOAD:
            customToast.error(action.message);
            return { ...state, data: [], loading: false, error: true, success: false };

        case Types.ADD_AVALIADOS_WORKLEVEL:
            return {
                ...state, data: state.data.map(worklevel => {
                    return {
                        ...worklevel,
                        avaliados: action.payload.filter(avaliado => avaliado.idWorklevel === worklevel.idWorklevel)
                    }
                })
            }

        case Types.RESET_AVALIADOS_WORKLEVEL:
            return {
                ...state,
                data: state.data.map(worklevel => {
                    return {
                        ...worklevel,
                        avaliados: []
                    }
                })
            }

        default:
            return state;
    }
}