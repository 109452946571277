import { takeEvery, put, call, all } from "redux-saga/effects";
import {
    Types,
    PDICompetenceType,
    IRequestPDICompetenceAddAction,
    ISuccessPDICompetenceLoadAction,
    ISuccessPDICompetenceAddAction,
} from "../ducks/competences/types";
import api from "~/services/api";


const apiCallLoadCompetence = () => {
    return api
        .get(`acoespdi/competencias`)
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            throw err.response;
        });
};

const apiCallAddCompetence = (descCompetencia: string) => {
    return api
        .post(`acoespdi/competencias`, {
            descCompetencia: descCompetencia
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            throw err.response;
        });
};

function* load() {
    try {
        const data: PDICompetenceType[] = yield call(apiCallLoadCompetence);
        yield put({
            type: Types.SUCCESS_PDI_COMPETENCE_LOAD,
            payload: data
        } as ISuccessPDICompetenceLoadAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível carregar as competências do PDI'
        yield put({
            type: Types.FAILURE_PDI_COMPETENCE_LOAD,
            message
        });
    }
}

function* add(action: IRequestPDICompetenceAddAction) {
    try {
        const idCompetencia = yield call(apiCallAddCompetence, action.descCompetencia);
        yield put({
            type: Types.SUCCESS_PDI_COMPETENCE_ADD,
            payload: {
                ...idCompetencia,
                descCompetencia: action.descCompetencia
            }
        } as ISuccessPDICompetenceAddAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível adicionar competência'
        yield put({
            type: Types.FAILURE_PDI_COMPETENCE_ADD,
            message
        });
    }
}


export default function* pdiRoot() {
    yield all([
        yield takeEvery(Types.REQUEST_PDI_COMPETENCE_LOAD, load),
        yield takeEvery(Types.REQUEST_PDI_COMPETENCE_ADD, add),
    ]);
}
