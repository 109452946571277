// #region Imports
import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
 
import moment from 'moment';

//FluentUI
import {
  DetailsListLayoutMode,
  SelectionMode,
  Selection,
  IColumn,
} from 'office-ui-fabric-react/lib/DetailsList';
import { ShimmeredDetailsList } from 'office-ui-fabric-react/lib/ShimmeredDetailsList';
import { CommandBarButton, ICommandBarStyles } from 'office-ui-fabric-react';
import { IDialogContentProps } from 'office-ui-fabric-react/lib/Dialog';

import CustomDialog from "~/components/CustomDialogFluentUI";
import { Page, BreadcrumbItems } from "~/store/ducks/home/types";

import { DeleteButton } from '~/components/Buttons';
import HeaderPage from '~/components/layout/HeaderPage';
import NoItems from '~/components/layout/NoItems';
//Estilos
import {
  Wrapper,
  ListContainer,
  DefaultButton,
  ContainerContent,
} from "./styles";

import { Creators as getMeetings } from "~/store/ducks/meetings";
import { Creators as addMeeting } from "~/store/ducks/meetings";
import { Creators as delMeeting } from "~/store/ducks/meetings";
import { DataTypes as DataTypesMeeting, MeetingType } from "~/store/ducks/meetings/types";

import { Creators as setCurrentPage } from "~/store/ducks/home";
import { DataTypes as DataTypesLogin } from '~/store/ducks/login/types';
import { RootState } from "~/store/ducks";

import DialogMeeting from './DialogMeetings';
//#endregion

const btnStyle: Partial<ICommandBarStyles> = {
  root: {
    height: 44
  }
}

/**
 * Validação do formulário
 */

interface IMeetingState {
  columns: IColumn[];
  items: MeetingType[];
  isDialogAddOpen: boolean;
  isFiltering: boolean;
  selectionDetails: string;
  selectionCount: number;
  isDialogExcludeOpen: boolean;
};

interface IPropsMeetings extends RouteComponentProps {
  meetings: DataTypesMeeting;
  login: DataTypesLogin;
  setCurrentPage: (page: Page) => void;
  getMeetings: () => void;
  addMeeting: (idCicloGrupo: number) => void;
  delMeeting: (idCicloReuniao: number) => void;

  history: RouteComponentProps["history"];
}

const itemsBreadCrumb: BreadcrumbItems[] = [
  { text: "Home", isCurrentItem: false, icon: "HomeSolid", onlyIcon: true, url: "/" },
  { text: "Reuniões", isCurrentItem: true },
];

class Meetings extends Component<IPropsMeetings, IMeetingState> {
  private formRef: any;
  private _selection: Selection;

  constructor(props: IPropsMeetings) {
    super(props);
    //#region Colunas
    const columns: IColumn[] = [
      {
        key: 'column1',
        name: 'ID',
        ariaLabel: 'idCicloReuniao',
        fieldName: 'idCicloReuniao',
        isRowHeader: true,
        minWidth: 50,
        maxWidth: 75,
        isResizable: true,
        isSortedDescending: false,
        onColumnClick: this._onColumnClick
      },
      {
        key: 'column2',
        name: 'Ciclo',
        ariaLabel: 'Ciclo',
        fieldName: 'ciclo',
        isRowHeader: true,
        minWidth: 75,
        maxWidth: 100,
        isResizable: true,
        isSortedDescending: false,
        onColumnClick: this._onColumnClick,
        onRender: (value: MeetingType) => value.ciclo.ano
      },
      {
        key: 'column3',
        name: 'Data',
        fieldName: 'dataHoraInicio',
        minWidth: 75,
        maxWidth: 100,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
        onRender: (value: MeetingType) => moment(value.dataHoraInicio).format('DD/MM/YYYY')
      },
      {
        key: 'column4',
        name: 'Grupo',
        fieldName: 'grupo',
        minWidth: 200,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
        onRender: (value: MeetingType) => value.grupo.nomeGrupo
      },
      {
        key: 'column5',
        name: 'Status',
        fieldName: 'status',
        minWidth: 150,
        maxWidth: 200,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
      }
    ];
    //#endregion

    this.state = {
      columns: columns,
      items: [],
      isDialogAddOpen: false,
      selectionDetails: "",
      isFiltering: false,
      selectionCount: 0,
      isDialogExcludeOpen: false,
    }

    this._selection = new Selection({
      onSelectionChanged: () => {
        this.setState({
          selectionDetails: this._getSelectionDetails(),
        });
      },
    });

    this.formRef = React.createRef();
  };

  componentDidMount() {
    const page: Page = {
      key: 'reunioes',
      pages: itemsBreadCrumb
    };
    this.props.setCurrentPage(page);
    this.props.getMeetings();
  };

  componentDidUpdate(prevProps: IPropsMeetings, _: any) {
    if (prevProps.meetings.successAdd !== this.props.meetings.successAdd) {
      if (this.props.meetings.successAdd) {
        this.setState({ isDialogAddOpen: false })
        this.props.history.push(`/reunioes/${this.props.meetings.idCicloReuniaoCreated}`)
      }
    }

  }

  // Submit do formulário Formik
  handleSubmit = () => {
    if (this.formRef.current) {
      this.formRef.current.handleSubmit();
    };
  };

  _onItemInvoked = (): void => {
    const idCicloReuniao: number = (this._selection.getSelection()[0] as MeetingType).idCicloReuniao!;
    this.props.history.push(`/reunioes/${idCicloReuniao}`)
  };

  _getSelectionDetails(): any {
    const selectionCount = this._selection.getSelectedCount();
    this.setState({ selectionCount });
    this._selection.getSelection();
  };


  cancelPanel = () => {
    this._selection.setAllSelected(false);
    this.setState({ isDialogAddOpen: false });
  };

  excludeMeeting = () => {
    this.props.delMeeting((this._selection.getSelection()[0] as MeetingType).idCicloReuniao!);
    this.setState({ isDialogExcludeOpen: false });
    this._selection.setAllSelected(false);
  };

  //#region  Funções do filtro

  handleSearch = (e: any) => {
    // const meetings = this.props.meetings.data;
    // const text = e.target.value;

    // let items = text
    //   ? meetings.filter(
    //     item =>
    //       item.nomeAtitude!.toLowerCase().indexOf(text.toLowerCase()) > -1
    //   )
    //   : attitudes;

    // this.setState({ items, isFiltering: true, search: text });
  };

  //#endregion

  commandBarRender = () => {
    const { selectionCount } = this.state;
    if (selectionCount === 1) {
      return (
        <>
          <CommandBarButton styles={btnStyle} iconProps={{ iconName: 'Delete' }} text="Excluir" onClick={() => this.setState({ isDialogExcludeOpen: true })} />
        </>
      );
    }
  };

  render() {
    const { isDialogAddOpen, isDialogExcludeOpen, isFiltering, items, columns } = this.state;
    const { getMeetings, addMeeting, meetings, login } = this.props;

    const dialogContentProps: IDialogContentProps = {
      title: 'Excluir?',
      closeButtonAriaLabel: 'Close',
      subText: 'Tem certeza de que deseja excluir esta reunião?'
    };

    return (
      <>
        <Wrapper>
          <ContainerContent>
            <HeaderPage
              title="Calibrações"
              leftItems={
                login.data.flagAdministrador ?
                <>
                  <CommandBarButton
                    styles={btnStyle}
                    iconProps={{ iconName: 'Add' }}
                    disabled={meetings.loadingData}
                    text="Adicionar Reunião"
                    onClick={() => this.setState(
                      {
                        isDialogAddOpen: true,
                      }
                    )}
                  />
                  {this.commandBarRender()}
                </>
                :
                <></>
              }
              rightItems={
                <>
                  <CommandBarButton
                    styles={btnStyle}
                    iconProps={{ iconName: 'Refresh' }}
                    text="Atualizar"
                    onClick={() => getMeetings()}
                  />
                </>
              }
            />
            {!meetings.loadingData && meetings.data?.length === 0 ?
              <NoItems
                error={meetings.error}
                text="Não há reuniões cadastradas"
                icon="ReminderPerson"
              />
              :
              <ListContainer>
                <ShimmeredDetailsList
                  items={isFiltering ? items : meetings.data}
                  enableShimmer={meetings.loadingData}
                  columns={columns}
                  selectionMode={SelectionMode.single}
                  selection={this._selection}
                  getKey={this._getKey}
                  selectionPreservedOnEmptyClick={true}
                  setKey="single"
                  layoutMode={DetailsListLayoutMode.justified}
                  isHeaderVisible={true}
                  onItemInvoked={this._onItemInvoked}
                />
              </ListContainer>
            }
          </ContainerContent>
        </Wrapper>

        <CustomDialog
          hidden={!isDialogExcludeOpen}
          onDismiss={() => this.setState({ isDialogExcludeOpen: false })}
          dialogContentProps={dialogContentProps}
        >
          <DefaultButton onClick={() => this.setState({ isDialogExcludeOpen: false })} text="Cancelar" />
          <DeleteButton onClick={() => this.excludeMeeting()} text="Excluir" />
        </CustomDialog>

        <DialogMeeting
          isOpen={isDialogAddOpen}
          isLoadingAction={meetings.loadingAction}
          onAdd={(values) => addMeeting(values.idCicloGrupo!)}
          onClose={() => this.setState({ isDialogAddOpen: false })}
        />
      </>
    );
  };

  private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    const { columns } = this.state;
    const newColumns: IColumn[] = columns.slice();
    let items: MeetingType[] = [];
    if (this.state.isFiltering) {
      items = this.state.items;
    } else {
      items = this.props.meetings.data;
    };
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      };
    });
    const newItems = this._sort(items, currColumn.fieldName!, currColumn.isSortedDescending);
    this.setState({
      columns: newColumns,
      items: newItems,
    });
  };

  private _getKey(item: any, index?: number): any {
    if (item !== undefined)
      return item.key;
  };

  private _sort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
    const key = columnKey as keyof T;
    const itemsSorted = items.sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
    return itemsSorted;
  };

}

const mapStateToProps = (state: RootState) => ({
  meetings: state.meetingsReducer,
  login: state.loginReducer
});

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  ...getMeetings,
  ...addMeeting,
  ...delMeeting,
  ...setCurrentPage
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Meetings));
