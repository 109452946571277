import styled from 'styled-components';

export const TimerLabel = styled.span`
  position: absolute;
  width: 85px;
  height: 85px;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 20px;
`;

export const BaseTimer = styled.div`
    position: relative;
    width: 85px;
    height: 85px;
`
export const BaseTimerSvg = styled.svg`
    transform: scaleX(-1);
`
export const BaseTimerCircle = styled.g`
    fill: none;
    stroke: none;
`
export const BaseTimerElapsed = styled.circle`
    stroke-width: 7px;
    stroke: rgba(0,0,0,0.1);
`
export const BaseTimerRemaining = styled.path<{ color: string }>`
    stroke-width: 7px;
    stroke-linecap: square;
    transform: rotate(90deg);
    transform-origin: center;
    transition: 1s linear all;
    fill-rule: nonzero;
    stroke: currentColor;
    color: ${({ color }) => color};
`