import styled from "styled-components";

export const Container = styled.div`
    width: calc(100% - 290px);
    height: calc(100vh - 50px);
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
`;

export const Wrapper = styled.div`
  height: calc(100vh - 50px);
  width: calc(100% - 250px);
  flex-basis: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow: auto;
`;

export const ContainerContent = styled.div`
  padding: 20px;
  height: calc(100% - 50px);
`

export const WrapperStatus = styled.div`
  display: flex;
`

export const WrapperCalib = styled.div<{ isOpen: boolean }>`
  width: 500px;
  height: auto;
  position: fixed;
  top: 55px;
  right: 15px;
  z-index: 999;
  background-color: #FFFFFF;
  box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
  padding: 24px;
  border-radius: 2px;
  display: ${({isOpen}) => isOpen ? 'block' : 'none'};
`
