import { takeEvery, put, call, all } from "redux-saga/effects";
import {
  Types,
  ReportMeritType,
  IRequestReportMeritLoadDetalhesAction,
  IRequestReportMeritLoadAction,
  ISuccessReportMeritLoadAction,
  ISuccessReportMeritLoadDetalhesAction,
  ReportMeritDetalhe,
} from "../ducks/reportMerit/types";
import api from "~/services/api";

const apiCallLoad = (payload: IRequestReportMeritLoadAction["payload"]) => {
  return api
    .get(`relatoriosMerito`, {
      params: {
        Ano: payload?.Ano,
      },
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallLoadDetalhe = (
  payload: IRequestReportMeritLoadDetalhesAction["payload"]
) => {
  return api
    .get(`relatoriosMerito/detalhes`, {
      params: {
        Ano: payload?.Ano,
        Decisao: payload?.Decisao,
        Grupos: payload?.Grupos?.join(","),
      },
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

// const apiCallLoadCurve = (
//   payload: IRequestReportCurveLoadAction["payload"]
// ) => {
//   return api
//     .get(`relatorios/curva`, {
//       params: {
//         Ano: payload?.Ano,
//         Reuniao: payload?.Reuniao?.join(","),
//       },
//     })
//     .then((response) => response.data)
//     .catch((err) => {
//       throw err.response;
//     });
// };

function* load(action: IRequestReportMeritLoadAction) {
  try {
    const data: ReportMeritType = yield call(apiCallLoad, action.payload);

    yield put({
      type: Types.SUCCESS_REPORT_MERIT_LOAD,
      payload: data,
    } as ISuccessReportMeritLoadAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível carregar o relatório.";
    yield put({ type: Types.FAILURE_REPORT_MERIT_LOAD, message });
  }
}

function* loadDetalhes(action: IRequestReportMeritLoadDetalhesAction) {
  try {
    const data: ReportMeritDetalhe = yield call(
      apiCallLoadDetalhe,
      action.payload
    );

    yield put({
      type: Types.SUCCESS_REPORT_MERIT_LOAD_DETALHES,
      payload: data,
    } as ISuccessReportMeritLoadDetalhesAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível carregar o relatório.";
    yield put({ type: Types.FAILURE_REPORT_MERIT_LOAD_DETALHES, message });
  }
}

export default function* companiesRoot() {
  yield all([
    yield takeEvery(Types.REQUEST_REPORT_MERIT_LOAD, load),
    yield takeEvery(Types.REQUEST_REPORT_MERIT_LOAD_DETALHES, loadDetalhes),
  ]);
}
