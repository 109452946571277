import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from "yup";
import { Formik } from "formik";

import { InputText, InputAutocomplete } from '~/components/Forms';

import { EmployeeType } from "~/store/ducks/employees/types";
import { CycleType } from "~/store/ducks/cycles/types";
import { Creators as CreatorsObjectives } from "~/store/ducks/objectives";
import { ObjectivesType, DataTypes as DataTypesObjectives } from "~/store/ducks/objectives/types";
import { FormHeaderStyle } from '~/components/FormGroup';

import InputAdornment from '@material-ui/core/InputAdornment';

import { RootState } from "~/store/ducks";

export type InfoCycleType = {
    ano: number | null;
    responsavel: Partial<EmployeeType>;
};

interface IFormInfoProps {
    formRef: any;
    initialValues: Partial<CycleType>;
    success: (values: Partial<CycleType>) => any;
};

const validationSchemaIniciativa = yup.object().shape({
    minimoIniciativas: yup.number().nullable().required('Campo obrigatório'),
    targetIniciativas: yup.number().nullable().required('Campo obrigatório'),
    maximoIniciativas: yup.number().nullable().required('Campo obrigatório'),
    idObjetivoIniciativa: yup.number().nullable().required("Campo obrigatório"),
});

const FormContentIniciativa: React.FC<IFormInfoProps> = (props) => {
    const [objetivo, setObjetivo] = useState<Partial<ObjectivesType>>(props.initialValues.objetivo!);
    const [initialValues, setInitialValues] = useState<Partial<CycleType>>(props.initialValues!);

    const dispatch = useDispatch();
    let timeout: number = 0;

    const objectiveState = useSelector<RootState, DataTypesObjectives>(state => state.objectivesReducer);

    useEffect(() => {
        if (initialValues.idObjetivoIniciativa === null) {
            setInitialValues({
                ...initialValues,
                minimoIniciativas: null,
                maximoIniciativas: null,
                targetIniciativas: null
            })
        }
    }, []);

    const search = (text: string, type: string) => {
        if (text.trim()) {
            clearTimeout(timeout);
            timeout = window.setTimeout(() => {
                switch (type) {
                    case 'objetivos':
                        dispatch(CreatorsObjectives.getObjectives(text, true, true));
                        break;
                }
            }, 700);
        }
    };

    return (
        <>
            <FormHeaderStyle text="Metas para Iniciativas" />
            <Formik
                enableReinitialize={true}
                innerRef={props.formRef}
                initialValues={initialValues}
                validationSchema={validationSchemaIniciativa}
                validateOnChange={false}
                validateOnBlur={true}
                onSubmit={(values: Partial<CycleType>) => {
                    props.success(values);
                }}
            >
                {({ handleSubmit, handleChange, values, errors, setFieldError, setFieldValue }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <div className="ms-Grid" dir="ltr">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 paddingZero" style={{ height: 70 }}>
                                        <InputAutocomplete
                                            value={(objetivo as ObjectivesType)}
                                            onChange={(_, newValue) => {
                                                setObjetivo(newValue!)
                                                setFieldValue("idObjetivoIniciativa", newValue?.idObjetivo);
                                            }}
                                            onInputChange={(_, newInputValue) => {
                                                setFieldError("idObjetivoIniciativa", "");
                                                search(newInputValue, "objetivos");
                                            }}
                                            getOptionLabel={(obj: ObjectivesType) => obj.descObjetivo}
                                            getOptionSelected={(option, value) => option.idObjetivo === value.idObjetivo}
                                            id="idObjetivoIniciativa"
                                            noOptionsText="Não há opções"
                                            options={objectiveState.data}
                                            input={{
                                                idInput: "idObjetivoIniciativa",
                                                labelInput: "Objetivo da iniciativa",
                                                helperTextInput: errors.idObjetivoIniciativa,
                                                errorInput: errors.idObjetivoIniciativa ? true : false,
                                                autoFocus: true
                                            }}
                                        />
                                    </div>
                                    <div className="ms-Grid-col ms-sm12" style={{ paddingLeft: 0, paddingRight: 8 }}>
                                        <InputText
                                            value={values.minimoIniciativas !== null ? values.minimoIniciativas : ""}
                                            onChange={e => { handleChange(e); setFieldError('minimoIniciativas', '') }}
                                            id="minimoIniciativas"
                                            error={errors.minimoIniciativas ? true : false}
                                            name="minimoIniciativas"
                                            type="number"
                                            label="Mínimo"
                                            helperText={errors.minimoIniciativas}
                                            className="mt-2"
                                            InputProps={{
                                                endAdornment: <InputAdornment
                                                    position="end"
                                                    style={{ paddingRight: 20 }}>
                                                    %
                                                </InputAdornment>,
                                                autoComplete: "off"
                                            }}
                                        />
                                    </div>
                                    <div className="ms-Grid-col ms-sm12" style={{ paddingLeft: 0, paddingRight: 8 }}>
                                        <InputText
                                            value={values.targetIniciativas !== null ? values.targetIniciativas : ""}
                                            onChange={e => { handleChange(e); setFieldError('targetIniciativas', '') }}
                                            id="targetIniciativas"
                                            error={errors.targetIniciativas ? true : false}
                                            name="targetIniciativas"
                                            type="number"
                                            label="Target"
                                            helperText={errors.targetIniciativas}
                                            className="mt-2"
                                            InputProps={{
                                                endAdornment: <InputAdornment
                                                    position="end"
                                                    style={{ paddingRight: 20 }}>
                                                    %
                                                </InputAdornment>,
                                                autoComplete: "off"
                                            }}
                                        />
                                    </div>
                                    <div className="ms-Grid-col ms-sm12" style={{ paddingLeft: 0, paddingRight: 8 }}>
                                        <InputText
                                            value={values.maximoIniciativas !== null ? values.maximoIniciativas : ""}
                                            onChange={e => { handleChange(e); setFieldError('maximoIniciativas', '') }}
                                            id="maximoIniciativas"
                                            error={errors.maximoIniciativas ? true : false}
                                            name="maximoIniciativas"
                                            type="number"
                                            label="Máximo"
                                            helperText={errors.maximoIniciativas}
                                            className="mt-2"
                                            InputProps={{
                                                endAdornment: <InputAdornment
                                                    position="end"
                                                    style={{ paddingRight: 20 }}>
                                                    %
                                                </InputAdornment>,
                                                autoComplete: "off"
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    )
                }}
            </Formik>
        </>
    );
};

export default FormContentIniciativa;