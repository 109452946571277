import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import { useHistory, useParams } from "react-router-dom";
import * as yup from "yup";

import PainelHeader from "~/components/layout/PainelHeader";

import { Container, Content, Divider } from "./styles";
import { convertStatusTarefa } from "~/utils/tasks";
import { ICommandBarStyles, Stack } from "@fluentui/react";
import { CommandBarButton } from "~/components/Buttons";
import Accordion from "~/components/Accordion";
import { LabelGroup } from "~/components/label";
import { InputText } from "~/components/Forms";

import { RootState } from "~/store/ducks";
import { Creators as CreatorsHome } from "~/store/ducks/home";
import { BreadcrumbItems, Page } from "~/store/ducks/home/types";
import { Creators } from "~/store/ducks/tasks";
import { DataTypes as DataTypesTask } from "~/store/ducks/tasks/types";
import {
  AreaAvaliationMap,
  RenderResultados,
} from "../SelfEvaluation/selfEvaluationRender";

interface IHalfYearAvaliationProps {
  taskState: DataTypesTask;
}

const HalfYearAvaliation: React.FC<IHalfYearAvaliationProps> = (props) => {
  const { taskState } = props;
  const { selected: task } = taskState;
  const isMeioAnoFunc = task?.tipoTarefa === "MF";

  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams() as any;
  const { idTarefa } = params;

  const {
    handleSubmit,
    handleChange,
    setFieldError,
    errors,
    values,
  } = useFormik({
    initialValues: { comentario: task?.TarefaMeioAno?.comentarios ?? "" },
    validationSchema: validationSchema,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit(values) {
      dispatch(
        Creators.finishTask(idTarefa, { comentarios: values.comentario })
      );
    },
  });

  useEffect(() => {
    dispatch(CreatorsHome.setCurrentPage(page));
  }, []);

  useEffect(() => {
    if (taskState.redirectTo) {
      history.push("/workflow");
    }
  }, [taskState.redirectTo]);

  return (
    <Container>
      <PainelHeader
        title={task?.tituloTarefa ?? ""}
        icon={{ icon: "Feedback" }}
        labelGroups={[
          {
            label: "Funcionário",
            value: task?.funcionario.nomeCompleto ?? "",
            icon: "HomeGroup",
            size: "full",
          },
          {
            label: "Status",
            value: convertStatusTarefa(task?.statusTarefa ?? ""),
            icon: "Flag",
            size: "full",
          },
        ]}
      />
      <Content>
        {task?.flagEdicao && (
          <Stack horizontal>
            <CommandBarButton
              iconProps={{ iconName: "Send" }}
              isLoading={taskState.loadingAction}
              onClick={() => {
                handleSubmit();
              }}
              styles={commandButtonStyle}
            >
              Enviar
            </CommandBarButton>
          </Stack>
        )}
        <RenderResultados
          areas={
            task?.TarefaMeioAno?.areas.map(
              (item) =>
                ({
                  ciclocolaborador: {
                    cargo: item.cargo,
                    unidade: item.unidade,
                    cc: item.cc,
                  },
                  meses: item.meses,
                  descPeriodo: item.descPeriodo,
                  iniciativas: item.projetos.map((proj) => ({
                    descProjeto: proj.descProjeto,
                    nomeProjeto: proj.nomeProjeto,
                    peso: proj.peso,
                    prazoEntrega: proj.prazoEntrega,
                  })),
                  resultados: item.metas.map((result) => {
                    const meta = result.meta[0] ?? {};
                    return {
                      colaboradormeta: {
                        cicloPeso: {
                          objetivo: result.cicloPeso.objetivo,
                        },
                        flagIniciativas: result.flagIniciativas,
                      },
                      meta: {
                        minimo: meta.minimo,
                        target: meta.target,
                        maximo: meta.maximo,
                      },
                      pilar: result.pilar,
                      real: meta.resultado ? Number(meta.resultado) : null,
                      resultado: meta.avaliacao,
                      ating: meta.ating,
                      peso: result.cicloPeso.peso,
                      cicloPesoPai: {
                        objetivo: {
                          descObjetivo:
                            result.cicloPeso.cicloPesoPai?.objetivo
                              .descObjetivo,
                        },
                      },
                    };
                  }),
                } as AreaAvaliationMap)
            ) ?? []
          }
          comentariosGerais=""
          flagAutoAvaliacao={false}
          habilitaAvaliacaoGestor={false}
          tipoAvaliacao=""
          hiddenResults
        />
        {/* <Accordion title="Informações Cadastrais">
          <WrapperLabelGroup>
            <LabelGroup
              icon="Work"
              label="Cargo"
              value="Analista de Sistemas"
              tooltipMessage="Analista de Sistemas"
            />
            <LabelGroup
              icon="EMI"
              label="Unidade"
              value="Desenvolvimento Organizacional"
              tooltipMessage="Desenvolvimento Organizacional"
            />
            <LabelGroup
              icon="MapPin"
              label="Centro de Custo"
              value="Gente & Gestão"
              tooltipMessage="Desenvolvimento Organizacional"
            />
            <LabelGroup
              icon="Calendar"
              label="Período"
              value="01/01 a 31/12"
              size="half"
            />
            <LabelGroup
              icon="Calendar"
              label="Tempo na Função"
              value="3 meses"
              size="half"
            />
          </WrapperLabelGroup>
        </Accordion> */}
        {isMeioAnoFunc && (
          <Accordion title="Comentários do Gestor">
            <InputText
              value={task?.feedbackGestor?.comentarios ?? ""}
              type="text"
              label="Comentários"
              height_container="auto"
              className="mt-2"
              multiline
              rows={5}
              rowsMax={15}
              disabled
            />
          </Accordion>
        )}
        <Accordion title="Comentários">
          <InputText
            value={values.comentario ?? ""}
            onChange={(e) => {
              handleChange(e);
              setFieldError("comentario", "");
            }}
            id="comentario"
            error={errors.comentario ? true : false}
            name="comentario"
            type="text"
            label="Insira aqui o acompanhamento do meio de ano"
            helperText={errors.comentario ?? ""}
            height_container="auto"
            disabled={!task?.flagEdicao}
            className="mt-2"
            multiline
            rows={5}
            rowsMax={15}
          />
        </Accordion>
        {task?.flagEdicao && (
          <>
            <Divider />
            <Stack horizontal>
              <CommandBarButton
                iconProps={{ iconName: "Send" }}
                isLoading={taskState.loadingAction}
                onClick={() => {
                  handleSubmit();
                }}
                styles={commandButtonStyle}
              >
                Enviar
              </CommandBarButton>
            </Stack>
          </>
        )}
      </Content>
    </Container>
  );
};

const commandButtonStyle: Partial<ICommandBarStyles> = {
  root: {
    height: 44,
  },
};

/**
 * Validação do formulário
 */
const validationSchema = yup.object().shape({
  comentario: yup
    .string()
    .min(2, "O comentário deve conter pelo menos 2 caracteres")
    .max(100, "O comentário não pode ter mais do que 100 caracteres")
    .required("Campo obrigatório"),
});

const itemsBreadCrumb: BreadcrumbItems[] = [
  {
    text: "Home",
    isCurrentItem: false,
    icon: "HomeSolid",
    onlyIcon: true,
    url: "/",
  },
  { text: "Workflow", isCurrentItem: false, url: "/workflow" },
  { text: "Acompanhamento Meio de Ano", isCurrentItem: true },
];

const page: Page = {
  key: "workflow",
  pages: itemsBreadCrumb,
};

export default HalfYearAvaliation;
