//#region  Imports
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation, useHistory } from "react-router-dom";
import { Persona, PersonaSize } from "office-ui-fabric-react/lib/Persona";
import {
  CommandBarButton,
  IButtonStyles,
} from "office-ui-fabric-react/lib/Button";
import {
  SearchBox,
  ISearchBoxStyles,
} from "office-ui-fabric-react/lib/SearchBox";
import { Text, ITextStyles } from "office-ui-fabric-react/lib/Text";
import { mergeStyles } from "office-ui-fabric-react/lib/Styling";

import {
  Container,
  Left,
  Right,
  StMarcheLogo,
  BtnMenu,
  WrapperBreadcrumbs,
  LeftContainer,
  WrapperSearchBox,
} from "./styles";

import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";

import { DataTypes } from "~/store/ducks/home/types";
import { LoginType } from "~/store/ducks/login/types";
import { RootState } from "~/store/ducks";

import colors from "~/assets/js/colors";

import { FontIcon } from "office-ui-fabric-react/lib/Icon";

import { BreadcrumbItems } from "~/store/ducks/home/types";
import { Creators } from "~/store/ducks/employees";
import { Creators as CreatorsHome } from "~/store/ducks/home";
import { DataTypes as DataTypesMenu } from "~/store/ducks/home/types";
import Colors from "~/assets/js/colors";
//#endregion
//#region  Styles
const SearchStyles: Partial<ISearchBoxStyles> = {
  root: {
    width: 315,
    // selectors: {
    //     "@media screen and (max-width: 960px)": {
    //         display: 'none'
    //     }
    // }
  },
};

const currentItem: Partial<ITextStyles> = {
  root: {
    color: "#FFFFFF",
    fontWeight: 500,
  },
};

const breadcrumbItem: Partial<ITextStyles> = {
  root: {
    color: "#FFFFFF",
    opacity: 0.8,
    selectors: {
      "&:hover": {
        color: colors.primary,
        opacity: 1,
      },
    },
  },
};

const iconClass = mergeStyles({
  color: colors.white,
  opacity: 0.8,
  selectors: {
    "&:hover": {
      color: colors.primary,
      opacity: 1,
    },
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      display: "flex",
      color: "#FFFFFF",
      textDecoration: "none",
      "&:hover": {
        textDecoration: "none",
      },
    },
    icon: {
      marginRight: theme.spacing(0.5),
      width: 20,
      height: 20,
      color: "#FFFFFF",
    },
  })
);
//#endregion

interface IProps {
  breadcrumb: BreadcrumbItems[];
  onRinger?: () => any;
  onConfig?: () => any;
  onHelp?: () => any;
  onUser?: () => any;
  closeDrawer?: boolean;
}
const initialState = {
  isRingerClicked: false,
  isConfigClicked: false,
  isHelpClicked: false,
  isUserClicked: false,
};

const Header: React.FC<IProps> = (props) => {
  const location = useLocation();
  const [state, setState] = useState(initialState);
  const [searchEmployeeText, setSearchEmployeeText] = useState("");
  const [openSearch, setOpenSearch] = useState(false);
  const [isAdminPage, setUsAdminPage] = useState(
    location.pathname.includes("admin")
  );

  const pageState = useSelector<RootState, DataTypes>(
    (state) => state.homeReducer
  );
  const {
    abbreviation,
    name,
    imagem,
    email,
    flagAdministrador,
    flagRH,
  } = useSelector<RootState, LoginType>((state) => state.loginReducer.data);
  const { isMenuExpanded } = useSelector<RootState, DataTypesMenu>(
    (state) => state.homeReducer
  );
  const items: BreadcrumbItems[] = pageState.page.pages;
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!props.closeDrawer) {
      setState(initialState);
    }
  }, [props.closeDrawer]);

  useEffect(() => {
    if (location.pathname === "/funcionarios" && searchEmployeeText !== "") {
      dispatch(Creators.getEmployees(searchEmployeeText));
      setSearchEmployeeText("");
    }
    setUsAdminPage(location.pathname.includes("admin"));
  }, [location]);

  //#region  Styles CommandBar Btns
  const commandBtnRingerStyles: IButtonStyles = {
    root: {
      backgroundColor: !state.isRingerClicked ? "#1D1D1D" : "#E9EBEE",
      height: 50,
      selectors: {
        "@media screen and (min-width: 961px)": {
          marginLeft: 15,
        },
      },
    },
    icon: {
      color: !state.isRingerClicked ? "#FFFFFF" : colors.primary,
    },
    flexContainer: {
      position: "relative",
    },
  };

  const commandBtnConfigStyles: IButtonStyles = {
    root: {
      backgroundColor: !state.isConfigClicked ? "#1D1D1D" : "#E9EBEE",
      height: 50,
    },
    icon: {
      color: !state.isConfigClicked ? "#FFFFFF" : colors.primary,
    },
    flexContainer: {
      position: "relative",
    },
  };

  const commandBtnHelpStyles: IButtonStyles = {
    root: {
      backgroundColor: !state.isHelpClicked ? "#1D1D1D" : "#E9EBEE",
      height: 50,
    },
    icon: {
      color: !state.isHelpClicked ? "#FFFFFF" : colors.primary,
    },
    flexContainer: {
      position: "relative",
    },
  };

  const commandBtnUserStyles: IButtonStyles = {
    root: {
      backgroundColor: !state.isUserClicked ? "#1D1D1D" : "#E9EBEE",
      height: 50,
      width: 50,
    },
    icon: {
      color: !state.isUserClicked ? "#FFFFFF" : colors.primary,
    },
    flexContainer: {
      position: "relative",
    },
  };

  const commandBtnSearchStyles: IButtonStyles = {
    root: {
      backgroundColor: "#1D1D1D",
      height: 50,
      selectors: {
        "@media screen and (min-width: 961px)": {
          display: "none",
        },
      },
    },
    icon: {
      color: "#FFFFFF",
    },
    flexContainer: {
      position: "relative",
    },
  };
  //#endregion
  //#region Functions CommandBar Btns
  const handleRingerClick = () => {
    props.onRinger!();
    setState({
      isRingerClicked: !state.isRingerClicked,
      isHelpClicked: false,
      isConfigClicked: false,
      isUserClicked: false,
    });
  };

  const handleConfigClick = () => {
    props.onConfig!();
    setState({
      isRingerClicked: false,
      isHelpClicked: false,
      isConfigClicked: !state.isConfigClicked,
      isUserClicked: false,
    });
  };

  const handleHelpClick = () => {
    props.onHelp!();
    setState({
      isRingerClicked: false,
      isHelpClicked: !state.isHelpClicked,
      isConfigClicked: false,
      isUserClicked: false,
    });
  };

  const handleUserClick = () => {
    props.onUser!();
    setState({
      isRingerClicked: false,
      isHelpClicked: false,
      isConfigClicked: false,
      isUserClicked: !state.isUserClicked,
    });
  };

  const handleSearchClick = () => {
    setOpenSearch(true);
  };

  const handleCloseSearchClick = () => {
    setOpenSearch(false);
  };
  //#endregion

  const handleSearch = (text: string) => {
    history.push({
      pathname: "/funcionarios",
      state: {
        employeeName: text,
      },
    });

    if (location.pathname === "/funcionarios") {
      setSearchEmployeeText(text);
    } else {
      setSearchEmployeeText("");
    }
  };

  const renderBreadcrumbs = () => {
    if (items) {
      return items.map((item, i) => {
        if (item.isCurrentItem) {
          return (
            <Text key={i} variant="medium" styles={currentItem}>
              {item.text}
            </Text>
          );
        } else {
          if (item.onlyIcon) {
            return (
              <Link to={item.url!} key={i} className={classes.link}>
                <FontIcon iconName={item.icon} className={iconClass} />
              </Link>
            );
          } else {
            return (
              <Link to={item.url!} key={i} className={classes.link}>
                {item.icon ? (
                  <FontIcon iconName={item.icon} className={iconClass} />
                ) : null}
                <Text variant="medium" styles={breadcrumbItem}>
                  {item.text}
                </Text>
              </Link>
            );
          }
        }
      });
    }
  };

  const handleMenuClick = () => {
    dispatch(CreatorsHome.setMenuExpanded(!isMenuExpanded));
  };

  return (
    <Container>
      <LeftContainer>
        <BtnMenu
          onClick={handleMenuClick}
          bg={isAdminPage ? Colors.black : Colors.primary}
        >
          <FontIcon
            iconName="GlobalNavButton"
            style={{ color: "#FFFFFF", fontSize: 16 }}
          />
        </BtnMenu>
        <Left
          isExpanded={isMenuExpanded}
          bg={isAdminPage ? Colors.black : Colors.primary}
        >
          <StMarcheLogo src="/static/img/st-marche-white.png" />
        </Left>
        <WrapperBreadcrumbs>
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={
              <FontIcon
                iconName="ChevronRightMed"
                style={{ color: "#FFFFFF", opacity: 0.8, fontSize: 12 }}
              />
            }
            style={{ marginLeft: 20 }}
          >
            {renderBreadcrumbs()}
          </Breadcrumbs>
        </WrapperBreadcrumbs>
      </LeftContainer>
      <Right>
        {flagAdministrador && (
          <WrapperSearchBox show={openSearch}>
            <SearchBox
              placeholder="Pesquisar Funcionário"
              value={searchEmployeeText}
              onSearch={(newValue) => handleSearch(newValue)}
              styles={SearchStyles}
            />
          </WrapperSearchBox>
        )}
        {openSearch ? (
          <CommandBarButton
            styles={commandBtnSearchStyles}
            iconProps={{ iconName: "Cancel" }}
            onClick={handleCloseSearchClick}
            title="Fechar pesquisa de funcionário"
          />
        ) : (
          <CommandBarButton
            styles={commandBtnSearchStyles}
            iconProps={{ iconName: "Search" }}
            onClick={handleSearchClick}
            title="Abrir pesquisa de funcionário"
          />
        )}

        {/* <CommandBarButton styles={commandBtnRingerStyles} iconProps={{ iconName: 'Ringer' }} onClick={handleRingerClick} title="Notificações">
                    <Badge>
                        2
                    </Badge>
                </CommandBarButton>
            <CommandBarButton styles={commandBtnHelpStyles} iconProps={{ iconName: 'Help' }} onClick={handleHelpClick} title="Ajuda" /> */}
        {(flagAdministrador || flagRH) && (
          <CommandBarButton
            styles={commandBtnConfigStyles}
            iconProps={{ iconName: "Settings" }}
            onClick={() => history.push("/admin")}
            title="Configurações"
          />
        )}
        <CommandBarButton
          styles={commandBtnUserStyles}
          onClick={handleUserClick}
          title="Minha conta"
        >
          <Persona
            imageUrl={imagem ?? ""}
            imageInitials={abbreviation}
            initialsColor={colors.primary}
            hidePersonaDetails
            size={PersonaSize.size32}
            imageAlt={name}
          />
        </CommandBarButton>
      </Right>
    </Container>
  );
};

export default Header;
