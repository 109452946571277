import { takeLatest, put, call, all } from "redux-saga/effects";
import {
  Types,
  IRequestFilesLoadAction,
  ISuccessFilesLoadAction,
} from "../ducks/files/types";
import api from "~/services/api";
import fileDownload from "js-file-download";

const apiCallDownload = (key: string) => {
  return api
    .get(`files/${key}`, {
      responseType: "blob",
    })
    .then((response) => {
      const filename = response.headers["content-disposition"].split("=")[1];
      fileDownload(response.data, filename);
    })
    .catch((err) => {
      throw err.response;
    });
};

function* download(action: IRequestFilesLoadAction) {
  try {
    yield call(apiCallDownload, action.key);
    yield put({
      type: Types.SUCCESS_FILES_LOAD,
    } as ISuccessFilesLoadAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível fazer o download do arquivo";
    yield put({
      type: Types.FAILURE_FILES_LOAD,
      message,
    });
  }
}

export default function* stepRoot() {
  yield all([yield takeLatest(Types.REQUEST_FILES_LOAD, download)]);
}
