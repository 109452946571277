import { Types, DataTypes, SanctionActionTypes, SanctionType } from "./types";
import customToast from "~/components/Toast/index";

/**
 * Reducers
 */
const INITIAL_STATE: DataTypes = {
  data: [],
  loading: false,
  loadingAction: false,
  error: false,
  success: false,
};

/**
 * Actions
 */

export const Creators = {
  getSanctions: (): SanctionActionTypes => {
    return {
      type: Types.REQUEST_SANCTIONS_LOAD,
    };
  },

  addSanction: (sanction: SanctionType): SanctionActionTypes => ({
    type: Types.REQUEST_SANCTION_ADD,
    sanction,
  }),

  editSanction: (sanction: SanctionType): SanctionActionTypes => ({
    type: Types.REQUEST_SANCTION_EDIT,
    sanction,
  }),

  delSanction: (idSancao: number): SanctionActionTypes => ({
    type: Types.REQUEST_SANCTION_DELETE,
    idSancao,
  }),
};

export default function sanctionReducer(
  state = INITIAL_STATE,
  action: SanctionActionTypes
): DataTypes {
  switch (action.type) {
    case Types.REQUEST_SANCTIONS_LOAD:
      return {
        ...state,
        loading: true,
        error: false,
        success: false,
      };
    case Types.SUCCESS_SANCTIONS_LOAD:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        success: true,
      };
    case Types.FAILURE_SANCTIONS_LOAD:
      customToast.error(action.message);
      return {
        ...state,
        data: [],
        loading: false,
        loadingAction: false,
        error: true,
        success: false,
      };
    case Types.REQUEST_SANCTION_ADD:
      return {
        ...state,
        error: false,
        success: false,
        loadingAction: true,
      };
    case Types.SUCCESS_SANCTION_ADD:
      customToast.success("Sanção cadastrada com sucesso");
      return {
        ...state,
        error: false,
        success: true,
        data: [...state.data, action.sanction],
        loadingAction: false,
      };
    case Types.FAILURE_SANCTION_ADD:
      customToast.error(action.message);
      return {
        ...state,
        error: true,
        success: false,
        loadingAction: false,
      };

    case Types.REQUEST_SANCTION_EDIT:
      return {
        ...state,
        error: false,
        success: false,
        loadingAction: true,
      };
    case Types.SUCCESS_SANCTION_EDIT:
      customToast.success("Sanção editada com sucesso");
      return {
        ...state,
        error: false,
        success: true,
        loadingAction: false,
        data: state.data.map((item) => {
          if (item.idSancao !== action.sanction.idSancao) {
            return item;
          }
          return action.sanction;
        }),
      };
    case Types.FAILURE_SANCTION_EDIT:
      customToast.error(action.message);
      return {
        ...state,
        error: true,
        success: false,
        loadingAction: false,
      };

    case Types.REQUEST_SANCTION_DELETE:
      return {
        ...state,
        error: false,
        success: false,
        loading: true,
      };
    case Types.SUCCESS_SANCTION_DELETE:
      customToast.success("Item excluído com sucesso");
      return {
        ...state,
        error: false,
        success: true,
        data: state.data.filter((item) => item.idSancao !== action.idSancao),
        loading: false,
      };
    case Types.FAILURE_SANCTION_DELETE:
      customToast.error(action.message);
      return {
        ...state,
        error: true,
        success: false,
        loading: false,
      };

    default:
      return state;
  }
}
