// #region Imports
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Formik } from "formik";
import * as yup from "yup";

//FluentUI
import { Stack } from "office-ui-fabric-react/lib/Stack";
import {
  DetailsListLayoutMode,
  SelectionMode,
  Selection,
  IColumn,
} from "office-ui-fabric-react/lib/DetailsList";
import { ShimmeredDetailsList } from "office-ui-fabric-react/lib/ShimmeredDetailsList";
import { CommandBarButton, ICommandBarStyles } from "office-ui-fabric-react";
import { IDialogContentProps } from "office-ui-fabric-react/lib/Dialog";
//MaterialUI
//Componentes
import Panel from "~/components/layout/Panel";
import Status from "~/components/Status";
import CustomDialog from "~/components/CustomDialogFluentUI";
import { InputText, InputCheckbox } from "~/components/Forms";
import HeaderPage from "~/components/layout/HeaderPage";
import NoItems from "~/components/layout/NoItems";
import { DeleteButton } from "~/components/Buttons";
import { Page, BreadcrumbItems } from "~/store/ducks/home/types";
//Estilos
import {
  Wrapper,
  ListContainer,
  DefaultButton,
  ContainerContent,
} from "./styles";

import { PrimaryButton } from "~/components/Buttons";
import { Creators as getSanctions } from "~/store/ducks/admin/sanctions";
import { Creators as addSanction } from "~/store/ducks/admin/sanctions";
import { Creators as delSanction } from "~/store/ducks/admin/sanctions";
import { Creators as editSanction } from "~/store/ducks/admin/sanctions";
import { Creators as setCurrentPage } from "~/store/ducks/home";
import { SanctionType, DataTypes } from "~/store/ducks/admin/sanctions/types";
import { RootState } from "~/store/ducks";
//#endregion

const btnStyle: Partial<ICommandBarStyles> = {
  root: {
    height: 44,
  },
};

/**
 * Validação do formulário
 */
const validationSanctionSchema = yup.object().shape({
  descSancao: yup.string().required("Campo obrigatório"),
  flagRecolherAssinatura: yup.bool(),
  flagJustaCausa: yup.bool(),
  flagAtiva: yup.bool(),
});

interface ISanctionsState {
  columns: IColumn[];
  items: SanctionType[];
  isPanelOpen: boolean;
  inicialValues: SanctionType;
  isFiltering: boolean;
  selectionDetails: string;
  selectionCount: number;
  isDialogExcludeOpen: boolean;
}

interface IPropsSanctions {
  sanctions: DataTypes;
  setCurrentPage: (page: Page) => void;
  getSanctions: () => void;
  addSanction: (sanction: SanctionType) => void;
  editSanction: (sanction: SanctionType) => void;
  delSanction: (id: number) => void;
}

const itemsBreadCrumb: BreadcrumbItems[] = [
  {
    text: "Home",
    isCurrentItem: false,
    icon: "HomeSolid",
    onlyIcon: true,
    url: "/",
  },
  { text: "Sanções", isCurrentItem: true },
];

const initialValue: SanctionType = {
  idSancao: null,
  descSancao: "",
  flagRecolherAssinatura: false,
  flagJustaCausa: false,
  flagAtivo: true,
};

class Sanctions extends Component<IPropsSanctions, ISanctionsState> {
  private formRef: any;
  private _selection: Selection;

  constructor(props: IPropsSanctions) {
    super(props);
    //#region Colunas
    const columns: IColumn[] = [
      {
        key: "column1",
        name: "",
        ariaLabel: "Status da Sanção",
        fieldName: "flagAtivo",
        minWidth: 15,
        maxWidth: 20,
        isResizable: true,
        isSortedDescending: false,
        onRender: (item: SanctionType) => <Status status={item.flagAtivo} />,
      },
      {
        key: "column2",
        name: "ID",
        ariaLabel: "Id da Sanção",
        fieldName: "idSancao",
        isRowHeader: true,
        minWidth: 75,
        maxWidth: 100,
        isResizable: true,
        isSortedDescending: false,
        onColumnClick: this._onColumnClick,
      },
      {
        key: "column3",
        name: "Descrição",
        fieldName: "descSancao",
        minWidth: 210,
        maxWidth: 350,
        isRowHeader: true,
        isResizable: true,
        isSorted: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "string",
        isPadded: true,
      },
    ];
    //#endregion

    this.state = {
      columns: columns,
      items: [],
      isPanelOpen: false,
      selectionDetails: "",
      inicialValues: initialValue,
      isFiltering: false,
      selectionCount: 0,
      isDialogExcludeOpen: false,
    };

    this._selection = new Selection({
      onSelectionChanged: () => {
        this.setState({
          selectionDetails: this._getSelectionDetails(),
        });
      },
    });

    this.formRef = React.createRef();
  }

  componentDidMount() {
    const page: Page = {
      key: "sancoes",
      pages: itemsBreadCrumb,
    };
    this.props.setCurrentPage(page);
    this.props.getSanctions();
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (this.state.isPanelOpen) {
      if (prevProps.sanctions.success !== this.props.sanctions.success) {
        if (this.props.sanctions.success) {
          this.setState({ isPanelOpen: false });
          this._selection.setAllSelected(false);
        }
      }
    }
  }

  // Submit do formulário Formik
  handleSubmit = () => {
    if (this.formRef.current) {
      this.formRef.current.handleSubmit();
    }
  };

  _onItemInvoked = (item: SanctionType): void => {
    this.setState({
      isPanelOpen: true,
      inicialValues: {
        ...item,
      },
    });
  };

  _getSelectionDetails(): any {
    const selectionCount = this._selection.getSelectedCount();
    this.setState({ selectionCount });
    this._selection.getSelection();
  }

  openEdit = () => {
    const selectItem: SanctionType = this._selection.getSelection()[0] as SanctionType;
    this.setState({ isPanelOpen: true, inicialValues: selectItem });
  };

  cancelPanel = () => {
    this._selection.setAllSelected(false);
    this.setState({ isPanelOpen: false });
  };

  excludeSanction = () => {
    this.props.delSanction(
      (this._selection.getSelection()[0] as SanctionType).idSancao!
    );
    this.setState({ isDialogExcludeOpen: false });
    this._selection.setAllSelected(false);
  };

  //#endregion

  commandBarRender = () => {
    const { selectionCount } = this.state;
    if (selectionCount === 1) {
      return (
        <>
          <CommandBarButton
            styles={btnStyle}
            iconProps={{ iconName: "Edit" }}
            text="Editar"
            onClick={() => this.openEdit()}
          />
          <CommandBarButton
            styles={btnStyle}
            iconProps={{ iconName: "Delete" }}
            text="Excluir"
            onClick={() => this.setState({ isDialogExcludeOpen: true })}
          />
        </>
      );
    } else if (selectionCount > 1) {
      return (
        <CommandBarButton
          styles={btnStyle}
          iconProps={{ iconName: "Delete" }}
          text="Excluir"
          onClick={() => this.setState({ isDialogExcludeOpen: true })}
        />
      );
    }
  };

  render() {
    const {
      isPanelOpen,
      isDialogExcludeOpen,
      selectionCount,
      inicialValues,
      isFiltering,
      items,
      columns,
    } = this.state;
    const { sanctions, addSanction, editSanction, getSanctions } = this.props;

    const dialogContentProps: IDialogContentProps = {
      title: "Excluir?",
      closeButtonAriaLabel: "Close",
      subText:
        selectionCount <= 1
          ? "Tem certeza de que deseja excluir este item?"
          : "Tem certeza de que deseja excluir estes items?",
    };

    return (
      <>
        <Panel
          title={
            !inicialValues.idSancao ? "Nova sanção" : inicialValues.descSancao
          }
          open={isPanelOpen}
          onClose={() => this.cancelPanel()}
          footer={
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <DefaultButton onClick={() => this.cancelPanel()}>
                Cancelar
              </DefaultButton>
              <PrimaryButton
                onClick={this.handleSubmit}
                isLoading={sanctions.loadingAction}
                text="Salvar"
              />
            </Stack>
          }
        >
          <Formik
            innerRef={this.formRef}
            initialValues={{
              ...inicialValues,
              flagAtivo: !inicialValues.flagAtivo,
            }}
            validationSchema={validationSanctionSchema}
            validateOnChange={false}
            validateOnBlur={true}
            onSubmit={(values: SanctionType) => {
              if (inicialValues.idSancao === null) {
                addSanction(values);
              } else {
                values.idSancao = inicialValues.idSancao;
                editSanction({ ...values, flagAtivo: !values.flagAtivo });
              }
            }}
          >
            {({
              handleSubmit,
              handleChange,
              values,
              errors,
              setFieldError,
            }) => (
              <form onSubmit={handleSubmit}>
                <InputText
                  value={values.descSancao}
                  onChange={(e) => {
                    handleChange(e);
                    setFieldError("descSancao", "");
                  }}
                  id="descSancao"
                  error={errors.descSancao ? true : false}
                  name="descSancao"
                  type="text"
                  label="Descrição"
                  helperText={errors.descSancao}
                  className="mt-2"
                  autoFocus
                />
                <Stack>
                  <InputCheckbox
                    checked={values.flagRecolherAssinatura}
                    onChange={handleChange}
                    name="flagRecolherAssinatura"
                    color="primary"
                    label="Recolher Assinatura"
                  />
                  <InputCheckbox
                    checked={values.flagJustaCausa}
                    onChange={handleChange}
                    name="flagJustaCausa"
                    color="primary"
                    label="Justa Causa"
                  />
                  {inicialValues.idSancao && (
                    <InputCheckbox
                      checked={values.flagAtivo}
                      onChange={handleChange}
                      name="flagAtivo"
                      color="primary"
                      label="Inativar Sanção"
                    />
                  )}
                </Stack>
              </form>
            )}
          </Formik>
        </Panel>

        <CustomDialog
          hidden={!isDialogExcludeOpen}
          onDismiss={() => this.setState({ isDialogExcludeOpen: false })}
          dialogContentProps={dialogContentProps}
        >
          <DefaultButton
            onClick={() => this.setState({ isDialogExcludeOpen: false })}
            text="Cancelar"
          />
          <DeleteButton onClick={() => this.excludeSanction()} text="Excluir" />
        </CustomDialog>
        <Wrapper>
          <ContainerContent>
            <HeaderPage
              title="Sanções"
              leftItems={
                <>
                  <CommandBarButton
                    styles={btnStyle}
                    iconProps={{ iconName: "Add" }}
                    disabled={sanctions.loading}
                    text="Adicionar Sanção"
                    onClick={() =>
                      this.setState({
                        isPanelOpen: true,
                        inicialValues: initialValue,
                      })
                    }
                  />
                  {this.commandBarRender()}
                </>
              }
              rightItems={
                <CommandBarButton
                  styles={btnStyle}
                  iconProps={{ iconName: "Refresh" }}
                  text="Atualizar"
                  onClick={() => getSanctions()}
                />
              }
            />
            {!sanctions.loading && sanctions.data.length === 0 ? (
              <NoItems
                error={sanctions.error}
                text="Não há sanções cadastradas"
                img="/static/icons/supermarket.svg"
                alt="Sanções"
              />
            ) : (
              <ListContainer>
                <ShimmeredDetailsList
                  items={isFiltering ? items : sanctions.data}
                  enableShimmer={sanctions.loading}
                  columns={columns}
                  selectionMode={SelectionMode.single}
                  selection={this._selection}
                  getKey={this._getKey}
                  selectionPreservedOnEmptyClick={true}
                  setKey="single"
                  layoutMode={DetailsListLayoutMode.justified}
                  isHeaderVisible={true}
                  onItemInvoked={this._onItemInvoked}
                />
              </ListContainer>
            )}
          </ContainerContent>
        </Wrapper>
      </>
    );
  }

  private _onColumnClick = (
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn
  ): void => {
    const { columns } = this.state;
    const newColumns: IColumn[] = columns.slice();
    let items: SanctionType[] = [];
    if (this.state.isFiltering) {
      items = this.state.items;
    } else {
      items = this.props.sanctions.data;
    }
    const currColumn: IColumn = newColumns.filter(
      (currCol) => column.key === currCol.key
    )[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    const newItems = this._sort(
      items,
      currColumn.fieldName!,
      currColumn.isSortedDescending
    );
    this.setState({
      columns: newColumns,
      items: newItems,
    });
  };

  private _getKey(item: any, index?: number): any {
    if (item !== undefined) return item.key;
  }

  private _sort<T>(
    items: T[],
    columnKey: string,
    isSortedDescending?: boolean
  ): T[] {
    const result = (prop: string, obj: T) =>
      prop.split(".").reduce((a: any, b: any) => a[b] ?? "", obj);
    return items.sort((a: T, b: T) =>
      (
        isSortedDescending
          ? result(columnKey, a) < result(columnKey, b)
          : result(columnKey, a) > result(columnKey, b)
      )
        ? 1
        : -1
    );
  }
}
const mapStateToProps = (state: RootState) => ({
  sanctions: state.sanctionReducer,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      ...getSanctions,
      ...addSanction,
      ...delSanction,
      ...editSanction,
      ...setCurrentPage,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Sanctions);
