import { PesquisaFuncType } from "../cycle/types";

/**
 * Types
 */
export enum Types {
    //GET
    REQUEST_WORKLEVEL_LOAD = "worklevel/REQUEST_WORKLEVEL_LOAD",
    SUCCESS_WORKLEVEL_LOAD = "worklevel/SUCCESS_WORKLEVEL_LOAD",
    FAILURE_WORKLEVEL_LOAD = "worklevel/FAILURE_WORKLEVEL_LOAD",
    // ADD AVALIADOS
    ADD_AVALIADOS_WORKLEVEL = "worklevel/ADD_AVALIADOS_WORKLEVEL",
    // RESET WORKLEVEL
    RESET_AVALIADOS_WORKLEVEL = "worklevel/RESET_AVALIADOS_WORKLEVEL"
};

export type WorklevelType = {
    idWorklevel: number | null;
    descricao: string;
    avaliados?: PesquisaFuncType[];
};


interface IWorklevelState {
    readonly data: WorklevelType[];
    readonly loading: boolean;
    readonly error: boolean;
    readonly success: boolean;
};

export type DataTypes = IWorklevelState;


/**
 * Actions
 */

//GET
export interface IRequestWorklevelLoadAction {
    type: typeof Types.REQUEST_WORKLEVEL_LOAD;
};

export interface ISuccessWorklevelLoadAction {
    type: typeof Types.SUCCESS_WORKLEVEL_LOAD;
    payload: WorklevelType[];
};

interface IFailureWorklevelLoadAction {
    type: typeof Types.FAILURE_WORKLEVEL_LOAD;
    message: string;
};

interface IAddAvaliadosWorklevel {
    type: typeof Types.ADD_AVALIADOS_WORKLEVEL;
    payload: PesquisaFuncType[];
}


interface IResetAvaliadosWorklevel {
    type: typeof Types.RESET_AVALIADOS_WORKLEVEL;
}


type WorklevelLoadAction = IRequestWorklevelLoadAction | ISuccessWorklevelLoadAction | IFailureWorklevelLoadAction;

export type WorklevelActionTypes = WorklevelLoadAction | IAddAvaliadosWorklevel | IResetAvaliadosWorklevel;