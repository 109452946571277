import api from "~/services/api";
import { takeLatest, put, call, all } from "redux-saga/effects";
import {
  EducationalMeasureType,
  IRequestMeasureAddAction,
  IRequestMeasureAddEvidenceAction,
  IRequestMeasureLoadSuggestedSanctionAction,
  IRequestMeasureLoadAction,
  IRequestMeasureGeraFormAction,
  ISuccessMeasureAddAction,
  ISuccessMeasureLoadSuggestedSanctionAction,
  ISuccessMeasureAddEvidenceAction,
  ISuccessMeasureLoadAction,
  ISuccessMeasureGeraFormAction,
  SuggestionType,
  Types,
  EvidenceType,
  IRequestMeasureAddAssinaturaAction,
  ISuccessMeasureAddAssinaturaAction,
  AssinaturaType,
} from "../ducks/educationalMeasure/types";

import {
  Types as TypesFile,
  IRequestFilesLoadAction,
} from "../ducks/files/types";

import moment from "moment";

const apiCallLoad = (payload: IRequestMeasureLoadAction["payload"]) => {
  return api
    .get(`perfil/${payload.idFuncionario}/medida/${payload.idMedidaEducativa}`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallPost = (item: EducationalMeasureType) => {
  return api
    .post(`perfil/${item.idFuncionario}/medida`, {
      ...item,
      dataOcorrencia: moment(item.dataOcorrencia).format("YYYY-MM-DD"),
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallLoadSuggestionSanction = (
  payload: IRequestMeasureLoadSuggestedSanctionAction["payload"]
) => {
  return api
    .post(`perfil/${payload.idFuncionario}/medida/carregaSugestaoSancao`, {
      ...payload,
      dataOcorrencia: moment(payload.dataOcorrencia).format("YYYY-MM-DD"),
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallAddEvidences = (
  payload: IRequestMeasureAddEvidenceAction["payload"]
) => {
  const formData = new FormData();
  payload.files.forEach((file) => {
    formData.append("files", file);
  });

  return api
    .post(
      `perfil/${payload.idFuncionario}/medida/${payload.idMedidaEducativa}/evidencias`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallAddAssinatura = (
  payload: IRequestMeasureAddAssinaturaAction["payload"]
) => {
  const formData = new FormData();
  formData.append("file", payload.file);

  return api
    .post(
      `perfil/${payload.idFuncionario}/medida/${payload.idMedidaEducativa}/assinatura`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallGeraForm = ({
  idFuncionario,
  idMedidaEducativa,
}: IRequestMeasureGeraFormAction["payload"]) => {
  return api
    .get(`perfil/${idFuncionario}/medida/${idMedidaEducativa}/geraFormMedida`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

function* load(action: IRequestMeasureLoadAction) {
  try {
    const measure: EducationalMeasureType = yield call(
      apiCallLoad,
      action.payload
    );
    yield put({
      type: Types.SUCCESS_MEASURE_LOAD,
      payload: measure,
    } as ISuccessMeasureLoadAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível carregar a medida educativa";
    yield put({ type: Types.FAILURE_MEASURE_LOAD, message });
  }
}

function* add(action: IRequestMeasureAddAction) {
  try {
    const measure: EducationalMeasureType = yield call(
      apiCallPost,
      action.payload.medida
    );
    yield put({
      type: Types.SUCCESS_MEASURE_ADD,
      measure: {
        ...action.payload.medida,
        ...measure,
        testemunhas: measure.testemunhas.map((test) => ({
          ...test,
          funcionario: action.payload.medida.testemunhas.find(
            (item) => item.obs === test.obs
          )?.funcionario,
        })),
      },
    } as ISuccessMeasureAddAction);
    if (action.payload.files.length > 0) {
      yield put({
        type: Types.REQUEST_MEASURE_EVIDENCE_ADD,
        payload: {
          idMedidaEducativa: measure.idMedidaEducativa,
          idFuncionario: measure.idFuncionario,
          files: action.payload.files,
        },
      } as IRequestMeasureAddEvidenceAction);
    }
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível adicionar a medida educativa";
    yield put({ type: Types.FAILURE_MEASURE_ADD, message });
  }
}

function* loadSuggestedSanction(
  action: IRequestMeasureLoadSuggestedSanctionAction
) {
  try {
    const suggestion: SuggestionType = yield call(
      apiCallLoadSuggestionSanction,
      action.payload
    );
    yield put({
      type: Types.SUCCESS_MEASURE_SUGGESTED_SANCTION_LOAD,
      suggestion,
    } as ISuccessMeasureLoadSuggestedSanctionAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível carregar a sugestão da sanção";
    yield put({ type: Types.FAILURE_MEASURE_SUGGESTED_SANCTION_LOAD, message });
  }
}

function* addEvidences(action: IRequestMeasureAddEvidenceAction) {
  try {
    const evidences: EvidenceType = yield call(
      apiCallAddEvidences,
      action.payload
    );
    yield put({
      type: Types.SUCCESS_MEASURE_EVIDENCE_ADD,
      evidences,
    } as ISuccessMeasureAddEvidenceAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível adicionar as evidências";
    yield put({ type: Types.FAILURE_MEASURE_EVIDENCE_ADD, message });
  }
}

function* addAssinatura(action: IRequestMeasureAddAssinaturaAction) {
  try {
    const status: { novoStatus: string } = yield call(
      apiCallAddAssinatura,
      action.payload
    );
    console.log(action.payload.file);
    yield put({
      type: Types.SUCCESS_MEASURE_ASSINATURA_ADD,
      payload: {
        ...status,
        anexoassinatura: {
          name: action.payload.file.name,
          size: action.payload.file.size,
        },
      },
    } as ISuccessMeasureAddAssinaturaAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível adicionar a assinatura";
    yield put({ type: Types.FAILURE_MEASURE_ASSINATURA_ADD, message });
  }
}

function* geraForm(action: IRequestMeasureGeraFormAction) {
  try {
    const data: { fileKey: string } = yield call(
      apiCallGeraForm,
      action.payload
    );
    yield put({
      type: TypesFile.REQUEST_FILES_LOAD,
      key: data.fileKey,
    } as IRequestFilesLoadAction);
    yield put({
      type: Types.SUCCESS_MEASURE_GERA_FORM,
    }as ISuccessMeasureGeraFormAction)
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível gerar o formulário";
    yield put({ type: Types.FAILURE_MEASURE_GERA_FORM, message });
  }
}

export default function* measureRoot() {
  yield all([
    yield takeLatest(Types.REQUEST_MEASURE_LOAD, load),
    yield takeLatest(Types.REQUEST_MEASURE_ADD, add),
    yield takeLatest(
      Types.REQUEST_MEASURE_SUGGESTED_SANCTION_LOAD,
      loadSuggestedSanction
    ),
    yield takeLatest(Types.REQUEST_MEASURE_EVIDENCE_ADD, addEvidences),
    yield takeLatest(Types.REQUEST_MEASURE_ASSINATURA_ADD, addAssinatura),
    yield takeLatest(Types.REQUEST_MEASURE_GERA_FORM, geraForm),
  ]);
}
