import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  padding: 0;
  border: 0;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const LeftBarContainer = styled.div`
  width: auto;
  height: calc(100vh - 50px);
  padding: 0px 30px;
  background-color: #e9ebee;
`;

export const WrapperTasks = styled.div`
  margin-top: 20px;
`;

export const ContainerTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const ImgUser = styled.img`
  width: 256px;
  height: 256px;
  border-radius: 50%;
`

export const FlexRow = styled.div`
    display: flex;
    align-items: center;
    margin-top: 20px;
`

export const WrapperInfo = styled.div`
    text-overflow: ellipsis;
    overflow: hidden;
    margin-left: 10px;
`

export const HoverImg = styled.div<{ isOver: boolean }>`
    width: 72px;
    height: 72px;
    background: rgba(0,0,0,0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: absolute;
    top: 72px;
    cursor: pointer;
    z-index: 9;
    opacity: ${({isOver}) => isOver ? 1 : 0};

    &:hover{
      opacity: 1;
    }
`

export const WrapperName = styled.div`
    display: block;
    height: 20px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`

export const FlexColumn = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`

export const Version = styled.p`
  position: absolute;
  cursor: pointer;
  bottom: 20px;
  right: 20px;
`

// export const UploadImg = styled.img`
//   object-fit: cover;
//   object-position: center;
//   width: 256px;
//   height: 256px;
//   border-radius: 50%;
// `
export const InputUpload = styled.input`
  display: none;
`