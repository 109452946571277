import styled from "styled-components";

export const Wrapper = styled.div`
  height: calc(100vh - 50px);
  width: calc(100% - 250px);
  flex-basis: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow: auto;
`;

export const ContainerContent = styled.div`
  padding: 20px 20px 0;
  height: calc(100% - 50px);
`;

export const ListContainer = styled.div`
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 240px);
  overflow-y: auto;
  position: relative;
`;

export const Comentario = styled.p`
  text-overflow: ellipsis;
  white-space: break-spaces;
  width: 200px;
  max-height: 40px;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  height: auto;
`;

export const WrapperPersonaInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 350px;
  min-height: 50px;
  height: auto;
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  padding: 10px;
`;

export const WrapperAutoComplete = styled.div`
  position: relative;
  width: 100%;
  .MuiAutocomplete-inputRoot .MuiAutocomplete-endAdornment {
    right: 35px;
  }
`;

export const Legenda = styled.div<{ bgColor: string }>`
  height: 16px;
  width: 24px;
  border-radius: 4px;
  background-color: ${({ bgColor }) => bgColor};
  margin-right: 8px;
`;

export const GridPositionFields = styled.div`
  display: grid;
  grid-template-areas:
    "valor btn"
    "comentarios comentarios";
`;
