import React from 'react';
import { Formik } from 'formik';
import * as yup from "yup";
import InputMask from "react-input-mask";
import { isValidCPF, } from "@brazilian-utils/brazilian-utils";
import { onlyDigits } from '~/utils/texts';

import { EmployeeType } from '~/store/ducks/employees/types';

import { InputText, InputDateDialog } from '~/components/Forms';
import Dropdown from '~/components/layout/Dropdown';
import { FormHeaderStyle } from '~/components/FormGroup';
import MenuItem from '@material-ui/core/MenuItem';

export interface IFormIdentification {
    initialValues: EmployeeType;
    formRef: any;
    success: (
        nomeCompleto: string,
        cpf: string,
        email: string,
        dataNascimento: Date,
        sexo: string
    ) => void;
};

const validationFormIdentificationSchema = yup.object().shape({
    matricula: yup.string().required("Campo obrigatório"),
    nomeCompleto: yup.string().required("Campo obrigatório"),
    cpf: yup.string().test('Validar CPF', 'CPF inválido', value => isValidCPF(value || '')).required("Campo obrigatório"),
    email: yup.string().email("E-mail inválido").required("Campo obrigatório"),
    dataNascimento: yup.date().typeError('Data inválida').nullable().required("Campo obrigatório"),
    sexo: yup.string().required("Campo obrigatório")
});

const ContentFormIdentification: React.FC<IFormIdentification> = (props) => {
    const { formRef, initialValues, success } = props;
    return (
        <>
            <FormHeaderStyle text="Gerenciar identificação" />
            <Formik
                enableReinitialize={true}
                innerRef={formRef}
                initialValues={initialValues}
                validationSchema={validationFormIdentificationSchema}
                validateOnChange={false}
                validateOnBlur={true}
                onSubmit={(values: EmployeeType) => {
                    values.cpf = onlyDigits(values.cpf);
                    const { nomeCompleto, cpf, email, dataNascimento, sexo } = values;
                    success(nomeCompleto, cpf, email, dataNascimento!, sexo);
                }}
            >
                {({ handleSubmit, handleChange, values, errors, setFieldError, setFieldValue }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <InputText
                                value={values.matricula}
                                id="matricula"
                                error={errors.matricula ? true : false}
                                name="matricula"
                                type="text"
                                label="Matrícula"
                                helperText={errors.matricula}
                                className="mt-2"
                                disabled
                            />
                            <InputText
                                value={values.nomeCompleto}
                                onChange={(e) => { handleChange(e); setFieldError("nomeCompleto", "") }}
                                id="nomeCompleto"
                                error={errors.nomeCompleto ? true : false}
                                name="nomeCompleto"
                                type="text"
                                label="Nome Completo"
                                helperText={errors.nomeCompleto}
                                className="mt-2"
                                autoFocus
                            />
                            <InputMask
                                mask='999.999.999-99'
                                maskChar={null}
                                onChange={(e) => { handleChange(e); setFieldError("cpf", ""); }}
                                value={values.cpf}
                            >
                                {() =>
                                    <InputText
                                        id="cpf"
                                        error={errors.cpf ? true : false}
                                        name="cpf"
                                        type="text"
                                        label="CPF"
                                        className="mt-2"
                                        helperText={errors.cpf} />
                                }
                            </InputMask>
                            <InputDateDialog
                                id="dataNascimento"
                                error={errors.dataNascimento ? true : false}
                                name="dataNascimento"
                                label="Data de Nascimento"
                                value={values.dataNascimento}
                                onChange={value => {
                                    setFieldError('dataNascimento', '');
                                    setFieldValue('dataNascimento', value);
                                }}
                                helperText={errors.dataNascimento}
                            />
                            <InputText
                                value={values.email}
                                onChange={(e) => { handleChange(e); setFieldError("email", "") }}
                                id="email"
                                error={errors.email ? true : false}
                                name="email"
                                type="text"
                                label="E-mail"
                                helperText={errors.email}
                                className="mt-2"
                            />
                            <Dropdown
                                errors={errors.sexo}
                                label="Sexo"
                                name="sexo"
                                values={values.sexo}
                                handleChange={handleChange}
                                errorText={errors.sexo}
                            >
                                <MenuItem value={"M"}>Masculino</MenuItem>
                                <MenuItem value={"F"}>Feminino</MenuItem>
                            </Dropdown>
                        </form>
                    )
                }}
            </Formik>
        </>
    );
};

export default ContentFormIdentification;