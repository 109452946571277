import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ActionButton, IButtonStyles } from 'office-ui-fabric-react';
import { Formik } from "formik"
import * as yup from 'yup';

//FluentUI
import { Text } from 'office-ui-fabric-react/lib/Text';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { AnimationStyles, mergeStyles } from 'office-ui-fabric-react';
import { DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { FontIcon } from 'office-ui-fabric-react/lib/Icon';
import {
    Container,
    ContainerPillar,
    ContainerPillarItem,
    Pillar,
    PillarOutline,
    IconButtonDelete,
    HoverPillar
} from './styles';

import CustomDialog from '~/components/CustomDialog';
import { DialogZ } from '~/components/CustomDialogFluentUI';
import { InputAutocomplete } from '~/components/Forms';
import MenuItem from '@material-ui/core/MenuItem';

import Dropdown from "~/components/layout/Dropdown"
import colors, { calcConstrast } from "~/assets/js/colors"
import ShimmerPillars from '~/components/Shimmer/shimmerPillars';
import { DataTypes, PillarsType, ObjectiveCycleType } from "~/store/ducks/cycle/types"
import { Creators as CreatorsPillars } from "~/store/ducks/pillars"
import { PillarType as PillarT } from "~/store/ducks/pillars/types"
import { Creators as CreatorsObjectives } from "~/store/ducks/objectives"
import { Creators as CreatorsCycle } from "~/store/ducks/cycle"
import { ObjectivesType } from "~/store/ducks/objectives/types"
import { Creators } from "~/store/ducks/cycle"
import { RootState } from "~/store/ducks"

interface IPillarsProps {
    idCycle: number | null;
}

const validationPillarSchema = yup.object().shape({
    pilar: yup.string().required("Campo obrigatório")
})

const validationGoalsSchema = yup.object().shape({
    objetivo: yup.object().shape({
        idObjetivo: yup.string().nullable().required("Campo obrigatório"),
    })
});

const actionButtonStyle: Partial<IButtonStyles> = {
    icon: {
        color:
            colors.primary
    },
    root: {
        marginTop: 15,
        width: 'fit-content'
    }
}

const slideUp = mergeStyles(AnimationStyles.slideUpIn20);

const Pillars: React.FC<IPillarsProps> = (props) => {
    const formRefPillar = useRef(null) as any;
    const formGoalsRef = useRef(null) as any;

    const [isDialogPillarsOpen, setIsDialogPillarsOpen] = useState<boolean>(false);
    const [isDialogGoalsOpen, setIsDialogGoalsOpen] = useState<boolean>(false);
    const [isDialogExcludeObjectiveOpen, setIsDialogExcludeObjectiveOpen] = useState<boolean>(false);
    const [isDialogExcludePillarOpen, setIsDialogExcludePillarOpen] = useState<boolean>(false);
    const [idItemDeleteObjective, setIdItemDeleteObjective] = useState<number | null>(null);
    const [idItemDeletePillar, setIdItemDeletePillar] = useState<number | null>(null);
    const [pillarSelected, setPillarSelected] = useState<number | null>(null)

    const [valueObjective, setValueObjective] = useState<Partial<ObjectivesType>>({});
    let timeout: number = 0;

    const dispatch = useDispatch();
    const pilares = useSelector<RootState, PillarsType[]>(state => state.cycleReducer.data.pillars)
    const allPilares = useSelector<RootState, PillarT[]>(state => state.pillarsReducer.data)
    const allObjectives = useSelector<RootState, ObjectivesType[]>(state => state.objectivesReducer.data)
    const cycle = useSelector<RootState, DataTypes>(state => state.cycleReducer)

    const { idCycle } = props;

    const dialogContentProps = {
        title: 'Adicionar Objetivo',
    };

    useEffect(() => {
        dispatch(CreatorsPillars.getPillars());
    }, [dispatch]);

    const search = (text: string) => {
        if (text.trim()) {
            clearTimeout(timeout);
            timeout = window.setTimeout(() => {
                dispatch(CreatorsObjectives.getObjectives(text, true, true))
            }, 700);
        }
    };

    const handleAddObjective = (idCicloPilar: number) => {
        dispatch(CreatorsObjectives.resetObjectives())
        setIsDialogGoalsOpen(true);
        setValueObjective({})
        setPillarSelected(idCicloPilar)
    }

    const handleDeletePillar = () => {
        dispatch(Creators.deleteCyclePillar(idCycle!, idItemDeletePillar!));
        setIsDialogExcludePillarOpen(false);
    }

    const handleOpenDeletePillar = (idPillar: number) => {
        setIsDialogExcludePillarOpen(true);
        setIdItemDeletePillar(idPillar);
    };

    const handleDeleteObjective = () => {
        dispatch(Creators.deleteCycleObjective(idCycle!, idItemDeleteObjective!));
        setIsDialogExcludeObjectiveOpen(false);
    }

    const handleOpenDeleteDialog = (idObjetivo: number) => {
        setIsDialogExcludeObjectiveOpen(true);
        setIdItemDeleteObjective(idObjetivo);
    };

    const renderPillars = () => {
        return pilares.map((item, i) => {
            const constrast = calcConstrast(item.pilar.corPilar) ? "#FFFFFF" : "#000000";
            return (
                <ContainerPillarItem key={i}>
                    <Pillar color={item.pilar.corPilar} colorText={constrast} className={slideUp}>
                        <Stack horizontal horizontalAlign="space-between" verticalAlign="center" styles={{ root: { width: '100%' } }}>
                            <Text variant="medium" styles={{ root: { width: '100%' } }}>{item.pilar.nomePilar}</Text>
                            <IconButtonDelete
                                iconProps={{ iconName: 'Delete', styles: { root: { color: constrast } } }}
                                title="Remover Pilar"
                                ariaLabel="Delete"
                                onClick={() => handleOpenDeletePillar(item.idCicloPilar)}
                            />
                            <HoverPillar>
                                <FontIcon
                                    iconName="DecreaseIndentArrow"
                                    onClick={() => dispatch(CreatorsCycle.pillarPrior(props.idCycle!, item.idCicloPilar))}
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 5,
                                        cursor: 'pointer',
                                        fontSize: 20
                                    }} />
                                <FontIcon iconName="IncreaseIndentArrow"
                                    onClick={() => dispatch(CreatorsCycle.pillarNext(props.idCycle!, item.idCicloPilar))}
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        right: 0,
                                        cursor: 'pointer',
                                        fontSize: 20
                                    }} />
                            </HoverPillar>
                        </Stack>
                    </Pillar>
                    <ActionButton
                        iconProps={{ iconName: "Add" }}
                        styles={{
                            icon: { color: colors.primary },
                            root: { margin: '15px 0 5px' }
                        }}
                        onClick={() => handleAddObjective(item.idCicloPilar)} >
                        Adicionar Objetivo
                </ActionButton>
                    {
                        item.objetivos.map((obj: any, index) => {
                            return (
                                <PillarOutline color={item.pilar.corPilar} key={index} className={slideUp}>
                                    <Stack horizontal horizontalAlign="space-between" verticalAlign="center" styles={{ root: { width: '100%' } }}>

                                        <Text variant="medium" styles={{ root: { width: '100%' } }}>{obj.objetivo.descObjetivo}</Text>
                                        <IconButtonDelete
                                            iconProps={{ iconName: 'Delete', styles: { root: { color: colors.black } } }}
                                            title="Remover Objetivo"
                                            ariaLabel="Delete"
                                            onClick={() => handleOpenDeleteDialog(obj.idCicloObjetivo)}
                                        />
                                    </Stack>
                                </PillarOutline>
                            )
                            // }
                        })
                    }
                </ContainerPillarItem >
            )
        })

    }

    const handleSubmitPillar = () => {
        if (formRefPillar.current) {
            formRefPillar.current.handleSubmit()
        }
    }

    const handleSubmitGoals = () => {
        if (formGoalsRef.current) {
            formGoalsRef.current.handleSubmit()
        }
    }

    return (
        <Container isLoading={cycle.loading}>
            {cycle.loading ?
                <ShimmerPillars />
                :
                <>
                    <ActionButton iconProps={{ iconName: "Add" }} styles={actionButtonStyle} onClick={() => setIsDialogPillarsOpen(true)}>
                        Adicionar Pilar
                    </ActionButton>
                    <ContainerPillar>
                        {renderPillars()}
                    </ContainerPillar>

                    <CustomDialog
                        title="Definir Pilar"
                        open={isDialogPillarsOpen}
                        onClickCancel={() => setIsDialogPillarsOpen(false)}
                        onClickConfirm={handleSubmitPillar}
                        btnConfirmText="Adicionar"
                        zIndex={1300}
                    >
                        <Formik
                            innerRef={formRefPillar}
                            initialValues={{ pilar: "" }}
                            validationSchema={validationPillarSchema}
                            validateOnChange={false}
                            validateOnBlur={true}
                            onSubmit={(values: any) => {
                                const pilar: PillarT = allPilares.find(pilar => pilar.idPilar === parseInt(values.pilar))!;

                                dispatch(Creators.addCyclePillar(idCycle!, pilar))
                                setIsDialogPillarsOpen(false)
                            }}
                        >
                            {({ handleSubmit, handleChange, values, errors }) => (
                                <form onSubmit={handleSubmit}>
                                    <Dropdown
                                        errors={errors.pilar}
                                        errorText={errors.pilar}
                                        label="Pilar"
                                        name="pilar"
                                        values={values.pilar}
                                        handleChange={handleChange}
                                        autofocus={true}
                                    >
                                        {
                                            allPilares.map((pilar, i) => <MenuItem key={i} value={`${pilar.idPilar}`}>{pilar.nomePilar}</MenuItem>)
                                        }
                                    </Dropdown>
                                </form>
                            )}
                        </Formik>
                    </CustomDialog>

                    <DialogZ
                        hidden={!isDialogGoalsOpen}
                        dialogContentProps={dialogContentProps}
                        zIndex={1000}
                    >
                        <Formik
                            innerRef={formGoalsRef}
                            initialValues={{ objetivo: "" }}
                            validationSchema={validationGoalsSchema}
                            validateOnChange={false}
                            validateOnBlur={true}
                            onSubmit={(values: any) => {
                                const objective: ObjectiveCycleType = {
                                    idCicloObjetivo: null,
                                    idObjetivo: valueObjective.idObjetivo!,
                                    objetivo: {
                                        descObjetivo: valueObjective.descObjetivo!,
                                    },
                                }
                                dispatch(Creators.addCycleObjective(idCycle!, pillarSelected!, objective))
                                setIsDialogGoalsOpen(false);
                            }}
                        >
                            {({ handleSubmit, errors, setFieldValue, setFieldError }) => (
                                <form onSubmit={handleSubmit}>
                                    <InputAutocomplete
                                        value={(valueObjective as ObjectivesType)}
                                        onChange={(_, newValue) => {
                                            setValueObjective(newValue!)
                                            setFieldValue("objetivo", { idObjetivo: newValue?.idObjetivo });
                                        }}
                                        onInputChange={(_, newInputValue) => {
                                            setFieldError("objetivo", "");
                                            search(newInputValue);
                                        }}
                                        getOptionLabel={(objective: ObjectivesType) => {
                                            if (objective.descObjetivo) {
                                                return objective.descObjetivo
                                            } else {
                                                return ""
                                            }
                                        }}
                                        getOptionSelected={(option, value) => {
                                            return option.idObjetivo === value.idObjetivo;
                                        }}
                                        options={allObjectives}
                                        input={{
                                            idInput: "objetivo",
                                            labelInput: "Objetivo",
                                            helperTextInput: errors.objetivo ? "Campo obrigatório" : "",
                                            errorInput: errors.objetivo ? true : false,
                                            autoFocus: true
                                        }}
                                    />
                                    <DialogFooter styles={{ actions: { marginTop: 24 } }}>
                                        <DefaultButton styles={{ root: { width: 110 } }} onClick={() => setIsDialogGoalsOpen(false)} text="Cancelar" />
                                        <PrimaryButton styles={{ root: { width: 110 } }} onClick={handleSubmitGoals} text="Adicionar" />
                                    </DialogFooter>
                                </form>
                            )}
                        </Formik>
                    </DialogZ>

                    <CustomDialog
                        title="Excluir Objetivo"
                        subText="Tem certeza que quer excluir o objetivo?"
                        btnConfirmText="Excluir"
                        deleteButton
                        open={isDialogExcludeObjectiveOpen}
                        onClickCancel={() => setIsDialogExcludeObjectiveOpen(false)}
                        onClickConfirm={handleDeleteObjective}
                    >
                    </CustomDialog>

                    <CustomDialog
                        title="Excluir Pilar"
                        subText="Tem certeza que quer excluir o pilar?"
                        btnConfirmText="Excluir"
                        deleteButton
                        open={isDialogExcludePillarOpen}
                        onClickCancel={() => setIsDialogExcludePillarOpen(false)}
                        onClickConfirm={handleDeletePillar}
                    >
                    </CustomDialog>
                </>
            }
        </Container>
    );
}

export default Pillars;