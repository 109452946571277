import { Types, DataTypes, UnitsActionTypes, IUnit, UnitESType } from "./types";
import customToast from "~/components/Toast/index";

/**
 * Reducers
 */
const INITIAL_STATE: DataTypes = {
    data: [],
    loadingData: false,
    loading: false,
    error: false,
    success: false,
    itemEsSelected: {
        item: null,
        loading: false,
        success: false,
        error: false,
    },
    itemSelected: {
        item: null,
        success: false,
        error: false,
        loading: false
    }
};


/**
 * Actions
 */

export const Creators = {
    getUnits: (search?: string, filter?: boolean | null): UnitsActionTypes => {
        const pesquisa = search !== "" && search !== undefined ? search : "";
        const filtro = filter !== null && filter !== undefined ? filter : null;
        return ({
            type: Types.REQUEST_UNITS_LOAD,
            search: {
                searchText: pesquisa,
                filterAtivo: filtro
            }
        })
    },

    getUnitById: (unit: IUnit): UnitsActionTypes => ({
        type: Types.REQUEST_UNITS_LOAD_ID,
        unit
    }),

    addUnit: (unit: IUnit): UnitsActionTypes => ({
        type: Types.REQUEST_UNIT_ADD,
        unit
    }),

    editUnit: (unit: IUnit): UnitsActionTypes => ({
        type: Types.REQUEST_UNIT_EDIT,
        unit
    }),

    delUnit: (id: number): UnitsActionTypes => ({
        type: Types.REQUEST_UNIT_DELETE,
        id
    }),

    loadUnitESById: (idUnidade: number, idUnidadeES: number): UnitsActionTypes => ({
        type: Types.REQUEST_UNIT_LOAD_ES_BY_ID,
        payload: {
            idUnidade,
            idUnidadeES
        }
    }),

    addUnitES: (idUnidade: number, es: UnitESType): UnitsActionTypes => ({
        type: Types.REQUEST_UNIT_ADD_ES,
        payload: {
            idUnidade,
            es
        }
    }),

    editUnitES: (idUnidade: number, es: UnitESType): UnitsActionTypes => ({
        type: Types.REQUEST_UNIT_EDIT_ES,
        payload: {
            idUnidade,
            es
        }
    }),

    delUnitES: (idUnidade: number, idUnidadeES: number): UnitsActionTypes => ({
        type: Types.REQUEST_UNIT_DEL_ES,
        payload: {
            idUnidade,
            idUnidadeES
        }
    })
};

export default function companiesReducer(state = INITIAL_STATE, action: UnitsActionTypes): DataTypes {
    switch (action.type) {

        case Types.REQUEST_UNITS_LOAD:
            return { ...state, data: [], loadingData: true, error: false, success: false };
        case Types.SUCCESS_UNITS_LOAD:
            return { ...state, data: action.payload, loadingData: false, error: false, success: true };
        case Types.FAILURE_UNITS_LOAD:
            customToast.error(action.message);
            return { ...state, data: [], loadingData: false, error: true, success: false };

        case Types.REQUEST_UNITS_LOAD_ID:
            return { ...state, error: false, itemSelected: { item: null, loading: true, success: false, error: false } };
        case Types.SUCCESS_UNITS_LOAD_ID:
            return { ...state, error: false, itemSelected: { loading: false, success: true, error: false, item: action.unit } };
        case Types.FAILURE_UNITS_LOAD_ID:
            customToast.error(action.message);
            return { ...state, error: true, itemSelected: { item: null, loading: false, success: false, error: true } };

        case Types.REQUEST_UNIT_ADD:
            return { ...state, loading: true, error: false, success: false };
        case Types.SUCCESS_UNIT_ADD:
            customToast.success("Unidade cadastrada com sucesso");
            return { ...state, loading: false, error: false, success: true, data: [...state.data, action.unit] };
        case Types.FAILURE_UNIT_ADD:
            customToast.error(action.message);
            return { ...state, loading: false, error: true, success: false };

        case Types.REQUEST_UNIT_EDIT:
            return { ...state, loading: true, error: false, success: false };
        case Types.SUCCESS_UNIT_EDIT:
            customToast.success("Unidade editada com sucesso");
            return {
                ...state,
                loading: false,
                error: false,
                success: true,
                data: state.data.map(item => {
                    if (item.idUnidade !== action.unit.idUnidade) {
                        return item;
                    } else {
                        return action.unit;
                    }
                })
            };
        case Types.FAILURE_UNIT_EDIT:
            customToast.error(action.message);
            return { ...state, loading: false, error: true, success: false };

        case Types.REQUEST_UNIT_DELETE:
            return { ...state, loadingData: true, error: false, success: false };
        case Types.SUCCESS_UNIT_DELETE:
            customToast.success("Unidade excluída com sucesso");
            return { ...state, loadingData: false, error: false, success: true, data: state.data.filter(item => item.idUnidade !== action.id) };
        case Types.FAILURE_UNIT_DELETE:
            customToast.error(action.message);
            return { ...state, loadingData: false, error: true, success: false };

        // LOAD ES BY ID
        case Types.REQUEST_UNIT_LOAD_ES_BY_ID:
            return {
                ...state,
                itemEsSelected: {
                    item: null,
                    loading: true,
                    success: false,
                    error: false,
                }
            }
        case Types.SUCCESS_UNIT_LOAD_ES_BY_ID:
            return {
                ...state,
                itemEsSelected: {
                    item: action.payload,
                    loading: false,
                    success: true,
                    error: false,
                }
            }
        case Types.FAILURE_UNIT_LOAD_ES_BY_ID:
            customToast.error(action.message);
            return {
                ...state,
                itemEsSelected: {
                    item: null,
                    loading: false,
                    success: false,
                    error: true,
                }
            }

        // ADD ES
        case Types.REQUEST_UNIT_ADD_ES:
            return { ...state, loading: true, success: false, error: false }
        case Types.SUCCESS_UNIT_ADD_ES:
            customToast.success("Estrutura salarial adicionada com sucesso");
            return {
                ...state,
                itemSelected: {
                    ...state.itemSelected,
                    item: {
                        ...state.itemSelected.item!,
                        es: [...state.itemSelected.item!.es, action.payload]
                    }
                },
                loading: false,
                success: true,
                error: false
            }
        case Types.FAILURE_UNIT_ADD_ES:
            customToast.error(action.message);
            return { ...state, loading: false, success: false, error: true }

        // EDIT ES
        case Types.REQUEST_UNIT_EDIT_ES:
            return { ...state, loading: true, success: false, error: false }
        case Types.SUCCESS_UNIT_EDIT_ES:
            customToast.success("Estrutura salarial editada com sucesso");
            return {
                ...state,
                itemSelected: {
                    ...state.itemSelected,
                    item: {
                        ...state.itemSelected.item!,
                        es: state.itemSelected.item!.es.map(item => {
                            if (item.idUnidadeES !== action.payload.idUnidadeES) {
                                return item;
                            } else {
                                return action.payload;
                            }
                        })
                    }
                },
                loading: false,
                success: true,
                error: false
            }
        case Types.FAILURE_UNIT_EDIT_ES:
            customToast.error(action.message);
            return { ...state, loading: false, success: false, error: true }

        // DEL ES
        case Types.REQUEST_UNIT_DEL_ES:
            return { ...state, loading: true, success: false, error: false }
        case Types.SUCCESS_UNIT_DEL_ES:
            customToast.success("Estrutura salarial excluída com sucesso");
            return {
                ...state,
                itemSelected: {
                    ...state.itemSelected,
                    item: {
                        ...state.itemSelected.item!,
                        es: state.itemSelected.item!.es.filter(item => item.idUnidadeES !== action.idUnidadeES)
                    }
                },
                loading: false,
                success: true,
                error: false
            }
        case Types.FAILURE_UNIT_DEL_ES:
            customToast.error(action.message);
            return { ...state, loading: false, success: false, error: true }


        default:
            return state;
    };
};