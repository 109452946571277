import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";

import { Stack, Spinner } from "office-ui-fabric-react";
import { Text } from "office-ui-fabric-react/lib/Text";
import {
  ICommandBarStyles,
  MessageBar,
  MessageBarType,
} from "office-ui-fabric-react";

import { CommandBarButton } from "~/components/Buttons";
import { InputText } from "~/components/Forms";
import PainelHeader from "~/components/layout/PainelHeader";

import {
  Container,
  Content,
  WrapperResults,
  WrapperForm,
  Divider,
  Bold,
} from "./styles";

import { Creators as CreatorsHome } from "~/store/ducks/home";
import { Creators as CreatorsTypesPDI } from "~/store/ducks/typesPDI";
import { Creators as CreatorsTask } from "~/store/ducks/tasks";
import { DataTypes as DataTypesTask } from "~/store/ducks/tasks/types";
import NoItems from "~/components/layout/NoItems";

import { BreadcrumbItems, Page } from "~/store/ducks/home/types";
import DevelopmentActions, { AcaoPDIMap } from "./DevelopmentActionsGrid";
import { convertStatusTarefa } from "~/utils/tasks";
import Accordion from "~/components/Accordion";

interface IPDIProps {
  taskState: DataTypesTask;
}

const itemsBreadCrumb: BreadcrumbItems[] = [
  {
    text: "Home",
    isCurrentItem: false,
    icon: "HomeSolid",
    onlyIcon: true,
    url: "/",
  },
  { text: "Workflow", isCurrentItem: false, url: "/workflow" },
  { text: "PDI", isCurrentItem: true },
];

const page: Page = {
  key: "PDI",
  pages: itemsBreadCrumb,
};

const PDI: React.FC<IPDIProps> = (props) => {
  const { taskState } = props;
  const {
    selected: task,
    error,
    loadingData,
    loadingAction,
    loadingSave,
  } = taskState;
  const { statusTarefa } = task ?? {};

  const history = useHistory();
  const dispatch = useDispatch();
  const params: any = useParams();
  const [errorForm, setErrorForm] = useState(false);

  const {
    handleSubmit,
    handleChange,
    errors,
    values,
    resetForm,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: validation,
    validateOnBlur: true,
    validateOnChange: false,
    // validate: () => {
    //   const errors: any = {};

    //   const { Regras } = TarefaPDI!;
    //   for (var regra of Regras!) {

    //     var acoes = TarefaPDI?.acoes!.filter(item => item.acaoPDI.tipoPDI.idTipoPDI! === regra.idTipoPDI)
    //     var percAtual = acoes!.length * 100 / TarefaPDI!.acoes!.length;

    //     if ((percAtual < Number(regra.percent) - 5) || (percAtual > Number(regra.percent) + 5)) {
    //       setErrorForm(true)
    //       errors.acoes = "O PDI não está respeitando as regras de distribuição";
    //     }
    //   }

    //   return errors
    // },
    onSubmit(values) {
      dispatch(
        CreatorsTask.finishTask(params.idTarefa, {
          comentarios: values.comentarios,
        })
      );
    },
  });

  useEffect(() => {
    dispatch(CreatorsHome.setCurrentPage(page));
    dispatch(CreatorsTypesPDI.getPdiTypes());
  }, [dispatch]);

  useEffect(() => {
    if (taskState.successAction) {
      setErrorForm(false);
    }
  }, [taskState.successAction]);

  useEffect(() => {
    resetForm({
      values: {
        comentarios: task?.TarefaPDI?.comentarios ?? "",
      },
    });
  }, [dispatch, resetForm, task]);

  useEffect(() => {
    if (taskState.redirectTo) {
      history.push("/workflow");
    }
  }, [taskState.redirectTo, history]);

  return (
    <Container>
      {
        <PainelHeader
          title={task?.tituloTarefa ?? ""}
          icon={{ icon: "PublishCourse" }}
          labelGroups={[
            {
              label: "Funcionário",
              value: task?.funcionario?.nomeCompleto ?? "",
              icon: "HomeGroup",
            },
            {
              label: "Ano",
              value: task?.TarefaPDI?.ano ?? "",
              icon: "Calendar",
            },
            {
              label: "Status",
              value: convertStatusTarefa(statusTarefa ?? ""),
              icon: "Flag",
            },
            {
              label: "Cargo",
              value: task?.TarefaPDI?.cargo?.titulo ?? "",
              icon: "Work",
            },
          ]}
        />
      }
      <Content>
        {loadingData ? (
          <Spinner
            label="Carregando avaliação"
            styles={{ root: { height: 500 } }}
          />
        ) : (
          <>
            {error ? (
              <NoItems text="Tarefa não encontrada" error={true} />
            ) : (
              <>
                {task?.flagEdicao && (
                  <>
                    <CommandBarButton
                      iconProps={{ iconName: "Save" }}
                      isLoading={loadingSave}
                      onClick={() => {
                        setErrorForm(false);
                        dispatch(
                          CreatorsTask.savePdiTask(
                            params.idTarefa,
                            values.comentarios
                          )
                        );
                      }}
                      styles={commandButtonStyle}
                    >
                      Salvar
                    </CommandBarButton>
                    <CommandBarButton
                      iconProps={{ iconName: "Send" }}
                      isLoading={loadingAction}
                      onClick={() => {
                        setErrorForm(false);
                        handleSubmit();
                      }}
                      styles={commandButtonStyle}
                    >
                      Finalizar
                    </CommandBarButton>
                  </>
                )}
                {errorForm && (
                  <MessageBar
                    messageBarType={MessageBarType.error}
                    isMultiline={false}
                    dismissButtonAriaLabel="Close"
                    onDismiss={() => {
                      setErrorForm(false);
                    }}
                  >
                    {(errors as any).acoes
                      ? (errors as any).acoes
                      : "Todos os campos são obrigatórios!"}
                  </MessageBar>
                )}

                <WrapperResults>
                  <Accordion title="PDI">
                    <Text>
                      <Bold>
                        Você sabe qual a diferença entre “TREINAMENTO” E
                        “DESENVOLVIMENTO?
                      </Bold>{" "}
                      “Treinamento” é uma mecânica a resultados de curto prazo,
                      onde o objetivo é capacitar o profissional (torná-lo
                      capaz) de performa em seu trabalha atual na máxima
                      plenitude; já o “Desenvolvimento” é uma mecânica orientada
                      ao médio/longo prazo, onde o objetivo é desenvolver o
                      profissional para desafios futuros, preparando-o para
                      assumir posições com maior responsabilidade na companhia,
                      de acordo com o seu potencial de crescimento.
                      <br />
                      <br />
                      <Bold>
                        Você conhece o “MODELO DE DESENVOLVIMENTO” do Grupo
                        Marche?
                      </Bold>{" "}
                      Nosso modelo se baseia em dois pilares, que são: A) o
                      autodesenvolvimento, onde o profissional é protagonista da
                      sua carreira; B) um plano de desenvolvimento estruturado
                      na metodologia 70/20/10, onde 70% do seu plano deve ser
                      baseado em auto-desenvolvimento e ações ‘ON THE JOB’ (no
                      próprio trabalho), tais como leitura de livros,
                      participação em projeto(s), entre outras; 20% do seu plano
                      deve ser baseado em COACHING E/OU MENTORIA, que é contar
                      com a ajuda de alguém que você reconhece/admira para
                      ajudá-lo(a) a aprender com a sua (dele) experiência; e 10%
                      do plano deve ser baseado em ações formais de treinamento
                      ‘EM SALA’ ou ‘EDUCAÇÃO À DISTÂNCIA’, custeadas ou não pela
                      empresa. Pronto para fazer seu plano?
                    </Text>
                  </Accordion>
                  <Accordion title="Ações de Desenvolvimento">
                    <DevelopmentActions
                      error={taskState.error}
                      success={taskState.successAction}
                      loading={taskState.loadingAction}
                      readOnly={!taskState.selected?.flagEdicao}
                      acoes={
                        taskState.selected?.TarefaPDI?.acoes?.map(
                          (item) =>
                            ({
                              id: item.idTarefaPDIAcao,
                              idPDIAcao: item.idAcaoPDI,
                              ...item,
                            } as AcaoPDIMap)
                        )!
                      }
                      regras={taskState.selected?.TarefaPDI?.Regras!}
                      onAdd={(pdiItem) =>
                        dispatch(
                          CreatorsTask.addPdiTask(params.idTarefa, {
                            idTarefaPDIAcao: pdiItem.id,
                            ...pdiItem,
                          })
                        )
                      }
                      onEdit={(pdiItem) =>
                        dispatch(
                          CreatorsTask.editPdiTask(params.idTarefa, {
                            idTarefaPDIAcao: pdiItem.id,
                            ...pdiItem,
                          })
                        )
                      }
                      onDel={(idPdi) =>
                        dispatch(
                          CreatorsTask.delPdiTask(params.idTarefa, idPdi)
                        )
                      }
                    />
                  </Accordion>

                  <form onSubmit={handleSubmit}>
                    <Accordion title="Comentários do PDI">
                      <Stack styles={{ root: { width: "100%" } }}>
                        <WrapperForm>
                          <InputText
                            label="Entre com os comentários do PDI"
                            name="comentarios"
                            multiline
                            rows={3}
                            onChange={handleChange}
                            value={values.comentarios ?? ""}
                            disabled={!task?.flagEdicao}
                            error={errors.comentarios ? true : false}
                            helperText={errors.comentarios}
                          />
                        </WrapperForm>
                      </Stack>
                    </Accordion>
                  </form>
                  {task?.flagEdicao && (
                    <>
                      <Divider />
                      <CommandBarButton
                        iconProps={{ iconName: "Save" }}
                        isLoading={loadingSave}
                        onClick={() => {
                          setErrorForm(false);
                          dispatch(
                            CreatorsTask.savePdiTask(
                              params.idTarefa,
                              values.comentarios
                            )
                          );
                        }}
                        styles={commandButtonStyle}
                      >
                        Salvar
                      </CommandBarButton>
                      <CommandBarButton
                        iconProps={{ iconName: "Send" }}
                        onClick={() => {
                          setErrorForm(false);
                          handleSubmit();
                        }}
                        isLoading={loadingAction}
                        styles={commandButtonStyle}
                      >
                        Finalizar
                      </CommandBarButton>
                    </>
                  )}
                  {errorForm && (
                    <MessageBar
                      messageBarType={MessageBarType.error}
                      isMultiline={false}
                      dismissButtonAriaLabel="Close"
                      onDismiss={() => {
                        setErrorForm(false);
                      }}
                    >
                      {(errors as any).acoes
                        ? (errors as any).acoes
                        : "Todos os campos são obrigatórios!"}
                    </MessageBar>
                  )}
                </WrapperResults>
              </>
            )}
          </>
        )}
      </Content>
    </Container>
  );
};

const validation = yup.object({
  comentarios: yup.string().required("Campo obrigatório"),
});

const initialValues = {
  comentarios: "",
};

const commandButtonStyle: Partial<ICommandBarStyles> = {
  root: {
    height: 44,
  },
};

export default PDI;
