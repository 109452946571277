import { ChartType } from "../../admin/chart/types";
import { ObjectivesType } from "../../objectives/types";
import { MetaType, PesoType } from "../painel/types";

/**
 * Types
 */
export enum Types {
    //LOAD DESMEMBRAMENTO
    REQUEST_DISMEMBERMENT_LOAD = "cycleDismemberment/REQUEST_DISMEMBERMENT_LOAD",
    SUCCESS_DISMEMBERMENT_LOAD = "cycleDismemberment/SUCCESS_DISMEMBERMENT_LOAD",
    FAILURE_DISMEMBERMENT_LOAD = "cycleDismemberment/FAILURE_DISMEMBERMENT_LOAD",
    //LOAD DESMEMBRAMENTO FILHOS
    REQUEST_DISMEMBERMENT_CHILDREN_LOAD = "cycleDismemberment/REQUEST_DISMEMBERMENT_CHILDREN_LOAD",
    SUCCESS_DISMEMBERMENT_CHILDREN_LOAD = "cycleDismemberment/SUCCESS_DISMEMBERMENT_CHILDREN_LOAD",
    FAILURE_DISMEMBERMENT_CHILDREN_LOAD = "cycleDismemberment/FAILURE_DISMEMBERMENT_CHILDREN_LOAD",
    //ADD PESO DESMEMBRAMENTO
    REQUEST_DISMEMBERMENT_ADD_PESO = "cycleDismemberment/REQUEST_DISMEMBERMENT_ADD_PESO",
    SUCCESS_DISMEMBERMENT_ADD_PESO = "cycleDismemberment/SUCCESS_DISMEMBERMENT_ADD_PESO",
    FAILURE_DISMEMBERMENT_ADD_PESO = "cycleDismemberment/FAILURE_DISMEMBERMENT_ADD_PESO",
    //EDIT PESO DESMEMBRAMENTO
    REQUEST_DISMEMBERMENT_EDIT_PESO = "cycleDismemberment/REQUEST_DISMEMBERMENT_EDIT_PESO",
    SUCCESS_DISMEMBERMENT_EDIT_PESO = "cycleDismemberment/SUCCESS_DISMEMBERMENT_EDIT_PESO",
    FAILURE_DISMEMBERMENT_EDIT_PESO = "cycleDismemberment/FAILURE_DISMEMBERMENT_EDIT_PESO",
    //DELETE PESO DESMEMBRAMENTO
    REQUEST_DISMEMBERMENT_DELETE_PESO = "cycleDismemberment/REQUEST_DISMEMBERMENT_DELETE_PESO",
    SUCCESS_DISMEMBERMENT_DELETE_PESO = "cycleDismemberment/SUCCESS_DISMEMBERMENT_DELETE_PESO",
    FAILURE_DISMEMBERMENT_DELETE_PESO = "cycleDismemberment/FAILURE_DISMEMBERMENT_DELETE_PESO",
    //ADD META DESMEMBRAMENTO
    REQUEST_DISMEMBERMENT_ADD_META = "cycleDismemberment/REQUEST_DISMEMBERMENT_ADD_META",
    SUCCESS_DISMEMBERMENT_ADD_META = "cycleDismemberment/SUCCESS_DISMEMBERMENT_ADD_META",
    FAILURE_DISMEMBERMENT_ADD_META = "cycleDismemberment/FAILURE_DISMEMBERMENT_ADD_META",
    //EDIT META DESMEMBRAMENTO
    REQUEST_DISMEMBERMENT_EDIT_META = "cycleDismemberment/REQUEST_DISMEMBERMENT_EDIT_META",
    SUCCESS_DISMEMBERMENT_EDIT_META = "cycleDismemberment/SUCCESS_DISMEMBERMENT_EDIT_META",
    FAILURE_DISMEMBERMENT_EDIT_META = "cycleDismemberment/FAILURE_DISMEMBERMENT_EDIT_META",
    //DELETE META DESMEMBRAMENTO
    REQUEST_DISMEMBERMENT_DELETE_META = "cycleDismemberment/REQUEST_DISMEMBERMENT_DELETE_META",
    SUCCESS_DISMEMBERMENT_DELETE_META = "cycleDismemberment/SUCCESS_DISMEMBERMENT_DELETE_META",
    FAILURE_DISMEMBERMENT_DELETE_META = "cycleDismemberment/FAILURE_DISMEMBERMENT_DELETE_META",
    // DISMEMBERMENT_ADD_INHERITED_AREA
    REQUEST_DISMEMBERMENT_ADD_INHERITED_AREA = "cycleDismemberment/REQUEST_DISMEMBERMENT_ADD_INHERITED_AREA",
    SUCCESS_DISMEMBERMENT_ADD_INHERITED_AREA = "cycleDismemberment/SUCCESS_DISMEMBERMENT_ADD_INHERITED_AREA",
    FAILURE_DISMEMBERMENT_ADD_INHERITED_AREA = "cycleDismemberment/FAILURE_DISMEMBERMENT_ADD_INHERITED_AREA",
    // DISMEMBERMENT_ADD_DUPLICATE_AREA
    ADD_DISMEMBERMENT_DUPLICATE_AREA = "cycleDismemberment/ADD_DISMEMBERMENT_DUPLICATE_AREA",
    // DELETE_DISMEMERMENT_INHERITED_AREA
    DELETE_DISMEMBERMENT_INHERITED_AREA = "cycleDismemberment/DELETE_DISMEMBERMENT_INHERITED_AREA",
    //CLEAR DESMBEMBRAMENTO
    CLEAR_DISMEMBERMENT = "cycleDismemberment/CLEAR_DISMEMBERMENT",
    //GET METAS
    REQUEST_DISMEMBERMENT_META_LOAD_BY_ID = "cycleDismemberment/REQUEST_DISMEMBERMENT_META_LOAD_BY_ID",
    SUCCESS_DISMEMBERMENT_META_LOAD_BY_ID = "cycleDismemberment/SUCCESS_DISMEMBERMENT_META_LOAD_BY_ID",
    FAILURE_DISMEMBERMENT_META_LOAD_BY_ID = "cycleDismemberment/FAILURE_DISMEMBERMENT_META_LOAD_BY_ID",
};

/**
 * Data types
 */
export type Peso = {
    idCicloPeso: number;
    idObjetivo: number;
    peso: number | string;
    flagInativo: boolean;
}

type meta = {
    idCicloMeta: number;
    minimo: string;
    target: string;
    maximo: string;
    avaliacao: string | null;
    flagInativo: boolean | null;
}

export type PesoDismembermentType = {
    descObjetivo: string;
    nomeLocal: string;
    peso: {
        idArea: number | null;
        idCicloPeso: number;
        idCicloPesoPai: number;
        idObjetivo: number;
        peso: string | number;
        flagInativo: boolean;
    }
}

export type MetaDismembermentType = {
    idCicloMeta: number;
    minimo: string;
    target: string;
    maximo: string;
    habilitarReferencia?: boolean;
    minimoRef?: string;
    targetRef?: string;
    maximoRef?: string;
    avaliacao: string | null;
    flagInativo: boolean | null;
}

export type ObjectiveDismemberment = {
    idObjetivo: number,
    codObjetivo: string,
    descObjetivo: string,
    nivel: string,
    unidade: string,
    descricaoMecanica: string,
    mecanicaCalculo: string,
    casasDecimais?: number | null
}

export type DismembermentChildrenType = {
    idArea: number;
    idCicloPeso: number | null;
    apelido: string;
    nomeLocal: string;
    meta: MetaDismembermentType;
    peso: Peso;
    objetivo?: ObjectiveDismemberment;
}

export type DismembermentItemType = {
    idCicloPeso: number;
    idCiclo: number;
    idObjetivo: number;
    idArea: number;
    peso: number | string;
    flagInativo: boolean;
    definirResponsabilidade: boolean;
    objetivo: {
        descObjetivo: string;
        mecanicaCalculo: string;
        unidade: string;
        descricaoMecanica: string;
        nivel: string;
        casasDecimais: number | null;
    }
    meta: meta[];
    corPilar: string;
    vFilhos: DismembermentChildrenType[];
}

export type DismembermentType = {
    area: Partial<ChartType>;
    respPesos: DismembermentItemType[];
}

type MetaSelectedDismembermentType = {
    data: MetaType | null;
    loading: boolean;
    success: boolean;
    error: boolean;
}

interface ICycleState {
    data: DismembermentType;
    metaSelected: MetaSelectedDismembermentType;
    success: boolean;
    successAction: boolean;
    loadingData: boolean;
    loadingAction: boolean;
    loadingChildren: boolean;
    areaSelected: Partial<ChartType> | null;
    idCicloPesoChildrenLoading: number | null;
    idCicloPesoChildrenError: number | null;
};

export type DataTypes = ICycleState;


/**
 * Actions
 */

//LOAD DISMEMBERMENT
export interface IRequestDismembermentLoadAction {
    type: typeof Types.REQUEST_DISMEMBERMENT_LOAD;
    payload: {
        idCiclo: number;
        idArea: number;
        nomeLocal?: string;
    };
};

export interface ISuccessDismembermentLoadAction {
    type: typeof Types.SUCCESS_DISMEMBERMENT_LOAD;
    payload: {
        data: DismembermentType,
        idArea: number;
        nomeLocal?: string;
    };
};

interface IFailureDismembermentLoadAction {
    type: typeof Types.FAILURE_DISMEMBERMENT_LOAD;
    message: string;
};

type DismembermentLoadAction = IRequestDismembermentLoadAction | ISuccessDismembermentLoadAction | IFailureDismembermentLoadAction;

//LOAD DISMEMBERMENT CHILDREN
export interface IRequestDismembermentChildrenLoadAction {
    type: typeof Types.REQUEST_DISMEMBERMENT_CHILDREN_LOAD;
    payload: {
        idCiclo: number;
        idArea: number;
        idCicloPeso: number;
    };
};

export interface ISuccessDismembermentChildrenLoadAction {
    type: typeof Types.SUCCESS_DISMEMBERMENT_CHILDREN_LOAD;
    payload: {
        vFilhos: DismembermentChildrenType[];
        idCicloPeso: number;
    };
};

interface IFailureDismembermentChildrenLoadAction {
    type: typeof Types.FAILURE_DISMEMBERMENT_CHILDREN_LOAD;
    payload: {
        message: string;
        idCicloPeso: number;
    }
};

type DismembermentLoadChildrenAction = IRequestDismembermentChildrenLoadAction | ISuccessDismembermentChildrenLoadAction | IFailureDismembermentChildrenLoadAction;


//ADD PESO
export interface IRequestDismembermentAddPesoAction {
    type: typeof Types.REQUEST_DISMEMBERMENT_ADD_PESO;
    payload: {
        idCiclo: number;
        idObjetivo: number;
        idCicloPeso: number;
        peso: Partial<PesoType>;
    };
};

export interface ISuccessDismembermentAddPesoAction {
    type: typeof Types.SUCCESS_DISMEMBERMENT_ADD_PESO;
    payload: {
        newPeso: Peso;
        idCicloPesoPai: number;
        idArea: number;
        idObjetivo: number;
        flagInativo: boolean;
    };
};

interface IFailureDismembermentAddPesoAction {
    type: typeof Types.FAILURE_DISMEMBERMENT_ADD_PESO;
    message: string;
};

type DismembermentAddPesoAction = IRequestDismembermentAddPesoAction | ISuccessDismembermentAddPesoAction | IFailureDismembermentAddPesoAction;

//EDIT PESO
export interface IRequestDismembermentEditPesoAction {
    type: typeof Types.REQUEST_DISMEMBERMENT_EDIT_PESO;
    payload: {
        idCiclo: number;
        idCicloPeso: number;
        idObjetivo: number;
        peso: Partial<PesoType>;
    };
};

export interface ISuccessDismembermentEditPesoAction {
    type: typeof Types.SUCCESS_DISMEMBERMENT_EDIT_PESO;
    payload: {
        newPeso: Peso;
        idCicloPesoPai: number;
        idArea: number;
        idObjetivo: number;
    };
};

interface IFailureDismembermentEditPesoAction {
    type: typeof Types.FAILURE_DISMEMBERMENT_EDIT_PESO;
    message: string;
};

type DismembermentEditPesoAction = IRequestDismembermentEditPesoAction | ISuccessDismembermentEditPesoAction | IFailureDismembermentEditPesoAction;

//DELETE PESO
export interface IRequestDismembermentDeletePesoAction {
    type: typeof Types.REQUEST_DISMEMBERMENT_DELETE_PESO;
    payload: {
        idCiclo: number;
        idCicloPeso: number;
        idCicloPesoPai: number;
        idObjetivo: number;
        idArea: number;
    };
};

export interface ISuccessDismembermentDeletePesoAction {
    type: typeof Types.SUCCESS_DISMEMBERMENT_DELETE_PESO;
    payload: {
        idCicloPeso: number;
        idCicloPesoPai: number;
        idArea: number;
        idObjetivo: number;
    }
};

interface IFailureDismembermentDeletePesoAction {
    type: typeof Types.FAILURE_DISMEMBERMENT_DELETE_PESO;
    message: string;
};

type DismembermentDeletePesoAction = IRequestDismembermentDeletePesoAction | ISuccessDismembermentDeletePesoAction | IFailureDismembermentDeletePesoAction;

//ADD META DESEMEMBRAMENTO
export interface IRequestDismembermentAddMetaAction {
    type: typeof Types.REQUEST_DISMEMBERMENT_ADD_META;
    payload: {
        idCiclo: number;
        idCicloPesoPai: number;
        idObjetivo: number;
        idArea: number;
        meta: Partial<MetaType>;
    };
};

export interface ISuccessDismembermentAddMetaAction {
    type: typeof Types.SUCCESS_DISMEMBERMENT_ADD_META;
    payload: {
        idCicloPesoPai: number;
        idArea: number;
        idObjetivo: number;
        meta: MetaDismembermentType;
    }
};

interface IFailureDismembermentAddMetaAction {
    type: typeof Types.FAILURE_DISMEMBERMENT_ADD_META;
    message: string;
};

type DismembermentAddMetaAction = IRequestDismembermentAddMetaAction | ISuccessDismembermentAddMetaAction | IFailureDismembermentAddMetaAction;

//EDIT META DESEMEMBRAMENTO
export interface IRequestDismembermentEditMetaAction {
    type: typeof Types.REQUEST_DISMEMBERMENT_EDIT_META;
    payload: {
        idCiclo: number;
        idCicloPesoPai: number;
        idCicloMeta: number;
        idObjetivo: number;
        idArea: number;
        meta: Partial<MetaType>;
    };
};

export interface ISuccessDismembermentEditMetaAction {
    type: typeof Types.SUCCESS_DISMEMBERMENT_EDIT_META;
    payload: {
        idCicloMeta: number;
        idObjetivo: number;
        idCicloPesoPai: number;
        idArea: number;
        meta: MetaDismembermentType;
    }
};

interface IFailureDismembermentEditMetaAction {
    type: typeof Types.FAILURE_DISMEMBERMENT_EDIT_META;
    message: string;
};

type DismembermentEditMetaAction = IRequestDismembermentEditMetaAction | ISuccessDismembermentEditMetaAction | IFailureDismembermentEditMetaAction;

//DELETE META DESEMEMBRAMENTO
export interface IRequestDismembermentDeleteMetaAction {
    type: typeof Types.REQUEST_DISMEMBERMENT_DELETE_META;
    payload: {
        idCiclo: number;
        idCicloPesoPai: number;
        idObjetivo: number;
        idArea: number;
        idCicloMeta: number;
    };
};

export interface ISuccessDismembermentDeleteMetaAction {
    type: typeof Types.SUCCESS_DISMEMBERMENT_DELETE_META;
    payload: {
        idCicloPesoPai: number;
        idArea: number;
        idObjetivo: number;
    }
};

interface IFailureDismembermentDeleteMetaAction {
    type: typeof Types.FAILURE_DISMEMBERMENT_DELETE_META;
    message: string;
};

type DismembermentDeleteMetaAction = IRequestDismembermentDeleteMetaAction | ISuccessDismembermentDeleteMetaAction | IFailureDismembermentDeleteMetaAction;

//CLEAR DESMEMBRAMENTO
export interface IRequestDismembermentClearAction {
    type: typeof Types.CLEAR_DISMEMBERMENT;
};

type DismembermentClearAction = IRequestDismembermentClearAction;

//DISMEMBERMENT_ADD_INHERITED_AREA
export interface IRequestDismembermentAddInheritedAreaAction {
    type: typeof Types.REQUEST_DISMEMBERMENT_ADD_INHERITED_AREA;
    payload: {
        idCiclo: number;
        idArea: number;
        parentArea: DismembermentItemType;
        objective?: Partial<ObjectivesType>;
    }
};
export interface ISuccessDismembermentAddInheritedAreaAction {
    type: typeof Types.SUCCESS_DISMEMBERMENT_ADD_INHERITED_AREA;
    payload: {
        idArea: number;
        parentArea: DismembermentItemType;
        meta: MetaDismembermentType;
        objective?: Partial<ObjectivesType>;
    }
};
interface IFailureDismembermentAddInheritedAreaAction {
    type: typeof Types.FAILURE_DISMEMBERMENT_ADD_INHERITED_AREA;
    message: string;
};

type DismembermentAddInheritedAreaAction = IRequestDismembermentAddInheritedAreaAction | ISuccessDismembermentAddInheritedAreaAction | IFailureDismembermentAddInheritedAreaAction;

export interface IDismembermentAddDuplicateAreaAction {
    type: typeof Types.ADD_DISMEMBERMENT_DUPLICATE_AREA;
    payload: {
        idCicloPesoPai: number;
        idArea: number;
    };
};

type DismembermentAddDuplicateAreaAction = IDismembermentAddDuplicateAreaAction;

interface IDeleteDismembermentAddInheritedAreaAction {
    type: typeof Types.DELETE_DISMEMBERMENT_INHERITED_AREA;
    payload: {
        idCicloPesoPai: number;
        idArea: number;
        idObjetivo: number;
    };
};

type DismembermentDeleteInheritedAreaAction = IDeleteDismembermentAddInheritedAreaAction;


//GET META BY ID
export interface IRequestDismembermentGetMetaIDAction {
    type: typeof Types.REQUEST_DISMEMBERMENT_META_LOAD_BY_ID;
    payload: {
        idCiclo: number;
        idCicloMeta: number;
    };
};

export interface ISuccessDismembermentGetMetaIDAction {
    type: typeof Types.SUCCESS_DISMEMBERMENT_META_LOAD_BY_ID;
    payload: MetaType;
};

interface IFailureDismembermentGetMetaIDAction {
    type: typeof Types.FAILURE_DISMEMBERMENT_META_LOAD_BY_ID;
    message: string;
};

type DismembermentGetMetaIDAction = IRequestDismembermentGetMetaIDAction | ISuccessDismembermentGetMetaIDAction | IFailureDismembermentGetMetaIDAction;


export type DismembermentActionTypes =
    DismembermentLoadAction |
    DismembermentLoadChildrenAction |
    DismembermentAddPesoAction |
    DismembermentEditPesoAction |
    DismembermentDeletePesoAction |
    DismembermentClearAction |
    DismembermentAddInheritedAreaAction |
    DismembermentAddMetaAction |
    DismembermentEditMetaAction |
    DismembermentDeleteMetaAction |
    DismembermentAddDuplicateAreaAction |
    DismembermentDeleteInheritedAreaAction |
    DismembermentGetMetaIDAction;