import {
  Types, DataTypes, TabPerformanceActionTypes, TabPerformanceType, NivelType,
} from "./types";
import customToast from "~/components/Toast/index";

/**
 * Reducers
 */
const INITIAL_STATE: DataTypes = {
  data: [],
  itemSelected: {
    item: null,
    loading: false,
    success: false,
    error: false,
  },
  error: false,
  loadingData: false,
  loadingAction: false,
  success: false,
};

/**
 * Actions
 */

export const Creators = {
  getTabPerformances: (SearchText?: string): TabPerformanceActionTypes => ({
    type: Types.REQUEST_TAB_PERFORMANCES_LOAD,
    payload: {
      SearchText,
    },
  }),

  getTabPerformanceById: (idTabPerformance: number): TabPerformanceActionTypes => ({
    type: Types.REQUEST_TAB_PERFORMANCES_LOAD_BY_ID,
    idTabPerformance,
  }),

  addTabPerformance: (payload: TabPerformanceType): TabPerformanceActionTypes => ({
    type: Types.REQUEST_TAB_PERFORMANCES_ADD,
    payload,
  }),

  editTabPerformance: (payload: TabPerformanceType): TabPerformanceActionTypes => ({
    type: Types.REQUEST_TAB_PERFORMANCES_EDIT,
    payload,
  }),

  delTabPerformance: (idTabPerformance: number): TabPerformanceActionTypes => ({
    type: Types.REQUEST_TAB_PERFORMANCES_DEL,
    idTabPerformance,
  }),

  addNivel: (idTabPerformance: number, nivel: NivelType): TabPerformanceActionTypes => ({
    type: Types.REQUEST_TAB_PERFORMANCES_ADD_NIVEL,
    payload: {
      idTabPerformance,
      nivel,
    },
  }),

  editNivel: (idTabPerformance: number, nivel: NivelType): TabPerformanceActionTypes => ({
    type: Types.REQUEST_TAB_PERFORMANCES_EDIT_NIVEL,
    payload: {
      idTabPerformance,
      nivel,
    },
  }),

  delNivel: (idTabPerformance: number, idTabPerformanceNivel: number): TabPerformanceActionTypes => ({
    type: Types.REQUEST_TAB_PERFORMANCES_DEL_NIVEL,
    payload: {
      idTabPerformance,
      idTabPerformanceNivel
    },
  }),
};

export default function tabPerformancesReducer(state = INITIAL_STATE, action: TabPerformanceActionTypes): DataTypes {
  switch (action.type) {
    // LOAD
    case Types.REQUEST_TAB_PERFORMANCES_LOAD:
      return {
        ...state, loadingData: true, error: false, success: false,
      };
    case Types.SUCCESS_TAB_PERFORMANCES_LOAD:
      return {
        ...state, data: action.payload, loadingData: false, error: false, success: true,
      };
    case Types.FAILURE_TAB_PERFORMANCES_LOAD:
      customToast.error(action.message);
      return {
        ...state, data: [], loadingData: false, error: true, success: false,
      };

    // LOAD BY ID
    case Types.REQUEST_TAB_PERFORMANCES_LOAD_BY_ID:
      return {
        ...state,
        itemSelected: {
          item: null,
          loading: true,
          success: false,
          error: false,
        },
      };
    case Types.SUCCESS_TAB_PERFORMANCES_LOAD_BY_ID:
      return {
        ...state,
        itemSelected: {
          item: action.payload,
          loading: false,
          success: true,
          error: false,
        },
      };
    case Types.FAILURE_TAB_PERFORMANCES_LOAD_BY_ID:
      customToast.error(action.message);
      return {
        ...state,
        itemSelected: {
          item: null,
          loading: false,
          success: false,
          error: true,
        },
      };

    // ADD
    case Types.REQUEST_TAB_PERFORMANCES_ADD:
      return {
        ...state, loadingAction: true, error: false, success: false,
      };
    case Types.SUCCESS_TAB_PERFORMANCES_ADD:
      customToast.success("Tabela de performance adicionada com sucesso");
      return {
        ...state, data: [...state.data, action.payload], loadingAction: false, error: false, success: true,
      };
    case Types.FAILURE_TAB_PERFORMANCES_ADD:
      customToast.error(action.message);
      return {
        ...state, loadingAction: false, error: true, success: false,
      };

    // ADD
    case Types.REQUEST_TAB_PERFORMANCES_EDIT:
      return {
        ...state, loadingAction: true, error: false, success: false,
      };
    case Types.SUCCESS_TAB_PERFORMANCES_EDIT:
      customToast.success("Tabela de performance editada com sucesso");
      return {
        ...state,
        data: state.data.map((item) => {
          if (item.idTabPerformance !== action.payload.idTabPerformance) {
            return item;
          }
          return action.payload;
        }),
        itemSelected: {
          ...state.itemSelected,
          item: action.payload,
        },
        loadingAction: false,
        error: false,
        success: true,
      };
    case Types.FAILURE_TAB_PERFORMANCES_EDIT:
      customToast.error(action.message);
      return {
        ...state, loadingAction: false, error: true, success: false,
      };

    // DEL
    case Types.REQUEST_TAB_PERFORMANCES_DEL:
      return {
        ...state, loadingAction: true, error: false, success: false,
      };
    case Types.SUCCESS_TAB_PERFORMANCES_DEL:
      customToast.success("Tabela de performance excluída com sucesso");
      return {
        ...state, data: state.data.filter((item) => item.idTabPerformance !== action.idTabPerformance), loadingAction: false, error: false, success: true,
      };
    case Types.FAILURE_TAB_PERFORMANCES_DEL:
      customToast.error(action.message);
      return {
        ...state, loadingAction: false, error: true, success: false,
      };

    // ADD NIVEL
    case Types.REQUEST_TAB_PERFORMANCES_ADD_NIVEL:
      return {
        ...state, loadingAction: true, error: false, success: false,
      };
    case Types.SUCCESS_TAB_PERFORMANCES_ADD_NIVEL:
      customToast.success("Nível adicionado com sucesso");
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          item: {
            ...state.itemSelected.item!,
            niveis: [...state.itemSelected.item!.niveis!, action.payload],
          },
        },
        loadingAction: false,
        error: false,
        success: true,
      };
    case Types.FAILURE_TAB_PERFORMANCES_ADD_NIVEL:
      customToast.error(action.message);
      return {
        ...state, loadingAction: false, error: true, success: false,
      };

    // EDIT NIVEL
    case Types.REQUEST_TAB_PERFORMANCES_EDIT_NIVEL:
      return {
        ...state, loadingAction: true, error: false, success: false,
      };
    case Types.SUCCESS_TAB_PERFORMANCES_EDIT_NIVEL:
      customToast.success("Nível editado com sucesso");
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          item: {
            ...state.itemSelected.item!,
            niveis: state.itemSelected.item!.niveis!.map(item => {
              if (item.idTabPerformanceNivel !== action.payload.idTabPerformanceNivel) {
                return item;
              } else {
                return action.payload;
              }
            }),
          },
        },
        loadingAction: false,
        error: false,
        success: true,
      };
    case Types.FAILURE_TAB_PERFORMANCES_EDIT_NIVEL:
      customToast.error(action.message);
      return {
        ...state, loadingAction: false, error: true, success: false,
      };

    // DEL NIVEL
    case Types.REQUEST_TAB_PERFORMANCES_DEL_NIVEL:
      return {
        ...state, loadingAction: true, error: false, success: false,
      };
    case Types.SUCCESS_TAB_PERFORMANCES_DEL_NIVEL:
      customToast.success("Nível editado com sucesso");
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          item: {
            ...state.itemSelected.item!,
            niveis: state.itemSelected.item!.niveis!.filter(item => item.idTabPerformanceNivel !== action.idTabPerformanceNivel),
          },
        },
        loadingAction: false,
        error: false,
        success: true,
      };
    case Types.FAILURE_TAB_PERFORMANCES_DEL_NIVEL:
      customToast.error(action.message);
      return {
        ...state, loadingAction: false, error: true, success: false,
      };

    default:
      return state;
  }
}
