// #region Imports
import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
 

//FluentUI
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import {
  DetailsListLayoutMode,
  SelectionMode,
  Selection,
  IColumn,
} from 'office-ui-fabric-react/lib/DetailsList';
import { ShimmeredDetailsList } from 'office-ui-fabric-react/lib/ShimmeredDetailsList';
import { CommandBarButton, ICommandBarStyles, Spinner, SpinnerSize, Text, Label } from 'office-ui-fabric-react';
import { IDialogContentProps } from 'office-ui-fabric-react/lib/Dialog';
import { Pivot, PivotItem } from 'office-ui-fabric-react/lib/Pivot';
import { Link as LinkUI, ILinkStyles } from 'office-ui-fabric-react/lib/Link';

//MaterialUI
import { PrimaryButton } from '~/components/Buttons';
import PanelWizard from "~/components/PanelWizard";
import PanelDisplay from "~/components/PanelDisplay";
import Chip from '~/components/Chip';
//Componentes
import CustomDialog from "~/components/CustomDialogFluentUI";

import { DeleteButton } from '~/components/Buttons';
import NoItems from '~/components/layout/NoItems';
import { InputAutocomplete } from '~/components/Forms';

import {
  TitleField,
  GroupItems,
  GroupItemDoisTerco,
} from '~/components/FormGroup';

//Estilos
import {
  Wrapper,
  ListContainer,
  DefaultButton,
  ContainerContent,
} from "./styles";

import { Creators as getCycleGroups } from "~/store/ducks/cycle/groups";
import { Creators as addCycleGroup } from "~/store/ducks/cycle/groups";
import { Creators as editCycleGroup } from "~/store/ducks/cycle/groups";
import { Creators as delCycleGroup } from "~/store/ducks/cycle/groups";
import { Creators as getCycleGroupById } from "~/store/ducks/cycle/groups";
import { Creators as delCycleGroupParticipant } from "~/store/ducks/cycle/groups";
import { Creators as addCycleGroupParticipants } from "~/store/ducks/cycle/groups";
import { Creators as addCycleGroupGuest } from "~/store/ducks/cycle/groups";
import { Creators as delCycleGroupGuest } from "~/store/ducks/cycle/groups";
import { Creators as pesquisaFunc } from "~/store/ducks/cycle";
import { DataTypes as DataTypesGroups, CycleGroupType, ParticipantsType } from "~/store/ducks/cycle/groups/types";

import { EmployeeType, DataTypes as DataTypesEmployee } from "~/store/ducks/employees/types";
import { DataTypes as DataTypesCycle, PesquisaFuncType } from "~/store/ducks/cycle/types";
import { Creators as getEmployees } from "~/store/ducks/employees";
import { Creators as resetAvaliadosWorklevel } from "~/store/ducks/worklevel";
import { WorklevelType, DataTypes as DataTypesWorklevel } from "~/store/ducks/worklevel/types";

import { RootState } from "~/store/ducks";

import FormInfoBasic, { IInfoBasicGroup } from './Forms/formInfoBasic';
import FormConvidados from './Forms/formAvaliadores';
import FormParticipantes from './Forms/formAvaliados';
import FormEditInfoBasic from './Forms/formEditInfoBasic';
import FormSearch from '../../Employees/formSearch';
import moment from "moment";

//#endregion

/**
 * Validação do formulário
 */

interface IGroupState {
  columns: IColumn[];
  items: CycleGroupType[];
  isDialogAddGroupOpen: boolean;
  isDialogOpen: boolean;
  isFiltering: boolean;
  selectionDetails: string;
  selectionCount: number;
  isDialogExcludeOpen: boolean;
  isDialogExcludeParticipantOpen: boolean;
  isDialogExcludeGuestOpen: boolean;
  flagAtivo: boolean;
  initialValues: CycleGroupType;

  isPanelWizardOpen: boolean;
  isPanelDisplayOpen: boolean;
  isPanelDisplayEditOpen: boolean;
  activeStep: number;
  initialInfoBasicValues: IInfoBasicGroup;
  initialConvidadosValues: Partial<EmployeeType>[];
  initialParticipantesValues: WorklevelType[];
  panelContent: TypeContentPanel | null;
  participantSelected: {
    idCicloGrupoParticipante: number | null;
    tipoFuncionario: string;
  };
  idCicloGrupoConvidado: number | null;
  isFormSearchOpen: boolean;
  valueEmployeeSearch: Partial<EmployeeType>;
};

enum TypeContentPanel {
  info = "INFO",
};

interface IPropsGroups extends RouteComponentProps {
  idCycle: number | null;
  cycleState: DataTypesCycle;
  cycleGroups: DataTypesGroups;
  employees: DataTypesEmployee;
  worklevel: DataTypesWorklevel;
  getCycleGroups: (idCiclo: number) => void;
  getCycleGroupById: (idCiclo: number, idCicloGroupo: number) => void;
  addCycleGroup: (idCiclo: number, group: CycleGroupType) => void;
  editCycleGroup: (idCiclo: number, group: CycleGroupType) => void;
  delCycleGroup: (idCiclo: number, idCicloGroupo: number) => void;
  addCycleGroupParticipants: (idCiclo: number, idCicloGrupo: number, participantes: PesquisaFuncType[]) => void;
  delCycleGroupParticipant: (idCiclo: number, idCicloGrupo: number, idCicloGrupoParticipante: number, tipoFuncionario: string) => void;
  getEmployees: (search?: string, filter?: boolean | null) => void;
  addCycleGroupGuest: (idCiclo: number, idCicloGrupo: number, convidado: Partial<EmployeeType>) => void;
  delCycleGroupGuest: (idCiclo: number, idCicloGrupo: number, idCicloGrupoConvidado: number) => void;
  resetAvaliadosWorklevel: () => void;
  pesquisaFunc: (idCiclo: number, nomePesq?: string, idArea?: number | null, worklevel?: number | null, idLider?: number | null) => void;
  history: RouteComponentProps["history"];
}

const defaultValues: CycleGroupType = {
  idCicloGrupo: null,
  idCiclo: null,
  nomeGrupo: '',
  idFuncionarioHRBP: null,
  idFuncionarioLider: null,
  idFuncionarioRH: null,
  minutos: null,
  flagIgnorarCurva: false,
  convidados: [],
  avaliados: [],
  lider: {
    nomeCompleto: ''
  },
  hrbp: {
    nomeCompleto: ''
  },
  rh: null,
  dataHoraPrevista: null
}

const defaultValuesInfoBasic: IInfoBasicGroup = {
  nomeGrupo: '',
  lider: {},
  assistenteRh: {},
  hrbp: {},
  date: null,
  time: null,
  minutos: null,
  flagIgnorarCurva: false
}

class CycleGroups extends Component<IPropsGroups, IGroupState> {
  private formRef: any;
  private _selection: Selection;
  private steps: string[];
  private timeout: number;

  private formRefInfoBasic: any;
  private formRefConvidado: any;
  private formRefParticipantes: any;
  private formRefEditInfoBasic: any;

  constructor(props: IPropsGroups) {
    super(props);
    //#region Colunas
    const columns: IColumn[] = [
      {
        key: 'column1',
        name: 'ID',
        minWidth: 30,
        maxWidth: 50,
        fieldName: 'idCicloGrupo',
        isRowHeader: true,
        isPadded: true,
        isResizable: true,
        onColumnClick: this._onColumnClick
      },
      {
        key: 'column2',
        name: 'Nome do Grupo',
        fieldName: 'nomeGrupo',
        minWidth: 210,
        maxWidth: 350,
        isRowHeader: true,
        isResizable: true,
        data: 'string',
        isPadded: true,
        onColumnClick: this._onColumnClick
      },
      {
        key: 'column3',
        name: 'Líder',
        fieldName: 'lider',
        minWidth: 210,
        maxWidth: 350,
        isRowHeader: true,
        isResizable: true,
        data: 'string',
        isPadded: true,
        onColumnClick: this._onColumnClick,
        onRender: (item: CycleGroupType) => item?.lider?.nomeCompleto
      },

    ];
    //#endregion

    this.state = {
      columns: columns,
      items: [],
      isDialogOpen: false,
      isDialogAddGroupOpen: false,
      selectionDetails: "",
      isFiltering: false,
      selectionCount: 0,
      isDialogExcludeOpen: false,
      isDialogExcludeParticipantOpen: false,
      isDialogExcludeGuestOpen: false,
      flagAtivo: false,
      initialValues: defaultValues,
      isPanelWizardOpen: false,
      isPanelDisplayOpen: false,
      isPanelDisplayEditOpen: false,
      activeStep: 0,
      initialInfoBasicValues: defaultValuesInfoBasic,
      initialConvidadosValues: [],
      initialParticipantesValues: [],
      panelContent: null,
      participantSelected: {
        idCicloGrupoParticipante: null,
        tipoFuncionario: ''
      },
      idCicloGrupoConvidado: null,
      isFormSearchOpen: false,
      valueEmployeeSearch: {
        idFuncionario: null,
        nomeCompleto: ''
      }
    }

    this._selection = new Selection({
      onSelectionChanged: () => {
        this.setState({
          selectionDetails: this._getSelectionDetails(),
        });
      },
    });


    this.timeout = 0;

    this.formRef = React.createRef();
    this.formRefInfoBasic = React.createRef();
    this.formRefConvidado = React.createRef();
    this.formRefParticipantes = React.createRef();
    this.formRefEditInfoBasic = React.createRef();
    this.steps = ["Informações Básicas", "Avaliados", 'Avaliadores'];
  };

  componentDidUpdate(prevProps: IPropsGroups, prevState: IGroupState) {
    const { valueEmployeeSearch: nextValueEmployeeSearch } = this.state;
    const { idCycle, cycleGroups } = this.props;

    if (prevProps.cycleGroups.success !== cycleGroups.success) {
      if (cycleGroups.success) {
        this.props.resetAvaliadosWorklevel();
        this.setState({ isPanelWizardOpen: false, isPanelDisplayEditOpen: false });
      }
    }

    if (prevProps.cycleGroups.itemSelected.error !== cycleGroups.itemSelected.error) {
      if (cycleGroups.itemSelected.error) {
        this.setState({ isPanelDisplayOpen: false })
      }
    }

    if (prevState.valueEmployeeSearch !== nextValueEmployeeSearch) {
      if (nextValueEmployeeSearch.idFuncionario) {
        this.props.addCycleGroupGuest(idCycle!, cycleGroups.itemSelected.item?.idCicloGrupo!, nextValueEmployeeSearch);
        this.setState({
          valueEmployeeSearch: {
            idFuncionario: null,
            nomeCompleto: ''
          }
        })
      }
    }
  }

  // Submit do formulário Formik
  handleSubmit = () => {
    if (this.formRef.current) {
      this.formRef.current.handleSubmit();
    };
  };

  _onItemInvoked = (item: any): void => {
    this.handleEdit();
  };

  _getSelectionDetails(): any {
    const selectionCount = this._selection.getSelectedCount();
    this.setState({ selectionCount });
    this._selection.getSelection();
  };

  excludeCycleGroup = () => {
    window.setTimeout(() => {
      this.setState({ isDialogExcludeOpen: false });
    }, 200)
    const idCicloGrupo = ((this._selection.getSelection()[0] as CycleGroupType).idCicloGrupo!);
    this.props.delCycleGroup(this.props.idCycle!, idCicloGrupo)
    this._selection.setAllSelected(false);
  };

  excludeCycleGroupParticipant = () => {
    const { idCicloGrupoParticipante, tipoFuncionario } = this.state.participantSelected;
    this.props.delCycleGroupParticipant(this.props.idCycle!, this.props.cycleGroups.itemSelected.item?.idCicloGrupo!, idCicloGrupoParticipante!, tipoFuncionario);
    window.setTimeout(() => {
      this.setState({ isDialogExcludeParticipantOpen: false });
    }, 200)
  };

  excludeCycleGroupGuest = () => {
    const { idCicloGrupoConvidado } = this.state;
    this.props.delCycleGroupGuest(this.props.idCycle!, this.props.cycleGroups.itemSelected.item?.idCicloGrupo!, idCicloGrupoConvidado!)
    window.setTimeout(() => {
      this.setState({ isDialogExcludeGuestOpen: false });
    }, 200)
  };

  handleEdit = () => {
    const idCicloGrupo = ((this._selection.getSelection()[0] as CycleGroupType).idCicloGrupo!);
    this.props.getCycleGroupById(this.props.idCycle!, idCicloGrupo)
    this.setState({ isPanelDisplayOpen: true })
  }

  search = (text: string) => {
    if (text.trim()) {
      clearTimeout(this.timeout);
      this.timeout = window.setTimeout(() => {
        this.props.getEmployees(text);
      }, 700);
    }
  };

  onKeyDown = (keyEvent: React.KeyboardEvent<HTMLDivElement>) => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }

  commandBarRender = () => {
    const { selectionCount } = this.state;
    if (selectionCount === 1) {
      return (
        <Stack horizontal verticalAlign="center">
          <CommandBarButton
            styles={btnStyle}
            iconProps={{ iconName: 'Edit' }}
            text="Editar"
            onClick={this.handleEdit}
          />
          <CommandBarButton
            styles={btnStyle}
            iconProps={{ iconName: 'Delete' }}
            text="Excluir"
            onClick={() => this.setState({ isDialogExcludeOpen: true })}
          />
        </Stack>
      );
    }
  };

  handleBack = () => {
    this.setState(prevState => ({
      activeStep: prevState.activeStep - 1
    }));
  }

  handleNext = () => {
    const formRefInfoBasic = this.formRefInfoBasic.current
    const formRefParticipantes = this.formRefParticipantes.current

    switch (this.state.activeStep) {
      case 0:
        if (formRefInfoBasic) {
          formRefInfoBasic.handleSubmit();
        };
        break;
      case 1:
        if (formRefParticipantes) {
          formRefParticipantes.handleSubmit();
        };
        break;

    }
  };

  renderForms = () => {
    const { initialInfoBasicValues, initialConvidadosValues, initialParticipantesValues } = this.state;

    switch (this.state.activeStep) {
      case 0:
        return (
          <FormInfoBasic
            formRef={this.formRefInfoBasic}
            initialValues={initialInfoBasicValues}
            success={(values: IInfoBasicGroup) => {
              const dataFormatada = `${moment(values.date).format('YYYY/MM/DD')} ${moment(values.time).format('HH:mm')}`
              this.setState(prevState => ({
                initialInfoBasicValues: values,
                activeStep: prevState.activeStep + 1,
                initialValues: {
                  ...this.state.initialValues,
                  nomeGrupo: values.nomeGrupo,
                  lider: {
                    nomeCompleto: values.lider.nomeCompleto!
                  },
                  idFuncionarioLider: values.lider.idFuncionario!,
                  idFuncionarioHRBP: values.hrbp.idFuncionario!,
                  idFuncionarioRH: values.assistenteRh.idFuncionario!,
                  minutos: values.minutos,
                  flagIgnorarCurva: values.flagIgnorarCurva,
                  dataHoraPrevista: new Date(dataFormatada)
                }
              }))
            }}
          />
        );
      case 1:
        return <FormParticipantes
          idCiclo={this.props.idCycle!}
          formRef={this.formRefParticipantes}
          initialValues={initialParticipantesValues}
          success={(values: WorklevelType[]) => {
            this.setState(prevState => ({
              initialParticipantesValues: values,
              activeStep: prevState.activeStep + 1,
              initialValues: {
                ...this.state.initialValues,
                avaliados: values
              }
            }));
          }}
        />;
      case 2:
        return (
          <FormConvidados
            formRef={this.formRefConvidado}
            initialValues={initialConvidadosValues}
            success={(values: Partial<EmployeeType>[]) => {
              this.setState({
                initialConvidadosValues: values,
                initialValues: {
                  ...this.state.initialValues,
                  convidados: values.map(item => ({ idFuncionario: item.idFuncionario! }))
                }
              });
            }}
          />);
    }
  }

  handleSave = () => {
    const formRefEditInfoBasic = this.formRefEditInfoBasic.current;

    if (formRefEditInfoBasic) {
      formRefEditInfoBasic.handleSubmit();
    };

  };

  render() {
    const {
      initialValues,
      isPanelDisplayEditOpen,
      isDialogExcludeOpen,
      isDialogExcludeGuestOpen,
      isFormSearchOpen,
      isFiltering,
      items,
      columns,
      isPanelWizardOpen,
      activeStep,
      isPanelDisplayOpen,
      panelContent,
      isDialogExcludeParticipantOpen,
      valueEmployeeSearch,
    } = this.state;
    const {
      worklevel,
      cycleGroups,
      addCycleGroup,
      editCycleGroup,
      getCycleGroups,
      idCycle,
      addCycleGroupParticipants,
      employees,
      resetAvaliadosWorklevel,
      cycleState
    } = this.props;
    const { itemSelected } = cycleGroups;
    const { item: groupSelected } = itemSelected;
    const { convidados = [], avaliados } = groupSelected ?? {};

    const dialogContentProps: IDialogContentProps = {
      title: 'Excluir?',
      closeButtonAriaLabel: 'Close',
      subText: 'Tem certeza de que deseja excluir este grupo?',
    };

    const dialogParticipantContentProps: IDialogContentProps = {
      title: 'Excluir?',
      closeButtonAriaLabel: 'Close',
      subText: 'Tem certeza de que deseja excluir este participante?'
    };

    const dialogGuestContentProps: IDialogContentProps = {
      title: 'Excluir?',
      closeButtonAriaLabel: 'Close',
      subText: 'Tem certeza de que deseja excluir este convidado?'
    };

    return (
      <>
        <CustomDialog
          hidden={!isDialogExcludeOpen}
          onDismiss={() => this.setState({ isDialogExcludeOpen: false })}
          dialogContentProps={dialogContentProps}
        >
          <DefaultButton onClick={() => this.setState({ isDialogExcludeOpen: false })} text="Cancelar" />
          <DeleteButton onClick={() => this.excludeCycleGroup()} text="Excluir" />
        </CustomDialog>

        <CustomDialog
          hidden={!isDialogExcludeParticipantOpen}
          dialogContentProps={dialogParticipantContentProps}
        >
          <DefaultButton onClick={() => this.setState({ isDialogExcludeParticipantOpen: false })} text="Cancelar" />
          <DeleteButton onClick={() => this.excludeCycleGroupParticipant()} text="Excluir" />
        </CustomDialog>

        <CustomDialog
          hidden={!isDialogExcludeGuestOpen}
          dialogContentProps={dialogGuestContentProps}
        >
          <DefaultButton onClick={() => this.setState({ isDialogExcludeGuestOpen: false })} text="Cancelar" />
          <DeleteButton onClick={() => this.excludeCycleGroupGuest()} text="Excluir" />
        </CustomDialog>

        <Wrapper>
          <ContainerContent>
            <Stack horizontal horizontalAlign="space-between">
              <Stack horizontal verticalAlign="center">
                <CommandBarButton
                  styles={btnStyle}
                  iconProps={{ iconName: 'Add' }}
                  text="Adicionar Grupo"
                  onClick={() => this.setState({
                    isPanelWizardOpen: true,
                    activeStep: 0,
                    initialInfoBasicValues: defaultValuesInfoBasic,
                    initialParticipantesValues: [],
                    initialConvidadosValues: [],
                    initialValues: defaultValues
                  })}
                />
                {this.commandBarRender()}
              </Stack>
              <CommandBarButton
                styles={btnStyle}
                iconProps={{ iconName: 'Refresh' }}
                text="Atualizar"
                onClick={() => getCycleGroups(idCycle!)}
              />
            </Stack>
            {!cycleGroups.loadingData && cycleGroups.data.length === 0 ?
              <NoItems
                error={cycleGroups.error}
                text="Não há grupos cadastrados"
                icon="Group"
              />
              :
              <ListContainer>
                <ShimmeredDetailsList
                  items={isFiltering ? items : cycleGroups.data}
                  enableShimmer={cycleGroups.loadingData}
                  columns={columns}
                  selectionMode={SelectionMode.single}
                  selection={this._selection}
                  getKey={this._getKey}
                  selectionPreservedOnEmptyClick={true}
                  setKey="single"
                  layoutMode={DetailsListLayoutMode.justified}
                  isHeaderVisible={true}
                  onItemInvoked={this._onItemInvoked}
                />
              </ListContainer>
            }
          </ContainerContent>
        </Wrapper>

        <PanelWizard
          open={isPanelWizardOpen}
          onClose={() => {
            this.setState({ isPanelWizardOpen: false });
            resetAvaliadosWorklevel()
          }}
          title="Adicionar Grupo de Calibração"
          isLoading={false}
          steps={this.steps}
          titleSteps={stepsTitles}
          activeStep={activeStep}
          handleBack={this.handleBack}
          handleNext={this.handleNext}
          footer={
            <>
              {this.state.activeStep === this.steps.length - 1 &&
                <PrimaryButton
                  onClick={() => addCycleGroup(idCycle!, initialValues)}
                  isLoading={cycleGroups.loadingAction}
                  text="Salvar"
                  styles={{ root: { position: 'relative', left: 196 } }}
                />
              }
            </>
          }
        >
          {
            this.renderForms()
          }
        </PanelWizard>

        <PanelDisplay
          open={isPanelDisplayOpen}
          onClose={() => this.setState({ isPanelDisplayOpen: false })}
          isLoading={itemSelected.loading}
          panelHeader={
            <Stack>
              <Text variant="xxLarge" styles={{ root: { fontWeight: 'bold' } }}>{itemSelected.item?.nomeGrupo ?? ''}</Text>
            </Stack>
          }
        >
          {
            itemSelected.loading ?
              <Spinner size={SpinnerSize.medium} label="Carregando Ciclo" styles={{ root: { height: '100%' } }} />
              :
              <Pivot aria-label="Basic Pivot Example" styles={{ root: { marginBottom: 32 } }}>
                <PivotItem headerText="Dados Principais">
                  <GroupItems title="Identificação" hasMultiItems>
                    <GroupItems>
                      <GroupItemDoisTerco>
                        <TitleField text="Nome do Grupo" />
                        <TitleField text={String(itemSelected.item?.nomeGrupo) ?? ''} />
                      </GroupItemDoisTerco>
                    </GroupItems>
                    <GroupItems>
                      <GroupItemDoisTerco>
                        <TitleField text="Líder" />
                        <TitleField text={String(itemSelected.item?.lider?.nomeCompleto) ?? ''} />
                      </GroupItemDoisTerco>
                    </GroupItems>
                    <GroupItems>
                      <GroupItemDoisTerco>
                        <TitleField text="HR Bussiness Partner" />
                        <TitleField text={String(itemSelected.item?.hrbp?.nomeCompleto) ?? ''} />
                      </GroupItemDoisTerco>
                    </GroupItems>
                    <GroupItems>
                      <GroupItemDoisTerco>
                        <TitleField text="Assitente do RH" />
                        <TitleField text={itemSelected.item?.rh?.nomeCompleto ?? ''} />
                      </GroupItemDoisTerco>
                    </GroupItems>
                    <GroupItems>
                      <GroupItemDoisTerco>
                        <TitleField text="Data/Hora Prevista" />
                        <TitleField text={itemSelected.item?.dataHoraPrevista ? moment(itemSelected.item?.dataHoraPrevista).format('DD/MM/YYYY HH:mm') ?? '' : ''} />
                      </GroupItemDoisTerco>
                    </GroupItems>
                    <GroupItems>
                      <GroupItemDoisTerco>
                        <TitleField text="Tempo de avaliação individual" />
                        <TitleField text={itemSelected.item?.minutos ? String(itemSelected.item?.minutos) : ''} />
                      </GroupItemDoisTerco>
                    </GroupItems>
                    <GroupItems>
                      <GroupItemDoisTerco>
                        <TitleField text="Ignorar curva de performance" />
                        <TitleField text={itemSelected.item?.flagIgnorarCurva ? 'SIM' : 'NÃO'} />
                        <LinkUI styles={linkStyles}
                          onClick={() => this.setState({ isPanelDisplayEditOpen: true, panelContent: TypeContentPanel.info })}
                        >
                          Gerenciar identificação
                        </LinkUI>
                      </GroupItemDoisTerco>
                    </GroupItems>
                  </GroupItems>
                </PivotItem>
                <PivotItem headerText="Avaliados">
                  <CommandBarButton styles={{ root: { height: 44 } }} iconProps={{ iconName: 'Add' }} text="Adicionar Avaliados" onClick={() => this.setState({ isFormSearchOpen: true })} />
                  {
                    worklevel?.data.map((wl, i) => {
                      const avaliadosFiltered = (avaliados as ParticipantsType[])?.filter(participante => participante.idWorklevel === wl.idWorklevel);
                      if (avaliados && avaliadosFiltered.length > 0) {
                        return (
                          <div className="ms-Grid-col ms-sm12 paddingZero" style={{ marginTop: 10 }} key={i}>
                            <GroupItems title={wl.descricao} styles={{ marginBottom: 40 }} hasMultiItems>
                              <GroupItems>
                                {
                                  avaliadosFiltered.map((item: ParticipantsType, i: number) =>
                                    <Chip
                                      key={i}
                                      nome={item.nomeCompleto ?? ""}
                                      handleClick={() => this.setState({
                                        isDialogExcludeParticipantOpen: true,
                                        participantSelected: {
                                          idCicloGrupoParticipante: item.idCicloGrupoParticipante!,
                                          tipoFuncionario: item?.tipoFuncionario ?? ''
                                        }
                                      })}
                                    />)
                                }
                              </GroupItems>
                            </GroupItems>
                          </div>
                        )
                      }
                    })
                  }
                </PivotItem>
                <PivotItem headerText="Avaliadores">
                  <Label styles={{ root: { marginBottom: 5, marginTop: 0 } }}>Adicionar avaliador</Label>
                  <InputAutocomplete
                    value={(valueEmployeeSearch as EmployeeType)}
                    onChange={(_, newValue) => {
                      this.setState({ valueEmployeeSearch: newValue! })
                    }}
                    onInputChange={(_, newInputValue) => {
                      this.search(newInputValue);
                    }}
                    onKeyDown={this.onKeyDown}
                    getOptionLabel={(employee: EmployeeType) => employee.nomeCompleto}
                    getOptionSelected={(option, value) => option.idFuncionario === value.idFuncionario}
                    options={employees.data}
                    input={{
                      idInput: "funcionario",
                      labelInput: "Nome do Avaliador",
                      autoFocus: true
                    }}
                  />
                  <Stack styles={{ root: { display: 'inline-flex', width: '100%' } }}>
                    <Stack horizontal>
                      <div className="ms-Grid-col ms-sm12 paddingZero">
                        {
                          convidados?.map((item, i) =>
                            <Chip
                              key={i}
                              nome={item.funcionario?.nomeCompleto ?? ""}
                              handleClick={() => this.setState({ isDialogExcludeGuestOpen: true, idCicloGrupoConvidado: item.idCicloGrupoConvidado! })}
                            />)
                        }
                      </div>
                    </Stack>
                  </Stack>
                </PivotItem>
              </Pivot>
          }
        </PanelDisplay>
        <PanelDisplay
          open={isPanelDisplayEditOpen}
          onClose={() => this.setState({ isPanelDisplayEditOpen: false })}
          isLoading={false}
          footer={
            <>
              <DefaultButton styles={{ root: { marginRight: 10 } }} onClick={() => this.setState({ isPanelDisplayEditOpen: false })}>Cancelar</DefaultButton>
              <PrimaryButton onClick={this.handleSave} text="Salvar" isLoading={cycleGroups.loadingAction} />
            </>
          }
        >
          {
            panelContent === TypeContentPanel.info &&
            <FormEditInfoBasic formRef={this.formRefEditInfoBasic} success={(values) => editCycleGroup(idCycle!, values!)} initialValues={itemSelected.item!} />
          }
        </PanelDisplay>

        <FormSearch
          isOpen={isFormSearchOpen}
          items={cycleState.pesquisaFunc}
          loading={cycleState.loading}
          onAdd={(values) => addCycleGroupParticipants(idCycle!, groupSelected?.idCicloGrupo!, values)}
          onClose={() => this.setState({ isFormSearchOpen: false })}
          searchAPI={(nomeSearch?: string, idArea?: number | null, idWorkLevel?: number | null, idLider?: number | null) =>
            this.props.pesquisaFunc(idCycle!, nomeSearch, idArea, idWorkLevel ? Number(idWorkLevel) : null, idLider ?? null)}
        />
      </>
    );
  };


  private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    const { columns } = this.state;
    const newColumns: IColumn[] = columns.slice();
    let items: any[] = [];
    // if (this.state.isFiltering) {
    //     items = this.state.items;
    // } else {
    //     items = this.props.companies.data;
    // };
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      };
    });
    const newItems = this._sort(items, currColumn.fieldName!, currColumn.isSortedDescending);
    this.setState({
      columns: newColumns,
      items: newItems,
    });
  };

  private _getKey(item: any, index?: number): any {
    if (item !== undefined)
      return item.key;
  };

  private _sort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
    const key = columnKey as keyof T;
    const itemsSorted = items.sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
    return itemsSorted;
  };

}

const stepsTitles = [
  {
    title: "Configurar identificação",
    subText: "Para começar, preenche as informações sobre o grupo de calibração."
  },
  {
    title: "Avaliados",
    subText: "Insira os funcionários que serão avaliados."
  },
  {
    title: "Avaliadores",
    subText: "Você pode convidar avaliador(es) que não aparece(m) na lista abaixo:"
  },
];

const linkStyles: Partial<ILinkStyles> = {
  root: {
    fontSize: 14
  },
};

const btnStyle: Partial<ICommandBarStyles> = {
  root: {
    height: 44,
    marginTop: 15,
  }
}

const mapStateToProps = (state: RootState) => ({
  cycleGroups: state.cycleGroupsReducer,
  employees: state.employeesReducer,
  worklevel: state.worklevelReducer,
  cycleState: state.cycleReducer
});

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  ...getCycleGroups,
  ...addCycleGroup,
  ...getCycleGroupById,
  ...editCycleGroup,
  ...delCycleGroup,
  ...addCycleGroupParticipants,
  ...delCycleGroupParticipant,
  ...addCycleGroupGuest,
  ...delCycleGroupGuest,
  ...getEmployees,
  ...resetAvaliadosWorklevel,
  ...pesquisaFunc
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CycleGroups));
