import {
  FontIcon,
  MessageBar,
  MessageBarButton,
  MessageBarType,
  Stack,
  Text,
} from "@fluentui/react";
import React from "react";
import {
  ContainerContent,
  TitleContainer,
  WrapperItemDownload,
} from "./styles";
import Accordion from "~/components/Accordion";
import Colors from "~/assets/js/colors";
import { OutlinedButton } from "~/components/Buttons";

export function Reports() {
  return (
    <ContainerContent>
      <TitleContainer>
        <Stack horizontal verticalAlign="center">
          <FontIcon
            iconName={"ReportDocument"}
            style={{ fontSize: 30, margin: "0 12px" }}
          />
          <Text variant="xxLarge">Relatórios</Text>
        </Stack>
      </TitleContainer>
      <MessageBar
        dismissButtonAriaLabel="Close"
        messageBarType={MessageBarType.warning}
        styles={{ root: { marginTop: 16 } }}
        messageBarIconProps={{
          iconName: "Clock",
        }}
      >
        Seu relatório <span style={{ fontWeight: "bolder" }}>FUNCIONÁRIOS</span>{" "}
        está sendo gerado
      </MessageBar>
      <MessageBar
        dismissButtonAriaLabel="Close"
        messageBarType={MessageBarType.success}
        styles={{ root: { marginTop: 16 } }}
        actions={
          <div>
            <MessageBarButton>Baixar Relatório</MessageBarButton>
          </div>
        }
      >
        Seu relatório <span style={{ fontWeight: "bolder" }}>CARGOS</span> foi
        gerado com sucesso e está disponível para download.
      </MessageBar>
      <Accordion title="Cadastrais" styles={{ details: { paddingLeft: 0 } }}>
        <ItemDownload
          iconName="Org"
          title="Áreas"
          subtitle="Extração do Organograma"
        />
        <ItemDownload
          iconName="Work"
          title="Cargos"
          subtitle="Relação de Cargos"
        />
      </Accordion>
      <Accordion title="Gerenciais" styles={{ details: { paddingLeft: 0 } }}>
        <ItemDownload
          iconName="Contact"
          title="Funcionários"
          subtitle="Relação completa de funcionários"
        />
      </Accordion>
    </ContainerContent>
  );
}

interface IItemDownloadProps {
  iconName: string;
  title: string;
  subtitle: string;
}

function ItemDownload({ iconName, title, subtitle }: IItemDownloadProps) {
  return (
    <WrapperItemDownload>
      <Stack horizontal>
        <FontIcon
          iconName={iconName}
          style={{ color: Colors.primary, fontSize: 32, marginRight: 16 }}
        />
        <Stack>
          <Text styles={{ root: { fontWeight: "600" } }}>{title}</Text>
          <Text variant="small" color="">
            {subtitle}
          </Text>
        </Stack>
      </Stack>
      <OutlinedButton text="Gerar Relatório" />
    </WrapperItemDownload>
  );
}
