/**
 * Types
 */
export enum Types {
  //GET
  REQUEST_CYCLE_TEAMS_LOAD = "cycle_teams/REQUEST_CYCLE_TEAMS_LOAD",
  SUCCESS_CYCLE_TEAMS_LOAD = "cycle_teams/SUCCESS_CYCLE_TEAMS_LOAD",
  FAILURE_CYCLE_TEAMS_LOAD = "cycle_teams/FAILURE_CYCLE_TEAMS_LOAD",
  //TRANSFER
  REQUEST_CYCLE_TEAMS_TRANSFER = "cycle_teams/REQUEST_CYCLE_TEAMS_TRANSFER",
  SUCCESS_CYCLE_TEAMS_TRANSFER = "cycle_teams/SUCCESS_CYCLE_TEAMS_TRANSFER",
  FAILURE_CYCLE_TEAMS_TRANSFER = "cycle_teams/FAILURE_CYCLE_TEAMS_TRANSFER",

  CLEAR_CYCLE_TEAMS = "cycle_teams/CLEAR_CYCLE_TEAMS",
}

export type CycleTeamType = {
  idCicloTimeMembro: number;
  idFuncionario: number;
  funcionario: {
    nomeCompleto: string;
    matricula: string;
    status: string;
  };
  time: {
    idFuncionario: number;
    funcionario: {
      idFuncionario: number;
      nomeCompleto: string;
      matricula: string;
    };
    ciclo: {
      ano: number;
    };
  };
};

interface ICycleTeamsState {
  readonly data: CycleTeamType[];
  readonly itemSelected: CycleTeamType | null;
  readonly loadingData: boolean;
  readonly loadingAction: boolean;
  readonly error: boolean;
  readonly success: boolean;
}

export type DataTypes = ICycleTeamsState;

//GET
export interface IRequestCycleTeamsLoadAction {
  type: typeof Types.REQUEST_CYCLE_TEAMS_LOAD;
  payload: {
    idCiclo: number;
    idLider?: number | null;
    idLiderado?: number | null;
  };
}

export interface ISuccessCycleTeamsLoadAction {
  type: typeof Types.SUCCESS_CYCLE_TEAMS_LOAD;
  payload: CycleTeamType[];
}

interface IFailureCycleGroupTeamsAction {
  type: typeof Types.FAILURE_CYCLE_TEAMS_LOAD;
  message: string;
}

type CycleGroupTeamsAction =
  | IRequestCycleTeamsLoadAction
  | ISuccessCycleTeamsLoadAction
  | IFailureCycleGroupTeamsAction;

//TRANSFER
export interface IRequestCycleTeamsTransferAction {
  type: typeof Types.REQUEST_CYCLE_TEAMS_TRANSFER;
  payload: {
    idCiclo: number;
    idFuncionario: number;
    liderNome: string;
    membros: Partial<CycleTeamType>[];
  };
}

export interface ISuccessCycleTeamsTransferAction {
  type: typeof Types.SUCCESS_CYCLE_TEAMS_TRANSFER;
  payload: {
    liderNome: string;
    membros: Partial<CycleTeamType>[];
  };
}

interface IFailureCycleGroupTeamsTransferAction {
  type: typeof Types.FAILURE_CYCLE_TEAMS_TRANSFER;
  message: string;
}

type CycleGroupTeamsTransferAction =
  | IRequestCycleTeamsTransferAction
  | ISuccessCycleTeamsTransferAction
  | IFailureCycleGroupTeamsTransferAction;

interface IClearCycleGroupTeams {
  type: typeof Types.CLEAR_CYCLE_TEAMS;
}

export type CycleTeamsActionTypes =
  | CycleGroupTeamsAction
  | CycleGroupTeamsTransferAction
  | IClearCycleGroupTeams;
