import styled from "styled-components";
import Colors from "~/assets/js/colors";

export const Container = styled.div``;

export const Details = styled.p`
  color: ${Colors.midGray};
  font-size: 16px;
  margin-top: -12px;
  margin-bottom: 20px;

  & span {
    color: ${Colors.primary};
  }
`;

export const UploadArea = styled.div`
  max-width: 480px;
  width: 100%;
  height: 104px;
  border-radius: 4px;
  border: 1px solid ${Colors.primary};
  border-style: dashed;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 24px;

  &:hover {
    background-color: ${Colors.gray}30;
  }
`;

export const Title = styled.p`
  font-size: 16px;
  font-weight: 400;
`;

export const Description = styled.p`
  font-size: 12px;
  font-weight: 400;
  color: ${Colors.midGray};
`;

export const CloudUploadIcon = styled.img`
  margin-bottom: 8px;
`;

export const HideInput = styled.input`
  display: none;
`;

export const DeleteButton = styled.button`
  position: absolute;
  right: -24px;
  top: 21px;
  border-radius: 6px;
  border: none;
  padding: 8px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: right 0.2s;

  &:hover {
    background-color: ${Colors.gray}10;
  }

  &:active {
    background-color: ${Colors.gray};
  }
`;

export const UploadFile = styled.div<{ error?: boolean; readOnly?: boolean }>`
  max-width: 480px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid
    ${({ error, readOnly }) =>
      error ? Colors.error : readOnly ? `${Colors.gray}50` : Colors.orange};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 16px;
  margin-bottom: 24px;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;

  &:hover {
    background-color: ${Colors.gray}30;

    ${DeleteButton} {
      right: 16px;
    }
  }
`;

export const WrapperInfoFile = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
`;

export const FileName = styled.p`
  font-size: 12px;
  color: ${Colors.black};
`;

export const FileSize = styled.span`
  font-size: 10px;
  color: ${Colors.gray};
  margin-top: 4px;
`;

// export const DelIcon = styled(DeleteIcon)`
//   height: 16px;
//   width: 16px;
//   color: ${Colors.gray};
// `;
