/**
 * Types
 */
export enum Types {
  REQUEST_USER_EMAIL = "login/REQUEST_USER_EMAIL",
  SUCCESS_USER_EMAIL = "login/SUCCESS_USER_EMAIL",
  FAILURE_USER_EMAIL = "login/FAILURE_USER_EMAIL",

  REQUEST_USER_LOGIN = "login/REQUEST_USER_LOGIN",
  SUCCESS_USER_LOGIN = "login/SUCCESS_USER_LOGIN",
  FAILURE_USER_LOGIN = "login/FAILURE_USER_LOGIN",
  
  // REFRESH TOKEN
  REQUEST_USER_REFRESH_TOKEN = "login/REQUEST_USER_REFRESH_TOKEN",
  SUCCESS_USER_REFRESH_TOKEN = "login/SUCCESS_USER_REFRESH_TOKEN",
  FAILURE_USER_REFRESH_TOKEN = "login/FAILURE_USER_REFRESH_TOKEN",

  USER_SIGNOUT = "login/USER_SIGNOUT",
  CLEAR_ERROR_LOGIN = "login/CLEAR_ERROR_LOGIN",
  CLEAR_LOADING_LOGIN = "login/CLEAR_LOADING_LOGIN",
  // UPLOAD IMAGE
  REQUEST_EMPLOYEE_UPLOAD = "login/REQUEST_EMPLOYEE_UPLOAD",
  SUCCESS_EMPLOYEE_UPLOAD = "login/SUCCESS_EMPLOYEE_UPLOAD",
  FAILURE_EMPLOYEE_UPLOAD = "login/FAILURE_EMPLOYEE_UPLOAD",
  // DELETE IMAGE
  REQUEST_EMPLOYEE_DEL_UPLOAD = "login/REQUEST_EMPLOYEE_DEL_UPLOAD",
  SUCCESS_EMPLOYEE_DEL_UPLOAD = "login/SUCCESS_EMPLOYEE_DEL_UPLOAD",
  FAILURE_EMPLOYEE_DEL_UPLOAD = "login/FAILURE_EMPLOYEE_DEL_UPLOAD",
  CLEAR_STORE = "login/CLEAR_STORE",

  REQUEST_NEW_PASSWORD = "login/REQUEST_NEW_PASSWORD",

  REQUEST_LOGIN_NEW_PASSWORD = "login/REQUEST_LOGIN_NEW_PASSWORD",
  SUCCESS_LOGIN_NEW_PASSWORD = "login/SUCCESS_LOGIN_NEW_PASSWORD",
  FAILURE_LOGIN_NEW_PASSWORD = "login/FAILURE_LOGIN_NEW_PASSWORD",

  REQUEST_LOGIN_RESET_PASSWORD = "login/REQUEST_LOGIN_RESET_PASSWORD",
  SUCCESS_LOGIN_RESET_PASSWORD = "login/SUCCESS_LOGIN_RESET_PASSWORD",
  FAILURE_LOGIN_RESET_PASSWORD = "login/FAILURE_LOGIN_RESET_PASSWORD",
};

/**
 * Data types
 */

export type LoginType = {
  email: string;
  name: string;
  abbreviation: string;
  imagem: string | null;
  flagAdministrador: boolean;
  flagRH: boolean;
  flagBP: boolean;
  flagEfetividade: boolean;
};

interface ILoginState {
  data: LoginType;
  RequestNovaSenha?: boolean;
  isLogged: boolean;
  loading: boolean;
  error: string;
  isUploading: boolean;
  successUpload: boolean;
  successReset: boolean;
  errorReset: boolean;
};

type SignIn = {
  email: string;
  password: string;
};

export type DataTypes = ILoginState;

/**
 * Actions
 */

interface IRequestUserEmailAction {
  type: typeof Types.REQUEST_USER_EMAIL;
  email: string;
};

export interface ISuccessUserEmailAction {
  type: typeof Types.SUCCESS_USER_EMAIL;
  email: string;
};

export interface ISignInLoginAction {
  type: typeof Types.REQUEST_USER_LOGIN;
  payload: SignIn;
};

interface ISuccessSignInLoginAction {
  type: typeof Types.SUCCESS_USER_LOGIN;
  payload: LoginType;
}

interface IFailureSignInLoginAction {
  type: typeof Types.FAILURE_USER_LOGIN;
  message: string;
}

interface ISignOutLoginAction {
  type: typeof Types.USER_SIGNOUT;
};

interface IClearErrorLoginAction {
  type: typeof Types.CLEAR_ERROR_LOGIN;
};

interface IClearStoreAction {
  type: typeof Types.CLEAR_STORE;
};


//UPLOAD
export interface IRequestEmployeeUploadImageAction {
  type: typeof Types.REQUEST_EMPLOYEE_UPLOAD;
  payload: {
    imagem: string;
  }
};

export interface ISuccessEmployeeUploadImageAction {
  type: typeof Types.SUCCESS_EMPLOYEE_UPLOAD;
  payload: {
    imagem: string;
  }
};

export interface IFailureEmployeeUploadImageAction {
  type: typeof Types.FAILURE_EMPLOYEE_UPLOAD;
  message: string;
};

type EmployeeUploadImageAction = IRequestEmployeeUploadImageAction | ISuccessEmployeeUploadImageAction | IFailureEmployeeUploadImageAction;

//DELETE UPLOAD
export interface IRequestEmployeeDelImageAction {
  type: typeof Types.REQUEST_EMPLOYEE_DEL_UPLOAD;
};

export interface ISuccessEmployeeDelImageAction {
  type: typeof Types.SUCCESS_EMPLOYEE_DEL_UPLOAD;
};

export interface IFailureEmployeeDelImageAction {
  type: typeof Types.FAILURE_EMPLOYEE_DEL_UPLOAD;
  message: string;
};

type EmployeeDelImageAction = IRequestEmployeeDelImageAction | ISuccessEmployeeDelImageAction | IFailureEmployeeDelImageAction;

export interface IRequestNewPasswordAction {
  type: typeof Types.REQUEST_NEW_PASSWORD;
};

//LOGIN NEW PASSWORD
export interface IRequestLoginNewPasswordAction {
  type: typeof Types.REQUEST_LOGIN_NEW_PASSWORD;
  payload: {
    email: string;
    password: string;
    novaSenha: string;
  }
};

export interface ISuccessLoginNewPasswordAction {
  type: typeof Types.SUCCESS_LOGIN_NEW_PASSWORD;
  payload: LoginType;
};

export interface IFailureLoginNewPasswordAction {
  type: typeof Types.FAILURE_LOGIN_NEW_PASSWORD;
  message: string;
};

type LoginNewPasswordAction = IRequestLoginNewPasswordAction | ISuccessLoginNewPasswordAction | IFailureLoginNewPasswordAction;

//RESET PASSWORD
export interface IRequestLoginResetPasswordAction {
  type: typeof Types.REQUEST_LOGIN_RESET_PASSWORD;
  payload: {
    email: string;
  }
};

export interface ISuccessLoginResetPasswordAction {
  type: typeof Types.SUCCESS_LOGIN_RESET_PASSWORD;
};

export interface IFailureLoginResetPasswordAction {
  type: typeof Types.FAILURE_LOGIN_RESET_PASSWORD;
  message: string;
};

type LoginResetPasswordAction = IRequestLoginResetPasswordAction | ISuccessLoginResetPasswordAction | IFailureLoginResetPasswordAction;

// REFRESH TOKEN
export interface IRequestLoginRefreshTokenAction {
  type: typeof Types.REQUEST_USER_REFRESH_TOKEN;
};

export interface ISuccessLoginRefreshTokenAction {
  type: typeof Types.SUCCESS_USER_REFRESH_TOKEN;
};

export interface IFailureLoginRefreshTokenAction {
  type: typeof Types.FAILURE_USER_REFRESH_TOKEN;
  message: string;
};

type LoginRefreshTokenAction = IRequestLoginRefreshTokenAction | ISuccessLoginRefreshTokenAction | IFailureLoginRefreshTokenAction;

export type LoginActionTypes =
  ISuccessUserEmailAction |
  ISignInLoginAction |
  ISuccessSignInLoginAction |
  IFailureSignInLoginAction |
  IRequestUserEmailAction |
  IClearErrorLoginAction |
  ISignOutLoginAction |
  EmployeeUploadImageAction |
  EmployeeDelImageAction |
  IClearStoreAction |
  IRequestNewPasswordAction |
  LoginNewPasswordAction |
  LoginResetPasswordAction |
  LoginRefreshTokenAction;
