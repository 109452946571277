/**
 * Types
 */
export enum Types {
  REQUEST_LOAD_CATEGORIES_POSITIONS = "categoriesPositions/REQUEST_LOAD_CATEGORIES_POSITIONS",
  SUCCESS_LOAD_CATEGORIES_POSITIONS = "categoriesPositions/SUCCESS_LOAD_CATEGORIES_POSITIONS",
  FAILURE_LOAD_CATEGORIES_POSITIONS = "categoriesPositions/FAILURE_LOAD_CATEGORIES_POSITIONS",

  REQUEST_LOAD_CATEGORY_POSITION_BY_ID = "categoriesPositions/REQUEST_LOAD_CATEGORY_POSITION_BY_ID",
  SUCCESS_LOAD_CATEGORY_POSITION_BY_ID = "categoriesPositions/SUCCESS_LOAD_CATEGORY_POSITION_BY_ID",
  FAILURE_LOAD_CATEGORY_POSITION_BY_ID = "categoriesPositions/FAILURE_LOAD_CATEGORY_POSITION_BY_ID",

  REQUEST_ADD_CATEGORY_POSITION = "categoriesPositions/REQUEST_ADD_CATEGORY_POSITION",
  SUCCESS_ADD_CATEGORY_POSITION = "categoriesPositions/SUCCESS_ADD_CATEGORY_POSITION",
  FAILURE_ADD_CATEGORY_POSITION = "categoriesPositions/FAILURE_ADD_CATEGORY_POSITION",

  REQUEST_EDIT_CATEGORY_POSITION = "categoriesPositions/REQUEST_EDIT_CATEGORY_POSITION",
  SUCCESS_EDIT_CATEGORY_POSITION = "categoriesPositions/SUCCESS_EDIT_CATEGORY_POSITION",
  FAILURE_EDIT_CATEGORY_POSITION = "categoriesPositions/FAILURE_EDIT_CATEGORY_POSITION",

  REQUEST_DEL_CATEGORY_POSITION = "categoriesPositions/REQUEST_DEL_CATEGORY_POSITION",
  SUCCESS_DEL_CATEGORY_POSITION = "categoriesPositions/SUCCESS_DEL_CATEGORY_POSITION",
  FAILURE_DEL_CATEGORY_POSITION = "categoriesPositions/FAILURE_DEL_CATEGORY_POSITION",
}

export type CategoryPositionLoadDTO = {
  idCategoriaCargo: number;
  descCategoriaCargo: string;
  flagAtivo: boolean;
  bonusTarget: number | null;
  nivel: number;
  flagBonusDescontarFaltas: boolean;
  flagMerito: boolean;
  flagIgnorarINPC: boolean;
};

export type CategoryPositionAddOrEditDTO = {
  idCategoriaCargo?: number;
  descCategoriaCargo: string;
  bonusTarget: number | null;
  flagBonusDescontarFaltas: boolean;
  flagAtivo: boolean;
  nivel: number | null;
  flagMerito: boolean;
  flagIgnorarINPC: boolean;
  avaliacaoColetiva: boolean;
};

export type CategoryPositionType = {
  idCategoriaCargo: number;
  descCategoriaCargo: string;
  bonusTarget: number | null;
  flagBonusDescontarFaltas: boolean;
  flagAtivo: boolean;
  nivel: number;
  flagMerito: boolean;
  flagIgnorarINPC: boolean;
  avaliacaoColetiva: boolean;
};

export type ItemSelectedType = {
  item: CategoryPositionType | null;
  loading: boolean;
  success: boolean;
  error: boolean;
};

interface ICategoriesPositionsState {
  readonly data: CategoryPositionLoadDTO[];
  readonly loadingData: boolean;
  readonly loadingAction: boolean;
  readonly success: boolean;
  readonly error: boolean;
  readonly itemSelected: ItemSelectedType;
}

export type DataTypes = ICategoriesPositionsState;

/**
 * Actions
 */

// LOAD
export interface IRequestGetCategoriesPositionsAction {
  type: typeof Types.REQUEST_LOAD_CATEGORIES_POSITIONS;
}

export interface ISuccessGetCategoriesPositionsAction {
  type: typeof Types.SUCCESS_LOAD_CATEGORIES_POSITIONS;
  payload: CategoryPositionLoadDTO[];
}

export interface IFailureGetCategoriesPositionsAction {
  type: typeof Types.FAILURE_LOAD_CATEGORIES_POSITIONS;
  message: string;
}

type CategoriesPositionGetActions =
  | IRequestGetCategoriesPositionsAction
  | ISuccessGetCategoriesPositionsAction
  | IFailureGetCategoriesPositionsAction;

//LOAD BY ID

export interface IRequestGetByIdCategoriesPositionsAction {
  type: typeof Types.REQUEST_LOAD_CATEGORY_POSITION_BY_ID;
  idCategoriaCargo: number;
}

export interface ISuccessGetByIdCategoriesPositionsAction {
  type: typeof Types.SUCCESS_LOAD_CATEGORY_POSITION_BY_ID;
  payload: CategoryPositionType;
}

export interface IFailureGetByIdCategoriesPositionsAction {
  type: typeof Types.FAILURE_LOAD_CATEGORY_POSITION_BY_ID;
  message: string;
}

type CategoriesGetByIdPositionGetActions =
  | IRequestGetByIdCategoriesPositionsAction
  | ISuccessGetByIdCategoriesPositionsAction
  | IFailureGetByIdCategoriesPositionsAction;

//ADD

export interface IRequestAddCategoriesPositionsAction {
  type: typeof Types.REQUEST_ADD_CATEGORY_POSITION;
  payload: CategoryPositionAddOrEditDTO;
}

export interface ISuccessAddCategoriesPositionsAction {
  type: typeof Types.SUCCESS_ADD_CATEGORY_POSITION;
  payload: CategoryPositionLoadDTO;
}

export interface IFailureAddCategoriesPositionsAction {
  type: typeof Types.FAILURE_ADD_CATEGORY_POSITION;
  message: string;
}

type CategoriesPositionAddActions =
  | IRequestAddCategoriesPositionsAction
  | ISuccessAddCategoriesPositionsAction
  | IFailureAddCategoriesPositionsAction;

//ADD

export interface IRequestEditCategoriesPositionsAction {
  type: typeof Types.REQUEST_EDIT_CATEGORY_POSITION;
  payload: CategoryPositionAddOrEditDTO;
}

export interface ISuccessEditCategoriesPositionsAction {
  type: typeof Types.SUCCESS_EDIT_CATEGORY_POSITION;
  payload: CategoryPositionLoadDTO;
}

export interface IFailureEditCategoriesPositionsAction {
  type: typeof Types.FAILURE_EDIT_CATEGORY_POSITION;
  message: string;
}

type CategoriesPositionEditActions =
  | IRequestEditCategoriesPositionsAction
  | ISuccessEditCategoriesPositionsAction
  | IFailureEditCategoriesPositionsAction;

//DELETE

export interface IRequestDelCategoriesPositionsAction {
  type: typeof Types.REQUEST_DEL_CATEGORY_POSITION;
  idCategoriaCargo: number;
}

export interface ISuccessDelCategoriesPositionsAction {
  type: typeof Types.SUCCESS_DEL_CATEGORY_POSITION;
  idCategoriaCargo: number;
}

export interface IFailureDelCategoriesPositionsAction {
  type: typeof Types.FAILURE_DEL_CATEGORY_POSITION;
  message: string;
}

type CategoriesPositionDelActions =
  | IRequestDelCategoriesPositionsAction
  | ISuccessDelCategoriesPositionsAction
  | IFailureDelCategoriesPositionsAction;

export type CategoriesPositionsActionTypes =
  | CategoriesPositionGetActions
  | CategoriesGetByIdPositionGetActions
  | CategoriesPositionAddActions
  | CategoriesPositionEditActions
  | CategoriesPositionDelActions;
