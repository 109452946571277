import { Types, DataTypes, MeetingsActionTypes, MeetingType } from "./types";
import customToast from "~/components/Toast/index";

/**
 * Reducers
 */
const INITIAL_STATE: DataTypes = {
    data: [],
    dataCriar: null,
    error: false,
    loadingData: false,
    loadingAction: false,
    loadingCriar: false,
    success: false,
    successAdd: false,
    successLoading: false,
    idCicloReuniaoCreated: null
};

/**
 * Actions
 */

export const Creators = {
    getMeetings: (): MeetingsActionTypes => {
        return {
            type: Types.REQUEST_MEETINGS_LOAD,
        }
    },

    getMeetingsCriar: (idCiclo?: number): MeetingsActionTypes => {
        return {
            type: Types.REQUEST_MEETINGS_LOAD_CRIAR,
            idCiclo
        }
    },

    addMeeting: (idCicloGrupo: number): MeetingsActionTypes => {
        return {
            type: Types.REQUEST_MEETINGS_ADD,
            payload: {
                idCicloGrupo
            }
        }
    },

    delMeeting: (idCicloReuniao: number): MeetingsActionTypes => {
        return {
            type: Types.REQUEST_MEETINGS_DEL,
            payload: {
                idCicloReuniao
            }
        }
    },
}


export default function meetingsReducer(state = INITIAL_STATE, action: MeetingsActionTypes): DataTypes {

    switch (action.type) {
        case Types.REQUEST_MEETINGS_LOAD:
            return { ...state, loadingData: true, error: false, success: false };
        case Types.SUCCESS_MEETINGS_LOAD:
            return { ...state, data: action.payload, loadingData: false, error: false, success: true };
        case Types.FAILURE_MEETINGS_LOAD:
            customToast.error(action.message);
            return { ...state, data: [], loadingData: false, error: true, success: false };

        case Types.REQUEST_MEETINGS_ADD:
            return { ...state, loadingAction: true, error: false, successAdd: false };
        case Types.SUCCESS_MEETINGS_ADD:
            customToast.success("Reunião criada com sucesso");
            return { ...state, loadingAction: false, error: false, successAdd: true, idCicloReuniaoCreated: action.idCicloReuniao };
        case Types.FAILURE_MEETINGS_ADD:
            customToast.error(action.message);
            return { ...state, loadingAction: false, error: true, successAdd: false };

        case Types.REQUEST_MEETINGS_DEL:
            return { ...state, loadingAction: true, error: false, success: false };
        case Types.SUCCESS_MEETINGS_DEL:
            customToast.success("Reunião excluída com sucesso");
            return {
                ...state,
                data: state.data.filter(item => item.idCicloReuniao !== action.payload.idCicloReuniao),
                loadingAction: false,
                error: false,
                success: true
            };
        case Types.FAILURE_MEETINGS_DEL:
            customToast.error(action.message);
            return { ...state, loadingAction: false, error: true, success: false };

        case Types.REQUEST_MEETINGS_LOAD_CRIAR:
            return { ...state, dataCriar: null, loadingCriar: true };
        case Types.SUCCESS_MEETINGS_LOAD_CRIAR:
            return { ...state, dataCriar: action.payload, loadingCriar: false };
        case Types.FAILURE_MEETINGS_LOAD_CRIAR:
            customToast.error(action.message);
            return { ...state, dataCriar: null, loadingCriar: false };

        default:
            return state;
    }
}