/**
 * Types
 */
export enum Types {
  //GET
  REQUEST_TYPES_CONDUCTS_LOAD = "typeConducts/REQUEST_TYPES_CONDUCTS_LOAD",
  SUCCESS_TYPES_CONDUCTS_LOAD = "typeConducts/SUCCESS_TYPES_CONDUCTS_LOAD",
  FAILURE_TYPES_CONDUCTS_LOAD = "typeConducts/FAILURE_TYPES_CONDUCTS_LOAD",
  //ADD
  REQUEST_TYPE_CONDUCT_ADD = "typeConducts/REQUEST_TYPE_CONDUCT_ADD",
  SUCCESS_TYPE_CONDUCT_ADD = "typeConducts/SUCCESS_TYPE_CONDUCT_ADD",
  FAILURE_TYPE_CONDUCT_ADD = "typeConducts/FAILURE_TYPE_CONDUCT_ADD",
  //PUT
  REQUEST_TYPE_CONDUCT_EDIT = "typeConducts/REQUEST_TYPE_CONDUCT_EDIT",
  SUCCESS_TYPE_CONDUCT_EDIT = "typeConducts/SUfCCESS_TYPE_CONDUCT_EDIT",
  FAILURE_TYPE_CONDUCT_EDIT = "typeConducts/FAILURE_TYPE_CONDUCT_EDIT",
  //DELETE
  REQUEST_TYPE_CONDUCT_DELETE = "typeConducts/REQUEST_TYPE_CONDUCT_DELETE",
  SUCCESS_TYPE_CONDUCT_DELETE = "typeConducts/SUCCESS_TYPE_CONDUCT_DELETE",
  FAILURE_TYPE_CONDUCT_DELETE = "typeConducts/FAILURE_TYPE_CONDUCT_DELETE",
}

export type TypeConductType = {
  idTipoConduta: number | null;
  titulo: string;
  descricao: string;
  flagAtivo: boolean;
};

interface ITypeConductState {
  readonly data: TypeConductType[];
  readonly loading: boolean;
  readonly loadingAction: boolean;
  readonly error: boolean;
  readonly success: boolean;
}

export type DataTypes = ITypeConductState;

/**
 * Actions
 */

//GET
export interface IRequestTypeConductLoadAction {
  type: typeof Types.REQUEST_TYPES_CONDUCTS_LOAD;
}

export interface ISuccessTypeConductLoadAction {
  type: typeof Types.SUCCESS_TYPES_CONDUCTS_LOAD;
  payload: TypeConductType[];
}

interface IFailureTypeConductLoadAction {
  type: typeof Types.FAILURE_TYPES_CONDUCTS_LOAD;
  message: string;
}

type TypeConductLoadAction =
  | IRequestTypeConductLoadAction
  | ISuccessTypeConductLoadAction
  | IFailureTypeConductLoadAction;

//ADD
export interface IRequestTypeConductAddAction {
  type: typeof Types.REQUEST_TYPE_CONDUCT_ADD;
  typeConduct: TypeConductType;
}

export interface ISuccessTypeConductAddAction {
  type: typeof Types.SUCCESS_TYPE_CONDUCT_ADD;
  typeConduct: TypeConductType;
}

interface IFailureTypeConductAddAction {
  type: typeof Types.FAILURE_TYPE_CONDUCT_ADD;
  message: string;
}

type TypeConductAddAction =
  | IRequestTypeConductAddAction
  | ISuccessTypeConductAddAction
  | IFailureTypeConductAddAction;

//PUT
export interface IRequestTypeConductEditAction {
  type: typeof Types.REQUEST_TYPE_CONDUCT_EDIT;
  typeConduct: TypeConductType;
}

export interface ISuccessTypeConductEditAction {
  type: typeof Types.SUCCESS_TYPE_CONDUCT_EDIT;
  typeConduct: TypeConductType;
}

export interface IFailureTypeConductEditAction {
  type: typeof Types.FAILURE_TYPE_CONDUCT_EDIT;
  message: string;
}

type TypeConductEditAction =
  | IRequestTypeConductEditAction
  | ISuccessTypeConductEditAction
  | IFailureTypeConductEditAction;

//DELETE
export interface IRequestTypeConductDeleteAction {
  type: typeof Types.REQUEST_TYPE_CONDUCT_DELETE;
  idTipoConduta: number;
}

export interface ISuccessTypeConductDeleteAction {
  type: typeof Types.SUCCESS_TYPE_CONDUCT_DELETE;
  idTipoConduta: number;
}

export interface IFailureTypeConductDeleteAction {
  type: typeof Types.FAILURE_TYPE_CONDUCT_DELETE;
  message: string;
}

type TypeConductDeleteAction =
  | IRequestTypeConductDeleteAction
  | ISuccessTypeConductDeleteAction
  | IFailureTypeConductDeleteAction;

export type TypeConductActionTypes =
  | TypeConductLoadAction
  | TypeConductAddAction
  | TypeConductEditAction
  | TypeConductDeleteAction;
