import React, { Component } from 'react'
import NoItems from '~/components/layout/NoItems';

interface IErrorBoundaryState {
    hasError: boolean;
}

export default class ErrorBoundary extends Component<{}, IErrorBoundaryState> {
    constructor(props: any) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: any) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error: any, errorInfo: any) {
        // You can also log the error to an error reporting service
        console.log(error)
        console.log(errorInfo)

        // logErrorToMyService(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <NoItems
                    error={true}
                    text="Ocorreu um erro. Caso o erro persista entre em contato com o suporte técnico"
                />
            );
        }

        return this.props.children;
    }
}