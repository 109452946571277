// #region Imports
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

//FluentUI
import { Text } from "office-ui-fabric-react/lib/Text";
import { Stack } from "office-ui-fabric-react/lib/Stack";
import {
  DetailsListLayoutMode,
  SelectionMode,
  Selection,
  IColumn,
} from "office-ui-fabric-react/lib/DetailsList";
import { ShimmeredDetailsList } from "office-ui-fabric-react/lib/ShimmeredDetailsList";

import { CycleGroupType } from "~/store/ducks/cycle/groups/types";

import { Creators as getAvaliationHistory } from "~/store/ducks/avaliation";
import { Creators as getAvaliationById } from "~/store/ducks/avaliation";

import {
  AvaliacaoGestorHistoryType,
} from "~/store/ducks/avaliation/types";

import { RootState } from "~/store/ducks";
import { AvaliationType } from "~/store/ducks/tasks/types";
import { IconButton } from "@fluentui/react";
import { Icon } from "office-ui-fabric-react";
import { AvaliacoesHistType } from "~/store/ducks/merit/types";
import { DialogPerformance } from "~/pages/Calibration/DialogPerformance";
//#endregion

interface IFormSearchState {
  columns: IColumn[];
  idCiclo: string;
  items: Partial<CycleGroupType>[];
  selectionDetails: string;
  selectionCount: number;
  dialogAvaliacao: {
    isOpen: boolean;
    title: string;
    idCicloAvaliacao: number | null;
  };
}

interface IPropsFormSearch {
  avaliacoes: AvaliacoesHistType[];
  isLoading: boolean;
  nomeCompleto: string;
}

class GridAvaliacoes extends Component<IPropsFormSearch, IFormSearchState> {
  private _selection: Selection;

  constructor(props: IPropsFormSearch) {
    super(props);
    //#region Colunas
    const columns: IColumn[] = [
      {
        key: "column1",
        name: "Ciclo",
        ariaLabel: "ciclo",
        fieldName: "ciclo.ano",
        isRowHeader: true,
        minWidth: 35,
        maxWidth: 50,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        onRender: (item: AvaliacoesHistType) => (
          <Stack
            horizontal
            verticalAlign="center"
            styles={{ root: { whiteSpace: "break-spaces", height: "100%" } }}
          >
            <Text variant="medium" styles={{ root: { fontWeight: "inherit" } }}>
              {item.ciclo?.ano}
            </Text>
          </Stack>
        ),
      },
      {
        key: "column2",
        name: "Performance",
        fieldName: "cargo.titulo",
        minWidth: 150,
        isRowHeader: true,
        isResizable: true,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "string",
        isPadded: true,
        onRender: (item: AvaliacoesHistType) => (
          <Stack
            horizontal
            verticalAlign="center"
            styles={{ root: { whiteSpace: "break-spaces", height: "100%" } }}
          >
            <Text variant="medium" styles={{ root: { fontWeight: "inherit" } }}>
              {item?.cargo?.titulo}
            </Text>
          </Stack>
        ),
      },
      {
        key: "column3",
        name: "Performance",
        fieldName: "performance.descNivel",
        minWidth: 150,
        maxWidth: 150,
        isRowHeader: true,
        isResizable: true,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "string",
        isPadded: true,
        onRender: (item: AvaliacoesHistType) => (
          <Stack
            horizontal
            verticalAlign="center"
            styles={{ root: { whiteSpace: "break-spaces", height: "100%" } }}
          >
            <Icon
              iconName="CircleFill"
              styles={{ root: { color: item.performance.cor, marginRight: 8 } }}
            />
            <Text
              variant="medium"
              styles={{ root: { fontWeight: "inherit", width: "100%" } }}
            >
              {item?.performance?.descNivel}
            </Text>
            {item.idCicloAvaliacao && (
              <IconButton
                iconProps={{ iconName: "View" }}
                title={"Visualizar"}
                ariaLabel={"Visualizar"}
                onClick={() => {
                  this.setState({
                    dialogAvaliacao: {
                      isOpen: true,
                      title: `${this.props.nomeCompleto} - ${item.ciclo.ano}`,
                      idCicloAvaliacao: item.idCicloAvaliacao ?? null,
                    },
                  });
                }}
              />
            )}
          </Stack>
        ),
      },
      {
        key: "column4",
        name: "Potencial",
        fieldName: "potencial",
        minWidth: 150,
        maxWidth: 150,
        isRowHeader: true,
        isResizable: true,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "string",
        isPadded: true,
        onRender: (item: AvaliacoesHistType) => (
          <Stack
            horizontal
            verticalAlign="center"
            styles={{ root: { whiteSpace: "break-spaces", height: "100%" } }}
          >
            <Text variant="medium" styles={{ root: { fontWeight: "inherit" } }}>
              {item?.potencial}
            </Text>
          </Stack>
        ),
      },
      {
        key: "column5",
        name: "Sucessão",
        fieldName: "sucessao",
        minWidth: 100,
        isRowHeader: true,
        isResizable: true,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "string",
        isPadded: true,
        onRender: (item: AvaliacaoGestorHistoryType) => (
          <Stack
            horizontal
            verticalAlign="center"
            styles={{ root: { whiteSpace: "break-spaces", height: "100%" } }}
          >
            <Text variant="medium" styles={{ root: { fontWeight: "inherit" } }}>
              {item?.sucessao}
            </Text>
          </Stack>
        ),
      },
    ];
    //#endregion

    this.state = {
      columns: columns,
      items: [],
      selectionDetails: "",
      selectionCount: 0,
      idCiclo: "",
      dialogAvaliacao: {
        isOpen: false,
        title: "",
        idCicloAvaliacao: null,
      },
    };

    this._selection = new Selection({
      onSelectionChanged: () => {
        this.setState({
          selectionDetails: this._getSelectionDetails(),
        });
      },
    });
  }

  _onItemInvoked = (item: CycleGroupType): void => { };

  _getSelectionDetails(): any {
    const selectionCount = this._selection.getSelectedCount();
    this.setState({ selectionCount });
    this._selection.getSelection();
  }

  //#endregion

  render() {
    const { columns, dialogAvaliacao } = this.state;
    const { avaliacoes, isLoading } = this.props;

    return (
      <>
        <Stack styles={{ root: { overflowY: "auto" } }}>
          <ShimmeredDetailsList
            items={avaliacoes ?? []}
            enableShimmer={isLoading}
            columns={columns}
            selectionMode={SelectionMode.none}
            selection={this._selection}
            getKey={this._getKey}
            selectionPreservedOnEmptyClick={true}
            setKey="multiple"
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible={true}
            onItemInvoked={this._onItemInvoked}
          />
        </Stack>
        <DialogPerformance
          isOpen={dialogAvaliacao.isOpen}
          title={dialogAvaliacao.title}
          onClose={() =>
            this.setState({
              dialogAvaliacao: {
                isOpen: false,
                title: "",
                idCicloAvaliacao: null,
              },
            })
          }
          idCicloAvaliacao={dialogAvaliacao.idCicloAvaliacao}
        />
      </>
    );
  }

  private _onColumnClick = (
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn
  ): void => {
    const { columns } = this.state;
    const newColumns: IColumn[] = columns.slice();
    let items: Partial<AvaliationType>[] = [];
    items = this.props.avaliacoes;
    const currColumn: IColumn = newColumns.filter(
      (currCol) => column.key === currCol.key
    )[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    const newItems = this._sort(
      items,
      currColumn.fieldName!,
      currColumn.isSortedDescending
    );
    this.setState({
      columns: newColumns,
      items: newItems,
    });
  };

  private _getKey(item: any, index?: number): any {
    if (item !== undefined) return item.key;
  }

  private _sort<T>(
    items: T[],
    columnKey: string,
    isSortedDescending?: boolean
  ): T[] {
    const result = (prop: string, obj: T) =>
      prop.split(".").reduce((a: any, b: any) => a[b] ?? "", obj);
    return items.sort((a: T, b: T) =>
      (
        isSortedDescending
          ? result(columnKey, a) < result(columnKey, b)
          : result(columnKey, a) > result(columnKey, b)
      )
        ? 1
        : -1
    );
  }
}
const mapStateToProps = (state: RootState) => ({
  avaliations: state.cycleAvaliationReducer,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      ...getAvaliationHistory,
      ...getAvaliationById,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(GridAvaliacoes);
