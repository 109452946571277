import api from "~/services/api";
import { takeEvery, put, call, all } from "redux-saga/effects";
import {
  Types,
  IRequestSaveSelfEvaluationAction,
  IRequestSendSelfEvaluationAction,
  ISuccessSaveSelfEvaluationAction,
  ISuccessSendSelfEvaluationAction
} from "../ducks/selfEvaluations/types";

const apiCallSave = (payload: IRequestSaveSelfEvaluationAction["payload"]) => {
  return api.put(`avaliacao/${payload.idCicloAvaliacao}`, {
    ...payload.data
  })
    .then(response => response.data)
    .catch(err => {
      throw err.response;
    });
};

const apiCallSend = (payload: IRequestSendSelfEvaluationAction["payload"]) => {
  return api.put(`avaliacao/${payload.idCicloAvaliacao}/enviar`, {
    ...payload.data
  })
    .then(response => response.data)
    .catch(err => {
      throw err.response;
    });
};

function* save(action: IRequestSaveSelfEvaluationAction) {
  try {
    yield call(apiCallSave, action.payload);
    yield put({
      type: Types.SUCCESS_SELF_EVALUATION_SAVE,
    } as ISuccessSaveSelfEvaluationAction);
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível salvar a tarefa.';
    yield put({ type: Types.FAILURE_SELF_EVALUATION_SAVE, message });
  };
};

function* send(action: IRequestSendSelfEvaluationAction) {
  try {
    yield call(apiCallSend, action.payload);
    yield put({
      type: Types.SUCCESS_SELF_EVALUATION_SEND,
    } as ISuccessSendSelfEvaluationAction);
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível enviar a tarefa.';
    yield put({ type: Types.FAILURE_SELF_EVALUATION_SEND, message });
  };
};

export default function* selfEvaluationRoot() {
  yield all([
    yield takeEvery(Types.REQUEST_SELF_EVALUATION_SAVE, save),
    yield takeEvery(Types.REQUEST_SELF_EVALUATION_SEND, send),
  ]);
};
