import { takeEvery, put, call, all } from "redux-saga/effects";
import {
    Types,
    SalaryStructureGS,
    SalaryStructureType,
    IRequestSalaryStructureLoadAction,
    IRequestSalaryStructureAddAction,
    IRequestSalaryStructureEditAction,
    IRequestSalaryStructureDelAction,
    IRequestSalaryStructureLoadByIdAction,
    IRequestSalaryStructureLoadGSAction,
    IRequestSalaryStructureAddTabAction,
    IRequestSalaryStructureAddGSAction,
    IRequestSalaryStructureEditGSAction,
    IRequestSalaryStructureDelGSAction,
    IRequestSalaryStructureLoadGSByIdAction,
    IRequestEsGetInfoSalarialAction,
    ISuccessSalaryStructureLoadAction,
    ISuccessSalaryStructureAddAction,
    ISuccessSalaryStructureEditAction,
    ISuccessSalaryStructureDelAction,
    ISuccessSalaryStructureLoadByIdAction,
    ISuccessSalaryStructureLoadGSAction,
    ISuccessSalaryStructureAddTabAction,
    ISuccessSalaryStructureAddGSAction,
    ISuccessSalaryStructureEditGSAction,
    ISuccessSalaryStructureDelGSAction,
    ISuccessSalaryStructureLoadGSByIdAction,
    ISuccessEsGetInfoSalarialAction,
    GS
} from "../../ducks/admin/salaryStructure/types";
import api from "~/services/api";

const apiCallGet = (search: string) => {
    return api
        .get(`es`, {
            params: {
                SearchText: search
            }
        })
        .then((response) => response.data)
        .catch((err) => {
            throw err.response;
        });
};

const apiCallGetById = (idES: number) => {
    return api
        .get(`es/${idES}`)
        .then((response) => response.data)
        .catch((err) => {
            throw err.response;
        });
};

const apiCallAdd = (payload: IRequestSalaryStructureAddAction["payload"]) => {
    return api
        .post(`es`, { ...payload })
        .then((response) => response.data)
        .catch((err) => {
            throw err.response;
        });
};

const apiCallEdit = (payload: IRequestSalaryStructureEditAction["payload"]) => {
    return api
        .put(`es/${payload.idES}`, {
            ...payload
        })
        .then((response) => response.data)
        .catch((err) => {
            throw err.response;
        });
};

const apiCallDel = (idES: number) => {
    return api
        .delete(`es/${idES}`)
        .then((response) => response.data)
        .catch((err) => {
            throw err.response;
        });
};

const apiCallLoadGS = (payload: IRequestSalaryStructureLoadGSAction["payload"]) => {
    return api
        .get(`es/${payload.idES}/tab/${payload.idESTab}/gs`)
        .then((response) => response.data)
        .catch((err) => {
            throw err.response;
        })
}

const apiCallLoadGSById = (payload: IRequestSalaryStructureLoadGSByIdAction["payload"]) => {
    return api
        .get(`es/${payload.idES}/tab/${payload.idESTab}/gs/${payload.idGS}`)
        .then((response) => response.data)
        .catch((err) => {
            throw err.response;
        })
}

const apiCallAddGS = (payload: IRequestSalaryStructureAddGSAction["payload"]) => {
    return api
        .post(`es/${payload.idES}/tab/${payload.idESTab}/gs`, {
            ...payload.gs
        })
        .then((response) => response.data)
        .catch((err) => {
            throw err.response;
        })
}

const apiCallEditGS = (payload: IRequestSalaryStructureEditGSAction["payload"]) => {
    return api
        .put(`es/${payload.idES}/tab/${payload.idESTab}/gs/${payload.gs.idGS}`, {
            ...payload.gs
        })
        .then((response) => response.data)
        .catch((err) => {
            throw err.response;
        })
}

const apiCallDelGS = (payload: IRequestSalaryStructureDelGSAction["payload"]) => {
    return api
        .delete(`es/${payload.idES}/tab/${payload.idESTab}/gs/${payload.idGS}`)
        .then((response) => response.data)
        .catch((err) => {
            throw err.response;
        })
}

const apiCallAddTab = (payload: IRequestSalaryStructureAddTabAction["payload"]) => {
    return api
        .post(`es/${payload.idES}/tab`, {
            ...payload
        })
        .then((response) => response.data)
        .catch((err) => {
            throw err.response;
        })
}

const apiCallGetInfoSalarial = (payload: IRequestEsGetInfoSalarialAction["payload"]) => {
    return api
        .get(`es/${payload.idES}/infoSalarial/${payload.gs}`)
        .then((response) => response.data)
        .catch((err) => {
            throw err.response;
        })
}

function* load(action: IRequestSalaryStructureLoadAction) {
    try {
        let items: SalaryStructureType[] = yield call(apiCallGet, action.search);
        yield put({
            type: Types.SUCCESS_ES_LOAD,
            payload: items
        } as ISuccessSalaryStructureLoadAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível carregar as estruturas salariais'
        yield put({
            type: Types.FAILURE_ES_LOAD,
            message
        });
    }
}

function* loadById(action: IRequestSalaryStructureLoadByIdAction) {
    try {
        let item: SalaryStructureType = yield call(apiCallGetById, action.idES);
        yield put({
            type: Types.SUCCESS_ES_LOAD_BY_ID,
            payload: item
        } as ISuccessSalaryStructureLoadByIdAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível carregar a estruturas salarial'
        yield put({
            type: Types.FAILURE_ES_LOAD_BY_ID,
            message
        });
    }
}

function* add(action: IRequestSalaryStructureAddAction) {
    try {
        let idES: { idES: number } = yield call(apiCallAdd, action.payload);
        yield put({
            type: Types.SUCCESS_ES_ADD,
            payload: {
                ...action.payload,
                ...idES
            }
        } as ISuccessSalaryStructureAddAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível adicionar a estrutura salarial'
        yield put({
            type: Types.FAILURE_ES_ADD,
            message
        });
    }
}

function* edit(action: IRequestSalaryStructureEditAction) {
    try {
        yield call(apiCallEdit, action.payload);
        yield put({
            type: Types.SUCCESS_ES_EDIT,
            payload: action.payload
        } as ISuccessSalaryStructureEditAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível editar a estrutura salarial'
        yield put({
            type: Types.FAILURE_ES_EDIT,
            message
        });
    }
}

function* del(action: IRequestSalaryStructureDelAction) {
    try {
        yield call(apiCallDel, action.idES);
        yield put({
            type: Types.SUCCESS_ES_DEL,
            idES: action.idES
        } as ISuccessSalaryStructureDelAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível remover a estrutura salarial'
        yield put({
            type: Types.FAILURE_ES_EDIT,
            message
        });
    }
}

function* loadGS(action: IRequestSalaryStructureLoadGSAction) {
    try {
        const data: SalaryStructureGS = yield call(apiCallLoadGS, action.payload);
        yield put({
            type: Types.SUCCESS_ES_LOAD_GS,
            payload: data
        } as ISuccessSalaryStructureLoadGSAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível carregar os grupos salariais'
        yield put({
            type: Types.FAILURE_ES_LOAD_GS,
            message
        });
    }
}

function* loadGSById(action: IRequestSalaryStructureLoadGSByIdAction) {
    try {
        const data: GS = yield call(apiCallLoadGSById, action.payload);
        yield put({
            type: Types.SUCCESS_ES_LOAD_GS_BY_ID,
            payload: data
        } as ISuccessSalaryStructureLoadGSByIdAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível carregar o grupo salarial'
        yield put({
            type: Types.FAILURE_ES_LOAD_GS_BY_ID,
            message
        });
    }
}

function* addGS(action: IRequestSalaryStructureAddGSAction) {
    try {
        const gs: GS = yield call(apiCallAddGS, action.payload);
        yield put({
            type: Types.SUCCESS_ES_ADD_GS,
            payload: gs
        } as ISuccessSalaryStructureAddGSAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível adicionar o GS'
        yield put({
            type: Types.FAILURE_ES_ADD_GS,
            message
        });
    }
}

function* editGS(action: IRequestSalaryStructureEditGSAction) {
    try {
        const gs: GS = yield call(apiCallEditGS, action.payload);
        yield put({
            type: Types.SUCCESS_ES_EDIT_GS,
            payload: gs
        } as ISuccessSalaryStructureEditGSAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível editar o GS'
        yield put({
            type: Types.FAILURE_ES_EDIT_GS,
            message
        });
    }
}

function* delGS(action: IRequestSalaryStructureDelGSAction) {
    try {
        yield call(apiCallDelGS, action.payload);
        yield put({
            type: Types.SUCCESS_ES_DEL_GS,
            idGS: action.payload.idGS
        } as ISuccessSalaryStructureDelGSAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível excluír o GS'
        yield put({
            type: Types.FAILURE_ES_DEL_GS,
            message
        });
    }
}

function* addEsTab(action: IRequestSalaryStructureAddTabAction) {
    try {
        const idESTab: { idESTab: number } = yield call(apiCallAddTab, action.payload);
        yield put({
            type: Types.SUCCESS_ES_TAB,
            payload: {
                ...idESTab,
                dataTab: action.payload.dataTab
            }
        } as ISuccessSalaryStructureAddTabAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível adicionar tabela'
        yield put({
            type: Types.FAILURE_ES_TAB,
            message
        });
    }
}

function* getInfoSalarial(action: IRequestEsGetInfoSalarialAction) {
    try {
        const data: Partial<GS> = yield call(apiCallGetInfoSalarial, action.payload);
        yield put({
            type: Types.SUCCESS_ES_INFO_SALARIAL,
            payload: data
        } as ISuccessEsGetInfoSalarialAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível carregar as informações salariais'
        yield put({
            type: Types.FAILURE_ES_INFO_SALARIAL,
            message
        });
    }
}

export default function* salaryStructureRoot() {
    yield all([
        yield takeEvery(Types.REQUEST_ES_LOAD, load),
        yield takeEvery(Types.REQUEST_ES_LOAD_BY_ID, loadById),
        yield takeEvery(Types.REQUEST_ES_ADD, add),
        yield takeEvery(Types.REQUEST_ES_EDIT, edit),
        yield takeEvery(Types.REQUEST_ES_DEL, del),
        yield takeEvery(Types.REQUEST_ES_LOAD_GS, loadGS),
        yield takeEvery(Types.REQUEST_ES_LOAD_GS_BY_ID, loadGSById),
        yield takeEvery(Types.REQUEST_ES_ADD_GS, addGS),
        yield takeEvery(Types.REQUEST_ES_EDIT_GS, editGS),
        yield takeEvery(Types.REQUEST_ES_DEL_GS, delGS),
        yield takeEvery(Types.REQUEST_ES_TAB, addEsTab),
        yield takeEvery(Types.REQUEST_ES_INFO_SALARIAL, getInfoSalarial),
    ]);
}
