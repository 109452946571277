import { takeEvery, put, call, all } from "redux-saga/effects";
import {
  Types,
  MeritGenerateType,
  IRequestMeritGenerateLoadAction,
  IRequestMeritGenerateAddAction,
  IRequestMeritGenerateDelAction,
  ISuccessMeritGenerateLoadAction,
  ISuccessMeritGenerateAddAction,
  ISuccessMeritGenerateDelAction,
  IRequestMeritGenerateFinalizarAction,
  ISuccessMeritGenerateFinalizarAction,
  IRequestMeritReabrirReuniaoAction,
  ISuccessMeritReabrirReuniaoAction,
} from "../ducks/meritGenerate/types";
import api from "~/services/api";

const apiCallGet = () => {
  return api
    .get(`merito`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallAdd = (payload: IRequestMeritGenerateAddAction["payload"]) => {
  return api
    .post(`merito`, {
      ...payload,
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallDel = (idMerito: number) => {
  return api
    .delete(`merito/${idMerito}`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallFinalizar = (idMerito: number) => {
  return api
    .post(`merito/${idMerito}/finalizar`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallReabrir = (idMerito: number) => {
  return api
    .post(`merito/${idMerito}/reabrir`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

function* load(action: IRequestMeritGenerateLoadAction) {
  try {
    let items: MeritGenerateType[] = yield call(apiCallGet);
    yield put({
      type: Types.SUCCESS_GENERATE_MERIT_LOAD,
      payload: items,
    } as ISuccessMeritGenerateLoadAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível carregar os méritos";
    yield put({
      type: Types.FAILURE_GENERATE_MERIT_LOAD,
      message,
    });
  }
}

function* add(action: IRequestMeritGenerateAddAction) {
  try {
    let data: MeritGenerateType = yield call(apiCallAdd, action.payload);
    yield put({
      type: Types.SUCCESS_GENERATE_MERIT_ADD,
      idMerito: data.idMerito,
    } as ISuccessMeritGenerateAddAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível adicionar o mérito";
    yield put({
      type: Types.FAILURE_GENERATE_MERIT_ADD,
      message,
    });
  }
}

function* del(action: IRequestMeritGenerateDelAction) {
  try {
    yield call(apiCallDel, action.idMerito);
    yield put({
      type: Types.SUCCESS_GENERATE_MERIT_DEL,
      idMerito: action.idMerito,
    } as ISuccessMeritGenerateDelAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível excluír o mérito";
    yield put({
      type: Types.FAILURE_GENERATE_MERIT_DEL,
      message,
    });
  }
}

function* finalizar(action: IRequestMeritGenerateFinalizarAction) {
  try {
    const data = yield call(apiCallFinalizar, action.idMerito);
    yield put({
      type: Types.SUCCESS_GENERATE_MERIT_FINALIZAR,
      payload: {
        ...data,
        idMerito: action.idMerito,
      },
    } as ISuccessMeritGenerateFinalizarAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível finalizar o mérito";
    yield put({
      type: Types.FAILURE_GENERATE_MERIT_FINALIZAR,
      message,
    });
  }
}

function* reabrir(action: IRequestMeritReabrirReuniaoAction) {
  try {
    const data = yield call(apiCallReabrir, action.payload.idMerito);
    yield put({
      type: Types.SUCCESS_GENERATE_MERIT_REABRIR,
      payload: {
        ...data,
        idMerito: action.payload.idMerito,
      },
    } as ISuccessMeritReabrirReuniaoAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível reabrir o mérito";
    yield put({
      type: Types.FAILURE_GENERATE_MERIT_REABRIR,
      message,
    });
  }
}

export default function* meritGenerateRoot() {
  yield all([
    yield takeEvery(Types.REQUEST_GENERATE_MERIT_LOAD, load),
    yield takeEvery(Types.REQUEST_GENERATE_MERIT_ADD, add),
    yield takeEvery(Types.REQUEST_GENERATE_MERIT_DEL, del),
    yield takeEvery(Types.REQUEST_GENERATE_MERIT_FINALIZAR, finalizar),
    yield takeEvery(Types.REQUEST_GENERATE_MERIT_REABRIR, reabrir),
  ]);
}
