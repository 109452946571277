import { Types, DataTypes, CycleType, CyclesActionTypes } from "./types";
import customToast from "~/components/Toast/index";

/**
 * Reducers
 */
const INITIAL_STATE: DataTypes = {
  data: [],
  loadingData: false,
  loadingAction: false,
  success: false,
  error: false,
  itemSelected: {
    item: null,
    loading: false,
    success: false,
    error: false,
  },
};

/**
 * Actions
 */

export const Creators = {
  getCycles: (search?: string): CyclesActionTypes => {
    search = search ? search : "";
    return {
      type: Types.REQUEST_CYCLES_LOAD,
      search,
    };
  },

  getCycleById: (id: number): CyclesActionTypes => ({
    type: Types.REQUEST_CYCLES_LOAD_ID,
    id,
  }),

  addCycle: (cycle: CycleType): CyclesActionTypes => ({
    type: Types.REQUEST_CYCLES_ADD,
    cycle,
  }),

  editCycle: (cycle: Partial<CycleType>): CyclesActionTypes => ({
    type: Types.REQUEST_CYCLES_EDIT,
    cycle,
  }),

  delCycle: (id: number): CyclesActionTypes => ({
    type: Types.REQUEST_CYCLES_DELETE,
    id,
  }),

  updateOrg: (idCiclo: number): CyclesActionTypes => ({
    type: Types.REQUEST_CYCLES_ATUALIZAR_ORG,
    idCiclo,
  }),
};

export default function cyclesReducer(
  state = INITIAL_STATE,
  action: CyclesActionTypes
): DataTypes {
  switch (action.type) {
    case Types.REQUEST_CYCLES_LOAD:
      return { ...state, data: [], loadingData: true, error: false };
    case Types.SUCCESS_CYCLES_LOAD:
      return {
        ...state,
        data: action.payload,
        loadingData: false,
        error: false,
      };
    case Types.FAILURE_CYCLES_LOAD:
      customToast.error(action.message);
      return { ...state, data: [], loadingData: false, error: true };

    case Types.REQUEST_CYCLES_LOAD_ID:
      return {
        ...state,
        itemSelected: {
          loading: true,
          success: false,
          error: false,
          item: null,
        },
      };
    case Types.SUCCESS_CYCLES_LOAD_ID:
      return {
        ...state,
        itemSelected: {
          loading: false,
          success: true,
          error: false,
          item: action.payload,
        },
      };
    case Types.FAILURE_CYCLES_LOAD_ID:
      customToast.error(action.message);
      return {
        ...state,
        itemSelected: {
          loading: false,
          success: false,
          error: true,
          item: null,
        },
      };

    case Types.REQUEST_CYCLES_ADD:
      return {
        ...state,
        loadingAction: true,
        error: false,
        success: false,
      };
    case Types.SUCCESS_CYCLES_ADD:
      customToast.success("Ciclo cadastrado com sucesso");
      return {
        ...state,
        loadingAction: false,
        error: false,
        data: [...state.data, action.cycle],
        success: true,
      };
    case Types.FAILURE_CYCLES_ADD:
      customToast.error(action.message);
      return {
        ...state,
        loadingAction: false,
        error: true,
        success: false,
      };

    case Types.REQUEST_CYCLES_EDIT:
      return {
        ...state,
        loadingAction: true,
        error: false,
        success: false,
      };
    case Types.SUCCESS_CYCLES_EDIT:
      customToast.success("Ciclo editado com sucesso");
      const { cycle } = action;
      return {
        ...state,
        loadingAction: false,
        error: false,
        success: true,
        data: state.data.map((item) => {
          if (item.idCiclo !== cycle.idCiclo) {
            return item;
          } else {
            return {
              ...item,
              ...cycle,
            };
          }
        }),
        itemSelected: {
          ...state.itemSelected,
          item: action.cycle,
        },
      };
    case Types.FAILURE_CYCLES_EDIT:
      customToast.error(action.message);
      return {
        ...state,
        loadingAction: false,
        error: true,
        success: false,
      };

    case Types.REQUEST_CYCLES_DELETE:
      return { ...state, loadingData: true, error: false };
    case Types.SUCCESS_CYCLES_DELETE:
      customToast.success("Item excluído com sucesso");
      return {
        ...state,
        loadingData: false,
        error: false,
        data: state.data.filter((item) => item.idCiclo !== action.id),
      };
    case Types.FAILURE_CYCLES_DELETE:
      customToast.error(action.message);
      return { ...state, loadingData: false, error: true };

    case Types.REQUEST_CYCLES_ATUALIZAR_ORG:
      return {
        ...state,
        loadingAction: true,
        success: false,
        error: false,
      };
    case Types.SUCCESS_CYCLES_ATUALIZAR_ORG:
      customToast.success("Organograma atualizado com sucesso!");
      return {
        ...state,
        itemSelected: {
          ...state.itemSelected,
          item: {
            ...state.itemSelected.item!,
            dataHoraAtuOrg: action.payload,
          },
        },
        loadingAction: false,
        success: true,
        error: false,
      };
    case Types.FAILURE_CYCLES_ATUALIZAR_ORG:
      customToast.error(action.message);
      return {
        ...state,
        loadingAction: false,
        success: false,
        error: true,
      };

    default:
      return state;
  }
}
