// #region Imports
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter, RouteComponentProps } from "react-router-dom";

import moment from "moment";
import { Formik } from "formik";
import * as yup from "yup";

//FluentUI
import CustomDialogUI from "~/components/CustomDialogFluentUI";
import {
  DetailsListLayoutMode,
  SelectionMode,
  Selection,
  IColumn,
} from "office-ui-fabric-react/lib/DetailsList";
import { ShimmeredDetailsList } from "office-ui-fabric-react/lib/ShimmeredDetailsList";
import {
  CommandBarButton,
  IButtonStyles,
  ICommandBarStyles,
  IconButton,
} from "office-ui-fabric-react";
import { IDialogContentProps } from "office-ui-fabric-react/lib/Dialog";
import { MessageBar, MessageBarType, Stack } from "@fluentui/react";

import { Text } from 'office-ui-fabric-react/lib/Text';
import {
  GroupItems,
  TitleField,
} from "~/components/FormGroup";
import Dialog from "~/components/CustomDialog";
import CustomDialog from "~/components/CustomDialogFluentUI";
import CustomModal from "~/components/CustomDialog";
import { ListItemText, Select, MenuItem } from "@material-ui/core";
import { Page, BreadcrumbItems } from "~/store/ducks/home/types";

import { DeleteButton, PrimaryButton } from "~/components/Buttons";
import HeaderPage from "~/components/layout/HeaderPage";
import NoItems from "~/components/layout/NoItems";
import { CustomDrawer as Drawer } from "~/components/layout/Drawer";
import colors from "~/assets/js/colors";
//Estilos
import {
  Wrapper,
  ListContainer,
  DefaultButton,
  ContainerContent,
  WrapperAutoComplete,
  GroupCheckbox
} from "./styles";

import { DataTypes as DataTypesMeritGenerate } from "~/store/ducks/meritGenerate/types";
import { Creators as getMeritGenerate } from "~/store/ducks/meritGenerate";
import { Creators as addMeritGenerate } from "~/store/ducks/meritGenerate";
import { Creators as delMeritGenerate } from "~/store/ducks/meritGenerate";
import { Creators as finalizarMeritGenerate } from "~/store/ducks/meritGenerate";
import { Creators as reabrirMeritoGenerate } from "~/store/ducks/meritGenerate";

import { DataTypes as DataTypesChart } from "~/store/ducks/admin/chart/types";
import { Creators as getChart } from "~/store/ducks/admin/chart";

import { Creators as setCurrentPage } from "~/store/ducks/home";
import { DataTypes as DataTypesLogin } from "~/store/ducks/login/types";
import { RootState } from "~/store/ducks";
import {
  InputAutocomplete,
  InputCheckbox,
  InputDate,
  InputText,
} from "~/components/Forms";
import { ChartType } from "~/store/ducks/admin/chart/types";
import TreeView from "~/components/TreeView";
import { MeritGenerateType } from "~/store/ducks/meritGenerate/types";
import { FormControlLabel } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
//#endregion

type StatusFilter = {
  "Em andamento": boolean;
  "Aguardando aprovação": boolean;
  "Aguardando finalização": boolean;
  "Finalizada": boolean;
};

type Filter = {
  isOpen?: boolean;
  years: string[];
  status: StatusFilter;
  allYears: string[];
};

interface IMeritsGenerateState {
  columns: IColumn[];
  items: MeritGenerateType[];
  isDialogAddOpen: boolean;
  isFiltering: boolean;
  selectionDetails: string;
  selectionCount: number;
  isDialogExcludeOpen: boolean;
  isDialogReabrir: {
    isOpen: boolean;
    idMerito: number | null;
  };
  isDialogTreeViewOpen: boolean;
  areaSelected: Partial<ChartType> | null;
  itemAreaTreeViewSelected: Partial<ChartType> | null;
  dialogFinalizar: {
    isOpen: boolean;
    idMerito: number | null;
    area: string;
  };
  filter: Filter;
}

interface IPropsMeritsGenerate extends RouteComponentProps {
  meritsGenerate: DataTypesMeritGenerate;
  areas: DataTypesChart;
  login: DataTypesLogin;
  setCurrentPage: (page: Page) => void;

  getMeritGenerate: () => void;
  addMeritGenerate: (meritGenerate: Partial<MeritGenerateType>) => void;
  delMeritGenerate: (idMerito: number) => void;
  finalizarMeritGenerate: (idMerito: number) => void;
  reabrirMeritoGenerate: (idMerito: number) => void;

  getChart: (
    search?: string,
    filter?: boolean | null,
    treeview?: boolean
  ) => void;
  history: RouteComponentProps["history"];
}

const itemsBreadCrumb: BreadcrumbItems[] = [
  {
    text: "Home",
    isCurrentItem: false,
    icon: "HomeSolid",
    onlyIcon: true,
    url: "/",
  },
  { text: "Geração de Mérito", isCurrentItem: true },
];

class MeritGerenate extends Component<
  IPropsMeritsGenerate,
  IMeritsGenerateState
> {
  private formRef: any;
  private _selection: Selection;
  private timeout: number;

  constructor(props: IPropsMeritsGenerate) {
    super(props);
    //#region Colunas
    const columns: IColumn[] = [
      {
        key: "column1",
        name: "Ano",
        ariaLabel: "ano",
        fieldName: "ano",
        isRowHeader: true,
        minWidth: 50,
        maxWidth: 75,
        isResizable: true,
        isSortedDescending: false,
        onColumnClick: this._onColumnClick,
      },
      {
        key: "column2",
        name: "Data",
        ariaLabel: "dataReuniao",
        fieldName: "dataReuniao",
        isRowHeader: true,
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
        isSortedDescending: false,
        onColumnClick: this._onColumnClick,
        onRender: (value: MeritGenerateType) =>
          value.dataReuniao && moment(value.dataReuniao).format("DD/MM/YYYY"),
      },
      {
        key: "column3",
        name: "Área",
        ariaLabel: "area",
        fieldName: "area.nomeLocal",
        isRowHeader: true,
        minWidth: 150,
        isResizable: true,
        isSortedDescending: false,
        onColumnClick: this._onColumnClick,
        onRender: (value: MeritGenerateType) => value.area.nomeLocal,
      },
      {
        key: "column4",
        name: "Diretoria",
        ariaLabel: "diretoria",
        fieldName: "diretoria.nomeLocal",
        isRowHeader: true,
        minWidth: 150,
        maxWidth: 400,
        isResizable: true,
        isSortedDescending: false,
        onColumnClick: this._onColumnClick,
        onRender: (value: MeritGenerateType) => value.diretoria.nomeLocal,
      },
      {
        key: "column5",
        name: "Responsável",
        ariaLabel: "responsavel",
        fieldName: "resp.nomeCompleto",
        isRowHeader: true,
        minWidth: 200,
        maxWidth: 300,
        isResizable: true,
        isSortedDescending: false,
        onColumnClick: this._onColumnClick,
        onRender: (value: MeritGenerateType) => value.resp.nomeCompleto,
      },
      {
        key: "column6",
        name: "Status",
        fieldName: "status",
        minWidth: 150,
        maxWidth: 300,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "string",
        isPadded: true,
      },
    ];
    //#endregion

    this.state = {
      columns: columns,
      items: [],
      isDialogAddOpen: false,
      selectionDetails: "",
      isFiltering: false,
      selectionCount: 0,
      isDialogExcludeOpen: false,
      isDialogTreeViewOpen: false,
      isDialogReabrir: {
        isOpen: false,
        idMerito: null,
      },
      areaSelected: null,
      itemAreaTreeViewSelected: null,
      dialogFinalizar: {
        isOpen: false,
        idMerito: null,
        area: "",
      },
      filter: {
        isOpen: false,
        years: [],
        allYears: [],
        status: {
          "Aguardando aprovação": true,
          "Aguardando finalização": true,
          "Em andamento": true,
          "Finalizada": true,
        }
      }
    };

    this._selection = new Selection({
      onSelectionChanged: () => {
        this.setState({
          selectionDetails: this._getSelectionDetails(),
        });
      },
    });

    this.formRef = React.createRef();
    this.timeout = 0;
  }

  componentDidMount() {
    const page: Page = {
      key: "merito",
      pages: itemsBreadCrumb,
    };
    this.props.setCurrentPage(page);
    this.props.getMeritGenerate();
  }

  componentDidUpdate(prevProps: IPropsMeritsGenerate, _: any) {
    if (
      prevProps.meritsGenerate.successAdd !==
      this.props.meritsGenerate.successAdd
    ) {
      if (this.props.meritsGenerate.successAdd) {
        this.setState({ isDialogAddOpen: false });
        this.props.getMeritGenerate()
        // this.props.history.push(
        //   `/merito/${this.props.meritsGenerate.idMeritoCreated}`
        // );
      }
    }

    if (prevProps.meritsGenerate.success !== this.props.meritsGenerate.success) {
      if (this.props.meritsGenerate.success) {
        const itemsYears = this.props.meritsGenerate.data.map(item => item.ano);
        const years: string[] = itemsYears.filter((element, index) => {
          return itemsYears.indexOf(element) === index;
        });
        this.setState({
          items: this.props.meritsGenerate.data,
          filter: {
            ...this.state.filter,
            status: {
              "Aguardando finalização": true,
              "Aguardando aprovação": true,
              "Em andamento": true,
              "Finalizada": true
            },
            years,
            allYears: years
          },
          isFiltering: false
        })
      }
    }
  }

  // Submit do formulário Formik
  handleSubmit = () => {
    if (this.formRef.current) {
      this.formRef.current.handleSubmit();
    }
  };

  _onItemInvoked = (): void => {
    const idMerito: number = (this._selection.getSelection()[0] as MeritGenerateType)
      .idMerito!;
    this.props.history.push(`/merito/${idMerito}`);
  };

  _getSelectionDetails(): any {
    const selectionCount = this._selection.getSelectedCount();
    this.setState({ selectionCount });
    this._selection.getSelection();
  }

  cancelPanel = () => {
    this._selection.setAllSelected(false);
    this.setState({ isDialogAddOpen: false });
  };

  excludeMeeting = () => {
    this.props.delMeritGenerate(
      (this._selection.getSelection()[0] as MeritGenerateType).idMerito!
    );
    this.setState({ isDialogExcludeOpen: false });
    this._selection.setAllSelected(false);
  };

  search = (text: string) => {
    if (text.trim()) {
      clearTimeout(this.timeout);
      this.timeout = window.setTimeout(() => {
        this.props.getChart(text, true, false);
      }, 700);
    }
  };

  toggleFilter = () => {
    this.setState({
      filter: {
        ...this.state.filter,
        isOpen: !this.state.filter.isOpen,
      }
    });
  };

  handleFilterStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { filter } = this.state;

    const newFilter = {
      ...filter,
      status: {
        ...filter.status,
        [e.target.name]: e.target.checked,
      },
    };

    this.setState({ filter: newFilter }, () => this.handleFilter())
  };

  handleChangeMultiple = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const yearsSelected = event.target.value as string[];

    this.setState({
      filter: { ...this.state.filter, years: yearsSelected },
    }, () => this.handleFilter())

  };

  handleFilter = () => {
    const { years, status } = this.state.filter;
    let itemsFiltered = this.props.meritsGenerate.data ?? [];

    const statusFiltered = Object.entries(status)
      .filter((item) => item[1])
      .map((item) => item[0]);

    itemsFiltered = itemsFiltered.filter(item => {
      return years.includes(item.ano)
    });


    itemsFiltered = itemsFiltered.filter(item => {
      return statusFiltered.includes(item.status)
    })

    this.setState({ items: itemsFiltered, isFiltering: true })
  }

  commandBarRender = () => {
    const { selectionCount } = this.state;
    const reuniaoSelected = this._selection.getSelection()[0] as MeritGenerateType;
    if (selectionCount === 1) {
      return (
        reuniaoSelected.status === "Aguardando aprovação" && (
          <>
            <CommandBarButton
              styles={btnStyle}
              iconProps={{ iconName: "Send" }}
              text="Finalizar"
              onClick={() =>
                this.setState({
                  dialogFinalizar: {
                    isOpen: true,
                    idMerito: reuniaoSelected.idMerito,
                    area: reuniaoSelected.area.nomeLocal,
                  },
                })
              }
            />
            <CommandBarButton
              styles={btnStyle}
              iconProps={{ iconName: "OpenInNewTab" }}
              text="Reabrir Reunião"
              onClick={() => {
                this.setState({
                  isDialogReabrir: {
                    isOpen: true,
                    idMerito: reuniaoSelected.idMerito!,
                  },
                });
              }}
            />
          </>
        )
      );
    }
  };

  render() {
    const {
      isDialogAddOpen,
      isDialogExcludeOpen,
      isFiltering,
      items,
      columns,
      areaSelected,
      isDialogTreeViewOpen,
      itemAreaTreeViewSelected,
      dialogFinalizar,
      isDialogReabrir,
      filter
    } = this.state;
    const {
      getMeritGenerate,
      addMeritGenerate,
      finalizarMeritGenerate,
      meritsGenerate,
      areas,
      login,
    } = this.props;

    const dialogContentProps: IDialogContentProps = {
      title: "Reabrir a reunião?",
      closeButtonAriaLabel: "Close",
      subText: "Tem certeza de que deseja reabrir esta reunião?",
    };

    return (
      <>
        <Wrapper>
          <Drawer
            isOpen={filter.isOpen}
            content={
              <ContainerContent>
                <HeaderPage
                  title="Geração do Mérito"
                  leftItems={
                    login.data.flagAdministrador ? (
                      <>
                        <CommandBarButton
                          styles={btnStyle}
                          iconProps={{ iconName: "Add" }}
                          disabled={meritsGenerate.loadingData}
                          text="Adicionar Mérito"
                          onClick={() =>
                            this.setState({
                              isDialogAddOpen: true,
                              areaSelected: null,
                            })
                          }
                        />
                        {this.commandBarRender()}
                      </>
                    ) : (
                      <React.Fragment />
                    )
                  }
                  rightItems={
                    <>
                      <CommandBarButton
                        styles={btnStyle}
                        iconProps={{ iconName: "Refresh" }}
                        text="Atualizar"
                        onClick={() => getMeritGenerate()}
                      />
                      <CommandBarButton
                        styles={btnStyle}
                        iconProps={{ iconName: 'filter' }}
                        text="Filtrar"
                        onClick={() => this.toggleFilter()}
                      />
                    </>
                  }
                />
                {!meritsGenerate.loadingData &&
                  meritsGenerate.data?.length === 0 ? (
                  <NoItems
                    error={meritsGenerate.error}
                    text="Não há méritos cadastrados"
                    icon="Teamwork"
                  />
                ) : (
                  <ListContainer>
                    <ShimmeredDetailsList
                      items={isFiltering ? items : meritsGenerate.data}
                      enableShimmer={meritsGenerate.loadingData}
                      columns={columns}
                      selectionMode={SelectionMode.single}
                      selection={this._selection}
                      getKey={this._getKey}
                      selectionPreservedOnEmptyClick={true}
                      setKey="single"
                      layoutMode={DetailsListLayoutMode.justified}
                      isHeaderVisible={true}
                      onItemInvoked={this._onItemInvoked}
                    />
                  </ListContainer>
                )}
              </ContainerContent>
            }>
            <>
              <Stack horizontal horizontalAlign="space-between">
                <Text variant="xLarge">Filtros</Text>
                <Stack horizontal>
                  <IconButton
                    iconProps={{ iconName: 'ChromeClose' }}
                    styles={{ root: { color: colors.darkGray } }}
                    title="Fechar"
                    ariaLabel="Fechar"
                    onClick={() => this.setState({ filter: { ...filter, isOpen: false } })}
                  />
                </Stack>
              </Stack>
              <Stack>
                <Text
                  variant="mediumPlus"
                  styles={{ root: { marginBottom: 10 } }}
                >
                  Ano
                </Text>
                <Stack horizontal styles={{ root: { height: 32 } }}>
                  <Select
                    className="selectMultiMerito"
                    variant="outlined"
                    labelWidth={0}
                    label=""
                    name="grupos"
                    value={this.state.filter.years}
                    multiple
                    onChange={this.handleChangeMultiple}
                    disabled={meritsGenerate.loadingData}
                    renderValue={(selected) => {
                      const yearsSelected = this.state.filter.allYears
                        .filter((item) => {
                          return (selected as any[]).some(
                            (anoItem) => anoItem === item
                          );
                        })
                        ?.map((item) => item);
                      return (yearsSelected as string[]).join(", ");
                    }}
                  >
                    {this.state.filter.allYears.map((item, i) => (
                      <MenuItem
                        key={i}
                        value={item}
                        style={{ height: 40 }}
                      >
                        <Checkbox
                          checked={this.state.filter.years.indexOf(item) > -1}
                          style={{ color: colors.primary }}
                        />
                        <ListItemText primary={item} />
                      </MenuItem>
                    ))}
                  </Select>
                </Stack>
              </Stack>
              <GroupCheckbox>
                <Text
                  variant="mediumPlus"
                  styles={{ root: { marginBottom: 10 } }}
                >
                  Status
                </Text>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filter.status["Em andamento"]}
                      onChange={this.handleFilterStatus}
                      name="Em andamento"
                      color="primary"
                    />
                  }
                  label={<span style={{ fontSize: 14 }}>Em andamento</span>}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filter.status["Aguardando aprovação"]}
                      onChange={this.handleFilterStatus}
                      name="Aguardando aprovação"
                      color="primary"
                    />
                  }
                  label={<span style={{ fontSize: 14 }}>Aguardando Aprovação</span>}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filter.status["Aguardando finalização"]}
                      onChange={this.handleFilterStatus}
                      name="Aguardando finalização"
                      color="primary"
                    />
                  }
                  label={<span style={{ fontSize: 14 }}>Aguardando Finalização</span>}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filter.status["Finalizada"]}
                      onChange={this.handleFilterStatus}
                      name="Finalizada"
                      color="primary"
                    />
                  }
                  label={<span style={{ fontSize: 14 }}>Finalizada</span>}
                />
              </GroupCheckbox>
            </>
          </Drawer>
        </Wrapper>

        <CustomDialog
          hidden={!isDialogExcludeOpen}
          onDismiss={() => this.setState({ isDialogExcludeOpen: false })}
          dialogContentProps={dialogContentProps}
        >
          <DefaultButton
            onClick={() => this.setState({ isDialogExcludeOpen: false })}
            text="Cancelar"
          />
          <DeleteButton onClick={() => this.excludeMeeting()} text="Excluir" />
        </CustomDialog>

        <Dialog
          title="Adicionar Mérito"
          open={isDialogAddOpen}
          onClickCancel={() => this.setState({ isDialogAddOpen: false })}
          onClickConfirm={this.handleSubmit}
          btnCancelText="Fechar"
          disabledBtnConfirm={meritsGenerate.loadingAction}
          isLoadingConfirm={meritsGenerate.loadingAction}
          btnConfirmText="Adicionar"
          zIndex={1300}
        >
          <Formik
            innerRef={this.formRef}
            initialValues={initialMerit}
            validationSchema={validationSchema}
            validateOnChange={false}
            validateOnBlur={true}
            onSubmit={(values) => {
              values.ano = String(values.ano);
              values.dataReferencia = moment(values.dataReferencia).format(
                "YYYY-MM-DD"
              );
              values.dataReuniao = moment(values.dataReuniao).format(
                "YYYY-MM-DD"
              );
              addMeritGenerate(values);
            }}
          >
            {({
              handleSubmit,
              handleChange,
              values,
              errors,
              setFieldError,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <InputText
                  value={values.ano ?? ""}
                  onChange={(e) => {
                    handleChange(e);
                    setFieldError("ano", "");
                  }}
                  id="ano"
                  error={!!errors.ano}
                  name="ano"
                  type="number"
                  label="Ano"
                  helperText={errors.ano}
                  autoFocus
                  className="mt-2"
                />
                <InputDate
                  label="Data da Reunião"
                  name="dataReuniao"
                  value={values.dataReuniao ? moment(values.dataReuniao) : null}
                  onChange={(value) => {
                    setFieldValue("dataReuniao", value);
                    setFieldError("dataReuniao", "");
                  }}
                  className="mt-2"
                  error={!!errors.dataReuniao}
                  helperText={errors.dataReuniao}
                />
                <InputDate
                  label="Data de Referência"
                  name="dataReferencia"
                  value={
                    values.dataReferencia ? moment(values.dataReferencia) : null
                  }
                  onChange={(value) => {
                    setFieldValue("dataReferencia", value);
                    setFieldError("dataReferencia", "");
                  }}
                  className="mt-2"
                  error={!!errors.dataReferencia}
                  helperText={errors.dataReferencia}
                />
                <WrapperAutoComplete>
                  <InputAutocomplete
                    value={areaSelected as ChartType}
                    onChange={(_, newValue) => {
                      this.setState({ areaSelected: newValue });
                      setFieldValue("idArea", newValue?.idArea);
                      setFieldError("idArea", "");
                    }}
                    onInputChange={(_, newInputValue) => {
                      this.search(newInputValue);
                    }}
                    getOptionLabel={(area: ChartType) => {
                      if (area.nomeLocal) {
                        return area.nomeLocal;
                      } else {
                        return "";
                      }
                    }}
                    getOptionSelected={(option, value) => {
                      return option.idArea === value.idArea;
                    }}
                    options={areas.data}
                    input={{
                      idInput: "area",
                      labelInput: "Área Mestre",
                      errorInput: !!errors.idArea,
                      helperTextInput: errors.idArea,
                    }}
                  />
                  <IconButton
                    iconProps={{ iconName: "Org" }}
                    styles={IconButtonStyle}
                    onClick={() => {
                      this.props.getChart("", true);
                      this.setState({ isDialogTreeViewOpen: true });
                    }}
                  />
                </WrapperAutoComplete>
                <InputCheckbox
                  checked={values?.flagUnicoNivel ?? false}
                  onChange={(e) => handleChange(e)}
                  name="flagUnicoNivel"
                  color="primary"
                  label="Gerar único nível hierárquico"
                />

                <Dialog
                  open={isDialogTreeViewOpen}
                  title="Escolher área"
                  width={850}
                  btnConfirmText="Selecionar"
                  btnCancelText="Fechar"
                  onClickCancel={() =>
                    this.setState({ isDialogTreeViewOpen: false })
                  }
                  onClickConfirm={() => {
                    setFieldValue("idArea", itemAreaTreeViewSelected?.idArea);
                    setFieldError("idArea", "");
                    this.setState({
                      isDialogTreeViewOpen: false,
                      areaSelected: itemAreaTreeViewSelected,
                    });
                  }}
                  disabledBtnConfirm={itemAreaTreeViewSelected === null}
                  height={500}
                >
                  <TreeView
                    columns={[
                      {
                        name: "Área",
                        fieldName: "nomeLocal",
                      },
                    ]}
                    state={areas}
                    fieldId="idArea"
                    changeItemsSelected={(items) => {
                      console.log(items);
                      this.setState({ itemAreaTreeViewSelected: items[0] });
                    }}
                  />
                </Dialog>
              </form>
            )}
          </Formik>
        </Dialog>

        <CustomModal
          open={dialogFinalizar.isOpen}
          title="Finalizar Reunião"
          onClickCancel={() =>
            this.setState({
              dialogFinalizar: {
                isOpen: false,
                idMerito: null,
                area: "",
              },
            })
          }
          onClickConfirm={() => {
            if (dialogFinalizar.idMerito) {
              finalizarMeritGenerate(dialogFinalizar.idMerito);
            }
            this.setState({
              dialogFinalizar: {
                isOpen: false,
                idMerito: null,
                area: "",
              },
            });
            this._selection.setAllSelected(false);
          }}
          width={640}
        >
          <Stack horizontal horizontalAlign="space-between">
            <GroupItems
              noMargins
              stylesWrapperItems={{ justifyContent: "space-between" }}
            >
              <GroupItems title="Reunião" titleRegular>
                <TitleField text={dialogFinalizar?.area} bold />
              </GroupItems>
            </GroupItems>
          </Stack>
          <MessageBar
            styles={{ root: { marginTop: 10 } }}
            messageBarType={MessageBarType.severeWarning}
          >
            Ao finalizar a reunião, não será mais possível reverter os aumentos.
            Os gestores dos funcionários avaliados receberão uma tarefa de
            DEVOLUTIVA DO MÉRITO para cada funcionário avaliado.
          </MessageBar>
        </CustomModal>
        <CustomDialogUI
          hidden={!isDialogReabrir.isOpen}
          onDismiss={() =>
            this.setState({
              isDialogReabrir: {
                isOpen: false,
                idMerito: null,
              },
            })
          }
          dialogContentProps={dialogContentProps}
        >
          <DefaultButton
            onClick={() =>
              this.setState({
                isDialogReabrir: {
                  isOpen: false,
                  idMerito: null,
                },
              })
            }
            text="Cancelar"
          />
          <PrimaryButton
            onClick={() => {
              this.props.reabrirMeritoGenerate(isDialogReabrir.idMerito!);
              this._selection.setAllSelected(false);
              this.setState({
                isDialogReabrir: {
                  isOpen: false,
                  idMerito: null,
                },
              });
            }}
            text="Reabrir"
          />
        </CustomDialogUI>
      </>
    );
  }

  private _onColumnClick = (
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn
  ): void => {
    const { columns } = this.state;
    const newColumns: IColumn[] = columns.slice();
    let items: MeritGenerateType[] = [];
    if (this.state.isFiltering) {
      items = this.state.items;
    } else {
      items = this.props.meritsGenerate.data;
    }
    const currColumn: IColumn = newColumns.filter(
      (currCol) => column.key === currCol.key
    )[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    const newItems = this._sort(
      items,
      currColumn.fieldName!,
      currColumn.isSortedDescending
    );
    this.setState({
      columns: newColumns,
      items: newItems,
    });
  };

  private _getKey(item: any, index?: number): any {
    if (item !== undefined) return item.key;
  }


  private _sort<T>(
    items: T[],
    columnKey: string,
    isSortedDescending?: boolean,
    typeData?: string
  ): T[] {
    const result = (prop: string, obj: T) =>
      prop.split(".").reduce((a: any, b: any) => a[b] ?? "", obj);
    return items.sort((a: T, b: T) => {
      const comparedA =
        typeData === "number"
          ? Number(result(columnKey, a))
          : result(columnKey, a);
      const comparedB =
        typeData === "number"
          ? Number(result(columnKey, b))
          : result(columnKey, b);
      return (
        isSortedDescending ? comparedA < comparedB : comparedA > comparedB
      )
        ? 1
        : -1;
    });
  }
}

const IconButtonStyle: Partial<IButtonStyles> = {
  root: {
    position: "absolute",
    top: 20,
    right: 8,
    width: 24,
    height: 24,
    padding: 8,
  },
  icon: {
    fontSize: 12,
  },
};

const btnStyle: Partial<ICommandBarStyles> = {
  root: {
    height: 44,
  },
};

const initialMerit: Partial<MeritGenerateType> = {
  ano: "",
  idArea: null,
  dataReferencia: null,
  dataReuniao: null,
  flagUnicoNivel: false,
};

const validationSchema = yup.object().shape({
  ano: yup.string().required("Campo obrigatório"),
  dataReferencia: yup
    .date()
    .nullable()
    .typeError("Data inválida")
    .required("Campo obrigatório"),
  dataReuniao: yup
    .date()
    .nullable()
    .typeError("Data inválida")
    .required("Campo obrigatório"),
  idArea: yup.number().nullable().required("Campo obrigatório"),
  flagUnicoNivel: yup.bool().notRequired(),
});

const mapStateToProps = (state: RootState) => ({
  meritsGenerate: state.meritGenerateReducer,
  areas: state.chartReducer,
  login: state.loginReducer,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      ...getMeritGenerate,
      ...addMeritGenerate,
      ...delMeritGenerate,
      ...finalizarMeritGenerate,
      ...reabrirMeritoGenerate,
      ...getChart,
      ...setCurrentPage,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MeritGerenate)
);
