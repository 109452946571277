// #region Imports
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Formik } from "formik";
import * as yup from "yup";

//FluentUI
import { Stack } from "office-ui-fabric-react/lib/Stack";
import {
  DetailsListLayoutMode,
  SelectionMode,
  Selection,
  IColumn,
} from "office-ui-fabric-react/lib/DetailsList";
import { ShimmeredDetailsList } from "office-ui-fabric-react/lib/ShimmeredDetailsList";
import { SearchBox } from "office-ui-fabric-react/lib/SearchBox";
import { CommandBarButton, ICommandBarStyles } from "office-ui-fabric-react";
import { IDialogContentProps } from "office-ui-fabric-react/lib/Dialog";
import { Spinner, SpinnerSize } from "office-ui-fabric-react/lib/Spinner";

import Panel from "~/components/layout/Panel";
import CustomDialog from "~/components/CustomDialogFluentUI";
import { Page, BreadcrumbItems } from "~/store/ducks/home/types";
import { InputText, InputCheckbox } from "~/components/Forms";

import { DeleteButton } from "~/components/Buttons";
import HeaderPage from "~/components/layout/HeaderPage";
import NoItems from "~/components/layout/NoItems";
import { PrimaryButton } from "~/components/Buttons";

//Estilos
import {
  Wrapper,
  ListContainer,
  DefaultButton,
  ContainerContent,
} from "./styles";

import {
  DataTypes as DataTypesTipoMov,
  TipoMovTypeLoadDTO,
  TipoMovType,
} from "~/store/ducks/admin/tipomov/types";
import { Creators as getTiposMov } from "~/store/ducks/admin/tipomov";
import { Creators as getTiposMovById } from "~/store/ducks/admin/tipomov";
import { Creators as addTipoMov } from "~/store/ducks/admin/tipomov";
import { Creators as editTipoMov } from "~/store/ducks/admin/tipomov";
import { Creators as delTipoMov } from "~/store/ducks/admin/tipomov";

import { Creators as setCurrentPage } from "~/store/ducks/home";
import { RootState } from "~/store/ducks";
//#endregion

interface ITipoMovState {
  columns: IColumn[];
  items: TipoMovTypeLoadDTO[];
  isPanelOpen: boolean;
  inicialValues: TipoMovType;
  isFiltering: boolean;
  selectionDetails: string;
  selectionCount: number;
  isDialogCompetenceOpen: boolean;
  isDialogTypeOpen: boolean;
  isDialogExcludeOpen: boolean;
  search: string;
  newCompetenceText: string;
  newTypePDIText: string;
}

interface IPropsTipoMov {
  tiposMov: DataTypesTipoMov;
  getTiposMov: () => void;
  getTiposMovById: (idTipoMov: number) => void;
  addTipoMov: (tipo: TipoMovType) => void;
  editTipoMov: (tipo: TipoMovType) => void;
  delTipoMov: (idTipoMov: number) => void;
  setCurrentPage: (page: Page) => void;
}

class TipoMov extends Component<IPropsTipoMov, ITipoMovState> {
  private formRef: any;
  private inputSearch: any;
  private _selection: Selection;

  constructor(props: IPropsTipoMov) {
    super(props);
    //#region Colunas
    const columns: IColumn[] = [
      {
        key: "column1",
        name: "ID",
        ariaLabel: "ID do tipo de movimentação",
        fieldName: "idTipoMov",
        isRowHeader: true,
        minWidth: 75,
        maxWidth: 100,
        isResizable: true,
        isSortedDescending: false,
        onColumnClick: this._onColumnClick,
      },
      {
        key: "column2",
        name: "Descrição",
        fieldName: "descTipoMov",
        minWidth: 210,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "string",
        isPadded: true,
      },
      {
        key: "column3",
        name: "Descrição Amigável",
        fieldName: "nomeAmigavel",
        minWidth: 200,
        maxWidth: 200,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "string",
        isPadded: true,
      },
    ];
    //#endregion

    this.state = {
      columns: columns,
      items: [],
      isPanelOpen: false,
      selectionDetails: "",
      inicialValues: initialTipo,
      isFiltering: false,
      selectionCount: 0,
      isDialogExcludeOpen: false,
      search: "",
      isDialogCompetenceOpen: false,
      isDialogTypeOpen: false,
      newCompetenceText: "",
      newTypePDIText: "",
    };

    this._selection = new Selection({
      onSelectionChanged: () => {
        this.setState({
          selectionDetails: this._getSelectionDetails(),
        });
      },
    });

    this.formRef = React.createRef();
    this.inputSearch = React.createRef();
  }

  componentDidMount() {
    const page: Page = {
      key: "tiposMovimentacoes",
      pages: itemsBreadCrumb,
    };
    this.props.setCurrentPage(page);
    this.props.getTiposMov();
  }

  componentDidUpdate(prevProps: IPropsTipoMov, _: any) {
    if (this.state.isPanelOpen) {
      if (prevProps.tiposMov.success !== this.props.tiposMov.success) {
        if (this.props.tiposMov.success) {
          this.setState({ isPanelOpen: false });
        }
      }
    }

    if (
      this.props.tiposMov.itemSelected.success !==
      prevProps.tiposMov.itemSelected.success
    ) {
      if (this.props.tiposMov.itemSelected.success) {
        this.setState({
          inicialValues: this.props.tiposMov.itemSelected.item!,
        });
      }
    }

    // if (this.props.pdi.error !== prevProps.pdi.error) {
    //     if (this.props.pdi.error) {
    //         this.setState({ isPanelOpen: false, inicialValues: initialTipo })
    //     }
    // }
  }

  // Submit do formulário Formik
  handleSubmit = () => {
    if (this.formRef.current) {
      this.formRef.current.handleSubmit();
    }
  };

  _onItemInvoked = (item: TipoMovTypeLoadDTO): void => {
    this.props.getTiposMovById(item.idTipoMov);

    this.setState({
      isPanelOpen: true,
    });
  };

  _getSelectionDetails(): any {
    const selectionCount = this._selection.getSelectedCount();
    this.setState({ selectionCount });
    this._selection.getSelection();
  }

  openEdit = () => {
    const selected = this._selection.getSelection()[0] as TipoMovTypeLoadDTO;
    this.props.getTiposMovById(selected.idTipoMov);

    this.setState({
      isPanelOpen: true,
    });
  };

  cancelPanel = () => {
    this._selection.setAllSelected(false);
    this.setState({ isPanelOpen: false });
  };

  exclude = () => {
    this.props.delTipoMov(
      (this._selection.getSelection()[0] as TipoMovTypeLoadDTO).idTipoMov
    );
    this.setState({ isDialogExcludeOpen: false });
    this._selection.setAllSelected(false);
  };

  //#region  Funções do filtro

  handleSearch = (e: any) => {
    const data = this.props.tiposMov.data;
    const text = e?.target?.value;

    let items = text
      ? data.filter(
          (item) =>
            item.descTipoMov!.toLowerCase().indexOf(text.toLowerCase()) > -1
        )
      : data;

    this.setState({ items, isFiltering: true, search: text });
  };

  //#endregion

  commandBarRender = () => {
    const { selectionCount } = this.state;
    if (selectionCount === 1) {
      return (
        <>
          <CommandBarButton
            styles={btnStyle}
            iconProps={{ iconName: "Edit" }}
            text="Editar"
            onClick={() => this.openEdit()}
          />
          <CommandBarButton
            styles={btnStyle}
            iconProps={{ iconName: "Delete" }}
            text="Excluir"
            onClick={() => this.setState({ isDialogExcludeOpen: true })}
          />
        </>
      );
    }
  };

  render() {
    const {
      isPanelOpen,
      isDialogExcludeOpen,
      inicialValues,
      isFiltering,
      items,
      columns,
      search,
    } = this.state;
    const { tiposMov, getTiposMov, addTipoMov, editTipoMov } = this.props;

    return (
      <>
        <Panel
          title={
            !inicialValues?.idTipoMov ? "Novo Tipo" : inicialValues?.descTipoMov
          }
          open={isPanelOpen}
          onClose={() => this.cancelPanel()}
          footer={
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <DefaultButton onClick={() => this.cancelPanel()}>
                Cancelar
              </DefaultButton>
              <PrimaryButton
                onClick={this.handleSubmit}
                isLoading={tiposMov.loadingAction}
                text="Salvar"
              />
            </Stack>
          }
        >
          {tiposMov.itemSelected.loading ? (
            <Spinner
              size={SpinnerSize.medium}
              styles={{ root: { height: "100%" } }}
            />
          ) : (
            <Formik
              innerRef={this.formRef}
              initialValues={inicialValues}
              validationSchema={validationTipoSchema}
              validateOnChange={false}
              validateOnBlur={true}
              enableReinitialize
              onSubmit={(values: TipoMovType) => {
                if (!values.idTipoMov) {
                  addTipoMov(values);
                } else {
                  editTipoMov(values);
                }
              }}
            >
              {({
                handleSubmit,
                handleChange,
                values,
                errors,
                setFieldValue,
                setFieldError,
              }) => (
                <form
                  onSubmit={handleSubmit}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <InputText
                    value={values?.descTipoMov ?? ""}
                    onChange={(e) => {
                      handleChange(e);
                      setFieldError("descTipoMov", "");
                    }}
                    id="descTipoMov"
                    error={errors.descTipoMov ? true : false}
                    name="descTipoMov"
                    type="text"
                    label="Descrição"
                    helperText={errors.descTipoMov}
                    className="mt-2"
                    autoFocus
                  />

                  <InputText
                    value={values?.nomeAmigavel ?? ""}
                    onChange={(e) => {
                      handleChange(e);
                      setFieldError("nomeAmigavel", "");
                    }}
                    id="nomeAmigavel"
                    error={errors.nomeAmigavel ? true : false}
                    name="nomeAmigavel"
                    type="text"
                    label="Descrição Amigável"
                    helperText={errors.nomeAmigavel}
                    className="mt-2"
                  />

                  <InputCheckbox
                    checked={values.flagIgnorarMovimentacao ?? false}
                    onChange={handleChange}
                    name="flagIgnorarMovimentacao"
                    color="primary"
                    label="Ignorar Movimentação"
                  />

                  <InputCheckbox
                    checked={values.flagEspontanea ?? false}
                    onChange={handleChange}
                    name="flagEspontanea"
                    color="primary"
                    label="Movimentação Espontânea"
                  />

                  <InputCheckbox
                    checked={values.antecipacaoMerito ?? false}
                    onChange={handleChange}
                    name="antecipacaoMerito"
                    color="primary"
                    label="Antecipação de Mérito"
                  />
                </form>
              )}
            </Formik>
          )}
        </Panel>

        <Wrapper>
          <ContainerContent>
            <HeaderPage
              title="Tipos de Movimentações"
              leftItems={
                <>
                  <CommandBarButton
                    styles={btnStyle}
                    iconProps={{ iconName: "Add" }}
                    disabled={tiposMov.loading}
                    text="Adicionar Motivo"
                    onClick={() =>
                      this.setState({
                        isPanelOpen: true,
                        inicialValues: initialTipo,
                      })
                    }
                  />
                  {this.commandBarRender()}
                </>
              }
              rightItems={
                <>
                  <SearchBox
                    placeholder="Pesquisar"
                    value={search}
                    onChange={(e) => this.handleSearch(e)}
                    // onKeyUp={(e) => this.filter(e.key)}
                    componentRef={this.inputSearch}
                    styles={{
                      root: {
                        border: "none",
                        width: 200,
                        marginRight: 1,
                      },
                    }}
                  />
                  <CommandBarButton
                    styles={btnStyle}
                    iconProps={{ iconName: "Refresh" }}
                    text="Atualizar"
                    onClick={() => getTiposMov()}
                  />
                </>
              }
            />
            {!tiposMov.loading && tiposMov.data.length === 0 ? (
              <NoItems
                error={tiposMov.error}
                text="Não há motivos de faltas cadastradas"
                icon="Work"
              />
            ) : (
              <ListContainer>
                <ShimmeredDetailsList
                  items={isFiltering ? items : tiposMov.data}
                  enableShimmer={tiposMov.loading}
                  columns={columns}
                  selectionMode={SelectionMode.single}
                  selection={this._selection}
                  getKey={this._getKey}
                  selectionPreservedOnEmptyClick={true}
                  setKey="single"
                  layoutMode={DetailsListLayoutMode.justified}
                  isHeaderVisible={true}
                  onItemInvoked={this._onItemInvoked}
                />
              </ListContainer>
            )}
          </ContainerContent>
        </Wrapper>

        <CustomDialog
          hidden={!isDialogExcludeOpen}
          onDismiss={() => this.setState({ isDialogExcludeOpen: false })}
          dialogContentProps={dialogContentProps}
        >
          <DefaultButton
            onClick={() => this.setState({ isDialogExcludeOpen: false })}
            text="Cancelar"
          />
          <DeleteButton onClick={() => this.exclude()} text="Excluir" />
        </CustomDialog>
      </>
    );
  }

  private _onColumnClick = (
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn
  ): void => {
    const { columns } = this.state;
    const newColumns: IColumn[] = columns.slice();
    let items: TipoMovTypeLoadDTO[] = [];
    if (this.state.isFiltering) {
      items = this.state.items;
    } else {
      items = this.props.tiposMov.data;
    }
    const currColumn: IColumn = newColumns.filter(
      (currCol) => column.key === currCol.key
    )[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    const newItems = this._sort(
      items,
      currColumn.fieldName!,
      currColumn.isSortedDescending
    );
    this.setState({
      columns: newColumns,
      items: newItems,
    });
  };

  private _getKey(item: any, index?: number): any {
    if (item !== undefined) return item.key;
  }

  private _sort<T>(
    items: T[],
    columnKey: string,
    isSortedDescending?: boolean
  ): T[] {
    const result = (prop: string, obj: T) =>
      prop.split(".").reduce((a: any, b: any) => a[b] ?? "", obj);
    return items.sort((a: T, b: T) =>
      (
        isSortedDescending
          ? result(columnKey, a) < result(columnKey, b)
          : result(columnKey, a) > result(columnKey, b)
      )
        ? 1
        : -1
    );
  }
}

/**
 * Validação do formulário
 */
const validationTipoSchema = yup.object().shape({
  descTipoMov: yup
    .string()
    .max(60, "Máximo de 60 caracteres")
    .required("Campo obrigatório"),
  nomeAmigavel: yup.string().max(100, "Máximo de 100 caracteres").nullable(),
  flagIgnorarMovimentacao: yup.bool().nullable(),
  flagEspontanea: yup.bool().nullable(),
  antecipacaoMerito: yup.bool().nullable(),
});

const dialogContentProps: IDialogContentProps = {
  title: "Excluir?",
  closeButtonAriaLabel: "Close",
  subText: "Tem certeza de que deseja excluir este item?",
};

const itemsBreadCrumb: BreadcrumbItems[] = [
  {
    text: "Home",
    isCurrentItem: false,
    icon: "HomeSolid",
    onlyIcon: true,
    url: "/",
  },
  { text: "Tipos de Movimentações", isCurrentItem: true },
];

const initialTipo: TipoMovType = {
  idTipoMov: null,
  descTipoMov: "",
  flagEspontanea: false,
  antecipacaoMerito: false,
  nomeAmigavel: "",
};

const btnStyle: Partial<ICommandBarStyles> = {
  root: {
    height: 44,
  },
};

const mapStateToProps = (state: RootState) => ({
  tiposMov: state.tipoMovReducer,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      ...getTiposMov,
      ...getTiposMovById,
      ...addTipoMov,
      ...editTipoMov,
      ...delTipoMov,
      ...setCurrentPage,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(TipoMov);
