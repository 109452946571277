/**
 * Types
 */
export enum Types {
    //GET
    SET_WORKFLOW_PIVOT = "workflow/SET_WORKFLOW_PIVOT",
}

/**
 * Data types
 */

interface IWorkflowState {
    readonly pivotSelected: string;
}

export type DataTypes = IWorkflowState


interface ISetPivotSelectedAction {
    type: typeof Types.SET_WORKFLOW_PIVOT;
    pivotSelected: string;
}

export type WorkflowActions = ISetPivotSelectedAction;