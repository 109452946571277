import api from "~/services/api";
import { takeEvery, put, call, all } from "redux-saga/effects";
import {
  Types,
  IRequestTeamEvaluationLoadAction,
  ISuccessTeamEvaluationLoadAction
} from "../ducks/teamEvaluation/types";

const apiCallLoad = (idCiclo: number) => {
  return api.get(`ciclos/${idCiclo}/time`)
    .then(response => response.data)
    .catch(err => {
      throw err.response;
    });
};

function* load(action: IRequestTeamEvaluationLoadAction) {
  try {
    let data = yield call(apiCallLoad, action.idCiclo);

    if (JSON.stringify(data) === '{}') {
      data = {
        idCicloTime: null,
        membros: []
      }
    }

    yield put({
      type: Types.SUCCESS_TEAM_EVALUATION_LOAD,
      payload: data
    } as ISuccessTeamEvaluationLoadAction);
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível carregar o time.';
    yield put({ type: Types.FAILURE_TEAM_EVALUATION_LOAD, message });
  };
};

export default function* teamEvaluationRoot() {
  yield all([
    yield takeEvery(Types.REQUEST_TEAM_EVALUATION_LOAD, load),
  ]);
};
