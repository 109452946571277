import { Types, DataTypes, TeamActionTypes } from "./types";
import customToast from "~/components/Toast/index";

/**
 * Reducers
 */
const INITIAL_STATE: DataTypes = {
  data: [],
  loadingAction: false,
  loadingData: false,
  error: false,
  success: false,
};

/**
 * Actions
 */

export const Creators = {
  loadTeam: (search?: string): TeamActionTypes => {
    return {
      type: Types.REQUEST_TEAM_LOAD,
      search,
    };
  },
};

export default function teamReducer(
  state = INITIAL_STATE,
  action: TeamActionTypes
): DataTypes {
  switch (action.type) {
    case Types.REQUEST_TEAM_LOAD:
      return { ...state, error: false, success: false, loadingData: true };
    case Types.SUCCESS_TEAM_LOAD:
      return {
        ...state,
        data: action.payload,
        error: false,
        success: true,
        loadingData: false,
      };
    case Types.FAILURE_TEAM_LOAD:
      customToast.error(action.message);
      return { ...state, error: true, success: false, loadingData: false };

    default: {
      return state;
    }
  }
}
