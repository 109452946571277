
/**
 * Types
 */
export enum Types {
    //GET TYPES
    REQUEST_PDI_TYPES_LOAD = "pdi/REQUEST_PDI_TYPES_LOAD",
    SUCCESS_PDI_TYPES_LOAD = "pdi/SUCCESS_PDI_TYPES_LOAD",
    FAILURE_PDI_TYPES_LOAD = "pdi/FAILURE_PDI_TYPES_LOAD",
    //ADD TYPES
    REQUEST_PDI_TYPES_ADD = "pdi/REQUEST_PDI_TYPES_ADD",
    SUCCESS_PDI_TYPES_ADD = "pdi/SUCCESS_PDI_TYPES_ADD",
    FAILURE_PDI_TYPES_ADD = "pdi/FAILURE_PDI_TYPES_ADD",
}

export type PDITiposType = {
    idTipoPDI: number | null;
    descTipoPDI: string;
    cor: string;
}

interface ITiposPDIState {
    data: PDITiposType[];
    loading: boolean;
    loadingAction: boolean;
    success: boolean;
    error: boolean;
};


export type DataTypes = ITiposPDIState;


//GET PDI TYPES
export interface IRequestPDITypesLoadAction {
    type: typeof Types.REQUEST_PDI_TYPES_LOAD;
};

export interface ISuccessPDITypesLoadAction {
    type: typeof Types.SUCCESS_PDI_TYPES_LOAD;
    payload: PDITiposType[];
};

interface IFailurePDITypesLoadAction {
    type: typeof Types.FAILURE_PDI_TYPES_LOAD;
    message: string;
};

type PDITypesLoadAction = IRequestPDITypesLoadAction | ISuccessPDITypesLoadAction | IFailurePDITypesLoadAction;

//ADD PDI TYPES
export interface IRequestPDITypesAddAction {
    type: typeof Types.REQUEST_PDI_TYPES_ADD;
    descTipoPDI: string;
};

export interface ISuccessPDITypesAddAction {
    type: typeof Types.SUCCESS_PDI_TYPES_ADD;
    payload: PDITiposType;
};

interface IFailurePDITypesAddAction {
    type: typeof Types.FAILURE_PDI_TYPES_ADD;
    message: string;
};

type PDITypesAddAction = IRequestPDITypesAddAction | ISuccessPDITypesAddAction | IFailurePDITypesAddAction;

export type TiposPDIActionTypes = PDITypesLoadAction | PDITypesAddAction;