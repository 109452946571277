import styled from 'styled-components';

export const Wrapper = styled.div`
  width: calc(100% - 250px);
  flex-basis: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

export const ContainerContent = styled.div`
  padding: 20px;
`

export const WrapperTreeview = styled.div`
    margin-top: 5px;
    overflow-y: auto;
    height: calc(100vh - 192px);
`

export const ListContainer = styled.div`
  width: 100%;
  height: calc(100% - 82px);
  overflow-y: auto;
`;