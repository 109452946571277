/**
 * Types
 */
export enum Types {
  //GET
  REQUEST_TIPO_MOV_LOAD = "tipomov/REQUEST_TIPO_MOV_LOAD",
  SUCCESS_TIPO_MOV_LOAD = "tipomov/SUCCESS_TIPO_MOV_LOAD",
  FAILURE_TIPO_MOV_LOAD = "tipomov/FAILURE_TIPO_MOV_LOAD",
  //GET BY ID
  REQUEST_TIPO_MOV_LOAD_BY_ID = "tipomov/REQUEST_TIPO_MOV_LOAD_BY_ID",
  SUCCESS_TIPO_MOV_LOAD_BY_ID = "tipomov/SUCCESS_TIPO_MOV_LOAD_BY_ID",
  FAILURE_TIPO_MOV_LOAD_BY_ID = "tipomov/FAILURE_TIPO_MOV_LOAD_BY_ID",
  //ADD
  REQUEST_TIPO_MOV_ADD = "tipomov/REQUEST_TIPO_MOV_ADD",
  SUCCESS_TIPO_MOV_ADD = "tipomov/SUCCESS_TIPO_MOV_ADD",
  FAILURE_TIPO_MOV_ADD = "tipomov/FAILURE_TIPO_MOV_ADD",
  //EDIT
  REQUEST_TIPO_MOV_EDIT = "tipomov/REQUEST_TIPO_MOV_EDIT",
  SUCCESS_TIPO_MOV_EDIT = "tipomov/SUCCESS_TIPO_MOV_EDIT",
  FAILURE_TIPO_MOV_EDIT = "tipomov/FAILURE_TIPO_MOV_EDIT",
  //DEL
  REQUEST_TIPO_MOV_DEL = "tipomov/REQUEST_TIPO_MOV_DEL",
  SUCCESS_TIPO_MOV_DEL = "tipomov/SUCCESS_TIPO_MOV_DEL",
  FAILURE_TIPO_MOV_DEL = "tipomov/FAILURE_TIPO_MOV_DEL",
}

export type TipoMovTypeLoadDTO = {
  idTipoMov: number;
  descTipoMov: string;
  nomeAmigavel: string | null;
};

export type TipoMovType = {
  idTipoMov: number | null;
  descTipoMov: string;
  nomeAmigavel?: string | null;
  flagIgnorarMovimentacao?: boolean;
  flagEspontanea?: boolean;
  antecipacaoMerito?: boolean;
};

export type ItemSelectedType = {
  item: TipoMovType | null;
  loading: boolean;
  success: boolean;
  error: boolean;
};

interface ITipoMovState {
  readonly data: TipoMovTypeLoadDTO[];
  readonly loading: boolean;
  readonly loadingAction: boolean;
  readonly error: boolean;
  readonly success: boolean;
  readonly itemSelected: ItemSelectedType;
}

export type DataTypes = ITipoMovState;

/**
 * Actions
 */

//GET
export interface IRequestTipoMovLoadAction {
  type: typeof Types.REQUEST_TIPO_MOV_LOAD;
  search?: string;
}

export interface ISuccessTipoMovLoadAction {
  type: typeof Types.SUCCESS_TIPO_MOV_LOAD;
  payload: TipoMovTypeLoadDTO[];
}

interface IFailureTipoMovLoadAction {
  type: typeof Types.FAILURE_TIPO_MOV_LOAD;
  message: string;
}

type TipoMovLoadAction =
  | IRequestTipoMovLoadAction
  | ISuccessTipoMovLoadAction
  | IFailureTipoMovLoadAction;

//GET BY ID
export interface IRequestTipoMovLoadByIdAction {
  type: typeof Types.REQUEST_TIPO_MOV_LOAD_BY_ID;
  idTipoMov: number;
}

export interface ISuccessTipoMovLoadByIdAction {
  type: typeof Types.SUCCESS_TIPO_MOV_LOAD_BY_ID;
  payload: TipoMovType;
}

interface IFailureTipoMovLoadByIdAction {
  type: typeof Types.FAILURE_TIPO_MOV_LOAD_BY_ID;
  message: string;
}

type TipoMovLoadByIdAction =
  | IRequestTipoMovLoadByIdAction
  | ISuccessTipoMovLoadByIdAction
  | IFailureTipoMovLoadByIdAction;

//ADD
export interface IRequestTipoMovAddAction {
  type: typeof Types.REQUEST_TIPO_MOV_ADD;
  payload: TipoMovType;
}

export interface ISuccessTipoMovAddAction {
  type: typeof Types.SUCCESS_TIPO_MOV_ADD;
  payload: TipoMovTypeLoadDTO;
}

interface IFailureTipoMovAddAction {
  type: typeof Types.FAILURE_TIPO_MOV_ADD;
  message: string;
}

type TipoMovAddAction =
  | IRequestTipoMovAddAction
  | ISuccessTipoMovAddAction
  | IFailureTipoMovAddAction;

//EDIT
export interface IRequestTipoMovEditAction {
  type: typeof Types.REQUEST_TIPO_MOV_EDIT;
  payload: TipoMovType;
}

export interface ISuccessTipoMovEditAction {
  type: typeof Types.SUCCESS_TIPO_MOV_EDIT;
  payload: TipoMovTypeLoadDTO;
}

interface IFailureTipoMovEditAction {
  type: typeof Types.FAILURE_TIPO_MOV_EDIT;
  message: string;
}

type TipoMovEditAction =
  | IRequestTipoMovEditAction
  | ISuccessTipoMovEditAction
  | IFailureTipoMovEditAction;

//DEL
export interface IRequestTipoMovDelAction {
  type: typeof Types.REQUEST_TIPO_MOV_DEL;
  idTipoMov: number;
}

export interface ISuccessTipoMovDelAction {
  type: typeof Types.SUCCESS_TIPO_MOV_DEL;
  idTipoMov: number;
}

interface IFailureTipoMovDelAction {
  type: typeof Types.FAILURE_TIPO_MOV_DEL;
  message: string;
}

type TipoMovDelAction =
  | IRequestTipoMovDelAction
  | ISuccessTipoMovDelAction
  | IFailureTipoMovDelAction;

export type TipoMovActionTypes =
  | TipoMovLoadAction
  | TipoMovLoadByIdAction
  | TipoMovAddAction
  | TipoMovEditAction
  | TipoMovDelAction;
