import React from 'react';

import { Shimmer, ShimmerElementType } from 'office-ui-fabric-react/lib/Shimmer';


const ShimmerPillars: React.FC = () => {
    
    const shimmerWithElementGoal = [
        { type: ShimmerElementType.line, height: 70, width: 212 },
        { type: ShimmerElementType.gap, width: 20 },
        { type: ShimmerElementType.line, height: 70, width: 212 },
        { type: ShimmerElementType.gap, width: 20 },
        { type: ShimmerElementType.line, height: 70, width: 212 },
        { type: ShimmerElementType.gap, width: 20 },
        { type: ShimmerElementType.line, height: 70, width: 212 },
        { type: ShimmerElementType.gap, width: 20 },
        { type: ShimmerElementType.line, height: 70, width: 212 },
        { type: ShimmerElementType.gap, width: "100%" },
    ];

    const shimmerWithBtn = [
        { type: ShimmerElementType.line, height: 38, width: 212 },
        { type: ShimmerElementType.gap, width: "100%" },
    ];
    
    const shimmerWithBtns = [
        { type: ShimmerElementType.line, height: 38, width: 212 },
        { type: ShimmerElementType.gap, width: 20 },
        { type: ShimmerElementType.line, height: 38, width: 212 },
        { type: ShimmerElementType.gap, width: 20 },
        { type: ShimmerElementType.line, height: 38, width: 212 },
        { type: ShimmerElementType.gap, width: 20 },
        { type: ShimmerElementType.line, height: 38, width: 212 },
        { type: ShimmerElementType.gap, width: 20 },
        { type: ShimmerElementType.line, height: 38, width: 212 },
        { type: ShimmerElementType.gap, width: "100%" },
    ];
    
    const shimmerWithItems = [
        { type: ShimmerElementType.line, height: 51, width: 212 },
        { type: ShimmerElementType.gap, width: 20 },
        { type: ShimmerElementType.line, height: 51, width: 212 },
        { type: ShimmerElementType.gap, width: 20 },
        { type: ShimmerElementType.line, height: 51, width: 212 },
        { type: ShimmerElementType.gap, width: 20 },
        { type: ShimmerElementType.line, height: 51, width: 212 },
        { type: ShimmerElementType.gap, width: 20 },
        { type: ShimmerElementType.line, height: 51, width: 212 },
        { type: ShimmerElementType.gap, width: "100%" },
    ];
   
    return (
        <>
            <Shimmer styles={{ shimmerWrapper: { marginTop: 15 } }} shimmerElements={shimmerWithBtn} />

            <Shimmer styles={{ shimmerWrapper: { marginTop: 11 } }} shimmerElements={shimmerWithElementGoal} />
            <Shimmer styles={{ shimmerWrapper: { marginTop: 11 } }} shimmerElements={shimmerWithBtns} />
            <Shimmer styles={{ shimmerWrapper: { marginTop: 11 } }} shimmerElements={shimmerWithItems} />
            <Shimmer styles={{ shimmerWrapper: { marginTop: 11 } }} shimmerElements={shimmerWithItems} />
            <Shimmer styles={{ shimmerWrapper: { marginTop: 11 } }} shimmerElements={shimmerWithItems} />
            <Shimmer styles={{ shimmerWrapper: { marginTop: 11 } }} shimmerElements={shimmerWithItems} />
            <Shimmer styles={{ shimmerWrapper: { marginTop: 11 } }} shimmerElements={shimmerWithItems} />
            <Shimmer styles={{ shimmerWrapper: { marginTop: 11 } }} shimmerElements={shimmerWithItems} />
        </>
    )
}

export default ShimmerPillars;