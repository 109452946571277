import { AvaliationType } from '../tasks/types';

/**
 * Types
 */
export enum Types {
  //GET AVALIACAO
  REQUEST_AVALIACAO_BY_ID = "avaliation/REQUEST_AVALIACAO_BY_ID",
  SUCCESS_AVALIACAO_BY_ID = "avaliation/SUCCESS_AVALIACAO_BY_ID",
  FAILURE_AVALIACAO_BY_ID = "avaliation/FAILURE_AVALIACAO_BY_ID",
  //GET AVALIACAO
  REQUEST_AVALIACAO_SAVE = "avaliation/REQUEST_AVALIACAO_SAVE",
  SUCCESS_AVALIACAO_SAVE = "avaliation/SUCCESS_AVALIACAO_SAVE",
  FAILURE_AVALIACAO_SAVE = "avaliation/FAILURE_AVALIACAO_SAVE",
  //GET HISTORICO
  REQUEST_AVALIACAO_HISTORY = "avaliation/REQUEST_AVALIACAO_HISTORY",
  SUCCESS_AVALIACAO_HISTORY = "avaliation/SUCCESS_AVALIACAO_HISTORY",
  FAILURE_AVALIACAO_HISTORY = "avaliation/FAILURE_AVALIACAO_HISTORY",
  //SAVE AVALIACAO
  SAVE_AVALIATION_ATITUDE_AVALIACAO = "avaliation/SAVE_AVALIATION_ATITUDE_AVALIACAO",
  SAVE_AVALIATION_ATITUDE_COMENTARIO = "avaliation/SAVE_AVALIATION_ATITUDE_COMENTARIO",
  SAVE_AVALIATION_ASPIRACOES_CARREIRA = "avaliation/SAVE_AVALIATION_ASPIRACOES_CARREIRA",
  SAVE_AVALIATION_COMENTARIOS_GERAIS = "avaliation/SAVE_AVALIATION_COMENTARIOS_GERAIS",
  SAVE_AVALIATION_COMENTARIOS_POSITIVO = "avaliation/SAVE_AVALIATION_COMENTARIOS_POSITIVO",
  SAVE_AVALIATION_COMENTARIOS_MELHORAR = "avaliation/SAVE_AVALIATION_COMENTARIOS_MELHORAR",
  // RESET REDIRECT
  RESET_REDIRECT = "avaliation/RESET_REDIRECT",
};

export interface AvaliacaoGestorType extends AvaliationType {
  comentariosPositivos: string;
  comentariosMelhorar: string;
  comentariosAtitudes: string;
  comentariosMetas: string;
}

export interface AvaliacaoGestorHistoryType {
  idCicloAvaliacao: number;
  idCiclo: number;
  idFuncionario: number;
  idTabPerformanceGestor: number;
  potencial: string;
  sucessao: string;
  ciclo: {
    ano: number;
  };
  performance: {
    descNivel: string;
    cor: string;
  };
  cargo:{
    idCargo: number;
    codCargo: number;
    titulo: string;
    dataHist: Date;
  };
}

interface ICycleState {
  readonly data: AvaliacaoGestorType | null;
  readonly dataHistory: Partial<AvaliationType>[];
  readonly loading: boolean;
  readonly loadingSave: boolean;
  readonly error: boolean;
  readonly success: boolean;
  readonly redirectTo: string | null;
};

export type DataTypes = ICycleState;


//REQUEST AVALIACAO BY ID
export interface IRequestCycleAvaliacaoByIdAction {
  type: typeof Types.REQUEST_AVALIACAO_BY_ID;
  idAvaliacao: number;
};

export interface ISuccessCycleAvaliacaoByIdAction {
  type: typeof Types.SUCCESS_AVALIACAO_BY_ID;
  payload: AvaliacaoGestorType;
};

export interface IFailureCycleAvaliacaoByIdAction {
  type: typeof Types.FAILURE_AVALIACAO_BY_ID;
  message: string;
};

type CycleAvaliacaoByIdAction = IRequestCycleAvaliacaoByIdAction | ISuccessCycleAvaliacaoByIdAction | IFailureCycleAvaliacaoByIdAction;


//REQUEST AVALIACAO HISTORY
export interface IRequestCycleAvaliacaoHistoryAction {
  type: typeof Types.REQUEST_AVALIACAO_HISTORY;
  idFuncionario: number;
};

export interface ISuccessCycleAvaliacaoHistoryAction {
  type: typeof Types.SUCCESS_AVALIACAO_HISTORY;
  payload: Partial<AvaliationType>[];
};

export interface IFailureCycleAvaliacaoHistoryAction {
  type: typeof Types.FAILURE_AVALIACAO_HISTORY;
  message: string;
};

type CycleAvaliacaoHistoryAction = IRequestCycleAvaliacaoHistoryAction | ISuccessCycleAvaliacaoHistoryAction | IFailureCycleAvaliacaoHistoryAction;

//REQUEST AVALIACAO SAVE
export interface IRequestCycleAvaliacaoSaveAction {
  type: typeof Types.REQUEST_AVALIACAO_SAVE;
  payload: {
    idTarefa: number;
    idAvaliacao: number;
    data: AvaliacaoGestorType;
  }
};

export interface ISuccessCycleAvaliacaoSaveAction {
  type: typeof Types.SUCCESS_AVALIACAO_SAVE;
  idTarefa: number;
};

export interface IFailureCycleAvaliacaoSaveAction {
  type: typeof Types.FAILURE_AVALIACAO_SAVE;
  message: string;
};

type CycleAvaliacaoSaveAction = IRequestCycleAvaliacaoSaveAction | ISuccessCycleAvaliacaoSaveAction | IFailureCycleAvaliacaoSaveAction;

interface IResetRedirectAction {
  type: typeof Types.RESET_REDIRECT
}


export interface ISaveTasksAtitudeAvaliacaoAction {
  type: typeof Types.SAVE_AVALIATION_ATITUDE_AVALIACAO,
  payload: {
    idCicloAvaliacaoAtitude: number;
    resultado: string;
  };
}

export interface ISaveTasksAtitudeComentarioAction {
  type: typeof Types.SAVE_AVALIATION_ATITUDE_COMENTARIO,
  payload: {
    idCicloAvaliacaoAtitude: number;
    comentarios: string;
  };
}

export interface ISaveTasksAtitudeAspiracoesCarreiraAction {
  type: typeof Types.SAVE_AVALIATION_ASPIRACOES_CARREIRA,
  aspiracao: string;
}

export interface ISaveTasksAtitudeComentariosGeraisAction {
  type: typeof Types.SAVE_AVALIATION_COMENTARIOS_GERAIS,
  comentariosGerais: string;
}

export interface ISaveTasksAtitudeComentariosPositivoAction {
  type: typeof Types.SAVE_AVALIATION_COMENTARIOS_POSITIVO,
  comentariosPositivos: string;
}

export interface ISaveTasksAtitudeComentariosMelhorarAction {
  type: typeof Types.SAVE_AVALIATION_COMENTARIOS_MELHORAR,
  comentariosMelhorar: string;
}

export type CycleAvaliationActionTypes =
  CycleAvaliacaoByIdAction |
  CycleAvaliacaoSaveAction |
  IResetRedirectAction |
  ISaveTasksAtitudeAvaliacaoAction |
  ISaveTasksAtitudeComentarioAction |
  ISaveTasksAtitudeAspiracoesCarreiraAction |
  ISaveTasksAtitudeComentariosGeraisAction |
  ISaveTasksAtitudeComentariosPositivoAction |
  ISaveTasksAtitudeComentariosMelhorarAction |
  CycleAvaliacaoHistoryAction;
