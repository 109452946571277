import React from 'react';

import { Text } from 'office-ui-fabric-react/lib/Text';
import { Stack } from 'office-ui-fabric-react';
import { FontIcon } from 'office-ui-fabric-react/lib/Icon';

import { DeleteButton, OutlinedButton, PrimaryButton } from '~/components/Buttons';
import { LabelGroup } from '~/components/label';

import {
  ContainerCycle,
  Title,
  IconCycle,
  DetailsWrapper,
  WrapperButton,
} from './styles';
import Colors from '~/assets/js/colors';

interface IGroup {
  label: string;
  value: string;
  icon: string;
  size?: "full" | "half";
  tooltipMessage?: string;
}

interface IPainelHeaderProps {
  title: string;
  icon: {
    icon?: string;
    svgIcon?: string;
  };
  labelGroups?: IGroup[];
  button?: {
    text: string;
    handleButton: () => any;
    isLoadingAction?: boolean;
    disabled?: boolean;
  } | null;
  multiButton?: {
    isVisible: boolean | undefined;
    text: string;
    variant?: 'primary' | 'outline' | 'delete';
    handleButton: () => any;
    isLoadingAction?: boolean;
    disabled?: boolean;
  }[];
  notHasMarginLabelGroups?: boolean;
}

const PainelHeader: React.FC<IPainelHeaderProps> = (props) => {
  const { title, icon, labelGroups, button, notHasMarginLabelGroups, multiButton = [] } = props;
  return (
    <ContainerCycle>
      <Title>
        {
          icon.svgIcon ?
            <IconCycle src="/static/icons/ciclo.svg" alt="Ícone Ciclo" />
            :
            <FontIcon
              iconName={icon.icon}
              style={{
                fontSize: 30,
                alignSelf: 'center',
                marginRight: 12
              }}
            />
        }
        <Text variant="xxLarge">{title}</Text>
      </Title>
      <DetailsWrapper notHasMarginLabelGroups={notHasMarginLabelGroups ?? false}>
        <Stack horizontal styles={{ root: { flexWrap: 'wrap', maxWidth: 990, width: '100%' } }}>
          {
            labelGroups?.map((group, i) => (
              <LabelGroup key={i} icon={group.icon} size={group.size} label={group.label} value={group.value} tooltipMessage={group.tooltipMessage} />
            ))
          }
          {
            props.children
          }
          <WrapperButton>
            <div>

              {
                button ?
                  <PrimaryButton text={button?.text} isLoading={button.isLoadingAction} disabled={button?.disabled} onClick={button?.handleButton} styles={{ root: { width: '100%', minWidth: 110 } }} />
                  :
                  null
              }
              <Stack horizontal>
                {multiButton?.length > 0 &&
                  multiButton?.map((mltButton, i) => {
                    if (mltButton.isVisible) {
                      switch (mltButton.variant) {
                        case 'delete':
                          return <DeleteButton
                            key={i}
                            text={mltButton.text}
                            isLoading={mltButton.isLoadingAction}
                            disabled={mltButton?.disabled}
                            onClick={mltButton?.handleButton}
                            minWidth={110}
                          // styles={{ root: { width: '100%', minWidth: 110, marginRight: 16 } }}
                          />
                        case 'outline':
                          return <OutlinedButton
                            key={i}
                            text={mltButton.text}
                            isLoading={mltButton.isLoadingAction}
                            disabled={mltButton?.disabled}
                            onClick={mltButton?.handleButton}
                            styles={{ root: { width: '100%', minWidth: 110, marginRight: 16 } }}
                          />
                        case 'primary':
                        default:
                          return <PrimaryButton
                            key={i}
                            text={mltButton.text}
                            isLoading={mltButton.isLoadingAction}
                            disabled={mltButton?.disabled}
                            onClick={mltButton?.handleButton}
                            styles={{ root: { width: '100%', minWidth: 110, marginRight: 16 } }}
                          />
                      }
                    }
                  }
                  )
                }
              </Stack>
            </div>
          </WrapperButton>
        </Stack>
      </DetailsWrapper>
    </ContainerCycle>
  );
}

export default PainelHeader;
