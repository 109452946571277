import styled from "styled-components";

export const Container = styled.div`
  flex-basis: 100%;
  width: calc(100% - 250px);
  height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

export const Content = styled.div`
  padding: 20px;
  /* overflow-y: scroll; */
`;
