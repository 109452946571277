// #region Imports
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Formik } from "formik";
import * as yup from "yup";

//FluentUI
import { Stack } from "office-ui-fabric-react/lib/Stack";
import {
  DetailsListLayoutMode,
  SelectionMode,
  IColumn,
} from "office-ui-fabric-react/lib/DetailsList";
import { ShimmeredDetailsList } from "office-ui-fabric-react/lib/ShimmeredDetailsList";
import { Text } from "office-ui-fabric-react";
import { Spinner, SpinnerSize } from "office-ui-fabric-react/lib/Spinner";

import InputAdornment from "@material-ui/core/InputAdornment";
import Panel from "~/components/layout/Panel";
import Dialog from "~/components/CustomDialog";
import Accordion from "~/components/Accordion";
import { Page, BreadcrumbItems } from "~/store/ducks/home/types";
import { InputText } from "~/components/Forms";

import { OutlinedButton } from "~/components/Buttons";
import HeaderPage from "~/components/layout/HeaderPage";
import { PrimaryButton } from "~/components/Buttons";
//Estilos
import {
  Wrapper,
  ListContainer,
  DefaultButton,
  ContainerContent,
  ContainerDropdownInput,
  InputContainer,
} from "./styles";

import {
  DataTypes as DataTypesMerit,
  ParametroType,
  MeritPerformanceType,
} from "~/store/ducks/admin/merit/types";
import { Creators as getMeritConfigs } from "~/store/ducks/admin/merit";
import { Creators as getMeritSeasons } from "~/store/ducks/admin/merit";
import { Creators as getMeritParametroById } from "~/store/ducks/admin/merit";
import { Creators as editMeritParametro } from "~/store/ducks/admin/merit";
import { Creators as getMeritPerformanceById } from "~/store/ducks/admin/merit";
import { Creators as editMeritPerformance } from "~/store/ducks/admin/merit";
import { Creators as addMeritSeason } from "~/store/ducks/admin/merit";
import { Creators as clearMerit } from "~/store/ducks/admin/merit";

import { DataTypes as DataTypesTabPerformance } from "~/store/ducks/admin/tabPerformances/types";
import { Creators as getTabPerformances } from "~/store/ducks/admin/tabPerformances";

import { Creators as setCurrentPage } from "~/store/ducks/home";
import { RootState } from "~/store/ducks";
import Dropdown from "~/components/layout/Dropdown";
import { MenuItem } from "@material-ui/core";
//#endregion

interface IConfigMeritState {
  columnsPerformance: IColumn[];
  isPanelParametroOpen: boolean;
  inicialValuesParametro: ParametroType | null;
  selectionDetails: string;
  isDialogAddSeasonOpen: boolean;
  idConfigMerito: string;
}

interface IPropsConfigMerit {
  merits: DataTypesMerit;
  tabPerformances: DataTypesTabPerformance;
  getMeritConfigs: (idConfigMerito: number) => void;
  getMeritParametroById: (
    idConfigMerito: number,
    idConfigMeritoParametro: number
  ) => void;
  editMeritParametro: (
    idConfigMerito: number,
    idConfigMeritoParametro: number,
    valor: string | number
  ) => void;
  getMeritPerformanceById: (
    idConfigMerito: number,
    idConfigMeritoPerformance: number
  ) => void;
  editMeritPerformance: (
    idConfigMerito: number,
    performance: MeritPerformanceType
  ) => void;
  getMeritSeasons: () => void;
  addMeritSeason: (ano: number, idTabPerformance: number) => void;
  getTabPerformances: () => void;
  clearMerit: () => void;
  setCurrentPage: (page: Page) => void;
}

class ConfigMerit extends Component<IPropsConfigMerit, IConfigMeritState> {
  private formRefParametro: any;
  private formRefAddSeason: any;

  constructor(props: IPropsConfigMerit) {
    super(props);
    //#region Colunas
    const columnsPerformance: IColumn[] = [
      {
        key: "column1",
        name: "Descrição",
        ariaLabel: "Descrição",
        fieldName: "nivel.descNivel",
        isRowHeader: true,
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        isSortedDescending: false,
        isPadded: true,
        onRender: (item: MeritPerformanceType) => item.nivel.descNivel,
      },
      {
        key: "column2",
        name: "Nível",
        fieldName: "nivel.nivel",
        minWidth: 50,
        maxWidth: 50,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        data: "string",
        styles: { cellTitle: { justifyContent: "flex-end" } },
        onRender: (item: MeritPerformanceType) => (
          <Stack horizontalAlign="end" styles={{ root: { width: "100%" } }}>
            <Text>{item.nivel.nivel}</Text>
          </Stack>
        ),
      },
      {
        key: "column3",
        name: "Multiplicador",
        fieldName: "multiplicador",
        minWidth: 150,
        maxWidth: 150,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        data: "string",
        styles: { cellTitle: { justifyContent: "flex-end", paddingRight: 10 } },
        onRender: (item: MeritPerformanceType) => (
          <Stack
            horizontalAlign="end"
            styles={{ root: { width: "100%", paddingRight: 10 } }}
          >
            <Text>{`${Number(item.multiplicador)}%`}</Text>
          </Stack>
        ),
      },
      {
        key: "column4",
        name: "Prom.",
        fieldName: "flagPromocao",
        minWidth: 100,
        maxWidth: 100,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        data: "boolean",
        styles: { cellTitle: { justifyContent: "center" } },
        onRender: (item: MeritPerformanceType) => (
          <Stack horizontalAlign="center">
            <Text>{item.flagPromocao ? "Sim" : "Não"}</Text>
          </Stack>
        ),
      },
      {
        key: "column5",
        name: "Enq.",
        fieldName: "flagEnquadramento",
        minWidth: 100,
        maxWidth: 100,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        data: "boolean",
        isPadded: true,
        onRender: (item: MeritPerformanceType) =>
          item.flagEnquadramento ? "Sim" : "Não",
      },
    ];
    //#endregion

    this.state = {
      columnsPerformance: columnsPerformance,
      isPanelParametroOpen: false,
      selectionDetails: "",
      inicialValuesParametro: null,
      isDialogAddSeasonOpen: false,
      idConfigMerito: "",
    };

    this.formRefParametro = React.createRef();
    this.formRefAddSeason = React.createRef();
  }

  componentDidMount() {
    const page: Page = {
      key: "configMerito",
      pages: itemsBreadCrumb,
    };
    this.props.setCurrentPage(page);
    this.props.clearMerit();
    this.props.getMeritSeasons();
  }

  componentDidUpdate(prevProps: IPropsConfigMerit, _: any) {
    const { success: prevSuccess } = prevProps.merits;
    const { success: nextSuccess } = this.props.merits;

    if (
      this.props.merits.itemSelected.success !==
      prevProps.merits.itemSelected.success
    ) {
      if (this.props.merits.itemSelected.success) {
        this.setState({
          inicialValuesParametro: this.props.merits.itemSelected.parametro,
        });
      }
    }

    if (prevSuccess !== nextSuccess) {
      if (nextSuccess) {
        this.setState({
          isPanelParametroOpen: false,
        });
      }
    }

    if (
      this.props.merits.itemSelected.error !==
      prevProps.merits.itemSelected.error
    ) {
      if (this.props.merits.itemSelected.error) {
        this.setState({
          isPanelParametroOpen: false,
          inicialValuesParametro: null,
        });
      }
    }
  }

  // Submit do formulário Formik
  handleSubmitParametro = () => {
    if (this.formRefParametro.current) {
      this.formRefParametro.current.handleSubmit();
    }
  };

  _onItemInvokedParametro = (item: ParametroType): void => {
    const { idConfigMerito } = this.state;
    const { idConfigMeritoParametro } = item;

    this.props.getMeritParametroById(
      Number(idConfigMerito),
      idConfigMeritoParametro
    );
    this.setState({ isPanelParametroOpen: true });
  };

  cancelPanel = () => {
    this.setState({ isPanelParametroOpen: false });
  };

  handleOpenAddSeason = () => {
    this.props.getTabPerformances();
    this.setState({ isDialogAddSeasonOpen: true });
  };

  handleSubmitAddSeason = () => {
    if (this.formRefAddSeason.current) {
      this.formRefAddSeason.current.handleSubmit();
    }
  };

  renderParametros = () => {
    const { parametros } = this.props.merits.data ?? {};

    const allParametroGrupo = parametros?.filter(
      (item, i, arr) =>
        arr
          .map((part) => part.parametro.idParametroGrupo)
          .indexOf(item.parametro.idParametroGrupo) == i
    );

    return allParametroGrupo?.map((item, i) => {
      const data =
        parametros?.filter(
          (feat) => feat.parametro.idParametroGrupo === item.parametro.idParametroGrupo
        ) ?? [];
      let columns: IColumn[] = [];
      if (!!item.parametro.grupo) {
        columns.push({
          key: `columnPolitica${item.parametro.idParametroGrupo}${i}`,
          name: "Política",
          ariaLabel: "Política",
          fieldName: "parametro.grupo",
          isRowHeader: true,
          minWidth: 75,
          maxWidth: 100,
          isResizable: true,
          isSortedDescending: false,
          isPadded: true,
          onRender: (item: ParametroType) => item.parametro.grupo,
        });
      }
      columns.push(
        {
          key: `columnDescricao${item.parametro.idParametroGrupo}${i}`,
          name: "Descrição",
          ariaLabel: "Descrição",
          fieldName: "parametro.nome",
          isRowHeader: true,
          minWidth: 150,
          isResizable: true,
          isSortedDescending: false,
          isPadded: true,
          onRender: (item: ParametroType) => item.parametro.nome,
        },
        {
          key: `columnValor${item.parametro.idParametroGrupo}${i}`,
          name: "Valor Atual",
          ariaLabel: "Descrição",
          fieldName: "valor",
          isRowHeader: true,
          minWidth: 100,
          maxWidth: 100,
          isResizable: true,
          isSortedDescending: false,
          isPadded: true,
          styles: { cellTitle: { justifyContent: "flex-end" } },
          onRender: (item: ParametroType) => (
            <Stack horizontalAlign="end">
              <Text>{convertValue(item.valor, item.parametro.tipoValor)}</Text>
            </Stack>
          ),
        },
        {
          key: `columnBlanl`,
          name: "",
          ariaLabel: "",
          fieldName: "",
          isRowHeader: true,
          minWidth: 100,
          isPadded: true,
        }
      );

      return (
        <Accordion title={item.parametro.grupoparam.nome} key={i}>
          <ListContainer>
            <ShimmeredDetailsList
              items={data}
              columns={columns}
              selectionMode={SelectionMode.none}
              selectionPreservedOnEmptyClick={true}
              setKey="single"
              layoutMode={DetailsListLayoutMode.justified}
              isHeaderVisible={true}
              onItemInvoked={this._onItemInvokedParametro}
            />
          </ListContainer>
        </Accordion>
      );
    });
  };

  render() {
    const {
      isPanelParametroOpen,
      inicialValuesParametro,
      idConfigMerito,
      isDialogAddSeasonOpen,
    } = this.state;
    const {
      getMeritConfigs,
      addMeritSeason,
      editMeritParametro,
      merits,
      tabPerformances,
    } = this.props;

    return (
      <>
        <Wrapper>
          <ContainerContent>
            <HeaderPage title="Configurações de Mérito" />
            <Stack horizontal style={{ marginBottom: 20, marginTop: 20 }}>
              <ContainerDropdownInput style={{ marginRight: 20 }}>
                <Text variant="medium" styles={{ root: { marginRight: 20 } }}>
                  Ano
                </Text>
                <InputContainer>
                  <Dropdown
                    errors=""
                    variant="outlined"
                    label=""
                    name="year"
                    values={idConfigMerito}
                    handleChange={(e: any) => {
                      getMeritConfigs(Number(e.target.value));
                      this.setState({ idConfigMerito: e.target.value });
                    }}
                    fieldHeight={32}
                    styles={{
                      boxSizing: "border-box",
                      height: 32,
                      backgroundColor: "transparent",
                    }}
                    autofocus
                    customPadding="6.5px 14px"
                  >
                    {merits.seasonData.map((temp, i) => (
                      <MenuItem key={i} value={String(temp.idConfigMerito)}>
                        {temp.ano}
                      </MenuItem>
                    ))}
                  </Dropdown>
                </InputContainer>
              </ContainerDropdownInput>
              <OutlinedButton
                text="Adicionar Temporada"
                onClick={this.handleOpenAddSeason}
              />
            </Stack>
            {merits.loadingData ? (
              <Spinner
                size={SpinnerSize.medium}
                label="Carregando Configurações"
                styles={{ root: { height: "100%" } }}
              />
            ) : (
              this.renderParametros()
            )}
          </ContainerContent>
        </Wrapper>

        <Panel
          title={inicialValuesParametro?.parametro.grupoparam.nome ?? ""}
          open={isPanelParametroOpen}
          onClose={() => this.cancelPanel()}
          footer={
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <DefaultButton onClick={() => this.cancelPanel()}>
                Cancelar
              </DefaultButton>
              <PrimaryButton
                onClick={this.handleSubmitParametro}
                disabled={merits.itemSelected.loading}
                isLoading={merits.loadingAction}
                text="Salvar"
              />
            </Stack>
          }
        >
          {merits.itemSelected.loading ? (
            <Spinner
              size={SpinnerSize.medium}
              styles={{ root: { height: "100%" } }}
            />
          ) : (
            <Formik
              innerRef={this.formRefParametro}
              initialValues={{ valor: inicialValuesParametro?.valor ?? "" }}
              validationSchema={validationParametroSchema}
              validateOnChange={false}
              validateOnBlur={true}
              enableReinitialize
              onSubmit={(values) => {
                editMeritParametro(
                  Number(idConfigMerito),
                  inicialValuesParametro?.idConfigMeritoParametro!,
                  values.valor
                );
              }}
            >
              {({
                handleSubmit,
                handleChange,
                values,
                errors,
                setFieldError,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Text
                    variant="smallPlus"
                    styles={{ root: { fontWeight: 600 } }}
                  >
                    {inicialValuesParametro?.parametro.descricao}
                  </Text>
                  <InputText
                    value={values.valor}
                    onChange={(e) => {
                      handleChange(e);
                      setFieldError("valor", "");
                    }}
                    id="valor"
                    error={!!errors.valor}
                    name="valor"
                    type={convertTypeValue(
                      inicialValuesParametro?.parametro.tipoValor
                    )}
                    label={inicialValuesParametro?.parametro.nome}
                    helperText={errors.valor}
                    className="mt-2"
                    autoFocus
                    InputProps={
                      inicialValuesParametro?.parametro.tipoValor === "P"
                        ? {
                            endAdornment: (
                              <InputAdornment
                                position="end"
                                style={{ paddingRight: 20 }}
                              >
                                %
                              </InputAdornment>
                            ),
                          }
                        : {}
                    }
                    styles={{ marginTop: 20 }}
                  />
                </form>
              )}
            </Formik>
          )}
        </Panel>

        <Dialog
          title="Adicionar Temporada"
          open={isDialogAddSeasonOpen}
          onClickCancel={() => this.setState({ isDialogAddSeasonOpen: false })}
          onClickConfirm={this.handleSubmitAddSeason}
          btnConfirmText="Adicionar"
        >
          <Formik
            innerRef={this.formRefAddSeason}
            initialValues={{ ano: "", idTabPerformance: null }}
            validationSchema={validationSeasonSchema}
            validateOnChange={false}
            validateOnBlur={true}
            onSubmit={(values) => {
              addMeritSeason(Number(values.ano), values.idTabPerformance!);
              this.setState({ isDialogAddSeasonOpen: false });
            }}
          >
            {({
              handleSubmit,
              handleChange,
              values,
              errors,
              setFieldError,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <InputText
                  value={values.ano ?? ""}
                  onChange={(e) => {
                    handleChange(e);
                    setFieldError("ano", "");
                  }}
                  id="ano"
                  error={!!errors.ano}
                  name="ano"
                  type="number"
                  label="Ano"
                  helperText={errors.ano}
                  autoFocus
                  className="mt-2"
                />
                <Dropdown
                  label="Tabela de Performance"
                  name="idTabPerformance"
                  values={values.idTabPerformance ?? ""}
                  handleChange={(e: any) =>
                    setFieldValue("idTabPerformance", Number(e.target.value))
                  }
                  errors={!!errors.idTabPerformance}
                >
                  {tabPerformances.data.map((tab, i) => (
                    <MenuItem key={i} value={String(tab.idTabPerformance)}>
                      {tab.nomeTabPerformance}
                    </MenuItem>
                  ))}
                </Dropdown>
              </form>
            )}
          </Formik>
        </Dialog>
      </>
    );
  }
}

const convertTypeValue = (type?: string): string => {
  switch (type) {
    case "N":
    case "P":
      return "number";
    default:
      return "text";
  }
};

const convertValue = (value: string | number, type?: string): string => {
  if (!!value) {
    switch (type) {
      case "P":
        return `${value}%`;
      default:
        return String(value);
    }
  } else {
    return "";
  }
};

/**
 * Validação do formulário
 */
const validationSeasonSchema = yup.object().shape({
  ano: yup.string().required("Campo obrigatório"),
  idTabPerformance: yup.number().required("Campo obrigatório"),
});

const validationParametroSchema = yup.object().shape({
  valor: yup.string().required("Campo obrigatório"),
});

const itemsBreadCrumb: BreadcrumbItems[] = [
  {
    text: "Home",
    isCurrentItem: false,
    icon: "HomeSolid",
    onlyIcon: true,
    url: "/",
  },
  { text: "Configurações de Mérito", isCurrentItem: true },
];

const mapStateToProps = (state: RootState) => ({
  merits: state.adminMeritReducer,
  tabPerformances: state.tabPerformancesReducer,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      ...getMeritConfigs,
      ...getMeritParametroById,
      ...editMeritParametro,
      ...getMeritPerformanceById,
      ...editMeritPerformance,
      ...getMeritSeasons,
      ...addMeritSeason,
      ...getTabPerformances,
      ...clearMerit,
      ...setCurrentPage,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ConfigMerit);
