import styled from 'styled-components';

import { calcConstrast } from "~/assets/js/colors";

export const Container = styled.div`
  position: relative;
  top: 50px;
  padding: 15px 0;
`;

export const Table = styled.table`
    width: max-content;
    margin-bottom: 15px;
    position: relative;
    top: 15px;
    border-collapse: separate;
    border-spacing: 0px;
    padding-right: 20px;
`

export const TdMetaHeader = styled.th<{ bold?: boolean, colorText?: string }>`
    width: 70px;
    min-width: 70px;
    height: 30px;
    box-sizing: border-box;
    text-align: center;
    border: 1px solid #CFD3DA;
    border-bottom: 1px solid #CFD3DA;
    background-color: #FFFFFF;
    border-right: 1px solid transparent;
    font-size: 12px;
    color: ${props => props.colorText ? props.colorText : "#323130"};
    font-weight: ${props => props.bold ? "bold" : "normal"};
`

export const TdPillar = styled.td<{ flagGeral: boolean, colorBorder?: string }>`
    width: ${props => props.flagGeral ? 580 : 150}px;
    box-sizing: border-box;
    color: #FFFFFF;
    padding: 0 15px;
    box-sizing: border-box;
    font-size: 14px;
    text-align: left;
    border-right: ${props => props.flagGeral ? `1px solid ${props.colorBorder} !important` : ""};
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    border-top-right-radius:${props => props.flagGeral ? 2 : 0}px;
    border-bottom-right-radius:${props => props.flagGeral ? 2 : 0}px;

`

export const BtnIcon = styled.button<{ bgColor: string }>`
    opacity: 0;
    top: 10px;
    right: 10px;
    position: absolute;
    border: 1px solid transparent;
    border-radius: 2px;
    padding: 2px;
    background-color: transparent;
    transition: opacity 0.2s;
    cursor: pointer;


    &:hover{
        background-color: rgba(255,255,255, 0.1);
    }

    &:focus{
        outline: none;
}
`

export const TdGoal = styled.td`
    width: 430px;
    text-align: left;
    box-sizing: border-box;
    padding: 0 15px;
    font-size: 12px;
    position: relative;

    &:hover{
        ${BtnIcon}{
            opacity: 1;
            transition: opacity 0.2s;
        }
    }
    `

export const TdItem = styled.td<{ color?: string }>`
    width: 230px;
    box-sizing: border-box;
    font-size: 12px;
    text-align: left;
    padding: 5px 40px 5px 10px;
    position: relative;
    ${({ color }) => color ? `
        background-color: ${color};
        color: ${calcConstrast(color) ? '#FFFFFF' : '#000000'} !important;
        border-left: 1px solid #FFFFFF !important;
    ` : ''};
    &:hover{
        ${BtnIcon}{
            opacity: 1;
            transition: opacity 0.2s;
        }
    }
`

export const TdMeta = styled.td<{ darker?: boolean, bold?: boolean, colorText?: string }>`
    width: 70px;
    text-align: center;
    border: 1px solid #CFD3DA;
    border-bottom: 1px solid transparent;
    border-right: 1px solid transparent;
    color: ${props => props.colorText ? props.colorText : "#323130"};
    font-weight: ${props => props.bold ? "bold" : "normal"};
    background-color: ${props => props.darker ? "#F0F0F0" : "transparent"};
    box-sizing: border-box;
    font-size: 12px;
    position: relative;
    cursor: pointer;
    :hover{
        filter: brightness(0.85);
        background-color: ${props => props.darker ? "#F0F0F0" : "#fafafa"};
        border: 1px solid   #CFD3DA !important;
    }
    :active{
        border: 2px solid #000 !important;
        border-radius: 2px;
    }
    `

export const TdPeso = styled.td<{ color?: string, borderColor?: boolean }>`
    width: 40px;
    max-width: 40px;
    height: 30px;
    text-align: center;
    border: 1px solid ${props => props.borderColor ? "#FFFFFF" : "#CFD3DA"};
    border-bottom: 1px solid transparent;
    border-right: 1px solid transparent;
    background-color: ${props => props.color};
    color: ${props => calcConstrast(props.color!) ? "#FFFFFF" : "#323130"};
    box-sizing: border-box;
    padding: 1px;
    font-size: 10px;
    position: relative;
    cursor: pointer;
    :hover{
        filter: brightness(0.85);
        background-color: ${props => props.color ? props.color : "#fafafa"};
        border: 1px solid   #CFD3DA !important;
        cursor: pointer;
    }
    :active{
        border: 2px solid #000 !important;
        border-radius: 2px;
    }
`

export const TdPesoHeader = styled.th<{ color?: string, colorText?: boolean, borderColor?: boolean }>`
    width: 40px;
    min-width: 40px;
    max-width: 40px;
    overflow: hidden;
    height: 30px;
    text-align: center;
    border: 1px solid ${props => props.borderColor ? "#FFFFFF" : "#CFD3DA"};
    /* border-bottom: 1px solid transparent; */
    border-right: 1px solid transparent;
    background-color: #FFFFFF;
    color: ${props => props.colorText ? "#FFFFFF" : "#323130"};
    box-sizing: border-box;
    font-size: 12px;
    position: relative;
    cursor: pointer;

    &:hover{
        background-color: #F5F5F5;
    }

    &:active{
        background-color: #CCCCCC;
    }
`

export const TdPesoTotal = styled.td`
    width: 40px;
    box-sizing: border-box;
    text-align: center;
    border: 1px solid #000000;
    border-right: 1px solid #CFD3DA;
    background-color: #000000;
    color: #FFFFFF;
    font-size: 10px;

`

export const Gap = styled.td`
    width: 10px;
    background-color: #FFFFFF;
    border: none !important;
    display: block;
`

export const GapHeader = styled.th`
    width: 10px;
    background-color: #FFFFFF;
    border: none !important;
    display: block;
`

export const ColumnTitle = styled.th<{ color: string, textColor?: string }>`
    z-index: 0 !important;
    text-align: center;
    padding: 5px;
    font-weight: bold;
    color: ${props => props.textColor ? props.textColor : "#323130"};
    background-color: ${props => props.color};
    border: 1px solid ${props => props.color};
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    font-size: 12px;
`

export const TdSemMeta = styled.td`
    width: 70px;
    border: none !important;
    background-color: #FFFFFF;
`

export const TrHeader = styled.tr`
    td:first-child{
        border-bottom-left-radius: 2px;
    }
    td.max {
        border-bottom-right-radius: 2px;
        border-right: 1px solid #CFD3DA;
    }
    td:last-child{
        border-right: 1px solid #CFD3DA;
    }
`

export const TrTotal = styled.tr`
    td:nth-child(3){
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
    }

    td:last-child{
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
    }
`

export const TdTotal = styled.td`
    width: 800px;
    background-color: black;
    color: white;
    text-align: center;
    padding: 5px;
    font-weight: bold;
    box-sizing: border-box;
    border-radius: 2px;
    font-size: 12px;
`

export const Tr = styled.tr`
    td {
        height: 44px;
    }
    :hover {
        background-color: #F5F5F5 !important;
    }
`

export const TBody = styled.tbody<{ color: string }>`

    ${Tr}.tbl-expanded {
        display: none;
    }

    ${Tr}.tbl-last-first-obj{
        td:first-child{
            border-bottom: 1px solid ${({ color }) => color};
        };
        td{
            border-bottom: 1px solid #CFD3DA;
        };
        td:nth-child(4){
            border-bottom-left-radius: 2px;
        };
        td:nth-child(6){
            border-bottom-right-radius: 2px;
        };
        td:nth-child(8){
            border-bottom-left-radius: 2px;
        };
        td:last-child{
            border-bottom-right-radius: 2px;
        };
    };

    ${Tr}.tbl-last-first-obj.tbl-parent-closed{
        td{
            border-bottom: none;
        };
    };



    ${TdPillar}{
        background-color: ${props => props.color};
        border: 1px solid ${props => props.color};
        border-right: 1px solid #FFFFFF;
        color: ${props => calcConstrast(props.color) ? "#FFFFFF" : "#323130"};
    }

    ${TdGoal}{
        border: 1px solid ${props => props.color};
        border-bottom: 1px solid #FFFFFF;
        background-color: ${props => props.color};
        color: ${props => calcConstrast(props.color) ? "#FFFFFF" : "#323130"};
    }

    ${TdGoal}.lastParentGoal{
        border-bottom: 1px solid ${props => props.color};
    }

    ${TdItem}{
        border: 1px solid ${props => props.color};
        border-bottom: 1px solid transparent;
        color: ${props => calcConstrast(props.color) ? props.color : "#323130"};
    }

    tr:first-child td.item{
        border-top-right-radius: 2px;
    }

    tr:last-child td:nth-child(1){
        border-bottom: 1px solid ${props => props.color};
    }

    tr:last-child td.item{
        border-bottom-right-radius: 2px;
        border-bottom: 1px solid ${props => props.color};
    }

    tr:first-child .gap + td{
        border-top-left-radius: 2px;
    }

    tr:last-child .gap + td{
        border-bottom-left-radius: 2px;
    }

    tr td.max{
        border-right: 1px solid #CFD3DA;
    }

    tr:first-child td.max{
        border-top-right-radius: 2px;
    }

    tr:last-child td.max{
        border-bottom-right-radius: 2px;
    }

    tr:first-child td:last-child{
        border-top-right-radius: 2px;
    }

    tr:last-child td:last-child{
        border-bottom-right-radius: 2px;
    }

    tr td:last-child{
        border-right: 1px solid #CFD3DA;
    }

    tr:last-child td{
        border-bottom: 1px solid #CFD3DA;
    }

`


export const WrapperTblHeader = styled.div`
    height: 100%;
    margin-top: 10px;
    margin-left: 20px;

  th{
        position: sticky;
        top: 54px;
        z-index: 999;

    }
`

export const TblHeader = styled.table`
  border-collapse: separate;
  border-spacing: 0px;
  width: 100%;

    /* tr:last-child th{
        border-bottom: 1px solid #CFD3DA;
    } */

    tr:last-child th:first-child{
        border-bottom-left-radius: 2px;
    }

    tr:last-child th:nth-child(3){
        border-right: 1px solid #CFD3DA;
        border-bottom-right-radius: 2px;
    }

    tr:last-child th:nth-child(5){
        border-bottom-left-radius: 2px;
    }

    tr:last-child th:last-child{
        border-right: 1px solid #CFD3DA;
        border-bottom-right-radius: 2px;
    }

    tr:last-child th.gapHeader + th{
        border-bottom-left-radius: 2px;
    }

    tr:last-child th:last-child{
        border-bottom-right-radius: 2px;
    }
`
export const Thead = styled.thead`
    margin-left: 590px;
    display: inline;
`
