import styled from 'styled-components';

export const Div = styled.div`
    flex-basis: 100%;
    width: calc(100% - 250px);
    height: calc(100vh - 50px);
    display: flex;
    flex-direction: column;
    overflow: auto;
`;

export const Wrapper = styled.div`
  width: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  z-index: 1;
  padding: 20px;
`;
