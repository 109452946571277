import api from "~/services/api";
import { takeEvery, put, call, all } from "redux-saga/effects";
import {
    Types,
    IRequestPositionsLoadAction,
    PositionTypes,
    IRequestPositionDeleteAction,
    IRequestPositionAddAction,
    IRequestPositionEditAction,
    IRequestPositionsLoadByIdAction,
    ISuccessPositionsLoadAction,
    ISuccessPositionsLoadByIdAction
} from "../../ducks/admin/positions/types";

const apiCallGet = (search: any) => {
    return api.get(`cargos`, {
        params: {
            ...search
        }
    })
        .then(response => response.data)
        .catch(err => {
            throw err.response;
        });
};

const apiCallGetById = (idCargo: number) => {
    return api.get(`cargos/${idCargo}`)
        .then(response => response.data)
        .catch(err => {
            throw err.response;
        });
};

const apiCallPost = (item: PositionTypes) => {
    return api.post('cargos', {
        ...item
    })
        .then(response => response.data)
        .catch(err => {
            throw err.response;
        });
};

const apiCallPut = (item: PositionTypes) => {
    return api.put(`cargos/${item.idCargo}`, {
        ...item
    })
        .then(response => response.data)
        .catch(err => {
            throw err.response;
        });
};

const apiCallDel = (id: number) => {
    return api.delete(`cargos/${id}`)
        .then(response => response.data)
        .catch(err => {
            throw err.response;
        });
};

function* load(action: IRequestPositionsLoadAction) {
    try {
        const items: PositionTypes[] = yield call(apiCallGet, action.payload.search);
        yield put({
            type: Types.SUCCESS_POSITIONS_LOAD,
            payload: {
                isSearch: action.payload.isSearch,
                position: items
            }
        } as ISuccessPositionsLoadAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível carregar os cargos.';
        yield put({ type: Types.FAILURE_POSITIONS_LOAD, message });
    };
};

function* loadById(action: IRequestPositionsLoadByIdAction) {
    try {
        const item: PositionTypes = yield call(apiCallGetById, action.idCargo);
        yield put({
            type: Types.SUCCESS_POSITIONS_LOAD_BY_ID,
            payload: item
        } as ISuccessPositionsLoadByIdAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível carrega o cargo.';
        yield put({ type: Types.FAILURE_POSITIONS_LOAD_BY_ID, message });
    };
};

function* add(action: IRequestPositionAddAction) {
    try {
        const idCargo: { idCargo: number } = yield call(apiCallPost, action.position);
        yield put({ type: Types.SUCCESS_POSITION_ADD, position: { ...action.position, ...idCargo, flagAtivo: true } });
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível adicionar cargo';
        yield put({ type: Types.FAILURE_POSITION_ADD, message });
    };
};

function* edit(action: IRequestPositionEditAction) {
    try {
        yield call(apiCallPut, action.position);
        yield put({ type: Types.SUCCESS_POSITION_EDIT, position: action.position });
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível editar cargo';
        yield put({ type: Types.FAILURE_POSITION_EDIT, message });
    };
};

function* del(action: IRequestPositionDeleteAction) {
    try {
        yield call(apiCallDel, action.id);
        yield put({ type: Types.SUCCESS_POSITION_DELETE, id: action.id });
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível excluir cargo';
        yield put({ type: Types.FAILURE_POSITION_DELETE, message });
    };
};

export default function* positionsRoot() {
    yield all([
        yield takeEvery(Types.REQUEST_POSITIONS_LOAD, load),
        yield takeEvery(Types.REQUEST_POSITIONS_LOAD_BY_ID, loadById),
        yield takeEvery(Types.REQUEST_POSITION_ADD, add),
        yield takeEvery(Types.REQUEST_POSITION_EDIT, edit),
        yield takeEvery(Types.REQUEST_POSITION_DELETE, del),
    ]);
};