import { Types, DataTypes, CyclePerformanceActionTypes, CycloPerformanceType } from "./types";
import customToast from "~/components/Toast/index";

/**
 * Reducers
 */
const INITIAL_STATE: DataTypes = {
    data: [],
    loadingData: false,
    loadingAction: false,
    success: false,
    error: false
};


/**
 * Actions
 */

export const Creators = {
    getCyclePerformance: (idCiclo: number): CyclePerformanceActionTypes => ({
        type: Types.REQUEST_CYCLE_PERFORMANCE_LOAD,
        idCiclo
    }),

    addCyclePerformance: (performance: CycloPerformanceType): CyclePerformanceActionTypes => ({
        type: Types.REQUEST_CYCLE_PERFORMANCE_ADD,
        payload: performance
    }),

    editCyclePerformance: (idCiclo: number, performance: CycloPerformanceType): CyclePerformanceActionTypes => ({
        type: Types.REQUEST_CYCLE_PERFORMANCE_EDIT,
        payload: {
            idCiclo,
            performance
        }
    }),

    delCyclePerformance: (idCiclo: number, idTabPerformance: number): CyclePerformanceActionTypes => ({
        type: Types.REQUEST_CYCLE_PERFORMANCE_DEL,
        payload: {
            idCiclo,
            idTabPerformance
        }
    }),
}


export default function cyclePerformanceReducer(state = INITIAL_STATE, action: CyclePerformanceActionTypes): DataTypes {

    switch (action.type) {
        case Types.REQUEST_CYCLE_PERFORMANCE_LOAD:
            return { ...state, loadingData: true, error: false, success: false };
        case Types.SUCCESS_CYCLE_PERFORMANCE_LOAD:
            return { ...state, data: action.payload, loadingData: false, error: false, success: true };
        case Types.FAILURE_CYCLE_PERFORMANCE_LOAD:
            customToast.error(action.message);
            return { ...state, data: [], loadingData: false, error: true, success: false };

        case Types.REQUEST_CYCLE_PERFORMANCE_ADD:
            return { ...state, error: false, success: false, loadingAction: true }
        case Types.SUCCESS_CYCLE_PERFORMANCE_ADD:
            return { ...state, error: false, success: true, data: [...state.data, action.payload], loadingAction: false }
        case Types.FAILURE_CYCLE_PERFORMANCE_ADD:
            return { ...state, error: true, success: false, loadingAction: false }

        case Types.REQUEST_CYCLE_PERFORMANCE_EDIT:
            return { ...state, error: false, success: false, loadingAction: true }
        case Types.SUCCESS_CYCLE_PERFORMANCE_EDIT:
            return {
                ...state, error: false, success: true, loadingAction: false, data: state.data.map(item => {
                    if (item.idTabPerformance !== action.payload.idTabPerformance) {
                        return item;
                    } else {
                        return { ...item, ...action.payload }
                    }
                })
            }
        case Types.FAILURE_CYCLE_PERFORMANCE_EDIT:
            return { ...state, error: true, success: false, loadingAction: false }

        case Types.REQUEST_CYCLE_PERFORMANCE_DEL:
            return { ...state, error: false, success: false }
        case Types.SUCCESS_CYCLE_PERFORMANCE_DEL:
            return { ...state, error: false, success: true, data: state.data.filter(item => item.idTabPerformance !== action.idTabPerformance) }
        case Types.FAILURE_CYCLE_PERFORMANCE_DEL:
            return { ...state, error: true, success: false }

        default:
            return state;
    }
}