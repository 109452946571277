import {
  Types,
  DataTypes,
  MeritActionTypes,
  MeritPerformanceType,
} from "./types";
import customToast from "~/components/Toast/index";

/**
 * Reducers
 */
const INITIAL_STATE: DataTypes = {
  data: null,
  seasonData: [],
  itemSelected: {
    parametro: null,
    performance: null,
    success: false,
    error: false,
    loading: false,
  },
  error: false,
  loadingData: false,
  loadingAction: false,
  loadingSeason: false,
  success: false,
};

/**
 * Actions
 */

export const Creators = {
  getMeritConfigs: (idConfigMerito: number): MeritActionTypes => {
    return {
      type: Types.REQUEST_MERIT_LOAD,
      idConfigMerito,
    };
  },

  getMeritParametroById: (
    idConfigMerito: number,
    idConfigMeritoParametro: number
  ): MeritActionTypes => {
    return {
      type: Types.REQUEST_MERIT_LOAD_BY_ID,
      payload: {
        idConfigMerito,
        idConfigMeritoParametro,
      },
    };
  },

  editMeritParametro: (
    idConfigMerito: number,
    idConfigMeritoParametro: number,
    valor: number | string
  ): MeritActionTypes => {
    return {
      type: Types.REQUEST_PARAMETRO_EDIT,
      payload: {
        idConfigMerito,
        idConfigMeritoParametro,
        valor,
      },
    };
  },

  getMeritPerformanceById: (
    idConfigMerito: number,
    idConfigMeritoPerformance: number
  ): MeritActionTypes => ({
    type: Types.REQUEST_MERIT_PERFORMANCE_LOAD_BY_ID,
    payload: {
      idConfigMerito,
      idConfigMeritoPerformance,
    },
  }),

  editMeritPerformance: (
    idConfigMerito: number,
    performance: MeritPerformanceType
  ): MeritActionTypes => ({
    type: Types.REQUEST_MERIT_PERFORMANCE_EDIT,
    payload: {
      idConfigMerito,
      performance,
    },
  }),

  getMeritSeasons: (): MeritActionTypes => {
    return {
      type: Types.REQUEST_MERIT_SEASONS_LOAD,
    };
  },

  addMeritSeason: (ano: number, idTabPerformance: number): MeritActionTypes => {
    return {
      type: Types.REQUEST_MERIT_SEASONS_ADD,
      payload: {
        ano,
        idTabPerformance,
      },
    };
  },

  clearMerit: (): MeritActionTypes => ({
    type: Types.CLEAR_MERIT,
  }),
};

export default function meritReducer(
  state = INITIAL_STATE,
  action: MeritActionTypes
): DataTypes {
  switch (action.type) {
    //LOAD
    case Types.REQUEST_MERIT_LOAD:
      return {
        ...state,
        data: null,
        loadingData: true,
        error: false,
        success: false,
      };
    case Types.SUCCESS_MERIT_LOAD:
      return {
        ...state,
        data: action.payload,
        loadingData: false,
        error: false,
        success: true,
      };
    case Types.FAILURE_MERIT_LOAD:
      customToast.error(action.message);
      return {
        ...state,
        data: null,
        loadingData: false,
        error: true,
        success: false,
      };

    //LOAD PARAMETRO
    case Types.REQUEST_MERIT_LOAD_BY_ID:
      return {
        ...state,
        itemSelected: {
          performance: null,
          parametro: null,
          loading: true,
          success: false,
          error: false,
        },
      };
    case Types.SUCCESS_MERIT_LOAD_BY_ID:
      return {
        ...state,
        itemSelected: {
          performance: null,
          parametro: action.payload,
          loading: false,
          success: true,
          error: false,
        },
      };
    case Types.FAILURE_MERIT_LOAD_BY_ID:
      customToast.error(action.message);
      return {
        ...state,
        itemSelected: {
          performance: null,
          parametro: null,
          loading: false,
          success: false,
          error: true,
        },
      };

    //LOAD PERFORMANCE
    case Types.REQUEST_MERIT_PERFORMANCE_LOAD_BY_ID:
      return {
        ...state,
        itemSelected: {
          performance: null,
          parametro: null,
          loading: true,
          success: false,
          error: false,
        },
      };
    case Types.SUCCESS_MERIT_PERFORMANCE_LOAD_BY_ID:
      return {
        ...state,
        itemSelected: {
          performance: action.payload,
          parametro: null,
          loading: false,
          success: true,
          error: false,
        },
      };
    case Types.FAILURE_MERIT_PERFORMANCE_LOAD_BY_ID:
      customToast.error(action.message);
      return {
        ...state,
        itemSelected: {
          performance: null,
          parametro: null,
          loading: false,
          success: false,
          error: true,
        },
      };

    //LOAD PERFORMANCE
    case Types.REQUEST_MERIT_PERFORMANCE_EDIT:
      return { ...state, loadingAction: true, success: false, error: false };
    case Types.SUCCESS_MERIT_PERFORMANCE_EDIT:
      customToast.success("Performance editada com sucesso");
      return {
        ...state,
        data: {
          ...state.data!,
          performances: state.data!.performances.map((item) => {
            if (
              item.idConfigMeritoPerformance !==
              action.payload.idConfigMeritoPerformance
            ) {
              return item;
            } else {
              return action.payload;
            }
          }),
        },
        loadingAction: false,
        success: true,
        error: false,
      };
    case Types.FAILURE_MERIT_PERFORMANCE_EDIT:
      customToast.error(action.message);
      return { ...state, loadingAction: false, success: false, error: true };

    //EDIT PARAMETRO
    case Types.REQUEST_PARAMETRO_EDIT:
      return { ...state, loadingAction: true, success: false, error: false };
    case Types.SUCCESS_PARAMETRO_EDIT:
      customToast.success("Configuração salva com sucesso");
      return {
        ...state,
        data: {
          ...state.data!,
          parametros: state.data!.parametros.map((item) => {
            if (
              item.idConfigMeritoParametro !==
              action.payload.idConfigMeritoParametro
            ) {
              return item;
            } else {
              return {
                ...item,
                valor: action.payload.valor,
              };
            }
          }),
        },
        loadingAction: false,
        success: true,
        error: false,
      };
    case Types.FAILURE_PARAMETRO_EDIT:
      customToast.error(action.message);
      return { ...state, loadingAction: false, success: false, error: true };

    //LOAD SEASON
    case Types.REQUEST_MERIT_SEASONS_LOAD:
      return {
        ...state,
        seasonData: [],
        loadingSeason: true,
        error: false,
        success: false,
      };
    case Types.SUCCESS_MERIT_SEASONS_LOAD:
      return {
        ...state,
        seasonData: action.payload,
        loadingSeason: false,
        error: false,
        success: true,
      };
    case Types.FAILURE_MERIT_SEASONS_LOAD:
      customToast.error(action.message);
      return {
        ...state,
        seasonData: [],
        loadingSeason: false,
        error: true,
        success: false,
      };

    //ADD SEASON
    case Types.REQUEST_MERIT_SEASONS_ADD:
      return { ...state, loadingSeason: true, error: false, success: false };
    case Types.SUCCESS_MERIT_SEASONS_ADD:
      customToast.success("Temporada adicionada com sucesso");
      return {
        ...state,
        data: action.payload,
        loadingSeason: false,
        error: false,
        success: true,
      };
    case Types.FAILURE_MERIT_SEASONS_ADD:
      customToast.error(action.message);
      return { ...state, loadingSeason: false, error: true, success: false };

    case Types.CLEAR_MERIT:
      return { ...state, data: null };

    default:
      return state;
  }
}
