import React, { useState } from "react";

import { Shimmer, Stack } from "office-ui-fabric-react";
import LinearProgress from "@material-ui/core/LinearProgress";
import { withStyles } from "@material-ui/core/styles";

import { Wrapper, Title, Value, Subtitle, Icon, EditIcon } from "./styles";
import Colors from "~/assets/js/colors";
import { toCurrency } from "~/utils/numbers";

interface ICardProps {
  color?: string;
  bgColor?: string;
  title: string;
  value: string | number;
  iconName: string;
  subtitle?: string;
  percent?: number;
  styles?: React.CSSProperties;
  editActionButton?: {
    canEdit: boolean;
    onClick: () => void;
  };
}

const Card: React.FC<ICardProps> = (props) => {
  const {
    title,
    color,
    value,
    subtitle,
    percent,
    iconName,
    bgColor,
    styles,
    editActionButton
  } = props;
  const isNegative = value < 0;

  const [isHovered, setHovered] = useState<boolean>(false);

  const Progress = withStyles({
    root: {
      backgroundColor: `${isNegative ? Colors.error : color}30`,
      height: 5,
      borderRadius: 3,
      "& .MuiLinearProgress-barColorPrimary": {
        backgroundColor: isNegative ? Colors.error : color,
      },
    },
  })(LinearProgress);
  return (
    <Wrapper
      bg={bgColor}
      color={isNegative ? Colors.error : color}
      style={styles}
      isNegative={isNegative}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Icon iconName={iconName} />
      {(editActionButton?.canEdit && isHovered) &&
        <EditIcon iconProps={{ iconName: "edit" }} onClick={editActionButton.onClick} />}
      <Stack
        verticalAlign={"space-between"}
        horizontalAlign={"end"}
        styles={{ root: { height: "100%" } }}
      >
        <Title>{title}</Title>
        <Value>{toCurrency(value).slice(0, -3)}</Value>
        {!!subtitle && <Subtitle>{subtitle}</Subtitle>}
        {percent && (
          <Stack
            verticalAlign="center"
            styles={{ root: { height: 15, width: "100%" } }}
          >
            <Progress
              variant="determinate"
              value={isNegative ? 100 : percent}
            />
          </Stack>
        )}
      </Stack>
    </Wrapper>
  );
};

export default Card;
