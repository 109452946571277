import React from "react";

import { Text, ITextStyles } from "office-ui-fabric-react/lib/Text";
import { FontIcon } from "office-ui-fabric-react/lib/Icon";

import { DetailsWrapperItem, DetailsWrapperText, IconAction } from "./styles";
import {
  TooltipHost,
} from "office-ui-fabric-react/lib/Tooltip";
import { useId } from "@uifabric/react-hooks";

interface ILabelGroupProps {
  icon: string;
  label: string;
  value?: string | number;
  fontSize?: number;
  name?: string;
  size?: "full" | "half";
  tooltipMessage?: string;
  styles?: React.CSSProperties;
  iconAction?: {
    iconName: string;
    mensagem: string;
    show: boolean;
  };
  onRender?: JSX.Element;
}

const LabelGroup: React.FC<ILabelGroupProps> = (props) => {
  const {
    icon,
    onRender,
    label,
    value,
    fontSize,
    size,
    styles,
    name,
    iconAction,
    tooltipMessage,
  } = props;
  const tooltipId = useId("tooltip");

  const textStyle: Partial<ITextStyles> = {
    root: {
      fontWeight: "bold",
      fontSize: fontSize ? fontSize : 12,
      height: 20,
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "100%",
      whiteSpace: "nowrap",
    },
  };

  return (
    <DetailsWrapperItem style={styles} size={size} className={name}>
      <FontIcon iconName={icon} style={{ marginRight: 6, color: "#666666" }} />
      <DetailsWrapperText>
        <Text
          variant="small"
          styles={{
            root: {
              color: "#666666",
              marginBottom: 4,
              fontSize: fontSize ? fontSize : 12,
            },
          }}
        >
          {label}
        </Text>
        {tooltipMessage ? (
          <TooltipHost
            content={tooltipMessage}
            id={tooltipId}
            styles={{
              root: {
                width: "100%",
                maxWidth: 300,
                overflow: "hidden",
                textOverflow: "ellipsis",
                position: "relative",
              },
            }}
          >
            {!onRender ? (
              <Text variant="small" styles={textStyle}>
                {value}
              </Text>
            ) : (
              <>{onRender}</>
            )}
          </TooltipHost>
        ) : !onRender ? (
          <Text variant="small" styles={textStyle}>
            {value}
          </Text>
        ) : (
          <>{onRender}</>
        )}
      </DetailsWrapperText>
      {iconAction && iconAction.show && (
        <TooltipHost
          content={iconAction.mensagem}
          id={tooltipId}
          styles={{ root: { alignSelf: "center", marginLeft: 16 } }}
        >
          <IconAction iconName={iconAction.iconName} />
        </TooltipHost>
      )}
    </DetailsWrapperItem>
  );
};

export { LabelGroup };
