import api from "~/services/api";
import { takeEvery, put, call, all } from "redux-saga/effects";
import { Types, IRequestCompanyAddAction, ICompany, IRequestCompanyDeleteAction, IRequestCompaniesLoadAction } from "../../ducks/admin/companies/types";

const apiCallGet = (search: any) => {
    const searchFilter = search.searchText !== "" ? `?SearchText=${search.searchText}`: "?";
    const filter = search.filterAtivo !== null ? `&FilterAtivo=${search.filterAtivo}`: "";

    return api.get(`empresas${searchFilter}${filter}`)
        .then(response => response.data)
        .catch(err => {
            throw err.response;
        });
};

const apiCallPost = (item: ICompany) => {
    const { codEmpresa, nomeEmpresa } = item
    return api.post('empresas', {
        codEmpresa,
        nomeEmpresa,
        flagAtiva: true
    })
        .then(response => response.data)
        .catch(err => {
            throw err.response;
        });
};

const apiCallPut = (item: ICompany) => {
    return api.put(`empresas/${item.idEmpresa}`, {
        ...item
    })
        .then(response => response.data)
        .catch(err => {
            throw err.response;
        });
};

const apiCallDel = (id: number) => {
    return api.delete(`empresas/${id}`)
        .then(response => response.data)
        .catch(err => {
            throw err.response;
        });
};

function* loadCompanies(action: IRequestCompaniesLoadAction) {
    try {
        const items = yield call(apiCallGet, action.search);
        yield put({ type: Types.SUCCESS_COMPANIES_LOAD, payload: items });
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível carregar as empresas.';
        yield put({ type: Types.FAILURE_COMPANIES_LOAD, message });
    };
};

function* add(action: IRequestCompanyAddAction) {
    try {
        const idEmpresa = yield call(apiCallPost, action.company);
        yield put({ type: Types.SUCCESS_COMPANY_ADD, company: { ...action.company, ...idEmpresa, flagAtiva: true } });
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível adicionar empresa';
        yield put({ type: Types.FAILURE_COMPANY_ADD, message });
    };
};

function* edit(action: IRequestCompanyAddAction) {
    try {
        yield call(apiCallPut, action.company);
        yield put({ type: Types.SUCCESS_COMPANY_EDIT, company: action.company });
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível editar empresa';
        yield put({ type: Types.FAILURE_COMPANY_EDIT, message });
    };
};

function* del(action: IRequestCompanyDeleteAction) {
    try {
        yield call(apiCallDel, action.id);
        yield put({ type: Types.SUCCESS_COMPANY_DELETE, id: action.id });
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível excluir empresa';
        yield put({ type: Types.FAILURE_COMPANY_DELETE, message });
    };
};

export default function* companiesRoot() {
    yield all([
        yield takeEvery(Types.REQUEST_COMPANIES_LOAD, loadCompanies),
        yield takeEvery(Types.REQUEST_COMPANY_ADD, add),
        yield takeEvery(Types.REQUEST_COMPANY_EDIT, edit),
        yield takeEvery(Types.REQUEST_COMPANY_DELETE, del),
    ]);
};