import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation, useHistory } from "react-router-dom";
import {
  ICommandBarStyles,
  MessageBar,
  MessageBarButton,
  MessageBarType,
  Spinner,
  SpinnerSize,
  Stack,
} from "office-ui-fabric-react";
import { MenuItem } from "@material-ui/core";
import { useFormik } from "formik";
import * as yup from "yup";

import { CommandBarButton, OutlinedButton } from "~/components/Buttons";
import PainelHeader from "~/components/layout/PainelHeader";
import { UploadCard, UploadFile } from "~/components/UploadCard";
import Chip from "~/components/Chip";
import Dropdown from "~/components/layout/Dropdown";

import { Container, Content } from "./styles";
import customToast from "~/components/Toast/index";
import { ChoiceGroup, IChoiceGroupOption, TooltipHost } from "@fluentui/react";

import { Creators as CreatorsHome } from "~/store/ducks/home";
import { BreadcrumbItems, Page } from "~/store/ducks/home/types";
import Accordion from "~/components/Accordion";
import { InputAutocomplete, InputDate, InputText } from "~/components/Forms";
import {
  ConductType,
  DataTypes as DataTypesConduct,
} from "~/store/ducks/admin/conducts/types";
import Dialog from "~/components/CustomDialog";

import { Creators as CreatorsConducts } from "~/store/ducks/admin/conducts";
import { DataTypes as DataTypesSanction } from "~/store/ducks/admin/sanctions/types";
import { Creators as CreatorsSanctions } from "~/store/ducks/admin/sanctions";
import { RootState } from "~/store/ducks";
import {
  EducationalMeasureType,
  TestemunhaType,
  DataTypes as DataTypesEducationalMeasure,
} from "~/store/ducks/educationalMeasure/types";
import { Creators as CreatorsMedida } from "~/store/ducks/educationalMeasure";
import moment from "moment";
import { Creators as CreatorsFunc } from "~/store/ducks/employees";
import {
  EmployeeType,
  DataTypes as DataTypesFunc,
} from "~/store/ducks/employees/types";
import { Creators as CreatorsProfile } from "~/store/ducks/profile";

let timeout: number = 0;
const EducationalMeasure: React.FC = () => {
  const condutasState = useSelector<RootState, DataTypesConduct>(
    (state) => state.conductsReducer
  );
  const { data: sanctions } = useSelector<RootState, DataTypesSanction>(
    (state) => state.sanctionReducer
  );
  const { data: employees, info: employeeInfo } = useSelector<
    RootState,
    DataTypesFunc
  >((state) => state.employeesReducer);
  const medidaState = useSelector<RootState, DataTypesEducationalMeasure>(
    (state) => state.educationalMeasureReducer
  );
  const { data: medidaData } = medidaState;
  const [conductSelected, setConductSelected] = useState<Partial<
    ConductType
  > | null>(null);
  const [anexos, setAnexos] = useState<File[]>([]);
  const [assinatura, setAssinatura] = useState<File[]>([]);
  const [isModalTestemunhaOpen, setIsModalTestemunhaOpen] = useState<boolean>(
    false
  );
  const [selectedTypeTestemunha, setSelectedTypeTestemunha] = useState<
    string | undefined
  >("I");
  const [selectedFunc, setSelectedFunc] = useState<EmployeeType | null>(null);

  const history = useHistory();
  const dispatch = useDispatch();
  const { state } = useLocation<{ nomeCompleto?: string }>();

  const { idFuncionario, idMedidaEducativa } = useParams<{
    idFuncionario?: string;
    idMedidaEducativa?: string;
  }>();

  const readOnly = medidaState.loading || !!medidaData.statusmedida;

  const itemsBreadCrumb: BreadcrumbItems[] = [
    {
      text: "Home",
      isCurrentItem: false,
      icon: "HomeSolid",
      onlyIcon: true,
      url: "/",
    },
    { text: "Perfil", url: `/perfil/${idFuncionario}` },
    { text: "Medida Educativa", isCurrentItem: true },
  ];

  const nomeFunc =
    medidaData.funcionario?.nomeCompleto ??
    state?.nomeCompleto ??
    employeeInfo.nomeCompleto;

  const goBack = () => {
    dispatch(CreatorsProfile.setCurrentTab("5"));
    history.push({
      pathname: `/perfil/${idFuncionario}`,
    });
    dispatch(CreatorsMedida.clear());
  };

  useEffect(() => {
    resetForm({ values: initialValues });
    dispatch(CreatorsMedida.clear());

    dispatch(CreatorsSanctions.getSanctions());

    if (idFuncionario && idMedidaEducativa) {
      dispatch(CreatorsMedida.loadMedida(idFuncionario, idMedidaEducativa));
    }

    if (!state?.nomeCompleto && !idMedidaEducativa && idFuncionario) {
      dispatch(CreatorsFunc.getEmployeeInfo(parseInt(idFuncionario)));
    }

    const page: Page = {
      key: "medidasEducativas",
      pages: itemsBreadCrumb,
    };

    dispatch(CreatorsHome.setCurrentPage(page));
  }, []);

  useEffect(() => {
    if (medidaState.redirect && medidaState.error) {
      goBack();
    }
    // else if (medidaState.redirect && medidaState.success) {
    //   history.push({
    //     pathname: `/perfil/${idFuncionario}`,
    //   });
    // }
  }, [medidaState.redirect]);

  useEffect(() => {
    resetForm({ values: medidaData });
    if (medidaData.idConduta) {
      setConductSelected({
        idConduta: medidaData.idConduta,
        ...medidaData.conduta,
      });
    }
  }, [medidaData]);

  useEffect(() => {
    if (
      medidaState.suggestion.idSancaoSugerida &&
      !medidaData.idMedidaEducativa
    ) {
      setFieldValue("idSancao", medidaState.suggestion.idSancaoSugerida);
      if (
        values.idSancao !== null &&
        medidaState.suggestion.idSancaoSugerida !== values.idSancao
      ) {
        customToast.warning("A sanção foi alterada!");
      }
    } else {
      setFieldValue("idSancao", null);
    }
  }, [medidaState.suggestion]);

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    resetForm,
    setFieldValue,
    setFieldError,
  } = useFormik({
    initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit(values) {
      values.idFuncionario = parseInt(idFuncionario!);
      if (!medidaData.idMedidaEducativa) {
        //Cria a media e envia os anexos
        values.conduta = {
          titulo: conductSelected?.titulo ?? "",
          gravidade: conductSelected?.gravidade ?? "",
        };
        dispatch(CreatorsMedida.addMedida(values, anexos));
      } else {
        //Envia só os anexos
        dispatch(
          CreatorsMedida.addEvidence(
            parseInt(idFuncionario!),
            medidaData.idMedidaEducativa,
            anexos
          )
        );
      }
    },
  });

  // useEffect(() => {
  //   console.log(values);
  // }, [values]);

  const {
    handleSubmit: handleSubmitTestemunha,
    handleChange: handleChangeTestemunha,
    values: valuesTestemunha,
    errors: errorsTestemunha,
    resetForm: resetFormTestemunha,
    setFieldValue: setFieldValueTestemunha,
    setFieldError: setFieldErrorTestemunha,
  } = useFormik({
    initialValues: { ...initialTestemunha, testemunhaType: "I" },
    validationSchema: validationSchemaTestemunha,
    validateOnBlur: true,
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit(valuesTestemunha) {
      let testemunha: TestemunhaType;

      if (valuesTestemunha.testemunhaType === "I") {
        testemunha = {
          idFuncionario: valuesTestemunha.idFuncionario,
          obs: valuesTestemunha.obs,
          funcionario: {
            nomeCompleto: selectedFunc?.nomeCompleto ?? "",
          },
        };
        setFieldValue("testemunhas", [...values.testemunhas, testemunha]);
      } else if (valuesTestemunha.testemunhaType === "E") {
        testemunha = {
          nomeTestemunhaExterna: valuesTestemunha.nomeTestemunhaExterna,
          obs: valuesTestemunha.obs,
        };
        setFieldValue("testemunhas", [...values.testemunhas, testemunha]);
      }

      resetFormTestemunha();
      setSelectedFunc(null);
      setIsModalTestemunhaOpen(false);
      setSelectedTypeTestemunha("I");
    },
  });

  const removeTestemunha = (idOrName?: string | number) => {
    setFieldValue(
      "testemunhas",
      values.testemunhas.filter((item) => {
        if (typeof idOrName === "number")
          return item.idFuncionario !== idOrName;
        else return item.nomeTestemunhaExterna !== idOrName;
      })
    );
  };

  const search = (text: string, type: string) => {
    if (text.trim()) {
      clearTimeout(timeout);
      timeout = window.setTimeout(() => {
        if (type === "conduta") dispatch(CreatorsConducts.getConducts());
        if (type === "func")
          dispatch(CreatorsFunc.getEmployees(text, null, true));
      }, 700);
    }
  };

  return (
    <Container>
      {
        <PainelHeader
          title="Registro de Medida Educativa"
          icon={{ icon: "WorkItemAlert" }}
          labelGroups={[
            {
              label: "Funcionário",
              value: nomeFunc,
              icon: "Contact",
              size: "full",
            },
            {
              label: "Status",
              value: medidaData.statusmedida ?? "Em Registro",
              icon: "Flag",
              size: "full",
            },
          ]}
        />
      }
      <Content>
        {medidaData.statusmedida !== "Registrada" && (
          <CommandBarButton
            iconProps={{ iconName: "Send" }}
            isLoading={medidaState.loadingAction}
            onClick={(e: any) => {
              if (medidaData.statusmedida === "Aguardando assinatura") {
                if (assinatura.length > 0) {
                  dispatch(
                    CreatorsMedida.addAssinatura(
                      idFuncionario!,
                      medidaData.idMedidaEducativa!,
                      assinatura[0]
                    )
                  );
                } else {
                  customToast.error("Anexe a assinatura do funcionário!");
                }
              } else {
                handleSubmit(e);
              }
            }}
            styles={commandButtonStyle}
          >
            Enviar
          </CommandBarButton>
        )}

        <CommandBarButton
          iconProps={{ iconName: "Cancel" }}
          onClick={goBack}
          styles={commandButtonStyle}
        >
          {readOnly ? "Fechar" : "Cancelar"}
        </CommandBarButton>
        {medidaData.statusmedida === "Aguardando assinatura" &&
          !medidaData.baixouFormulario && (
            <MessageBar
              messageBarType={MessageBarType.success}
              isMultiline={true}
              styles={{ root: { marginBottom: 16 } }}
              actions={
                <MessageBarButton
                  disabled={medidaState.loadingFicha}
                  onClick={() =>
                    dispatch(
                      CreatorsMedida.geraForm(
                        idFuncionario!,
                        medidaData.idMedidaEducativa!
                      )
                    )
                  }
                >
                  Baixar Ficha da Ocorrência
                  {medidaState.loadingFicha && (
                    <Spinner
                      size={SpinnerSize.small}
                      styles={{ root: { marginLeft: 8 } }}
                    />
                  )}
                </MessageBarButton>
              }
            >
              A ocorrência foi registrada com sucesso e está disponível para
              download.
            </MessageBar>
          )}
        <form onSubmit={handleSubmit}>
          <Accordion title="Registro da ocorrência" defaultExpanded={true}>
            <Stack styles={{ root: { marginBottom: 32 } }}>
              <Stack horizontal>
                <Stack styles={{ root: { width: 176, marginRight: 60 } }}>
                  <InputDate
                    label="Data da Ocorrência"
                    id="dataOcorrencia"
                    name="dataOcorrencia"
                    value={
                      values.dataOcorrencia
                        ? moment(values.dataOcorrencia)
                        : null
                    }
                    onChange={(value) => {
                      setFieldValue("dataOcorrencia", value);
                      setFieldError("dataOcorrencia", "");
                    }}
                    onBlur={() => {
                      if (
                        idFuncionario &&
                        values.dataOcorrencia &&
                        values.idConduta
                      ) {
                        dispatch(
                          CreatorsMedida.loadSuggestionSanction(
                            parseInt(idFuncionario),
                            values.dataOcorrencia,
                            values.idConduta
                          )
                        );
                      }
                    }}
                    error={!!errors.dataOcorrencia}
                    helperText={errors.dataOcorrencia}
                    disabled={readOnly}
                  />
                </Stack>
                <Stack styles={{ root: { width: 360 } }}>
                  <InputAutocomplete
                    value={conductSelected as ConductType}
                    onChange={(_, newValue) => {
                      setFieldValue("idConduta", newValue?.idConduta);
                      setConductSelected(newValue);
                    }}
                    onInputChange={(_, newInputValue) => {
                      search(newInputValue, "conduta");
                      setFieldError("idConduta", "");
                    }}
                    onBlur={() => {
                      if (
                        idFuncionario &&
                        values.dataOcorrencia &&
                        values.idConduta
                      ) {
                        dispatch(
                          CreatorsMedida.loadSuggestionSanction(
                            parseInt(idFuncionario),
                            values.dataOcorrencia,
                            values.idConduta
                          )
                        );
                      }
                    }}
                    getOptionLabel={(conduta: ConductType) => conduta.titulo}
                    getOptionSelected={(option, value) =>
                      option.idConduta === value.idConduta
                    }
                    options={condutasState.data}
                    disabled={readOnly}
                    input={{
                      idInput: "idConduta",
                      labelInput: "Conduta",
                      helperTextInput: errors.idConduta as string,
                      errorInput: !!errors.idConduta,
                    }}
                  />
                </Stack>
              </Stack>
              <InputText
                label="Descrição da Ocorrência"
                id="descricao"
                name="descricao"
                value={values.descricao}
                onChange={(e) => {
                  handleChange(e);
                  setFieldError("descricao", "");
                }}
                multiline
                rows={3}
                error={!!errors.descricao}
                helperText={errors.descricao}
                disabled={readOnly}
              />
            </Stack>
          </Accordion>
          <Accordion title="Evidências & Anexos" defaultExpanded={true}>
            <Stack styles={{ root: { marginBottom: 32 } }}>
              {!readOnly || medidaData.statusmedida === "Aguardando anexos" ? (
                <UploadCard
                  title="Envie a foto ou arquivo escaneado do documento"
                  descriptionUpload="Armazene preferencialmente arquivos PDF, JPG ou PNG"
                  managerDocs={[anexos, setAnexos]}
                  multiple
                  error={medidaState.errorAnexos}
                />
              ) : (
                medidaData.anexos.map((anexo) => (
                  <UploadFile
                    key={anexo.idAnexo}
                    name={anexo.anexo.name}
                    size={anexo.anexo.size}
                    readOnly
                  />
                ))
              )}
            </Stack>
          </Accordion>
          {medidaState.data?.assinaturaNecessaria &&
            (medidaData.statusmedida === "Aguardando assinatura" ||
              medidaData.statusmedida === "Registrada") && (
              <Accordion
                title="Assinatura do Funcionário"
                defaultExpanded={true}
              >
                <Stack styles={{ root: { marginBottom: 32 } }}>
                  {medidaData.statusmedida === "Aguardando assinatura" ? (
                    <UploadCard
                      title="Envie a foto ou arquivo escaneado do documento"
                      descriptionUpload="Armazene preferencialmente arquivos PDF, JPG ou PNG"
                      managerDocs={[assinatura, setAssinatura]}
                    />
                  ) : (
                    <UploadFile
                      name={medidaData.anexoassinatura?.name ?? ""}
                      size={medidaData.anexoassinatura?.size ?? 0}
                      readOnly
                    />
                  )}
                </Stack>
              </Accordion>
            )}
          <Accordion title="Testemunhas" defaultExpanded={true}>
            <Stack styles={{ root: { marginBottom: 32 } }}>
              {!readOnly && (
                <OutlinedButton
                  text="Adicionar Testemunha"
                  styles={{
                    root: { width: 238, marginBottom: 22 },
                  }}
                  onClick={() => setIsModalTestemunhaOpen(true)}
                />
              )}
              <Stack horizontal style={{ position: "relative" }}>
                {values.testemunhas.map((testemunha, i) => (
                  <TooltipHost key={i} content={testemunha.obs} id={`${i}`}>
                    <Chip
                      nome={
                        testemunha.funcionario?.nomeCompleto ??
                        testemunha.nomeTestemunhaExterna ??
                        ""
                      }
                      disabledChip={readOnly}
                      handleClick={() =>
                        removeTestemunha(
                          testemunha.idFuncionario ??
                            testemunha.nomeTestemunhaExterna
                        )
                      }
                    />
                  </TooltipHost>
                ))}
              </Stack>
            </Stack>
          </Accordion>
          <Accordion title="Sanção" defaultExpanded={true}>
            <Stack styles={{ root: { marginBottom: 32 } }}>
              {medidaState.suggestion.flagAlertaJustaCausa && (
                <MessageBar
                  messageBarType={MessageBarType.severeWarning}
                  isMultiline={false}
                  styles={{ root: { marginBottom: 16 } }}
                >
                  Este funcionário possui histórico de sanções nos últimos 12
                  meses que justificam Justa Causa!
                </MessageBar>
              )}
              <Dropdown
                errors={errors.idSancao}
                errorText={errors.idSancao}
                name="idSancao"
                label="Sanção"
                values={values.idSancao ? `${values.idSancao}` : ""}
                styles={{ width: 320 }}
                disabled={readOnly}
                handleChange={(e: any) => {
                  handleChange(e);
                  setFieldError("idSancao", "");
                }}
              >
                {sanctions.map(({ descSancao, idSancao }) => (
                  <MenuItem key={idSancao} value={`${idSancao}`}>
                    {descSancao}
                  </MenuItem>
                ))}
              </Dropdown>
              <InputText
                label="Justificativa"
                id="justificativa"
                name="justificativa"
                value={values.justificativa}
                onChange={(e) => {
                  handleChange(e);
                  setFieldError("justificativa", "");
                }}
                multiline
                rows={3}
                error={!!errors.justificativa}
                helperText={errors.justificativa}
                disabled={readOnly}
              />
            </Stack>
          </Accordion>
          <Accordion title="Orientação" defaultExpanded={true}>
            <Stack styles={{ root: { marginBottom: 32 } }}>
              <InputText
                label="Orientação"
                id="orientacao"
                name="orientacao"
                value={values.orientacao ?? ""}
                onChange={(e) => {
                  handleChange(e);
                  setFieldError("orientacao", "");
                }}
                multiline
                rows={3}
                error={!!errors.orientacao}
                helperText={errors.orientacao}
                disabled={readOnly}
              />
            </Stack>
          </Accordion>
          <Accordion title="Plano de Ação" defaultExpanded={true}>
            <Stack styles={{ root: { marginBottom: 32 } }}>
              <InputText
                label="Plano de Ação"
                id="planoAcao"
                name="planoAcao"
                value={values.planoAcao ?? ""}
                onChange={(e) => {
                  handleChange(e);
                  setFieldError("planoAcao", "");
                }}
                multiline
                rows={3}
                error={!!errors.planoAcao}
                helperText={errors.planoAcao}
                disabled={readOnly}
              />
            </Stack>
          </Accordion>
        </form>

        <Dialog
          open={isModalTestemunhaOpen}
          onClickConfirm={(e: any) => handleSubmitTestemunha(e)}
          onClickCancel={() => {
            setIsModalTestemunhaOpen(false);
            setSelectedTypeTestemunha("I");
            resetFormTestemunha();
          }}
          title="Adicionar Testemunha"
          height={380}
        >
          <form onSubmit={handleSubmitTestemunha}>
            <ChoiceGroup
              selectedKey={selectedTypeTestemunha}
              options={options}
              id="testemunhaType"
              name="testemunhaType"
              onChange={(e, option) => {
                setSelectedTypeTestemunha(option?.key);
                setFieldValueTestemunha("testemunhaType", option?.key);
                if (option?.key === "I") {
                  setFieldValueTestemunha("nomeTestemunhaExterna", "");
                } else {
                  setFieldValueTestemunha("idFuncionario", null);
                  setSelectedFunc(null);
                }
              }}
              styles={{
                flexContainer: {
                  display: "flex",
                },
                root: {
                  marginBottom: 24,
                  div: {
                    marginRight: 16,
                  },
                },
              }}
            />
            {selectedTypeTestemunha === "I" ? (
              <InputAutocomplete
                value={selectedFunc as EmployeeType}
                onChange={(_, newValue) => {
                  setFieldValueTestemunha(
                    "idFuncionario",
                    newValue?.idFuncionario
                  );
                  setSelectedFunc(newValue);
                }}
                onInputChange={(_, newInputValue) => {
                  search(newInputValue, "func");
                  setFieldErrorTestemunha("idFuncionario", "");
                }}
                getOptionLabel={(func: EmployeeType) => func.nomeCompleto}
                getOptionSelected={(option, value) =>
                  option.idFuncionario === value.idFuncionario
                }
                options={employees}
                input={{
                  idInput: "idFuncionario",
                  labelInput: "Funcionário",
                  helperTextInput: errorsTestemunha.idFuncionario as string,
                  errorInput: !!errorsTestemunha.idFuncionario,
                  autoFocus: true,
                }}
              />
            ) : (
              <InputText
                label="Nome Testemunha Externa"
                id="nomeTestemunhaExterna"
                name="nomeTestemunhaExterna"
                autoFocus
                value={valuesTestemunha.nomeTestemunhaExterna}
                onChange={(e) => {
                  handleChangeTestemunha(e);
                  setFieldErrorTestemunha("nomeTestemunhaExterna", "");
                }}
                error={!!errorsTestemunha.nomeTestemunhaExterna}
                helperText={errorsTestemunha.nomeTestemunhaExterna}
              />
            )}
            <InputText
              label="Observações"
              id="obs"
              name="obs"
              value={valuesTestemunha.obs}
              onChange={(e) => {
                handleChangeTestemunha(e);
                setFieldErrorTestemunha("obs", "");
              }}
              multiline
              rows={3}
              error={!!errorsTestemunha.obs}
              helperText={errorsTestemunha.obs}
            />
          </form>
        </Dialog>
      </Content>
    </Container>
  );
};

const options: IChoiceGroupOption[] = [
  { key: "I", text: "Funcionário" },
  { key: "E", text: "Não Funcionário" },
];

const commandButtonStyle: Partial<ICommandBarStyles> = {
  root: {
    height: 44,
  },
};

const validationSchema = yup.object().shape({
  dataOcorrencia: yup
    .date()
    .nullable()
    .typeError("Data inválida")
    .required("Campo obrigatório"),
  idConduta: yup.number().nullable().required("Campo obrigatório"),
  idSancao: yup.number().nullable().required("Campo obrigatório"),
  descricao: yup.string().required("Campo obrigatório"),
  justificativa: yup.string().required("Campo obrigatório"),
  orientacao: yup.string().required("Campo obrigatório"),
  planoAcao: yup.string().required("Campo obrigatório"),
  testemunhas: yup.array().of(
    yup.object().shape({
      idFuncionario: yup.number().nullable().notRequired(),
      obs: yup.string().required("Campo obrigatório"),
      nomeTestemunhaExterna: yup.string().nullable().notRequired(),
    })
  ),
});

const validationSchemaTestemunha = yup.object().shape({
  obs: yup
    .string()
    .min(10, "A observação de ter no mínimo 10 caracteres")
    .required("Campo obrigatório"),
  testemunhaType: yup.string().notRequired(),
  idFuncionario: yup
    .number()
    .nullable()
    .when("testemunhaType", {
      is: "I",
      then: yup.number().nullable().required("Campo obrigatório"),
    }),
  nomeTestemunhaExterna: yup
    .string()
    .nullable()
    .when("testemunhaType", {
      is: "E",
      then: yup.string().nullable().required("Campo obrigatório"),
    }),
});

const initialValues: EducationalMeasureType = {
  idConduta: null,
  idSancao: null,
  descricao: "",
  orientacao: "",
  justificativa: "",
  baixouFormulario: false,
  assinaturaNecessaria: false,
  dataOcorrencia: null,
  planoAcao: "",
  testemunhas: [],
  anexos: [],
};

const initialTestemunha: TestemunhaType = {
  obs: "",
  nomeTestemunhaExterna: "",
};

export default EducationalMeasure;
