import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import * as yup from "yup";

import { Dialog, DialogFooter } from "office-ui-fabric-react/lib/Dialog";
import { FontIcon, DefaultButton, Text } from "office-ui-fabric-react";
import { Spinner, SpinnerSize } from "office-ui-fabric-react/lib/Spinner";
import { Stack } from "office-ui-fabric-react/lib/Stack";

import { convertToNumber } from "~/utils/texts";
import { InputText, InputNumber } from "~/components/Forms/index";
import { PrimaryButton } from "~/components/Buttons";

import InputAdornment from "@material-ui/core/InputAdornment";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import { MetaMonthType, MetaType } from "~/store/ducks/cycle/painel/types";
import Colors from "~/assets/js/colors";
import { IPivotStyles, Pivot, PivotItem } from "@fluentui/react";

import FormMetaMonths from "./months";
import { useEffect } from "react";

const dialogContentProps = {
  title: "Definir Meta",
  closeButtonAriaLabel: "Close",
  subText: "",
};

interface IFormMetasProps {
  isOpen: boolean;
  setIsOpen: () => void;
  isLoading: boolean;
  isLoadingAction: boolean;
  valuesInitialMeta: MetaType;
  onAddMeta: (values: MetaType) => void;
  onEditMeta: (values: MetaType) => void;
  onDeleteMeta: () => void;
}

const validationDefineGoalSchema = yup.object().shape({
  definirMeta: yup.bool(),
  mecanicaCalculo: yup.string(),
  minimo: yup
    .number()
    .nullable()
    .when("definirMeta", {
      is: true,
      then: yup.number().required("Campo obrigatório"),
    }),
  target: yup
    .number()
    .nullable()
    .when("definirMeta", {
      is: true,
      then: yup.number().required("Campo obrigatório"),
    }),
  habilitarReferencia: yup.bool(),
  minimoRef: yup
    .number()
    .nullable()
    .when("habilitarReferencia", {
      is: true,
      then: yup.number().required("Campo obrigatório"),
    }),
  targetRef: yup
    .number()
    .nullable()
    .when("habilitarReferencia", {
      is: true,
      then: yup.number().required("Campo obrigatório"),
    }),
  habilitarResultado: yup.bool().nullable(),
  resultado: yup
    .number()
    .nullable()
    .when("habilitarResultado", {
      is: true,
      then: yup.number().required("Campo obrigatório"),
    }),
  flagInativo: yup.bool().nullable(),
  forcarMetaBonus: yup.bool().nullable(),
  metaBonus: yup
    .number()
    .nullable()
    .when("forcarMetaBonus", {
      is: true,
      then: yup.number().required("Campo obrigatório"),
    }),
});

const FormMetas: React.FC<IFormMetasProps> = (props) => {
  const {
    isOpen,
    isLoading,
    isLoadingAction,
    valuesInitialMeta,
    setIsOpen,
    onAddMeta,
    onEditMeta,
    onDeleteMeta,
  } = props;
  const dispatch = useDispatch();

  const [meses, setMeses] = useState<MetaMonthType[]>(valuesInitialMeta.meses);

  useEffect(() => {
    setMeses(valuesInitialMeta.meses);
  }, [valuesInitialMeta.meses, isOpen]);

  const editMonth = (mes: MetaMonthType) => {
    const allMonths = meses.map((item) => {
      if (item.mes === mes.mes) {
        return mes;
      } else {
        return item;
      }
    });

    setMeses(allMonths);
  };

  return (
    <Dialog
      hidden={!isOpen}
      dialogContentProps={dialogContentProps}
      minWidth={640}
    >
      {isLoading ? (
        <Stack styles={{ root: { height: 347 } }} verticalAlign="center">
          <Spinner size={SpinnerSize.large} />
        </Stack>
      ) : (
        <>
          <div className="ms-Grid-col ms-sm12">
            <InputText
              value={valuesInitialMeta.objetivo.descObjetivo}
              id="objetivo"
              name="objetivo"
              type="text"
              label="Objetivo"
              disabled={true}
              className="mt-2"
            />
          </div>
          <Formik
            initialValues={{
              ...valuesInitialMeta,
              definirMeta: true,
              habilitarResultado:
                valuesInitialMeta.resultado !== null ? true : false,
              forcarMetaBonus: valuesInitialMeta.forcarMetaBonus ?? false,
            }}
            validationSchema={validationDefineGoalSchema}
            validateOnChange={false}
            enableReinitialize
            validateOnBlur={true}
            validate={(values) => {
              const {
                minimo,
                target,
                definirMeta,
                habilitarReferencia,
                targetRef,
                minimoRef,
              } = values;
              const errors: any = {};

              const minimoNum = convertToNumber(minimo);
              const targetNum = convertToNumber(target);
              const minimoRefNum = convertToNumber(minimoRef);
              const targetRefNum = convertToNumber(targetRef);

              if (definirMeta && target !== "") {
                if (
                  valuesInitialMeta.objetivo.mecanicaCalculo === "-" &&
                  minimoNum < targetNum
                ) {
                  errors.target =
                    "O target não pode ser maior que o valor mínimo";
                } else if (
                  valuesInitialMeta.objetivo.mecanicaCalculo === "+" &&
                  minimoNum > targetNum
                ) {
                  errors.target =
                    "O target não pode ser menor que o valor mínimo";
                }

                if (habilitarReferencia && targetRef !== "") {
                  if (
                    valuesInitialMeta.objetivo.mecanicaCalculo === "-" &&
                    minimoRefNum <= targetRefNum
                  ) {
                    errors.targetRef =
                      "A referência do target não pode ser maior ou igual que a referência do mínimo";
                  } else if (
                    valuesInitialMeta.objetivo.mecanicaCalculo === "+" &&
                    (minimoRefNum >= targetRefNum)
                  ) {
                    errors.targetRef =
                      "A referência do target não pode ser menor ou igual que a referência do mínimo";
                  }
                }
              }

              console.log(errors)

              return errors;
            }}
            onSubmit={(values: any) => {
              let valuesRef: Partial<MetaType> = {};

              if (!values.habilitarReferencia) {
                valuesRef = {
                  minimoRef: "0",
                  targetRef: "0",
                  maximoRef: "0",
                };
              }

              if (values.resultado == null) {
                valuesRef = {
                  metaBonus: null
                }
              }

              if (
                Number.isFinite(values.resultado) &&
                values.habilitarResultado
              ) {
                values.avaliacao = CalculaAting(
                  Number(values.minimo),
                  Number(values.target),
                  Number(values.maximo),
                  Number(values.resultado),
                  values.flagInativo ?? false
                ).avaliacao;
              }

              const max = convertToNumber(values.maximo);
              const maxRef = convertToNumber(
                valuesRef.maximoRef ?? values.maximoRef
              );

              if (!values.idCicloMeta) {
                dispatch(
                  onAddMeta({
                    ...values,
                    ...valuesRef,
                    maximo: max,
                    maximoRef: maxRef,
                    meses,
                  })
                );
              } else {
                if (values.definirMeta) {
                  dispatch(
                    onEditMeta({
                      ...values,
                      ...valuesRef,
                      maximo: max,
                      maximoRef: maxRef,
                      meses,
                    })
                  );
                } else {
                  dispatch(onDeleteMeta());
                }
              }
            }}
          >
            {({
              handleSubmit,
              handleChange,
              values,
              errors,
              setFieldValue,
              setFieldError,
            }) => {
              const unidade: string = values.objetivo.unidade;
              const isCurrency: boolean = unidade === "M" || unidade === "L";

              return (
                <form onSubmit={handleSubmit}>
                  <Pivot aria-label="Metas" styles={pivotStyles}>
                    <PivotItem headerText="Meta">
                      <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                          {(values.idCicloMeta || values.idCicloMeta !== 0) && (
                            <div className="ms-Grid-col ms-sm12">
                              <FormControlLabel
                                style={{ padding: "5px 0 15px" }}
                                control={
                                  <Switch
                                    size="small"
                                    type="checkbox"
                                    checked={values.definirMeta}
                                    onChange={(e) => {
                                      handleChange(e);
                                      if (!e.target.checked) {
                                        setFieldValue(
                                          "habilitarResultado",
                                          false
                                        );
                                      }
                                    }}
                                    name="definirMeta"
                                    id="definirMeta"
                                    color="primary"
                                  />
                                }
                                label="Definir Meta"
                              />
                            </div>
                          )}

                          {isCurrency ? (
                            <>
                              <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4">
                                <InputNumber
                                  value={
                                    !!values.minimo
                                      ? Number(values.minimo)
                                      : null
                                  }
                                  onValueChange={(value) => {
                                    setFieldValue(
                                      "maximo",
                                      calcMax(
                                        value.floatValue ?? 0,
                                        values.target,
                                        valuesInitialMeta.objetivo
                                      )
                                    );
                                    setFieldValue("minimo", value.floatValue);
                                    setFieldError("minimo", "");
                                    if (values.resultado != null && !values.forcarMetaBonus) {
                                      setFieldValue(
                                        "metaBonus",
                                        CalculaMeta(
                                          value.floatValue ?? 0,
                                          values.target,
                                          calcMax(
                                            value.floatValue ?? 0,
                                            values.target,
                                            valuesInitialMeta.objetivo
                                          ),
                                          values.resultado,
                                          values.minimoRef,
                                          values.targetRef,
                                          values.maximoRef,
                                          values.flagInativo ?? false
                                        )
                                      );
                                    }
                                  }}
                                  id="minimo"
                                  name="minimo"
                                  label="Mínimo"
                                  helperText={errors.minimo}
                                  error={errors.minimo ? true : false}
                                  autoFocus
                                />
                              </div>
                              <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4">
                                <InputNumber
                                  value={
                                    !!values.target
                                      ? Number(values.target)
                                      : null
                                  }
                                  onValueChange={(value) => {
                                    setFieldValue(
                                      "maximo",
                                      calcMax(
                                        values.minimo,
                                        value.floatValue ?? 0,
                                        valuesInitialMeta.objetivo
                                      )
                                    );
                                    setFieldValue("target", value.floatValue);
                                    setFieldError("target", "");
                                    if (values.resultado != null && !values.forcarMetaBonus) {
                                      setFieldValue(
                                        "metaBonus",
                                        CalculaMeta(
                                          values.minimo,
                                          value.floatValue ?? 0,
                                          calcMax(
                                            values.minimo,
                                            value.floatValue ?? 0,
                                            valuesInitialMeta.objetivo
                                          ),
                                          values.resultado,
                                          values.minimoRef,
                                          values.targetRef,
                                          values.maximoRef,
                                          values.flagInativo ?? false
                                        )
                                      );
                                    }
                                  }}
                                  id="target"
                                  name="target"
                                  label="Target"
                                  helperText={errors.target}
                                  error={!!errors.target}
                                />
                              </div>
                              <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4">
                                <InputNumber
                                  value={Number(values.maximo)}
                                  id="maximo"
                                  name="maximo"
                                  label="Máximo"
                                  helperText={errors.maximo}
                                  error={!!errors.maximo}
                                  disabled={true}
                                />
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4">
                                <InputText
                                  value={values.minimo ?? ""}
                                  onChange={(e) => {
                                    setFieldValue(
                                      "maximo",
                                      calcMax(
                                        e.target.value,
                                        values.target,
                                        valuesInitialMeta.objetivo
                                      )
                                    );
                                    handleChange(e);
                                    setFieldError("minimo", "");
                                    if (values.resultado != null && !values.forcarMetaBonus) {
                                      setFieldValue(
                                        "metaBonus",
                                        CalculaMeta(
                                          e.target.value,
                                          values.target,
                                          calcMax(
                                            e.target.value,
                                            values.target,
                                            valuesInitialMeta.objetivo
                                          ),
                                          values.resultado,
                                          values.minimoRef,
                                          values.targetRef,
                                          values.maximoRef,
                                          values.flagInativo ?? false
                                        )
                                      );
                                    }
                                  }}
                                  id="minimo"
                                  error={errors.minimo ? true : false}
                                  name="minimo"
                                  type="number"
                                  label="Mínimo"
                                  className="mt-2"
                                  disabled={!values.definirMeta}
                                  autoFocus
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment
                                        position="end"
                                        style={{ paddingRight: 20 }}
                                      >
                                        {unidade === "P" ? "%" : ""}
                                      </InputAdornment>
                                    ),
                                    autoComplete: "off",
                                  }}
                                  helperText={errors.minimo}
                                />
                              </div>
                              <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4">
                                <InputText
                                  value={values.target ?? ""}
                                  onChange={(e: any) => {
                                    handleChange(e);
                                    setFieldValue(
                                      "maximo",
                                      calcMax(
                                        values.minimo,
                                        e.target.value,
                                        valuesInitialMeta.objetivo
                                      )
                                    );
                                    setFieldError("target", "");
                                    if (values.resultado != null && !values.forcarMetaBonus) {
                                      setFieldValue(
                                        "metaBonus",
                                        CalculaMeta(
                                          values.minimo,
                                          e.target.value,
                                          calcMax(
                                            values.minimo,
                                            e.target.value,
                                            valuesInitialMeta.objetivo
                                          ),
                                          values.resultado,
                                          values.minimoRef,
                                          values.targetRef,
                                          values.maximoRef,
                                          values.flagInativo ?? false
                                        )
                                      );
                                    }
                                  }}
                                  id="target"
                                  error={errors.target ? true : false}
                                  name="target"
                                  type="number"
                                  label="Target"
                                  className="mt-2"
                                  disabled={!values.definirMeta}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment
                                        position="end"
                                        style={{ paddingRight: 20 }}
                                      >
                                        {unidade === "P" ? "%" : ""}
                                      </InputAdornment>
                                    ),
                                    autoComplete: "off",
                                  }}
                                  helperText={errors.target}
                                />
                              </div>
                              <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4">
                                <InputText
                                  value={values.maximo}
                                  onChange={handleChange}
                                  id="maximo"
                                  error={errors.maximo ? true : false}
                                  name="maximo"
                                  type="text"
                                  label="Máximo"
                                  className="mt-2"
                                  disabled={true}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment
                                        position="end"
                                        style={{ paddingRight: 20 }}
                                      >
                                        {unidade === "P" ? "%" : ""}
                                      </InputAdornment>
                                    ),
                                  }}
                                  helperText={errors.maximo}
                                />
                              </div>
                            </>
                          )}
                        </div>
                        <FormControlLabel
                          style={{ padding: "5px 0 15px" }}
                          control={
                            <Switch
                              size="small"
                              type="checkbox"
                              checked={values.habilitarReferencia}
                              onChange={e => {
                                handleChange(e);
                                if (values.resultado != null && !values.forcarMetaBonus) {
                                  setFieldValue(
                                    "metaBonus",
                                    CalculaMeta(
                                      values.minimo,
                                      values.target,
                                      values.maximo,
                                      values.resultado,
                                      values.minimoRef,
                                      values.targetRef,
                                      values.maximoRef,
                                      values.flagInativo ?? false
                                    )
                                  );
                                }
                              }}
                              name="habilitarReferencia"
                              id="habilitarReferencia"
                              color="primary"
                              disabled={!values.definirMeta}
                            />
                          }
                          label="Habilitar Referência"
                        />
                        <div className="ms-Grid-row">
                          <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4">
                            <InputText
                              value={values.minimoRef ?? ""}
                              onChange={(e) => {
                                setFieldValue(
                                  "maximoRef",
                                  calcMax(
                                    e.target.value,
                                    values.targetRef,
                                    valuesInitialMeta.objetivo
                                  )
                                );
                                handleChange(e);
                                setFieldError("minimoRef", "");
                                if (values.resultado != null && !values.forcarMetaBonus) {
                                  setFieldValue(
                                    "metaBonus",
                                    CalculaMeta(
                                      values.minimo,
                                      values.target,
                                      values.maximo,
                                      values.resultado,
                                      e.target.value,
                                      values.targetRef,
                                      calcMax(
                                        e.target.value,
                                        values.targetRef,
                                        valuesInitialMeta.objetivo
                                      ),
                                      values.flagInativo ?? false
                                    )
                                  );
                                }
                              }}
                              id="minimoRef"
                              error={errors.minimoRef ? true : false}
                              name="minimoRef"
                              type="number"
                              label="Mínimo"
                              className="mt-2"
                              InputProps={{
                                autoComplete: "off",
                              }}
                              disabled={
                                !values.definirMeta ||
                                !values.habilitarReferencia
                              }
                              helperText={errors.minimoRef}
                            />
                          </div>
                          <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4">
                            <InputText
                              value={values.targetRef ?? ""}
                              onChange={(e) => {
                                setFieldValue(
                                  "maximoRef",
                                  calcMax(
                                    values.minimoRef,
                                    e.target.value,
                                    valuesInitialMeta.objetivo
                                  )
                                );
                                handleChange(e);
                                setFieldError("targetRef", "");
                                if (values.resultado != null && !values.forcarMetaBonus) {
                                  setFieldValue(
                                    "metaBonus",
                                    CalculaMeta(
                                      values.minimo,
                                      values.target,
                                      values.maximo,
                                      values.resultado,
                                      values.minimoRef,
                                      e.target.value,
                                      calcMax(
                                        values.minimoRef,
                                        e.target.value,
                                        valuesInitialMeta.objetivo
                                      ),
                                      values.flagInativo ?? false
                                    )
                                  );
                                }
                              }}
                              id="targetRef"
                              error={errors.targetRef ? true : false}
                              name="targetRef"
                              type="number"
                              label="Target"
                              className="mt-2"
                              InputProps={{
                                autoComplete: "off",
                              }}
                              disabled={
                                !values.definirMeta ||
                                !values.habilitarReferencia
                              }
                              helperText={errors.targetRef}
                            />
                          </div>
                          <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4">
                            <InputText
                              value={values.maximoRef}
                              onChange={handleChange}
                              id="maximoRef"
                              error={errors.maximoRef ? true : false}
                              name="maximoRef"
                              type="text"
                              label="Máximo"
                              className="mt-2"
                              disabled={true}
                              helperText={errors.maximoRef}
                            />
                          </div>
                        </div>
                        <FormControlLabel
                          style={{ padding: "5px 0 15px" }}
                          control={
                            <Switch
                              size="small"
                              type="checkbox"
                              checked={values.habilitarResultado}
                              onChange={(e) => {
                                handleChange(e);
                                if (values.resultado != null && !values.forcarMetaBonus) {
                                  setFieldValue(
                                    "metaBonus",
                                    CalculaMeta(
                                      values.minimo,
                                      values.target,
                                      values.maximo,
                                      values.resultado,
                                      values.minimoRef,
                                      values.targetRef,
                                      values.maximoRef,
                                      values.flagInativo ?? false
                                    )
                                  );
                                }
                                if (!e.target.checked) {
                                  setFieldValue("resultado", null);
                                  setFieldValue("ating", null);
                                  setFieldValue("forcarMetaBonus", false);
                                  setFieldValue("metaBonus", null);
                                }
                              }}
                              name="habilitarResultado"
                              id="habilitarResultado"
                              color="primary"
                              disabled={
                                (values.flagInativo || !values.definirMeta) ??
                                false
                              }
                            />
                          }
                          label="Habilitar Resultado"
                        />
                        <FormControlLabel
                          style={{ padding: "5px 25px 15px" }}
                          control={
                            <Switch
                              size="small"
                              type="checkbox"
                              checked={values.flagInativo ?? false}
                              onChange={(e) => {
                                handleChange(e);
                                setFieldValue("habilitarResultado", false);
                                setFieldValue("forcarMetaBonus", false);
                              }}
                              name="flagInativo"
                              id="flagInativo"
                              color="primary"
                              disabled={!values.definirMeta}
                            />
                          }
                          label="Inativar Meta"
                        />
                        <div className="ms-Grid-row">
                          {isCurrency ? (
                            <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4">
                              <InputNumber
                                value={
                                  values.habilitarResultado &&
                                    !!Number.isFinite(
                                      parseFloat(values.resultado ?? "")
                                    )
                                    ? Number(values.resultado) ?? ""
                                    : ""
                                }
                                onValueChange={(value) => {
                                  setFieldValue(
                                    "resultado",
                                    value.floatValue ?? ""
                                  );
                                  setFieldError("resultado", "");
                                  if (values.resultado != null && !values.forcarMetaBonus) {
                                    setFieldValue(
                                      "metaBonus",
                                      CalculaMeta(
                                        values.minimo,
                                        values.target,
                                        values.maximo,
                                        value.floatValue,
                                        values.minimoRef,
                                        values.targetRef,
                                        values.maximoRef,
                                        values.flagInativo ?? false
                                      )
                                    );
                                  }
                                }}
                                id="resultado"
                                name="resultado"
                                label="Resultado"
                                helperText={errors.resultado}
                                error={!!errors.resultado}
                                disabled={!values.habilitarResultado}
                              />
                            </div>
                          ) : (
                            <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4">
                              <InputText
                                value={
                                  values.habilitarResultado
                                    ? values.resultado ?? ""
                                    : ""
                                }
                                onChange={e => {
                                  handleChange(e);
                                  if (values.resultado != null && !values.forcarMetaBonus) {
                                    setFieldValue(
                                      "metaBonus",
                                      CalculaMeta(
                                        values.minimo,
                                        values.target,
                                        values.maximo,
                                        e.target.value,
                                        values.minimoRef,
                                        values.targetRef,
                                        values.maximoRef,
                                        values.flagInativo ?? false
                                      )
                                    );
                                  }
                                }}
                                id="resultado"
                                error={errors.resultado ? true : false}
                                name="resultado"
                                type="number"
                                label="Resultado"
                                className="mt-2"
                                InputProps={{
                                  autoComplete: "off",
                                  endAdornment: (
                                    <InputAdornment
                                      position="end"
                                      style={{ paddingRight: 20 }}
                                    >
                                      {unidade === "P" ? "%" : ""}
                                    </InputAdornment>
                                  ),
                                }}
                                disabled={
                                  !values.habilitarResultado ||
                                  (values.flagInativo ?? false)
                                }
                                helperText={errors.resultado}
                              />
                            </div>
                          )}
                          <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4">
                            <InputText
                              value={
                                values.habilitarResultado &&
                                  values.resultado !== null
                                  ? Math.round(
                                    CalculaAting(
                                      Number(values.minimo),
                                      Number(values.target),
                                      Number(values.maximo),
                                      Number(values.resultado),
                                      values.flagInativo ?? false
                                    ).ating * 100
                                  )
                                  : ""
                              }
                              type="number"
                              label="% Ating"
                              disabled={true}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment
                                    position="end"
                                    style={{ paddingRight: 20 }}
                                  >
                                    %
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </div>
                          <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4">
                            {values.habilitarResultado &&
                              values.resultado !== null &&
                              values.resultado !== undefined && (
                                <Stack verticalAlign="start">
                                  <Text
                                    variant="smallPlus"
                                    styles={{ root: { marginBottom: 7 } }}
                                  >
                                    Avaliação
                                  </Text>
                                  <Stack
                                    horizontal
                                    verticalAlign="center"
                                    styles={{ root: { fontSize: 13 } }}
                                  >
                                    {renderAvaliacao(
                                      CalculaAting(
                                        Number(values.minimo),
                                        Number(values.target),
                                        Number(values.maximo),
                                        Number(values.resultado),
                                        values.flagInativo ?? false
                                      ).avaliacao
                                    )}
                                  </Stack>
                                </Stack>
                              )}
                          </div>
                        </div>
                        <FormControlLabel
                          style={{ padding: "5px 0px 15px" }}
                          control={
                            <Switch
                              size="small"
                              type="checkbox"
                              checked={values.forcarMetaBonus ?? false}
                              onChange={e => {
                                handleChange(e);
                                if (values.resultado != null && !e.target.checked) {
                                  setFieldValue(
                                    "metaBonus",
                                    CalculaMeta(
                                      values.minimo,
                                      values.target,
                                      values.maximo,
                                      values.resultado!,
                                      values.minimoRef,
                                      values.targetRef,
                                      values.maximoRef,
                                      values.flagInativo ?? false
                                    )
                                  );
                                }
                              }}
                              name="forcarMetaBonus"
                              id="forcarMetaBonus"
                              color="primary"
                              disabled={!values.definirMeta || !values.habilitarResultado}
                            />
                          }
                          label="Editar Meta Bônus"
                        />
                        <div className="ms-Grid-row">
                          <div className="ms-Grid-col ms-lg4">
                            <InputText
                              value={
                                values.resultado !== null
                                  ?
                                  values.metaBonus
                                  : ""
                              }
                              name="metaBonus"
                              id="metaBonus"
                              onChange={handleChange}
                              type="number"
                              label="% Meta Bônus"
                              disabled={!values.forcarMetaBonus || !values.definirMeta}
                              helperText={errors.metaBonus}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment
                                    position="end"
                                    style={{ paddingRight: 20 }}
                                  >
                                    %
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </PivotItem>
                    <PivotItem headerText="Mensal">
                      <FormMetaMonths
                        months={meses}
                        unidade={unidade}
                        objetivo={valuesInitialMeta.objetivo}
                        isCurrency={isCurrency}
                        editMonth={editMonth}
                      />
                    </PivotItem>
                  </Pivot>
                  <div style={{ paddingRight: 5 }}>
                    <DialogFooter
                      styles={{
                        actionsRight: {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        },
                      }}
                    >
                      <Text styles={{ root: { color: Colors.error } }}>
                        {!Object.entries(errors).every(
                          (item) => item[1] === ""
                        ) && "Verifique todos os campos da meta"}
                      </Text>
                      <Stack horizontal>
                        <DefaultButton
                          styles={{ root: { width: 110, marginRight: 8 } }}
                          onClick={setIsOpen}
                          text="Cancelar"
                        />
                        <PrimaryButton
                          type="submit"
                          isLoading={isLoadingAction}
                          text="Salvar"
                        />
                      </Stack>
                    </DialogFooter>
                  </div>
                </form>
              );
            }}
          </Formik>
        </>
      )}
    </Dialog>
  );
};

export const calcMax = (
  min: string | number,
  target: string | number,
  objetivo: MetaType["objetivo"]
): string => {
  const minNum = !isNaN(parseFloat(String(min))) ? parseFloat(String(min)) : 0;
  const targetNum = !isNaN(parseFloat(String(target)))
    ? parseFloat(String(target))
    : 0;
  const diff = Math.abs(minNum - targetNum);

  const unit = objetivo.unidade;
  const isCurrency = unit === "M" || unit === "L";

  let valueSum = targetNum + diff;
  let valueSubt = targetNum - diff;

  if (isCurrency || unit === "P") {
    if (objetivo.mecanicaCalculo === "-") {
      return valueSubt.toString().replace(/[^0-9\.-]+/g, "");
    } else {
      return valueSum.toString().replace(/[^0-9\.-]+/g, "");
    }
  } else {
    if (objetivo.mecanicaCalculo === "-") {
      return valueSubt.toString();
    } else {
      return valueSum.toString();
    }
  }
};

interface AtingType {
  ating: number;
  avaliacao: string | null;
}

export const renderAvaliacao = (
  result: string | null,
  onlyIcon: boolean = true
) => {
  switch (result) {
    case "Superado":
      return Superado(onlyIcon);
    case "Não atingido":
      return NaoAtingido(onlyIcon);
    case "Parcialmente atingido":
      return Parcialmente(onlyIcon);
    case "Atingido":
      return Atingido(onlyIcon);
    case "Não se aplica":
      return NaoAplica(onlyIcon);
    default:
      return "";
  }
};

export const CalculaAting = (
  minimo: number,
  target: number,
  maximo: number,
  resultado: number,
  flagInativo: boolean
) => {
  let Retorno: AtingType = {
    ating: 0,
    avaliacao: null,
  };

  if (flagInativo) {
    Retorno = { ating: 0, avaliacao: "Não se aplica" };
    return Retorno;
  }

  if (minimo == maximo) {
    let Calculo = resultado / target;
    if (resultado < target)
       Retorno = {ating : Calculo, avaliacao: "Não atingido"}
    else
    if ( resultado == target)
      Retorno = {ating : Calculo, avaliacao: "Atingido"}
    else
    Retorno = {ating : Calculo, avaliacao: "Superado"}
  }
  else
  if (minimo < maximo) {
    // quanto maior melhor
    if (resultado >= maximo) {
      //superado
      Retorno = { ating: 1.5, avaliacao: "Superado" };
    } else if (resultado < minimo) {
      //não atingido
      Retorno = { ating: 0, avaliacao: "Não atingido" };
    } else if (resultado < target) {
      //parcialmente atingido
      //parcialmente atingido
      let DifResultTarget = resultado - target;
      let DifMinimoTarget = minimo - target;

      let Divisao = DifResultTarget / DifMinimoTarget;
      let DistanciaPercent = 1 - 1.5;
      let ResultDivisao = Divisao * DistanciaPercent;
      Retorno = {
        ating: 1 + ResultDivisao,
        avaliacao: "Parcialmente atingido",
      };
    } else {
      let DifResultTarget = resultado - target;
      let DifMinimoTarget = maximo - target;

      let Divisao = DifResultTarget / DifMinimoTarget;
      let DistanciaPercent = 1.5 - 1;

      let ResultDivisao = Divisao * DistanciaPercent;

      if (resultado == target) {
        Retorno = { ating: 1, avaliacao: "Atingido" };
      } else Retorno = { ating: 1 + ResultDivisao, avaliacao: "Superado" };
    }
  } else {
    //quanto menor melhor

    if (resultado <= maximo) Retorno = { ating: 1.5, avaliacao: "Superado" };
    else if (resultado > minimo)
      Retorno = { ating: 0, avaliacao: "Não atingido" };
    else if (resultado > target) {
      //parcialmente atingido
      let DifResultTarget = resultado - target;
      let DifMinimoTarget = minimo - target;

      let Divisao = DifResultTarget / DifMinimoTarget;
      let DistanciaPercent = 1 - 1.5;
      let ResultDivisao = Divisao * DistanciaPercent;
      Retorno = {
        ating: 1 - ResultDivisao,
        avaliacao: "Parcialmente atingido",
      };
    } else {
      let DifResultTarget = resultado - target;
      let DifMinimoTarget = maximo - target;

      let Divisao = DifResultTarget / DifMinimoTarget;
      let DistanciaPercent = 1.5 - 1;

      let ResultDivisao = Divisao * DistanciaPercent;

      if (resultado == target) Retorno = { ating: 1, avaliacao: "Atingido" };
      else Retorno = { ating: 1 + ResultDivisao, avaliacao: "Superado" };
    }
  }

  return Retorno;
};

export const CalculaMeta = (
  minimo: number | string,
  target: number | string,
  maximo: number | string,
  resultado: number | string | null = 0,
  minimoRef: number | string,
  targetRef: number | string,
  maximoRef: number | string,
  flagInativo: boolean
) => {

  let retorno = 0


  if (flagInativo) return retorno;

  minimo = Number(minimo);
  target = Number(target);
  maximo = Number(maximo);
  resultado = Number(resultado);
  minimoRef = Number(minimoRef);
  targetRef = Number(targetRef);
  maximoRef = Number(maximoRef);

  if (minimo == maximo) {
    retorno = resultado * 100 / target
  }
  else
  if (minimo < maximo) {
    let percMenor = targetRef - minimoRef;
    let DifResultTarget = resultado - target;
    let DifMinimoTarget = minimo - target;

    let primeiroCalculo = targetRef - (percMenor * DifResultTarget / DifMinimoTarget)

    if (primeiroCalculo > maximoRef)
      retorno = maximoRef
    else {
      if (primeiroCalculo < minimoRef)
        retorno = 0
      else
        retorno = primeiroCalculo
    }
  }
  else {
    let percMenor = maximoRef - targetRef;
    let DifResultTarget = resultado - target;
    let DifMinimoTarget = maximo - target;

    let primeiroCalculo = targetRef - (percMenor * DifResultTarget / DifMinimoTarget)

    if (primeiroCalculo > minimoRef)
      retorno = maximoRef
    else {
      if (primeiroCalculo < maximoRef)
        retorno = 0
      else
        retorno = primeiroCalculo
    }
  }

  return retorno.toFixed(2)
}

const Superado = (onlyIcon: boolean) => (
  <>
    <FontIcon
      iconName="SkypeCircleArrow"
      style={{
        fontSize: 16,
        marginRight: 5,
        color: Colors.blue,
        transform: "rotate(90deg)",
      }}
    />
    {onlyIcon && "SUPERADO"}
  </>
);

const NaoAtingido = (onlyIcon: boolean) => (
  <>
    <FontIcon
      iconName="SkypeCircleArrow"
      style={{
        fontSize: 16,
        marginRight: 5,
        color: Colors.error,
        transform: "rotate(-90deg)",
      }}
    />
    {onlyIcon && "NÃO ATINGIDO"}
  </>
);

const Atingido = (onlyIcon: boolean) => (
  <>
    <FontIcon
      iconName="SkypeCircleCheck"
      style={{ fontSize: 16, marginRight: 5, color: Colors.primary }}
    />
    {onlyIcon && "ATINGIDO"}
  </>
);

const NaoAplica = (onlyIcon: boolean) => (
  <>
    <FontIcon
      iconName="StatusErrorFull"
      style={{ fontSize: 16, marginRight: 5, color: "#484644" }}
    />
    {onlyIcon && "NÃO SE APLICA"}
  </>
);
const Parcialmente = (onlyIcon: boolean) => (
  <>
    <FontIcon
      iconName="CircleHalfFull"
      style={{ fontSize: 16, marginRight: 5, color: "#FB6B20" }}
    />
    {onlyIcon && "PARCIALMENTE ATINGIDO"}
  </>
);

const pivotStyles: Partial<IPivotStyles> = {
  root: {
    borderBottom: "1px solid #CFD3DA",
    backgroundColor: "#FFFFFF",
    width: "100%",
    zIndex: 1000,
    marginBottom: 15,
  },
  linkContent: {
    width: 130,
  },
};
export default FormMetas;
