// #region Imports
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Formik } from "formik";
import * as yup from "yup";

//FluentUI
import { Stack } from "office-ui-fabric-react/lib/Stack";
import { useId } from "@uifabric/react-hooks";
import {
  DetailsListLayoutMode,
  SelectionMode,
  Selection,
  IColumn,
} from "office-ui-fabric-react/lib/DetailsList";
import { ShimmeredDetailsList } from "office-ui-fabric-react/lib/ShimmeredDetailsList";
import {
  CommandBarButton,
  FontIcon,
  IButtonStyles,
  ICommandBarStyles,
  Text,
  TooltipHost,
} from "office-ui-fabric-react";
import { IDialogContentProps } from "office-ui-fabric-react/lib/Dialog";

import CustomDialog from "~/components/CustomDialogFluentUI";
import Dialog from "~/components/CustomDialog";

import { InputText, InputAutocomplete } from "~/components/Forms";

import { DeleteButton } from "~/components/Buttons";
import NoItems from "~/components/layout/NoItems";
//Estilos
import {
  Wrapper,
  ListContainer,
  DefaultButton,
  WrapperBox,
  WrapperAutoComplete,
} from "./styles";

import {
  PDIType,
  DataTypes as DataTypesPDI,
} from "~/store/ducks/admin/pdi/types";
import { Creators as getPdi } from "~/store/ducks/admin/pdi";

import {
  AcaoTarefaPDI,
  RegrasPDIType,
} from "~/store/ducks/tasks/types";
import { RootState } from "~/store/ducks";
import Colors from "~/assets/js/colors";

import FormSearchPDI from "../formSearchPDI";
import { IconButton } from "@fluentui/react";
import {
  DataTypes as DataTypesEmployee,
  EmployeeType,
} from "~/store/ducks/employees/types";
import { Creators as getEmployees } from "~/store/ducks/employees";
//#endregion

const btnStyle: Partial<ICommandBarStyles> = {
  root: {
    height: 44,
  },
};

/**
 * Validação do formulário
 */
const validationPdiTaskSchema = yup.object().shape({
  idAcaoPDI: yup.number().nullable().required("Campo obrigatório"),
  idFuncionarioRelacionado: yup.number().nullable().notRequired(),
  complemento: yup
    .string()
    .max(80, "O complemento não pode ter mais do que 80 caracteres")
    .notRequired(),
  obs: yup.string().required("Campo obrigatório"),
});

const validationEditPdiTaskSchema = yup.object().shape({
  idFuncionarioRelacionado: yup.number().nullable().notRequired(),
  complemento: yup
    .string()
    .max(80, "O complemento não pode ter mais do que 80 caracteres")
    .notRequired(),
  obs: yup.string().required("Campo obrigatório"),
});

interface IDevelopmentActionsState {
  columns: IColumn[];
  items: AcaoPDIMap[];
  isPDISearchOpen: boolean;
  isDialogAddOpen: boolean;
  inicialValues: AcaoPDIMap;
  isFiltering: boolean;
  selectionDetails: string;
  selectionCount: number;
  isDialogExcludeOpen: boolean;
  search: string;
  pdiSelected: Partial<PDIType> | null;
  funcRelacionado: Partial<EmployeeType>;
}

export interface AcaoPDIMap
  extends Omit<AcaoTarefaPDI, "idTarefaPDIAcao" | "idTarefaPDIAcao"> {
  id: number | null;
  idPDIAcao?: number;
}

interface IPropsDevelopmentActions {
  pdiState: DataTypesPDI;
  employees: DataTypesEmployee;
  acoes: AcaoPDIMap[];
  regras?: RegrasPDIType[];
  readOnly: boolean;
  loading: boolean;
  success: boolean;
  error: boolean;
  onAdd: (acaoPDI: AcaoPDIMap) => void;
  onEdit: (acaoPDI: AcaoPDIMap) => void;
  onDel: (idPDIAcao: number) => void;
  getPdi: (SearchText?: string, TipoPDI?: number, Competencia?: number) => void;
  getEmployees: (search?: string, filter?: boolean | null) => void;
}

const initialAction: AcaoPDIMap = {
  id: null,
  idAcaoPDI: null,
  obs: "",
  complemento: "",
  acaoPDI: {
    nomeAcaoPDI: "",
    prazo: null,
    flagRelacionarFuncionario: false,
    tipoPDI: {
      cor: "",
      idTipoPDI: null,
    },
  },
  funcionario: {
    idFuncionario: null,
    nomeCompleto: "",
  },
};

class DevelopmentActions extends Component<
  IPropsDevelopmentActions,
  IDevelopmentActionsState
> {
  private formRef: any;
  private timeout: number;
  private _selection: Selection;

  constructor(props: IPropsDevelopmentActions) {
    super(props);
    //#region Colunas
    const columns: IColumn[] = [
      {
        key: "column1",
        name: "ID",
        ariaLabel: "ID",
        fieldName: "id",
        isRowHeader: true,
        minWidth: 35,
        maxWidth: 35,
        isResizable: true,
        isSortedDescending: false,
        onColumnClick: this._onColumnClick,
      },
      {
        key: "column2",
        name: "Tipo",
        fieldName: "acaoPDI.tipoPDI.idTipoPDI",
        minWidth: 50,
        maxWidth: 50,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "string",
        styles: { cellTitle: { justifyContent: "center" } },
        onRender: (value: AcaoTarefaPDI) => (
          <Stack
            horizontalAlign="center"
            verticalAlign="center"
            styles={{ root: { width: "100%", height: "100%" } }}
          >
            <FontIcon
              iconName="CircleShapeSolid"
              style={{ color: value.acaoPDI.tipoPDI.cor }}
            />
          </Stack>
        ),
      },
      {
        key: "column3",
        name: "Descrição",
        fieldName: "acaoPDI.nomeAcaoPDI",
        minWidth: 210,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "string",
        isPadded: true,
        onRender: (value: AcaoTarefaPDI) =>
          `${value.acaoPDI.nomeAcaoPDI} ${
            value.complemento ? `- ${value.complemento}` : ""
          } ${
            value.funcionario?.nomeCompleto
              ? `- ${value.funcionario.nomeCompleto}`
              : ""
          }`,
      },
      // {
      //     key: 'column4',
      //     name: 'Prazo (M)',
      //     fieldName: 'acaoPDI.prazo',
      //     minWidth: 75,
      //     maxWidth: 75,
      //     isRowHeader: true,
      //     isResizable: true,
      //     isSortedDescending: false,
      //     sortAscendingAriaLabel: 'Sorted A to Z',
      //     sortDescendingAriaLabel: 'Sorted Z to A',
      //     onColumnClick: this._onColumnClick,
      //     data: 'string',
      //     isPadded: true,
      //     onRender: (value: AcaoTarefaPDI) => value.acaoPDI.prazo
      // },
      {
        key: "column5",
        name: "Observações",
        fieldName: "obs",
        minWidth: 350,
        maxWidth: 400,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "string",
        isPadded: true,
      },
    ];
    //#endregion

    this.state = {
      columns: columns,
      items: [],
      isDialogAddOpen: false,
      isPDISearchOpen: false,
      selectionDetails: "",
      inicialValues: initialAction,
      isFiltering: false,
      selectionCount: 0,
      isDialogExcludeOpen: false,
      search: "",
      pdiSelected: null,
      funcRelacionado: {
        idFuncionario: null,
        nomeCompleto: "",
      },
    };

    this._selection = new Selection({
      onSelectionChanged: () => {
        this.setState({
          selectionDetails: this._getSelectionDetails(),
        });
      },
    });

    this.formRef = React.createRef();
    this.timeout = 0;
  }

  componentDidUpdate(prevProps: IPropsDevelopmentActions, _: any) {
    if (this.state.isDialogAddOpen) {
      if (prevProps.success !== this.props.success) {
        if (this.props.success) {
          this.setState({ isDialogAddOpen: false });
        }
      }
    }
  }

  // Submit do formulário Formik
  handleSubmit = () => {
    if (this.formRef.current) {
      this.formRef.current.handleSubmit();
    }
  };

  _onItemInvoked = (item: AcaoTarefaPDI): void => {
    if (!this.props.readOnly) {
      this.openEdit();
    }
  };

  _getSelectionDetails(): any {
    const selectionCount = this._selection.getSelectedCount();
    this.setState({ selectionCount });
    this._selection.getSelection();
  }

  openEdit = () => {
    const pdiTarefa = (this._selection.getSelection()[0] as AcaoPDIMap)!;
    // const pdiTarefa = this.props.acoes.find(
    //   (item) => item.idTarefaPDIAcao === tarefaPDIAcao.idTarefaPDIAcao
    // );

    this.setState({
      isDialogAddOpen: true,
      inicialValues: pdiTarefa!,
      pdiSelected: {
        idAcaoPDI: pdiTarefa?.idAcaoPDI,
        nomeAcaoPDI: pdiTarefa?.acaoPDI.nomeAcaoPDI,
        prazo: pdiTarefa?.acaoPDI.prazo,
        idTipoPDI: pdiTarefa?.acaoPDI.tipoPDI.idTipoPDI,
        flagRelacionarFuncionario: pdiTarefa.acaoPDI.flagRelacionarFuncionario,
        tipoPDI: {
          cor: pdiTarefa?.acaoPDI.tipoPDI.cor ?? "",
          descTipoPDI: "",
          // flagRelacionarFuncionario: pdiTarefa?.acaoPDI.tipoPDI.flagRelacionarFuncionario ?? false
        },
      },
      funcRelacionado: pdiTarefa.funcionario,
    });
  };

  cancelPanel = () => {
    this._selection.setAllSelected(false);
    this.setState({ isDialogAddOpen: false });
  };

  excludeActionPDI = () => {
    const id = (this._selection.getSelection()[0] as AcaoPDIMap).id!;

    this.props.onDel(id);
    this.setState({ isDialogExcludeOpen: false });
    this._selection.setAllSelected(false);
  };

  //#region  Funções do filtro

  handleSearch = (e: any) => {
    // const attitudes = this.props.attitudes.data;
    // const text = e.target.value;
    // let items = text
    //     ? attitudes.filter(
    //         item =>
    //             item.nomeAtitude!.toLowerCase().indexOf(text.toLowerCase()) > -1
    //     )
    //     : attitudes;
    // this.setState({ items, isFiltering: true, search: text });
  };

  //#endregion

  commandBarRender = () => {
    const { selectionCount } = this.state;
    const readOnly = this.props.readOnly;
    if (selectionCount === 1) {
      return (
        <>
          <CommandBarButton
            styles={btnStyle}
            iconProps={{ iconName: "Edit" }}
            text="Editar"
            onClick={() => this.openEdit()}
            disabled={readOnly}
          />
          <CommandBarButton
            styles={btnStyle}
            iconProps={{ iconName: "Delete" }}
            text="Excluir"
            onClick={() => this.setState({ isDialogExcludeOpen: true })}
            disabled={readOnly}
          />
        </>
      );
    }
  };

  search = (tipo: string, text: string) => {
    if (text.trim()) {
      clearTimeout(this.timeout);
      this.timeout = window.setTimeout(() => {
        switch (tipo) {
          case "pdi":
            this.props.getPdi(text);
            break;
          case "funcionario":
            this.props.getEmployees(text);
            break;
        }
      }, 700);
    }
  };

  render() {
    const {
      isDialogAddOpen,
      isDialogExcludeOpen,
      inicialValues,
      columns,
      pdiSelected,
      isPDISearchOpen,
      funcRelacionado,
    } = this.state;
    const {
      acoes,
      regras,
      pdiState,
      employees,
      loading,
      error,
      readOnly,
      onAdd,
      onEdit,
    } = this.props;
    // const { TarefaPDI, flagEdicao } = selected ?? {};
    // const { Regras, acoes } = TarefaPDI ?? {};
    // const readOnly = !flagEdicao;

    return (
      <>
        <Wrapper>
          <Stack horizontal style={{ margin: "20px 0" }}>
            {regras?.map((item, i) => {
              const { tipoPDI, percent, idTipoPDI } = item;
              const total = acoes?.length ?? 1;
              const numTipos =
                acoes?.reduce((total, item) => {
                  if (item.acaoPDI.tipoPDI.idTipoPDI === idTipoPDI) {
                    return (total += 1);
                  } else {
                    return total;
                  }
                }, 0) ?? 0;

              const percentCalc = (numTipos / total) * 100;

              return (
                <Box
                  key={i}
                  bgColor={tipoPDI.cor}
                  title={tipoPDI.descTipoPDI}
                  target={Number(percent)}
                  complemento={tipoPDI.complemento}
                  percentAtual={
                    percentCalc ? Number(percentCalc.toFixed(1)) : 0
                  }
                />
              );
            })}
          </Stack>

          <Stack
            horizontal
            horizontalAlign="space-between"
            verticalAlign="center"
          >
            <Stack horizontal>
              <CommandBarButton
                styles={btnStyle}
                iconProps={{ iconName: "Add" }}
                disabled={readOnly}
                text="Adicionar Ação"
                onClick={() =>
                  this.setState({
                    isDialogAddOpen: true,
                    inicialValues: initialAction,
                    pdiSelected: null,
                    funcRelacionado: {
                      idFuncionario: null,
                      nomeCompleto: "",
                    },
                  })
                }
              />

              {this.commandBarRender()}
            </Stack>
            <Stack horizontal verticalAlign="center">
              {/* <SearchBox
                                placeholder="Pesquisar"
                                value={search}
                                onChange={(e) => this.handleSearch(e)}
                                // onKeyUp={(e) => this.filter(e.key)}
                                componentRef={this.inputSearch}
                                styles={{
                                    root:
                                    {
                                        border: 'none',
                                        width: 200,
                                        marginRight: 1
                                    }
                                }}
                            /> */}
              {/* <CommandBarButton
                                styles={btnStyle}
                                iconProps={{ iconName: 'Refresh' }}
                                text="Atualizar"
                                onClick={() => getAttitudes()}
                            /> */}
            </Stack>
          </Stack>
          {!loading && acoes.length === 0 ? (
            <NoItems
              error={error}
              text="Não há ações cadastradas"
              icon="PublishCourse"
            />
          ) : (
            <ListContainer>
              <ShimmeredDetailsList
                items={acoes ?? []}
                // items={isFiltering ? items : attitudes.data}
                enableShimmer={acoes.length === 0 && loading}
                columns={columns}
                selectionMode={
                  !readOnly ? SelectionMode.single : SelectionMode.none
                }
                selection={this._selection}
                getKey={this._getKey}
                selectionPreservedOnEmptyClick={true}
                setKey="single"
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                onItemInvoked={this._onItemInvoked}
              />
            </ListContainer>
          )}
        </Wrapper>

        <Dialog
          open={isDialogAddOpen}
          title="Ações de Desenvolvimento"
          btnConfirmText="Salvar"
          onClickConfirm={() => this.handleSubmit()}
          onClickCancel={() => this.setState({ isDialogAddOpen: false })}
          isLoadingConfirm={loading}
          width={640}
        >
          <Formik
            innerRef={this.formRef}
            initialValues={inicialValues}
            validationSchema={
              !inicialValues.id
                ? validationPdiTaskSchema
                : validationEditPdiTaskSchema
            }
            validateOnChange={false}
            validateOnBlur={true}
            enableReinitialize
            validate={(values) => {
              const errors: any = {};

              if (
                pdiSelected?.flagRelacionarFuncionario &&
                !values.idFuncionarioRelacionado
              ) {
                errors.idFuncionarioRelacionado = "Campo obrigatório";
              }

              return errors;
            }}
            onSubmit={(values) => {
              const val: AcaoPDIMap = {
                ...values,
                acaoPDI: {
                  nomeAcaoPDI: pdiSelected?.nomeAcaoPDI ?? "",
                  prazo: pdiSelected?.prazo ?? 0,
                  flagRelacionarFuncionario:
                    pdiSelected?.flagRelacionarFuncionario ?? false,
                  tipoPDI: {
                    cor: pdiSelected?.tipoPDI?.cor ?? "",
                    idTipoPDI: pdiSelected?.idTipoPDI ?? null,
                  },
                },
                idFuncionarioRelacionado:
                  funcRelacionado?.idFuncionario ?? null,
                funcionario: {
                  idFuncionario: funcRelacionado?.idFuncionario ?? null,
                  nomeCompleto: funcRelacionado?.nomeCompleto ?? "",
                },
              };
              if (!val.idFuncionarioRelacionado) {
                delete val.idFuncionarioRelacionado;
              }

              if (val.id === null) {
                onAdd({
                  id: null,
                  ...val,
                });
              } else {
                val.id = Number(val.id);
                onEdit(val);
              }
            }}
          >
            {({
              handleSubmit,
              handleChange,
              values,
              errors,
              setFieldError,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="ms-Grid" dir="ltr">
                  <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12">
                      <WrapperAutoComplete>
                        <InputAutocomplete
                          value={pdiSelected as PDIType}
                          onChange={(_, newValue) => {
                            this.setState({
                              pdiSelected: newValue!,
                              funcRelacionado: {
                                idFuncionario: null,
                                nomeCompleto: "",
                              },
                            });
                            setFieldValue("idAcaoPDI", newValue?.idAcaoPDI);
                            if (!newValue?.flagRelacionarFuncionario) {
                              setFieldValue(
                                "idFuncionarioRelacionado",
                                null,
                                false
                              );
                            }
                          }}
                          onInputChange={(_, newInputValue) => {
                            this.search("pdi", newInputValue);
                          }}
                          getOptionLabel={(pdi: PDIType) => {
                            if (pdi.nomeAcaoPDI) {
                              return pdi.nomeAcaoPDI;
                            } else {
                              return "";
                            }
                          }}
                          disabled={!!values.id}
                          renderOption={(option) => (
                            <React.Fragment>
                              <FontIcon
                                iconName="CircleFill"
                                style={{
                                  color: option.tipoPDI.cor,
                                  marginRight: 10,
                                }}
                              />
                              {option.nomeAcaoPDI}
                            </React.Fragment>
                          )}
                          getOptionSelected={(option, value) => {
                            return option.idAcaoPDI === value.idAcaoPDI;
                          }}
                          options={pdiState.data}
                          input={{
                            idInput: "idAcaoPDI",
                            labelInput: "Ação",
                            autoFocus: true,
                            helperTextInput: errors.idAcaoPDI,
                            errorInput: errors.idAcaoPDI ? true : false,
                          }}
                        />
                        <IconButton
                          iconProps={{ iconName: "Search" }}
                          styles={IconButtonStyle}
                          onClick={() =>
                            this.setState({ isPDISearchOpen: true })
                          }
                        />
                      </WrapperAutoComplete>

                      <FormSearchPDI
                        isOpen={isPDISearchOpen}
                        onAdd={(values) => {
                          setFieldValue("idAcaoPDI", values[0].idAcaoPDI!);
                          this.setState({ pdiSelected: values[0] });
                        }}
                        onClose={() =>
                          this.setState({ isPDISearchOpen: false })
                        }
                      />
                    </div>
                    {pdiSelected?.flagRelacionarFuncionario && (
                      <div className="ms-Grid-col ms-sm12">
                        <InputAutocomplete
                          value={funcRelacionado as EmployeeType}
                          onChange={(_, newValue) => {
                            this.setState({ funcRelacionado: newValue! });
                            setFieldValue(
                              "idFuncionarioRelacionado",
                              newValue?.idFuncionario
                            );
                            setFieldError("idFuncionarioRelacionado", "");
                          }}
                          onInputChange={(_, newInputValue) => {
                            this.search("funcionario", newInputValue);
                          }}
                          getOptionLabel={(employee: EmployeeType) => {
                            if (employee.nomeCompleto) {
                              return employee.nomeCompleto;
                            } else {
                              return "";
                            }
                          }}
                          getOptionSelected={(option, value) => {
                            return option.idFuncionario === value.idFuncionario;
                          }}
                          options={employees.data}
                          input={{
                            idInput: "idFuncionarioRelacionado",
                            labelInput: "Funcionário Relacionado",
                            helperTextInput: errors.idFuncionarioRelacionado,
                            errorInput: errors.idFuncionarioRelacionado
                              ? true
                              : false,
                          }}
                        />
                      </div>
                    )}
                    <div className="ms-Grid-col ms-sm8">
                      <InputText
                        type="text"
                        name="complemento"
                        label="Complemento"
                        value={values.complemento}
                        onChange={handleChange}
                        error={errors.complemento ? true : false}
                        helperText={errors.complemento}
                        className="mt-2"
                        inputProps={{ maxLength: 80 }}
                      />
                    </div>
                    <div className="ms-Grid-col ms-sm4">
                      {/* <InputText
                                                value={pdiSelected?.prazo ?? ""}
                                                type="text"
                                                label="Prazo"
                                                className="mt-2"
                                                helperText="Meses"
                                                disabled={true}
                                            /> */}
                    </div>
                    <div className="ms-Grid-col ms-sm12">
                      <InputText
                        value={values.obs}
                        onChange={(e) => {
                          handleChange(e);
                          setFieldError("obs", "");
                        }}
                        id="obs"
                        error={errors.obs ? true : false}
                        name="obs"
                        type="text"
                        label="Observações"
                        height_container="auto"
                        helperText={errors.obs?.concat(" ") ?? " "}
                        className="mt-2"
                        multiline
                        rows={3}
                        rowsMax={15}
                      />
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </Dialog>

        <CustomDialog
          hidden={!isDialogExcludeOpen}
          onDismiss={() => this.setState({ isDialogExcludeOpen: false })}
          dialogContentProps={dialogContentProps}
        >
          <DefaultButton
            onClick={() => this.setState({ isDialogExcludeOpen: false })}
            text="Cancelar"
          />
          <DeleteButton
            onClick={() => this.excludeActionPDI()}
            text="Excluir"
          />
        </CustomDialog>
      </>
    );
  }

  private _onColumnClick = (
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn
  ): void => {
    const { columns } = this.state;
    const newColumns: IColumn[] = columns.slice();
    let items: AcaoPDIMap[] = [];
    if (this.state.isFiltering) {
      items = this.state.items;
    } else {
      items = this.props.acoes ?? [];
    }
    const currColumn: IColumn = newColumns.filter(
      (currCol) => column.key === currCol.key
    )[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    const newItems = this._sort(
      items,
      currColumn.fieldName!,
      currColumn.isSortedDescending
    );
    this.setState({
      columns: newColumns,
      items: newItems,
    });
  };

  private _getKey(item: any, index?: number): any {
    if (item !== undefined) return item.key;
  }

  private _sort<T>(
    items: T[],
    columnKey: string,
    isSortedDescending?: boolean
  ): T[] {
    const result = (prop: string, obj: T) =>
      prop.split(".").reduce((a: any, b: any) => a[b] ?? "", obj);
    return items.sort((a: T, b: T) =>
      (
        isSortedDescending
          ? result(columnKey, a) < result(columnKey, b)
          : result(columnKey, a) > result(columnKey, b)
      )
        ? 1
        : -1
    );
  }
}

interface IBoxProps {
  bgColor: string;
  title: string;
  target: number;
  percentAtual: number;
  complemento: string;
}

const Box: React.FC<IBoxProps> = (props) => {
  const { bgColor, title, target, percentAtual, complemento } = props;
  const tooltip = useId("tooltip");
  return (
    <TooltipHost
      content={complemento}
      id={tooltip}
      styles={{ root: { flex: 1 } }}
    >
      <WrapperBox color={bgColor}>
        <Stack styles={{ root: { width: "100%" } }}>
          <Stack horizontal horizontalAlign="space-between">
            <Text
              variant="small"
              styles={{ root: { fontWeight: 600, color: Colors.white } }}
            >
              {title}
            </Text>
          </Stack>
          <Stack
            horizontalAlign="end"
            verticalAlign="end"
            styles={{ root: { height: "100%" } }}
          >
            <Text
              styles={{
                root: {
                  fontWeight: 600,
                  fontSize: 50,
                  color: Colors.white,
                  lineHeight: "1",
                },
              }}
            >
              {target}
            </Text>
            <Text
              styles={{
                root: { fontWeight: 200, fontSize: 18, color: Colors.white },
              }}
            >
              ({percentAtual})%
            </Text>
          </Stack>
        </Stack>
      </WrapperBox>
    </TooltipHost>
  );
};

const IconButtonStyle: Partial<IButtonStyles> = {
  root: {
    position: "absolute",
    top: 20,
    right: 8,
    width: 24,
    height: 24,
    padding: 8,
  },
  icon: {
    fontSize: 12,
  },
};

const dialogContentProps: IDialogContentProps = {
  title: "Excluir?",
  closeButtonAriaLabel: "Close",
  subText: "Tem certeza de que deseja excluir este item?",
};

const mapStateToProps = (state: RootState) => ({
  pdiState: state.pdiReducer,
  employees: state.employeesReducer,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      ...getPdi,
      ...getEmployees,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DevelopmentActions);
