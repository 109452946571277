import { Types, DataTypes, PDIActionTypes } from "./types";
import customToast from "~/components/Toast/index";

/**
 * Reducers
 */
const INITIAL_STATE: DataTypes = {
    data: [],
    error: false,
    loading: false,
    loadingAction: false,
    success: false
};

export const Creators = {
    /**
     * REQUEST: Carregar todas as competências dos PDIs
     * @returns Types.REQUEST_PDI_COMPETENCE_LOAD
     */
    getPdiCompetences: (): PDIActionTypes => ({
        type: Types.REQUEST_PDI_COMPETENCE_LOAD,
    }),

    addPdiCompetence: (descCompetencia: string): PDIActionTypes => ({
        type: Types.REQUEST_PDI_COMPETENCE_ADD,
        descCompetencia
    })
}


export default function competenceReducer(state = INITIAL_STATE, action: PDIActionTypes): DataTypes {

    switch (action.type) {
        // GET PDI COMPETENCES
        case Types.REQUEST_PDI_COMPETENCE_LOAD:
            return {
                ...state,
                loading: true,
                success: false,
                error: false
            };
        case Types.SUCCESS_PDI_COMPETENCE_LOAD:
            return {
                ...state,
                data: action.payload,
                loading: false,
                success: true,
                error: false
            };
        case Types.FAILURE_PDI_COMPETENCE_LOAD:
            customToast.error(action.message);
            return {
                ...state,
                data: [],
                loading: false,
                success: false,
                error: true
            };

        case Types.REQUEST_PDI_COMPETENCE_ADD:
            return { ...state, loadingAction: true, success: false, error: false }
        case Types.SUCCESS_PDI_COMPETENCE_ADD:
            customToast.success("Competência adicionado com sucesso!");
            return { ...state, data: [...state.data, action.payload], loadingAction: false, success: true, error: false }
        case Types.FAILURE_PDI_COMPETENCE_ADD:
            customToast.error(action.message);
            return { ...state, loadingAction: false, success: false, error: true }

        default:
            return state;
    }
}