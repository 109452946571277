// #region Imports
import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik } from "formik";
import * as yup from 'yup';

//FluentUI
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import {
    DetailsListLayoutMode,
    SelectionMode,
    Selection,
    IColumn,
} from 'office-ui-fabric-react/lib/DetailsList';
import { ShimmeredDetailsList } from 'office-ui-fabric-react/lib/ShimmeredDetailsList';
import { SearchBox } from 'office-ui-fabric-react/lib/SearchBox';
import { CommandBarButton, ICommandBarStyles } from 'office-ui-fabric-react';
import { IDialogContentProps } from 'office-ui-fabric-react/lib/Dialog';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';

import Panel from "~/components/layout/Panel";
import CustomDialog from "~/components/CustomDialogFluentUI";
import { Page, BreadcrumbItems } from "~/store/ducks/home/types";
import { InputText } from '~/components/Forms';

import { DeleteButton } from '~/components/Buttons';
import HeaderPage from '~/components/layout/HeaderPage';
import NoItems from '~/components/layout/NoItems';
import { PrimaryButton } from '~/components/Buttons';
//Estilos
import {
    Wrapper,
    ListContainer,
    DefaultButton,
    ContainerContent,
} from "./styles";

import { Creators as getAttitudes } from "~/store/ducks/attitudes";
import { Creators as getAttitudeById } from "~/store/ducks/attitudes";
import { Creators as addAttitude } from "~/store/ducks/attitudes";
import { Creators as editAttitude } from "~/store/ducks/attitudes";
import { Creators as delAttitude } from "~/store/ducks/attitudes";
import { Creators as setCurrentPage } from "~/store/ducks/home";
import { AttitudeType, DataTypes } from "~/store/ducks/attitudes/types";
import { RootState } from "~/store/ducks";
//#endregion

const btnStyle: Partial<ICommandBarStyles> = {
    root: {
        height: 44
    }
}

/**
 * Validação do formulário
 */
const validationAttitudesSchema = yup.object().shape({
    nomeAtitude: yup.string()
        .min(2, "O nome da atitude deve conter pelo menos 2 caracteres")
        .max(100, "O nome da atitude não pode ter mais do que 100 caracteres")
        .required("Campo obrigatório"),
    descAtitude:
        yup.string()
            .required("Campo obrigatório"),
    descColetivo:
        yup.string()
            .nullable()
            .required("Campo obrigatório"),

});

interface IAttitudesState {
    columns: IColumn[];
    items: AttitudeType[];
    isPanelOpen: boolean;
    inicialValues: AttitudeType;
    isFiltering: boolean;
    selectionDetails: string;
    selectionCount: number;
    isDialogExcludeOpen: boolean;
    search: string;
};

interface IPropsAttitudes {
    attitudes: DataTypes;
    setCurrentPage: (page: Page) => void;
    getAttitudes: (search?: string) => void;
    getAttitudeById: (id: number) => void;
    addAttitude: (attitude: AttitudeType) => void;
    editAttitude: (attitude: AttitudeType) => void;
    delAttitude: (id: number) => void;
}

const itemsBreadCrumb: BreadcrumbItems[] = [
    { text: "Home", isCurrentItem: false, icon: "HomeSolid", onlyIcon: true, url: "/" },
    { text: "Atitudes", isCurrentItem: true },
];

const initialPosition: AttitudeType = {
    idAtitude: null,
    nomeAtitude: '',
    descAtitude: '',
    descColetivo: ''
};

class Attitudes extends Component<IPropsAttitudes, IAttitudesState> {
    private formRef: any;
    private inputSearch: any;
    private _selection: Selection;

    constructor(props: IPropsAttitudes) {
        super(props);
        //#region Colunas
        const columns: IColumn[] = [
            {
                key: 'column1',
                name: 'ID',
                ariaLabel: 'ID da atitude',
                fieldName: 'idAtitude',
                isRowHeader: true,
                minWidth: 75,
                maxWidth: 100,
                isResizable: true,
                isSortedDescending: false,
                onColumnClick: this._onColumnClick
            },
            {
                key: 'column2',
                name: 'Nome',
                fieldName: 'nomeAtitude',
                minWidth: 210,
                maxWidth: 350,
                isRowHeader: true,
                isResizable: true,
                isSortedDescending: false,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                onColumnClick: this._onColumnClick,
                data: 'string',
                isPadded: true,
            }
        ];
        //#endregion

        this.state = {
            columns: columns,
            items: [],
            isPanelOpen: false,
            selectionDetails: "",
            inicialValues: initialPosition,
            isFiltering: false,
            selectionCount: 0,
            isDialogExcludeOpen: false,
            search: "",
        }

        this._selection = new Selection({
            onSelectionChanged: () => {
                this.setState({
                    selectionDetails: this._getSelectionDetails(),
                });
            },
        });

        this.formRef = React.createRef();
        this.inputSearch = React.createRef();
    };

    componentDidMount() {
        const page: Page = {
            key: 'atitudes',
            pages: itemsBreadCrumb
        };
        this.props.setCurrentPage(page);
        this.props.getAttitudes();
    };

    componentDidUpdate(prevProps: IPropsAttitudes, _: any) {
        if (this.state.isPanelOpen) {
            if (prevProps.attitudes.success !== this.props.attitudes.success) {
                if (this.props.attitudes.success) {
                    this.setState({ isPanelOpen: false })
                }
            }
        }

        if (this.props.attitudes.itemSelected.success !== prevProps.attitudes.itemSelected.success) {
            if (this.props.attitudes.itemSelected.success) {
                this.setState({ inicialValues: this.props.attitudes.itemSelected.item! })
            }
        }

        if (this.props.attitudes.itemSelected.error !== prevProps.attitudes.itemSelected.error) {
            if (this.props.attitudes.itemSelected.error) {
                this.setState({ isPanelOpen: false, inicialValues: initialPosition })
            }
        }
    }

    // Submit do formulário Formik
    handleSubmit = () => {
        if (this.formRef.current) {
            this.formRef.current.handleSubmit();
        };
    };

    _onItemInvoked = (item: AttitudeType): void => {
        this.props.getAttitudeById(item.idAtitude!)
        this.setState({
            isPanelOpen: true,
        });
    };

    _getSelectionDetails(): any {
        const selectionCount = this._selection.getSelectedCount();
        this.setState({ selectionCount });
        this._selection.getSelection();
    };

    openEdit = () => {
        const selectItem: number = (this._selection.getSelection()[0] as AttitudeType).idAtitude!;
        this.props.getAttitudeById(selectItem)
        this.setState({ isPanelOpen: true });
    };

    cancelPanel = () => {
        this._selection.setAllSelected(false);
        this.setState({ isPanelOpen: false });
    };

    excludePosition = () => {
        this.props.delAttitude((this._selection.getSelection()[0] as AttitudeType).idAtitude!);
        this.setState({ isDialogExcludeOpen: false });
        this._selection.setAllSelected(false);
    };

    //#region  Funções do filtro

    handleSearch = (e: any) => {
        const attitudes = this.props.attitudes.data;
        const text = e.target.value;

        let items = text
            ? attitudes.filter(
                item =>
                    item.nomeAtitude!.toLowerCase().indexOf(text.toLowerCase()) > -1
            )
            : attitudes;

        this.setState({ items, isFiltering: true, search: text });
    };

    //#endregion

    commandBarRender = () => {
        const { selectionCount } = this.state;
        if (selectionCount === 1) {
            return (
                <>
                    <CommandBarButton styles={btnStyle} iconProps={{ iconName: 'Edit' }} text="Editar" onClick={() => this.openEdit()} />
                    <CommandBarButton styles={btnStyle} iconProps={{ iconName: 'Delete' }} text="Excluir" onClick={() => this.setState({ isDialogExcludeOpen: true })} />
                </>
            );
        }
    };

    render() {
        const { isPanelOpen, isDialogExcludeOpen, selectionCount, inicialValues, isFiltering, items, columns, search } = this.state;
        const { attitudes, getAttitudes, addAttitude, editAttitude } = this.props;

        const dialogContentProps: IDialogContentProps = {
            title: 'Excluir?',
            closeButtonAriaLabel: 'Close',
            subText: selectionCount <= 1 ? 'Tem certeza de que deseja excluir este item?' : 'Tem certeza de que deseja excluir estes items?',
        };

        return (
            <>
                <Panel
                    title={!inicialValues.idAtitude ? "Nova atitude" : inicialValues.nomeAtitude}
                    open={isPanelOpen}
                    onClose={() => this.cancelPanel()}
                    footer={
                        <Stack horizontal tokens={{ childrenGap: 10 }}>
                            <DefaultButton onClick={() => this.cancelPanel()}>Cancelar</DefaultButton>
                            <PrimaryButton onClick={this.handleSubmit} isLoading={attitudes.loadingAction} text="Salvar" />
                        </Stack>
                    }
                >
                    {
                        attitudes.itemSelected.loading ?
                            <Spinner size={SpinnerSize.medium} styles={{ root: { height: '100%' } }} />
                            :
                            <Formik
                                innerRef={this.formRef}
                                initialValues={inicialValues}
                                validationSchema={validationAttitudesSchema}
                                validateOnChange={false}
                                validateOnBlur={true}
                                enableReinitialize
                                onSubmit={(values: AttitudeType) => {
                                    if (inicialValues.idAtitude === null) {
                                        addAttitude(values);
                                    } else {
                                        editAttitude(values);
                                    }
                                }}
                            >
                                {({ handleSubmit, handleChange, values, errors, setFieldError, setFieldValue }) => (
                                    <form onSubmit={handleSubmit}>
                                        <InputText
                                            value={values.nomeAtitude}
                                            onChange={(e) => { handleChange(e); setFieldError("nomeAtitude", "") }}
                                            id="nomeAtitude"
                                            error={errors.nomeAtitude ? true : false}
                                            name="nomeAtitude"
                                            type="text"
                                            label="Nome"
                                            helperText={errors.nomeAtitude}
                                            inputProps={{ maxLength: 100 }}
                                            className="mt-2"
                                            autoFocus
                                        />
                                        <InputText
                                            value={values.descAtitude}
                                            onChange={(e) => { handleChange(e); setFieldError("descAtitude", "") }}
                                            id="descAtitude"
                                            error={errors.descAtitude ? true : false}
                                            name="descAtitude"
                                            type="text"
                                            label="Descrição"
                                            height_container="auto"
                                            helperText={errors.descAtitude ?? " "}
                                            className="mt-2"
                                            multiline
                                            rows={3}
                                            rowsMax={15}
                                        />
                                        <InputText
                                            value={values.descColetivo ?? ""}
                                            onChange={(e) => { handleChange(e); setFieldError("descColetivo", "") }}
                                            id="descColetivo"
                                            error={errors.descColetivo ? true : false}
                                            name="descColetivo"
                                            type="text"
                                            label="Descrição para avaliação coletiva"
                                            helperText={errors.descColetivo ?? " "}
                                            height_container="auto"
                                            className="mt-2"
                                            multiline
                                            rows={3}
                                            rowsMax={15}
                                        />
                                    </form>
                                )}
                            </Formik>
                    }
                </Panel>

                <CustomDialog
                    hidden={!isDialogExcludeOpen}
                    onDismiss={() => this.setState({ isDialogExcludeOpen: false })}
                    dialogContentProps={dialogContentProps}
                >
                    <DefaultButton onClick={() => this.setState({ isDialogExcludeOpen: false })} text="Cancelar" />
                    <DeleteButton onClick={() => this.excludePosition()} text="Excluir" />
                </CustomDialog>
                <Wrapper>
                    <ContainerContent>
                        <HeaderPage
                            title="Atitudes"
                            leftItems={
                                <>
                                    <CommandBarButton
                                        styles={btnStyle}
                                        iconProps={{ iconName: 'Add' }}
                                        disabled={attitudes.loadingData}
                                        text="Adicionar Atitude"
                                        onClick={() => this.setState(
                                            {
                                                isPanelOpen: true,
                                                inicialValues: initialPosition
                                            }
                                        )}
                                    />
                                    {this.commandBarRender()}
                                </>
                            }
                            rightItems={
                                <>
                                    <SearchBox
                                        placeholder="Pesquisar"
                                        value={search}
                                        onChange={(e) => this.handleSearch(e)}
                                        // onKeyUp={(e) => this.filter(e.key)}
                                        componentRef={this.inputSearch}
                                        styles={{
                                            root:
                                            {
                                                border: 'none',
                                                width: 200,
                                                marginRight: 1
                                            }
                                        }}
                                    />
                                    <CommandBarButton
                                        styles={btnStyle}
                                        iconProps={{ iconName: 'Refresh' }}
                                        text="Atualizar"
                                        onClick={() => getAttitudes()}
                                    />
                                </>
                            }
                        />
                        {!attitudes.loadingData && attitudes.data.length === 0 ?
                            <NoItems
                                error={attitudes.error}
                                text="Não há atitudes cadastradas"
                                icon="ReminderPerson"
                            />
                            :
                            <ListContainer>
                                <ShimmeredDetailsList
                                    items={isFiltering ? items : attitudes.data}
                                    enableShimmer={attitudes.loadingData}
                                    columns={columns}
                                    selectionMode={SelectionMode.single}
                                    selection={this._selection}
                                    getKey={this._getKey}
                                    selectionPreservedOnEmptyClick={true}
                                    setKey="single"
                                    layoutMode={DetailsListLayoutMode.justified}
                                    isHeaderVisible={true}
                                    onItemInvoked={this._onItemInvoked}
                                />
                            </ListContainer>
                        }
                    </ContainerContent>
                </Wrapper>
            </>
        );
    };

    private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const { columns } = this.state;
        const newColumns: IColumn[] = columns.slice();
        let items: AttitudeType[] = [];
        if (this.state.isFiltering) {
            items = this.state.items;
        } else {
            items = this.props.attitudes.data;
        };
        const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            };
        });
        const newItems = this._sort(items, currColumn.fieldName!, currColumn.isSortedDescending);
        this.setState({
            columns: newColumns,
            items: newItems,
        });
    };

    private _getKey(item: any, index?: number): any {
        if (item !== undefined)
            return item.key;
    };

    private _sort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
        const result = (prop: string, obj: T) => (prop.split('.').reduce((a: any, b: any) => a[b] ?? '', obj))
        return items.sort((a: T, b: T) => ((isSortedDescending ? result(columnKey, a) < result(columnKey, b) : result(columnKey, a) > result(columnKey, b)) ? 1 : -1));
    };

}
const mapStateToProps = (state: RootState) => ({
    attitudes: state.attitudesReducer
});

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    ...getAttitudes,
    ...getAttitudeById,
    ...addAttitude,
    ...editAttitude,
    ...delAttitude,
    ...setCurrentPage
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Attitudes);
