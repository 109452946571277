import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  DetailsListLayoutMode,
  SelectionMode,
  IColumn,
} from "office-ui-fabric-react/lib/DetailsList";
import {
  ShimmeredDetailsList,
  IShimmeredDetailsListProps,
} from "office-ui-fabric-react/lib/ShimmeredDetailsList";

import { Creators as loadEmployeeOcorrencias } from "~/store/ducks/employees";
import {
  EmployeeOccurrenceType,
  EmployeeSelected,
} from "~/store/ducks/employees/types";
import { RootState } from "~/store/ducks";
import { DetailsHeader } from "office-ui-fabric-react";
import { Sticky } from "@fluentui/react";

interface IGridOcorrenciasProps
  extends Omit<IShimmeredDetailsListProps, "items"> {
  employee: EmployeeSelected;
  idFuncionario: number | null;
  selectionMode?: SelectionMode;
  loadEmployeeOcorrencias: (idFuncionario: number) => void;
  _onItemInvoked?: (
    item?: any,
    index?: number | undefined,
    ev?: Event | undefined
  ) => void;
}

interface IGridOcorrenciasState {
  columns: IColumn[];
}

class GridOcorrencias extends React.Component<
  IGridOcorrenciasProps,
  IGridOcorrenciasState
> {
  constructor(props: IGridOcorrenciasProps) {
    super(props);

    const columns: IColumn[] = [
      {
        key: "column1",
        name: "Data Afastamento",
        fieldName: "dataInicio",
        minWidth: 100,
        maxWidth: 100,
        isRowHeader: true,
        isResizable: true,
        data: "data",
        isPadded: true,
        onRender: (item: EmployeeOccurrenceType) =>
          moment(item.dataInicio).format("DD/MM/YYYY"),
      },
      {
        key: "column2",
        name: "Data Retorno",
        fieldName: "dataRetorno",
        isRowHeader: true,
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
        onRender: (item: EmployeeOccurrenceType) =>
          item.dataRetorno ? moment(item.dataRetorno).format("DD/MM/YYYY") : "",
      },
      {
        key: "column3",
        name: "Motivo Afastamento",
        fieldName: "motivoAfastamento",
        minWidth: 75,
        isRowHeader: true,
        isResizable: true,
        isPadded: true,
        onRender: (item: EmployeeOccurrenceType) =>
          item.motivoAfastamento?.descMotivoAfastamento ?? "",
      },
    ];

    this.state = {
      columns,
    };
  }

  componentDidMount() {
    const { loadEmployeeOcorrencias, idFuncionario } = this.props;

    if (!!idFuncionario) {
      loadEmployeeOcorrencias(idFuncionario);
    }
  }

  render() {
    const { columns } = this.state;
    const { employee, ...rest } = this.props;
    return (
      <ShimmeredDetailsList
        {...rest}
        items={employee.ocorrencias ?? []}
        enableShimmer={employee.loading}
        columns={columns}
        styles={{ root: { paddingTop: 0 } }}
        onRenderDetailsHeader={(detailsHeaderProps) => {
          return (
            <Sticky>
              <DetailsHeader
                {...detailsHeaderProps}
                layoutMode={DetailsListLayoutMode.justified}
              />
            </Sticky>
          );
        }}
      />
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  employee: state.employeesReducer.itemSelected,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      ...loadEmployeeOcorrencias,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(GridOcorrencias);
