/**
 * Types
 */
export enum Types {
    //GET
    REQUEST_PILLARS_LOAD = "pillars/REQUEST_PILLARS_LOAD",
    SUCCESS_PILLARS_LOAD = "pillars/SUCCESS_PILLARS_LOAD",
    FAILURE_PILLARS_LOAD = "pillars/FAILURE_PILLARS_LOAD",
    //ADD
    REQUEST_PILLARS_ADD = "pillars/REQUEST_PILLARS_ADD",
    SUCCESS_PILLARS_ADD = "pillars/SUCCESS_PILLARS_ADD",
    FAILURE_PILLARS_ADD = "pillars/FAILURE_PILLARS_ADD",
    //PUT
    REQUEST_PILLARS_EDIT = "pillars/REQUEST_PILLARS_EDIT",
    SUCCESS_PILLARS_EDIT = "pillars/SUCCESS_PILLARS_EDIT",
    FAILURE_PILLARS_EDIT = "pillars/FAILURE_PILLARS_EDIT",
    //DELETE
    REQUEST_PILLARS_DELETE = "pillars/REQUEST_PILLARS_DELETE",
    SUCCESS_PILLARS_DELETE = "pillars/SUCCESS_PILLARS_DELETE",
    FAILURE_PILLARS_DELETE = "pillars/FAILURE_PILLARS_DELETE",
};
/**
 * Data types
 */



export type PillarType = {
    idPilar: number | null;
    nomePilar: string;
    corPilar: string;
};

interface IPillarsState {
    readonly data: PillarType[];
    readonly loadingData: boolean;
    readonly loadingAction: boolean;
    readonly error: boolean;
    readonly success: boolean;
};

export type DataTypes = IPillarsState;

/**
 * Actions
 */

//GET
export interface IRequestPillarsLoadAction {
    type: typeof Types.REQUEST_PILLARS_LOAD;
    search: string;
};

interface ISuccessPillarsLoadAction {
    type: typeof Types.SUCCESS_PILLARS_LOAD;
    payload: PillarType[];
};

interface IFailurePillarsLoadAction {
    type: typeof Types.FAILURE_PILLARS_LOAD;
    message: string;
};

type PillarsLoadAction = IRequestPillarsLoadAction | ISuccessPillarsLoadAction | IFailurePillarsLoadAction;

//ADD
export interface IRequestPillarsAddAction {
    type: typeof Types.REQUEST_PILLARS_ADD;
    pillar: PillarType;
};

interface ISuccessPillarsAddAction {
    type: typeof Types.SUCCESS_PILLARS_ADD;
    pillar: PillarType;
};

interface IFailurePillarsAddAction {
    type: typeof Types.FAILURE_PILLARS_ADD;
    message: string;
};

type PillarsAddAction = IRequestPillarsAddAction | ISuccessPillarsAddAction | IFailurePillarsAddAction;

//PUT
export interface IRequestPillarsEditAction {
    type: typeof Types.REQUEST_PILLARS_EDIT;
    pillar: PillarType;
};

export interface ISuccessPillarsEditAction {
    type: typeof Types.SUCCESS_PILLARS_EDIT;
    pillar: PillarType;
};

export interface IFailurePillarsEditAction {
    type: typeof Types.FAILURE_PILLARS_EDIT;
    message: string;
};

type PillarsEditAction = IRequestPillarsEditAction | ISuccessPillarsEditAction | IFailurePillarsEditAction;


//DELETE
export interface IRequestPillarsDeleteAction {
    type: typeof Types.REQUEST_PILLARS_DELETE;
    id: number;
};

export interface ISuccessPillarsDeleteAction {
    type: typeof Types.SUCCESS_PILLARS_DELETE;
    id: number;
};

export interface IFailurePillarsDeleteAction {
    type: typeof Types.FAILURE_PILLARS_DELETE;
    message: string;
};

type PillarsDeleteAction = IRequestPillarsDeleteAction | ISuccessPillarsDeleteAction | IFailurePillarsDeleteAction;

export type PillarsActionTypes = PillarsLoadAction | PillarsAddAction | PillarsEditAction | PillarsDeleteAction;