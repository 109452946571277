/**
 * Types
 */
export enum Types {
    //GET
    REQUEST_STEP_LOAD = "step/REQUEST_STEP_LOAD",
    SUCCESS_STEP_LOAD = "step/SUCCESS_STEP_LOAD",
    FAILURE_STEP_LOAD = "step/FAILURE_STEP_LOAD",
    //ADD
    REQUEST_STEP_ADD = "step/REQUEST_STEP_ADD",
    SUCCESS_STEP_ADD = "step/SUCCESS_STEP_ADD",
    FAILURE_STEP_ADD = "step/FAILURE_STEP_ADD",
    //DEL
    REQUEST_STEP_DEL = "step/REQUEST_STEP_DEL",
    SUCCESS_STEP_DEL = "step/SUCCESS_STEP_DEL",
    FAILURE_STEP_DEL = "step/FAILURE_STEP_DEL",
}

export type StepType = {
    idStep: number | null;
    percent: string | number;
}

interface IStepState {
    readonly data: StepType[];
    readonly itemSelected: StepType | null;
    readonly loadingData: boolean;
    readonly loadingAction: boolean;
    readonly error: boolean;
    readonly success: boolean;
};

export type DataTypes = IStepState;

/**
 * Actions
 */

//GET
export interface IRequestStepLoadAction {
    type: typeof Types.REQUEST_STEP_LOAD;
};

export interface ISuccessStepLoadAction {
    type: typeof Types.SUCCESS_STEP_LOAD;
    payload: StepType[];
};

interface IFailureStepLoadAction {
    type: typeof Types.FAILURE_STEP_LOAD;
    message: string;
};

type StepLoadAction = IRequestStepLoadAction | ISuccessStepLoadAction | IFailureStepLoadAction;

//ADD
export interface IRequestStepAddAction {
    type: typeof Types.REQUEST_STEP_ADD;
    payload: StepType;
};

export interface ISuccessStepAddAction {
    type: typeof Types.SUCCESS_STEP_ADD;
    payload: StepType;
};

interface IFailureStepAddAction {
    type: typeof Types.FAILURE_STEP_ADD;
    message: string;
};

type StepAddAction = IRequestStepAddAction | ISuccessStepAddAction | IFailureStepAddAction;

//DEL
export interface IRequestStepDelAction {
    type: typeof Types.REQUEST_STEP_DEL;
    idStep: number;
};

export interface ISuccessStepDelAction {
    type: typeof Types.SUCCESS_STEP_DEL;
    idStep: number;
}; 

interface IFailureStepDelAction {
    type: typeof Types.FAILURE_STEP_DEL;
    message: string;
};

type StepDelAction = IRequestStepDelAction | ISuccessStepDelAction | IFailureStepDelAction;


export type StepActionTypes =
    StepLoadAction |
    StepAddAction |
    StepDelAction;