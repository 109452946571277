import React, { useState, useEffect } from 'react';
import * as yup from "yup";
import { Formik } from "formik";
import { useDispatch, useSelector } from 'react-redux';

import { InputText, InputAutocomplete, InputDateDialog, InputTimeDialog, InputCheckbox } from '~/components/Forms';

import { Creators } from '~/store/ducks/employees'
import { EmployeeType, DataTypes } from '~/store/ducks/employees/types'

import { RootState } from "~/store/ducks";

export interface IInfoBasicGroup {
  nomeGrupo: string;
  lider: Partial<EmployeeType>;
  assistenteRh: Partial<EmployeeType>;
  hrbp: Partial<EmployeeType>;
  date: Date | null;
  time: Date | null;
  minutos: number | null;
  flagIgnorarCurva: boolean;
}

interface IFormInfoBasicGroupProps {
  formRef: any;
  initialValues: IInfoBasicGroup;
  success: (values: any) => any;
};

const validationSchemaBasicInfo = yup.object().shape({
  nomeGrupo: yup.string().required('Campo obrigatório'),
  lider: yup.object().shape({
    idFuncionario:
      yup.number()
        .nullable()
        .required("Campo obrigatório"),
  }),
  hrbp: yup.object().shape({
    idFuncionario:
      yup.number()
        .nullable()
        .required("Campo obrigatório"),
  }),
  assistente: yup.object().shape({
    idFuncionario:
      yup.number()
        .nullable()
        .notRequired(),
  }),
  date: yup.date().nullable().typeError('Data inválida').required("Campo obrigatório"),
  time: yup.date().nullable().typeError("Hora inválida").required("Campo obrigatório"),
  minutos: yup.number().typeError('Campo obrigatório').moreThan(0, 'o tempo precisa ser maior que zero').required("Campo obrigatório"),
  flagIgnorarCurva: yup.bool().notRequired(),
});

let timeout: number = 0;

const FormInfoBasic: React.FC<IFormInfoBasicGroupProps> = (props) => {
  // const [initialValues, setInitialValues] = useState<Partial<EmployeeType>[]>(props.initialValues);
  const [isLiderOpen, setIsLiderOpen] = useState(false);
  const [isHrbpOpen, setIsHrbpOpen] = useState(false);
  const [isAssitenteOpen, setIsAssitenteOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [valueLider, setValueLider] = useState<Partial<EmployeeType>>({
    idFuncionario: null,
    nomeCompleto: ''
  });

  const [valueHrbp, setValueHrbp] = useState<Partial<EmployeeType>>({
    idFuncionario: null,
    nomeCompleto: ''
  });

  const [valueAssistente, setValueAssistente] = useState<Partial<EmployeeType>>({
    idFuncionario: null,
    nomeCompleto: ''
  });

  const employeesState = useSelector<RootState, DataTypes>(state => state.employeesReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    setValueLider({
      idFuncionario: props.initialValues.lider.idFuncionario ?? null,
      nomeCompleto: props.initialValues.lider.nomeCompleto ?? ''
    })
    setValueHrbp({
      idFuncionario: props.initialValues.hrbp.idFuncionario ?? null,
      nomeCompleto: props.initialValues.hrbp.nomeCompleto ?? ''
    })
    setValueAssistente({
      idFuncionario: props.initialValues.assistenteRh.idFuncionario ?? null,
      nomeCompleto: props.initialValues.assistenteRh.nomeCompleto ?? ''
    })
  }, [])

  const search = (text: string) => {
    if (text.trim()) {
      clearTimeout(timeout);
      setIsLoading(true);
      timeout = window.setTimeout(() => {
        dispatch(Creators.getEmployees(text, null, true));
        setIsLoading(false);
      }, 700);
    }
  };

  return (
    <Formik
      enableReinitialize={true}
      innerRef={props.formRef}
      initialValues={props.initialValues}
      validationSchema={validationSchemaBasicInfo}
      validateOnChange={false}
      validateOnBlur={true}
      onSubmit={(values: IInfoBasicGroup) => {
        values.lider = {
          idFuncionario: valueLider.idFuncionario,
          nomeCompleto: valueLider.nomeCompleto
        };
        values.assistenteRh = {
          idFuncionario: valueAssistente.idFuncionario,
          nomeCompleto: valueAssistente.nomeCompleto
        };

        values.hrbp = {
          idFuncionario: valueHrbp.idFuncionario,
          nomeCompleto: valueHrbp.nomeCompleto
        }

        props.success(values);
      }}
    >
      {({ handleSubmit, handleChange, values, errors, setFieldError, setFieldValue }) => {
        return (
          <form onSubmit={handleSubmit}>
            <div className="ms-Grid" dir="ltr">
              <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 paddingZero">
                  <InputText
                    value={values.nomeGrupo}
                    onChange={(e) => { handleChange(e); setFieldError("nomeGrupo", "") }}
                    id="nomeGrupo"
                    error={errors.nomeGrupo ? true : false}
                    name="nomeGrupo"
                    type="text"
                    label="Nome do Grupo"
                    helperText={errors.nomeGrupo}
                    className="mt-2"
                    inputProps={{
                      maxLength: 80
                    }}
                    autoFocus
                  />
                </div>
                <div className="ms-Grid-col ms-sm6 paddingZero">
                  <div className="ms-Grid-col ms-sm12 paddingZero">
                    <InputAutocomplete
                      value={(valueLider as EmployeeType)}
                      onChange={(_, newValue) => {
                        setValueLider(newValue!)
                        setFieldValue("lider", { idFuncionario: newValue?.idFuncionario });
                      }}
                      onInputChange={(_, newInputValue) => {
                        setFieldError("lider", "");
                        search(newInputValue);
                      }}
                      onOpen={() => setIsLiderOpen(true)}
                      onClose={() => setIsLiderOpen(false)}
                      loading={employeesState.loadingData}
                      getOptionLabel={(employee: EmployeeType) => employee.nomeCompleto}
                      getOptionSelected={(option, value) => option.idFuncionario === value.idFuncionario}
                      options={employeesState.data}
                      input={{
                        idInput: "funcionario",
                        labelInput: "Líder",
                        helperTextInput: errors.lider?.idFuncionario,
                        errorInput: errors.lider?.idFuncionario ? true : false,
                        isLoading: isLiderOpen && isLoading,
                      }}
                    />
                  </div>

                  <div className="ms-Grid-col ms-sm12 paddingZero">
                    <InputAutocomplete
                      value={(valueHrbp as EmployeeType)}
                      onChange={(_, newValue) => {
                        setValueHrbp(newValue!)
                        setFieldValue("hrbp", { idFuncionario: newValue?.idFuncionario });
                      }}
                      onInputChange={(_, newInputValue) => {
                        setFieldError("hrbp", "");
                        search(newInputValue);
                      }}
                      onOpen={() => setIsHrbpOpen(true)}
                      onClose={() => setIsHrbpOpen(false)}
                      loading={employeesState.loadingData}
                      getOptionLabel={(employee: EmployeeType) => employee.nomeCompleto}
                      getOptionSelected={(option, value) => option.idFuncionario === value.idFuncionario}
                      options={employeesState.data}
                      input={{
                        idInput: "idFuncionarioHRBP",
                        labelInput: "HR Bussiness Partner",
                        helperTextInput: errors.hrbp?.idFuncionario,
                        errorInput: errors.hrbp?.idFuncionario ? true : false,
                        isLoading: isHrbpOpen && isLoading,
                      }}
                    />
                  </div>
                  <div className="ms-Grid-col ms-sm12 paddingZero">
                    <InputAutocomplete
                      value={(valueAssistente as EmployeeType)}
                      onChange={(_, newValue) => {
                        setValueAssistente(newValue!)
                        setFieldValue("assistente", { idFuncionario: newValue?.idFuncionario });
                      }}
                      onInputChange={(_, newInputValue) => {
                        setFieldError("assistente", "");
                        search(newInputValue);
                      }}
                      onOpen={() => setIsAssitenteOpen(true)}
                      onClose={() => setIsAssitenteOpen(false)}
                      loading={employeesState.loadingData}
                      getOptionLabel={(employee: EmployeeType) => employee.nomeCompleto}
                      getOptionSelected={(option, value) => option.idFuncionario === value.idFuncionario}
                      options={employeesState.data}
                      input={{
                        idInput: "funcionario",
                        labelInput: "Assistente do RH",
                        helperTextInput: errors.assistenteRh?.idFuncionario,
                        errorInput: errors.assistenteRh?.idFuncionario ? true : false,
                        isLoading: isAssitenteOpen && isLoading,
                      }}
                    />
                  </div>
                  <div className="ms-Grid-col ms-sm6" style={{ paddingLeft: 0, paddingRight: '5px !important' }}>
                    <InputDateDialog
                      id="date"
                      error={errors.date ? true : false}
                      name="date"
                      label="Data prevista"
                      value={values.date}
                      onChange={value => {
                        setFieldError('date', '');
                        setFieldValue('date', value);
                      }}
                      helperText={errors.date}
                    />
                  </div>
                  <div className="ms-Grid-col ms-sm6" style={{ paddingLeft: '5px !important', paddingRight: '0px !important' }}>
                    <InputTimeDialog
                      id="time"
                      error={errors.time ? true : false}
                      name="time"
                      label="Hora prevista"
                      value={values.time}
                      onChange={value => {
                        setFieldError('time', '');
                        setFieldValue('time', value);
                      }}
                      helperText={errors.time}
                    />
                  </div>
                  <div className="ms-Grid-col ms-sm12 paddingZero">
                    <InputText
                      id="minutos"
                      type="number"
                      error={errors.minutos ? true : false}
                      name="minutos"
                      label="Tempo de avaliação individual"
                      value={values.minutos ?? ''}
                      onChange={value => {
                        setFieldError('minutos', '');
                        setFieldValue('minutos', Number(value.target.value));
                      }}
                      helperText={errors.minutos}
                    />
                    <div className="ms-Grid-col ms-sm12 paddingZero">
                      <InputCheckbox
                        checked={values.flagIgnorarCurva}
                        onChange={handleChange}
                        name="flagIgnorarCurva"
                        color="primary"
                        label="Ignorar curva de performance"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        )
      }}
    </Formik>
  );
}

export default FormInfoBasic;
