import {
  Types,
  DataTypes,
  TaskActionTypes,
  IniciativasType,
  ResultadosType,
  AcaoTarefaPDI,
  TaskFilterType,
} from "./types";
import customToast from "~/components/Toast/index";
import {
  Types as TypesSelfEvaluation,
  SelfEvaluationActionTypes,
} from "../selfEvaluations/types";
import { CalculaAting } from "~/pages/Cycle/FormMetas";
import jwt from "jwt-decode";

/**
 * Reducers
 */
const token = localStorage.getItem("user/token");

const defaultFilters = {
  isOpen: false,
  filteredByStatus: [],
  filteredByType: [],
  group: {
    a: false,
    f: false,
    e: false,
    c: false,
  },
  responsavel: {
    idFuncionario: undefined,
    // idFuncionario: token ? (jwt(token ?? "") as any)?.idFuncionario : null,
    nomeCompleto: "",
  },
};

const INITIAL_STATE: DataTypes = {
  data: [],
  filter: defaultFilters,
  searchText: "",
  selected: null,
  selectedColetiva: null,
  selectedColetivaFeedback: null,
  loadingData: false,
  loadingSave: false,
  loadingAction: false,
  error: false,
  errorAction: false,
  success: false,
  successAction: false,
  redirectTo: null,
  nextTask: null,
};

/**
 * Actions
 */

export const Creators = {
  getTasks: (
    responsavelId?: number | null,
    search: string = "",
    filterStatus?: string[],
    filterType?: string[]
  ): TaskActionTypes => {
    return {
      type: Types.REQUEST_TASKS_LOAD,
      payload: {
        searchText: search,
        filterStatus,
        filterType,
        responsavelId,
      },
    };
  },

  getTasksById: (idTarefa: number): TaskActionTypes => {
    return {
      type: Types.REQUEST_TASKS_LOAD_ID,
      idTarefa,
    };
  },

  saveIniciativasAutoavaliacao: (
    idCicloAvaliacaoArea: number,
    iniciativas: Partial<IniciativasType>[],
    comentarios: string,
    idCicloAvaliacaoAreaResultado: number
  ): TaskActionTypes => ({
    type: Types.SAVE_TASK_INICIATIVAS_AUTOAVALICACAO,
    payload: {
      idCicloAvaliacaoArea,
      iniciativas,
      comentarios,
      idCicloAvaliacaoAreaResultado,
    },
  }),

  saveAtitudeAvaliacao: (
    idCicloAvaliacaoAtitude: number,
    resultado: string
  ): TaskActionTypes => ({
    type: Types.SAVE_TASK_ATITUDE_AVALIACAO,
    payload: {
      idCicloAvaliacaoAtitude,
      resultado,
    },
  }),

  saveAtitudeComentario: (
    idCicloAvaliacaoAtitude: number,
    comentarios: string
  ): TaskActionTypes => ({
    type: Types.SAVE_TASK_ATITUDE_COMENTARIO,
    payload: {
      idCicloAvaliacaoAtitude,
      comentarios,
    },
  }),

  saveAspiracaoCarreira: (aspiracao: string): TaskActionTypes => ({
    type: Types.SAVE_TASK_ASPIRACOES_CARREIRA,
    aspiracao,
  }),

  saveComentariosGerais: (comentariosGerais: string): TaskActionTypes => ({
    type: Types.SAVE_TASK_COMENTARIOS_GERAIS,
    comentariosGerais,
  }),

  finishTask: (idTarefa: number, data?: object): TaskActionTypes => ({
    type: Types.REQUEST_TASKS_FINISH,
    payload: {
      idTarefa,
      data,
    },
  }),

  resetTask: (): TaskActionTypes => ({
    type: Types.RESET_TASK,
  }),

  addPdiTask: (idTarefa: number, acao: AcaoTarefaPDI): TaskActionTypes => ({
    type: Types.REQUEST_TASKS_ACAO_PDI_ADD,
    payload: {
      acao,
      idTarefa,
    },
  }),

  editPdiTask: (idTarefa: number, acaoPDI: AcaoTarefaPDI): TaskActionTypes => ({
    type: Types.REQUEST_TASKS_ACAO_PDI_EDIT,
    payload: {
      idTarefa,
      acaoPDI,
    },
  }),

  delPdiTask: (idTarefa: number, idTarefaPDIAcao: number): TaskActionTypes => ({
    type: Types.REQUEST_TASKS_ACAO_PDI_DEL,
    payload: {
      idTarefa,
      idTarefaPDIAcao,
    },
  }),

  savePdiTask: (idTarefa: number, comentarios: string): TaskActionTypes => ({
    type: Types.REQUEST_TASKS_ACAO_PDI_SAVE,
    payload: {
      idTarefa,
      comentarios,
    },
  }),

  clearFilters: (): TaskActionTypes => ({
    type: Types.FILTERS_TASK_CLEAR,
  }),

  editFilters: (filter: TaskFilterType): TaskActionTypes => ({
    type: Types.FILTERS_TASK_EDIT,
    payload: filter,
  }),

  editSearchText: (searchText: string): TaskActionTypes => ({
    type: Types.SEARCH_TEXT_TASK_EDIT,
    payload: searchText,
  }),

  solicitarCartasDevolutiva: (
    idTarefa: number,
    cartas: number[]
  ): TaskActionTypes => ({
    type: Types.REQUEST_TASKS_DEVOLUTIVA_MERITO_SOLIC_CARTAS,
    payload: {
      idTarefa,
      cartas,
    },
  }),

  statusCartasDevolutiva: (
    idTarefa: number,
    idTarefaDevMerito: number | string
  ): TaskActionTypes => ({
    type: Types.REQUEST_TASKS_DEVOLUTIVA_MERITO_STATUS_CARTAS,
    payload: {
      idTarefa,
      idTarefaDevMerito,
    },
  }),

  downloadCartaDevolutiva: (
    idTarefa: number,
    idTarefaDevMerito: number | string
  ): TaskActionTypes => ({
    type: Types.REQUEST_TASKS_DEVOLUTIVA_MERITO_DOWNLOAD_CARTAS,
    payload: {
      idTarefa,
      idTarefaDevMerito,
    },
  }),

  realizarDevolutiva: (
    idTarefa: number,
    idTarefaDevMerito: number | string
  ): TaskActionTypes => ({
    type: Types.REQUEST_TASKS_DEVOLUTIVA_REALIZAR,
    payload: {
      idTarefa,
      idTarefaDevMerito,
    },
  }),

  cancelarDevolutiva: (
    idTarefa: number,
    idTarefaDevMerito: number | string
  ): TaskActionTypes => ({
    type: Types.REQUEST_TASKS_DEVOLUTIVA_CANCELAR,
    payload: {
      idTarefa,
      idTarefaDevMerito,
    },
  }),

  getInfoAvaliacaoColetiva: (
    idTarefa: number,
    idTarefaAvaliacao: number
  ): TaskActionTypes => ({
    type: Types.REQUEST_TASKS_COLETIVA_INFO_AVALIACAO,
    payload: {
      idTarefa,
      idTarefaAvaliacao,
    },
  }),

  editAvaliacaoColetivaResultadoMeta: (
    idTarefa: number,
    idTarefaAvaliacao: number,
    resultadometas: string,
    comentariosMetas: string
  ): TaskActionTypes => ({
    type: Types.REQUEST_TASKS_COLETIVA_RESULTADO_META_EDIT,
    payload: {
      idTarefa,
      idTarefaAvaliacao,
      comentariosMetas,
      resultadometas,
    },
  }),

  editAvaliacaoColetivaResultadoAtitude: (
    idTarefa: number,
    idTarefaAvaliacao: number,
    resultadoatitudes: string,
    comentariosAtitudes: string
  ): TaskActionTypes => ({
    type: Types.REQUEST_TASKS_COLETIVA_RESULTADO_ATITUDE_EDIT,
    payload: {
      idTarefa,
      idTarefaAvaliacao,
      comentariosAtitudes,
      resultadoatitudes,
    },
  }),

  editAvaliacaoColetivaResultadoFinal: (
    idTarefa: number,
    idTarefaAvaliacao: number,
    idTabPerformance: number | null,
    potencial: string,
    sucessao: string | null,
    comentariosGerais: string
  ): TaskActionTypes => ({
    type: Types.REQUEST_TASKS_COLETIVA_RESULTADO_FINAL_EDIT,
    payload: {
      idTarefa,
      idTarefaAvaliacao,
      idTabPerformance,
      potencial,
      sucessao,
      comentariosGerais,
    },
  }),

  finalizaTarefaColetiva: (idTarefa: number): TaskActionTypes => ({
    type: Types.REQUEST_TASKS_COLETIVA_FINISH,
    idTarefa,
  }),

  getTarefaColetivaFeedback: (
    idTarefa: number,
    idTarefaFeedback: number
  ): TaskActionTypes => ({
    type: Types.REQUEST_TASKS_COLETIVA_FEEDBACK,
    payload: { idTarefa, idTarefaFeedback },
  }),

  finishTarefaColetivaFeedback: (
    idTarefa: number,
    idTarefaFeedback: number,
    feedbackGestor: string
  ): TaskActionTypes => ({
    type: Types.REQUEST_TASKS_COLETIVA_FEEDBACK_FINISH,
    payload: { idTarefa, idTarefaFeedback, feedbackGestor },
  }),

  finishTarefaFuncionarioFeedback: (
    idTarefa: number,
    deAcordo: string,
    comentariosDeAcordo: string
  ): TaskActionTypes => ({
    type: Types.REQUEST_TASKS_FUNCIONARIO_FEEDBACK_FINISH,
    payload: { idTarefa, deAcordo, comentariosDeAcordo },
  }),
};

export default function tasksReducer(
  state = INITIAL_STATE,
  action: TaskActionTypes | SelfEvaluationActionTypes
): DataTypes {
  switch (action.type) {
    case Types.REQUEST_TASKS_LOAD:
      return {
        ...state,
        data: [],
        loadingData: true,
        error: false,
        redirectTo: null,
      };
    case Types.SUCCESS_TASKS_LOAD:
      return {
        ...state,
        data: action.payload,
        loadingData: false,
        error: false,
        redirectTo: null,
      };
    case Types.FAILURE_TASKS_LOAD:
      customToast.error(action.message);
      return {
        ...state,
        data: [],
        loadingData: false,
        error: true,
        redirectTo: null,
      };

    case Types.REQUEST_TASKS_LOAD_ID:
      return {
        ...state,
        success: false,
        error: false,
        loadingData: true,
        redirectTo: null,
      };
    case Types.SUCCESS_TASKS_LOAD_ID:
      return {
        ...state,
        selected: action.payload,
        success: true,
        error: false,
        loadingData: false,
        redirectTo: null,
      };
    case Types.FAILURE_TASKS_LOAD_ID:
      customToast.error(action.message);
      return {
        ...state,
        success: false,
        selected: null,
        error: true,
        loadingData: false,
        redirectTo: null,
      };

    case Types.SAVE_TASK_INICIATIVAS_AUTOAVALICACAO:
      const newIniciativas: IniciativasType[] = state
        .selected!.TarefaAvaliacao!.avaliacao?.areas!.find(
          (item) =>
            item!.idCicloAvaliacaoArea! === action.payload.idCicloAvaliacaoArea
        )!
        .iniciativas.map((item, i) => ({
          ...item,
          resultado: action.payload.iniciativas[i].resultado!,
          percent: action.payload.iniciativas[i].percent ?? null,
        }));

      const real = RetornaPercentIniciativas(newIniciativas);
      const newResultado = state
        .selected!.TarefaAvaliacao!.avaliacao.areas!.find(
          (item) =>
            item.idCicloAvaliacaoArea === action.payload.idCicloAvaliacaoArea
        )!
        .resultados.map((item) => {
          if (
            item.idCicloAvaliacaoAreaResultado !==
            action.payload.idCicloAvaliacaoAreaResultado
          ) {
            return item;
          } else {
            return {
              ...item,
              real: real,
              resultado: CalculaAting(
                Number(item.meta.minimo),
                Number(item.meta.target),
                Number(item.meta.maximo),
                real,
                false
              ).avaliacao!,
              ating: CalculaAting(
                Number(item.meta.minimo),
                Number(item.meta.target),
                Number(item.meta.maximo),
                real,
                false
              ).ating!,
              percent:
                CalculaAting(
                  Number(item.meta.minimo),
                  Number(item.meta.target),
                  Number(item.meta.maximo),
                  real,
                  false
                ).ating! * item.peso,
            };
          }
        });

      const resultIniciativa = newResultado.find(
        (item) =>
          item.idCicloAvaliacaoAreaResultado ===
          action.payload.idCicloAvaliacaoAreaResultado
      );

      return {
        ...state,
        selected: {
          ...state.selected!,
          TarefaAvaliacao: {
            ...state.selected!.TarefaAvaliacao!,
            avaliacao: {
              ...state.selected?.TarefaAvaliacao?.avaliacao!,
              areas: state.selected?.TarefaAvaliacao?.avaliacao.areas!.map(
                (area) => {
                  if (
                    area.idCicloAvaliacaoArea ===
                    action.payload.idCicloAvaliacaoArea
                  ) {
                    return {
                      ...area!,
                      comentariosIniciativas: action.payload.comentarios,
                      iniciativas: newIniciativas!,
                      resultados: area.resultados.map((result) => {
                        if (
                          result.idCicloAvaliacaoAreaResultado ===
                          action.payload.idCicloAvaliacaoAreaResultado
                        ) {
                          return {
                            ...result,
                            resultado: resultIniciativa?.resultado!,
                            real: resultIniciativa?.real!,
                            percent: resultIniciativa?.percent!,
                            ating: resultIniciativa?.ating!,
                          };
                        } else {
                          return result;
                        }
                      }),
                      resultadometas: calculaResultadoFinal(newResultado)
                        .resultadoMeta,
                      somaMetas: calculaResultadoFinal(newResultado).somaMetas,
                      // resultados: newResultado
                    };
                  } else {
                    return area!;
                  }
                }
              )!,
            },
          },
        },
        redirectTo: null,
      };

    case Types.SAVE_TASK_ATITUDE_AVALIACAO:
      return {
        ...state,
        selected: {
          ...state.selected!,
          TarefaAvaliacao: {
            ...state.selected!.TarefaAvaliacao!,
            avaliacao: {
              ...state.selected?.TarefaAvaliacao?.avaliacao!,
              atitudes: state.selected!.TarefaAvaliacao!.avaliacao?.atitudes?.map(
                (item) => {
                  if (
                    item.idCicloAvaliacaoAtitude !==
                    action.payload.idCicloAvaliacaoAtitude
                  ) {
                    return item;
                  } else {
                    return {
                      ...item,
                      resultado: action.payload.resultado,
                    };
                  }
                }
              ),
            },
          },
        },
        redirectTo: null,
      };

    case Types.SAVE_TASK_ATITUDE_COMENTARIO:
      return {
        ...state,
        selected: {
          ...state.selected!,
          TarefaAvaliacao: {
            ...state.selected!.TarefaAvaliacao!,
            avaliacao: {
              ...state.selected?.TarefaAvaliacao?.avaliacao!,
              atitudes: state.selected!.TarefaAvaliacao!.avaliacao?.atitudes?.map(
                (item) => {
                  if (
                    item.idCicloAvaliacaoAtitude !==
                    action.payload.idCicloAvaliacaoAtitude
                  ) {
                    return item;
                  } else {
                    return {
                      ...item,
                      comentarios: action.payload.comentarios,
                    };
                  }
                }
              ),
            },
          },
        },
        redirectTo: null,
      };

    case Types.SAVE_TASK_ASPIRACOES_CARREIRA:
      return {
        ...state,
        redirectTo: null,
        selected: {
          ...state.selected!,
          TarefaAvaliacao: {
            ...state.selected?.TarefaAvaliacao!,
            avaliacao: {
              ...state.selected?.TarefaAvaliacao?.avaliacao!,
              aspiracoesCarreira: action.aspiracao,
            },
          },
        },
      };
    case Types.SAVE_TASK_COMENTARIOS_GERAIS:
      return {
        ...state,
        redirectTo: null,
        selected: {
          ...state.selected!,
          TarefaAvaliacao: {
            ...state.selected?.TarefaAvaliacao!,
            avaliacao: {
              ...state.selected?.TarefaAvaliacao?.avaliacao!,
              comentariosGerais: action.comentariosGerais,
            },
          },
        },
      };
    case TypesSelfEvaluation.REQUEST_SELF_EVALUATION_SEND:
      return { ...state, redirectTo: null, loadingAction: true };
    case TypesSelfEvaluation.SUCCESS_SELF_EVALUATION_SEND:
      return { ...state, redirectTo: "/workflow", loadingAction: false };
    case TypesSelfEvaluation.FAILURE_SELF_EVALUATION_SEND:
      customToast.error(action.message);
      return { ...state, redirectTo: null, loadingAction: false };

    case Types.REQUEST_TASKS_FINISH:
      return {
        ...state,
        redirectTo: null,
        loadingAction: true,
        nextTask: null,
      };
    case Types.SUCCESS_TASKS_FINISH:
      customToast.success("Tarefa finalizada com sucesso");
      return {
        ...state,
        redirectTo: "/workflow",
        loadingAction: false,
        nextTask: action?.data ?? null,
      };
    case Types.FAILURE_TASKS_FINISH:
      customToast.error(action.message);
      return {
        ...state,
        redirectTo: null,
        loadingAction: false,
        nextTask: null,
      };

    case Types.REQUEST_TASKS_ACAO_PDI_ADD:
      return {
        ...state,
        loadingAction: true,
        successAction: false,
        errorAction: false,
      };
    case Types.SUCCESS_TASKS_ACAO_PDI_ADD:
      return {
        ...state,
        selected: {
          ...state.selected!,
          TarefaPDI: {
            ...state.selected?.TarefaPDI!,
            acoes: [...state.selected?.TarefaPDI?.acoes!, action.payload].sort(
              (a, b) =>
                (a.acaoPDI.tipoPDI.idTipoPDI ?? 0) -
                (b.acaoPDI.tipoPDI.idTipoPDI ?? 0)
            ),
          },
        },
        loadingAction: false,
        successAction: true,
        errorAction: false,
      };
    case Types.FAILURE_TASKS_ACAO_PDI_ADD:
      customToast.error(action.message);
      return {
        ...state,
        loadingAction: false,
        successAction: false,
        errorAction: true,
      };

    // EDIT PDI TASK
    case Types.REQUEST_TASKS_ACAO_PDI_EDIT:
      return {
        ...state,
        loadingAction: true,
        successAction: false,
        errorAction: false,
      };
    case Types.SUCCESS_TASKS_ACAO_PDI_EDIT:
      return {
        ...state,
        selected: {
          ...state.selected!,
          TarefaPDI: {
            ...state.selected?.TarefaPDI!,
            acoes: state.selected?.TarefaPDI?.acoes.map((item) => {
              if (
                Number(item.idTarefaPDIAcao) !== action.payload.idTarefaPDIAcao
              ) {
                return item;
              } else {
                return action.payload;
              }
            })!,
          },
        },
        loadingAction: false,
        successAction: true,
        errorAction: false,
      };
    case Types.FAILURE_TASKS_ACAO_PDI_EDIT:
      customToast.error(action.message);
      return {
        ...state,
        loadingAction: false,
        successAction: false,
        errorAction: true,
      };

    // DELETE PDI TASK
    case Types.REQUEST_TASKS_ACAO_PDI_DEL:
      return {
        ...state,
        loadingAction: true,
        successAction: false,
        errorAction: false,
      };
    case Types.SUCCESS_TASKS_ACAO_PDI_DEL:
      return {
        ...state,
        selected: {
          ...state.selected!,
          TarefaPDI: {
            ...state.selected?.TarefaPDI!,
            acoes: state.selected?.TarefaPDI?.acoes.filter(
              (item) => item.idTarefaPDIAcao !== action.idTarefaPDIAcao
            )!,
          },
        },
        loadingAction: false,
        successAction: true,
        errorAction: false,
      };
    case Types.FAILURE_TASKS_ACAO_PDI_DEL:
      customToast.error(action.message);
      return {
        ...state,
        loadingAction: false,
        successAction: false,
        error: true,
      };

    // REQUEST_TASKS_DEVOLUTIVA_MERITO_SOLIC_CARTAS
    case Types.REQUEST_TASKS_DEVOLUTIVA_MERITO_SOLIC_CARTAS:
      return {
        ...state,
        loadingAction: true,
        successAction: false,
        errorAction: false,
      };
    case Types.SUCCESS_TASKS_DEVOLUTIVA_MERITO_SOLIC_CARTAS:
      return {
        ...state,
        selected: {
          ...state.selected!,
          TarefaDevMerito: state.selected?.TarefaDevMerito?.map((item) => {
            const newItem = action.payload.find(
              (newItem) => newItem.idTarefaDevMerito === item.idTarefaDevMerito
            );
            if (newItem) {
              return { ...item, ...newItem };
            }
            return item;
          }),
        },
        loadingAction: false,
        successAction: true,
        errorAction: false,
      };
    case Types.FAILURE_TASKS_DEVOLUTIVA_MERITO_SOLIC_CARTAS:
      customToast.error(action.message);
      return {
        ...state,
        loadingAction: false,
        successAction: false,
        error: true,
      };

    // REQUEST_TASKS_DEVOLUTIVA_MERITO_SOLIC_CARTAS
    case Types.REQUEST_TASKS_DEVOLUTIVA_MERITO_STATUS_CARTAS:
      return {
        ...state,
        loadingAction: true,
        successAction: false,
        errorAction: false,
      };
    case Types.SUCCESS_TASKS_DEVOLUTIVA_MERITO_STATUS_CARTAS:
      return {
        ...state,
        selected: {
          ...state.selected!,
          TarefaDevMerito: state.selected?.TarefaDevMerito?.map((item) => {
            if (item.idTarefaDevMerito === action.payload.idTarefaDevMerito) {
              return {
                ...item,
                meritoFun: {
                  ...item.meritoFun!,
                  statusCarta: action.payload.statusCarta,
                },
              };
            }
            return item;
          }),
        },
        loadingAction: false,
        successAction: true,
        errorAction: false,
      };
    case Types.FAILURE_TASKS_DEVOLUTIVA_MERITO_STATUS_CARTAS:
      customToast.error(action.message);
      return {
        ...state,
        loadingAction: false,
        successAction: false,
        error: true,
      };

    // REQUEST_TASKS_DEVOLUTIVA_MERITO_SOLIC_CARTAS
    case Types.REQUEST_TASKS_DEVOLUTIVA_MERITO_DOWNLOAD_CARTAS:
      return {
        ...state,
        loadingAction: true,
        successAction: false,
        errorAction: false,
      };
    case Types.SUCCESS_TASKS_DEVOLUTIVA_MERITO_DOWNLOAD_CARTAS:
      return {
        ...state,
        selected: {
          ...state.selected!,
          TarefaDevMerito: state.selected?.TarefaDevMerito?.map((item) => {
            if (item.idTarefaDevMerito == action.idTarefaDevMerito) {
              return {
                ...item,
                flagBaixouCarta: true,
              };
            }
            return item;
          }),
        },
        loadingAction: false,
        successAction: true,
        errorAction: false,
      };
    case Types.FAILURE_TASKS_DEVOLUTIVA_MERITO_DOWNLOAD_CARTAS:
      customToast.error(action.message);
      return {
        ...state,
        loadingAction: false,
        successAction: false,
        error: true,
      };

    // REQUEST_TASKS_DEVOLUTIVA_REALIZAR
    case Types.REQUEST_TASKS_DEVOLUTIVA_REALIZAR:
      return {
        ...state,
        loadingAction: true,
        successAction: false,
        errorAction: false,
      };
    case Types.SUCCESS_TASKS_DEVOLUTIVA_REALIZAR:
      return {
        ...state,
        selected: {
          ...state.selected!,
          TarefaDevMerito: state.selected?.TarefaDevMerito?.map((item) => {
            if (item.idTarefaDevMerito === action.payload.idTarefaDevMerito) {
              return { ...item, ...action.payload.dados };
            }
            return item;
          }),
          habilitaFinalizarTarefa: action.payload.habilitaFinalizarTarefa,
        },
        loadingAction: false,
        successAction: true,
        errorAction: false,
      };
    case Types.FAILURE_TASKS_DEVOLUTIVA_REALIZAR:
      customToast.error(action.message);
      return {
        ...state,
        loadingAction: false,
        successAction: false,
        error: true,
      };

    // REQUEST_TASKS_DEVOLUTIVA_CANCELAR
    case Types.REQUEST_TASKS_DEVOLUTIVA_CANCELAR:
      return {
        ...state,
        loadingAction: true,
        successAction: false,
        errorAction: false,
      };
    case Types.SUCCESS_TASKS_DEVOLUTIVA_CANCELAR:
      return {
        ...state,
        selected: {
          ...state.selected!,
          TarefaDevMerito: state.selected?.TarefaDevMerito?.map((item) => {
            if (item.idTarefaDevMerito === action.payload.idTarefaDevMerito) {
              return { ...item, ...action.payload.dados };
            }
            return item;
          }),
          habilitaFinalizarTarefa: action.payload.habilitaFinalizarTarefa,
        },
        loadingAction: false,
        successAction: true,
        errorAction: false,
      };
    case Types.FAILURE_TASKS_DEVOLUTIVA_CANCELAR:
      customToast.error(action.message);
      return {
        ...state,
        loadingAction: false,
        successAction: false,
        error: true,
      };

    case Types.RESET_TASK:
      return { ...state, selected: null };

    case Types.REQUEST_TASKS_ACAO_PDI_SAVE:
      return { ...state, loadingSave: true };
    case Types.SUCCESS_TASKS_ACAO_PDI_SAVE:
      customToast.success("Tarefa salva com sucesso");
      return { ...state, loadingSave: false };
    case Types.FAILURE_TASKS_ACAO_PDI_SAVE:
      customToast.error(action.message);
      return { ...state, loadingSave: false };

    case Types.FILTERS_TASK_CLEAR:
      return {
        ...state,
        filter: defaultFilters,
      };

    case Types.FILTERS_TASK_EDIT:
      return {
        ...state,
        filter: action.payload,
      };

    case Types.SEARCH_TEXT_TASK_EDIT:
      return {
        ...state,
        searchText: action.payload,
      };

    case Types.REQUEST_TASKS_COLETIVA_INFO_AVALIACAO:
      return {
        ...state,
        selectedColetiva: null,
        loadingAction: true,
      };

    case Types.SUCCESS_TASKS_COLETIVA_INFO_AVALIACAO:
      return {
        ...state,
        selectedColetiva: action.payload,
        loadingAction: false,
      };

    case Types.FAILURE_TASKS_COLETIVA_INFO_AVALIACAO:
      customToast.error(action.message);
      return {
        ...state,
        selectedColetiva: null,
        loadingAction: false,
      };

    //REQUEST_TASKS_COLETIVA_RESULTADO_META_EDIT
    case Types.REQUEST_TASKS_COLETIVA_RESULTADO_META_EDIT:
    case Types.REQUEST_TASKS_COLETIVA_RESULTADO_ATITUDE_EDIT:
    case Types.REQUEST_TASKS_COLETIVA_RESULTADO_FINAL_EDIT:
      return {
        ...state,
        loadingSave: true,
        successAction: false,
      };

    case Types.SUCCESS_TASKS_COLETIVA_RESULTADO_META_EDIT:
    case Types.SUCCESS_TASKS_COLETIVA_RESULTADO_ATITUDE_EDIT:
    case Types.SUCCESS_TASKS_COLETIVA_RESULTADO_FINAL_EDIT:
      return {
        ...state,
        selected: {
          ...state.selected!,
          TarefaAvaliacaoColetiva: {
            ...state.selected!.TarefaAvaliacaoColetiva!,
            resumo: action.payload.avaliacao.resumo,
            funcionarios: state.selected!.TarefaAvaliacaoColetiva!.funcionarios.map(
              (func) => {
                if (func.idTarefaAvaliacao === action.payload.idTarefaAvaliacao)
                  return {
                    ...func,
                    avaliacao: {
                      ...func.avaliacao,
                      ...action.payload.avaliacao.dados,
                      funcionario: func.avaliacao.funcionario,
                      performancegestor: action.payload.avaliacao.avaliacao
                        ? {
                          descNivel:
                            action.payload.avaliacao.avaliacao.descNivel,
                        }
                        : null,
                    },
                  };

                return func;
              }
            ),
          },
        },
        loadingSave: false,
        successAction: true,
      };

    case Types.FAILURE_TASKS_COLETIVA_RESULTADO_META_EDIT:
    case Types.FAILURE_TASKS_COLETIVA_RESULTADO_ATITUDE_EDIT:
    case Types.FAILURE_TASKS_COLETIVA_RESULTADO_FINAL_EDIT:
      customToast.error(action.message);
      return {
        ...state,
        loadingSave: false,
        successAction: false,
      };

    case Types.REQUEST_TASKS_COLETIVA_FINISH:
      return {
        ...state,
        loadingAction: true,
        redirectTo: null,
      };
    case Types.SUCCESS_TASKS_COLETIVA_FINISH:
      customToast.success("Tarefa finalizada com sucesso");
      return {
        ...state,
        loadingAction: false,
        redirectTo: "/workflow",
        selected: {
          ...state.selected!,
          statusTarefa: 'f',
          flagFinalizada: true,
          flagEdicao: false,
        },
      };
    case Types.FAILURE_TASKS_COLETIVA_FINISH:
      customToast.error(action.message);
      return {
        ...state,
        redirectTo: null,
        loadingAction: false,
      };

    case Types.REQUEST_TASKS_COLETIVA_FEEDBACK:
      return {
        ...state,
        loadingData: true,
        selectedColetivaFeedback: null,
      };
    case Types.SUCCESS_TASKS_COLETIVA_FEEDBACK:
      return {
        ...state,
        loadingData: false,
        selectedColetivaFeedback: action.payload,
      };
    case Types.FAILURE_TASKS_COLETIVA_FEEDBACK:
      customToast.error(action.message);
      return {
        ...state,
        loadingData: false,
      };

    //REQUEST_TASKS_COLETIVA_FEEDBACK_FINISH
    case Types.REQUEST_TASKS_COLETIVA_FEEDBACK_FINISH:
      return {
        ...state,
        loadingAction: true,
        redirectTo: null,
      };
    case Types.SUCCESS_TASKS_COLETIVA_FEEDBACK_FINISH:
      return {
        ...state,
        loadingAction: false,
        redirectTo: `/tarefas/${action.idTarefa}`,
      };
    case Types.FAILURE_TASKS_COLETIVA_FEEDBACK_FINISH:
      customToast.error(action.message);
      return {
        ...state,
        loadingAction: false,
        redirectTo: null,
      };

    //REQUEST_TASKS_FUNCIONARIO_FEEDBACK_FINISH
    case Types.REQUEST_TASKS_FUNCIONARIO_FEEDBACK_FINISH:
      return {
        ...state,
        loadingAction: true,
        redirectTo: null,
      };
    case Types.SUCCESS_TASKS_FUNCIONARIO_FEEDBACK_FINISH:
      customToast.success("Resposta do feedback enviado com sucesso!");
      return {
        ...state,
        loadingAction: false,
      };
    case Types.FAILURE_TASKS_COLETIVA_FEEDBACK_FINISH:
      customToast.error(action.message);
      return {
        ...state,
        loadingAction: false,
      };

    default:
      return state;
  }
}

function RetornaPercentIniciativas(iniciativas: IniciativasType[]) {
  let totalPercent = 0;

  for (var iniciativa of iniciativas) {
    let fator = 0;

    if (iniciativa.resultado === "Parcialmente atingido") {
      fator = 50;
    } else if (iniciativa.resultado === "Atingido") {
      fator = 100;
    } else if (iniciativa.resultado === "Superado") {
      fator = 150;
    }

    iniciativa.colaboradoriniciativa.peso = Number(
      iniciativa.colaboradoriniciativa.peso
    );

    iniciativa.percent = (iniciativa.colaboradoriniciativa.peso * fator) / 100;

    totalPercent = totalPercent + iniciativa.percent;
  }

  return totalPercent;
}

// interface CalcAtingType {
//   ating: number;
//   avaliacao: string | null;
// }

// const calculaAting = (minimo: number, target: number, maximo: number, resultado: number, flagInativo: boolean) => {
//   let Retorno: CalcAtingType = {
//     ating: 0,
//     avaliacao: null
//   }
//   minimo = Number(minimo);
//   target = Number(target);
//   maximo = Number(maximo);
//   resultado = Number(resultado);

//   if (flagInativo)
//     return Retorno;

//   if (minimo < maximo) {
//     // quanto maior melhor
//     if (resultado >= maximo) {
//       //superado

//       Retorno = { ating: 1.5, avaliacao: 'Superado' }
//       console.log(Retorno)
//     } else if (resultado < minimo) {
//       //não atingido
//       Retorno = { ating: 0, avaliacao: 'Não atingido' }
//     } else if (resultado < target) {
//       //parcialmente atingido
//       let DifResultTarget = resultado - target;
//       let DifMinimoTarget = minimo - target;

//       let Divisao = (DifResultTarget / DifMinimoTarget);
//       let DistanciaPercent = 1 - 1.5;
//       let ResultDivisao = Divisao * DistanciaPercent;
//       Retorno = { ating: 1 - ResultDivisao, avaliacao: 'Parcialmente atingido' }
//     }
//     else {
//       let DifResultTarget = resultado - target;
//       let DifMinimoTarget = maximo - target;

//       let Divisao = (DifResultTarget / DifMinimoTarget);
//       let DistanciaPercent = 1.5 - 1;

//       let ResultDivisao = Divisao * DistanciaPercent;

//       if (resultado == target)
//         Retorno = { ating: 1, avaliacao: 'Atingido' }
//       else
//         Retorno = { ating: 1 + ResultDivisao, avaliacao: 'Superado' }
//     }
//   } else {
//     //quanto menor melhor
//     if (resultado <= maximo) {
//       Retorno = { ating: 1.5, avaliacao: 'Superado' }
//     } else if (resultado > minimo) {
//       Retorno = { ating: 0, avaliacao: 'Não atingido' }
//     } else if (resultado > target) {
//       //parcialmente atingido
//       let DifResultTarget = resultado - target;
//       let DifMinimoTarget = minimo - target;

//       let Divisao = (DifResultTarget / DifMinimoTarget);
//       let DistanciaPercent = 1 - 1.5;
//       let ResultDivisao = Divisao * DistanciaPercent;
//       Retorno = { ating: 1 - ResultDivisao, avaliacao: 'Parcialmente atingido' }
//     }
//     else {
//       let DifResultTarget = resultado - target;
//       let DifMinimoTarget = maximo - target;

//       let Divisao = (DifResultTarget / DifMinimoTarget);
//       let DistanciaPercent = 1.5 - 1;

//       let ResultDivisao = Divisao * DistanciaPercent;

//       if (resultado == target)
//         Retorno = { ating: 1, avaliacao: 'Atingido' }
//       else
//         Retorno = { ating: 1 + ResultDivisao, avaliacao: 'Superado' }
//     }
//   }
//   return Retorno;
// }

const calculaResultadoFinal = (resultadosOriginais: ResultadosType[]) => {
  let somaMetas: number = 0;
  let resultadoMeta = null;

  for (var item of resultadosOriginais)
    somaMetas = somaMetas + Number(item.percent);

  if (somaMetas < 95) resultadoMeta = "Abaixo da Expectativa";
  else if (somaMetas > 105) resultadoMeta = "Acima da Expectativa";
  else resultadoMeta = "Dentro da Expectativa";

  //#region Avaliacoes
  return {
    somaMetas,
    resultadoMeta,
  };
};
