import { ICicloPesosPerfil } from '../profile/types';
/**
 * Types
 */
export enum Types {
    SET_CURRENT_TAB = "solicitation/SET_CURRENT_TAB",
    // GET
    REQUEST_SOLICITATION_LOAD = "solicitation/REQUEST_SOLICITATION_LOAD",
    SUCCESS_SOLICITATION_LOAD = "solicitation/SUCCESS_SOLICITATION_LOAD",
    FAILURE_SOLICITATION_LOAD = "solicitation/FAILURE_SOLICITATION_LOAD",
    // PUT - FLAG ACEITE
    REQUEST_SOLICITATION_LOAD_BY_ID = "solicitation/REQUEST_SOLICITATION_LOAD_BY_ID",
    SUCCESS_SOLICITATION_LOAD_BY_ID = "solicitation/SUCCESS_SOLICITATION_LOAD_BY_ID",
    FAILURE_SOLICITATION_LOAD_BY_ID = "solicitation/FAILURE_SOLICITATION_LOAD_BY_ID",
    // GET APPROVALS
    REQUEST_LOAD_APPROVALS = "solicitation/REQUEST_LOAD_APPROVALS",
    SUCCESS_LOAD_APPROVALS = "solicitation/SUCCESS_LOAD_APPROVALS",
    FAILURE_LOAD_APPROVALS = "solicitation/FAILURE_LOAD_APPROVALS",
    // APROVAR
    REQUEST_APPROVAL_SOLICITATION = "solicitation/REQUEST_APPROVAL_SOLICITATION",
    SUCCESS_APPROVAL_SOLICITATION = "solicitation/SUCCESS_APPROVAL_SOLICITATION",
    FAILURE_APPROVAL_SOLICITATION = "solicitation/FAILURE_APPROVAL_SOLICITATION",
    // REPROVAR
    REQUEST_DISAPPROVAL_SOLICITATION = "solicitation/REQUEST_DISAPPROVAL_SOLICITATION",
    SUCCESS_DISAPPROVAL_SOLICITATION = "solicitation/SUCCESS_DISAPPROVAL_SOLICITATION",
    FAILURE_DISAPPROVAL_SOLICITATION = "solicitation/FAILURE_DISAPPROVAL_SOLICITATION",

};


interface ISolicitationState {
    readonly data: ISolicitationType[];
    readonly metaSolicitationSelected: IMetaSolicitation | null;
    readonly success: boolean;
    readonly error: boolean;
    readonly loading: boolean;
    readonly currentTab: string;
    readonly canRedirect: boolean;
};

export interface IMetaSolicitation {
    idSolicitacao: number;
    tituloSolicitacao: string;
    status: string;
    dataExpiracao: Date;
    idFuncionario: number;
    idFuncionarioOwner: number;
    tipoSolicitacao: string;
    dataHoraFinalizada: Date | null;
    dataHoraAprovacao: Date | null;
    motivoCancelamento: string | null;
    createdAt: Date;
    FlagFinalizado: boolean;
    FlagPermiteAprovar: boolean;
    FlagPermiteCancelar: boolean;
    Area: {
        nomeLocal: string;
    };
    FuncionarioOwner: {
        idFuncionario: number;
        matricula: string;
        nomeCompleto: string;
        status: string;
    }
    solicitacaoMeta: ISolicitacaoMetaType;
}

export interface ISolicitacaoMetaType {
    idSolicitacaoMeta: number;
    idSolicitacao: number;
    idFuncionario: number;
    idFuncionarioOwner: number;
    idCicloColaborador: number;
    idCiclo: number;
    idArea: number;
    idVaga: number;
    ciclo: {
        ano: number;
    };
    cicloColaborador: {
        idCC: number;
        idUnidade: number;
        cc: {
            nomeCC: string;
        };
        unidade: {
            nomeUnidade: string;
        };
    };
    solMetaPeso: ISolicitacaoMetaPesoType[];
    solicitacaoMetaProjeto: ISolicitacaoMetaProjetoType[];

}

export interface ISolicitacaoMetaProjetoType {
    idCicloMetaProjeto: number;
    nomeProjeto: string;
    descProjeto: string;
    peso: string | number;
    prazoEntrega: Date;
}

interface ISolicitacaoMetaPesoType {
    idSolicitacaoMetaPeso: number;
    metaColab: ICicloPesosPerfil;
}

export interface ISolicitationType {
    idSolicitacao: number | null;
    tituloSolicitacao: string;
    status: string;
    dataExpiracao: Date;
    idFuncionario: number;
    idFuncionarioOwner: number;
    tipoSolicitacao: string;
    dataHoraFinalizada: Date;
    dataHoraAprovacao: Date;
    motivoCancelamento: string;
};

export type DataTypes = ISolicitationState;


/**
 * Actions
 */

//GET

interface ISetCurrentTab {
    type: typeof Types.SET_CURRENT_TAB,
    indexTab: string;
}

export interface IRequestSolicitationsAction {
    type: typeof Types.REQUEST_SOLICITATION_LOAD;
    payload: {
        searchText: string;
        status?: string[];
    }
};

export interface ISuccessSolicitationsAction {
    type: typeof Types.SUCCESS_SOLICITATION_LOAD;
    payload: ISolicitationType[];
};

export interface IFailureSolicitationsAction {
    type: typeof Types.FAILURE_SOLICITATION_LOAD;
    message: string;
};

type GetSolicitationsAction = IRequestSolicitationsAction | ISuccessSolicitationsAction | IFailureSolicitationsAction;

//GET BY ID

export interface IRequestSolicitationsByIdAction {
    type: typeof Types.REQUEST_SOLICITATION_LOAD_BY_ID;
    id: number;
};

export interface ISuccessSolicitationsByIdAction {
    type: typeof Types.SUCCESS_SOLICITATION_LOAD_BY_ID;
    payload: IMetaSolicitation;
};

export interface IFailureSolicitationsByIdAction {
    type: typeof Types.FAILURE_SOLICITATION_LOAD_BY_ID;
    message: string;
};

type GetSolicitationsByIdAction = IRequestSolicitationsByIdAction | ISuccessSolicitationsByIdAction | IFailureSolicitationsByIdAction;

// APPROVAL SOLICITATION

export interface IRequestApprovalSolicitationAction {
    type: typeof Types.REQUEST_APPROVAL_SOLICITATION;
    payload: {
        idSolicitacao: number;
        motivoCancelamento: string | null;
    }
};

export interface ISuccessApprovalSolicitationAction {
    type: typeof Types.SUCCESS_APPROVAL_SOLICITATION;
    payload: {
        idSolicitacao: number;
        status: string;
        motivoCancelamento: string | null;
    }
};

export interface IFailureApprovalSolicitationAction {
    type: typeof Types.FAILURE_APPROVAL_SOLICITATION;
    message: string;
};

type ApprovalSolicitationAction = IRequestApprovalSolicitationAction | ISuccessApprovalSolicitationAction | IFailureApprovalSolicitationAction;

// APPROVALS

export interface IRequestLoadApprovalsAction {
    type: typeof Types.REQUEST_LOAD_APPROVALS;
    payload: {
        searchText: string;
        status?: string[];
    }
};
export interface ISuccessLoadApprovalsAction {
    type: typeof Types.SUCCESS_LOAD_APPROVALS;
    payload: ISolicitationType[];
};

export interface IFailureLoadApprovalsAction {
    type: typeof Types.FAILURE_LOAD_APPROVALS;
    message: string;
};

type LoadApprovalsAction = IRequestLoadApprovalsAction | ISuccessLoadApprovalsAction | IFailureLoadApprovalsAction;

//  DISAPPROVAL

export interface IRequestDisapprovalsAction {
    type: typeof Types.REQUEST_DISAPPROVAL_SOLICITATION;
    payload: {
        idSolicitacao: number;
        motivoCancelamento: string | null;
    }
};
export interface ISuccessDisapprovalsAction {
    type: typeof Types.SUCCESS_DISAPPROVAL_SOLICITATION;
    payload: {
        idSolicitacao: number;
        motivoCancelamento: string | null;
        status: string;
    }
};

export interface IFailureDisapprovalsAction {
    type: typeof Types.FAILURE_DISAPPROVAL_SOLICITATION;
    message: string;
};

type LoadDisapprovalsAction = IRequestDisapprovalsAction | ISuccessDisapprovalsAction | IFailureDisapprovalsAction;


export type SolicitationsActionTypes =
    ISetCurrentTab |
    GetSolicitationsAction |
    GetSolicitationsByIdAction |
    ApprovalSolicitationAction |
    LoadApprovalsAction |
    LoadDisapprovalsAction;
