import { takeEvery, put, call, all } from "redux-saga/effects";
import {
    Types,
    IRequestCyclePerformanceLoadAction,
    IRequestCyclePerformanceAddAction,
    IRequestCyclePerformanceDelAction,
    IRequestCyclePerformanceEditAction,
    ISuccessCyclePerformanceLoadAction,
    ISuccessCyclePerformanceAddAction,
    ISuccessCyclePerformanceDelAction,
    ISuccessCyclePerformanceEditAction
} from "../ducks/cycles/performance/types";
import api from "~/services/api";


const apiCallGet = (idCiclo: number) => {
    return api
        .get(`ciclos/${idCiclo}/performance`)
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            throw err.response;
        });
};

const apiCallAdd = (payload: IRequestCyclePerformanceAddAction["payload"]) => {
    return api
        .post(`ciclos/${payload.idCiclo}/performance`, {
            ...payload
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            throw err.response;
        });
};

const apiCallEdit = (payload: IRequestCyclePerformanceEditAction["payload"]) => {
    return api
        .put(`ciclos/${payload.idCiclo}/performance/${payload.performance.idTabPerformance}`, {
            ...payload.performance
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            throw err.response;
        });
};

const apiCallDel = (payload: IRequestCyclePerformanceDelAction["payload"]) => {
    return api
        .delete(`ciclos/${payload.idCiclo}/performance/${payload.idTabPerformance}`)
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            throw err.response;
        });
};

function* load(action: IRequestCyclePerformanceLoadAction) {
    try {
        let items: any = yield call(apiCallGet, action.idCiclo);
        yield put({
            type: Types.SUCCESS_CYCLE_PERFORMANCE_LOAD,
            payload: items
        } as ISuccessCyclePerformanceLoadAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível carregar as performances'
        yield put({
            type: Types.FAILURE_CYCLE_PERFORMANCE_LOAD,
            message
        });
    }
}

function* add(action: IRequestCyclePerformanceAddAction) {
    try {
        let idTabPerformance: any = yield call(apiCallAdd, action.payload);
        yield put({
            type: Types.SUCCESS_CYCLE_PERFORMANCE_ADD,
            payload: {...action.payload, ...idTabPerformance}
        } as ISuccessCyclePerformanceAddAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível adicionar a performance'
        yield put({
            type: Types.FAILURE_CYCLE_PERFORMANCE_ADD,
            message
        });
    }
}

function* edit(action: IRequestCyclePerformanceEditAction) {
    try {
        yield call(apiCallEdit, action.payload);
        yield put({
            type: Types.SUCCESS_CYCLE_PERFORMANCE_EDIT,
            payload: action.payload.performance
        } as ISuccessCyclePerformanceEditAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível editar a performance'
        yield put({
            type: Types.FAILURE_CYCLE_PERFORMANCE_EDIT,
            message
        });
    }
}

function* del(action: IRequestCyclePerformanceDelAction) {
    try {
        yield call(apiCallDel, action.payload);
        yield put({
            type: Types.SUCCESS_CYCLE_PERFORMANCE_DEL,
            idTabPerformance: action.payload.idTabPerformance
        } as ISuccessCyclePerformanceDelAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível excluir a performance'
        yield put({
            type: Types.FAILURE_CYCLE_PERFORMANCE_DEL,
            message
        });
    }
}

export default function* companiesRoot() {
    yield all([
        yield takeEvery(Types.REQUEST_CYCLE_PERFORMANCE_LOAD, load),
        yield takeEvery(Types.REQUEST_CYCLE_PERFORMANCE_ADD, add),
        yield takeEvery(Types.REQUEST_CYCLE_PERFORMANCE_EDIT, edit),
        yield takeEvery(Types.REQUEST_CYCLE_PERFORMANCE_DEL, del),
    ]);
}
