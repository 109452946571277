import React, { useEffect, useState } from 'react';

import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { CommandBarButton, Stack } from 'office-ui-fabric-react';

import { SelectInput, WrapperField, WrapperMenuDropdown, WrapperMenu, Overlay } from './styles';
import { SelectProps } from '@material-ui/core/Select';
import colors from "~/assets/js/colors"

interface IProps extends SelectProps {
    errors?: any;
    values?: string | number | null;
    handleChange?: any;
    name: string;
    label: string;
    helperText?: string;
    errorText?: string;
    noHelperText?: boolean;
    variant?: "filled" | "outlined" | "standard";
    styles?: React.CSSProperties;
    fieldHeight?: number;
    customPadding?: string;
    autofocus?: boolean;
    disabled?: boolean;
    stylesLabel?: React.CSSProperties;
    size?: 'small',
    defaultValue?: string;
    paddingSelected?: string;
    endAdornment?: React.ReactNode;
}

const Dropdown: React.FC<IProps> = props => {
    const stylesSmall: React.CSSProperties = {
        position: 'absolute',
        top: 0,
        left: 15,
        padding: '0 2px',
        backgroundColor: 'white',
        zIndex: 9,
        width: 'fit-content',
        color: props?.errors ? colors?.error : ""
    }

    const styleNormal: React.CSSProperties = {
        color: props?.errors ? colors?.error : ""
    }

    return (
        <WrapperField height={props.size ? 40 : props.fieldHeight} size={props?.size} padding_selected={props?.paddingSelected}>
            <InputLabel id="idLabel" style={props?.size === 'small' ? stylesSmall : styleNormal}>{props.label}</InputLabel>
            <SelectInput
                variant={props.variant}
                id={props.name}
                defaultValue={props.defaultValue}
                error={props.errors ? true : false}
                labelId="idLabel"
                name={props.name}
                value={props.values}
                onChange={props.handleChange}
                style={props?.size === 'small' ? {
                    height: 40,
                    ...props.styles
                }
                    :
                    props.styles
                }
                custompadding={props.customPadding}
                autoFocus={props.autofocus}
                disabled={props.disabled}
                endAdornment={props.endAdornment}
            >
                {props.children}
            </SelectInput>
            {
                !props.noHelperText &&
                <FormHelperText style={{ color: props.errors ? colors.error : "" }}>{!props.errors ? props.helperText : props.errorText}</FormHelperText>
            }
        </WrapperField >
    );
}

interface IMenuDropdown {
    close?: boolean;
}

export const MenuDropdown: React.FC<IMenuDropdown> = (props) => {
    const { close } = props;
    const [isOpen, setIsOpen] = useState(false);
    const [top, setTop] = useState(0);

    const offset = (el: any) => {
        var rect = el.getBoundingClientRect(),
            scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
            scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        const heightDoc = window.innerHeight;
        return { top: rect.top + scrollTop, left: rect.left + scrollLeft, bottom: heightDoc - rect.bottom }
    }

    useEffect(() => {
        setIsOpen(false)
    }, [close])

    useEffect(() => {
        const menuHeight = document.getElementById('wrapper-menu')?.getBoundingClientRect().height!
        const diff = (offset(document.getElementById('button-filter')).bottom - menuHeight) + 20;
        if (diff < 0) {
            setTop(diff)
        } else {
            setTop(0)
        }
    }, [isOpen])

    return (
        <div>
            <Overlay onClick={() => setIsOpen(false)} isOpen={isOpen} />
            <WrapperMenuDropdown>
                <Stack horizontal>
                    <CommandBarButton
                        iconProps={{ iconName: 'Filter' }}
                        text="Filtrar"
                        styles={{ root: { height: 44 } }}
                        onClick={() => setIsOpen(true)}
                        id="button-filter"
                    />
                </Stack>
                <WrapperMenu isOpen={isOpen} id="wrapper-menu" top={top}>
                    {props.children}
                </WrapperMenu>
            </WrapperMenuDropdown>
        </div>
    )
}

// export const MenuDropdown: React.FC<IMenuDropdown> = (props) => {
//     const { onRenderMenu, close } = props;
//     const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

//     useEffect(() => {
//         setAnchorEl(null);
//     }, [close])

//     const handleClick = (event: React.MouseEvent<HTMLElement>) => {
//         setAnchorEl(event.currentTarget);
//     };

//     const handleClose = () => {
//         setAnchorEl(null);
//     };
//     return (
//         <>
//             <CommandBarButton id="menuDropdown" iconProps={{ iconName: 'Filter' }} styles={{ root: { height: 44 } }} text="Filtro" onClick={handleClick} />
//             {/* <StyledMenu
//                 id="customized-menu"
//                 anchorEl={anchorEl}
//                 keepMounted
//                 open={Boolean(anchorEl)}
//                 onClose={handleClose}
//             >
//                 <WrapperContent>
//                     {onRenderMenu}
//                 </WrapperContent>
//             </StyledMenu> */}
//         </>
//     )
// }


// const StyledMenu = withStyles({
//     paper: {
//         boxShadow: 'rgb(0 0 0 / 13%) 0px 3.2px 7.2px 0px, rgb(0 0 0 / 11%) 0px 0.6px 1.8px 0px',
//         padding: 15,
//         boxSizing: 'border-box',
//         borderRadius: 2,
//         width: 380,
//         outline: 'none'
//     },
// })((props: MenuProps) => (
//     <Menu
//         elevation={0}
//         getContentAnchorEl={null}
//         anchorOrigin={{
//             vertical: 'bottom',
//             horizontal: 'center',
//         }}
//         transformOrigin={{
//             vertical: 'top',
//             horizontal: 'center',
//         }}
//         {...props}
//     />
// ));

export default Dropdown;