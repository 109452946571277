// #region Imports
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter, RouteComponentProps } from "react-router-dom";

import { Formik } from "formik";
import * as yup from "yup";

//FluentUI
import InputLabel from "@material-ui/core/InputLabel";
import { Stack } from "office-ui-fabric-react/lib/Stack";
import {
  DetailsListLayoutMode,
  SelectionMode,
  Selection,
  IColumn,
  IDetailsListProps,
  IDetailsRowStyles,
  DetailsRow,
  ConstrainMode,
} from "office-ui-fabric-react/lib/DetailsList";
import { ShimmeredDetailsList } from "office-ui-fabric-react/lib/ShimmeredDetailsList";
import {
  CommandBarButton,
  FontIcon,
  IconButton,
  IPivotStyles,
  Label,
  Persona,
  PersonaPresence,
  PersonaSize,
  Pivot,
  Text,
  HoverCard,
  HoverCardType,
  IHoverCard,
  IHoverCardStyles,
  IButtonStyles,
  TooltipHost,
  ITooltipHostStyles,
  IDialogContentProps,
  DefaultButton,
} from "office-ui-fabric-react";
import { Input, ListItemText, Select, TextField } from "@material-ui/core";

import CustomDialog from "~/components/CustomDialog";
import { Page, BreadcrumbItems } from "~/store/ducks/home/types";
import {
  InputAutocomplete,
  InputCheckbox,
  InputNumber,
  InputText,
} from "~/components/Forms";

import NoItems from "~/components/layout/NoItems";
//Estilos
import {
  Wrapper,
  ListContainer,
  ContainerContent,
  Comentario,
  WrapperPersonaInfo,
  WrapperAutoComplete,
  Legenda,
  GridPositionFields
} from "./styles";
import { AttitudeType } from "~/store/ducks/attitudes/types";
import { RootState } from "~/store/ducks";
import PainelHeader from "~/components/layout/PainelHeader";
import {
  MessageBar,
  MessageBarType,
  PivotItem,
  ScrollablePane,
} from "@fluentui/react";
import Accordion from "~/components/Accordion";
import Card from "~/components/Card";
import Colors from "~/assets/js/colors";
import DialogComentario from "~/components/DialogComentario";
import moment from "moment";
import {
  Checkbox,
  FormControlLabel,
  FormControl,
  MenuItem,
} from "@material-ui/core";

import SearchPosition from "../Admin/Positions/SearchPosition";
import Dropdown, { MenuDropdown } from "~/components/layout/Dropdown";
import { getAbbreviatedName } from "~/utils/abbreviatedName";
import { arredondaSalario, toCurrency, toNumber } from "~/utils/numbers";
import {
  GroupItemDoisTerco,
  GroupItemFull,
  GroupItems,
  GroupItemUmTerco,
  TitleField,
} from "~/components/FormGroup";
import { ModalEdit } from "~/components/CustomDialog";
import * as CustomDialogFluentUI from "~/components/CustomDialogFluentUI";
import { DeleteButton, PrimaryButton } from "~/components/Buttons";

import {
  DataTypes as DataTypesMerit,
  IRequestMeritAtualizaPromocaoAction,
  MeritEmployee,
  Budget
} from "~/store/ducks/merit/types";
import { Creators as getMeritById } from "~/store/ducks/merit";
import { Creators as editMerit } from "~/store/ducks/merit";
import { Creators as atualizaPercentual } from "~/store/ducks/merit";
import { Creators as atualizaPromocao } from "~/store/ducks/merit";
import { Creators as atualizaSalario } from "~/store/ducks/merit";
import { Creators as atualizaComentarios } from "~/store/ducks/merit";
import { Creators as finalizar } from "~/store/ducks/merit";
import { Creators as reativar } from "~/store/ducks/merit";
import { Creators as aplicar } from "~/store/ducks/merit";
import { Creators as ignorar } from "~/store/ducks/merit";
import { Creators as solicitarAprovacao } from "~/store/ducks/merit";
import { Creators as getBudgets } from "~/store/ducks/merit";
import { Creators as editBudget } from "~/store/ducks/merit";
import { Creators as getSaldo } from "~/store/ducks/merit";
import { Creators as aprovarMerito } from "~/store/ducks/merit";
import { Creators as reprovarMerito } from "~/store/ducks/merit";
import { Creators as finalizarReuniao } from "~/store/ducks/merit";
import { Creators as reabrirReuniao } from "~/store/ducks/merit";
import { Creators as listarCargos } from "~/store/ducks/merit";
import { Creators as listarSalarios } from "~/store/ducks/merit";
import { Creators as listarAvaliacoes } from "~/store/ducks/merit";
import { Creators as listarOcorrencias } from "~/store/ducks/merit";
import { Creators as clearListaCargos } from "~/store/ducks/merit";
import { Creators as clearListaSalarios } from "~/store/ducks/merit";
import { Creators as clearListaAvaliacoes } from "~/store/ducks/merit";
import { Creators as clearListaOcorrencias } from "~/store/ducks/merit";

import { Creators as downloadCarta } from "~/store/ducks/merit";
import { Creators as getInfoSalarial } from "~/store/ducks/admin/salaryStructure";

import { Creators as getCC } from "~/store/ducks/cc";
import { CCType, DataTypes as DataTypesCC } from "~/store/ducks/cc/types";

import { Creators as getCategoriesPositions } from "~/store/ducks/admin/categoriesPositions";
import { DataTypes as DataTypesCatogories } from "~/store/ducks/admin/categoriesPositions/types";

import { Creators as getPositions } from "~/store/ducks/admin/positions";

import {
  PositionTypes,
  DataTypes as DataTypesPosition,
} from "~/store/ducks/admin/positions/types";

import {
  ChartType,
  DataTypes as DataTypesChart,
} from "~/store/ducks/admin/chart/types";
import { Creators as getChart } from "~/store/ducks/admin/chart";

import { Creators as setCurrentPage } from "~/store/ducks/home";
import TreeView from "~/components/TreeView";
import customToast from "~/components/Toast";
import Resume from "./Resume";
import GridOcorrencias from "./Grid/Ocorrencias";
import GridAvaliacoes from "./Grid/Avaliacoes";
import GridSalarios from "./Grid/Salarios";
import GridBudget from "./Grid/Budget";
import GridCargos from "./Grid/Cargos";

const baseURL = process.env.REACT_APP_BASE_URL;

//#endregion

interface IMeritState {
  columns: IColumn[];
  items: MeritEmployee[];
  isFiltering: boolean;
  selectionDetails: string;
  comentarioEdit: {
    isOpen: boolean;
    comentario: string;
  };
  showBudget: boolean;
  isSearchPositionOpen: boolean;
  isSearchPositionFormOpen: boolean;
  meritoFuncionarioSelected: MeritEmployee | null;
  search: string;
  isOpenFilter: boolean;
  isOpenHistory: boolean;
  editSalario: {
    isOpen: boolean;
    funcionario: {
      idMeritoFuncionario: number;
      nome: string;
    } | null;
    salario: {
      salarioAtual: number;
      salarioNovo: number | null;
      reajuste: number | null;
      faixa: number | null;
      valorMedio: number | null;
    } | null;
  };
  filter: {
    gestor: string;
    cargo: string;
    area: Partial<ChartType> | null;
    tempo: string;
    categoriasCargos: number[];
    idTabPerformance: string;
    search: string;
    cc: Partial<CCType> | null;
    flagElegivel: "Sim" | "Nao" | "Todos";
  };
  budgetFiltered: {
    budgetAprovado: number;
    valorUsado: number;
    saldo: number;
    saldoPercent: number;
  };
  isDialogTreeViewOpen: boolean;
  idAreaTreeViewSelected: ChartType[];
  itemAreaTreeViewSelected: Partial<ChartType>;
  isModalPromoverOpen: boolean;
  initialMerito: Partial<MeritEmployee>;
  novoCargoSelected: Partial<PositionTypes> | null;
  isDialogNovoCargoOpen: boolean;
  dialogOcorrencias: {
    isOpen: boolean;
    nomeCompleto: string;
  };
  dialogSalario: {
    isOpen: boolean;
    nomeCompleto: string;
  };
  dialogCargos: {
    isOpen: boolean;
    nomeCompleto: string;
  },
  dialogAvaliacoes: {
    isOpen: boolean;
    nomeCompleto: string;
  },
  isDialogBudgetOpen: boolean;
  funcionarioSelected: {
    idFuncionario: number | null;
    nomeCompleto: string;
  };
  isDialogSolicitarAprovacaoOpen: boolean;
  isDialogReprovarSolicitacaoOpen: boolean;
  isDialogFinalizarOpen: boolean;
  isDialogReabrirOpen: boolean;
  isDialogAprovarSolicitacaoOpen: boolean;
  comentarioFinal: { value: string; error: boolean };
}

interface IPropsMerit extends RouteComponentProps {
  merits: DataTypesMerit;
  areas: DataTypesChart;
  positions: DataTypesPosition;
  cc: DataTypesCC;
  categoriasCargos: DataTypesCatogories;
  getMeritById: (idMerito: number) => void;
  editMerit: (idMerito: number, merit: Partial<MeritEmployee>) => void;
  atualizaPercentual: (
    idMerito: number,
    idMeritoFuncionario: number,
    percEnquadramento: number,
    percMerito: number,
    percPromocao: number
  ) => void;
  atualizaPromocao: (
    idMerito: number,
    merito: IRequestMeritAtualizaPromocaoAction["payload"]["merito"]
  ) => void;
  atualizaSalario: (
    idMerito: number,
    idMeritoFuncionario: number,
    novoSalario: number
  ) => void;
  atualizaComentarios: (
    idMerito: number,
    idMeritoFuncionario: number,
    comentarios: string
  ) => void;
  finalizar: (idMerito: number, idMeritoFuncionario: number) => void;
  reativar: (idMerito: number, idMeritoFuncionario: number) => void;
  aplicar: (idMerito: number, idMeritoFuncionario: number) => void;
  ignorar: (idMerito: number, idMeritoFuncionario: number) => void;
  solicitarAprovacao: (idMerito: number, comentarios: string) => void;
  getInfoSalarial: (idES: number, gs: number) => void;
  getChart: (
    search?: string,
    filter?: boolean | null,
    treeview?: boolean
  ) => void;
  getPositions: (
    search?: string,
    isSearch?: boolean,
    filter?: boolean | null,
    gs?: string | null
  ) => void;
  getCC: (SearchText?: string) => void;
  setCurrentPage: (page: Page) => void;
  getCategoriesPositions: () => void;
  getBudgets: (idMerito: number) => void;
  editBudget: (idMerito: number, budget: Budget) => void;
  getSaldo: (idMerito: number) => void;
  aprovarMerito: (idMerito: number) => void;
  reprovarMerito: (idMerito: number) => void;
  finalizarReuniao: (idMerito: number) => void;
  reabrirReuniao: (idMerito: number) => void;
  listarCargos: (idMerito: number, idMeritoFuncionario: number) => void;
  listarSalarios: (idMerito: number, idMeritoFuncionario: number) => void;
  listarAvaliacoes: (idMerito: number, idMeritoFuncionario: number) => void;
  listarOcorrencias: (idMerito: number, idMeritoFuncionario: number) => void;
  clearListaCargos: () => void;
  clearListaSalarios: () => void;
  clearListaOcorrencias: () => void;
  clearListaAvaliacoes: () => void;
  downloadCarta: (
    idMerito: number,
    idMeritoFuncionario: number
  ) => void;

  match: any;
  history: RouteComponentProps["history"];
}

class Merit extends Component<IPropsMerit, IMeritState> {
  private formRef: any;
  private formNovoCargoRef: any;
  private formEditBudgetRef: any;
  private _selection: Selection;
  private hoverCard: React.RefObject<IHoverCard>;
  private timeout: number;

  constructor(props: IPropsMerit) {
    super(props);
    //#region Colunas
    const columns: IColumn[] = [
      {
        key: "column1",
        name: "",
        fieldName: "",
        minWidth: 30,
        maxWidth: 30,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "string",
        styles: {
          root: {
            position: "sticky",
            left: 0,
            top: 50,
            backgroundColor: "#FFFFFF",
            zIndex: 9,
          },
        },
        onRender: (item: MeritEmployee) => {
          const { nomeCompleto, imagem } = item.funcionario ?? {};
          // const { imagem, nomeCompleto, status, isVisible } = values.funcionario ?? {};
          // const imagePath = `${baseURL}uploads/${imagem}`;
          const imagePath = `${baseURL}uploads/${imagem}`;
          const initials = getAbbreviatedName(nomeCompleto ?? "");


          const onRenderPlainCard = (): JSX.Element => {
            return (
              <WrapperPersonaInfo>
                <Stack styles={{ root: { height: "auto" } }}>
                  <Stack horizontal style={{ marginBottom: 25 }}>
                    <Persona
                      imageUrl={imagePath}
                      imageInitials={initials}
                      initialsColor={Colors.primary}
                      size={PersonaSize.size56}
                      styles={{ root: { width: 56, marginRight: 10 } }}
                    />
                    <Stack>
                      <Text
                        variant="medium"
                        styles={{ root: { fontWeight: "bold" } }}
                      >
                        {nomeCompleto}
                      </Text>
                      <Text variant="smallPlus">{item.cargoOrigem.titulo}</Text>
                    </Stack>
                  </Stack>
                  {/* {observacoesPolitica.split("\n").map((obs, i) => (
                    <Stack
                      key={i}
                      horizontal
                      verticalAlign="center"
                      style={{ marginBottom: 10 }}
                    >
                      <FontIcon
                        iconName="AlertSolid"
                        style={{
                          color: Colors.error,
                          fontSize: 20,
                          marginRight: 10,
                        }}
                      />
                      <Text styles={{ root: { marginBottom: 1 } }}>{obs}</Text>
                    </Stack>
                  ))} */}
                </Stack>
              </WrapperPersonaInfo>
            );
          };

          const onRenderAvatar = (): JSX.Element => {
            return <Stack
              horizontal
              horizontalAlign="space-between"
              verticalAlign="center"
              styles={{ root: { whiteSpace: "break-spaces", width: "100%" } }}
            >
              <Stack horizontal verticalAlign="center">
                <Persona
                  imageUrl={imagePath}
                  imageInitials={initials}
                  initialsColor={Colors.primary}
                  size={PersonaSize.size32}
                  presence={getPresence(item.statusFuncionario)}
                  styles={{
                    root: {
                      width: 32,
                      marginRight: 10,
                      opacity: item.aplicarMerito ? 1 : 0.5,
                      selectors: {
                        "& .ms-Persona-initials": {
                          filter: "grayscale(0)",
                        },
                      },
                    },
                  }}
                />
              </Stack>
            </Stack>
          }

          return <HoverCard
            type={HoverCardType.plain}
            plainCardProps={{ onRenderPlainCard }}
            componentRef={this.hoverCard}
            styles={hoverCardStyles}
          >
            {onRenderAvatar()}
          </HoverCard>
        },
      },
      {
        key: "column2",
        name: "Matrícula",
        fieldName: "funcionario.matricula",
        minWidth: 80,
        maxWidth: 80,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "number",
        styles: {
          root: {
            position: "sticky",
            left: 50,
            top: 50,
            backgroundColor: "#FFFFFF",
            zIndex: 9,
          },
        },
        onRender: (item: MeritEmployee) => (
          <Text>{item.funcionario.matricula}</Text>
        ),
      },
      {
        key: "column3",
        name: "Nome",
        fieldName: "funcionario.nomeCompleto",
        minWidth: 200,
        maxWidth: 250,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "string",
        styles: {
          root: {
            position: "sticky",
            left: 150,
            top: 50,
            backgroundColor: "#FFFFFF",
            zIndex: 9,
          },
        },
        onRender: (item: MeritEmployee) => (
          <Text style={{ whiteSpace: "break-spaces" }}>
            {item.funcionario.nomeCompleto}
          </Text>
        ),
      },
      {
        key: "column4",
        name: "Idade",
        fieldName: "idade",
        minWidth: 50,
        maxWidth: 50,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "number",
        styles: { cellTitle: { justifyContent: "center" } },
        onRender: (item: MeritEmployee) => item.idade,
      },
      {
        key: "column5",
        name: "Afastamento",
        fieldName: "flagAfastado",
        minWidth: 100,
        maxWidth: 100,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "number",
        styles: { cellTitle: { justifyContent: "center" } },
        onRender: (item: MeritEmployee) => {
          const idMerito = this.props.match.params?.idMerito || this.props.merits.data?.merito.idMerito;

          let color = null;
          if (item.flagAfastado && !item.ignorarAfastamento) {
            color = Colors.red;
          } else if (item.flagAfastado && item.ignorarAfastamento) {
            color = Colors.blue;
          } else if (item.alertarAfastamento) {
            color = Colors.orange;
          }
          if (color) {
            return (
              <Stack horizontal verticalAlign="center">
                <FontIcon
                  iconName={"StatusCircleOuter"}
                  style={{
                    fontSize: 10,
                    color,
                  }}
                />
                <IconButton
                  iconProps={{ iconName: "View" }}
                  onClick={() => {
                    this.props.listarOcorrencias(idMerito, item.idMeritoFuncionario);

                    this.setState({
                      dialogOcorrencias: {
                        isOpen: true,
                        nomeCompleto: item.funcionario.nomeCompleto,
                      },
                    })
                  }}
                />
              </Stack>
            );
          }


          return (
            <IconButton
              iconProps={{ iconName: "View" }}
              onClick={() => {
                this.props.listarOcorrencias(idMerito, item.idMeritoFuncionario);
                this.setState({
                  dialogOcorrencias: {
                    isOpen: true,
                    nomeCompleto: item.funcionario.nomeCompleto,
                  },
                })
              }
              }
            />
          );
        },
      },
      {
        key: "column6",
        name: "Data Admissão",
        fieldName: "funcionario.dataAdmissao",
        minWidth: 100,
        maxWidth: 100,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "string",
        onRender: (item: MeritEmployee) =>
          item.funcionario.dataAdmissao
            ? moment(item.funcionario.dataAdmissao).utc().format("DD/MM/YYYY")
            : "",
      },
      {
        key: "column7",
        name: "Tempo",
        fieldName: "tempoEmpresa",
        minWidth: 50,
        maxWidth: 50,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "number",
        styles: { cellTitle: { justifyContent: "center" } },
        onRender: (item: MeritEmployee) => {
          if (item.tempoEmpresa < 12) {
            return <Text styles={{ root: { color: Colors.error } }}>{`${item.tempoEmpresa} m`}</Text>;
          } else {
            return `${(item.tempoEmpresa / 12).toFixed(0)} a`;
          }
        },
      },
      {
        key: "column8",
        name: "Área",
        fieldName: "area.nomeLocal",
        minWidth: 200,
        maxWidth: 200,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "string",
        onRender: (item: MeritEmployee) => (
          <Text style={{ whiteSpace: "break-spaces" }}>
            {item.area?.nomeLocal}
          </Text>
        ),
      },
      {
        key: "column9",
        name: "Gestor",
        fieldName: "gestor.nomeCompleto",
        minWidth: 200,
        maxWidth: 200,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "string",
        onRender: (item: MeritEmployee) => (
          <Text style={{ whiteSpace: "break-spaces" }}>
            {item.gestor.nomeCompleto}
          </Text>
        ),
      },
      {
        key: "column10",
        name: "Cargo",
        fieldName: "cargoOrigem.titulo",
        minWidth: 250,
        maxWidth: 250,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "string",
        onRender: (item: MeritEmployee) => {
          const idMerito = this.props.match.params?.idMerito || this.props.merits.data?.merito.idMerito;

          return <Stack horizontal verticalAlign="center" horizontalAlign="space-between" styles={{ root: { width: '100%' } }}>
            <Text style={{ whiteSpace: "break-spaces" }}>
              {item.cargoOrigem.titulo}
            </Text>
            <IconButton
              iconProps={{ iconName: "View" }}
              onClick={() => {
                this.props.listarCargos(idMerito, item.idMeritoFuncionario)
                this.setState({
                  dialogCargos: {
                    isOpen: true,
                    nomeCompleto: item.funcionario.nomeCompleto,
                  },
                })
              }}
            />
          </Stack>
        },
      },
      {
        key: "column11",
        name: "GS",
        fieldName: "gsOrigem",
        minWidth: 30,
        maxWidth: 30,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "string",
        onRender: (item: MeritEmployee) => item.gsOrigem,
      },
      {
        key: "column12",
        name: "Tempo (Cargo)",
        fieldName: "tempoCargo",
        minWidth: 100,
        maxWidth: 100,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "string",
        onRender: (item: MeritEmployee) => `${item.tempoCargo} m`,
      },
      {
        key: "column13",
        name: "Salário",
        fieldName: "salarioOrigem",
        minWidth: 150,
        maxWidth: 150,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "number",
        styles: { cellTitle: { justifyContent: "flex-end", paddingRight: 40 } },
        onRender: (item: MeritEmployee) => {
          const idMerito = this.props.match.params?.idMerito || this.props.merits.data?.merito.idMerito;

          return (
            <Stack horizontal verticalAlign="center">
              {toCurrency(item.salarioOrigem)}
              <IconButton
                iconProps={{ iconName: "View" }}
                onClick={() => {
                  this.props.listarSalarios(idMerito, item.idMeritoFuncionario)
                  this.setState({
                    dialogSalario: {
                      isOpen: true,
                      nomeCompleto: item.funcionario.nomeCompleto,
                    },
                  })
                }
                }
              />
            </Stack>
          )
        },
      },
      {
        key: "column14",
        name: "Faixa",
        fieldName: "faixaOrigem",
        minWidth: 100,
        maxWidth: 100,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "number",
        styles: { cellTitle: { justifyContent: "center" } },
        onRender: (item: MeritEmployee) => (
          <Text
            style={{ color: this.handleColorFaixa(item.faixaOrigem) }}
          >{`${Number(item.faixaOrigem).toFixed(2)}%`}</Text>
        ),
      },
      {
        key: "column15",
        name: "Úl. Aumento",
        fieldName: "ultimaAlteracao",
        minWidth: 100,
        maxWidth: 100,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "string",
        onRender: (item: MeritEmployee) =>
          item.ultimaAlteracao
            ? moment(item.ultimaAlteracao).utc().format("DD/MM/YYYY")
            : "",
      },
      {
        key: "column16",
        name: "Motivo",
        fieldName: "tipomov.nomeAmigavel",
        minWidth: 150,
        maxWidth: 150,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "string",
        onRender: (item: MeritEmployee) => item?.tipomov?.nomeAmigavel,
      },
      {
        key: "column17",
        name: "Perc.",
        fieldName: "percReajuste",
        minWidth: 60,
        maxWidth: 60,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "number",
        styles: { cellTitle: { justifyContent: "center" } },
        onRender: (item: MeritEmployee) =>
          !!item.percReajuste ? `${Number(item.percReajuste).toFixed(2)}%` : "",
      },
      {
        key: "column18",
        name: "Hist. Performance",
        fieldName: "idTabPerformanceAnterior",
        minWidth: 120,
        maxWidth: 120,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "string",
        styles: {
          cellTitle: {
            backgroundColor: "#F6F6F6",
            justifyContent: "center",
            selectors: {
              "&:hover": {
                backgroundColor: "#EBEBEB",
              },
            },
          },
        },
        onRender: (item: MeritEmployee) => {
          const idMerito = this.props.match.params?.idMerito || this.props.merits.data?.merito.idMerito;
          return (
            <Stack
              horizontal
              verticalAlign="center"
              horizontalAlign="center"
              styles={{ root: { width: "100%" } }}
            >
              <IconButton
                iconProps={{ iconName: "View" }}
                title={"Visualizar avaliação anterior"}
                ariaLabel={"Visualizar avaliação anterior"}
                styles={{ root: { fontSize: 14 } }}
                onClick={() => {
                  this.props.listarAvaliacoes(idMerito, item.idMeritoFuncionario);
                  this.setState({
                    dialogAvaliacoes: {
                      isOpen: true,
                      nomeCompleto: item.funcionario.nomeCompleto
                    },
                  })
                }}
              />
            </Stack>
          )
        },
      },
      {
        key: "column19",
        name: `Performance ${this.props.merits.data?.merito.ano ?? ""}`,
        fieldName: "idTabPerformance",
        minWidth: 120,
        maxWidth: 120,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "string",
        styles: {
          cellTitle: {
            backgroundColor: "#F6F6F6",
            selectors: {
              "&:hover": {
                backgroundColor: "#EBEBEB",
              },
            },
          },
        },
        onRender: (item: MeritEmployee) =>
          item.performance && (
            <Stack
              horizontal
              verticalAlign="center"
              horizontalAlign="space-between"
              styles={{ root: { width: "100%" } }}
            >
              <Stack horizontal>
                <FontIcon
                  iconName="CircleFill"
                  style={{
                    fontSize: 17,
                    color: item.performance?.cor,
                    marginRight: 12,
                  }}
                />
                <Text
                  variant="medium"
                  styles={{ root: { fontWeight: "inherit" } }}
                >
                  {item.performance?.descNivel}
                </Text>
              </Stack>
            </Stack>
          ),
      },
      {
        key: "column20",
        name: "Min.",
        fieldName: "min",
        minWidth: 50,
        maxWidth: 50,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "number",
        styles: {
          cellTitle: {
            justifyContent: "center",
            backgroundColor: "#F6F6F6",
            selectors: {
              "&:hover": {
                backgroundColor: "#EBEBEB",
              },
            },
          },
        },
        onRender: (item: MeritEmployee) => `${Number(item.min).toFixed(2)}%`,
      },
      {
        key: "column21",
        name: "Méd.",
        fieldName: "med",
        minWidth: 50,
        maxWidth: 50,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "number",
        styles: {
          cellTitle: {
            justifyContent: "center",
            backgroundColor: "#F6F6F6",
            selectors: {
              "&:hover": {
                backgroundColor: "#EBEBEB",
              },
            },
          },
        },
        onRender: (item: MeritEmployee) => `${Number(item.med).toFixed(2)}%`,
      },
      {
        key: "column22",
        name: "Máx.",
        fieldName: "max",
        minWidth: 50,
        maxWidth: 50,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "number",
        styles: {
          cellTitle: {
            justifyContent: "center",
            backgroundColor: "#F6F6F6",
            selectors: {
              "&:hover": {
                backgroundColor: "#EBEBEB",
              },
            },
          },
        },
        onRender: (item: MeritEmployee) => `${Number(item.max).toFixed(2)}%`,
      },
      {
        key: "column23",
        name: "Eleg.",
        fieldName: "flagElegivel",
        minWidth: 50,
        maxWidth: 50,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "number",
        styles: {
          cellTitle: {
            backgroundColor: "#D3EADF",
            justifyContent: "center",
            selectors: {
              "&:hover": {
                backgroundColor: "#DFEBE5",
              },
            },
          },
        },
        onRender: (item: MeritEmployee) => (item.flagElegivel ? "SIM" : "NÃO"),
      },
      {
        key: "column24",
        name: "Antecipado",
        fieldName: "aumentoAntecipado",
        minWidth: 100,
        maxWidth: 100,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        styles: {
          cellTitle: {
            backgroundColor: "#D3EADF",
            justifyContent: "flex-end",
            selectors: {
              "&:hover": {
                backgroundColor: "#DFEBE5",
              },
            },
          },
        },
        onRender: (item: MeritEmployee) => {
          return (
            <Text
              styles={{
                root: {
                  color:
                    (item?.aumentoAntecipado ?? 0) > 0
                      ? Colors.error
                      : Colors.black,
                },
              }}
            >
              {toCurrency(item.aumentoAntecipado ?? 0)}
            </Text>
          );
        },
      },
      {
        key: "column25",
        name: "% Enq.",
        fieldName: "percEnquadramentoSugerido",
        minWidth: 100,
        maxWidth: 100,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        styles: {
          cellTitle: {
            backgroundColor: "#D3EADF",
            justifyContent: "center",
            selectors: {
              "&:hover": {
                backgroundColor: "#DFEBE5",
              },
            },
          },
        },
        onRender: (item: MeritEmployee) => {
          return (
            <Text>{`${Number(item.percEnquadramentoSugerido).toFixed(
              2
            )}%`}</Text>
          );
        },
      },
      {
        key: "column26",
        name: "% Mer.",
        fieldName: "percMeritoSugerido",
        minWidth: 100,
        maxWidth: 100,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        styles: {
          cellTitle: {
            backgroundColor: "#D3EADF",
            justifyContent: "center",
            selectors: {
              "&:hover": {
                backgroundColor: "#DFEBE5",
              },
            },
          },
        },
        onRender: (item: MeritEmployee) => {
          return (
            <Text>{`${Number(item.percMeritoSugerido).toFixed(2)}%`}</Text>
          );
        },
      },
      {
        key: "column27",
        name: "Aplicar",
        fieldName: "aplicarMerito",
        minWidth: 90,
        maxWidth: 90,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "number",
        styles: {
          cellTitle: {
            backgroundColor: "#BEDBCA",
            justifyContent: "center",
            selectors: {
              "&:hover": {
                backgroundColor: "#CAE0D6",
              },
            },
          },
        },
        onRender: (item: MeritEmployee) => {
          const disabled =
            !this.props.merits.data?.controls.permiteEditar || item.flagFinalizado;

          return (
            <InputCheckbox
              checked={item.aplicarMerito}
              styles={{ margin: 0 }}
              onChange={(e) => this.handleAplicarMerito(e.target.checked, item)}
              disabled={disabled}
            />
          );
        },
      },
      {
        key: "column28",
        name: "% Enq.",
        fieldName: "percEnquadramento",
        minWidth: 90,
        maxWidth: 90,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "number",
        styles: {
          cellTitle: {
            backgroundColor: "#BEDBCA",
            justifyContent: "center",
            selectors: {
              "&:hover": {
                backgroundColor: "#CAE0D6",
              },
            },
          },
        },
        onRender: (item: MeritEmployee) => {
          const readOnly = !this.props.merits.data?.controls.permiteEditar;
          return (
            <>
              {item.flagPercEnqEdit ? (
                <InputNumber
                  defaultValue={Number(item.percEnquadramento)}
                  decimalScale={2}
                  onBlur={(e) => {
                    this.handlePercEnquadramento(
                      toNumber(e.target.value),
                      item
                    );
                    this.handleToggleEnq(item);
                  }}
                  height_container="100%"
                  disabled={readOnly}
                />
              ) : (
                <Stack
                  horizontal
                  horizontalAlign="space-between"
                  verticalAlign="center"
                  styles={{ root: { width: "100%" } }}
                >
                  <Text
                    styles={{ root: { textAlign: "right", width: "100%" } }}
                  >{`${Number(item.percEnquadramento).toFixed(2)}%`}</Text>
                  <IconButton
                    className="btnEditGrid"
                    iconProps={{ iconName: "Edit" }}
                    style={{ marginLeft: 5 }}
                    disabled={
                      readOnly ||
                      !item.aplicarMerito ||
                      item.flagFinalizado ||
                      item.flagPromover
                    }
                    styles={{
                      rootHovered: { backgroundColor: "rgba(0, 0, 0, 0.05)" },
                      root: {
                        visibility: `hidden ${!item.aplicarMerito ||
                          item.flagFinalizado ||
                          item.flagPromover ||
                          readOnly
                          ? "!important"
                          : ""
                          }`,
                      },
                      rootPressed: { backgroundColor: "rgba(0, 0, 0, 0.1)" },
                    }}
                    onClick={() => this.handleToggleEnq(item)}
                  />
                </Stack>
              )}
            </>
          );
        },
      },
      {
        key: "column29",
        name: "% Mér.",
        fieldName: "percMerito",
        minWidth: 90,
        maxWidth: 90,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "number",
        styles: {
          cellTitle: {
            backgroundColor: "#BEDBCA",
            paddingRight: 32,
            justifyContent: "center",
            selectors: {
              "&:hover": {
                backgroundColor: "#CAE0D6",
              },
            },
          },
        },
        onRender: (item: MeritEmployee) => {
          const readOnly = !this.props.merits.data?.controls.permiteEditar;

          return (
            <>
              {item.flagPercMeritoEdit ? (
                <InputNumber
                  defaultValue={
                    !!item.percMerito ? Number(item.percMerito) : undefined
                  }
                  decimalScale={2}
                  onBlur={(e) => {
                    this.handlePercMerito(toNumber(e.target.value), item);
                    this.handleToggleMerito(item);
                  }}
                  height_container="100%"
                  disabled={readOnly}
                />
              ) : (
                <Stack
                  horizontal
                  horizontalAlign="space-between"
                  verticalAlign="center"
                  styles={{ root: { width: "100%" } }}
                >
                  <Text
                    styles={{ root: { textAlign: "right", width: "100%" } }}
                  >{`${Number(item.percMerito).toFixed(2)}%`}</Text>
                  <IconButton
                    className="btnEditGrid"
                    iconProps={{ iconName: "Edit" }}
                    style={{ marginLeft: 5 }}
                    disabled={
                      !item.aplicarMerito ||
                      item.flagFinalizado ||
                      item.flagPromover ||
                      readOnly
                    }
                    styles={{
                      rootHovered: { backgroundColor: "rgba(0, 0, 0, 0.05)" },
                      root: {
                        visibility: `hidden ${!item.aplicarMerito ||
                          item.flagFinalizado ||
                          item.flagPromover ||
                          readOnly
                          ? "!important"
                          : ""
                          }`,
                      },
                      rootPressed: { backgroundColor: "rgba(0, 0, 0, 0.1)" },
                    }}
                    onClick={() => this.handleToggleMerito(item)}
                  />
                </Stack>
              )}
            </>
          );
        },
      },
      {
        key: "column30",
        name: "Promover",
        fieldName: "flagPromover",
        minWidth: 70,
        maxWidth: 70,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        styles: {
          cellTitle: {
            backgroundColor: "#BEDBCA",
            justifyContent: "center",
            selectors: {
              "&:hover": {
                backgroundColor: "#CAE0D6",
              },
            },
          },
        },
        onRender: (item: MeritEmployee) => {
          const isFinalizado = !this.props.merits.data?.controls.permiteEditar;

          const disabled =
            !item.aplicarMerito ||
            item.flagFinalizado ||
            !item.cargoOrigem.cargosuperior ||
            isFinalizado;
          return (
            item.aplicarMerito && (
              <TooltipHost
                content={
                  !item.cargoOrigem.cargosuperior
                    ? "Promoção não habilitada para o funcionário"
                    : ""
                }
                id={String(item.idMeritoFuncionario)}
                styles={tooltipStyle}
              >
                <InputCheckbox
                  checked={item.flagPromover}
                  styles={{ margin: 0 }}
                  onChange={(e) => this.handlePromover(e.target.checked, item)}
                  disabled={disabled}
                />
              </TooltipHost>
            )
          );
        },
      },
      {
        key: "column31",
        name: "% Prom.",
        fieldName: "percPromocao",
        minWidth: 90,
        maxWidth: 90,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "number",
        styles: {
          cellTitle: {
            backgroundColor: "#BEDBCA",
            justifyContent: "center",
            selectors: {
              "&:hover": {
                backgroundColor: "#CAE0D6",
              },
            },
          },
        },
        onRender: (item: MeritEmployee) => {
          const readOnly = !this.props.merits.data?.controls.permiteEditar;

          if (item.flagPromover && !readOnly) {
            return item.flagPercPromocaoEdit && item.idCargoDestino ? (
              <InputNumber
                defaultValue={Number(item.percPromocao)}
                decimalScale={1}
                onBlur={(e) => {
                  this.handleEditPercPromocao(toNumber(e.target.value), item);
                  this.handleTogglePercPromocao(item);
                }}
                height_container="100%"
              />
            ) : (
              <Stack
                horizontal
                horizontalAlign="space-between"
                verticalAlign="center"
                styles={{ root: { width: "100%" } }}
              >
                <Text
                  styles={{ root: { textAlign: "right", width: "100%" } }}
                >{`${Number(item.percPromocao).toFixed(2)}%`}</Text>
                <IconButton
                  className="btnEditGrid"
                  iconProps={{ iconName: "Edit" }}
                  style={{ marginLeft: 5 }}
                  disabled={
                    !item.aplicarMerito ||
                    item.flagFinalizado ||
                    !item.idCargoDestino
                  }
                  styles={{
                    rootHovered: { backgroundColor: "rgba(0, 0, 0, 0.05)" },
                    root: {
                      visibility: `hidden ${!item.aplicarMerito ||
                        item.flagFinalizado ||
                        !item.idCargoDestino
                        ? "!important"
                        : ""
                        }`,
                    },
                    rootPressed: { backgroundColor: "rgba(0, 0, 0, 0.1)" },
                  }}
                  onClick={() => this.handleTogglePercPromocao(item)}
                />
              </Stack>
            );
          }
          else
            if (item.flagPromover) {
              //se for readonly
              return <Stack
                horizontal
                horizontalAlign="space-between"
                verticalAlign="center"
                styles={{ root: { width: "100%" } }}
              >
                <Text
                  styles={{ root: { textAlign: "right", width: "100%" } }}
                >{`${Number(item.percPromocao).toFixed(2)}%`}</Text>

              </Stack>
            }
        },
      },
      {
        key: "column32",
        name: "Novo Cargo",
        fieldName: "tituloCargoDestino",
        minWidth: 250,
        maxWidth: 250,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "number",
        styles: {
          cellTitle: {
            backgroundColor: "#BEDBCA",
            selectors: {
              "&:hover": {
                backgroundColor: "#CAE0D6",
              },
            },
          },
        },
        onRender: (item: MeritEmployee) => {
          const readOnly = !this.props.merits.data?.controls.permiteEditar;

          if (item.flagPromover && !readOnly) {
            return (
              <Stack
                horizontal
                horizontalAlign="space-between"
                verticalAlign="center"
                styles={{ root: { width: "100%" } }}
              >
                <Text
                  styles={{ root: { whiteSpace: "break-spaces" } }}
                >{`${item.cargoOrigem.cargosuperior?.titulo}`}</Text>
                { }
              </Stack>
            );
          }
          else
            if (item.flagPromover) {
              //se for readonly
              return (
                <Stack
                  horizontal
                  horizontalAlign="space-between"
                  verticalAlign="center"
                  styles={{ root: { width: "100%" } }}
                >
                  <Text
                    styles={{ root: { whiteSpace: "break-spaces" } }}
                  >{`${item.tituloCargoDestino}`}</Text>
                  { }
                </Stack>
              );
            }
        },
      },
      {
        key: "column33",
        name: "GS",
        fieldName: "novoGs",
        minWidth: 30,
        maxWidth: 30,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "number",
        styles: {
          cellTitle: {
            backgroundColor: "#BEDBCA",
            selectors: {
              "&:hover": {
                backgroundColor: "#CAE0D6",
              },
            },
          },
        },
        onRender: (item: MeritEmployee) => {
          if (item.flagPromover) {
            return item.gsDestino;
          }
        },
      },
      {
        key: "column34",
        name: "% Total",
        fieldName: "totalPercent",
        minWidth: 60,
        maxWidth: 60,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "number",
        styles: {
          cellTitle: {
            backgroundColor: "#BEDBCA",
            justifyContent: "center",
            selectors: {
              "&:hover": {
                backgroundColor: "#CAE0D6",
              },
            },
          },
        },
        onRender: (item: MeritEmployee) =>
          `${Number(item.totalPercent).toFixed(2)}%`,
      },
      {
        key: "column35",
        name: "R$ Total",
        fieldName: "valorAumento",
        minWidth: 100,
        maxWidth: 100,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "number",
        styles: {
          cellTitle: {
            backgroundColor: "#BEDBCA",
            justifyContent: "flex-end",
            selectors: {
              "&:hover": {
                backgroundColor: "#CAE0D6",
              },
            },
          },
        },
        onRender: (item: MeritEmployee) => toCurrency(item.valorAumento),
      },
      {
        key: "column36",
        name: "Novo Salário",
        fieldName: "novoSalario",
        minWidth: 150,
        maxWidth: 150,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "number",
        styles: {
          cellTitle: {
            backgroundColor: "#BEDBCA",
            justifyContent: "center",
            selectors: {
              "&:hover": {
                backgroundColor: "#CAE0D6",
              },
            },
          },
        },
        onRender: (item: MeritEmployee) => {
          const readOnly = !this.props.merits.data?.controls.permiteEditar;

          return (
            <Stack
              horizontal
              horizontalAlign="end"
              verticalAlign="center"
              styles={{ root: { width: "100%" } }}
            >
              <Text styles={{ root: { whiteSpace: "break-spaces" } }}>
                {toCurrency(item.novoSalario)}
              </Text>
              <IconButton
                className="btnEditGrid"
                iconProps={{ iconName: "Edit" }}
                disabled={!item.aplicarMerito || readOnly}
                style={{ marginLeft: 5 }}
                styles={{
                  rootHovered: { backgroundColor: "rgba(0, 0, 0, 0.05)" },
                  root: {
                    visibility: `hidden ${!item.aplicarMerito || item.flagFinalizado
                      ? "!important"
                      : ""
                      }`,
                  },
                  rootPressed: { backgroundColor: "rgba(0, 0, 0, 0.1)" },
                }}
                onClick={() =>
                  this.setState({
                    editSalario: {
                      isOpen: true,
                      funcionario: {
                        idMeritoFuncionario: item.idMeritoFuncionario,
                        nome: item.funcionario.nomeCompleto,
                      },
                      salario: {
                        salarioAtual: item.salarioOrigem,
                        salarioNovo: Number(item.novoSalario),
                        faixa: null,
                        reajuste: null,
                        valorMedio: item.infoGSDestino?.valorMedio ?? 0,
                      },
                    },
                  })
                }
              />
            </Stack>
          );
        },
      },
      {
        key: "column37",
        name: "% Faixa",
        fieldName: "novaFaixa",
        minWidth: 100,
        maxWidth: 100,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "number",
        styles: {
          cellTitle: {
            backgroundColor: "#BEDBCA",
            justifyContent: "center",
            selectors: {
              "&:hover": {
                backgroundColor: "#CAE0D6",
              },
            },
          },
        },
        onRender: (item: MeritEmployee) => (
          <Text
            style={{ color: this.handleColorFaixa(item.novaFaixa) }}
          >{`${Number(item.novaFaixa).toFixed(2)}%`}</Text>
        ),
      },
      {
        key: "column38",
        name: "Finalizado",
        fieldName: "flagFinalizado",
        minWidth: 70,
        maxWidth: 70,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        styles: {
          cellTitle: {
            backgroundColor: "#F8E8E8",
            justifyContent: "center",
            selectors: {
              "&:hover": {
                backgroundColor: "#FBE0E0",
              },
            },
          },
        },
        onRender: (item: MeritEmployee) => {
          const readOnly = !this.props.merits.data?.controls.permiteEditar;

          return (
            <FormControlLabel
              style={{ margin: 0 }}
              disabled={readOnly}
              control={
                <Checkbox
                  checked={item.flagFinalizado}
                  icon={<FontIcon iconName="Unlock" style={{ fontSize: 20 }} />}
                  disabled={readOnly}
                  checkedIcon={
                    <FontIcon
                      iconName="LockSolid"
                      style={{ color: Colors.primary, fontSize: 20 }}
                    />
                  }
                  color="primary"
                  onChange={(e) => {
                    this.handleFuncFinalizado(e.target.checked, item);
                  }}
                />
              }
              label=""
            />
          );
        },
      },
      {
        key: "column39",
        name: "Política",
        fieldName: "statusPolitica",
        minWidth: 70,
        maxWidth: 70,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        styles: {
          cellTitle: {
            backgroundColor: "#F8E8E8",
            justifyContent: "center",
            selectors: {
              "&:hover": {
                backgroundColor: "#FBE0E0",
              },
            },
          },
        },
        onRender: (item: MeritEmployee) => {
          const {
            funcionario,
            cargoOrigem,
            statusPolitica,
            observacoesPolitica,
            idMeritoFuncionario,
          } = item;
          const { nomeCompleto, imagem } = funcionario;
          const imagePath = `${baseURL}uploads/${imagem}`;
          const initials = getAbbreviatedName(nomeCompleto);

          const onRenderPlainCard = (): JSX.Element => {
            return (
              <WrapperPersonaInfo>
                <Stack styles={{ root: { height: "auto" } }}>
                  <Stack horizontal style={{ marginBottom: 25 }}>
                    <Persona
                      imageUrl={imagePath}
                      imageInitials={initials}
                      initialsColor={Colors.primary}
                      size={PersonaSize.size56}
                      styles={{ root: { width: 56, marginRight: 10 } }}
                    />
                    <Stack>
                      <Text
                        variant="medium"
                        styles={{ root: { fontWeight: "bold" } }}
                      >
                        {nomeCompleto}
                      </Text>
                      <Text variant="smallPlus">{cargoOrigem.titulo}</Text>
                    </Stack>
                  </Stack>
                  {observacoesPolitica.split("\n").map((obs, i) => (
                    <Stack
                      key={i}
                      horizontal
                      verticalAlign="center"
                      style={{ marginBottom: 10 }}
                    >
                      <FontIcon
                        iconName="AlertSolid"
                        style={{
                          color: Colors.error,
                          fontSize: 20,
                          marginRight: 10,
                        }}
                      />
                      <Text styles={{ root: { marginBottom: 1 } }}>{obs}</Text>
                    </Stack>
                  ))}
                </Stack>
              </WrapperPersonaInfo>
            );
          };
          return statusPolitica ? (
            <FontIcon
              iconName="SkypeCircleCheck"
              style={{ color: Colors.primary, fontSize: 20 }}
            />
          ) : (
            <HoverCard
              type={HoverCardType.plain}
              plainCardProps={{ onRenderPlainCard }}
              componentRef={this.hoverCard}
              styles={hoverCardStyles}
            >
              <FontIcon
                iconName="AlertSolid"
                style={{ color: Colors.error, fontSize: 20 }}
              />
            </HoverCard>
          );
        },
      },
      {
        key: "column40",
        name: "Comentários",
        fieldName: "comentarios",
        minWidth: 250,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        styles: {
          cellTitle: {
            backgroundColor: "#F8E8E8",
            selectors: {
              "&:hover": {
                backgroundColor: "#FBE0E0",
              },
            },
          },
        },
        onRender: (item: MeritEmployee) => {
          const readOnly = !this.props.merits.data?.controls.permiteEditar;
          return (
            <TooltipHost
              content={item.comentarios ?? ""} 
              id={String(item.idFuncionario)}
              // styles={funcStyles}
            >
              <Stack
                horizontal
                horizontalAlign="space-between"
                verticalAlign="center"
              >
                {item.comentarios && <Comentario>{item.comentarios}</Comentario>}
                <IconButton
                  className="btnEditGrid"
                  iconProps={{ iconName: "Edit" }}
                  disabled={item.flagFinalizado || readOnly}
                  style={{ marginLeft: 5 }}
                  styles={{
                    rootHovered: { backgroundColor: "rgba(0, 0, 0, 0.05)" },
                    root: {
                      visibility: `hidden ${item.flagFinalizado ? "!important" : ""
                        }`,
                    },
                    rootPressed: { backgroundColor: "rgba(0, 0, 0, 0.1)" },
                  }}
                  onClick={() =>
                    this.setState({
                      comentarioEdit: {
                        isOpen: true,
                        comentario: item.comentarios ?? "",
                      },
                      meritoFuncionarioSelected: item,
                    })
                  }
                />
              </Stack>
            </TooltipHost>
          );
        },
      },
    ];
    //#endregion

    this.state = {
      columns: columns,
      items: [],
      selectionDetails: "",
      isFiltering: false,
      showBudget: false,
      comentarioEdit: {
        isOpen: false,
        comentario: "",
      },
      isSearchPositionOpen: false,
      isSearchPositionFormOpen: false,
      meritoFuncionarioSelected: null,
      isOpenFilter: false,
      isOpenHistory: false,
      search: "",
      editSalario: {
        isOpen: false,
        funcionario: null,
        salario: null,
      },
      filter: {
        gestor: "",
        cargo: "",
        area: null,
        tempo: "",
        categoriasCargos: [],
        idTabPerformance: "",
        search: "",
        cc: null,
        flagElegivel: "Todos",
      },
      budgetFiltered: {
        budgetAprovado: 0,
        saldo: 0,
        saldoPercent: 0,
        valorUsado: 0
      },
      isDialogTreeViewOpen: false,
      idAreaTreeViewSelected: [],
      itemAreaTreeViewSelected: {
        idArea: null,
        nomeLocal: "",
      },
      isModalPromoverOpen: false,
      initialMerito: {},
      novoCargoSelected: null,
      isDialogNovoCargoOpen: false,
      dialogOcorrencias: {
        isOpen: false,
        nomeCompleto: "",
      },
      dialogSalario: {
        isOpen: false,
        nomeCompleto: "",
      },
      funcionarioSelected: {
        idFuncionario: null,
        nomeCompleto: "",
      },
      dialogCargos: {
        isOpen: false,
        nomeCompleto: "",
      },
      dialogAvaliacoes: {
        isOpen: false,
        nomeCompleto: "",
      },
      isDialogSolicitarAprovacaoOpen: false,
      isDialogBudgetOpen: false,
      isDialogReprovarSolicitacaoOpen: false,
      isDialogFinalizarOpen: false,
      isDialogReabrirOpen: false,
      isDialogAprovarSolicitacaoOpen: false,
      comentarioFinal: { value: "", error: false },
    };

    this._selection = new Selection({
      onSelectionChanged: () => {
        this.setState({
          selectionDetails: this._getSelectionDetails(),
        });
      },
    });

    this.formRef = React.createRef();
    this.formNovoCargoRef = React.createRef();
    this.formEditBudgetRef = React.createRef();
    this.hoverCard = React.createRef();
    this.timeout = 0;
  }

  componentDidMount() {
    this.props.getCategoriesPositions();
    this.loadMerit();
  }

  componentDidUpdate(prevProps: IPropsMerit, prevState: IMeritState) {
    const idMerito = this.props.match.params?.idMerito || this.props.merits.data?.merito.idMerito;
    if (prevProps.merits.errorData !== this.props.merits.errorData) {
      if (this.props.merits.errorData) {
        this.props.history.push("/merito");
      }
    }

    if (
      prevProps.merits.data?.funcionarios !==
      this.props.merits.data?.funcionarios
    ) {
      const allFuncFiltered =
        this.props.merits.data?.funcionarios.filter((item) => {
          return this.state.items
            .map((itemMapped) => itemMapped.idMeritoFuncionario)
            .includes(item.idMeritoFuncionario);
        }) ?? [];

      this.setState({
        items: this.state.items.map((item) => {
          const finded = allFuncFiltered.find(
            (func) => func.idMeritoFuncionario === item.idMeritoFuncionario
          );
          if (finded) {
            return { ...item, ...finded };
          }
          return item;
        }),
      });
    }

    if (prevState.editSalario.isOpen !== this.state.editSalario.isOpen) {
      if (this.state.editSalario.isOpen) {
        this.updateSalario(this.state.editSalario.salario?.salarioNovo ?? 0);
      }
    }

    if (prevProps.merits.success !== this.props.merits.success) {
      if (this.props.merits.success) {
        this.setState({
          isModalPromoverOpen: false,
          isSearchPositionOpen: false,
          isDialogNovoCargoOpen: false,
        });
      }
    }

    if (
      prevProps.merits.data?.merito.ano !== this.props.merits.data?.merito.ano
    ) {
      this.setState({
        columns: [
          ...this.state.columns.map((item) => {
            if (item.fieldName === "idTabPerformance") {
              return {
                ...item,
                name: `Performance ${this.props.merits.data?.merito.ano ?? ""}`,
              };
            }
            return item;
          }),
        ],
      });
    }

    if (
      prevProps.merits.successFinalizar !== this.props.merits.successFinalizar
    ) {
      if (this.props.merits.successFinalizar) {
        this.setState({
          isDialogSolicitarAprovacaoOpen: false,
          comentarioFinal: { value: "", error: false },
        });
      }
    }

    if (prevProps.categoriasCargos.data !== this.props.categoriasCargos.data) {
      this.setState({
        filter: {
          ...this.state.filter,
          categoriasCargos: this.props.categoriasCargos.data.map(
            (item) => item.idCategoriaCargo
          ),
        },
      });
    }

    if (prevProps.merits.successBudget !== this.props.merits.successBudget) {
      if (this.props.merits.successBudget) {
        if (this.formEditBudgetRef?.current) {
          this.formEditBudgetRef?.current.handleReset()
        }
        this.props.getSaldo(idMerito);
      }
    }

    if (prevProps.merits.successAprovarReprovar !== this.props.merits.successAprovarReprovar) {
      if (this.props.merits.successAprovarReprovar) {
        this.setState({
          isDialogReprovarSolicitacaoOpen: false,
          isDialogAprovarSolicitacaoOpen: false,
        })
      }
    }

    if (prevProps.merits.successFinalizar !== this.props.merits.successFinalizar) {
      if (this.props.merits.successFinalizar) {
        this.setState({
          isDialogFinalizarOpen: false,
        })
      }
    }
    if (prevProps.merits.successReabrir !== this.props.merits.successReabrir) {
      if (this.props.merits.successReabrir) {
        this.setState({
          isDialogReabrirOpen: false,
        })
      }
    }

    if (
      prevProps.merits.data?.merito.status !==
      this.props.merits.data?.merito.status
    ) {
      const readOnly = !this.props.merits.data?.controls.permiteEditar;

      if (readOnly) {
        this.setState({
          columns: this.state.columns.map((col) => {
            if (col.name === "Finalizado") {
              return {
                ...col,
                onRender: (item: MeritEmployee) => {
                  return (
                    <FormControlLabel
                      style={{ margin: 0 }}
                      disabled={readOnly}
                      control={
                        <Checkbox
                          checked={item.flagFinalizado}
                          icon={
                            <FontIcon
                              iconName="Unlock"
                              style={{
                                fontSize: 20,
                                opacity: readOnly ? 0.5 : 1,
                              }}
                            />
                          }
                          disabled={readOnly}
                          checkedIcon={
                            <FontIcon
                              iconName="LockSolid"
                              style={{
                                color: Colors.primary,
                                fontSize: 20,
                                opacity: readOnly ? 0.5 : 1,
                              }}
                            />
                          }
                          color="primary"
                          onChange={(e) =>
                            this.handleFuncFinalizado(e.target.checked, item)
                          }
                        />
                      }
                      label=""
                    />
                  );
                },
              };
            }
            return col;
          }),
        });
      }
    }

    const orcColumns = [
      ...this.state.columns.filter(item => item.key !== 'column48'),
      ...[
        {
          key: "column41",
          name: "Salário Ref.",
          fieldName: "salarioRef",
          minWidth: 100,
          maxWidth: 100,
          isRowHeader: true,
          isResizable: true,
          isSortedDescending: false,
          sortAscendingAriaLabel: "Sorted A to Z",
          sortDescendingAriaLabel: "Sorted Z to A",
          onColumnClick: this._onColumnClick,
          data: "number",
          styles: {
            cellTitle: {
              justifyContent: "flex-end",
              backgroundColor: "#FDEEDE",
              selectors: {
                "&:hover": {
                  backgroundColor: "#FFEAD4",
                },
              },
            },
          },

          onRender: (item: MeritEmployee) => toCurrency(item.salarioRef),
        },
        {
          key: "column42",
          name: "% Enq.",
          fieldName: "percEnquadramentoAprovado",
          minWidth: 50,
          maxWidth: 50,
          isRowHeader: true,
          isResizable: true,
          isSortedDescending: false,
          sortAscendingAriaLabel: "Sorted A to Z",
          sortDescendingAriaLabel: "Sorted Z to A",
          onColumnClick: this._onColumnClick,
          data: "number",
          styles: {
            cellTitle: {
              justifyContent: "center",
              backgroundColor: "#FDEEDE",
              selectors: {
                "&:hover": {
                  backgroundColor: "#FFEAD4",
                },
              },
            },
          },

          onRender: (item: MeritEmployee) =>
            `${Number(item.percEnquadramentoAprovado).toFixed(2)}%`,
        },
        {
          key: "column43",
          name: "%Mér.",
          fieldName: "percMeritoAprovado",
          minWidth: 50,
          maxWidth: 50,
          isRowHeader: true,
          isResizable: true,
          isSortedDescending: false,
          sortAscendingAriaLabel: "Sorted A to Z",
          sortDescendingAriaLabel: "Sorted Z to A",
          onColumnClick: this._onColumnClick,
          data: "number",
          styles: {
            cellTitle: {
              justifyContent: "center",
              backgroundColor: "#FDEEDE",
              selectors: {
                "&:hover": {
                  backgroundColor: "#FFEAD4",
                },
              },
            },
          },
          onRender: (item: MeritEmployee) =>
            `${Number(item.percMeritoAprovado).toFixed(2)}%`,
        },
        {
          key: "column44",
          name: "Total",
          fieldName: "percTotalAprovado",
          minWidth: 50,
          maxWidth: 50,
          isRowHeader: true,
          isResizable: true,
          isSortedDescending: false,
          sortAscendingAriaLabel: "Sorted A to Z",
          sortDescendingAriaLabel: "Sorted Z to A",
          onColumnClick: this._onColumnClick,
          data: "number",
          styles: {
            cellTitle: {
              justifyContent: "center",
              backgroundColor: "#FADFC2",
              selectors: {
                "&:hover": {
                  backgroundColor: "#FBD6AF",
                },
              },
            },
          },
          onRender: (item: MeritEmployee) =>
            `${Number(item.percTotalAprovado).toFixed(2)}%`,
        },
        {
          key: "column45",
          name: "Salário Aprovado",
          fieldName: "salarioAprovado",
          minWidth: 150,
          maxWidth: 150,
          isRowHeader: true,
          isResizable: true,
          isSortedDescending: false,
          sortAscendingAriaLabel: "Sorted A to Z",
          sortDescendingAriaLabel: "Sorted Z to A",
          onColumnClick: this._onColumnClick,
          data: "number",
          styles: {
            cellTitle: {
              backgroundColor: "#FDEEDE",
              justifyContent: "flex-end",
              selectors: {
                "&:hover": {
                  backgroundColor: "#FFEAD4",
                },
              },
            },
          },
          onRender: (item: MeritEmployee) =>
            toCurrency(item.salarioAprovado),
        },
        {
          key: "column46",
          name: "% Faixa",
          fieldName: "faixaAprovada",
          minWidth: 100,
          maxWidth: 100,
          isRowHeader: true,
          isResizable: true,
          isSortedDescending: false,
          sortAscendingAriaLabel: "Sorted A to Z",
          sortDescendingAriaLabel: "Sorted Z to A",
          onColumnClick: this._onColumnClick,
          data: "number",
          styles: {
            cellTitle: {
              backgroundColor: "#FDEEDE",
              justifyContent: "center",
              selectors: {
                "&:hover": {
                  backgroundColor: "#FFEAD4",
                },
              },
            },
          },
          onRender: (item: MeritEmployee) => (
            <Text
              style={{ color: this.handleColorFaixa(item.faixaAprovada) }}
            >{`${Number(item.faixaAprovada).toFixed(2)}%`}</Text>
          ),
        },
        {
          key: "column47",
          name: "Saldo Orçado",
          fieldName: "saldoOrcamento",
          minWidth: 100,
          maxWidth: 100,
          isRowHeader: true,
          isResizable: true,
          isSortedDescending: false,
          sortAscendingAriaLabel: "Sorted A to Z",
          sortDescendingAriaLabel: "Sorted Z to A",
          onColumnClick: this._onColumnClick,
          styles: {
            cellTitle: {
              backgroundColor: "#FDEEDE",
              justifyContent: "flex-end",
              selectors: {
                "&:hover": {
                  backgroundColor: "#FFEAD4",
                },
              },
            },
          },
          onRender: (item: MeritEmployee) => {
            return (
              <Stack horizontal verticalAlign="baseline">
                <Text>{toCurrency(item.saldoOrcamento ?? 0)}</Text>
                {Number(item.saldoOrcamento) < 0 && (
                  <FontIcon
                    iconName="CircleFill"
                    style={{
                      fontSize: 10,
                      color: Colors.red,
                      marginLeft: 8,
                    }}
                  />
                )}
              </Stack>
            );
          },
        },
      ],
    ];

    if (prevState.showBudget !== this.state.showBudget) {
      if (this.state.showBudget) {
        if (this.props.merits.data?.controls.habilitaBaixarCarta === false) {
          this.setState({
            columns: orcColumns
          });
        } else {
          this.setState({
            columns: [...orcColumns,
            {
              key: "column48",
              name: "Devolutiva",
              fieldName: "devolutivaMerito",
              minWidth: 100,
              maxWidth: 100,
              isRowHeader: true,
              isResizable: true,
              isSortedDescending: false,
              sortAscendingAriaLabel: "Sorted A to Z",
              sortDescendingAriaLabel: "Sorted Z to A",
              onColumnClick: this._onColumnClick,
              data: "number",
              styles: {
                cellTitle: {
                  justifyContent: "center",
                  backgroundColor: "#FDEEDE",
                  selectors: {
                    "&:hover": {
                      backgroundColor: "#FFEAD4",
                    },
                  },
                },
              },
              onRender: (item: MeritEmployee) => <IconButton
                iconProps={{ iconName: "DownloadDocument" }}
                onClick={() =>
                  this.handleBaixarCarta(item.idMeritoFuncionario)
                }
              />,
            }
            ]
          });
        }
      } else {
        this.setState({
          columns: this.state.columns.filter((_, i, arr) => {
            if (this.props.merits.data?.controls.habilitaBaixarCarta === false) {
              return i < 40
            }
            return i < 40 || i + 1 == arr.length
          }),
        });
      }
    }

    if (prevProps.merits.data?.controls.habilitaBaixarCarta !== this.props.merits.data?.controls.habilitaBaixarCarta) {
      if (this.props.merits.data?.controls.habilitaBaixarCarta) {
        this.setState({
          columns: [
            ...this.state.columns,
            ...[
              {
                key: "column48",
                name: "Devolutiva",
                fieldName: "devolutivaMerito",
                minWidth: 100,
                maxWidth: 100,
                isRowHeader: true,
                isResizable: true,
                isSortedDescending: false,
                sortAscendingAriaLabel: "Sorted A to Z",
                sortDescendingAriaLabel: "Sorted Z to A",
                onColumnClick: this._onColumnClick,
                data: "number",
                styles: {
                  cellTitle: {
                    justifyContent: "center",
                    backgroundColor: "#FDEEDE",
                    selectors: {
                      "&:hover": {
                        backgroundColor: "#FFEAD4",
                      },
                    },
                  },
                },
                onRender: (item: MeritEmployee) => <IconButton
                  iconProps={{ iconName: "DownloadDocument" }}
                  onClick={() =>
                    this.handleBaixarCarta(item.idMeritoFuncionario)
                  }
                />,
              },
            ],
          ],
        });
      } else {
        const maxColumnsAtTheMoment = 40 + (this.state.showBudget ? 7 : 0)
        this.setState({
          columns: this.state.columns.filter((_, i) => i < maxColumnsAtTheMoment),
        });
      }
    }

    if (prevState.isFiltering !== this.state.isFiltering) {
      if (this.state.isFiltering) {
        const budgetAprovado = this.state.items.reduce((total, item) => total + Number(item.valorTotalAprovado), 0)
        const valorUsado = this.state.items.reduce((total, item) => total + Number(item.valorUtilizadoReal), 0)
        const saldo = this.state.items.reduce((total, item) => total + Number(item.saldoOrcamento), 0)
        const saldoPercent = Math.round((valorUsado / budgetAprovado) * 100) ?? 0

        this.setState({
          budgetFiltered: {
            budgetAprovado,
            valorUsado,
            saldo,
            saldoPercent
          }
        })
      } else {
        this.setState({
          budgetFiltered: {
            budgetAprovado: 0,
            valorUsado: 0,
            saldo: 0,
            saldoPercent: 0
          }
        })
      }
    }
  }

  loadMerit = () => {
    const idMerito = this.props.match.params?.idMerito;

    const itemsBreadCrumb: BreadcrumbItems[] = []

    if (this.props.match.params?.idMerito) {
      itemsBreadCrumb.push(
        {
          text: "Home",
          isCurrentItem: false,
          icon: "HomeSolid",
          onlyIcon: true,
          url: "/",
        },
        { text: "Geração de Mérito", url: "/merito" },
        { text: "Mérito", isCurrentItem: true },
      )
    } else {
      itemsBreadCrumb.push(
        {
          text: "Home",
          isCurrentItem: false,
          icon: "HomeSolid",
          onlyIcon: true,
          url: "/",
        },
        { text: "Painel Workflow", url: "/workflow" },
        { text: "Mérito", isCurrentItem: true },
      )

    }

    const page: Page = {
      key: "merito",
      pages: itemsBreadCrumb,
    };
    this.props.setCurrentPage(page);

    if (idMerito) {
      this.props.getMeritById(idMerito);
      this.clearFilter();
    }
  };

  handleBaixarCarta = (idMeritoFuncionario: number) => {
    const idMerito = this.props.match.params?.idMerito || this.props.merits.data?.merito.idMerito;
    if (idMerito) {
      this.props.downloadCarta(idMerito, idMeritoFuncionario);
    }
  };

  // Submit do formulário Formik
  handleSubmit = () => {
    if (this.formRef.current) {
      this.formRef.current.handleSubmit();
    }
  };

  handleSubmitNovoCargo = () => {
    if (this.formNovoCargoRef.current) {
      this.formNovoCargoRef.current.handleSubmit();
    }
  };

  handleSubmitBudget = () => {
    if (this.formEditBudgetRef.current) {
      this.formEditBudgetRef.current.handleSubmit();
    }
  };

  _onItemInvoked = (item: AttitudeType): void => { };

  _getSelectionDetails(): any {
    const selectionCount = this._selection.getSelectedCount();
    this._selection.getSelection();
  }

  clearFilter = () => {
    this.setState({
      isOpenFilter: !this.state.isOpenFilter,
    });
    this.setState({
      filter: {
        gestor: "",
        cargo: "",
        area: null,
        tempo: "",
        categoriasCargos: this.props.categoriasCargos.data.map(
          (item) => item.idCategoriaCargo
        ),
        idTabPerformance: "",
        search: "",
        cc: null,
        flagElegivel: "Todos",
      },
      isFiltering: false,
    });
  };

  handleSearch = (e: React.KeyboardEvent<Element>) => {
    if (e.key === "Enter") {
      this.handleFilter();
    }
  };

  handleToggleMerito = (meritoFunc: MeritEmployee) => {
    const { idMeritoFuncionario, flagPercMeritoEdit } = meritoFunc;
    this.handleEditMerit({
      idMeritoFuncionario,
      flagPercMeritoEdit: !flagPercMeritoEdit,
    });
  };

  handleTogglePercPromocao = (meritoFunc: MeritEmployee) => {
    const { idMeritoFuncionario, flagPercPromocaoEdit } = meritoFunc;
    this.handleEditMerit({
      idMeritoFuncionario,
      flagPercPromocaoEdit: !flagPercPromocaoEdit,
    });
  };

  handleToggleEnq = (meritoFunc: MeritEmployee) => {
    const { idMeritoFuncionario, flagPercEnqEdit } = meritoFunc;
    this.handleEditMerit({
      idMeritoFuncionario,
      flagPercEnqEdit: !flagPercEnqEdit,
    });
  };

  handleAplicarMerito = (isChecked: boolean, meritoFunc: MeritEmployee) => {
    const idMerito = this.props.merits.data?.merito.idMerito!;
    const { idMeritoFuncionario } = meritoFunc;

    if (isChecked) {
      this.props.aplicar(idMerito, idMeritoFuncionario);
    } else {
      this.props.ignorar(idMerito, idMeritoFuncionario);
    }
  };

  handlePromover = (isChecked: boolean, meritoFunc: MeritEmployee) => {
    const idMerito = this.props.merits.data?.merito.idMerito!;
    const {
      idMeritoFuncionario,
      idCargoDestino,
      cargoOrigem,
      idESDestino,
      gsDestino,
      tituloCargoDestino,
    } = meritoFunc;

    if (isChecked) {
      if (cargoOrigem.cargosuperior) {
        this.props.atualizaPromocao(idMerito, {
          idMeritoFuncionario,
          flagPromover: true,
          idCargoDestino: cargoOrigem.cargosuperior.idCargo ?? 0,
          idESDestino: Number(idESDestino) ?? 0,
          gsDestino: cargoOrigem.cargosuperior.gs,
          tituloCargoDestino: cargoOrigem.cargosuperior.titulo,
        });
      } else {
        customToast.error("Promoção não habilitada para o funcionário");
      }
    } else {
      this.props.atualizaPromocao(idMerito, {
        idMeritoFuncionario,
        flagPromover: false,
        idCargoDestino,
        idESDestino: Number(idESDestino) ?? 0,
        gsDestino: Number(gsDestino) ?? 0,
        tituloCargoDestino,
      });
    }
  };

  handleEditNovoCargo = (
    novoCargo: PositionTypes,
    meritoFunc: MeritEmployee
  ) => {
    const idMerito = this.props.merits.data?.merito.idMerito!;
    const { idESDestino, idMeritoFuncionario } = meritoFunc;

    this.props.atualizaPromocao(idMerito, {
      idMeritoFuncionario,
      flagPromover: true,
      idCargoDestino: novoCargo.idCargo,
      idESDestino: Number(idESDestino) ?? 0,
      gsDestino: novoCargo.gs ?? 0,
      tituloCargoDestino: novoCargo.titulo,
    });
  };

  handleFuncFinalizado = (
    flagFinalizado: boolean,
    meritoFunc: MeritEmployee
  ) => {
    const idMerito = this.props.merits.data?.merito.idMerito!;
    const {
      idMeritoFuncionario,
      novoSalario,
      salarioSugerido,
      comentarios,
    } = meritoFunc;

    if (flagFinalizado) {
      this.props.finalizar(idMerito, idMeritoFuncionario);
    } else {
      this.props.reativar(idMerito, idMeritoFuncionario);
    }
  };

  handleEditComentario = (comentario: string) => {
    const idMerito = this.props.merits.data?.merito.idMerito!;
    const { idMeritoFuncionario } = this.state.meritoFuncionarioSelected!;

    this.props.atualizaComentarios(idMerito, idMeritoFuncionario, comentario);

    this.setState({
      comentarioEdit: {
        isOpen: false,
        comentario: "",
      },
      meritoFuncionarioSelected: null,
    });
  };

  handlePercEnquadramento = (
    percEnquadramento: number,
    meritoFunc: MeritEmployee
  ) => {
    const idMerito = this.props.merits.data?.merito.idMerito!;
    const { idMeritoFuncionario, percMerito, percPromocao } = meritoFunc;

    this.props.atualizaPercentual(
      idMerito,
      idMeritoFuncionario,
      percEnquadramento,
      percMerito,
      Number(percPromocao)
    );
  };

  handlePercMerito = (percMerito: number, meritoFunc: MeritEmployee) => {
    const idMerito = this.props.merits.data?.merito.idMerito!;
    const { idMeritoFuncionario, percEnquadramento, percPromocao } = meritoFunc;

    this.props.atualizaPercentual(
      idMerito,
      idMeritoFuncionario,
      percEnquadramento,
      percMerito,
      Number(percPromocao)
    );
  };

  handleEditPercPromocao = (
    percPromocao: number,
    meritoFunc: MeritEmployee
  ) => {
    const idMerito = this.props.merits.data?.merito.idMerito!;
    const { idMeritoFuncionario, percEnquadramento, percMerito } = meritoFunc;

    this.props.atualizaPercentual(
      idMerito,
      idMeritoFuncionario,
      percEnquadramento,
      percMerito,
      Number(percPromocao)
    );
  };

  handleChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    const search = e.target.value;
    this.setState({ search });
  };

  handleEditMerit = ({ ...meritEmployee }: Partial<MeritEmployee>) => {
    const idMerito = this.props.merits.data?.merito.idMerito!;
    this.props.editMerit(idMerito, { ...meritEmployee });
  };

  handleNovoSalario = () => {
    const idMerito = this.props.merits.data?.merito.idMerito!;
    const { funcionario, salario } = this.state.editSalario;
    const { salarioNovo } = salario ?? {};

    this.props.atualizaSalario(
      idMerito,
      funcionario?.idMeritoFuncionario!,
      salarioNovo!
    );

    this.setState({
      editSalario: {
        ...this.state.editSalario,
        isOpen: false,
      },
    });
  };

  updateSalario = (salarioNovo: number = 0) => {
    const { salarioAtual = 1, valorMedio } =
      this.state.editSalario.salario ?? {};
    var vAux = (Number(salarioNovo) * 100) / salarioAtual;

    const propostaPerc = (vAux - 100).toFixed(2);
    let vFaixa = (Number(salarioNovo) * 100) / (valorMedio ?? 1);
    const propostaFaixa = vFaixa.toFixed(2);

    this.setState({
      editSalario: {
        ...this.state.editSalario,
        salario: {
          ...this.state.editSalario.salario!,
          salarioAtual: salarioAtual,
          faixa: Number(propostaFaixa),
          reajuste: Number(propostaPerc),
          salarioNovo: salarioNovo,
        },
      },
    });
  };

  updateReajuste = (reajuste: number = 0) => {
    const { salarioAtual = 1, valorMedio } =
      this.state.editSalario.salario ?? {};

    const vAux = Number(salarioAtual) + (Number(salarioAtual) * reajuste) / 100;
    const propostaSalario = arredondaSalario(vAux);
    const vFaixa = (propostaSalario * 100) / (valorMedio ?? 1);
    const propostaFaixa = vFaixa.toFixed(2);

    this.setState({
      editSalario: {
        ...this.state.editSalario,
        salario: {
          ...this.state.editSalario.salario!,
          salarioAtual: salarioAtual,
          faixa: Number(propostaFaixa),
          reajuste: reajuste,
          salarioNovo: propostaSalario,
        },
      },
    });
  };

  updateFaixa = (faixa: number = 0) => {
    const { salarioAtual = 1, valorMedio } =
      this.state.editSalario.salario ?? {};

    const vAux2 = (faixa * (valorMedio ?? 1)) / 100;
    const propostaSalario = arredondaSalario(vAux2);
    var vAux = (propostaSalario * 100) / Number(salarioAtual);
    const propostaPerc = Number(vAux.toFixed(2)) - 100;

    this.setState({
      editSalario: {
        ...this.state.editSalario,
        salario: {
          ...this.state.editSalario.salario!,
          salarioAtual: salarioAtual,
          faixa: faixa,
          reajuste: Number(propostaPerc.toFixed(2)),
          salarioNovo: propostaSalario,
        },
      },
    });
  };

  handleColorFaixa = (faixa: number): string => {
    const { steps } = this.props.merits.data ?? {};
    const piso = steps?.[0].percent;
    const teto = steps?.[steps.length - 1].percent;
    let cor = "#323130";

    if (piso && teto) {
      if (faixa < Number(piso) || faixa > Number(teto)) {
        cor = Colors.error;
      } else if (faixa > 100) {
        cor = Colors.greenDarker;
      }
    }

    return cor;
  };

  calcValorUtilizado = () => {
    const { funcionarios } = this.props.merits.data ?? {};
    const { Meses = 0, percEncargos = 0 } =
      this.props.merits.data?.config ?? {};

    const valorUtilizado =
      funcionarios?.reduce((total, item) => {
        let valor = 0;

        valor = Number(item.valorUtilizadoReal);

        // if (item.aplicarMerito) {
        //   valor = arredondaSalario(
        //     Number(item.novoSalario) * Meses * (percEncargos / 100 + 1) -
        //       Number(item.salarioRef) * Meses * (percEncargos / 100 + 1),
        //     2
        //   );
        // }

        return (total += valor);
      }, 0) ?? 0;

    return valorUtilizado;
  };

  // calcSaldo = () => {
  //   const { budgetAprovado = 0 } =
  //     this.props.merits.data?.funcionarios.resumo ?? {};
  //   return budgetAprovado - this.calcValorUtilizado();
  // };

  // calcSaldoPerc = () => {
  //   const { budgetAprovado = 0 } =
  //     this.props.merits.data?.funcionarios.resumo ?? {};
  //   return Number((100 - (this.calcSaldo() / budgetAprovado) * 100).toFixed(2));
  // };

  compareString = (item: string, itemCompared: string): boolean =>
    item.toLowerCase().indexOf(itemCompared.toLowerCase()) > -1;

  setFilter = (
    field: string,
    value: string | number | number[] | Object | null
  ) => {
    if (field === "categoria") {
      this.setState({
        filter: {
          ...this.state.filter,
          categoriasCargos: value as number[],
        },
      });
    } else {
      this.setState({
        filter: {
          ...this.state.filter,
          [field]: value,
        },
      });
    }
  };

  handleFilter = () => {
    const { funcionarios } = this.props.merits.data ?? {};
    const { idAreaTreeViewSelected } = this.state;
    const {
      gestor,
      cargo,
      area,
      tempo,
      idTabPerformance,
      search,
      cc,
      flagElegivel,
      categoriasCargos,
    } = this.state.filter;

    let result = funcionarios ?? [];

    result = result?.filter((item) =>
      this.compareString(item.cargoOrigem.titulo, cargo)
    );

    result = result?.filter((item) =>
      this.compareString(item.gestor?.nomeCompleto ?? "", gestor)
    );

    if (!!search) {
      if (Number(search)) {
        result = result?.filter(
          (item) => item.funcionario.matricula == Number(search)
        );
      } else {
        result = result?.filter((item) =>
          this.compareString(item.funcionario.nomeCompleto, search)
        );
      }
    }

    if (Number(tempo)) {
      result = result?.filter((item) => item.tempoCargo > Number(tempo));
    }

    if (idTabPerformance) {
      result = result?.filter(
        (item) => Number(item.idTabPerformance) === Number(idTabPerformance)
      );
    }

    if (area?.idArea !== null && area?.idArea !== undefined) {
      if (area?.idArea === 0) {
        result = result?.filter((item) => {
          return idAreaTreeViewSelected.some((area) => {
            return item.idArea === area.idArea;
          });
        });
      } else {
        result = result?.filter((item) => item.idArea === area?.idArea);
      }
    }

    if (cc?.nomeCC) {
      result = result?.filter((item) => item.cc?.nomeCC === cc?.nomeCC);
    }
    if (categoriasCargos.length > 0) {
      result = result?.filter((item) => {
        return categoriasCargos.find(
          (idCategoriaCargo) =>
            Number(idCategoriaCargo) === item.cargoOrigem.idCategoriaCargo
        );
      });
    }

    result = result?.filter((item) => {
      if (flagElegivel === "Todos") return true;
      if (flagElegivel === "Sim") return item.flagElegivel;
      if (flagElegivel === "Nao") return !item.flagElegivel;
    });

    this.setState({ items: result, isFiltering: true });
  };

  search = (text: string, type: string) => {
    if (text.trim()) {
      clearTimeout(this.timeout);
      this.timeout = window.setTimeout(() => {
        if (type === "area") {
          this.props.getChart(text, true, false);
        } else if (type === "cc") {
          this.props.getCC(text);
        } else {
          this.props.getPositions(text, false, true);
        }
      }, 700);
    }
  };

  render() {
    const {
      isFiltering,
      items,
      filter,
      columns,
      search,
      isSearchPositionOpen,
      meritoFuncionarioSelected,
      isOpenFilter,
      editSalario,
      comentarioEdit,
      isDialogTreeViewOpen,
      idAreaTreeViewSelected,
      itemAreaTreeViewSelected,
      isModalPromoverOpen,
      initialMerito,
      novoCargoSelected,
      isSearchPositionFormOpen,
      isDialogNovoCargoOpen,
      dialogOcorrencias,
      dialogSalario,
      showBudget,
      dialogAvaliacoes,
      isDialogSolicitarAprovacaoOpen,
      comentarioFinal,
      isDialogBudgetOpen,
      dialogCargos,
      isDialogAprovarSolicitacaoOpen,
      isDialogReprovarSolicitacaoOpen,
      isDialogFinalizarOpen,
      isDialogReabrirOpen,
      budgetFiltered
    } = this.state;
    const {
      merits,
      areas,
      positions,
      cc,
      categoriasCargos,
      solicitarAprovacao,
      getBudgets,
      editBudget,
      aprovarMerito,
      reprovarMerito,
      finalizarReuniao,
      reabrirReuniao,
      clearListaCargos,
      clearListaSalarios,
      clearListaOcorrencias,
      clearListaAvaliacoes
    } = this.props;
    const { merito, performances, segurancaMerito, controls } =
      merits.data ?? {};
    const { funcionarios: funcData, saldo } = merits.data ?? {};

    const {
      habilitaFinalizarMerito,
      permiteEditarSaldo,
      habilitaSolicitacarAprovacao,
      habilitaAprovarReprovar
    } = controls ?? {};

    const idMerito = this.props.match.params?.idMerito || this.props.merits.data?.merito.idMerito;
    return (
      <>
        <Wrapper>
          <PainelHeader
            title={`Mérito ${merito?.area.nomeLocal ?? ""} ${merito?.ano ?? ""
              }`}
            icon={{ icon: "Teamwork" }}
            labelGroups={[
              {
                icon: "DateTime",
                label: "Data de Referência",
                value: merito?.dataReferencia
                  ? moment(merito?.dataReferencia).format("DD/MM/YYYY")
                  : "",
                size: "half",
              },
              {
                icon: "DateTime",
                label: "Data da Reunião",
                value: merito?.dataReuniao
                  ? `${moment(merito?.dataReuniao).format("DD/MM/YYYY")}`
                  : "",
                size: "half",
              },
              {
                icon: "Flag",
                label: "Status",
                value: merito?.status
                  ? `${merito?.status}${merito?.status === "Em andamento" ? ` - ${funcData?.filter((item) => item.flagFinalizado).length
                    } / ${funcData?.length}` : ''}`
                  : "",
              },
            ]}
            button={
              habilitaSolicitacarAprovacao ?
                {
                  text: "Solicitar aprovação",
                  handleButton: () =>
                    this.setState({ isDialogSolicitarAprovacaoOpen: true }),
                }
                :
                null
            }
            multiButton={[
              {
                isVisible: habilitaAprovarReprovar,
                text: "Aprovar",
                handleButton: () => this.setState({ isDialogAprovarSolicitacaoOpen: true })
              },
              {
                isVisible: habilitaAprovarReprovar,
                text: "Reprovar",
                variant: 'delete',
                handleButton: () => this.setState({ isDialogReprovarSolicitacaoOpen: true })
              },
              {
                isVisible: habilitaFinalizarMerito,
                text: "Finalizar",
                handleButton: () => this.setState({ isDialogFinalizarOpen: true })
              },
              {
                isVisible: habilitaFinalizarMerito,
                text: "Reabrir",
                variant: 'outline',
                handleButton: () => this.setState({ isDialogReabrirOpen: true })
              }
            ]}
          />
          <Pivot aria-label="Basic Pivot Example" styles={pivotStyles}>
            <PivotItem headerText="Funcionários">
              <ContainerContent>
                <Stack
                  horizontal
                  verticalAlign="center"
                  styles={{ root: { marginBottom: 20 } }}
                >
                  <InputText
                    label="Entre com o id ou o nome para a busca"
                    value={filter.search}
                    onChange={(e) => this.setFilter("search", e.target.value)}
                    onKeyUp={(e: React.KeyboardEvent<Element>) =>
                      this.handleSearch(e)
                    }
                    variant="outlined"
                    height_container={40}
                    fullWidth
                    size="small"
                    smaller="small"
                  />
                  <CommandBarButton
                    styles={{
                      root: {
                        height: 40,
                        marginLeft: "15px !important",
                        padding: "0 5px",
                        minWidth: 100,
                      },
                    }}
                    iconProps={{ iconName: "Refresh" }}
                    text="Atualizar"
                    onClick={this.loadMerit}
                  />
                  <MenuDropdown close={isOpenFilter}>
                    <Stack>
                      <Stack horizontal horizontalAlign="space-between">
                        <Label styles={{ root: { fontSize: 16 } }}>
                          Filtros
                        </Label>
                        <IconButton
                          iconProps={{ iconName: "ClearFilter" }}
                          title="Limpar filtro"
                          ariaLabel="Limpar filtro"
                          onClick={this.clearFilter}
                        />
                      </Stack>
                      <InputText
                        label="Gestor"
                        type="text"
                        value={filter.gestor}
                        onChange={(e) =>
                          this.setFilter("gestor", e.target.value)
                        }
                        autoFocus
                      />
                      <InputText
                        label="Cargo de Origem"
                        type="text"
                        value={filter.cargo}
                        onChange={(e) =>
                          this.setFilter("cargo", e.target.value)
                        }
                      />
                      <FormControl style={{ height: 70 }}>
                        <InputLabel>Categoria de cargos</InputLabel>
                        <Select
                          label="Categoria de cargos"
                          value={filter.categoriasCargos}
                          multiple
                          onChange={(e) =>
                            this.setFilter(
                              "categoria",
                              e.target.value as number[]
                            )
                          }
                          renderValue={(selected) => {
                            const categorias = categoriasCargos.data
                              .filter((item) => {
                                return (selected as any[]).some(
                                  (sel) => sel === item.idCategoriaCargo
                                );
                              })
                              .map((item) => item.descCategoriaCargo);
                            return (categorias as string[]).join(", ");
                          }}
                        >
                          {categoriasCargos.data.map((item, i) => (
                            <MenuItem
                              key={i}
                              value={item.idCategoriaCargo}
                              style={{ height: 40 }}
                            >
                              <Checkbox
                                checked={
                                  filter.categoriasCargos
                                    .find(
                                      (cat) => cat === item.idCategoriaCargo
                                    )
                                    ?.toString()
                                    ? true
                                    : false
                                }
                                style={{ color: Colors.primary }}
                              />
                              <ListItemText primary={item.descCategoriaCargo} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <InputAutocomplete
                        value={filter.cc as CCType}
                        onChange={(_, newValue) => {
                          this.setFilter("cc", newValue);
                        }}
                        onInputChange={(_, newInputValue) => {
                          this.search(newInputValue, "cc");
                        }}
                        getOptionLabel={(cc: CCType) =>
                          `${cc.idCC} - ${cc.nomeCC}`
                        }
                        getOptionSelected={(option, value) => {
                          return option.idCC === value.idCC;
                        }}
                        options={cc.data}
                        input={{
                          idInput: "cc",
                          labelInput: "Centro de Custo",
                        }}
                      />
                      <WrapperAutoComplete>
                        <InputAutocomplete
                          value={filter.area as ChartType}
                          onChange={(_, newValue) => {
                            this.setFilter("area", newValue);
                          }}
                          onInputChange={(_, newInputValue) => {
                            this.search(newInputValue, "area");
                          }}
                          getOptionLabel={(area: ChartType) => {
                            if (area.nomeLocal) {
                              return area.nomeLocal;
                            } else {
                              return "";
                            }
                          }}
                          getOptionSelected={(option, value) => {
                            return option.idArea === value.idArea;
                          }}
                          options={areas.data}
                          input={{
                            idInput: "area",
                            labelInput: "Área",
                          }}
                        />
                        <IconButton
                          iconProps={{ iconName: "Org" }}
                          styles={IconButtonStyle}
                          onClick={() => {
                            this.props.getChart("", true, true);
                            this.setState({ isDialogTreeViewOpen: true });
                          }}
                        />
                      </WrapperAutoComplete>
                      <InputText
                        label="Tempo mínimo de empresa"
                        type="number"
                        value={filter.tempo ?? ""}
                        onChange={(e) =>
                          this.setFilter("tempo", e.target.value)
                        }
                        helperText="Meses"
                      />

                      <Dropdown
                        label="Performance calibrada"
                        name="perfomance"
                        values={filter.idTabPerformance}
                        handleChange={(e: any) =>
                          this.setFilter("idTabPerformance", e.target.value)
                        }
                      >
                        <MenuItem value={""}>Nenhum</MenuItem>
                        {performances?.map((item) => (
                          <MenuItem
                            key={`${item.idTabPerformance}`}
                            value={`${item.idTabPerformance}`}
                          >
                            {item.descNivel}
                          </MenuItem>
                        ))}
                      </Dropdown>

                      <Dropdown
                        label="Elegíveis"
                        name="elegiveis"
                        values={filter.flagElegivel}
                        handleChange={(e: any) =>
                          this.setFilter("flagElegivel", e.target.value)
                        }
                      >
                        <MenuItem value={"Todos"}>Todos</MenuItem>
                        <MenuItem value={"Sim"}>Sim</MenuItem>
                        <MenuItem value={"Nao"}>Não</MenuItem>
                      </Dropdown>
                      <PrimaryButton
                        text="Aplicar Filtro"
                        styles={{
                          root: {
                            width: 125,
                            alignSelf: "flex-end",
                          },
                        }}
                        onClick={() => {
                          this.handleFilter();
                          this.setState({ isOpenFilter: !isOpenFilter });
                        }}
                      />
                    </Stack>
                  </MenuDropdown>
                  {segurancaMerito?.permiteVerOrcamento && (
                    <InputCheckbox
                      checked={showBudget}
                      styles={{ margin: 0, fontSize: 14, minWidth: 183 }}
                      onChange={(e) =>
                        this.setState({ showBudget: e.target.checked })
                      }
                      label="Detalhes do orçamento"
                      size="small"
                    />
                  )}
                </Stack>

                <Accordion title="Resumo">
                  <Stack horizontal horizontalAlign="center">
                    <Card
                      title="Budget Aprovado"
                      value={isFiltering ? budgetFiltered.budgetAprovado : saldo?.budgetAprovado ?? 0}
                      iconName="Money"
                      bgColor="#F08F24"
                      subtitle={`${funcData?.length ?? ""} Funcionários`}
                      styles={{ marginRight: 30 }}
                      editActionButton={{
                        canEdit: permiteEditarSaldo ?? false,
                        // canEdit: permiteEditarSaldo ?? false, 
                        onClick: () => {
                          getBudgets(idMerito);
                          this.setState({ isDialogBudgetOpen: true });
                        }
                      }}
                    />
                    <Card
                      title="Valor Usado"
                      value={isFiltering ? budgetFiltered.valorUsado : saldo?.valorUsado ?? 0}
                      iconName="PaymentCard"
                      bgColor={Colors.primary}
                      subtitle={`${isFiltering ? budgetFiltered.saldoPercent ?? 0 : saldo?.percent ?? ""}% BUDGET`}
                      styles={{ marginRight: 30 }}
                    />
                    <Card
                      title="Saldo"
                      value={isFiltering ? budgetFiltered.saldo : saldo?.saldo ?? 0}
                      iconName="BarChart4"
                      color={Colors.primary}
                      percent={saldo?.percent}
                    />
                  </Stack>
                </Accordion>
                <Accordion
                  title="Funcionários"
                  styles={{ details: { marginBottom: 0 } }}
                >
                  <Stack
                    horizontal
                    verticalAlign="center"
                    styles={{ root: { height: 40 } }}
                  >
                    <Stack
                      horizontal
                      verticalAlign="center"
                      styles={{ root: { marginRight: 16 } }}
                    >
                      <Legenda bgColor="#F6F6F6" />
                      <Text styles={{ root: { fontSize: 11 } }}>
                        Performance
                      </Text>
                    </Stack>
                    <Stack
                      horizontal
                      verticalAlign="center"
                      styles={{ root: { marginRight: 16 } }}
                    >
                      <Legenda bgColor="#d3eadf" />
                      <Text styles={{ root: { fontSize: 11 } }}>
                        Recomendado
                      </Text>
                    </Stack>
                    <Stack
                      horizontal
                      verticalAlign="center"
                      styles={{ root: { marginRight: 16 } }}
                    >
                      <Legenda bgColor="#bedbca" />
                      <Text styles={{ root: { fontSize: 11 } }}>Proposta</Text>
                    </Stack>
                    <Stack
                      horizontal
                      verticalAlign="center"
                      styles={{ root: { marginRight: 16 } }}
                    >
                      <Legenda bgColor="#f8e8e8" />
                      <Text styles={{ root: { fontSize: 11 } }}>Política</Text>
                    </Stack>
                    {showBudget && (
                      <Stack horizontal verticalAlign="center">
                        <Legenda bgColor="#fdeede" />
                        <Text styles={{ root: { fontSize: 11 } }}>
                          Orçamento
                        </Text>
                      </Stack>
                    )}
                  </Stack>
                  {!merits.loadingData && funcData?.length === 0 ? (
                    <NoItems
                      error={merits.error}
                      text="Não há méritos cadastrados"
                      icon="Teamwork"
                    />
                  ) : (
                    <ListContainer>
                      <ShimmeredDetailsList
                        items={isFiltering ? items : funcData ?? []}
                        className="meritTable"
                        enableShimmer={merits.loadingData}
                        columns={columns}
                        selectionMode={SelectionMode.none}
                        selection={this._selection}
                        getKey={this._getKey}
                        selectionPreservedOnEmptyClick={true}
                        setKey="single"
                        useReducedRowRenderer={false}
                        constrainMode={ConstrainMode.unconstrained}
                        layoutMode={DetailsListLayoutMode.fixedColumns}
                        isHeaderVisible={true}
                        onRenderRow={this._onRenderRow}
                      />
                    </ListContainer>
                  )}
                </Accordion>
              </ContainerContent>
            </PivotItem>
            <PivotItem headerText="Resumo">
              <Resume idMerito={idMerito} />
            </PivotItem>
          </Pivot>
        </Wrapper>

        <SearchPosition
          isOpen={isSearchPositionOpen}
          onClose={() =>
            this.setState({
              meritoFuncionarioSelected: null,
              isSearchPositionOpen: false,
            })
          }
          onAdd={(value) =>
            this.handleEditNovoCargo(value[0], meritoFuncionarioSelected!)
          }
          optionButton={{
            text: "Escolher cargo não cadastrado",
            handleClick: () => this.setState({ isDialogNovoCargoOpen: true }),
          }}
        />

        <CustomDialog
          open={isDialogNovoCargoOpen}
          title="Novo Cargo"
          btnConfirmText="Salvar"
          onClickCancel={() => this.setState({ isDialogNovoCargoOpen: false })}
          width={420}
          isLoadingConfirm={merits.loadingAction}
          onClickConfirm={() => this.handleSubmitNovoCargo()}
        >
          <Formik
            innerRef={this.formNovoCargoRef}
            initialValues={initialNovoCargo}
            validationSchema={validationNovoCargoSchema}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={(values) => {
              const { idMeritoFuncionario, idESDestino } =
                meritoFuncionarioSelected ?? {};
              this.props.atualizaPromocao(idMerito, {
                flagPromover: true,
                tituloCargoDestino: values.tituloCargoDestino,
                gsDestino: values.gsDestino!,
                idCargoDestino: null,
                idESDestino: idESDestino!,
                idMeritoFuncionario: idMeritoFuncionario!,
              });
            }}
          >
            {({
              handleSubmit,
              handleChange,
              values,
              errors,
              setFieldValue,
              setFieldError,
            }) => (
              <form onSubmit={handleSubmit}>
                <InputText
                  label="Título"
                  id="tituloCargoDestino"
                  name="tituloCargoDestino"
                  value={values.tituloCargoDestino}
                  onChange={(e) => {
                    handleChange(e);
                    setFieldError("tituloCargoDestino", "");
                  }}
                  error={!!errors.tituloCargoDestino}
                  helperText={errors.tituloCargoDestino}
                />
                <InputNumber
                  label="GS"
                  value={values.gsDestino ? Number(values.gsDestino) : ""}
                  decimalScale={0}
                  onValueChange={(value) => {
                    setFieldValue("gsDestino", value.floatValue);
                    setFieldError("gsDestino", "");
                  }}
                  error={!!errors.gsDestino}
                  helperText={errors.gsDestino}
                />
              </form>
            )}
          </Formik>
        </CustomDialog>

        <CustomDialog
          open={editSalario.isOpen}
          title="Alteração Salarial"
          btnConfirmText="Salvar"
          onClickCancel={() =>
            this.setState({
              editSalario: {
                isOpen: false,
                funcionario: null,
                salario: null,
              },
            })
          }
          width={420}
          // disabledBtnConfirm={this._selection.getSelectedCount() === 0 || meetings.loadingAction}
          // isLoadingConfirm={isLoadingAction}
          onClickConfirm={this.handleNovoSalario}
        >
          <GroupItems>
            <GroupItemDoisTerco>
              <TitleField text="Funcionario" />
              <TitleField text={editSalario.funcionario?.nome ?? ""} isValue />
            </GroupItemDoisTerco>
          </GroupItems>
          <GroupItems>
            <GroupItemDoisTerco>
              <TitleField text="Salário Atual" />
              <TitleField
                text={toCurrency(editSalario.salario?.salarioAtual ?? "") ?? ""}
                isValue
              />
            </GroupItemDoisTerco>
          </GroupItems>
          <InputNumber
            label="Salário Proposto"
            value={editSalario.salario?.salarioNovo ?? ""}
            autoFocus
            onChange={(e) => this.updateSalario(toNumber(e.target.value))}
          />
          <InputNumber
            label="% Reajuste"
            value={editSalario.salario?.reajuste ?? ""}
            decimalScale={1}
            onChange={(e) => this.updateReajuste(toNumber(e.target.value))}
          />
          <InputNumber
            label="% Faixa"
            value={editSalario.salario?.faixa ?? ""}
            onChange={(e) => this.updateFaixa(toNumber(e.target.value))}
          />
        </CustomDialog>

        <DialogComentario
          isOpen={comentarioEdit.isOpen}
          onClose={() =>
            this.setState({
              comentarioEdit: {
                isOpen: false,
                comentario: "",
              },
              meritoFuncionarioSelected: null,
            })
          }
          onConfirm={this.handleEditComentario}
          comentario={comentarioEdit.comentario}
        />

        <CustomDialog
          open={isDialogTreeViewOpen}
          title="Escolher área"
          width={850}
          btnConfirmText="Definir"
          onClickCancel={() => this.setState({ isDialogTreeViewOpen: false })}
          onClickConfirm={() => {
            const area: Partial<ChartType> =
              idAreaTreeViewSelected.length === 1
                ? idAreaTreeViewSelected[0]!
                : {
                  idArea: 0,
                  nomeLocal: "Multiplos áreas",
                };

            this.setState({
              filter: {
                ...filter,
                area,
              },
            });

            this.setState({ isDialogTreeViewOpen: false });
          }}
          disabledBtnConfirm={itemAreaTreeViewSelected === null}
          height={500}
        >
          <TreeView
            columns={[
              {
                name: "Área",
                fieldName: "nomeLocal",
              },
            ]}
            selectionMode="multiple"
            state={areas}
            fieldId="idArea"
            changeItemsSelected={(items) => {
              this.setState({ idAreaTreeViewSelected: items });
            }}
          />
        </CustomDialog>

        <CustomDialog
          open={dialogSalario.isOpen}
          title={`Salários de ${dialogSalario.nomeCompleto}`}
          btnConfirmText="OK"
          width={640}
          height={375}
          onClickConfirm={() => {
            clearListaSalarios();
            this.setState({
              dialogSalario: {
                isOpen: false,
                nomeCompleto: "",
              },
            })
          }}
        >
          <ScrollablePane
            styles={{
              root: {
                top: 70,
                height: 240,
                margin: "0 20px",
              },
            }}
          >
            <GridSalarios
              salarios={merits.salariosHistory ?? []}
              isLoading={merits.loadingGridsHistory}
            />
          </ScrollablePane>
        </CustomDialog>
        <CustomDialog
          open={dialogCargos.isOpen}
          title={`Cargos de ${dialogCargos.nomeCompleto}`}
          btnConfirmText="OK"
          width={640}
          height={375}
          onClickConfirm={() => {
            clearListaCargos();
            this.setState({
              dialogCargos: {
                isOpen: false,
                nomeCompleto: "",
              },
            })
          }}
        >
          <ScrollablePane
            styles={{
              root: {
                top: 70,
                height: 240,
                margin: "0 20px",
              },
            }}
          >
            <GridCargos
              cargos={merits.cargosHistory ?? []}
              isLoading={merits.loadingGridsHistory}
            />
          </ScrollablePane>
        </CustomDialog>
        <CustomDialog
          open={dialogOcorrencias.isOpen}
          title={`Ocorrências de ${dialogOcorrencias.nomeCompleto}`}
          btnConfirmText="OK"
          width={640}
          height={375}
          onClickConfirm={() => {
            clearListaOcorrencias();
            this.setState({
              dialogOcorrencias: {
                isOpen: false,
                nomeCompleto: "",
              },
            })
          }}
        >
          <ScrollablePane
            styles={{
              root: {
                top: 70,
                height: 240,
                margin: "0 20px",
              },
            }}
          >
            <GridOcorrencias
              ocorrencias={merits.ocorrenciasHistory ?? []}
              isLoading={merits.loadingGridsHistory}
            />
          </ScrollablePane>
        </CustomDialog>
        <ModalEdit
          open={isModalPromoverOpen}
          onClickCancel={() => this.setState({ isModalPromoverOpen: false })}
          title="Promover"
          onClickConfirm={() => this.handleSubmit()}
          isLoadingConfirm={merits.loadingAction}
        >
          <GroupItems>
            <GroupItems>
              <GroupItemFull>
                <TitleField text="Funcionário" />
                <TitleField
                  text={initialMerito?.funcionario?.nomeCompleto ?? ""}
                  isValue
                />
              </GroupItemFull>
            </GroupItems>
            <GroupItems noMargins>
              <GroupItemDoisTerco>
                <TitleField text="Cargo Atual" />
                <TitleField
                  text={initialMerito?.cargoOrigem?.titulo ?? ""}
                  isValue
                />
              </GroupItemDoisTerco>
              <GroupItemUmTerco>
                <TitleField text="Grupo Salarial" />
                <TitleField text={initialMerito?.gsOrigem ?? ""} isValue />
              </GroupItemUmTerco>
            </GroupItems>
          </GroupItems>
          <Formik
            innerRef={this.formRef}
            initialValues={{
              ...initialMerito,
              percPromocao: initialMerito.tituloCargoDestino
                ? initialMerito.percPromocao
                : "",
              gsDestino: initialMerito.tituloCargoDestino
                ? initialMerito.gsDestino
                : "",
            }}
            validationSchema={validationPromoverSchema}
            validateOnChange={false}
            validateOnBlur={true}
            enableReinitialize
            onSubmit={(values) => {
              const {
                idMeritoFuncionario,
                tituloCargoDestino,
                idESDestino,
                idCargoDestino,
                gsDestino,
              } = values;
              this.props.atualizaPromocao(idMerito, {
                idMeritoFuncionario: idMeritoFuncionario!,
                flagPromover: true,
                gsDestino: Number(gsDestino)!,
                idCargoDestino: Number(idCargoDestino),
                idESDestino: Number(idESDestino),
                tituloCargoDestino: tituloCargoDestino ?? "",
              });
            }}
          >
            {({
              handleSubmit,
              values,
              errors,
              setFieldError,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <Stack styles={{ root: { width: 380, paddingRight: 10 } }}>
                  <InputText
                    label="Percentual Promoção"
                    type="number"
                    value={values.percPromocao}
                    onChange={(e) => {
                      setFieldValue("percPromocao", e.target.value);
                      setFieldError("percPromocao", "");
                    }}
                    error={!!errors.percPromocao}
                    helperText={errors.percPromocao}
                    autoFocus
                  />
                </Stack>
                <Stack horizontal>
                  <Stack styles={{ root: { width: 380, paddingRight: 10 } }}>
                    <WrapperAutoComplete>
                      <InputAutocomplete
                        value={novoCargoSelected as PositionTypes}
                        onChange={(_, newValue) => {
                          this.setState({ novoCargoSelected: newValue });
                          setFieldValue("tituloCargoDestino", newValue?.titulo);
                        }}
                        onInputChange={(_, newInputValue) => {
                          this.search(newInputValue, "cargo");
                        }}
                        getOptionLabel={(cargo: PositionTypes) => {
                          if (cargo.titulo) {
                            return cargo.titulo;
                          } else {
                            return "";
                          }
                        }}
                        getOptionSelected={(option, value) => {
                          return option.idCargo === value.idCargo;
                        }}
                        options={positions.data}
                        input={{
                          idInput: "tituloCargoDestino",
                          labelInput: "Novo Cargo",
                          errorInput: !!errors.tituloCargoDestino,
                          helperTextInput: errors.tituloCargoDestino,
                        }}
                      />
                      <IconButton
                        iconProps={{ iconName: "Search" }}
                        styles={IconButtonStyle}
                        onClick={() => {
                          this.props.getChart("", true, true);
                          this.setState({ isSearchPositionFormOpen: true });
                        }}
                      />
                    </WrapperAutoComplete>
                  </Stack>
                  <Stack styles={{ root: { width: 190 } }}>
                    <InputText
                      label="Grupo Salarial"
                      type="number"
                      value={novoCargoSelected?.gs ?? ""}
                      disabled={true}
                      error={!!errors.gsDestino}
                      helperText={errors.gsDestino}
                    />
                  </Stack>
                </Stack>

                <SearchPosition
                  isOpen={isSearchPositionFormOpen}
                  onClose={() =>
                    this.setState({
                      isSearchPositionFormOpen: false,
                    })
                  }
                  onAdd={(value) => {
                    const novoCargo: PositionTypes = value[0];
                    this.setState({
                      novoCargoSelected: novoCargo,
                    });
                    setFieldValue("tituloCargoDestino", novoCargo.titulo);
                    setFieldValue("idCargoDestino", novoCargo.idCargo);
                    setFieldError("tituloCargoDestino", "");
                    setFieldValue("gsDestino", novoCargo.gs);
                    setFieldError("gsDestino", "");
                  }}
                />
              </form>
            )}
          </Formik>
        </ModalEdit>
        {/* <DialogHistory
          isOpen={isOpenHistory}
          funcionario={funcionarioSelected}
          onClose={() => this.setState({ isOpenHistory: false })}
        /> */}
        <CustomDialog
          open={dialogAvaliacoes.isOpen}
          title={`Histórico de Performance: ${dialogAvaliacoes.nomeCompleto}`}
          btnConfirmText="OK"
          width={640}
          height={375}
          onClickConfirm={() => {
            clearListaAvaliacoes();
            this.setState({
              dialogAvaliacoes: {
                isOpen: false,
                nomeCompleto: "",
              },
            })
          }}
        >
          <ScrollablePane
            styles={{
              root: {
                top: 70,
                height: 240,
                margin: "0 20px",
              },
            }}
          >
            <GridAvaliacoes
              avaliacoes={merits.avaliacoesHistory ?? []}
              isLoading={merits.loadingGridsHistory}
              nomeCompleto={dialogAvaliacoes.nomeCompleto}
            />
          </ScrollablePane>
        </CustomDialog>
        <CustomDialog
          open={isDialogSolicitarAprovacaoOpen}
          title="Solicitar aprovação"
          onClickCancel={() =>
            this.setState({
              isDialogSolicitarAprovacaoOpen: false,
              comentarioFinal: { value: "", error: false },
            })
          }
          onClickConfirm={() => {
            if (comentarioFinal.value) {
              solicitarAprovacao(idMerito, comentarioFinal.value);
            } else {
              this.setState({
                comentarioFinal: { ...comentarioFinal, error: true },
              });
            }
          }}
          width={640}
        >
          <Stack horizontal horizontalAlign="space-between">
            <GroupItems
              noMargins
              stylesWrapperItems={{ justifyContent: "space-between" }}
            >
              <GroupItems title="Reunião" titleRegular>
                <TitleField text={merito?.area.nomeLocal} bold />
              </GroupItems>
            </GroupItems>
            <Card
              title="Saldo"
              value={saldo?.saldo ?? 0}
              iconName="BarChart4"
              color={Colors.primary}
              percent={saldo?.percent}
            />
          </Stack>
          <InputText
            name="comentarios"
            label="Comentários sobre o final do mérito"
            value={comentarioFinal.value}
            onChange={(e) =>
              this.setState({
                comentarioFinal: {
                  value: e.target.value,
                  error: false,
                },
              })
            }
            height_container="calc(100% + 20px)"
            multiline
            rows={3}
            rowsMax={3}
            error={comentarioFinal.error}
            helperText={comentarioFinal.error ? "Campo obrigatório" : " "}
          />

          <MessageBar
            styles={{ root: { marginTop: 10 } }}
            messageBarType={MessageBarType.severeWarning}
          >
            Sua reunião de mérito será submetido a aprovação dos responsáveis.
            Assim que aprovada, os gestores dos funcionários receberão uma
            tarefa de DEVOLUTIVA DO MÉRITO com as cartas geradas
          </MessageBar>
        </CustomDialog>

        <CustomDialog
          open={isDialogBudgetOpen}
          title="Adicionar / Subtrair Budget"
          btnConfirmText="Fechar"
          width={640}
          onClickConfirm={() => {
            this.setState({ isDialogBudgetOpen: false })
          }}
          height={600}
        >
          <Formik
            innerRef={this.formEditBudgetRef}
            initialValues={initialBudget}
            validationSchema={validationBudgetSchema}
            validateOnBlur={false}
            validateOnChange={false}
            enableReinitialize
            onSubmit={(values) => {
              editBudget(idMerito, values);
            }}
          >
            {({
              handleSubmit,
              handleChange,
              values,
              errors,
              setFieldValue,
              setFieldError,
            }) => (
              <form onSubmit={handleSubmit} style={{ marginBottom: 24 }}>
                <GridPositionFields>
                  <Stack styles={{ root: { width: 200 } }}>
                    <InputNumber
                      label="Valor a Adicionar / Subtrair"
                      value={values.valor ? Number(values.valor) : ""}
                      decimalScale={0}
                      onValueChange={(value) => {
                        setFieldValue("valor", value.floatValue);
                        setFieldError("valor", "");
                      }}
                      error={!!errors.valor}
                      helperText={errors.valor}
                      styles={{ gridArea: 'valor' }}
                      disabled={!permiteEditarSaldo ?? true}
                    />
                  </Stack>
                  <InputText
                    label="Observações"
                    id="comentarios"
                    name="comentarios"
                    value={values.comentarios}
                    onChange={(e) => {
                      handleChange(e);
                      setFieldError("comentarios", "");
                    }}
                    height_container="auto"
                    error={!!errors.comentarios}
                    helperText={errors.comentarios}
                    multiline
                    rows={3}
                    rowsMax={15}
                    styles={{ gridArea: 'comentarios' }}
                    disabled={!permiteEditarSaldo ?? true}
                  />
                  <Stack horizontalAlign="end">
                    <PrimaryButton
                      text="Adicionar"
                      onClick={this.handleSubmitBudget}
                      styles={{ root: { gridArea: 'btn', width: 110 } }}
                      isLoading={merits.loadingAction}
                      disabled={!permiteEditarSaldo ?? true}
                    />
                  </Stack>
                </GridPositionFields>

              </form>
            )}
          </Formik>
          <Text variant="medium" styles={{ root: { fontWeight: 'bold' } }}>HISTÓRICO</Text>
          <GridBudget
            budgets={merits.budgetHistory ?? []}
            isLoading={merits.loadingBudgetHistory}
          />
        </CustomDialog>

        <CustomDialogFluentUI.default
          hidden={!isDialogAprovarSolicitacaoOpen}
          onDismiss={() => this.setState({ isDialogAprovarSolicitacaoOpen: false })}
          dialogContentProps={dialogAprovarContentProps}
        >
          <DefaultButton
            onClick={() => this.setState({ isDialogAprovarSolicitacaoOpen: false })}
            text="Cancelar"
          />
          <PrimaryButton onClick={() => aprovarMerito(idMerito)} text="Aprovar" />
        </CustomDialogFluentUI.default>

        <CustomDialogFluentUI.default
          hidden={!isDialogReprovarSolicitacaoOpen}
          onDismiss={() => this.setState({ isDialogReprovarSolicitacaoOpen: false })}
          dialogContentProps={dialogReprovarContentProps}
        >
          <DefaultButton
            onClick={() => this.setState({ isDialogReprovarSolicitacaoOpen: false })}
            text="Cancelar"
          />
          <DeleteButton onClick={() => reprovarMerito(idMerito)} text="Reprovar" />
        </CustomDialogFluentUI.default>

        <CustomDialogFluentUI.default
          hidden={!isDialogFinalizarOpen}
          onDismiss={() => this.setState({ isDialogFinalizarOpen: false })}
          dialogContentProps={dialogFinalizarContentProps}
        >
          <DefaultButton
            onClick={() => this.setState({ isDialogFinalizarOpen: false })}
            text="Cancelar"
          />
          <PrimaryButton onClick={() => finalizarReuniao(idMerito)} text="Finalizar" />
        </CustomDialogFluentUI.default>

        <CustomDialogFluentUI.default
          hidden={!isDialogReabrirOpen}
          onDismiss={() => this.setState({ isDialogReabrirOpen: false })}
          dialogContentProps={dialogReabrirContentProps}
        >
          <DefaultButton
            onClick={() => this.setState({ isDialogReabrirOpen: false })}
            text="Cancelar"
          />
          <PrimaryButton onClick={() => reabrirReuniao(idMerito)} text="Reabrir" />
        </CustomDialogFluentUI.default>

      </>
    );
  }

  private _onRenderRow: IDetailsListProps["onRenderRow"] = (props) => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (props) {
      const stylesForRow = (detailsRowStyleProps: any) => {
        customStyles.root = {
          selectors: {
            // "& .ms-DetailsRow-fields": {
            //   opacity: props.item.aplicarMerito ? 1 : 0.5,
            // },
            "&:hover div.ms-DetailsRow-fields div.ms-DetailsRow-cell": {
              backgroundColor: "#F3F2F1",
            },
            "&:hover div.ms-DetailsRow-fields div.ms-DetailsRow-cell:nth-child(18), &:hover div.ms-DetailsRow-fields div.ms-DetailsRow-cell:nth-child(18), &:hover div.ms-DetailsRow-fields div.ms-DetailsRow-cell:nth-child(19), &:hover div.ms-DetailsRow-fields div.ms-DetailsRow-cell:nth-child(20), &:hover div.ms-DetailsRow-fields div.ms-DetailsRow-cell:nth-child(21), &:hover div.ms-DetailsRow-fields div.ms-DetailsRow-cell:nth-child(22)": {
              backgroundColor: "#EBEBEB",
            },
            "&:hover div.ms-DetailsRow-fields div.ms-DetailsRow-cell:nth-child(23), &:hover div.ms-DetailsRow-fields div.ms-DetailsRow-cell:nth-child(24), &:hover div.ms-DetailsRow-fields div.ms-DetailsRow-cell:nth-child(25), &:hover div.ms-DetailsRow-fields div.ms-DetailsRow-cell:nth-child(26)": {
              backgroundColor: "#CAE0D6",
            },
            "&:hover div.ms-DetailsRow-fields div.ms-DetailsRow-cell:nth-child(27), &:hover div.ms-DetailsRow-fields div.ms-DetailsRow-cell:nth-child(28),&:hover div.ms-DetailsRow-fields div.ms-DetailsRow-cell:nth-child(29),&:hover div.ms-DetailsRow-fields div.ms-DetailsRow-cell:nth-child(30), &:hover div.ms-DetailsRow-fields div.ms-DetailsRow-cell:nth-child(31), &:hover div.ms-DetailsRow-fields div.ms-DetailsRow-cell:nth-child(32), &:hover div.ms-DetailsRow-fields div.ms-DetailsRow-cell:nth-child(33), &:hover div.ms-DetailsRow-fields div.ms-DetailsRow-cell:nth-child(34), &:hover div.ms-DetailsRow-fields div.ms-DetailsRow-cell:nth-child(35), &:hover div.ms-DetailsRow-fields div.ms-DetailsRow-cell:nth-child(36), &:hover div.ms-DetailsRow-fields div.ms-DetailsRow-cell:nth-child(37)": {
              backgroundColor: "#acccb9",
            },
            "&:hover div.ms-DetailsRow-fields div.ms-DetailsRow-cell:nth-child(38),&:hover div.ms-DetailsRow-fields div.ms-DetailsRow-cell:nth-child(39), &:hover div.ms-DetailsRow-fields div.ms-DetailsRow-cell:nth-child(40)": {
              backgroundColor: "#FBE0E0",
            },
            "&:hover div.ms-DetailsRow-fields div.ms-DetailsRow-cell:nth-child(41), &:hover div.ms-DetailsRow-fields div.ms-DetailsRow-cell:nth-child(42), &:hover div.ms-DetailsRow-fields div.ms-DetailsRow-cell:nth-child(43), &:hover div.ms-DetailsRow-fields div.ms-DetailsRow-cell:nth-child(45), &:hover div.ms-DetailsRow-fields div.ms-DetailsRow-cell:nth-child(46), &:hover div.ms-DetailsRow-fields div.ms-DetailsRow-cell:nth-child(47)": {
              backgroundColor: "#FFEAD4",
            },
            "&:hover div.ms-DetailsRow-fields div.ms-DetailsRow-cell:nth-child(44)": {
              backgroundColor: "#FBD6AF",
            },
            "&:hover div.ms-DetailsRow-cell": {
              backgroundColor: "#F3F2F1",
            },
            "&:hover .btnEditGrid": {
              visibility: "visible",
            },
          },
        };
        if (props?.columns[0].key === "column1") {
          customStyles.cell = {
            display: "flex",
            alignItems: "center",
            whiteSpace: "break-spaces",
            position: "sticky",
            top: 50,
            opacity: props.item.aplicarMerito ? 1 : 0.5,
            selectors: {
              "&:nth-child(1), &:nth-child(2), &:nth-child(3)": {
                position: "sticky",
                zIndex: 9,
                opacity: 1,
                span: {
                  opacity: props.item.aplicarMerito ? 1 : 0.5,
                },
                backgroundColor: detailsRowStyleProps.isSelected
                  ? "#EDEBE9"
                  : "#FFFFFF",
              },
              "&:nth-child(1)": {
                left: 0,
              },
              "&:nth-child(2)": {
                left: 50,
              },
              "&:nth-child(3)": {
                left: 150,
              },
              "&:nth-child(4), &:nth-child(5), &:nth-child(6), &:nth-child(7), &:nth-child(11), &:nth-child(12), &:nth-child(14), &:nth-child(18), &:nth-child(17), &:nth-child(19), &:nth-child(21), &:nth-child(23), &:nth-child(24), &:nth-child(25), &:nth-child(26), &:nth-child(27), &:nth-child(27), &:nth-child(28),&:nth-child(29), &:nth-child(30), &:nth-child(31), &:nth-child(32), &:nth-child(33), &:nth-child(35), &:nth-child(36), &:nth-child(37), &:nth-child(38), &:nth-child(39), &:nth-child(41),  &:nth-child(42),  &:nth-child(43), &:nth-child(46) ": {
                justifyContent: "center",
              },
              "&:nth-child(13), &:nth-child(24), &:nth-child(34), &:nth-child(35), &:nth-child(40), &:nth-child(42), &:nth-child(45), &:nth-child(47)": {
                justifyContent: "flex-end",
              },
              "&:nth-child(18), &:nth-child(19),&:nth-child(20), &:nth-child(21), &:nth-child(22)": {
                backgroundColor: "#F6F6F6",
              },
              "&:nth-child(23), &:nth-child(24), &:nth-child(25), &:nth-child(26)": {
                backgroundColor: "#D3EADF",
              },
              "&:nth-child(27), &:nth-child(28), &:nth-child(29), &:nth-child(30), &:nth-child(31), &:nth-child(32), &:nth-child(33), &:nth-child(34), &:nth-child(35), &:nth-child(36), &:nth-child(37)": {
                backgroundColor: "#BEDBCA",
              },
              "&:nth-child(5)": {
                opacity: 1,
              },
              "&:nth-child(38), &:nth-child(39), &:nth-child(40)": {
                backgroundColor: "#F8E8E8",
                opacity: 1,
              },
              "&:nth-child(41), &:nth-child(42), &:nth-child(44), &:nth-child(45), &:nth-child(46), &:nth-child(43), &:nth-child(47)": {
                backgroundColor: "#FDEEDE",
              },
              "&:nth-child(44)": {
                backgroundColor: "#FADFC2",
              },
            },
          };
        }
        return customStyles;
      };
      return (
        <DetailsRow
          {...props}
          styles={stylesForRow}
          itemIndex={props.itemIndex}
        />
      );
    }
    return null;
  };

  private _onColumnClick = (
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn
  ): void => {
    const { columns } = this.state;
    const newColumns: IColumn[] = columns.slice();
    let items: MeritEmployee[] = [];
    if (this.state.isFiltering) {
      items = this.state.items;
    } else {
      items = this.props.merits.data?.funcionarios ?? [];
    }
    const currColumn: IColumn = newColumns.filter(
      (currCol) => column.key === currCol.key
    )[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    const newItems = this._sort(
      items,
      currColumn.fieldName!,
      currColumn.isSortedDescending,
      currColumn.data
    );
    this.setState({
      columns: newColumns,
      items: newItems,
    });
  };

  private _getKey(item: any, index?: number): any {
    if (item !== undefined) return item.key;
  }

  private _sort<T>(
    items: T[],
    columnKey: string,
    isSortedDescending?: boolean,
    typeData?: string
  ): T[] {
    const result = (prop: string, obj: T) =>
      prop.split(".").reduce((a: any, b: any) => a[b] ?? "", obj);
    return items.sort((a: T, b: T) => {
      const comparedA =
        typeData === "number"
          ? Number(result(columnKey, a))
          : result(columnKey, a);
      const comparedB =
        typeData === "number"
          ? Number(result(columnKey, b))
          : result(columnKey, b);
      return (
        isSortedDescending ? comparedA < comparedB : comparedA > comparedB
      )
        ? 1
        : -1;
    });
  }
}

const pivotStyles: Partial<IPivotStyles> = {
  root: {
    borderBottom: "1px solid #CFD3DA",
    padding: "10px 20px 0px",
    backgroundColor: "#FFFFFF",
    width: "100%",
  },
  linkContent: {
    width: 130,
  },
};

const hoverCardStyles: Partial<IHoverCardStyles> = {
  host: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "default",
  },
};

const IconButtonStyle: Partial<IButtonStyles> = {
  root: {
    position: "absolute",
    top: 20,
    right: 8,
    width: 24,
    height: 24,
    padding: 8,
  },
  icon: {
    fontSize: 12,
  },
};

const tooltipStyle: Partial<ITooltipHostStyles> = {
  root: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

const dialogAprovarContentProps: IDialogContentProps = {
  title: "Aprovar?",
  closeButtonAriaLabel: "Close",
  subText: "Tem certeza de que deseja aprovar esta solicitação?"
};

const dialogReprovarContentProps: IDialogContentProps = {
  title: "Reprovar?",
  closeButtonAriaLabel: "Close",
  subText: "Tem certeza de que deseja reprovar esta solicitação?"
};

const dialogFinalizarContentProps: IDialogContentProps = {
  title: "Finalizar Reunião?",
  closeButtonAriaLabel: "Close",
  subText: "Tem certeza de que deseja finalizar esta reunião?"
};

const dialogReabrirContentProps: IDialogContentProps = {
  title: "Reabrir Reunião?",
  closeButtonAriaLabel: "Close",
  subText: "Tem certeza de que deseja reabrir esta reunião?"
};


const getPresence = (status: string): PersonaPresence => {
  switch (status) {
    case "D":
      return PersonaPresence.offline;
    case "F":
      return PersonaPresence.away;
    case "A":
      return PersonaPresence.online;
    default:
      return PersonaPresence.online;
  }
};

const validationPromoverSchema = yup.object().shape({
  percPromocao: yup.number().required("Campo obrigatório"),
  gsDestino: yup.number().required("Campo obrigatório"),
  tituloCargoDestino: yup.string().nullable().required("Campo obrigatório"),
});

const validationNovoCargoSchema = yup.object().shape({
  tituloCargoDestino: yup.string().required("Campo obrigatório"),
  gsDestino: yup.number().nullable().required("Campo obrigatório"),
});

const validationBudgetSchema = yup.object().shape({
  comentarios: yup.string().required("Campo obrigatório"),
  valor: yup.number().nullable().required("Campo obrigatório"),
});

interface NovoCargo {
  tituloCargoDestino: string;
  gsDestino: number | null;
}

const initialNovoCargo: NovoCargo = {
  tituloCargoDestino: "",
  gsDestino: null,
};

const initialBudget: Budget = {
  comentarios: "",
  valor: null,
};

const mapStateToProps = (state: RootState) => ({
  merits: state.meritReducer,
  areas: state.chartReducer,
  cc: state.ccReducer,
  positions: state.positionsReducer,
  categoriasCargos: state.categoriesPositionsReducer,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      ...getMeritById,
      ...editMerit,
      ...getInfoSalarial,
      ...atualizaPercentual,
      ...atualizaPromocao,
      ...atualizaSalario,
      ...atualizaComentarios,
      ...finalizar,
      ...reativar,
      ...aplicar,
      ...ignorar,
      ...solicitarAprovacao,
      ...getChart,
      ...getPositions,
      ...getCC,
      ...getCategoriesPositions,
      ...getBudgets,
      ...editBudget,
      ...getSaldo,
      ...aprovarMerito,
      ...reprovarMerito,
      ...finalizarReuniao,
      ...reabrirReuniao,
      ...listarCargos,
      ...listarSalarios,
      ...listarAvaliacoes,
      ...listarOcorrencias,
      ...clearListaCargos,
      ...clearListaSalarios,
      ...clearListaAvaliacoes,
      ...clearListaOcorrencias,
      ...downloadCarta,
      ...setCurrentPage,
    },
    dispatch
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Merit));
