import api from "~/services/api";
import { takeEvery, put, call, all } from "redux-saga/effects";
import {
  Types,
  IRequestTasksLoadAction,
  IRequestTasksLoadByIdAction,
  IRequestTasksFinishAction,
  IRequestTaskAcaoPDIAddAction,
  IRequestTaskAcaoPDIEditAction,
  IRequestTaskAcaoPDIDelAction,
  IRequestTaskAcaoPDISaveAction,
  ISuccessTasksLoadAction,
  ISuccessTasksLoadByIdAction,
  ISuccessTasksFinishAction,
  ISuccessTaskAcaoPDIAddAction,
  ISuccessTaskAcaoPDIEditAction,
  ISuccessTaskAcaoPDIDelAction,
  ISuccessTaskAcaoPDISaveAction,
  ISuccessTaskDevolutivaMerSolicCartasAction,
  IRequestTaskDevolutivaMerSolicCartasAction,
  ISuccessTaskDevolutivaMerStatusCartasAction,
  IRequestTaskDevolutivaMerStatusCartasAction,
  ISuccessTaskDevolutivaMerBaixarCartasAction,
  IRequestTaskDevolutivaMerBaixarCartasAction,
  IRequestTaskDevolutivaRealizarAction,
  ISuccessTaskDevolutivaRealizarAction,
  IRequestTaskDevolutivaCancelarAction,
  ISuccessTaskDevolutivaCancelarAction,
  IRequestTaskCollectiveInfoAvaliacaoAction,
  ISuccessTaskCollectiveInfoAvaliacaoAction,
  TaskAvaliacaoColetivaSelected,
  IRequestTaskCollectiverEditResultadoMetaAction,
  IRequestTaskCollectiverEditResultadoAtitudeAction,
  ISuccessTaskCollectiverEditResultadoAtitudeAction,
  ISuccessTaskCollectiverEditResultadoMetaAction,
  TaskAvaliacaoColetivaEditResponse,
  IRequestTaskCollectiverEditResultadoFinalAction,
  ISuccessTaskCollectiverEditResultadoFinalAction,
  ISuccessTaskCollectiverFinishAction,
  IRequestTaskCollectiverFinishAction,
  IRequestTaskCollectiveFeedbackAction,
  ISuccessTaskCollectiveFeedbackAction,
  AvaliationType,
  AvaliationFeedback,
  IRequestTaskCollectiveFeedbackFinishAction,
  ISuccessTaskCollectiveFeedbackFinishAction,
  IRequestTaskFuncionarioFeedbackFinishAction,
  ISuccessTaskFuncionarioFeedbackFinishAction,
} from "../ducks/tasks/types";

import { Types as TypesCycleAvaliation } from "../ducks/avaliation/types";
import fileDownload from "js-file-download";

const apiCallGet = (payload: IRequestTasksLoadAction["payload"]) => {
  const { searchText, filterStatus, filterType, responsavelId } = payload;

  // const searchFilter = searchText !== "" ? `?SearchText=${searchText}` : "?";
  // const filter = filterStatus ? `&FilterStatus=${filterStatus}` : "";

  // return api.get(`tarefas${searchFilter}`)
  return api
    .get(`tarefas`, {
      params: {
        SearchText: searchText,
        FilterStatus: filterStatus?.join(","),
        FilterTipo: filterType?.join(","),
        Responsavel: responsavelId,
      },
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallGetById = (idTarefa: number) => {
  return api
    .get(`tarefas/${idTarefa}`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallFinishTask = (payload: IRequestTasksFinishAction["payload"]) => {
  return api
    .post(`tarefas/${payload.idTarefa}/finalizar`, {
      ...payload.data,
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallAddPDITask = (
  payload: IRequestTaskAcaoPDIAddAction["payload"]
) => {
  return api
    .post(`tarefas/${payload.idTarefa}/acaoPDI`, {
      ...payload.acao,
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallEditPDITask = (
  payload: IRequestTaskAcaoPDIEditAction["payload"]
) => {
  return api
    .put(
      `tarefas/${payload.idTarefa}/acaoPDI/${payload.acaoPDI.idTarefaPDIAcao}`,
      {
        ...payload.acaoPDI,
      }
    )
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallDelPDITask = (
  payload: IRequestTaskAcaoPDIDelAction["payload"]
) => {
  return api
    .delete(`tarefas/${payload.idTarefa}/acaoPDI/${payload.idTarefaPDIAcao}`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallSavePDITask = (
  payload: IRequestTaskAcaoPDISaveAction["payload"]
) => {
  return api
    .put(`tarefas/${payload.idTarefa}/acaoPDI`, {
      ...payload,
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallSolicCartas = (
  payload: IRequestTaskDevolutivaMerSolicCartasAction["payload"]
) => {
  return api
    .post(`tarefas/${payload.idTarefa}/solicitarCartas`, {
      cartas: payload.cartas,
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallStatusCartas = (
  payload: IRequestTaskDevolutivaMerStatusCartasAction["payload"]
) => {
  return api
    .post(
      `tarefas/${payload.idTarefa}/statusCarta/${payload.idTarefaDevMerito}`
    )
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallDownloadCarta = (
  payload: IRequestTaskDevolutivaMerBaixarCartasAction["payload"]
) => {
  return api
    .post(
      `tarefas/${payload.idTarefa}/downloadCarta/${payload.idTarefaDevMerito}`,
      {},
      {
        responseType: "blob",
      }
    )
    .then((response: any) => {
      const filename = response.headers["content-disposition"].split("=")[1];
      console.log(response.headers);
      fileDownload(response.data, filename);

      //Create a Blob from the PDF Stream
      // const file = new Blob([response.data], { type: "application/pdf" });
      // //Build a URL from the file
      // const fileURL = URL.createObjectURL(file);
      // //Open the URL on new Window
      // window.open(fileURL);

      return response.data;
    })
    .catch((err) => {
      throw err.response;
    });
};

const apiCallRealizarDevolutiva = (
  payload: IRequestTaskDevolutivaRealizarAction["payload"]
) => {
  return api
    .post(
      `tarefas/${payload.idTarefa}/realizaDevolutiva/${payload.idTarefaDevMerito}`
    )
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallCancelarDevolutiva = (
  payload: IRequestTaskDevolutivaCancelarAction["payload"]
) => {
  return api
    .post(
      `tarefas/${payload.idTarefa}/cancelarDevolutiva/${payload.idTarefaDevMerito}`
    )
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallGetInfoAvaliacao = (
  payload: IRequestTaskCollectiveInfoAvaliacaoAction["payload"]
) => {
  return api
    .get(
      `tarefas/${payload.idTarefa}/ac/${payload.idTarefaAvaliacao}/infoAvaliacao`
    )
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallEditResultadoAtitude = (
  payload: IRequestTaskCollectiverEditResultadoAtitudeAction["payload"]
) => {
  return api
    .put(
      `tarefas/${payload.idTarefa}/ac/${payload.idTarefaAvaliacao}/resultadoAtitude`,
      {
        resultadoatitudes: payload.resultadoatitudes,
        comentariosAtitudes: payload.comentariosAtitudes,
      }
    )
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallEditResultadoMeta = (
  payload: IRequestTaskCollectiverEditResultadoMetaAction["payload"]
) => {
  return api
    .put(
      `tarefas/${payload.idTarefa}/ac/${payload.idTarefaAvaliacao}/resultadoMeta`,
      {
        resultadometas: payload.resultadometas,
        comentariosMetas: payload.comentariosMetas,
      }
    )
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallEditResultadoFinal = (
  payload: IRequestTaskCollectiverEditResultadoFinalAction["payload"]
) => {
  return api
    .put(
      `tarefas/${payload.idTarefa}/ac/${payload.idTarefaAvaliacao}/resultadoFinal`,
      {
        idTabPerformance: payload.idTabPerformance,
        comentariosGerais: payload.comentariosGerais,
        potencial: payload.potencial,
        sucessao: payload.sucessao,
      }
    )
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallFinalizaTarefaColetiva = (idTarefa: number) => {
  return api
    .post(`tarefas/${idTarefa}/finalizar`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallGetTarefaColetivaFeedback = (
  payload: IRequestTaskCollectiveFeedbackAction["payload"]
) => {
  return api
    .get(`tarefas/${payload.idTarefa}/feedback/${payload.idTarefaFeedback}`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallFinishTarefaColetivaFeedback = (
  payload: IRequestTaskCollectiveFeedbackFinishAction["payload"]
) => {
  return api
    .put(`tarefas/${payload.idTarefa}/feedback/${payload.idTarefaFeedback}`, {
      feedbackGestor: payload.feedbackGestor,
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallFinishTarefaFuncionarioFeedback = (
  payload: IRequestTaskFuncionarioFeedbackFinishAction["payload"]
) => {
  return api
    .post(`tarefas/${payload.idTarefa}/finalizar`, {
      ...payload
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

function* load(action: IRequestTasksLoadAction) {
  try {
    const items = yield call(apiCallGet, action.payload);
    yield put({
      type: Types.SUCCESS_TASKS_LOAD,
      payload: items,
    } as ISuccessTasksLoadAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível carregar as tarefas.";
    yield put({ type: Types.FAILURE_TASKS_LOAD, message });
  }
}

function* loadById(action: IRequestTasksLoadByIdAction) {
  try {
    const item = yield call(apiCallGetById, action.idTarefa);
    yield put({
      type: Types.SUCCESS_TASKS_LOAD_ID,
      payload: item,
    } as ISuccessTasksLoadByIdAction);

    yield put({
      type: TypesCycleAvaliation.RESET_REDIRECT,
    });
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível carregar a tarefa.";
    yield put({ type: Types.FAILURE_TASKS_LOAD_ID, message });
  }
}

function* finishTask(action: IRequestTasksFinishAction) {
  try {
    const data = yield call(apiCallFinishTask, action.payload);
    yield put({
      type: Types.SUCCESS_TASKS_FINISH,
      data,
    } as ISuccessTasksFinishAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível finalizar a tarefa.";
    yield put({ type: Types.FAILURE_TASKS_FINISH, message });
  }
}

function* addPDITask(action: IRequestTaskAcaoPDIAddAction) {
  try {
    const idTarefaPDIAcao = yield call(apiCallAddPDITask, action.payload);
    yield put({
      type: Types.SUCCESS_TASKS_ACAO_PDI_ADD,
      payload: {
        ...action.payload.acao,
        ...idTarefaPDIAcao,
      },
    } as ISuccessTaskAcaoPDIAddAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível adicionar o PDI.";
    yield put({ type: Types.FAILURE_TASKS_ACAO_PDI_ADD, message });
  }
}

function* editPDITask(action: IRequestTaskAcaoPDIEditAction) {
  try {
    yield call(apiCallEditPDITask, action.payload);
    yield put({
      type: Types.SUCCESS_TASKS_ACAO_PDI_EDIT,
      payload: action.payload.acaoPDI,
    } as ISuccessTaskAcaoPDIEditAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível adicionar o PDI.";
    yield put({ type: Types.FAILURE_TASKS_ACAO_PDI_ADD, message });
  }
}

function* delPDITask(action: IRequestTaskAcaoPDIDelAction) {
  try {
    yield call(apiCallDelPDITask, action.payload);
    yield put({
      type: Types.SUCCESS_TASKS_ACAO_PDI_DEL,
      idTarefaPDIAcao: action.payload.idTarefaPDIAcao,
    } as ISuccessTaskAcaoPDIDelAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível adicionar o PDI.";
    yield put({ type: Types.FAILURE_TASKS_ACAO_PDI_DEL, message });
  }
}

function* savePDITask(action: IRequestTaskAcaoPDISaveAction) {
  try {
    yield call(apiCallSavePDITask, action.payload);
    yield put({
      type: Types.SUCCESS_TASKS_ACAO_PDI_SAVE,
    } as ISuccessTaskAcaoPDISaveAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível salvar tarefa.";
    yield put({ type: Types.FAILURE_TASKS_ACAO_PDI_SAVE, message });
  }
}

function* solicCartas(action: IRequestTaskDevolutivaMerSolicCartasAction) {
  try {
    const data = yield call(apiCallSolicCartas, action.payload);

    yield put({
      type: Types.SUCCESS_TASKS_DEVOLUTIVA_MERITO_SOLIC_CARTAS,
      payload: data,
    } as ISuccessTaskDevolutivaMerSolicCartasAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível solicitar as cartas.";
    yield put({
      type: Types.FAILURE_TASKS_DEVOLUTIVA_MERITO_SOLIC_CARTAS,
      message,
    });
  }
}

function* statusCartas(action: IRequestTaskDevolutivaMerStatusCartasAction) {
  try {
    const data = yield call(apiCallStatusCartas, action.payload);
    yield put({
      type: Types.SUCCESS_TASKS_DEVOLUTIVA_MERITO_STATUS_CARTAS,
      payload: {
        idTarefaDevMerito: action.payload.idTarefaDevMerito,
        statusCarta: data.statusCarta,
      },
    } as ISuccessTaskDevolutivaMerStatusCartasAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível verificar o status da carta.";
    yield put({
      type: Types.FAILURE_TASKS_DEVOLUTIVA_MERITO_STATUS_CARTAS,
      message,
    });
  }
}

function* downloadCarta(action: IRequestTaskDevolutivaMerBaixarCartasAction) {
  try {
    yield call(apiCallDownloadCarta, action.payload);
    yield put({
      type: Types.SUCCESS_TASKS_DEVOLUTIVA_MERITO_DOWNLOAD_CARTAS,
      idTarefaDevMerito: action.payload.idTarefaDevMerito,
    } as ISuccessTaskDevolutivaMerBaixarCartasAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível baixar a carta.";
    yield put({
      type: Types.FAILURE_TASKS_DEVOLUTIVA_MERITO_DOWNLOAD_CARTAS,
      message,
    });
  }
}

function* realizarDevolutiva(action: IRequestTaskDevolutivaRealizarAction) {
  try {
    const data = yield call(apiCallRealizarDevolutiva, action.payload);
    yield put({
      type: Types.SUCCESS_TASKS_DEVOLUTIVA_REALIZAR,
      payload: {
        idTarefaDevMerito: action.payload.idTarefaDevMerito,
        ...data,
      },
    } as ISuccessTaskDevolutivaRealizarAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível baixar a carta.";
    yield put({
      type: Types.FAILURE_TASKS_DEVOLUTIVA_REALIZAR,
      message,
    });
  }
}

function* cancelarDevolutiva(action: IRequestTaskDevolutivaCancelarAction) {
  try {
    const data = yield call(apiCallCancelarDevolutiva, action.payload);
    yield put({
      type: Types.SUCCESS_TASKS_DEVOLUTIVA_CANCELAR,
      payload: {
        idTarefaDevMerito: action.payload.idTarefaDevMerito,
        ...data,
      },
    } as ISuccessTaskDevolutivaCancelarAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível cancelar a carta.";
    yield put({
      type: Types.FAILURE_TASKS_DEVOLUTIVA_CANCELAR,
      message,
    });
  }
}

function* getInfoAvaliacao(action: IRequestTaskCollectiveInfoAvaliacaoAction) {
  try {
    const data: TaskAvaliacaoColetivaSelected = yield call(
      apiCallGetInfoAvaliacao,
      action.payload
    );
    yield put({
      type: Types.SUCCESS_TASKS_COLETIVA_INFO_AVALIACAO,
      payload: data,
    } as ISuccessTaskCollectiveInfoAvaliacaoAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível carregar informações da avaliação.";
    yield put({
      type: Types.FAILURE_TASKS_COLETIVA_INFO_AVALIACAO,
      message,
    });
  }
}

function* editResultadoAtitude(
  action: IRequestTaskCollectiverEditResultadoAtitudeAction
) {
  try {
    const data: TaskAvaliacaoColetivaEditResponse = yield call(
      apiCallEditResultadoAtitude,
      action.payload
    );
    yield put({
      type: Types.SUCCESS_TASKS_COLETIVA_RESULTADO_ATITUDE_EDIT,
      payload: {
        idTarefaAvaliacao: action.payload.idTarefaAvaliacao,
        avaliacao: data,
      },
    } as ISuccessTaskCollectiverEditResultadoAtitudeAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível carregar editar o resultado da meta.";
    yield put({
      type: Types.FAILURE_TASKS_COLETIVA_RESULTADO_ATITUDE_EDIT,
      message,
    });
  }
}

function* editResultadoMeta(
  action: IRequestTaskCollectiverEditResultadoMetaAction
) {
  try {
    const data: TaskAvaliacaoColetivaEditResponse = yield call(
      apiCallEditResultadoMeta,
      action.payload
    );
    yield put({
      type: Types.SUCCESS_TASKS_COLETIVA_RESULTADO_META_EDIT,
      payload: {
        idTarefaAvaliacao: action.payload.idTarefaAvaliacao,
        avaliacao: data,
      },
    } as ISuccessTaskCollectiverEditResultadoMetaAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível carregar editar o resultado da meta.";
    yield put({
      type: Types.FAILURE_TASKS_COLETIVA_RESULTADO_META_EDIT,
      message,
    });
  }
}

function* editResultadoFinal(
  action: IRequestTaskCollectiverEditResultadoFinalAction
) {
  try {
    const data: TaskAvaliacaoColetivaEditResponse = yield call(
      apiCallEditResultadoFinal,
      action.payload
    );
    yield put({
      type: Types.SUCCESS_TASKS_COLETIVA_RESULTADO_FINAL_EDIT,
      payload: {
        idTarefaAvaliacao: action.payload.idTarefaAvaliacao,
        avaliacao: {
          ...data,
          avaliacao: {
            ...data.avaliacao,
            idTabPerformance: action.payload.idTabPerformance,
          },
        },
      },
    } as ISuccessTaskCollectiverEditResultadoFinalAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível carregar editar o resultado final.";
    yield put({
      type: Types.FAILURE_TASKS_COLETIVA_RESULTADO_FINAL_EDIT,
      message,
    });
  }
}

function* finalizaTarefaColetiva(action: IRequestTaskCollectiverFinishAction) {
  try {
    yield call(apiCallFinalizaTarefaColetiva, action.idTarefa);
    yield put({
      type: Types.SUCCESS_TASKS_COLETIVA_FINISH,
    } as ISuccessTaskCollectiverFinishAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível finalizar a tarefa";
    yield put({
      type: Types.FAILURE_TASKS_COLETIVA_FINISH,
      message,
    });
  }
}

function* getTarefaColetivaFeedback(
  action: IRequestTaskCollectiveFeedbackAction
) {
  try {
    const data: AvaliationFeedback = yield call(
      apiCallGetTarefaColetivaFeedback,
      action.payload
    );
    yield put({
      type: Types.SUCCESS_TASKS_COLETIVA_FEEDBACK,
      payload: data,
    } as ISuccessTaskCollectiveFeedbackAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível carregar a tarefa";
    yield put({
      type: Types.FAILURE_TASKS_COLETIVA_FEEDBACK,
      message,
    });
  }
}

function* finishTarefaColetivaFeedback(
  action: IRequestTaskCollectiveFeedbackFinishAction
) {
  try {
    yield call(apiCallFinishTarefaColetivaFeedback, action.payload);
    yield put({
      type: Types.SUCCESS_TASKS_COLETIVA_FEEDBACK_FINISH,
      idTarefa: String(action.payload.idTarefa),
    } as ISuccessTaskCollectiveFeedbackFinishAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível carregar enviar o feedback";
    yield put({
      type: Types.FAILURE_TASKS_COLETIVA_FEEDBACK_FINISH,
      message,
    });
  }
}

function* finishTarefaFuncionarioFeedback(
  action: IRequestTaskFuncionarioFeedbackFinishAction
) {
  try {
    yield call(apiCallFinishTarefaFuncionarioFeedback, action.payload);
    yield put({
      type: Types.SUCCESS_TASKS_FUNCIONARIO_FEEDBACK_FINISH,
    } as ISuccessTaskFuncionarioFeedbackFinishAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível carregar enviar a resposta do feedback";
    yield put({
      type: Types.FAILURE_TASKS_FUNCIONARIO_FEEDBACK_FINISH,
      message,
    });
  }
}

export default function* tasksRoot() {
  yield all([
    yield takeEvery(Types.REQUEST_TASKS_LOAD, load),
    yield takeEvery(Types.REQUEST_TASKS_LOAD_ID, loadById),
    yield takeEvery(Types.REQUEST_TASKS_FINISH, finishTask),
    yield takeEvery(Types.REQUEST_TASKS_ACAO_PDI_ADD, addPDITask),
    yield takeEvery(Types.REQUEST_TASKS_ACAO_PDI_EDIT, editPDITask),
    yield takeEvery(Types.REQUEST_TASKS_ACAO_PDI_DEL, delPDITask),
    yield takeEvery(Types.REQUEST_TASKS_ACAO_PDI_SAVE, savePDITask),
    yield takeEvery(
      Types.REQUEST_TASKS_DEVOLUTIVA_MERITO_SOLIC_CARTAS,
      solicCartas
    ),
    yield takeEvery(
      Types.REQUEST_TASKS_DEVOLUTIVA_MERITO_STATUS_CARTAS,
      statusCartas
    ),
    yield takeEvery(
      Types.REQUEST_TASKS_DEVOLUTIVA_MERITO_DOWNLOAD_CARTAS,
      downloadCarta
    ),
    yield takeEvery(
      Types.REQUEST_TASKS_DEVOLUTIVA_REALIZAR,
      realizarDevolutiva
    ),
    yield takeEvery(
      Types.REQUEST_TASKS_DEVOLUTIVA_CANCELAR,
      cancelarDevolutiva
    ),
    yield takeEvery(
      Types.REQUEST_TASKS_COLETIVA_INFO_AVALIACAO,
      getInfoAvaliacao
    ),
    yield takeEvery(
      Types.REQUEST_TASKS_COLETIVA_RESULTADO_ATITUDE_EDIT,
      editResultadoAtitude
    ),
    yield takeEvery(
      Types.REQUEST_TASKS_COLETIVA_RESULTADO_META_EDIT,
      editResultadoMeta
    ),
    yield takeEvery(
      Types.REQUEST_TASKS_COLETIVA_RESULTADO_FINAL_EDIT,
      editResultadoFinal
    ),
    yield takeEvery(
      Types.REQUEST_TASKS_COLETIVA_FINISH,
      finalizaTarefaColetiva
    ),
    yield takeEvery(
      Types.REQUEST_TASKS_COLETIVA_FEEDBACK,
      getTarefaColetivaFeedback
    ),
    yield takeEvery(
      Types.REQUEST_TASKS_COLETIVA_FEEDBACK_FINISH,
      finishTarefaColetivaFeedback
    ),
    yield takeEvery(
      Types.REQUEST_TASKS_FUNCIONARIO_FEEDBACK_FINISH,
      finishTarefaFuncionarioFeedback
    ),
  ]);
}
