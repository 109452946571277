import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import jwt from "jwt-decode";

import {
  DefaultButton,
  Spinner,
  SpinnerSize,
  Stack,
  IButtonStyles,
} from "office-ui-fabric-react";
import { Text, ITextStyles } from "office-ui-fabric-react/lib/Text";
import { FontIcon } from "office-ui-fabric-react/lib/Icon";
import {
  Dialog,
  DialogType,
  DialogFooter,
} from "office-ui-fabric-react/lib/Dialog";

import MuiAccordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import { withStyles } from "@material-ui/core/styles";

import TableGoals from "./TableGoals";
import KeyProjects from "./KeyProjects";

import {
  ContainerHeader,
  ContainerDropdownInput,
  InputContainer,
  WrapperLabelGroup,
  WrapperBtnSolicita,
} from "./styles";

import { PrimaryButton } from "~/components/Buttons";
import customToast from "~/components/Toast/index";
import { LabelGroup } from "~/components/label";
import NoItems from "~/components/layout/NoItems";
import Dropdown from "~/components/layout/Dropdown";
import MenuItem from "@material-ui/core/MenuItem";

import { DataTypes as DataTypesTask } from "~/store/ducks/tasks/types";
import { Creators as CreatorsProfile } from "~/store/ducks/profile";
import {
  DataTypes as DataTypesProfileGoals,
  IProfileMetasType,
} from "~/store/ducks/profile/types";
import { RootState } from "~/store/ducks";

const textStyles: ITextStyles = {
  root: {
    fontWeight: 500,
  },
};

const dialogContentProps = {
  type: DialogType.normal,
  title: "Solicitar aprovação",
  closeButtonAriaLabel: "Fechar",
  subText:
    "Ao solicitar aprovação você está ciente e de acordo com as metas estabelecidas?",
};

const Accordion = withStyles({
  root: {
    padding: "0px !important",
    paddingBottom: 10,
    boxShadow: "none",

    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {
    borderBottom: 0,
  },
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    borderBottom: "1px solid #CFD3DA",
    height: 50,
    minHeight: "50px !important",
    "&:hover": {
      backgroundColor: "#F3F2F1",
    },
    expanded: {},
  },
})(MuiAccordionSummary);

const token = localStorage.getItem("user/token");
const idFuncionarioOwner = token
  ? (jwt(token ?? "") as any)?.idFuncionario
  : null;


interface IGoalsProps {
  idFuncionario?: number;
}

const Goals: React.FC<IGoalsProps> = (props) => {

  const [isModalSolicitacaoOpen, setIsModalSolicitacaoOpen] = useState<boolean>(
    false
  );

  const profileState = useSelector<RootState, DataTypesProfileGoals>(
    (state) => state.profileReducer
  );
  const taskState = useSelector<RootState, DataTypesTask>(
    (state) => state.tasksReducer
  );
  const isOwnFunc = idFuncionarioOwner === profileState.dataProfile.idFuncionario
  const dispatch = useDispatch();

  const profileData: IProfileMetasType = profileState.data;

  const [season, setSeason] = useState("");
  const [position, setPosition] = useState("");

  useEffect(() => {
    dispatch(CreatorsProfile.getProfileMetas(props.idFuncionario));
  }, []);

  useEffect(() => {
    if (taskState.success) {
      if (profileData.temporadas.length > 0) {
        const temporada = profileData.temporadas[0];
        dispatch(
          CreatorsProfile.getProfileMetas(
            props.idFuncionario,
            temporada?.idCiclo,
            taskState.selected?.TarefaMeta?.idCicloColaborador!
          )
        );
      }
    }
  }, [taskState.success]);

  useEffect(() => {
    if (profileData.temporadas.length > 0 && season === "") {
      const temporada = profileData.temporadas[0];
      setSeason(temporada?.idCiclo.toString() ?? "");
    }
  }, [profileData.temporadas]);

  useEffect(() => {
    setSeason("");
  }, [props.idFuncionario]);

  useEffect(() => {
    if (profileData.posicoes.length > 0) {
      const posicoes = profileData.posicoes.find(
        (pos) => pos.idArea === profileData.posicao.idArea
      );
      setPosition(String(posicoes?.idCicloColaborador) ?? "");
    }
  }, [profileData.posicoes]);

  useEffect(() => {
    if (profileState.success) {
      setIsModalSolicitacaoOpen(false);
    }
  }, [profileState.success]);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const idCicloSelected: number = event.target.value as number;
    setSeason(event.target.value as string);
    dispatch(
      CreatorsProfile.getProfileMetas(props.idFuncionario, idCicloSelected)
    );
  };

  const handleChangePosition = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const idCiclo =
      season === "" ? profileData.temporadas[0].idCiclo : Number(season);
    setPosition(event.target.value as string);
    dispatch(
      CreatorsProfile.getProfileMetas(
        props.idFuncionario,
        idCiclo,
        Number(event.target.value!)
      )
    );
  };

  const handleSolicitarApr = () => {
    setIsModalSolicitacaoOpen(true);
  };

  const handleConfirmSol = () => {
    const idCiclo =
      season === "" ? profileData.temporadas[0].idCiclo : Number(season);
    const { idCicloColaborador } = profileData.posicao;

    const totalPesos = profileData.posicao.projetos.reduce(
      (total, item) => total + Number(item.peso),
      0
    );

    const hasIniciativas = profileData.posicao.metas.some(
      (item) => item.cicloPeso.objetivo.unidade === "A"
    );

    if (hasIniciativas && (totalPesos < 100 || totalPesos > 100)) {
      customToast.error(
        "A soma dos pesos das iniciativas chave deve ser 100%!"
      );
    } else {
      setIsModalSolicitacaoOpen(false);
      dispatch(CreatorsProfile.reqAprovacao(idCiclo, idCicloColaborador!));
    }
  };

  return (
    <>
      {profileState.loadingData || taskState.loadingData ? (
        <Spinner
          size={SpinnerSize.medium}
          title="Carregando as metas"
          label="Carregando as metas"
          styles={{
            root: {
              height: 400,
            },
          }}
        />
      ) : !profileState.hasMetas ? (
        <NoItems
          error={false}
          text="Não há metas cadastradas"
          icon="Bullseye"
          alt="Empresas"
        />
      ) : (
        <>
          <ContainerHeader>
            <ContainerDropdownInput style={{ marginRight: 40 }}>
              <Text variant="medium" styles={{ root: { marginRight: 20 } }}>
                Ciclo
              </Text>
              <InputContainer>
                <Dropdown
                  errors=""
                  variant="outlined"
                  label=""
                  name="season"
                  values={season}
                  handleChange={handleChange}
                  fieldHeight={32}
                  styles={{
                    boxSizing: "border-box",
                    height: 32,
                    backgroundColor: "transparent",
                  }}
                  autofocus
                  customPadding="6.5px 14px"
                >
                  {profileData.temporadas.map((temp) => (
                    <MenuItem key={temp.idCiclo} value={temp.idCiclo}>
                      {temp.ano}
                    </MenuItem>
                  ))}
                </Dropdown>
              </InputContainer>
            </ContainerDropdownInput>
            <ContainerDropdownInput>
              <Text variant="medium" styles={{ root: { marginRight: 20 } }}>
                Posição
              </Text>
              <InputContainer>
                <Dropdown
                  errors=""
                  variant="outlined"
                  label=""
                  name="season"
                  values={position}
                  handleChange={handleChangePosition}
                  fieldHeight={32}
                  styles={{
                    boxSizing: "border-box",
                    height: 32,
                    backgroundColor: "transparent",
                    width: 380,
                  }}
                  customPadding="6.5px 14px"
                >
                  {profileData.posicoes.map((pos) => (
                    <MenuItem
                      key={pos.idCicloColaborador}
                      value={pos.idCicloColaborador}
                    >
                      {pos.area.nomeLocal}
                    </MenuItem>
                  ))}
                </Dropdown>
              </InputContainer>
            </ContainerDropdownInput>
          </ContainerHeader>
          <Accordion defaultExpanded={true}>
            <AccordionSummary
              expandIcon={
                <FontIcon iconName="ChevronDown" style={{ fontSize: 16 }} />
              }
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Text variant="mediumPlus" styles={textStyles}>
                Informações Básicas
              </Text>
            </AccordionSummary>
            <AccordionDetails>
              <Stack styles={{ root: { width: "100%" } }}>
                <WrapperLabelGroup>
                  <LabelGroup
                    name="lg"
                    icon="EMI"
                    label="Unidade"
                    value={profileData.posicao.unidade?.nomeUnidade ?? ""}
                    tooltipMessage={
                      profileData.posicao.unidade?.nomeUnidade ?? ""
                    }
                  />
                  <LabelGroup
                    name="lg"
                    icon="Org"
                    label="Área"
                    value={profileData.posicao?.area?.nomeLocal ?? ""}
                    tooltipMessage={profileData.posicao?.area?.nomeLocal ?? ""}
                  />
                  <LabelGroup
                    name="lg"
                    styles={{ minWidth: "auto" }}
                    icon="Flag"
                    label="Status"
                    value={profileData.posicao?.status.toUpperCase()}
                    iconAction={{
                      iconName: "RedEye",
                      mensagem: profileData.posicao?.obsStatus ?? "",
                      show: profileData.posicao?.obsStatus ? true : false,
                    }}
                  />
                  <LabelGroup
                    name="lg"
                    icon="contact"
                    label="Líder"
                    value={profileData.FuncionarioOwner?.nomeCompleto ?? ""}
                  />
                  <LabelGroup
                    name="lg"
                    icon="Calendar"
                    label="Período"
                    value={profileData.posicao?.descPeriodo ?? ""}
                  />
                  {profileData.posicao.flagSolicitarAprovacao &&
                    isOwnFunc && (
                      <WrapperBtnSolicita>
                        <PrimaryButton
                          text="Solicitar Aprovação"
                          isLoading={profileState.loadingAprovacao}
                          onClick={handleSolicitarApr}
                          styles={btnSolicitaStyle}
                        />
                      </WrapperBtnSolicita>
                    )}
                </WrapperLabelGroup>
              </Stack>
            </AccordionDetails>
          </Accordion>

          <Accordion defaultExpanded={true}>
            <AccordionSummary
              expandIcon={
                <FontIcon iconName="ChevronDown" style={{ fontSize: 16 }} />
              }
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Text variant="mediumPlus" styles={textStyles}>
                Metas
              </Text>
            </AccordionSummary>
            <AccordionDetails style={{ overflow: "auto" }}>
              <TableGoals cicloPesos={profileData.posicao.metas} totalPesos={profileData.totalPesos} />
            </AccordionDetails>
          </Accordion>
          {
            profileData.posicao?.status !== 'visualização' &&
              <Accordion defaultExpanded={true}>
                <AccordionSummary
                  aria-controls="panel2d-content"
                  id="panel2d-header"
                  expandIcon={
                    <FontIcon iconName="ChevronDown" style={{ fontSize: 16 }} />
                  }
                >
                  <Text variant="mediumPlus" styles={textStyles}>
                    Iniciativas Chave
                  </Text>
                </AccordionSummary>
                <AccordionDetails>
                  <KeyProjects
                    idCiclo={Number(season)}
                    idCicloColaborador={profileData.posicao.idCicloColaborador}
                    allProjects={profileData.posicao.projetos}
                    errorProjects={profileState.error}
                    isLoadingProjects={profileState.loadingProject}
                    successProjects={profileState.success}
                    objetivosPesoAreas={profileData.objetivosPesoAreas}
                    selectedProject={profileData.projectSelected}
                    idFuncionario={props.idFuncionario}
                    readOnly={!profileData.posicao.flagSolicitarAprovacao}
                    isOwnFunc={isOwnFunc}
                  />
                </AccordionDetails>
              </Accordion>
          }
        </>
      )}

      <Dialog
        hidden={!isModalSolicitacaoOpen}
        dialogContentProps={dialogContentProps}
      >
        <DialogFooter styles={{ actions: { lineHeight: 0 } }}>
          <Stack horizontal horizontalAlign="end">
            <DefaultButton
              onClick={() => setIsModalSolicitacaoOpen(false)}
              text="Cancelar"
            />
            <PrimaryButton
              onClick={handleConfirmSol}
              text="Confirmar"
              styles={{ root: { marginLeft: "10px !important" } }}
            />
          </Stack>
        </DialogFooter>
      </Dialog>
    </>
  );
};

const btnSolicitaStyle: Partial<IButtonStyles> = {
  root: {
    // maxWidth: 165,
    minWidth: "165px !important",
    marginTop: 20,
    display: "flex",
    alignItems: "center",
    transition: "width 0.2s",
  },
};

export default Goals;
