import styled from "styled-components";

export const Wrapper = styled.div`
  height: calc(100vh - 50px);
  width: calc(100% - 250px);
  flex-basis: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

export const ContainerContent = styled.div`
  padding: 0 20px;
  height: calc(100% - 50px);
  display: flex;
  flex-direction: column;
  overflow: auto;
`

export const ListContainer = styled.div`
  width: 100%;
  overflow-y: auto;
  position: relative;
  height: 100%;
`;

export const ContainerTitle = styled.div`
    border-bottom: 1px solid #CFD3DA;
    padding-bottom: 10px;
`

export const StatusTeam = styled.div<{ bgColor: string }>`
    width: 17px;
    min-width: 17px;
    height: 17px;
    min-height: 17px;
    background-color: ${({ bgColor }) => bgColor};
    border-radius: 50%;
    margin-right: 12px;
`
