import { takeEvery, put, call, all } from "redux-saga/effects";
import {
  Types,
  CarreiraLoadDTO,
  ISuccessGetCarreirasAction
} from "../../ducks/admin/carreira/types";
import api from "~/services/api";

const apiCallGet = () => {
  return api
    .get("admin/carreiras")
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};


function* load() {
  try {
    const items: CarreiraLoadDTO[] = yield call(apiCallGet);
    yield put({
      type: Types.SUCCESS_LOAD_CARREIRAS,
      payload: items,
    } as ISuccessGetCarreirasAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível carregar as carreiras";
    yield put({ type: Types.FAILURE_LOAD_CARREIRAS, message });
  }
}

export default function* vinculosRoot() {
  yield all([
    yield takeEvery(Types.REQUEST_LOAD_CARREIRAS, load),
  ]);
}
