import React from "react";
import { Box, Percent, Title, Value } from "./styles";

interface IPerformanceBoardProps {
  color: string;
  title: string;
  value: number | string;
  percent?: number;
  total?: number;
}

export function PerformanceBoard({
  color,
  title,
  value,
  percent,
  total,
}: IPerformanceBoardProps) {
  return (
    <Box color={color}>
      <Title>{title}</Title>
      <Value>{value}</Value>
      <p>{total}</p>
      <Percent>
        {percent}
        {!!String(percent) && "%"}
      </Percent>
    </Box>
  );
}

export function PerformanceBoardTotal({
  title,
  value,
  percent,
  total,
}: IPerformanceBoardProps) {
  return (
    <Box
      color={"#484644"}
      style={{ backgroundColor: "#484644", marginRight: 24 }}
    >
      <Title style={{ color: "white" }}>{title}</Title>
      <Value style={{ color: "white" }}>
        {value}
        <span style={{ color: "white", fontSize: 26, fontWeight: "300" }}>
          /{total}
        </span>
      </Value>

      <Percent style={{ color: "white" }}>{percent}</Percent>
    </Box>
  );
}
