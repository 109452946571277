/**
 * Types
 */
export enum Types {
  REQUEST_LOAD_MOTIVOS_DESLIGAMENTOS = "motivosDesligamentos/REQUEST_LOAD_MOTIVOS_DESLIGAMENTOS",
  SUCCESS_LOAD_MOTIVOS_DESLIGAMENTOS = "motivosDesligamentos/SUCCESS_LOAD_MOTIVOS_DESLIGAMENTOS",
  FAILURE_LOAD_MOTIVOS_DESLIGAMENTOS = "motivosDesligamentos/FAILURE_LOAD_MOTIVOS_DESLIGAMENTOS",

  REQUEST_LOAD_MOTIVO_DESLIGAMENTO_BY_ID = "motivosDesligamentos/REQUEST_LOAD_MOTIVO_DESLIGAMENTO_BY_ID",
  SUCCESS_LOAD_MOTIVO_DESLIGAMENTO_BY_ID = "motivosDesligamentos/SUCCESS_LOAD_MOTIVO_DESLIGAMENTO_BY_ID",
  FAILURE_LOAD_MOTIVO_DESLIGAMENTO_BY_ID = "motivosDesligamentos/FAILURE_LOAD_MOTIVO_DESLIGAMENTO_BY_ID",

  REQUEST_ADD_MOTIVO_DESLIGAMENTO = "motivosDesligamentos/REQUEST_ADD_MOTIVO_DESLIGAMENTO",
  SUCCESS_ADD_MOTIVO_DESLIGAMENTO = "motivosDesligamentos/SUCCESS_ADD_MOTIVO_DESLIGAMENTO",
  FAILURE_ADD_MOTIVO_DESLIGAMENTO = "motivosDesligamentos/FAILURE_ADD_MOTIVO_DESLIGAMENTO",

  REQUEST_EDIT_MOTIVO_DESLIGAMENTO = "motivosDesligamentos/REQUEST_EDIT_MOTIVO_DESLIGAMENTO",
  SUCCESS_EDIT_MOTIVO_DESLIGAMENTO = "motivosDesligamentos/SUCCESS_EDIT_MOTIVO_DESLIGAMENTO",
  FAILURE_EDIT_MOTIVO_DESLIGAMENTO = "motivosDesligamentos/FAILURE_EDIT_MOTIVO_DESLIGAMENTO",

  REQUEST_DEL_MOTIVO_DESLIGAMENTO = "motivosDesligamentos/REQUEST_DEL_MOTIVO_DESLIGAMENTO",
  SUCCESS_DEL_MOTIVO_DESLIGAMENTO = "motivosDesligamentos/SUCCESS_DEL_MOTIVO_DESLIGAMENTO",
  FAILURE_DEL_MOTIVO_DESLIGAMENTO = "motivosDesligamentos/FAILURE_DEL_MOTIVO_DESLIGAMENTO",
}

export type MotivosDesligamentosLoadDTO = {
  idMotivoDesligamento: number;
  descMotivo: string;
};

export type MotivosDesligamentosAddOrEditDTO = {
  idMotivoDesligamento?: number;
  descMotivo: string;
  flagGerarMeta: boolean;
  BonusCicloAnterior: boolean;
};

export type MotivoDesligamentoType = {
  idMotivoDesligamento: number;
  descMotivo: string;
  flagGerarMeta: boolean;
  BonusCicloAnterior: boolean;
};

export type ItemSelectedType = {
  item: MotivoDesligamentoType | null;
  loading: boolean;
  success: boolean;
  error: boolean;
};

interface IMotivosDesligamentosState {
  readonly data: MotivosDesligamentosLoadDTO[];
  readonly loadingData: boolean;
  readonly loadingAction: boolean;
  readonly success: boolean;
  readonly error: boolean;
  readonly itemSelected: ItemSelectedType;
}

export type DataTypes = IMotivosDesligamentosState;

/**
 * Actions
 */

// LOAD
export interface IRequestGetMotivosDesligamentosAction {
  type: typeof Types.REQUEST_LOAD_MOTIVOS_DESLIGAMENTOS;
}

export interface ISuccessGetMotivosDesligamentosAction {
  type: typeof Types.SUCCESS_LOAD_MOTIVOS_DESLIGAMENTOS;
  payload: MotivosDesligamentosLoadDTO[];
}

export interface IFailureGetMotivosDesligamentosAction {
  type: typeof Types.FAILURE_LOAD_MOTIVOS_DESLIGAMENTOS;
  message: string;
}

type MotivosDesligamentosActionGetActions =
  | IRequestGetMotivosDesligamentosAction
  | ISuccessGetMotivosDesligamentosAction
  | IFailureGetMotivosDesligamentosAction;

//LOAD BY ID

export interface IRequestGetByIdMotivoDesligamentoAction {
  type: typeof Types.REQUEST_LOAD_MOTIVO_DESLIGAMENTO_BY_ID;
  idMotivoDesligamento: number;
}

export interface ISuccessGetByIdMotivoDesligamentoAction {
  type: typeof Types.SUCCESS_LOAD_MOTIVO_DESLIGAMENTO_BY_ID;
  payload: MotivoDesligamentoType;
}

export interface IFailureGetByIdMotivoDesligamentoAction {
  type: typeof Types.FAILURE_LOAD_MOTIVO_DESLIGAMENTO_BY_ID;
  message: string;
}

type MotivoDesligamentoGetByIdActions =
  | IRequestGetByIdMotivoDesligamentoAction
  | ISuccessGetByIdMotivoDesligamentoAction
  | IFailureGetByIdMotivoDesligamentoAction;

// //ADD

export interface IRequestAddMotivoDesligamentoAction {
  type: typeof Types.REQUEST_ADD_MOTIVO_DESLIGAMENTO;
  payload: MotivosDesligamentosAddOrEditDTO;
}

export interface ISuccessAddMotivoDesligamentoAction {
  type: typeof Types.SUCCESS_ADD_MOTIVO_DESLIGAMENTO;
  payload: MotivosDesligamentosLoadDTO;
}

export interface IFailureAddMotivoDesligamentoAction {
  type: typeof Types.FAILURE_ADD_MOTIVO_DESLIGAMENTO;
  message: string;
}

type MotivoDesligamentoAddActions =
  | IRequestAddMotivoDesligamentoAction
  | ISuccessAddMotivoDesligamentoAction
  | IFailureAddMotivoDesligamentoAction;

//EDIT

export interface IRequestEditMotivoDesligamentoAction {
  type: typeof Types.REQUEST_EDIT_MOTIVO_DESLIGAMENTO;
  payload: MotivosDesligamentosAddOrEditDTO;
}

export interface ISuccessEditMotivoDesligamentoAction {
  type: typeof Types.SUCCESS_EDIT_MOTIVO_DESLIGAMENTO;
  payload: MotivosDesligamentosLoadDTO;
}

export interface IFailureEditMotivoDesligamentoAction {
  type: typeof Types.FAILURE_EDIT_MOTIVO_DESLIGAMENTO;
  message: string;
}

type MotivoDesligamentoEditActions =
  | IRequestEditMotivoDesligamentoAction
  | ISuccessEditMotivoDesligamentoAction
  | IFailureEditMotivoDesligamentoAction;

//DELETE

export interface IRequestDelMotivoDesligamentoAction {
  type: typeof Types.REQUEST_DEL_MOTIVO_DESLIGAMENTO;
  idMotivoDesligamento: number;
}

export interface ISuccessDelMotivoDesligamentoAction {
  type: typeof Types.SUCCESS_DEL_MOTIVO_DESLIGAMENTO;
  idMotivoDesligamento: number;
}

export interface IFailureDelMotivoDesligamentoAction {
  type: typeof Types.FAILURE_DEL_MOTIVO_DESLIGAMENTO;
  message: string;
}

type MotivoDesligamentoDelActions =
  | IRequestDelMotivoDesligamentoAction
  | ISuccessDelMotivoDesligamentoAction
  | IFailureDelMotivoDesligamentoAction;

export type MotivosDesligamentosActionTypes =
  | MotivosDesligamentosActionGetActions
  | MotivoDesligamentoGetByIdActions
  | MotivoDesligamentoAddActions
  | MotivoDesligamentoEditActions
  | MotivoDesligamentoDelActions;
