import { takeEvery, put, call, all } from "redux-saga/effects";
import {
  Types,
  MotivoAfastamentoType,
  MotivosAfastamentosLoadDTO,
  IRequestGetByIdMotivoAfastamentoAction,
  IRequestAddMotivoAfastamentoAction,
  IRequestEditMotivoAfastamentoAction,
  IRequestDelMotivoAfastamentoAction,
  ISuccessGetMotivosAfastamentosAction,
  ISuccessGetByIdMotivoAfastamentoAction,
  ISuccessAddMotivoAfastamentoAction,
  ISuccessEditMotivoAfastamentoAction,
  ISuccessDelMotivoAfastamentoAction
} from "../../ducks/admin/motivoAfastamento/types";
import api from "~/services/api";

const apiCallGet = () => {
  return api
    .get("admin/motivosAfastamentos")
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallGetById = (idMotivoAfastamento: number) => {
  return api
    .get(`admin/motivosAfastamentos/${idMotivoAfastamento}`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallAdd = (item: IRequestAddMotivoAfastamentoAction["payload"]) => {
  return api
    .post("admin/motivosAfastamentos", item)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallEdit = (item: IRequestEditMotivoAfastamentoAction["payload"]) => {
  return api
    .put(`admin/motivosAfastamentos/${item.idMotivoAfastamento}`, item)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallDel = (idMotivoAfastamento: number) => {
  return api
    .delete(`admin/motivosAfastamentos/${idMotivoAfastamento}`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

function* load() {
  try {
    const items: MotivosAfastamentosLoadDTO[] = yield call(apiCallGet);
    yield put({
      type: Types.SUCCESS_LOAD_MOTIVOS_AFASTAMENTOS,
      payload: items,
    } as ISuccessGetMotivosAfastamentosAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível carregar os motivos de afastamentos";
    yield put({ type: Types.FAILURE_LOAD_MOTIVOS_AFASTAMENTOS, message });
  }
}

function* loadById(action: IRequestGetByIdMotivoAfastamentoAction) {
  try {
    const item: MotivoAfastamentoType = yield call(
      apiCallGetById,
      action.idMotivoAfastamento
    );
    yield put({
      type: Types.SUCCESS_LOAD_MOTIVO_AFASTAMENTO_BY_ID,
      payload: item,
    } as ISuccessGetByIdMotivoAfastamentoAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível carregar o motivo de afastamento";
    yield put({ type: Types.FAILURE_LOAD_MOTIVO_AFASTAMENTO_BY_ID, message });
  }
}

function* add(action: IRequestAddMotivoAfastamentoAction) {
  try {
    const data: { idMotivoAfastamento: number } = yield call(
      apiCallAdd,
      action.payload
    );
    yield put({
      type: Types.SUCCESS_ADD_MOTIVO_AFASTAMENTO,
      payload: {
        idMotivoAfastamento: data.idMotivoAfastamento,
        descMotivoAfastamento: action.payload.descMotivoAfastamento,
      },
    } as ISuccessAddMotivoAfastamentoAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível adicionar o motivo de afastamento";
    yield put({ type: Types.FAILURE_ADD_MOTIVO_AFASTAMENTO, message });
  }
}

function* edit(action: IRequestEditMotivoAfastamentoAction) {
  try {
    yield call(apiCallEdit, action.payload);
    yield put({
      type: Types.SUCCESS_EDIT_MOTIVO_AFASTAMENTO,
      payload: action.payload,
    } as ISuccessEditMotivoAfastamentoAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível editar o motivo de afastamento";
    yield put({ type: Types.FAILURE_EDIT_MOTIVO_AFASTAMENTO, message });
  }
}

function* del(action: IRequestDelMotivoAfastamentoAction) {
  try {
    yield call(apiCallDel, action.idMotivoAfastamento);
    yield put({
      type: Types.SUCCESS_DEL_MOTIVO_AFASTAMENTO,
      idMotivoAfastamento: action.idMotivoAfastamento,
    } as ISuccessDelMotivoAfastamentoAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível excluír o motivo de afastamento";
    yield put({ type: Types.FAILURE_DEL_MOTIVO_AFASTAMENTO, message });
  }
}

export default function* motivoAfastamentoRoot() {
  yield all([
    yield takeEvery(Types.REQUEST_LOAD_MOTIVOS_AFASTAMENTOS, load),
    yield takeEvery(Types.REQUEST_LOAD_MOTIVO_AFASTAMENTO_BY_ID, loadById),
    yield takeEvery(Types.REQUEST_ADD_MOTIVO_AFASTAMENTO, add),
    yield takeEvery(Types.REQUEST_EDIT_MOTIVO_AFASTAMENTO, edit),
    yield takeEvery(Types.REQUEST_DEL_MOTIVO_AFASTAMENTO, del),
  ]);
}
