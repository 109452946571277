import styled from "styled-components";

import colors from "~/assets/js/colors";

export const Container = styled.div`
  height: 100vh;
  width: 100%;
  background-color: ${colors.white};
  background-image: url("/static/img/bg-st-marche.jpg");
  overflow: hidden;
  position: absolute;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
`;

export const Content = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const Card = styled.div`
  width: 550px;
  height: auto;
  overflow: hidden;
  border-radius: 2px;
  background-color: ${colors.white};
  margin: 10px;
  padding: 20px 20px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.16);
`;

export const CardContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const RomasoftLogo = styled.img`
  height: auto;
  margin-top: 20px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 40px;
  margin-bottom: 20px;
`;

export const Text = styled.p`
  font-size: 0.875rem;
  margin-bottom: 5px;
`;

export const WrapperReCAPTCHA = styled.div`
  margin: 23px 0;
  height: 78px;
`

export const ErrorMessage = styled.span`
  font-size: 0.875rem;
  color: ${colors.red};
`;

export const FeedbackMessage = styled.span`
  font-size: 1rem;
  color:  ${props => props.color };
`;

export const WrapperResult = styled.div`
  margin: 0px 23px;
`;
  
export const WrapperText = styled.p`
margin: 20px 0
`;