// #region Imports
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Formik } from "formik";
import * as yup from "yup";

//FluentUI
import { Stack } from "office-ui-fabric-react/lib/Stack";
import {
  DetailsListLayoutMode,
  SelectionMode,
  Selection,
  IColumn,
} from "office-ui-fabric-react/lib/DetailsList";
import { ShimmeredDetailsList } from "office-ui-fabric-react/lib/ShimmeredDetailsList";
import { SearchBox } from "office-ui-fabric-react/lib/SearchBox";
import { CommandBarButton, ICommandBarStyles } from "office-ui-fabric-react";
import { IDialogContentProps } from "office-ui-fabric-react/lib/Dialog";

import Panel from "~/components/layout/Panel";
import CustomDialog from "~/components/CustomDialogFluentUI";
import { Page, BreadcrumbItems } from "~/store/ducks/home/types";
import { InputText } from "~/components/Forms";

import { DeleteButton } from "~/components/Buttons";
import HeaderPage from "~/components/layout/HeaderPage";
import NoItems from "~/components/layout/NoItems";
import { PrimaryButton } from "~/components/Buttons";

//Estilos
import {
  Wrapper,
  ListContainer,
  DefaultButton,
  ContainerContent,
} from "./styles";

import {
  StandardPositionLoadDTO,
  StandardPositionAddOrEditDTO,
  DataTypes as StandardsPositionsData,
} from "~/store/ducks/admin/standardPosition/types";
import { Creators as getStandardsPositions } from "~/store/ducks/admin/standardPosition";
import { Creators as addStandardPosition } from "~/store/ducks/admin/standardPosition";
import { Creators as editStandardPosition } from "~/store/ducks/admin/standardPosition";
import { Creators as delStandardPosition } from "~/store/ducks/admin/standardPosition";

import { Creators as setCurrentPage } from "~/store/ducks/home";
import { RootState } from "~/store/ducks";
//#endregion

interface IStandardsPositionsState {
  columns: IColumn[];
  items: StandardPositionLoadDTO[];
  isPanelOpen: boolean;
  inicialValues: StandardPositionAddOrEditDTO;
  isFiltering: boolean;
  selectionDetails: string;
  selectionCount: number;
  isDialogCompetenceOpen: boolean;
  isDialogTypeOpen: boolean;
  isDialogExcludeOpen: boolean;
  search: string;
  newCompetenceText: string;
  newTypePDIText: string;
}

interface IPropsStandardsPositions {
  standardsPositions: StandardsPositionsData;
  getStandardsPositions: () => void;
  addStandardPosition: (padrao: StandardPositionAddOrEditDTO) => void;
  editStandardPosition: (padrao: StandardPositionAddOrEditDTO) => void;
  delStandardPosition: (idPadraoCargo: number) => void;
  setCurrentPage: (page: Page) => void;
}

class StandardsPositions extends Component<
  IPropsStandardsPositions,
  IStandardsPositionsState
> {
  private formRef: any;
  private inputSearch: any;
  private _selection: Selection;

  constructor(props: IPropsStandardsPositions) {
    super(props);
    //#region Colunas
    const columns: IColumn[] = [
      {
        key: "column2",
        name: "ID",
        ariaLabel: "ID do padrão",
        fieldName: "idPadraoCargo",
        isRowHeader: true,
        minWidth: 75,
        maxWidth: 100,
        isResizable: true,
        isSortedDescending: false,
        onColumnClick: this._onColumnClick,
      },
      {
        key: "column3",
        name: "Descrição",
        fieldName: "descPadraoCargo",
        minWidth: 210,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "string",
        isPadded: true,
      },
    ];
    //#endregion

    this.state = {
      columns: columns,
      items: [],
      isPanelOpen: false,
      selectionDetails: "",
      inicialValues: initialStandard,
      isFiltering: false,
      selectionCount: 0,
      isDialogExcludeOpen: false,
      search: "",
      isDialogCompetenceOpen: false,
      isDialogTypeOpen: false,
      newCompetenceText: "",
      newTypePDIText: "",
    };

    this._selection = new Selection({
      onSelectionChanged: () => {
        this.setState({
          selectionDetails: this._getSelectionDetails(),
        });
      },
    });

    this.formRef = React.createRef();
    this.inputSearch = React.createRef();
  }

  componentDidMount() {
    const page: Page = {
      key: "padroesCargos",
      pages: itemsBreadCrumb,
    };
    this.props.setCurrentPage(page);
    this.props.getStandardsPositions();
  }

  componentDidUpdate(prevProps: IPropsStandardsPositions, _: any) {
    if (this.state.isPanelOpen) {
      if (
        prevProps.standardsPositions.success !==
        this.props.standardsPositions.success
      ) {
        if (this.props.standardsPositions.success) {
          this.setState({ isPanelOpen: false });
        }
      }
    }

    // if (this.props.pdi.error !== prevProps.pdi.error) {
    //     if (this.props.pdi.error) {
    //         this.setState({ isPanelOpen: false, inicialValues: initialStandard })
    //     }
    // }
  }

  // Submit do formulário Formik
  handleSubmit = () => {
    if (this.formRef.current) {
      this.formRef.current.handleSubmit();
    }
  };

  _onItemInvoked = (item: StandardPositionLoadDTO): void => {
    this.setState({
      isPanelOpen: true,
      inicialValues: item,
    });
  };

  _getSelectionDetails(): any {
    const selectionCount = this._selection.getSelectedCount();
    this.setState({ selectionCount });
    this._selection.getSelection();
  }

  openEdit = () => {
    const selected = this._selection.getSelection();

    this.setState({
      isPanelOpen: true,
      inicialValues: selected[0] as StandardPositionLoadDTO,
    });
  };

  cancelPanel = () => {
    this._selection.setAllSelected(false);
    this.setState({ isPanelOpen: false });
  };

  exclude = () => {
    this.props.delStandardPosition(
      (this._selection.getSelection()[0] as StandardPositionLoadDTO)
        .idPadraoCargo
    );
    this.setState({ isDialogExcludeOpen: false });
    this._selection.setAllSelected(false);
  };

  //#region  Funções do filtro

  handleSearch = (e: any) => {
    const data = this.props.standardsPositions.data;
    const text = e?.target?.value;

    let items = text
      ? data.filter(
          (item) =>
            item.descPadraoCargo!.toLowerCase().indexOf(text.toLowerCase()) > -1
        )
      : data;

    this.setState({ items, isFiltering: true, search: text });
  };

  //#endregion

  commandBarRender = () => {
    const { selectionCount } = this.state;
    if (selectionCount === 1) {
      return (
        <>
          <CommandBarButton
            styles={btnStyle}
            iconProps={{ iconName: "Edit" }}
            text="Editar"
            onClick={() => this.openEdit()}
          />
          <CommandBarButton
            styles={btnStyle}
            iconProps={{ iconName: "Delete" }}
            text="Excluir"
            onClick={() => this.setState({ isDialogExcludeOpen: true })}
          />
        </>
      );
    }
  };

  render() {
    const {
      isPanelOpen,
      isDialogExcludeOpen,
      inicialValues,
      isFiltering,
      items,
      columns,
      search,
    } = this.state;
    const {
      standardsPositions,
      getStandardsPositions,
      addStandardPosition,
      editStandardPosition,
    } = this.props;

    return (
      <>
        <Panel
          title={
            !inicialValues?.idPadraoCargo
              ? "Novo Padrão"
              : inicialValues?.descPadraoCargo
          }
          open={isPanelOpen}
          onClose={() => this.cancelPanel()}
          footer={
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <DefaultButton onClick={() => this.cancelPanel()}>
                Cancelar
              </DefaultButton>
              <PrimaryButton
                onClick={this.handleSubmit}
                isLoading={standardsPositions.loadingAction}
                text="Salvar"
              />
            </Stack>
          }
        >
          <Formik
            innerRef={this.formRef}
            initialValues={inicialValues}
            validationSchema={validationStandardSchema}
            validateOnChange={false}
            validateOnBlur={true}
            enableReinitialize
            onSubmit={(values: StandardPositionAddOrEditDTO) => {
              if (!values.idPadraoCargo) {
                addStandardPosition(values);
              } else {
                editStandardPosition(values);
              }
            }}
          >
            {({
              handleSubmit,
              handleChange,
              values,
              errors,
              setFieldValue,
              setFieldError,
            }) => (
              <form
                onSubmit={handleSubmit}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <InputText
                  value={values?.descPadraoCargo ?? ""}
                  onChange={(e) => {
                    handleChange(e);
                    setFieldError("descPadraoCargo", "");
                  }}
                  id="descPadraoCargo"
                  error={errors.descPadraoCargo ? true : false}
                  name="descPadraoCargo"
                  type="text"
                  label="Descrição"
                  helperText={errors.descPadraoCargo}
                  className="mt-2"
                  autoFocus
                />
              </form>
            )}
          </Formik>
        </Panel>

        <Wrapper>
          <ContainerContent>
            <HeaderPage
              title="Padrões de Cargo"
              leftItems={
                <>
                  <CommandBarButton
                    styles={btnStyle}
                    iconProps={{ iconName: "Add" }}
                    disabled={standardsPositions.loadingData}
                    text="Adicionar Padrão"
                    onClick={() =>
                      this.setState({
                        isPanelOpen: true,
                        inicialValues: initialStandard,
                      })
                    }
                  />
                  {this.commandBarRender()}
                </>
              }
              rightItems={
                <>
                  <SearchBox
                    placeholder="Pesquisar"
                    value={search}
                    onChange={(e) => this.handleSearch(e)}
                    // onKeyUp={(e) => this.filter(e.key)}
                    componentRef={this.inputSearch}
                    styles={{
                      root: {
                        border: "none",
                        width: 200,
                        marginRight: 1,
                      },
                    }}
                  />
                  <CommandBarButton
                    styles={btnStyle}
                    iconProps={{ iconName: "Refresh" }}
                    text="Atualizar"
                    onClick={() => getStandardsPositions()}
                  />
                </>
              }
            />
            {!standardsPositions.loadingData &&
            standardsPositions.data.length === 0 ? (
              <NoItems
                error={standardsPositions.error}
                text="Não há padrões de cargos cadastradas"
                icon="Work"
              />
            ) : (
              <ListContainer>
                <ShimmeredDetailsList
                  items={isFiltering ? items : standardsPositions.data}
                  enableShimmer={standardsPositions.loadingData}
                  columns={columns}
                  selectionMode={SelectionMode.single}
                  selection={this._selection}
                  getKey={this._getKey}
                  selectionPreservedOnEmptyClick={true}
                  setKey="single"
                  layoutMode={DetailsListLayoutMode.justified}
                  isHeaderVisible={true}
                  onItemInvoked={this._onItemInvoked}
                />
              </ListContainer>
            )}
          </ContainerContent>
        </Wrapper>

        <CustomDialog
          hidden={!isDialogExcludeOpen}
          onDismiss={() => this.setState({ isDialogExcludeOpen: false })}
          dialogContentProps={dialogContentProps}
        >
          <DefaultButton
            onClick={() => this.setState({ isDialogExcludeOpen: false })}
            text="Cancelar"
          />
          <DeleteButton onClick={() => this.exclude()} text="Excluir" />
        </CustomDialog>
      </>
    );
  }

  private _onColumnClick = (
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn
  ): void => {
    const { columns } = this.state;
    const newColumns: IColumn[] = columns.slice();
    let items: StandardPositionLoadDTO[] = [];
    if (this.state.isFiltering) {
      items = this.state.items;
    } else {
      items = this.props.standardsPositions.data;
    }
    const currColumn: IColumn = newColumns.filter(
      (currCol) => column.key === currCol.key
    )[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    const newItems = this._sort(
      items,
      currColumn.fieldName!,
      currColumn.isSortedDescending
    );
    this.setState({
      columns: newColumns,
      items: newItems,
    });
  };

  private _getKey(item: any, index?: number): any {
    if (item !== undefined) return item.key;
  }

  private _sort<T>(
    items: T[],
    columnKey: string,
    isSortedDescending?: boolean
  ): T[] {
    const result = (prop: string, obj: T) =>
      prop.split(".").reduce((a: any, b: any) => a[b] ?? "", obj);
    return items.sort((a: T, b: T) =>
      (
        isSortedDescending
          ? result(columnKey, a) < result(columnKey, b)
          : result(columnKey, a) > result(columnKey, b)
      )
        ? 1
        : -1
    );
  }
}

/**
 * Validação do formulário
 */
const validationStandardSchema = yup.object().shape({
  descPadraoCargo: yup
    .string()
    .max(100, "Máximo de 100 caracteres")
    .required("Campo obrigatório"),
});

const dialogContentProps: IDialogContentProps = {
  title: "Excluir?",
  closeButtonAriaLabel: "Close",
  subText: "Tem certeza de que deseja excluir este item?",
};

const itemsBreadCrumb: BreadcrumbItems[] = [
  {
    text: "Home",
    isCurrentItem: false,
    icon: "HomeSolid",
    onlyIcon: true,
    url: "/",
  },
  { text: "Padrões de Cargo", isCurrentItem: true },
];

const initialStandard: StandardPositionAddOrEditDTO = {
  descPadraoCargo: "",
};

const btnStyle: Partial<ICommandBarStyles> = {
  root: {
    height: 44,
  },
};

const mapStateToProps = (state: RootState) => ({
  standardsPositions: state.standardPositionReducer,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      ...getStandardsPositions,
      ...addStandardPosition,
      ...editStandardPosition,
      ...delStandardPosition,
      ...setCurrentPage,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(StandardsPositions);
