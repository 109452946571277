import { combineReducers } from "redux";

import loginReducer from "./login";
import companiesReducer from "./admin/companies";
import cyclesReducer from "./cycles";
import cycleReducer from "./cycle";
import cycleAttitudeReducer from "./cycles/attitude";
import cyclePainelReducer from "./cycle/painel";
import cycleDismembermentReducer from "./cycle/dismemberment";
import cycleValidationReducer from "./cycle/validation";
import cyclePerformanceReducer from "./cycles/performance";
import cycleAvaliationReducer from "./avaliation";
import cycleGroupsReducer from "./cycle/groups";
import cycleTeamsReducer from "./cycle/teams";
import pillarsReducer from "./pillars";
import homeReducer from "./home";
import objectivesReducer from "./objectives";
import chartReducer from "./admin/chart";
import employeesReducer from "./employees";
import unitsReducer from "./admin/units";
import positionsReducer from "./admin/positions";
import tasksReducer from "./tasks";
import profileReducer from "./profile";
import solicitationReducer from "./solicitation";
import attitudesReducer from "./attitudes";
import selfEvaluationsReducer from "./selfEvaluations";
import teamEvaluationReducer from "./teamEvaluation";
import workflowReducer from "./workflow";
import meetingsReducer from "./meetings";
import calibrationReducer from "./calibration";
import worklevelReducer from "./worklevel";
import pdiReducer from "./admin/pdi";
import competenceReducer from "./competences";
import typesPDIReducer from "./typesPDI";
import reportsReducer from "./reports";
import salaryStructureReducer from "./admin/salaryStructure";
import stepsReducer from "./admin/steps";
import tipoMovReducer from "./admin/tipomov";
import adminReducer from "./admin/admin";
import vacancyReducer from "./vacancies";
import adminMeritReducer from "./admin/merit";
import categoriesPositionsReducer from "./admin/categoriesPositions";
import motivoDesligamentoReducer from "./admin/motivoDesligamento";
import motivoFaltaReducer from "./admin/motivoFalta";
import standardPositionReducer from "./admin/standardPosition";
import tabPerformancesReducer from "./admin/tabPerformances";
import motivoAfastamentoReducer from "./admin/motivoAfastamento";
import vinculoReducer from "./admin/vinculo";
import meritGenerateReducer from "./meritGenerate";
import meritReducer from "./merit";
import sanctionReducer from "./admin/sanctions";
import conductsReducer from "./admin/conducts";
import typeConductReducer from "./admin/typeConducts";
import ccReducer from "./cc";
import teamReducer from "./team";
import bonusReducer from "./bonus";
import bonusGenerateReducer from "./bonusGenerate";
import reportMeritReducer from "./reportMerit";
import carreiraReducer from "./admin/carreira";
import educationalMeasureReducer from "./educationalMeasure";
import filesReducer from "./files";

const reducers = combineReducers({
  loginReducer,
  companiesReducer,
  cyclesReducer,
  cycleReducer,
  cycleAttitudeReducer,
  cyclePainelReducer,
  cycleDismembermentReducer,
  cyclePerformanceReducer,
  cycleAvaliationReducer,
  cycleGroupsReducer,
  cycleTeamsReducer,
  pillarsReducer,
  homeReducer,
  objectivesReducer,
  chartReducer,
  employeesReducer,
  unitsReducer,
  positionsReducer,
  cycleValidationReducer,
  tasksReducer,
  profileReducer,
  solicitationReducer,
  attitudesReducer,
  selfEvaluationsReducer,
  teamEvaluationReducer,
  workflowReducer,
  meetingsReducer,
  worklevelReducer,
  calibrationReducer,
  pdiReducer,
  competenceReducer,
  typesPDIReducer,
  reportsReducer,
  salaryStructureReducer,
  stepsReducer,
  tipoMovReducer,
  adminReducer,
  vacancyReducer,
  adminMeritReducer,
  tabPerformancesReducer,
  meritGenerateReducer,
  meritReducer,
  ccReducer,
  teamReducer,
  bonusReducer,
  bonusGenerateReducer,
  categoriesPositionsReducer,
  motivoDesligamentoReducer,
  motivoFaltaReducer,
  standardPositionReducer,
  motivoAfastamentoReducer,
  vinculoReducer,
  reportMeritReducer,
  carreiraReducer,
  sanctionReducer,
  conductsReducer,
  typeConductReducer,
  educationalMeasureReducer,
  filesReducer,
});

export const rootReducer = (state: any, action: any) => {
  if (action.type === "login/CLEAR_STORE") return reducers(undefined, action);
  return reducers(state, action);
};

export type RootState = ReturnType<typeof reducers>;
