// customToast.js
import React from 'react'
import { FontIcon } from 'office-ui-fabric-react/lib/Icon';

import { toast, Zoom } from 'react-toastify';
import "./styles.css"

const customToast = {
    success(msg: any, options = {}) {
        return toast.success(<span style={{display: 'flex', alignItems: 'center'}}><FontIcon iconName="SkypeCircleCheck" style={{fontSize: 22, paddingRight: 10}} />{`${msg}`}</span>, {
            ...options,
            className: 'toast-container toast-success',
            transition: Zoom,
            draggable: false,
            autoClose: 5000            
        });
    },
    error(msg: string, options = {}) {
        return toast.error(<span style={{display: 'flex', alignItems: 'center'}}><FontIcon iconName="Error" style={{fontSize: 22, paddingRight: 10}} />{`${msg}`}</span>, {
            ...options,
            className: 'toast-container toast-error',
            transition: Zoom,
            draggable: false,
            autoClose: 5000     
        });
    },
    info(msg: string, options = {}) {
        return toast.error(<span style={{display: 'flex', alignItems: 'center'}}><FontIcon iconName="Info" style={{fontSize: 22, paddingRight: 10}} />{`${msg}`}</span>, {
            ...options,
            className: 'toast-container toast-info',
            transition: Zoom,
            draggable: false,
            autoClose: 5000     
        });
    },
    warning(msg: string, options = {}) {
        return toast.error(<span style={{display: 'flex', alignItems: 'center'}}><FontIcon iconName="Warning" style={{fontSize: 22, paddingRight: 10}} />{`${msg}`}</span>, {
            ...options,
            className: 'toast-container toast-warning',
            transition: Zoom,
            draggable: false,
            autoClose: 5000     
        });
    },
};


export default customToast;