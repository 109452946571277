import styled from 'styled-components';
import Colors from '~/assets/js/colors';

export const Table = styled.table`
    border-collapse: separate;
    border-spacing: 20px;
    width: 100%;
    display: block;
    overflow: auto;
    margin: -10px -20px;
    width: calc(100% + 20px);
`
export const Th = styled.th`
    width: 195px;
    min-width: 195px;
    max-width: 195px;
    box-sizing: border-box;
`
export const Tr = styled.tr`
    height: 72px;
`

export const VagaContainer = styled.div<{ cor: boolean }>`
    background-color: ${({ cor }) => cor ? '#CCCCCC' : '#484644'};
    color: #FFFFFF;
    text-align: center;
    height: 48px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    justify-content: center;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
`

export const LiderContainer = styled.div`
    background-color: #48464410;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 24px;
    box-sizing: border-box;
    padding: 0 20px;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
`

export const SuccessorCointainer = styled.div`
    height: 100%;
    padding: 0 10px;
    overflow: hidden;

`


export const SuccessionTimeWrapper = styled.div`
    height: 24px;
    min-height: 24px;
    text-align: center;
    border-radius: 2px;
    color: #FFFFFF;
    text-transform: uppercase;
`

export const Td = styled.td<{ color: string }>`
    width: 195px;
    min-width: 195px;
    max-width: 195px;
    height: inherit;
    box-sizing: border-box;
    border-radius: 2px;
    background-color: ${({ color }) => color}10;

    &:hover .linkAddFuncSuccessao{
        display: block;
        cursor: pointer;
        color: ${Colors.primary};
    }

    ${SuccessionTimeWrapper}{
        background-color: ${({ color }) => color};
    }
`

export const WrapperTd = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`

export const WrapperRemove = styled.div`
    position: absolute;
    top: 0px;
    right: -16px;
    display: none;
    transition: right 0.2s;
`

export const SuccessorWrapper = styled.div<{ flagInicial?: boolean }>`
    display: flex;
    align-items: center;
    height: 24px;
    min-height: 24px;
    position: sticky;

    ${({ flagInicial }) => !flagInicial ? `
    &:hover ${WrapperRemove} {
        display: block;
    }
    ` : ''}
`

