import styled from 'styled-components';
import { ITextStyles } from "office-ui-fabric-react/lib/Text";

export const TitleGroupStyles: Partial<ITextStyles> = {
    root: {
        marginBottom: 4,
        height: 20,
        display: 'flex',
        alignItems: 'center',
        fontWeight: 600
    }
}

export const TitleFieldStyles: Partial<ITextStyles> = {
    root: {
        marginBottom: 4,
        height: 'auto',
        minHeight: 20,
        display: 'flex',
        alignItems: 'center',
    }
}

export const TitleHeaderStyles = styled.div`
    margin-top: 5px;
    margin-bottom: 32px;
    height: 32px;
`

export const WrapperGroupItemLeft = styled.div`
    width: 290px;
    margin-right: 10px;
`

export const WrapperGroupItemRight = styled.div`
    width: 290px;
`

export const WrapperGroupItems = styled.div<{ margin?: boolean }>`
    margin-bottom: ${({ margin }) => margin ? 0 : 24}px;
    
`

export const WrapperItems = styled.div<{ hasMultiItems: boolean }>`
    display: ${props => props.hasMultiItems ? 'block' : 'flex'};
    flex-wrap: wrap;
    flex-direction: row;

    div{
        padding-right: 10px;
    }
    div:last-child{
        padding-right: 0px;
    }
`

export const WrapperGroupItemUmTerco = styled.div`
    box-sizing: border-box;
    width: 190px;
`
export const WrapperGroupItemMini = styled.div`
    box-sizing: border-box;
    width: 90px;
`

export const WrapperGroupItemFull = styled.div`
    box-sizing: border-box;
    width: 590px;
`

export const WrapperGroupItemDoisTerco = styled.div<{ width?: number }>`
    box-sizing: border-box;
    width: ${({ width }) => width ? width : 390}px;
`