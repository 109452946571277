import { Types, DataTypes, TeamEvaluationActionTypes } from "./types";
import customToast from "~/components/Toast/index";

/**
 * Reducers
 */

const initialValues = {
  idCicloTime: null,
  membros: [],
  ciclo: {
    ano: null
  },
  funcionario: {
    nomeCompleto: ''
  }
}
const INITIAL_STATE: DataTypes = {
  data: initialValues,
  loading: false,
  error: false,
  success: false
};

/**
 * Actions
 */

export const Creators = {
  loadTeam: (idCiclo: number): TeamEvaluationActionTypes => {
    return ({
      type: Types.REQUEST_TEAM_EVALUATION_LOAD,
      idCiclo
    })
  },

}


export default function teamEvaluationReducer(state = INITIAL_STATE, action: TeamEvaluationActionTypes): DataTypes {
  switch (action.type) {

    case Types.REQUEST_TEAM_EVALUATION_LOAD:
      return { ...state, data: initialValues, error: false, success: false, loading: true };
    case Types.SUCCESS_TEAM_EVALUATION_LOAD:
      return { ...state, data: action.payload, error: false, success: true, loading: false };
    case Types.FAILURE_TEAM_EVALUATION_LOAD:
      customToast.error(action.message)
      return { ...state, error: true, success: false, loading: false };

    default: {
      return state;
    }
  }
}
