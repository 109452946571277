
/**
 * Types
 */
export enum Types {
    //GET
    REQUEST_PDI_LOAD = "pdi/REQUEST_PDI_LOAD",
    SUCCESS_PDI_LOAD = "pdi/SUCCESS_PDI_LOAD",
    FAILURE_PDI_LOAD = "pdi/FAILURE_PDI_LOAD",
    //ADD
    REQUEST_PDI_ADD = "pdi/REQUEST_PDI_ADD",
    SUCCESS_PDI_ADD = "pdi/SUCCESS_PDI_ADD",
    FAILURE_PDI_ADD = "pdi/FAILURE_PDI_ADD",
    //EDIT
    REQUEST_PDI_EDIT = "pdi/REQUEST_PDI_EDIT",
    SUCCESS_PDI_EDIT = "pdi/SUCCESS_PDI_EDIT",
    FAILURE_PDI_EDIT = "pdi/FAILURE_PDI_EDIT",
    //DELETE
    REQUEST_PDI_DELETE = "pdi/REQUEST_PDI_DELETE",
    SUCCESS_PDI_DELETE = "pdi/SUCCESS_PDI_DELETE",
    FAILURE_PDI_DELETE = "pdi/FAILURE_PDI_DELETE",
    //RESET
    RESET_PDI = "pdi/RESET_PDI",
}

export type PDIType = {
    idAcaoPDI: number | null;
    idCompetencia: number | null;
    idTipoPDI: number | null;
    nomeAcaoPDI: string;
    prazo: number | null;
    flagOutros: boolean | null;
    flagRelacionarFuncionario: boolean;
    // competencia: {
    //     descCompetencia: string;
    // };
    tipoPDI: {
        descTipoPDI: string;
        cor: string;
        // flagRelacionarFuncionario: boolean;
    }
}

interface IItemSelectedState {
    item: PDIType | null;
    loading: boolean;
    loadingAction: boolean;
    success: boolean;
    error: boolean;
}

interface IPDIState {
    readonly data: PDIType[];
    readonly itemSelected: IItemSelectedState;
    readonly loadingData: boolean;
    readonly loadingAction: boolean;
    readonly error: boolean;
    readonly errorAction: boolean;
    readonly success: boolean;
};

export type DataTypes = IPDIState;

//GET PDI
export interface IRequestPDILoadAction {
    type: typeof Types.REQUEST_PDI_LOAD;
    payload: {
        SearchText?: string;
        TipoPDI?: number | string;
        Competencia?: number | string;
    }
};

export interface ISuccessPDILoadAction {
    type: typeof Types.SUCCESS_PDI_LOAD;
    payload: PDIType[];
};

interface IFailurePDILoadAction {
    type: typeof Types.FAILURE_PDI_LOAD;
    message: string;
};

type PdiLoadAction = IRequestPDILoadAction | ISuccessPDILoadAction | IFailurePDILoadAction;

//ADD PDI
export interface IRequestPDIAddAction {
    type: typeof Types.REQUEST_PDI_ADD;
    payload: {
        pdi: PDIType;
    };
};

export interface ISuccessPDIAddAction {
    type: typeof Types.SUCCESS_PDI_ADD;
    payload: PDIType;
};

interface IFailurePDIAddAction {
    type: typeof Types.FAILURE_PDI_ADD;
    message: string;
};

type PdiAddAction = IRequestPDIAddAction | ISuccessPDIAddAction | IFailurePDIAddAction;

//EDIT PDI
export interface IRequestPDIEditAction {
    type: typeof Types.REQUEST_PDI_EDIT;
    payload: {
        pdi: PDIType;
    };
};

export interface ISuccessPDIEditAction {
    type: typeof Types.SUCCESS_PDI_EDIT;
    payload: PDIType;
};

interface IFailurePDIEditAction {
    type: typeof Types.FAILURE_PDI_EDIT;
    message: string;
};

type PdiEditAction = IRequestPDIEditAction | ISuccessPDIEditAction | IFailurePDIEditAction;

//delete PDI
export interface IRequestPDIDeleteAction {
    type: typeof Types.REQUEST_PDI_DELETE;
    idAcaoPDI: number;
};

export interface ISuccessPDIDeleteAction {
    type: typeof Types.SUCCESS_PDI_DELETE;
    idAcaoPDI: number;
};

interface IFailurePDIDeleteAction {
    type: typeof Types.FAILURE_PDI_DELETE;
    message: string;
};

type PdiDeleteAction = IRequestPDIDeleteAction | ISuccessPDIDeleteAction | IFailurePDIDeleteAction;

interface IResetPDIAction {
    type: typeof Types.RESET_PDI;
};

export type PDIActionTypes = PdiLoadAction | PdiAddAction | PdiEditAction | PdiDeleteAction | IResetPDIAction;

