import React, { useEffect, useState } from 'react';
import * as yup from "yup";
import { Formik } from "formik";

import { InputText, InputCheckbox } from '~/components/Forms';

import { EmployeeType } from "~/store/ducks/employees/types";
import { CycleType } from "~/store/ducks/cycles/types";
import { FormHeaderStyle } from '~/components/FormGroup';

export type InfoCycleType = {
    ano: number | null;
    responsavel: Partial<EmployeeType>;
};

interface IFormInfoProps {
    formRef: any;
    initialValues: Partial<CycleType>;
    success: (values: Partial<CycleType>) => any;
    // idAreaPai: number | null;
};

const validationSchemaGeracao = yup.object().shape({
    gerarProjeto: yup.boolean().required("Campo obrigatório"),
    nomeProjeto: yup.string().nullable().when("gerarProjeto", {
        is: true,
        then: yup.string().nullable().required("Campo obrigatório")
    }),
    descProjeto: yup.string().nullable().when("gerarProjeto", {
        is: true,
        then: yup.string().nullable().required("Campo obrigatório")
    }),
});

const FormContentGeracao: React.FC<IFormInfoProps> = (props) => {
    const [initialValues, setInitialValues] = useState(props.initialValues);

    useEffect(() => {
        if (props.initialValues.gerarProjeto === null) {
            setInitialValues({
                ...initialValues,
                gerarProjeto: false
            })
        }
    }, [])

    return (
        <>
            <FormHeaderStyle text="Metas para Iniciativas" />
            <Formik
                enableReinitialize={true}
                innerRef={props.formRef}
                initialValues={initialValues}
                validationSchema={validationSchemaGeracao}
                validateOnChange={false}
                validateOnBlur={true}
                onSubmit={(values: Partial<CycleType>) => {
                    props.success(values);
                }}
            >
                {({ handleSubmit, handleChange, values, errors, setFieldError, setFieldValue }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <div className="ms-Grid" dir="ltr">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12" style={{ paddingLeft: 0, paddingRight: 8 }}>
                                        <InputCheckbox
                                            checked={values.gerarProjeto ?? false}
                                            onChange={(e => {
                                                handleChange(e);
                                                if (!e.target.checked) {
                                                    setFieldValue('nomeProjeto', '');
                                                    setFieldValue('descProjeto', '');
                                                }
                                            })}
                                            name="gerarProjeto"
                                            color="primary"
                                            label="Gerar iniciativas automaticamente"
                                        />
                                    </div>
                                    <div className="ms-Grid-col ms-sm12" style={{ paddingLeft: 0, paddingRight: 8 }}>
                                        <InputText
                                            value={!values.gerarProjeto ? '' : values.nomeProjeto !== null ? values.nomeProjeto : ""}
                                            onChange={e => { handleChange(e); setFieldError('nomeProjeto', '') }}
                                            id="nomeProjeto"
                                            error={errors.nomeProjeto ? true : false}
                                            name="nomeProjeto"
                                            type="text"
                                            label="Nome da iniciativa"
                                            helperText={errors.nomeProjeto}
                                            disabled={!values.gerarProjeto}
                                            className="mt-2"
                                            InputProps={{
                                                autoComplete: "off"
                                            }}
                                        />
                                    </div>
                                    <div className="ms-Grid-col ms-sm12" style={{ paddingLeft: 0, paddingRight: 8 }}>
                                        <InputText
                                            value={!values.gerarProjeto ? '' : values.descProjeto !== null ? values.descProjeto : ""}
                                            onChange={e => { handleChange(e); setFieldError('descProjeto', '') }}
                                            id="descProjeto"
                                            error={errors.descProjeto ? true : false}
                                            name="descProjeto"
                                            type="text"
                                            label="Descrição da iniciativa"
                                            helperText={errors.descProjeto}
                                            disabled={!values.gerarProjeto}
                                            className="mt-2"
                                            multiline
                                            rows={3}
                                            InputProps={{
                                                autoComplete: "off"
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    )
                }}
            </Formik>
        </>
    );
};

export default FormContentGeracao;