// #region Imports
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
 
import moment from "moment";
import { Formik } from "formik";
import * as yup from "yup";

//FluentUI
import {
  DetailsListLayoutMode,
  SelectionMode,
  Selection,
  IColumn,
} from "office-ui-fabric-react/lib/DetailsList";
import { ShimmeredDetailsList } from "office-ui-fabric-react/lib/ShimmeredDetailsList";
import { CommandBarButton, ICommandBarStyles } from "office-ui-fabric-react";
import { IDialogContentProps } from "office-ui-fabric-react/lib/Dialog";

import MenuItem from "@material-ui/core/MenuItem";
import Dropdown from "~/components/layout/Dropdown";
import CustomDialog from "~/components/CustomDialogFluentUI";
import { Page, BreadcrumbItems } from "~/store/ducks/home/types";

import { DeleteButton } from "~/components/Buttons";
import HeaderPage from "~/components/layout/HeaderPage";
import NoItems from "~/components/layout/NoItems";
//Estilos
import {
  Wrapper,
  ListContainer,
  DefaultButton,
  ContainerContent,
} from "./styles";
import Dialog from "~/components/CustomDialog";

import { Creators as getBonus } from "~/store/ducks/bonusGenerate";
import { Creators as addBonus } from "~/store/ducks/bonusGenerate";
import { Creators as delBonus } from "~/store/ducks/bonusGenerate";
import {
  DataTypes as DataTypesBonus,
  BonusGenerateType,
} from "~/store/ducks/bonusGenerate/types";

import { Creators as setCurrentPage } from "~/store/ducks/home";
import { DataTypes as DataTypesLogin } from "~/store/ducks/login/types";
import { RootState } from "~/store/ducks";
import { InputDate, InputNumber, InputText } from "~/components/Forms";
import { Creators as getTabPerformances } from "~/store/ducks/admin/tabPerformances";
import { DataTypes as DataTypesTabPerformance } from "~/store/ducks/admin/tabPerformances/types";

//#endregion

/**
 * Validação do formulário
 */

interface IBonusState {
  columns: IColumn[];
  items: BonusGenerateType[];
  isDialogAddOpen: boolean;
  isFiltering: boolean;
  selectionDetails: string;
  selectionCount: number;
  isDialogExcludeOpen: boolean;
}

interface IPropsBonus {
  bonus: DataTypesBonus;
  login: DataTypesLogin;
  tabPerformance: DataTypesTabPerformance;
  setCurrentPage: (page: Page) => void;
  getBonus: () => void;
  addBonus: (bonus: Partial<BonusGenerateType>) => void;
  delBonus: (idBonus: number) => void;
  getTabPerformances: (SearchText?: string) => void;
  history: RouteComponentProps["history"];
}

const itemsBreadCrumb: BreadcrumbItems[] = [
  {
    text: "Home",
    isCurrentItem: false,
    icon: "HomeSolid",
    onlyIcon: true,
    url: "/",
  },
  { text: "Bônus", isCurrentItem: true },
];

class Bonus extends Component<IPropsBonus, IBonusState> {
  private formRef: any;
  private _selection: Selection;

  constructor(props: IPropsBonus) {
    super(props);
    //#region Colunas
    const columns: IColumn[] = [
      {
        key: "column1",
        name: "Ano",
        ariaLabel: "ano",
        fieldName: "ano",
        isRowHeader: true,
        minWidth: 50,
        maxWidth: 75,
        isResizable: true,
        isSortedDescending: false,
        onColumnClick: this._onColumnClick,
      },
      {
        key: "column2",
        name: "Data",
        fieldName: "dataReferencia",
        minWidth: 75,
        maxWidth: 100,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "string",
        isPadded: true,
        onRender: (value: BonusGenerateType) =>
          moment(value.dataReferencia).format("DD/MM/YYYY"),
      },
      {
        key: "column4 ",
        name: "Status",
        fieldName: "statusbonus",
        minWidth: 150,
        maxWidth: 200,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "string",
        isPadded: true,
      },
    ];
    //#endregion

    this.state = {
      columns: columns,
      items: [],
      isDialogAddOpen: false,
      selectionDetails: "",
      isFiltering: false,
      selectionCount: 0,
      isDialogExcludeOpen: false,
    };

    this._selection = new Selection({
      onSelectionChanged: () => {
        this.setState({
          selectionDetails: this._getSelectionDetails(),
        });
      },
    });

    this.formRef = React.createRef();
  }

  componentDidMount() {
    const page: Page = {
      key: "bonus",
      pages: itemsBreadCrumb,
    };
    this.props.setCurrentPage(page);
    this.props.getBonus();
    this.props.getTabPerformances();
  }

  componentDidUpdate(prevProps: IPropsBonus, _: any) {
    if (prevProps.bonus.successAdd !== this.props.bonus.successAdd) {
      if (this.props.bonus.successAdd) {
        this.setState({ isDialogAddOpen: false });
        // this.props.history.push(
        //   `/bonus/${this.props.bonus.idBonusCreated}`
        // );
      }
    }
  }

  // Submit do formulário Formik
  handleSubmit = () => {
    if (this.formRef.current) {
      this.formRef.current.handleSubmit();
    }
  };

  _onItemInvoked = (): void => {
    const {
      idBonus,
    } = (this._selection.getSelection()[0] as BonusGenerateType)!;
    this.props.history.push(`/bonus/${idBonus}`);
  };

  _getSelectionDetails(): any {
    const selectionCount = this._selection.getSelectedCount();
    this.setState({ selectionCount });
    this._selection.getSelection();
  }

  cancelPanel = () => {
    this._selection.setAllSelected(false);
    this.setState({ isDialogAddOpen: false });
  };

  excludeBonus = () => {
    const { idBonus } = this._selection.getSelection()[0] as BonusGenerateType;
    this.props.delBonus(idBonus!);
    this.setState({ isDialogExcludeOpen: false });
    this._selection.setAllSelected(false);
  };

  commandBarRender = () => {
    const { selectionCount } = this.state;
    if (selectionCount === 1) {
      return (
        <CommandBarButton
          styles={btnStyle}
          iconProps={{ iconName: "Delete" }}
          text="Excluir"
          onClick={() => this.setState({ isDialogExcludeOpen: true })}
        />
      );
    }
  };

  render() {
    const {
      isDialogAddOpen,
      isDialogExcludeOpen,
      isFiltering,
      items,
      columns,
    } = this.state;
    const { getBonus, addBonus, bonus, login, tabPerformance } = this.props;

    return (
      <>
        <Wrapper>
          <ContainerContent>
            <HeaderPage
              title="Geração do Bônus"
              leftItems={
                login.data.flagAdministrador ? (
                  <>
                    <CommandBarButton
                      styles={btnStyle}
                      iconProps={{ iconName: "Add" }}
                      disabled={bonus.loading}
                      text="Adicionar Geração"
                      onClick={() =>
                        this.setState({
                          isDialogAddOpen: true,
                        })
                      }
                    />
                    {this.commandBarRender()}
                  </>
                ) : (
                  <></>
                )
              }
              rightItems={
                <>
                  <CommandBarButton
                    styles={btnStyle}
                    iconProps={{ iconName: "Refresh" }}
                    text="Atualizar"
                    onClick={() => getBonus()}
                  />
                </>
              }
            />
            {!bonus.loading && bonus.data?.length === 0 ? (
              <NoItems
                error={bonus.error}
                text="Não há bônus gerado"
                icon="Ribbon"
              />
            ) : (
              <ListContainer>
                <ShimmeredDetailsList
                  items={isFiltering ? items : bonus.data}
                  enableShimmer={bonus.loading}
                  columns={columns}
                  selectionMode={SelectionMode.single}
                  selection={this._selection}
                  getKey={this._getKey}
                  selectionPreservedOnEmptyClick={true}
                  setKey="single"
                  layoutMode={DetailsListLayoutMode.justified}
                  isHeaderVisible={true}
                  onItemInvoked={this._onItemInvoked}
                />
              </ListContainer>
            )}
          </ContainerContent>
        </Wrapper>

        <CustomDialog
          hidden={!isDialogExcludeOpen}
          onDismiss={() => this.setState({ isDialogExcludeOpen: false })}
          dialogContentProps={dialogContentProps}
        >
          <DefaultButton
            onClick={() => this.setState({ isDialogExcludeOpen: false })}
            text="Cancelar"
          />
          <DeleteButton onClick={() => this.excludeBonus()} text="Excluir" />
        </CustomDialog>

        <Dialog
          title="Adicionar Bônus"
          open={isDialogAddOpen}
          onClickCancel={() => this.setState({ isDialogAddOpen: false })}
          onClickConfirm={this.handleSubmit}
          btnCancelText="Fechar"
          disabledBtnConfirm={bonus.loadingAction}
          isLoadingConfirm={bonus.loadingAction}
          btnConfirmText="Criar"
          zIndex={1300}
          height={424}
        >
          <Formik
            innerRef={this.formRef}
            initialValues={initialBonus}
            validationSchema={validationSchema}
            validateOnChange={false}
            validateOnBlur={true}
            onSubmit={(values) => {
              values.ano = String(values.ano);
              values.dataReferencia = moment(values.dataReferencia).format(
                "YYYY-MM-DD"
              );
              addBonus(values);
            }}
          >
            {({
              handleSubmit,
              handleChange,
              values,
              errors,
              setFieldError,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <InputText
                  value={values.ano ?? ""}
                  onChange={(e) => {
                    handleChange(e);
                    setFieldError("ano", "");
                  }}
                  id="ano"
                  error={!!errors.ano}
                  name="ano"
                  type="number"
                  label="Ano"
                  helperText={errors.ano}
                  autoFocus
                  className="mt-2"
                />
                <InputDate
                  label="Data de Referência"
                  name="dataReferencia"
                  value={
                    values.dataReferencia ? moment(values.dataReferencia) : null
                  }
                  onChange={(value) => {
                    setFieldValue("dataReferencia", value);
                    setFieldError("dataReferencia", "");
                  }}
                  className="mt-2"
                  error={!!errors.dataReferencia}
                  helperText={errors.dataReferencia}
                />
                <Dropdown
                  errors={errors.idTabPerformance}
                  label="Tabela de Performance"
                  name="idTabPerformance"
                  values={values.idTabPerformance ?? ""}
                  handleChange={(e: any) => {
                    setFieldValue("idTabPerformance", Number(e.target.value));
                    setFieldError("idTabPerformance", "");
                  }}
                  errorText={errors.idTabPerformance}
                >
                  {tabPerformance.data.map((tab) => (
                    <MenuItem
                      key={tab.idTabPerformance}
                      value={`${tab.idTabPerformance}`}
                    >
                      {tab.nomeTabPerformance}
                    </MenuItem>
                  ))}
                </Dropdown>
                <InputNumber
                  value={
                    !!values.mesesTrabalhados
                      ? Number(values.mesesTrabalhados)
                      : null
                  }
                  onValueChange={(value) => {
                    setFieldValue("mesesTrabalhados", value.floatValue);
                    setFieldError("mesesTrabalhados", "");
                  }}
                  id="mesesTrabalhados"
                  name="mesesTrabalhados"
                  label="Meses trabalhados"
                  decimalScale={0}
                  helperText={errors.mesesTrabalhados}
                  error={!!errors.mesesTrabalhados}
                />
              </form>
            )}
          </Formik>
        </Dialog>
      </>
    );
  }

  private _onColumnClick = (
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn
  ): void => {
    const { columns } = this.state;
    const newColumns: IColumn[] = columns.slice();
    let items: BonusGenerateType[] = [];
    if (this.state.isFiltering) {
      items = this.state.items;
    } else {
      items = this.props.bonus.data;
    }
    const currColumn: IColumn = newColumns.filter(
      (currCol) => column.key === currCol.key
    )[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    const newItems = this._sort(
      items,
      currColumn.fieldName!,
      currColumn.isSortedDescending
    );
    this.setState({
      columns: newColumns,
      items: newItems,
    });
  };

  private _getKey(item: any, index?: number): any {
    if (item !== undefined) return item.key;
  }

  private _sort<T>(
    items: T[],
    columnKey: string,
    isSortedDescending?: boolean
  ): T[] {
    const key = columnKey as keyof T;
    const itemsSorted = items.sort((a: T, b: T) =>
      (isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1
    );
    return itemsSorted;
  }
}

const initialBonus: BonusGenerateType = {
  ano: "",
  dataReferencia: null,
  idTabPerformance: null,
  statusbonus: "",
  mesesTrabalhados: null,
};

const validationSchema = yup.object().shape({
  ano: yup.string().required("Campo obrigatório"),
  dataReferencia: yup
    .date()
    .nullable()
    .typeError("Data inválida")
    .required("Campo obrigatório"),
  idTabPerformance: yup.number().nullable().required("Campo obrigatório"),
  mesesTrabalhados: yup
    .number()
    .min(0, "Qtde. mínima meses trabalhados inválida")
    .max(12, "Qtde. mínima meses trabalhados maior que 12")
    .nullable()
    .required("Campo obrigatório"),
});

const dialogContentProps: IDialogContentProps = {
  title: "Excluir?",
  closeButtonAriaLabel: "Close",
  subText: "Tem certeza de que deseja excluir esta reunião?",
};

const btnStyle: Partial<ICommandBarStyles> = {
  root: {
    height: 44,
  },
};

const mapStateToProps = (state: RootState) => ({
  bonus: state.bonusGenerateReducer,
  login: state.loginReducer,
  tabPerformance: state.tabPerformancesReducer,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      ...getBonus,
      ...addBonus,
      ...delBonus,
      ...getTabPerformances,
      ...setCurrentPage,
    },
    dispatch
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Bonus));
