import React, { useState, useEffect } from 'react';
import Colors from '~/assets/js/colors';

import {
    TimerLabel,
    BaseTimer,
    BaseTimerSvg,
    BaseTimerCircle,
    BaseTimerElapsed,
    BaseTimerRemaining
} from './styles';

interface ICountDownProps {
    limit: number;
    acumulated: number;
    isActive: boolean;
}

const CountDown: React.FC<ICountDownProps> = (props) => {
    const FULL_DASH_ARRAY = 283;
    const WARNING_THRESHOLD = 10;
    const ALERT_THRESHOLD = 5;

    const COLOR_CODES = {
        info: {
            color: "green"
        },
        warning: {
            color: "orange",
            threshold: WARNING_THRESHOLD
        },
        alert: {
            color: "red",
            threshold: ALERT_THRESHOLD
        }
    };

    const TIME_LIMIT = props.limit;
    let timePassed = props.acumulated;
    let timerInterval: number | null = null;
    let remainingPathColor = COLOR_CODES.info.color;

    const [timeLeft, setTimeLeft] = useState(TIME_LIMIT)

    useEffect(() => {
        if (props.isActive) {
            startTimer();
        } else {
            timerInterval = null;
            onTimesUp();
        }
        return () => {
            onTimesUp()
        }
    }, [props.isActive])

    useEffect(() => {
        const circleDasharray = `${(
            calculateTimeFraction() * FULL_DASH_ARRAY
        ).toFixed(0)} 283`;
        document
            .getElementById("base-timer-path-remaining")!
            .setAttribute("stroke-dasharray", circleDasharray);
    }, [timeLeft])


    const onTimesUp = () => {
        window.clearInterval(timerInterval!);
    }

    const startTimer = () => {
        timerInterval = window.setInterval(() => {
            timePassed = timePassed += 1;
            setTimeLeft(TIME_LIMIT - timePassed);
            // document.getElementById("base-timer-label")!.innerHTML = formatTime(
            //     timeLeft
            // );
            // setCircleDasharray(timeLeft);
            setRemainingPathColor(timeLeft);

            if (timeLeft === 0) {
                onTimesUp();
            }
        }, 1000);
    }

    const formatTime = (time: number): string => {
        let minutes: number | string = Math.floor(time / 60);
        let seconds: number | string = time % 60;
        if (time >= 0) {
            if (minutes < 10) {
                minutes = `0${minutes}`;
            }
            if (seconds < 10) {
                seconds = `0${seconds}`;
            }
        } else {
            if (Math.abs(minutes) < 10) {
                minutes = `0${Math.abs(minutes + 1)}`;
            }
            if (Math.abs(seconds) < 10) {
                seconds = `0${Math.abs(seconds)}`;
            } else {
                seconds = `${Math.abs(seconds)}`;
            }
        }

        return `${minutes}:${seconds}`;
    }

    const setRemainingPathColor = (timeLeft: number) => {
        const { alert, warning, info } = COLOR_CODES;
        if (timeLeft <= alert.threshold) {
            document
                .getElementById("base-timer-path-remaining")!
                .classList.remove(warning.color);
            document
                .getElementById("base-timer-path-remaining")!
                .classList.add(alert.color);
        } else if (timeLeft <= warning.threshold) {
            document
                .getElementById("base-timer-path-remaining")!
                .classList.remove(info.color);
            document
                .getElementById("base-timer-path-remaining")!
                .classList.add(warning.color);
        }
    }

    const calculateTimeFraction = () => {
        const rawTimeFraction = timeLeft / TIME_LIMIT;
        return rawTimeFraction - (1 / TIME_LIMIT) * (1 - rawTimeFraction);
    }

    const color = (): string => {
        if (timeLeft > 60) {
            return Colors.primary;
        } else if (timeLeft > 0) {
            return "#FB6320";
        } else {
            return Colors.error;
        }
    }

    return (
        <BaseTimer>
            <BaseTimerSvg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                <BaseTimerCircle>
                    <BaseTimerElapsed cx="50" cy="50" r="45"></BaseTimerElapsed>
                    <BaseTimerRemaining
                        color={color()}
                        id="base-timer-path-remaining"
                        stroke-dasharray="283"
                        d="
                            M 50, 50
                            m -45, 0
                            a 45,45 0 1,0 90,0
                            a 45,45 0 1,0 -90,0
                            "
                    ></BaseTimerRemaining>
                </BaseTimerCircle>
            </BaseTimerSvg>
            <TimerLabel id="base-timer-label">
                {`${formatTime(timeLeft)}`}
            </TimerLabel>
        </BaseTimer>
    )

}

export default CountDown;
