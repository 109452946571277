/**
 * Types
 */
export enum Types {
  //GET METAS
  REQUEST_CYCLES_LOAD_PAINEL = "cyclePainel/REQUEST_CYCLES_LOAD_PAINEL",
  SUCCESS_CYCLES_LOAD_PAINEL = "cyclePainel/SUCCESS_CYCLES_LOAD_PAINEL",
  FAILURE_CYCLES_LOAD_PAINEL = "cyclePainel/FAILURE_CYCLES_LOAD_PAINEL",
  //GET METAS
  REQUEST_CYCLES_META_LOAD_BY_ID = "cyclePainel/REQUEST_CYCLES_META_LOAD_BY_ID",
  SUCCESS_CYCLES_META_LOAD_BY_ID = "cyclePainel/SUCCESS_CYCLES_META_LOAD_BY_ID",
  FAILURE_CYCLES_META_LOAD_BY_ID = "cyclePainel/FAILURE_CYCLES_META_LOAD_BY_ID",
  //GET METAS BY OBJETIVO
  REQUEST_CYCLES_META_LOAD_BY_ID_OBJECTIVE = "cyclePainel/REQUEST_CYCLES_META_LOAD_BY_ID_OBJECTIVE",
  SUCCESS_CYCLES_META_LOAD_BY_ID_OBJECTIVE = "cyclePainel/SUCCESS_CYCLES_META_LOAD_BY_ID_OBJECTIVE",
  FAILURE_CYCLES_META_LOAD_BY_ID_OBJECTIVE = "cyclePainel/FAILURE_CYCLES_META_LOAD_BY_ID_OBJECTIVE",
  //ADD METAS
  REQUEST_CYCLES_META_ADD_BY_ID = "cyclePainel/REQUEST_CYCLES_META_ADD_BY_ID",
  SUCCESS_CYCLES_META_ADD_BY_ID = "cyclePainel/SUCCESS_CYCLES_META_ADD_BY_ID",
  FAILURE_CYCLES_META_ADD_BY_ID = "cyclePainel/FAILURE_CYCLES_META_ADD_BY_ID",
  //EDIT METAS
  REQUEST_CYCLES_META_EDIT_BY_ID = "cyclePainel/REQUEST_CYCLES_META_EDIT_BY_ID",
  SUCCESS_CYCLES_META_EDIT_BY_ID = "cyclePainel/SUCCESS_CYCLES_META_EDIT_BY_ID",
  FAILURE_CYCLES_META_EDIT_BY_ID = "cyclePainel/FAILURE_CYCLES_META_EDIT_BY_ID",
  //DELETE METAS
  REQUEST_CYCLES_META_DELETE = "cyclePainel/REQUEST_CYCLES_META_DELETE",
  SUCCESS_CYCLES_META_DELETE = "cyclePainel/SUCCESS_CYCLES_META_DELETE",
  FAILURE_CYCLES_META_DELETE = "cyclePainel/FAILURE_CYCLES_META_DELETE",
  //GET PESO BY ID
  REQUEST_CYCLES_LOAD_PESO_BY_ID = "cyclePainel/REQUEST_CYCLES_LOAD_PESO_BY_ID",
  SUCCESS_CYCLES_LOAD_PESO_BY_ID = "cyclePainel/SUCCESS_CYCLES_LOAD_PESO_BY_ID",
  FAILURE_CYCLES_LOAD_PESO_BY_ID = "cyclePainel/FAILURE_CYCLES_LOAD_PESO_BY_ID",
  //ADD PESO
  REQUEST_CYCLES_ADD_PESO = "cyclePainel/REQUEST_CYCLES_ADD_PESO",
  SUCCESS_CYCLES_ADD_PESO = "cyclePainel/SUCCESS_CYCLES_ADD_PESO",
  FAILURE_CYCLES_ADD_PESO = "cyclePainel/FAILURE_CYCLES_ADD_PESO",
  //EDIT PESO
  REQUEST_CYCLES_EDIT_PESO = "cyclePainel/REQUEST_CYCLES_EDIT_PESO",
  SUCCESS_CYCLES_EDIT_PESO = "cyclePainel/SUCCESS_CYCLES_EDIT_PESO",
  FAILURE_CYCLES_EDIT_PESO = "cyclePainel/FAILURE_CYCLES_EDIT_PESO",
  //DELETE PESO
  REQUEST_CYCLES_DELETE_PESO = "cyclePainel/REQUEST_CYCLES_DELETE_PESO",
  SUCCESS_CYCLES_DELETE_PESO = "cyclePainel/SUCCESS_CYCLES_DELETE_PESO",
  FAILURE_CYCLES_DELETE_PESO = "cyclePainel/FAILURE_CYCLES_DELETE_PESO",
  //DELETE PESO
  REQUEST_CYCLES_LOAD_PESO_BY_AREA = "cyclePainel/REQUEST_CYCLES_LOAD_PESO_BY_AREA",
  SUCCESS_CYCLES_LOAD_PESO_BY_AREA = "cyclePainel/SUCCESS_CYCLES_LOAD_PESO_BY_AREA",
  FAILURE_CYCLES_LOAD_PESO_BY_AREA = "cyclePainel/FAILURE_CYCLES_LOAD_PESO_BY_AREA",
}

export type MetaType = {
  idCicloMeta: number | null;
  minimo: string;
  target: string;
  maximo: string;
  habilitarReferencia: boolean;
  minimoRef: string;
  targetRef: string;
  maximoRef: string;
  forcarMetaBonus: boolean;
  metaBonus: string | null;
  objetivo: {
    descObjetivo: string;
    mecanicaCalculo: string;
    unidade: string;
  };
  resultado: string | null;
  ating: string | null;
  flagInativo: boolean | null;
  avaliacao: string | null;
  ciclo?: {
    ano: number;
  };
  meses: MetaMonthType[];
};

export type MetaMonthType = {
  idCicloMetaMes: number | null;
  idCicloMeta: number | null;
  referencia: string;
  minimo: string | number | null;
  target: string | number | null;
  maximo: string | number | null;
  resultado: string | number | null;
  avaliacao: string | null;
  mes: number;
};

export type SubPesoType = {
  idArea: number | null;
  apelido?: string;
  peso: {
    peso: number | string;
    idCicloPeso: number | null;
    definirResponsabilidade: boolean;
    flagInativo: boolean;
  };
};

export type PesoType = {
  idCicloPeso: number | null;
  idCicloPesoPai?: number | null;
  peso: number | string;
  idObjetivo: number | null;
  idArea: number | null;
  definirResponsabilidade: boolean;

  flagInativo: boolean;
  objetivo: {
    descObjetivo: string;
    mecanicaCalculo: string;
    unidade?: string;
  };
  area: {
    nomeLocal: string;
  };
};

export type ObjectiveCycleType = {
  idCicloObjetivo: number | null;
  idObjetivo: number;
  objetivo: {
    descObjetivo: string;
    unidade: string;
    casasDecimais: number | null;
  };
  meta: MetaType | null;
  pesos: SubPesoType[] | null;
  objetivos: ObjectiveCycleType[];
};

type HeaderType = {
  idArea: number;
  apelido: string | null;
  nomeLocal: string;
  // areas?: HeaderType[];
};

export type PainelType = {
  header: HeaderType[];
  body: PillarsPainelType[];
};

export type PillarsPainelType = {
  idCiclo: number;
  idPilar: number;
  idCicloPilar: number;
  pilar: {
    nomePilar: string;
    corPilar: string;
  };
  flagGeral: boolean;
  objetivos: ObjectiveCycleType[];
};

export type CyclePainel = {
  painel: PainelType;
  metaSelected: MetaType | null;
  pesoSelected: PesoType | null;
};

interface ICyclePainelState {
  readonly data: CyclePainel;
  readonly loading: boolean;
  readonly loadingPainel: boolean;
  readonly loadingMeta: boolean;
  readonly loadingPeso: boolean;
  readonly loadingPesoAction: boolean;
  readonly error: boolean;
  readonly successAction: boolean;
}

export type DataTypes = ICyclePainelState;

/**
 * Actions
 */

//GET PAINEL
export interface IRequestCyclesGetPainelAction {
  type: typeof Types.REQUEST_CYCLES_LOAD_PAINEL;
  payload: {
    idCiclo: number;
    nivel: string;
  };
}

export interface ISuccessCyclesGetPainelAction {
  type: typeof Types.SUCCESS_CYCLES_LOAD_PAINEL;
  payload: PainelType;
}

interface IFailureCyclesGetPainelAction {
  type: typeof Types.FAILURE_CYCLES_LOAD_PAINEL;
  message: string;
}

type CyclesGetPainelAction =
  | IRequestCyclesGetPainelAction
  | ISuccessCyclesGetPainelAction
  | IFailureCyclesGetPainelAction;

//#region METAS

//GET META BY ID
export interface IRequestCyclesGetMetaIDAction {
  type: typeof Types.REQUEST_CYCLES_META_LOAD_BY_ID;
  payload: {
    idCiclo: number;
    idCicloMeta: number;
  };
}

export interface ISuccessCyclesGetMetaIDAction {
  type: typeof Types.SUCCESS_CYCLES_META_LOAD_BY_ID;
  payload: MetaType;
}

interface IFailureCyclesGetMetaIDAction {
  type: typeof Types.FAILURE_CYCLES_META_LOAD_BY_ID;
  message: string;
}

type CyclesGetMetaIDAction =
  | IRequestCyclesGetMetaIDAction
  | ISuccessCyclesGetMetaIDAction
  | IFailureCyclesGetMetaIDAction;

//GET META BY ID
export interface IRequestCyclesGetMetaIdObjectiveAction {
  type: typeof Types.REQUEST_CYCLES_META_LOAD_BY_ID_OBJECTIVE;
  payload: {
    idCiclo: number;
    idObjective: number;
  };
}

export interface ISuccessCyclesGetMetaIdObjectiveAction {
  type: typeof Types.SUCCESS_CYCLES_META_LOAD_BY_ID_OBJECTIVE;
  payload: MetaType;
}

interface IFailureCyclesGetMetaIdObjectiveAction {
  type: typeof Types.FAILURE_CYCLES_META_LOAD_BY_ID_OBJECTIVE;
  message: string;
}

type CyclesGetMetaIdObjectiveAction =
  | IRequestCyclesGetMetaIdObjectiveAction
  | ISuccessCyclesGetMetaIdObjectiveAction
  | IFailureCyclesGetMetaIdObjectiveAction;

//ADD META BY ID
export interface IRequestCyclesAddMetaIDAction {
  type: typeof Types.REQUEST_CYCLES_META_ADD_BY_ID;
  payload: {
    idCiclo: number;
    idObjetivo: number;
    idCicloPilar: number;
    meta: MetaType;
  };
}

export interface ISuccessCyclesAddMetaIDAction {
  type: typeof Types.SUCCESS_CYCLES_META_ADD_BY_ID;
  idObjetivo: number;
  idCicloPilar: number;
  meta: MetaType;
}

interface IFailureCyclesAddMetaIDAction {
  type: typeof Types.FAILURE_CYCLES_META_ADD_BY_ID;
  message: string;
}

type CyclesAddMetaIDAction =
  | IRequestCyclesAddMetaIDAction
  | ISuccessCyclesAddMetaIDAction
  | IFailureCyclesAddMetaIDAction;

//EDIT META BY ID
export interface IRequestCyclesEditMetaIDAction {
  type: typeof Types.REQUEST_CYCLES_META_EDIT_BY_ID;
  payload: {
    idCiclo: number;
    idObjetivo: number;
    idCicloPilar: number;
    idCicloMeta: number;
    meta: MetaType;
  };
}

export interface ISuccessCyclesEditMetaIDAction {
  type: typeof Types.SUCCESS_CYCLES_META_EDIT_BY_ID;
  idObjetivo: number;
  idCicloPilar: number;
  meta: MetaType;
}

interface IFailureCyclesEditMetaIDAction {
  type: typeof Types.FAILURE_CYCLES_META_EDIT_BY_ID;
  message: string;
}

type CyclesEditMetaIDAction =
  | IRequestCyclesEditMetaIDAction
  | ISuccessCyclesEditMetaIDAction
  | IFailureCyclesEditMetaIDAction;

//DELETE META
export interface IRequestCyclesDeleteMetaAction {
  type: typeof Types.REQUEST_CYCLES_META_DELETE;
  payload: {
    idCiclo: number;
    idCicloMeta: number;
    idCicloPilar: number;
    idObjetivo: number;
  };
}

export interface ISuccessCyclesDeleteMetaAction {
  type: typeof Types.SUCCESS_CYCLES_META_DELETE;
  idCicloPilar: number;
  idObjetivo: number;
}

interface IFailureCyclesDeleteMetaAction {
  type: typeof Types.FAILURE_CYCLES_META_DELETE;
  message: string;
}

type CyclesDeleteMetaAction =
  | IRequestCyclesDeleteMetaAction
  | ISuccessCyclesDeleteMetaAction
  | IFailureCyclesDeleteMetaAction;

//#endregion
//#region PESO

//GET PESO BY ID
export interface IRequestCyclesLoadPesoByIdAction {
  type: typeof Types.REQUEST_CYCLES_LOAD_PESO_BY_ID;
  payload: {
    idCiclo: number;
    idCicloPeso: number;
  };
}

export interface ISuccessCyclesLoadPesoByIdAction {
  type: typeof Types.SUCCESS_CYCLES_LOAD_PESO_BY_ID;
  payload: PesoType;
}

interface IFailureCyclesLoadPesoByIdAction {
  type: typeof Types.FAILURE_CYCLES_LOAD_PESO_BY_ID;
  message: string;
}

type CyclesLoadPesoByIdAction =
  | IRequestCyclesLoadPesoByIdAction
  | ISuccessCyclesLoadPesoByIdAction
  | IFailureCyclesLoadPesoByIdAction;

//ADD PESO
export interface IRequestCyclesAddPesoAction {
  type: typeof Types.REQUEST_CYCLES_ADD_PESO;
  payload: {
    idCiclo: number;
    idCicloPilar: number;
    idObjetivo: number;
    peso: PesoType;
  };
}

export interface ISuccessCyclesAddPesoAction {
  type: typeof Types.SUCCESS_CYCLES_ADD_PESO;
  payload: {
    peso: SubPesoType;
    idCicloPilar: number;
    idObjetivo: number;
  };
}

interface IFailureCyclesAddPesoAction {
  type: typeof Types.FAILURE_CYCLES_ADD_PESO;
  message: string;
}

type CyclesAddPesoAction =
  | IRequestCyclesAddPesoAction
  | ISuccessCyclesAddPesoAction
  | IFailureCyclesAddPesoAction;

//EDIT PESO
export interface IRequestCyclesEditPesoAction {
  type: typeof Types.REQUEST_CYCLES_EDIT_PESO;
  payload: {
    idCiclo: number;
    idCicloPilar: number;
    idCicloPeso: number;
    idObjetivo: number;
    peso: PesoType;
  };
}

export interface ISuccessCyclesEditPesoAction {
  type: typeof Types.SUCCESS_CYCLES_EDIT_PESO;
  payload: {
    peso: SubPesoType;
    idCicloPilar: number;
    idObjetivo: number;
  };
}

interface IFailureCyclesEditPesoAction {
  type: typeof Types.FAILURE_CYCLES_EDIT_PESO;
  message: string;
}

type CyclesEditPesoAction =
  | IRequestCyclesEditPesoAction
  | ISuccessCyclesEditPesoAction
  | IFailureCyclesEditPesoAction;

//DELETE PESO
export interface IRequestCyclesDeletePesoAction {
  type: typeof Types.REQUEST_CYCLES_DELETE_PESO;
  payload: {
    idCiclo: number;
    idCicloPeso: number;
    idCicloPilar: number;
    idObjetivo: number;
  };
}

export interface ISuccessCyclesDeletePesoAction {
  type: typeof Types.SUCCESS_CYCLES_DELETE_PESO;
  payload: {
    idCicloPilar: number;
    idObjetivo: number;
    idCicloPeso: number;
  };
}

interface IFailureCyclesDeletePesoAction {
  type: typeof Types.FAILURE_CYCLES_DELETE_PESO;
  message: string;
}

type CyclesDeletePesoAction =
  | IRequestCyclesDeletePesoAction
  | ISuccessCyclesDeletePesoAction
  | IFailureCyclesDeletePesoAction;

//GET PESO BY AREA
export interface IRequestCyclesLoadPesoByAreaAction {
  type: typeof Types.REQUEST_CYCLES_LOAD_PESO_BY_AREA;
  payload: {
    idCiclo: number;
    idObjetivo: number;
    idArea: number;
  };
}

export interface ISuccessCyclesLoadPesoByAreaAction {
  type: typeof Types.SUCCESS_CYCLES_LOAD_PESO_BY_AREA;
  payload: SubPesoType;
}

interface IFailureCyclesLoadPesoByAreaAction {
  type: typeof Types.FAILURE_CYCLES_LOAD_PESO_BY_AREA;
  message: string;
}

type CyclesLoadPesoByAreaAction =
  | IRequestCyclesLoadPesoByAreaAction
  | ISuccessCyclesLoadPesoByAreaAction
  | IFailureCyclesLoadPesoByAreaAction;

export type CyclePainelActionTypes =
  | CyclesGetPainelAction
  | CyclesGetMetaIDAction
  | CyclesGetMetaIdObjectiveAction
  | CyclesAddMetaIDAction
  | CyclesEditMetaIDAction
  | CyclesDeleteMetaAction
  | CyclesAddPesoAction
  | CyclesLoadPesoByIdAction
  | CyclesEditPesoAction
  | CyclesDeletePesoAction
  | CyclesLoadPesoByAreaAction;
