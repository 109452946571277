import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { Formik } from 'formik';
import * as yup from 'yup';

import {
  DetailsListLayoutMode,
  SelectionMode,
  Selection,
  IColumn,
} from 'office-ui-fabric-react/lib/DetailsList';
import { ShimmeredDetailsList } from 'office-ui-fabric-react/lib/ShimmeredDetailsList';
import { CommandBarButton, DefaultButton, ICommandBarStyles, IDialogContentProps } from 'office-ui-fabric-react';

import NoItems from '~/components/layout/NoItems';
import { Stack } from 'office-ui-fabric-react';
import { RootState } from "~/store/ducks";
import Panel from '~/components/layout/Panel';
import { DeleteButton, PrimaryButton } from '~/components/Buttons';
import { InputAutocomplete, InputDate } from '~/components/Forms';
import CustomDialog from '~/components/CustomDialogFluentUI';

import { Creators as loadEmployeeUnidades } from '~/store/ducks/employees';
import { Creators as addEmployeeUnidade } from '~/store/ducks/employees';
import { Creators as delEmployeeUnidade } from '~/store/ducks/employees';
import { EmployeeUnidadeType, EmployeeSelected } from '~/store/ducks/employees/types';

import { DataTypes as DataTypesUnit, IUnit } from '~/store/ducks/admin/units/types'
import { Creators as getUnits } from '~/store/ducks/admin/units'

interface IEmployeeAreasState {
  columns: IColumn[];
  items: EmployeeUnidadeType[];
  selectionDetails: string;
  selectionCount: number;
  isPanelOpen: boolean;
  isDialogExcludeOpen: boolean;
  unidadeSelected: IUnit | null;
}

interface IEmployeeUnidadeProps {
  employee: EmployeeSelected;
  idFuncionario: number;
  unitState: DataTypesUnit;
  loadEmployeeUnidades: (idFuncionario: number) => void;
  addEmployeeUnidade: (idFuncionario: number, unidade: EmployeeUnidadeType) => void;
  delEmployeeUnidade: (idFuncionario: number, idHistUnidade: number) => void;
  getUnits: (search?: string, filter?: boolean | null) => void;
}

class EmployeeUnidades extends Component<IEmployeeUnidadeProps, IEmployeeAreasState> {
  private _selection: Selection;
  private formRef: any;
  private timeout: number

  constructor(props: IEmployeeUnidadeProps) {
    super(props)

    const columns: IColumn[] = [
      {
        key: 'column1',
        name: 'Data',
        fieldName: 'dataHist',
        minWidth: 75,
        maxWidth: 75,
        isRowHeader: true,
        isResizable: true,
        // isSortedDescending: false,
        // sortAscendingAriaLabel: 'Sorted A to Z',
        // sortDescendingAriaLabel: 'Sorted Z to A',
        // onColumnClick: this._onColumnClick,
        data: 'data',
        isPadded: true,
        onRender: (item: EmployeeUnidadeType) => moment(item.dataHist).format('DD/MM/YYYY')
      },
      {
        key: 'column2',
        name: 'ID',
        ariaLabel: 'Código id hist área',
        fieldName: 'idUnidade',
        isRowHeader: true,
        minWidth: 50,
        maxWidth: 50,
        isResizable: true,
        // isSortedDescending: false,
        // onColumnClick: this._onColumnClick
      },
      {
        key: 'column3',
        name: 'Unidade',
        fieldName: 'unidade',
        minWidth: 210,
        isRowHeader: true,
        isResizable: true,
        // isSortedDescending: false,
        // sortAscendingAriaLabel: 'Sorted A to Z',
        // sortDescendingAriaLabel: 'Sorted Z to A',
        // onColumnClick: this._onColumnClick,
        isPadded: true,
        onRender: (item: EmployeeUnidadeType) => item.unidade.nomeUnidade
      },
    ];

    this.state = {
      columns,
      items: [],
      selectionCount: 0,
      selectionDetails: '',
      isPanelOpen: false,
      isDialogExcludeOpen: false,
      unidadeSelected: null
    }

    this._selection = new Selection({
      onSelectionChanged: () => {
        this.setState({
          selectionDetails: this._getSelectionDetails(),
        });
      },
    });

    this.formRef = React.createRef();
    this.timeout = 0;
  }

  componentDidMount() {
    const { loadEmployeeUnidades, idFuncionario, employee } = this.props;

    if (employee.unidades?.length === 0 || employee.unidades === null) {
      loadEmployeeUnidades(idFuncionario);
    }
  }

  componentDidUpdate(prevProps: IEmployeeUnidadeProps) {
    if (prevProps.employee.success !== this.props.employee.success) {
      if (this.props.employee.success) {
        this.setState({ isPanelOpen: false })
      }
    }
  }

  _getSelectionDetails(): any {
    const selectionCount = this._selection.getSelectedCount();
    this.setState({ selectionCount });
    this._selection.getSelection();
  };

  excludeUnidade = () => {
    const { idFuncionario } = this.props;
    const idHistUnidade = (this._selection.getSelection()[0] as EmployeeUnidadeType).idHistUnidade!
    this.props.delEmployeeUnidade(idFuncionario, idHistUnidade);
    this.setState({ isDialogExcludeOpen: false });
    this._selection.setAllSelected(false);
  }

  cancelPanel = () => {
    this._selection.setAllSelected(false);
    this.setState({ isPanelOpen: false });
  };

  handleSubmit = () => {
    if (this.formRef.current) {
      this.formRef.current.handleSubmit();
    };
  };

  search = (text: string) => {
    if (text.trim()) {
      clearTimeout(this.timeout);
      this.timeout = window.setTimeout(() => {
        this.props.getUnits(text, true)
      }, 700);
    };
  }

  render() {
    const { columns, isPanelOpen, isDialogExcludeOpen, unidadeSelected } = this.state;
    const { employee, loadEmployeeUnidades, idFuncionario, unitState, addEmployeeUnidade } = this.props;
    const { unidades, loadingItems } = employee;
    return (
      <>
        <Stack horizontal horizontalAlign="space-between" styles={{ root: { width: '100%' } }}>
          <Stack horizontal verticalAlign="center">
            <CommandBarButton styles={btnStyle} iconProps={{ iconName: 'Add' }} text="Adicionar Unidade" onClick={() => this.setState({ isPanelOpen: true })} />
            {
              this._selection.getSelectedCount() > 0 &&
              <CommandBarButton styles={btnStyle} iconProps={{ iconName: 'Delete' }} text="Excluir" onClick={() => this.setState({ isDialogExcludeOpen: true })} />
            }
          </Stack>
          <Stack>
            <CommandBarButton styles={btnStyle} iconProps={{ iconName: 'Refresh' }} text="Atualizar" onClick={() => loadEmployeeUnidades(idFuncionario)} />
          </Stack>
        </Stack>
        {
          unidades?.length === 0 ?
            <NoItems
              text="Não há histórico de unidades"
              img="/static/icons/supermarket.svg"
            />
            :
            <ShimmeredDetailsList
              items={unidades ?? []}
              enableShimmer={loadingItems}
              columns={columns}
              selectionMode={SelectionMode.single}
              selection={this._selection}
              getKey={this._getKey}
              selectionPreservedOnEmptyClick={true}
              setKey="single"
              layoutMode={DetailsListLayoutMode.justified}
              isHeaderVisible={true}
              styles={{ root: { paddingTop: 0 } }}
            />
        }

        <Panel
          title={"Nova Unidade"}
          open={isPanelOpen}
          onClose={() => this.cancelPanel()}
          footer={
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <DefaultButton onClick={() => this.cancelPanel()}>Cancelar</DefaultButton>
              <PrimaryButton onClick={this.handleSubmit} isLoading={employee.loadingAction} text="Salvar" />
            </Stack>
          }
        >
          <Formik
            innerRef={this.formRef}
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnChange={false}
            validateOnBlur={true}
            enableReinitialize
            onSubmit={(values) => {
              values.unidade!.nomeUnidade = unidadeSelected?.nomeUnidade ?? '';
              addEmployeeUnidade(idFuncionario, values)
            }}
          >
            {({ handleSubmit, handleChange, values, errors, setFieldError, setFieldValue }) => (
              <form onSubmit={handleSubmit}>
                <InputDate
                  label="Data"
                  name='dataHist'
                  value={values.dataHist ? moment(values.dataHist) : null}
                  onChange={value => {
                    setFieldValue('dataHist', value)
                    setFieldError('dataHist', '')
                  }}
                  className="mt-2"
                  error={!!errors.dataHist}
                  helperText={errors.dataHist}
                  autoFocus
                />
                <InputAutocomplete
                  value={(unidadeSelected as IUnit)}
                  onChange={(_, newValue) => {
                    this.setState({ unidadeSelected: newValue })
                    setFieldValue("idUnidade", newValue?.idUnidade);
                  }}
                  onInputChange={(_, newInputValue) => {
                    setFieldError("idUnidade", "");
                    this.search(newInputValue);
                  }}
                  getOptionLabel={(unit: IUnit) => `${unit.idUnidade} - ${unit.nomeUnidade}`}
                  getOptionSelected={(option, value) => option.idUnidade === value.idUnidade}
                  options={unitState.data}
                  input={{
                    idInput: "unidade",
                    labelInput: "Unidade",
                    helperTextInput: errors.idUnidade,
                    errorInput: !!errors.idUnidade
                  }}
                />
              </form>
            )}
          </Formik>
        </Panel>

        <CustomDialog
          hidden={!isDialogExcludeOpen}
          onDismiss={() => this.setState({ isDialogExcludeOpen: false })}
          dialogContentProps={dialogContentProps}
        >
          <DefaultButton onClick={() => this.setState({ isDialogExcludeOpen: false })} text="Cancelar" />
          <DeleteButton onClick={() => this.excludeUnidade()} text="Excluir" />
        </CustomDialog>
      </>
    )
  }

  private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    const { columns } = this.state;
    const newColumns: IColumn[] = columns.slice();
    let items: EmployeeUnidadeType[] = this.props.employee.unidades ?? [];
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      };
    });
    const newItems = this._sort(items, currColumn.fieldName!, currColumn.isSortedDescending);
    this.setState({
      columns: newColumns,
      items: newItems,
    });
  };

  private _getKey(item: any, index?: number): any {
    if (item !== undefined)
      return item.key;
  };

  private _sort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
    const key = columnKey as keyof T;
    const itemsSorted = items.sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
    return itemsSorted;
  };
}

const btnStyle: Partial<ICommandBarStyles> = {
  root: {
    height: 44
  }
}

const initialValues: EmployeeUnidadeType = {
  idUnidade: null,
  dataHist: null,
  unidade: {
    nomeUnidade: ''
  }
}

const validationSchema = yup.object().shape({
  dataHist: yup.date().nullable().typeError('Data inválida').required("Campo obrigatório"),
  idUnidade: yup.number().nullable().required("Campo obrigatório")
});

const dialogContentProps: IDialogContentProps = {
  title: 'Excluir?',
  closeButtonAriaLabel: 'Close',
  subText: 'Tem certeza de que deseja excluir este item?'
};


const mapStateToProps = (state: RootState) => ({
  employee: state.employeesReducer.itemSelected,
  unitState: state.unitsReducer
});

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  ...loadEmployeeUnidades,
  ...addEmployeeUnidade,
  ...delEmployeeUnidade,
  ...getUnits
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeUnidades);
