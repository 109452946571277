import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import { useFormik } from "formik";
import * as yup from "yup";

import {
  FontIcon,
  Stack,
  Spinner,
  Label,
  MessageBarButton,
} from "office-ui-fabric-react";
import { Text, ITextStyles } from "office-ui-fabric-react/lib/Text";
import {
  ICommandBarStyles,
  MessageBar,
  MessageBarType,
} from "office-ui-fabric-react";

import MuiAccordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import { withStyles } from "@material-ui/core/styles";

import { CommandBarButton } from "~/components/Buttons";
import { InputCheckbox, InputText } from "~/components/Forms";
import CustomDialog from "~/components/CustomDialog";
import PainelHeader from "~/components/layout/PainelHeader";

import {
  Container,
  Content,
  WrapperResults,
  WrapperForm,
  Table,
  Tbody,
  TdResultAtitudes,
  Divider,
  StatusTeam,
} from "./styles";

import { Creators as CreatorsHome } from "~/store/ducks/home";
import { Creators as CreatorsTask } from "~/store/ducks/tasks";
import { DataTypes as DataTypesTask } from "~/store/ducks/tasks/types";
import NoItems from "~/components/layout/NoItems";

import Atitudes from "~/components/Avaliations/Attitudes";
import Performance from "~/components/Avaliations/Performance";
import { GroupItems, TitleField } from "~/components/FormGroup";
import { BreadcrumbItems, Page } from "~/store/ducks/home/types";
import { convertStatusTarefa } from "~/utils/tasks";
import Colors from "~/assets/js/colors";
import { RootState } from "~/store/ducks";
import { renderAvaliacao } from "~/pages/SelfEvaluationCollective/selfEvaluationCollectiveRender";

interface SelfEvaluationProps {
  taskState?: DataTypesTask;
}

const itemsBreadCrumb: BreadcrumbItems[] = [
  {
    text: "Home",
    isCurrentItem: false,
    icon: "HomeSolid",
    onlyIcon: true,
    url: "/",
  },
  { text: "Workflow", isCurrentItem: false, url: "/workflow" },
  { text: "Feedback", isCurrentItem: true },
];

const page: Page = {
  key: "feedback",
  pages: itemsBreadCrumb,
};

interface IAcceptSend {
  isAccepted: boolean;
  error: boolean;
}

interface Params {
  idTarefa: string;
  idTarefaFeedback?: string;
}

const Feedback: React.FC<SelfEvaluationProps> = (props) => {
  const { taskState } = props;
  const {
    selected: feedback,
    selectedColetivaFeedback,
    error,
    loadingData,
    loadingAction,
    redirectTo,
  } =
    taskState ??
    useSelector<RootState, DataTypesTask>((state) => state.tasksReducer);
  const { TarefaFeedback, tipoTarefa, statusTarefa } = feedback ?? {};
  // const { avaliacao } = TarefaFeedback ?? {};
  // const {
  //   selectedColetivaFeedback: avaliacao,
  //   error,
  //   loadingData,
  //   loadingAction,
  //   redirectTo,
  //   nextTask,
  // } = ;
  const avaliacao = TarefaFeedback?.avaliacao ?? selectedColetivaFeedback;

  const { calibracao } = avaliacao ?? {};
  const [isOpenDetails, setIsOpenDetails] = useState(false);
  const [showMsgPDI, setShowMsgPDI] = useState(false);

  const params = useParams<Params>();
  const history = useHistory();
  const dispatch = useDispatch();
  const [errorForm, setErrorForm] = useState(false);
  const [acceptSend, setAcceptSend] = useState<IAcceptSend>({
    isAccepted: false,
    error: false,
  });

  const isFB = tipoTarefa === "FB";
  const isFBColetiva = avaliacao?.tipoAvaliacao === "Avaliação coletiva";

  const {
    handleSubmit,
    setFieldValue,
    setFieldError,
    errors,
    values,
    resetForm,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: validation,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit(values) {
      if (isFB || isFBColetiva) {
        if (isFBColetiva) {
          dispatch(
            CreatorsTask.finishTarefaColetivaFeedback(
              Number(params.idTarefa)!,
              Number(params.idTarefaFeedback),
              values.feedbackGestor
            )
          );
        } else {
          dispatch(
            CreatorsTask.finishTask(Number(params.idTarefa)!, {
              feedbackGestor: values.feedbackGestor,
            })
          );
        }
      } else {
        dispatch(
          CreatorsTask.finishTask(Number(params.idTarefa)!, {
            feedback: values.feedback,
          })
        );
      }
    },
  });

  useEffect(() => {
    dispatch(CreatorsHome.setCurrentPage(page));
    if (!taskState && params?.idTarefaFeedback) {
      dispatch(
        CreatorsTask.getTarefaColetivaFeedback(
          Number(params.idTarefa),
          Number(params.idTarefaFeedback)
        )
      );
    }
  }, []);

  useEffect(() => {
    if (avaliacao?.tipoAvaliacao === "Avaliação coletiva") {
      dispatch(
        CreatorsHome.setCurrentPage({
          key: "feedback",
          pages: [
            {
              text: "Home",
              isCurrentItem: false,
              icon: "HomeSolid",
              onlyIcon: true,
              url: "/",
            },
            { text: "Workflow", isCurrentItem: false, url: "/workflow" },
            {
              text: "Feedback Coletivo",
              isCurrentItem: false,
              url: "/tarefas/" + params.idTarefa,
            },
            { text: "Feedback", isCurrentItem: true },
          ],
        })
      );
    }
    resetForm({
      values: {
        isFB: isFB || isFBColetiva,
        feedbackGestor: avaliacao?.feedbackGestor ?? "",
        feedback: avaliacao?.feedback ?? "",
      },
    });
  }, [dispatch, resetForm, avaliacao]);

  useEffect(() => {
    if (redirectTo) {
      if (isFB || isFBColetiva) {
        history.push(redirectTo);
      } else {
        // history.push('/workflow')
        setShowMsgPDI(true);
      }
    }
  }, [redirectTo]);

  const loadPDI = () => {
    history.push(`/tarefas/${taskState?.nextTask?.idTarefa}`);
    dispatch(CreatorsTask.getTasksById(taskState?.nextTask?.idTarefa!));
  };

  return (
    <Container>
      {
        <PainelHeader
          title={`Feedback ${avaliacao?.ciclo.ano ?? ""} de ${
            avaliacao?.funcionario.nomeCompleto ?? ""
          }`}
          icon={{ icon: "Comment" }}
          labelGroups={
            avaliacao
              ? [
                  {
                    label: "Funcionário",
                    value: avaliacao?.funcionario.nomeCompleto ?? "",
                    icon: "HomeGroup",
                    size: "full",
                  },
                  {
                    label: "Status",
                    value: isFBColetiva
                      ? avaliacao?.statusAvaliacao
                      : convertStatusTarefa(statusTarefa ?? ""),
                    icon: "Flag",
                    size: "full",
                  },
                ]
              : []
          }
        />
      }
      <Content>
        {loadingData ? (
          <Spinner
            label="Carregando avaliação"
            styles={{ root: { height: 500 } }}
          />
        ) : (
          <>
            {error ? (
              <NoItems text="Tarefa não encontrada" error={true} />
            ) : (
              <>
                {(avaliacao?.habilitaFeedbackGestor ||
                  avaliacao?.habilitaFeedback) &&
                !showMsgPDI ? (
                  <>
                    <CommandBarButton
                      iconProps={{ iconName: "Send" }}
                      isLoading={loadingAction}
                      onClick={() => {
                        if (isFB && !acceptSend.isAccepted) {
                          setAcceptSend({ error: true, isAccepted: false });
                        } else {
                          handleSubmit();
                        }
                      }}
                      styles={commandButtonStyle}
                    >
                      Enviar
                    </CommandBarButton>
                    {isFB && acceptSend.error && (
                      <MessageBar messageBarType={MessageBarType.error}>
                        É necessário aceitar termo de ciência antes de enviar o
                        feedback
                      </MessageBar>
                    )}
                  </>
                ) : (
                  <CommandBarButton
                    iconProps={{ iconName: "ChromeClose" }}
                    onClick={() => history.goBack()}
                    styles={commandButtonStyle}
                  >
                    Fechar
                  </CommandBarButton>
                )}
                {showMsgPDI && !isFB && (
                  <MessageBar
                    messageBarType={MessageBarType.success}
                    isMultiline={true}
                    actions={
                      <div>
                        <MessageBarButton
                          onClick={() => history.push("/workflow")}
                        >
                          Não
                        </MessageBarButton>
                        <MessageBarButton onClick={loadPDI}>
                          Sim
                        </MessageBarButton>
                      </div>
                    }
                  >
                    Sua tarefa de Plano de Desenvolvimento Individual foi gerada
                    para o ciclo 2021. Deseja iniciar seu PDI?
                  </MessageBar>
                )}
                {errorForm && (
                  <MessageBar
                    messageBarType={MessageBarType.error}
                    isMultiline={false}
                    dismissButtonAriaLabel="Close"
                    onDismiss={() => {
                      setErrorForm(false);
                    }}
                  >
                    Todos os campos são obrigatórios!
                  </MessageBar>
                )}

                <WrapperResults>
                  {isFB && (
                    <Atitudes
                      atitudes={avaliacao?.atitudes ?? []}
                      flagAutoAvaliacao={false}
                      habilitaAvaliacaoGestor={false}
                      tipoAvaliacao={avaliacao?.tipoAvaliacao ?? ""}
                      isLoading={loadingData ?? false}
                    />
                  )}
                  {isFBColetiva && (
                    <Accordion defaultExpanded={true}>
                      <AccordionSummary
                        expandIcon={
                          <FontIcon
                            iconName="ChevronDown"
                            style={{ fontSize: 16 }}
                          />
                        }
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Text variant="mediumPlus" styles={textStyles}>
                          Atitudes vencedoras
                        </Text>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Table style={{ width: "100%" }}>
                          <Tbody>
                            <tr>
                              <td style={{ width: 530, minWidth: 530 }} />
                              <TdResultAtitudes
                                style={{ width: 420, minWidth: 420 }}
                              >
                                <Stack
                                  horizontal
                                  verticalAlign="center"
                                  horizontalAlign="center"
                                >
                                  {renderAvaliacao(
                                    avaliacao?.resultadometas ?? ""
                                  )}
                                </Stack>
                              </TdResultAtitudes>
                            </tr>
                          </Tbody>
                        </Table>
                      </AccordionDetails>
                    </Accordion>
                  )}

                  {isFB && (
                    <Accordion defaultExpanded={true}>
                      <AccordionSummary
                        expandIcon={
                          <FontIcon
                            iconName="ChevronDown"
                            style={{ fontSize: 16 }}
                          />
                        }
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Text variant="mediumPlus" styles={textStyles}>
                          Carreira
                        </Text>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Stack styles={{ root: { width: "100%" } }}>
                          <WrapperForm>
                            <InputText
                              label="Aspirações de desenvolvimento da carreira"
                              multiline
                              rows={3}
                              value={avaliacao?.aspiracoesCarreira ?? ""}
                              disabled={true}
                            />
                          </WrapperForm>
                        </Stack>
                      </AccordionDetails>
                    </Accordion>
                  )}

                  {!isFBColetiva && (
                    <Accordion defaultExpanded={true}>
                      <AccordionSummary
                        expandIcon={
                          <FontIcon
                            iconName="ChevronDown"
                            style={{ fontSize: 16 }}
                          />
                        }
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Text variant="mediumPlus" styles={textStyles}>
                          Resultado da Autoavaliação
                        </Text>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Table style={{ width: "100%" }}>
                          <Tbody>
                            <tr>
                              <td style={{ width: 530, minWidth: 530 }} />
                              <TdResultAtitudes
                                style={{ width: 420, minWidth: 420 }}
                              >
                                <Stack
                                  horizontal
                                  verticalAlign="center"
                                  horizontalAlign="center"
                                >
                                  <Stack horizontal verticalAlign="center">
                                    <StatusTeam
                                      bgColor={
                                        avaliacao?.performance?.cor ?? ""
                                      }
                                    />
                                    <Text variant="medium">
                                      {avaliacao?.performance?.descNivel}
                                    </Text>
                                  </Stack>
                                </Stack>
                              </TdResultAtitudes>
                            </tr>
                          </Tbody>
                        </Table>
                      </AccordionDetails>
                    </Accordion>
                  )}

                  {(isFB || isFBColetiva) && (
                    <Accordion defaultExpanded={true}>
                      <AccordionSummary
                        expandIcon={
                          <FontIcon
                            iconName="ChevronDown"
                            style={{ fontSize: 16 }}
                          />
                        }
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Text variant="mediumPlus" styles={textStyles}>
                          Informações confidenciais da calibração
                        </Text>
                      </AccordionSummary>
                      <AccordionDetails>
                        <MessageBar
                          messageBarType={MessageBarType.severeWarning}
                          actions={
                            <div>
                              <MessageBarButton
                                styles={{ root: { height: 32 } }}
                                onClick={() => setIsOpenDetails(true)}
                              >
                                Mostrar detalhes
                              </MessageBarButton>
                            </div>
                          }
                        >
                          As informações deste bloco são sensíveis e
                          confidenciais. Em NENHUMA HIPÓTESE o funcionário
                          poderá ter acesso a elas, em especial as informações
                          de potencial e sucessão.
                        </MessageBar>
                      </AccordionDetails>
                    </Accordion>
                  )}

                  <Accordion defaultExpanded={true}>
                    <AccordionSummary
                      expandIcon={
                        <FontIcon
                          iconName="ChevronDown"
                          style={{ fontSize: 16 }}
                        />
                      }
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Text variant="mediumPlus" styles={textStyles}>
                        Avaliação Pós Calibração
                      </Text>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Table style={{ width: "100%" }}>
                        <Tbody>
                          <tr>
                            <td style={{ width: 530, minWidth: 530 }} />
                            <TdResultAtitudes
                              style={{ width: 420, minWidth: 420 }}
                            >
                              <Stack
                                horizontal
                                verticalAlign="center"
                                horizontalAlign="center"
                              >
                                <Stack horizontal verticalAlign="center">
                                  <StatusTeam
                                    bgColor={
                                      avaliacao?.calibracao.performance.cor ??
                                      ""
                                    }
                                  />
                                  <Text variant="medium">
                                    {
                                      avaliacao?.calibracao.performance
                                        .descNivel
                                    }
                                  </Text>
                                </Stack>
                              </Stack>
                            </TdResultAtitudes>
                          </tr>
                        </Tbody>
                      </Table>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion defaultExpanded={true}>
                    <AccordionSummary
                      expandIcon={
                        <FontIcon
                          iconName="ChevronDown"
                          style={{ fontSize: 16 }}
                        />
                      }
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Text variant="mediumPlus" styles={textStyles}>
                        Feedback do Gestor
                      </Text>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Stack styles={{ root: { width: "100%" } }}>
                        {(isFB || isFBColetiva) && (
                          <Label
                            styles={{ root: { fontSize: 11, marginTop: -5 } }}
                          >
                            Aqui você poderá destacar os principais pontos
                            abordados no feedback realizado após a calibração de
                            performance. Esse campo ficará visível para o
                            funcionário e por isso é importante sinalizar aqui
                            os pontos de destaque e também os de melhoria para
                            que ele tenha fácil acesso à essas referências para
                            a construção do PDI (Plano de desenvolvimento
                            Individual).
                          </Label>
                        )}
                        <WrapperForm>
                          <InputText
                            label={
                              isFB || isFBColetiva
                                ? "Entre com os comentários do feedback"
                                : ""
                            }
                            multiline
                            rows={3}
                            value={
                              isFB || isFBColetiva
                                ? values.feedbackGestor ?? ""
                                : avaliacao?.feedbackGestor ?? ""
                            }
                            onChange={(e) => {
                              setFieldValue("feedbackGestor", e.target.value);
                              setFieldError("feedbackGestor", "");
                            }}
                            // onBlur={e => dispatch(CreatorsAvaliation.saveComentariosMelhorar(e.target.value))}
                            error={errors.feedbackGestor ? true : false}
                            helperText={errors.feedbackGestor}
                            disabled={
                              !avaliacao?.habilitaFeedbackGestor &&
                              (!isFB || !isFBColetiva)
                            }
                          />
                        </WrapperForm>
                        {isFB && avaliacao?.habilitaFeedbackGestor && (
                          <InputCheckbox
                            checked={acceptSend.isAccepted}
                            onChange={(e) =>
                              setAcceptSend({
                                isAccepted: e.target.checked,
                                error: false,
                              })
                            }
                            styles={{
                              fontSize: 14,
                              marginTop: 10,
                              color: acceptSend.error
                                ? Colors.error
                                : "inherit",
                            }}
                            size="small"
                            label="Estou ciente que o funcionário terá acesso ao feedback inserido acima."
                          />
                        )}
                      </Stack>
                    </AccordionDetails>
                  </Accordion>
                  {!isFB && !avaliacao?.isGestor && (
                    <Accordion defaultExpanded={true}>
                      <AccordionSummary
                        expandIcon={
                          <FontIcon
                            iconName="ChevronDown"
                            style={{ fontSize: 16 }}
                          />
                        }
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Text variant="mediumPlus" styles={textStyles}>
                          Comentários sobre o Feedback do Gestor
                        </Text>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Stack styles={{ root: { width: "100%" } }}>
                          {!isFB && (
                            <Label
                              styles={{ root: { fontSize: 11, marginTop: -5 } }}
                            >
                              Seu feedback tem como principal objetivo auxiliar
                              seu desenvolvimento, considerando sua a
                              performance e o alinhamento com as nossas Atitudes
                              Vencedoras. Durante o feedback, também é
                              sinalizado correções de rota importantes para que
                              não só os objetivos do negócio sejam atingidos
                              como também seus objetivos de carreira no Grupo
                              Marche. Após o feedback do seu gestor, registre
                              aqui suas percepções sobre a objetividade do
                              feedback, sobre o alinhamento de expectativas e
                              principalmente sua percepção sobre a qualidade
                              dessa conversa e do quanto ela contribuirá com sua
                              evolução e desenvolvimento.
                            </Label>
                          )}
                          <WrapperForm>
                            <InputText
                              label="Entre com os comentários sobre o feedback do gestor"
                              multiline
                              rows={3}
                              value={values.feedback ?? ""}
                              onChange={(e) => {
                                setFieldValue("feedback", e.target.value);
                                setFieldError("feedback", "");
                              }}
                              // onBlur={e => dispatch(CreatorsAvaliation.saveComentariosMelhorar(e.target.value))}
                              error={errors.feedback ? true : false}
                              helperText={errors.feedback}
                              disabled={
                                (!avaliacao?.habilitaFeedback && !isFB) ||
                                showMsgPDI
                              }
                            />
                          </WrapperForm>
                        </Stack>
                      </AccordionDetails>
                    </Accordion>
                  )}
                  {(avaliacao?.habilitaFeedbackGestor ||
                    avaliacao?.habilitaFeedback) &&
                    !showMsgPDI && (
                      <>
                        <Divider />
                        <CommandBarButton
                          iconProps={{ iconName: "Send" }}
                          onClick={() => {
                            if (isFB && !acceptSend.isAccepted) {
                              setAcceptSend({ error: true, isAccepted: false });
                            } else {
                              handleSubmit();
                            }
                          }}
                          isLoading={loadingAction}
                          styles={commandButtonStyle}
                        >
                          Enviar
                        </CommandBarButton>
                        {isFB && acceptSend.error && (
                          <MessageBar messageBarType={MessageBarType.error}>
                            É necessário aceitar termo de ciência antes de
                            enviar o feedback
                          </MessageBar>
                        )}
                      </>
                    )}
                  {errorForm && (
                    <MessageBar
                      messageBarType={MessageBarType.error}
                      isMultiline={false}
                      dismissButtonAriaLabel="Close"
                      onDismiss={() => {
                        setErrorForm(false);
                      }}
                    >
                      Todos os campos são obrigatórios!
                    </MessageBar>
                  )}
                </WrapperResults>

                {(isFB || isFBColetiva) && (
                  <CustomDialog
                    open={isOpenDetails}
                    title={"Detalhes da Calibração"}
                    btnConfirmText="Fechar"
                    onClickConfirm={() => setIsOpenDetails(false)}
                    width={1120}
                    height={720}
                  >
                    <GroupItems title="Funcionário">
                      <TitleField
                        text={avaliacao?.funcionario.nomeCompleto ?? ""}
                        bold={true}
                      />
                    </GroupItems>
                    <Performance
                      potencial={avaliacao?.potencial ?? ""}
                      sucessao={avaliacao?.sucessao ?? null}
                      tabPerformance={avaliacao?.tabPerformance ?? []}
                      idTabPerformance={
                        avaliacao?.idTabPerformanceGestor ?? null
                      }
                      readonly={true}
                    />
                    <Performance
                      titleAccordion="Avaliação Calibrada"
                      potencial={calibracao?.potencial ?? null}
                      sucessao={calibracao?.sucessao ?? null}
                      tabPerformance={avaliacao?.tabPerformance ?? []}
                      idTabPerformance={calibracao?.idTabPerformance ?? null}
                      readonly={true}
                    />
                    <Accordion defaultExpanded={true}>
                      <AccordionSummary
                        expandIcon={
                          <FontIcon
                            iconName="ChevronDown"
                            style={{ fontSize: 16 }}
                          />
                        }
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Text variant="mediumPlus" styles={textStyles}>
                          Comentários
                        </Text>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Stack>
                          {calibracao?.comentarios?.map((comentario, i) => (
                            <GroupItems
                              key={i}
                              title={moment(comentario.dataHoraAnotacao).format(
                                "DD/MM/YYYY HH:mm"
                              )}
                              titleRegular
                              stylesTitle={{ color: "#00000050", fontSize: 12 }}
                            >
                              <TitleField text={comentario.comentarios} />
                            </GroupItems>
                          ))}
                        </Stack>
                      </AccordionDetails>
                    </Accordion>
                  </CustomDialog>
                )}
              </>
            )}
          </>
        )}
      </Content>
    </Container>
  );
};

const Accordion = withStyles({
  root: {
    padding: "0px !important",
    paddingBottom: 10,
    boxShadow: "none",
    display: "contents",

    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {
    borderBottom: 0,
  },
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    borderBottom: "1px solid #CFD3DA",
    height: 50,
    minHeight: "50px !important",
    "&:hover": {
      backgroundColor: "#F3F2F1",
    },
    expanded: {},
  },
})(MuiAccordionSummary);

const textStyles: ITextStyles = {
  root: {
    fontWeight: 500,
    display: "block",
  },
};

const validation = yup.object({
  isFB: yup.bool().notRequired(),
  feedbackGestor: yup.string().when("isFB", {
    is: true,
    then: yup.string().required("Campo obrigatório"),
  }),
  feedback: yup.string().when("isFB", {
    is: false,
    then: yup.string().required("Campo obrigatório"),
  }),
});

const initialValues = {
  isFB: false,
  feedbackGestor: "",
  feedback: "",
};

const commandButtonStyle: Partial<ICommandBarStyles> = {
  root: {
    height: 44,
  },
};

export default Feedback;
