import styled from "styled-components";

export const Title = styled.p`
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
`;

export const Value = styled.p`
  font-size: 40px;
  font-weight: bold;
`;

export const Percent = styled.p`
  font-size: 14px;
  font-weight: 200;
`;

export const Box = styled.div<{ color: string }>`
  min-width: 100px;
  width: 136px;
  height: 90px;
  max-height: 90px;
  border-radius: 4px;
  border: 1px solid ${({ color }) => color};
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 4px;

  ${Title} {
    color: ${({ color }) => color};
  }

  ${Value} {
    color: #484644;
  }
`;
