import React from 'react';
import colors from '~/assets/js/colors';

import { Text } from 'office-ui-fabric-react/lib/Text';
import { FontIcon } from 'office-ui-fabric-react/lib/Icon';

import { ContainerNoItems } from './styles';

interface IProps {
    error?: boolean;
    text: string;
    icon?: string;
    img?: string;
    alt?: string;
    styles?: React.CSSProperties;
}

const NoItems: React.FC<IProps> = (props) => {
    const hasImg: boolean = props.img !== undefined;
    const hasIcon: boolean = props.icon !== undefined;

    return (
        <>
            {!props.error ?
                <ContainerNoItems style={props.styles}>
                    {hasImg &&
                        <img src={props.img} alt={props.alt} />
                    }
                    {hasIcon &&
                        <FontIcon iconName={props.icon} style={{ color: colors.primary, fontSize: 60 }} />
                    }
                    <Text variant="mediumPlus" styles={{ root: { fontWeight: 500, marginTop: 15, textAlign: 'center' } }}>{props.text}</Text>
                </ContainerNoItems>
                :
                <ContainerNoItems>
                    <FontIcon iconName="StatusErrorFull" style={{ color: colors.error, fontSize: 60 }} />
                    <Text variant="mediumPlus" styles={{ root: { fontWeight: 500, marginTop: 15, textAlign: 'center' } }}>
                        {props.text ? props.text : 'Ocorreu algum problema ao carregar'}
                    </Text>
                </ContainerNoItems>}
        </>
    )
}

export default NoItems;

